<template>
  <div class="tab_area">
    <ul class="tab">
      <li
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{active: activeTab === index}"
          @click="selectTab(index)"
      >
        <button type="button" class="btn_tab">
          {{ tab.name }}
          <i v-if="activeTab === index" class="sr_only">선택됨</i>
        </button>
      </li>
    </ul>
    <div class="tab_conts_wrap">
      <section class="tab_conts" v-show="activeTab === 0">
        <h3 class="title2">실적 현황</h3>
        <sb-grid
            id="prfmncGrid"
            :dataSource="prfmncDataSource"
            :columns="prfmncGridColumns"
            :gridattr="prfmncGridAttr"
            v-model:grid="prfmncGridObject"
            @loaded="prfmncGridLoaded"
            :refreshGrid="prfmncGridReload"
        />
      </section>

      <section class="tab_conts" v-show="activeTab === 1">
        <h3 class="title2">폐기물 지역별 반입 현황</h3>
        <sb-grid
            id="crynGrid"
            :dataSource="crynDataSource"
            :columns="crynGridColumns"
            :gridattr="crynGridAttr"
            v-model:grid="crynGridObject"
            @loaded="crynGridLoaded"
            :refreshGrid="crynGridReload"
        />
      </section>

      <section class="tab_conts" v-show="activeTab === 2">
        <h3 class="title2">사업장별 에너지 판매 현황</h3>
        <sb-grid
            id="ntslDtlGrid"
            :dataSource="ntslDtlDataSource"
            :columns="ntslDtlGridColumns"
            :gridattr="ntslDtlGridAttr"
            v-model:grid="ntslDtlGridObject"
            @loaded="ntslDtlGridLoaded"
            :refreshGrid="ntslDtlGridReload"
        />
      </section>
    </div>
  </div>
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";
import SbGrid from "@/components/SbGrid.vue";

const API = {
  PERFORMANCE_STATUS: "/prfmnc/fireRtrvl/prfmnc-stts",
  CARRY_IN_STATUS: "/prfmnc/fireRtrvl/cryn-stts",
  NET_SALES_DETAILS_STATUS: "/prfmnc/fireRtrvl/ntsl-dtl-stts",
};

export default {
  components: {SbGrid},
  props: ['param'],
  data() {
    return {
      tabs: [
        { name: '실적현황' },
        { name: '반입상세현황' },
        { name: '판매상세현황' },
      ],
      activeTab: 0,
      prfmncGridReload: false,
      prfmncGridObject: null,
      prfmncDataSource: [],
      prfmncGridColumns: [],
      prfmncGridAttr: {
        showRowNo: false,
        pageable: false,
        excelExport: {
          fileName: '실적 현황.xlsx',
          includeHeader: true,
        },
        height: 550,
      },
      crynGridReload: false,
      crynGridObject: null,
      crynDataSource: [],
      crynGridColumns: [],
      crynGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        excelExport: {
          fileName: '폐기물 지역별 반입 현황.xlsx',
          keepMerge: true,
        },
      },
      ntslDtlGridReload: false,
      ntslDtlGridObject: null,
      ntslDtlDataSource: [],
      ntslDtlGridColumns: [],
      ntslDtlGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        excelExport: {
          fileName: '업체별 에너지 판매 현황.xlsx',
          includeHeader: true,
          keepMerge: true,
        }
      },
      prfmncInfo: {},
      crynInfo: {},
      ntslDtlInfo: {},
    };
  },
  computed: {},
  watch: {
    activeTab() {
      this.search();
    }
  },
  created() {
    this.initialize();
  },
  mounted() {
  },
  methods: {
    async initialize() {
      storeSwitch.on("loading");
      await this.loadPrfmncInfo();
      this.initializePrfmncInfo();
      await this.loadCrynInfo();
      this.initializeCrynInfo();
      await this.loadNtslDtlInfo();
      this.initializeNtslDtl();
      storeSwitch.off("loading");
    },
    loadPrfmncInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.PERFORMANCE_STATUS,
            { bplcId: this.param.bplcId, prfmncYr: this.param.prfmncYr },
            data => {
              if (data.result) {
                this.prfmncInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load prfmnc info."));
            }
        );
      });
    },
    loadCrynInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.CARRY_IN_STATUS,
            { bplcId: this.param.bplcId, prfmncYr: this.param.prfmncYr },
            data => {
              if (data.result) {
                this.crynInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load cryn info."));
            }
        );
      });
    },
    loadNtslDtlInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.NET_SALES_DETAILS_STATUS,
            { bplcId: this.param.bplcId, prfmncYr: this.param.prfmncYr },
            data => {
              if (data.result) {
                this.ntslDtlInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load ntsl dtl info."));
            }
        );
      });
    },
    initializePrfmncInfo() {
      this.prfmncDataSource = this.prfmncInfo.list;
      this.initializePrfmncGridColumns();
      this.initializePrfmncGridAttr();
      this.prfmncGridReload = true;
    },
    initializeCrynInfo() {
      this.crynDataSource = this.crynInfo.list;
      this.initializeCrynGridColumns();
      this.initializeCrynGridAttr();
      this.crynGridReload = true;
    },
    initializeNtslDtl() {
      this.ntslDtlDataSource = this.ntslDtlInfo.list;
      this.initializeNtslDtlGridColumns();
      this.initializeNtslDtlGridAttr();
      this.ntslDtlGridReload = true;
    },
    initializePrfmncGridColumns() {
      const is2025OrLater = this.param.prfmncYr >= 2025;

      this.prfmncGridColumns = [
        { field: 'prfmncId', caption: '실적아이디', visible: false },
        { field: 'prfmncYm', caption: '실적연월', type: 'date', width: 100, part: 'head',
          total: { headerCss:'grid-header' },
          format: (date) => {
            if (date && date.length === 6) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              return `${yyyy}-${mm}`;
            }
            return date;
          },
        },
        { field: 'prfmncSttsNm', caption: '실적상태', type: 'date', width: 100,
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          },
        },
        { caption: '폐기물 반입현황',
          columns: [
            { field: 'wtSumCryqty', caption: '반입량 합계(톤)', width: 140, align: 'right',
              dataType: 'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', nullAs: 0, require: 'wtSumCryqty'}],
                header: [{template: '{{sum}}', format: '#,##0.00'}],
                headerCss:'grid-header',
              },
            },
            { field: 'bplcDschrgSumWstqty', caption: '배출사업장계(톤)', width: 140, align: 'right',
              dataType: 'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', nullAs: 0, require: 'bplcDschrgSumWstqty'}],
                header: [{template: '{{sum}}', format: '#,##0.00'}],
                headerCss:'grid-header',
              },
            },
            { field: 'hmeLifeSumWstqty', caption: '가정생활(톤)', width: 140, align: 'right',
              dataType: 'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', nullAs: 0, require: 'hmeLifeSumWstqty'}],
                header: [{template: '{{sum}}', format: '#,##0.00'}],
                headerCss:'grid-header',
              },
            },
            { field: 'bplcLifeSumWstqty', caption: '사업장생활', width: 140, align: 'right',
              dataType: 'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', nullAs: 0, require: 'bplcLifeSumWstqty'}],
                header: [{template: '{{sum}}', format: '#,##0.00'}],
                headerCss:'grid-header',
              },
            },
            { field: 'largeSumWstqty', caption: '대형폐기물(톤)', width: 140, align: 'right',
              dataType: 'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', nullAs: 0, require: 'largeSumWstqty'}],
                header: [{template: '{{sum}}', format: '#,##0.00'}],
                headerCss:'grid-header',
              },
            },
            { field: 'wstsrtSumWstqty', caption: '폐합성수지(톤)', width: 140, align: 'right',
              dataType: 'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', nullAs: 0, require: 'wstsrtSumWstqty'}],
                header: [{template: '{{sum}}', format: '#,##0.00'}],
                headerCss:'grid-header',
              },
            },
            { field: 'wstwdtSumWstqty', caption: '폐목재(톤)', width: 140, align: 'right',
              dataType: 'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', nullAs: 0, require: 'wstwdtSumWstqty'}],
                header: [{template: '{{sum}}', format: '#,##0.00'}],
                headerCss:'grid-header',
              },
            },
            { field: 'wstsftSumWstqty', caption: '폐합성섬유(톤)', width: 140, align: 'right',
              dataType: 'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', nullAs: 0, require: 'wstsftSumWstqty'}],
                header: [{template: '{{sum}}', format: '#,##0.00'}],
                headerCss:'grid-header',
              },
            },
            { field: 'wstvnlSumWstqty', caption: '영농폐비닐(톤)', width: 140, align: 'right',
              dataType: 'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', nullAs: 0, require: 'wstvnlSumWstqty'}],
                header: [{template: '{{sum}}', format: '#,##0.00'}],
                headerCss:'grid-header',
              },
            },
            { field: 'etcSumWstqty', caption: '기타(톤)', width: 140, align: 'right',
              dataType: 'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', nullAs: 0, require: 'etcSumWstqty'}],
                header: [{template: '{{sum}}', format: '#,##0.00'}],
                headerCss:'grid-header',
              },
            },
          ]
        },
        { caption: '폐기물 투입현황',
          columns: [
            { field: 'wtSumInpqty', caption: '투입량 합계(톤)', width: 140, align: 'right',
              dataType: 'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', nullAs: 0, require: 'wtSumInpqty'}],
                header: [{template: '{{sum}}', format: '#,##0.00'}],
                headerCss:'grid-header',
              },
            },
            ...(is2025OrLater ? [
              { field: 'inpBplcDschrgSumWstqty', caption: '배출사업장계(톤)', width: 140, align: 'right',
                dataType: 'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'inpBplcDschrgSumWstqty'}],
                  header: [{template: '{{sum}}', format: '#,##0.00'}],
                  headerCss:'grid-header',
                },
              },
              { field: 'inpHmeLifeSumWstqty', caption: '가정생활(톤)', width: 140, align: 'right',
                dataType: 'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'inpHmeLifeSumWstqty'}],
                  header: [{template: '{{sum}}', format: '#,##0.00'}],
                  headerCss:'grid-header',
                },
              },
              { field: 'inpBplcLifeSumWstqty', caption: '사업장생활(톤)', width: 140, align: 'right',
                dataType: 'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'inpBplcLifeSumWstqty'}],
                  header: [{template: '{{sum}}', format: '#,##0.00'}],
                  headerCss:'grid-header',
                },
              },
              { field: 'inpLargeSumWstqty', caption: '대형폐기물(톤)', width: 140, align: 'right',
                dataType: 'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'inpLargeSumWstqty'}],
                  header: [{template: '{{sum}}', format: '#,##0.00'}],
                  headerCss:'grid-header',
                },
              },
              { field: 'inpWstsrtSumWstqty', caption: '폐합성수지(톤)', width: 140, align: 'right',
                dataType: 'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'inpWstsrtSumWstqty'}],
                  header: [{template: '{{sum}}', format: '#,##0.00'}],
                  headerCss:'grid-header',
                },
              },
              { field: 'inpWstwdtSumWstqty', caption: '폐목재(톤)', width: 140, align: 'right',
                dataType: 'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'inpWstwdtSumWstqty'}],
                  header: [{template: '{{sum}}', format: '#,##0.00'}],
                  headerCss:'grid-header',
                },
              },
              { field: 'inpWstsftSumWstqty', caption: '폐합성섬유(톤)', width: 140, align: 'right',
                dataType: 'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'inpWstsftSumWstqty'}],
                  header: [{template: '{{sum}}', format: '#,##0.00'}],
                  headerCss:'grid-header',
                },
              },
              { field: 'inpWstvnlSumWstqty', caption: '영농폐비닐(톤)', width: 140, align: 'right',
                dataType: 'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'inpWstvnlSumWstqty'}],
                  header: [{template: '{{sum}}', format: '#,##0.00'}],
                  headerCss:'grid-header',
                },
              },
              { field: 'inpEtcSumWstqty', caption: '기타(톤)', width: 140, align: 'right',
                dataType: 'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'inpEtcSumWstqty'}],
                  header: [{template: '{{sum}}', format: '#,##0.00'}],
                  headerCss:'grid-header',
                },
              },
            ] : []),
          ]
        },
        { caption: '보조연료 사용량', columns: [
            ...(!is2025OrLater ? [
              {field: 'oilAsstFuelUsqty', caption: '유류(㎘)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'oilAsstFuelUsqty'}],
                  header: [{template: '{{sum}}', format: '#,##0.00'}],
                  headerCss:'grid-header',
                },
              },
              {field: 'lngAsstFuelUsqty', caption: 'LNG(㎥)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'lngAsstFuelUsqty'}],
                  header: [{template: '{{sum}}', format: '#,##0.00'}],
                  headerCss:'grid-header',
                },
              },
              {field: 'etcAsstFuelUsqty', caption: '기타(TOE)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'etcAsstFuelUsqty'}],
                  header: [{template: '{{sum}}', format: '#,##0.00'}],
                  headerCss:'grid-header',
                },
              },
              { caption: '전기(MWh)', columns: [
                {field: 'crynPrvtmAsstFuelUsqty', caption: '외부반입(한전)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', nullAs: 0, require: 'crynPrvtmAsstFuelUsqty'}],
                    header: [{template: '{{sum}}', format: '#,##0.00'}],
                    headerCss:'grid-header',
                  },
                },
                {field: 'prdctnPrvtmAsstFuelUsqty', caption: '자체생산', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', nullAs: 0, require: 'prdctnPrvtmAsstFuelUsqty'}],
                    header: [{template: '{{sum}}', format: '#,##0.00'}],
                    headerCss:'grid-header',
                  },
                },
              ]}
            ] : []),
            ...(is2025OrLater ? [
              {
                caption: '유류', columns: [
                  {field: 'asstFuel0101', caption: '유류(㎘)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                    total: {
                      aggregates: [{func: 'sum', nullAs: 0, require: 'asstFuel0101'}],
                      header: [{template: '{{sum}}', format: '#,##0.00'}],
                      headerCss:'grid-header',
                    },
                  },
                  {field: 'asstFuel0102', caption: '유류(톤)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                    total: {
                      aggregates: [{func: 'sum', nullAs: 0, require: 'asstFuel0102'}],
                      header: [{template: '{{sum}}', format: '#,##0.00'}],
                      headerCss:'grid-header',
                    },
                  },
                ]
              },
              {field: 'asstFuel0203', caption: '가스(N㎥)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'asstFuel0203'}],
                  header: [{template: '{{sum}}', format: '#,##0.00'}],
                  headerCss:'grid-header',
                },
              },
              {field: 'asstFuel0304', caption: '전기(MWh)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'asstFuel0304'}],
                  header: [{template: '{{sum}}', format: '#,##0.00'}],
                  headerCss:'grid-header',
                },
              },
              {field: 'asstFuel9905', caption: '기타(TOE)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'asstFuel9905'}],
                  header: [{template: '{{sum}}', format: '#,##0.00'}],
                  headerCss:'grid-header',
                },
              },
            ] : []),
          ]
        },
        { caption: '생산 현황',
          columns: [
            /*{ field: 'fireAvgLcp', caption: '평균발열량', width: 140, align: 'right',
              dataType: 'number', format: '#,##0.00',
              total: { headerCss:'grid-header' }
            },*/
            { field: 'eneRtrqty', caption: '총에너지회수량(Gcal)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', nullAs: 0, require: 'eneRtrqty'}],
                header: [{template: '{{sum}}', format: '#,##0.00'}],
                headerCss:'grid-header',
              },
            },
            { field: 'eneSumPrdqty', caption: '에너지발생량(Gcal)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', nullAs: 0, require: 'eneSumPrdqty'}],
                header: [{template: '{{sum}}', format: '#,##0.00'}],
                headerCss:'grid-header',
              },
            },
            { field: 'eneUnuqty', caption: '미이용(단순처리)(Gcal)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', nullAs: 0, require: 'eneUnuqty'}],
                header: [{template: '{{sum}}', format: '#,##0.00'}],
                headerCss:'grid-header',
              },
            },
            { field: 'eneUtztnEfcnc', caption: '에너지이용효율(%)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
              total: { headerCss:'grid-header' },
            },
          ]
        },
        { caption: '에너지 사용',
          columns: [
            ...(!is2025OrLater ? [
              { caption: '자체사용', columns: [
                { field: 'stotUsqty', caption: '소계', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                  total: {
                      aggregates: [{func: 'sum', nullAs: 0, require: 'stotUsqty'}],
                      header: [{template: '{{sum}}', format: '#,##0.00'}],
                      headerCss: 'grid-header',
                    },
                },
                { field: 'htsrcUsqty', caption: '열사용(Gcal)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                  total: {
                      aggregates: [{func: 'sum', nullAs: 0, require: 'htsrcUsqty'}],
                      header: [{template: '{{sum}}', format: '#,##0.00'}],
                      headerCss: 'grid-header',
                    },
                },
                { field: 'etcUsgUsqty', caption: '기타(Gcal)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                  total: {
                      aggregates: [{func: 'sum', nullAs: 0, require: 'etcUsgUsqty'}],
                      header: [{template: '{{sum}}', format: '#,##0.00'}],
                      headerCss: 'grid-header',
                    },
                },
              ]},
              { caption: '외부공급', columns: [
                { field: 'stotNsqty', caption: '소계', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                  total: {
                      aggregates: [{func: 'sum', nullAs: 0, require: 'stotNsqty'}],
                      header: [{template: '{{sum}}', format: '#,##0.00'}],
                      headerCss: 'grid-header',
                    },
                },
                { field: 'htsrcSumNsqty', caption: '열공급(Gcal)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                  total: {
                      aggregates: [{func: 'sum', nullAs: 0, require: 'htsrcSumNsqty'}],
                      header: [{template: '{{sum}}', format: '#,##0.00'}],
                      headerCss: 'grid-header',
                    },
                },
                { field: 'etcUsgSumNsqty', caption: '기타(Gcal)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                  total: {
                      aggregates: [{func: 'sum', nullAs: 0, require: 'etcUsgSumNsqty'}],
                      header: [{template: '{{sum}}', format: '#,##0.00'}],
                      headerCss: 'grid-header',
                    },
                },
              ]},
              { field: 'elctgnEquipInpqty', caption: '발전설비투입량(Gcal)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'elctgnEquipInpqty'}],
                  header: [{template: '{{sum}}', format: '#,##0.00'}],
                  headerCss: 'grid-header',
                },
              },
              { caption: '전력발전 생산/이용 현황', columns: [
                { field: 'prvtmSumQty', caption: '발전량 합계(MWh)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', nullAs: 0, require: 'prvtmSumQty'}],
                    header: [{template: '{{sum}}', format: '#,##0.00'}],
                    headerCss: 'grid-header',
                  },
                },
                { field: 'prvtmSumUsqty', caption: '자체사용량(MWh)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', nullAs: 0, require: 'prvtmSumUsqty'}],
                    header: [{template: '{{sum}}', format: '#,##0.00'}],
                    headerCss: 'grid-header',
                  },
                },
                { field: 'prvtmSumNsqty', caption: '외부판매량(MWh)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', nullAs: 0, require: 'prvtmSumNsqty'}],
                    header: [{template: '{{sum}}', format: '#,##0.00'}],
                    headerCss: 'grid-header',
                  },
                },
                { field: 'etcPrvtmUsqty', caption: '기타(MWh)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', nullAs: 0, require: 'etcPrvtmUsqty'}],
                    header: [{template: '{{sum}}', format: '#,##0.00'}],
                    headerCss: 'grid-header',
                  },
                },
              ]},
            ] : []),
            ...(is2025OrLater ? [
              { caption: '증기', captionCss:'gridh-bg-vapor', columns: [
                { caption: '자체이용(톤)', captionCss:'gridh-bg-vapor', columns: [
                  { field: 'stUsgSumUsqtyTon', captionCss:'gridh-bg-vapor', caption: '스팀이용(증기톤)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                    total: {
                      aggregates: [{func: 'sum', nullAs: 0, require: 'stUsgSumUsqtyTon'}],
                      header: [{template: '{{sum}}', format: '#,##0.00'}],
                      headerCss: 'grid-header',
                    },
                  },
                  { field: 'htwtrPrdctnUsgSumUsqtyTon', captionCss:'gridh-bg-vapor', caption: '온수이용(증기톤)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                    total: {
                      aggregates: [{func: 'sum', nullAs: 0, require: 'htwtrPrdctnUsgSumUsqtyTon'}],
                      header: [{template: '{{sum}}', format: '#,##0.00'}],
                      headerCss: 'grid-header',
                    },
                  },
                  { field: 'elctgnUsgSumUsqtyTon', captionCss:'gridh-bg-vapor', caption: '발전이용(증기톤)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                    total: {
                      aggregates: [{func: 'sum', nullAs: 0, require: 'elctgnUsgSumUsqtyTon'}],
                      header: [{template: '{{sum}}', format: '#,##0.00'}],
                      headerCss:'grid-header',
                    },
                  },
                ]},
                { field: 'vaporUsqty', captionCss:'gridh-bg-vapor', caption: '자체이용(Gcal)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', nullAs: 0, require: 'vaporUsqty'}],
                    header: [{template: '{{sum}}', format: '#,##0.00'}],
                    headerCss:'grid-header',
                  },
                },
                { field: 'vaporNsqtyTon', captionCss:'gridh-bg-vapor', caption: '외부판매(톤)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', nullAs: 0, require: 'vaporNsqtyTon'}],
                    header: [{template: '{{sum}}', format: '#,##0.00'}],
                    headerCss:'grid-header',
                  },
                },
                { field: 'vaporNsqty', captionCss:'gridh-bg-vapor', caption: '외부판매(Gcal)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', nullAs: 0, require: 'vaporNsqty'}],
                    header: [{template: '{{sum}}', format: '#,##0.00'}],
                    headerCss:'grid-header',
                  },
                },
              ]},
              { caption: '온수', captionCss:'gridh-bg-hot-water', columns: [
                { field: 'htwtrUsqty', captionCss:'gridh-bg-hot-water', caption: '자체이용(Gcal)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', nullAs: 0, require: 'htwtrUsqty'}],
                    header: [{template: '{{sum}}', format: '#,##0.00'}],
                    headerCss:'grid-header',
                  },
                },
                { field: 'htwtrNsqty', captionCss:'gridh-bg-hot-water', caption: '외부판매(Gcal)', width: 140, align: 'right',dataType: 'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', nullAs: 0, require: 'htwtrNsqty'}],
                    header: [{template: '{{sum}}', format: '#,##0.00'}],
                    headerCss:'grid-header',
                  },
                },
              ]},
              { caption: '전기', captionCss:'gridh-bg-electricity', columns: [
                { field: 'prvtmUsqty', captionCss:'gridh-bg-electricity', caption: '자체이용(MWh)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', nullAs: 0, require: 'htwtrUsqty'}],
                    header: [{template: '{{sum}}', format: '#,##0.00'}],
                    headerCss:'grid-header',
                  },
                },
                { field: 'prvtmNsqty', captionCss:'gridh-bg-electricity', caption: '외부판매(MWh)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', nullAs: 0, require: 'prvtmNsqty'}],
                    header: [{template: '{{sum}}', format: '#,##0.00'}],
                    headerCss:'grid-header',
                  },
                },
              ]},
          ] : [])
        ]},
        { caption: '에너지 판매',
          columns: [
            ...(!is2025OrLater ? [
              { field: 'htsrcSumNsqty', caption: '열 판매량(Gcal)', width: 140, align: 'right', dataType:'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'htsrcSumNsqty'}],
                  header: [{ template: '{{sum}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
              },
              { field: 'prvtmSumNsqty', caption: '전력 판매량(MWh)', width: 140, align: 'right', dataType:'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'prvtmSumNsqty'}],
                  header: [{ template: '{{sum}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
              },
              { field: 'etcEneSumNsqty', caption: '기타 판매량(Gcal)', width: 140, align: 'right', dataType:'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'etcEneSumNsqty'}],
                  header: [{ template: '{{sum}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
              },
            ] : []),
            ...(is2025OrLater ? [
              { field: 'vaporSumNsqty', captionCss:'gridh-bg-vapor', caption: '증기(톤)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'vaporSumNsqty'}],
                  header: [{template: '{{sum}}', format: '#,##0.00'}],
                  headerCss:'grid-header',
                },
              },
              { field: 'htwtrSumNsqty', captionCss:'gridh-bg-hot-water', caption: '온수(Gcal)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'htwtrSumNsqty'}],
                  header: [{template: '{{sum}}', format: '#,##0.00'}],
                  headerCss:'grid-header',
                },
              },
              { field: 'prvtmSumNsqty', captionCss:'gridh-bg-electricity', caption: '전기(MWh)', width: 140, align: 'right', dataType: 'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'prvtmSumNsqty'}],
                  header: [{template: '{{sum}}', format: '#,##0.00'}],
                  headerCss:'grid-header',
                },
              },
            ] : []),
          ]
        }
      ]
    },
    initializePrfmncGridAttr() {
    },
    initializeCrynGridColumns() {
      this.crynGridColumns = [
        { field: 'prfmncId', caption: '실적아이디', visible: false },
        { field: 'prfmncYm', caption: '실적연월', type: 'date', width: 100, mergeRow: true, part: 'head',
          total: { headerCss:'grid-header' },
          format: (date) => {
            if (date && date.length === 6) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              return `${yyyy}-${mm}`;
            }
            return date;
          },
        },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false },
        { field: 'ctpvStdgNm', caption: '시·도', width: 110,
          mergeRow: (data) => data.prfmncYm + '.' + data.ctpvStdgCd,
          total: { headerCss:'grid-header' }
        },
        { field: 'sggStdgNm', caption: '시·군·구', width: 110,
          total: {
            aggregates: [
            ],
            header: [
              { template: '합계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        { caption: '반입량(톤)',
          columns: [
            { field: 'wtStotSumCryqty', caption: '반입량 합계', width: 140, align: 'right', dataType:'number', format: '#,##0.00',
              total: this.gridTotalSubSumObj('wtStotSumCryqty')
            },
            { field: 'bplcDschrgSumWstqty', caption: '배출시설계(톤)', width: 140, align: 'right', dataType:'number', format: '#,##0.00',
              total: this.gridTotalSubSumObj('bplcDschrgSumWstqty')
            },
            { field: 'hmeLifeSumWstqty', caption: '가정생활(톤)', width: 140, align: 'right', dataType:'number', format: '#,##0.00',
              total: this.gridTotalSubSumObj('hmeLifeSumWstqty')
            },
            { field: 'bplcLifeSumWstqty', caption: '사업장생활(톤)', width: 140, align: 'right', dataType:'number', format: '#,##0.00',
              total: this.gridTotalSubSumObj('bplcLifeSumWstqty')
            },
            { field: 'largeSumWstqty', caption: '대형폐기물(톤)', width: 140, align: 'right', dataType:'number', format: '#,##0.00',
              total: this.gridTotalSubSumObj('largeSumWstqty')
            },
            { field: 'wstsrtSumWstqty', caption: '폐합성수지(톤)', width: 140, align: 'right', dataType:'number', format: '#,##0.00',
              total: this.gridTotalSubSumObj('wstsrtSumWstqty')
            },
            { field: 'wstwdtSumWstqty', caption: '폐목재(톤)', width: 140, align: 'right', dataType:'number', format: '#,##0.00',
              total: this.gridTotalSubSumObj('wstwdtSumWstqty')
            },
            { field: 'wstsftSumWstqty', caption: '폐합성섬유(톤)', width: 140, align: 'right', dataType:'number', format: '#,##0.00',
              total: this.gridTotalSubSumObj('wstsftSumWstqty')
            },
            { field: 'wstvnlSumWstqty', caption: '영농폐비닐(톤)', width: 140, align: 'right', dataType:'number', format: '#,##0.00',
              total: this.gridTotalSubSumObj('wstvnlSumWstqty')
            },
            { field: 'etcSumWstqty', caption: '기타(톤)', width: 140, align: 'right', dataType:'number', format: '#,##0.00',
              total: this.gridTotalSubSumObj('etcSumWstqty')
            },
          ]
        }
      ]
    },
    initializeCrynGridAttr() {
    },
    initializeNtslDtlGridColumns() {
      const is2025OrLater = this.param.prfmncYr >= 2025;

      this.ntslDtlGridColumns = [
        { field: 'prfmncId', caption: '실적아이디', visible: false },
        { field: 'prfmncYm', caption: '실적연월', type: 'date', width: 100, mergeRow: true, part: 'head',
          total: { headerCss:'grid-header' },
          format: (date) => {
            if (date && date.length === 6) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              return `${yyyy}-${mm}`;
            }
            return date;
          },
        },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false },
        { field: 'ctpvStdgNm', caption: '시·도', width: 110,
          mergeRow: (data) => data.prfmncYm + '.' + data.ctpvStdgCd,
          total: { headerCss:'grid-header' },
        },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false },
        { field: 'sggStdgNm', caption: '시·군·구', width: 110,
          mergeRow: (data) => data.prfmncYm + '.' + data.ctpvStdgCd + "." + data.sggStdgCd,
          total: { headerCss:'grid-header' },
        },
        { field: 'bzentyNm', caption: '업체명', minWidth: 200, width:100, unit:'%', align: 'left',
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          },
        },
        { caption: '에너지 판매',
          columns: [
            ...(!is2025OrLater ? [
              { field: 'htsrcNsqty', caption: '열 판매량(Gcal)', width: 150, align: 'right', dataType:'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'htsrcNsqty'}],
                  header: [{ template: '{{sum}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
              },
              { field: 'prvtmNsqty', caption: '전력 판매량(MWh)', width: 150, align: 'right', dataType:'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'prvtmNsqty'}],
                  header: [{ template: '{{sum}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
              },
              { field: 'etcEneNsqty', caption: '기타 판매량(Gcal)', width: 150, align: 'right', dataType:'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'etcEneNsqty'}],
                  header: [{ template: '{{sum}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
              },
            ] : []),
            ...(is2025OrLater ? [
              { field: 'vaporNsqty', captionCss:'gridh-bg-vapor', caption: '증기(톤)', width: 150, align: 'right', dataType: 'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'vaporNsqty'}],
                  header: [{template: '{{sum}}', format: '#,##0.00'}],
                  headerCss:'grid-header',
                },
              },
              { field: 'htwtrNsqty', captionCss:'gridh-bg-hot-water', caption: '온수(Gcal)', width: 150, align: 'right', dataType: 'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'htwtrNsqty'}],
                  header: [{template: '{{sum}}', format: '#,##0.00'}],
                  headerCss:'grid-header',
                },
              },
              { field: 'prvtmNsqty', captionCss:'gridh-bg-electricity', caption: '전기(MWh)', width: 150, align: 'right', dataType: 'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0, require: 'prvtmNsqty'}],
                  header: [{template: '{{sum}}', format: '#,##0.00'}],
                  headerCss:'grid-header',
                },
              },
            ] : []),
          ]
        },
      ]
    },
    initializeNtslDtlGridAttr() {
    },
    prfmncGridLoaded() {
      this.prfmncGridReload = false;
    },
    crynGridLoaded() {
      this.crynGridReload = false;
    },
    ntslDtlGridLoaded() {
      this.ntslDtlGridReload = false;
    },
    selectTab(index) {
      this.activeTab = index;
    },
    async prfmncSearch() {
      await this.loadPrfmncInfo();
      this.initializePrfmncInfo();
    },
    async crynSearch() {
      await this.loadCrynInfo();
      window.SBGrid3.setClientData(this.crynGridObject, this.crynInfo.list || []);
    },
    async ntslDtlSearch() {
      await this.loadNtslDtlInfo();
      this.initializeNtslDtl();
    },
    async search() {
      storeSwitch.on("loading");
      switch (this.activeTab) {
        case 0: // 실적현황
            await this.prfmncSearch();
          break;
        case 1: // 반입상세현황
            await this.crynSearch();
          break;
        case 2: // 판매상세현황
            await this.ntslDtlSearch();
          break;
      }
      storeSwitch.off("loading");
    },
    gridTotalSubSumObj(field){
      return {
        aggregates: [
          {
            func: (items) => items
                .filter(entry => entry.rowType === 'SUB_SUM')
                .reduce((sum, entry) => sum + (entry[field] || 0), 0),
            field: `${field}Total`
          }
        ],
        header: [
          { template: `{{${field}Total}}`, format: '#,##0.00' }
        ],
        headerCss:'grid-header',
      }
    }
  }
}
</script>

<style scoped>

</style>