<template>
  <h2 class="title1">수정허용요청 이력 현황</h2>
  <sb-grid
      id="prfmncMdfncPrmGrid"
      :dataSource="dataSource"
      :columns="gridColumns"
      :gridattr="gridAttr"
      :param="searchParam"
      v-model:grid="gridObject"
      @loaded="gridLoaded"
      :refreshGrid="gridReload"
  />
</template>

<script>
import SbGrid from "@/components/SbGrid.vue";

const API = {
  PERFORMANCE_MODIFIER_PERMIT_HISTORY : "/prfmnc/mdfnc-prm-hstry",
};

export default {
  components: {SbGrid},
  props: ['prfmncId'],
  data() {
    return {
      searchParam: {
        prfmncId: this.prfmncId,
      },
      gridReload: false,
      gridObject: null,
      dataSource: {
        ajaxConfig: {
          select: {url: API.PERFORMANCE_MODIFIER_PERMIT_HISTORY},
          paging: true,
        },
        pageSize: 5,
      },
      gridColumns: [
        {field: 'prfmncId', caption: '실적ID', visible: false},
        {field: 'prfmncMdfcnPrmSqno', caption: '실적수정허용순번', visible: false},
        {field: 'prfmncMdfcnPrmSttsNm', caption: '요청상태', width: 100,},
        {field: 'dmndDt', caption: '요청일자', width: 120 },
        {field: 'dmndUserNm', caption: '요청자명', width: 120, },
        {field: 'mdfcnPrmDmndRsn', caption: '요청사유', width: 50, unit: '%',
          tooltip: { cell: { placement: 'bottom'}  }
        },
        {field: 'prcsDt', caption: '처리일자', width: 120},
        {field: 'prcsUserNm', caption: '처리자명', width: 120,},
        {field: 'mdfcnPrmPrcsRsn', caption: '처리사유', width: 50, unit: '%',
          tooltip: { cell: { placement: 'bottom'}  }
        },
      ],
      gridAttr: {
        pageable: true,
        height: 264,
      },
    };
  },
  watch: {
    prfmncId(newValue) {
      if (newValue) {
        this.searchParam.prfmncId = newValue;
        this.gridReload = true;
      }
    }
  },
  mounted() {
    if (this.searchParam.prfmncId) {
      this.gridReload = true;
    }
  },
  methods: {
    gridLoaded() {
      this.gridReload = false;
    }
  }
}
</script>

<style scoped>

</style>