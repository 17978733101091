<template>
  <label :for="label" style="display: none">{{ label }}</label>
  <textarea
      :id = "label"
      :class="clazz"
      :rows="rows"
      v-model="inputValue"
      :placeholder="placeholder"
      @keyup="onKeyUp"
      :disabled="disabled"
      :readonly="readonly"
      :maxlength="maxlength"
  >
  </textarea>
</template>

<script>
import { storeValidator } from "@/js/store/store-validator";
import { storeProhibit } from "@/js/store/store-prohibit";
import pp from '@/js/postposition';

export default {
  props: {
    clazz: { type: String, default: 'form_control' },
    label: { type: String, default: '' },
    modelValue: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    rows: { type: Number, default: 5 },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    prohibited: { type: Boolean, default: true },
    readonly: { type: Boolean, default: false },
    maxlength: { type: Number, default: null },
    panel: { type: String, default: '' },
  },
  emits: ['update:modelValue', 'keyup'],
  data() {
    return {
      inputValue: this.modelValue,
      hasError: false,
      errorMessage: "",
    };
  },
  watch: {
    modelValue(newValue) {
      this.inputValue = newValue;
    },
    inputValue() {
      this.$emit('update:modelValue', this.inputValue);
      this.validate();
    },
  },
  created() {
    this.validate();
    storeValidator.add(this.$route.path, this.panel, this);
  },
  beforeUnmount() {
    storeValidator.remove(this.$route.path, this.panel, this.label);
  },
  methods: {
    validate() {
      this.validateEmpty();
      if (this.inputValue && this.inputValue.length > 0) {
        this.validateProhibit();
      }
    },
    validateEmpty() {
      if (this.required && this.inputValue === "") {
        this.hasError = true;
        this.errorMessage = `${pp.eunNn(this.label)} 필수 입력값 입니다.`;
      } else {
        this.hasError = false;
        this.errorMessage = "";
      }
    },
    validateProhibit() {
      if (storeProhibit.validate(this.inputValue)) {
        this.hasError = false;
        this.errorMessage = "";
      } else {
        this.hasError = true;
        this.errorMessage = `${this.label}에 금지어가 포함되어있습니다.`;
      }
    },
    onKeyUp(event) {
      this.$emit('keyup', event.target.value);
    },
  }
}
</script>

<style scoped>

</style>