<template>
  <div>
    <div class="main-container">
      <div class="editor-container editor-container_classic-editor" ref="editorContainerElement">
        <div class="editor-container__editor">
          <div ref="editorElement">
            <ckeditor v-if="isReady"
                      :editor="editor"
                      v-model="inputValue"
                      :config="config"
                      :disabled="disabled"
                      tag-name="textarea"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {
  ClassicEditor,
  Alignment,
  Autoformat,
  AutoImage,
  Autosave,
  Bold,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  HtmlComment,
  ImageBlock,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MediaEmbed,
  Paragraph,
  SelectAll,
  SimpleUploadAdapter,
  Table,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  Undo
} from 'ckeditor5';
import {UploadAdapter} from "@/js/ckeditor/upload-adapter";
import translations from 'ckeditor5/translations/ko.js';

import 'ckeditor5/ckeditor5.css';
import '@/css/ckeditor.css'
import pp from "@/js/postposition";
import {storeValidator} from "@/js/store/store-validator";
import {storeProhibit} from "@/js/store/store-prohibit";

export default {
  props : {
    label : {type : String, required : true},
    modelValue : {type : String, default : ""},
    placeholder : {type : String, default : ""},
    required : {type : Boolean, default : false},
    disabled : {type : Boolean, default : false},
    prohibited: { type: Boolean, default: true },
    panel : {type : String, default : ''},
  },
  emits : ['update:modelValue'],
  watch : {
    modelValue(newValue) {
      this.inputValue = newValue;
    },
    inputValue(newValue) {
      this.$emit('update:modelValue', newValue);
      this.validate();
    },
  },
  created() {
    this.validate();
    storeValidator.add(this.$route.path, this.panel, this);
  },
  beforeUnmount() {
    storeValidator.remove(this.$route.path, this.panel, this.label);
  },
  data() {
    return {
      isReady : false,
      editor : ClassicEditor,
      inputValue : this.modelValue,
      config : null,
      hasError : false,
      errorMessage : "",
    };
  },
  mounted() {
    this.config = {
      toolbar : {
        items : [
          'undo',
          'redo',
          '|',
          'heading',
          '|',
          'fontSize',
          'fontFamily',
          'fontColor',
          'fontBackgroundColor',
          '|',
          'bold',
          'italic',
          '|',
          'link',
          'insertImage',
          'mediaEmbed',
          'insertTable',
          '|',
          'alignment',
          '|',
          'bulletedList',
          'numberedList',
          'outdent',
          'indent',
        ],
        shouldNotGroupWhenFull : false
      },
      plugins : [
        Alignment,
        Autoformat,
        AutoImage,
        Autosave,
        Bold,
        Essentials,
        FontBackgroundColor,
        FontColor,
        FontFamily,
        FontSize,
        Heading,
        HtmlComment,
        ImageBlock,
        ImageInsert,
        ImageInsertViaUrl,
        ImageResize,
        ImageStyle,
        ImageToolbar,
        ImageUpload,
        Indent,
        IndentBlock,
        Italic,
        Link,
        List,
        MediaEmbed,
        Paragraph,
        SelectAll,
        SimpleUploadAdapter,
        Table,
        TableCellProperties,
        TableColumnResize,
        TableProperties,
        TableToolbar,
        Undo
      ],
      fontFamily : {
        supportAllValues : true
      },
      fontSize : {
        options : [10, 12, 14, 'default', 18, 20, 22],
        supportAllValues : true
      },
      heading : {
        options : [
          {
            model : 'paragraph',
            title : 'Paragraph',
            class : 'ck-heading_paragraph'
          },
          {
            model : 'heading1',
            view : 'h1',
            title : 'Heading 1',
            class : 'ck-heading_heading1'
          },
          {
            model : 'heading2',
            view : 'h2',
            title : 'Heading 2',
            class : 'ck-heading_heading2'
          },
          {
            model : 'heading3',
            view : 'h3',
            title : 'Heading 3',
            class : 'ck-heading_heading3'
          },
        ]
      },
      image : {
        toolbar : [
          'imageTextAlternative',
          '|',
          'imageStyle:alignBlockLeft',
          'imageStyle:block',
          'imageStyle:alignBlockRight',
          '|',
          'resizeImage'
        ],
        styles : {
          options : ['alignBlockLeft', 'block', 'alignBlockRight']
        }
      },
      link : {
        addTargetToExternalLinks : true,
        defaultProtocol : 'https://',
      },
      list : {
        properties : {
          styles : true,
          startIndex : true,
          reversed : true
        }
      },
      placeholder : this.getPlaceHolder(),
      table : {
        contentToolbar : ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
      },
      extraPlugins : [UploadAdapterPlugin],
      language : 'ko',
      translations : [translations]
    }

    this.isReady = true;
  },
  methods : {
    validate() {
      this.validateEmpty();
      if (this.inputValue && this.inputValue.length > 0) {
        this.validateProhibit();
      }
    },
    validateEmpty() {
      if (this.required && this.inputValue === "") {
        this.hasError = true;
        this.errorMessage = `${pp.eunNn(this.label)} 필수 입력값 입니다.`;
      } else {
        this.hasError = false;
        this.errorMessage = "";
      }
    },
    validateProhibit() {
      if (storeProhibit.validate(this.inputValue)) {
        this.hasError = false;
        this.errorMessage = "";
      } else {
        this.hasError = true;
        this.errorMessage = `${this.label}에 금지어가 포함되어있습니다.`;
      }
    },
    getPlaceHolder() {
      return !this.disabled && !this.placeholder ? `${this.label} 입력` : this.placeholder;
    }
  }
};

function UploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new UploadAdapter(loader);
  };
}

</script>