<template>
    <div class="board_info">
      <h2 class="title1">강의시청내역조회</h2>
    </div>
    <div class="sch_form_wrap">
        <div class="sch_form row2">
            <span>
                <label>구분</label>
            <br-form-select label="구분" v-model:value="Param.searchType" :options="searchTypelist" allSelectable/>
            </span>
            <span>
                <label>검색어</label>
                <br-form-input label="검색어 입력" v-model="Param.keyword" @enterKeyup="search"/>
            </span>
            <span>
                <label>교육기간</label>
                <div class="input_group">
                    <br-date-picker label="교육기간" v-model:value="Param.eduYmd" @enterKeyup="search"/>
                </div>
            </span>
            <span>
                <label>수료여부</label>
                <br-form-radio label="수료여부" :options="eduFnshYnlist" v-model:value="Param.eduFnshYn" allSelectable @enterKeyup="search"/>
            </span>

        </div>
        <div style="height: 40px;"></div>
        <div class="btn_area" >
            <br-anchor-button role="button" label="검색" size="sm" format="sch" @click="search" />
            <br-anchor-button role="button" label="새로고침" size="sm" format="txtN" icon="ico_refresh" @click="reset" />
        </div>
    </div>
    <div class="board_info">
        <p class="page">
            <span class="total">전체 <strong>{{ Number(watchList.DataCount).toLocaleString() }}</strong></span>
            <span>페이지 <strong>{{ this.searchParam.page }}/{{ this.maxPage }}</strong></span>
        </p>
    </div>
    <div :class="['board_list', { scroll: watchList.length > 0 }]">
        <table>
          <caption>시설검사신청 현황 조회-검사신청번호, 검사구분, 시설 명, 시설종류, 신청일자, 접수일자, 검사(예정)일자, 진행상태, 검사결과로 구성
          </caption>
          <colgroup>
              <col style="width:3%">
              <col style="width:20%">
              <col style="width:20%">
              <!--<col style="width:15%">-->
              <!--<col style="width:10%">-->
              <col style="width:5%">
              <col style="width:5%">
              <col style="width:5%">
              <col style="width:5%">
              <col style="width:5%">
          </colgroup>
          <thead>
              <tr>
                  <th scope="col">번호</th>
                  <th scope="col">강좌명</th>
                  <th scope="col">강의명</th>
                  <!--<th scope="col">교육기간</th>-->
                  <!--<th scope="col">강좌신청일자</th>-->
                  <th scope="col">시청시작일자</th>
                  <th scope="col">수강진행률</th>
                  <th scope="col">완강여부</th>
                  <th scope="col">수료여부</th>
                  <th scope="col">교육방법</th>
              </tr>
          </thead>
          <tbody>
              <!-- 데이터가 없을 때 메시지 표시 -->
              <tr v-if="watchList.length === 0">
                  <td colspan="9" style="text-align: center; padding: 20px;">조회된 데이터가 없습니다.</td>
              </tr>
              <!-- 데이터가 있을 때 테이블 데이터 표시 -->
              <tr  v-for="(item, index) in watchList" v-bind:key="index">
                  <td aria-label="번호">{{ index + 1 }}</td>
                  <td aria-label="강좌명" style="text-align: left;">{{ item.eduNm }}</td>
                  <td aria-label="강의명" style="text-align: left;">{{ item.lctrNm }}</td>
                  
                  <!--<td aria-label="교육기간">{{ item.eduYmd }}</td>-->
                  <!--<td aria-label="강좌신청일자">{{ item.eduAplyYmd }}</td>-->
                  <td aria-label="시청시작일자">{{ item.mdfcnDt }}</td>
                  <td v-if="item.rgcrsPrgrt" aria-label="수강진행률" >{{ item.rgcrsPrgrt }} %</td>
                  <td v-if="!item.rgcrsPrgrt" aria-label="수강진행률" >0 %</td>
                  <td aria-label="완강여부"><span :class="['badge', item.rgcrsCmptnYn === 'Y' ? 'primary' : 'bg1']">{{ item.rgcrsCmptnYn === 'Y' ? '완료' : '진행중' }}</span></td>
                  <td aria-label="수료여부">{{ item.eduFnshYn === 'Y' ? '수료' : '미수료' }}</td>
                  <td aria-label="교육방법">{{ item.eduMthdCd === '01' ? '온라인' : '오프라인' }}</td>
              </tr>
          </tbody>
      </table>
  </div>
  <!-- 그리드 페이징 영역 -->
  <insp-pagination :total="watchList.DataCount" :size="searchParam.size" v-model:page="searchParam.page" v-model:maxPage="maxPage" @clickedPage="readOnlineEducationWatchInq"></insp-pagination>
</template>
  
<script>/**
 * 온라인교육 강의시청 현황조회 화면입니다.
 *
 * 작성자 : 정미라
 * 작성일 : 24. 10. 17.
 * 버전 : 1.0
 */
 import inspPagination from "@/views/sfli/components/inspPagination.vue";

 const API = {
    ONLINE_EDUCATION : "/online-education/LectureWatchInq"
 }

 const PAGE_SIZE = 10;

 export default {
    components: { inspPagination },
    data() {
      return {
        searchParam: {
            page: 1
        },
        Param :{
            searchType : "",
            keyword : "",
            eduYmd : "",
            eduFnshYn : "",
            eduAplyStartDate : ""
        },
        searchTypelist : [
            { value: "01", text: "강좌명" },
            { value: "02", text: "강좌설명" }
        ],
        eduFnshYnlist : [
          { value: "",  text: "전체"},
          { value: "Y", text: "수료" },
          { value: "N", text: "미수료" },
        ],
        maxPage: 1,
        DataCount: 0,
        watchList : []
      };
    },
    watch : {},
    created() {},
    beforeMount() {},
    mounted() {
        this.readOnlineEducationWatchInq();
    },
    beforeUnmount() {},
    methods: {
        readOnlineEducationWatchInq() {
            this.searchParam.size = PAGE_SIZE;
            this.$apiCall.get(API.ONLINE_EDUCATION, this.searchParam, this.readOnlineEducationWatchInqSuccess, this.error);
        },
        readOnlineEducationWatchInqSuccess(data) {
            if (!data || !data.result || !data.result.list) {
                this.error();
                return;
            }
            this.watchList = data.result.list; 
            this.watchList.DataCount = data.result.total;
        },
        error() {
        alert("데이터를 가져오는 중 오류가 발생했습니다.");
        },
        reset() {
            this.Param.searchType = "",
            this.Param.keyword = "",
            this.Param.eduYmd = "",
            this.Param.eduFnshYn = "",    
            this.search();
        },
        search(){
            const params = {
                searchType: this.Param.searchType,
                keyword: this.Param.keyword,
                eduYmd: this.Param.eduYmd,
                eduFnshYn: this.Param.eduFnshYn,
                TEST: this.Param.TEST
            };

            // 쿼리스트링으로 변환된 파라미터를 API에 전달
            const queryString = new URLSearchParams(params).toString();

            this.$apiCall.get(`${API.ONLINE_EDUCATION}?${queryString}`, {}, this.readOnlineEducationWatchInqSuccess, this.error);
        }
    }
  };
  </script>
  
  <style scoped>
  .sch_form > span:last-child {
     padding-right: 0rem; 
    }
  
  </style>