<template>
  <div class="sch_form_wrap">
    <div class="sch_form row2">
      <span>
        <label>실적연월</label>
        <br-form-select label="실적연도" v-model:value="searchParam.prfmncYr" :options="prfmncYrList" @change="search" allSelectable />
        <br-form-select label="실적월" v-model:value="searchParam.prfmncMm" :options="prfmncMmList" @change="search" allSelectable />
      </span>
      <span>
        <label>사업장종류</label>
        <br-form-checkbox label="사업장종류" v-model:values="searchParam.bplcKndCds" :options="bplcKndList" @change="search" allSelectable />
      </span>
      <span>
        <label>사업장</label>
        <br-form-select label="사업장" v-model:value="searchParam.bplcId" :options="filteredBplcList" @change="search" allSelectable />
      </span>
      <span>
        <label>실적상태</label>
        <br-form-select label="실적상태" customClass="form_select w45p" v-model:value="searchParam.prfmncSttsCd" :options="prfmncSttsList" @change="search" allSelectable />
      </span>
    </div>
    <div class="btn_area">
      <br-anchor-button role="link" label="검색" size="sm" format="sch" @click="search" />
      <br-anchor-button role="link" label="새로고침" size="sm" format="txtN" icon="ico_refresh" @click="reset" />
    </div>
  </div>
  <div class="txt_box">
    <ul class="dot_list">
      <li>매월 10일까지 이전년월의 실적을 제출해주시기 바랍니다.</li>
      <li>접수/승인된 실적을 변경하고자 하는 경우 수정허용을 요청해주시기 바랍니다.</li>
      <li>본 목록의 생산량 및 사용&판매량은 동일한 단위(톤/Nm³/Gcal)에 해당되는 에너지만 합계하여 표시합니다.</li>
      <li>실적삭제는 실적상태가 "작성중"인 경우에만 가능합니다. </li>
    </ul>
  </div>
  <div class="board_info mt_20">
    <h3 class="title2">실적보고 목록</h3>
    <div class="btn_area">
      <br-button label="수정허용요청" size="sm" @click="showMdfcnPrmPopup" />
      <br-button label="회수" size="sm" format="tertiary" @click="retrieval" />
      <br-button label="신규등록" size="sm" format="secondary" @click="showNewRegPopup" />
      <br-button label="실적삭제" size="sm" format="tertiary" @click="deletePrfmncRpt" />
    </div>
  </div>
  <sb-grid
      id="prfmncGrid"
      :dataSource="dataSource"
      :columns="gridColumns"
      :gridattr="gridAttr"
      :param="searchParam"
      v-model:grid="prfmncGridObject"
      @loaded="gridLoaded"
      :refreshGrid="gridReload"
      @row-double-clicked="toDetail"
  />

  <prfmnc-new-reg-idnty-popup @prfmnc-reg="showBplcInfoPopup" />
  <bplc-info-idnty-popup />
  <prfmnc-mdfcn-prm-popup @completed="search" />
</template>

<script>
import { storeComponent } from "@/js/store/store-component";
import { storeSwitch } from "@/js/store/store-switch";
import SbGrid from "@/components/SbGrid.vue";
import PrfmncNewRegIdntyPopup from "@/views/prfmnc-rpt/components/popup/PrfmncNewRegIdntyPopup.vue";
import BplcInfoIdntyPopup from "@/views/prfmnc-rpt/components/popup/BplcInfoIdntyPopup.vue";
import PrfmncMdfcnPrmPopup from "@/views/prfmnc-rpt/components/popup/PrfmncMdfcnPrmPopup.vue";

const API = {
  MEMBER_BPLC: "/member/bplc-list",
  PERFORMANCE_LIST: "/prfmnc/list-all",
  PERFORMANCE_LIST_STATUS: "/prfmnc/list-stts",
  PERFORMANCE_DELETE: "/prfmnc/list-del",
  PRFMNC_DDLN_STATUS: "/prfmnc/ddln-stts",
};
const CODE = {
  PERFORMANCE_STATUS: "PFM001", // 실적상태코드
  MODIFICATION_PERMIT_STATUS: "PFM002", // 실적수정허용상태코드
};

export default {
  components: {
    SbGrid,
    PrfmncMdfcnPrmPopup,
    BplcInfoIdntyPopup,
    PrfmncNewRegIdntyPopup,
  },
  data() {
    return {
      searchParam: {
        prfmncYr: "",
        prfmncMm: "",
        bplcId: "",
        bplcKndCds: [],
        prfmncSttsCd: "",
      },
      prfmncDdln: false,
      prfmncYrList: [],
      prfmncMmList: [],
      bplcList: [],
      bplcKndList: [],
      prfmncSttsList: [],
      prfmncMdfcnPrmSttsList: [],
      gridReload: false,
      prfmncGridObject: null,
      dataSource: {
        ajaxConfig: {
          select: { url: API.PERFORMANCE_LIST },
          paging: true,
        },
        schema: {
          id: 'prfmncId'
        }
      },
      gridColumns: [],
      gridAttr: {
        checkable: true,
        pageable: true,
        captionHeight: 40,
        excelExport: {
          fileName: "실적보고.xlsx",
          adjustCaption: (value) => {
            return value?.toString().includes('<br>') ? value.replace(/<br\s*\/?>/gi, '\n') : value;
          },
        },
      },
    };
  },
  computed: {
    filteredBplcList() {
      if (!this.searchParam.bplcKndCds || this.searchParam.bplcKndCds.length === 0) {
        return this.bplcList;
      }

      return this.bplcList.filter(item => this.searchParam.bplcKndCds.includes(item.bplcKndCd));
    },
  },
  watch: {
    filteredBplcList() {
      this.searchParam.bplcId = "";
    }
  },
  created() {
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.initYearMonthOptions();
      this.loadPrfmncSttsCd();
      this.loadPrfmncMdfcnPrmSttsCd();
      await this.loadMemberBplc();
      this.applyStoredSearchParam();
      this.initializePrfmncGridColumns();
      this.initializeBzentyGridAttr();
      this.gridReload = true;
    },
    initYearMonthOptions() {
      const today = new Date();
      today.setMonth(today.getMonth() - 1); // 현재 날짜에서 -1개월로 설정
      const currentYear = today.getFullYear();

      this.prfmncYrList = Array.from({ length: currentYear - 2012 }, (_, i) => ({
        text: `${currentYear - i}년`,
        value: (currentYear - i).toString(),
      }));

      this.prfmncMmList = Array.from({ length: 12 }, (_, i) => ({
        text: `${i + 1}월`,
        value: (i + 1).toString().padStart(2, '0'),
      }));
    },
    loadPrfmncSttsCd() {
      const prfmncSttsList = this.$store.getters.getCodeList({ groupCd: CODE.PERFORMANCE_STATUS, useYn: 'Y' });
      this.prfmncSttsList = prfmncSttsList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    loadPrfmncMdfcnPrmSttsCd() {
      const prfmncMdfcnPrmSttsList = this.$store.getters.getCodeList({ groupCd: CODE.MODIFICATION_PERMIT_STATUS, useYn: 'Y' });
      this.prfmncMdfcnPrmSttsList = prfmncMdfcnPrmSttsList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    loadMemberBplc() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.MEMBER_BPLC, {}, data => {
          if (data.result) {
            this.bplcList = data.result.bplcList.map(item => ({
              text: item.bplcNm,
              value: item.bplcId,
              bplcKndCd: item.bplcKndCd,
            }));
            this.bplcKndList = data.result.bplcKndList.map(item => ({
              text: item.bplcKndNm,
              value: item.bplcKndCd,
            }));

            // if (this.bplcKndList.length > 0) {
            //   this.searchParam.bplcKndCds = this.bplcKndList.map(item => item.value);
            // }

            resolve();
          } else {
            reject(new Error("Failed to load member bplc."));
          }
        });
      });
    },
    applyStoredSearchParam() {
      const storeParam = storeComponent.get("prfmncRpt");

      if (storeParam) {
        this.searchParam = { ...this.searchParam, ...storeParam };
        storeComponent.clearStorageData("prfmncRpt"); // 저장된 searchParam 제거
      }
    },
    initializePrfmncGridColumns() {
      this.gridColumns = [
        { field: 'prfmncId', caption: '실적아이디', visible: false },
        { field: 'prfmncYr', caption: '실적연도', width: 60, part: 'sticky' },
        { field: 'prfmncMm', caption: '실적월', width: 50, part: 'sticky' },
        { field: 'bplcKndCd',
          caption: '사업장종류',
          type: 'combo',
          items: this.bplcKndList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 120,
          exportLabel : true,
        },
        { field: 'bplcNm', caption: '사업장명', minWidth:180, width: 100, unit:'%', align: 'left', part: 'sticky' },
        { field: 'prfmncSttsCd',
          caption: '실적상태',
          type: 'combo',
          items: this.prfmncSttsList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 90,
          exportLabel : true,
        },
        { field: 'prfmncMdfcnPrmSttsCd',
          caption: '수정허용상태',
          type: 'combo',
          items: this.prfmncMdfcnPrmSttsList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 90,
          exportLabel : true,
        },
        { field: 'sumCryqty', caption: '총 반입량<br>(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 100, },
        { field: 'sumPrdqty', caption: '총 생산량<br>(톤/Nm³/Gcal)', dataType: 'number', align: 'right', format: '#,##0.00', width: 100 },
        { field: 'sumUseNsqty', caption: '사용&판매량<br>(톤/Nm³/Gcal)', dataType: 'number', align: 'right', format: '#,##0.00', width: 100 },
        { field: 'sbmsnYmd', caption: '제출일자', width: 100,
          format: (date) => {
            if (date && date.length === 8) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              const dd = date.substring(6, 8);
              return `${yyyy}-${mm}-${dd}`;
            }
            return date;
          },
        },
        { field: 'aprvYmd', caption: '승인일자', width: 100,
          format: (date) => {
            if (date && date.length === 8) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              const dd = date.substring(6, 8);
              return `${yyyy}-${mm}-${dd}`;
            }
            return date;
          },
        },
        { field: 'button', caption: '상세', type: 'button', width: 70, part: 'sticky',
          command: (container, rowItem) => {
            const button = document.createElement('button');
            button.innerText = '상세'
            button.style = 'background:#616161; color:white;'
            button.onclick = () => {
              this.toDetail(rowItem.data);
            }
            container.append(button)
          },
          exportVisible: false
        },
      ]
    },
    initializeBzentyGridAttr() {
      this.gridAttr.rowCss = (data) => {
        const keys = window.SBGrid3.getCheckedKeys(this.prfmncGridObject);

        if (keys.includes(data.prfmncId)) {
          return 'grid-good';
        }

        return '';
      }
    },
    gridLoaded() {
      this.gridReload = false;
    },
    reset() {
      this.searchParam.prfmncYr = "";
      this.searchParam.prfmncMm = "";
      this.searchParam.bplcKndCds = [];
      this.searchParam.bplcId = "";
      this.searchParam.prfmncSttsCd = "";
      this.search();
    },
    search() {
      window.SBGrid3.reload(this.prfmncGridObject);
    },
    toDetail(data) {
      const bplcKndCd = data.bplcKndCd;

      let path = "";

      switch (bplcKndCd) {
        case "01-01": // 고형연료제조
          path = "/prfmnc-rpt/dtl/SdflMnfrt";
          break;
        case "01-02": // 고형연료사용
          path = "/prfmnc-rpt/dtl/SdflUse";
          break;
        case "01-03": // 고형연료수입
          path = "/prfmnc-rpt/dtl/SdflIncm";
          break;
        case "02-01": // 매립가스
          path = "/prfmnc-rpt/dtl/FillGas";
          break;
        case "03-01": // 소각
        case "03-02": // 소각열회수
          path = "/prfmnc-rpt/dtl/FireRtrvl";
          break;
        case "04-01": // 바이오가스
          path = "/prfmnc-rpt/dtl/BioGas";
          break;
        case "05-01": // 폐기물합성가스
          path = "/prfmnc-rpt/dtl/SynthGas";
          break;
        default :
          path = "";
      }

      if (path) {
        storeComponent.set("prfmncRpt", this.searchParam);

        this.$router.push({path : path, query : {prfmncId : data.prfmncId}}).catch(() => {
          console.error("Navigation Error");
        });
      }
    },
    retrieval() {
      const checkedPrfmncItem = window.SBGrid3.getCheckedRows(this.prfmncGridObject);

      if (checkedPrfmncItem.length === 0) {
        alert("선택된 실적이 없습니다.");
        return;
      }

      const subItems = checkedPrfmncItem.filter(item => item.originData.prfmncSttsCd === "SUB");
      const nonSubItems = checkedPrfmncItem.filter(item => item.originData.prfmncSttsCd !== "SUB");

      const recoverablePrfmncIds = subItems.map(item => item.originData.prfmncId);
      const recoverableItemsCount = subItems.length;
      const totalItems = checkedPrfmncItem.length;

      if (recoverableItemsCount === 0) {
        alert("회수 가능한 실적이 없습니다. (상태가 '제출'인 실적만 회수 가능합니다.)");
        return;
      }

      let confirmMessage;
      if (nonSubItems.length > 0) {
        confirmMessage = `${totalItems}개 중 ${recoverableItemsCount}개의 실적만 회수 가능합니다.\r\n회수 가능한 ${recoverableItemsCount}개를 회수하시겠습니까?`;
      } else {
        confirmMessage = `선택된 ${recoverableItemsCount}개의 실적을 회수하시겠습니까?`;
      }

      if (confirm(confirmMessage)) {
        this.$apiCall.put(
            API.PERFORMANCE_LIST_STATUS,
            {
              prfmncIdList: recoverablePrfmncIds,
              prfmncSttsCd: "IPG"
            },
            () => {
              alert("회수되었습니다.");
              window.SBGrid3.reload(this.prfmncGridObject);
            },
            (error) => {
              alert(error.message);
            }
        );
      }
    },
    showMdfcnPrmPopup() {

      const checkedPrfmncItem = window.SBGrid3.getCheckedRows(this.prfmncGridObject);
      if (checkedPrfmncItem.length === 0) {
        alert("선택된 실적이 없습니다.");
        return;
      }

      const hasPrfmncSttsCd = checkedPrfmncItem.some(
        item => item.data.prfmncSttsCd !== 'REC' && item.data.prfmncSttsCd !== 'APR'
      );
      if (hasPrfmncSttsCd) {
        alert("수정허용요청은 실적 상태가 접수, 승인 상태만 요청할 수 없습니다.");
        return;
      }

      const hasPrfmncMdfcnPrmSttsCd = checkedPrfmncItem.some(
        item => item.data.prfmncMdfcnPrmSttsCd !== undefined &&
                item.data.prfmncMdfcnPrmSttsCd !== null &&
                item.data.prfmncMdfcnPrmSttsCd !== ''
      );
      if (hasPrfmncMdfcnPrmSttsCd) {
        alert("수정허용요청이 진행중인 실적은 요청할 수 없습니다.");
        return;
      }

      const uniqueBplcIds = [...new Set(checkedPrfmncItem.map(item => item.data.bplcId))];
      if (uniqueBplcIds.length !== 1) {
        alert("수정허용요청은 단일 사업장만 선택해야 합니다.");
        return;
      }

      const prfmncIds = checkedPrfmncItem.map(item => (item.data.prfmncId));
      const checkedItems = checkedPrfmncItem.map(item => ({
        prfmncYr: item.data.prfmncYr,
        prfmncMm: item.data.prfmncMm,
        bplcKndCd: item.data.bplcKndCd,
      })).sort((a, b) => {
        if (a.prfmncYr === b.prfmncYr) {
            return a.prfmncMm - b.prfmncMm; // prfmncYr이 같으면 prfmncMm으로 정렬
        }
        return a.prfmncYr - b.prfmncYr; // prfmncYr 기준 정렬
      });

      const param = {
        bplcId: uniqueBplcIds[0],
        items : checkedItems,
        prfmncIds : prfmncIds,
      };

      Promise.all(checkedItems.map(item => {
        return new Promise((resolve, reject) => {
          this.$apiCall.get(
              API.PRFMNC_DDLN_STATUS,
              { prfmncYr: item.prfmncYr, bplcKndCd: item.bplcKndCd },
              data => resolve(data),
              error => reject(error)
          );
        });
      })).then(results => {
        // 응답 결과 중 하나라도 'N'이면 처리 중단
        if (results.some(data => data.result === 'Y')) {
          alert("실적마감된 연도는 수정허용요청이 불가능합니다.");
          return;
        }
        // 모든 결과가 조건에 부합하면 팝업 실행
        this.prfmncDdln = true;
        if (this.prfmncDdln) {
          storeSwitch.on('PrfmncMdfcnPrmPopup', param);
        }
      }).catch(error => {
        console.error(error);
      });

      // if (checkedPrfmncItem.length > 1) {
      //   alert("수정허용요청은 한번에 1건만 가능합니다.");
      //   return;
      // }
      //
      // const prfmncItem = checkedPrfmncItem[0].originData;
      // const prfmncSttsCd = prfmncItem.prfmncSttsCd;
      // const prfmncMdfcnPrmSttsCd = prfmncItem.prfmncMdfcnPrmSttsCd;
      //
      // if (prfmncSttsCd !== "APR" && prfmncSttsCd !== "REC") {
      //   alert("접수/승인 상태의 실적만 수정허용요청이 가능합니다.");
      //   return;
      // }
      //
      // if (prfmncMdfcnPrmSttsCd) {
      //   alert("이미 수정허용요청이 존재합니다.");
      //   return;
      // }
      //
      // const param = {
      //   bplcId: prfmncItem.bplcId,
      //   prfmncYr: prfmncItem.prfmncYr,
      //   prfmncMm: prfmncItem.prfmncMm,
      //   prfmncId: prfmncItem.prfmncId
      // };
      //

      //storeSwitch.on('PrfmncMdfcnPrmPopup', param);
    },
    showNewRegPopup() {
      storeSwitch.on('PrfmncNewRegIdntyPopup');
    },
    showBplcInfoPopup(data) {
      storeSwitch.on('BplcInfoIdntyPopup', data);
    },
    //2025.02.06 실적삭제 기능 추가
    deletePrfmncRpt() {
      const checkedPrfmncItem = window.SBGrid3.getCheckedRows(this.prfmncGridObject);

      if (checkedPrfmncItem.length === 0) {
        alert("선택된 실적이 없습니다.");
        return;
      }

      const ipgItems = checkedPrfmncItem.filter(item => item.originData.prfmncSttsCd === "IPG");
      const nonIpgItems = checkedPrfmncItem.filter(item => item.originData.prfmncSttsCd !== "IPG");

      // const deletePrfmncIds = ipgItems.map(item => item.originData.prfmncId);
      const deletePrfmncIds = ipgItems.map(item => ({prfmncId: item.originData.prfmncId, bplcKndCd: item.originData.bplcKndCd}));
      const ipgItemsCount = ipgItems.length;
      const nonIpgItemsCount = nonIpgItems.length;

      // 
      // const recoverableItemsCount = subItems.length;
      // const totalItems = checkedPrfmncItem.length;

      if(ipgItemsCount === 0){
        alert(`실적상태가 "작성중"인 실적을 선택하여 주십시오.`);
        return;
      }else if(ipgItemsCount > 1){
        alert(`실적은 1건씩 삭제 가능합니다.`);
        return;
      }

      if (nonIpgItemsCount > 0) {
        alert(`삭제가 불가능한 실적이 ${nonIpgItemsCount}개 존재합니다.`);
        return;
      }

      // console.log(deletePrfmncIds);

      const confirmMessage = `선택한 실적을 삭제하시겠습니까?`;

      if (confirm(confirmMessage)) {
        this.$apiCall.delete(
            API.PERFORMANCE_DELETE,
            {
              prfmncRpfList: deletePrfmncIds,
            },
            () => {
              alert("삭제가 완료 되었습니다.");
              window.SBGrid3.reload(this.prfmncGridObject);
            },
            (error) => {
              alert(error.message);
            }
        );
      }
    },
  }
}
</script>

<style scoped>

</style>