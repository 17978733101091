<template>
  <div class="sch_form_wrap">
    <div class="sch_form row2">
      <span>
        <label>구분</label>
        <br-form-select label="구분" v-model:value="selected" :options="options" @change="(data) => this.type=data.value"
                        includeSelectOption/>
        <br-form-input label="검색어" v-model="keyword" @enterKeyup="searchBtn"/>
      </span>
      <span>
      </span>
    </div>
    <div class="btn_area">
      <br-anchor-button label="검색" role="button" size="sm" format="sch" @click="searchBtn" />
      <br-anchor-button label="새로고침" role="button" size="sm" format="txtN" icon="ico_refresh" @click="reset" />
    </div>
  </div>
  <div class="board_info">
    <p class="page">
      <span class="total">전체 <strong>{{ this.count }}</strong></span>
      <span>페이지 <strong>{{ this.searchParam.page }}/{{ this.maxPage }}</strong></span>
    </p>
  </div>
  <div class="board_list">
    <ul class="accordion">
      <template v-for="(item, index) in listObj" :key="index">
      <li v-show="index==activeIndex" class="acco_item active">
        <a role="link" href="javascript:void(0);" onclick="return false;" class="btn_acco active" @click="toggleAccordion(index)">
          <span class="num">Q. {{item.rowNum}}</span>
          <span class="title"><strong class="strong" v-html="item.bbsPstQstnCn"></strong></span>
          <span class="date">{{ item.regDt }}</span>
        </a>
        <div class="acco_body">
          <span class="reply">답변</span>
          <div class="cont" style="" v-html="item.bbsPstQstnAnsCn"></div>
          <br-attach-file v-if="item.atchFileGroupId!=null"
              label="첨부파일"
              :files="item.files"
              :readonly="true"
          />
        </div>
      </li>
      <li v-show="index!=activeIndex" class="acco_item">
        <a role="link" href="javascript:void(0);" onclick="return false;" class="btn_acco" @click="toggleAccordion(index)">
          <span class="num">Q. {{item.rowNum}}</span>
          <span class="title"><strong class="strong1" v-html="item.bbsPstQstnCn"
                      style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"></strong></span>
          <span class="date">{{ item.regDt }}</span>
        </a>
      </li>
      </template>
    </ul>
  </div>
  <insp-pagination :total="count" :size="searchParam.size" v-model:page="searchParam.page" v-model:maxPage="maxPage" @clickedPage="search"> </insp-pagination>
</template>

<script>

import inspPagination from "@/views/sfli/components/inspPagination.vue";
import {storeSession} from "@/js/store/store-session";

const API = {
  FAQ: "/faq/",
};

const PAGE_SIZE = 10;

export default {
  components: {inspPagination},
  data() {
    return {
      options: [
          // {text: "제목", value: "1"},
          {text: "내용", value: "2"},
          // {text: "작성자", value: "3"},
          // {text: "제목 + 내용", value: "4"}
      ],
      selected: "",
      keyword: "",
      type: "",
      test: 0,
      searchParam: {
        qstnTtl: "",
        bbsPstCn: "",
        rgtrNm: "",
        bbsSn: "",
        cn: "",
        page: 1
      },
      fileProcessingData: {},
      fileCd: "FAQ",
      activeIndex: null,
      userinfo: storeSession.getMemberInfo(),
      count: 0,
      maxPage: 1,
      files: [],
      listObj: [
      ],
    };
  },
  computed: {
  },
  watch: {
    activeIndex() {
      this.$forceUpdate();
    }
  },
  created() {
  },
  beforeMount() {
    this.searchParam.bbsSn = 32
  },
  mounted() {
    this.search();
  },
  methods: {
    reset() {
      this.keyword = ""
      this.selected = ""
      // this.search();
    },
    searchBtn(){
      this.searchParam.page = 1;
      this.search();
    },
    search(){
      this.activeIndex = null;
      this.searchParam.size = PAGE_SIZE;
      this.chaged();
      this.$apiCall.get(API.FAQ, this.searchParam, this.success, this.error);
    },
    success(data){
      if(!data.result){
        return;
      }
      this.listObj = data.result.list;
      this.count = data.result.total;
      this.files = this.listObj[0].files;
      this.$forceUpdate();
    },
    error() {
      alert("데이터를 가져오는 중 오류가 발생했습니다.");
    },
    toggleAccordion(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
    chaged(){
      this.searchParam.qstnTtl = ""
      this.searchParam.cn = ""
      this.searchParam.rgtrNm = ""
      if(this.type==""){
        this.keyword = ""
      }else if(this.type==1){
        this.searchParam.qstnTtl = this.keyword
      }else if(this.type==2){
        this.searchParam.cn = this.keyword
      }else if(this.type==3){
        this.searchParam.rgtrNm = this.keyword
      }else if(this.type==4){
        this.searchParam.qstnTtl = this.keyword
        this.searchParam.cn = this.keyword
      }
    },
    toDetail(data){
      this.$router.push({path : `/cust-sprt/FaqDetail`, query : {pstSn : data.pstSn}})
        .catch(() => {
          console.error("Navigation Error");
        });
    }
  }
}
</script>

<style scoped>
.strong1 * {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
