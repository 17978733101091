<template>
  <!-- 업무설명 영역 -->
  <div class="desc_box">
    <i class="icon"><img src="@/assets/images/common/ico_desc.png" alt="이미지"></i>
    <div class="desc">
      <strong class="desc_title">수입품질검사 현황</strong>
      <ul class="dot_list">
        <li>수입품질검사 신청 목록이 조회 됩니다.</li>
        <li>검사 완료 시에는 검사결과를 조회 할 수 있습니다.</li>
        <li>신규로 검사를 신청하는 경우에는 "수입품질검사 신청" 메뉴 클릭하여 신청해 주시기 바랍니다.</li>
        <li>검사진행상태가 서류보완의 경우 사유를 확인하시고 수입품질검사 현황에서 자료를 보충하신 후 검사신청 해주시기 바랍니다.</li>
      </ul>
      <!-- <div class="btn_area left">
        <a role="link" href="#modalQltyInspAplyGuide" class="btn xsm helper ico_helper modal_open" @click.prevent="openModal('#modalQltyInspAplyGuide')">품질검사안내</a>
      </div> -->
    </div>
  </div>
  <!-- 화면 타이틀 영역 -->
  <div class="board_info">
    <h2 class="title1">수입품질검사 현황</h2>
  </div>
  <!-- 조회조건 영역 -->
  <div class="sch_form_wrap">
    <div class="sch_form row2">
      <span>
          <label>신청일자</label>
          <div class="input_group">
            <br-date-from-to label="신청일자" v-model:fromDate="searchParam.fromDate" v-model:toDate="searchParam.toDate" @change="search" />
          </div>
      </span>
      <span>
        <label for="srchMnftrNtnCd">제조국</label>
        <br-form-select id="srchMnftrNtnCd" label="제조국" v-model:value="searchParam.mnftrNtnCd" groupCd="COM415" @change="search" allSelectable />
      </span>
      <span>
        <label for="">도시</label>
        <br-form-input label="도시" v-model="searchParam.ctyNm" @enter-keyup="search" />
      </span>
      <span>
        <label for="">제조업체(영문)</label>
        <br-form-input label="제조업체(영문)" v-model="searchParam.bzentyNm" @enter-keyup="search" class="w45p"/>
      </span>
    </div>
    <div class="btn_area">
      <br-anchor-button role="link" label="검색" size="sm" format="sch" @click="search" />
      <br-anchor-button role="link" label="새로고침" size="sm" format="txtN" icon="ico_refresh" @click="reset" />
    </div>
  </div>
  <!-- 그리드 소계 및 버튼 영역 -->
  <div class="board_info">
    <p class="page">
      <span class="total">전체 <strong>{{ this.qltyInspDataCount }}</strong></span>
      <span>페이지 <strong>{{ this.searchParam.page }}/{{ this.lastPage === 0 ? 1 : this.lastPage }}</strong></span>
    </p>
    <div class="btn_area">
      <br-anchor-button role="link" label="수입신고서 양식 출력" size="sm" format="" icon="ico_print" @click="printIncmReporting"/>
      <br-anchor-button role="link" label="엑셀다운로드" size="sm" icon="ico_excel" @click="excelDownload"/>
    </div>
  </div>
  <!-- 그리드 컨텐츠 영역 -->
  <div class="board_list scroll">
    <table>
      <caption>수입검사 현황 조회-검사신청번호, 업체명, 제조국, 도시, 제조업체명, 신청일자, 접수일자, 검사(예정)일자, 검사연장신청, 연기/반려사유, 처리기한연장, 진행상태, 검사결과로 구성
      </caption>
      <colgroup>
        <col style="width:60px">
        <col style="width:120px">
        <col style="width:100px">
        <col style="width:130px">
        <col style="width:130px">
        <col style="width:160px">
        <col style="width:160px">
        <col style="width:260px">
        <col style="width:150px">
        <col style="width:150px">
        <col style="width:150px">
        <col style="width:120px">
        <!-- <col style="width:120px"> -->
        <col style="width:120px">
      </colgroup>
      <thead>
      <tr>
        <th scope="col">선택</th>
        <th scope="col">검사신청번호</th>
        <th scope="col">검사결과</th>
        <th scope="col">진행상태</th>
        <th scope="col">업체 명</th>
        <th scope="col">제조국</th>
        <th scope="col">도시</th>
        <th scope="col">제조업체 명</th>
        <th scope="col">신청일자</th>
        <th scope="col">접수일자</th>
        <th scope="col">검사(예정)일자</th>
        <th scope="col">검사연장신청</th>
        <!-- <th scope="col">연기/반려사유</th> -->
        <th scope="col">처리기한연장</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="qltyInspDataList.length <= 0" >
        <td colspan="14">
          <div style="display: flex; justify-content: center;">
            <div class="no-data-message">조회조건에 해당하는 데이터가 없습니다.</div>
          </div>
        </td>
      </tr>
      <tr v-else v-for="(item, index) in qltyInspDataList" :key="index" @dblclick="goDetail(item.inspAplySn, item.bplcId)">
        <td aria-label="선택" @click.prevent="item.chk = (item.chk === 'Y')? 'N' : 'Y'">
          <input type="checkbox" id="chk1" v-model="item.chk" true-value="Y" false-value="N" @click.stop aria-label="선택">
        </td>
        <td aria-label="검사신청번호">{{ item.inspAplySn }}</td>
        <td aria-label="검사결과"><span v-if="item.inspRsltNm" class="badge" :class="{ bg2: item.inspRsltCd === '20' }">{{ item.inspRsltNm }}</span></td>
        <td aria-label="진행상태"><span v-if="item.inspPrgrsSttsNm" class="badge" :class="{ bg1: item.inspPrgrsSttsCd === '70', bg3: this.isBg3BadgeClass(item.inspPrgrsSttsCd) }">{{ item.inspPrgrsSttsNm }}</span></td>
        <td aria-label="업체 명" style="text-align: left">{{ item.bzentyNm }}</td>
        <td aria-label="제조국" style="text-align: left">{{ item.mnftrNtnNm }}</td>
        <td aria-label="도시" style="text-align: left">{{ item.ctyNm }}</td>
        <td aria-label="제조업체 명" style="text-align: left">{{ item.mnftrBzentyNm }}</td>
        <td aria-label="신청일자">{{ item.inspAplyYmd }}</td>
        <td aria-label="접수일자">{{ item.inspRcptYmd }}</td>
        <td aria-label="검사(예정)일자">{{ item.inspPrnmntYmd }}</td>
        <td aria-label="검사연장신청">
          <!-- <br-button v-if="item.canApply === 'Y'" label="신청" size="sm" format="secondary" @click="applyExtension" /> -->
          <div v-if="item.inspSpnsSttsNm" 
                class="btn xsm secondary" 
                @click.stop="openModalInspSpnsAply(item.inspAplySn, item.inspSpnsPrlgSqno)"
          >
            {{ item.inspSpnsSttsNm }}
          </div>
        </td>
        <!-- <td aria-label="연기/반려사유"><br-button v-if="item.chgPrcsYmd" label="상세" size="sm" @click="showExtendedReason" /></td> -->
        <td aria-label="처리기한연장">{{ item.chgPrcsYmd }}</td>
      </tr>
      </tbody>
    </table>
  </div>
  <!-- 그리드 페이징 영역 -->
  <insp-pagination :total="qltyInspDataCount" :size="searchParam.size" v-model:page="searchParam.page" v-model:maxPage="lastPage" @clickedPage="search"></insp-pagination>
  <!-- modal popup 품질검사안내 -->
  <div :class="['modal', { visible: isModalQltyInspAplyGuideVisible }]" id="modalQltyInspAplyGuide">
    <div class="popup_box" tabindex="0">
      <h1 class="popup_title">품질검사 신청 안내</h1>
      <div class="popup_cont">
        <div class="desc_box progress_box">
          <i class="icon"><img src="@/assets/images/common/ico_noti.png" alt="이미지"></i>
          <div class="progress">
            <div><strong>품질검사</strong>는 폐자원으로 생산한 고형연료의 품질 여부를 검사합니다. <span>아래 절차대로 진행됩니다.</span></div>
          </div>
        </div>
        <h2 class="title1">최초 검사</h2>
        <div class="img_box_area mt_10">
          <img src="@/assets/images/sub/img_info_pop1-1.png" alt="품질 검사 절차">
        </div>

        <h2 class="title1">확인 검사<span class="info_title">정기 검사 시 해당 분기 확인 검사 시료채취 및 분석 결과 활용</span></h2>
        <div class="img_box_area mt_10">
          <img src="@/assets/images/sub/img_info_pop1-2.png" alt="1. 제조자/사용자 고형 연료 제품, 2. 시료 채취 및 계획 수립 검사 예정일 3일 전까지 통보, 3. 시료 채취 및 분석 고형 연료 제품, 품질 실험/분석 방법, 4. 품질 검사 결과서 발급, 5. 지방 자치 단체 통보(부적합 시 통보)">
        </div>
        <div class="btn_area center">
          <button type="button" class="btn md primary" @click="navigateTo('/wstrsc-ene-intro/sfl/QltyInspGd')">자세히 보기</button>
          <button type="button" class="btn md" @click="closeModal">닫기</button>
        </div>
      </div>
      <button type="button" class="modal_close" @click="closeModal">팝업 닫기</button>
    </div>
  </div>

  <!-- s: modal popup 검사연기 -->
  <div :class="['modal', { visible: isModalInspSpnsVisible }]" id="modalInspSpns"> 
    <div class="popup_box" tabindex="0">
      <h1 class="popup_title">검사연기</h1>
      <div class="popup_cont">
          <div class="desc_box progress_box">
              <i class="icon"><img src="@/assets/images/common/ico_noti.png" alt="이미지"></i>
              <div class="progress">
                  <div><strong>검사연기 신청기준 : 검사일 3일 전까지 요청한 경우 (공휴일 제외)</strong></div>
              </div>
          </div>
          <div class="board_write">
              <table>
                  <caption>검사연기신청_변경일자, 증빙서류, 신청사유로 구성</caption>
                  <colgroup>
                      <col style="width:15%">
                      <col style="width:85%">
                  </colgroup>
                  <tbody>
                      <tr>
                          <th scope="row"><i v-if="inspSpnsObj.pageType === 'new' || inspSpnsObj.inspSpnsSttsCd === '03'" class="necessary">필수입력</i>변경일자</th>
                          <td>
                            <span v-if="inspSpnsObj.pageType === 'new' || inspSpnsObj.inspSpnsSttsCd === '03'">
                              <br-date-picker label="변경일자" title="변경일자" v-model:value="inspSpnsObj.chgYmd" format="YYYYMMDD" panel="panel" required />
                            </span>
                            <span v-else>
                              {{inspSpnsObj.chgYmd}} 
                            </span>
                          </td>
                      </tr>
                      <tr v-if="inspSpnsObj.pageType === 'new' || inspSpnsObj.inspSpnsSttsCd === '03'">
                        <th scope="row">증빙서류</th>
                        <td>
                          <div class="file_upload_list" :class="{'file-upload-visible': isFileComponentVisible}">
                            <li v-if="isModalInspSpnsVisible">
                              <br-attach-file-list label="정기검사 연기 첨부파일 목록"
                                          ref="extAttachFileList"
                                          atchFileTaskSeCd="EXT" 
                                          v-model:atchFileGroupId="this.inspSpnsObj.inspAplyStatAtchFileGroupId"
                                          v-model:fileProcessingData="this.inspSpnsObj.fileProcessingData"
                                          :allowedExtensions="fileAllowedExtensions" 
                                          :textAreaWidth="fileTextAreaWidth"
                                          :readonly="false" 
                                          :useEtcFile="false" 
                                          panel="panel"
                                          required
                              /> 
                            </li> 
                          </div>
                        </td>
                      </tr>

                      <tr v-else>
                        <th scope="row">증빙서류</th>
                        <td>
                          <div class="file_upload_list" :class="{'file-upload-visible': isFileComponentVisible}">
                            <li>
                              <br-attach-file-list label="정기검사 연기 첨부파일 목록"
                                          ref="extAttachFileList"
                                          atchFileTaskSeCd="EXT" 
                                          v-model:atchFileGroupId="this.inspSpnsObj.inspAplyStatAtchFileGroupId"
                                          v-model:fileProcessingData="this.inspSpnsObj.fileProcessingData"
                                          :allowedExtensions="fileAllowedExtensions" 
                                          :textAreaWidth="fileTextAreaWidth"
                                          :readonly="true" 
                                          :useEtcFile="false" 
                              /> 
                            </li> 
                          </div>
                        </td>
                      </tr>

                      <tr>
                          <th scope="row"><i v-if="inspSpnsObj.pageType === 'new' || inspSpnsObj.inspSpnsSttsCd === '03'" class="necessary">필수입력</i>신청사유</th>
                          <td style="padding-top: 30px; padding-bottom: 30px;">
                            <span v-if="inspSpnsObj.pageType === 'new' || inspSpnsObj.inspSpnsSttsCd === '03'">
                              <br-form-input label="신청사유" title="신청사유" v-model="inspSpnsObj.inspSpnsAplyRsn" panel="panel" required/>
                            </span>
                            <span v-else>
                              {{inspSpnsObj.inspSpnsAplyRsn}}
                            </span>
                          </td>
                      </tr>
                  </tbody>
              </table>

              <table v-if="inspSpnsObj.inspSpnsSttsCd === '03'">
                <caption>검사연기신청_변경일자, 증빙서류, 신청사유로 구성</caption>
                  <colgroup>
                      <col style="width:15%">
                      <col style="width:35%">
                      <col style="width:15%">
                      <col style="width:35%">
                  </colgroup>
                  <tbody>
                    <tr>
                        <th scope="row">반려사유</th>
                        <td colspan="3" style="padding-top: 30px; padding-bottom: 30px;">
                          <span>{{inspSpnsObj.chgRsn}}</span>
                        </td>
                    </tr>
                    <tr>
                      <th scope="row">처리일자</th>
                      <td>{{inspSpnsObj.chgPrcsYmd}}</td>
                      <th scope="row">처리자</th>
                      <td>{{inspSpnsObj.userNm}}</td>
                    </tr>
                  </tbody>
              </table>
          </div>

          <div class="btn_area center">
              <br-button v-if="inspSpnsObj.pageType === 'new' || inspSpnsObj.aplyPsblYn === 'Y'" 
                        label="신청" title="신청" class="btn md primary" panel="panel" validate @click="createQltyInspSpnsAply()"/>
              <br-button label="닫기" title="닫기" class="btn md" @click="closeModal" />
          </div>
      </div>
      <button type="button" class="modal_close" @click="closeModal">팝업 닫기</button>
    </div>
  </div>
  <!-- e: modal popup 검사연기 --> 

  <!--리포트-->
  <component  v-if="reportParamObj.selectedPopupComponent" 
            :is="reportParamObj.selectedPopupComponent" 
            :rd-param-file-name="reportParamObj.rdParamFileName" 
            :rd-param-key="reportParamObj.rdParamKey" 
            :rd-param-value="reportParamObj.rdParamValue" 
  />
</template>

<script>
/**
 * 수입품질검사에 대한 현황 조회 및 검사신청 처리 화면
 *
 * 작성자 : jhkim
 * 작성일 : 11/06/2024
 * 버전 : 1.0
 */
import {buildUri} from "@/js/utils/uri-builder";
import {storeSwitch} from "@/js/store/store-switch";
import moment from "moment";
import inspPagination from "@/views/sfli/components/inspPagination.vue";
import reportQltyInspPopup from "@/views/sfli/qlty-insp-stat/popup/ReportQltyInspPopup.vue";

const API = {
  INCM_QLTY_INSP_STAT : "/incm-qlty-insp-stat",
  INCM_QLTY_INSP_EXCEL_DOWN : "/incm-qlty-insp-stat/excel-download",
  INCM_QLTY_INSP_SPNS_DETAIL : "/fcltInsp/spnsRsct/{inspAplySn}/sqno/{inspSpnsPrlgSqno}",
}
const PAGE_SIZE = 10;

export default {
  components: {inspPagination, reportQltyInspPopup},
  computed : {},
  data() {
    return {
      searchParam: {
        fromDate: moment().startOf('year').format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
        bzentyNm: "",
        mnftrNtnCd: "",
        ctyNm: "",
        page: 1,
      },
      lastPage: 1,
      qltyInspDataCount: 0,
      qltyInspDataList: [],
      isModalQltyInspAplyGuideVisible: false,
      reportParamObj : {
        selectedPopupComponent: null, // 선택된 리포트/이폼 화면 구분 ID 
        rdParamKey: "",
        rdParamValue: "",
        rdParamFileName: "", 
      },
      inspAplySn: "",
      inspSpnsObj : {
        inspSpnsAplySn : "",
        chgBfrYmd : "",
        chgYmd : "", 
        inspSpnsAplyRsn : "", 
        inspAplyStatAtchFileGroupId : "", 
        inspSpnsPrlgSeCd : "",
        inspSpnsSttsCd : "",
        pageType : "",
        atchFileGroupId : "",
        aplyPsblYn : "",
        fileProcessingData: {}
      },
      isModalInspSpnsVisible: false, 
    };
  },
  watch : {},
  created() {},
  mounted() {
    this.initialize();
  },
  beforeMount (){},
  methods : {
    initialize() {
      this.search();
    },
    search() {
      this.searchParam.size = PAGE_SIZE;
      this.$apiCall.get(API.INCM_QLTY_INSP_STAT, this.searchParam, data => {
        if (data.result.list) {
          this.qltyInspDataCount = data.result.total;
          this.qltyInspDataList = data.result.list;
        } else {
          this.qltyInspDataList = [];
        }
      });
    },
    reset() {
      this.searchParam.fromDate = moment().startOf('year').format('YYYY-MM-DD');
      this.searchParam.toDate = moment().format('YYYY-MM-DD');
      this.searchParam.mnftrNtnCd = '';
      this.searchParam.ctyNm = '';
      this.searchParam.bzentyNm = '';    
    },
    isBg3BadgeClass(inspPrgrsSttsCd) {
      let result = false;
      switch (inspPrgrsSttsCd) {
          case '00':  // 작성중
          case '10':  // 신청
          case '30':  // 서류보완요청
          case '50':  // 반려
          case '80':  // 사전취소
          case '90':  // 검사취하
            result = true;
            break;
      }
      return result;
    },
    excelDownload() {
      this.$apiCall.download(API.INCM_QLTY_INSP_EXCEL_DOWN, {
        fileName : `수입품질검사현황(${this.searchParam.fromDate.replaceAll('-', '')}~${this.searchParam.toDate.replaceAll('-', '')}).xlsx`,
        ...this.searchParam,
      });
    },
    goDetail(inspAplySn, bplcId) {
      this.$router.push({path: '/sfli/incm-qlty-insp-stat/IncmQltyInspStatDetail', query: {inspAplySn: inspAplySn, bplcId: bplcId}}).catch(() => {
        console.error("Navigation Error");
      });
    },
    openModal(modalId) {
      if (modalId === '#modalQltyInspAplyGuide') {
        this.isModalQltyInspAplyGuideVisible = true;
      }
    },
    closeModal() {
      this.isModalQltyInspAplyGuideVisible = false;
      this.isModalInspSpnsVisible = false;
    },
    printIncmReporting() {
      const checkedItems = this.qltyInspDataList.filter(item => item.chk === 'Y').map(item => item.inspAplySn).join(',');
      
      if(checkedItems == "" || checkedItems.includes(",")){
        alert("목록의 신청서를 1건 선택하여 주십시오.");
        return;
      }
      this.inspAplySn = checkedItems;
      this.openReportForm('reportSrfIncmDclr');
    },
    // applyExtension() {
    //   alert('검사연기신청')
    // },
    // showExtendedReason() {
    //   alert('연기/반려사유 상세 팝업')
    // },
    openReportForm(rdFimeName) { // 리포트/이폼 화면 열기 

      this.reportParamObj.selectedPopupComponent = null; // 컴포넌트 리셋
      this.openLayerPopup(rdFimeName);

      /*
      * 재호출 후 팝업에서 노출되는 현상으로 재호출 
      */ 
      setTimeout(() => {
        this.openLayerPopup(rdFimeName);
      }, 50); 
      },
      openLayerPopup(rdFimeName) { // 레이어 팝업 기능 

      setTimeout(() => {
        this.reportParamObj.selectedPopupComponent = 'reportQltyInspPopup'; // 화면내 팝업 컴포넌트 

        this.reportParamObj.rdParamFileName = rdFimeName;                         // 리포트 mrd 파일명 
        this.reportParamObj.rdParamKey = 'inspAplySn';                            // 리포트 화면 Param Key 
        this.reportParamObj.rdParamValue = this.inspAplySn;  // 리포트 화면 Param Key에 대한 Value  

        storeSwitch.on(this.reportParamObj.selectedPopupComponent); 
      }, 0); // 0ms 딜레이로 컴포넌트 강제 리렌더링 (리포트 리렌더링 안 되는 케이스가 존재하여 추가)
    },
    openModalInspSpnsAply(pInspAplySn, pInspSpnsPrlgSqno) { 
      this.inspSpnsObj.inspSpnsAplySn = pInspAplySn;

      /**
       * 정기 검사연기 신청 Modal 
       */
      if (!pInspSpnsPrlgSqno) { // 신규 등록 
        this.isModalInspSpnsVisible = true;
        this.inspSpnsObj.chgBfrYmd = "";
        this.inspSpnsObj.chgYmd = "";
        this.inspSpnsObj.inspSpnsAplyRsn = "";
        this.inspSpnsObj.inspAplyStatAtchFileGroupId = "";
        this.inspSpnsObj.atchFileGroupId = "";
        this.inspSpnsObj.fileProcessingData = {};
        this.inspSpnsObj.inspSpnsPrlgSeCd = "01-00-00";
        this.inspSpnsObj.inspSpnsSttsCd = "01"; 
        this.inspSpnsObj.pageType = "new"; 

      } else { // 상세 조회 
        this.inspSpnsObj.pageType = "search"; 
        this.readInspSpnsDetail(pInspAplySn, pInspSpnsPrlgSqno);
      } 
    },
    createQltyInspSpnsAply() {
        if (!confirm("검사 연기 신청하시겠습니까?")){
            return; 
        }

        this.inspSpnsObj.inspSpnsPrlgSeCd = "01-00-00";
        this.inspSpnsObj.inspSpnsSttsCd = "01"; 

        this.$refs.extAttachFileList.save().then(atchFileGroupId => {  
          this.inspSpnsObj.atchFileGroupId = atchFileGroupId;

            this.$apiCall.post(
                API.FCLT_INSP_SPNS_APLY, 
                this.inspSpnsObj,
                () => {
                    alert("검사 연기 신청이 완료 되었습니다.");
                    this.search();
                    this.closeModal(); 
                },
                () => {
                    alert("저장에 실패하였습니다.");
                }
            );
        });
    }, 
    readInspSpnsDetail(pInspAplySn, pInspSpnsPrlgSqno) {
        // 정기검사 연기 신청 정보 조회 
        this.$apiCall.get(buildUri(API.INCM_QLTY_INSP_SPNS_DETAIL, {inspAplySn : pInspAplySn, inspSpnsPrlgSqno : pInspSpnsPrlgSqno}), {}, this.readInspSpnsDetailSuccess, this.error);
    },
    readInspSpnsDetailSuccess(data) {
      if (!data.result) {
        this.error();
        return;
      }

      this.inspSpnsObj = data.result;
      this.inspSpnsObj.inspSpnsAplySn = data.result.inspAplySn;
      this.inspSpnsObj.chgBfrYmd = data.result.chgYmd.replaceAll('-', '');
      this.isModalInspSpnsVisible = true;
    },
    error() {
      alert("데이터를 가져오는 중 오류가 발생했습니다.");
    },
  }
}
</script>

<style scoped>
.board_list.scroll > table { table-layout: fixed; width:100%;}

.no-data-message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  margin: 20px 0px;
  border-radius: 10px;
  background-color: #f7f7f7;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 350px;
}

.no-data-message::before { content:''; width:2rem; height:2rem; background:url(@/assets/images/common/icon_search.png) no-repeat; background-size: 2rem; margin-right: 8px;}

.board_list.scroll > table tbody tr:hover{ background:var(--bg-gray10)}
</style>