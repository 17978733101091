<template>
  <!-- s: 콘텐츠 영역 -->
  <div class="sch_form_wrap">
    <div class="sch_form row2">
      <span>
        <label>검색어</label>
        <br-form-input label="제목 및 내용" v-model="searchParam.contsNm" @enterKeyup="search"/>
      </span>
      <span>
        <label for="">구분</label>
        <div class="input_group">
        <br-form-select label="구분" groupCd="COM526" v-model:value="searchParam.contsSeCd" allSelectable @change="search"/>
      </div>
      </span>
    </div>
    <div class="btn_area">
      <br-anchor-button label="조회" role="button" size="sm" format="sch" @click="search"/>
      <br-anchor-button label="새로고침" role="button" size="sm" format="txtN" icon="ico_refresh" @click="reset"/>
    </div>
  </div>
  <div class="board_info">  
    <p class="page">
      <span class="total">전체 <strong>{{ this.totalCount }}</strong></span>
      <span>페이지 <strong>{{ this.searchParam.page }}/{{ this.lastPage === 0 ? 1 : this.lastPage }}</strong></span>
    </p>
  </div>
  <div class="board_list">
    <ul class="photo video">
      <li v-for="item in list" :key="item.contsSn" @click="goDetail(item.contsSn)"> <!-- 고유한 ID로 변경 -->
        <a href="javascript:void(0);" role="link">
          <span class="thumb">
            <img :src="getThmbImg(item.thmbImgFiles)" alt="컨텐츠 이미지"
                 style="max-width: 100%; max-height: 300px; object-fit: contain;"/>
          </span>
          <span class="title">
            <strong>{{ item.contsNm }}</strong>
          </span>
          <span class="info">
            <span class="date">{{ item.regDt }}</span>
            <span class="view">{{ item.inqCnt }}</span>
          </span>
        </a>
      </li>
    </ul>
  </div>
  <insp-pagination :total="totalCount" :size="searchParam.size" v-model:page="searchParam.page"
                   v-model:maxPage="lastPage" @clickedPage="search"></insp-pagination>
</template>

<script>
import inspPagination from "@/views/sfli/components/inspPagination.vue";
import {buildUri} from "@/js/utils/uri-builder";

const API = {
  CONTENTS: "/contents",
  DOWNLOAD: "/api/file/download/{atchFileVldNo}"
};

const PAGE_SIZE = 6;

export default {
  components: {inspPagination},
  data() {
    return {
      searchParam: {
        contsSeCd: "",
        contsNm: "",
        page: 1
      },
      lastPage: 1,
      totalCount: 0,
      list: [], // API로부터 받아오는 데이터
      currentPage: 1, // 현재 페이지
      itemsPerPage: 6, // 페이지당 항목 수
    };
  },
  computed: {},
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.search();
    },
    search() {
      this.searchParam.size = PAGE_SIZE;
      this.$apiCall.get(API.CONTENTS, this.searchParam, data => {
        if (data.result.list) {
          this.totalCount = data.result.total;
          this.list = data.result.list;
        } else {
          this.list = [];
        }
      });
    },
    getThmbImg(thmbImgFiles) {
      if (thmbImgFiles.length === 0 || !thmbImgFiles[0].atchFileVldNo) {
        return "";
      }

      let imageUrl = buildUri(API.DOWNLOAD, { atchFileVldNo : thmbImgFiles[0].atchFileVldNo });

      return process.env.VUE_APP_ENV === 'local'
          ? `${process.env.VUE_APP_API_PROXY_URL}${imageUrl}`
          : `${imageUrl}`;
    },
    reset() {
      this.searchParam.contsSeCd = '';
      this.searchParam.contsNm = '';
    },
    goDetail(contsSn) {
      this.$router.push({path: `/cust-sprt/ContentsDetail`, query: {contsSn: contsSn}}).catch(() => {
        console.error("Navigation Error");
      });
    }
  }
};
</script>

<style scoped>
.form_select {
  max-width: none;
}

.thumb:after {
  content: none !important; /* "thumb" 클래스가 있을 때 :after 콘텐츠 제거 */
}

</style>
