const MENU_LIST = [
    {
        menuGrpNm: "고형연료",
        path: "",
        items: [
            {
                menuNm: "고형연료안내",
                path: "/wstrsc-ene-intro/sfl/SflGd",
                isPublic: true,
            },
            {
                menuNm: "고형연료제품 시설 공정도",
                path: "/wstrsc-ene-intro/sfl/SflFcltProcs",
                isPublic: true,
            },
            {
                menuNm: "시설검사안내",
                path: "/wstrsc-ene-intro/sfl/FcltInspGd",
                isPublic: true,
            },
            {
                menuNm: "품질검사안내",
                path: "/wstrsc-ene-intro/sfl/QltyInspGd",
                isPublic: true,
            },
            {
                menuNm: "수입품질검사안내",
                path: "/wstrsc-ene-intro/sfl/IncmQltyInspGd",
                isPublic: true,
            },
            {
                menuNm: "원료선별요령안내",
                path: "/wstrsc-ene-intro/sfl/MtralSlMthdGd",
                isPublic: true,
            },
            {
                menuNm: "신고안내",
                path: "/wstrsc-ene-intro/sfl/DclrGd",
                isPublic: true,
            },
        ],
    },
    {
        menuGrpNm: "폐자원에너지 유형",
        path: "/wstrsc-ene-intro/TaskManual",
        items: [],
        isPublic: true,
    },
    {
        menuGrpNm: "법령정보",
        path: "/wstrsc-ene-intro/SttInfo",
        items: [],
        isPublic: true,
    },
    {
        menuGrpNm: "조직도",
        path: "/wstrsc-ene-intro/OgnzCht",
        items: [],
        isPublic: true,
    },
];

export default {
    getMenus: function (){
        return {
            groupName: "폐자원에너지소개",
            menuList: MENU_LIST,
        };
    },
};