<template>
  <div class="tab_conts_wrap">
    <section id="dep1Tabpanel01" class="tab_conts active">
      <!-- 보완요청/반려 안내문구 -->
      <template v-if="['02', '03'].includes(bizfaciltyBzenty.bzentyChgAplySttsCd)">
        <div class="desc_box progress_box">
          <i class="icon"><img src="@/assets/images/common/ico_noti.png" alt="이미지"></i>
          <div class="progress">
            <div><strong>{{ bizfaciltyBzenty.bzentyChgAplySttsNm }}</strong>상태입니다.<span>{{ bizfaciltyBzenty.bzentyChgAplySttsNm }} 사유를 확인해주세요.</span></div>
          </div>
        </div>
      </template>
      <!-- 업체 정보변경 이력 -->
      <div class="board_info" style="position: relative;">
        <h2 class="title1">업체 정보변경신청 이력</h2>
        <div class="page_button_area">
          <br-button format="tertiary" size="sm" icon="search" label="업체 정보변경 이력" @click="callPopupBzentyChgHstry" />
        </div>
      </div>
      <div class="board_info" style="margin-bottom: 0px;">
        <p class="page" >
          <span class="total">전체 <strong>{{ this.bzentyInfoChgHstryCount }}</strong></span>
          <span>페이지 <strong>{{ this.searchParam.page }}/{{ this.maxPage === 0 ? 1 : this.maxPage }}</strong></span>
        </p>
      </div>
      <div class="board_list" style="position: relative;">
        <table style="table-layout: fixed;">
          <caption> 업체정보 변경이력</caption>
          <colgroup>
            <col style="width:220px">
            <col style="width:14rem">
            <col style="width:24rem">
            <col style="width:27rem">
            <col style="width:15rem">
          </colgroup>
          <thead>
          <tr>
            <th scope="col">일시</th>
            <th scope="col">처리유형</th>
            <th scope="col">상세처리상태</th>
            <th scope="col">처리사유</th>
            <th scope="col">처리자</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="bzentyInfoChgHstryList.length <= 0">
            <td colspan="12">
              <div style="display: flex; justify-content: center;">
                <div class="no-data-message">조회조건에 해당하는 데이터가 없습니다.</div>
              </div>
            </td>
          </tr>
          <tr v-else v-for="(item, index) in bzentyInfoChgHstryList" :key="index">
            <td aria-label="일시">{{item.prcsDt }}</td>
            <td aria-label="처리유형"> {{ getCdName(this.chgAplySeCdList, item.chgAplySeCd) }}</td>
            <td aria-label="상세처리상태" style="text-align: center">
              {{ getCdName(this.chgAplyPrcsSttsCdList, item.dtlChgAplyPrcsSttsCd) }}
              <br-button v-if="item.dtlChgAplyPrcsSttsCd === '02' && index === 0" label="조치하기" class="ml_5 xsm tertiary" style="height: 2.6rem;" @click="takeAction(item)"/>
              <br-button v-if="['01', '04'].includes(item.dtlChgAplyPrcsSttsCd)" label="변경정보확인" class="ml_5 xsm tertiary" style="height: 2.6rem;" @click="callPopupBzentyChgAplyInfo(item)"/>
            </td>
            <td aria-label="처리사유" style="text-align: left;">{{item.prcsRsn}}</td>
            <td aria-label="처리자">{{item.userNm}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <insp-pagination :total="bzentyInfoChgHstryCount" :size="searchParam.size" v-model:page="searchParam.page" v-model:maxPage="maxPage" @clickedPage="searchChgHstry"/>
      <!-- 업체 정보 -->
      <h2 class="title1">업체 정보</h2>
      <div class="board_write">
        <p class="t_caption" v-if="isEditMode"><span class="necessary">(<i>※</i>)</span> 필수 입력항목입니다.</p>
        <table>
          <caption>업체정보로 구성</caption>
          <colgroup>
            <col style="width:18%">
            <col style="width:32%">
            <col style="width:18%">
            <col style="width:32%">
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">사업자등록번호</th>
              <td>
                {{this.bizfaciltyBzenty.brno }}
              </td>
              <th scope="row">업체 승인상태</th>
              <td :style="bizfaciltyBzenty.aprvYn === 'N' ? 'color: red' : '' ">
                {{getCdName(this.aprvYnCdList, bizfaciltyBzenty.aprvYn)}}
              </td>
            </tr>
            <tr>
              <th scope="row">업체 명</th>
              <td>
                {{this.bizfaciltyBzenty.bzentyNm }}
              </td>
              <th scope="row">업체 명(영문)</th>
              <td>
                {{this.bizfaciltyBzenty.engBzentyNm }}
              </td>
            </tr>

            <!--  승인완료 and 업체 정보 변경 화면  -->
            <template v-if="isEditMode">
              <tr>
                <th scope="row"><i class="necessary">필수입력</i>대표자명</th>
                <td>
                  <br-form-input label="대표자명" v-model="modifiableData.rprsvNm" panel="panel2" :required="isEditMode"/>
                </td>
                <th scope="row">대표자명(영문)</th>
                <td>
                  <br-form-input label="대표자명(영문)" v-model="modifiableData.engRprsvNm"/>
                </td>
              </tr>
              <tr>
                <th scope="row"><i class="necessary">필수입력</i>전화번호</th>
                <td>
                  <mbr-form-telno label="전화번호"
                                  v-model:telNo1="modifiableData.telno1"
                                  v-model:telNo2="modifiableData.telno2"
                                  v-model:telNo3="modifiableData.telno3"
                                  :required="true"
                                  ref="telnoInput"/>
                </td>
                <th scope="row">FAX</th>
                <td>
                  <mbr-form-telno label="FAX"
                                  v-model:telNo1="modifiableData.fxno1"
                                  v-model:telNo2="modifiableData.fxno2"
                                  v-model:telNo3="modifiableData.fxno3"
                                  ref="fxnoInput"/>
                </td>
              </tr>
              <tr>
                <th scope="row">주소</th>
                <td colspan="3">
                  {{ `[${this.bizfaciltyBzenty.untyZip}] ${this.bizfaciltyBzenty.bzentyAddr} ${this.bizfaciltyBzenty.bzentyDaddr}` }}
                </td>
              </tr>
              <tr>
                <th scope="row">EPR 업체</th>
                <td>
                  <br-form-radio label="EPR업체여부"  v-model:value="modifiableData.eprBzentyYn" :options="eprBzentyYnlist" value-field="value" text-field="text"/>
                </td>
                <th scope="row">업체 상태</th>
                <td>
                  <br-form-radio label="업체상태" groupCd="MBR003" v-model:value="modifiableData.bzentySttsCd"/>
                </td>
              </tr>
            </template>

            <!--  초기화면 (정보변경 X)  -->
            <template v-else>
              <tr>
                <th scope="row">대표자 명</th>
                <td>
                  {{this.bizfaciltyBzenty.rprsvNm }}
                </td>
                <th scope="row">대표자 명(영문)</th>
                <td>
                  {{this.bizfaciltyBzenty.engRprsvNm }}
                </td>
              </tr>
              <tr>
                <th scope="row">전화번호</th>
                <td>
                  {{ this.bizfaciltyBzenty.telno === '--' ? '' : this.bizfaciltyBzenty.telno }}
                </td>
                <th scope="row">FAX</th>
                <td>
                  {{ this.bizfaciltyBzenty.fxno === '--' ? '' : this.bizfaciltyBzenty.fxno }}
                </td>
              </tr>
              <tr>
                <th scope="row">주소</th>
                <td colspan="3">
                  {{ this.bizfaciltyBzenty.untyZip 
                    ? `[${this.bizfaciltyBzenty.untyZip}] ${this.bizfaciltyBzenty.bzentyAddr} ${this.bizfaciltyBzenty.bzentyDaddr}`
                    : `${this.bizfaciltyBzenty.bzentyAddr} ${this.bizfaciltyBzenty.bzentyDaddr}` }}
                </td>
              </tr>
              <tr>
                <th scope="row">EPR 업체</th>
                <td>
                  {{'Y' === this.bizfaciltyBzenty.eprBzentyYn ? '대상' : '대상아님'}}
                </td>
                <th scope="row">업체 상태</th>
                <td>
                  {{getCdName(this.bzentySttsCdList, this.bizfaciltyBzenty.bzentySttsCd)}}
                </td>
              </tr>
            </template>
            <tr>
              <th scope="row">첨부서류</th>
              <td colspan="3">
                <br-attach-file-list label="업체 첨부파일 목록"
                                     ref="attachFileList"
                                     :atchFileTaskSeCd="atchFileTaskSeCd"
                                     v-model:atchFileGroupId="atchFileGroupId"
                                     :maxFileCount="10"
                                     :readonly="!isEditMode"
                                     :useEtcFile="true"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="btn_area center">
        <br-button v-if="!isEditMode && (['05', '03'].includes(bizfaciltyBzenty.bzentyChgAplySttsCd) || !bizfaciltyBzenty.bzentyChgAplySttsCd)" label="정보 변경" class="btn md" @click="onClickInfoChg" />
        <br-button v-if="isEditMode"
                   :label="(bizfaciltyBzenty.bzentyChgAplySeCd === '01' && bizfaciltyBzenty.bzentyChgAplySttsCd === '03') ? '재승인 신청' : '변경 신청'"
                   class="btn md primary"
                   @click="callPopupBzentyChgAply"
                   panel="panel2" validate/>
        <br-button v-if="isEditMode" label="취소" class="btn md point"  @click="cancelModify"/>
      </div>
      <!-- 업체 검사 이력 -->
      <div class="board_info">
        <h2 class="title1">업체 검사 이력</h2>
      </div>
      <div class="board_info" style="margin-bottom: 0px;">
        <p class="page" >
          <span class="total">전체 <strong>{{ this.bzentyInspHstryCount }}</strong></span>
          <span>페이지 <strong>{{ this.searchParam.inspPage }}/{{ this.inspMaxPage === 0 ? 1 : this.inspMaxPage }}</strong></span>
        </p>
      </div>
      <div class="board_list" style="position: relative;">
        <table style="table-layout: fixed;">
          <caption>최근 검사진행 목록-시설명, 검사종류, 검사신청일자, 검사희망일자, 검사(예정)일자, 현재상태, 검사결과로 구성
          </caption>
          <colgroup>
            <col style="width:30%">
            <col style="width:12%">
            <col style="width:12%">
            <col style="width:12%">
            <col style="width:12%">
            <col style="width:12%">
            <col style="width:10%">
          </colgroup>
          <thead>
          <tr>
            <th scope="col">시설명</th>
            <th scope="col">검사종류</th>
            <th scope="col">검사신청일자</th>
            <th scope="col">검사희망일자</th>
            <th scope="col">검사(예정)일자</th>
            <th scope="col">현재상태</th>
            <th scope="col">검사결과</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="bzentyInspHstryList.length <= 0">
            <td colspan="7">
              <div style="display: flex; justify-content: center;">
                <div class="no-data-message">최근 검사진행 내역이 없습니다.</div>
              </div>
            </td>
          </tr>
          <tr v-else v-for="(item, index) in bzentyInspHstryList" :key="index">
            <td aria-label="시설명" class="txt_left"><a href="javascript:void(0);" @click.prevent="onClickedInsp(item)">{{ item.fcltNm }}</a></td>
            <td aria-label="검사종류">{{ item.inspSeNm }}</td>
            <td aria-label="검사신청일자">{{ item.inspAplyYmd }}</td>
            <td aria-label="검사희망일자">{{ item.inspHopeYmd }}</td>
            <td aria-label="검사(예정)일자">{{ item.inspPrnmntYmd }}</td>
            <td aria-label="진행상태"><span v-if="item.inspPrgrsSttsNm" class="badge" :class="{ bg1: item.inspPrgrsSttsCd === '70', bg3: this.isBg3BadgeClass(item.inspPrgrsSttsCd) }">{{ item.inspPrgrsSttsNm }}</span></td>
            <td aria-label="검사결과"><span v-if="item.inspRsltNm" class="badge" :class="{ bg2: item.inspRsltCd === '20' }" style="height: auto;" >{{ item.inspRsltNm }}</span></td>
          </tr>
          </tbody>
        </table>
      </div>
      <insp-pagination :total="bzentyInspHstryCount" :size="searchParam.inspSize" v-model:page="searchParam.inspPage" v-model:maxPage="inspMaxPage" @clickedPage="searchInspHstry"/>
    </section>
  </div>
  <chgAplyPopup
      :id="chgAplyPopId"
      @chgAplyClick="processChangeRequest"
  />
  <bzentyChgHstryPopup
  :bzentyId = this.bizfaciltyBzenty.bzentyId
  />
  <BzentySplmntPopup
      :chgAplyPrcsSttsCd="this.chgAplyPrcsSttsCd"
      :chgAplySn="this.chgAplySn"
      :bzentyId="this.bizfaciltyBzenty.bzentyId"
      :prcsRsn="this.prcsRsn"
      :chgAplySeCd="this.chgAplySeCd"
      @complete="chgHstryGridReload"
  />
  <BzentyChgAplyInfo
      :chgAplySn="this.chgAplySn"
      :bzentyId="this.bizfaciltyBzenty.bzentyId"
  />
</template>

<script>
import {storeComponent} from "@/js/store/store-component";
import {storeSwitch} from "@/js/store/store-switch";
import {buildUri} from "@/js/utils/uri-builder";
import SbGrid from "@/components/SbGrid.vue";
import MbrFormTelno from "@/views/mbr-mng/components/MbrFormTelno.vue";
import inspPagination from "@/views/sfli/components/inspPagination.vue";
import {storeSession} from "@/js/store/store-session";
import BrFormRadio from "@/components/BrFormRadio.vue";
import AddressPopup from "@/views/mbr-mng/components/MbrFormAddress.vue";
import ChgAplyPopup from "@/views/my-office/components/popup/ChgAplyPopup.vue";
import BrButton from "@/components/BrButton.vue";
import BrAttachFileList from "@/components/BrAttachFileList.vue";
import BrFormInput from "@/components/BrFormInput.vue";
import BzentyChgHstryPopup from "@/views/my-office/components/popup/BzentyChgHstryPopup.vue";
import BzentySplmntPopup from "@/views/my-office/components/popup/BzentySplmntPopup.vue";
import BzentyChgAplyInfo from "@/views/my-office/components/popup/BzentyChgAplyInfo.vue";

const API = {
  USER_BZENTY: "/bizfacility/user-bzenty",
  BZENTY_INFO: "/bizfacility/bzenty-info/{bzentyId}",
  BZENTY_CHG_INFO: "/bizfacility/bzenty-chg-info",
  BZENTY_INSP_HIST: "/bizfacility/bzenty-insp-hstry",
  BZENTY_CHG_HSTRY: "/bizfacility/bzenty-chg-hstry/{bzentyId}",
};

const CODE = {
  ARPV_YN_CD: "SIS020", // 승인여부코드(업체)
  INSP_SE_CD: "SIS003",   //검사구분코드
  INSP_PRGRS_STTS_CD: "SIS022",   //검사진행상태코드
  BZENTY_STTS_CD: "MBR003", // 업체상태코드
  BPLC_KND_CD: "MBR006", // 사업장종류코드
  PRDCTN_PRDCT_KND_CD: "MBR010", // 생산제품종류코드
  PUB_BZENTY_YN_CD: "MBR014", // 공공업체여부코드
  INSP_RSLT_CD: "COM430",   //검사결과코드
  CHG_APLY_PRCS_STTS_CD: "MBR009",  //변경신청처리상태코드
  CHG_APLY_SE_CD: "MBR025",  //변경신청구분코드
};

const PAGE_SIZE = 5;

const initBizfaciltyBzenty = {
  bzentyChgAplySeCd: '',
  bzentyChgAplySttsCd: '',
  bzentyChgAplySttsNm: '',
  bzentyId: 0,
  bzentyNm: '',
  bzentyNmDp: '',
  engBzentyNm: '',
  brno: '',
  rprsvNm: '',
  engRprsvNm: '',
  rprsvBrdt: '',
  telno: '',
  telno1: '',
  telno2: '',
  telno3: '',
  fxno: '',
  fxno1: '',
  fxno2: '',
  fxno3: '',
  untyZip: '',
  bzentyAddr: '',
  bzentyDaddr: '',
  ctpvStdgCd: '',
  sggStdgCd: '',
  pubBzentyYn: '',
  atchFileGroupId: '',
  bzentySttsCd: '',
  aprvYn: '',
  eprBzentyYn: '',
  trlBzentyYn: '',
  useYn: '',
  regDt: '',
  rgtrId: '',
  mdfcnDt: '',
  mdfrId: '',
};

export default {
  components: {
    BzentyChgAplyInfo,
    BzentySplmntPopup,
    BrFormInput,
    BrAttachFileList,
    BrButton,
    AddressPopup,
    BrFormRadio,
    inspPagination,
    MbrFormTelno,
    SbGrid,
    ChgAplyPopup,
    BzentyChgHstryPopup
  },
  props: {
    menu: {type: Object, required: true},
  },
  emits: ['startInitialize'],
  expose: ['initialize', 'readBzentyInfo', 'reset'],
  data() {
    return {
      sysAuthrtCd: storeSession.getMemberInfo().sysAuthrtCd,
      userInfo: storeSession.getMemberInfo(),
      isEditMode : false,
      addressPopId: 'addressPopupForBzenty',
      chgAplyPopId: 'chgAplyPopupForBzenty',
      bzentyInspHstryList: [],
      bzentyInspHstryCount: 0,
      bzentyInfoChgHstryList: [],
      bzentyInfoChgHstryCount: 0,
      atchFileTaskSeCd: 'BET',
      prcsRsn: '',
      chgAplySeCd: '',
      maxPage: 1,
      inspMaxPage: 1,
      searchParam: {
        page: 1,
        size: 5,
        inspPage: 1,
        inspSize: 5,
      },
      userBzenty: {
        bzentyId: 0,
        bzentyAprvYn: '',
      },
      bizfaciltyBzenty: {...initBizfaciltyBzenty},
      modifiableData: {...initBizfaciltyBzenty},
      bzentySttsCdList: [],
      bplcKndCdList: [],
      prdctnPrdctKndCdList: [],
      aprvYnCdList: [],
      chgAplyTypeCdList: [],
      chgAplyPrcsSttsCdList: [],
      chgAplySeCdList: [],
      inspSeCdList: [],
      eprBzentyYnlist : [
        { value: "Y", text: "대상" },
        { value: "N", text: "대상아님" },
      ],
      chgAplySn: 0,
      chgAplyPrcsSttsCd: '',
    };
  },
  computed: {
    // 업체 정보 수정여부 확인하기
    isChanged(){
      return JSON.stringify(this.bizfaciltyBzenty) !== JSON.stringify(this.modifiableData);
    },
    atchFileGroupId: {
      get() {
        return this.isEditMode ? this.modifiableData.atchFileGroupId : this.bizfaciltyBzenty.atchFileGroupId;
      },
      set(value) {
        if (this.isEditMode) {
          this.modifiableData.atchFileGroupId = value;
        } else {
          this.bizfaciltyBzenty.atchFileGroupId = value;
        }
      }
    },
  },
  watch: {},
  created() {
  },
  beforeMount() {
    // 컴포넌트 전체 데이터 가져오기
    storeComponent.restore(this.menu, this);
  },
  mounted() {
    this.setUserBzenty();
  },
  beforeUnmount() {
    // 컴포넌트 전체 데이터 저장하기
    storeComponent.store(this.menu, this);
  },
  methods: {
    setUserBzenty(){
      this.$apiCall.get(API.USER_BZENTY, {}, data => {
        if (data.result) {
          for (const key of Object.keys(this.userBzenty)) {
            this.userBzenty[key] = data.result[key];
          }
          this.$emit('startInitialize', this.userBzenty);
        }
      });
    },
    initialize(bzentyId) {

      this.bizfaciltyBzenty.bzentyId = bzentyId;
      if (bzentyId) {
        // 공통코드 초기화
        this.bzentySttsCdList = this.$store.getters.getCodeList({groupCd: CODE.BZENTY_STTS_CD, useYn: 'Y'});   // 업체상태코드
        this.bplcKndCdList = this.$store.getters.getCodeList({groupCd: CODE.BPLC_KND_CD, useYn: 'Y'});         // 사업장종류코드
        this.prdctnPrdctKndCdList = this.$store.getters.getCodeList({groupCd: CODE.PRDCTN_PRDCT_KND_CD, useYn: 'Y'});   // 생산제품종류코드
        this.inspSeCdList = this.$store.getters.getCodeList({groupCd: CODE.INSP_SE_CD, useYn: 'Y'});   // 검사구분코드
        this.inspPrgrsSttsCdList = this.$store.getters.getCodeList({groupCd: CODE.INSP_PRGRS_STTS_CD, useYn: 'Y'});   // 검사진행상태코드
        this.inspRsltCdList = this.$store.getters.getCodeList({groupCd: CODE.INSP_RSLT_CD, useYn: 'Y'});   // 검사결과코드
        this.aprvYnCdList = this.$store.getters.getCodeList({groupCd: CODE.ARPV_YN_CD, useYn: 'Y'});           // 승인여부코드
        this.chgAplyPrcsSttsCdList = this.$store.getters.getCodeList({groupCd: CODE.CHG_APLY_PRCS_STTS_CD, useYn: 'Y'});           // 변경신청처리상태코드
        this.chgAplyTypeCdList = this.$store.getters.getCodeList({groupCd: CODE.CHG_APLY_TYPE_CD, useYn: 'Y'});           // 변경신청유형코드
        this.chgAplySeCdList = this.$store.getters.getCodeList({groupCd: CODE.CHG_APLY_SE_CD, useYn: 'Y'});           // 변경신청구분코드

        this.readBzentyInfo();
        this.searchInspHstry();
        this.searchChgHstry();
      }
    },
    readBzentyInfo() {
      this.isEditMode = false;

      const bzentyInfoUrl = buildUri(API.BZENTY_INFO, {bzentyId: this.bizfaciltyBzenty.bzentyId});
      this.$apiCall.get(bzentyInfoUrl, {}, data => {
        if (data.result) {
          for (const key of Object.keys(this.bizfaciltyBzenty)) {
            this.bizfaciltyBzenty[key] = data.result?.[key] ?? '';
          }
          this.modifiableData = JSON.parse(JSON.stringify(this.bizfaciltyBzenty));
        }
      });
    },
    searchInspHstry(){
      this.searchParam.inspSize = PAGE_SIZE;
      this.$apiCall.get(API.BZENTY_INSP_HIST, {page: this.searchParam.inspPage, size: this.searchParam.inspSize,}, data => {
        if (data.result.list) {
          this.bzentyInspHstryCount = data.result.total;
          this.bzentyInspHstryList = data.result.list;
        } else {
          this.bzentyInspHstryList = [];
        }
      });
    },
    searchChgHstry(){
      const bzentyChgHstryUrl = buildUri(API.BZENTY_CHG_HSTRY, {bzentyId: this.bizfaciltyBzenty.bzentyId});

      this.searchParam.size = PAGE_SIZE;

      this.$apiCall.get(bzentyChgHstryUrl, this.searchParam, data => {
        if (data.result.list) {
          this.bzentyInfoChgHstryCount = data.result.total;
          this.bzentyInfoChgHstryList = data.result.list;
        } else {
          this.bzentyInfoChgHstryList = [];
        }
      });
    },
    getCdName(list, code) {
      const status = list.find(item => item.dtlCd === code);
      return status ? status.dtlCdNm : '';
    },
    validateTelno() {
      const telnoInput = this.$refs.telnoInput;
      if (telnoInput && !telnoInput.isValid()) {
        alert("전화번호를 올바르게 입력해 주세요.")
        return false;
      }
      const fxnoInput = this.$refs.fxnoInput;
      if (this.modifiableData.fxno && !fxnoInput.isValid()) {
        alert("팩스번호를 올바르게 입력해 주세요.")
        return false;
      }
      return true;
    },
    reset(){
      this.modifiableData = {...initBizfaciltyBzenty};
      this.isEditMode = false;
    },
    isBg3BadgeClass(inspPrgrsSttsCd) {
      let result = false;
      switch (inspPrgrsSttsCd) {
        case '00':  // 작성중
        case '10':  // 신청
        case '30':  // 서류보완요청
        case '50':  // 반려
        case '80':  // 사전취소
        case '90':  // 검사취하
          result = true;
          break;
      }
      return result;
    },
    callPopupBzentyChgHstry(){
      storeSwitch.on('bzentyChgHstryPopup');
    },
    callPopupBzentyChgAply(){
      this.modifiableData.telno = `${this.modifiableData.telno1}${this.modifiableData.telno2}${this.modifiableData.telno3}`
      this.modifiableData.fxno = `${this.modifiableData?.fxno1 || ''}${this.modifiableData?.fxno2 || ''}${this.modifiableData?.fxno3 || ''}`

      if(this.validateTelno()){
          storeSwitch.on(this.chgAplyPopId);
        }
    },
    callPopupBzentySplmnt(){
      storeSwitch.on('bzentySplmntPopup');
    },
    callPopupBzentyChgAplyInfo(item){
      this.chgAplySn = item.chgAplySn;

      storeSwitch.on('bzentyChgAplyInfoPopup');
    },
    chgHstryGridReload(){
      this.searchChgHstry();
    },
    processChangeRequest(prcsRsn){
        this.modifiableData.prcsRsn = prcsRsn;

        this.$refs.attachFileList.save().then(atchFileGroupId => {

          this.modifiableData.atchFileGroupId = atchFileGroupId;

          // 승인신청(01) 상태에서 반려(03)당한 후 정보변경신청을 하는 경우엔 정보변경구분코드를 승인신청(01)으로 다시 넣어준다.
          if( this.modifiableData.bzentyChgAplySeCd === '01' && this.modifiableData.bzentyChgAplySttsCd === '03' ) {
            this.modifiableData.chgAplySeCd = '01';
          } else {
            //변경신청구분코드 -> 02 정보변경신청
            this.modifiableData.chgAplySeCd = '02';
          }

          //변경신청처리상태코드  -> 01 승인대기
          this.modifiableData.chgAplyPrcsSttsCd = '01';
          //상세변경신청처리상태코드 -> 01 승인대기
          this.modifiableData.dtlChgAplyPrcsSttsCd = '01';
          //변경신청유형코드  -> 01 업체
          this.modifiableData.chgAplyTypeCd = '01';

          this.$apiCall.post(API.BZENTY_CHG_INFO, this.modifiableData, () => {
                alert("변경 신청되었습니다.");
                this.readBzentyInfo();
                this.searchInspHstry();
                this.searchChgHstry();
                this.isEditMode = false;
              },
              () => {
                alert('변경 신청에 실패하였습니다.');
              }
          );
        });
    },
    onClickInfoChg(){
      this.isEditMode  = true;
    },
    cancelModify(){
      this.isEditMode  = false;
    },
    takeAction(item){
      this.chgAplyPrcsSttsCd = item.chgAplyPrcsSttsCd;
      this.chgAplySn = item.chgAplySn;
      this.prcsRsn = item.prcsRsn;
      this.chgAplySeCd = item.chgAplySeCd
      this.callPopupBzentySplmnt();
    },
    onClickedInsp(item) {
      console.dir(item)

      const dataObj = {
        inspAplySnValue: item.inspAplySn, /* 검사일련번호 */
      };
      switch (item.inspSeCd.slice(0, 2)){
        case '10':  //시설검사
          console.log('시설검사')
          // 데이터를 Local Storage에 저장
          localStorage.setItem('fcltInspDetailObj', JSON.stringify(dataObj));
          this.$router.push(
              {
                path : "/sfli/fclt-insp-aply/FcltInspAplyDetail"
              }
          ).catch(
              () => {
                console.error("Navigation Error");
              }
          );
          break;
        case '20':  //품질검사
          console.log('품질검사')
          this.$router.push({path: '/sfli/qlty-insp-stat/QltyInspStatDetail', query: {inspAplySn: item.inspAplySn}}).catch(() => {
            console.error("Navigation Error");
          });
          break;
        case '30':  //수입품질검사
          console.log('수입품질검사')
          this.$router.push({path: '/sfli/incm-qlty-insp-stat/IncmQltyInspStatDetail', query: {inspAplySn: item.inspAplySn}}).catch(() => {
            console.error("Navigation Error");
          });
          break;
        case '40':  //품질표시검사
          break;
        case '50':  //수입품질표시검사
          break;
      }
    },
  },
}
</script>

<style scoped>
.no-data-message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  margin: 20px 0px;
  border-radius: 10px;
  background-color: #f7f7f7;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 350px;
}
.board_info .page_button_area {position: absolute; top: 0px; right: 0px;}
</style>