<template>
  <div v-show="isVisible" class="modal visible">
    <div class="popup_box" tabindex="0">
      <h1 class="popup_title">실적 변경 내역</h1>
      <div class="popup_cont">
        <div class="grid_search">
          <h4>제품 잔량이 수정될 경우, 이후 실적의 정보를 확인 하기 위한 화면입니다.</h4>
        </div>
        <sb-grid
            id="prfmncChgGrid"
            :dataSource="dataSource"
            :columns="columns"
            :gridattr="gridattr"
            v-model:grid="gridObject"
            @loaded="gridLoaded"
            :refreshGrid="gridReload"
        />

        <div class="grid_box pt_15 pb_20 txt_center">
          <p>{{ yearMonthText }} 실적의 정보가 변경 됨에 따라 이후 실적의 정보가 위와 같이 변경됩니다.</p>
        </div>

        <div class="btn_area right">
          <br-button label="일괄적용" size="md" format="primary" @click="eblcAplcn" />
          <br-button label="닫기" size="md" @click="hide" />
        </div>
      </div>
      <button type="button" class="modal_close" @click="hide">팝업 닫기</button>
    </div>
  </div>
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";
import SbGrid from "@/components/SbGrid.vue";

export default {
  components: {SbGrid},
  emits: ['eblc-aplcn'],
  data() {
    return {
      isVisible: false,
      gridReload: false,
      gridObject: null,
      dataSource: {},
      columns: [],
      gridattr: {
        showRowNo: false,
        pageable: false,
        editable: true,
        toolBar: [],
        height: 320,
      },
      yearMonthText: "",
      prfmncYm: "",
    };
  },
  computed: {},
  watch: {},
  created() {
    storeSwitch.setBulb('SdflUsePrcsPrfmncChgDscntPopup', this);
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.initializeColumns();
    },
    initializeColumns() {
      this.columns = [
        { field: 'prfmncId', caption: '실적아이디', visible: false },
        { field: 'prfmncYm', caption: '실적연월', width: 100, editable: false,
          format: (date) => {
            if (date && date.length === 6) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              return `${yyyy}-${mm}`;
            }
            return date;
          },
        },
        { caption: '변경전',
          columns: [
            { field: 'prfmncSttsCd', caption: '실적상태코드', visible: false },
            { field: 'prfmncSttsNm', caption: '실적상태', width: 100, editable: false },
            { caption: '고형연료제품',
              columns: [
                { field: 'prdctSumCrfqty', caption: '이월량', dataType: 'number', width: 33, unit: '%', align: 'right', format: '#,##0.00', editable: false },
                { field: 'prdctSumRmnqty', caption: '잔량', dataType: 'number', width: 33, unit: '%', align: 'right', format: '#,##0.00', editable: false },
              ]
            },
          ],
        },
        { caption: '변경후',
          columns: [
            { field: 'updatePrfmncSttsCd', type: 'radio', caption: '실적상태', width: 140,
              editable: (data) => {
                if(data.prfmncYm === this.prfmncYm && data.updatePrfmncSttsCd === "SUB") {
                  return true;
                }
                return data.prfmncSttsCd !== 'IPG';
              },
              radioItems: [
                { label: '작성중', value: 'IPG' },
                { label: '제출', value: 'SUB' },
              ],
              colCss: (data) => {
                if(data.prfmncYm === this.prfmncYm) {
                  if (data.updatePrfmncSttsCd === "IPG") {
                    return 'grid-disabled';
                  } else {
                    return '';
                  }
                }
                return data.prfmncSttsCd !== 'IPG' ? '' : 'grid-disabled';
              },
            },
            { caption: '고형연료제품',
              columns: [
                { field: 'updatedPrdctSumCrfqty', caption: '이월량', dataType: 'number', width: 33, unit: '%', align: 'right', format: '#,##0.00', editable: false },
                { field: 'updatedPrdctSumRmnqty', caption: '잔량', dataType: 'number', width: 33, unit: '%', align: 'right', format: '#,##0.00', editable: false },
              ]
            },
          ],
        },
      ];
    },
    show(param) {
      this.dataSource = param || [];

      if (param && param.length > 0) {
        const firstItem = param[0];
        const year = firstItem.prfmncYr || "";
        const month = firstItem.prfmncMm || "";
        this.yearMonthText = `${year}년 ${month}월`;
        this.prfmncYm = firstItem.prfmncYm || "";
      } else {
        this.yearMonthText = "";
        this.prfmncYm = "";
      }

      this.isVisible = true;
      document.documentElement.style.overflow = 'hidden';
      this.gridReload = true;
    },
    hide() {
      this.isVisible = false;
      document.documentElement.style.overflow = '';
    },
    gridLoaded() {
      this.gridReload = false;
    },
    eblcAplcn() {
      const allItems = window.SBGrid3.getAllItems(this.gridObject);
      const firstUpdatePrfmncSttsCd = allItems[0]?.updatePrfmncSttsCd || "SUB";

      this.$emit('eblc-aplcn', firstUpdatePrfmncSttsCd, allItems);
    },
  }
}
</script>

<style scoped>

</style>