<template>
  <label :for="prefix" style="display: none">{{ label }}</label>
  <select
      :class="customClass"
      :id="prefix"
      :value="value"
      :required="required"
      :disabled="onDisabled"
      @change="onChange"
      :style="customStyle"
      :title="label"
  >
    <option v-for="option in selectOptions" :key="option.value" :value="option.value">
      {{ option.text }}
    </option>
  </select>
</template>

<script>
import { storeValidator } from "@/js/store/store-validator";
import pp from '@/js/postposition';
import { v4 as uuidv4 } from "uuid";
import { mapGetters } from "vuex";

export default {
  props: {
    label: {type: String, required: true},
    options: {type: Array, default: () => []},
    groupCd: {type: String, default: null},
    frstRefVl: {type: String, default: null},
    scdRefVl: {type: String, default: null},
    thdRefVl: {type: String, default: null},
    frthRefVl: {type: String, default: null},
    fthRefVl: {type: String, default: null},
    value: { type: String, default: '' },
    text: { type: String, default: '' },
    required: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    readonly: { type: Boolean, default: false },
    panel: {type: String, default: ''},
    allSelectable: { type: Boolean, default: false },
    includeSelectOption: { type: Boolean, default: false },
    customStyle: { type: Object, default: () => ({}) },
    customClass: {type: String, default: 'form_select'}
  },
  emits: [ 'update:value', 'update:text', 'change' ],
  data() {
    return {
      prefix: "select-" + uuidv4(),
      selectOptions: [],
      hasError: false,
      errorMessage: "",
      selectedOption: null,
    };
  },
  computed: {
    ...mapGetters(['getCodeList']),
    filteredCodeList() {
      return this.getCodeList({groupCd: this.groupCd,
        useYn: 'Y',
        ...(this.frstRefVl && { frstRefVl: this.frstRefVl }),
        ...(this.scdRefVl && { scdRefVl: this.scdRefVl }),
        ...(this.thdRefVl && { thdRefVl: this.thdRefVl }),
        ...(this.frthRefVl && { frthRefVl: this.frthRefVl }),
        ...(this.fthRefVl && { fthRefVl: this.fthRefVl }),
      });
    },
    onDisabled() {
      return this.disabled || this.readonly;
    }
  },
  watch: {
    value() {
      this.updateSelectedOption();
    },
    options(newVal) {
      this.selectOptions = newVal;
      if (this.allSelectable && !this.selectOptions.some(opt => opt.value === "")) {
        this.selectOptions.unshift({ text: "전체", value: "" });
      }else if (this.includeSelectOption){
        this.selectOptions.unshift({ text: "선택", value: "" });
      }
      this.updateSelectedOption();
    }
  },
  created() {
    if (this.groupCd) {
      this.setComCd();
    } else {
      this.selectOptions = this.options;
      if (this.allSelectable && !this.selectOptions.some(opt => opt.value === "")) {
        this.selectOptions.unshift({ text: "전체", value: "" });
      } else if (this.includeSelectOption){
        this.selectOptions.unshift({ text: "선택", value: "" });
      }
        this.updateSelectedOption();
    }
    if (this.required) {
      storeValidator.add(this.$route.path, this.panel, this);
    }
  },
  beforeUnmount() {
    if (this.required) {
      storeValidator.remove(this.$route.path, this.panel, this.label);
    }
  },
  methods: {
    setComCd() {
      this.selectOptions = [];
      const codeList = this.filteredCodeList;
      if (codeList.length > 0) {
        this.selectOptions = codeList.map(item => ({
          text: item.dtlCdNm,
          value: item.dtlCd
        }));
        if (this.allSelectable) {
          this.selectOptions.unshift({ text: "전체", value: "" });
        }else if (this.includeSelectOption) {
          this.selectOptions.unshift({ text: "선택", value: "" });
        }
      } else {
        this.setError();
      }
      this.updateSelectedOption();
    },
    setError() {
      this.selectOptions.push({ text: "", value: "" });
    },
    validate() {
      if (this.required && !this.selectedOption.value) {
        this.hasError = true;
        this.errorMessage = `${pp.eunNn(this.label)} 필수 선택값 입니다.`;
      } else {
        this.hasError = false;
        this.errorMessage = "";
      }
    },
    onChange(event) {
      const selectedValue = event.target.value;
      const selectedOption = this.findOption(selectedValue);
      this.selectOption(selectedOption);
    },
    selectOption(option) {
      this.selectedOption = option;
      this.$emit('update:value', this.selectedOption.value);
      this.$emit('update:text', this.selectedOption.text);
      this.$emit('change', this.selectedOption);
      this.validate();
    },
    findOption(value) {
      return this.selectOptions.find(opt => opt.value === value) || { text: "", value: "" };
    },
    updateSelectedOption() {
      this.selectedOption = this.findOption(this.value);
      this.$emit('update:text', this.selectedOption.text);
      this.validate();
    }
  }
}
</script>

<style scoped>
</style>
