<template>
  <div class="pagination">
    <a role="link" href="javascript:void(0);" class="arr first" @click.prevent="goFirst">처음</a>
    <a role="link" href="javascript:void(0);" class="arr prev" @click.prevent="goPrevious">이전</a>
    <div>
      <a role="link" href="javascript:void(0);" v-show="!pageNumbers.includes(1)" @click.prevent="onClickPage(1)">{{ 1 }}</a>
      <span v-show="!pageNumbers.includes(1)" class="link_dot"></span>
      <template v-for="pageNo in pageNumbers" :key="pageNo">
        <a role="link" href="javascript:void(0);" :class="{active: pageNo == currentPage}" @click.prevent="onClickPage(pageNo)">{{ pageNo }}</a>
      </template>
      <span v-show="!pageNumbers.includes(totalPage)" class="link_dot"></span>
      <a role="link" href="javascript:void(0);" v-show="!pageNumbers.includes(totalPage)" @click.prevent="onClickPage(totalPage)">{{ this.totalPage }}</a>
    </div>
    <a role="link" href="javascript:void(0);" class="arr next" @click.prevent="goNext">다음</a>
    <a role="link" href="javascript:void(0);" class="arr last" @click.prevent="goLast">마지막</a>
  </div>
</template>

<script>
/**
 * 테이블 그리드 페이징 처리
 *
 * 작성자 : jhkim
 * 작성일 : 10/28/2024
 * 버전 : 1.0
 */

const MAX_PAGE_BUTTUNS = 5;

export default {
  props: {
    total: {type: Number, default: 1, required: true},
    size: {type: Number, default: 10, required: true},
    page: {type: Number, default: 1, required: true},
    maxPage: {type: Number, default: 1, required: true},
  },
  emits: [ 'update:page', 'update:maxPage', 'clickedPage'],
  data() {
    return {
      currentPage: this.page,
    };
  },
  computed: {
    totalPage() {
      return Math.ceil(this.total / this.size);
    },
    pageNumbers() {
      let pageNumber = [];

      // 모든 페이지 표시
      if (this.totalPage <= MAX_PAGE_BUTTUNS) {
        for (let i = 1; i <= this.totalPage; i++) {
          pageNumber.push(i);
        }
      } else {
        // 현재 페이지가 초기 구간에 있을 때 (1부터 시작하는 페이지 범위)
        if (this.currentPage <= Math.ceil(MAX_PAGE_BUTTUNS / 2)) {
          for (let i = 1; i <= MAX_PAGE_BUTTUNS; i++) {
            pageNumber.push(i);
          }
        }
        // 현재 페이지가 마지막 구간에 있을 때 (끝 페이지에서부터 시작하는 페이지 범위)
        else if (this.currentPage > this.totalPage - Math.floor(MAX_PAGE_BUTTUNS / 2)) {
          for (let i = this.totalPage - MAX_PAGE_BUTTUNS + 1; i <= this.totalPage; i++) {
            pageNumber.push(i);
          }
        }
        // 현재 페이지가 중간에 있을 때 (현재 페이지 기준 ±2 범위)
        else {
          const startPage = this.currentPage - Math.floor(MAX_PAGE_BUTTUNS / 2);
          const endPage = this.currentPage + Math.floor(MAX_PAGE_BUTTUNS / 2);
          for (let i = startPage; i <= endPage; i++) {
            pageNumber.push(i);
          }
        }
      }

      return pageNumber;
    },
  },
  watch: {
    totalPage(newVal) {
      this.$emit('update:maxPage', newVal);
    }
  },
  mounted() {},
  methods: {
    onClickPage(pageNo) {
      this.currentPage = pageNo;
      this.$emit('update:page', pageNo);
      this.$emit('clickedPage');
    },
    goFirst() {
      this.onClickPage(1);
    },
    goPrevious() {
      this.onClickPage(--this.currentPage);
    },
    goNext() {
      this.onClickPage(++this.currentPage);
    },
    goLast() {
      this.onClickPage(this.totalPage);
    },
  }
}
</script>

<style scoped>

</style>