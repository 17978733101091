import { createApp, defineAsyncComponent } from 'vue'

import "@/js/css";

import router from "@/js/router";
import axios from "axios";
import apiCall from "@/js/api-call";
import menusTotal from "@/js/menu/menus-total";
import ckEditor from '@ckeditor/ckeditor5-vue';
import storeCode from "@/js/store/store-code";

const BR_COMPONENTS = [
    "BrAnchorButton", "BrAttachFile", "BrButton", "BrDateFromTo", "BrDatePicker",
    "BrDateRange", "BrFormCheckbox", "BrFormInput", "BrFormRadio", "BrFormSelect",
    "BrFormTextarea", "BrMonthFromTo", "BrMonthPicker", "BrSpanButton", "BrStaticFileDownload",
    "BrAttachFileList", "BrYearPicker", "BrLocalLoading"
];

function loadBrComponents(app) {
    try {
        for(let component of BR_COMPONENTS) {
            app.component(component, defineAsyncComponent(() =>
                import(`@/components/${component}.vue`)));
        }
    } catch (error) {
        console.log("loadEpComponents: Error=" + error);
    }
}

import App from './App.vue'
const app = createApp(App);
app.use(router);
app.use(ckEditor);
app.use(storeCode);

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$apiCall = apiCall;
app.config.globalProperties.$menusTotal = menusTotal;

loadBrComponents(app);

app.mount('#app');
