<template>
    <div class="input_group">
      <label for="telno" style="display: none">전화번호</label>
      <select v-model="areaCode" @change="emitTelno" class="form_control" id="telno" :disabled="disabled">
        <option value="">선택</option>
        <option v-for="option in areaCodeOptions" :key="option.value" :value="option.value">
          {{ option.label }}
        </option>
      </select>
      
      <br-form-input 
        v-model="telNo2Value"
        @input="emitTelno"
        @keyup="replaceTelNo"
        :maxlength="4" 
        placeholder="입력"
        label="전화번호"
        :disabled="disabled"
      />
      
      <br-form-input 
        v-model="telNo3Value" 
        @input="emitTelno"
        @keyup="replaceTelNo"
        :maxlength="4" 
        placeholder="입력"
        label="전화번호"
        :disabled="disabled"
      />
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex'
  
  export default {
    props: {
      telNo1: {type: String, default: ''},
      telNo2: {type: String, default: ''},
      telNo3: {type: String, default: ''},
      useYn: {type: String, default: 'Y'},
      phoneType: { type: String, default: 'LANDLINE' }, // 전화번호 유형
      required: {type: Boolean, default: false},
      disabled: { type: Boolean, default: false }
    },
    emits: ["update:telNo1", "update:telNo2", "update:telNo3", 'input'],
    name: 'TelnoInput',
    data() {
      return {
        areaCode: '',
        middlePart: '',
        lastPart: '',
        areaCodeOptions: [],
        telNo1Value: this.telNo1,
        telNo2Value: this.telNo2,
        telNo3Value: this.telNo3,
        telno: '',
        isRequiredError: false // 필수 체크 에러 상태
      }
    },
    computed: {
      ...mapGetters(['getCodeList'])
    },
    created() {
      this.fetchAreaCodeOptions()
      this.initializeAreaCode();
    },
    watch: {
      telNo1(newVal) {
        this.areaCode = newVal;
      },
      telNo2(newVal) {
        this.telNo2Value = newVal;
      },
      telNo3(newVal) {
        this.telNo3Value = newVal;
      }
    },
    methods: {
      fetchAreaCodeOptions() {
        const groupCd = this.phoneType === 'MOBILE' ? 'SIS006' : 'SIS005'; // 휴대전화번호 그룹 코드 선택
        const options = this.useYn === 'ALL' ? this.getCodeList({ groupCd }) : this.getCodeList({ groupCd, useYn: this.useYn })
        this.areaCodeOptions = options.map(option => ({
          value: option.dtlCd,
          label: option.dtlCdNm 
        }))
      },
      initializeAreaCode() {
        // 초기값 설정 (telNo1에서 값을 받아서 areaCode로 설정)
        if (this.telNo1) {
          this.areaCode = this.telNo1;
        }
      },
      emitTelno() {
        let telno = this.areaCode
  
        // 서울 (02) 지역번호의 경우 중간 번호가 3자리 또는 4자리
        if (this.areaCode === '02') {
          if (this.telNo2Value.length > 3) {
            telno += `${this.telNo2Value}${this.telNo3Value}`
          } else {
            telno += `${this.telNo2Value}${this.telNo3Value.slice(0, 4)}`
          }
        } else {
          // 그 외 지역의 경우
          telno += `${this.telNo2Value}${this.telNo3Value}`
        }
  
        this.$emit('input', telno.trim());
        this.$emit('update:telNo1', this.areaCode); // areaCode 업데이트
        this.$emit('update:telNo2', this.telNo2Value);
        this.$emit('update:telNo3', this.telNo3Value);
  
      },
      isValid() { // 전화번호, 팩스번호 유효성 체크
        return this.areaCode && this.telNo2Value.length >= 3 && this.telNo2Value.length <= 4 && this.telNo3Value.length === 4;
      },
      isPhoneValid() {  // 휴대전화번호 유효성 체크
        return this.areaCode && this.telNo2Value.length == 4 && this.telNo2Value.length <= 4 && this.telNo3Value.length === 4;
      },
      replaceTelNo() {
        this.telNo2Value = this.telNo2Value.replace(/[^0-9]/g, '');
        this.telNo3Value = this.telNo3Value.replace(/[^0-9]/g, '');
        this.emitTelno();
      },
    }
  }
  </script>
  
  <style scoped>
  
  </style>