<template>
  <div class="member_wrap">
    <ol class="join_step_wrap">
      <li class="active">
        <i class="step1"></i>
        <span>회원선택</span>
      </li>
      <li>
        <i class="step2"></i>
        <span>가입여부 확인</span>
      </li>
      <li>
        <i class="step3"></i>
        <span>약관동의</span>
      </li>
      <li>
        <i class="step4"></i>
        <span>사용자정보 입력</span>
      </li>
      <li>
        <i class="step5"></i>
        <span>가입완료</span>
      </li>
    </ol>

    <h2 class="member_title">회원가입 유형 선택</h2>
    <div class="tit_box">만 14세 미만은 회원가입이 불가합니다.</div>
    <div class="type_area">
      <a href="javascript:void(0);" class="type_box">
        <i class="user"></i>
        <h3>시설 사용자</h3>
        <p>사업자등록번호가 있는 고형연료/폐자원에너지 시설 소속 사용자</p>
        <span class="btn sm tertiary">선택</span>
      </a>
      <a href="javascript:void(0);" class="type_box">
        <i class="admin"></i>
        <h3>업무 관리자</h3>
        <p>시설/품질검사, 등록실적 관리 등 폐자원에너지 업무 전반을 관리하는 지자체, 환경청, 외부시험기관 사용자</p>
        <span class="btn sm tertiary">선택</span>
      </a>
    </div>
    <p style="font-size: 1.9rem;" class="txt_center mb_10">시설 사용자께서는 <strong class="txt_point">사업자등록번호</strong>를 입력하신 후 회원가입 진행이 계속됩니다.</p>
    <div class="member_box">
      <input type="text" name="" id="" class="form_control w20p"> -  <input type="text" name="" id="" class="form_control w10p"> -  <input type="text" name="" id="" class="form_control w20p">
      <button type="button" class="btn sm primary line ml_20">확인</button>
    </div>

    <div class="pb_50"></div>

    <ol class="join_step_wrap">
      <li>
        <i class="step1"></i>
        <span>회원선택</span>
      </li>
      <li class="active">
        <i class="step2"></i>
        <span>가입여부 확인</span>
      </li>
      <li>
        <i class="step3"></i>
        <span>약관동의</span>
      </li>
      <li>
        <i class="step4"></i>
        <span>사용자정보 입력</span>
      </li>
      <li>
        <i class="step5"></i>
        <span>가입완료</span>
      </li>
    </ol>

    <h2 class="member_title">가입여부 확인</h2>
    <div class="tit_box">본인인증을 통해 가입여부를 체크합니다.</div>
    <div class="type_area">
      <a href="javascript:void(0);" class="type_box">
        <i class="mobile"></i>
        <h3>휴대폰 본인인증</h3>
        <p>법인 명의 및 타인 명의의 휴대폰으로는 인증이 불가합니다</p>
        <span class="btn sm tertiary">인증하기</span>
      </a>
    </div>

    <div class="pb_50"></div>

    <ol class="join_step_wrap">
      <li>
        <i class="step1"></i>
        <span>회원선택</span>
      </li>
      <li>
        <i class="step2"></i>
        <span>가입여부 확인</span>
      </li>
      <li class="active">
        <i class="step3"></i>
        <span>약관동의</span>
      </li>
      <li>
        <i class="step4"></i>
        <span>사용자정보 입력</span>
      </li>
      <li>
        <i class="step5"></i>
        <span>가입완료</span>
      </li>
    </ol>

    <h2 class="member_title">약관동의</h2>
    <div class="form_check terms_agree_all"><input type="checkbox" name="agree_chk0" id="agree_chk01"><label for="agree_chk01">전체동의</label></div>
    <h3 class="agree_title">서비스 이용약관<span class="txt_point">(필수)</span></h3>
    <div class="agree_txt">
      <!--약관 내용 들어가는 곳-->
    </div>
    <div class="agree_box">
      <span>서비스 이용약관에 동의하십니까?</span>
      <div class="chk_area">
        <div class="form_check"><input type="radio" name="agree_chk3" id="agree_chk31"><label for="agree_chk31">동의함</label></div>
        <div class="form_check"><input type="radio" name="agree_chk3" id="agree_chk32"><label for="agree_chk32">동의하지않음</label></div>
      </div>
      <p>
        위의 서비스 이용약관에 대한 동의를 거부할 권리가 있습니다. <br>
        다만 동의를 거부하실 경우 이용자 가입 및 서비스 이용이 제한될 수 있습니다.
      </p>
    </div>
    <h3 class="agree_title">개인정보 수집 및 이용에 관한 안내<span class="txt_point">(필수)</span></h3>
    <div class="agree_txt">
      <!--개인정보 내용 들어가는 곳-->
    </div>
    <div class="agree_box">
      <span>개인정보 수집 이용에 대에 동의하십니까?</span>
      <div class="chk_area">
        <div class="form_check"><input type="radio" name="agree_chk3" id="agree_chk31"><label for="agree_chk31">동의함</label></div>
        <div class="form_check"><input type="radio" name="agree_chk3" id="agree_chk32"><label for="agree_chk32">동의하지않음</label></div>
      </div>
      <p>
        위의 개인정보 수집·이용에 대한 동의를 거부할 권리가 있습니다. <br>
        다만 동의를 거부하실 경우 이용자 가입 및 서비스 이용이 제한될 수 있습니다.
      </p>
    </div>
    <div class="btn_area center">
      <a href="javascript:void(0);" class="btn md primary">다음</a>
    </div>

    <div class="pb_50"></div>

    <ol class="join_step_wrap">
      <li>
        <i class="step1"></i>
        <span>회원선택</span>
      </li>
      <li>
        <i class="step2"></i>
        <span>가입여부 확인</span>
      </li>
      <li>
        <i class="step3"></i>
        <span>약관동의</span>
      </li>
      <li class="active">
        <i class="step4"></i>
        <span>사용자정보 입력</span>
      </li>
      <li>
        <i class="step5"></i>
        <span>가입완료</span>
      </li>
    </ol>

    <h2 class="member_title">사용자정보 입력</h2>
    <h3 class="agree_title">업체정보</h3>
    <div class="board_write">
      <p class="t_caption"><span class="necessary">(<i>※</i>)</span> 필수 입력항목입니다.</p>
      <table>
        <caption>업체정보_사업자등록번호, 업체 명, 대표자 명, 업체 명(영문), 전화번호, FAX, 주소, 시도, 시군구, EPR 업체, 증빙서류로 구성</caption>
        <colgroup>
          <col style="width:18%">
          <col style="width:32%">
          <col style="width:18%">
          <col style="width:32%">
        </colgroup>
        <tbody>
        <tr>
          <th scope="row"><i class="necessary">필수입력</i>사업자등록번호</th>
          <td><div class="input_group"><input type="text" id="" class="form_control" disabled title="사업자등록번호 앞자리"><input type="text" id="" class="form_control" disabled title="사업자등록번호 가운데자리"><input type="text" id="" class="form_control" disabled title="사업자등록번호 뒷자리"></div></td>
          <th scope="row"><i class="necessary">필수입력</i>업체 명</th>
          <td><input type="text" id="" class="form_control" title="업체명"></td>
        </tr>
        <tr>
          <th scope="row"><i class="necessary">필수입력</i>대표자 명</th>
          <td><input type="text" id="" class="form_control" title="대표자명"></td>
          <th scope="row"><i class="necessary">필수입력</i>업체 명(영문)</th>
          <td><input type="text" id="" class="form_control" title="업체명(영문)"></td>
        </tr>
        <tr>
          <th scope="row"><i class="necessary">필수입력</i>이름</th>
          <td><input type="text" id="" class="form_control" title="이름"></td>
          <th scope="row"><i class="necessary">필수입력</i>아이디</th>
          <td>
            <div class="input_group"><input type="text" id="" class="form_control" title="아이디"> <a href="javascript:void(0);" class="btn sm secondary">중복확인</a></div></td>
        </tr>
        <tr>
          <th scope="row"><i class="necessary">필수입력</i>전화번호</th>
          <td><input type="text" id="" class="form_control" title="전화번호"></td>
          <th scope="row">FAX</th>
          <td><input type="text" id="" class="form_control" title="FAX"></td>
        </tr>
        <tr>
          <th scope="row"><i class="necessary">필수입력</i>주소</th>
          <td colspan="3">
            <div class="form_group_addr">
              <div class="input_search">
                <input type="text" name="" class="form_control addr_zip" disabled="">
                <button type="button" class="btn sm ico_search txtN">주소검색</button>
              </div>
              <input type="text" name="" class="form_control">
            </div>
          </td>
        </tr>
        <tr>
          <th scope="row">시도</th>
          <td>
            <select id="" class="form_select" disabled>
              <option>전체</option>
              <option>인천광역시</option>
            </select>
          </td>
          <th scope="row">시군구</th>
          <td>
            <select id="" class="form_select" disabled>
              <option>전체</option>
            </select>
          </td>
        </tr>
        <tr>
          <th scope="row">EPR 업체</th>
          <td colspan="3">
            <div class="chk_area">
              <div class="form_check"><input type="radio" name="agree_chk1" id="agree_chk11"><label for="agree_chk11">대상</label></div>
              <div class="form_check"><input type="radio" name="agree_chk1" id="agree_chk12"><label for="agree_chk12">대상아님</label></div>
            </div>
          </td>
        </tr>
        <tr>
          <th scope="row">알림수신채널</th>
          <td colspan="3">
            <div class="chk_area">
              <div class="form_check"><input type="checkbox" name="agree_chk21" id="agree_chk21"><label for="agree_chk21">이메일</label></div>
              <div class="form_check"><input type="checkbox" name="agree_chk22" id="agree_chk22"><label for="agree_chk22">SMS</label></div>
              <div class="form_check"><input type="checkbox" name="agree_chk23" id="agree_chk23"><label for="agree_chk23">알림톡(카카오)</label></div>
            </div>
          </td>
        </tr>
        <tr>
          <th scope="row">내부 IP주소</th>
          <td><input type="text" id="" class="form_control" title="내부 IP주소"></td>
          <th scope="row">외부 IP주소</th>
          <td><input type="text" id="" class="form_control" title="외부 IP주소"></td>
        </tr>
        <tr>
          <td colspan="4" style="border-right:1px solid #e0e0e0">
            <div class="info_box" style="border:0">
              <strong class="info_title">IP 주소 확인 방법</strong>
              <ul class="dot_list">
                <li>내부 IP 주소 : 1. 윈도우 화면 좌측 하단 찾기에서 cmd 검색 후 클릭 2. ipconfig 입력 후 IPv4 주소 확인</li>
                <li>외부 IP 주소 : 네이버 검색창에 “IP 주소 조회” 검색</li>
              </ul>
            </div>
          </td>
        </tr>
        <tr>
          <th scope="row">증빙서류</th>
          <td colspan="3">
            <div class="file_upload_list">
              <dl class="list">
                <dt><i class="necessary">필수입력</i> 사업자등록증</dt>
                <dd>
                  <div class="file_box">
                    <input type="text" disabled class="file_name" value="파일을 선택하세요">
                    <label for="file" class="btn xsm secondary">찾아보기</label>
                    <input type="file" id="file" class="file">
                  </div>
                </dd>
              </dl>
              <dl class="list">
                <dt><i class="necessary">필수입력</i> 통장사본</dt>
                <dd>
                  <div class="file_box">
                    <input type="text" disabled class="file_name" value="파일을 선택하세요">
                    <label for="file" class="btn xsm secondary">찾아보기</label>
                    <input type="file" id="file" class="file">
                  </div>
                </dd>
              </dl>
              <dl class="list">
                <dt>기타 서류 #1</dt>
                <dd>
                  <div class="file_box">
                    <input type="text" disabled class="file_name" value="파일을 선택하세요">
                    <label for="file" class="btn xsm secondary">찾아보기</label>
                    <input type="file" id="file" class="file">
                  </div>
                </dd>
              </dl>
              <dl class="list">
                <dt>기타 서류 #2</dt>
                <dd>
                  <div class="file_box">
                    <input type="text" disabled class="file_name" value="파일을 선택하세요">
                    <label for="file" class="btn xsm secondary">찾아보기</label>
                    <input type="file" id="file" class="file">
                  </div>
                </dd>
              </dl>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="btn_area center">
      <a href="javascript:void(0);" class="btn md primary">다음</a>
    </div>

    <div class="pb_50"></div>

    <ol class="join_step_wrap">
      <li>
        <i class="step1"></i>
        <span>회원선택</span>
      </li>
      <li>
        <i class="step2"></i>
        <span>가입여부 확인</span>
      </li>
      <li>
        <i class="step3"></i>
        <span>약관동의</span>
      </li>
      <li>
        <i class="step4"></i>
        <span>사용자정보 입력</span>
      </li>
      <li class="active">
        <i class="step5"></i>
        <span>가입완료</span>
      </li>
    </ol>

    <div class="join_finish">
      <img src="@/assets/images/sub/ico_join_finish.png" alt="폐자원에너지 종합정보관리시스템">
      <strong>회원가입이 완료되었습니다.</strong>
      <p>
        폐자원에너지 종합정보관리시스템의 다양한 정보를 이용하실 수 있습니다.<br>
        더 좋은 정보 제공을 위해 노력하겠습니다.<br>
        감사합니다.
      </p>
    </div>
    <div class="member_box">
      <p>홍길동(gildong)님은 2024.09.02 시설운영자로 가입되셨습니다.</p>
      <!-- p class="txt_hint pt_10" style="color:var(--gray50)">[12345] 인천광역시 서구 정서진로 410 (오류동) a동 102호 | 02-1234-5678</p -->
    </div>
    <div class="btn_area center">
      <a href="javascript:void(0);" class="btn md tertiary" @click.prevent="navigateTo('/')">메인으로</a>
      <a href="javascript:void(0);" class="btn md primary" @click.prevent="navigateTo('/user/login')">로그인하기</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {
    navigateTo(path) {
      if (path) {
        this.$router.push(path)
            .catch(() => {
              console.error("Navigation Error");
            });
      }
    },
  }
}
</script>

<style scoped>

</style>