<template>
<!--  <div v-if="countVisible" class="table_info">-->
<!--    <div class="total_page">-->
<!--      <span>전체 <strong>{{ totalCount }}</strong></span>-->
<!--    </div>-->
<!--  </div>-->
  <div class="grid_box" :style="{ height: gridattr.height ? gridattr.height + 8 + 'px' : '508px' }">
    <div :id="id"></div>
  </div>
</template>

<script>
import '@/css/sbgrid.css';
import { storeSession } from "@/js/store/store-session";
import {v4 as uuidv4} from "uuid";

export default {
  props: {
    id: { type: String, required: true },
    columns: { type: Array, required: true },
    dataSource: { type: Object, required: true },
    gridattr: { type: Object, required: false, default: () => {} },
    tree: { type: Object, required: false, default: () => null },
    grid: { type: Object, default: null },
    param: { type: Object, default: () => {} },
    fileName: { type: String, default: uuidv4() },
    countVisible: { type: Boolean, default: true },
    excelBtnVisible: { type: Boolean, default: true },
    refreshGrid: { type: Boolean, default: false },
  },
  emits: [ 'row-clicked', 'row-double-clicked', 'loaded', 'focus', 'change', 'update:grid' ],
  data() {
    return {
      menu: this.$menusTotal.getRouteMenu(this.$route.path),
      gridObject: null,
      clickTimer: null,
      totalCount: 0,
      gridattrChanged: Object,
    };
  },
  computed: {
  },
  watch: {
    refreshGrid(newVal) {
      if (newVal) {
        this.createGrid();
      }
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    createGrid() {
      const ajaxConfig = this.dataSource && this.dataSource.ajaxConfig ? this.dataSource.ajaxConfig : null;
      const pageSize = this.dataSource && this.dataSource.pageSize ? this.dataSource.pageSize : 20;
      const schema = this.dataSource && this.dataSource.schema ? this.dataSource.schema : {};
      const treeConfig = this.tree || {};

      const dataSourceConfig = ajaxConfig && ajaxConfig.select ? {
        ajax: {
          select: ajaxConfig.select ? async (request) => await this.getData(request, ajaxConfig.select) : null,
          total: 'total',
          selected: 'data',
        },
        pageSize : pageSize,
        serverPaging: typeof ajaxConfig.paging !== 'undefined' ? ajaxConfig.paging : true,
        schema: schema,
        ...treeConfig,
      } : this.dataSource;

      const toolBarConfig = this.hasExcelAuth() ? { right: ['excel'] } : {};
      if(!this.excelBtnVisible){
        toolBarConfig.right = [];
      }

      if (this.gridattr && this.gridattr.excelImport) {
        toolBarConfig.left = toolBarConfig.left || [];
        toolBarConfig.left.push('excelImport');
      }

      const excelExportConfig = {
        rowRange: async () => {
          const allData = await this.getAllData();
          return allData.map(item => {
            return this.columns.reduce((acc, col) => {
              acc[col.field] = item[col.field];
              return acc;
            }, {});
          });
        },
      };

      if (this.fileName) {
        excelExportConfig.fileName = this.fileName;
      }

      // 2025.01.09, kjh추가, 편집중 value값 바로적용되는 옵션 일괄 전체 적용 (focusout 시 편집중 데이터 지워지는 문제 방지용)
      //2025.03.17 그리드 헤더 4줄까지 옵션 적용 되도록 수정
      this.columns.forEach(item => {
        if(item.columns){

          item.columns.forEach(cols => {

            if(cols.columns){

              cols.columns.forEach(colsDepth1 => {

                if(colsDepth1.columns){
                  colsDepth1.columns.forEach(colsDepth2 => {
                    if(colsDepth2.directApplyValue === undefined) {
                      colsDepth2.directApplyValue = true;
                    }
                  });
                }else if(colsDepth1.directApplyValue === undefined) {
                  colsDepth1.directApplyValue = true;
                }

              });
            }else if(cols.directApplyValue === undefined) {
              cols.directApplyValue = true;
            }

          });

        }else if(item.directApplyValue === undefined) {
          item.directApplyValue = true;
        }
      });

      // 2025.01.09, kjh추가, 마우스 클릭 시, 바로 편집모드 들어가도록 옵션 일괄 적용 (마우스 더블클릭 해야만 편집모드 활성화되는 문제 방지용)
      this.gridattrChanged = this.gridattr;
      if(typeof this.gridattrChanged.editable === "boolean" && this.gridattrChanged.editable === true) {
        this.gridattrChanged.editable = {
          eventType: 'mouseup',
          keyEdit: true,
        }
      }

      this.gridObject = window.SBGrid3.createGrid({
        dataSource: dataSourceConfig,
        container: '#' + this.id,
        columns: this.columns,
        width: '100%',
        height: '500px',
        showRowNo: true,
        resizable: true,
        navigatable: true,
        copyable: {
          fill: true,
          textCopyMode: 'text'
        },
        selectable: {
          selectMode: 'multi',
          cellSelect: 'cell',
        },
        hover: 'row',
        pageable: {
          pager: {
            buttonCount: 10,
            numeric: true,
            previousNext: true,
          },
          pagerCombo : { listCount : 5 },
        },
        pagerBar: { center: 'pager' },
        toolBar: toolBarConfig,
        excelExport: excelExportConfig,
        doCommand: (grid, name, command) => {
          switch (name) {
            case 'event': {
              if (command.event.type === 'click') {

                if (this.clickTimer) {
                  clearTimeout(this.clickTimer);
                  this.clickTimer = null;
                }

                this.clickTimer = setTimeout(() => {
                  this.clickTimer = null;
                  if(command.key) {
                    const rowData = window.SBGrid3.getRowData(grid, command.key);

                    this.$emit('row-clicked', rowData);
                  }
                }, 250);
              } else if (command.event.type === 'dblclick') {
                if (this.clickTimer) {
                  clearTimeout(this.clickTimer);
                  this.clickTimer = null;
                }

                if(command.key) {
                  const rowData = window.SBGrid3.getRowData(grid, command.key);
                  this.$emit('row-double-clicked', rowData);
                }
              }
              break;
            }
            case 'loaded': {
              this.$emit('loaded');
              break;
            }
            case 'focus' : {
              if(command.key) {
                const rowData = window.SBGrid3.getRowData(grid, command.key);
                this.$emit('focus', rowData);
              }
              break;
            }
            case 'updated': {
              this.$emit('change', command.values);
              break;
            }
          }
        },
        ...this.gridattrChanged,
      });
      this.$emit('update:grid', this.gridObject);
    },
    async getData(request, config) {
      const params = { ...this.param, page: request.pageNo + 1, size: request.pageSize || 99999 }

      return new Promise(resolve=> {
        this.$apiCall.get(
            config.url,
            params,
            result => {
              this.totalCount = result.result.total;
              resolve({ data: result.result.list, total: result.result.total });
            },
            () => {
              alert("데이터 조회에 실패하였습니다.");
              resolve({ data: [], total: 0 });
            }
        );
      });
    },
    async getAllData() {
      const params = { ...this.param, page: 1, size: this.totalCount }
      return new Promise(resolve => {
        this.$apiCall.get(
            this.dataSource.ajaxConfig.select.url,
            params,
            result => {
              if (result && result.result && result.result.list) {
                resolve(result.result.list);
              } else {
                resolve([]);
              }
            }
        );
      });
    },
    hasExcelAuth() {
      const auth = storeSession.getMenuAuth(this.menu);
      return storeSession.hasAuth(auth, ['E']);
    },
  }
}
</script>

<style scoped>

</style>