<template>
  <div v-show="isOn" class="modal visible">
    <div class="popup_box" style="width: 80%; max-width: inherit">
      <h1 class="popup_title">사업장 선택</h1>
      <div>
        <button type="button" class="modal_close" @click.prevent="hide">팝업닫기</button>
      </div>
      <div class="popup_cont" style="height: 88%;">
        <!-- 화면 타이틀 영역 -->
        <div class="board_info">
          <h2 class="title1">사업장 목록</h2>
        </div>
        <div class="popup_cont" style="height: 88%;">
          <div class="sch_form_wrap">
            <div class="sch_form row2">
              <span>
                <label for="" style="width: 12rem">업체 명</label>
                <div class="input_group">
                <br-form-input class="form_control cal" label="업체명" v-model="searchParam.bzentyNm"
                               @enterKeyup="search"/>
                </div>
              </span>

              <span>
                <label for="" style="width: 12rem">사업장 명</label>
                <div class="input_group">
                <br-form-input class="form_control cal" label="사업장명" v-model="searchParam.bplcNm" @enterKeyup="search"/>
                </div>
              </span>

              <span>
                <label for="" style="width: 12rem">업체 소재지</label>
                <div class="input_group">
                  <br-form-select class="form_select" label="시도" :options="bzentyCtpvCdList"
                                  v-model:value="searchParam.bzentyCtpvCd"
                                  @change="(data) => onChangedCtpvCd('BZENTY', data)"/>
                </div>
                <div class="input_group">
                  <br-form-select class="form_select" label="시군구" :options="bzentySggCdList"
                                  v-model:value="searchParam.bzentySggCd"/>
                </div>
              </span>

              <span>
                <label for="" style="width: 12rem">사업장 소재지</label>
                <div class="input_group">
                  <br-form-select class="form_select" label="시도" :options="bplcCtpvCdList"
                                  v-model:value="searchParam.bplcCtpvCd"
                                  @change="(data) => onChangedCtpvCd('BPLC', data)"/>
                </div>
                <div class="input_group">
                  <br-form-select class="form_select" label="시군구" :options="bplcSggCdList"
                                  v-model:value="searchParam.bplcSggCd"/>
                </div>
              </span>
              <span>
                <label for="" style="width: 12rem">업체 상태</label>
                <div class="input_group">
                  <br-form-select class="form_select" label="업체상태" groupCd="MBR003" allSelectable
                                  v-model:value="searchParam.bzentySttsCd" @enterKeyup="search"/>
                </div>
              </span>

              <span>
                <label for="" style="width: 12rem">사업장 상태</label>
                <div class="input_group">
                  <br-form-select class="form_select" label="사업장상태" groupCd="MBR003" allSelectable
                                  v-model:value="searchParam.bplcSttsCd" @enterKeyup="search"/>
                </div>
              </span>

              <span>
                <label for="" style="width: 12rem">사업자등록번호</label>
                <div class="input_group">
                <br-form-input class="form_control cal" label="사업자등록번호" v-model="searchParam.brno"
                               @enterKeyup="search"/>
                </div>
              </span>
            </div>
            <div class="btn_area">
              <br-anchor-button class="btn sm txtN ico_refresh" label="새로고침" icon="reset" @click="reset"/>
              <br-anchor-button class="btn sm sch" label="조회" icon="icon_search" @click="search"/>
            </div>
          </div>
        </div>

        <!-- 그리드 소계 및 버튼 영역 -->
        <div class="board_info">
          <p class="page">
            <span class="total">전체 <strong>{{ this.bplcPupupDataCount }}</strong></span>
            <span>페이지 <strong>{{ this.searchParam.page }}/{{ this.maxPage }}</strong></span>
          </p>
          <div>
            <a href="javascript:void(0);" class="btn sm ico_excel">엑셀다운로드</a>
          </div>
        </div>
        <!-- 그리드 컨텐츠 영역 -->
        <div class="board_list scroll">
          <table style="table-layout: fixed;">
            <caption></caption>
            <colgroup>
              <col style="width:22rem">
              <col style="width:22rem">
              <col style="width:12rem">
              <col style="width:15rem">
              <col style="width:10rem">
              <col style="width:15rem">
              <col style="width:20rem">
              <col style="width:45rem">
              <col style="width:20rem">
              <col style="width:15rem">
              <col style="width:45rem">
              <col style="width:20rem">
              <col style="width:15rem">
              <col style="width:0rem">
              <col style="width:0rem">
            </colgroup>
            <thead>
            <tr>
              <th scope="col" hidden>업체아이디</th>
              <th scope="col" hidden>사업장아이디</th>
              <th scope="col">업체명</th>
              <th scope="col">사업장명</th>
              <th scope="col">사업장종류</th>
              <th scope="col">사업자등록번호</th>
              <th scope="col">대표자명</th>
              <th scope="col">전화번호</th>
              <th scope="col">위탁업체명</th>
              <th scope="col">업체주소</th>
              <th scope="col">업체상세주소</th>
              <th scope="col">업체상태</th>
              <th scope="col">사업장주소</th>
              <th scope="col">사업장상세주소</th>
              <th scope="col">사업장상태</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="bplcPupupDataList.length <= 0">
              <td colspan="12">
                <div style="display: flex; justify-content: center;">
                  <div class="no-data-message">조회조건에 해당하는 데이터가 없습니다.</div>
                </div>
              </td>
            </tr>
            <tr v-else v-for="(item, index) in bplcPupupDataList" :key="index"
                :class="{ selected: selectedRowIndex === index }" @click="selectedRow(item, index)">
              <td aria-label="업체아이디" hidden>{{ item.bzentyId }}</td>
              <td aria-label="사업장아이디" hidden>{{ item.bplcId }}</td>
              <td aria-label="업체명">{{ item.bzentyNm }}</td>
              <td aria-label="사업장명">{{ item.bplcNm }}</td>
              <td aria-label="사업장종류">{{getCdName(this.bplcKndCdList, item.bplcKndCd)}}</td>
              <td aria-label="사업자등록번호">{{ item.brno }}</td>
              <td aria-label="대표자명">{{ item.rprsvNm }}</td>
              <td aria-label="전화번호">{{ item.telno }}</td>
              <td aria-label="위탁업체명" style="text-align: center">{{ item.cnsgnBzentyNm }}</td>
              <td aria-label="업체주소" style="text-align: left">{{ item.bzentyAddr }}</td>
              <td aria-label="업체상세주소" style="text-align: left">{{ item.bzentyDaddr }}</td>
              <td aria-label="업체상태">{{getCdName(this.bzentySttsCdList, item.bzentySttsCd)}}</td>
              <td aria-label="사업장주소" style="text-align: left">{{ item.bplcAddr }}</td>
              <td aria-label="사업장상세주소" style="text-align: left">{{ item.bplcDaddr }}</td>
              <td aria-label="사업장상태">{{getCdName(this.bplcSttsCdList, item.bplcSttsCd)}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="btn_area center">
          <br-button label="선택" class="btn md tertiary" @click="onClickedSelectButton"/>
          <br-button label="취소" class="btn md tertiary" @click="hide"/>
        </div>

        <!-- 그리드 페이징 영역 -->
        <insp-pagination :total="bplcPupupDataCount" :size="searchParam.size" v-model:page="searchParam.page"
                         v-model:maxPage="maxPage" @clickedPage="search"></insp-pagination>
      </div>

    </div>
  </div>
</template>

<script>
/**
 * 사업장팝업
 *
 * 작성자 : lhs
 * 작성일 : 24. 10. 24.
 * 버전 : 1.0
 */
import {storeSwitch} from "@/js/store/store-switch";
import {buildUri} from "@/js/utils/uri-builder";
import SbGrid from "@/components/SbGrid.vue";
import inspPagination from "@/views/sfli/components/inspPagination.vue";


const API = {
  CTPV_LIST: "/sgg/ctpv-list",
  SGG_LIST: "/sgg/sgg-list/{ctpvCd}",
  COM_DTL_CD: "/code/detail",
  BPLC: "/bizfacility/bplc",
};

const CODE = {
  BZENTY_STTS_CD: "MBR003", // 업체상태코드
  BPLC_STTS_CD: "MBR004", // 사업장상태코드
  PUB_BZENTY_YN_CD: "MBR014", // 공공업체여부코드
  BPLC_KND_CD: "MBR006", // 사업장종류코드
}

const PAGE_SIZE = 10;

export default {
  components: {inspPagination, SbGrid},
  props: [],
  emits: ['select-button-clicked'],
  data() {
    return {
      isOn: false,
      searchParam: {
        bzentyNm: '',
        brno: '',
        bzentyCtpvCd: '',
        bzentySggCd: '',
        pubBzentyYn: '',
        bzentySttsCd: '',
        bplcNm: '',
        bplcCtpvCd: '',
        bplcSggCd: '',
        bplcSttsCd: '',
        page: 1,
      },
      maxPage: 1,
      selectedRowData: '',           // 선택된 행의 데이터를 저장할 변수
      selectedRowIndex: '',       // 선택된 행의 인덱스를 저장할 변수
      bzentyCtpvCdList: [],
      bzentySggCdList: [],
      bzentySttsCdList: [],
      pubBzentyYnCdList: [],
      bplcCtpvCdList: [],
      bplcSggCdList: [],
      bplcSttsCdList: [],
      bplcKndCdList: [],
      bplcPupupDataList: [],
      bplcPupupDataCount: 0,

    };
  },
  computed: {},
  watch: {
    isOn(newVal) {
      if (!newVal) {
        this.reset();
      } else {
        this.search();
      }
    }
  },
  created() {
    storeSwitch.setBulb('bplcPopup', this);
  },
  beforeMount() {
  },
  mounted() {
    this.initialize();
  },
  beforeUnmount() {
  },
  methods: {
    /** 팝업 Show */
    show() {
      this.isOn = true;
    },
    /** 팝업 Hide */
    hide() {
      this.isOn = false;
    },
    async initialize() {

      // 시도 select 초기화
      await this.loadCtpvCdList();

      // 시군구 select 초기화
      this.bzentySggCdList = [{text: "전체", value: ""}];
      this.bplcSggCdList = [{text: "전체", value: ""}];

      this.bzentySttsCdList = this.$store.getters.getCodeList({groupCd: CODE.BZENTY_STTS_CD});     // 업체상태코드
      this.bplcSttsCdList = this.$store.getters.getCodeList({groupCd: CODE.BPLC_STTS_CD});         // 사업장상태코드
      this.pubBzentyYnCdList = this.$store.getters.getCodeList({groupCd: CODE.PUB_BZENTY_YN_CD});  // 공공업체여부코드
      this.bplcKndCdList = this.$store.getters.getCodeList({groupCd: CODE.BPLC_KND_CD});           // 사업장종류코드
      this.search();

    },
    search() {
      this.searchParam.size = PAGE_SIZE;
      this.$apiCall.get(API.BPLC, this.searchParam, data => {
        if (data.result.list) {
          this.bplcPupupDataCount = data.result.total;
          this.bplcPupupDataList = data.result.list;
        } else {
          this.bplcPupupDataList = [];
        }
      });
    },
    reset() {
      this.searchParam.bzentyNm = '';
      this.searchParam.brno = '';
      this.searchParam.bzentyCtpvCd = '';
      this.searchParam.bzentySggCd = '';
      this.searchParam.pubBzentyYn = '';
      this.searchParam.bzentySttsCd = '';
      this.searchParam.bplcNm = '';
      this.searchParam.bplcCtpvCd = '';
      this.searchParam.bplcSggCd = '';
      this.searchParam.bplcSttsCd = '';
      this.selectedRowData = '';
      this.selectedRowIndex = '';
    },
    /** 시도 코드목록 조회 */
    loadCtpvCdList() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.CTPV_LIST, {useYn: 'Y'}, data => {
          if (data.result) {
            const resultOfGet = data.result.map(item => ({
              text: item.ctpvNm,
              value: item.ctpvCd,
            }));

            resultOfGet.unshift({text: "전체", value: ""});

            this.bzentyCtpvCdList = resultOfGet;
            this.bplcCtpvCdList = resultOfGet;

            resolve();
          } else {
            reject(new Error("Failed to load system codes."));
          }
        });
      });
    },
    /** 시군구 코드목록 조회 */
    loadSggCdList(calledBy, ctpvCd) {
      const sggListUrl = buildUri(API.SGG_LIST, {ctpvCd: ctpvCd});
      return new Promise((resolve, reject) => {
        this.$apiCall.get(sggListUrl, {useYn: 'Y'}, data => {
          if (data.result) {
            const resultOfGet = data.result.map(item => ({
              text: item.sggNm,
              value: item.sggStdgCd,
              upperCd: item.upSggStdgCd,
            }));

            resultOfGet.unshift({text: "전체", value: ""});

            switch (calledBy) {
              case "BZENTY":
                this.searchParam.bzentySggCd = '';
                this.bzentySggCdList = resultOfGet;
                break;
              case "BPLC":
                this.searchParam.bplcSggCd = '';
                this.bplcSggCdList = resultOfGet;
                break;
            }

            resolve();
          } else {
            reject(new Error("Failed to load system codes."));
          }
        });
      });
    },
    /** 시도코드 변경 이벤트 */
    onChangedCtpvCd(calledBy, data) {
      this.loadSggCdList(calledBy, data.value);
    },

    /** 사업장 정보 선택 */
    selectedRow(item, index) {
      this.selectedRowData = item;     // 선택된 데이터를 저장
      this.selectedRowIndex = index; // 선택된 행의 인덱스를 저장
    },
    /** 선택 버튼 클릭 */
    onClickedSelectButton() {
      if (this.selectedRowData) {
        this.$emit('select-button-clicked', this.selectedRowData);
        this.isOn = false;
        this.reset();
      } else {
        alert('선택된 사업장이 없습니다.')
      }
    },
    getCdName(list, code) {
      const status = list.find(item => item.dtlCd === code);
      return status ? status.dtlCdNm : '';
    },
  }
}

</script>

<style scoped>
.selected {
  background-color: #f0f8ff; /* 선택 시 배경색 */
}
</style>