<template>
    <head>
        <meta name="description" content="이 페이지는 개선금지명령을 조회합니다.">
    </head>
    <div class="sch_form_wrap">
        <div class="sch_form row2">
            <span>
                <label for="">사업장 명</label>
                <br-form-input label="사업장명" v-model="searchParam.bplcNm" @enterKeyup="search"/>
            </span>
            <span>
                <label for="">시설 명</label>
                <br-form-input label="시설명" v-model="searchParam.fcltNm" @enterKeyup="search"/>
            </span>
            <span>
                <label for="">명령일자</label>
                <br-date-from-to label="명령일자" v-model:fromDate="searchParam.startDate" v-model:toDate="searchParam.endDate"/>
            </span>
            <span>
                <label for="admdspCd">처분종류</label>
                <br-form-select label="처분종류" :options="admdspCdOption" v-model:value="searchParam.admdspCd" allSelectable @change="search" id="admdspCd" customClass="form_select w45p" />
            </span>
        </div>
        <div class="btn_area">
            <br-anchor-button label="검색" role="button" class="btn sm sch" @click="search"/>
            <br-anchor-button label="초기화" role="button" class="btn sm txtN ico_refresh"  @click="reset"/>
        </div>
    </div>
    <div class="board_info">
        <p class="page">
            <span class="total">전체 <strong>{{ this.listItemCount }}</strong></span>
            <span>페이지 <strong>{{ this.searchParam.page }}/{{ this.lastPage }}</strong></span>
        </p>
        <div class="btn_area">
            <a href="javascript:void(0);" role="button" class="btn sm ico_excel" @click.prevent="excelDownload()">엑셀다운로드</a>
        </div>
    </div>
    <div class="board_list scroll">
      <table>
        <caption>개선금지명령 내용으로 구성</caption>
        <colgroup>
            <col style="width: 55px;">
            <col style="width: 130px;">
            <col style="width: 180px;">
            <col style="width: 130px;">
            <col style="width: 130px;">
            <col style="width: 100px;">
            <col style="width: 230px;">
            <col style="width: 350px;">
            <col style="width: 180px;">
            <col style="width: 230px;">
            <col style="width: 350px;">
            <col style="width: 230px;">
        </colgroup>
        <thead>
          <tr>
            <th scope="col" rowspan="2">번호</th>
            <th scope="col" rowspan="2">명령일자</th>
            <th scope="col" rowspan="2">처분종류</th>
            <th scope="col" colspan="2">이행확인</th>
            <th scope="col" rowspan="2">처분대상<br>유형</th>
            <th scope="col" rowspan="2">업체 명</th>
            <th scope="col" rowspan="2">업체 주소</th>
            <th scope="col" rowspan="2">사업장 종류</th>
            <th scope="col" rowspan="2">사업장 명</th>
            <th scope="col" rowspan="2">사업장 주소</th>
            <th scope="col" rowspan="2">시설 명</th>
          </tr>
          <tr>
            <th scope="col">예정일자</th>
            <th scope="col" style="border-right: 1px solid var(--gray30); ">여부</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="listObj.list.length <= 0">
              <td colspan="12">
                <div style="display: flex; justify-content: center;">
                  <div class="no-data-message">조회조건에 해당하는 데이터가 없습니다.</div>
                </div>
              </td>
          </tr>
          <tr v-for="(item, index) in listObj.list" :key="index" @dblclick="detail(item.impvProhbtCmdSn)">
            <td aria-label="번호" style="text-align: center;">{{item.rowNum}}</td>
            <td aria-label="명령일자" style="text-align: center;">{{item.admdspYmd}}</td>
            <td aria-label="처분종류" style="text-align: center;">{{item.admdspNm}}</td>
            <td aria-label="이행확인 예정일자" style="text-align: center;">{{item.impltRsltIdntyPrnmntYmd}}</td>
            <td aria-label="이행확인 여부" style="text-align: center;">{{item.excutYn}}</td>
            <td aria-label="처분대상 유형" style="text-align: center;">{{item.admdspTrgtNm}}</td>
            <td aria-label="업체 명" style="text-align: left;">{{item.bzentyNm}}</td>
            <td aria-label="업체 주소" style="text-align: left;">{{item.bzentyAddr}}</td>
            <td aria-label="사업장 종류" style="text-align: center;">{{item.bplcKndNm}}</td>
            <td aria-label="사업장 명" style="text-align: left;">{{item.bplcNm}}</td>
            <td aria-label="사업장 주소" style="text-align: left;">{{item.bplcAddr}}</td>
            <td aria-label="시설 명" style="text-align: left;">{{item.fcltNm}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <insp-pagination :total="listItemCount" :size="searchParam.size" v-model:page="searchParam.page" v-model:maxPage="lastPage" @clickedPage="search"></insp-pagination>
</template>
    
<script>
import {storeSession} from "@/js/store/store-session";
import inspPagination from "@/views/sfli/components/inspPagination.vue";
import moment from "moment/moment";

const API = {
    MY_IMPV : "/user/impv",
    HOME : "/",
    IMPV_EXCEL : "/user/impv/excel-download-impv",
}
const CODE = {
  ADMDSP_CD: 'SIS007',   // 행정처분코드
}
const PAGE_SIZE = 10;

export default {
  components: {inspPagination},
  data() {
    return {
      searchParam : {   // 조회조건
        startDate : moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
        endDate : moment().format('YYYY-MM-DD'),
        fcltNm : "",
        bplcNm : "",
        admdspCd : "",
        userId : "",
        page : 1,
      },
      listObj : {   // 목록
        impvProhbtCmdSn : "",
        rowNum : "",
        fcltNm : "",
        bplcNm : "",
        bplcKndCd : "",
        bplcKndNm : "",
        bplcAddr : "",
        bplcDaddr : "",
        admdspCd : "",
        admdspNm : "",
        admdspYmd : "",
        impltRsltIdntyPrnmntYmd : "",
        excutYn : "",
        list : [],
      },
      userinfo: storeSession.getMemberInfo(),
      listItemCount : 0,
      lastPage: 1,
      admdspCdOption: [],
    };
  },
  computed: {
  },
  watch: {},
  created() {},
  beforeMount() {
    this.initialize();
  },
  mounted() {
    this.search();
  },
  methods: {
    initialize() {
      this.admdspCdOption = this.$store.getters.getCodeList({groupCd: CODE.ADMDSP_CD, useYn: 'Y'}).map(item => ({ text: `${item.scdRefVl} > ${item.dtlCdNm}`, value: item.dtlCd }));
    },
    navigateTo(path) {
      if (path) {
        this.$router.push(path).catch(() => {
            console.error("Navigation Error");
        });
      }
      window.scrollTo({ top: 0 });
    },
    excelDownload() {
        const date = new Date();

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
        const day = String(date.getDate()).padStart(2, '0');

        this.$apiCall.download(API.IMPV_EXCEL, {
            "fileName" : "개선금지명령_" + `${year}${month}${day}` + ".xlsx", "userId" : this.userinfo.userId
        });
    },
    search() {
        this.searchParam.size = PAGE_SIZE;
        this.searchParam.userId = this.userinfo.userId;
        this.$apiCall.get(API.MY_IMPV, this.searchParam, this.success, this.error);
    },
    success(data) {
        if (!data.result) {
            this.error();
            return;
        }
        this.listObj = data.result;
        this.listItemCount = data.result.total;
    },
    error() {
        alert("데이터를 가져오는 중 오류가 발생했습니다.");
    },
    detail(impvProhbtCmdSn) {
        this.$router.push({path : "/my-office/ImpvProhbtCmdDetail", query : {impvProhbtCmdSn : impvProhbtCmdSn}}).catch(() => {
        console.error("NavigationMng Error");
        });
    },
    reset() {
        this.searchParam.startDate = "";
        this.searchParam.endDate = "";
        this.searchParam.bplcNm = "",
        this.searchParam.fcltNm = "",
        this.searchParam.admdspCd = ""

        this.search();
    },
  },
}
</script>

<style scoped>
.board_list.scroll > table { table-layout: fixed; width:120rem;}
.no-data-message { display: flex; align-items: center; justify-content: center; padding: 10px 5px; margin: 20px 0px; border-radius: 10px; background-color: #f7f7f7; color: #333; font-size: 16px; font-weight: 500; border: 1px solid #ddd; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); width: 350px; }
</style>