<template>
  {{}}
</template>

<script>
import {storeSession} from "@/js/store/store-session";

const ROUTE_MAIN = "/";

const API = {
  PROXY_ACCESS_TOKEN: "/auth/proxy/access-token",
};

export default {
  name: "ProxyRedirect",
  data() {
    return {
      code: "",
      redirect: "",
    };
  },
  created() {

  },
  beforeMount() {
    this.code = this.$route.query.code || this.code;
    this.redirect = this.$route.query.redirect || this.redirect;
  },
  mounted() {
    if (this.code) {
      this.login();
    } else {
      this.$router.push(ROUTE_MAIN).catch(() => {});
    }
  },
  methods: {
    login() {
      this.$apiCall.write(API.PROXY_ACCESS_TOKEN, { code : this.code }, this.gotoMain, this.showAlert);
    },
    gotoMain(data){
      storeSession.setSession(data.result);
      this.$router.push(this.getRedirect()).catch(() => {});
    },
    showAlert(error) {
      console.error(error);
      this.$router.push(ROUTE_MAIN).catch(() => {});
    },
    getRedirect() {
      return this.redirect || ROUTE_MAIN;
    }
  }
}
</script>


