<template>

  <h2 class="title1 mt_0">실적보고 상세</h2>
  <div class="board_write">
    <table>
      <caption>실적연도, 실적상태, 사업장명, 사업장종류</caption>
      <colgroup>
        <col style="width:18%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:32%">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">실적연월</th>
        <td>{{ formattedPrfmncYm }}</td>
        <th scope="row">실적상태</th>
        <td>{{ bioGasDtlObj.prfmncMst.prfmncSttsNm || "작성중" }}</td>
      </tr>
      <tr>
        <th scope="row">사업장명</th>
        <td>{{ bioGasDtlObj.prfmncMst.bplcNm }}</td>
        <th scope="row">사업장종류</th>
        <td>{{ bioGasDtlObj.prfmncMst.bplcKndNm }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div v-show="!showDetail" class="txt_box mt_10">
    <ul class="dot_list">
      <li>실적 등록 시, 반드시 톤 단위로 환산하여 입력 부탁드립니다.</li>
    </ul>
  </div>

  <rjct-rsn-area :prfmncBasicInfo="bioGasDtlObj.prfmncMst" />
  <prfmnc-mdfcn-prm-area :prfmncBasicInfo="bioGasDtlObj.prfmncMst" @dmnd-rtrcn="dmndRtrcnCmptn" />

  <div class="board_info mt_40">
    <h3 class="title2">폐기물 반입 현황</h3>
  </div>

  <div class="grid_search">
    <h4>반입 폐기물 종류는 마이오피스 > 사업장정보에서 관리할 수 있습니다.</h4>
  </div>
  <sb-grid
      id="prfmncDtlBioGasWtCrynGrid"
      :dataSource="dataSourceBioGasWtCryn"
      :columns="gridColumnsBioGasWtCryn"
      :gridattr="gridAttrBioGasWtCryn"
      v-model:grid="prfmncBioGasWtCrynGridObject"
      @loaded="gridLoadedBioGasWtCryn"
      :refreshGrid="gridReloadBioGasWtCryn"
  />

  <div class="board_info mt_40">
    <h3 class="title2">보조에너지 사용량</h3>
  </div>
  <div class="board_write thead">
    <table>
      <caption>보조에너지 사용량</caption>
      <colgroup>
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
      </colgroup>
      <thead>
      <tr>
        <th scope="col" colspan="3">보조연료 사용량</th>
        <th scope="col" colspan="2">전기 사용량(MWh)</th>
      </tr>
      <tr>
        <th scope="row">유류(㎘)</th>
        <th scope="row">LNG(㎥)</th>
        <th scope="row">기타(TOE)</th>
        <th scope="row">외부반입(한전)</th>
        <th scope="row">자체생산</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="txt_right" aria-label="유류(㎘)">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.oilAsstFuelUsqty" :maxlength="15" validator="number" align="right" unit="㎘" panel="login" required />
        </td>
        <td class="txt_right" aria-label="LNG(㎥)">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.lngAsstFuelUsqty" :maxlength="15" validator="number" align="right" unit="㎥" panel="login" required />
        </td>
        <td class="txt_right" aria-label="기타(TOE)">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.etcAsstFuelUsqty" :maxlength="15" validator="number" align="right" unit="TOE" panel="login" required />
        </td>
        <td class="txt_right" aria-label="외부반입(한전)">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.crynPrvtmAsstFuelUsqty" :maxlength="15" validator="number" align="right" unit="MWh" panel="login" required />
        </td>
        <td class="txt_right" aria-label="자체생산">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.prdctnPrvtmAsstFuelUsqty" :maxlength="15" validator="number" align="right" unit="MWh" panel="login" required />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info mt_40">
    <h3 class="title2">에너지 이용 현황</h3>
  </div>
  <div class="board_write thead">
    <table>
      <caption>에너지 이용 현황</caption>
      <colgroup>
        <col style="width: 14%;">
        <col style="width: 12%;">
        <col style="width: 12%;">
        <col style="width: 12%;">
        <col style="width: 12%;">
        <col style="width: 12%;">
        <col style="width: 12%;">
        <col style="width: 14%;">
      </colgroup>
      <thead>
      <tr>
        <th scope="row" rowspan="2">생산량<br />(A + B)</th>
        <th scope="col" colspan="6">에너지이용현황</th>
        <th scope="row" rowspan="2">미이용(단순처리)<br />(B)</th>
      </tr>
      <tr>
        <th scope="row" style="border-left: 1px solid #e0e0e0;">총 이용량(A)</th>
        <th scope="row">발전</th>
        <th scope="row">가스외부공급</th>
        <th scope="row">가스자체사용</th>
        <th scope="row">스팀생산</th>
        <th scope="row">기타</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="txt_right" aria-label="생산량(A + B)">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.enePrdqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" readonly required />
        </td>
        <td class="txt_right" aria-label="총 이용량(A)">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.eneUtzqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" readonly required />
        </td>
        <td class="txt_right" aria-label="발전">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.elctgnUsgGasUsqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" required />
        </td>
        <td class="txt_right" aria-label="가스외부공급">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.gasOtsdSupqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" required />
        </td>
        <td class="txt_right" aria-label="가스자체사용">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.gasSlfUsqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" required />
        </td>
        <td class="txt_right" aria-label="스팀생산">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.stUsgGasUsqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" required />
        </td>
        <td class="txt_right" aria-label="기타">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.etcUsgGasUsqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" required />
        </td>
        <td class="txt_right" aria-label="미이용(단순처리)(B)">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.eneUnuqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" required />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info mt_40">
    <h3 class="title2">에너지 판매 현황</h3>
  </div>
  <div class="grid_search">
    <h4><strong class="mr_5">(에너지 판매량)</strong>외부공급(판매) 상세현황
      <span>※ 판매업체 목록은 에너지공급업체 현황에서 관리 할 수 있습니다.</span>
    </h4>
  </div>
  <sb-grid
      id="prfmncBioGasNtslGrid"
      :dataSource="dataSourceBioGasNtsl"
      :columns="gridColumnsBioGasNtsl"
      :gridattr="gridAttrBioGasNtsl"
      v-model:grid="prfmncBioGasNtslGridObject"
      @loaded="gridLoadedBioGasNtsl"
      :refreshGrid="gridReloadBioGasNtsl"
  />

  <!-- 실적상태이력 현황 (신규등록인 경우 노출안함) -->
  <prfmnc-stts-hstry v-if="bioGasDtlObj.prfmncMst.prfmncId" :prfmncId="bioGasDtlObj.prfmncMst.prfmncId" />

  <!-- 수정허용요청 이력 현황 (신규등록인 경우 노출안함) -->
  <prfmnc-mdfcn-prm-hstry v-if="bioGasDtlObj.prfmncMst.prfmncId" :prfmncId="bioGasDtlObj.prfmncMst.prfmncId" />

  <!-- 버튼 -->
  <common-button-area
      :prfmncBasicInfo="bioGasDtlObj.prfmncMst"
      @save="prtmncRptDtlSave"
      @submission="prtmncRptDtlSave(true)"
  />
</template>

<script>
import SbGrid from "@/components/SbGrid.vue";
import utils from "@/js/utils/utils";
import commonButtonArea from "@/views/prfmnc-rpt/dtl/components/CommonButtonArea.vue";
import prfmncSttsHstry from "@/views/prfmnc-rpt/components/PrfmncSttsHstry.vue";
import PrfmncMdfcnPrmHstry from "@/views/prfmnc-rpt/components/PrfmncMdfcnPrmHstry.vue";
import PrfmncMdfcnPrmArea from "@/views/prfmnc-rpt/dtl/components/PrfmncMdfcnPrmArea.vue";
import RjctRsnArea from "@/views/prfmnc-rpt/dtl/components/RjctRsnArea.vue";

const ROUTE = {
  PERFORMANCE_LIST: "/prfmnc-rpt/PrfmncRpt",
  PERFORMANCE_DTL: "/prfmnc-rpt/dtl/BioGas"
};

const API = {
  PRFMNC_DTL_SAVE: "/prfmnc/bfrBioGas", // 실적 합성가스 상세저장
};

const CODE = {
  PERFORMANCE_STATUS: "PFM001", // 실적상태코드
};

export default {
  components: {
    RjctRsnArea, PrfmncMdfcnPrmArea,
    SbGrid,
    commonButtonArea,
    prfmncSttsHstry,
    PrfmncMdfcnPrmHstry
  },
  props: ['prfmncBioGas', 'prfmncBioGasWtCrynList', 'prfmncBioGasNtslList', 'prfmncMst', 'prfmncFclt', 'showDetail'],
  data() {
    return {
      prfmncSttsList: [], // 실적상태코드

      updatedMtralCrynData: [], // 원료반입현황 수정된 행
      insertedMtralCrynData: [], // 원료반입현황 추가된 행

      // 실적 바이오가스 상세정보 데이터
      bioGasDtlObj:{
        prfmncMst: {},
        prfmncBioGasWtCrynList : [], // 원료반입현황
        prfmncBioGasNtslList : [], // 합성가스판매현황
        prfmncBioGas: {
          enePrdqty: 0,  // 에너지생산량
          oilAsstFuelUsqty: 0,  // 유류보조연료사용량
          lngAsstFuelUsqty: 0,  // LNG보조연료사용량
          etcAsstFuelUsqty: 0,  // 기타보조연료사용량
          crynPrvtmAsstFuelUsqty: 0,  // 반입전기보조연료사용량
          prdctnPrvtmAsstFuelUsqty: 0,  // 생산전기보조연료사용량
          eneUtzqty: 0,  // 에너지이용량
          elctgnUsgGasUsqty: 0,  // 발전용도가스사용량
          gasOtsdSupqty: 0,  // 가스외부공급량
          gasSlfUsqty: 0,  // 가스자체사용량
          stUsgGasUsqty: 0,  // 스팀용도가스사용량
          etcUsgGasUsqty: 0,  // 기타용도가스사용량
          eneUnuqty: 0,  // 에너지미이용량
          prvtmSumNsqty: 0,  // 전기합계판매량
          gasSumNsqty: 0,  // 가스합계판매량
          stSumNsqty: 0,  // 스팀합계판매량
          etcEneSumNsqty: 0,  // 기타에너지합계판매량
        }, // 합성가스실적 (메탄가스생산현황, 메탄가스사용현황)
      },


      /**************** S : 원료반입현황 Grid ****************/
      dataSourceBioGasWtCryn: [],
      gridColumnsBioGasWtCryn: [],
      gridAttrBioGasWtCryn: {
        checkable: false,
        pageable: false,
        editable: {eventType: 'mouseup'},
        showRowNo : false,
        excelExport: {
          fileName: '폐기물반입현황(바이오가스).xlsx',
          includeHeader: true,
          includeFormula: true,
        },
        showStatus:true
      },
      prfmncBioGasWtCrynGridObject: null,
      gridReloadBioGasWtCryn: false,
      /**************** E : 원료반입현황 Grid ****************/

      /**************** S : 합성가스판매현황 Grid ****************/
      dataSourceBioGasNtsl: [],
      gridColumnsBioGasNtsl: [],
      gridAttrBioGasNtsl: {
        checkable: false,
        pageable: false,
        editable: {eventType: 'mouseup'},
        showRowNo : false,
        excelExport: {
          fileName: '에너지판매현황(바이오가스).xlsx',
          includeHeader: true,
          includeFormula: true,
        },
        showStatus:true
      },
      prfmncBioGasNtslGridObject: null,
      gridReloadBioGasNtsl: false,
      /**************** E : 합성가스판매현황 Grid ****************/
    };
  },
  computed: {
    /**
     * [실적보고상세 - 실적연월]
     * YYYY-MM 포맷처리
     */
    formattedPrfmncYm() {
      return (this.bioGasDtlObj.prfmncMst.prfmncYm?.substring(0, 4) ?? "") + "-" + (this.bioGasDtlObj.prfmncMst.prfmncYm?.substring(4, 6) ?? "");
    },
  },
  watch: {
    prfmncMst: {
      handler(newVal) {
        this.bioGasDtlObj.prfmncMst = {...this.bioGasDtlObj.prfmncMst, ...newVal};
      },
      immediate: true,
      deep:true,
    },
    prfmncBioGas() {
      this.bioGasDtlObj.prfmncBioGas = {...this.bioGasDtlObj.prfmncBioGas, ...this.prfmncBioGas};
    },
    prfmncBioGasWtCrynList() {
      this.dataSourceBioGasWtCryn = { data: this.prfmncBioGasWtCrynList.list, schema: { id: 'sggStdgCd'}};
      this.initializeprfmncBioGasMtralGridColumns();
      const itemCount = this.prfmncBioGasWtCrynList.list.length;
      this.gridAttrBioGasWtCryn.height = Math.max((itemCount * 30) + 190, 210);
      this.gridReloadBioGasWtCryn = true;

      // this.bioGasMtralCrynTotal();
      // this.totalGetAllItems(this.prfmncBioGasWtCrynGridObject, "hdrcbtCryqty", "totalHdrcbtCryqty");
      // 화면 초기 진입 시 반입량합계를 폐기물매립현황(당월매립량)에 노출 - 이후 반입량 변경 시 changeBioGasMtral 메서드로 계산
      // const totalWtStotCryqty = this.prfmncBioGasWtCrynList.list.reduce((acc, item) => acc + (item.wtStotCryqty || 0), 0);
      // this.bioGasDtlObj.prfmncBioGas.fillWtTon = totalWtStotCryqty;
    },
    prfmncBioGasNtslList() {
      this.dataSourceBioGasNtsl = this.prfmncBioGasNtslList.list;
      this.initializeprfmncBioGasNtslGridColumns();
      const itemCount = this.prfmncBioGasNtslList.list.length;
      this.gridAttrBioGasNtsl.height = Math.max((itemCount * 30) + 190, 210);
      this.gridReloadBioGasNtsl = true;
    },
    // prfmncFclt() {
    //   const maxFillCapTon = (parseFloat(this.prfmncFclt.totfillCap) / 1000);
    //   this.bioGasDtlObj.prfmncBioGas.maxFillCapTon = maxFillCapTon;
    //   const excsFillTon = parseFloat(this.fillAcmlWstqtyTotal) - maxFillCapTon;
    //   this.bioGasDtlObj.prfmncBioGas.excsFillTon = excsFillTon < 0 ? 0 : excsFillTon;
    // }

    // 발전 값 변경 시 총이용량 재계산
    'bioGasDtlObj.prfmncBioGas.elctgnUsgGasUsqty': 'updateEneUtzqty',
    // 가스외부공급 값 변경 시 총이용량 재계산
    'bioGasDtlObj.prfmncBioGas.gasOtsdSupqty': 'updateEneUtzqty',
    // 가스자체사용 값 변경 시 총이용량 재계산
    'bioGasDtlObj.prfmncBioGas.gasSlfUsqty': 'updateEneUtzqty',
    // 스팀생산 값 변경 시 총이용량 재계산
    'bioGasDtlObj.prfmncBioGas.stUsgGasUsqty': 'updateEneUtzqty',
    // 기타 값 변경 시 총이용량 재계산
    'bioGasDtlObj.prfmncBioGas.etcUsgGasUsqty': 'updateEneUtzqty',

    // 총이용량 값 변경 시 생산량 재계산
    'bioGasDtlObj.prfmncBioGas.eneUtzqty': 'updateEnePrdqty',
    // 미이용 값 변경 시 생산량 재계산
    'bioGasDtlObj.prfmncBioGas.eneUnuqty': 'updateEnePrdqty',


  },
  created() {
    this.initialize();
  },
  mounted() {
  },
  methods: {
    dmndRtrcnCmptn() {
      location.reload();
    },
    // 에너지이용현황 (총이용량 계산)
    updateEneUtzqty() {
      this.bioGasDtlObj.prfmncBioGas.eneUtzqty =
          parseFloat(this.bioGasDtlObj.prfmncBioGas.elctgnUsgGasUsqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGas.gasOtsdSupqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGas.gasSlfUsqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGas.stUsgGasUsqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGas.etcUsgGasUsqty);
    },
    // 에너지이용현황 (생산량 계산)
    updateEnePrdqty() {
      this.bioGasDtlObj.prfmncBioGas.enePrdqty =
          parseFloat(this.bioGasDtlObj.prfmncBioGas.eneUtzqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGas.eneUnuqty);
    },
    async initialize() {
      await this.loadPrfmncSttsCd();
    },
    // 공통코드(실적상태코드) 로드
    async loadPrfmncSttsCd() {
      const prfmncSttsList = this.$store.getters.getCodeList({groupCd: CODE.PERFORMANCE_STATUS, useYn: 'Y'});
      this.prfmncSttsList = prfmncSttsList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    // 폐기물반입현황 그리드 컬럼 세팅
    initializeprfmncBioGasMtralGridColumns() {
      this.gridColumnsBioGasWtCryn = [
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        {
          caption: '반입',
          width: 200,
          part: 'head',
          columns : [
            {
              field: 'ctpvStdgNm',
              caption: '시·도',
              width: 100,
              editable: false,
            },
            {
              field: 'sggStdgNm',
              caption: '시·군·구',
              width: 100,
              editable: false,
            }
          ],
          total: {
            aggregates: [
            ],
            header: [
              { template: '계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        { caption: '원재료 종류(톤)' },
      ];

      if (this.prfmncBioGasWtCrynList.inputItemList && this.prfmncBioGasWtCrynList.inputItemList.length > 0) {
        this.gridColumnsBioGasWtCryn[3].columns = [];

        const inputItemCds = this.prfmncBioGasWtCrynList.inputItemList.map(item => item.inputItemCd);

        this.gridColumnsBioGasWtCryn[3].columns.push({
          field: 'sum', caption: '반입량 합계',
          editable: false,
          colCss: 'grid-disabled',
          total: {
            aggregates: [ {func: 'sum', require: 'sum'} ],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
          calc: {
            require: inputItemCds,
            eval: 'sum',
            nullAs: 0,
            dataType: 'number',
          },
          align: 'right',
          format: '#,##0.00'
        });

        const columnCount = this.prfmncBioGasWtCrynList.inputItemList.length;
        const columnWidth = columnCount > 0 ? (100 / columnCount).toFixed(2) : 100;

        this.prfmncBioGasWtCrynList.inputItemList.forEach(item => {
          this.gridColumnsBioGasWtCryn[3].columns.push({
            field: item.inputItemCd,
            caption: item.inputItemNm,
            width: columnWidth,
            unit: '%',
            total: {
              aggregates: [ {func: 'sum', require: item.inputItemCd} ],
              header: [{ template: '{{sum}}', format: '#,##0.00' }],
              headerCss:'grid-header',
            },
            align: 'right',
            format: '#,##0.00'
          });
        });
      }
    },
    // 에너지판매현황 그리드 컬럼 세팅
    initializeprfmncBioGasNtslGridColumns() {
      this.gridColumnsBioGasNtsl = [
        { field: 'prfmncId', caption: '실적아이디', visible: false, editable: false },
        { field: 'bplcId', caption: '사업장아이디', visible: false, editable: false },
        { field: 'eneBzentySn', caption: '에너지업체일련번호', visible: false, editable: false },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        {
          field: 'bzentyNm',
          caption: '판매 업체명',
          width: 230,
          align: 'left',
          part: 'head',
          editable: false,
          total: {
            aggregates: [
            ],
            header: [
              { template: '계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        {
          field: 'totalNsqty',
          caption: '총 판매금액',
          width: 200,
          align: 'right',
          dataType: 'number',
          editable: false,
          colCss: 'grid-disabled',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          calc: {
            require: ['prvtmNtslAmt', 'gasNtslAmt', 'stNtslAmt'],
            eval: 'sum',
            nullAs: 0,
            dataType: 'number',
          },
          format: '#,##0.00',
        },
        {
          field: 'prvtmNsqty',
          caption: '전기판매량(MWh)',
          width: 200,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
          captionCss:'gridh-bg-electricity',
        },
        {
          field: 'prvtmNtslAmt',
          caption: '전기판매금액(원)',
          width: 200,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
          captionCss:'gridh-bg-electricity',
        },
        {
          field: 'gasNsqty',
          caption: '가스판매량(㎥)',
          width: 200,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
          captionCss:'gridh-bg-gas',
        },
        {
          field: 'gasNtslAmt',
          caption: '가스판매금액(원)',
          width: 200,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
          captionCss:'gridh-bg-gas',
        },
        {
          field: 'stNsqty',
          caption: '스팀판매량(st/톤)',
          width: 200,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
          captionCss:'gridh-bg-vapor',
        },
        {
          field: 'stNtslAmt',
          caption: '스팀판매금액(원)',
          width: 200,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
          captionCss:'gridh-bg-vapor',
        },
        {
          field: 'etcEneNsqty',
          caption: '기타',
          width: 200,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
          captionCss:'gridh-bg-etc',
        },
      ]
    },
    gridLoadedBioGasWtCryn() {
      this.gridReloadBioGasWtCryn = false;
    },
    gridLoadedBioGasNtsl() {
      this.gridReloadBioGasNtsl = false;
    },
    formatNumber(number) {
      return utils.formatNumberWithComma(number);
    },
    // 실적보고상세저장 호출
    async prtmncRptDtlSave(isSubmit = false){
      if (!isSubmit && !confirm("저장하시겠습니까?")) {
        return;
      }

      // 유효성 체크
      const validationMessage = this.validate(isSubmit);

      if(validationMessage && isSubmit){
        alert(validationMessage);
        return;
      }

      if (!validationMessage && confirm("필수입력 조건이 만족하여 제출가능한상태입니다.\n제출하시겠습니까?")) {
        isSubmit = true; // 제출상태로 변경
      }else{
        if(isSubmit){
          return;
        }
      }

      const { updated: wtCrynUpdated } = window.SBGrid3.getSaveData(this.prfmncBioGasWtCrynGridObject, false, true, false);
      const { updated: ntslUpdated } = window.SBGrid3.getSaveData(this.prfmncBioGasNtslGridObject, false, true, false);

      this.$apiCall.post(
          API.PRFMNC_DTL_SAVE,
          {
            wtCrynUpdated,
            ntslUpdated,
            prfmncMst: Object.assign({}, this.bioGasDtlObj.prfmncMst, isSubmit ? { prfmncSttsCd: "SUB" } : {}),
            prfmncBioGas: this.bioGasDtlObj.prfmncBioGas,
          },
          (data) => {
            alert((isSubmit ? '제출' : '저장') + "이 완료되었습니다.");
            let routerChuck = {path: ROUTE.PERFORMANCE_LIST};

            if(!isSubmit) {
              routerChuck = {path: ROUTE.PERFORMANCE_DTL, query: {prfmncId: data.result.prfmncId}};
            }

            this.$router.push(routerChuck)
                .then(() => !isSubmit && location.reload())
                .catch((err) => {
                  console.error('Navigation failed:', err);
                });
          },
          () => {
            alert((isSubmit ? '제출' : '저장') + "에 실패하였습니다.");
          }
      );
    },
    validate(focusOnError = false) {
      // 21년까지의 바이오가스는 등록, 수정화면을 제공하지 않기 때문에 작성하지 않음.
      console.log(focusOnError);

      return "";
    },
  } // END methods
} // END export
</script>

<style scoped>

</style>