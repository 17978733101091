<template>
  <button v-if="isFolded" type="button" class="btn_lnb expand" @click="toggleLnbMenu">메뉴펼치기<i class="arr"></i></button>
  <div v-show="!isFolded" class="lnb_wrap">
    <nav id="lnb">
      <div class="menu_title">
        <div class="title_bg_img"></div>
        <h2 class="title">{{ filteredGroup.groupName }}</h2>
      </div>
      <ul class="list">
        <li v-for="menuGrp in filteredGroup.menuList" :key="menuGrp.menuSn"
            @click="handleMenuGrp(menuGrp.menuSn)"
            :class="{ active: activeLnbMenuGrp === menuGrp.menuSn, plus: hasSubMenu(menuGrp) }"
        >
          <a href="javascript:void(0);" role="link" @click.prevent="handleNavigation(menuGrp.path)">{{ menuGrp.menuGrpNm }}</a>
          <ul v-if="menuGrp.items && menuGrp.items.length && hasVisibleItems(menuGrp.items)">
            <template v-for="item in menuGrp.items" :key="item.menuSn">
              <li v-if="!item.hidden"
                  @click="handleLnbMenuItem(item.menuSn)"
                  :class="{ active: activeLnbMenuItem === item.menuSn }"
              >
                <a href="javascript:void(0);" role="link" @click.stop.prevent="handleNavigation(item.path)">{{ item.menuNm }}</a>
              </li>
            </template>
          </ul>
        </li>
      </ul>
      <button type="button" class="btn_lnb fold" @click="toggleLnbMenu"><i class="arr"></i>메뉴접기</button>
    </nav>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['groups', 'menu'],
  emits: ['menu-select'],
  data() {
    return {
      filteredGroup: {
        groupName: "",
        menuList: [],
      },
      activeLnbMenuGrp: null,
      activeLnbMenuItem: null,
      isFolded: false,
      isManuallyToggled: false, // 사용자가 수동으로 설정했는지 여부
      foldedUrls: [
          '/prfmnc-rpt/CrynSplyBzentyStts',
          '/prfmnc-rpt/dtl'
      ]
    };
  },
  computed: {
    currentPath() {
      return this.$route.path;
    }
  },
  watch: {
    menu() {
      this.setFilteredGroups();
    },
    currentPath() {
      if (!this.isManuallyToggled) {
        this.initializeFoldedState(); // 경로가 변경될 때 접힘 상태를 설정
      }
    }
  },
  created() {
    this.setFilteredGroups();
    this.initializeFoldedState();
  },
  mounted() {
  },
  methods: {
    handleMenuGrp(menuSn) {
      this.activeLnbMenuGrp = menuSn;
    },
    handleLnbMenuItem(menuSn) {
      this.activeLnbMenuItem = menuSn;
    },
    handleNavigation(path) {
      this.$emit("menu-select", path);
    },
    setFilteredGroups() {
      const { menuSn } = this.menu || {};

      if (!menuSn) return;

      this.filteredGroup = this.groups.find(group => {
        if (group.menuSn === menuSn) {
          return true;
        } else {
          return group.menuList.some(menuGroup => {
            if (menuGroup.menuSn === menuSn) {
              this.activeLnbMenuGrp = menuSn;
              return true;
            } else {
              const item = menuGroup.items.find(item => item.menuSn === menuSn);
              if (item) {
                if (!item.hidden) {
                  this.activeLnbMenuItem = menuSn;
                }
                this.activeLnbMenuGrp = menuGroup.menuSn;
                return true;
              }
            }
            return false;
          });
        }
      }) || { groupName: "", menuList: [] };
    },
    hasSubMenu(menuGrp) {
      return menuGrp.items && menuGrp.items.filter(item => !item.hidden).length > 0;
    },
    hasVisibleItems(items) {
      return items.some(item => !item.hidden);
    },
    toggleLnbMenu() {
      this.isFolded = !this.isFolded;
      this.isManuallyToggled = true; // 사용자가 수동으로 설정했음을 표시
    },
    initializeFoldedState() {
      if (!this.isManuallyToggled) {
        this.isFolded = this.foldedUrls.some(url => this.currentPath.includes(url));
      }
    }
  }
}
</script>

<style scoped>

</style>