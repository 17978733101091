<template>
  <div v-show="isOn" class="modal visible">
    <div class="popup_box"  style="width: 80%;">
      <h1 class="popup_title">사업장 신규등록</h1>
      <div>
        <button type="button" class="modal_close" @click.prevent="hide">팝업닫기</button>
      </div>
      <div class="popup_cont" style="max-height: calc(90vh - 15px);overflow: hidden;overflow-y: auto;">
        <!-- 사업장 정보 -->
        <div class="board_info">
          <h3 class="title1">사업장 정보
            <div v-if="bizfaciltyBplc.bplcKndCd === '01-01'"><span class="" style="color: #ea4444">&nbsp;&nbsp;※ 신고허가번호와 신고허가일자는 첨부한 고형연료제조신고증에 기재된 내용을 참고하여 작성해 주시기 바랍니다.</span></div>
          </h3>
        </div>
        <div class="board_write">
          <table>
            <caption></caption>
            <colgroup>
              <col style="width:15%">
              <col style="width:35%">
              <col style="width:15%">
              <col style="width:35%">
            </colgroup>
            <tbody>
            <tr>
              <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>사업장 종류</th>
              <td>
                <br-form-select label="사업장 종류" groupCd="MBR006" v-model:value="bizfaciltyBplc.bplcKndCd" @change="onChangeBplcKnd()" required panel="panel1" includeSelectOption/>
              </td>
              <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>사업장 명</th>
              <td>
                <br-form-input label="사업장명" v-model="bizfaciltyBplc.bplcNm" required panel="panel1"/>
              </td>
            </tr>
            <tr>
              <th scope="row">사업장 명(영문)</th>
              <td>
                <br-form-input label="영문사업장명" v-model="bizfaciltyBplc.engBplcNm"/>
              </td>
              <th scope="row">담당자 명</th>
              <td>
                <br-form-input label="담당자명" v-model="bizfaciltyBplc.picNm"/>
              </td>
            </tr>
            <tr>
              <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>전화번호</th>
              <td>
                <div class="input_group">
                  <mbr-form-telno
                      label="전화번호"
                      v-model:tel-no1="bizfaciltyBplc.telno1"
                      v-model:tel-no2="bizfaciltyBplc.telno2"
                      v-model:tel-no3="bizfaciltyBplc.telno3"
                      :required="true"
                      ref="telnoInput"
                  >
                  </mbr-form-telno>
                </div>
              </td>
              <th scope="row">FAX</th>
              <td>
                <div class="input_group">
                  <mbr-form-telno label="기본 선택"
                                  v-model:telNo1="bizfaciltyBplc.fxno1"
                                  v-model:telNo2="bizfaciltyBplc.fxno2"
                                  v-model:telNo3="bizfaciltyBplc.fxno3"
                                  ref="fxnoInput"/>
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>주소</th>
              <td colspan="3">
                <div class="form_group_addr" style="display: flex; flex-direction: column;">
                  <div class="input_group" style="max-width: calc(50% - .6rem);">
                    <br-form-input label="우편번호" class="form_control addr_zip" style="max-width: 30%; text-align: left;" v-model="bizfaciltyBplc.untyZip" disabled></br-form-input>
                    <br-button label="주소검색" format="primary" size="sm" @click="callPopupAddress" />
                  </div>
                  <div class="input_group">
                    <br-form-input label="주소" class="form_control" style="text-align: left;" v-model="bizfaciltyBplc.bplcAddr" panel="panel1" required disabled></br-form-input>
                    <br-form-input label="상세주소" class="form_control" style="text-align: left;" v-model="bizfaciltyBplc.bplcDaddr"></br-form-input>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="conds.uncheck && !conds.income">
              <th scope="row">위탁운영관리</th>
              <td colspan="3">
                <div class="chk_area">
                  <br-form-radio label="위탁운영관리여부"  v-model:value="bizfaciltyBplc.cnsgnOperMngYn" :options="radioBtnYnList" value-field="value" text-field="text" @change="setUnusedAtchFileList"/>
                </div>
              </td>
            </tr>
            <tr v-if="conds.uncheck && bizfaciltyBplc.cnsgnOperMngYn === 'Y'">
              <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>위탁업체명</th>
              <td>
                <br-form-input label="위탁업체명" :disabled="bizfaciltyBplc.cnsgnOperMngYn === 'N'" v-model="bizfaciltyBplc.cnsgnBzentyNm" panel="panel1" :required="bizfaciltyBplc.cnsgnOperMngYn === 'Y'"/>
              </td>
              <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>위탁업체 사업자등록번호</th>
              <td>
                <br-form-input label="위탁업체사업자등록번호" :disabled="bizfaciltyBplc.cnsgnOperMngYn === 'N'" v-model="bizfaciltyBplc.cnsgnBzentyBrno" :maxlength="10" panel="panel1" :required="bizfaciltyBplc.cnsgnOperMngYn === 'Y'" validator="brno"/>
              </td>
            </tr>
            <tr v-if="conds.uncheck && !conds.income">
              <th scope="row">대기배출시설설치신고</th>
              <td>
                <div class="chk_area">
                  <br-form-radio label="대기배출시설설치신고여부"  v-model:value="bizfaciltyBplc.atmoDschrgFcltInstlDclrYn" :options="radioBtnYnList" value-field="value" text-field="text"/>
                </div>
              </td>
              <th scope="row">중간재활용업체</th>
              <td>
                <div class="chk_area">
                  <br-form-radio label="중간재활용업체여부"  v-model:value="bizfaciltyBplc.mdRecyBzentyYn" :options="radioBtnYnList" value-field="value" text-field="text"/>
                </div>
              </td>
            </tr>
            <tr v-if="conds.uncheck && !conds.income">
              <th scope="row">종합재활용업체</th>
              <td>
                <div class="chk_area">
                  <br-form-radio label="중간재활용업체여부"  v-model:value="bizfaciltyBplc.snthsRecyBzentyYn" :options="radioBtnYnList" value-field="value" text-field="text"/>
                </div>
              </td>
              <th scope="row">최종재활용업체</th>
              <td>
                <div class="chk_area">
                  <br-form-radio label="최종재활용업체여부"  v-model:value="bizfaciltyBplc.lastRecyBzentyYn" :options="radioBtnYnList" value-field="value" text-field="text"/>
                </div>
              </td>
            </tr>
            <tr v-if="conds.showWes && conds.uncheck && !conds.income">
              <th scope="row">중간처분업체</th>
              <td>
                <div class="chk_area">
                  <br-form-radio label="중간처분업체여부"  v-model:value="bizfaciltyBplc.mdDspsBzentyYn" :options="radioBtnYnList" value-field="value" text-field="text"/>
                </div>
              </td>
              <th scope="row">최종처분업체</th>
              <td>
                <div class="chk_area">
                  <br-form-radio label="최종처분업체여부"  v-model:value="bizfaciltyBplc.lastDspsBzentyYn" :options="radioBtnYnList" value-field="value" text-field="text"/>
                </div>
              </td>
            </tr>
            <tr v-if="conds.showWes && conds.uncheck && !conds.income">
              <th scope="row">종합처분업체</th>
              <td>
                <div class="chk_area">
                  <br-form-radio label="종합처분업체여부"  v-model:value="bizfaciltyBplc.snthsDspsBzentyYn" :options="radioBtnYnList" value-field="value" text-field="text"/>
                </div>
              </td>
              <th scope="row" v-if="conds.showWes && conds.uncheck">자원순환시설ID</th>
              <td style="display: flex; gap: 5px" v-if="conds.showWes && conds.uncheck">
                <br-form-input style="width: 70%" label="자원순환시설ID"  v-model="bizfaciltyBplc.rsrcRcyclnFcltId" readonly/>
                <br-button label="시설검색" format="tertiary" size="sm" icon="search" style="width: 30%;" @click="callRsrcRcyclnFcltPopup"/>
              </td>
            </tr>
            <tr v-if="conds.uncheck && !conds.income">
              <th scope="row"><i v-if="conds.showAllSrf" class="necessary">*<span class="sr_only">필수입력</span></i>설치운영허가번호</th>
              <td>
                <br-form-input label="설치운영허가번호" v-model="bizfaciltyBplc.instlOperPrmsnNo" panel="panel1"/>
              </td>
              <th scope="row" style="text-align: left;">
                <div style="display: flex; align-items: center;">
                  <i v-if="conds.showAllSrf" class="necessary" style="margin-right: 5px;">필수입력</i>설치운영<br/>허가일자
                </div>
              </th>
              <td>
                <br-date-picker label="설치운영허가일자" v-model:value="bizfaciltyBplc.instlOperPrmsnYmd" panel="panel1" :customStyle="{ 'max-width': 'none' }" :required="conds.showAllSrf"/>
              </td>
            </tr>
            <tr v-if="conds.uncheck && !conds.income">
              <th scope="row">신고허가번호</th>
              <td>
                <br-form-input label="신고허가번호" v-model="bizfaciltyBplc.dclrPrmsnNo"/>
              </td>
              <th scope="row">신고허가일자</th>
              <td>
                <br-date-picker label="신고허가일자" v-model:value="bizfaciltyBplc.dclrPrmsnYmd" :customStyle="{ 'max-width': 'none' }"/>
              </td>
            </tr>
            <tr v-if="conds.uncheck && !conds.income">
              <th scope="row">처리시설설치신고</th>
              <td>
                <div class="chk_area">
                  <br-form-radio label="처리시설설치신고여부"  v-model:value="bizfaciltyBplc.prcsFcltInstlDclrYn" :options="radioBtnYnList" value-field="value" text-field="text"/>
                </div>
              </td>
              <th scope="row">총 사업비</th>
              <td>
                <br-form-input label="총사업비" v-model="bizfaciltyBplc.tpcoAmt" validator="number"/>
              </td>
            </tr>
            <tr v-if="conds.uncheck && !conds.income">
              <th scope="row">인원수</th>
              <td>
                <br-form-input label="인원수" v-model="bizfaciltyBplc.nope" validator="number"/>
              </td>
              <th scope="row">운영금액</th>
              <td>
                <br-form-input label="운영금액" v-model="bizfaciltyBplc.operAmt" validator="number"/>
              </td>
            </tr>
            <tr v-if="conds.uncheck && !conds.income">
              <th scope="row">편익시설 종류</th>
              <td>
                <br-form-input label="편익시설종류" v-model="bizfaciltyBplc.cnvncFcltKndNm"/>
              </td>
              <th scope="row">편익시설 설치비용</th>
              <td>
                <br-form-input label="편익시설설치비용" v-model="bizfaciltyBplc.cnvncFcltInstlCst" validator="number"/>
              </td>
            </tr>
            <tr v-if="conds.uncheck && !conds.income">
              <th scope="row">편익시설 운영기관</th>
              <td>
                <br-form-input label="편익시설운영기관" v-model="bizfaciltyBplc.cnvncFcltOperInstNm"/>
              </td>
              <th scope="row">편익시설 비고</th>
              <td>
                <br-form-input label="편익시설비고" v-model="bizfaciltyBplc.cnvncFcltRmrkCn"/>
              </td>
            </tr>
            <tr v-if="conds.uncheck && !conds.income">
              <th scope="row">사업기간</th>
              <td colspan="3">
                <br-date-from-to label="사업기간" v-model:fromDate="bizfaciltyBplc.bizBgngYmd" v-model:toDate="bizfaciltyBplc.bizEndYmd"/>
              </td>
            </tr>
            <tr v-if="conds.uncheck && !conds.income">
              <th scope="row">국고금액</th>
              <td>
                <br-form-input label="국고금액" v-model="bizfaciltyBplc.gvcfrAmt" validator="number"/>
              </td>
              <th scope="row">지방비금액</th>
              <td>
                <br-form-input label="지방비금액" v-model="bizfaciltyBplc.lgscAmt" validator="number"/>
              </td>
            </tr>
            <tr v-if="conds.uncheck && !conds.income">
              <th scope="row">민자금액</th>
              <td>
                <br-form-input label="민자금액" v-model="bizfaciltyBplc.pvtcptAmt" validator="number"/>
              </td>
              <th scope="row">기타금액</th>
              <td>
                <br-form-input label="기타금액" v-model="bizfaciltyBplc.etcAmt" validator="number"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <h3 class="title2">첨부서류</h3>
        <br-attach-file-list label="사업장 첨부파일 목록"
                             ref="attachFileList"
                             v-model:atchFileTaskSeCd="atchFileTaskSeCd"
                             v-model:atchFileGroupId="this.bizfaciltyBplc.atchFileGroupId"
                             :maxFileCount="10"
                             :readonly="false"
                             :unusedAtchFileList="unusedAtchFileList"
                             :useEtcFile="true"
        />
        <div class="btn_area center">
        <br-button label="등록" format="primary" @click="createBplc" panel="panel1" validate/>
        <br-button label="닫기" class="popup_close " @click="hide"/>
        </div>
      </div>
    </div>
  </div>
  <address-popup @input-button-clicked="selectedAddress"/>
  <RsrcRcyclnFcltPopup @select-button-clicked="selectedRsrcRcyclnFcltPopup" :id="rsrcRcyclnFcltPopId"/>
</template>

<script>
import MbrFormTelno from "@/views/mbr-mng/components/MbrFormTelno.vue";
import {storeSession} from "@/js/store/store-session";
import {storeSwitch} from "@/js/store/store-switch";
import AddressPopup from "@/views/mbr-mng/components/MbrFormAddress.vue";
import BrFormRadio from "@/components/BrFormRadio.vue";
import BrDatePicker from "@/components/BrDatePicker.vue";
import RsrcRcyclnFcltPopup from "@/views/my-office/components/popup/RsrcRcyclnFcltPopup.vue";

const API = {
  BPLC_INFO: "/bizfacility/bplc-info",
};
const initialBizfaciltyBplc = {
  bplcId: '',
  bplcNm: '',
  engBplcNm: '',
  bzentyId: '',
  bplcKndCd: '',
  cnsgnOperMngYn: 'N',
  cnsgnBzentyNm: '',
  cnsgnBzentyBrno: '',
  cnsgnBzentyScndwpNo: '',
  picNm: '',
  telno: '',
  telno1: '',
  telno2: '',
  telno3: '',
  fxno: '',
  fxno1: '',
  fxno2: '',
  fxno3: '',
  untyZip: '',
  bplcAddr: '',
  bplcDaddr: '',
  ctpvStdgCd: '',
  sggStdgCd: '',
  cmptncRgnHqCd: '',
  lat: '',
  lot: '',
  snthsRecyBzentyYn: 'N',
  lastRecyBzentyYn: 'N',
  mdRecyBzentyYn: 'N',
  prcsFcltInstlDclrYn: 'N',
  atmoDschrgFcltInstlDclrYn: 'N',
  mdDspsBzentyYn: 'N',
  lastDspsBzentyYn: 'N',
  snthsDspsBzentyYn: 'N',
  instlOperPrmsnNo: '',
  instlOperPrmsnYmd: '',
  dclrPrmsnNo: '',
  dclrPrmsnYmd: '',
  atchFileGroupId: '',
  bplcSttsCd: '01',
  aprvYn: '',
  rsrcRcyclnFcltId: '',
  mngrMemoCn: '',
  userMemoCn: '',
  useYn: 'Y',
  nope: '',
  operAmt: '',
  rmrkCn: '',
  cnvncFcltKndNm: '',
  cnvncFcltInstlCst: '',
  cnvncFcltOperInstNm: '',
  cnvncFcltRmrkCn: '',
  tpcoAmt: '',
  bizBgngYmd: '',
  bizEndYmd: '',
  gvcfrAmt: '',
  lgscAmt: '',
  pvtcptAmt: '',
  etcAmt: '',
  fillAftrUsePlanCn: '',
  regDt: '',
  rgtrId: '',
  mdfcnDt: '',
  mdfrId: '',
  bplcCtpvCd: '',
  chgAplyPrcsSttsCd: '',
  dtlChgAplyPrcsSttsCd: '',
  prcsRsn: '',
  chgAplyTypeCd: '',
};

const initialUnusedAtchFileList = ['053', '054', '008'];

const  initialConds = {
  showWes: false,
  income: false,
  uncheck: false,
  showAllSrf: false,  //고형연료 제조, 사용
  showSrfMnf: false,  //고형연료 제조
  showSrfUse: false,  //고형연료 사용
};

export default {
  components: {
    RsrcRcyclnFcltPopup, BrDatePicker, BrFormRadio, AddressPopup, MbrFormTelno},
  props: {
    bzentyId: {type: Number, required: true},
    bplcMyMngBplcList : {type: Array, required: true},
  },
  emits: ['completed'],
  expose: [],
  data() {
    return {
      isOn: false,
      rsrcRcyclnFcltPopId: 'rsrcRcyclnFcltPopupForCreateBplc',
      atchFileTaskSeCd: '',
      sysAuthrtCd: storeSession.getMemberInfo().sysAuthrtCd,
      bizfaciltyBplc: {...initialBizfaciltyBplc},
      conds: {...initialConds},
      unusedAtchFileList: [...initialUnusedAtchFileList],
      uncheckList: [  // 미관리 종류
        '06-01',
        '07-01',
        '08-01',
        '09-01',
        '10-01',
      ],
      bplcKndCdList: [],
      bplcCtpvCdList: [],
      prdctnPrdctKndCdList: [],
      radioBtnYnList: [
        { value: "Y", text: "대상" },
        { value: "N", text: "비대상" },
      ],
    }
  },
  computed: {},
  watch: {
    isOn(newVal) {
      if(newVal){
        this.reset();
        this.initialize();
      }
    },
    'bizfaciltyBplc.cnsgnOperMngYn'(newVal) {
      if (newVal === 'N') {
        this.bizfaciltyBplc.cnsgnBzentyNm = '';
      }
    }
  },
  created() {
    storeSwitch.setBulb('createBplcPopup', this);
  },
  beforeMount() {},
  mounted() {},
  beforeUnmount() {},
  methods: {
    show() {
      this.isOn = true;

    },
    hide() {
      this.isOn = false;
    },
    initialize() {
      this.setConditions(this.bizfaciltyBplc.bplcKndCd);

      // 첨부파일 목록 갱신
      this.$refs.attachFileList.load(this.atchFileTaskSeCd, this.bizfaciltyBplc.atchFileGroupId);
    },
    callPopupAddress() {
      storeSwitch.on('addressPopup');
    },
    selectedAddress(data) {
      this.bizfaciltyBplc.untyZip = data.zipNo;
      this.bizfaciltyBplc.bplcAddr = data.roadAddrPart1;
      this.bizfaciltyBplc.bplcDaddr = data.roadDetailAddr;

      if(data.admCd) {
        const stdgCd = data.admCd;
        this.bizfaciltyBplc.ctpvStdgCd = stdgCd.slice(0, 2) + '0'.repeat(stdgCd.length - 2);
        this.bizfaciltyBplc.sggStdgCd = stdgCd.slice(0, 5) + '0'.repeat(stdgCd.length - 5);
      }
    },
    callRsrcRcyclnFcltPopup() {
      // alert('준비중 입니다.')
      storeSwitch.on(this.rsrcRcyclnFcltPopId);
    },
    onChangeBplcKnd() {
      let checkFlag = false;
      this.bplcMyMngBplcList.forEach((item) => {
        if(this.bizfaciltyBplc.bplcKndCd === item.bplcKndCd) {
          if(this.bizfaciltyBplc.bplcKndCd === "01-01" || this.bizfaciltyBplc.bplcKndCd === "01-02") {
            // 이미 가입된 사업장이 존재하며, 제조, 사용 시설인 경우
            checkFlag = true;
          }
        }
      });

      if(checkFlag){
        // true : 가입된 사업장 정보가 있음
        // false : 없음
        let rtnStr = "";
        switch(this.bizfaciltyBplc.bplcKndCd){
          case "01-01" : //제조시설
            rtnStr = "제조시설 사업장이 존재합니다.\n(제품 추가시 시설신규등록을 해야합니다.)\n\n신규 등록 하시겠습니까?";
            break;
          case "01-02" : //사용시설
            rtnStr = "사용시설 사업장이 존재합니다.\n(소각기 추가시 시설신규등록을 해야합니다.)\n\n신규 등록 하시겠습니까?";
            break;
        }
        if(!confirm(rtnStr)){
          this.hide();
        }
      }

      this.setConditions(this.bizfaciltyBplc.bplcKndCd);

      // 첨부파일 목록 갱신
      this.$refs.attachFileList.load(this.atchFileTaskSeCd, this.bizfaciltyBplc.atchFileGroupId);
    },
    setConditions(bplcKndCd){
      if(!bplcKndCd){
        return;
      }

      const mainCd = bplcKndCd.slice(0, 2);
      const subCd = bplcKndCd.slice(3, 5);

      // 초기화
      this.conds = {
        showWes: mainCd !== '01',
        uncheck: !this.uncheckList.includes(bplcKndCd),
        income: mainCd === '01' && subCd === '03',
        showAllSrf: mainCd === '01' ,  //고형연료 제조, 사용
        showSrfMnf: bplcKndCd === '01-01',  //고형연료 제조
        showSrfUse: bplcKndCd === '01-02',  //고형연료 사용
      };
      // 고형연료 및 수입연료 조건 처리
      if (mainCd === '01') {
        if (subCd === '01' || subCd === '02') {
          switch (subCd){
            case '01' :
              this.atchFileTaskSeCd = 'BPCA'
              break;
            case '02' :
              this.atchFileTaskSeCd = 'BPCC'
              break;
          }
          this.conds.income = false;
        } else if (subCd === '03') {
          this.conds.income = true;
          this.atchFileTaskSeCd = 'BPCD'
        }
      }else{
        this.atchFileTaskSeCd = 'BPCB';
      }
    },
    createBplc() {
      if(!this.validateTelno()){
        return;
      }
      if(confirm("등록하시겠습니까?")){
        this.bizfaciltyBplc.bzentyId = this.bzentyId;

        this.$refs.attachFileList.save(true, false).then(atchFileGroupId => {
          this.bizfaciltyBplc.atchFileGroupId = atchFileGroupId;
          this.bizfaciltyBplc.telno = `${this.bizfaciltyBplc.telno1}${this.bizfaciltyBplc.telno2}${this.bizfaciltyBplc.telno3}`
          this.bizfaciltyBplc.fxno = `${this.bizfaciltyBplc?.fxno1 || ''}${this.bizfaciltyBplc?.fxno2 || ''}${this.bizfaciltyBplc?.fxno3 || ''}`
          this.bizfaciltyBplc.prcsRsn = '신규 등록'
          this.bizfaciltyBplc.chgAplySeCd = '01';             // '01' 승인신청 -> 변경신청구분코드
          this.bizfaciltyBplc.chgAplyPrcsSttsCd = '01';       // '01' 승인대기 -> 변경신청처리상태코드
          this.bizfaciltyBplc.dtlChgAplyPrcsSttsCd = '01';    // '01' 승인대기 -> 상세변경신청처리상태코드
          this.bizfaciltyBplc.chgAplyTypeCd = '02';           // 변경신청유형코드  -> 02 사업장

          this.$apiCall.post(API.BPLC_INFO, this.bizfaciltyBplc, () => {
                alert("정상적으로 등록되었습니다.\n 시스템 이용을 위해서는 관리자의 승인이 필요합니다.");
                this.reset();
                this.$emit('completed',)
                this.isOn = false;
              },
              () => {
                alert('요청에 실패하였습니다.');
              }
          );
        });
      }
    },
    setUnusedAtchFileList(){
      if(this.bizfaciltyBplc.cnsgnOperMngYn === 'N'){
        this.unusedAtchFileList = [...initialUnusedAtchFileList];
      }else{
        this.unusedAtchFileList = [];
      }
      this.$refs.attachFileList.load(this.atchFileTaskSeCd, '')
    },
    selectedRsrcRcyclnFcltPopup(data){
      if( data.bplcId ) this.bizfaciltyBplc.rsrcRcyclnFcltId = data.bplcId;
      else this.bizfaciltyBplc.rsrcRcyclnFcltId = '';
    },
    reset() {
      this.bizfaciltyBplc = {...initialBizfaciltyBplc};
      this.conds = {...initialConds};
    },
    validateTelno() {
      const telnoInput = this.$refs.telnoInput;
      if (telnoInput && !telnoInput.isValid()) {
        alert("전화번호를 올바르게 입력해 주세요.")
        return false;
      }
      const fxnoInput = this.$refs.fxnoInput;
      if (this.bizfaciltyBplc.fxno && !fxnoInput.isValid()) {
        alert("팩스번호를 올바르게 입력해 주세요.")
        return false ;
      }
      return true;
    },
  }
}
</script>

<style scoped>

</style>
