<template>
  <div v-show="isVisible" class="modal visible">
    <div class="popup_box" tabindex="0">
      <h1 class="popup_title">동영상 업무 매뉴얼</h1>
      <div class="popup_cont manualCont">
        <div class="tab_area">
          <ul class="tab">
            <li :class="{ active: activeTab === 0}">
              <button type="button" class="btn_tab" @click="setActiveTab(0)">
                고형연료
                <i v-if="activeTab === 0" class="sr_only"></i>
              </button>
            </li>
            <li :class="{ active: activeTab === 1}">
              <button type="button" class="btn_tab" @click="setActiveTab(1)">
                매립가스
                <i v-if="activeTab === 1" class="sr_only"></i>
              </button>
            </li>
            <li :class="{ active: activeTab === 2}">
              <button type="button" class="btn_tab" @click="setActiveTab(2)">
                소각(소각열회수)
                <i v-if="activeTab === 2" class="sr_only"></i>
              </button>
            </li>
            <li :class="{ active: activeTab === 3}">
              <button type="button" class="btn_tab" @click="setActiveTab(3)">
                바이오가스
                <i v-if="activeTab === 3" class="sr_only"></i>
              </button>
            </li>
            <li :class="{ active: activeTab === 4}" style="margin-right: 100px">
              <button type="button" class="btn_tab" @click="setActiveTab(4)">
                폐기물합성가스
                <i v-if="activeTab === 4" class="sr_only"></i>
              </button>
            </li>
            <li :class="{ active: activeTab === 5}">
              <button type="button" class="btn_tab" @click="setActiveTab(5)">
                열분해유
                <i v-if="activeTab === 5" class="sr_only"></i>
              </button>
            </li>
            <li :class="{ active: activeTab === 6}">
              <button type="button" class="btn_tab" @click="setActiveTab(6)">
                재생연료유
                <i v-if="activeTab === 6" class="sr_only"></i>
              </button>
            </li>
            <li :class="{ active: activeTab === 7}">
              <button type="button" class="btn_tab" @click="setActiveTab(7)">
                정재연료유
                <i v-if="activeTab === 7" class="sr_only"></i>
              </button>
            </li>
            <li :class="{ active: activeTab === 8}">
              <button type="button" class="btn_tab" @click="setActiveTab(8)">
                시멘트소성로 보조연료
                <i v-if="activeTab === 8" class="sr_only"></i>
              </button>
            </li>
            <li :class="{ active: activeTab === 9}">
              <button type="button" class="btn_tab" @click="setActiveTab(9)">
                유기성오니 보조연료
                <i v-if="activeTab === 9" class="sr_only"></i>
              </button>
            </li>
          </ul>

          <div class="tab_conts_wrap">
            <section v-show="activeTab === 0" class="tab_conts">
              <div class="movListArea">
                <div class="movArea">
                  <video width="100%" controls autoplay :src="videoSrc">
                    동영상을 지원하지 않는 브라우저에서는 해당 텍스트가 표시됩니다.
                  </video>
                </div>
                <ul class="movList">
                  <li><strong>고형연료 (제조)</strong></li>
                  <li :class="{ active: activeVideo === 0}"><button type="button" @click="setActiveVideo(0)">1. 반입현황</button></li>
                  <li :class="{ active: activeVideo === 1}"><button type="button" @click="setActiveVideo(1)">2. 투입/생산현황</button></li>
                  <li :class="{ active: activeVideo === 2}"><button type="button" @click="setActiveVideo(2)">3. 판매현황</button></li>
                  <li><strong>고형연료 (사용)</strong></li>
                  <li :class="{ active: activeVideo === 3}"><button type="button" @click="setActiveVideo(3)">1. 반입현황</button></li>
                  <li :class="{ active: activeVideo === 5}"><button type="button" @click="setActiveVideo(5)">2. 투입/생산현황</button></li>
                  <li :class="{ active: activeVideo === 4}"><button type="button" @click="setActiveVideo(4)">3. 이용/판매현황</button></li>
                </ul>
              </div>
              <div class="movSynop">
                <strong>고형연료제조</strong>
                <ol class="blue_dot_list">
                  <li>
                    반입현황
                    <ol class="dot_list">
                      <li>시도와 폐기물 항목 정보를 선택 후 반입량을 입력합니다.</li>
                      <li>입력된 반입량은 시도별로 합산되어 자동 계산됩니다.</li>
                    </ol>
                    <h3><span>* 반입 시도는 반입/판매업체현황 메뉴, 폐기물 항목은 마이오피스에서 관리할 수 있습니다.</span></h3>
                  </li>
                  <li>
                    투입/생산현황
                    <ol class="dot_list">
                      <li>원재료의 이월/반입/투입/잔량을 조회합니다.</li>
                      <li>투입된 원재료의 투입정보를 입력합니다.</li>
                      <li>생산된 고형연료제품의 생산정보를 입력합니다.</li>
                    </ol>
                  </li>
                   <li>
                    판매현황
                    <ol class="dot_list">
                      <li>사업장별 판매현황을 입력합니다.</li>
                      <li>판매량 검증은 판매사업장에서 반입정보를 등록한 경우, 검증 판매량이 조회되고 둘의 차이량을 비교합니다.</li>
                      <li>고형연료제품의 판매정보를 입력합니다.</li>
                      <li>고형연료제품의 판매정보를 입력합니다.</li>
                    </ol>
                    <h3><span>* 판매 사업장은 반입/판매업체현황 메뉴에서 관리할 수 있습니다.</span></h3>
                  </li>
                </ol> <br/>
                <strong>고형연료사용</strong>
                <ol class="blue_dot_list">
                  <li>
                    반입현황
                    <ol class="dot_list">
                      <li>사업장별 고형연료제품의 인수량을 입력합니다.</li>
                      <li>반입 사업장에서 판매정보를 등록한 경우, 실시간 인계량이 조회되고 그 차이량을 비교합니다.</li>
                    </ol>
                  </li>
                  <li>
                    투입/생산현황
                    <ol class="dot_list">
                      <li>시설별 고형연료제품의 투입량과 에너지 생산량을 입력합니다.</li>
                      <li>고형연료제품의 종류별 현황 정보를 조회합니다.</li>
                      <li>시설에 투입된 보조연료 정보를 입력합니다.</li>
                    </ol>
                  </li>
                  <li>
                    이용/판매현황
                    <ol class="dot_list">
                      <li>시설별 에너지의 이용량을 입력합니다.(증기/온수/전기의 각 자체이용/외부판매량)</li>
                      <li>판매업체별 판매량을 입력합니다.</li>
                    </ol>
                  </li>
                </ol> <br/>
                <strong>회수통계조사</strong>
                <ol class="blue_dot_list">
                  <li>
                    메인 → 실적보고 → 회수통계조사
                    <ol class="dot_list">
                      <li>사업장의 시설/제품별로 조사가 이뤄집니다.</li>
                      <li>에너지원별로 조사항목이 다르며, 실적 보고를 진행하는 에너지원은 1년간 보고한 실적 정보의 합계를 회수통계 조사표에 먼저 보여드리고 있습니다.</li>
                      <li>실적 보고 누락 자료가 있거나 수정할 정보를 보정하시고 “제출” 하시면 됩니다.</li>
                      <li>실적 보고를 하지 않는 에너지원은 기초 자료 제공은 없으며 정보를 기입하고 “제출” 하시면 됩니다.</li>
                    </ol>
                  </li>
                </ol>
              </div>
            </section>
            <section v-show="activeTab === 1" class="tab_conts">
              <div class="movListArea">
                <div class="movArea">
                  <video width="100%" controls autoplay :src="videoSrc">
                    동영상을 지원하지 않는 브라우저에서는 해당 텍스트가 표시됩니다.
                  </video>
                </div>
                <ul class="movList">
                  <li><strong>매립가스</strong></li>
                  <li :class="{ active: activeVideo === 0}"><button type="button" @click="setActiveVideo(0)">1. 반입현황</button></li>
                  <li :class="{ active: activeVideo === 1}"><button type="button" @click="setActiveVideo(1)">2. 생산이용현황</button></li>
                </ul>
              </div>
              <div class="movSynop">
                <strong>매립가스</strong>
                <ol class="blue_dot_list">
                  <li>
                    반입현황
                    <ol class="dot_list">
                      <li>반입현황 목록에서 직접 입력하거나, 자원순환정보 시스템에 등록된 반입정보가 존재하는 경우는 정보적용 버튼을 클릭하여 한번에 적용할 수 있습니다.</li>
                      <li>자원순환정보시스템의 업체를 저장해야만 반입정보를 연계할 수 있습니다. </li>
                    </ol>
                    <h3><span>* 자원순환시스템ID는 마이오피스>사업장 정보에서 관리할 수 있습니다.</span></h3>
                  </li>
                  <li>
                    이용/판매현황
                    <ol class="dot_list">
                      <li>에너지의 이용(자체이용/외부판매)량을 입력합니다.</li>
                      <li>판매업체별 판매량을 입력합니다.</li>
                    </ol>
                  </li>
                </ol> <br/>
                <strong>회수통계조사</strong>
                <ol class="blue_dot_list">
                  <li>
                    메인 → 실적보고 → 회수통계조사
                    <ol class="dot_list">
                      <li>사업장의 시설/제품별로 조사가 이뤄집니다.</li>
                      <li>에너지원별로 조사항목이 다르며, 실적 보고를 진행하는 에너지원은 1년간 보고한 실적 정보의 합계를 회수통계 조사표에 먼저 보여드리고 있습니다.</li>
                      <li>실적 보고 누락 자료가 있거나 수정할 정보를 보정하시고 “제출” 하시면 됩니다.</li>
                      <li>실적 보고를 하지 않는 에너지원은 기초 자료 제공은 없으며 정보를 기입하고 “제출” 하시면 됩니다.</li>
                    </ol>
                  </li>
                </ol>
              </div>
            </section>
            <section v-show="activeTab === 2" class="tab_conts">
              <div class="movListArea">
                <div class="movArea">
                  <video width="100%" controls autoplay :src="videoSrc">
                    동영상을 지원하지 않는 브라우저에서는 해당 텍스트가 표시됩니다.
                  </video>
                </div>
                <ul class="movList">
                  <li><strong>소각</strong></li>
                  <li :class="{ active: activeVideo === 0}"><button type="button" @click="setActiveVideo(0)">1. 반입현황</button></li>
                  <li :class="{ active: activeVideo === 1}"><button type="button" @click="setActiveVideo(2)">2. 투입현황</button></li>
                  <li :class="{ active: activeVideo === 1}"><button type="button" @click="setActiveVideo(1)">3. 생산이용현황</button></li>
                </ul>
              </div>
              <div class="movSynop">
                <strong>소각(소각열회수)</strong>
                <ol class="blue_dot_list">
                  <li>
                    반입현황
                    <ol class="dot_list">
                      <li>반입현황 목록에서 직접 입력하거나, 자원순환정보 시스템에 등록된 반입정보가 존재하는 경우는 정보적용 버튼을 클릭하여 한번에 적용할 수 있습니다.</li>
                      <li>자원순환정보시스템의 업체를 저장해야만 반입정보를 연계할 수 있습니다. </li>
                    </ol>
                    <h3><span>* 자원순환시스템ID는 마이오피스>사업장 정보에서 관리할 수 있습니다.</span></h3>
                  </li>
                  <li>
                    투입/생산현황
                    <ol class="dot_list">
                      <li>시설별 폐기물의 투입량과 에너지 생산량을 입력합니다.</li>
                      <li>시설에 투입된 보조연료 정보를 입력합니다.</li>
                    </ol>
                  </li>
                  <li>
                    이용/판매현황
                    <ol class="dot_list">
                      <li>시설별 에너지의 이용량을 입력합니다.(증기/온수/전기의 각 자체이용/외부판매량)</li>
                      <li>판매업체별 판매량을 입력합니다.</li>
                    </ol>
                  </li>
                </ol> <br/>
                <strong>회수통계조사</strong>
                <ol class="blue_dot_list">
                  <li>
                    메인 → 실적보고 → 회수통계조사
                    <ol class="dot_list">
                      <li>사업장의 시설/제품별로 조사가 이뤄집니다.</li>
                      <li>에너지원별로 조사항목이 다르며, 실적 보고를 진행하는 에너지원은 1년간 보고한 실적 정보의 합계를 회수통계 조사표에 먼저 보여드리고 있습니다.</li>
                      <li>실적 보고 누락 자료가 있거나 수정할 정보를 보정하시고 “제출” 하시면 됩니다.</li>
                      <li>실적 보고를 하지 않는 에너지원은 기초 자료 제공은 없으며 정보를 기입하고 “제출” 하시면 됩니다.</li>
                    </ol>
                  </li>
                </ol>
              </div>
            </section>
            <section v-show="activeTab === 3" class="tab_conts">
              <div class="movListArea">
                <div class="movArea">
                  <video width="100%" controls autoplay :src="videoSrc">
                    동영상을 지원하지 않는 브라우저에서는 해당 텍스트가 표시됩니다.
                  </video>
                </div>
                <ul class="movList">
                  <li><strong>바이오가스</strong></li>
                  <li :class="{ active: activeVideo === 0}"><button type="button" @click="setActiveVideo(0)">1. 반입현황</button></li>
                  <li :class="{ active: activeVideo === 1}"><button type="button" @click="setActiveVideo(2)">2. 가동현황</button></li>
                  <li :class="{ active: activeVideo === 1}"><button type="button" @click="setActiveVideo(1)">3. 이용/판매현황</button></li>
                </ul>
              </div>
              <div class="movSynop">
                <strong>바이오가스</strong>
                <ol class="blue_dot_list">
                  <li>
                    반입현황
                    <ol class="dot_list">
                      <li>시도/시군구별 폐기물 종류의 반입량을 입력합니다.</li>
                      <li>반입량을 투입량으로 적용 버튼 클릭 시, 투입량이 자동입력 됩니다.</li>
                    </ol>
                    <h3><span>* 투입 폐기물 항목은 마이오피스에서 관리할 수 있습니다</span></h3>
                  </li>
                  <li>
                    가동현황
                    <ol class="dot_list">
                      <li>가동현황 정보를 입력합니다.</li>
                      <li>시설에 투입된 보조연료 정보를 입력합니다.</li>
                    </ol>
                  </li>
                  <li>
                    이용/판매현황
                    <ol class="dot_list">
                      <li>에너지의 이용(자체이용/외부판매)량을 입력합니다.</li>
                      <li>판매업체별 판매량을 입력합니다.</li>
                    </ol>
                  </li>
                </ol> <br/>
                <strong>회수통계조사</strong>
                <ol class="blue_dot_list">
                  <li>
                    메인 → 실적보고 → 회수통계조사
                    <ol class="dot_list">
                      <li>사업장의 시설/제품별로 조사가 이뤄집니다.</li>
                      <li>에너지원별로 조사항목이 다르며, 실적 보고를 진행하는 에너지원은 1년간 보고한 실적 정보의 합계를 회수통계 조사표에 먼저 보여드리고 있습니다.</li>
                      <li>실적 보고 누락 자료가 있거나 수정할 정보를 보정하시고 “제출” 하시면 됩니다.</li>
                      <li>실적 보고를 하지 않는 에너지원은 기초 자료 제공은 없으며 정보를 기입하고 “제출” 하시면 됩니다.</li>
                    </ol>
                  </li>
                </ol>
              </div>
            </section>
            <section v-show="activeTab === 4" class="tab_conts">
              <div class="movListArea">
                <div class="movArea">
                  <video width="100%" controls autoplay :src="videoSrc">
                    동영상을 지원하지 않는 브라우저에서는 해당 텍스트가 표시됩니다.
                  </video>
                </div>
                <ul class="movList">
                  <li><strong>폐기물합성가스</strong></li>
                  <li :class="{ active: activeVideo === 0}"><button type="button" @click="setActiveVideo(0)">실적보고</button></li>
                </ul>
              </div>
              <div class="movSynop">
                <strong>폐기물합성가스</strong>
                <ol class="blue_dot_list">
                  <li>시도/시군구별 폐기물 종류의 반입량을 입력합니다.</li>
                  <li>생산 및 이용, 판매정보를 입력합니다.</li>
                </ol> <br/>
                <strong>회수통계조사</strong>
                <ol class="blue_dot_list">
                  <li>
                    메인 → 실적보고 → 회수통계조사
                    <ol class="dot_list">
                      <li>사업장의 시설/제품별로 조사가 이뤄집니다.</li>
                      <li>에너지원별로 조사항목이 다르며, 실적 보고를 진행하는 에너지원은 1년간 보고한 실적 정보의 합계를 회수통계 조사표에 먼저 보여드리고 있습니다.</li>
                      <li>실적 보고 누락 자료가 있거나 수정할 정보를 보정하시고 “제출” 하시면 됩니다.</li>
                      <li>실적 보고를 하지 않는 에너지원은 기초 자료 제공은 없으며 정보를 기입하고 “제출” 하시면 됩니다.</li>
                    </ol>
                  </li>
                </ol>
              </div>
            </section>
            <section v-show="activeTab === 5" class="tab_conts">
              <div class="movListArea">
                <div class="movArea">
                  <video width="100%" controls autoplay :src="videoSrc">
                    동영상을 지원하지 않는 브라우저에서는 해당 텍스트가 표시됩니다.
                  </video>
                </div>
                <ul class="movList">
                  <li><strong>회수통계</strong></li>
                  <li :class="{ active: activeVideo === 0}"><button type="button" @click="setActiveVideo(0)">회수통계</button></li>
                </ul>
              </div>
              <div class="movSynop">
                <!-- 열분해유 -->
                <strong>회수통계조사</strong>
                <ol class="blue_dot_list">
                  <li>
                    메인 → 실적보고 → 회수통계조사
                    <ol class="dot_list">
                      <li>사업장의 시설/제품별로 조사가 이뤄집니다.</li>
                      <li>에너지원별로 조사항목이 다르며, 실적 보고를 진행하는 에너지원은 1년간 보고한 실적 정보의 합계를 회수통계 조사표에 먼저 보여드리고 있습니다.</li>
                      <li>실적 보고 누락 자료가 있거나 수정할 정보를 보정하시고 “제출” 하시면 됩니다.</li>
                      <li>실적 보고를 하지 않는 에너지원은 기초 자료 제공은 없으며 정보를 기입하고 “제출” 하시면 됩니다.</li>
                    </ol>
                  </li>
                </ol>
              </div>
            </section>
            <section v-show="activeTab === 6" class="tab_conts">
              <div class="movListArea">
                <div class="movArea">
                  <video width="100%" controls autoplay :src="videoSrc">
                    동영상을 지원하지 않는 브라우저에서는 해당 텍스트가 표시됩니다.
                  </video>
                </div>
                <ul class="movList">
                  <li><strong>회수통계</strong></li>
                  <li :class="{ active: activeVideo === 0}"><button type="button" @click="setActiveVideo(0)">회수통계</button></li>
                </ul>
              </div>
              <div class="movSynop">
                <!-- 재생연료유 -->
                <strong>회수통계조사</strong>
                <ol class="blue_dot_list">
                  <li>
                    메인 → 실적보고 → 회수통계조사
                    <ol class="dot_list">
                      <li>사업장의 시설/제품별로 조사가 이뤄집니다.</li>
                      <li>에너지원별로 조사항목이 다르며, 실적 보고를 진행하는 에너지원은 1년간 보고한 실적 정보의 합계를 회수통계 조사표에 먼저 보여드리고 있습니다.</li>
                      <li>실적 보고 누락 자료가 있거나 수정할 정보를 보정하시고 “제출” 하시면 됩니다.</li>
                      <li>실적 보고를 하지 않는 에너지원은 기초 자료 제공은 없으며 정보를 기입하고 “제출” 하시면 됩니다.</li>
                    </ol>
                  </li>
                </ol>
              </div>
            </section>
            <section v-show="activeTab === 7" class="tab_conts">
              <div class="movListArea">
                <div class="movArea">
                  <video width="100%" controls autoplay :src="videoSrc">
                    동영상을 지원하지 않는 브라우저에서는 해당 텍스트가 표시됩니다.
                  </video>
                </div>
                <ul class="movList">
                  <li><strong>회수통계</strong></li>
                  <li :class="{ active: activeVideo === 0}"><button type="button" @click="setActiveVideo(0)">회수통계</button></li>
                </ul>
              </div>
              <div class="movSynop">
                <!-- 정재연료유 설명 -->
                <strong>회수통계조사</strong>
                <ol class="blue_dot_list">
                  <li>
                    메인 → 실적보고 → 회수통계조사
                    <ol class="dot_list">
                      <li>사업장의 시설/제품별로 조사가 이뤄집니다.</li>
                      <li>에너지원별로 조사항목이 다르며, 실적 보고를 진행하는 에너지원은 1년간 보고한 실적 정보의 합계를 회수통계 조사표에 먼저 보여드리고 있습니다.</li>
                      <li>실적 보고 누락 자료가 있거나 수정할 정보를 보정하시고 “제출” 하시면 됩니다.</li>
                      <li>실적 보고를 하지 않는 에너지원은 기초 자료 제공은 없으며 정보를 기입하고 “제출” 하시면 됩니다.</li>
                    </ol>
                  </li>
                </ol>
              </div>
            </section>
            <section v-show="activeTab === 8" class="tab_conts">
              <div class="movListArea">
                <div class="movArea">
                  <video width="100%" controls autoplay :src="videoSrc">
                    동영상을 지원하지 않는 브라우저에서는 해당 텍스트가 표시됩니다.
                  </video>
                </div>
                <ul class="movList">
                  <li><strong>회수통계</strong></li>
                  <li :class="{ active: activeVideo === 0}"><button type="button" @click="setActiveVideo(0)">회수통계</button></li>
                </ul>
              </div>
              <div class="movSynop">
                <!-- 시멘트소성로 보조연료 설명 -->
                <strong>회수통계조사</strong>
                <ol class="blue_dot_list">
                  <li>
                    메인 → 실적보고 → 회수통계조사
                    <ol class="dot_list">
                      <li>사업장의 시설/제품별로 조사가 이뤄집니다.</li>
                      <li>에너지원별로 조사항목이 다르며, 실적 보고를 진행하는 에너지원은 1년간 보고한 실적 정보의 합계를 회수통계 조사표에 먼저 보여드리고 있습니다.</li>
                      <li>실적 보고 누락 자료가 있거나 수정할 정보를 보정하시고 “제출” 하시면 됩니다.</li>
                      <li>실적 보고를 하지 않는 에너지원은 기초 자료 제공은 없으며 정보를 기입하고 “제출” 하시면 됩니다.</li>
                    </ol>
                  </li>
                </ol>
              </div>
            </section>
            <section v-show="activeTab === 9" class="tab_conts">
              <div class="movListArea">
                <div class="movArea">
                  <video width="100%" controls autoplay :src="videoSrc">
                    동영상을 지원하지 않는 브라우저에서는 해당 텍스트가 표시됩니다.
                  </video>
                </div>
                <ul class="movList">
                  <li><strong>회수통계</strong></li>
                  <li :class="{ active: activeVideo === 0}"><button type="button" @click="setActiveVideo(0)">회수통계</button></li>
                </ul>
              </div>
              <div class="movSynop">
                <!-- 유기성오니 보조연료 설명 -->
                <strong>회수통계조사</strong>
                <ol class="blue_dot_list">
                  <li>
                    메인 → 실적보고 → 회수통계조사
                    <ol class="dot_list">
                      <li>사업장의 시설/제품별로 조사가 이뤄집니다.</li>
                      <li>에너지원별로 조사항목이 다르며, 실적 보고를 진행하는 에너지원은 1년간 보고한 실적 정보의 합계를 회수통계 조사표에 먼저 보여드리고 있습니다.</li>
                      <li>실적 보고 누락 자료가 있거나 수정할 정보를 보정하시고 “제출” 하시면 됩니다.</li>
                      <li>실적 보고를 하지 않는 에너지원은 기초 자료 제공은 없으며 정보를 기입하고 “제출” 하시면 됩니다.</li>
                    </ol>
                  </li>
                </ol>
              </div>
            </section>
          </div>
        </div>

        <div class="btn_area right">
          <br-button label="닫기" size="md" @click="hide" />
        </div>
      </div>
      <button type="button" class="modal_close" @click="hide">팝업 닫기</button>
    </div>
  </div>
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";

export default {
  components: {},
  data() {
    return {
      isVisible: false,
      activeTab: 0,
      activeVideo: 0,
      basePath: `${process.env.VUE_APP_IMAGE_RESOURCE}/api/image`,
      videoSources: {
        0: [
            "mnftrt1.mp4", // 고형연료제조 반입현황
            "mnftrt2.mp4", // 고형연료제조 투입생산현황
            "mnftrt3.mp4", // 고형연료제조 판매현황
            "use1.mp4", // 고형연료사용 반입현황
            "use2.mp4", // 고형연료사용 에너지이용현황
            "use3.mp4", // 고형연료사용 투입생산현황
        ],
        1: [
            "fillgas1.mp4", // 매립가스 반입현황
            "fillgas2.mp4", // 매립가스 생산이용현황
        ],
        2: [
            "fire1.mp4", // 소각 반입현황
            "fire2.mp4", // 소각 에너지생산이용현황
            "fire3.mp4", // 소각 투입현황
        ],
        3: [
            "biogas1.mp4", // 바이오가스 반입현황
            "biogas2.mp4", // 바이오가스 에너지생산이용현황
            "biogas3.mp4", // 바이오가스 투입현황
        ],
        4: [
            "synthgas1.mp4", // 폐기물합성가스
        ],
        5: [
            "rtrvl1.mp4", // 회수통계
        ],
        6: [
          "rtrvl1.mp4", // 회수통계
        ],
        7: [
          "rtrvl1.mp4", // 회수통계
        ],
        8: [
          "rtrvl1.mp4", // 회수통계
        ],
        9: [
          "rtrvl1.mp4", // 회수통계
        ],
      }
    };
  },
  computed: {
    videoSrc() {
      const videoPath = this.videoSources[this.activeTab]?.[this.activeVideo] || "";
      return videoPath ? `${this.basePath}/${videoPath}` : "";
    }
  },
  watch: {},
  created() {
    storeSwitch.setBulb('VideoManualPopup', this);
  },
  mounted() {
  },
  methods: {
    show(tabIndex) {
      if (typeof tabIndex === "number") {
        this.setActiveTab(tabIndex);
      }
      this.isVisible = true;
      document.documentElement.style.overflow = 'hidden';
    },
    hide() {
      this.isVisible = false;
      document.documentElement.style.overflow = '';
    },
    setActiveTab(index) {
      this.activeTab = index;
      this.activeVideo = 0;
    },
    setActiveVideo(index) {
      this.activeVideo = index;
    }
  }
}
</script>

<style scoped>
  .movSynop ol {margin: 0}
  .manualCont .tab_conts .movList {height: 30rem; overflow-y: scroll;}
  .movSynop li span {
    display: inline-block;
    line-height: 1;
    color: var(--secondary40);
    font-size: 1.5rem;
    font-weight: 400;
  }
</style>