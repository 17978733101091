<template>
  <div v-show="isVisible" class="modal visible">
    <div class="popup_box full" tabindex="0">
      <h1 class="popup_title">
        {{ bplcKndCdText }}업체 조회
<!--        <span v-if="bplcKndCd === '01-01'">(제조사업장)</span>
        <span v-if="bplcKndCd === '01-02'">(사용사업장)</span>-->
      </h1>
      <div class="popup_cont">
        <div class="sch_form_wrap">
          <div class="sch_form row2">
            <span>
              <label>시·도</label>
              <br-form-select label="시·도" v-model:value="searchParam.ctpvStdgCd" :options="ctpvCdList" allSelectable @change="search" />
            </span>
            <span>
              <label>시·군·구</label>
              <br-form-select label="시·군·구" v-model:value="searchParam.sggStdgCd" :options="filteredSggCdList" allSelectable @change="search" />
            </span>
            <span style="flex: 0 0 87%;">
              <label>사업장명</label>
              <br-form-input label="사업장명" v-model="searchParam.bplcNmList" @enter-keyup="search"
                             placeholder="사업장명을 입력하세요. 여러 개 검색 시 쉼표로 구분하세요.(예: 사업장명1,사업장명2,사업장명3)"
              />
            </span>
            <span>
              <label>사업장상태</label>
              <br-form-select label="사업장상태" v-model:value="searchParam.bplcSttsCd" :options="bplcSttsList" allSelectable @change="search" />
            </span>
            <span>
              <label>등록여부</label>
              <br-form-radio label="등록여부" v-model:value="searchParam.regYn" :options="regYnOptions" allSelectable @selected="search"/>
            </span>
          </div>
          <div class="btn_area">
            <br-anchor-button label="검색" size="sm" format="sch" @click="search" />
            <br-anchor-button label="새로고침" size="sm" format="txtN" icon="ico_refresh" @click="reset" />
          </div>
        </div>
        <div class="grid_area">
          <div class="board_info">
            <h3 class="title2">사업장 현황</h3>
          </div>
          <sb-grid
              id="bplcGrid"
              :dataSource="bplcDataSource"
              :columns="bplcGridColumns"
              :gridattr="bplcGridAttr"
              :param="searchParam"
              v-model:grid="bplcGridObject"
              @loaded="bplcGridLoaded"
              :refreshGrid="bplcGridReload"
              fileName="사업장 현황"
          />
        </div>
        <div class="btn_area right">
          <br-button :label="bplcKndCdText + '업체등록'" size="md" format="primary" @click="selectBplc" />
          <br-button label="닫기" size="md" @click="hide" />
        </div>
      </div>
      <button type="button" class="modal_close" @click="hide">팝업 닫기</button>
    </div>
  </div>
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";
import SbGrid from "@/components/SbGrid.vue";

const API = {
  USE_MNFTR_BPLC : "/useMnftr/bplc"
};
const CODE = {
  BPLC_STTS : "MBR004", // 사업장상태코드
};

export default {
  components: {SbGrid},
  props: ['bplcKndCd'],
  emits: ['select-bplc'],
  data() {
    return {
      isVisible: false,
      searchParam: {
        ctpvStdgCd : "",
        sggStdgCd: "",
        bplcSttsCd: "",
        bplcNmList: "",
        bplcKndCd: this.bplcKndCd,
        regYn: "",
        bplcId: "",
      },
      ctpvCdList: [],
      sggCdList: [],
      bplcSttsList: [],
      bplcGridReload: false,
      bplcGridObject: null,
      bplcDataSource: {
        ajaxConfig: {
          select: { url: API.USE_MNFTR_BPLC },
          paging: true,
        },
        schema: {
          id: 'bplcId'
        }
      },
      bplcGridColumns: [],
      bplcGridAttr: {
        checkable: true,
        pageable: true,
        mergeRow: true,
      },
      regYnOptions : [
        {value : "Y", text : "등록"},
        {value : "N", text : "미등록"},
      ],
    };
  },
  computed: {
    filteredSggCdList() {
      return this.sggCdList.filter(item => item.upSggStdgCd === this.searchParam.ctpvStdgCd);
    },
    bplcKndCdText() {
      if (this.bplcKndCd === '01-01') {
        return "반입";
      } else if (this.bplcKndCd === '01-02') {
        return "판매";
      } else {
        return "";
      }
    }
  },
  watch: {},
  created() {
    storeSwitch.setBulb('BplcPopup', this);
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loadCtpvCd();
      this.loadSggCd();
      this.loadBplcSttsCd();
      this.initializeBplcGridColumns();
      this.initializeBplcGridAttr();
    },
    loadCtpvCd() {
      const ctpvList = this.$store.getters.getSggList({ type: 'ctpv' });
      this.ctpvCdList = ctpvList.map(item => ({
        text: item.sggNm,
        value: item.sggStdgCd
      }));
    },
    loadSggCd() {
      const sggList = this.$store.getters.getSggList({ type: 'sgg' });
      this.sggCdList = sggList.map(item => ({
        text: item.sggNm,
        value: item.sggStdgCd,
        upSggStdgCd: item.upSggStdgCd
      }));
    },
    loadBplcSttsCd() {
      const bplcSttsList = this.$store.getters.getCodeList({ groupCd: CODE.BPLC_STTS, useYn: 'Y' });
      this.bplcSttsList = bplcSttsList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    initializeBplcGridColumns() {
      this.bplcGridColumns = [
        { field: 'bplcId', caption: '사업장아이디', visible: false },
        { field: 'ctpvStdgCd',
          caption: '시·도',
          type: 'combo',
          items: this.ctpvCdList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 115,
          colCss: (data) => {
            if (this.searchParam.ctpvStdgCd && data.ctpvStdgCd && data.ctpvStdgCd === this.searchParam.ctpvStdgCd) {
              return 'grid-warning';
            } else {
              return '';
            }
          },
          mergeRow:true,
          exportLabel : true
        },
        { field: 'sggStdgCd',
          caption: '시·군·구',
          type: 'combo',
          items: this.sggCdList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 115,
          colCss: (data) => {
            if (this.searchParam.sggStdgCd && data.sggStdgCd && data.sggStdgCd === this.searchParam.sggStdgCd) {
              return 'grid-warning';
            } else {
              return '';
            }
          },
          mergeRow:true,
          exportLabel : true
        },
        { field: 'bplcNm', caption: '사업장명', minWidth: 120, width: 100, unit:'%', align: 'left',
          colCss: (data) => {
            if (this.searchParam.bplcNm && data.bplcNm && data.bplcNm.includes(this.searchParam.bplcNm)) {
              return 'grid-warning';
            } else {
              return '';
            }
          },
          placeHolder: (data) => { return data.bzentyNm ? '' : '필수입력'; },
          placeHolderCss: (data) => { return data.bzentyNm ? '' : 'not-good'; },
        },
        { field: 'prdctnPrdctKnds', caption: '생산제품', width: 300, align: 'left',
          visible: this.bplcKndCd === '01-01'
        },
        { field: 'inputItems', caption: '투입품목', width: 320, align: 'left',
          visible: this.bplcKndCd === '01-02'
        },
        { field: 'mdftrRegYn', caption: '등록여부', width: 100, visible: this.bplcKndCd === '01-01',
          type: 'combo',
          items: this.regYnOptions,
          comboLabel: 'text',
          comboValue: 'value',
          colCss: (data) => {
            if (data.mdftrRegYn === 'Y') {
              return 'bg-blue-light';
            } else {
              return '';
            }
          },
          exportLabel : true
        },
        { field: 'useRegYn', caption: '등록여부', width: 100, visible: this.bplcKndCd === '01-02',
          type: 'combo',
          items: this.regYnOptions,
          comboLabel: 'text',
          comboValue: 'value',
          colCss: (data) => {
            if (data.useRegYn === 'Y') {
              return 'bg-blue-light';
            } else {
              return '';
            }
          },
          exportLabel : true
        },
        { field: 'bplcSttsCd',
          caption: '사업장상태',
          type: 'combo',
          items: this.bplcSttsList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 125,
          colCss: (data) => {
            if (this.searchParam.bplcSttsCd && data.bplcSttsCd && data.bplcSttsCd === this.searchParam.bplcSttsCd) {
              return 'grid-warning';
            } else {
              return '';
            }
          },
          exportLabel : true
        },
      ]
    },
    initializeBplcGridAttr() {
      this.bplcGridAttr.rowCss = (data) => {
        const keys = window.SBGrid3.getCheckedKeys(this.bplcGridObject);

        if (keys.includes(data.bplcId)) {
          return 'grid-good';
        }

        return '';
      }
    },
    show(bplcId) {
      this.isVisible = true;
      document.documentElement.style.overflow = 'hidden';
      this.searchParam.bplcId = bplcId || "";
      this.bplcGridReload = true;
    },
    hide() {
      this.isVisible = false;
      document.documentElement.style.overflow = '';
    },
    search() {
      window.SBGrid3.clearSaveData(this.bplcGridObject);
      window.SBGrid3.reload(this.bplcGridObject);
    },
    reset() {
      this.searchParam.ctpvStdgCd = "";
      this.searchParam.sggStdgCd = "";
      this.searchParam.bplcSttsCd = "";
      this.searchParam.bplcNmList = "";
      this.searchParam.regYn = "";
      this.search();
    },
    bplcGridLoaded() {
      this.bplcGridReload = false;
    },
    selectBplc() {
      const checkedBplcItem = window.SBGrid3.getCheckedRows(this.bplcGridObject);

      if (checkedBplcItem.length === 0) {
        alert("선택된 사업장이 없습니다.");
        return;
      }

      let hasRegistered;
      if (this.bplcKndCd === '01-02') {
        hasRegistered = checkedBplcItem.some(item => item.data.useRegYn === 'Y');
      } else if (this.bplcKndCd === '01-01') {
        hasRegistered = checkedBplcItem.some(item => item.data.mdftrRegYn === 'Y');
      }

      if (hasRegistered) {
        alert("이미 등록된 사업장이 포함되어 있습니다.");
        return;
      }

      const confirmMessage = `선택된 ${checkedBplcItem.length}개의 사업장을 등록하시겠습니까?`;

      if (confirm(confirmMessage)) {
        const selected = checkedBplcItem.map(item => item.data.bplcId);

        this.$emit('select-bplc', selected);
      }
    }
  }
}
</script>

<style scoped>
.sch_form_wrap {
  margin-bottom: 2rem;
}
</style>