<template>
  <div v-show="isOn" class="modal visible">
    <div class="popup_box" style="width: 90%;">
      <h1 class="popup_title">시설 보완요청 정보</h1>
      <div>
        <button type="button" class="modal_close" @click.prevent="hide">팝업닫기</button>
      </div>
      <div class="popup_cont" style="max-height: calc(90vh - 15px); overflow: hidden;overflow-y: auto;">
        <!-- 보완요청 사유 -->
        <div class="board_info">
          <h3 class="title1">보완요청 사유</h3>
        </div>
        <div class="board_write">
          <div class="splmnt-message">
            {{ this.prcsRsn }}
          </div>
        </div>
        <!-- 시설 정보 -->
        <h3 class="title1">시설 정보<span class="subtitle-note">*은 필수입력 항목 입니다.</span></h3>
        <div class="board_write">
          <table>
            <caption></caption>
            <colgroup>
              <col style="width:15%">
              <col style="width:35%">
              <col style="width:15%">
              <col style="width:35%">
            </colgroup>
            <tbody>
            <tr>
              <th scope="row">시설명</th>
              <td :colspan="conds.showSrfMnf? '' : 3">
                {{ this.bizfaciltyFclt.fcltNm }}
              </td>
              <th scope="row" v-if="conds.showSrfMnf">생산제품종류</th>
              <td v-if="conds.showSrfMnf">
                {{getCdName(this.prdctnPrdctKndCdList, bizfaciltyFclt.prdctnPrdctKndCd)}}
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row" style="text-align: left;">
                <div style="display: flex; align-items: center;">
                  시설준공예정일자
                </div>
              </th>
              <td>
                <br-date-picker label="시설준공예정일자" v-model:value="modifiableData.fcltCmcnPrnmntYmd" :customStyle="{ 'max-width': '280px' }" />
              </td>
              <th scope="row">시설준공일자</th>
              <td>
                <br-date-picker label="시설준공일자" v-model:value="modifiableData.fcltCmcnYmd" :customStyle="{ 'max-width': '280px' }"/>
              </td>
            </tr>
            <tr v-if="conds.showAllSrf && conds.uncheck">
              <th scope="row" style="text-align: left;">
                <div style="display: flex; align-items: center;">
                  <i class="necessary" style="margin-right: 5px;">필수입력</i>건조시설<br/>보유여부
                </div>
              </th>
              <td>
                <br-form-radio label="건조시설보유여부"  v-model:value="modifiableData.dhmdfyFcltHldYn" :options="radioBtnYnList" value-field="value" text-field="text" panel="panel5" :required="conds.showAllSrf"/>
              </td>
              <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>변경공사예정</th>
              <td>
                <br-form-radio label="변경공사예정여부"  v-model:value="modifiableData.chgCstrnPrnmntYn" :options="radioBtnYnList" value-field="value" text-field="text" panel="panel5" :required="conds.showAllSrf"/>
              </td>
            </tr>
            <tr v-if="conds.uncheck && conds.showAllSrf">
              <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>시설단위</th>
              <td :colspan="conds.showSrfUse? '' : 3" >
                <br-form-select label="시설단위" groupCd="MBR011" includeSelectOption v-model:value="modifiableData.fcltUnitCd"  :customStyle="{ 'max-width': '270px' }" panel="panel5" :required="conds.showSrfUse"/>
              </td>
              <th scope="row" v-if="conds.showSrfUse"><i class="necessary">*<span class="sr_only">필수입력</span></i>사용시설종류</th>
              <td v-if="conds.showSrfUse">
                <br-form-select label="사용시설종류" groupCd="COM441" includeSelectOption v-model:value="modifiableData.useFcltKndCd" panel="panel5" :required="conds.showSrfUse"/>
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row"><i v-if="conds.showAllSrf" class="necessary">*<span class="sr_only">필수입력</span></i>시설공정</th>
              <td>
                <br-form-input label="시설공정" v-model="modifiableData.fcltProcsCn" panel="panel5" :required="conds.showAllSrf"/>
              </td>
              <th scope="row">형식</th>
              <td>
                <br-form-input label="형식" v-model="modifiableData.oprtngFrmCn" />
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row">가동방식</th>
              <td>
                <br-form-input label="가동방식" v-model="modifiableData.oprtngMthCn" />
              </td>
              <th scope="row">시설상태</th>
              <td>
                <br-form-radio label="시설상태" group-cd="MBR005" v-model:value="modifiableData.fcltSttsCd" />
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row"><i v-if="conds.showAllSrf" class="necessary">*<span class="sr_only">필수입력</span></i>가동시간</th>
              <td style="white-space: nowrap;" colspan="3">
                <div class="form_group">
                  <div class="input_group">
                    <br-form-input label="일간가동시간" class="form_control" v-model="modifiableData.dilyOprtngHr" align="right" unit="hr/일" validator="number" panel="panel5" :required="conds.showSrfMnf || conds.showSrfUse"/>
                    <br-form-input label="연간가동일수" class="form_control" v-model="modifiableData.anlOprtngDayCnt" align="right" unit="일/년" validator="number" panel="panel5" :required="conds.showSrfMnf || conds.showSrfUse"/>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>시설용량</th>
              <td style="white-space: nowrap;" colspan="3">
                <div class="form_group">
                  <div class="input_group">
                    <br-form-input label="시간당시설용량" class="form_control" v-model="modifiableData.hfcapa" align="right" unit="kg/시간" validator="number" panel="panel5" required/>
                    <br-form-input label="일간시설용량"  class="form_control" v-model="modifiableData.dilyFcltCap" align="right" unit="ton/일" validator="number" panel="panel5" required/>
                    <br-form-input label="연간시설용량" class="form_control" v-model="modifiableData.anlFcltCap" align="right" unit="ton/년" validator="number" panel="panel5" required/>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row">시설면적(m²)</th>
              <td colspan="3">
                <div class="form_group">
                  <div class="input_group">
                    <br-form-input label="시설면적" v-model="modifiableData.fcar" align="right" unit="m²" validator="number"/>
                    <span>m²</span>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="conds.showFire && conds.uncheck">
              <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>소각방식</th>
              <td>
                <br-form-select label="소각방식" groupCd="COM122" v-model:value="modifiableData.fireMthCd" panel="panel5" :required="conds.showFire"/>
              </td>
              <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>소각유형</th>
              <td>
                <br-form-select label="소각유형" groupCd="COM123" v-model:value="modifiableData.fireOperCd" panel="panel5" :required="conds.showFire"/>
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row"><i v-if="conds.showAllSrf" class="necessary">*<span class="sr_only">필수입력</span></i>제품보관장소</th>
              <td>
                <br-form-input label="제품보관장소" v-model="modifiableData.prdctCstdyPlcCn" panel="panel5" :required="conds.showAllSrf"/>
              </td>
              <th scope="row"><i v-if="conds.showAllSrf" class="necessary">*<span class="sr_only">필수입력</span></i>제품보관유형</th>
              <td>
                <br-form-select label="제품보관유형" groupCd="COM406" includeSelectOption v-model:value="modifiableData.prdctCstdyTypeCd" panel="panel5" :required="conds.showAllSrf"/>
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row">모델번호</th>
              <td>
                <br-form-input label="제품보관장소" v-model="modifiableData.mdlNo" />
              </td>
              <th scope="row">제조업체명</th>
              <td>
                <br-form-input label="제조업체명" v-model="modifiableData.mnftrBzentyNm" />
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row" v-if="'01' === modifiableData.fcltSttsCd"><i class="necessary">*<span class="sr_only">필수입력</span></i>가동시작일자</th>
              <td colspan="3" v-if="'01' === modifiableData.fcltSttsCd">
                <br-date-picker label="가동시작일자" v-model:value="modifiableData.oprtngBgngYmd" :customStyle="{ 'max-width': '280px' }" panel="panel6" :required="modifiableData.fcltSttsCd === '01'"/>
              </td>
              <th scope="row" v-if="'02' === modifiableData.fcltSttsCd"><i class="necessary">*<span class="sr_only">필수입력</span></i></th>
              <td v-if="'02' === modifiableData.fcltSttsCd">
                <br-date-from-to label="가동중지기간" v-model:fromDate="modifiableData.oprtngHltBgngYmd" v-model:toDate="modifiableData.oprtngHltEndYmd" panel="panel6" :required="modifiableData.fcltSttsCd === '02'"/>
              </td>
              <th scope="row" v-if="'03' === modifiableData.fcltSttsCd"><i class="necessary">*<span class="sr_only">필수입력</span></i>폐쇄일자</th>
              <td v-if="'03' === modifiableData.fcltSttsCd">
                <br-date-picker label="폐쇄일자" v-model="modifiableData.clsgYmd" :customStyle="{ 'max-width': '280px' }" panel="panel6" :required="modifiableData.fcltSttsCd === '03'"/>
              </td>
              <th scope="row" v-if="['02','03'].includes(modifiableData.fcltSttsCd)"><i class="necessary">*<span class="sr_only">필수입력</span></i>가동중지/폐쇄사유</th>
              <td v-if="['02','03'].includes(modifiableData.fcltSttsCd)">
                <br-form-input label="가동중지/폐쇄사유" v-model="modifiableData.oprtngHltClsgRsn" panel="panel6" :required="['02','03'].includes(modifiableData.fcltSttsCd)"/>
              </td>
            </tr>
            <tr v-if="conds.showWes && conds.uncheck">
              <th scope="row">에너지자체사용</th>
              <td>
                <br-form-radio label="에너지자체사용여부"  v-model:value="modifiableData.eneSlfUseYn" :options="radioBtnYnList" value-field="value" text-field="text"/>
              </td>
              <th scope="row">발전시설 용량</th>
              <td>
                <div class="form_group">
                  <div class="input_group">
                    <br-form-input label="발전시설용량" v-model="modifiableData.elctgnFcltCap" unit="kw" align="right" validator="number"/>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="conds.uncheck && (conds.showFire || conds.showSrfUse)">
              <th scope="row" v-if="conds.showFire">소각폐기물발열량</th>
              <td v-if="conds.showFire">
                <div class="form_group">
                  <div class="input_group">
                    <br-form-input label="소각폐기물발열량" v-model="modifiableData.fireWtCalqty" align="right" unit="kcal/kg" validator="number" panel="panel5" :required="conds.showFire"/>
                  </div>
                </div>
              </td>
              <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>증기엔탈피값</th>
              <td :colspan="conds.showFire ? 1 : 3">
                <div class="input_group">
                  <br-form-input label="증기엔탈피값" v-model="modifiableData.vaporEnthlpVl" align="right" unit="Kcal/kg" validator="number" panel="panel5" :required="conds.showFire || conds.showSrfUse"/>
                </div>
              </td>
            </tr>
            <tr v-if="(conds.showFire || conds.showSrfUse) && conds.uncheck">
              <th scope="row">보일러부하율</th>
              <td>
                <br-form-input label="보일러부하율" v-model="modifiableData.blrLdrt" unit="%" align="right" validator="number"/>
              </td>
              <th scope="row">보일러효율</th>
              <td>
                <br-form-input label="보일러효율" v-model="modifiableData.blrEfcnc" unit="%" align="right" validator="number"/>
              </td>
            </tr>
            <tr v-if="conds.showBio && conds.uncheck">
              <th scope="row">BIO가스생산량</th>
              <td>
                <div class="form_group">
                  <div class="input_group">
                    <br-form-input label="BIO가스생산량" v-model="modifiableData.bioGasPrdqty" align="right" unit="N㎥" validator="number"/>
                  </div>
                </div>
              </td>
              <th scope="row">BIO가스 공급시설 용량</th>
              <td>
                <div class="form_group">
                  <div class="input_group">
                    <br-form-input label="BIO가스공급시설용량" v-model="modifiableData.bioGasSplyFcltCap" align="right" unit="N㎥" validator="number"/>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="conds.showBio && conds.uncheck">
              <th scope="row">소화조설계 유입량</th>
              <td>
                <div class="form_group">
                  <div class="input_group">
                    <br-form-input label="소화조설계유입량" v-model="modifiableData.dgstrDesignInsrtCap" align="right" unit="N㎥" validator="number"/>
                  </div>
                </div>
              </td>
              <th scope="row">소화조 체류일수</th>
              <td>
                <div class="form_group">
                  <div class="input_group">
                    <br-form-input label="소화조체류일수" v-model="modifiableData.dgstrStayNmds" align="right" unit="일" validator="number" />
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="conds.showBio && conds.uncheck">
              <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>소화조 용량</th>
              <td colspan="3">
                <div class="form_group">
                  <div class="input_group">
                    <br-form-input label="소화조용량" v-model="modifiableData.dgstrCap" align="right" unit="N㎥" validator="number" panel="panel5" :required="conds.showBio"/>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="conds.showSrfUse">
              <th scope="row">혼소비율</th>
              <td colspan="3">
                <div class="form_group">
                  <div class="input_group">
                    <br-form-input label="혼소비율" v-model="modifiableData.synFireRtCn"/>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="conds.showAllSrf">
              <th scope="row">시설신고허가일자</th>
              <td colspan="3">
                <div class="form_group">
                  <div class="input_group">
                    <br-date-picker label="시설신고허가일자" v-model:value="modifiableData.fcltDclrPrmsnYmd" />
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- 시설 투입품목 정보 -->
        <template v-if="['01-01', '01-02', '04-01'].includes(this.bplcKndCd)">
          <div class="board_info mt_40">
            <h3 class="title2"><i class="necessary">*<span class="sr_only">필수입력</span></i>시설 투입품목</h3>
            <div>
              <br-button format="tertiary" label="추가" style="margin-right: 5px" class="btn sm primary line ico_plus" @click="addRow" />
              <br-button format="tertiary" label="삭제" class="btn sm point ico_minus" @click="removeRow()" />
            </div>
          </div>
          <p class="page">
            <span class="total">전체 <strong>{{ this.fcltInputItemCount }}</strong>건</span>
            <span> 페이지 <strong>{{ this.searchParam.page }}/{{ this.maxPage }}</strong></span>
          </p>
          <div class="board_list">
            <table style="table-layout: fixed;">
              <caption> 시설 투입품목 - 투입원료, 투입비율, 일간투입량으로 구성 </caption>
              <colgroup>
                <col style="width:10%">
                <col style="width:60%">
                <col v-show="['01-01', '01-02'].includes(this.bplcKndCd)" style="width:30%">
                <col v-show="['04-01'].includes(this.bplcKndCd)" style="width:30%">
              </colgroup>
              <thead>
              <tr>
                <th scope="col">선택</th>
                <th scope="col">투입품목</th>
                <th v-show="['01-01', '01-02'].includes(this.bplcKndCd)" scope="col">투입비율 (%)</th>
                <th v-show="['04-01'].includes(this.bplcKndCd)" scope="col">일간투입량</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="fcltInputItemList.length <= 0">
                <td colspan="12">
                  <div style="display: flex; justify-content: center;">
                    <div class="no-data-message">조회조건에 해당하는 데이터가 없습니다.</div>
                  </div>
                </td>
              </tr>
              <tr v-else v-for="(item, index) in fcltInputItemList" :key="index"
                  :class="{'checked-style': item.chk === 'Y'}">
                <td aria-label="선택" @click="toggleCheckbox(index)" >
                  <input type="checkbox" id="chk1" v-model="item.chk" true-value="Y" false-value="N" @click.stop>
                </td>
                <td aria-label="투입품목">
                  <br-form-select label="투입품목" :options="inputItemCdOption" v-model:value="item.inputItemCd" :disabled="!!item.fcltSqno" @change="handleSelectChange(item.inputItemCd, index)"/>
                </td>
                <td v-show="['01-01', '01-02'].includes(this.bplcKndCd)" aria-label="투입비율">
                  <br-form-input label="투입비율"
                                 oninput="this.value = this.value.replace(/[^0-9.]/g, '')"
                                 v-model="item.inputItemCpstRt"
                                 @input="markAsChanged(index)"
                                 :maxlength="3"
                                 :disabled="['04-01'].includes(this.bplcKndCd)"
                                 align="right"/>
                </td>
                <td v-show="['04-01'].includes(this.bplcKndCd)" aria-label="일간투입량">
                  <br-form-input label="일간투입량"
                                 oninput="this.value = this.value.replace(/[^0-9.]/g, '')"
                                 v-model="item.dilyInpqty"
                                 @input="markAsChanged(index)"
                                 :disabled="['01-01', '01-02'].includes(this.bplcKndCd)"
                                 align="right"/>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </template>
        <h3 class="title2">첨부서류</h3>
        <br-attach-file-list label="시설 첨부파일 목록"
                             ref="attachFileList"
                             :atchFileTaskSeCd="atchFileTaskSeCd"
                             v-model:atchFileGroupId="modifiableData.atchFileGroupId"
                             :maxFileCount="10"
                             :useEtcFile="true"
        />
        <div class="btn_area center">
          <br-button label="변경 재신청" class="btn md primary"  @click="processChangeRequest" panel="panel5" validate/>
          <br-button label="닫기" class="btn md point"  @click="cancelModify"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";
import MbrFormTelno from "@/views/mbr-mng/components/MbrFormTelno.vue";
import BrDatePicker from "@/components/BrDatePicker.vue";
import inspPagination from "@/views/sfli/components/inspPagination.vue";
import BrFormRadio from "@/components/BrFormRadio.vue";
import {storeSession} from "@/js/store/store-session";
import BrFormSelect from "@/components/BrFormSelect.vue";
import BrFormInput from "@/components/BrFormInput.vue";
import BrButton from "@/components/BrButton.vue";
import BrDateFromTo from "@/components/BrDateFromTo.vue";

const API = {
  FCLT_CHG_INFO: "/bizfacility/fclt-chg-info",
  FCLT_INPUT_ITEM: "/bizfacility/input-item-chg-info",
};

const CODE = {
  INPUT_ITEM_CD: "MBR010", // 투입품목코드
  PRDCTN_PRDCT_KND_CD: "MBR010", // 생산제품종류코드
};

const PAGE_SIZE = 5;

export default {
  components: {
    BrDateFromTo,
    BrButton,
    BrFormInput,
    BrFormSelect,
    BrFormRadio,
    inspPagination,
    BrDatePicker,
    MbrFormTelno,
  },
  emits: ['complete'],
  props: {
    bplcId: {type: String, required: true},
    fcltSqno: {type: Number, required: false},
    chgAplySn: {type: Number, required: true},
    chgAplySeCd: {type: String, required: true},
    chgAplyPrcsSttsCd: {type: String, required: true, default: ''},
    prcsRsn: {type: String, default: ''},
    bplcKndCd: {type: String, required: true},
  },
  data() {
    return {
      isOn: false,
      userInfo: storeSession.getMemberInfo(),
      maxPage: 1,
      searchParam: {
        page: 1,
        size: 5,
      },
      selectedRowData: '',           // 선택된 행의 데이터를 저장할 변수
      selectedRowIndex: '',       // 선택된 행의 인덱스를 저장할 변수
      bplcNm: '',
      bizfaciltyFclt: {
        cmptncRgnHqCd: '',
        bplcId: '',
        fcltSqno: 0,
        fcltNm: '',
        qltyTaskIssuNo: '',
        prdctnPrdctKndCd: '',
        fcltCmcnPrnmntYmd: '',
        fcltCmcnYmd: '',
        dhmdfyFcltHldYn: '',
        chgCstrnPrnmntYn: '',
        useFcltKndCd: '',
        fcar: '',
        hfcapa: '',
        dilyFcltCap: '',
        anlFcltCap: '',
        fcltUnitCd: '',
        fcltProcsCn: '',
        oprtngFrmCn: '',
        oprtngMthCn: '',
        dilyOprtngHr: '',
        anlOprtngDayCnt: '',
        prdctCstdyPlcCn: '',
        prdctCstdyTypeCd: '',
        fcltSttsCd: '',
        aprvYn: '',
        oprtngBgngYmd: '',
        oprtngHltBgngYmd: '',
        oprtngHltEndYmd: '',
        clsgYmd: '',
        oprtngHltClsgRsn: '',
        mdlNo: '',
        mnftrBzentyNm: '',
        frstInspRsltSn: '',
        frstInspYmd: '',
        frstInspPassYn: '',
        chgInspTrgtYn: '',
        vaporEnthlpVl: '',
        blrLdrt: '',
        blrEfcnc: '',
        eneSlfUseYn: '',
        fireMthCd: '',
        fireOperCd: '',
        fireWtCalqty: '',
        bioGasPrdqty: '',
        bioGasSplyFcltCap: '',
        dgstrDesignInsrtCap: '',
        dgstrStayNmds: '',
        dgstrCap: '',
        elctgnFcltCap: '',
        synFireRtCn: '',
        atchFileGroupId: '',
        useYn: '',
        rmrkCn: '',
        regDt: '',
        rgtrId: '',
        mdfcnDt: '',
        mdfrId: '',
        fcltDclrPrmsnYmd: '',
        inserted: '',
        updated: '',
      },
      uncheckedList: [  // 미관리 종류
        '06-01',
        '07-01',
        '08-01',
        '09-01',
        '10-01',
      ],
      conds: {
        showWes: false,
        showFire: false,
        showBio: false,
        showAllSrf: false,
        showSrfMnf: false,
        showSrfUse: false,
        uncheck: false,
      },
      prdctnPrdctKndCdList: [],
      inputItemCdList: [],
      inputItemCdOption: [],
      initialInputItemOption: [{ value: '', text: '선택' }],
      modifiableData: {},
      fcltInfoChgHstryList: [],
      fcltInfoChgHstryCount: 0,
      fcltInputItemList: [],
      fcltInputItemCount: 0,
      myMngFcltList: [],
      myMngFcltCount: 0,
      atchFileTaskSeCd: '',
      radioBtnYnList: [
        {value: "Y", text: "대상"},
        {value: "N", text: "비대상"},
      ],
      useYnList: [
        {value: "Y", text: "사용"},
        {value: "N", text: "미사용"},
      ],
      changedRows: new Set(), // 변경된 행의 인덱스를 저장하는 Set
    };
  },
  computed: {
    // 업체 정보 수정여부 확인하기
    isChanged() {
      // bizfaciltyFclt modifiableData 비교하여 변경된 경우 true를 반환
      return JSON.stringify(this.bizfaciltyFclt) !== JSON.stringify(this.modifiableData);
    },
  },
  watch: {
    isOn(newVal){
      if(newVal){
        this.initialize();
      }else{
        this.reset();
      }
    }
  },
  created() {
    storeSwitch.setBulb('fcltSplmntPopup', this);
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeUnmount() {
  },
  methods: {
    show() {
      this.isOn = true;
    },
    hide() {
      this.isOn = false;
    },
    async initialize() {

      this.inputItemCdList = this.$store.getters.getCodeList({groupCd: CODE.INPUT_ITEM_CD, useYn: 'Y'});   // 투입품목코드
      this.prdctnPrdctKndCdList = this.$store.getters.getCodeList({groupCd: CODE.PRDCTN_PRDCT_KND_CD, useYn: 'Y'});   // 투입품목코드

      this.inputItemCdOption = [...this.initialInputItemOption];
      await this.readFcltInfo();
      await this.filterInputItemList();
    },
    readFcltInfo() {
      this.$apiCall.get(
          API.FCLT_CHG_INFO,
          {
            bplcId: this.bplcId,
            fcltSqno: this.fcltSqno,
            chgAplySn: this.chgAplySn
          },
          async data => {
        if (data.result) {
          for (const key of Object.keys(this.bizfaciltyFclt)) {
            this.bizfaciltyFclt[key] = data.result?.[key] ?? '';
          }
          this.modifiableData = JSON.parse(JSON.stringify(this.bizfaciltyFclt));
          await this.searchFcltInputItem();
          await this.setConditions(this.bplcKndCd);

          if(this.conds.uncheck) {
            // 첨부파일 목록 갱신
            await this.$refs.attachFileList.load(this.atchFileTaskSeCd, this.bizfaciltyFclt.atchFileGroupId);
          }else{
            await this.$refs.attachFileList.load('X', 'X');
          }
        }
      });
    },
    setConditions(bplcKndCd) {
      const mainCd = bplcKndCd.slice(0, 2);
      const subCd = bplcKndCd.slice(3, 5);

      // 초기화
      this.conds = {
        showWes: false,
        showFire: false,
        showBio: false,
        showAllSrf: false,
        showSrfMnf: false,
        showSrfUse: false,
        uncheck: !this.uncheckedList.includes(bplcKndCd),
      };

      // 조건 설정
      if (mainCd === '01') {
        if (['01', '02'].includes(subCd)) {
          switch (subCd){
            case '01' :
              this.atchFileTaskSeCd = 'FCTA'
              break;
            case '02' :
              this.atchFileTaskSeCd = 'FCTC'
              break;
          }
          this.conds.showAllSrf = true;
          this.conds.showSrfMnf = subCd === '01';
          this.conds.showSrfUse = subCd === '02';
        }
      } else {
        this.atchFileTaskSeCd = 'FCTB';
        this.conds.showWes = true;

        if (mainCd === '03') this.conds.showFire = true;
        else if (mainCd === '04') this.conds.showBio = true;

      }

      // 관리되지 않는 폐자원에너지 추가 조건 (06~10)
      if (['06', '07', '08', '09', '10'].includes(mainCd)) {
        this.conds.uncheck = true;
        this.$refs.attachFileList.load('X', 'X');
      }
    },
    searchFcltInputItem() {
      this.searchParam.size = PAGE_SIZE;
      this.$apiCall.get(
          API.FCLT_INPUT_ITEM,
          {
            chgAplySn: this.chgAplySn,
            bplcId: this.bizfaciltyFclt.bplcId,
            fcltSqno: this.bizfaciltyFclt.fcltSqno,
            page: this.searchParam.page,
            size: this.searchParam.size
          },
          data => {
            if (data.result.list) {
              this.fcltInputItemCount = data.result.total;
              this.fcltInputItemList = data.result.list;
            } else {
              this.fcltInputItemList = [];
            }
            this.changedRows = new Set();
          });
    },
    reset() {
      Object.keys(this.modifiableData).forEach(key => {
        delete this.modifiableData[key];
      });
      this.changedRows = new Set();
    },
    getCdName(list, code) {
      if (!code) {
        return '';
      }
      const status = list.find(item => item.dtlCd === code);
      return status ? status.dtlCdNm : '';
    },
    cancelModify() {
      this.changedRows.clear(); // 시설투입품목 변경된 행 인덱스 초기화
      //fcltSqno가 없는 항목은 리스트에서 제거
      this.fcltInputItemList = this.fcltInputItemList.filter(item => !(!item.fcltSqno));
      this.isOn = false
    },
    addRow() {
      const newRow = {
        fcltSqno: '',
        chk: 'N',
        inputItemCd: '',
        inputItemCpstRt: '',
        dilyInpqty: '',
        useYn: 'Y',
      };
      this.fcltInputItemList.push(newRow);
    },
    removeRow() {
      // 체크된 행만 필터링
      const checkedRows = this.fcltInputItemList.filter(item => item.chk === 'Y');

      // 체크된 행이 없는 경우 알림 표시 및 함수 종료
      if (checkedRows.length === 0) {
        alert("선택된 항목이 없습니다.");
        return;
      }

      // 삭제 리스트와 화면에서 제거할 행을 분리
      const deleteList = checkedRows
          .filter(item => item.fcltSqno)
          .map(item =>
              ({
                chgAplySn: this.chgAplySn,
                bplcId: item.bplcId,
                fcltSqno: item.fcltSqno,
                inputItemCd: item.inputItemCd,
              })
          );

      const confirmMessage = `선택된 ${checkedRows.length}개의 투입품목을 정말 삭제하시겠습니까?`;

      if (confirm(confirmMessage)) {
        if(deleteList.length !== 0){
          this.$apiCall.delete(
              API.FCLT_INPUT_ITEM,
              {deleteList: deleteList},
              () => {
                //fcltSqno가 없는 항목은 리스트에서 제거
                this.fcltInputItemList = this.fcltInputItemList.filter(item => !(item.chk === 'Y' && !item.fcltSqno));

                alert("삭제되었습니다.")

                this.searchFcltInputItem();
              },
          );
        }
        //fcltSqno가 없는 항목은 리스트에서 제거
        this.fcltInputItemList = this.fcltInputItemList.filter(item => !(item.chk === 'Y' && !item.fcltSqno));
      }
    },
    markAsChanged(index) {
      // 변경된 인덱스를 Set에 추가 (중복 방지)
      this.changedRows.add(index);
    },
    processChangeRequest(prcsRsn){
      this.modifiableData.prcsRsn = prcsRsn;

      // 변경된 행 정보 필터링 및 분류
      const inserted = [];
      const updated = [];

      //changedRows를 순회하면서 fcltSqno 값에 따라 분류
      Array.from(this.changedRows).forEach(index => {
        const row = this.fcltInputItemList[index]; // 변경된 행 데이터 가져오기
        if (!row.fcltSqno) {
          inserted.push(row); // fcltSqno가 false이면 inserted에 추가
        } else {
          updated.push(row); // fcltSqno가 true이면 updated에 추가
        }
      });

      this.modifiableData.inserted = inserted;
      this.modifiableData.updated = updated;

      if (!this.validateFcltItem()) return;

      if (confirm("변경 재신청하시겠습니까?")) {
        this.$refs.attachFileList.save(true, false).then(atchFileGroupId => {

          this.modifiableData.atchFileGroupId = atchFileGroupId;

          //변경신청일련번호, 변경신청구분코드, 변경신청처리상태코드, 상세변경신청처리상태코드, 변경신청유형코드
          this.modifiableData.chgAplySn = this.chgAplySn;
          this.modifiableData.chgAplySeCd = this.chgAplySeCd;
          this.modifiableData.chgAplyPrcsSttsCd = '04'; //보완 후 재신청
          this.modifiableData.dtlChgAplyPrcsSttsCd = '04';  //보완 후 재신청
          this.modifiableData.chgAplyTypeCd = '03'; //시설

          this.$apiCall.post(API.FCLT_CHG_INFO, this.modifiableData, () => {
                alert("변경 신청되었습니다.");
                this.$emit('complete')
                this.isOn = false;
              },
              () => {
                alert('변경 신청에 실패하였습니다.');
              }
          );
        });
      }
    },
    handleSelectChange(inputItemCd, currentIndex) {
      const isDuplicate = this.fcltInputItemList.some(
          (item, index) => item.inputItemCd === inputItemCd && index !== currentIndex
      );
      if(isDuplicate){
        alert("이미 존재하는 투입품목입니다.")

        this.$nextTick(() => {
          // DOM 업데이트 이후 강제로 반영 -> 업데이트 하지않을 시 첫 이벤트만 동작하고, 이후 동작하지 않음.
          this.fcltInputItemList[currentIndex].inputItemCd = '';
        });
      }else{
        this.markAsChanged(currentIndex);
      }
    },
    filterInputItemList() {
      switch(this.bplcKndCd) {
        case '01-01':
          this.inputItemCdOption.push(...this.inputItemCdList
              .filter(item => item.frstRefVl === 'WT')
              .map(item => ({
                value: String(item.dtlCd),
                text: String(item.dtlCdNm)
              }))
          )
          break;
        case '01-02':
          this.inputItemCdOption.push(...this.inputItemCdList
              .filter(item => item.frstRefVl === 'SRF')
              .map(item => ({
                value: String(item.dtlCd),
                text: String(item.dtlCdNm)
              }))
          )
          break;
        case '04-01':
          this.inputItemCdOption.push(...this.inputItemCdList
              .filter(item => item.frstRefVl === 'BIOWT')
              .map(item => ({
                value: String(item.dtlCd),
                text: String(item.dtlCdNm)
              }))
          )
          break;
        default :
          this.inputItemCdOption.push(...this.inputItemCdList
              .map(item => ({
                value: String(item.dtlCd),
                text: String(item.dtlCdNm)
              }))
          )
          break;
      }
    },
    validateFcltItem() {
      if(!['01-01', '01-02', '04-01'].includes(this.bplcKndCd)){
        return true;
      }

      if(this.fcltInputItemList.length === 0){
        alert("시설 투입품목 입력은 필수 사항입니다.");
        return false;
      }
      let totalInputItemCpstRt = 0;

      for (const fcltInputItem of this.fcltInputItemList) {
        totalInputItemCpstRt += Number(fcltInputItem.inputItemCpstRt);
        if (!fcltInputItem.inputItemCd) {
          alert("투입품목이 비어 있는 항목이 있습니다. 확인 후 다시 신청해주세요.");
          return false;
        }
        if (['01-01', '01-02'].includes(this.bplcKndCd) && (!fcltInputItem.inputItemCpstRt || Number(fcltInputItem.inputItemCpstRt) === 0)) {
          alert("투입비율이 비어있습니다. 확인 후 다시 신청해주세요.");
          return false;
        }
        if (['04-01'].includes(this.bplcKndCd) && (!fcltInputItem.dilyInpqty || Number(fcltInputItem.dilyInpqty) === 0)) {
          alert("일간투입량이 비어있습니다. 확인 후 다시 신청해주세요.");
          return false;
        }
        if(Number(fcltInputItem.inputItemCpstRt) > 100){
          alert("투입비율은 100%를 넘길 수 없습니다. 확인 후 다시 신청해주세요.");
          return false;
        }
      }
      if(totalInputItemCpstRt > 100){
        alert("투입비율의 총 합은 100%를 넘길 수 없습니다. 확인 후 다시 신청해주세요.");
        return false;
      }
      if(['01-01', '01-02'].includes(this.bplcKndCd) && totalInputItemCpstRt < 100){
        alert("투입비율의 총 합이 100%가 아닙니다. 확인 후 다시 신청해주세요.");
        return false;
      }
      return true;
    },
    toggleCheckbox(index){
      this.fcltInputItemList[index].chk = this.fcltInputItemList[index].chk === 'Y' ? 'N' : 'Y';
    },
  }
}
</script>

<style scoped>
.subtitle-note {
  font-size: 15px; /* 폰트 크기 설정 */
  font-weight: normal; /* 굵기 설정 (기본 또는 normal) */
  color: #888888; /* 원하는 색상 코드로 변경 */
  margin-left: 10px;
}

.splmnt-message {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 20px;
  margin: 5px 0px;
  border-radius: 10px;
  background-color: #f7f7f7;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #ddd;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}
.board_write > table th{
  padding: .8rem 1.0rem;
}
</style>