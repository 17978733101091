<template>

  <div class="desc_box">
    <i class="icon"><img src="@/assets/images/common/ico_desc.png" alt="이미지"></i>
    <div class="desc">
      <P>폐자원에너지 종합정보관리시스템 전체 메뉴를 한눈에 볼 수 있습니다. <br>
        해당 메뉴를 클릭하시면 해당페이지로 이동합니다.</P>
    </div>
  </div>
  <ul class="syt_allMenu">
    <li v-for="menu in topLevelMenus"
        :key="menu.menuSn">
      <div class="menu_group">
        <div class="group_bg_img"></div>
        <h3>{{ menu.menuNm }}</h3>
      </div>
      <ul class="dep2">
        <li v-for="(item, index) in getSubMenus(menu.menuSn)"
            :key="index">
          <a href="" onclick="return false;" @click="toMenu(item)">{{ item.menuNm }}</a>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>

import {storeSession} from "@/js/store/store-session";

const API = {
  SITEMAP: "/sitemap/",
};

export default {
  components: {},
  data() {
    return {
      menuList:[],
    };
  },
  computed: {
    // 최상위 메뉴 (upMenuSn이 null인 항목)
    topLevelMenus() {
      return this.menuList.filter(menu => menu.upMenuSn == 24);
    }
  },
  watch: {
  },
  created() {
  },
  beforeMount() {
  },
  mounted() {
    window.scrollTo(0, 0);
    this.load();
  },
  methods: {
    // 특정 상위 메뉴에 해당하는 하위 메뉴 반환
    getSubMenus(upMenuSn) {
      return this.menuList.filter(menu => menu.upMenuSn == upMenuSn);
    },
    load(){
      this.$apiCall.get(API.SITEMAP, {}, this.success, this.error);
    },
    success(data){
      if(!data.result){
        this.error();
        return;
      }
      this.menuList = data.result
    },
    error() {
      alert("데이터를 가져오는 중 오류가 발생했습니다.");
    },
    toMenu(data){
      if(storeSession.hasMenuSn(data.menuSn)){
        window.scrollTo(0, 0);
        this.$router.push({path : `${data.urlAddr}`, })
          .catch(() => {
            console.error("Navigation Error");
          });
      }else{
        alert("메뉴 접근 권한이 없습니다.");
      }
    },
  }
}
</script>

<style scoped>
body {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  //background-color: #fdfdfd;
  margin: 0;
  padding: 20px;
}

.sitemap-box {
  width: 150px;
  padding: 10px;
  background-color: #eeeeee;
  border-radius: 5px;
  box-sizing: border-box;
}

.title {
  font-weight: bold;
  margin-bottom: 10px;
}

.description {
  color: #555;
  margin-bottom: 8px;
}

.menu-item {
  color: #333;
  text-decoration: underline;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
</style>
