import { reactive } from "vue";

export const storeValidator = reactive({
    storage: new Map(),

    add(path, panel, input) {
        let inputs = this.storage.get(path + panel)
        if (inputs == null) {
            inputs = [];
        }
        for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].label === input.label) {
                inputs.splice(i, 1);
            }
        }
        inputs.push(input);
        this.storage.set(path + panel, inputs);
    },
    remove(path, panel, label) {
        let key = path + panel;
        let inputs = this.storage.get(key);

        if (inputs !== null && Array.isArray(inputs)) {
            this.storage.set(
                key,
                inputs.filter(input => input.label !== label)
            );
        }
    },
    validate(path, panel) {
        let key = path + panel;
        let inputs = this.storage.get(key);
        if (inputs == null || inputs.length === 0) {
            return true;
        }
        for (let i = 0; i < inputs.length; i++) {
            const input = inputs[i];

            if (input.hasError) {
                if (input.prohibited) {
                    alert(input.errorMessage);
                    return false;
                }

                if (input.required) {
                    alert(input.errorMessage);
                    return false;
                }
            }
        }
        return true;
    },
})