<template>
  <div class="board_info">
    <h2 class="title1">온라인교육 강좌상세</h2>
  </div>
  <div class="board_view online_edu">
    <div class="b_tit">
      <h2 v-if="onlineEducationParam && onlineEducationParam.eduNm">{{ onlineEducationParam.eduNm }}</h2>
      <div :class="['', onlineEducationByAplyParam.eduFnshYn === 'Y' ? 'b_state primary' : ( onlineEducationByAplyParam.eduFnshYn === 'N' ? 'b_state' :'' )]" 
        v-if="isInDateRange( onlineEducationParam.eduYmd ) && onlineEducationByAplyParam.eduAplySttsCd === '04'" >
        {{ onlineEducationByAplyParam.eduFnshYn === 'Y' ? '수료' : ( onlineEducationByAplyParam.eduFnshYn === 'N' ? '학습중' : '' ) }}
      </div>
    </div>
    <div class="b_cont">
      <dl>
        <dt>교육기간</dt>
        <dd>{{ onlineEducationParam.eduYmd }}</dd>
      </dl>
      <dl>
          <dt>교육방법</dt>
          <dd>{{ onlineEducationParam.eduMthdCd === '01' ? '온라인' : '오프라인' }}</dd>
      </dl>
      <dl>
          <dt>모집인원</dt>
          <dd>{{ onlineEducationParam.eduRcrtNope }} 명</dd>
      </dl>
      <dl v-if="onlineEducationByAplyParam.eduAplySttsCd">
          <dt>신청여부</dt>
          <dd>{{ getAplySttsText( onlineEducationByAplyParam.eduAplySttsCd ) }} ({{ onlineEducationByAplyParam.eduAplyYmd }})</dd>
      </dl>
      <dl v-if="onlineEducationByAplyParam.eduFnshYmd">
          <dt>수료일자</dt>
          <dd>{{ onlineEducationByAplyParam.eduFnshYmd }}</dd>
      </dl>
      <dl class="w100p">
          <dt>강좌소개</dt>
          <dd v-html="onlineEducationParam.eduCn" style="word-wrap: break-word; word-break: break-all; white-space: pre-wrap; text-Indent: 10px; font-weight: normal;"></dd>
      </dl>
    </div>
    <div class="edu_list">
      <h3>강의목차({{ Number( lectureList.length ).toLocaleString() }})</h3>
      <ul>
        <li v-if="this.lectureTotalCnt === 0">
          <span>등록된 강의가 없습니다.</span>
        </li>
        <li v-for="item in lectureList" :key="item.lctrSqno">
          <span class="title">{{ item.lctrNm }}</span>
          <span class="time">{{ item.lctrHr }}</span>
          <span class="percent" style="width: 100px;">{{ item.rgcrsPrgrt ? item.rgcrsPrgrt : 0 }} % / {{ item.rgcrsCmptnRt }} %</span>
          <span class="state">{{ onlineEducationByAplyParam.eduFnshYn === 'Y' ? '완료' : '미완료' }}</span>
          <br-button label="강의보기" format="btn xsm secondary" @click="lectureInq( item )"/>
        </li>
      </ul>
      <div class="btn_area">
        <div >
          <a href="javascript:void(0);" v-if="onlineEducationByAplyParam.eduFnshYn === 'Y'" class="btn md secondary" @click.prevent="openReportForm( 'reportOnlineEducationCfmcd' )">교육확인증 발급</a>
        </div>
        <div>
          <br-button label="신청" format="primary" style="margin-right: 10px;"
            v-if="( !onlineEducationByAplyParam.eduAplySttsCd && lectureList.length > 0 ) || 
                    onlineEducationByAplyParam.eduAplySttsCd === '' || 
                    onlineEducationByAplyParam.eduAplySttsCd === '03'" 
                    @click="eduAplyBtn" :auth="[ 'C' ]"/>
          <br-button label="신청취소" format="primary" v-if="onlineEducationByAplyParam.eduAplySttsCd === '01'" style="margin-right: 10px;" @click="eduRtrcnBtn" :auth="[ 'U' ]"/>
          <br-button label="목록" format="tertiary line" @click="navigateTo( '/cust-mng/OnlineEducationManage' )"/>
        </div>
      </div>
    </div>
  </div>
  <component v-if="reportParamObj.selectedPopupComponent" 
    :is="reportParamObj.selectedPopupComponent" 
    :rd-param-file-name="reportParamObj.rdParamFileName" 
    :rd-param-key1="reportParamObj.rdParamKey1" 
    :rd-param-value1="reportParamObj.rdParamValue1" 
    :rd-param-key2="reportParamObj.rdParamKey2" 
    :rd-param-value2="reportParamObj.rdParamValue2" 
    :rd-param-key3="reportParamObj.rdParamKey3" 
    :rd-param-value3="reportParamObj.rdParamValue3" 
    :rd-param-key4="reportParamObj.rdParamKey4" 
    :rd-param-value4="reportParamObj.rdParamValue4" 
  />
</template>
  
<script>
/**
 * 온라인교육(강좌) 상세 조회 화면입니다.
 *
 * 작성자 : 정미라
 * 작성일 : 24. 10. 19.
 * 버전 : 1.0
 */
  import { storeSwitch } from "@/js/store/store-switch";
  import { storeSession } from "@/js/store/store-session";
  import reportEducationPopup from "@/views/cust-mng/popup/ReportEducationPopup.vue";
  
  
  const API = {
    ONLINE_EDUCATION : "/online-education",
    ONLINE_EDUCATION_APLY : "/online-education/educationAply",
    ONLINE_EDUCATION_RTRCN : "/online-education/educationRtrcn"
  }

  export default {
    components: { reportEducationPopup }, 
    data() {
      return {
        eduSn : "",
        onlineEducationParam : {
          eduSn : "",         // 강좌 일련번호
          eduNm : "",         // 강좌명
          eduCn : "",         // 강좌설명
          eduYmd : "",        // 교육기간
          eduMthdCd : "",     // 교육방법
          eduRcrtNope : "",   // 수강인원
          eduAplyNope : "",   // 수강신청완료인원
        },
        onlineEducationByAplyParam : {
          eduFnshYn : "",     // 수료여부
          eduFnshYmd : "",    // 수료일자
          eduAplySttsCd : "" ,// 신청코드
          eduAplyYmd : ""     // 신청일자
        },
        eduFnshYnlist : [
          { value: "01", text: "학습중" },
          { value: "02", text: "수료" },
        ],
        lectureList: [],
        total: "",
        lectureTotalCnt: "",
        reportParamObj : {
          selectedPopupComponent: null, // 선택된 리포트/이폼 화면 구분 ID 
          rdParamKey1: "",
          rdParamValue1: "",
          rdParamKey2: "",
          rdParamValue2: "",
          rdParamKey3: "",
          rdParamValue3: "",
          rdParamKey4: "",
          rdParamValue4: "",
          rdParamFileName: ""
        },
        userinfo: storeSession.getMemberInfo(),
      };
    },
    computed : {},
    watch : {},
    created() {},
    beforeMount() {},
    mounted() {
      this.eduSn = this.$route.query.eduSn;
      // 초기값 설정 (빈 객체로 초기화)
      this.onlineEducationParam = {
        eduNm: "",
        eduCn: "",
        eduYmd: "",
        eduMthdCd: "",
        eduRcrtNope: "",
        eduAplyNope: ""
      };
      this.readOnlineEducation();
    },
    beforeUnmount() {},
    methods: {
      // 강좌상세 조회
      readOnlineEducation() {
        this.$apiCall.get( `${API.ONLINE_EDUCATION}/${this.eduSn}`, {}, this.readOnlineEducationSuccess, this.error );
      },
      readOnlineEducationSuccess( data ) {
        if ( !data.result ) {
          this.Educationerror();
          return;
        }
        this.onlineEducationParam = data.result.onlineEducation;
        this.total = data.result.total;

        // total이 0일 경우 onlineEducationByAplyParam을 빈 값으로 설정
        if ( this.total === 0 ) {
          this.onlineEducationByAplyParam = {
            eduSn: this.eduSn,
            eduFnshYn: "",
            eduFnshYmd: "",
            eduAplySttsCd: "",
            eduAplyYmd: ""
          };
        } else {
          this.onlineEducationByAplyParam = data.result.onlineEducationByAply;
        }
        this.readOnlineLecture();
      },
      Educationerror() {
        alert( "데이터를 가져오는 중 오류가 발생했습니다.111" );
      },
      // 강의상세 조회 
      readOnlineLecture() {
        const url = `/online-education/${ this.eduSn }/lecture`; // 경로에 eduSn을 바로 치환
        this.$apiCall.get( url, {}, this.readOnlineLectureSuccess, this.error );        
      },
      readOnlineLectureSuccess( data ) {
        if ( !data.result ) {
          this.Lectureerror();
          return;
        }
        this.lectureList = data.result.list; // 강의데이터 불러와서 저장
        this.lectureTotalCnt = data.result.total;
      },
      Lectureerror() {
        alert( "데이터를 가져오는 중 오류가 발생했습니다." );
      },
      getAplySttsText( eduAplySttsCd ) {
        switch ( eduAplySttsCd ) {
          case '01':
            return '신청중';
          case '02':
            return '일정취소';
          case '03':
            return '신청취소';
          case '04':
            return '승인완료';
          default:
            return '상태없음';
        }
      },
      // 현재 날짜가 교육 기간 내에 있는지 확인하는 메서드
      isInDateRange( eduYmd ) {
          const [ startDateStr, endDateStr ] = eduYmd.split( " ~ " );
          const startDate = new Date( startDateStr );
          const endDate = new Date( endDateStr );
          const currentDate = new Date();
          return currentDate >= startDate && currentDate <= endDate;
      },
      lectureInq( item ) {
        if ( this.onlineEducationByAplyParam.eduAplySttsCd !== '01' && this.onlineEducationByAplyParam.eduAplySttsCd !== '04' ){ 
          alert( "강좌 신청 후 시청 가능합니다." );
          return; 
        } else if ( this.onlineEducationByAplyParam.eduAplySttsCd == '01' ) { // 신청중
          alert( "관리자가 확인 후, 신청완료가 되면 시청 가능합니다." );
          return; 
        }
        if ( !item.lctrSqno ) {
          alert( "강의 데이터가 존재하지 않습니다." );
          return;
        }
        // 조회수 증가 및 강의보기 이동
        const url = `/online-education/${ item.eduSn }/lecture/${ item.lctrSqno }/lctrInqCnt`;
        this.$apiCall.post( url, item, 
        () => {
          this.$router.push({
            path : "/cust-mng/online-education/OnlineLectureDetail", 
            query : { eduSn: this.eduSn, lctrSqno: item.lctrSqno }
            }).catch(() => {
              console.error( "Navigation Error" );
            });
        });
      },
      navigateTo( path ) {
        if ( path ) {
          this.$router.push( path ).catch(() => {
            console.error( "Navigation Error" );
          });
        }
      },
      // 신청 버튼클릭
      eduAplyBtn(){
        if (this.lectureTotalCnt === 0) {
          alert( "등록된 강의가 없어 신청할수 없습니다." );
          return;
        }
        if ( !confirm( "강좌를 신청하시겠습니까?" )){
            return; 
        }
        // 모집인원보다 신청완료 인원이 크면 신청 못하게 막음.
        if ( this.onlineEducationParam.eduRcrtNope < this.onlineEducationParam.eduAplyNope ){
          alert( "모집인원이 마감되었습니다." );
          return;
        }
        this.$apiCall.post( API.ONLINE_EDUCATION_APLY, this.onlineEducationByAplyParam,
          () => {
            alert( "강좌 신청이 완료 되었습니다." );
            window.location.reload()
          },
          () => {
            alert( "저장에 실패하였습니다." );
          }
        );
      },
      // 신청취소 버튼클릭(신청중일때만 보여짐)
      eduRtrcnBtn(){
        if ( !confirm( "강좌신청을 취소하시겠습니까?" )){
          return; 
        }
        this.$apiCall.put( API.ONLINE_EDUCATION_RTRCN, this.onlineEducationByAplyParam,
          () => {
              alert( "강좌신청 취소가 완료 되었습니다." );
              window.location.reload()
          },
          () => {
              alert( "저장에 실패하였습니다." );
          }
        );
      },
      openReportForm( rdFimeName ) {                        // 리포트 화면 열기 
        this.reportParamObj.selectedPopupComponent = null;  // 컴포넌트 리셋
        this.openLayerPopup( rdFimeName );
        // 재호출 후 팝업에서 노출되는 현상으로 재호출  
        setTimeout(() => {
          this.openLayerPopup( rdFimeName );
        }, 50 ); 
      },
      // 레이어 팝업
      openLayerPopup( rdFimeName ) {
        setTimeout(() => {
          this.reportParamObj.selectedPopupComponent = 'reportEducationPopup';
          // rd Param 초기화 
          this.reportParamObj.rdParamFileName = rdFimeName;
          this.reportParamObj.rdParamKey1 = "";
          this.reportParamObj.rdParamValue1 = "";
          this.reportParamObj.rdParamKey2 = "";
          this.reportParamObj.rdParamValue2 = "";
          this.reportParamObj.rdParamKey3 = "";
          this.reportParamObj.rdParamValue3 = "";
          this.reportParamObj.rdParamKey4 = "";
          this.reportParamObj.rdParamValue4 = "";
          // rd 파일명 기준으로 Param 적용 
          this.reportParamObj.rdParamFileName = rdFimeName;     // 리포트 mrd 파일명 
          this.reportParamObj.rdParamKey1 = 'enuSn';            // 리포트 화면 Param Key 
          this.reportParamObj.rdParamValue1 = this.eduSn;       // 리포트 화면 Param Key에 대한 Value  
          this.reportParamObj.rdParamKey2 = 'delYn'; 
          this.reportParamObj.rdParamValue2 = 'N'; 
          this.reportParamObj.rdParamKey3 = 'userId'; 
          this.reportParamObj.rdParamValue3 = this.userinfo.userId; 
          this.reportParamObj.rdParamKey4 = 'eduFnshYn'; 
          this.reportParamObj.rdParamValue4 = 'Y'; 
          storeSwitch.on(this.reportParamObj.selectedPopupComponent);
        }, 0 ); // 0ms 딜레이로 컴포넌트 강제 리렌더링 (리포트 리렌더링 안 되는 케이스가 존재하여 추가)
      },
        
    }
  };
</script>

<style scoped>
  .board_view .b_cont:first-child {
    width: 30px;
  }
  .board_view .b_cont dl {
    width: 32%;
  }
  .board_view .edu_list li .time {
    width: 10.2rem;
  }
</style>