<template>
  <h2 class="title1 mt_0">관련 법률 안내</h2>
  <p class="law_txt">한국환경공단은 <strong>『고형연료제품 제조시설 및 사용시설의 정기검사 관련 법률』</strong>에 근거하여 <span>폐자원에너지 종합정보관리시스템을 운영합니다.</span></p>
  <div class="law_box">
    <ul>
      <li>「자원의 절약과 재활용촉진에 관한 법률」제25조의8 <a href="http://www.law.go.kr/법령/자원의%20절약과%20재활용촉진에%20관한%20법률" target="_blank" title="새창열림" class="btn sm">관련법률 상세보기</a></li>
      <li>「자원의 절약과 재활용촉진에 관한 법률」시행령 <a href="http://www.law.go.kr/법령/자원의%20절약과%20재활용촉진에%20관한%20법률%20시행령" target="_blank" title="새창열림" class="btn sm">관련법률 상세보기</a></li>
      <li>「자원의 절약과 재활용촉진에 관한 법률」시행규칙 제20조의8, 제20조의9 <a href="http://www.law.go.kr/법령/자원의%20절약과%20재활용촉진에%20관한%20법률%20시행규칙" target="_blank" title="새창열림" class="btn sm">관련법률 상세보기</a></li>
      <li>「고형연료제품 제조시설 및 사용시설의 정기검사 절차 및 방법 등에 관한 고시」 <a href="http://www.law.go.kr/행정규칙/고형연료제품제조시설및사용시설의정기검사절차및방법등에관한고시" target="_blank" title="새창열림" class="btn sm">관련법률 상세보기</a></li>
    </ul>
  </div>

  <h2 class="title1">고형연료제품 시설정기검사 신청 관련서류</h2>
  <div class="tbl_wrap">
    <table>
      <caption>고형연료제품 시설정기검사 신청 관련서류_NO, 제조시설, 사용시설, 양식으로 구성</caption>
      <colgroup>
        <col style="width:8%">
        <col style="width:40%">
        <col style="width:40%">
        <col style="width:12%">
      </colgroup>
      <thead>
      <tr>
        <th scope="col">NO</th>
        <th scope="col">제조시설</th>
        <th scope="col">사용시설</th>
        <th scope="col">양식</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>1</td>
        <td class="txt_left">
          [필수] 민간시설: 폐기물 종합ㆍ최종 재활용업 허가증 사본
          <br>
          [필수] 지자체 시설: 폐물처분시설 또는 재활용시설 설치 승인서ㆍ신고증명서 사본
        </td>
        <td>-</td>
        <td></td>
      </tr>
      <tr>
        <td>2</td>
        <td class="txt_left">[선택] 대기배출시설 설치 허가증ㆍ신고증명서 사본</td>
        <td class="txt_left">[필수] 대기배출시설 설치 허가증ㆍ신고증명서 사본</td>
        <td></td>
      </tr>
      <tr>
        <td>3</td>
        <td class="txt_left">[필수] 사업자등록증 사본</td>
        <td class="txt_left">[필수] 사업자등록증 사본</td>
        <td></td>
      </tr>
      <tr>
        <td>4</td>
        <td class="txt_left">[필수] 고형연료제품 정기검사 신청서</td>
        <td class="txt_left">[필수] 고형연료제품 정기검사 신청서</td>
        <td>
          <br-static-file-download atchFileVldNo="458e1329-cba9-4228-af19-1dc8b3862116" />
        </td>
      </tr>
      <tr>
        <td>5</td>
        <td class="txt_left">[필수] 고형연료제품 제조시설의 설치명세서(도면 포함)</td>
        <td class="txt_left">[필수] 고형연료제품 사용시설의 설치명세서(도면 포함)</td>
        <td></td>
      </tr>
      <tr>
        <td>6</td>
        <td class="txt_left">[필수] 고형연료제품 제조시설의 가동 및 유지관리 명세서</td>
        <td class="txt_left">[필수] 고형연료제품 사용시설의 가동 및 유지관리 명세서</td>
        <td>
          <br-static-file-download atchFileVldNo="27904ddc-b223-4a7f-8fc1-1178d45df0d7" />
        </td>
      </tr>
      <tr>
        <td>7</td>
        <td class="txt_left">[필수] 통장사본</td>
        <td class="txt_left">[필수] 통장사본</td>
        <td></td>
      </tr>
      <tr>
        <td>8</td>
        <td class="txt_left">[필수] 제조신고확인증 사본</td>
        <td class="txt_left">[필수] 사용허가증 사본</td>
        <td></td>
      </tr>
      <tr>
        <td>9</td>
        <td class="txt_left">[선택] 위탁운영 관련 증빙 서류(계약서 또는 확인서)</td>
        <td class="txt_left">[필수] 위탁운영 관련 증빙 서류(계약서 또는 확인서)</td>
        <td></td>
      </tr>
      <tr>
        <td>10</td>
        <td>-</td>
        <td class="txt_left">[선택] 승온관련 운영기록지</td>
        <td>
          <br-static-file-download atchFileVldNo="26586cda-355b-437e-a3d9-df635be1abb6" />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h2 class="title1">검사 절차 안내</h2>
  <div class="img_box_area">
    <img src="@/assets/images/sub/reporting_procedures-1.png" alt="시설검사 절차 안내">
  </div>
  <div class="img_box_area">
    <div class="img_box top_line">사용시설</div>
    <img src="@/assets/images/sub/reporting_procedures-3.png" alt="사용시설의 시설검사 절차 상세 안내">
  </div>
  <div class="img_box_area">
    <div class="img_box top_line">제조시설</div>
    <img src="@/assets/images/sub/reporting_procedures-4.png" alt="제조시설의 시설검사 절차 상세 안내">
  </div>

  <h2 class="title1">수수료 안내 <span class="s_title">제조·사용시설 정기검사(최초, 계속, 변경검사)</span></h2>
  <div class="tbl_wrap">
    <table>
      <caption>수수료 안내_구분, 최초/변경검사, 계속검사로 구성</caption>
      <colgroup>
        <col style="width:18%">
        <col style="width:32%">
        <col style="width:25%">
        <col style="width:25%">
      </colgroup>
      <thead>
      <tr>
        <th scope="col" colspan="2">구분</th>
        <th scope="col">최초/변경 검사 (부가세 포함)</th>
        <th scope="col">계속검사 (부가세 포함)</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th scope="row" rowspan="2">제조시설</th>
        <td class="txt_left">건조시설을 보유한 경우</td>
        <td class="txt_right">2,409,000원</td>
        <td class="txt_right">2,168,100원</td>
      </tr>
      <tr>
        <td class="txt_left">건조시설을 보유하지 않은 경우</td>
        <td class="txt_right">1,936,000원</td>
        <td class="txt_right">1,742,400원</td>
      </tr>
      <tr>
        <th scope="row" rowspan="2">사용시설</th>
        <td class="txt_left">별표 1 제3호 나목19)에 해당되지 않는 경우</td>
        <td class="txt_right">2,541,000원</td>
        <td class="txt_right">2,286,900원</td>
      </tr>
      <tr>
        <td class="txt_left">별표 1 제3호 나목19)에 해당되는 경우</td>
        <td class="txt_right">1,408,000원</td>
        <td class="txt_right">1,267,200원</td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="info_box mt_40">
    <strong class="info_title">문의 안내</strong>
    <ul class="dot_list">
      <li>주소 : 인천광역시 서구 환경로 42(경서동 종합환경연구단지) 한국환경공단 폐자원에너지센터 폐자원에너지검사부</li>
      <li>전화 : 032-590-5427</li>
      <li>메일 : srf-fa@keco.or.kr</li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {
    download() {

    }
  }
}
</script>

<style scoped>

</style>