<template>
    <div class="formatted-text">
        1. 개인정보 수집 및 이용 목적<br />
        폐자원에너지 종합정보관리시스템 사용자 등록, 사용자 본인확인, 사용자 신원증명<br />
        <br />
        2. 개인정보 수집 및 이용 항목<br />
        이름, 아이디, 비밀번호, 전화번호, 이메일, 휴대폰번호, 생년월일, CI, DI, 부서, 담당업무, 위탁운영업체 사용자 여부<br />
        <br />
        3. 개인정보의 보유기간<br />
        사용자 탈퇴 요청 시까지(단, 업체 소속 마지막 사용자인 경우 업체 확인 후 처리)<br />
        <br />
        위 개인정보는 개인정보 보호법 제15조제1항제4호에 따라 정보주체와 체결한 계약(약관)을 이행하거나 계약(약관)을 체결하는 과정에서 정보주체의 요청에 따른 조치를 이행하기 위해여 필요한 경우에 해당하여 <strong>동의 없이 수집 및 이용이 가능</strong>합니다.
    </div>
</template>
      
<script>
export default {
    data() {
        return {
        };
    },
    computed: {},
    watch: {},
    created() {
    },
    mounted() {
    },
    methods: {
    }
}
</script>

<style scoped>
.formatted-text {
  white-space: pre-wrap; /* 띄어쓰기와 줄바꿈을 그대로 유지 */
}

table {
        border-collapse: collapse;
}

td {
    border: 1px solid #000;
    text-align: center;
    vertical-align: middle;
    font-size: 18px;
}
</style>