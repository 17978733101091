<template>

  <h2 class="title1 mt_0">회수통계 조사</h2>
  <div class="board_write mb_25" v-show="isMounted">
    <table>
        <caption>회수통계 조사</caption>
        <colgroup>
            <col style="width:18%">
            <col style="width:32%">
            <col style="width:18%">
            <col style="width:32%">
        </colgroup>
        <tbody>
            <tr>
                <th scope="row">조사년도</th>
                <td>{{exmnParam.exmnYr}}</td>
                <th scope="row">조사기간</th>
                <td>{{ exmnParam.exmnBgngYmd }} ~ {{ exmnParam.exmnEndYmd }}</td>
            </tr>
        </tbody>
    </table>
  </div> 

  <div ref="listDiv" style="height: 10px;"/>
  
  <h3 class="title2" v-show="isMounted">회수통계 조사대상</h3>
  <div class="sch_form_wrap" v-show="isMounted">
    <div class="sch_form row2">
      <span>
        <label>업체명</label>
        <br-form-input label="업체명" v-model="searchParam.bzentyNm" panel="search" @enter-keyup="readRtrvlStatsExmnTrgt" />
      </span>
      <span>
        <label>사업자<br/>등록번호</label>
        <br-form-input label="사업자등록번호" v-model="searchParam.brno" panel="search" @enter-keyup="readRtrvlStatsExmnTrgt" />
      </span>
      <span>
        <label>업체 소재지</label>
        <br-form-select label="시도" :options="bzentyCtpvCdList" v-model:value="searchParam.bzentyCtpvCd" @change="(data) => onChangedCtpvCd('BZENTY', data)" />
        <br-form-select label="시군구" :options="bzentySggCdList" v-model:value="searchParam.bzentySggCd" />
      </span>
      <span>
        <label>사업장종류</label>
        <br-form-select label="에너지원" customClass="form_select w45p" groupCd="MBR007" allSelectable v-model:value="searchParam.bplcKndCd" />
      </span>
    </div>
    <div class="btn_area">
      <br-anchor-button role="link" label="검색" size="sm" format="sch" @click="readRtrvlStatsExmnTrgt" />
      <br-anchor-button role="link" label="초기화" size="sm" format="txtN" icon="ico_refresh" @click="readRtrvlStatsExmnTrgtReset" />
    </div>
  </div>

  <!-- 그리드 소계 및 버튼 영역 -->
   <div class="board_info" v-show="isMounted">
    <p class="page">
      <span class="total">전체 <strong>{{ this.rtrvlStatsTrgtCount }}</strong></span>
      <span>페이지 <strong>{{ this.searchParam.page }}/{{ this.lastPage }}</strong></span>
    </p>
    <div>
      <br-anchor-button role="link" label="엑셀다운로드" size="sm" icon="ico_excel" @click="excelDownload"/>
    </div>
  </div>
  <!-- 그리드 컨텐츠 영역 -->
  <div class="board_list scroll" v-show="isMounted">
    <table>
      <caption></caption>
      <colgroup>
        <col style="width:13%;">
        <col style="width:15%;">
        <col style="width:15%;">
        <col style="width:10%;">
        <col style="width:15%;">
        <col style="width:32%;">
        <!--
        <col style="width:10%;">
        <col style="width:8%;">
        <col style="width:5%;">
        <col style="width:5%;">
        <col style="width:5%;">
      -->
      </colgroup>
      <thead>
      <tr>
        <th scope="col">에너지원</th>
        <th scope="col">업체명</th>
        <th scope="col">사업자등록번호</th>
        <th scope="col">대표자명</th>
        <th scope="col">전화번호</th>
        <th scope="col">업체주소</th>
        <!--
        <th scope="col">업체상세주소</th>
        <th scope="col">공공업체여부</th>
        <th scope="col">업체상태</th>
        <th scope="col">사업장상태</th>
        <th scope="col">시설상태</th>
      -->
      </tr>
      </thead>
      <tbody>
        <tr v-if="rtrvlStatsTrgtList.length <= 0" >
          <td colspan="7">
            <div style="display: flex; justify-content: center;">
              <div class="no-data-message">조회조건에 해당하는 데이터가 없습니다.</div>
            </div>
          </td>
        </tr>

        <tr v-else v-for="(item, index) in rtrvlStatsTrgtList" :key="index" @click="readExmnTrgtBplcInfo(item)">
          <td aria-label="에너지원" style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">{{ item.bplcKndCdNm }}</td>
          <td aria-label="업체명">{{ item.bzentyNm }}</td>
          <td aria-label="사업자등록번호" style="text-align: center">{{ item.brno }}</td>
          <td aria-label="대표자명">{{ item.rprsvNm }}</td>
          <td aria-label="전화번호">{{ item.telno }}</td>
          <td aria-label="업체주소">{{ item.bzentyAddr }}</td>
          <!--
          <td aria-label="업체상세주소">{{ item.bzentyDaddr }}</td>
          <td aria-label="공공업체여부">{{ item.pubBzentyYnNm }}</td>
          <td aria-label="업체상태">{{ item.bzentySttsCdNm }}영중</td>
          <td aria-label="사업장상태">{{ item.bplcSttsCdNm }}</td>
          <td aria-label="시설상태">{{ item.fcltSttsCdNm }}</td>
          -->
        </tr>
      </tbody>
    </table>
  </div>
  
  <!-- 그리드 페이징 영역 -->
  <rtrvl-pagination :total="rtrvlStatsTrgtCount" :size="searchParam.size" v-model:page="searchParam.page" v-model:maxPage="lastPage" @clickedPage="readRtrvlStatsExmnTrgtPage" v-show="isMounted"></rtrvl-pagination>

  <div class="info_box" v-show="showMsg">
      <p>{{ msg }}</p>
  </div>

  <div v-show="bplcKndBtnDivShow">
    <div class="" v-for="(row, rowIndex) in bplcBtntList" :key="rowIndex">
      <div class="my_office_tab">
        <a role="link" v-for="(item, index) in row" :key="item.id" class="bplcBtnt-item" :class="{ active: bplcBtnRowIndex === rowIndex && bplcBtnIndex === index}" @click="bplcBtnIndex = index; bplcBtnRowIndex = rowIndex; readExmnTrgtFcltInfo(item);" href="javascript:void(0);" >{{item.bplcNm}}</a>
      </div>    
    </div>
  </div>

  <div ref="workSheetDiv"/>

  <div v-show="fcltDivShow" style="margin-top:10px;">

    <div class="tab_area">
      <ul class="tab" style="justify-content:left;">
        <li v-for="(fclt, index) in fcltList" :key="index" @click="fcltTabIndex = index; fncFcltTabClick(fclt);" :class="{ active: fcltTabIndex === index }">
          <button type="button" class="btn_tab">{{ !fclt.fcltNm  ? '시설' : fclt.fcltNm }}</button>
        </li>
      </ul>

      <component :is="workSheet" :parentData="parentData" v-if="workSheetIf"></component>

    </div>
  </div>
</template>


<script>
/**
 * TODO : 회수통계조사 연구수행기관
 *
 * 작성자 : yw
 * 작성일 : 2024.10.14
 * 버전 : 1.0
 */

import SrfMnftrRslt from "@/views/prfmnc-rpt/components/workSheet/SrfMnftrRslt.vue"; //SRF 제조
import SrfMnftrRsltSrf from "@/views/prfmnc-rpt/components/workSheet/SrfMnftrRsltSrf.vue"; //SRF
import SrfMnftrRsltBio from "@/views/prfmnc-rpt/components/workSheet/SrfMnftrRsltBio.vue"; //Bio-SRF

import SrfIncmRslt from "@/views/prfmnc-rpt/components/workSheet//SrfIncmRslt.vue"; //SRF 수입
import SrfUseRslt from "@/views/prfmnc-rpt/components/workSheet//SrfUseRslt.vue"; //SRF 사용
import FireRslt from "@/views/prfmnc-rpt/components/workSheet//FireRslt.vue";   //소각열
import BioGasRslt from "@/views/prfmnc-rpt/components/workSheet//BioGasRslt.vue"; //바이오가스
import FillGasRslt from "@/views/prfmnc-rpt/components/workSheet//FillGasRslt.vue"; //매립가스
import PrlsslRslt from "@/views/prfmnc-rpt/components/workSheet//PrlsslRslt.vue"; //열분해유
import RgnrtnFlolRslt from "@/views/prfmnc-rpt/components/workSheet//RgnrtnFlolRslt.vue"; //재생연료유
import RfndflRslt from "@/views/prfmnc-rpt/components/workSheet//RfndflRslt.vue"; //정제연료유
import CemntRstklnAsstFuelRslt from "@/views/prfmnc-rpt/components/workSheet//CemntRstklnAsstFuelRslt.vue"; //시멘트소성로
import OrgnicSldgFuelRslt from "@/views/prfmnc-rpt/components/workSheet//OrgnicSldgFuelRslt.vue"; //유기성 오니
import SynthGasRslt from "@/views/prfmnc-rpt/components/workSheet//SynthGasRslt.vue"; //합성가스

import rtrvlPagination from "@/views/prfmnc-rpt/components/rtrvlPagination.vue";

const API = {
  CTPV_LIST: "/sgg/ctpv-list",
  SGG_LIST: "/sgg/sgg-list/{ctpvCd}",
  RTRVLSTATS_EXMN_YN: "/rtrvlStatsExmn/readRtrvlStatsExmnYn", 
  RTRVLSTATS_EXMN: "/rtrvlStatsExmn",//회수통계조사 조회
  RTRVLSTATS_EXMN_TRGT: "/rtrvlStatsExmn/readRtrvlStatsExmnTrgt",//회수통계 조사대상 조회(업체)
  RTRVLSTATS_EXMN_TRGT_BPLC: "/rtrvlStatsExmn/readRtrvlStatsExmnTrgtBplc", //회수통계 조사대상 조회(사업장)
  RTRVLSTATS_EXMN_TRGT_FCLT: "/rtrvlStatsExmn/readRtrvlStatsExmnTrgtFclt", //회수통계 조사대상 조회(시설)
  RTRVLSTATS_EXCEL_DOWN: "/rtrvlStatsExmn/excelDownload",
}

const PAGE_SIZE = 10;

export default {
  components: {rtrvlPagination, SrfMnftrRslt, SrfMnftrRsltSrf, SrfMnftrRsltBio, SrfIncmRslt, SrfUseRslt, FireRslt, BioGasRslt, FillGasRslt, PrlsslRslt, RgnrtnFlolRslt, RfndflRslt, CemntRstklnAsstFuelRslt, OrgnicSldgFuelRslt, SynthGasRslt},
  data() {
    return {
      isMounted: false,
      showMsg: false,
      mag: '',
      exmnParam: {
        exmnYr: '',
        exmnBgngYmd: '',
        exmnEndYmd: '',
      },
      bzentyCtpvCdList: [],
      bzentySggCdList: [],
      searchParam : {
        rtrvlStatsSn: '',
        bzentyNm: '',
        brno: '',
        bzentyCtpvCd : '',
        bzentySggCd: '',
        bplcKndCd: '',
        page: 1,
        instYn: 'Y',
      },
      lastPage: 1,
      rtrvlStatsSn: '',
      workSheet: '',
      parentData: {},
      bplcBtnIndex: 0,
      bplcBtnRowIndex: 0,
      bplcList: [],
      fcltList: [],
      fcltTabIndex: 0,
      workSheetIf: false,
      rtrvlStatsTrgtCount: 0,
      rtrvlStatsTrgtList: [],
    };
  },
  computed: {
    bplcBtntList() {
      const size = 6;
      let array = [];
      for (let i = 0; i < this.bplcList.length; i += size) {
        array.push(this.bplcList.slice(i, i + size));
      }
      return array;
    },
  },
  watch: {},
  created() {
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      // 시도 select 초기화
      this.loadCtpvCdList();
      this.bzentySggCdList = [{ text: "전체", value: "" }];
      this.bplcSggCdList = [{ text: "전체", value: "" }];

      //회수통계조사 있는지 조회
      this.readRtrvlStatsExmnYn();
      
    },

    /**회수통계가 있는지 여부 조회 있으면 rtrvlStatsSn, bzentyId 받아와서 나머지 진행  */
    readRtrvlStatsExmnYn() {
      this.$apiCall.get(API.RTRVLSTATS_EXMN_YN, {instYn: 'Y'}, this.readRtrvlStatsExmnYnExmnSuccess, this.readRtrvlStatsExmnYnExmnError);
    },

    /**회수통계조사 조회 성공 */
    readRtrvlStatsExmnYnExmnSuccess(data){
      if (data.result) {
        console.log(JSON.stringify(data.result));

        if(data.result.code == "00"){
          this.isMounted = true;
          this.rtrvlStatsSn = data.result.rtrvlStatsSn;
          this.searchParam.rtrvlStatsSn = data.result.rtrvlStatsSn;
          this.readRtrvlStatsExmn();
        } else {
          this.showMsg = true;
          this.msg = data.result.msg;
        }
      }
    },

    /**회수통계조사 조회 실패 */
    readRtrvlStatsExmnYnExmnError(){
      alert('warning', "데이터를 가져오는 중 오류가 발생했습니다.");
    },

    /**시도 코드목록 조회 */
    loadCtpvCdList() {
      this.bzentyCtpvCdList = [{ text: "전체", value: "" }];
      const bzentyCtpvList = this.$store.getters.getSggList({ type: 'ctpv' });
      for(let ctpv of bzentyCtpvList){
        this.bzentyCtpvCdList.push({value: ctpv.sggStdgCd, text: ctpv.sggNm});
      }
    },

    /**시군구 코드목록 조회 */
    loadSggCdList(calledBy, ctpvCd) {
      if(calledBy === 'BZENTY') {
        this.searchParam.bzentySggCd = '';
        this.bzentySggCdList = [{ text: "전체", value: "" }];
        const bzentySggList = this.$store.getters.getSggList({ upSggStdgCd: ctpvCd });
        for(let ctpv of bzentySggList){
          this.bzentySggCdList.push({value: ctpv.sggStdgCd, text: ctpv.sggNm});
        }
      }
    },

    /**시도코드 변경 이벤트 */
    onChangedCtpvCd(calledBy, data) {
      this.loadSggCdList(calledBy, data.value);
    },

    /**회수통계조사 조회 */
    readRtrvlStatsExmn(){
      this.$apiCall.get(API.RTRVLSTATS_EXMN, {rtrvlStatsSn: this.rtrvlStatsSn}, this.readRtrvlStatsExmnSuccess, this.readRtrvlStatsExmnError);
    },

    /**회수통계조사 조회 성공 */
    readRtrvlStatsExmnSuccess(data){
      if (data.result) {
        this.exmnParam = data.result;
        this.readRtrvlStatsExmnTrgt();
      }
    },

    /**회수통계조사 조회 실패 */
    readRtrvlStatsExmnError(){
      alert('warning', "데이터를 가져오는 중 오류가 발생했습니다.");
    },

    /**회수통계조사 대상 검색 */
    readRtrvlStatsExmnTrgt() {
      this.searchParam.size = PAGE_SIZE;
      this.searchParam.page = 1;
      this.$apiCall.get(API.RTRVLSTATS_EXMN_TRGT, this.searchParam, data => {
        if (data.result.list) {
            this.rtrvlStatsTrgtCount = data.result.total;
            this.rtrvlStatsTrgtList = data.result.list;
          } else {
            this.rtrvlStatsTrgtList = [];
          }
      });
    },

    readRtrvlStatsExmnTrgtPage() {
      this.searchParam.size = PAGE_SIZE;
      this.$apiCall.get(API.RTRVLSTATS_EXMN_TRGT, this.searchParam, data => {
        if (data.result.list) {
            this.rtrvlStatsTrgtCount = data.result.total;
            this.rtrvlStatsTrgtList = data.result.list;
          } else {
            this.rtrvlStatsTrgtList = [];
          }
      });
    },

    /**회수통계조사 대상 초기화 */
    readRtrvlStatsExmnTrgtReset() {
      this.searchParam.bzentyNm = "";
      this.searchParam.brno = "";
      this.searchParam.bzentyCtpvCd = "";
      this.searchParam.bzentySggCd = "";
      this.searchParam.bplcKndCd = "";

      this.readRtrvlStatsExmnTrgt();
    },

    /**조사대상 그리드 더블클릭 */
    readExmnTrgtBplcInfo(data){

      if(data){
        this.bplcKndBtnDivShow = false;
        this.fcltDivShow = false;
        this.workSheet= "";
        this.workSheetIf= false;
        this.bplcBtnIndex = 0;
        this.bplcList = [];
        this.fcltTabIndex = 0;
        this.fcltList= [];
      }
      
      this.$apiCall.get(API.RTRVLSTATS_EXMN_TRGT_BPLC, {rtrvlStatsSn: this.rtrvlStatsSn, bzentyId: data.bzentyId, instYn: 'Y'}, this.readExmnTrgtBplcInfoSuccess, this.readExmnTrgtBplcInfoError);
    },

    /**조사대상 그리드 더블클릭 성공 */
    readExmnTrgtBplcInfoSuccess(data){
      if (data.result.list.length > 0) {

        this.bplcList = data.result.list; /**사업장 버튼 생성 */
        this.bplcKndBtnDivShow = true;
        this.fcltDivShow = false;
        this.workSheet = "";
        this.workSheetIf= false;

        if(data.result.list[0].bzentyId && data.result.list[0].bplcId){
          this.readExmnTrgtFcltInfo(data.result.list[0]); /**첫번째 사업장 클릭 */
        }
      }
    },

    /**조사대상 그리드 더블클릭 실패 */
    readExmnTrgtBplcInfoError(){
      alert('warning', "데이터를 가져오는 중 오류가 발생했습니다.");
    },

    /**사업장 버튼 클릭 이벤트 */
    readExmnTrgtFcltInfo(data){
      if(data){
        if(data.bzentyId && data.bplcId){
          this.fcltDivShow = false;
          this.workSheet = "";
          this.workSheetIf= false;
          this.fcltTabIndex = 0;
          this.fcltList= [];

          this.$apiCall.get(
            API.RTRVLSTATS_EXMN_TRGT_FCLT, 
            {rtrvlStatsSn: this.rtrvlStatsSn, bzentyId: data.bzentyId, bplcId: data.bplcId, bplcKndCd: data.bplcKndCd}, 
            this.readExmnTrgtFcltInfoSuccess, 
            this.readExmnTrgtFcltInfoError
          );
        }
      }
    },
    /**사업장 버튼 클릭 이벤트 성공 */
    readExmnTrgtFcltInfoSuccess(data){
      if (data.result.list.length > 0) {
        this.fcltDivShow = true;
        this.fcltList = data.result.list; //시설 탭 생성

        //첫번째 시설 탭 클릭
         this.parentData={
           rtrvlStatsSn: this.rtrvlStatsSn, 
           bzentyId: data.result.list[0].bzentyId, 
           bplcId: data.result.list[0].bplcId, 
           fcltSqno: data.result.list[0].fcltSqno,
           bplcKndCd: data.result.list[0].bplcKndCd,
           mnftrPrdctKndCd: data.result.list[0].mnftrPrdctKndCd,
           updateYn: 'Y',
         };
        
         this.workSheet = data.result.list[0].tabNm;
         this.workSheetIf= true;
      }
    },
    /**사업장 버튼 클릭 이벤트 실패 */
    readExmnTrgtFcltInfoError(){
      alert('warning', "데이터를 가져오는 중 오류가 발생했습니다.");
    },

    /**시설탭 클릭 이벤트 */
    fncFcltTabClick(data){
      this.workSheet = "";
      this.workSheetIf= false;
      if(data){

        this.parentData={
            rtrvlStatsSn: this.rtrvlStatsSn, 
            bzentyId: data.bzentyId, 
            bplcId: data.bplcId, 
            fcltSqno: data.fcltSqno,
            bplcKndCd: data.bplcKndCd,
            mnftrPrdctKndCd: data.mnftrPrdctKndCd,
            updateYn: 'Y',
        };

        this.workSheet = data.tabNm;
        this.$nextTick(() => { 
          this.workSheetIf = true; // 다시 데이터 변경
        });
      }
    },

    excelDownload(){
      this.$apiCall.download(API.RTRVLSTATS_EXCEL_DOWN, {fileName : `회수통계조사 대상목록.xlsx`,...this.searchParam,});
    },

  }
}
</script>

<style scoped>
.bplcBtnt-item {
  width: calc(100% / 6); /* 한 행에 6개의 체크박스를 표시 */
}
.board_list.scroll > table { table-layout: fixed; width:120rem;}

.no-data-message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  margin: 20px 0px;
  border-radius: 10px;
  background-color: #f7f7f7;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 350px;
}

.no-data-message::before { content:''; width:2rem; height:2rem; background:url(@/assets/images/common/icon_search.png) no-repeat; background-size: 2rem; margin-right: 8px;}

.board_list.scroll > table tbody tr:hover{ background:var(--bg-gray10)}

</style>