<template>
    <div class="formatted-text">
        1. 개인정보의 수집 및 이용 항목<br />
        ㅇ 선택항목 : 직급/직책, 알림수신채널 여부
        <br />
        <br />
        2. 개인정보의 수집· 이용 목적<br />
        사용자 직급/직책 확인
        <br />
        <br />
        3. 개인정보의 보유 및 이용 기간<br />
        <strong style="color: blue; text-decoration: underline;">사용자 탈퇴 요청 시까지</strong> (단, 업체 소속 마지막 사용자인 경우 업체 확인 후 처리)<br />
        <br />
        <br />
        위 개인정보는 선택사항으로 동의를 거부하실 수 있습니다. 동의를 거부하실 경우에도 사용자 등록은 가능합니다.
    </div>
</template>
      
<script>
export default {
    data() {
        return {
        };
    },
    computed: {},
    watch: {},
    created() {
    },
    mounted() {
    },
    methods: {
    }
}
</script>

<style scoped>
.formatted-text {
  white-space: pre-wrap; /* 띄어쓰기와 줄바꿈을 그대로 유지 */
}

table {
        border-collapse: collapse;
}

td {
    border: 1px solid #000;
    text-align: center;
    vertical-align: middle;
    font-size: 18px;
}
</style>