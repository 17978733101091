<template>
  <template v-if="isAdminUser">
    <div class="cont_section"
       :style="{ paddingBottom: '20rem', background: `url(${require('@/assets/images/sub/bg_orgCht.png')}) no-repeat bottom center`, backgroundSize: '32rem' }">
      <div class="desc_box admin_user">
        <div>
          <h3>현재 업무담당자 권한으로 로그인 중이 십니다. 업무 페이지로 이동하시려면 아래 버튼을 클릭하세요.</h3>
        </div>
      </div>
      <div class="admin_user">
        <button type="button" class="btn" @click="moveAdmin">업무페이지 접속하기</button>
      </div>
    </div>
  </template>
  <template v-else>
    <!-- 최상단 소계 영역 -->
    <ul class="my_office_bn">
      <li>
        <i class="icon1"></i>
        <span>나의 신청 현황 <strong><a href="javascript:void(0);" role="link" @click.prevent="scrollTo('APLY')">총 <mark>{{ this.myAplyTotCnt }}</mark>건</a></strong></span>
      </li>
      <li>
        <i class="icon2"></i>
        <span>처리가 필요한 업무 <strong><a href="javascript:void(0);" role="link" @click.prevent="scrollTo('TASK')">총 <mark>{{ this.myTaskTotCnt }}</mark>건</a></strong></span>
      </li>
      <li>
        <i class="icon3"></i>
        <span>최근 검사진행 건수 <strong><a href="javascript:void(0);" role="link" @click.prevent="scrollTo('INSP')">총 <mark>{{ this.myInspTotCnt }}</mark>건</a></strong></span>
      </li>
    </ul>
    <!-- 나의 신청 현황 영역 -->
    <div ref="myAplyList" style="height: 10px;"/>
    <h2 class="title1 mt_0">나의 신청 현황 <span>(총 <span class="txt_point">{{ this.myAplyTotCnt }}</span>건)</span></h2>
    <div class="my_office_tab">
      <a href="javascript:void(0);" role="link" :class="{active: aplyType === 'ALL'}" @click.prevent="onClickedAplyType('ALL')">전체</a>
      <a href="javascript:void(0);" role="link" :class="{active: aplyType === 'FCLT'}" @click.prevent="onClickedAplyType('FCLT')">정기검사</a>
      <a href="javascript:void(0);" role="link" :class="{active: aplyType === 'QLTY'}" @click.prevent="onClickedAplyType('QLTY')">품질검사</a>
      <a href="javascript:void(0);" role="link" :class="{active: aplyType === 'PRFMNC'}" @click.prevent="onClickedAplyType('PRFMNC')">실적보고</a>
    </div>
    <ul class="my_office_list" ref="myAplyListScroll">
      <!-- 보여줄 카드가 없을 때 -->
      <li class="no-data" v-if="myAplyInspList.length <= 0 && myAplyPrfmncList.length <= 0">
        <div class="no-data-message">나의 신청 내역이 없습니다.</div>
      </li>
      <!-- 카드 출력 -->
      <template v-else>
        <!-- 시설/품질검사 카드 -->
        <li v-for="(item, index) in myAplyInspList" :key="index">
          <div class="office_top">
            <div class="info">
              <a href="javascript:void(0);" role="link" class="title">{{ item.inspClsfNm }} > {{ item.inspSeNm }}</a>
              <span>신청일<strong>{{ item.inspAplyYmd }}(<span class="txt_point">D+{{ item.elapsedDay }}</span>)</strong></span>
              <span>{{ item.bplcKndNm }}<strong>{{ item.fcltNm }}</strong></span>
            </div>
            <ol class="step">
              <li :class="{active: item.inspPrgrsSttsCd === '10'}"><i>01</i>신청</li>
              <li :class="{active: item.inspPrgrsSttsCd === '20'}"><i>02</i>서류검토중</li>
              <li :class="{active: item.inspPrgrsSttsCd === '30'}"><i>03</i>서류보완요청</li>
              <li :class="{active: item.inspPrgrsSttsCd === '31'}"><i>04</i>서류보완</li>
              <li :class="{active: item.inspPrgrsSttsCd === '40'}"><i>05</i>접수</li>
              <li :class="{active: item.inspPrgrsSttsCd === '60'}"><i>06</i>검사진행중</li>
              <li :class="{active: item.inspPrgrsSttsCd === '70'}"><i>07</i>완료</li>
            </ol>
          </div>
          <div class="office_body">
            <div class="office_txt">
              <span class="step"><i>{{ getStep('INSP', item.inspPrgrsSttsCd).phase }}</i>{{ getStep('INSP', item.inspPrgrsSttsCd).step }}</span>
              <p>{{ getStep('INSP', item.inspPrgrsSttsCd).desc }}</p>
            </div>
            <div class="txt_desc_box">
              <template v-if="item.inspPrgrsSttsCd === '10'">
                <span><strong>검사희망일자: </strong>{{ item.inspHopeYmd }}</span>
              </template>
              <template v-if="item.inspPrgrsSttsCd === '20'">
                <span><strong>검사희망일자: </strong>{{ item.inspHopeYmd }}</span>
                <span><strong>서류검토일자: </strong>{{ item.prcsYmd }}</span>
                <span><strong>서류검토자: </strong>{{ item.prcsUserNm }}</span>
              </template>
              <template v-if="item.inspPrgrsSttsCd === '30'">
                <span><strong>검사희망일자: </strong>{{ item.inspHopeYmd }}</span>
                <span><strong>서류보완요청일자: </strong>{{ item.prcsYmd }}</span>
                <span><strong>서류보완사유: </strong>{{ item.taskPrcsRsn }}</span>
              </template>
              <template v-if="item.inspPrgrsSttsCd === '31'">
                <span><strong>재신청일자: </strong>{{ item.inspAplyYmd }}</span>
                <span><strong>검사희망일자: </strong>{{ item.inspHopeYmd }}</span>
              </template>
              <template v-if="item.inspPrgrsSttsCd === '40'">
                <span><strong>검사희망일자: </strong>{{ item.inspHopeYmd }}</span>
                <span><strong>접수일자: </strong>{{ item.prcsYmd }}</span>
                <span><strong>검사(예정)일자: </strong>{{ item.inspPrnmntYmd ? item.inspPrnmntYmd : '미정' }}</span>
                <span><strong>검사원: </strong>{{ item.inspectors ? item.inspectors : '미정' }}</span>
              </template>
              <template v-if="item.inspPrgrsSttsCd === '60'">
                <span><strong>검사희망일자: </strong>{{ item.inspHopeYmd }}</span>
                <span><strong>검사(예정)일자: </strong>{{ item.inspPrnmntYmd ? item.inspPrnmntYmd : '미정' }}</span>
                <span><strong>검사원: </strong>{{ item.inspectors ? item.inspectors : '미정' }}</span>
              </template>
            </div>
          </div>
        </li>
        <!-- 실적보고 카드 -->
        <li v-for="(item, index) in myAplyPrfmncList" :key="index">
          <div class="office_top">
            <div class="info">
              <a href="javascript:void(0);" class="title">실적보고</a>
              <span v-if="['SUB', 'REC', 'APR', 'REJ'].includes(item.prfmncSttsCd)">제출일<strong>{{ item.sbmsnYmd }}(<span class="txt_point">D+{{ item.elapsedDay }}</span>)</strong></span>
              <span v-if="item.prfmncSttsCd === 'REC'">접수일<strong>{{ item.mdfcnDt }}</strong></span>
              <span v-if="item.prfmncSttsCd === 'APR'">승인일<strong>{{ item.aprvYmd }}(<span class="txt_point">D+{{ item.elapsedDay }}</span>)</strong></span>
              <span v-if="item.prfmncSttsCd === 'REJ'">반려일<strong>{{ item.rjctYmd }}(<span class="txt_point">D+{{ item.elapsedDay }}</span>)</strong></span>
              <span>{{ item.bplcKndNm }}<strong>{{ item.bplcNm }}</strong></span>
            </div>
            <ol class="step">
              <li :class="{active: item.prfmncSttsCd === 'IPG'}"><i>01</i>작성중</li>
              <li :class="{active: item.prfmncSttsCd === 'SUB'}"><i>02</i>제출</li>
              <li :class="{active: item.prfmncSttsCd === 'REC'}"><i>03</i>접수</li>
              <li :class="{active: ['APR', 'REJ'].includes(item.prfmncSttsCd)}"><i>04</i>승인/반려</li>
            </ol>
          </div>
          <div class="office_body">
            <div class="office_txt">
              <span class="step"><i>{{ getStep('PRFMNC', item.prfmncSttsCd).phase }}</i>{{ getStep('PRFMNC', item.prfmncSttsCd).step }}</span>
              <p>{{ getStep('PRFMNC', item.prfmncSttsCd).desc }}</p>
            </div>
            <div class="txt_desc_box">
              <template v-if="item.prfmncSttsCd === 'IPG'">
                <span><strong>실적년월: </strong>{{ item.prfmncYm }}</span>
                <span><strong>합계 반입량: </strong>{{ item.sumCryqty.toLocaleString() }}</span>
                <span v-if="item.bplcKndCd !== '01-03'"><strong>합계 생산량: </strong>{{ item.sumPrdqty.toLocaleString() }}</span>
                <span><strong>합계 사용/판매량: </strong>{{ item.sumUseNsqty.toLocaleString() }}</span>
              </template>
              <template v-if="item.prfmncSttsCd === 'SUB'">
                <span><strong>실적년월: </strong>{{ item.prfmncYm }}</span>
                <span><strong>합계 반입량: </strong>{{ item.sumCryqty.toLocaleString() }}</span>
                <span v-if="item.bplcKndCd !== '01-03'"><strong>합계 생산량: </strong>{{ item.sumPrdqty.toLocaleString() }}</span>
                <span><strong>합계 사용/판매량: </strong>{{ item.sumUseNsqty.toLocaleString() }}</span>
              </template>
              <template v-if="item.prfmncSttsCd === 'REC'">
                <span><strong>실적년월: </strong>{{ item.prfmncYm }}</span>
                <span><strong>합계 반입량: </strong>{{ item.sumCryqty.toLocaleString() }}</span>
                <span v-if="item.bplcKndCd !== '01-03'"><strong>합계 생산량: </strong>{{ item.sumPrdqty.toLocaleString() }}</span>
                <span><strong>합계 사용/판매량: </strong>{{ item.sumUseNsqty.toLocaleString() }}</span>
              </template>
              <template v-if="item.prfmncSttsCd === 'APR'">
                <span><strong>실적년월: </strong>{{ item.prfmncYm }}</span>
                <span><strong>합계 반입량: </strong>{{ item.sumCryqty.toLocaleString() }}</span>
                <span v-if="item.bplcKndCd !== '01-03'"><strong>합계 생산량: </strong>{{ item.sumPrdqty.toLocaleString() }}</span>
                <span><strong>합계 사용/판매량: </strong>{{ item.sumUseNsqty.toLocaleString() }}</span>
                <span><strong>승인일자: </strong>{{ item.aprvYmd }}</span>
              </template>
              <template v-if="item.prfmncSttsCd === 'REJ'">
                <span><strong>실적년월: </strong>{{ item.prfmncYm }}</span>
                <span><strong>합계 반입량: </strong>{{ item.sumCryqty.toLocaleString() }}</span>
                <span v-if="item.bplcKndCd !== '01-03'"><strong>합계 생산량: </strong>{{ item.sumPrdqty.toLocaleString() }}</span>
                <span><strong>합계 사용/판매량: </strong>{{ item.sumUseNsqty.toLocaleString() }}</span>
                <span><strong>반려일자: </strong>{{ item.rjctYmd }}</span>
                <span><strong>반려사유: </strong>{{ item.rjctRsn }}</span>
              </template>
            </div>
          </div>
        </li>
      </template>
      <!-- 로딩바 -->
      <br-local-loading :isOn="isLoadingMyAplyList" />
    </ul>
    <!-- 처리가 필요한 업무 영역 -->
    <div ref="myTaskList" style="height: 10px"/>
    <h2 class="title1" style="margin-top: 0;">처리가 필요한 업무 <span>(총 <span class="txt_point">{{ this.myTaskTotCnt }}</span>건)</span></h2>
    <div class="my_office_tab">
      <a href="javascript:void(0);" role="link" :class="{active: taskType === 'ALL'}" @click.prevent="onClickedTaskType('ALL')">전체</a>
      <a href="javascript:void(0);" role="link" :class="{active: taskType === 'FCLT'}" @click.prevent="onClickedTaskType('FCLT')">정기검사</a>
      <a href="javascript:void(0);" role="link" :class="{active: taskType === 'QLTY'}" @click.prevent="onClickedTaskType('QLTY')">품질검사</a>
      <a href="javascript:void(0);" role="link" :class="{active: taskType === 'PRFMNC'}" @click.prevent="onClickedTaskType('PRFMNC')">실적보고</a>
      <a href="javascript:void(0);" role="link" :class="{active: taskType === 'RTRVL'}" @click.prevent="onClickedTaskType('RTRVL')">회수통계</a>
    </div>
    <div class="my_office_list office_swiper">
      <template v-if="0 < myTaskTotCnt && myTaskTotCnt <= 3">
        <div class="swiper-wrapper" aria-live="polite">
          <!-- 정기검사 > 최초검사 -->
          <div v-for="(item, index) in myTaskListForFcltInspFirst" :key="`FCLT-FST-${index}`" class="swiper-slide">
            <a href="javascript:void(0);" role="group" style="width: 326.667px; margin-right: 20px;">
              <div class="office_top">
                <div class="info">
                  <span class="title">정기검사</span>
                  <span class="badge">최초검사</span>
                  <span><strong>{{ item.bplcNm }}</strong> {{ item.fcltNm }}</span>
                </div>
              </div>
              <div class="office_body">
                <div class="office_txt">신규 시설 등록 시, 최초검사를 진행해야만 해당 시설을 사용할 수 있습니다.</div>
                <div class="txt_desc_box my_task">
                  <span><strong>시설 준공일자: </strong>{{ item.fcltCmcnYmd }}</span>
                  <span><strong>최초검사 결과: </strong>{{ getDisplayText(item.frstInspPassYn) }}</span>
                </div>
              </div>
              <div class="btn_area center m0 mt_5"><span class="btn xsm" @click="goToWork('FCLT-FST', item)">업무처리 하러가기 →</span></div>
            </a>
          </div>
          <!-- 정기검사 > 계속검사 -->
          <div v-for="(item, index) in myTaskListForFcltInspContinue"  :key="`FCLT-CTN-${index}`" class="swiper-slide">
            <a href="javascript:void(0);" role="group" style="width: 326.667px; margin-right: 20px;">
              <div class="office_top">
                <div class="info">
                  <span class="title">정기검사</span>
                  <span class="badge">계속검사</span>
                  <span><strong>{{ item.bplcNm }}</strong> {{ item.fcltNm }}</span>
                </div>
              </div>
              <div class="office_body">
                <div class="office_txt">최초검사 이후, 1년을 주기로 시설에 대한 계속검사를 진행해야 합니다. 곧 검사기간이니 검사신청을 진행해주세요.</div>
                <div class="txt_desc_box my_task">
                  <span><strong>최초 검사일자: </strong>{{ item.frstInspYmd }}</span>
                  <span><strong>마지막 검사일자: </strong>{{ item.inspEndYmd }}</span>
                  <span><strong>계속검사 마감일자: </strong>{{ item.dueDate }}</span>
                </div>
              </div>
              <div class="btn_area center m0 mt_5"><span class="btn xsm" @click="goToWork('FCLT_CTN', item)">업무처리 하러가기 →</span></div>
            </a>
          </div>
          <!-- 정기검사 > 변경검사 -->
          <div v-for="(item, index) in myTaskListForFcltInspChange" :key="`FCLT-CHG-${index}`" class="swiper-slide">
            <a href="javascript:void(0);" role="group" style="width: 326.667px; margin-right: 20px;">
              <div class="office_top">
                <div class="info">
                  <span class="title">정기검사</span>
                  <span class="badge">변경검사</span>
                  <span><strong>{{ item.bplcNm }}</strong> {{ item.fcltNm }}</span>
                </div>
              </div>
              <div class="office_body">
                <div class="office_txt">품질에 영향을 주는 시설정보가 변경되거나, "가동중지/폐쇄 → 가동중" 시설상태 변경 시, 변경검사가 필요합니다.</div>
                <div class="txt_desc_box my_task">
                  <span><strong>변경검사 대상지정일자: </strong>{{ item.chgInspTrgtDsgnYmd }}</span>
                  <span><strong>이전 상태: </strong>{{ item.prevFcltSttsNm }}</span>
                  <span><strong>현재 상태: </strong>{{ item.fcltSttsNm }}</span>
                </div>
              </div>
              <div class="btn_area center m0 mt_5"><span class="btn xsm" @click="goToWork('FCLT-CHG', item)">업무처리 하러가기 →</span></div>
            </a>
          </div>
          <!-- 품질검사 > 최초검사 -->
          <div v-for="(item, index) in myTaskListForQltyInspFirst" :key="`QLTY-FST-${index}`" class="swiper-slide">
            <a href="javascript:void(0);" role="group" style="width: 326.667px; margin-right: 20px;">
              <div class="office_top">
                <div class="info">
                  <span class="title">품질검사</span>
                  <span class="badge">최초검사</span>
                  <span><strong>{{ item.bplcNm }}</strong> {{ item.fcltNm }}</span>
                </div>
              </div>
              <div class="office_body">
                <div class="office_txt">신규 시설 등록 시, 최초검사를 진행해야만 해당 시설을 사용할 수 있습니다.</div>
                <div class="txt_desc_box my_task">
                  <span><strong>시설 준공일자: </strong>{{ item.fcltCmcnYmd }}</span>
                  <span><strong>최초검사 결과: </strong>{{ getDisplayText(item.frstInspPassYn) }}</span>
                </div>
              </div>
              <div class="btn_area center m0 mt_5"><span class="btn xsm" @click="goToWork('QLTY-FST', item)">업무처리 하러가기 →</span></div>
            </a>
          </div>
          <!-- 품질검사 > 개선명령 -->
          <div v-for="(item, index) in myTaskListForQltyInspImprove" :key="`QLTY-IPRV-${index}`" class="swiper-slide">
            <a href="javascript:void(0);" role="group" style="width: 326.667px; margin-right: 20px;">
              <div class="office_top">
                <div class="info">
                  <span class="title">품질검사</span>
                  <span class="badge">개선검사</span>
                  <span><strong>{{ item.bplcNm }}</strong> {{ item.fcltNm }}</span>
                </div>
              </div>
              <div class="office_body">
                <div class="office_txt">확인검사에서 적합 판정을 받지 못하였으나, 적정한 조치를 취하지 않아 개선명령을 받은 상태입니다. 상세내용을 확인해주세요.</div>
                <div class="txt_desc_box my_task">
                  <span><strong>마지막 검사결과: </strong>{{ item.inspRsltNm }}</span>
                  <span><strong>개선명령일자: </strong>{{ item.admdspYmd }}</span>
                  <span><strong>이행확인 예정일자: </strong>{{ item.impltRsltIdntyPrnmntYmd }}</span>
                </div>
              </div>
              <div class="btn_area center m0 mt_5"><span class="btn xsm" @click="goToWork('QLTY-IPRV', item)">업무처리 하러가기 →</span></div>
            </a>
          </div>
          <!-- 검사공통 > 서류보완 -->
          <div v-for="(item, index) in myTaskListForInspCommSupplement" :key="`ISPC-SPLM-${index}`" class="swiper-slide">
            <a href="javascript:void(0);" role="group" style="width: 326.667px; margin-right: 20px;">
              <div class="office_top">
                <div class="info">
                  <span class="title">검사공통</span>
                  <span class="badge">서류보완</span>
                  <span><strong>{{ item.bplcNm }}</strong> {{ item.fcltNm }}</span>
                </div>
              </div>
              <div class="office_body">
                <div class="office_txt">검사신청 서류 검토 중 보완사항이 발견되어 서류보완요청이 들어온 상태입니다. 내용 확인 후 보완 작업을 진행해주세요.</div>
                <div class="txt_desc_box my_task">
                  <span><strong>서류보완요청일자: </strong>{{ item.prcsYmd }}</span>
                  <span><strong>서류보완요청사유: </strong>{{ item.taskPrcsRsn }}</span>
                </div>
              </div>
              <div class="btn_area center m0 mt_5"><span class="btn xsm" @click="goToWork('ISPC-SPLM', item)">업무처리 하러가기 →</span></div>
            </a>
          </div>
          <!-- 검사공통 > 검사수수료 -->
          <div v-for="(item, index) in myTaskListForInspCommFeePay" :key="`ISPC-FEE-${index}`" class="swiper-slide">
            <a href="javascript:void(0);" role="group" style="width: 326.667px; margin-right: 20px;">
              <div class="office_top">
                <div class="info">
                  <span class="title">검사공통</span>
                  <span class="badge">검사수수료</span>
                  <span><strong>{{ item.bplcNm }}</strong> {{ item.fcltNm }}</span>
                </div>
              </div>
              <div class="office_body">
                <div class="office_txt">검사 수술료가 산정 되었습니다. 수수료가 입금되어야 검사 접수가 가능하오니, 수수료를 입금해주세요.</div>
                <div class="txt_desc_box my_task">
                  <span><strong>검사 수수료: </strong>{{ item.inspFee }}원</span>
                  <span><strong>납부계좌: </strong>{{ item.bankAccount }}</span>
                </div>
              </div>
              <div class="btn_area center m0 mt_5"><span class="btn xsm" @click="goToWork('ISPC-FEE')">업무처리 하러가기 →</span></div>
            </a>
          </div>
          <!-- 실적보고 > 당월등록 -->
          <div v-for="(item, index) in myTaskListForPrfmncRegist" :key="`PRFMNC-RGST-${index}`" class="swiper-slide">
            <a href="javascript:void(0);" role="group" style="width: 326.667px; margin-right: 20px;">
              <div class="office_top">
                <div class="info">
                  <span class="title">실적보고</span>
                  <span class="badge">당월등록</span>
                  <span><strong>{{ item.bplcNm }}</strong> {{ item.fcltNm }}</span>
                </div>
              </div>
              <div class="office_body">
                <div class="office_txt">매월 {{ item.limitDay }}일까지 실적 등록이 필요합니다. 아직 등록된 실적이 없으므로, 실적 등록을 진행해주세요.</div>
                <div class="txt_desc_box my_task">
                  <span><strong>실적 귀속년월: </strong>{{ item.prfmncYm }}</span>
                  <span><strong>실적 등록상태: </strong>{{ item.prfmncSttsNm }}</span>
                  <span><strong>실적 등록기한: </strong>{{ item.dueDate }}</span>
                </div>
              </div>
              <div class="btn_area center m0 mt_5"><span class="btn xsm" @click="goToWork('PRFMNC-RGST', item)">업무처리 하러가기 →</span></div>
            </a>
          </div>
          <!-- 실적보고 > 실적보완 -->
          <div v-for="(item, index) in myTaskListForPrfmncSupplement" :key="`PRFMNC-SPLM-${index}`" class="swiper-slide">
            <a href="javascript:void(0);" role="group" style="width: 326.667px; margin-right: 20px;">
              <div class="office_top">
                <div class="info">
                  <span class="title">실적보고</span>
                  <span class="badge">실적보완</span>
                  <span><strong>{{ item.bplcNm }}</strong> {{ item.fcltNm }}</span>
                </div>
              </div>
              <div class="office_body">
                <div class="office_txt">신청된 당월 신청이 반려되었습니다. 반려사유를 확인하신 뒤, 실적 수정을 진행해주세요.</div>
                <div class="txt_desc_box my_task">
                  <span><strong>실적 귀속년월: </strong>{{ item.prfmncYm }}</span>
                  <span><strong>실적 등록상태: </strong>{{ item.prfmncSttsNm }}</span>
                  <span><strong>반려사유: </strong>{{ item.rjctRsn }}</span>
                </div>
              </div>
              <div class="btn_area center m0 mt_5"><span class="btn xsm" @click="goToWork('PRFMNC-SPLM', item)">업무처리 하러가기 →</span></div>
            </a>
          </div>
          <!-- 회수통계 > 통계작성 -->
          <div v-for="(item, index) in myTaskListForRtrvlStats" :key="`RTRV-STTS-${index}`" class="swiper-slide">
            <a href="javascript:void(0);" role="group" style="width: 326.667px; margin-right: 20px;">
              <div class="office_top">
                <div class="info">
                  <span class="title">회수통계</span>
                  <span class="badge">통계작성</span>
                  <span><strong>{{ item.bplcNm }}</strong> {{ item.fcltNm }}</span>
                </div>
              </div>
              <div class="office_body">
                <div class="office_txt">지금은 회수통계 조사기간 입니다.<br>회수통계 내용을 확인해주세요.</div>
                <div class="txt_desc_box my_task">
                  <span><strong>조사년도: </strong>{{ item.exmnYr }}</span>
                  <span><strong>조사기간: </strong>{{ `${item.exmnBgngYmd} ~ ${item.exmnEndYmd}` }}</span>
                  <span v-if="item.rdmndYn === 'Y'"><strong>재요청사유: </strong>{{ item.rdmndRsnCd }}</span>
                </div>
              </div>
              <div class="btn_area center m0 mt_5"><span class="btn xsm" @click="goToWork('RTRVL', item)">업무처리 하러가기 →</span></div>
            </a>
          </div>
        </div>
      </template>
      <template v-else-if="myTaskTotCnt > 3">
        <div class="swiper-wrapper" aria-live="polite">
          <swiper
              :modules="modules"
              :slidesPerView="3"
              :space-between="30"
              :scrollbar="{hide: false,}"
              :autoHeight="true"
              :pagination="{clickable: false,}"
          >
            <!-- 정기검사 > 최초검사 -->
            <swiper-slide v-for="(item, index) in myTaskListForFcltInspFirst" :key="`FCLT-FST-${index}`">
              <a href="javascript:void(0);" role="group" style="width: 326.667px; margin-right: 20px;">
                <div class="office_top">
                  <div class="info">
                    <span class="title">정기검사</span>
                    <span class="badge">최초검사</span>
                    <span><strong>{{ item.bplcNm }}</strong> {{ item.fcltNm }}</span>
                  </div>
                </div>
                <div class="office_body">
                  <div class="office_txt">신규 시설 등록 시, 최초검사를 진행해야만 해당 시설을 사용할 수 있습니다.</div>
                  <div class="txt_desc_box my_task">
                    <span><strong>시설 준공일자: </strong>{{ item.fcltCmcnYmd }}</span>
                    <span><strong>최초검사 결과: </strong>{{ getDisplayText(item.frstInspPassYn) }}</span>
                  </div>
                </div>
                <div class="btn_area center m0 mt_5"><span class="btn xsm" @click="goToWork('FCLT-FST', item)">업무처리 하러가기 →</span></div>
              </a>
            </swiper-slide>
            <!-- 정기검사 > 계속검사 -->
            <swiper-slide v-for="(item, index) in myTaskListForFcltInspContinue"  :key="`FCLT-CTN-${index}`">
              <a href="javascript:void(0);" role="group" style="width: 326.667px; margin-right: 20px;">
                <div class="office_top">
                  <div class="info">
                    <span class="title">정기검사</span>
                    <span class="badge">계속검사</span>
                    <span><strong>{{ item.bplcNm }}</strong> {{ item.fcltNm }}</span>
                  </div>
                </div>
                <div class="office_body">
                  <div class="office_txt">최초검사 이후, 1년을 주기로 시설에 대한 계속검사를 진행해야 합니다. 곧 검사기간이니 검사신청을 진행해주세요.</div>
                  <div class="txt_desc_box my_task">
                    <span><strong>최초 검사일자: </strong>{{ item.frstInspYmd }}</span>
                    <span><strong>마지막 검사일자: </strong>{{ item.inspEndYmd }}</span>
                    <span><strong>계속검사 마감일자: </strong>{{ item.dueDate }}</span>
                  </div>
                </div>
                <div class="btn_area center m0 mt_5"><span class="btn xsm" @click="goToWork('FCLT_CTN', item)">업무처리 하러가기 →</span></div>
              </a>
            </swiper-slide>
            <!-- 정기검사 > 변경검사 -->
            <swiper-slide v-for="(item, index) in myTaskListForFcltInspChange" :key="`FCLT-CHG-${index}`">
              <a href="javascript:void(0);" role="group" style="width: 326.667px; margin-right: 20px;">
                <div class="office_top">
                  <div class="info">
                    <span class="title">정기검사</span>
                    <span class="badge">변경검사</span>
                    <span><strong>{{ item.bplcNm }}</strong> {{ item.fcltNm }}</span>
                  </div>
                </div>
                <div class="office_body">
                  <div class="office_txt">품질에 영향을 주는 시설정보가 변경되거나, "가동중지/폐쇄 → 가동중" 시설상태 변경 시, 변경검사가 필요합니다.</div>
                  <div class="txt_desc_box my_task">
                    <span><strong>변경검사 대상지정일자: </strong>{{ item.chgInspTrgtDsgnYmd }}</span>
                    <span><strong>이전 상태: </strong>{{ item.prevFcltSttsNm }}</span>
                    <span><strong>현재 상태: </strong>{{ item.fcltSttsNm }}</span>
                  </div>
                </div>
                <div class="btn_area center m0 mt_5"><span class="btn xsm" @click="goToWork('FCLT-CHG', item)">업무처리 하러가기 →</span></div>
              </a>
            </swiper-slide>
            <!-- 품질검사 > 최초검사 -->
            <swiper-slide v-for="(item, index) in myTaskListForQltyInspFirst" :key="`QLTY-FST-${index}`">
              <a href="javascript:void(0);" role="group" style="width: 326.667px; margin-right: 20px;">
                <div class="office_top">
                  <div class="info">
                    <span class="title">품질검사</span>
                    <span class="badge">최초검사</span>
                    <span><strong>{{ item.bplcNm }}</strong> {{ item.fcltNm }}</span>
                  </div>
                </div>
                <div class="office_body">
                  <div class="office_txt">신규 시설 등록 시, 최초검사를 진행해야만 해당 시설을 사용할 수 있습니다.</div>
                  <div class="txt_desc_box my_task">
                    <span><strong>시설 준공일자: </strong>{{ item.fcltCmcnYmd }}</span>
                    <span><strong>최초검사 결과: </strong>{{ getDisplayText(item.frstInspPassYn) }}</span>
                  </div>
                </div>
                <div class="btn_area center m0 mt_5"><span class="btn xsm" @click="goToWork('QLTY-FST', item)">업무처리 하러가기 →</span></div>
              </a>
            </swiper-slide>
            <!-- 품질검사 > 개선명령 -->
            <swiper-slide v-for="(item, index) in myTaskListForQltyInspImprove" :key="`QLTY-IPRV-${index}`">
              <a href="javascript:void(0);" role="group" style="width: 326.667px; margin-right: 20px;">
                <div class="office_top">
                  <div class="info">
                    <span class="title">품질검사</span>
                    <span class="badge">개선검사</span>
                    <span><strong>{{ item.bplcNm }}</strong> {{ item.fcltNm }}</span>
                  </div>
                </div>
                <div class="office_body">
                  <div class="office_txt">확인검사에서 적합 판정을 받지 못하였으나, 적정한 조치를 취하지 않아 개선명령을 받은 상태입니다. 상세내용을 확인해주세요.</div>
                  <div class="txt_desc_box my_task">
                    <span><strong>마지막 검사결과: </strong>{{ item.inspRsltNm }}</span>
                    <span><strong>개선명령일자: </strong>{{ item.admdspYmd }}</span>
                    <span><strong>이행확인 예정일자: </strong>{{ item.impltRsltIdntyPrnmntYmd }}</span>
                  </div>
                </div>
                <div class="btn_area center m0 mt_5"><span class="btn xsm" @click="goToWork('QLTY-IPRV', item)">업무처리 하러가기 →</span></div>
              </a>
            </swiper-slide>
            <!-- 검사공통 > 서류보완 -->
            <swiper-slide v-for="(item, index) in myTaskListForInspCommSupplement" :key="`ISPC-SPLM-${index}`">
              <a href="javascript:void(0);" role="group" style="width: 326.667px; margin-right: 20px;">
                <div class="office_top">
                  <div class="info">
                    <span class="title">검사공통</span>
                    <span class="badge">서류보완</span>
                    <span><strong>{{ item.bplcNm }}</strong> {{ item.fcltNm }}</span>
                  </div>
                </div>
                <div class="office_body">
                  <div class="office_txt">검사신청 서류 검토 중 보완사항이 발견되어 서류보완요청이 들어온 상태입니다. 내용 확인 후 보완 작업을 진행해주세요.</div>
                  <div class="txt_desc_box my_task">
                    <span><strong>서류보완요청일자: </strong>{{ item.prcsYmd }}</span>
                    <span><strong>서류보완요청사유: </strong>{{ item.taskPrcsRsn }}</span>
                  </div>
                </div>
                <div class="btn_area center m0 mt_5"><span class="btn xsm" @click="goToWork('ISPC-SPLM', item)">업무처리 하러가기 →</span></div>
              </a>
            </swiper-slide>
            <!-- 검사공통 > 검사수수료 -->
            <swiper-slide v-for="(item, index) in myTaskListForInspCommFeePay" :key="`ISPC-FEE-${index}`">
              <a href="javascript:void(0);" role="group" style="width: 326.667px; margin-right: 20px;">
                <div class="office_top">
                  <div class="info">
                    <span class="title">검사공통</span>
                    <span class="badge">검사수수료</span>
                    <span><strong>{{ item.bplcNm }}</strong> {{ item.fcltNm }}</span>
                  </div>
                </div>
                <div class="office_body">
                  <div class="office_txt">검사 수술료가 산정 되었습니다. 수수료가 입금되어야 검사 접수가 가능하오니, 수수료를 입금해주세요.</div>
                  <div class="txt_desc_box my_task">
                    <span><strong>검사 수수료: </strong>{{ item.inspFee }}원</span>
                    <span><strong>납부계좌: </strong>{{ item.bankAccount }}</span>
                  </div>
                </div>
                <div class="btn_area center m0 mt_5"><span class="btn xsm" @click="goToWork('ISPC-FEE')">업무처리 하러가기 →</span></div>
              </a>
            </swiper-slide>
            <!-- 실적보고 > 당월등록 -->
            <swiper-slide v-for="(item, index) in myTaskListForPrfmncRegist" :key="`PRFMNC-RGST-${index}`">
              <a href="javascript:void(0);" role="group" style="width: 326.667px; margin-right: 20px;">
                <div class="office_top">
                  <div class="info">
                    <span class="title">실적보고</span>
                    <span class="badge">당월등록</span>
                    <span><strong>{{ item.bplcNm }}</strong> {{ item.fcltNm }}</span>
                  </div>
                </div>
                <div class="office_body">
                  <div class="office_txt">매월 10일까지 실적 등록이 필요합니다. 아직 등록된 실적이 없으므로, 실적 등록을 진행해주세요.</div>
                  <div class="txt_desc_box my_task">
                    <span><strong>실적 귀속년월: </strong>{{ item.prfmncYm }}</span>
                    <span><strong>실적 등록상태: </strong>{{ item.prfmncSttsNm }}</span>
                    <span><strong>실적 등록기한: </strong>{{ item.dueDate }}</span>
                  </div>
                </div>
                <div class="btn_area center m0 mt_5"><span class="btn xsm" @click="goToWork('PRFMNC-RGST', item)">업무처리 하러가기 →</span></div>
              </a>
            </swiper-slide>
            <!-- 실적보고 > 실적보완 -->
            <swiper-slide v-for="(item, index) in myTaskListForPrfmncSupplement" :key="`PRFMNC-SPLM-${index}`">
              <a href="javascript:void(0);" role="group" style="width: 326.667px; margin-right: 20px;">
                <div class="office_top">
                  <div class="info">
                    <span class="title">실적보고</span>
                    <span class="badge">실적보완</span>
                    <span><strong>{{ item.bplcNm }}</strong> {{ item.fcltNm }}</span>
                  </div>
                </div>
                <div class="office_body">
                  <div class="office_txt">신청된 당월 신청이 반려되었습니다. 반려사유를 확인하신 뒤, 실적 수정을 진행해주세요.</div>
                  <div class="txt_desc_box my_task">
                    <span><strong>실적 귀속년월: </strong>{{ item.prfmncYm }}</span>
                    <span><strong>실적 등록상태: </strong>{{ item.prfmncSttsNm }}</span>
                    <span><strong>반려사유: </strong>{{ item.rjctRsn }}</span>
                  </div>
                </div>
                <div class="btn_area center m0 mt_5"><span class="btn xsm" @click="goToWork('PRFMNC-SPLM', item)">업무처리 하러가기 →</span></div>
              </a>
            </swiper-slide>
            <!-- 회수통계 > 통계작성 -->
            <swiper-slide v-for="(item, index) in myTaskListForRtrvlStats" :key="`RTRV-STTS-${index}`">
              <a href="javascript:void(0);" role="group" style="width: 326.667px; margin-right: 20px;">
                <div class="office_top">
                  <div class="info">
                    <span class="title">회수통계</span>
                    <span class="badge">통계작성</span>
                    <span><strong>{{ item.bplcNm }}</strong> {{ item.fcltNm }}</span>
                  </div>
                </div>
                <div class="office_body">
                  <div class="office_txt">지금은 회수통계 조사기간 입니다.<br>회수통계 내용을 확인해주세요.</div>
                  <div class="txt_desc_box my_task">
                    <span><strong>조사년도: </strong>{{ item.exmnYr }}</span>
                    <span><strong>조사기간: </strong>{{ `${item.exmnBgngYmd} ~ ${item.exmnEndYmd}` }}</span>
                    <span v-if="item.rdmndYn === 'Y'"><strong>재요청사유: </strong>{{ item.rdmndRsnCd }}</span>
                  </div>
                </div>
                <div class="btn_area center m0 mt_5"><span class="btn xsm" @click="goToWork('RTRVL', item)">업무처리 하러가기 →</span></div>
              </a>
            </swiper-slide>
          </swiper>
        </div>
      </template>
      <template v-else>
        <div style="display: flex; justify-content: center;">
          <div class="no-data-message">처리가 필요한 업무 내역이 없습니다.</div>
        </div>
      </template>
    </div>
    <!-- 최근 검사진행 영역 -->
    <div ref="myInspList" style="height: 10px;"/>
    <div class="board_info">
      <h2 class="title1">최근 검사진행 목록 <span>(최대 <span class="txt_point">10</span>건)</span></h2>
<!--      <div>
        <br-anchor-button role="button" label="더보기" size="xsm" @click="onClickedInspMore"/>
      </div>-->
    </div>
    <div class="board_list" style="position: relative;">
      <table>
        <caption>최근 검사진행 목록-시설명, 검사종류, 검사신청일자, 검사희망일자, 검사(예정)일자, 현재상태, 검사결과로 구성</caption>
        <colgroup>
          <col>
          <col style="width:16rem">
          <col style="width:12rem">
          <col style="width:12rem">
          <col style="width:12rem">
          <col style="width:8rem">
          <col style="width:8rem">
        </colgroup>
        <thead>
        <tr>
          <th scope="col">시설명</th>
          <th scope="col">검사종류</th>
          <th scope="col">검사신청일자</th>
          <th scope="col">검사희망일자</th>
          <th scope="col">검사(예정)일자</th>
          <th scope="col">현재상태</th>
          <th scope="col">검사결과</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="latestInspList.length <= 0" >
          <td colspan="7">
            <div style="display: flex; justify-content: center;">
              <div class="no-data-message">최근 검사진행 내역이 없습니다.</div>
            </div>
          </td>
        </tr>
        <tr v-else v-for="(item, index) in latestInspList" :key="index">
          <td aria-label="시설명" class="txt_left"><a href="javascript:void(0);" @click.prevent="onClickedInsp(item)">{{ item.fcltNm }}</a></td>
          <td aria-label="검사종류">{{ item.inspNm }}</td>
          <td aria-label="검사신청일자">{{ item.inspAplyYmd }}</td>
          <td aria-label="검사희망일자">{{ item.inspHopeYmd }}</td>
          <td aria-label="검사(예정)일자">{{ item.inspPrnmntYmd }}</td>
          <td aria-label="진행상태"><span v-if="item.inspPrgrsSttsNm" class="badge" :class="{ bg1: item.inspPrgrsSttsCd === '70', bg3: this.isBg3BadgeClass(item.inspPrgrsSttsCd) }">{{ item.inspPrgrsSttsNm }}</span></td>
          <td aria-label="검사결과"><span v-if="item.inspRsltNm" class="badge" :class="{ bg2: item.inspRsltCd === '20' }" style="height: auto;">{{ item.inspRsltNm }}</span></td>
        </tr>
        </tbody>
      </table>
      <br-local-loading :isOn="isLoadingMyInspList" />
    </div>
  </template>
</template>

<script>
/**
 * 사용자의 폐자원에너지 업무 진행사항 전반을 보여주는 마이오피스 현황 화면
 *
 * 작성자 : jhkim
 * 작성일 : 11/08/2024
 * 버전 : 1.0
 */
import {storeSession} from "@/js/store/store-session";
import {Scrollbar} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/scrollbar';

const API = {
  MY_APLY : "/my-office/aply",
  MY_TASK : "/my-office/task",
  LATEST_INSP : "/my-office/insp",
}

export default {
  components: {Swiper, SwiperSlide},
  data() {
    return {
      modules: [Scrollbar],
      aplyType: 'ALL',
      taskType: 'ALL',
      myAplyInspList: [],
      myAplyPrfmncList: [],
      myTaskListForFcltInspChange: [],
      myTaskListForFcltInspContinue: [],
      myTaskListForFcltInspFirst: [],
      myTaskListForInspCommFeePay: [],
      myTaskListForInspCommSupplement: [],
      myTaskListForPrfmncRegist: [],
      myTaskListForPrfmncSupplement: [],
      myTaskListForQltyInspFirst: [],
      myTaskListForQltyInspImprove: [],
      myTaskListForRtrvlStats: [],
      latestInspList: [],
      isLoadingMyAplyList: false,
      isLoadingMyTaskList: false,
      isLoadingMyInspList: false,
      isAproveEnterMenu: false,
    };
  },
  computed: {
    myAplyTotCnt() {
      return this.myAplyInspList.length + this.myAplyPrfmncList.length;
    },
    myTaskTotCnt() {
      return this.myTaskListForFcltInspChange.length
          + this.myTaskListForFcltInspContinue.length
          + this.myTaskListForFcltInspFirst.length
          + this.myTaskListForInspCommFeePay.length
          + this.myTaskListForInspCommSupplement.length
          + this.myTaskListForPrfmncRegist.length
          + this.myTaskListForPrfmncSupplement.length
          + this.myTaskListForQltyInspFirst.length
          + this.myTaskListForQltyInspImprove.length
          + this.myTaskListForRtrvlStats.length;
    },
    myInspTotCnt() {
      return this.latestInspList.length;
    },
    isAdminUser() {
      return storeSession.isAdminUser();
    },
  },
  watch: {},
  created() {},
  beforeMount() {
    if( !this.canEnterMenu(storeSession.getMemberInfo()) ) {
      this.isAproveEnterMenu = false;
      alert('마이오피스 현황의 접근 권한이 없는 계정입니다.');
      this.$router.push('/')
          .catch(() => {
            console.error("Navigation Error");
          });
    } else {
      this.isAproveEnterMenu = true;
    }
  },
  mounted() {
    this.initialize();
  },
  beforeUnmount() {},
  methods: {
    initialize() {
      if( this. isAproveEnterMenu) {
        this.readAply()
        this.readTask()
        this.readInsp()
      }
    },
    readAply(type = 'ALL') {
      this.isLoadingMyAplyList = true;
      this.$apiCall.get(`${API.MY_APLY}`, {type: type}, data => {
        if (data.result) {
          this.myAplyInspList = data.result.inspList;
          this.myAplyPrfmncList = data.result.prfmncList;
        } else {
          this.myAplyInspList = [];
          this.myAplyPrfmncList = [];
        }

        this.$refs.myAplyListScroll.scrollTop = 0;
        this.isLoadingMyAplyList = false;
      }, () => {
        this.isLoadingMyAplyList = false;
      });
    },
    readTask(type = 'ALL') {
      this.isLoadingMyTaskList = true;
      this.$apiCall.get(`${API.MY_TASK}`, {type: type}, data => {
        if (data.result) {
          this.myTaskListForFcltInspChange = data.result.taskListForFcltInspChange? data.result.taskListForFcltInspChange : [];
          this.myTaskListForFcltInspContinue = data.result.taskListForFcltInspContinue? data.result.taskListForFcltInspContinue : [];
          this.myTaskListForFcltInspFirst = data.result.taskListForFcltInspFirst? data.result.taskListForFcltInspFirst : [];
          this.myTaskListForInspCommFeePay = data.result.taskListForInspCommFeePay? data.result.taskListForInspCommFeePay : [];
          this.myTaskListForInspCommSupplement = data.result.taskListForInspCommSupplement? data.result.taskListForInspCommSupplement : [];
          this.myTaskListForPrfmncRegist = data.result.taskListForPrfmncRegist? data.result.taskListForPrfmncRegist : [];
          this.myTaskListForPrfmncSupplement = data.result.taskListForPrfmncSupplement? data.result.taskListForPrfmncSupplement : [];
          this.myTaskListForQltyInspFirst = data.result.taskListForQltyInspFirst? data.result.taskListForQltyInspFirst : [];
          this.myTaskListForQltyInspImprove = data.result.taskListForQltyInspImprove? data.result.taskListForQltyInspImprove : [];
          this.myTaskListForRtrvlStats = data.result.taskListForRtrvlStats? data.result.taskListForRtrvlStats : [];
        } else {
          this.myTaskList = [];
        }

        this.isLoadingMyTaskList = false;
      }, () => {
        this.isLoadingMyTaskList = false;
      });
    },
    readInsp() {
      this.isLoadingMyInspList = true;
      this.$apiCall.get(`${API.LATEST_INSP}`, {}, data => {
        if (data.result) {
          this.latestInspList = data.result;
        } else {
          this.latestInspList = [];
        }

        this.isLoadingMyInspList = false;
      }, () => {
        this.isLoadingMyInspList = false;
      });
    },
    canEnterMenu(mbrInfo) {
      return mbrInfo.sysAuthrtCd.startsWith('01')
          || mbrInfo.sysAuthrtCd.startsWith('02')
          || mbrInfo.sysAuthrtCd.startsWith('03')
          || mbrInfo.sysAuthrtCd.startsWith('04')
          || mbrInfo.sysAuthrtCd.startsWith('05')
          || mbrInfo.sysAuthrtCd.startsWith('06')
          || mbrInfo.sysAuthrtCd === '08-10'
    },
    scrollTo(to) {
      switch (to) {
        case 'APLY':
          this.$refs.myAplyList.scrollIntoView({ behavior: 'smooth' });
          break;
        case 'TASK':
          this.$refs.myTaskList.scrollIntoView({ behavior: 'smooth' });
          break;
        case 'INSP':
          this.$refs.myInspList.scrollIntoView({ behavior: 'smooth' });
          break;
      }
    },
    onClickedAplyType(type) {
      this.aplyType = type;
      switch (type) {
        case 'ALL':
          this.readAply()
          break;
        case 'FCLT':
          this.readAply('FCLT')
          break;
        case 'QLTY':
          this.readAply('QLTY')
          break;
        case 'PRFMNC':
          this.readAply('PRFMNC')
          break;
      }
    },
    onClickedTaskType(type) {
      this.taskType = type;
      switch (type) {
        case 'ALL':
          this.readTask();
          break;
        case 'FCLT':
          this.readTask('FCLT');
          break;
        case 'QLTY':
          this.readTask('QLTY');
          break;
        case 'PRFMNC':
          this.readTask('PRFMNC');
          break;
        case 'RTRVL':
          this.readTask('RTRVL');
          break;
      }
    },
    // onClickedInspMore() {  //검사종류는 여러개인데 더보기를 누르면 어디로 보낼 것인가??
    //   this.$router.push('/sfli/QltyInspStat').catch(() => {
    //     console.error("Navigation Error");
    //   });
    // },
    isBg3BadgeClass(inspPrgrsSttsCd) {
      let result = false;
      switch (inspPrgrsSttsCd) {
        case '00':  // 작성중
        case '10':  // 신청
        case '30':  // 서류보완요청
        case '50':  // 반려
        case '80':  // 사전취소
        case '90':  // 검사취하
          result = true;
          break;
      }
      return result;
    },
    onClickedInsp(item) {
      console.dir(item)
      const dataObj = {
        inspAplySnValue: item.inspAplySn, /* 검사일련번호 */
      };
      switch (item.inspClsfCd){
        case '10':  //시설검사
          // 데이터를 Local Storage에 저장
          localStorage.setItem('fcltInspDetailObj', JSON.stringify(dataObj));
          this.$router.push(
              {
                path : "/sfli/fclt-insp-aply/FcltInspAplyDetail"
              }
          ).catch(
              () => {
                console.error("Navigation Error");
              }
          );
          break;
        case '20':  //품질검사
          this.$router.push({path: '/sfli/qlty-insp-stat/QltyInspStatDetail', query: {inspAplySn: item.inspAplySn}}).catch(() => {
            console.error("Navigation Error");
          });
          break;
        case '30':  //수입품질검사
          this.$router.push({path: '/sfli/incm-qlty-insp-stat/IncmQltyInspStatDetail', query: {inspAplySn: item.inspAplySn}}).catch(() => {
            console.error("Navigation Error");
          });
          break;
        case '40':  //품질표시검사
          break;
        case '50':  //수입품질표시검사
          break;
      }
    },
    getStep(type, sttsCd) {
      if(type === 'INSP') {
        switch (sttsCd) {
          case '10': return {phase: '01', step: '신청', desc: '검사신청이 완료되었으며, 관리자의 서류검토를 기다리는 중 입니다.'};
          case '20': return {phase: '02', step: '서류검토중', desc: '작성하신 검사신청정보를 관리자가 검토중 입니다.'};
          case '30': return {phase: '03', step: '서류보완요청', desc: '작성하신 검사신청정보 중 보완이 필요한 사항이 있습니다. 내용 확인 후 보완 작업을 진행해주세요.'};
          case '31': return {phase: '04', step: '서류보완', desc: '보완 작업이 완료되어 재신청 하신 상태입니다. 관리자의 확인을 기다리고 있습니다.'};
          case '40': return {phase: '05', step: '접수', desc: '작성하신 검사신청정보가 접수되었습니다. 검사일정을 확인 중 입니다.'};
          case '60': return {phase: '06', step: '검사진행중', desc: '검사일정 확정 및 검사원 배정되어 검사가 진행 중 입니다.'};
          case '70': return {phase: '07', step: '완료', desc: '모든 검사 작업이 완료되었습니다.'};
          default: return {phase: '', step: '', desc: ''};
        }
      } else if(type === 'PRFMNC') {
          switch (sttsCd) {
            case 'IPG': return {phase: '01', step: '작성중', desc: '현재 실적 작성 중 상태입니다. 작성 완료 후 실적 제출이 필요합니다.'};
            case 'SUB': return {phase: '02', step: '제출', desc: '작성하신 실적이 제출 완료되었으며, 관리자의 접수를 기다리는 중 입니다.'};
            case 'REC': return {phase: '03', step: '접수', desc: '작성하신 실적이 접수 완료되었으며, 관리자의 승인을 기다리는 중 입니다.'};
            case 'APR': return {phase: '04', step: '승인', desc: '작성하신 실적의 승인인 완료되었습니다.'};
            case 'REJ': return {phase: '04', step: '반려', desc: '작성하신 실적에 보완사항이 있어 반려되었습니다.'};
            default: return {phase: '', step: '', desc: ''};
        }
      }
    },
    goToWork(workType, item) {
      switch (workType) {
        case 'FCLT-FST':    // 정기검사 - 최초검사
        case 'FCLT_CTN':    // 정기검사 - 계속검사
        case 'FCLT-CHG':    // 정기검사 - 변경검사
          this.$router.push('/sfli/FcltInspAply').catch(() => {
            console.error("Navigation Error");
          });
          break;
        case 'QLTY-FST':    // 품질검사 - 최초검사
          this.$router.push('/sfli/QltyInspAply').catch(() => {
            console.error("Navigation Error");
          });
          break;
        case 'QLTY-IPRV':   // 품질검사 - 개선명령
          this.$router.push('/my-office/ImpvProhbtCmd').catch(() => {
            console.error("Navigation Error");
          });
          break;
        case 'ISPC-SPLM':   // 검사공통 - 보완요청
        case 'ISPC-FEE':    // 검사공통 - 수수료납부
          if( item.inspClsfCd === '10' ) {
            this.$router.push('/sfli/FcltInspAplyStat').catch(() => {
              console.error("Navigation Error");
            });
          } else if( item.inspClsfCd === '20' ) {
            this.$router.push('/sfli/QltyInspStat').catch(() => {
              console.error("Navigation Error");
            });
          }
          break;
        case 'PRFMNC-RGST':   // 실적보고 - 당월등록
        case 'PRFMNC-SPLM':   // 실적보고 - 당월반려
          this.$router.push('/prfmnc-rpt/PrfmncRpt').catch(() => {
            console.error("Navigation Error");
          });
          break;
        case 'RTRVL':   // 회수통계
          this.$router.push('/prfmnc-rpt/RtrvlStatsExmn').catch(() => {
            console.error("Navigation Error");
          });
          break;
      }
    },
    getDisplayText(item) {
      switch (item) {
        case 'Y': return '합격';
        case 'N': return '불합격';
        default: return '미진행';
      }
    },
    moveAdmin() {
      const url = `${process.env.VUE_APP_ADMIN_URL}`;
      location.replace(url);
    }
  }
}
</script>

<style scoped>
.board_list.scroll > table { table-layout: fixed; width:100%;}
.no-data-message { display: flex; align-items: center; justify-content: center; padding: 10px 5px; margin: 20px 0px; border-radius: 10px; background-color: #f7f7f7; color: #333; font-size: 16px; font-weight: 500; border: 1px solid #ddd; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); width: 350px;}
.no-data-message::before { content:''; width:2rem; height:2rem; background:url(@/assets/images/common/icon_search.png) no-repeat; background-size: 2rem; margin-right: 8px;}
.board_list.scroll > table tbody tr:hover{ background:var(--bg-gray10)}
.my_office_list.office_swiper .swiper-wrapper .swiper-slide { width: 320px; margin-right: 30px;}
.my_office_list > li.no-data { border: none; box-shadow: none; display: flex; justify-content: center; }
.admin_user { display: flex; align-items: center; justify-content: center; height: 100%; }
</style>