
export default {
    isDefined(t) {
        return typeof t != "undefined";
    },
    sformat(fmt, reps) {
        let s = fmt;
        for (let i = 0; i < reps.length; i++) {
            let rex = new RegExp("\\{" + i + "\\}", "gm");
            s = s.replace(rex, reps[i])
        }
        return s;
    },
    isFileList(inst) {
        return inst instanceof FileList || Object.prototype.toString.call(inst) === "[object FileList]";
    },
    isFile(inst) {
        return inst instanceof File || inst instanceof Blob || Object.prototype.toString.call(inst) === "[object File]";
    },
    getFileExtension(source) {
        if (!source) return "";
        const lindex = source.lastIndexOf(".");
        return lindex >= 0 ? source.substring(lindex + 1) : ""
    },
    getFriendlySize(size, points) {
        if (size === 0) return "0 byte";
        points = points || 1;
        const units = [" bytes", " KB", " MB", " GB", " TB"];
        for (let i = 0; i < units.length; i++) {
            if (parseInt(size / 1024) === 0) {
                return (i > 0 ? size.toFixed(points) : size) + units[i];
            } else {
                size /= 1024;
            }
        }
    },
};