<template>

  <h2 class="title1 mt_0">실적보고 상세</h2>
  <div class="board_write">
    <table>
      <caption>실적연도, 실적상태, 사업장명, 사업장종류</caption>
      <colgroup>
        <col style="width:18%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:32%">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">실적연월</th>
        <td>{{ formattedPrfmncYm }}</td>
        <th scope="row">실적상태</th>
        <td>{{ fireRtrvlDtlObj.prfmncMst.prfmncSttsNm || "작성중" }}</td>
      </tr>
      <tr>
        <th scope="row">사업장명</th>
        <td>{{ fireRtrvlDtlObj.prfmncMst.bplcNm }}</td>
        <th scope="row">사업장종류</th>
        <td>{{ fireRtrvlDtlObj.prfmncMst.bplcKndNm }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <rjct-rsn-area :prfmncBasicInfo="fireRtrvlDtlObj.prfmncMst" />
  <prfmnc-mdfcn-prm-area :prfmncBasicInfo="fireRtrvlDtlObj.prfmncMst" />

  <div class="board_info mt_40">
    <h3 class="title2">폐기물 반입 현황</h3>
  </div>

  <sb-grid
      id="prfmncDtlFireRtrvlWtCrynGrid"
      :dataSource="dataSourceFireRtrvlWtCryn"
      :columns="gridColumnsFireRtrvlWtCryn"
      :gridattr="gridAttrFireRtrvlWtCryn"
      v-model:grid="prfmncFireRtrvlWtCrynGridObject"
      @loaded="gridLoadedFireRtrvlWtCryn"
      :refreshGrid="gridReloadFireRtrvlWtCryn"
  />

  <div class="board_info mt_40">
    <h3 class="title2">폐기물 처리 현황</h3>
  </div>
  <div class="grid_search">
    <h4>폐기물 처리 현황 <span>※ 전처리량(톤):전처리되어 소각로에 투입되지 않는 폐기물</span></h4>
  </div>
  <div class="board_write thead">
    <table>
      <caption>폐기물 처리 현황</caption>
      <colgroup>
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
      </colgroup>
      <thead>
      <tr>
        <th scope="col">이월량</th>
        <th scope="col">반입량</th>
        <th scope="col">전처리량</th>
        <th scope="col">투입량</th>
        <th scope="col">잔량</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="txt_right" aria-label="이월량">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.wtCrfqty) }} 톤
        </td>
        <td class="txt_right" aria-label="반입량">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.wtSumCryqty) }} 톤
        </td>
        <td class="txt_right" aria-label="전처리량">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.prprcsWstqty) }} 톤
        </td>
        <td class="txt_right" aria-label="투입량">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.wtSumInpqty) }} 톤
        </td>
        <td class="txt_right" aria-label="잔량">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.wtRmnqty) }} 톤
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info mt_40">
    <h4 class="title3" @click="callInfoPopup">
      시설별 폐기물 투입 현황
    </h4>
  </div>

  <sb-grid
      id="prfmncDtlFireRtrvlInputGrid"
      :dataSource="dataSourceFireRtrvlInput"
      :columns="gridColumnsFireRtrvlInput"
      :gridattr="gridAttrFireRtrvlInput"
      :param="fireRtrvlDtlObj.prfmncMst"
      v-model:grid="prfmncFireRtrvlInputGridObject"
      @loaded="gridLoadedFireRtrvlInput"
      :refreshGrid="gridReloadFireRtrvlInput"
  />

  <div class="board_info mt_40">
    <h4 class="title3" @click="callEnergyPopup">시설별 보조연료 투입 현황</h4>
  </div>

  <sb-grid
      id="asstFuelInputGrid"
      :dataSource="asstFuelInputDataSource"
      :columns="asstFuelInputGridColumns"
      :gridattr="asstFuelInputGridAttr"
      v-model:grid="asstFuelInputGridObject"
      @loaded="asstFuelInputGridLoaded"
      :refreshGrid="asstFuelInputGridReload"
  />

  <div class="board_info mt_40" style="display: flow " v-if="fireRtrvlDtlObj.prfmncFireRtrvl.exclRsnYn">
    <input type="checkbox" id="fireRtrvlDtlObj.prfmncFireRtrvl.exclRsnYn" label="에너지 회수,이용량 계측불가 시설일 경우"
           v-model="fireRtrvlDtlObj.prfmncFireRtrvl.exclRsnYn"
           disabled
    /><label for="계측불가여부" >  ※ 에너지 회수,이용량 계측불가 시설일 경우</label>
    <br>
    <textarea type="textarea"
              v-model="fireRtrvlDtlObj.prfmncFireRtrvl.exclRsnCn"
              style="width: 1275px; height: 154px;"
              readonly
              value="aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"/>
  </div>

  <div class="board_info mt_40">
    <h3 class="title2">에너지 회수 현황</h3>
    <span>* 전기이용량은 1MWh = 0.86Gcal로 환산됩니다.</span>
  </div>

  <div class="board_list">
    <table>
      <caption>에너지 회수 현황</caption>
      <colgroup>
        <col :style="{width: `calc(100% / 6)`}">
        <col :style="{width: `calc(100% / 6)`}">
        <col :style="{width: `calc(100% / 6)`}">
        <col :style="{width: `calc(100% / 6)`}">
        <col :style="{width: `calc(100% / 6)`}">
        <col :style="{width: `calc(100% / 6)`}">
      </colgroup>
      <thead>
      <tr>
        <th scope="col">총 에너지 발생가능량<br />(Gcal)</th>
        <th scope="col">총 에너지 회수량(Gcal)<br />(X + Y)</th>
        <th scope="col">에너지 발생량(Gcal)<br />(X)</th>
        <th scope="col">미이용(Gcal)<br />(Y)</th>
        <th scope="col">에너지 이용량(Gcal)<br />(A + B + C + D + E + F)</th>
        <th scope="col">에너지 이용효율(%)</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="txt_right" aria-label="총 에너지 발생가능량(Gcal)">
          {{ formatNumber(totalEneOcrnPsbltyQty) }} Gcal
        </td>
        <td class="txt_right" aria-label="총 에너지 회수량 (Gcal) (X + Y)">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.eneRtrqty) }} Gcal
        </td>
        <td class="txt_right" aria-label="에너지 발생량(Gcal) (X)">
          {{ formatNumber(fireRtrvlDtlObj.eneOcrnQtyTotal) }} Gcal
        </td>
        <td class="txt_right" aria-label="미이용(Gcal) (Y)">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.eneUnuqty) }} Gcal
        </td>
        <td class="txt_right" aria-label="에너지 이용량(Gcal) (A + B + C + D)">
          {{ formatNumber(fireRtrvlDtlObj.eneutzQtyTotal) }} Gcal
        </td>
        <td class="txt_right" aria-label="에너지 이용효율(%)">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.eneUtztnEfcnc) }} %
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info mt_40">
    <h3 class="title2">에너지 생산 현황</h3>
    <span>* 생산되는 증기/온수/전기 (중복불가)</span>
  </div>

  <div class="grid_search">
    <h4>에너지 생산 현황 <span>※ 증기생산량(Gcal)값은 증기엔탈피와 증기생산량(톤)을 이용하여 자동환산 됩니다. 증기엔탈피는 마이오피스에서 수정가능합니다.</span></h4>
  </div>

  <sb-grid
      id="prfmncDtlFireRtrvlPrdctnGrid"
      :dataSource="dataSourceFireRtrvlPrdctn"
      :columns="gridColumnsFireRtrvlPrdctn"
      :gridattr="gridAttrFireRtrvlPrdctn"
      :param="fireRtrvlDtlObj.prfmncMst"
      v-model:grid="prfmncFireRtrvlPrdctnGridObject"
      @loaded="gridLoadedFireRtrvlPrdctn"
      :refreshGrid="gridReloadFireRtrvlPrdctn"
  />

  <div class="board_info mt_40">
    <h3 class="title2">에너지 이용 현황</h3>
    <span>* 증기로 발전과 단순열발전 중복입력 주의</span>
  </div>

  <sb-grid
      id="prfmncDtlFireRtrvlUseGrid"
      :dataSource="dataSourceFireRtrvlUse"
      :columns="gridColumnsFireRtrvlUse"
      :gridattr="gridAttrFireRtrvlUse"
      :param="fireRtrvlDtlObj.prfmncMst"
      v-model:grid="prfmncFireRtrvlUseGridObject"
      @loaded="gridLoadedFireRtrvlUse"
      :refreshGrid="gridReloadFireRtrvlUse"
  />

  <h3 class="title2">에너지 외부판매 현황</h3>
  <div class="grid_search">
    <h4>에너지 외부판매 현황 <span>※ 외부판매량은 월별 누계값을 입력 하셔야 합니다.</span></h4>
  </div>
  <sb-grid
      id="prfmncFireRtrvlNtslGrid"
      :dataSource="dataSourceFireRtrvlNtsl"
      :columns="gridColumnsFireRtrvlNtsl"
      :gridattr="gridAttrFireRtrvlNtsl"
      v-model:grid="prfmncFireRtrvlNtslGridObject"
      @loaded="gridLoadedFireRtrvlNtsl"
      :refreshGrid="gridReloadFireRtrvlNtsl"
  />

  <!-- 실적상태이력 현황 (신규등록인 경우 노출안함) -->
  <prfmnc-stts-hstry v-if="fireRtrvlDtlObj.prfmncMst.prfmncId" :prfmncId="fireRtrvlDtlObj.prfmncMst.prfmncId" />

  <!-- 수정허용요청 이력 현황 (신규등록인 경우 노출안함) -->
  <prfmnc-mdfcn-prm-hstry v-if="fireRtrvlDtlObj.prfmncMst.prfmncId" :prfmncId="fireRtrvlDtlObj.prfmncMst.prfmncId" />

  <!-- 버튼 -->
  <common-button-area
      :prfmncBasicInfo="fireRtrvlDtlObj.prfmncMst"
  />
  <info-popup></info-popup>
  <energy-popup></energy-popup>
</template>

<script>
import SbGrid from "@/components/SbGrid.vue";
import utils from "@/js/utils/utils";
import commonButtonArea from "@/views/prfmnc-rpt/dtl/components/CommonButtonArea.vue";
import prfmncSttsHstry from "@/views/prfmnc-rpt/components/PrfmncSttsHstry.vue";
import PrfmncMdfcnPrmHstry from "@/views/prfmnc-rpt/components/PrfmncMdfcnPrmHstry.vue";
import PrfmncMdfcnPrmArea from "@/views/prfmnc-rpt/dtl/components/PrfmncMdfcnPrmArea.vue";
import RjctRsnArea from "@/views/prfmnc-rpt/dtl/components/RjctRsnArea.vue";
import infoPopup from "@/views/prfmnc-rpt/dtl/fire-rtrvl/popup/infoPopup.vue";
import energyPopup from "@/views/prfmnc-rpt/dtl/fire-rtrvl/popup/energyPopup.vue";
import {storeSwitch} from "@/js/store/store-switch";

const CODE = {
  PERFORMANCE_STATUS: "PFM001", // 실적상태코드
  ASSISTANCE_FUEL_KIND_GROUP: "PFM007", // 보조연료종류그룹코드
  ASSISTANCE_FUEL_KIND: "PFM008", // 보조연료종류코드
  ASSISTANCE_FUEL_UNIT: "PFM009", // 보조연료단위코드
  LOW_CALORIFIC_POWER_UNIT: "PFM010", // 저위발열량단위코드
};

export default {
  components: {
    SbGrid,
    commonButtonArea,
    prfmncSttsHstry,
    PrfmncMdfcnPrmHstry,
    PrfmncMdfcnPrmArea,
    RjctRsnArea,
    infoPopup,
    energyPopup
  },
  props: ['prfmncFireRtrvl', 'prvPrfmncFireRtrvl', 'prfmncFireRtrvlPrdctnList', 'prfmncFireRtrvlWtCrynList', 'prfmncFireRtrvlNtslList', 'prfmncFireRtrvlWtInputList', 'prfmncMst', 'prfmncFclt', 'asstFuelInputInfo', 'showDetail'],
  data() {
    return {
      prfmncSttsList: [], // 실적상태코드

      updatedWtCrynData: [], // 폐기물반입현황 수정된 행
      insertedWtCrynData: [], // 폐기물반입현황 추가된 행

      // 실적 매립가스 상세정보 데이터
      fireRtrvlDtlObj:{
        bplcDschrgWstqtyTotal: 0, // [시설별폐기물투입현황] 배출사업장계 합계
        hmeLifeWstqtyTotal: 0, // [시설별폐기물투입현황] 생활폐기물 합계
        bplcLifeWstqtyTotal: 0, // [시설별폐기물투입현황] 사업장폐기물 합계
        largeWstqtyTotal: 0, // [시설별폐기물투입현황] 대형폐기물 합계
        wstsrtWstqtyTotal: 0, // [시설별폐기물투입현황] 폐합성수지류 합계
        wstwdtWstqtyTotal: 0, // [시설별폐기물투입현황] 폐목재 합계
        wstsftWstqtyTotal: 0, // [시설별폐기물투입현황] 폐섬유 합계
        wstvnlWstqtyTotal: 0, // [시설별폐기물투입현황] 영농폐비닐 합계
        etcWstqtyTotal: 0, // [시설별폐기물투입현황] 기타 합계

        vaporUsqtyTotal: 0, // [에너지이용현황] 증기(증기톤) > 자체이용(Gcal) 항목값 합계
        vaporNsqtyTotal: 0, // [에너지이용현황] 증기(증기톤) > 외부판매(Gcal) 항목값 합계
        htwtrUsqtyTotal: 0, // [에너지이용현황] 증기(증기톤) > 자체이용 항목값 합계
        htwtrNsqtyTotal: 0, // [에너지이용현황] 증기(증기톤) > 외부판매 항목값 합계
        prvtmUsqtyTotal: 0, // [에너지이용현황] 전기 > 전기 자체이용(MWh) 항목값 합계
        prvtmNsqtyTotal: 0, // [에너지이용현황] 전기 > 전기 외부판매(MWh) 항목값 합계

        prvtmMWhToGcalTotal: 0,  // [에너지이용현황] 전기 > (전기 자체이용(MWh) + 전기 > 전기 외부판매(MWh)) * 0.86 합계

        wtStotCryqtyTotal: 0, // 반입량 합계
        elctgnQtyTotal: 0, // 발전량 합계 (자체사용량 + 외부판매량 + 기타)
        wtInpqtyTotal: 0, // 소각처리량 합계
        eneOcrnQtyTotal: 0, // 에너지발생량 합계
        eneutzQtyTotal: 0, // 에너지이용량 합계
        totalGasStotNsqty: 0, // 메탄가스 총 판매량 합계

        prfmncMst: {},
        prfmncFireRtrvlWtCrynList : [], // 폐기물반입현황
        prfmncFireRtrvlNtslList : [], // 소각판매현황
        prfmncFireRtrvl: {
          wtCrfqty: 0, // 폐기물이월량
          wtSumCryqty: 0, // 폐기물합계반입량
          prprcsWstqty: 0, // 전처리폐기물량
          wtSumInpqty: 0, // 폐기물합계투입량
          wtRmnqty: 0, // 폐기물잔량
          fireAvgLcp: 0, // 소각평균저위발열량
          eneRtrqty: 0, // 에너지회수량
          eneUnuqty: 0, // 에너지미이용량
          eneUtztnEfcnc: 0, // 에너지이용효율
          stotUsqty: 0, // 소계사용량
          htsrcUsqty: 0, // 열원사용량
          etcUsgUsqty: 0, // 기타용도사용량
          elctgnUsgUsqty: 0, // 발전용도사용량
          stotNsqty: 0, // 소계판매량
          htsrcSumNsqty: 0, // 열원합계판매량
          etcUsgSumNsqty: 0, // 기타용도합계판매량
          elctgnUsgNsqty: 0, // 발전용도판매량
          elctgnEquipInpqty: 0, // 발전설비투입량
          prvtmSumNsqty: 0, // 전기합계판매량
          prvtmSumUsqty: 0, // 전기합계사용량
          etcPrvtmUsqty: 0, // 기타전기사용량
          oilAsstFuelUsqty: 0, // 유류보조연료사용량
          lngAsstFuelUsqty: 0, // LNG보조연료사용량
          etcAsstFuelUsqty: 0, // 기타보조연료사용량
          crynPrvtmAsstFuelUsqty: 0, // 반입전기보조연료사용량
          prdctnPrvtmAsstFuelUsqty: 0, // 생산전기보조연료사용량
        }, // 매립가스실적 (메탄가스생산현황, 메탄가스사용현황)
        prvPrfmncFireRtrvl: {
          wtCrfqty: 0, // 폐기물이월량
          wtSumCryqty: 0, // 폐기물합계반입량
          prprcsWstqty: 0, // 전처리폐기물량
          wtSumInpqty: 0, // 폐기물합계투입량
          wtRmnqty: 0, // 폐기물잔량
          fireAvgLcp: 0, // 소각평균저위발열량
          eneRtrqty: 0, // 에너지회수량
          eneUnuqty: 0, // 에너지미이용량
          eneUtztnEfcnc: 0, // 에너지이용효율
          stotUsqty: 0, // 소계사용량
          htsrcUsqty: 0, // 열원사용량
          etcUsgUsqty: 0, // 기타용도사용량
          elctgnUsgUsqty: 0, // 발전용도사용량
          stotNsqty: 0, // 소계판매량
          htsrcSumNsqty: 0, // 열원합계판매량
          etcUsgSumNsqty: 0, // 기타용도합계판매량
          elctgnUsgNsqty: 0, // 발전용도판매량
          elctgnEquipInpqty: 0, // 발전설비투입량
          prvtmSumNsqty: 0, // 전기합계판매량
          prvtmSumUsqty: 0, // 전기합계사용량
          etcPrvtmUsqty: 0, // 기타전기사용량
          oilAsstFuelUsqty: 0, // 유류보조연료사용량
          lngAsstFuelUsqty: 0, // LNG보조연료사용량
          etcAsstFuelUsqty: 0, // 기타보조연료사용량
          crynPrvtmAsstFuelUsqty: 0, // 반입전기보조연료사용량
          prdctnPrvtmAsstFuelUsqty: 0, // 생산전기보조연료사용량
        }, // 전월 매립가스실적
        asstFuelInputInfo: {}, // [보조연료 투입현황]
      },


      /**************** S : 폐기물반입현황 Grid ****************/
      dataSourceFireRtrvlWtCryn: [],
      gridColumnsFireRtrvlWtCryn: [],
      gridAttrFireRtrvlWtCryn: {
        checkable: false,
        pageable: false,
        editable : false,
        showRowNo : false,
        excelExport: {
          fileName: '폐기물반입현황(' + this.prfmncMst.bplcKndNm + ').xlsx',
          includeHeader: true,
          includeFormula: true,
        },
        showStatus:false,
      },
      prfmncFireRtrvlWtCrynGridObject: null,
      gridReloadFireRtrvlWtCryn: false,
      /**************** E : 폐기물반입현황 Grid ****************/

      /**************** S : 시설별 폐기물 투입 현황 Grid ****************/
      dataSourceFireRtrvlInput: [],
      gridColumnsFireRtrvlInput: [],
      gridAttrFireRtrvlInput: {
        checkable: false,
        pageable: false,
        editable : false,
        showRowNo : false,
        excelExport: {
          fileName: '시설별폐기물투입현황(' + this.prfmncMst.bplcKndNm + ').xlsx',
          includeHeader: true,
          includeFormula: false,
        },
        showStatus:false,
      },
      prfmncFireRtrvlInputGridObject: null,
      gridReloadFireRtrvlInput: false,
      /**************** E : 시설별 폐기물 투입 현황 Grid ****************/

      /**************** S : 보조연료 투입 현황 Grid ****************/
      asstFuelInputGridReload: false,
      asstFuelInputGridObject: null,
      asstFuelInputDataSource: [],
      asstFuelInputGridColumns: [],
      asstFuelInputGridAttr: {
        showRowNo: false,
        checkable: false,
        pageable: false,
        editable: false,
        toolBar: [],
        hideDeleted: true,
        showStatus:false,
      },
      /**************** E : 보조연료 투입 현황 Grid ****************/

      /**************** S : 에너지생산 현황 Grid ****************/
      dataSourceFireRtrvlPrdctn: [],
      gridColumnsFireRtrvlPrdctn: [],
      gridAttrFireRtrvlPrdctn: {
        checkable: false,
        pageable: false,
        editable : false,
        showRowNo : false,
        excelExport: {
          fileName: '에너지생산현황(' + this.prfmncMst.bplcKndNm + ').xlsx',
          includeHeader: true,
          includeFormula: true,
        },
        showStatus:false,
      },
      prfmncFireRtrvlPrdctnGridObject: null,
      gridReloadFireRtrvlPrdctn: false,
      /**************** E : 에너지생산 현황 Grid ****************/

      /**************** S : 에너지이용 현황 Grid ****************/
      dataSourceFireRtrvlUse: [],
      gridColumnsFireRtrvlUse: [],
      gridAttrFireRtrvlUse: {
        checkable: false,
        pageable: false,
        editable : false,
        showRowNo : false,
        excelExport: {
          fileName: '에너지이용현황(' + this.prfmncMst.bplcKndNm + ').xlsx',
          includeHeader: true,
          includeFormula: true,
        },
        showStatus:false,
      },
      prfmncFireRtrvlUseGridObject: null,
      gridReloadFireRtrvlUse: false,
      /**************** E : 에너지이용 현황 Grid ****************/

      /**************** S : 에너지 외부판매 Grid ****************/
      dataSourceFireRtrvlNtsl: [],
      gridColumnsFireRtrvlNtsl: [],
      gridAttrFireRtrvlNtsl: {
        checkable: false,
        pageable: false,
        editable: false,
        showRowNo : false,
        excelExport: {
          fileName: '에너지외부판매현황(' + this.prfmncMst.bplcKndNm + ').xlsx',
          includeHeader: true,
          includeFormula: true,
        },
        showStatus:false,
      },
      prfmncFireRtrvlNtslGridObject: null,
      gridReloadFireRtrvlNtsl: false,
      /**************** E : 에너지 외부판매 Grid ****************/
    };
  },
  computed: {
    /**
     * [에너지회수현황 - 총 에너지 발생가능량(Gcal)]
     * (저위발열량 가중치평균 * 투입량 합계) / 1000
     */
    totalEneOcrnPsbltyQty() {
      const totalEneOcrnPsbltyQty = (parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.fireAvgLcp) * parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.wtSumInpqty)) / 1000;
      return parseFloat(totalEneOcrnPsbltyQty.toFixed(2));
    },

    /**
     * [실적보고상세 - 실적연월]
     * YYYY-MM 포맷처리
     */
    formattedPrfmncYm() {
      return (this.fireRtrvlDtlObj.prfmncMst.prfmncYm?.substring(0, 4) ?? "") + "-" + (this.fireRtrvlDtlObj.prfmncMst.prfmncYm?.substring(4, 6) ?? "");
    },

    /**
     * [메탄가스사용현황 - 총 이용량]
     * 발전 + 냉난방 + 가스공급 + 차량연료 + 기타
     */
    gasStotUsqty() {
      const total = (
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.elctgnUsgGasUsqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.cdysmUsgGasUsqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.gasSplyUsgGasUsqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.vhclFuelUsgGasUsqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.etcUsgGasUsqty)
      ) ?? 0;
      return parseFloat(total.toFixed(2));
    }

  },
  watch: {
    prfmncMst: {
      handler(newVal) {
        this.fireRtrvlDtlObj.prfmncMst = {...this.fireRtrvlDtlObj.prfmncMst, ...newVal};
      },
      immediate: true,
      deep:true,
    },
    prfmncFireRtrvl() {
      this.fireRtrvlDtlObj.prfmncFireRtrvl = {...this.fireRtrvlDtlObj.prfmncFireRtrvl, ...this.prfmncFireRtrvl};
    },
    prvPrfmncFireRtrvl() {
      this.fireRtrvlDtlObj.prvPrfmncFireRtrvl = {...this.fireRtrvlDtlObj.prvPrfmncFireRtrvl, ...this.prvPrfmncFireRtrvl};

      // 신규 등록(실적아이디 존재여부로 구분)을 하려는 상태인 경우 폐기물처리현황의 이월량은 전월의 실적잔량을 가져온다.
      if(!this.fireRtrvlDtlObj.prfmncMst.prfmncId){
        this.fireRtrvlDtlObj.prfmncFireRtrvl.wtCrfqty = this.fireRtrvlDtlObj.prvPrfmncFireRtrvl.wtRmnqty;
      }
    },
    prfmncFireRtrvlWtInputList() {
      this.dataSourceFireRtrvlInput = { data: this.prfmncFireRtrvlWtInputList, schema: { id: 'fcltSqno'}};
      this.initializePrfmncFireRtrvlInputGridColumns();
      const itemCount = this.prfmncFireRtrvlWtInputList.length;
      this.gridAttrFireRtrvlInput.height = Math.max((itemCount * 30) + 190, 210);
      this.gridReloadFireRtrvlInput = true;

      // 시설별폐기물투입현황의 투입량합계를 계산해서 폐기물처리현황의 투입량 항목에 적용
      this.fireRtrvlDtlObj.bplcDschrgWstqtyTotal = this.prfmncFireRtrvlWtInputList.reduce((acc, item) => acc + (item.bplcDschrgWstqty || 0), 0);
      this.fireRtrvlDtlObj.hmeLifeWstqtyTotal = this.prfmncFireRtrvlWtInputList.reduce((acc, item) => acc + (item.hmeLifeWstqty || 0), 0);
      this.fireRtrvlDtlObj.bplcLifeWstqtyTotal = this.prfmncFireRtrvlWtInputList.reduce((acc, item) => acc + (item.bplcLifeWstqty || 0), 0);
      this.fireRtrvlDtlObj.largeWstqtyTotal = this.prfmncFireRtrvlWtInputList.reduce((acc, item) => acc + (item.largeWstqty || 0), 0);
      this.fireRtrvlDtlObj.wstsrtWstqtyTotal = this.prfmncFireRtrvlWtInputList.reduce((acc, item) => acc + (item.wstsrtWstqty || 0), 0);
      this.fireRtrvlDtlObj.wstwdtWstqtyTotal = this.prfmncFireRtrvlWtInputList.reduce((acc, item) => acc + (item.wstwdtWstqty || 0), 0);
      this.fireRtrvlDtlObj.wstsftWstqtyTotal = this.prfmncFireRtrvlWtInputList.reduce((acc, item) => acc + (item.wstsftWstqty || 0), 0);
      this.fireRtrvlDtlObj.wstvnlWstqtyTotal = this.prfmncFireRtrvlWtInputList.reduce((acc, item) => acc + (item.wstvnlWstqty || 0), 0);
      this.fireRtrvlDtlObj.etcWstqtyTotal = this.prfmncFireRtrvlWtInputList.reduce((acc, item) => acc + (item.etcWstqty || 0), 0);

      this.fireRtrvlDtlObj.prfmncFireRtrvl.wtSumInpqty = this.fireRtrvlDtlObj.bplcDschrgWstqtyTotal +
          this.fireRtrvlDtlObj.hmeLifeWstqtyTotal +
          this.fireRtrvlDtlObj.bplcLifeWstqtyTotal +
          this.fireRtrvlDtlObj.largeWstqtyTotal +
          this.fireRtrvlDtlObj.wstsrtWstqtyTotal +
          this.fireRtrvlDtlObj.wstwdtWstqtyTotal +
          this.fireRtrvlDtlObj.wstsftWstqtyTotal +
          this.fireRtrvlDtlObj.wstvnlWstqtyTotal +
          this.fireRtrvlDtlObj.etcWstqtyTotal;

      // 시설별폐기물투입현황의 저위발열량 평균값
      const filteredItems = this.prfmncFireRtrvlWtInputList.filter(item => item.fireLcp > 0);
      const sumWstqty = filteredItems.reduce((total, item) => {
        const totalWstqty =
            (item.bplcDschrgWstqty || 0) +
            (item.hmeLifeWstqty || 0) +
            (item.bplcLifeWstqty || 0) +
            (item.largeWstqty || 0) +
            (item.wstsrtWstqty || 0) +
            (item.wstwdtWstqty || 0) +
            (item.wstsftWstqty || 0) +
            (item.wstvnlWstqty || 0) +
            (item.etcWstqty || 0);
        return total + totalWstqty;
      }, 0);

      const sumWstqtyLcp = filteredItems.reduce((total, item) => {
        const totalWstqty =
            (item.bplcDschrgWstqty || 0) +
            (item.hmeLifeWstqty || 0) +
            (item.bplcLifeWstqty || 0) +
            (item.largeWstqty || 0) +
            (item.wstsrtWstqty || 0) +
            (item.wstwdtWstqty || 0) +
            (item.wstsftWstqty || 0) +
            (item.wstvnlWstqty || 0) +
            (item.etcWstqty || 0);
        const fireLcp = item.fireLcp || 0;
        return total + (totalWstqty * fireLcp);
      }, 0);

      this.fireRtrvlDtlObj.prfmncFireRtrvl.fireAvgLcp = sumWstqty > 0 ? utils.round2(sumWstqtyLcp / sumWstqty) : 0;
    },
    prfmncFireRtrvlPrdctnList() {
      this.dataSourceFireRtrvlPrdctn = this.prfmncFireRtrvlPrdctnList;
      this.initializePrfmncFireRtrvlPrdctnGridColumns();
      const itemCount = this.prfmncFireRtrvlPrdctnList.length;
      this.gridAttrFireRtrvlPrdctn.height = Math.max((itemCount * 30) + 190, 210);
      this.gridReloadFireRtrvlPrdctn = true;

      // 화면 초기 진입 시 에너지생산현황의 증기생산량(Gcal) 합계값을 에너지회수현황의 에너지발생량(Gcal) 항목에 적용
      this.fireRtrvlDtlObj.eneOcrnQtyTotal = this.prfmncFireRtrvlPrdctnList.reduce((acc, item) => acc + (item.vaporPrdqty || 0), 0);

      this.dataSourceFireRtrvlUse = this.prfmncFireRtrvlPrdctnList;
      this.initializePrfmncFireRtrvlUseGridColumns();
      this.gridAttrFireRtrvlUse.height = Math.max((itemCount * 30) + 250, 270);
      this.gridReloadFireRtrvlUse = true;

      // 화면 초기 진입 시 에너지이용현황의 증기(자체이용Gcal), 증기(외부판매Gcal), 온수(자체이용), 온수(외부판매) 항목합계를 더해서 에너지회수현황(에너지이용량Gcal) 항목에 적용
      this.fireRtrvlDtlObj.vaporUsqtyTotal = this.prfmncFireRtrvlPrdctnList.reduce((acc, item) => acc + (item.vaporUsqty || 0), 0);
      this.fireRtrvlDtlObj.vaporNsqtyTotal = this.prfmncFireRtrvlPrdctnList.reduce((acc, item) => acc + (item.vaporNsqty || 0), 0);
      this.fireRtrvlDtlObj.htwtrUsqtyTotal = this.prfmncFireRtrvlPrdctnList.reduce((acc, item) => acc + (item.htwtrUsqty || 0), 0);
      this.fireRtrvlDtlObj.htwtrNsqtyTotal = this.prfmncFireRtrvlPrdctnList.reduce((acc, item) => acc + (item.htwtrNsqty || 0), 0);
      this.fireRtrvlDtlObj.prvtmUsqtyTotal = this.prfmncFireRtrvlPrdctnList.reduce((acc, item) => acc + (item.prvtmUsqty || 0), 0);
      this.fireRtrvlDtlObj.prvtmNsqtyTotal = this.prfmncFireRtrvlPrdctnList.reduce((acc, item) => acc + (item.prvtmNsqty || 0), 0);

      // 증기일 경우 1MWh 당 0.86(Gcal)로 환산해주어야 함.
      this.fireRtrvlDtlObj.prvtmMWhToGcalTotal = parseFloat((this.fireRtrvlDtlObj.prvtmUsqtyTotal + this.fireRtrvlDtlObj.prvtmNsqtyTotal) * 0.86);

      this.fireRtrvlDtlObj.eneutzQtyTotal = this.sanitizeFloatingPointError(parseFloat(this.fireRtrvlDtlObj.vaporUsqtyTotal) +
          parseFloat(this.fireRtrvlDtlObj.vaporNsqtyTotal) +
          parseFloat(this.fireRtrvlDtlObj.htwtrUsqtyTotal) +
          parseFloat(this.fireRtrvlDtlObj.htwtrNsqtyTotal) +
          parseFloat(this.fireRtrvlDtlObj.prvtmMWhToGcalTotal)
      );
    },
    prfmncFireRtrvlWtCrynList() {
      this.dataSourceFireRtrvlWtCryn = { data: this.prfmncFireRtrvlWtCrynList.list, schema: { id: 'sggStdgCd'}};
      this.initializePrfmncFireRtrvlWtCrynGridColumns();
      const itemCount = this.prfmncFireRtrvlWtCrynList.list.length;
      this.gridAttrFireRtrvlWtCryn.height = Math.max((itemCount * 30) + 190, 210);
      this.gridReloadFireRtrvlWtCryn = true;
    },
    prfmncFireRtrvlNtslList() {
      this.dataSourceFireRtrvlNtsl = this.prfmncFireRtrvlNtslList.list;
      this.initializePrfmncFireRtrvlNtslGridColumns();
      const itemCount = this.prfmncFireRtrvlNtslList.list.length;
      this.gridAttrFireRtrvlNtsl.height = Math.max((itemCount * 30) + 190, 210);
      this.gridReloadFireRtrvlNtsl = true;
    },

    asstFuelInputInfo(){
      this.asstFuelInputDataSource = this.asstFuelInputInfo.list;
      this.initializeAsstFuelInputGridColumns();
      const itemCount = this.asstFuelInputInfo.list.length;
      // this.asstFuelInputGridAttr.height = (itemCount * 30) + 136;
      this.asstFuelInputGridAttr.height = Math.max((itemCount * 30) + 190, 210);
      this.asstFuelInputGridReload = true;
    },

    // 반입 그리드에서 반입량 합계가 변경되면 폐기물처리현황의 반입량항목에 적용
    'fireRtrvlDtlObj.wtStotCryqtyTotal': {
      handler(newValue) {
        this.fireRtrvlDtlObj.prfmncFireRtrvl.wtSumCryqty = newValue;
      },
      immediate: true
    },

    // 폐기물처리현황-이월량 값 변경 시 잔량 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.wtCrfqty': 'updateWtRmnqty',
    // 폐기물처리현황-반입량 값 변경 시 잔량 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.wtSumCryqty': 'updateWtRmnqty',
    // 폐기물처리현황-전처리량 값 변경 시 잔량 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.prprcsWstqty': 'updateWtRmnqty',
    // 폐기물처리현황-투입량 값 변경 시
    'fireRtrvlDtlObj.prfmncFireRtrvl.wtSumInpqty': {
      handler() {
        this.updateWtRmnqty(); // 폐기물처리현황의 잔량 계산
      },
      immediate: true
    },

    // 에너지회수현황-에너지발생량 값 변경 시 총에너지회수량 재계산
    'fireRtrvlDtlObj.eneOcrnQtyTotal': 'updateEneRtrqty',
    // 에너지회수현황-미이용 값 변경 시 총에너지회수량 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.eneUnuqty': 'updateEneRtrqty',
  },
  created() {
    this.initialize();
  },
  mounted() {
  },
  methods: {
    // 보조연료종류그룹코드(PFM007) 로드
    loadAsstFuelKndGroupCd() {
      const asstFuelKndGroupCdList = this.$store.getters.getCodeList({ groupCd: CODE.ASSISTANCE_FUEL_KIND_GROUP, useYn: 'Y' });
      this.asstFuelKndGroupCdList = asstFuelKndGroupCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    // 보조연료종류코드(PFM008) 로드
    loadAsstFuelKndCd() {
      const asstFuelKndCdList = this.$store.getters.getCodeList({ groupCd: CODE.ASSISTANCE_FUEL_KIND, useYn: 'Y' });
      this.asstFuelKndCdList = asstFuelKndCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd,
        frstRefVl: item.frstRefVl,
      }));
    },
    // 보조연료단위코드(PFM009) 로드
    loadAsstFuelUnitCd() {
      const asstFuelUnitCdList = this.$store.getters.getCodeList({ groupCd: CODE.ASSISTANCE_FUEL_UNIT, useYn: 'Y' });
      this.asstFuelUnitCdList = asstFuelUnitCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd,
        frstRefVl: item.frstRefVl,
      }));
    },
    // 저위발열량단위코드(PFM010) 로드
    loadLcpUnitCd() {
      const lcpUnitCdList = this.$store.getters.getCodeList({ groupCd: CODE.LOW_CALORIFIC_POWER_UNIT, useYn: 'Y' });
      this.lcpUnitCdList = lcpUnitCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd,
        frstRefVl: item.frstRefVl,
      }));
    },
    // 폐기물처리현황의 잔량 계산
    updateWtRmnqty() {
      this.fireRtrvlDtlObj.prfmncFireRtrvl.wtRmnqty =
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.wtCrfqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.wtSumCryqty) -
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.prprcsWstqty) -
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.wtSumInpqty);
    },
    // 에너지회수현황의 에너지이용량 계산
    updateEneUtzqty() {
      this.fireRtrvlDtlObj.eneutzQtyTotal =
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.stotUsqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.stotNsqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.elctgnEquipInpqty);
    },
    // 에너지회수현황의 총에너지회수량 계산
    updateEneRtrqty() {
      this.fireRtrvlDtlObj.prfmncFireRtrvl.eneRtrqty =
          parseFloat(this.fireRtrvlDtlObj.eneOcrnQtyTotal) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.eneUnuqty);
    },
    async initialize() {
      await this.loadPrfmncSttsCd();
      this.loadAsstFuelKndGroupCd();
      this.loadAsstFuelKndCd();
      this.loadAsstFuelUnitCd();
      this.loadLcpUnitCd();
    },
    // 공통코드(실적상태코드) 로드
    async loadPrfmncSttsCd() {
      const prfmncSttsList = this.$store.getters.getCodeList({groupCd: CODE.PERFORMANCE_STATUS, useYn: 'Y'});
      this.prfmncSttsList = prfmncSttsList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    // 폐기물 반입현황 그리드 컬럼 세팅
    initializePrfmncFireRtrvlWtCrynGridColumns() {
      this.gridColumnsFireRtrvlWtCryn = [
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        {
          caption: '반입',
          width: 200,
          part: 'head',
          columns : [
            {
              field: 'ctpvStdgNm',
              caption: '시·도',
              width: 100,
              editable: false,
            },
            {
              field: 'sggStdgNm',
              caption: '시·군·구',
              width: 100,
              editable: false,
            }
          ],
          total: {
            aggregates: [
            ],
            header: [
              { template: '계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        {
          field: 'wtStotCryqty',
          caption: '반입량 합계(톤)',
          unit: '%',
          align: 'right',
          dataType: 'number',
          editable: false,
          colCss: 'grid-disabled',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          calc: {
            require: ['bplcDschrgWstqty', 'hmeLifeWstqty', 'bplcLifeWstqty', 'largeWstqty', 'wstsrtWstqty', 'wstwdtWstqty', 'wstsftWstqty', 'wstvnlWstqty', 'etcWstqty'],
            eval: 'sum',
            nullAs: 0,
            dataType: 'number',
          },
          format: '#,##0.00'
        },
        {
          field: 'bplcDschrgWstqty',
          caption: '배출사업장계(톤)',
          width: 100,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00'
        },
        {
          caption: '종량제에 의한 혼합배출',
          columns : [
            {
              field: 'hmeLifeWstqty',
              caption: '가정생활(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'bplcLifeWstqty',
              caption: '사업장생활(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            }
          ]
        },
        {
          caption: '종량제 외 배출',
          columns : [
            {
              field: 'largeWstqty',
              caption: '대형폐기물(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'wstsrtWstqty',
              caption: '폐합성수지류(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'wstwdtWstqty',
              caption: '폐목재(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'wstsftWstqty',
              caption: '폐섬유(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'wstvnlWstqty',
              caption: '영농폐비닐(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'etcWstqty',
              caption: '기타(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            }
          ]
        }
      ]
    },
    // 시설별 폐기물 투입 그리드 컬럼 세팅
    initializePrfmncFireRtrvlInputGridColumns() {
      this.gridColumnsFireRtrvlInput = [
        { field: 'fcltSqno', caption: '시설순번', visible: false, editable: false },
        {
          field: 'fcltNm',
          caption: '시설명',
          width: 300,
          align: 'left',
          part: 'head',
          editable: false,
          total: {
            aggregates: [
            ],
            header: [
              { template: '계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        {
          caption: '투입량(톤)',
          columns: [
            {
              field: 'wtInpqtyTotal',
              caption: '투입량 합계(톤)',
              width: 150,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              calc: {
                require: ['bplcDschrgWstqty', 'hmeLifeWstqty', 'bplcLifeWstqty', 'largeWstqty', 'wstsrtWstqty', 'wstwdtWstqty', 'wstsftWstqty', 'wstvnlWstqty', 'etcWstqty'],
                eval: 'sum',
                nullAs: 0,
                dataType: 'number',
              },
              format: '#,##0.00',
            },
            {
              field: 'bplcDschrgWstqty',
              caption: '배출사업장계(톤)',
              width: 150,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'hmeLifeWstqty',
              caption: '생활폐기물(톤)',
              width: 150,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'bplcLifeWstqty',
              caption: '사업장폐기물(톤)',
              width: 150,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'largeWstqty',
              caption: '대형폐기물(톤)',
              width: 150,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'wstsrtWstqty',
              caption: '폐합성수지류(톤)',
              width: 150,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'wstwdtWstqty',
              caption: '폐목재(톤)',
              width: 150,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'wstsftWstqty',
              caption: '폐섬유(톤)',
              width: 150,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'wstvnlWstqty',
              caption: '영농폐비닐(톤)',
              width: 150,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'etcWstqty',
              caption: '기타(톤)',
              width: 150,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
          ]
        },
        {
          field: 'fireLcp',
          caption: '저위발열량(kcal/kg)',
          width: 150,
          dataType: 'number',
          align: 'right',
          total: {
            aggregates: [
              { func: (items) => {
                  const filteredItems = items.filter(item => item.fireLcp > 0);
                  const sumWstqty = filteredItems.reduce((total, item) => {
                    const totalWstqty =
                        (item.bplcDschrgWstqty || 0) +
                        (item.hmeLifeWstqty || 0) +
                        (item.bplcLifeWstqty || 0) +
                        (item.largeWstqty || 0) +
                        (item.wstsrtWstqty || 0) +
                        (item.wstwdtWstqty || 0) +
                        (item.wstsftWstqty || 0) +
                        (item.wstvnlWstqty || 0) +
                        (item.etcWstqty || 0);
                    return total + totalWstqty;
                  }, 0);

                  const sumWstqtyLcp = filteredItems.reduce((total, item) => {
                    const totalWstqty =
                        (item.bplcDschrgWstqty || 0) +
                        (item.hmeLifeWstqty || 0) +
                        (item.bplcLifeWstqty || 0) +
                        (item.largeWstqty || 0) +
                        (item.wstsrtWstqty || 0) +
                        (item.wstwdtWstqty || 0) +
                        (item.wstsftWstqty || 0) +
                        (item.wstvnlWstqty || 0) +
                        (item.etcWstqty || 0);
                    const fireLcp = item.fireLcp || 0;
                    return total + (totalWstqty * fireLcp);
                  }, 0);

                  return sumWstqty > 0 ? utils.round2(sumWstqtyLcp / sumWstqty) : 0;
                },
                field: 'fireLcpAvg'
              }
            ],
            header: [{ template: '{{fireLcpAvg}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
          format: '#,##0.00'
        },
        {
          caption: '투입량 검증', captionCss:'gridh-bg-validation',
          columns : [
            {
              field: 'mndlyFcltCap',
              caption: '월간시설용량(톤/월)',
              captionCss:'gridh-bg-validation',
              width: 150,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
              visible: false,
            },
            {
              field: 'sggStdgNm',
              caption: '초과용량(톤)',
              captionCss:'gridh-bg-validation',
              width: 150,
              align: 'right',
              dataType: 'number',
              editable: false,
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              getValue: (value, field, rowItem) => {
                const wtInpqtyTotal = rowItem.data.wtInpqtyTotal || 0;
                const mndlyFcltCap = rowItem.data.mndlyFcltCap || 0;

                return (parseFloat(wtInpqtyTotal) - parseFloat(mndlyFcltCap)) < 0 ? 0 : parseFloat(wtInpqtyTotal) - parseFloat(mndlyFcltCap);
              },
              colCss: (data) => {
                const wtInpqtyTotal = data.wtInpqtyTotal || 0;
                const mndlyFcltCap = data.mndlyFcltCap || 0;

                if (wtInpqtyTotal > mndlyFcltCap) {
                  return 'grid-danger';
                } else {
                  return 'grid-good';
                }
              },
              format: '#,##0.00',
              visible: false,
            },
            {
              field: 'vrfcRslt',
              caption: '검증결과',
              captionCss:'gridh-bg-validation',
              width: 150,
              editable: false,
              getValue: (value, field, rowItem) => {
                const wtInpqtyTotal = rowItem.data.wtInpqtyTotal || 0;
                const mndlyFcltCap = rowItem.data.mndlyFcltCap || 0;

                return wtInpqtyTotal > mndlyFcltCap ? "초과" : "적합";
              },
              colCss: (data) => {
                const wtInpqtyTotal = data.wtInpqtyTotal || 0;
                const mndlyFcltCap = data.mndlyFcltCap || 0;

                if (wtInpqtyTotal > mndlyFcltCap) {
                  return 'grid-danger';
                } else {
                  return 'grid-good';
                }
              },
              total: {
                aggregates: [
                ],
                header: [
                  { template: ''}
                ],
                headerCss:'grid-header',
              },
              visible: false,
            }
          ],
        },
      ]
    },
    initializeAsstFuelInputGridColumns() {
      this.asstFuelInputGridColumns = [
        { field: 'fcltSqno', caption: '시설명', width: 200,
          type: 'combo',
          // items: this.fcltList,
          items: this.asstFuelInputInfo.fcltList,
          comboLabel: 'fcltNm',
          comboValue: 'fcltSqno',
        },
        { caption: '보조연료 정보',
          columns: [
            { field: 'asstFuelKndGroupCd', caption: '연료구분', width: 150,
              type: 'combo',
              items: this.asstFuelKndGroupCdList,
              comboLabel: 'text',
              comboValue: 'value',
            },
            { field: 'asstFuelKndCd', caption: '연료종류', width: 150,
              type: 'combo',
              items: this.asstFuelKndCdList,
              comboLabel: 'text',
              comboValue: 'value',
              master: 'asstFuelKndGroupCd',
              masterField: 'frstRefVl',
              useFilter: true,
            },
            { field: 'asstFuelKndCn', caption: '기타종류명', width: 150,
              getValue: (value, field, rowItem) => {
                return rowItem.data.asstFuelKndGroupCd === '99' ? value : ''
              },
              colCss: (data) => {
                return data.asstFuelKndGroupCd === '99' ? '' : 'grid-disabled'
              }
            },
            { field: 'asstFuelInpqty', caption: '연료투입량', dataType: 'number', align: 'right', format: '#,##0.00', width: 150 },
            { field: 'asstFuelUnitCd', caption: '연료단위', width: 150,
              type: 'combo',
              items: this.asstFuelUnitCdList,
              comboLabel: 'text',
              comboValue: 'value',
              master: 'asstFuelKndGroupCd',
              masterField: 'frstRefVl',
              useFilter: true,
            },
            { field: 'asstFuelLcp', caption: '저위발열량', dataType: 'number', align: 'right', format: '#,##0.00', width: 150 },
            { field: 'lcpUnitCd', caption: '저위발열량 단위', width: 150,
              type: 'combo',
              items: this.lcpUnitCdList,
              comboLabel: 'text',
              comboValue: 'value',
              master: 'asstFuelUnitCd',
              masterField: 'frstRefVl',
              useFilter: true,
            },
          ]
        },
      ]
    },
    // 에너지생산 현황 그리드 컬럼 세팅
    initializePrfmncFireRtrvlPrdctnGridColumns() {
      this.gridColumnsFireRtrvlPrdctn = [
        { field: 'fcltSqno', caption: '시설순번', visible: false, editable: false },
        {
          field: 'fcltNm',
          caption: '시설명',
          width: 300,
          align: 'left',
          part: 'head',
          editable: false,
          total: {
            aggregates: [
            ],
            header: [
              { template: '계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        {
          caption: '증기', captionCss:'gridh-bg-vapor',
          columns: [
            {
              field: 'vaporPrdctnTon',
              caption: '증기생산량(톤)',
              width: 150,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
              captionCss:'gridh-bg-vapor',
            },
            {
              field: 'vaporEnthlpVl',
              caption: '증기엔탈피(kcal/kg)',
              width: 150,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              format: '#,##0.00',
              total: {
                headerCss:'grid-header',
              },
              captionCss:'gridh-bg-vapor',
            },
            {
              field: 'vaporPrdqty',
              caption: '증기생산량(Gcal)',
              width: 150,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
              captionCss:'gridh-bg-vapor',
            },
            {
              field: 'vaporTp',
              caption: '증기온도(°C)',
              width: 150,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
              captionCss:'gridh-bg-vapor',
            },
            {
              field: 'vaporPrssVl',
              caption: '증기압력(kgf/㎠)',
              width: 150,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
              captionCss:'gridh-bg-vapor',
            },
          ],
        },
        {
          field: 'htwtrPrdqty',
          caption: '온수생산량(Gcal)',
          width: 150,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              {func: 'sum', nullAs: 0},
            ],
            header: [
              {template: '{{sum}}', format: '#,##0.00', align: 'right'}
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
          captionCss:'gridh-bg-hot-water',
        },
        {
          field: 'prvtmPrdqty',
          caption: '전기생산량(MWh)',
          width: 150,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              {func: 'sum', nullAs: 0},
            ],
            header: [
              {template: '{{sum}}', format: '#,##0.00', align: 'right'}
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
          captionCss:'gridh-bg-electricity',
        },
      ]
    },
    // 에너지이용 현황 그리드 컬럼 세팅
    initializePrfmncFireRtrvlUseGridColumns() {
      this.gridColumnsFireRtrvlUse = [
        { field: 'fcltSqno', caption: '시설순번', visible: false, editable: false },
        {
          field: 'fcltNm',
          caption: '시설명',
          width: 300,
          align: 'left',
          part: 'head',
          editable: false,
          total: {
            aggregates: [
            ],
            header: [
              { template: '계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        {
          caption: '에너지 이용 정보',
          columns: [
            {
              caption: '증기(증기톤)', captionCss:'gridh-bg-vapor',
              columns: [
                {
                  caption: '자체이용(톤)', captionCss:'gridh-bg-vapor',
                  columns: [
                    {
                      field: 'stUsgUsqtyTon',
                      caption: '스팀이용',
                      width: 150,
                      align: 'right',
                      dataType: 'number',
                      total: {
                        aggregates: [
                          {func: 'sum', nullAs: 0},
                        ],
                        header: [
                          {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                        ],
                        headerCss:'grid-header',
                      },
                      format: '#,##0.00',
                      captionCss:'gridh-bg-vapor',
                    },
                    {
                      field: 'htwtrPrdctnUsgUsqtyTon',
                      caption: '온수이용',
                      width: 150,
                      align: 'right',
                      dataType: 'number',
                      total: {
                        aggregates: [
                          {func: 'sum', nullAs: 0},
                        ],
                        header: [
                          {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                        ],
                        headerCss:'grid-header',
                      },
                      format: '#,##0.00',
                      captionCss:'gridh-bg-vapor',
                    },
                    {
                      field: 'elctgnUsgUsqtyTon',
                      caption: '발전이용',
                      width: 150,
                      align: 'right',
                      dataType: 'number',
                      total: {
                        aggregates: [
                          {func: 'sum', nullAs: 0},
                        ],
                        header: [
                          {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                        ],
                        headerCss:'grid-header',
                      },
                      format: '#,##0.00',
                      captionCss:'gridh-bg-vapor',
                    },
                  ]
                },
                {
                  field: 'vaporUsqty',
                  caption: '자체이용(Gcal) (A)',
                  width: 150,
                  align: 'right',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      {func: 'sum', nullAs: 0},
                    ],
                    header: [
                      {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-vapor',
                },
                {
                  field: 'vaporNsqtyTon',
                  caption: '외부판매(톤)',
                  width: 150,
                  align: 'right',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      {func: 'sum', nullAs: 0},
                    ],
                    header: [
                      {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-vapor',
                },
                {
                  field: 'vaporNsqty',
                  caption: '외부판매(Gcal) (B)',
                  width: 150,
                  align: 'right',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      {func: 'sum', nullAs: 0},
                    ],
                    header: [
                      {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-vapor',
                },
              ]
            },
            {
              caption: '온수(Gcal)', captionCss:'gridh-bg-hot-water',
              columns: [
                {
                  field: 'htwtrUsqty',
                  caption: '자체이용',
                  width: 150,
                  align: 'right',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      {func: 'sum', nullAs: 0},
                    ],
                    header: [
                      {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-hot-water',
                },
                {
                  field: 'htwtrNsqty',
                  caption: '외부판매',
                  width: 150,
                  align: 'right',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      {func: 'sum', nullAs: 0},
                    ],
                    header: [
                      {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-hot-water',
                },
              ]
            },
            {
              caption: '전기(MWh)', captionCss:'gridh-bg-electricity',
              columns: [
                {
                  field: 'prvtmUsqty',
                  caption: '자체이용',
                  width: 150,
                  align: 'right',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      {func: 'sum', nullAs: 0},
                    ],
                    header: [
                      {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-electricity',
                },
                {
                  field: 'prvtmNsqty',
                  caption: '외부판매',
                  width: 150,
                  align: 'right',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      {func: 'sum', nullAs: 0},
                    ],
                    header: [
                      {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-electricity',
                },
              ]
            },
          ],
        },
      ]
    },
    // 에너지 외부판매 현황 그리드 컬럼 세팅
    initializePrfmncFireRtrvlNtslGridColumns() {
      this.gridColumnsFireRtrvlNtsl = [
        { field: 'eneBzentySn', caption: '에너지업체일련번호', visible: false, editable: false },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        {
          caption: '판매 업체',
          width: 700,
          part: 'head',
          columns: [
            {
              field: 'ctpvStdgNm',
              caption: '시·도',
              width: 200,
              editable: false,
            },
            {
              field: 'sggStdgNm',
              caption: '시·군·구',
              width: 200,
              editable: false,
            },
            {
              field: 'bzentyNm',
              caption: '업체명',
              width: 300,
              align: 'left',
              editable: false,
            },
          ],
          total: {
            aggregates: [],
            header: [
              {template: '계', align: 'right'}
            ],
            headerCss:'grid-header',
          },
        },
        {
          caption: '외부판매량',
          columns: [
            {
              field: 'vaporTonNsqty',
              caption: '증기(증기톤)',
              width: 150,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
              captionCss:'gridh-bg-vapor',
            },
            {
              field: 'vaporNsqty',
              caption: '증기(Gcal)',
              width: 150,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
              captionCss:'gridh-bg-vapor',
            },
            {
              field: 'htwtrNsqty',
              caption: '온수(Gcal)',
              width: 150,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
              captionCss:'gridh-bg-hot-water',
            },
            {
              field: 'prvtmNsqty',
              caption: '전기(MWh)',
              unit: '%',
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
              captionCss:'gridh-bg-electricity',
            },
          ]
        }
      ]
    },
    gridLoadedFireRtrvlWtCryn() {
      this.gridReloadFireRtrvlWtCryn = false;
    },
    gridLoadedFireRtrvlInput() {
      this.gridReloadFireRtrvlInput = false;
    },
    asstFuelInputGridLoaded() {
      this.asstFuelInputGridReload = false;
    },
    gridLoadedFireRtrvlPrdctn() {
      this.gridReloadFireRtrvlPrdctn = false;
    },
    gridLoadedFireRtrvlUse() {
      this.gridReloadFireRtrvlUse = false;
    },
    gridLoadedFireRtrvlNtsl() {
      this.gridReloadFireRtrvlNtsl = false;
    },
    formatNumber(number) {
      return utils.formatNumberWithComma(number);
    },
    // 팝업 show 함수
    callInfoPopup(){
      storeSwitch.on('infoPopup');
    },
    callEnergyPopup(){
      storeSwitch.on('energyPopup');
    },

    // 소수점 둘쨰자리 오류 수정
    // 부동소수점(floating point) 연산의 정밀도 문제 스크립트 고유 버그
    sanitizeFloatingPointError(value){
      if (typeof value !== "number") return value; // 숫자가 아닐 경우 반환

      // 소수점 2자리까지만 유지하고 반올림하여 부동소수점 오차 제거
      return parseFloat((Math.round(value * 100) / 100).toFixed(2));

    },

  }
}
</script>

<style scoped>

</style>