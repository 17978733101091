<template>
  <div class="desc_box">
    <i class="icon"><img src="@/assets/images/common/ico_desc.png" alt="이미지"></i>
    <div class="desc">
      <strong class="desc_title">품질등급제란?</strong>
      <p>고형연료제품 품질(확인)검사, 품질표시검사의 결과를 활용하여 품질등급을 판정하고, 고품질의 고형연료제품 제조ㆍ사용을 유도하는 등 고형연료제품의 환경적 안정성을 도모하기 위한 제도</p>
      <div class="btn_area" style="font-size: 1.5rem;">[관련근거: 자원의 절약과 재활용 촉진에 관한 법률 제25조5 (고형연료제품의 품질검사)]</div>
    </div>
  </div>
  <h2 class="title1">품질등급제 개요 및 등급산정</h2>
  <div class="img_box_area"><img src="@/assets/images/sub/qltyGrdeIntro_01.png" alt="등급 평가 대상 : 고형연료제품 제조자·수입자. 품질 평가 항목 : (저위)발열량, 수은, 염소, 황분(4가지 항목). 등급 기준 : '최우수', '우수', '양호'(3개 등급)"></div>
  <div class="img_box_area">
    <div class="img_box top_line">등급판정</div>
    <img src="@/assets/images/sub/qltyGrdeIntro_02.png" alt="1. 품질항목별 평가점수 결정(각 항목당 1~3점) 2. 품질항목별 평가점수 합계(총합 4~12점) 3. 품질등급 판정(최우수, 우수, 양호)">
    <p class="mt_20"><strong>정기판정</strong>은 6월 30일, 12월 31일에 판정이 진행됩니다.<br>
      <strong>수시판정</strong>은 신규사업자 제조신고 시 실시한 품질검사 결과로 등급판정(다음 정기 판정일까지 적용)이 진행되고,<br>
      수입자 수입신고 시 실시한 품질검사 결과로 등급판정(수입 유효기간동안 적용)이 진행됩니다.</p>
  </div>
  <div class="tbl_wrap mt_40">
    <table>
      <caption>등급판정_구분, 단위, SRF(3점, 2점, 1점), BIO-SRF(3점, 2점, 1점)으로 구성</caption>
      <colgroup>
        <col style="width:10%">
        <col style="width:22%">
        <col style="width:8%">
        <col style="width:10%">
        <col style="width:10%">
        <col style="width:10%">
        <col style="width:10%">
        <col style="width:10%">
        <col style="width:10%">
      </colgroup>
      <thead>
      <tr>
        <th scope="col" rowspan="2" colspan="2">구분</th>
        <th scope="col" rowspan="2">단위</th>
        <th scope="col" colspan="3">SRF</th>
        <th scope="col" colspan="3">BIO-SRF</th>
      </tr>
      <tr>
        <th scope="col">3점</th>
        <th scope="col">2점</th>
        <th scope="col">1점</th>
        <th scope="col">3점</th>
        <th scope="col">2점</th>
        <th scope="col">1점</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th scope="row" rowspan="4">품질항목</th>
        <th scope="row">(저위)발열량<br>(수입제품)</th>
        <td>㎉/㎏</td>
        <td>≥6,000<br>(≥6,150)</td>
        <td>≥5,000<br>(≥5,150)</td>
        <td>≥3,500<br>(≥3,650)</td>
        <td>≥3,600<br>(≥3,750)</td>
        <td>≥3,300<br>(≥3,450)</td>
        <td>≥3,000<br>(≥3,150)</td>
      </tr>
      <tr>
        <th scope="row">수은</th>
        <td>㎎/㎏</td>
        <td>≤0.2</td>
        <td>≤0.5</td>
        <td>≤1</td>
        <td>≤0.1</td>
        <td>≤0.3</td>
        <td>≤0.6</td>
      </tr>
      <tr>
        <th scope="row">염소</th>
        <td>wt.%</td>
        <td>≤0.5</td>
        <td>≤1</td>
        <td>≤2</td>
        <td>≤0.1</td>
        <td>≤0.3</td>
        <td>≤0.5</td>
      </tr>
      <tr>
        <th scope="row">황분<br>(폐타이어만으로 제조한 경우)</th>
        <td>wt.%</td>
        <td>≤0.2<br>(≤1.6)</td>
        <td>≤0.4<br>(≤1.8)</td>
        <td>≤0.6<br>(≤2.0)</td>
        <td>≤0.05</td>
        <td>≤0.1</td>
        <td>≤0.6</td>
      </tr>
      <tr>
        <th scope="row" colspan="2">판정기준</th>
        <td colspan="7" class="txt_left">
          ① 최우수 : 4개 품질항목의 평가점수 합이 10점 이상<br>
          ② 우수 : 4개 품질항목의 평가점수 합이 8점 이상 10점 미만<br>
          ③ 양호 : 4개 품질항목의 평가점수 합이 4점 이상 8점 미만
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h3 class="title2">사용시설 인센티브</h3>
  <p>면제조건을 만족하는 사용시설에 대하여 해당분기 품질확인검사 면제</p>
  <ul class="blue_dot_list">
    <li>면제조건 :
      <ul class="dot_list">
        <li>고형연료제품 사용량 중 ‘최우수’ 등급을 70%이상 사용하는 경우</li>
        <li>고형연료제품 사용량 중 ‘최우수’ 등급과 ‘우수’ 등급을 90%이상 사용하는 경우</li>
      </ul>
    </li>
    <li>적용방법 :
      <ul class="dot_list">
        <li>폐자원에너지종합정보관리시스템의 사용실적을 확인하여 분기 시작 후 15일 이내에 면제대상여부시스템 ‘공지사항’에 통보</li>
        <li>품질등급 최초적용('21.1.1.)의 다음분기인 '21.4.1.부터 적용</li>
      </ul>
      <span class="txt_hint">※ 분기 시작 후 15일 이내란? 1월 15일, 4월 15일, 7월 15일, 10월 15일 이내를 말함.</span>
    </li>
  </ul>

  <h2 class="title1">업무절차 흐름도</h2>
  <div class="img_box_area mt_0"><img src="@/assets/images/sub/qltyGrdeIntro_03.png" alt="품질확인검사, 품질표시검사 결과 폐자원에너지센터에서 임시 품질등급 산정 → 이의신청 → 품질등급 최종 판정 → 사용시설 인센티브 대상을 산정합니다."></div>
  <div class="tbl_wrap mt_20">
    <table>
      <caption>업무절차_구분, 대상업체, 검사기간, 임시 품질등급서 발급, 의견서 제출 기간, 품질등급 발표일(유효기간), 사용시설 인센티브 대상 선정으로 구성</caption>
      <colgroup>
        <col style="width:14%">
        <col style="width:14%">
        <col style="width:14%">
        <col style="width:14%">
        <col style="width:14%">
        <col style="width:15%">
        <col style="width:15%">
      </colgroup>
      <thead>
      <tr>
        <th scope="col">구분</th>
        <th scope="col">대상업체</th>
        <th scope="col">검사기간</th>
        <th scope="col">임시 품질등급서 발급</th>
        <th scope="col">의견서 제출 기간</th>
        <th scope="col">품질등급 발표일<br>(유효기간)</th>
        <th scope="col">사용시설 인센티브 대상 선정</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th scope="row" rowspan="2">정기판정</th>
        <td rowspan="2">제조업체</td>
        <td>4/1 ~ 9/30<br>(2,3분기)</td>
        <td>11/25</td>
        <td rowspan="4">임시 품질등급서 발급 후 10일 이내</td>
        <td>12/31<br>(1/1 ~ 6/30)</td>
        <td rowspan="4">분기 시작 후 15일 이내에 인센티브 대상 발표</td>
      </tr>
      <tr>
        <td>10/1 ~ 3/31<br>(4,1분기)</td>
        <td>5/26</td>
        <td>6/30<br>(7/1 ~ 12/31)</td>
      </tr>
      <tr>
        <th scope="row" rowspan="2">수시판정</th>
        <td>신규 제조업체</td>
        <td rowspan="2">-</td>
        <td rowspan="2">시료채취일로부터 30일 이내</td>
        <td>시료채취일로부터 60일 이내 ~ 다음 품질등급 공개일까지</td>
      </tr>
      <tr>
        <td>수입업체</td>
        <td>~ 수입유효기간</td>
      </tr>
      </tbody>
    </table>
  </div>

  <h2 class="title1">관련규정</h2>
  <ul class="blue_dot_list">
    <li>「자원의 절약과 재활용 촉진에 관한 법률」 법 제25조의5 (고형연료제품의 품질검사)</li>
    <li>「자원의 절약과 재활용 촉진에 관한 법률」 시행규칙 제20조의2 (고형연료제품의 품질기준 등)</li>
    <li>「자원의 절약과 재활용 촉진에 관한 법률」 시행규칙 제20조의5 (고형연료제품의 품질확인 절차 및 주기 등)</li>
    <li>고형연료제품의 품질표시 방법 및 품질등급 구분 등에 관한 고시</li>
  </ul>
</template>

<script>
export default {  
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {}
}
</script>

<style scoped>

</style>