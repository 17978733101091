<template>
  <div class="sch_form_wrap">
    <div class="sch_form row3">
        <span>
          <label>업체명</label>
          <br-form-input label="업체명" v-model="searchParam.bzentyNm" disabled/>
        </span>
        <span>
          <label>사업장명</label>
          <br-form-input label="사업장명" v-model="searchParam.bplcNm" class="w200" disabled/>
        </span>
        <span v-if="searchParam.bplcKndCd=='01-01'">
          <label>시설명</label>
          <br-form-input label="시설명" v-model="searchParam.fcltNm" class="w200" disabled/>
        </span>
        <span v-if="searchParam.bplcKndCd=='01-03'">
          <label>검사ID</label>
          <br-form-input class="w200" label="검사ID" v-model="searchParam.inspId" disabled/>
        </span>
      </div>
    </div>
    <div class="cont_box">
      <div align="center" class="title mt_35" style="display: flex; flex-direction: column; align-items: center;">
        <h3>
          <br-form-radio label="시설 종류" :options="bplcKndOption" v-model:value="bplcParam.bplcKndCd"  panel="panel" readonly/>
        </h3>
        <h1 class="mb_25" >외부 품질표시검사 현황</h1>
      </div>
      <div class="board_write mb_25">
        <table>
          <caption>
            고형연료 제품 수입ㆍ제조사, 고형연료 제품 품질내용
          </caption>
          <colgroup>
              <col style="width:15%">
              <col style="width:35%">
              <col style="width:15%">
              <col style="width:35%">
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">품질명세서 첨부파일</th>
              <td colspan="3">
                <br-attach-file label="품질명세서 첨부파일"
                  atchFileTaskSeCd="FSM"
                  v-model:atchFileGroupId="atchFileGroupId"
                  v-model:fileProcessingData="param.fileProcessingData"
                  :files="files" panel="panel"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="board_write">
        <table>
          <caption>고형연료 제품 수입ㆍ제조사 상호, 대표자성명, 사업장 소재지, 전화번호, 수입ㆍ제조신고 번호, 제조국ㆍ제조업체</caption>
          <colgroup>
              <col style="width:8%">
              <col style="width:14%">
              <col style="width:35%">
              <col style="width:14%">
              <col style="width:70%">
          </colgroup>
          <tbody>
              <tr>
                <th scope="row" rowspan="4" style="text-align: center; border-right:1px solid #e0e0e0">고형연료 제품 수입ㆍ제조사</th>
                <th scope="row">상호</th>
                <td>{{ bplcParam.bplcNm }}</td>
                <th scope="row">대표자성명</th>
                <td>{{ bplcParam.rprsvNm }}</td>
              </tr>
              <tr>
                <th scope="row">사업장 소재지</th>
                <td v-if="bplcParam.untyZip"> [{{ bplcParam.untyZip }}] {{ bplcParam.bplcAddr }} {{ bplcParam.bplcDaddr }}</td>
                <td v-else>{{ bplcParam.bplcAddr }} {{ bplcParam.bplcDaddr }}</td>
                <th scope="row">전화번호</th>
                <td>{{ bplcParam.telno }}</td>
              </tr>
              <tr>
                <th scope="row">수입ㆍ제조신고 번호</th>
                <td colspan="3" style="text-align: left">{{ bplcParam.dclrNo }}</td>
              </tr>
              <tr>
                <th scope="row">제조국ㆍ제조업체</th>
                <td colspan="3" style="text-align: left">{{ bplcParam.bzentyNm }}</td>
              </tr>
            <tr>
              <th scope="row" rowspan="10" style="text-align: center; border-right:1px solid #e0e0e0">고형연료 제품 품질내용</th>
              <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>발급번호</th>
              <td>
                <br-form-input class="w200" label="발급번호" panel="panel" required v-model="param.qltyIndctIssuNo" :maxlength="20"/>
              </td>
              <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>검사기관</th>
              <td>
                <br-form-select label="검사기관" v-model:value="param.qltyIndctInsofcCd" :options="insofcOtions" panel="panel" required/>
              </td>
            </tr>
            <tr>
              <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>검사일자</th>
              <td>
                <br-date-picker label="검사일자" v-model:value="param.inspBgngYmd" panel="panel" required class="mr_10"/>
              </td>
              <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>발급일자</th>
              <td>
                <br-date-picker label="발급일자" v-model:value="param.inspEndYmd" panel="panel" required class="mr_10"/>
              </td>
            </tr>
            <tr>
              <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>종류</th>
              <td>
                <br-form-checkbox label="일반 성형" v-model:values="srfList" :options="srfCheckOption" @change="srfChange"/>
              </td>
              <td colspan="2">
                <br-form-checkbox label="바이오 성형" v-model:values="bioSrfList" :options="bioSrfCheckOption" @change="bioSrfChange"/>
              </td>
            </tr>
            <tr>
              <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>원재료의 종류 및 구성비율</th>
              <td colspan="3">
                <table>
                  <caption>원재료 종류</caption>
                  <colgroup>
                    <col style="width:16.66%"><col style="width:16.66%">
                  </colgroup>
                  <tbody>
                    <tr>
                      <th colspan="3" scope="col">원재료 종류</th>
                    </tr>
                    <tr>
                      <td colspan="3" v-if="bplcParam.bplcKndCd=='01-03'"><br-form-input label="원재료종류" v-model="bplcParam.hskNm" panel="panel" required readonly style="text-align: center"/></td>
                      <td colspan="3" v-if="bplcParam.bplcKndCd=='01-01'"><br-form-select label="원재료종류" v-model:value="param.prdctRawmtrlCd" panel="panel" required groupCd="COM416"/></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <th scope="row">모양 및 크기</th>
              <td colspan="3">
                <table>
                  <caption>모양 및 크기 비성형(체통과율), 성형(직경/가로)(비성형의 경우 체의 크기), 성형(길이/세로)(비성형의 경우 체의 크기)/</caption>
                  <colgroup>
                    <col style="width:16.66%"><col style="width:16.66%"><col style="width:16.66%">
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="col">비성형(체통과율)</th>
                      <th scope="col">성형(직경/가로)<BR/>(비성형의 경우 체의 크기)</th>
                      <th scope="col">성형(길이/세로)<BR/>(비성형의 경우 체의 크기)</th>
                    </tr>
                    <tr>
                      <td>
                        <br-form-input label="체통과율" 
                        :disabled="param.prdctKndCd === '0001' || param.prdctKndCd === '0003'" 
                        panel="panel" placeholder="" v-model="param.svpsrt" align="right" unit="%" validator="number" :maxlength="10" />
                      </td>
                      <td><br-form-input label="직경/가로" placeholder="" v-model="param.dmtrMsrmtVl" panel="panel"  align="right" unit="mm" validator="number" :maxlength="10"/></td>
                      <td><br-form-input label="길이/세로" placeholder="" v-model="param.lenMsrmtVl" panel="panel" align="right" unit="mm" validator="number" :maxlength="10"/></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>발열량 및 수분 함유량</th>
              <td colspan="3">
                <table>
                  <caption>발열량 및 수분 함유량 (저위)발열량, 수분 함유량</caption>
                  <colgroup>
                    <col style="width:16.66%"><col style="width:16.66%">
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="col">(저위)발열량</th>
                      <th scope="col">수분 함유량</th>
                    </tr>
                    <tr>
                      <td><br-form-input label="(저위)발열량" placeholder="" v-model="param.inspQty180" required panel="panel" align="right" unit="kcal/kg" validator="number" :maxlength="10"/></td>
                      <td><br-form-input label="수분 함유량" placeholder="" v-model="param.inspQty160" required panel="panel" align="right" unit="wt.%" validator="number" :maxlength="10"/></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>금속성분 함유량</th>
              <td colspan="3">
                <table>
                  <caption>금속성분 함유량 수은(Hg), 카드뮴(Cd), 납(pb), 비소(As), 크롬(Cr), 안티몬(Sb)</caption>
                  <colgroup>
                    <col style="width:16.66%"><col style="width:16.66%"><col style="width:16.66%"><col style="width:16.66%"><col style="width:16.66%"><col style="width:16.66%">
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="col">수은(Hg)</th>
                      <th scope="col">카드뮴(Cd)</th>
                      <th scope="col">납(pb)</th>
                      <th scope="col">비소(As)</th>
                      <th scope="col">크롬(Cr)</th>
                      <th scope="col">안티몬(Sb)</th>
                    </tr>
                    <tr>
                      <td><br-form-input label="수은(Hg)" placeholder="" v-model="param.inspQty80" panel="panel" required align="right" unit="mg/kg" validator="number" :maxlength="10"/></td>
                      <td><br-form-input label="카드뮴(Cd)" placeholder="" v-model="param.inspQty90" panel="panel" required align="right" unit="mg/kg" validator="number" :maxlength="10"/></td>
                      <td><br-form-input label="납(pb)" placeholder="" v-model="param.inspQty100" panel="panel" required align="right" unit="mg/kg" validator="number" :maxlength="10"/></td>
                      <td><br-form-input label="비소(As)" placeholder="" v-model="param.inspQty110" panel="panel" required align="right" unit="mg/kg" validator="number" :maxlength="10"/></td>
                      <td><br-form-input label="크롬(Cr)" placeholder="" v-model="param.inspQty120" panel="panel" required align="right" unit="mg/kg" validator="number" :maxlength="10"/></td>
                      <td><br-form-input label="안티몬(Sb)" placeholder="" v-model="param.inspQty10" panel="panel" required align="right" unit="mg/kg" validator="number" :maxlength="10"/></td>
                    </tr>
                    <tr>
                      <th scope="col">코발트</th>
                      <th scope="col">구리</th>
                      <th scope="col">망간</th>
                      <th scope="col">니켈</th>
                      <th scope="col">탈륨</th>
                      <th scope="col">바나듐</th>
                    </tr>
                    <tr>
                      <td><br-form-input label="코발트" placeholder="" v-model="param.inspQty20" panel="panel" required align="right" unit="mg/kg" validator="number" :maxlength="10"/></td>
                      <td><br-form-input label="구리" placeholder="" v-model="param.inspQty30" panel="panel" required align="right" unit="mg/kg" validator="number" :maxlength="10"/></td>
                      <td><br-form-input label="망간" placeholder="" v-model="param.inspQty40" panel="panel" required align="right" unit="mg/kg" validator="number" :maxlength="10"/></td>
                      <td><br-form-input label="니켈" placeholder="" v-model="param.inspQty50" panel="panel" required align="right" unit="mg/kg" validator="number" :maxlength="10"/></td>
                      <td><br-form-input label="탈륨" placeholder="" v-model="param.inspQty60" panel="panel" required align="right" unit="mg/kg" validator="number" :maxlength="10"/></td>
                      <td><br-form-input label="바나듐" placeholder="" v-model="param.inspQty70" panel="panel" required align="right" unit="mg/kg" validator="number" :maxlength="10"/></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>원소성분 함유량</th>
              <td colspan="3">
                <table>
                  <caption>원소성분 함유량 회분 함유량, 염소(Cl) 함유량, 황분(S) 함유량, 바이오매스</caption>
                  <colgroup>
                    <col style="width:16.66%"><col style="width:16.66%"><col style="width:16.66%"><col style="width:16.66%">
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="col">회분 함유량</th>
                      <th scope="col">염소(Cl) 함유량</th>
                      <th scope="col">황분(S) 함유량</th>
                      <th scope="col">바이오매스</th>
                    </tr>
                    <tr>
                      <td><br-form-input label="회분 함유량" placeholder="" v-model="param.inspQty170" panel="panel" required align="right" unit="wt.%" validator="number" :maxlength="10"/></td>
                      <td><br-form-input label="염소(Cl) 함유량" placeholder="" v-model="param.inspQty140" panel="panel" required align="right" unit="wt.%" validator="number" :maxlength="10"/></td>
                      <td><br-form-input label="황분(S) 함유량" placeholder="" v-model="param.inspQty130" panel="panel" required align="right" unit="wt.%" validator="number" :maxlength="10"/></td>
                      <td><br-form-input label="바이오매스" placeholder="" v-model="param.inspQty190" panel="panel" required align="right" unit="wt.%" validator="number" :maxlength="10"/></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <th scope="row">기타</th>
              <td colspan="3"><br-form-input label="기타" placeholder="" v-model="param.etcQltyIndctInspCn" panel="panel" :maxlength="1000"/></td>
            </tr>
            <tr>
              <th scope="row">참고사항</th>
              <td colspan="3"><br-form-input label="참고사항" placeholder="" v-model="param.qltyIndctInspExplnCn" panel="panel" :maxlength="1000"/></td>
            </tr>
            <tr v-show="param.rjctCn !== undefined && param.rjctCn !== ''">
              <th scope="row" colspan="2" style="text-align: center;">반려사유</th>
              <td colspan="3">{{ param.rjctCn }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    <div class="btn_area right">
      <br-button label="저장" format="sch" size="sm" 
        :disabled="param.aprvYn === 'Y'" 
        @click="update" panel="panel" validate :auth="['C']"/>
      <br-button label="삭제" format="sch" size="sm" 
        :disabled="param.aprvYn !== 'D'" 
        @click="remove" panel="panel" validate :auth="['C']"/>
      <br-anchor-button label="목록" size="sm" @click="navigateTo"/>
    </div>
  </div>
  <ocr-yn-popup v-model:ocrAplcnYn="this.param.ocrAplcnYn" />
  </template>
  
  <script>
  import { storeSession } from "@/js/store/store-session";
  import { storeSwitch } from "@/js/store/store-switch";
  import OcrYnPopup from '@/views/sfli/popup/OcrYnPopup.vue';
  
  const API = {
    OTST_QLTY_INDCT_RSLT: "/otsdQltyIndctRslt",
  }
  
  export default {
    components: {OcrYnPopup},
    data() {
      return {
        userInfo : storeSession.getMemberInfo(),
        searchParam: {
          bzentyNm: "",
          bplcNm: "",
          inspId: "",
          fcltSqno: "",
          fcltNm: "",
        },
        bplcParam: {
          bplcNm: "",
          picNm: "",
          untyZip: "",
          bplcAddr: "",
          bplcDaddr: "",
          telno : "",
          dclrNo: "",
          bzentyNm: "",
          hskNm: "",
          bplcKndCd : "",
        },
        atchFileGroupId: "",
        param : {
          qltyInspRsltSn: "",
          inspSn : "",
          bplcId : "",
          qltyIndctInsofcCd : "",
          qltyIndctIssuNo: "",
          
          prdctKndCd: "",
          hskCd: "",
          prdctRawmtrlCd: "",
          svpsrt: "",
          dmtrMsrmtVl: "",
          lenMsrmtVl: "",
          inspQty10: "",
          inspQty20: "",
          inspQty30: "",
          inspQty40: "",
          inspQty50: "",
          inspQty60: "",
          inspQty70: "",
          inspQty80: "",
          inspQty90: "",
          inspQty100: "",
          inspQty110: "",
          inspQty120: "",
          inspQty130: "",
          inspQty140: "",
          inspQty160: "",
          inspQty170: "",
          inspQty180: "",
          inspQty190: "",
          ocrAplcnYn: "N",
          etcQltyIndctInspCn: "",
          qltyIndctInspExplnCn: "",
          atchFileGroupId: "",
          fileProcessingData: {},
          inspBgngYmd : "",
          inspEndYmd : "",
        },
        srfList: [],
        bioSrfList: [],
        bzentyOptions: [],
        bplcOptions: [],
        inspOptions: [],
        files: [],
        bplcKndOption: [
          {value: '01-03', text: '수입'},
          {value: '01-01', text: '제조'},
        ],
        insofcOtions : [
          {value: '10', text: '한국산업기술시험원'},
          {value: '20', text: '한국기계연구원'},
          {value: '30', text: '한국에너지기술연구원'},
          {value: '50', text: 'FITI시험연구원'},
          {value: '60', text: '대덕분석기술연구원'},
          {value: '70', text: '한국화학융합시험연구원'},
        ],
        srfCheckOption : [
          {value: '1', text: '일반 고형연료'},
          {value: '2', text: '성형'},
          {value: '3', text: '비성형'},
        ],
        bioSrfCheckOption : [
          {value: '1', text: '바이오 고형연료'},
          {value: '2', text: '성형'},
          {value: '3', text: '비성형'},
        ],
      };
    },
    mounted() {
      window.scrollTo(0, 0);
      this.param.qltyInspRsltSn = this.$route.query.qltyInspRsltSn
      this.param.bplcId = this.$route.query.bplcId
      this.param.inspSn = this.$route.query.inspAplySn
      this.param.bplcKndCd = this.$route.query.bplcKndCd
      this.searchParam.inspId = this.$route.query.inspId
      this.searchParam.bzentyNm = this.$route.query.bzentyNm
      this.searchParam.bplcNm = this.$route.query.bplcNm
      this.searchParam.fcltSqno = this.$route.query.fcltSqno
      this.searchParam.fcltNm = this.$route.query.fcltNm
      this.searchParam.bplcKndCd = this.$route.query.bplcKndCd
      this.search()
    },
    watch: {
      atchFileGroupId(newVal) {
        this.param.atchFileGroupId = newVal;
        this.showOcrYnPopup();
      }
    },
    methods: {
      remove() {
        if(!this.param.qltyInspRsltSn) {
          alert('품질검사결과일련번호 확인해주세요.')
          return false;
        }

        if(confirm("삭제 하시겠습니까?")) { 
          this.$apiCall.delete(
            API.OTST_QLTY_INDCT_RSLT,
            {'qltyInspRsltSn' : this.param.qltyInspRsltSn},
            () => {
              alert("삭제 하였습니다.");
              this.navigateTo();
            },
            () => {
              alert("삭제 실패하였습니다.");
            }
          )
        }
      },
      update() {
        if( this.param.bplcId == '' ) {
          alert('사업장을 선택해주세요.')
          return false;
        }
        if( this.param.prdctKndCd == '' ) {
          alert('종류는 필수입력입니다.')
          return false;
        }
        if( this.param.inspBgngYmd > this.param.inspEndYmd ) {
          alert('발급일자가 검사일자보다 빠릅니다.')
          return false;
        }

        if(confirm("저장 하시겠습니까?")) { 
          let updateParam = this.param;
          updateParam.inspQty = [];
          for(let i = 1; i < 20; i++){
            updateParam.inspQty.push(
              this.param[`inspQty${i*10}`]
            )
          }
          this.$apiCall.put(
            API.OTST_QLTY_INDCT_RSLT,
            updateParam,
            () => {
              alert("저장에 성공하였습니다.");
              window.scrollTo(0, 0);
            },
            () => {
              alert("저장에 실패하였습니다.");
            }
          )
        }
      },
      srfChange(){
        this.bioSrfList = []
        if(this.srfList.slice(-1)[0] == '2'){
          this.srfList = []
          this.srfList.push('1')
          this.srfList.push('2')
          this.param.prdctKndCd = '0001'
        }
        if(this.srfList.slice(-1)[0] == '3'){
          this.srfList = []
          this.srfList.push('1')
          this.srfList.push('3')
          this.param.prdctKndCd = '0002'
        }
        this.selChgPrdctKnd();
      },
      bioSrfChange(){
        this.srfList = []
        if(this.bioSrfList.slice(-1)[0] == '2'){
          this.bioSrfList = []
          this.bioSrfList.push('1')
          this.bioSrfList.push('2')
          this.param.prdctKndCd = '0003'
        }
        if(this.bioSrfList.slice(-1)[0] == '3'){
          this.bioSrfList = []
          this.bioSrfList.push('1')
          this.bioSrfList.push('3')
          this.param.prdctKndCd = '0004'
        }
        this.selChgPrdctKnd();
      },
      search() {
        this.$apiCall.get(
          API.OTST_QLTY_INDCT_RSLT + "/detail",
          {'inspSn' : this.param.inspSn,
          'bplcId' : this.param.bplcId,
          'qltyInspRsltSn' : this.param.qltyInspRsltSn,
          'bplcKndCd' : this.param.bplcKndCd},
          (data)=>{
            if(data.result != null) {
              if(data.result.qltyInfo != null){
                const json = JSON.parse(JSON.stringify(data.result.qltyInfo));
                json.inspSn = String(json.inspSn)
                this.param = json
                this.srfList = [];
                this.bioSrfList = [];
                if (this.param.prdctKndCd == '0001'){
                  this.srfList.push('1');
                  this.srfList.push('2');
                }
                if (this.param.prdctKndCd == '0002'){
                  this.srfList.push('1');
                  this.srfList.push('3');
                }
                if (this.param.prdctKndCd == '0003'){
                  this.bioSrfList.push('1');
                  this.bioSrfList.push('2');
                }
                if (this.param.prdctKndCd == '0004'){
                  this.bioSrfList.push('1');
                  this.bioSrfList.push('3');
                }
                if (data.result.files) {
                  this.files = data.result.files
                }
              }else{
                this.setParamNull()
              }
              
              if(data.result.bplcInfo != null){
                const json = JSON.parse(JSON.stringify(data.result.bplcInfo));
                this.bplcParam = json;
              } else{
                this.setBplcParamNull();
              }

              if(this.param.rjctCn) {
                alert("반려사유 확인바랍니다.");
                window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
              }
            } else {
              this.setParamNull()
              this.setBplcParamNull()
            }
          },
          ()=>{
            alert("조회에 실패하였습니다.");
            this.setParamNull()
          },
        )
      },
      setParamNull() {
        this.srfList = [];
        this.bioSrfList = [];
        const paramKeys = Object.keys(this.param);
        for (let key of paramKeys) {
          if (key != 'inspSn' && key != 'bplcId') {
            this.param[key] = "";
          }
        }
        
      },
      setBplcParamNull() {
        const bplcKeys = Object.keys(this.bplcParam);
        for (let key of bplcKeys) {
          this.bplcParam[key] = "";
        }
      },
      showOcrYnPopup() {
        storeSwitch.on('OcrYnPopup');
      },
      navigateTo() {
        this.$router.push(
          {path: "/sfli/OtsdQltyIndctRslt"})
          .catch(() => {
            console.error("Navigation Error");
          }
        );
      },
      selChgPrdctKnd() {
        if(this.param.prdctKndCd === '0001' || this.param.prdctKndCd === '0003') {
            this.param.svpsrt = '';
        }
      },
      
    },
  }
  </script>
  
  <style scoped>
  .board_write > table table td {
    text-align: center;
  }
  .board_write > table table th {
    text-align: center;
  }
  </style>