<template>
  <div v-show="isOn" class="modal visible">
    <div class="popup_box" style="width: 70%; max-width: inherit">
      <h1 class="popup_title">수입 거래처 관리</h1>
      <div>
        <button type="button" class="modal_close" @click.prevent="hide" >팝업닫기</button>
      </div>
      <div class="popup_cont">



        <div class="sch_form_wrap">
          <div class="sch_form row2">

            <span>
              <label for="">업체 명</label>
              <br-form-input class="form_control" label="업체명" v-model="searchParam.bzentyNm" @enterKeyup="search" />
            </span>
            <span>
              <label for="">국가</label>
              <br-form-select customClass="form_select w150" label="국가" groupCd="COM415" allSelectable v-model:value="searchParam.mnftrNtnCd" @enterKeyup="search" />
            </span>
          </div>
          <div class="btn_area">
            <br-anchor-button role="link" class="btn sm sch" label="조회" icon="icon_search" @click="search" />
            <br-anchor-button role="link" class="btn sm txtN ico_refresh" label="새로고침"  icon="resetSearch" @click="resetSearch" />            
          </div>
        </div>


        <div class="board_info">
          <div class="board_info">
            <h3 class="title2">수입 거래처 목록</h3>
          </div>
          <div>
            <br-button label="추가" class="btn sm primary line ico_plus" style="margin-right: 8px" @click="callPopupIncmFclt" />
            <br-button label="삭제" class="btn sm point ico_minus" @click="deleteOvrsIncmCnpt"/>
          </div>
        </div>
          <sb-grid
              id="ovrsIncmCnptMngPopupGrid"
              :dataSource="ovrsIncmCnptMngPopupDataSource"
              :columns="ovrsIncmCnptMngPopupGridColumns"
              :gridattr="ovrsIncmCnptMngPopupGridAttr"
              v-model:grid="ovrsIncmCnptMngPopupGridObject"
              :refreshGrid="ovrsIncmCnptMngPopupGridReload"
              @loaded="ovrsIncmCnptMngPopupGridLoaded"
              @row-clicked="onRowChangedIncmCnpt"
              @add="addedOvrsIncmCnptSetData"
              :param="searchParam"
          />
        <div class="board_info">
          <h3 class="title2">거래처 상세
            <span class="txt_hint"></span>
          </h3>
          <div>
            <br-button label="저장" format="primary" @click="saveOvrsIncmCnpt" panel="panel1" validate/>
          </div>
        </div>

          <div class="board_write">
            <table>
              <caption></caption>
              <colgroup>
                <col style="width:15%">
                <col style="width:35%">
                <col style="width:15%">
                <col style="width:35%">
              </colgroup>
              <tbody>
              <tr>
                <th scope="row">업체명</th>
                <td>
                  <br-form-input label="업체명" v-model="bizfaciltyIncmCnpt.bzentyNm" disabled/>
                </td>
                <th scope="row">국가</th>
                <td>
                  <br-form-select label="국가" groupCd="COM415" v-model:value="bizfaciltyIncmCnpt.mnftrNtnCd" v-model:text="bizfaciltyIncmCnpt.mnftrNtnNm" disabled/>
                </td>
              </tr>
              <tr>
                <th scope="row">시설 주소</th>
                <td colspan="3">
                  <br-form-input label="시설주소" v-model="bizfaciltyIncmCnpt.fcltAddr" disabled/>
                </td>
              </tr>
              <tr>
                <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>거래처 구분</th>
                <td>
                  <br-form-select panel="panel1" required label="거래처 구분" groupCd="COM448" v-model:value="bizfaciltyIncmCnpt.incmCnptSeCd" @change="isChangedVal('incmCnptSeCd')" />
                </td>
                <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>담당자 명</th>
                <td>
                  <br-form-input panel="panel1" required label="담당자명" v-model="bizfaciltyIncmCnpt.picNm" @input="isChangedVal('picNm')"/>
                </td>
              </tr>
              <tr>
                <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>이메일 주소</th>
                <td>
                  <br-form-input panel="panel1" validator="email" required label="이메일주소" v-model="bizfaciltyIncmCnpt.emlAddr" @input="isChangedVal('emlAddr')"/>
                </td>
                <th scope="row">전화번호</th>
                <td style="display: flex; gap: 5px">
                  <br-form-input style="flex: 1" label="국제전화" v-model="bizfaciltyIncmCnpt.intclNtnNo" @input="isChangedVal('intclNtnNo')"  :maxlength="6"/>
                  <br-form-input style="flex: 2" label="전화번호" v-model="bizfaciltyIncmCnpt.telno" @input="isChangedVal('telno')" :maxlength="12"/>
                </td>

              </tr>
              <tr>
                <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>제품종류</th>
                <td colspan="3">
                  <br-form-checkbox panel="panel1" required
                                    label="제품종류"
                                    :options="options"
                                    v-model:values="values"
                                    @input="isChangedVal('prdctKnd')"/>
                </td>
              </tr>
              <tr>
                <th scope="row">제품보관장소</th>
                <td>
                  <br-form-input label="제품보관장소" v-model="bizfaciltyIncmCnpt.prdctCstdyPlcCn" @input="isChangedVal('prdctCstdyPlcCn')"/>
                </td>
                <th scope="row">제품보관유형</th>
                <td>
                  <br-form-select label="제품보관유형" groupCd="COM406" v-model:value="bizfaciltyIncmCnpt.prdctCstdyTypeCd" @change="isChangedVal('prdctCstdyTypeCd')"/>
                </td>
              </tr>
              <tr>
                <th scope="row">시설공정</th>
                <td>
                  <br-form-input label="시설공정" v-model="bizfaciltyIncmCnpt.fcltProcsCn" @input="isChangedVal('fcltProcsCn')" />
                </td>
                <th scope="row">제조량</th>
                <td>
                  <br-form-input label="제조량" type="" v-model="bizfaciltyIncmCnpt.mnfqty" @input="isChangedVal('mnfqty')"/>
                </td>
              </tr>
              <tr>
                <th scope="row">수입량</th>
                <td>
                  <br-form-input label="수입량" v-model="bizfaciltyIncmCnpt.incmPldqty" @input="isChangedVal('incmPldqty')"/>
                </td>
                <th scope="row">사용여부</th>
                <td colspan="3">
                  <br-form-radio label="사용여부" v-model="bizfaciltyIncmCnpt.useYn" :options="useYnList" value-field="value" text-field="text"
                                  @change="isChangedVal('useYn')"/>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="btn_area center">
            <br-button label="선택" class="btn md tertiary" @click="onClickedSelectButton" />
            <br-button label="닫기" class="btn md tertiary" @click="hide" />
          </div>
        </div>

      </div>
  </div>
  <ovrs-incm-fclt-popup @select-button-clicked="selectedOvrsIncmFcltPopup" :bplcId="this.bplcId"></ovrs-incm-fclt-popup>
</template>

<script>
/**
 * 해외수입거래처관리
 *
 * 작성자 : lhs
 * 작성일 : 24. 10. 16.
 * 버전 : 1.0
 */
import { storeSwitch } from "@/js/store/store-switch";
import SbGrid from "@/components/SbGrid.vue";
import MbrFormTelno from "@/views/mbr-mng/components/MbrFormTelno.vue";
import OvrsIncmFcltPopup from "@/views/mbr-mng/components/popup/OvrsIncmFcltPopup.vue";
import {buildUri} from "@/js/utils/uri-builder";
import BrFormCheckbox from "@/components/BrFormCheckbox.vue";
import BrFormInput from "@/components/BrFormInput.vue";
import BrFormRadio from "@/components/BrFormRadio.vue";
import BrButton from "@/components/BrButton.vue";
import BrFormSelect from "@/components/BrFormSelect.vue";

const API = {
  COM_DTL_CD: "/code/detail",
  OVRS_INCM_CNPT: "/bizfacility/ovrs-incm-cnpt/{bplcId}",
  OVRS_INCM_CNPT_INFO: "/bizfacility/ovrs-incm-cnpt-info/{bplcId}/{incmFcltSn}",
};


const CODE = {
  MNFTR_NTN_CD: "COM415", // 국가코드
  PRDCT_CSTDY_TYPE_CD: "COM406", // 제품보관유형코드
}

export default {
  components: {BrFormSelect, BrButton, BrFormRadio, BrFormInput, BrFormCheckbox, OvrsIncmFcltPopup, MbrFormTelno, SbGrid},
  props: {
    bplcId: {type: String, required: true},
    userPopup: {type: Boolean, required: false},
  },
  emits: [ 'select-button-clicked' ],
  data() {
    return {
      values: [],
      isOn: false,
      bizfaciltyIncmCnpt: {
        bzentyNm : '',
        mnftrNtnCd : '',
        incmFcltSn: '',
        bzentyAddr : '',
        fcltAddr : '',
        incmCnptSeCd : '',
        picNm : '',
        telno : '',
        emlAddr : '',
        srfPtYn : '',
        srfNptYn : '',
        bioSrfPtYn : '',
        bioSrfNptYn : '',
        prdctCstdyPlcCn : '',
        prdctCstdyTypeCd : '',
        fcltProcsCn : '',
        mnfqty : '',
        incmPldqty : '',
        useYn : '',
        prdctKnd: '',
        intclNtnNo : '',
      },
      mnftrNtnCdList: [],
      options: [
        {value: 'srfPtYn', text: 'SRF 성형'},
        {value: 'srfNptYn', text: 'SRF 비성형'},
        {value: 'bioSrfPtYn', text: 'BIO-SRF 성형'},
        {value: 'bioSrfNptYn', text: 'BIO-SRF 비성형'},
      ],
      useYnList: [
        {value: "Y", text: "사용"},
        {value: "N", text: "미사용"},
      ],
      ovrsIncmCnptMngPopupDataSource: {
        ajaxConfig: {
          select: { url: this.incmCnptBuildUriForGrid('OVRS_INCM_CNPT') },
          paging: true,
        },
        schema: {
          id: 'incmFcltSn'
        }
      },
      ovrsIncmCnptMngPopupGridColumns: [],
      ovrsIncmCnptMngPopupGridAttr: {
        checkable: false,
        showStatus:true,
        showRowNo: false,
        pageable: true,
        editable: true,
        height: 350,
        showCheck:true,
        excelExport: {
          fileName: '수입 거래처 목록.xlsx',
          includeHeader: true,
          includeFormula: true,
        },
      },
      ovrsIncmCnptMngPopupGridObject: null,
      ovrsIncmCnptMngPopupGridReload: false,
      rowData: [],
      searchParam: {
        bzentyNm: '',
        mnftrNtnCd: '',
      },
    };
  },
  computed: {
  },
  watch: {
    isOn(newVal) {
      if (!newVal) {
        this.reset();
      } else {
        this.ovrsIncmCnptMngPopupDataSource.ajaxConfig.select.url = buildUri(API.OVRS_INCM_CNPT, {bplcId : this.bplcId});
        window.SBGrid3.reload(this.ovrsIncmCnptMngPopupGridObject);
      }
    },
  },
  created() {
    storeSwitch.setBulb('ovrsIncmCnptMngPopup', this);
  },
  beforeMount() {

  },
  mounted() {
    this.initialize();
  },
  beforeUnmount() {},
  methods: {
    /** 팝업 Show */
    show() {
      this.reset();
      this.isOn = true;
    },
    /** 팝업 Hide */
    hide() {
      this.isOn = false;
    },
    /** 화면 초기화 */
    async initialize() {
      // 공통코드 초기화
      this.mnftrNtnCdList = this.$store.getters.getCodeList({ groupCd: CODE.MNFTR_NTN_CD });   // 국가코드
      this.prdctCstdyTypeCdList = this.$store.getters.getCodeList({ groupCd: CODE.PRDCT_CSTDY_TYPE_CD });   // 보관유형코드

      // 그리드 설정
      this.initializeOvrsIncmCnptMngPopupGridColumns();
      this.ovrsIncmCnptMngPopupGridReload = true;

      // this.ovrsIncmCnptInfo();

    },
    /** 그리드 컬럼 초기화 */
    initializeOvrsIncmCnptMngPopupGridColumns() {
      this.ovrsIncmCnptMngPopupGridColumns = [
        { field: 'bplcId', caption: '사업장아이디', part: '', width: 0, type: 'text', editable: false, visible: false, align: 'center' },
        { field: 'incmFcltSn', caption: '수입시설일련번호', part: '', width: 0, editable: false, visible: false, align: 'center'},
        { field: 'bzentyNm', caption: '업체명', part: '', width: 250, type: 'text', editable: false, visible: true, align: 'left',
          colCss : (data) => {
            const rowKey = window.SBGrid3.getCheckedKeys(this.ovrsIncmCnptMngPopupGridObject)
            if(rowKey){
              if (rowKey.includes(data.incmFcltSn)) {
                return 'grid-warning';
              } else {
                return '';
              }
            }
          }
        },
        { field: 'mnftrNtnCd', caption: '제조국가', part: '', width: 100, type: 'combo', items: this.mnftrNtnCdList, comboLabel: 'dtlCdNm', comboValue: 'dtlCd', editable: false, visible: true, align: 'center',
          colCss : (data) => {
            const rowKey = window.SBGrid3.getCheckedKeys(this.ovrsIncmCnptMngPopupGridObject)
            if(rowKey){
              if (rowKey.includes(data.incmFcltSn)) {
                return 'grid-warning';
              } else {
                return '';
              }
            }
          }
        },
        { field: 'fcltAddr', caption: '시설주소', part: '', width: 250, type: 'text', editable: false, visible: true, align: 'left',
          colCss : (data) => {
            const rowKey = window.SBGrid3.getCheckedKeys(this.ovrsIncmCnptMngPopupGridObject)
            if(rowKey){
              if (rowKey.includes(data.incmFcltSn)) {
                return 'grid-warning';
              } else {
                return '';
              }
            }
          }
        },
        { field: 'prdctKnd', caption: '제품종류', part: '', width: 200, type: 'text', editable: false, visible: true, align: 'left',
          colCss : (data) => {
            const rowKey = window.SBGrid3.getCheckedKeys(this.ovrsIncmCnptMngPopupGridObject)
            if(rowKey){
              if (rowKey.includes(data.incmFcltSn)) {
                return 'grid-warning';
              } else {
                return '';
              }
            }
          }
        },
        { field: 'prdctCstdyPlcCn', caption: '제품보관장소내용', part: '', width: 150, type: 'text', editable: false, visible: true, align: 'left',
          colCss : (data) => {
            const rowKey = window.SBGrid3.getCheckedKeys(this.ovrsIncmCnptMngPopupGridObject)
            if(rowKey){
              if (rowKey.includes(data.incmFcltSn)) {
                return 'grid-warning';
              } else {
                return '';
              }
            }
          }
        },
        { field: 'prdctCstdyTypeCd', caption: '제품보관유형', part: '', width: 100, type: 'combo', items: this.prdctCstdyTypeCdList, comboLabel: 'dtlCdNm', comboValue: 'dtlCd', editable: false, visible: true, align: 'center',
          colCss : (data) => {
            const rowKey = window.SBGrid3.getCheckedKeys(this.ovrsIncmCnptMngPopupGridObject)
            if(rowKey){
              if (rowKey.includes(data.incmFcltSn)) {
                return 'grid-warning';
              } else {
                return '';
              }
            }
          }
        },
        { field: 'mnfqty', caption: '제조량', part: '', width: 80, type: 'number', editable: true, visible: true, align: 'right', format: '#,###',
          colCss : (data) => {
            const rowKey = window.SBGrid3.getCheckedKeys(this.ovrsIncmCnptMngPopupGridObject)
            if(rowKey){
              if (rowKey.includes(data.incmFcltSn)) {
                return 'grid-warning';
              } else {
                return '';
              }
            }
          }
        },
        { field: 'incmPldqty', caption: '수입예정량', part: '', width: 100, type: 'number', editable: true, visible: true, align: 'right', format: '#,###',
          colCss : (data) => {
            const rowKey = window.SBGrid3.getCheckedKeys(this.ovrsIncmCnptMngPopupGridObject)
            if(rowKey){
              if (rowKey.includes(data.incmFcltSn)) {
                return 'grid-warning';
              } else {
                return '';
              }
            }
          }
        },
        /* 거래처정보를 저장하기 위해 상세정보 값을 저장할 필드설정*/
        { field: 'incmCnptSeCd', caption: '수입거래처구분', part: '', width: 100, type: 'text', editable: false, visible: false, align: 'right' },
        { field: 'picNm', caption: '담당자명', part: '', width: 100, type: 'text', editable: false, visible: false, align: 'right' },
        { field: 'intclNtnNo', caption: '국제전화국가번호', part: '', width: 100, type: 'text', editable: false, visible: false, align: 'right' },
        { field: 'telno', caption: '전화번호', part: '', width: 100, type: 'text', editable: false, visible: false, align: 'right' },
        { field: 'emlAddr', caption: '이메일주소', part: '', width: 100, type: 'text', editable: false, visible: false, align: 'right' },
        { field: 'srfPtYn', caption: 'SRF성형여부', part: '', width: 100, type: 'text', editable: false, visible: false, align: 'right' },
        { field: 'srfNptYn', caption: 'SRF비성형여부', part: '', width: 100, type: 'text', editable: false, visible: false, align: 'right' },
        { field: 'bioSrfPtYn', caption: 'BIOSRF성형여부', part: '', width: 100, type: 'text', editable: false, visible: false, align: 'right' },
        { field: 'bioSrfNptYn', caption: 'BIOSRF비성형여부', part: '', width: 100, type: 'text', editable: false, visible: false, align: 'right' },
      ]
    },
    /** 그리드 데이터로드 완료 이벤트 */
    ovrsIncmCnptMngPopupGridLoaded() {
      this.ovrsIncmCnptMngPopupGridReload = false;
    },
    incmCnptBuildUriForGrid(type){
      let incmCnptInfoUrl = '';
      if(type){
        incmCnptInfoUrl = buildUri(API.OVRS_INCM_CNPT, {bplcId : this.bplcId});
      }
      return incmCnptInfoUrl;
    },
    /** 수입 거래처 정보 선택 */
    onRowChangedIncmCnpt(data) {
      this.incmFcltSn = data.incmFcltSn;
      this.bizfaciltyIncmCnpt = {...data};

      const srfData ={
        srfPtYn : this.bizfaciltyIncmCnpt.srfPtYn,
        srfNptYn : this.bizfaciltyIncmCnpt.srfNptYn,
        bioSrfPtYn : this.bizfaciltyIncmCnpt.bioSrfPtYn,
        bioSrfNptYn : this.bizfaciltyIncmCnpt.bioSrfNptYn,
      }

      this.values = Object.keys(srfData).filter(key => srfData[key] === 'Y');
    },
    /** 해외수입시설 팝업 호출 */
    callPopupIncmFclt(){
      storeSwitch.on('ovrsIncmFcltPopup');
      this.$emit('ovrs-incm-fclt-popup', this.bplcId)
    },
    /** 선택 버튼 클릭 */
    onClickedSelectButton() {
      if(this.bizfaciltyIncmCnpt.incmFcltSn) {
        this.$emit('select-button-clicked', this.bizfaciltyIncmCnpt);
        this.isOn = false;
        this.reset();
      } else {
        alert('선택된 시설이 없습니다.')
      }
    },
    /** 해외수입시설 팝업 선택 이벤트 */
    selectedOvrsIncmFcltPopup(selectedIncmFclt){
      this.addOvrsIncmFclt(selectedIncmFclt);
    },

    /** 해외 거래처 목록에 수입시설 추가 */
    addOvrsIncmFclt(selectedIncmFclt){
      this.bizfaciltyIncmCnpt = {...selectedIncmFclt};

      const inputRowData = {
        bplcId: this.bplcId
        , incmFcltSn: selectedIncmFclt.incmFcltSn
        , bzentyNm: selectedIncmFclt.bzentyNm
        , mnftrNtnCd: selectedIncmFclt.mnftrNtnCd
        , bzentyAddr: selectedIncmFclt.bzentyAddr
        , fcltAddr: selectedIncmFclt.fcltAddr
        , useYn: 'Y'
      }

      const key = window.SBGrid3.getFocusedKey(this.ovrsIncmCnptMngPopupGridObject);
      if (key) {
        window.SBGrid3.appendRow(this.ovrsIncmCnptMngPopupGridObject, key, inputRowData);
      } else {
        window.SBGrid3.insertRow(this.ovrsIncmCnptMngPopupGridObject, null, inputRowData);
      }
    },
    addedOvrsIncmCnptSetData(rowData){

      this.bizfaciltyIncmCnpt = {...rowData};

      this.bizfaciltyIncmCnpt.useYn = 'Y';

    },
    deleteOvrsIncmCnpt() {
      const checkedOvrsIncmCnptItem = window.SBGrid3.getCheckedRows(this.ovrsIncmCnptMngPopupGridObject);

      if (checkedOvrsIncmCnptItem.length === 0) {
        alert("선택된 업체가 없습니다.");
        return;
      }

      const confirmMessage = `선택된 ${checkedOvrsIncmCnptItem.length}개의 업체를 정말 삭제하시겠습니까?`;

      if(confirm(confirmMessage)){
        const deleted = checkedOvrsIncmCnptItem.map(item => item.data.incmFcltSn);
        const ovrsIncmCnptDeleteUrl = buildUri(API.OVRS_INCM_CNPT, {bplcId: this.bplcId});
        this.$apiCall.delete(
            ovrsIncmCnptDeleteUrl,
            {list : deleted},
            () => {
              alert("삭제되었습니다.")
              window.SBGrid3.clearSaveData(this.ovrsIncmCnptMngPopupGridObject);
              window.SBGrid3.reload(this.ovrsIncmCnptMngPopupGridObject);
              this.reset();
            },
            () => {
              alert("삭제에 실패하였습니다.")
            }
        );
      }
    },
    saveOvrsIncmCnpt() {
      const { inserted, updated } = window.SBGrid3.getSaveData(this.ovrsIncmCnptMngPopupGridObject, true, true, false);

      const list = [...inserted, ...updated];
      if (list.length === 0) {
        alert("추가되거나 수정된 항목이 없습니다.");
        return;
      }
      const ovrsIncmCnptSaveUrl = buildUri(API.OVRS_INCM_CNPT, {bplcId: this.bplcId});

      if(confirm("변경 사항을 저장하시겠습니까?")){
        this.$apiCall.post(
            ovrsIncmCnptSaveUrl,
            {inserted, updated},
            () => {
              alert("저장되었습니다.")
              window.SBGrid3.clearSaveData(this.ovrsIncmCnptMngPopupGridObject);
              window.SBGrid3.reload(this.ovrsIncmCnptMngPopupGridObject);
              this.reset();
            },
            () => {
              alert("저장에 실패하였습니다.")
            }
        );
      }
    },
    reset(){
      Object.keys(this.bizfaciltyIncmCnpt).forEach(key => {
        this.bizfaciltyIncmCnpt[key] = '';
        this.values = [];
      });
    },
    isChangedVal(field){
      const focusRow = window.SBGrid3.getFocusedRow(this.ovrsIncmCnptMngPopupGridObject);
      const focusRowKey = focusRow.data._key_;

      // 전화번호의 값은 숫자만 가능하도록
      if(field === 'telno'){
        if(focusRow.data.telno){
          const currentTelno = this.bizfaciltyIncmCnpt.telno || '';
          this.bizfaciltyIncmCnpt.telno = currentTelno.replace(/[^0-9]/g, '');
        }
      }

      /* 상품종류 ( SRF 성형, SRF 비성형, BIO-SRF 성형, BIO-SRF 비성형 여부 Y,N 입력 */
      if (field === 'prdctKnd') {
        const prdcKndMap = {
          srfPtYn: 'SRF 성형',
          srfNptYn: 'SRF 비성형',
          bioSrfPtYn: 'BIO-SRF 성형',
          bioSrfNptYn: 'BIO-SRF 비성형'
        };

        const prdcKndList = Object.entries(prdcKndMap)
            .filter(([key]) => this.values.includes(key))
            .map(([, value]) => value);

        Object.keys(prdcKndMap).forEach((key) => {
          const isActive = prdcKndList.includes(prdcKndMap[key]) ? 'Y' : 'N';
          window.SBGrid3.setValue(this.ovrsIncmCnptMngPopupGridObject, focusRowKey, isActive, key);
        });

        this.bizfaciltyIncmCnpt.prdctKnd = prdcKndList.join(', ');
      }

      // 상세정보 입력데이터 -> 수입거래처목록 그리드 저장
      window.SBGrid3.setValue(this.ovrsIncmCnptMngPopupGridObject, focusRowKey, this.bizfaciltyIncmCnpt[field], field);
      // setValue로는 그리드가 행이 수정되었다는 걸 인식 못함. -> status 및 isEdit 속성 강제 변환
      focusRow.data._status_ = 'U';
      focusRow.isEdit = true;

    },
     /** 데이터 조회 */
     search() {
      window.SBGrid3.reload(this.ovrsIncmCnptMngPopupGridObject);
    },
    resetSearch() {
      for (const key of Object.keys(this.searchParam)) {
        this.searchParam[key] = '';
      }
      this.rowData = [];

      this.ovrsIncmCnptMngPopupDataSource.ajaxConfig.select.url = buildUri(API.OVRS_INCM_CNPT, {bplcId : this.bplcId});
      window.SBGrid3.reload(this.ovrsIncmCnptMngPopupGridObject);
    },
  }
}
</script>

<style scoped>

</style>