<template>
  <div class="error_page">
    <img src="@/assets/images/sub/img_error02.png" alt="이미지">
    <div class="error_title">죄송합니다.<br>요청하신 <span class="txt_primary">페이지를 찾을 수 없습니다.</span></div>
    <div class="error_cont">
      해당 페이지의 권한이 없거나<br>
      페이지의 주소가 변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다.<br>
      입력하신 주소가 정확한지 다시 한번 확인해 주시기 바랍니다.<br>
      동일한 문제가 지속적으로 발생하는 경우는 고객센터(1588-6446)로 문의해주세요.
    </div>
    <div class="btn_area">
      <button type="button" class="btn primary" @click="goHome">메인으로</button>
      <button type="button" class="btn primary line" @click="goBack">이전페이지</button>
    </div>
  </div>
</template>

<script>
const ROUTE = {
  HOME: "/"
}

export default {
  methods: {
    goHome() {
      this.$router.push(ROUTE.HOME);
    },
    goBack() {
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>

</style>