<template>
    <div v-show="isOn" class="modal visible">
      <div class="popup_box" style="width: 70%;">
        <h1 class="popup_title">아이디 중복 확인</h1>
        <div class="popup_cont" style="height: 100%;">
          <div class="board_write">
            <table>
                <caption></caption>
                <colgroup>
                    <col style="width:30%">
                    <col style="width:70%">
                </colgroup>
                <tbody>
                <tr>
                    <th scope="row">아이디</th>
                    <td>
                        <br-form-input label="아이디" v-model="param.userId" @input="validateUserId" panel="panel" required/>
                    </td>
                </tr>
                </tbody>
            </table>
          </div>
          <div class="btn_area center">
            <br-button label="확인" class="btn md primary" @click="onClickedSelectButton" />
            <br-button label="취소" class="btn md" @click="hide" />
          </div>
        </div>
        
        <button type="button" class="modal_close" @click.prevent="hide" >팝업닫기</button>
      </div>
    </div>
  </template>
  
  <script>
  import { storeSwitch } from "@/js/store/store-switch";
  
  const API = {
    Id_CHECK: "/join/idCheck",
  };
  
  
  export default {
    components: {},
    props: [],
    emits: [ 'select-button-clicked' ],
    data() {
      return {
        isOn: false,
        param: {
          userId: '',

        },
      };
    },
    computed: {},
    watch: {
    },
    created() {
      storeSwitch.setBulb('idDpctChkPopup', this);
    },
    beforeMount() {
  
    },
    mounted() {
      this.initialize();
    },
    beforeUnmount() {},
    methods: {
      /** 팝업 Show */
      show() {
        this.isOn = true;
      },
      /** 팝업 Hide */
      hide() {
        this.isOn = false;
      },
      /** 화면 초기화 */
      async initialize() {
        // 시도 select 초기화
        
      },
      validateUserId() {
        // 영어와 숫자만 허용
        this.param.userId = this.param.userId.replace(/[^a-zA-Z0-9]/g, '');
      },
      /** 확인 버튼 클릭 */
      onClickedSelectButton() {
        // 유효성 체크
        const userIdRegex = /^[A-Za-z]{1}[A-Za-z0-9]{5,20}$/;
        if(this.param.userId === null || this.param.userId === '') {
            alert("아이디를 입력해주십시오.");
            return;
        } else if(!userIdRegex.test(this.param.userId)) {
            alert("첫 글자는 영문이여야 하며 6~20자의 영문 대/소문자,숫자를 혼합해서 사용하실 수 있습니다.");
            return;
        } else {
            return new Promise((resolve) => {
            this.$apiCall.get(API.Id_CHECK, this.param, data => {
                if (data.result == false) {
                    alert("사용 가능한 아이디입니다.");
                    resolve();
                    this.$emit('select-button-clicked', this.param.userId);
                    this.hide(); // 팝업 닫기
                } else {
                    alert("이미 존재하는 아이디입니다.");
                    return;
                }
            });
            });

        }
      },
    }
  }
  </script>
  
  <style scoped>
  
  </style>