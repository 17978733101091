<template>
    <div class="member_wrap">
      <div class="tab_area">
        <div class="tab_conts_wrap">
            <div class="user_info ico_pw"><span>{{ this.param.userNm }}님(<strong class="txt_primary">{{ storeFindAccountInfo.userId }}</strong>) 비밀번호를 변경해주세요.</span></div>
            <div class="member_box">
                <div class="form_group">
                <div class="input_group">
                    <label for="">새 비밀번호</label>
                    <br-form-input type="password" label="새 비밀번호" v-model="param.encptPswd" required panel="panel" />
                </div>
                <div class="input_group">
                    <label for="">새 비밀번호 확인</label>
                    <br-form-input type="password" label="새 비밀번호 확인" v-model="encptPswdCh" required panel="panel" />
                </div>
                </div>
            </div>
            <ul class="sch_pw_info">
                <li><span>※</span> 비밀번호는 대문자, 소문자, 숫자, 특수문자를 포함한 9자 이상이어야 합니다.</li>
                <li><span>※</span> 안전한 계정 사용을 위해 비밀번호는 주기적으로 변경해 주세요.</li>
            </ul>
            <div class="btn_area center">
                <br-button label="비밀번호 변경" class="btn md primary" @click="pswdChg" />
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>

  import storeAccount from "@/js/store/store-account";

  const API = {
    LOGIN: "/user/login",
    PSWD: "/user/pswdChg",
    PSWD_CRITERIA : "/join/pswdCriteria",
    USER_INFO : "/user/pswdInfo",
  }
  
  export default {
    data() {
      return {
        param : {
            encptPswd: "",  // 비밀번호
            pswdChgDmndYmd: "", // 비밀번호 변경 요청 일자
            userNm: "",
            userId: "",
        },
        encptPswdCh: "",    // 비밀번호 확인
        pswdCriteria: '',   // 비밀번호 변경 알람 개월수
      };
    },
    computed: {
      storeFindAccountInfo() {
        return storeAccount.getters.getFindAccountInfo;
      },
    },
    watch: {},
    created() {
      this.param.userId = this.$route.query.userId;
    },
    mounted() {
      this.read();
      this.initialize();
    },
    methods: {
      async initialize() {
        await this.loadPswdCriteria();
      },
      login() {
        this.navigateTo(API.LOGIN);
      },
      navigateTo(path) {
        if (path) {
          this.$router.push(path)
              .catch(() => {
                console.error("Navigation Error");
              });
        }
      },
      loadPswdCriteria() {
        return new Promise((resolve, reject) => {
          this.$apiCall.get(API.PSWD_CRITERIA, {}, data => {
          if (data.result) {
            this.pswdCriteria = data.result.mainRfrncVl

            const currentDate = new Date()

            // 현재 날짜에서 기준 날짜를 설정
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth();
            const day = currentDate.getDate();

            // 새로운 월과 연도를 계산
            const newDate = new Date(year, month + Number(this.pswdCriteria), day);

            // YYYYMMDD 형식으로 변환
            const newYear = newDate.getFullYear();
            const newMonth = String(newDate.getMonth() + 1).padStart(2, '0');
            const newDay = String(newDate.getDate()).padStart(2, '0');

            this.param.pswdChgDmndYmd = `${newYear}${newMonth}${newDay}`;

            resolve();
          } else {
            reject(new Error("Failed to load pswd criteria."));
          }
          });
        });
      },
      read(){
        this.$apiCall.get(API.USER_INFO, {userId : storeAccount.state.findAccountInfo.userId}, this.success, this.error);
      },
      success(data) { 
        if (!data.result) {
          this.error();
          return;
        }

        this.param = data.result;
      },
      error() {
        alert("데이터를 가져오는 중 오류가 발생했습니다.");
      },
      pswdChg() {
        const password = this.param.encptPswd;
        const passwordConfirm = this.encptPswdCh;
        const passwordPattern = /^(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{9,}$/;
 
        if (!password || !passwordPattern.test(password)) {
          alert('비밀번호는 대/소문자, 숫자, 특수문자를 포함한 9자 이상이어야 합니다.');
          return;
        }

        if (password !== passwordConfirm) {
          alert('비밀번호가 일치하지 않습니다.');
          return;
        }

        this.$apiCall.put(
            API.PSWD,
            this.param,
            () => {
              alert("비밀번호가 변경되었습니다. 로그인 화면으로 이동합니다.");
              this.login();
              window.scrollTo({ top: 0 });
            },
            () => {
              alert("비밀번호 변경에 실패하였습니다.");
              return;
            }
        );
      },
    }
  }
  </script>
  
  <style scoped>
  
  </style>