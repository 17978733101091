<template>
  <div class="sch_form_wrap">
    <div class="sch_form row2">
      <span>
        <label for="bplcKndCd">사업장종류</label>
        <br-form-select id="bplcKndCd" label="사업장종류" v-model:value="searchParam.bplcKndCd" :options="bplcKndList" @change="search" />
      </span>
      <span>
        <label for="bplcId">사업장명</label>
        <br-form-select id="bplcId" label="사업장명" v-model:value="searchParam.bplcId" :options="filteredBplcList" @change="search" />
      </span>
      <span>
        <label for="ctpvStdgCd">시·도</label>
        <br-form-select id="ctpvStdgCd" label="시·도" v-model:value="searchParam.ctpvStdgCd" :options="ctpvList" allSelectable @change="search" />
      </span>
      <span>
        <label for="sggStdgCd">시·군·구</label>
        <br-form-select id="sggStdgCd" label="시·군·구" v-model:value="searchParam.sggStdgCd" :options="sggList" allSelectable @change="search" />
      </span>
      <span>
        <label for="useYn">사용여부</label>
        <br-form-select id="useYn" label="사용여부" groupCd="COM440" v-model:value="searchParam.useYn" allSelectable @change="search" />
      </span>
    </div>
    <div class="btn_area">
      <br-anchor-button role="link" label="검색" size="sm" format="sch" @click="search" />
      <br-anchor-button role="link" label="새로고침" size="sm" format="txtN" icon="ico_refresh" @click="reset" />
    </div>
  </div>
  <div class="txt_box mt_10">
    <ul class="dot_list">
      <li v-if="searchParam.bplcKndCd !== '01-03'">업체를 추가하려는 경우, 업체검색 버튼을 클릭해주세요.</li>
      <li v-if="searchParam.bplcKndCd === '01-03'">수입업체인 경우 현황만 조회가 가능합니다.</li>
    </ul>
  </div>

  <component v-if="currentBplcComponent" :is="currentBplcComponent" :param="searchParam" ref="dynamicBplcComponent" />

</template>

<script>
import MnfrtBplc from "@/views/prfmnc-rpt/components/MnfrtBplc.vue";
import UseBplc from "@/views/prfmnc-rpt/components/UseBplc.vue";
import IncmBplc from "@/views/prfmnc-rpt/components/IncmBplc.vue";
import EtcBplc from "@/views/prfmnc-rpt/components/EtcBplc.vue";

const API = {
  MEMBER_BPLC : "/member/bplc-list"
}

export default {
  components: {EtcBplc, IncmBplc, UseBplc, MnfrtBplc},
  data() {
    return {
      searchParam: {
        bplcId: "",
        bplcKndCd: "",
        ctpvStdgCd : "",
        sggStdgCd: "",
        useYn: "",
      },
      bplcList: [],
      bplcKndList: [],
    };
  },
  computed: {
    filteredBplcList() {
      return this.bplcList.filter(item => item.bplcKndCd === this.searchParam.bplcKndCd);
    },
    ctpvList() {
      const ctpvList = this.$store.getters.getSggList({ type: 'ctpv' });
      return ctpvList.map(sgg => ({ text: sgg.sggNm, value: sgg.sggStdgCd }));
    },
    sggList() {
      if (this.searchParam.ctpvStdgCd) {
        const sggList = this.$store.getters.getSggList({ upSggStdgCd: this.searchParam.ctpvStdgCd });
        return sggList.map(sgg => ({ text: sgg.sggNm, value: sgg.sggStdgCd }));
      }
      return [];
    },
    currentBplcComponent() {
      if (!this.searchParam.bplcKndCd) return null;

      switch (this.searchParam.bplcKndCd) {
        case "01-01":
          return "MnfrtBplc";
        case "01-02":
          return "UseBplc";
        case "01-03":
          return "IncmBplc";
        default:
          return "EtcBplc";
      }
    }
  },
  watch: {
    filteredBplcList(newList) {
      if (newList.length > 0) {
        this.searchParam.bplcId = newList[0].value;
      } else {
        this.searchParam.bplcId = "";
      }
    }
  },
  created() {
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.loadMemberBplc();
    },
    loadMemberBplc() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.MEMBER_BPLC, {}, data => {
          if (data.result) {
            this.bplcList = data.result.bplcList.map(item => ({
              text: item.bplcNm,
              value: item.bplcId,
              bplcKndCd: item.bplcKndCd,
            }));
            this.bplcKndList = data.result.bplcKndList.map(item => ({
              text: item.bplcKndNm,
              value: item.bplcKndCd,
            }));

            if (this.bplcKndList.length > 0) {
              this.searchParam.bplcKndCd = this.bplcKndList[0].value;
            } else {
              this.searchParam.bplcKndCd = "";
            }

            resolve();
          } else {
            reject(new Error("Failed to load member bplc."));
          }
        });
      });
    },
    reset() {
      if (this.bplcKndList.length > 0) {
        this.searchParam.bplcKndCd = this.bplcKndList[0].value;
      } else {
        this.searchParam.bplcKndCd = "";
      }
      this.searchParam.ctpvStdgCd = "";
      this.searchParam.sggStdgCd = "";
      this.searchParam.useYn = "";
    },
    search() {
      if (this.$refs.dynamicBplcComponent && this.$refs.dynamicBplcComponent.search) {
        this.$refs.dynamicBplcComponent.search();
      } else {
        console.log("search 메서드를 찾을 수 없습니다.");
      }
    }
  }
}
</script>

<style scoped>
.form_select {
  max-width: none;
}
</style>