<template>
  <div class="sch_form_wrap">
    <div class="sch_form row2">
      <span>
        <label>실적연도</label>
        <br-form-select label="실적연도" v-model:value="searchParam.prfmncYr" :options="prfmncYrList" @change="search" />
      </span>
      <span>
        <label>사업장</label>
        <br-form-select label="사업장" v-model:value="searchParam.bplcId" :options="bplcList" @change="search" />
      </span>
      <span>
        <label>사업장종류</label>
        <br-form-select label="사업장종류" v-model:value="searchParam.bplcKndCd" :options="bplcKndList" disabled />
      </span>
      <div class="btn_area">
        <br-anchor-button role="link" label="검색" size="sm" format="sch" @click="search" />
        <br-anchor-button role="link" label="새로고침" size="sm" format="txtN" icon="ico_refresh" @click="reset" />
      </div>
    </div>
  </div>

  <component v-if="currentBplcComponent" :is="currentBplcComponent" :param="searchParam" ref="dynamicBplcKndComponent" />

</template>

<script>
import SdflMnfrtStts from "@/views/prfmnc-rpt/stts/SdflMnfrtStts.vue";
import SdflUseStts from "@/views/prfmnc-rpt/stts/SdflUseStts.vue";
import SdflIncmStts from "@/views/prfmnc-rpt/stts/SdflIncmStts.vue";
import FillGasStts from "@/views/prfmnc-rpt/stts/FillGasStts.vue";
import SynthGasStts from "@/views/prfmnc-rpt/stts/SynthGasStts.vue";
import FireRtrvlStts from "@/views/prfmnc-rpt/stts/FireRtrvlStts.vue";
import By2021BioGasStts from "@/views/prfmnc-rpt/stts/By2021BioGasStts.vue";
import BioGasStts from "@/views/prfmnc-rpt/stts/BioGasStts.vue";

const API = {
  MEMBER_BPLC : "/member/bplc-list"
}

export default {
  components: {SdflMnfrtStts, SdflUseStts, SdflIncmStts, FillGasStts, SynthGasStts, FireRtrvlStts, By2021BioGasStts, BioGasStts},
  data() {
    return {
      searchParam: {
        prfmncYr: "",
        bplcId: "",
        bplcKndCd: "",
      },
      prfmncYrList: [],
      bplcList: [],
      bplcKndList: [],
    };
  },
  computed: {
    currentBplcComponent() {
      if (!this.searchParam.bplcKndCd) return null;
      if (!this.searchParam.prfmncYr) return null;

      const year = parseInt(this.searchParam.prfmncYr, 10);

      switch (this.searchParam.bplcKndCd) {
        case "01-01": // 고형연료제조
          return "SdflMnfrtStts";
        case "01-02": // 고형연료사용
          return "SdflUseStts";
        case "01-03": // 고형연료수입
          return "SdflIncmStts";
        case "02-01": // 매립가스
          return "FillGasStts";
        case "05-01": // 폐기물합성가스
          return "SynthGasStts";
        case "03-01": // 소각
        case "03-02": // 소각열회수
          return "FireRtrvlStts";
        case "04-01": // 바이오가스
          return year <= 2021 ? "By2021BioGasStts" : "BioGasStts";
        default:
          return null;
      }
    }
  },
  watch: {
    'searchParam.bplcId'(newBplcId) {
      const selectedBplc = this.bplcList.find(item => item.value === newBplcId);
      if (selectedBplc) {
        this.searchParam.bplcKndCd = selectedBplc.bplcKndCd;
      } else {
        this.searchParam.bplcKndCd = "";
      }
    }
  },
  created() {
    this.initialize();
  },
  mounted() {
  },
  methods: {
    async initialize() {
      this.initYearOptions();
      await this.loadMemberBplc();
      const initialBplc = this.bplcList.find(item => item.value === this.searchParam.bplcId);
      this.searchParam.bplcKndCd = initialBplc ? initialBplc.bplcKndCd : "";
    },
    initYearOptions() {
      const today = new Date();
      today.setMonth(today.getMonth() - 1); // 현재 날짜에서 -1개월로 설정
      const currentYear = today.getFullYear();

      this.prfmncYrList = Array.from({ length: currentYear - 2012 }, (_, i) => ({
        text: `${currentYear - i}년`,
        value: (currentYear - i).toString(),
      }));

      this.searchParam.prfmncYr = currentYear.toString();
    },
    loadMemberBplc() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.MEMBER_BPLC, {}, data => {
          if (data.result) {
            this.bplcList = data.result.bplcList.map(item => ({
              text: item.bplcNm,
              value: item.bplcId,
              bplcKndCd: item.bplcKndCd,
            }));
            this.bplcKndList = data.result.bplcKndList.map(item => ({
              text: item.bplcKndNm,
              value: item.bplcKndCd,
            }));

            if (this.bplcList.length > 0) {
              this.searchParam.bplcId = this.bplcList[0].value;
            } else {
              this.searchParam.bplcId = "";
            }

            resolve();
          } else {
            reject(new Error("Failed to load member bplc."));
          }
        });
      });
    },
    reset() {
      if (this.bplcList.length > 0) {
        this.searchParam.bplcId = this.bplcList[0].value;
      }
      this.searchParam.prfmncYr = new Date().getFullYear().toString();
      this.search();
    },
    search() {
      if (this.$refs.dynamicBplcKndComponent && this.$refs.dynamicBplcKndComponent.search) {
        this.$refs.dynamicBplcKndComponent.search();
      } else {
        console.log("search 메서드를 찾을 수 없습니다.");
      }
    }
  }
}
</script>

<style scoped>
.form_select {
  max-width: none;
}
</style>