<template>
  <div class="tab_conts_wrap">
    <section id="dep1Tabpanel03" class="tab_conts active">
      <!-- 업체 승인 이후 -->
      <template v-if="bzentyAprvYn === 'Y'">
        <template v-if="myMngFcltList.length > 0">
          <!-- 나의 관리 사업장 및 시설 -->
          <div class="board_info mt_40">
            <div style="flex: auto;">
              <h3 class="title2">나의 관리 사업장 및 시설</h3>
            </div>
          </div>
          <div class="board_info" style="margin-bottom: 0px;">
            <p class="page" >
              <span class="total">전체 <strong>{{ this.myMngFcltCount }}</strong></span>
              <span>페이지 <strong>{{ this.searchParam.page }}/{{ this.maxPage === 0 ? 1 : this.maxPage }}</strong></span>
            </p>
          </div>
          <div class="board_list">
            <table style="table-layout: fixed;">
              <caption>나의 관리 사업장 및 시설</caption>
              <colgroup>
                <col style="width:40%">
                <col style="width:20%">
                <col style="width:25%">
                <col style="width:15%">
              </colgroup>
              <thead>
              <tr>
                <th scope="col">사업장명</th>
                <th scope="col">사업장종류</th>
                <th scope="col">시설명</th>
                <th scope="col">시설상태</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="myMngFcltList.length <= 0">
                <td colspan="4">
                  <div style="display: flex; justify-content: center;">
                    <div class="no-data-message">조회조건에 해당하는 데이터가 없습니다.</div>
                  </div>
                </td>
              </tr>
              <tr v-else v-for="(item, index) in myMngFcltList" :key="index"
                  :class="{ selected: selectedRowIndex === index }" @click="selectedRow(item, index)">
                <td aria-label="사업장명">{{ item.bplcNm }}</td>
                <td aria-label="사업장종류" style="text-align: center">{{ getCdName(this.bplcKndCdList, item.bplcKndCd) }}</td>
                <td aria-label="시설명"> {{item.fcltNm}}</td>
                <td aria-label="시설상태" style="text-align: center">{{ getCdName(this.fcltSttsCdList, item.fcltSttsCd)  }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <insp-pagination :total="myMngFcltCount" :size="searchParam.size" v-model:page="searchParam.page" v-model:maxPage="maxPage" @clickedPage="searchMyMngFclt"/>
          <!-- 보완요청/반려 안내문구 -->
          <template v-if="['02', '03'].includes(bizfaciltyFclt.fcltChgAplySttsCd)">
            <div class="desc_box progress_box">
              <i class="icon"><img src="@/assets/images/common/ico_noti.png" alt="이미지"></i>
              <div class="progress">
                <div><strong>{{ bizfaciltyFclt.fcltChgAplySttsNm }}</strong>상태입니다.<span>{{ bizfaciltyFclt.fcltChgAplySttsNm }} 사유를 확인해주세요.</span></div>
              </div>
            </div>
          </template>
          <template v-if="this.bizfaciltyFclt.fcltSqno === 0">
            <!-- 시설 정보 -->
            <div class="board_info mt_40">
              <h3 class="title2">시설 정보</h3>
              <div>
                <br-button label="신규 시설 등록" format="tertiary" size="sm" icon="add" @click="callPopupCreateFclt"/>
              </div>
            </div>
            <div class="desc_box progress_box">
              <i class="icon"><img src="@/assets/images/common/ico_noti.png" alt="이미지"></i>
              <div class="progress">
                <span>시설이 존재하지 않습니다. 시설을 등록해주세요.</span>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="board_info mt_40" style="position: relative;">
              <h3 class="title2">시설 정보변경신청 이력</h3>
              <div class="page_button_area">
                <br-button format="tertiary" size="sm" icon="search" label="시설 정보변경 이력" @click="callPopupFcltChgHstry()" />
              </div>
            </div>
            <div class="board_info" style="margin-bottom: 0px;">
              <p class="page" >
                <span class="total">전체 <strong>{{ this.fcltInfoChgHstryCount }}</strong></span>
                <span>페이지 <strong>{{ this.hstrySearchParam.page }}/{{ this.hstryMaxPage === 0 ? 1 : this.hstryMaxPage }}</strong></span>
              </p>
            </div>
            <div class="board_list">
              <table style="table-layout: fixed; width: 102rem">
                <caption> 시설정보 변경이력</caption>
                <colgroup>
                  <col style="width:210px">
                  <col style="width:14rem">
                  <col style="width:24rem">
                  <col style="width:27rem">
                  <col style="width:12rem">
                </colgroup>
                <thead>
                <tr>
                  <th scope="col">일시</th>
                  <th scope="col">유형</th>
                  <th scope="col">처리상태</th>
                  <th scope="col">처리사유</th>
                  <th scope="col">처리자</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="fcltInfoChgHstryList.length <= 0">
                  <td colspan="12">
                    <div style="display: flex; justify-content: center;">
                      <div class="no-data-message">조회조건에 해당하는 데이터가 없습니다.</div>
                    </div>
                  </td>
                </tr>
                <tr v-else v-for="(item, index) in fcltInfoChgHstryList" :key="index">
                  <td aria-label="일시">{{item.prcsDt }}</td>
                  <td aria-label="처리유형"> {{ getCdName(this.chgAplySeCdList, item.chgAplySeCd) }}</td>
                  <td aria-label="상세처리상태" style="text-align: center">
                    {{ getCdName(this.chgAplyPrcsSttsCdList, item.dtlChgAplyPrcsSttsCd) }}
                    <br-button v-if="item.dtlChgAplyPrcsSttsCd === '02' && index === 0" label="조치하기" class="ml_5 xsm tertiary" style="height: 2.6rem;" @click="takeAction(item)"/>
                    <br-button v-if="['01', '04'].includes(item.dtlChgAplyPrcsSttsCd)" label="변경정보확인" class="ml_5 xsm tertiary" style="height: 2.6rem;" @click="callPopupFcltChgAplyInfo(item)"/>
                  </td>
                  <td aria-label="처리사유" style="text-align: left;">{{item.prcsRsn}}</td>
                  <td aria-label="처리자">{{item.userNm}}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <insp-pagination :total="fcltInfoChgHstryCount" :size="hstrySearchParam.size" v-model:page="hstrySearchParam.page" v-model:maxPage="hstryMaxPage" @clickedPage="searchChgHstry"></insp-pagination>
            <!-- 시설 정보 -->
            <div class="board_info mt_40">
              <h3 class="title2">시설 정보</h3>
              <div>
                <br-button v-if="selectedRowData.bplcAprvYn === 'Y'" label="신규 시설 등록" format="tertiary" size="sm" icon="add" @click="callPopupCreateFclt"/>
              </div>
            </div>
            <div class="board_write">
              <table>
                <caption></caption>
                <colgroup>
                  <col style="width:15%">
                  <col style="width:35%">
                  <col style="width:15%">
                  <col style="width:35%">
                </colgroup>
                <tbody>
                <tr>
                  <th scope="row">시설명</th>
                  <td :colspan="conds.showSrfMnf? '' : 3" >
                    {{ this.bizfaciltyFclt.fcltNm }}
                  </td>
                  <th scope="row" v-if="conds.showSrfMnf">생산제품종류</th>
                  <td v-if="conds.showSrfMnf">
                    {{getCdName(this.inputItemCdList, bizfaciltyFclt.prdctnPrdctKndCd)}}
                  </td>
                </tr>
                <!-- 1.1. 정보변경 모드  -->
                <template v-if="isEditMode">
                  <tr v-if="conds.uncheck">
                    <th scope="row" style="text-align: left;">
                      <div style="display: flex; align-items: center;">
                        시설준공예정일자
                      </div>
                    </th>
                    <td>
                      <br-date-picker label="시설준공예정일자" v-model:value="modifiableData.fcltCmcnPrnmntYmd"/>
                    </td>
                    <th scope="row">시설준공일자</th>
                    <td>
                      <br-date-picker label="시설준공일자" v-model:value="modifiableData.fcltCmcnYmd"/>
                    </td>
                  </tr>
                  <tr v-if="conds.showAllSrf && conds.uncheck">
                    <th scope="row" style="text-align: left;">
                      <div style="display: flex; align-items: center;">
                        <i class="necessary" style="margin-right: 5px;">필수입력</i>건조시설<br/>보유여부
                      </div>
                    </th>
                    <td>
                      <br-form-radio label="건조시설보유여부" v-model:value="modifiableData.dhmdfyFcltHldYn" :options="radioBtnYnList" value-field="value" text-field="text" panel="panel6" :required="conds.showAllSrf"/>
                    </td>
                    <th scope="row" style="text-align: left;">
                      <div style="display: flex; align-items: center;">
                        <i class="necessary" style="margin-right: 5px;">필수입력</i>변경공사<br/>예정여부
                      </div>
                    </th>
                    <td>
                      <br-form-radio label="변경공사예정여부"  v-model:value="modifiableData.chgCstrnPrnmntYn" :options="radioBtnYnList" value-field="value" text-field="text" panel="panel6" :required="conds.showAllSrf"/>
                    </td>
                  </tr>
                  <tr v-if="conds.uncheck && conds.showAllSrf">
                    <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>시설단위</th>
                    <td :colspan="conds.showSrfUse? '' : 3" >
                      <br-form-select label="시설단위" groupCd="MBR011" includeSelectOption v-model:value="modifiableData.fcltUnitCd" :customStyle="{ 'max-width': '270px' }" panel="panel6" required/>
                    </td>
                    <th scope="row" v-if="conds.showSrfUse"><i class="necessary">*<span class="sr_only">필수입력</span></i>사용시설종류</th>
                    <td v-if="conds.showSrfUse">
                      <br-form-select label="사용시설종류" groupCd="COM441" includeSelectOption v-model:value="modifiableData.useFcltKndCd" panel="panel6" required/>
                    </td>
                  </tr>
                  <tr v-if="conds.uncheck">
                    <th scope="row"><i v-if="conds.showAllSrf" class="necessary">*<span class="sr_only">필수입력</span></i>시설공정</th>
                    <td>
                      <br-form-input label="시설공정" v-model="modifiableData.fcltProcsCn" panel="panel6" :required="conds.showAllSrf"/>
                    </td>
                    <th scope="row">형식</th>
                    <td>
                      <br-form-input label="형식" v-model="modifiableData.oprtngFrmCn" />
                    </td>
                  </tr>
                  <tr v-if="conds.uncheck">
                    <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>시설상태</th>
                    <td>
                      <br-form-radio label="시설상태" group-cd="MBR005" v-model:value="modifiableData.fcltSttsCd" />
                    </td>
                    <th scope="row">가동방식</th>
                    <td>
                      <br-form-input label="가동방식" v-model="modifiableData.oprtngMthCn" />
                    </td>
                  </tr>
                  <tr v-if="conds.uncheck">
                    <th scope="row"><i v-if="conds.showSrfUse" class="necessary">*<span class="sr_only">필수입력</span></i>가동시작일자</th>
                    <td :colspan="modifiableData.fcltSttsCd !== '03' ? 3 : '' ">
                      <br-date-picker label="가동시작일자" v-model:value="modifiableData.oprtngBgngYmd" panel="panel6" :required="conds.showSrfUse"/>
                    </td>
                    <th scope="row" v-if="modifiableData.fcltSttsCd === '03'"><i class="necessary">*<span class="sr_only">필수입력</span></i>폐쇄일자</th>
                    <td v-if="modifiableData.fcltSttsCd === '03'">
                      <br-date-picker label="폐쇄일자" v-model:value="modifiableData.clsgYmd" panel="panel6" :required="modifiableData.fcltSttsCd === '03'"/>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" v-if="modifiableData.fcltSttsCd === '02'"><i class="necessary">*<span class="sr_only">필수입력</span></i>가동중지기간</th>
                    <td v-if="modifiableData.fcltSttsCd === '02'" colspan="3">
                      <br-date-from-to label="가동중지기간" v-model:fromDate="modifiableData.oprtngHltBgngYmd" v-model:toDate="modifiableData.oprtngHltEndYmd" :disabled="isDisabled" panel="panel6" :required="modifiableData.fcltSttsCd === '02'"/>
                      <br-button v-if="isDisabled" label="가동중지기간연장" class="btn sm " style="margin-left: 5px" @click="callPopupOprtngHltYmd"/>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" v-if="['02','03'].includes(modifiableData.fcltSttsCd)" ><i class="necessary">*<span class="sr_only">필수입력</span></i>{{modifiableData.fcltSttsCd === '02'? '가동중지사유' : '폐쇄사유'}}</th>
                    <td v-if="['02','03'].includes(modifiableData.fcltSttsCd)" colspan=3>
                      <br-form-input :label="modifiableData.fcltSttsCd === '02'? '가동중지사유' : '폐쇄사유'" v-model="modifiableData.oprtngHltClsgRsn" :maxlength="255" panel="panel6" :required="['02','03'].includes(modifiableData.fcltSttsCd)"/>
                    </td>
                  </tr>
                  <tr v-if="conds.uncheck">
                    <th scope="row"><i v-if="conds.showAllSrf" class="necessary">*<span class="sr_only">필수입력</span></i>가동시간</th>
                    <td style="white-space: nowrap;" colspan="3">
                      <div class="form_group">
                        <div class="input_group">
                          <br-form-input label="일간가동시간" class="form_control" unit="hr/일" v-model="modifiableData.dilyOprtngHr" align="right" validator="number" panel="panel6" :required="conds.showSrfMnf || conds.showSrfUse"/>
                          <br-form-input label="연간가동일수" class="form_control" unit="일/년" v-model="modifiableData.anlOprtngDayCnt" align="right" validator="number" panel="panel6" :required="conds.showSrfMnf || conds.showSrfUse"/>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="conds.uncheck">
                    <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>시설용량</th>
                    <td style="white-space: nowrap;" colspan="3">
                      <div class="form_group">
                        <div class="input_group">
                          <br-form-input label="시간당시설용량" class="form_control" unit="kg/시간" v-model="modifiableData.hfcapa" align="right" validator="number" panel="panel6" required/>
                          <br-form-input label="일간시설용량"  class="form_control" unit="ton/일" v-model="modifiableData.dilyFcltCap" align="right" validator="number" panel="panel6" required/>
                          <br-form-input label="연간시설용량" class="form_control" unit="ton/년" v-model="modifiableData.anlFcltCap" align="right" validator="number" panel="panel6" required/>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="conds.uncheck">
                    <th scope="row">시설면적(m²)</th>
                    <td colspan="3">
                      <div class="form_group">
                        <div class="input_group">
                          <br-form-input label="시설면적" unit="m²" v-model="modifiableData.fcar" align="right" validator="number"/>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="conds.showFire && conds.uncheck">
                    <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>소각방식</th>
                    <td>
                      <br-form-select label="소각방식" groupCd="COM122" includeSelectOption v-model:value="modifiableData.fireMthCd" panel="panel6" :required="conds.showFire"/>
                    </td>
                    <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>소각유형</th>
                    <td>
                      <br-form-select label="소각유형" groupCd="COM123" includeSelectOption v-model:value="modifiableData.fireOperCd" panel="panel6" :required="conds.showFire"/>
                    </td>
                  </tr>
                  <tr v-if="conds.uncheck">
                    <th scope="row"><i v-if="conds.showAllSrf" class="necessary">*<span class="sr_only">필수입력</span></i>제품보관장소</th>
                    <td>
                      <br-form-input label="제품보관장소" v-model="modifiableData.prdctCstdyPlcCn" panel="panel6" :required="conds.showAllSrf"/>
                    </td>
                    <th scope="row"><i v-if="conds.showAllSrf" class="necessary">*<span class="sr_only">필수입력</span></i>제품보관유형</th>
                    <td>
                      <br-form-select label="제품보관유형" groupCd="COM406" includeSelectOption v-model:value="modifiableData.prdctCstdyTypeCd" panel="panel6" :required="conds.showAllSrf"/>
                    </td>
                  </tr>
                  <tr v-if="conds.uncheck">
                    <th scope="row">모델번호</th>
                    <td>
                      <br-form-input label="모델번호" v-model="modifiableData.mdlNo" />
                    </td>
                    <th scope="row">제조업체명</th>
                    <td>
                      <br-form-input label="제조업체명" v-model="modifiableData.mnftrBzentyNm" />
                    </td>
                  </tr>
                  <tr v-if="conds.showWes && conds.uncheck">
                    <th scope="row">에너지자체사용</th>
                    <td>
                      <br-form-radio label="에너지자체사용여부"  v-model:value="modifiableData.eneSlfUseYn" :options="radioBtnYnList" value-field="value" text-field="text"/>
                    </td>
                    <th scope="row">발전시설 용량</th>
                    <td>
                      <div class="form_group">
                        <div class="input_group">
                          <br-form-input label="발전시설용량" unit="kw" v-model="modifiableData.elctgnFcltCap" align="right" validator="number"/>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="conds.uncheck && (conds.showFire || conds.showSrfUse)">
                    <th scope="row" v-if="conds.showFire"><i class="necessary">*<span class="sr_only">필수입력</span></i>소각폐기물발열량</th>
                    <td v-if="conds.showFire">
                      <div class="form_group">
                        <div class="input_group">
                          <br-form-input label="소각폐기물발열량" unit="kcal/kg" v-model="modifiableData.fireWtCalqty" align="right" validator="number" panel="panel6" :required="conds.showFire"/>
                        </div>
                      </div>
                    </td>
                    <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>증기엔탈피값</th>
                    <td :colspan="conds.showFire ? 1 : 3">
                      <div class="form_group">
                        <div class="input_group">
                          <br-form-input label="증기엔탈피값" unit="kcal/kg" v-model="modifiableData.vaporEnthlpVl" align="right" validator="number" panel="panel6" :required="conds.showFire || conds.showSrfUse"/>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="(conds.showFire || conds.showSrfUse) && conds.uncheck">
                    <th scope="row">보일러부하율</th>
                    <td>
                      <div class="form_group">
                        <div class="input_group">
                          <br-form-input label="보일러부하율" unit="%" v-model="modifiableData.blrLdrt" align="right" validator="number" :maxlength="3"/>
                        </div>
                      </div>
                    </td>
                    <th scope="row">보일러효율</th>
                    <td>
                      <div class="form_group">
                        <div class="input_group">
                          <br-form-input label="보일러효율" unit="%" v-model="modifiableData.blrEfcnc" align="right" validator="number" :maxlength="3"/>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="conds.showBio && conds.uncheck">
                    <th scope="row">BIO가스생산량</th>
                    <td>
                      <div class="form_group">
                        <div class="input_group">
                          <br-form-input label="BIO가스생산량" unit="N㎥" v-model="modifiableData.bioGasPrdqty" align="right" validator="number"/>
                        </div>
                      </div>
                    </td>
                    <th scope="row">BIO가스 공급시설 용량</th>
                    <td>
                      <div class="form_group">
                        <div class="input_group">
                          <br-form-input label="BIO가스공급시설용량" unit="N㎥" v-model="modifiableData.bioGasSplyFcltCap" align="right" validator="number"/>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="conds.showBio && conds.uncheck">
                    <th scope="row">소화조설계 유입량</th>
                    <td>
                      <div class="form_group">
                        <div class="input_group">
                          <br-form-input label="소화조설계유입량" unit="N㎥" v-model="modifiableData.dgstrDesignInsrtCap" align="right" validator="number"/>
                        </div>
                      </div>
                    </td>
                    <th scope="row">소화조 체류일수</th>
                    <td>
                      <div class="form_group">
                        <div class="input_group">
                          <br-form-input label="소화조체류일수" unit="일" v-model="modifiableData.dgstrStayNmds" align="right" validator="number"/>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="conds.showBio && conds.uncheck">
                    <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>소화조 용량</th>
                    <td colspan="3">
                      <div class="form_group">
                        <div class="input_group">
                          <br-form-input label="소화조용량" unit="N㎥"
                                         v-model="modifiableData.dgstrCap"
                                         align="right"
                                         validator="number"
                                         panel="panel6"
                                         :required="conds.showBio"/>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="conds.showSrfUse">
                    <th scope="row">혼소비율</th>
                    <td colspan="3">
                      <div class="form_group">
                        <div class="input_group">
                          <br-form-input label="혼소비율" v-model="modifiableData.synFireRtCn"/>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="conds.showAllSrf">
                    <th scope="row">시설신고허가일자</th>
                    <td colspan="3">
                      <div class="form_group">
                        <div class="input_group">
                          <br-date-picker label="시설신고허가일자" v-model:value="modifiableData.fcltDclrPrmsnYmd" />
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
                <!-- 1.1. 정보변경 모드 템플릿 끝 -->

                <!-- 1.2. 조회만 가능한 템플릿(정보변경 불가) 시작 -->
                <template v-else>
                  <tr v-if="conds.uncheck">
                    <th scope="row">시설준공예정일자</th>
                    <td>
                      {{bizfaciltyFclt.fcltCmcnPrnmntYmd}}
                    </td>
                    <th scope="row">시설준공일자</th>
                    <td>
                      {{bizfaciltyFclt.fcltCmcnYmd}}
                    </td>
                  </tr>
                  <tr v-if="conds.showAllSrf && conds.uncheck">
                    <th scope="row">건조시설보유여부</th>
                    <td>
                      {{ this.bizfaciltyFclt.dhmdfyFcltHldYn === 'Y' ? '보유' : '미보유' }}
                    </td>
                    <th scope="row">변경공사예정여부</th>
                    <td>
                      {{ this.bizfaciltyFclt.chgCstrnPrnmntYn === 'Y' ? '예정' : '미정' }}
                    </td>
                  </tr>
                  <tr v-if="conds.uncheck">
                    <th scope="row" v-if="conds.showAllSrf">시설단위</th>
                    <td v-if="conds.showAllSrf" :colspan="conds.showSrfUse? '' : 3">
                      {{getCdName(this.fcltUnitCdList, this.bizfaciltyFclt.fcltUnitCd)}}
                    </td>
                    <th scope="row" v-if="conds.showSrfUse">사용시설종류</th>
                    <td v-if="conds.showSrfUse">
                      {{getCdName(this.useFcltKndCdList, this.bizfaciltyFclt.useFcltKndCd)}}
                    </td>
                  </tr>
                  <tr v-if="conds.uncheck">
                    <th scope="row">시설공정</th>
                    <td>
                      {{bizfaciltyFclt.fcltProcsCn}}
                    </td>
                    <th scope="row">형식</th>
                    <td>
                      {{bizfaciltyFclt.oprtngFrmCn}}
                    </td>
                  </tr>
                  <tr v-if="conds.uncheck">
                    <th scope="row">시설상태</th>
                    <td>
                      {{getCdName(this.fcltSttsCdList, this.bizfaciltyFclt.fcltSttsCd)}}
                    </td>
                    <th scope="row">가동방식</th>
                    <td>
                      {{bizfaciltyFclt.oprtngMthCn}}
                    </td>
                  </tr>
                  <tr v-if="conds.uncheck">
                    <th v-if="bizfaciltyFclt.fcltSttsCd === '01'" scope="row">가동시작일자</th>
                    <td v-if="bizfaciltyFclt.fcltSttsCd === '01'" :colspan="bizfaciltyFclt.fcltSttsCd === '01' ? 3 : ''">
                      {{bizfaciltyFclt.oprtngBgngYmd}}
                    </td>
                    <th scope="row" v-if="bizfaciltyFclt.fcltSttsCd === '02'">가동중지기간</th>
                    <td  v-if="bizfaciltyFclt.fcltSttsCd === '02'" :colspan="bizfaciltyFclt.fcltSttsCd === '02' ? 3 : ''">
                      {{bizfaciltyFclt.oprtngHltBgngYmd}} <span> ~ </span>
                      {{bizfaciltyFclt.oprtngHltEndYmd}}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row"  v-if="bizfaciltyFclt.fcltSttsCd === '03'">폐쇄일자</th>
                    <td  v-if="bizfaciltyFclt.fcltSttsCd === '03'">
                      {{bizfaciltyFclt.clsgYmd}}
                    </td>
                    <th scope="row" v-if="['02','03'].includes(bizfaciltyFclt.fcltSttsCd)">가동중지/폐쇄사유</th>
                    <td v-if="['02','03'].includes(bizfaciltyFclt.fcltSttsCd)" :colspan="bizfaciltyFclt.fcltSttsCd === '02' ? 3 : ''">
                      {{bizfaciltyFclt.oprtngHltClsgRsn}}
                    </td>
                  </tr>
                  <tr v-if="conds.uncheck">
                    <th scope="row">가동시간</th>
                    <td style="white-space: nowrap;">
                      <div class="form_group">
                        <div class="input_group">
                          {{bizfaciltyFclt.dilyOprtngHr}}
                          <span>hr/일</span>
                        </div>
                        <div class="input_group">
                          {{bizfaciltyFclt.anlOprtngDayCnt}}
                          <span>일/년</span>
                        </div>
                      </div>
                    </td>
                    <th scope="row">시설 승인상태</th>
                    <td :style="bizfaciltyFclt.aprvYn === 'N' ? 'color: red' : '' ">
                      {{getCdName(this.aprvYnCdList, bizfaciltyFclt.aprvYn)}}
                    </td>
                  </tr>
                  <tr v-if="conds.uncheck">
                    <th scope="row">시설용량</th>
                    <td style="white-space: nowrap;" >
                      <div class="form_group">
                        <div class="input_group" style="gap: 1rem">
                          {{formatNumber(bizfaciltyFclt.hfcapa)}}
                          <span>kg/시간</span>
                        </div>
                        <div class="input_group" style="gap: 1rem">
                          {{formatNumber(bizfaciltyFclt.dilyFcltCap)}}
                          <span>ton/일</span>
                        </div>
                        <div class="input_group" style="gap: 1rem">
                          {{formatNumber(bizfaciltyFclt.anlFcltCap)}}
                          <span>ton/년</span>
                        </div>
                      </div>
                    </td>
                    <th scope="row">시설면적(m²)</th>
                    <td>
                      <div class="form_group">
                        <div class="input_group">
                          {{formatNumber(bizfaciltyFclt.fcar)}}
                          <span v-show="bizfaciltyFclt.fcar">m²</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="conds.showFire && conds.uncheck">
                    <th scope="row">소각방식</th>
                    <td>
                      {{getCdName(this.fireMthCdList, this.bizfaciltyFclt.fireMthCd)}}
                    </td>
                    <th scope="row">소각유형</th>
                    <td>
                      {{getCdName(this.fireOperCdList, this.bizfaciltyFclt.fireOperCd)}}
                    </td>
                  </tr>
                  <tr v-if="conds.uncheck">
                    <th scope="row">제품보관장소</th>
                    <td>
                      {{bizfaciltyFclt.prdctCstdyPlcCn}}
                    </td>
                    <th scope="row">제품보관유형</th>
                    <td>
                      {{getCdName(this.prdctCstdyTypeCdList, this.bizfaciltyFclt.prdctCstdyTypeCd)}}
                    </td>
                  </tr>
                  <tr v-if="conds.uncheck">
                    <th scope="row">모델번호</th>
                    <td>
                      {{bizfaciltyFclt.mdlNo}}
                    </td>
                    <th scope="row">제조업체명</th>
                    <td>
                      {{bizfaciltyFclt.mnftrBzentyNm}}
                    </td>
                  </tr>
                  <tr v-if="conds.showWes && conds.uncheck">
                    <th scope="row">에너지자체사용여부</th>
                    <td>
                      {{bizfaciltyFclt.eneSlfUseYn === 'Y' ? '사용' : '미사용' }}
                    </td>
                    <th scope="row">발전시설 용량</th>
                    <td>
                      <div class="form_group">
                        <div class="input_group">
                          {{formatNumber(bizfaciltyFclt.elctgnFcltCap)}}
                          <span v-show="bizfaciltyFclt.elctgnFcltCap">kw</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr  v-if="conds.uncheck && (conds.showFire || conds.showSrfUse)">
                    <th scope="row" v-if="conds.showFire">소각폐기물발열량</th>
                    <td v-if="conds.showFire">
                      <div class="form_group">
                        <div class="input_group">
                          {{formatNumber(bizfaciltyFclt.fireWtCalqty)}}
                          <span v-if="bizfaciltyFclt.fireWtCalqty">kcal/kg</span>
                        </div>
                      </div>
                    </td>
                    <th scope="row">증기엔탈피값</th>
                    <td :colspan="conds.showFire ? 1 : 3">
                      <div class="form_group">
                        <div class="input_group">
                          {{formatNumber(bizfaciltyFclt.vaporEnthlpVl)}}
                          <span v-show="bizfaciltyFclt.vaporEnthlpVl">Kcal/kg</span>
<!--                          <br-button v-if="!isEditMode && (['05', '03'].includes(bizfaciltyFclt.fcltChgAplySttsCd) || !bizfaciltyFclt.fcltChgAplySttsCd)" label="값 수정" class="ml_5 xsm tertiary" style="height: 2.6rem;" @click="callPopupVaporEnthlp()"/>-->
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="(conds.showFire || conds.showSrfUse) && conds.uncheck">
                    <th scope="row">보일러부하율</th>
                    <td>
                      <div class="form_group">
                        <div class="input_group">
                          {{bizfaciltyFclt.blrLdrt}}
                          <span v-show="bizfaciltyFclt.blrLdrt">%</span>
                        </div>
                      </div>
                    </td>
                    <th scope="row">보일러효율</th>
                    <td>
                      <div class="form_group">
                        <div class="input_group">
                          {{bizfaciltyFclt.blrEfcnc}}
                          <span v-show="bizfaciltyFclt.blrEfcnc">%</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="conds.showBio && conds.uncheck">
                    <th scope="row">BIO가스 생산량</th>
                    <td>
                      <div class="form_group">
                        <div class="input_group">
                          {{formatNumber(bizfaciltyFclt.bioGasPrdqty)}}
                          <span v-show="bizfaciltyFclt.bioGasPrdqty">N㎥</span>
                        </div>
                      </div>
                    </td>
                    <th scope="row">BIO가스 공급시설 용량</th>
                    <td>
                      <div class="form_group">
                        <div class="input_group">
                          {{formatNumber(bizfaciltyFclt.bioGasSplyFcltCap)}}
                          <span v-show="bizfaciltyFclt.bioGasSplyFcltCap">N㎥</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="conds.showBio && conds.uncheck">
                    <th scope="row">소화조 설계 유입량</th>
                    <td>
                      <div class="form_group">
                        <div class="input_group">
                          {{formatNumber(bizfaciltyFclt.dgstrDesignInsrtCap)}}
                          <span v-show="bizfaciltyFclt.dgstrDesignInsrtCap">N㎥</span>
                        </div>
                      </div>
                    </td>
                    <th scope="row">소화조 체류일수</th>
                    <td>
                      <div class="form_group">
                        <div class="input_group">
                          {{bizfaciltyFclt.dgstrStayNmds}}
                          <span v-show="bizfaciltyFclt.dgstrStayNmds">일</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="conds.showBio && conds.uncheck">
                    <th scope="row">소화조 용량</th>
                    <td colspan="3">
                      <div class="form_group">
                        <div class="input_group">
                          {{formatNumber(bizfaciltyFclt.dgstrCap)}}
                          <span v-show="bizfaciltyFclt.dgstrCap">N㎥</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="conds.showSrfUse">
                    <th scope="row">혼소비율</th>
                    <td colspan="3">
                      {{bizfaciltyFclt.synFireRtCn}}
                    </td>
                  </tr>
                  <tr v-if="conds.showAllSrf">
                    <th scope="row">시설신고허가일자</th>
                    <td colspan="3">
                      {{bizfaciltyFclt.fcltDclrPrmsnYmd}}
                    </td>
                  </tr>
                </template>
                <!-- 1.2. 조회만 가능(정보변경 불가) 템플릿 끝 -->
                </tbody>
              </table>
              <!-- 1.3. 투입품목 그리드 조회 템플릿 ( SRF제조/사용, 바이오가스  )-->
              <template v-if="['01-01', '01-02', '04-01'].includes(this.bplcKndCd)">
                <!-- 1.3.1.  투입품목 그리드 수정모드 템플릿 시작    -->
                <template v-if="isEditMode">
                  <div class="board_info mt_40">
                    <h3 class="title2"><i class="necessary">*<span class="sr_only">필수입력</span></i>시설 투입품목</h3>
                    <div>
                      <br-button format="tertiary" label="추가" style="margin-right: 5px" class="btn sm primary line ico_plus" @click="addRow" />
                      <br-button format="tertiary" label="삭제" class="btn sm point ico_minus" @click="removeRow()" />
                    </div>
                  </div>
                  <div class="board_list">
                    <table style="table-layout: fixed;">
                      <caption> 시설 투입품목 - 투입품목, 투입비율, 일간투입량으로 구성 </caption>
                      <colgroup>
                        <col style="width:10%">
                        <col style="width:50%">
                        <col v-show="['01-01', '01-02'].includes(this.bplcKndCd)" style="width:20%">
                        <col v-show="['04-01'].includes(this.bplcKndCd)" style="width:20%">
                      </colgroup>
                      <thead>
                      <tr>
                        <th scope="col">선택</th>
                        <th scope="col">투입품목</th>
                        <th v-show="['01-01', '01-02'].includes(this.bplcKndCd)" scope="col">투입비율 (%)</th>
                        <th v-show="['04-01'].includes(this.bplcKndCd)" scope="col">일간투입량</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-if="fcltInputItemList.length <= 0">
                        <td colspan="12">
                          <div style="display: flex; justify-content: center;">
                            <div class="no-data-message">조회조건에 해당하는 데이터가 없습니다.</div>
                          </div>
                        </td>
                      </tr>
                      <tr v-else v-for="(item, index) in fcltInputItemList" :key="index"
                          :class="{'checked-style': item.chk === 'Y'}">
                        <td aria-label="선택" @click="toggleCheckbox(index)" class="checkbox-cell">
                          <input type="checkbox" id="chk1" v-model="item.chk" true-value="Y" false-value="N" @click.stop>
                        </td>
                        <td aria-label="투입품목">
                          <br-form-select label="투입품목"
                                          :options="inputItemCdOption"
                                          v-model:value="item.inputItemCd"
                                          :disabled="!!item.fcltSqno"
                                          @change="handleSelectChange(item.inputItemCd, index)"/>
                        </td>
                        <td v-show="['01-01', '01-02'].includes(this.bplcKndCd)" aria-label="투입비율">
                          <br-form-input label="투입비율"
                                         oninput="this.value = this.value.replace(/[^0-9.]/g, '')"
                                         v-model="item.inputItemCpstRt" :maxlength="3"
                                         @input="markAsChanged(index)"
                                         :disabled="['04-01'].includes(this.bplcKndCd)"
                                         align="right"/>
                        </td>
                        <td v-show="['04-01'].includes(this.bplcKndCd)" aria-label="일간투입량">
                          <br-form-input label="일간투입량"
                                         oninput="this.value = this.value.replace(/[^0-9.]/g, '')"
                                         v-model="item.dilyInpqty"
                                         @input="markAsChanged(index)"
                                         :disabled="['01-01', '01-02'].includes(this.bplcKndCd)"
                                         align="right"/>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </template>
                <!-- 1.3.1.  투입품목 그리드 수정모드 템플릿 끝    -->
                <!-- 1.3.2.  투입품목 그리드 수정불가모드(읽기전용) 템플릿 시작    -->
                <template v-else>
                  <div class="board_info mt_40">
                    <h3 class="title2">시설 투입품목</h3>
                  </div>
                  <div class="board_list">
                    <table style="table-layout: fixed;">
                      <caption> 시설 투입품목 - 투입품목, 투입비율, 일간투입량으로 구성 </caption>
                      <colgroup>
                        <col style="width:10%">
                        <col style="width:50%">
                        <col v-show="['01-01', '01-02'].includes(this.bplcKndCd)" style="width:20%">
                        <col v-show="['04-01'].includes(this.bplcKndCd)" style="width:20%">
                      </colgroup>
                      <thead>
                      <tr>
                        <th scope="col">선택</th>
                        <th scope="col">투입품목</th>
                        <th v-show="['01-01', '01-02'].includes(this.bplcKndCd)" scope="col">투입비율 (%)</th>
                        <th v-show="['04-01'].includes(this.bplcKndCd)" scope="col">일간투입량</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-if="fcltInputItemList.length <= 0">
                        <td colspan="12">
                          <div style="display: flex; justify-content: center;">
                            <div class="no-data-message">조회조건에 해당하는 데이터가 없습니다.</div>
                          </div>
                        </td>
                      </tr>
                      <tr v-else v-for="(item, index) in fcltInputItemList" :key="index"
                          :class="{'checked-style': item.chk === 'Y'}">
                        <td aria-label="선택">
                          <input type="checkbox" id="chk1" v-model="item.chk"
                                 true-value="Y" false-value="N" disabled
                          >
                        </td>
                        <td aria-label="투입품목">{{ getCdName(this.inputItemCdList, item.inputItemCd)}}</td>
                        <td v-show="['01-01', '01-02'].includes(this.bplcKndCd)" aria-label="투입비율" style="text-align: right">{{item.inputItemCpstRt }}</td>
                        <td v-show="['04-01'].includes(this.bplcKndCd)" aria-label="일간투입량" style="text-align: right">{{item.dilyInpqty}}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </template>
                <!-- 1.3.2.  투입품목 그리드 수정불가모드(읽기전용) 템플릿 시작    -->
              </template>
              <!-- 1.3. 투입품목 그리드 조회 템플릿 ( SRF제조/사용, 바이오가스  ) 끝-->
              <h3 class="title2">첨부서류</h3>
              <br-attach-file-list label="시설 첨부파일 목록"
                                   ref="attachFileList"
                                   :atchFileTaskSeCd="atchFileTaskSeCd"
                                   v-model:atchFileGroupId="atchFileGroupId"
                                   :maxFileCount="10"
                                   :readonly="!isEditMode"
                                   :useEtcFile="true"
              />
              <div class="btn_area center">
                <br-button v-if="!isEditMode && (['05', '03'].includes(bizfaciltyFclt.fcltChgAplySttsCd) || !bizfaciltyFclt.fcltChgAplySttsCd)" label="정보 변경" class="btn md" @click="onClickInfoChg" />
                <br-button v-if="isEditMode" :label="(bizfaciltyFclt.fcltChgAplySeCd === '01' && bizfaciltyFclt.fcltChgAplySttsCd === '03') ? '재승인 신청' : '변경 신청'" class="btn md primary"  @click="callPopupFcltChgAply" panel="panel6" validate/>
                <br-button v-if="isEditMode" label="취소" class="btn md point"  @click="cancelModify"/>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="desc_box progress_box">
            <i class="icon"><img src="@/assets/images/common/ico_noti.png" alt="이미지"></i>
            <div class="progress">
              <span>시설이 존재하지 않습니다. 나의 관리 사업장 등록/승인 후, 시설을 등록해주세요.</span>
            </div>
          </div>
        </template>
      </template>
      <!-- 업체 승인 전 -->
      <template v-else>
        <div class="desc_box progress_box">
          <i class="icon"><img src="@/assets/images/common/ico_noti.png" alt="이미지"></i>
          <div class="progress">
            <div><span>업체 승인 후 시설 등록이 가능합니다.</span></div>
          </div>
        </div>
      </template>
    </section>
  </div>
  <createFcltPopup
      :bplcId="this.bizfaciltyFclt.bplcId"
      :bplcNm="this.bplcNm"
      :bplcKndCd="this.bplcKndCd"
      @completed="createdFcltForFclt"
  />
  <chgAplyPopup
      :id="chgAplyPopId"
      @chgAplyClick="processChangeRequest"
  />
  <FcltChgHstryPopup
      :fcltSqno="this.bizfaciltyFclt.fcltSqno"
      :bplcId="this.bizfaciltyFclt.bplcId"
  />
  <fcltSplmntPopup
      :bplcId="this.bizfaciltyFclt.bplcId"
      :fcltSqno="this.bizfaciltyFclt.fcltSqno"
      :chgAplyPrcsSttsCd="this.chgAplyPrcsSttsCd"
      :chgAplySn="this.chgAplySn"
      :prcsRsn="this.prcsRsn"
      :chgAplySeCd="this.chgAplySeCd"
      :bplcKndCd="this.bplcKndCd"
      @complete="chgHstryGridReload"
  />
  <FcltChgAplyInfo
      :bplcId="this.bizfaciltyFclt.bplcId"
      :fcltSqno="this.bizfaciltyFclt.fcltSqno"
      :chgAplySn="this.chgAplySn"
  />

  <!-- 가동중지기간 연장 팝업 -->
  <div v-show="isOn" class="modal visible">
    <div class="popup_box" style="width: 20%;">
      <h1 class="popup_title">가동중지기간 연장</h1>
        <button type="button" class="modal_close" @click.prevent="hide">팝업닫기</button>
      <div class="popup_cont">
        <div class="board_write">
          <table>
            <caption></caption>
            <colgroup>
              <col style="width:40%">
              <col style="width:60%">
            </colgroup>
            <tbody>
            <tr>
              <th scope="row">연장일수</th>
              <td>
                <br-form-input label="가동중지기간연장일수" :maxlength="255" validator="number" v-model="oprtngHltYmd" align="right"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="btn_area right">
          <br-button label="저장" format="primary" size="sm" @click="complete"/>
          <br-button label="취소" format="tertiary" size="sm" @click="hide" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storeComponent } from "@/js/store/store-component";
import { buildUri } from "@/js/utils/uri-builder";
import { storeSwitch } from "@/js/store/store-switch";
import SbGrid from "@/components/SbGrid.vue";
import MbrFormTelno from "@/views/mbr-mng/components/MbrFormTelno.vue";
import BrDatePicker from "@/components/BrDatePicker.vue";
import CreateFcltPopup from "@/views/my-office/components/popup/CreateFcltPopup.vue";
import inspPagination from "@/views/sfli/components/inspPagination.vue";
import BrFormRadio from "@/components/BrFormRadio.vue";
import {storeSession} from "@/js/store/store-session";
import BrFormSelect from "@/components/BrFormSelect.vue";
import BrFormInput from "@/components/BrFormInput.vue";
import ChgAplyPopup from "@/views/my-office/components/popup/ChgAplyPopup.vue";
import BrButton from "@/components/BrButton.vue";
import BrDateFromTo from "@/components/BrDateFromTo.vue";
import FcltChgHstryPopup from "@/views/my-office/components/popup/FcltChgHstryPopup.vue";
import FcltSplmntPopup from "@/views/my-office/components/popup/FcltSplmntPopup.vue";
import FcltChgAplyInfo from "@/views/my-office/components/popup/FcltChgAplyInfo.vue";

const API = {
  FCLT_INFO: "/bizfacility/fclt-info/{bplcId}/{fcltSqno}",
  FCLT_INFO_UPDATE: "/bizfacility/fclt-info",
  FCLT_CHG_HSTRY: "/bizfacility/fclt-chg-hstry/{bplcId}/{fcltSqno}",
  FCLT_INPUT_ITEM: "/bizfacility/fclt-input-item",
  MY_MNG_FCLT: "/bizfacility/my-mng-fclt",
  FCLT_CHG_INFO: "/bizfacility/fclt-chg-info",
};

const PAGE_SIZE = 5;

const CODE = {
  ARPV_YN_CD: "SIS020", // 승인여부코드
  BPLC_STTS_CD: "MBR004", // 사업장상태코드
  BPLC_KND_CD: "MBR006", // 사업장종류코드
  PRDCTN_PRDCT_KND_CD: "MBR010", // 생산제품종류코드
  INPUT_ITEM_CD: "MBR010", // 투입품목코드
  CHG_APLY_TYPE_CD: "MBR008", //변경신청유형코드
  CHG_APLY_PRCS_STTS_CD: "MBR009",  //변경신청처리상태코드
  FCLT_STTS_CD: "MBR005", // 시설상태
  FCLT_UNIT_CD: "MBR011",   //시설단위
  USE_FCLT_KND_CD: "COM441",   //사용시설종류
  FIRE_MTH_CD: "COM122",   //소각방식
  FIRE_OPER_CD: "COM123",   //소각유형
  PRDCT_CSTDY_TYPE_CD: "COM406",   //제품보관유형
  CHG_APLY_SE_CD: "MBR025",  //변경신청구분코드
  BPLC_MNG_PIC_STTS_CD: "MBR023",  //사업장관리담당자상태코드
};

const initBizfaciltyFclt = {
      fcltChgAplySeCd: '',
      fcltChgAplySttsCd: '',
      fcltChgAplySttsNm: '',
      bplcId: '',
      fcltSqno: 0,
      fcltNm: '',
      qltyTaskIssuNo: '',
      prdctnPrdctKndCd: '',
      fcltCmcnPrnmntYmd: '',
      fcltCmcnYmd: '',
      dhmdfyFcltHldYn: '',
      chgCstrnPrnmntYn: '',
      useFcltKndCd: '',
      fcar: '',
      hfcapa: '',
      dilyFcltCap: '',
      anlFcltCap: '',
      fcltUnitCd: '',
      fcltProcsCn: '',
      oprtngFrmCn: '',
      oprtngMthCn: '',
      dilyOprtngHr: '',
      anlOprtngDayCnt: '',
      prdctCstdyPlcCn: '',
      prdctCstdyTypeCd: '',
      fcltSttsCd: '',
      aprvYn: '',
      oprtngBgngYmd: '',
      oprtngHltBgngYmd: '',
      oprtngHltEndYmd: '',
      clsgYmd: '',
      oprtngHltClsgRsn: '',
      mdlNo: '',
      mnftrBzentyNm: '',
      frstInspRsltSn: '',
      frstInspYmd: '',
      frstInspPassYn: '',
      chgInspTrgtYn: '',
      vaporEnthlpVl: '',
      blrLdrt: '',
      blrEfcnc: '',
      eneSlfUseYn: '',
      fireMthCd: '',
      fireOperCd: '',
      fireWtCalqty: '',
      bioGasPrdqty: '',
      bioGasSplyFcltCap: '',
      dgstrDesignInsrtCap: '',
      dgstrStayNmds: '',
      dgstrCap: '',
      elctgnFcltCap: '',
      synFireRtCn: '',
      atchFileGroupId: '',
      useYn: '',
      rmrkCn: '',
      regDt: '',
      rgtrId: '',
      mdfcnDt: '',
      mdfrId: '',
      inserted: '',
      updated: '',
      bplcAprvYn: '',
      fcltDclrPrmsnYmd: '',
};

export default {
  components: {
    FcltChgAplyInfo,
    FcltSplmntPopup,
    FcltChgHstryPopup,
    BrDateFromTo,
    BrButton,
    ChgAplyPopup,
    BrFormInput,
    BrFormSelect,
    BrFormRadio,
    inspPagination,
    BrDatePicker,
    MbrFormTelno,
    SbGrid,
    CreateFcltPopup
  },
  props: {
    menu: {type: Object, required: true},
    bzentyAprvYn: {type: String, required: true},
  },
  expose: ['initialize', 'reset', 'searchMyMngFclt'],
  data() {
    return {
      isOn: false,
      isEditMode: false,
      createFcltPopId: 'createFcltPopupForFclt',
      chgAplyPopId: 'chgAplyPopupForFclt',
      userInfo: storeSession.getMemberInfo(),
      oprtngHltYmd: 0,
      fcltCnt: 0,
      prcsRsn: '',
      chgAplyPrcsSttsCd: '',
      chgAplySn: 0,
      maxPage: 1,
      hstryMaxPage: 1,
      searchParam: {
        page: 1,
        size: 5,
      },
      hstrySearchParam: {
        page: 1,
        size: 5,
      },
      selectedRowData: '',           // 선택된 행의 데이터를 저장할 변수
      selectedRowIndex: '',       // 선택된 행의 인덱스를 저장할 변수
      bplcNm: '',
      bplcKndCd: '',
      chgAplySeCd: '',
      bizfaciltyFclt: {...initBizfaciltyFclt},
      isDisabled: false,
      uncheckedList: [  // 미관리 종류
        '06-01',
        '07-01',
        '08-01',
        '09-01',
        '10-01',
      ],
      conds: {
        showWes: false,
        showFire: false,
        showBio: false,
        showAllSrf: false,
        showSrfMnf: false,
        showSrfUse: false,
        uncheck: false,
      },
      modifiableData: {},
      aprvYnCdList: [],
      inputItemCdList: [],
      initialInputItemOption: [{ value: '', text: '선택' }],
      inputItemCdOption: [],
      bplcSttsCdList: [],
      bplcKndCdList: [],
      fcltUnitCdList: [],
      useFcltKndCdList: [],
      fcltSttsCdList: [],
      fireMthCdList: [],
      fireOperCdList: [],
      chgAplySeCdList: [],
      bplcMngPicSttsCdList: [],
      chgAplyPrcsSttsCdList: [],
      prdctCstdyTypeCdList: [],
      fcltInfoChgHstryList: [],
      fcltInfoChgHstryCount: 0,
      fcltInputItemList: [],
      fcltInputItemCount: 0,
      myMngFcltList: [],
      myMngFcltCount: 0,
      atchFileTaskSeCd: '',
      radioBtnYnList: [
        {value: "Y", text: "대상"},
        {value: "N", text: "비대상"},
      ],
      useYnList: [
        {value: "Y", text: "사용"},
        {value: "N", text: "미사용"},
      ],
      changedRows: new Set(), // 변경된 행의 인덱스를 저장하는 Set
    };
  },
  computed: {
    // 업체 정보 수정여부 확인하기
    atchFileGroupId: {
      get() {
        return this.isEditMode ? this.modifiableData.atchFileGroupId : this.bizfaciltyFclt.atchFileGroupId;
      },
      set(value) {
        if (this.isEditMode) {
          this.modifiableData.atchFileGroupId = value;
        } else {
          this.bizfaciltyFclt.atchFileGroupId = value;
        }
      }
    },
  },
  watch: {},
  created() {
  },
  beforeMount() {
    // 컴포넌트 전체 데이터 가져오기
    storeComponent.restore(this.menu, this);
  },
  mounted() {
  },
  beforeUnmount() {
    // 컴포넌트 전체 데이터 저장하기
    storeComponent.store(this.menu, this);
  },
  methods: {
    initialize() {
        this.aprvYnCdList = this.$store.getters.getCodeList({groupCd: CODE.ARPV_YN_CD, useYn: 'Y'});           // 승인여부코드
        this.bplcSttsCdList = this.$store.getters.getCodeList({groupCd: CODE.BPLC_STTS_CD, useYn: 'Y'});       // 사업장상태코드
        this.bplcKndCdList = this.$store.getters.getCodeList({groupCd: CODE.BPLC_KND_CD, useYn: 'Y'});         // 사업장종류코드
        this.inputItemCdList = this.$store.getters.getCodeList({groupCd: CODE.INPUT_ITEM_CD, useYn: 'Y'});   // 투입품목코드
        this.fcltUnitCdList = this.$store.getters.getCodeList({groupCd: CODE.FCLT_UNIT_CD, useYn: 'Y'});   // 시설단위코드
        this.chgAplyPrcsSttsCdList = this.$store.getters.getCodeList({groupCd: CODE.CHG_APLY_PRCS_STTS_CD, useYn: 'Y'});           // 변경신청처리상태코드
        this.chgAplyTypeCdList = this.$store.getters.getCodeList({groupCd: CODE.CHG_APLY_TYPE_CD, useYn: 'Y'});           // 변경신청유형코드
        this.fcltSttsCdList = this.$store.getters.getCodeList({groupCd: CODE.FCLT_STTS_CD, useYn: 'Y'});   // 시설상태
        this.fcltUnitCdList = this.$store.getters.getCodeList({groupCd: CODE.CHG_APLY_TYPE_CD, useYn: 'Y'});           // 시설단위
        this.useFcltKndCdList = this.$store.getters.getCodeList({groupCd: CODE.USE_FCLT_KND_CD, useYn: 'Y'});           // 사용시설종류
        this.fireMthCdList = this.$store.getters.getCodeList({groupCd: CODE.FIRE_MTH_CD, useYn: 'Y'});           // 소각방식
        this.fireOperCdList = this.$store.getters.getCodeList({groupCd: CODE.FIRE_OPER_CD, useYn: 'Y'});           // 소각유형
        this.prdctCstdyTypeCdList = this.$store.getters.getCodeList({groupCd: CODE.PRDCT_CSTDY_TYPE_CD, useYn: 'Y'});           // 제품보관유형
        this.chgAplySeCdList = this.$store.getters.getCodeList({groupCd: CODE.CHG_APLY_SE_CD, useYn: 'Y'});           // 변경신청구분코드
        this.bplcMngPicSttsCdList = this.$store.getters.getCodeList({groupCd: CODE.BPLC_MNG_PIC_STTS_CD, useYn: 'Y'});   // 사업장관리담당자상태코드
        this.searchMyMngFclt();
    },
    readFcltInfo() {
      const fcltInfoUrl = buildUri(API.FCLT_INFO, {bplcId: this.bizfaciltyFclt.bplcId, fcltSqno: this.bizfaciltyFclt.fcltSqno});
      this.$apiCall.get(fcltInfoUrl, {},  data => {
        if (data.result) {
          for (const key of Object.keys(this.bizfaciltyFclt)) {
            this.bizfaciltyFclt[key] = data.result?.[key] ?? null;
          }
          this.modifiableData = JSON.parse(JSON.stringify(this.bizfaciltyFclt));
          // 가동중지기간인 시설은 기간 연장하려면 연장버튼을 통해 조작
          this.isDisabled = !!this.bizfaciltyFclt.oprtngHltBgngYmd && !!this.bizfaciltyFclt.oprtngHltEndYmd && this.bizfaciltyFclt.fcltSttsCd === '02';

          setTimeout(() => {
            this.$refs.attachFileList.load(this.atchFileTaskSeCd, this.bizfaciltyFclt.atchFileGroupId);
          }, 300);
        }
      });
    },
    searchChgHstry() {
      const fcltChgHstryUrl = buildUri(API.FCLT_CHG_HSTRY, {bplcId: this.bizfaciltyFclt.bplcId, fcltSqno: this.bizfaciltyFclt.fcltSqno, size: this.hstrySearchParam.size, page: this.hstrySearchParam.page});

      this.hstrySearchParam.size = PAGE_SIZE;

      this.$apiCall.get(fcltChgHstryUrl, this.hstrySearchParam, data => {
        if (data.result.list) {
          this.fcInfoChgHstryCount = data.result.total;
          this.fcltInfoChgHstryList = data.result.list;
        } else {
          this.fcltInfoChgHstryList = [];
        }
      });
    },
    setConditions(bplcKndCd) {
      const mainCd = bplcKndCd.slice(0, 2);
      const subCd = bplcKndCd.slice(3, 5);

      // 초기화
      this.conds = {
        showWes: false,
        showFire: false,
        showBio: false,
        showAllSrf: false,
        showSrfMnf: false,
        showSrfUse: false,
        uncheck: !this.uncheckedList.includes(bplcKndCd),
      };

      // 조건 설정
      if (mainCd === '01') {
        this.atchFileTaskSeCd = 'FCTA';
        if (['01', '02'].includes(subCd)) {
          switch (subCd){
            case '01' :
              this.atchFileTaskSeCd = 'FCTA'
              break;
            case '02' :
              this.atchFileTaskSeCd = 'FCTC'


              break;
          }
          this.conds.showAllSrf = true;
          this.conds.showSrfMnf = subCd === '01';
          this.conds.showSrfUse = subCd === '02';
        }
      } else {
        this.atchFileTaskSeCd = 'FCTB';
        this.conds.showWes = true;

        if (mainCd === '03') this.conds.showFire = true;
        else if (mainCd === '04') this.conds.showBio = true;
      }

      // 관리되지 않는 폐자원에너지 추가 조건 (06~10)
      if (['06', '07', '08', '09', '10'].includes(mainCd)) {
        this.conds.uncheck = false;
        this.$refs.attachFileList.load('X', 'X');
      }
    },
    searchMyMngFclt() {
      this.searchParam.size = PAGE_SIZE;
      this.$apiCall.get(API.MY_MNG_FCLT, this.searchParam, data => {
        if (data.result.list) {
          this.myMngFcltCount = data.result.total;
          this.myMngFcltList = data.result.list;
          this.setMaxPage();
          this.selectFirstRow();
        } else {
          this.myMngFcltList = [];
        }
      });
    },
    setMaxPage() {
      const totalPage = Math.ceil(this.myMngFcltCount / this.searchParam.size);
      this.maxPage = totalPage === 0 ? 1 : totalPage;
    },
    searchFcltInputItem() {
      this.$apiCall.get(
          API.FCLT_INPUT_ITEM,
          {
            bplcId: this.bizfaciltyFclt.bplcId,
            fcltSqno: this.bizfaciltyFclt.fcltSqno,
            // page: this.searchParam.page,
            // size: this.searchParam.size
          },
          data => {
        if (data.result.list) {
          this.fcltInputItemCount = data.result.total;
          this.fcltInputItemList = data.result.list;
        } else {
          this.fcltInputItemList = [];
        }
        this.changedRows = new Set();
      });
    },
    selectFirstRow() {
      if (this.myMngFcltList && this.myMngFcltList.length > 0) {
        const firstRow = this.myMngFcltList[0]; // 첫 번째 행 데이터
        this.selectedRowIndex = 0;
        this.handleRowSelection(firstRow); // 함수 호출
      }
    },
    async handleRowSelection(rowData) {
      this.isEditMode = false;
      this.inputItemCdOption = [...this.initialInputItemOption];

      this.selectedRowData = rowData;     // 선택된 데이터를 저장
      this.bizfaciltyFclt.bplcId = rowData.bplcId;
      this.fcltCnt = rowData.fcltCnt;
      this.bplcNm = rowData.bplcNm;
      this.bizfaciltyFclt.fcltSqno = rowData.fcltSqno ? rowData.fcltSqno : 0;
      this.bplcKndCd = rowData.bplcKndCd;
      this.bplcAprvYn = rowData.bplcAprvYn;
      //선택한 사업장이 승인상태이고 시설이 존재할 경우에만 시설정보 및 그리드 조회
      if(rowData.fcltSqno){
        //시설정보 조회
        await this.readFcltInfo();
        //사업장종류에 따른 조건 값 설정
        await this.setConditions(this.bplcKndCd);
        //변경이력 조회
        await this.searchChgHstry();
        //시설투입품목 조회
        await this.searchFcltInputItem();
        // 사업장 종류에 따른 생산제품(투입품목) 리스트 설정
        await this.filterInputItemList();

        await this.$refs.attachFileList.load(this.atchFileTaskSeCd, this.bizfaciltyFclt.atchFileGroupId);
      } else {
        // 그 외는 시설정보 초기화
        await Object.keys(this.modifiableData).forEach(key => {
          delete this.modifiableData[key];
        });
        this.isEditMode = false;
        this.bizfaciltyFclt = {...initBizfaciltyFclt};
        this.bizfaciltyFclt.bplcId = rowData.bplcId;
        this.bizfaciltyFclt.bplcAprvYn = rowData.bplcAprvYn;
      }
    },
    selectedRow(item, index) {
      this.selectedRowData = item;    // 선택된 데이터를 저장
      this.selectedRowIndex = index;  // 선택된 행의 인덱스를 저장
      this.handleRowSelection(item);
    },
    validateFcltItem() {
      if(!['01-01', '01-02', '04-01'].includes(this.bplcKndCd)){
        return true;
      }

      if(this.fcltInputItemList.length === 0){
        alert("시설 투입품목 입력은 필수 사항입니다.");
        return false;
      }

      let totalInputItemCpstRt = 0;

      for (const fcltInputItem of this.fcltInputItemList) {
        totalInputItemCpstRt += Number(fcltInputItem.inputItemCpstRt);
        if (!fcltInputItem.inputItemCd) {
          alert("투입품목이 비어 있는 항목이 있습니다. 확인 후 다시 신청해주세요.");
          return false;
        }
        if (['01-01', '01-02'].includes(this.bplcKndCd) && (!fcltInputItem.inputItemCpstRt || Number(fcltInputItem.inputItemCpstRt) === 0)) {
          alert("투입비율이 비어있습니다. 확인 후 다시 신청해주세요.");
          return false;
        }
        if (['04-01'].includes(this.bplcKndCd) && (!fcltInputItem.dilyInpqty || Number(fcltInputItem.dilyInpqty) === 0)) {
          alert("일간투입량이 비어있습니다. 확인 후 다시 신청해주세요.");
          return false;
        }
        if(Number(fcltInputItem.inputItemCpstRt) > 100){
          alert("투입비율은 100%를 넘길 수 없습니다. 확인 후 다시 신청해주세요.");
          return false;
        }
      }
      if(totalInputItemCpstRt > 100){
        alert("투입비율의 총 합은 100%를 넘길 수 없습니다. 확인 후 다시 신청해주세요.");
        return false;
      }
      if(['01-01', '01-02'].includes(this.bplcKndCd) && totalInputItemCpstRt < 100){
        alert("투입비율의 총 합이 100%가 아닙니다. 확인 후 다시 신청해주세요.");
        return false;
      }
      return true;
    },
    createdFcltForFclt() {
      this.searchMyMngFclt();
    },
    reset() {
      // Object.keys(this.modifiableData).forEach(key => {
      //   delete this.modifiableData[key];
      // });
      this.isEditMode = false;
      this.changedRows = new Set();
    },
    getCdName(list, code) {
      if(!code){
        return '' ;
      }
      const status = list.find(item => item.dtlCd === code);
      return status ? status.dtlCdNm : '';
    },
    onClickInfoChg() {
      this.isEditMode = true;
    },
    cancelModify() {
      this.changedRows.clear(); // 시설투입품목 변경된 행 인덱스 초기화
      this.searchChgHstry();

      this.searchFcltInputItem();
      //fcltSqno가 없는 항목은 리스트에서 제거
      // this.fcltInputItemList = this.fcltInputItemList.filter(item => !(!item.fcltSqno));
      this.isEditMode = false;
    },
    addRow() {
      const newRow = {
        fcltSqno: 0,
        chk: 'N',
        inputItemCd: '',
        inputItemCpstRt: '',
        dilyInpqty: '',
        useYn: 'Y',
      };
      this.fcltInputItemList.push(newRow);
    },
    removeRow() {
      // 체크된 행만 필터링
      const checkedRows = this.fcltInputItemList.filter(item => item.chk === 'Y');

      // 체크된 행이 없는 경우 알림 표시 및 함수 종료
      if (checkedRows.length === 0) {
        alert("선택된 항목이 없습니다.");
        return;
      }

      const confirmMessage = `선택된 ${checkedRows.length}개의 투입품목을 정말 삭제하시겠습니까?`;

      if (confirm(confirmMessage)) {
        this.fcltInputItemList = this.fcltInputItemList.filter(item => item.chk !== 'Y');
      }
    },
    markAsChanged(index){
      // 변경된 인덱스를 Set에 추가 (중복 방지)
      this.changedRows.add(index);
    },
    handleSelectChange(inputItemCd, currentIndex) {
       const isDuplicate = this.fcltInputItemList.some(
          (item, index) => item.inputItemCd === inputItemCd && index !== currentIndex
      );
      if(isDuplicate){
        alert("이미 존재하는 투입품목입니다.")

        this.$nextTick(() => {
          // DOM 업데이트 이후 강제로 반영 -> 업데이트 하지않을 시 첫 이벤트만 동작하고, 이후 동작하지 않음.
          this.fcltInputItemList[currentIndex].inputItemCd = '';
        });
      }else{
        this.markAsChanged(currentIndex);
      }
    },
    processChangeRequest(prcsRsn){
      this.modifiableData.prcsRsn = prcsRsn;

      this.modifiableData.inserted = [...this.fcltInputItemList];

      this.$refs.attachFileList.save(true, false).then(atchFileGroupId => {

        this.modifiableData.atchFileGroupId = atchFileGroupId;

        // 승인신청(01) 상태에서 반려(03)당한 후 정보변경신청을 하는 경우엔 정보변경구분코드를 승인신청(01)으로 다시 넣어준다.
        if( this.modifiableData.fcltChgAplySeCd === '01' && this.modifiableData.fcltChgAplySttsCd === '03' ) {
          this.modifiableData.chgAplySeCd = '01';
        } else {
          //변경신청구분코드 -> 02 정보변경신청
          this.modifiableData.chgAplySeCd = '02';
        }

        switch(this.modifiableData.fcltSttsCd){
          case '01' :
            this.modifiableData.oprtngHltBgngYmd  = ''
            this.modifiableData.oprtngHltEndYmd = ''
            this.modifiableData.clsgYmd = ''
            this.modifiableData.oprtngHltClsgRsn  = ''
            break;
          case '02' :
            this.modifiableData.clsgYmd = ''
            break;
          case '03' :
            this.modifiableData.oprtngHltBgngYmd  = ''
            this.modifiableData.oprtngHltEndYmd = ''
            break;
        }

        //변경신청처리상태코드  -> 01 승인대기
        this.modifiableData.chgAplyPrcsSttsCd = '01';
        //상세변경신청처리상태코드 -> 01 승인대기
        this.modifiableData.dtlChgAplyPrcsSttsCd = '01';
        //변경신청유형코드  -> 03 시설
        this.modifiableData.chgAplyTypeCd = '03';

        this.$apiCall.post(API.FCLT_CHG_INFO, this.modifiableData, () => {
              alert("변경 신청되었습니다.");
              this.isEditMode = false;
              this.readFcltInfo();
              this.searchChgHstry();
              this.searchFcltInputItem();
            },
            () => {
              alert('변경 신청에 실패하였습니다.');
            }
        );
      });
    },
    takeAction(item){
      this.chgAplyPrcsSttsCd = item.chgAplyPrcsSttsCd;
      this.chgAplySn = item.chgAplySn;
      this.prcsRsn = item.prcsRsn;
      this.callPopupFcltSplmnt();
    },
    callPopupFcltSplmnt(){
      storeSwitch.on('fcltSplmntPopup');
    },
    callPopupCreateFclt() {

      if(['02-01', '04-01', '05-01'].includes(this.bplcKndCd) && this.fcltCnt > 0){
        switch (this.bplcKndCd){
          case '02-01' :
            alert("매립가스는 최대 하나의 시설만 등록가능합니다.")
            break;
          case '04-01' :
            alert("바이오가스는 최대 하나의 시설만 등록가능합니다.")
            break;
          case '05-01' :
            alert("합성가스는 최대 하나의 시설만 등록가능합니다.")
            break;
        }
      }else{
        storeSwitch.on('createFcltPopup');
      }
    },
    callPopupFcltChgHstry() {
      storeSwitch.on('fcltChgHstryPopup');
    },
    callPopupFcltChgAplyInfo(item) {
      this.chgAplySn  = item.chgAplySn;
      storeSwitch.on('fcltChgAplyInfoPopup');
    },
    callPopupFcltChgAply(){
      if (!this.validateFcltItem()) return;
      storeSwitch.on(this.chgAplyPopId);
    },
    chgHstryGridReload(){
      this.searchChgHstry();
    },
    filterInputItemList() {
      switch(this.bplcKndCd) {
        case '01-01':
          this.inputItemCdOption.push(...this.inputItemCdList
              .filter(item => item.frstRefVl === 'WT')
              .map(item => ({
                value: String(item.dtlCd),
                text: String(item.dtlCdNm)
              }))
          )
          break;
        case '01-02':
          this.inputItemCdOption.push(...this.inputItemCdList
              .filter(item => item.frstRefVl === 'SRF')
              .map(item => ({
                value: String(item.dtlCd),
                text: String(item.dtlCdNm)
              }))
          )
          break;
        case '04-01':
          this.inputItemCdOption.push(...this.inputItemCdList
              .filter(item => item.frstRefVl === 'BIOWT')
              .map(item => ({
                value: String(item.dtlCd),
                text: String(item.dtlCdNm)
              }))
          )
          break;
        default :
          this.inputItemCdOption.push(...this.inputItemCdList
              .map(item => ({
                value: String(item.dtlCd),
                text: String(item.dtlCdNm)
              }))
          )
          break;
      }
    },
    toggleCheckbox(index){
      this.fcltInputItemList[index].chk = this.fcltInputItemList[index].chk === 'Y' ? 'N' : 'Y';
    },
    formatNumber(value) {
      if (!value) return '';
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    callPopupOprtngHltYmd(){
      this.isOn = true;
    },
    hide(){
      this.isOn = false;
    },
    complete(){
      this.isOn = false;
      const date = new Date(this.modifiableData.oprtngHltBgngYmd); // 문자열을 Date 객체로 변환
      date.setDate(date.getDate() + this.oprtngHltYmd); // 날짜 연산
      this.modifiableData.oprtngHltEndYmd = date.toISOString().split('T')[0]; // YYYY-MM-DD 포맷으로 반환
    },
  }
}
</script>

<style scoped>
.selected {
  background-color: #f0f8ff; /* 선택 시 배경색 */
}
.subtitle-note {
  font-size: 15px;      /* 폰트 크기 설정 */
  font-weight: normal;  /* 굵기 설정 (기본 또는 normal) */
  color: #888888;       /* 원하는 색상 코드로 변경 */
  margin-left: 10px;
}
.no-data-message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  margin: 20px 0px;
  border-radius: 10px;
  background-color: #f7f7f7;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 350px;
}
.checkbox-cell {
  cursor: pointer;
}
</style>