<template>
  <div v-if="isVisible" class="modal visible">
    <div class="popup_box" tabindex="0">
      <h1 class="popup_title">간편 인증</h1>
      <div class="popup_cont">
        <div class="desc_box progress_box">
          <i class="icon"><img src="@/assets/images/common/ico_noti.png" alt="이미지"></i>
          <div class="progress">
            <div>본인인증이 필요합니다. 간편인증을 완료해주세요</div>
          </div>
        </div>
        <div class="btn_area center">
          <br-button :label="label" format="primary" size="md" @click="simpleAuth" :disabled="disabled"/>
        </div>
      </div>
      <button type="button" class="modal_close" @click="hide">팝업 닫기</button>
    </div>
  </div>
</template>
<script>
import {v4 as uuidv4} from "uuid";
import {storeSwitch} from "@/js/store/store-switch";

const ROUTE = {
  HOME: "/home",
};

const API = {
  LOGIN_USER: "/auth/login-user"
}

export default {
  props: {
    isPopup : {type : Boolean, default : true},
    hasLogin: {type: Boolean, default: false},
  },
  emits: [ 'callback', 'fail' ],
  data() {
    return {
      isVisible: false,
      label : "간편인증 하러가기",
      disabled : false,
      uuid : uuidv4(),
      retType : "callback",
      isReToMain: false,
      result : {}
    };
  },
  created() {
    storeSwitch.setBulb('SimpleAuthModal', this);
  },
  mounted() {
    window.simpleAuthResult = this.simpleAuthResult;
    this.init();
  },
  methods : {
    simpleAuth() {
      window.eziok_std_process(`/api/eziok/request?retType=${this.retType}&code=${this.uuid}`, "WB", "simpleAuthResult");
      this.process();
    },
    process() {
      this.label = "인증을 완료해주세요";
      this.disabled = true;
    },
    init() {
      this.label = "간편인증 하러가기"
      this.disabled = false;
    },
    simpleAuthResult(value) {
      const data = JSON.parse(value);
      const code = data.code;
      const message = data.message;

      if (code === 0 && this.hasLogin) {
        this.label = "인증이 완료되었습니다.";
        this.disabled = true;
        this.result = data.result;
        this.loginUser();
        return;
      }

      if (code === 0) {
        this.isReToMain = false;
        this.$emit("callback", data.result);
        this.init();
        this.hide();
        return;
      }

      console.error(code + " " + message);
      alert("간편인증에 실패하였습니다.");

      this.$emit("fail", data);
      this.init();
    },
    async show(param) {
      this.init();
      this.popupShow(param);

      if (this.isPopup === false) {
        this.simpleAuth();
      }
    },
    popupShow(param) {
      if (this.isPopup === false) return;

      document.documentElement.style.overflow = 'hidden';

      if (param && param.isReToMain !== undefined) {
        this.isReToMain = param.isReToMain;
      }

      this.isVisible = true;
    },
    hide() {
      if (this.isPopup === false) return;

      this.isVisible = false;
      document.documentElement.style.overflow = '';

      // isReToMain 값이 true일 경우 메인 화면으로 이동
      if (this.isReToMain) {
        this.$router.push(ROUTE.HOME).catch(() => {});
      }
    },
    loginUser() {
      this.$apiCall.post(API.LOGIN_USER, { "provider": this.result.provider }, this.loginUserSuccess, this.loginUserFail);
    },
    loginUserSuccess(data) {
      this.$emit("callback", { ...this.result, ...data.result });
    },
    loginUserFail(error) {
      alert(error.message);
      this.$emit("fail", this.result);
    }
  }
}
</script>
