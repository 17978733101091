<template>

  <h2 class="title1 mt_0">회수통계 조사</h2>
  <div class="board_write mb_25" v-if="isMounted">
    <table>
        <caption>회수통계 조사</caption>
        <colgroup>
            <col style="width:18%">
            <col style="width:32%">
            <col style="width:18%">
            <col style="width:32%">
        </colgroup>
        <tbody>
            <tr>
                <th scope="row">조사년도</th>
                <td>{{exmnParam.exmnYr}}</td>
                <th scope="row">조사기간</th>
                <td>{{ exmnParam.exmnBgngYmd }} ~ {{ exmnParam.exmnEndYmd }}</td>
            </tr>
        </tbody>
    </table>
  </div>  
  
  <div class="info_box" v-if="showMsg">
      <p>{{ msg }}</p>
  </div>

  <div v-show="bplcKndBtnDivShow">
    <div class="" v-for="(row, rowIndex) in bplcBtntList" :key="rowIndex">
      <div class="my_office_tab">
        <a v-for="(item, index) in row" :key="item.id" class="bplcBtnt-item" :class="{ active: bplcBtnRowIndex === rowIndex && bplcBtnIndex === index}" @click="bplcBtnIndex = index; bplcBtnRowIndex = rowIndex; readExmnTrgtFcltInfo(item);" href="javascript:void(0);" >{{item.bplcNm}}</a>
      </div>    
    </div>
  </div>

  <div v-show="fcltDivShow" style="margin-top:10px;">
    <div class="tab_area">
      <ul class="tab" style="justify-content:left;">
        <li v-for="(fclt, index) in fcltList" :key="index" @click="fcltTabIndex = index; fncFcltTabClick(fclt);" :class="{ active: fcltTabIndex === index }">
          <button type="button" class="btn_tab">{{ !fclt.fcltNm  ? '시설' : fclt.fcltNm }}</button>
        </li>
      </ul>

      <component :is="workSheet" :parentData="parentData" v-if="workSheetIf"></component>

    </div>
  </div>

</template>

<script>

import { storeSwitch } from "@/js/store/store-switch";

import SrfMnftrRslt from "@/views/prfmnc-rpt/components/workSheet/SrfMnftrRslt.vue"; //SRF 제조
import SrfMnftrRsltSrf from "@/views/prfmnc-rpt/components/workSheet/SrfMnftrRsltSrf.vue"; //SRF
import SrfMnftrRsltBio from "@/views/prfmnc-rpt/components/workSheet/SrfMnftrRsltBio.vue"; //Bio-SRF


import SrfIncmRslt from "@/views/prfmnc-rpt/components/workSheet//SrfIncmRslt.vue"; //SRF 수입
import SrfUseRslt from "@/views/prfmnc-rpt/components/workSheet//SrfUseRslt.vue"; //SRF 사용
import FireRslt from "@/views/prfmnc-rpt/components/workSheet//FireRslt.vue";   //소각열
import BioGasRslt from "@/views/prfmnc-rpt/components/workSheet//BioGasRslt.vue"; //바이오가스
import FillGasRslt from "@/views/prfmnc-rpt/components/workSheet//FillGasRslt.vue"; //매립가스
import PrlsslRslt from "@/views/prfmnc-rpt/components/workSheet//PrlsslRslt.vue"; //열분해유
import RgnrtnFlolRslt from "@/views/prfmnc-rpt/components/workSheet//RgnrtnFlolRslt.vue"; //재생연료유
import RfndflRslt from "@/views/prfmnc-rpt/components/workSheet//RfndflRslt.vue"; //정제연료유
import CemntRstklnAsstFuelRslt from "@/views/prfmnc-rpt/components/workSheet//CemntRstklnAsstFuelRslt.vue"; //시멘트소성로
import OrgnicSldgFuelRslt from "@/views/prfmnc-rpt/components/workSheet//OrgnicSldgFuelRslt.vue"; //유기성 오니
import SynthGasRslt from "@/views/prfmnc-rpt/components/workSheet//SynthGasRslt.vue"; //합성가스


const API = {
  RTRVLSTATS_EXMN_YN: "/rtrvlStatsExmn/readRtrvlStatsExmnYn", 
  RTRVLSTATS_EXMN: "/rtrvlStatsExmn",//회수통계조사 조회
  RTRVLSTATS_EXMN_TRGT_BPLC: "/rtrvlStatsExmn/readRtrvlStatsExmnTrgtBplc", //회수통계 조사대상 조회(사업장)
  RTRVLSTATS_EXMN_TRGT_FCLT: "/rtrvlStatsExmn/readRtrvlStatsExmnTrgtFclt", //회수통계 조사대상 조회(시설)
}

export default {
  components: {SrfMnftrRslt, SrfMnftrRsltSrf, SrfMnftrRsltBio, SrfIncmRslt, SrfUseRslt, FireRslt, BioGasRslt, FillGasRslt, PrlsslRslt, RgnrtnFlolRslt, RfndflRslt, CemntRstklnAsstFuelRslt, OrgnicSldgFuelRslt, SynthGasRslt},
  data() {
    return {
      isMounted: false,
      showMsg: false,
      mag: '',
      exmnParam: {
        exmnYr: '',
        exmnBgngYmd: '',
        exmnEndYmd: '',
      },
      rtrvlStatsSn: '',
      bzentyId: '',
      workSheet: '',
      parentData: {},
      bplcBtnIndex: 0,
      bplcBtnRowIndex: 0,
      bplcList: [],
      fcltList: [],
      fcltTabIndex: 0,
      workSheetIf: false,
    };
  },
  computed: {
    bplcBtntList() {
      const size = 6;
      let array = [];
      for (let i = 0; i < this.bplcList.length; i += size) {
        array.push(this.bplcList.slice(i, i + size));
      }
      return array;
    },
  },
  watch: {},
  created() {
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      //회수통계조사 있는지 조회
      this.readRtrvlStatsExmnYn();
    },
    
    /**회수통계가 있는지 여부 조회 있으면 rtrvlStatsSn, bzentyId 받아와서 나머지 진행  */
    readRtrvlStatsExmnYn() {
      this.$apiCall.get(API.RTRVLSTATS_EXMN_YN, {instYn: 'N'}, this.readRtrvlStatsExmnYnExmnSuccess, this.readRtrvlStatsExmnYnExmnError);
    },

    /**회수통계조사 조회 성공 */
    readRtrvlStatsExmnYnExmnSuccess(data){
      if (data.result) {
        if(data.result.code == "00"){
          this.isMounted = true;
          this.rtrvlStatsSn = data.result.rtrvlStatsSn;
          this.bzentyId = data.result.bzentyId;
          this.readRtrvlStatsExmn();
        } else {
          this.showMsg = true;
          this.msg = data.result.msg;
        }
      }
    },

    /**회수통계조사 조회 실패 */
    readRtrvlStatsExmnYnExmnError(){
      alert("데이터를 가져오는 중 오류가 발생했습니다.");
    },

    /**회수통계조사 조회 */
    readRtrvlStatsExmn(){
      //this.rtrvlStatsSn='2024000001';
      this.$apiCall.get(API.RTRVLSTATS_EXMN, {rtrvlStatsSn: this.rtrvlStatsSn}, this.readRtrvlStatsExmnSuccess, this.readRtrvlStatsExmnError);
    },

    /**회수통계조사 조회 성공 */
    readRtrvlStatsExmnSuccess(data){
      if (data.result) {
        this.exmnParam = data.result;

        this.readExmnTrgtBplcInfo();    
      }
    },

    /**회수통계조사 조회 실패 */
    readRtrvlStatsExmnError(){
      alert("데이터를 가져오는 중 오류가 발생했습니다.");
    },

    /**조사대상 그리드 더블클릭 */
    readExmnTrgtBplcInfo() {
      this.$apiCall.get(API.RTRVLSTATS_EXMN_TRGT_BPLC, {rtrvlStatsSn: this.rtrvlStatsSn, bzentyId: this.bzentyId, instYn: 'N'}, this.readExmnTrgtBplcInfoSuccess, this.readExmnTrgtBplcInfoError);
    },

    /**조사대상 그리드 더블클릭 성공 */
    readExmnTrgtBplcInfoSuccess(data){
      if (data.result.list.length > 0) {
        this.bplcList = data.result.list; /**사업장 버튼 생성 */
        this.bplcKndBtnDivShow = true;
        this.fcltDivShow = false;
        this.workSheet = "";
        this.workSheetIf= false;

        if(data.result.list[0].bzentyId && data.result.list[0].bplcId){
          this.readExmnTrgtFcltInfo(data.result.list[0]); /**첫번째 사업장 클릭 */
        }
      }
    },

    /**조사대상 그리드 더블클릭 실패 */
    readExmnTrgtBplcInfoError(){
      storeSwitch.alert('warning', "데이터를 가져오는 중 오류가 발생했습니다.");
    },

    /**사업장 버튼 클릭 이벤트 */
    readExmnTrgtFcltInfo(data){
      if(data){
        if(data.bzentyId && data.bplcId){
          this.fcltDivShow = false;
          this.workSheet = "";
          this.workSheetIf= false;
          this.fcltTabIndex = 0;
          this.fcltList= [];

          this.$apiCall.get(
            API.RTRVLSTATS_EXMN_TRGT_FCLT, 
            {rtrvlStatsSn: this.rtrvlStatsSn, bzentyId: data.bzentyId, bplcId: data.bplcId, bplcKndCd: data.bplcKndCd}, 
            this.readExmnTrgtFcltInfoSuccess, 
            this.readExmnTrgtFcltInfoError
          );
        }
      }
    },
    /**사업장 버튼 클릭 이벤트 성공 */
    readExmnTrgtFcltInfoSuccess(data){
      if (data.result.list.length > 0) {
        this.fcltDivShow = true;
        this.fcltList = data.result.list; //시설 탭 생성

        //첫번째 시설 탭 클릭
         this.parentData={
           rtrvlStatsSn: this.rtrvlStatsSn, 
           bzentyId: data.result.list[0].bzentyId, 
           bplcId: data.result.list[0].bplcId, 
           fcltSqno: data.result.list[0].fcltSqno,
           bplcKndCd: data.result.list[0].bplcKndCd,
           mnftrPrdctKndCd: data.result.list[0].mnftrPrdctKndCd,
           updateYn: 'Y',
         };
        
         this.workSheet = data.result.list[0].tabNm;
         this.workSheetIf= true;
      }
    },
    /**사업장 버튼 클릭 이벤트 실패 */
    readExmnTrgtFcltInfoError(){
      storeSwitch.alert('warning', "데이터를 가져오는 중 오류가 발생했습니다.");
    },

    /**시설탭 클릭 이벤트 */
    fncFcltTabClick(data){
      this.workSheet = "";
      this.workSheetIf= false;
      if(data){
        this.parentData={
            rtrvlStatsSn: this.rtrvlStatsSn, 
            bzentyId: data.bzentyId, 
            bplcId: data.bplcId, 
            fcltSqno: data.fcltSqno,
            bplcKndCd: data.bplcKndCd,
            mnftrPrdctKndCd: data.mnftrPrdctKndCd,
            updateYn: 'Y',
        };

        this.workSheet = data.tabNm;
        this.$nextTick(() => { 
          this.workSheetIf = true; // 다시 데이터 변경 
        });
      }
    },
  }
}
</script>

<style scoped>
.bplcBtnt-item {
  width: calc(100% / 6); /* 한 행에 6개의 체크박스를 표시 */
}
</style>