<template>
  <template v-if="isAdmin">
    <div class="desc_box progress_box">
      <i class="icon"><img src="@/assets/images/common/ico_noti.png" alt="이미지"></i>
      <div class="progress">
        <div><span>관리자는 조회가 불가능한 화면입니다. 관리자페이지에서 로그인 후 이용해주세요.</span></div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="desc_box">
      <i class="icon"><img src="@/assets/images/common/ico_desc.png" alt="이미지"></i>
      <div class="desc">
        <p>폐자원에너지 종합정보관리시스템에 등록되어 있는 사업장 정보입니다. <br>
          업체 및 사업장, 시설 등의 정보를 수정 및 확인하실 수 있습니다.</p>
      </div>
    </div>
    <div class="tab_area">
      <ul class="tab">
        <li aria-controls="dep1Tabpanel01" :class="{active: tabIndex === 0}"><button type="button" class="btn_tab" @click="tabClickEvent(0)">업체</button></li>
        <li aria-controls="dep1Tabpanel02" :class="{active: tabIndex === 1}"><button type="button" class="btn_tab" @click="tabClickEvent(1)">사업장</button></li>
        <li v-show="!isIncome" aria-controls="dep1Tabpanel03" :class="{active: tabIndex === 2}"><button type="button" class="btn_tab" @click="tabClickEvent(2)">시설</button></li>
      </ul>
      <div v-show="tabIndex === 0">
        <bzentyInfo ref="bzentyInfoComp"
                    :menu="this.menu"
                    @startInitialize="allInitialize"
        />
      </div>
      <div v-show="tabIndex === 1">
        <bplcInfo  ref="bplcInfoComp"
                   :menu="this.menu"
                   :bzentyId="this.bzentyId"
                   :bzentyAprvYn="this.bzentyAprvYn"
                   @isIncome = "fcltTabControl"
        />
      </div>
      <div v-show="tabIndex === 2">
        <fcltInfo  ref="fcltInfoComp"
                   :menu="this.menu"
                   :bzentyAprvYn="this.bzentyAprvYn"

        />
      </div>
    </div>
  </template>
</template>

<script>
import BzentyInfo from "@/views/my-office/components/BizFacilityBzenty.vue";
import BplcInfo from "@/views/my-office/components/BizFacilityBplc.vue";
import FcltInfo from "@/views/my-office/components/BizFacilityFclt.vue";
import { storeSession } from "@/js/store/store-session";

export default {
  components: {BzentyInfo, BplcInfo, FcltInfo},
  data() {
    return {
      sysAuthrtCd: storeSession.getMemberInfo().sysAuthrtCd,
      sessionInfo: storeSession.getSession(),
      menu: this.$menusTotal.getRouteMenu(this.$route.path),
      bzentyId: 0,
      bzentyAprvYn: 'N',
      tabIndex: 0,
      isIncome: false,
    };
  },
  computed: {
    isAdmin(){
        return '02-01' !== this.sysAuthrtCd;
      },
  },
  watch: {
  },
  created() {
  },
  beforeMount() {
  },
  mounted() {
    this.tabIndex = this.$route.query.tabIndex ? Number(this.$route.query.tabIndex) : 0;
  },
  beforeUnmount() {
  },
  methods: {
    /** 업체, 사업장, 시설 탭 정보 조회 */
    async allInitialize(data){
      this.bzentyAprvYn = data.bzentyAprvYn;
      this.bzentyId = data.bzentyId;
      await this.$refs.bzentyInfoComp.initialize(data.bzentyId);
      await this.$refs.bplcInfoComp.initialize();
      await this.$refs.fcltInfoComp.initialize();
    },
    /** 탭 클릭 이벤트 (탭 클릭 시 이전 탭 정보 초기화) */
    async tabClickEvent(tabIndex){
      switch (tabIndex){
        case 0 :
          this.tabIndex = 0;
          await this.$refs.bzentyInfoComp.reset();
          await this.$refs.bzentyInfoComp.readBzentyInfo();
          break;
        case 1 :
          this.tabIndex = 1;
          await this.$refs.bplcInfoComp.reset();
          await this.$refs.bplcInfoComp.searchMyMngBplc();
          break;
        case 2 :
          this.tabIndex = 2;
          await this.$refs.fcltInfoComp.reset();
          await this.$refs.fcltInfoComp.searchMyMngFclt();
          break;
      }
    },
    fcltTabControl(data){
      this.isIncome = data;
    },
  }
}
</script>

<style scoped>

</style>