<template>
  <h2 class="title1 mt_0">관련 법률 안내</h2>
  <p class="law_txt">한국환경공단은 <strong>『고형연료제품의 품질검사 관련 법률』</strong>에 근거하여 <span>폐자원에너지 종합정보관리시스템을 운영합니다.</span></p>
  <div class="law_box">
    <ul>
      <li>「자원의 절약과 재활용촉진에 관한 법률」제25조의5, 제25조의6 <a href="http://www.law.go.kr/법령/자원의%20절약과%20재활용촉진에%20관한%20법률" target="_blank" title="새창열림" class="btn sm">관련법률 상세보기</a></li>
      <li>「자원의 절약과 재활용촉진에 관한 법률」시행령 <a href="http://www.law.go.kr/법령/자원의%20절약과%20재활용촉진에%20관한%20법률%20시행령" target="_blank" title="새창열림" class="btn sm">관련법률 상세보기</a></li>
      <li>「자원의 절약과 재활용촉진에 관한 법률」시행규칙 제20조의2, 제20조의3, 제20조의4, 제20조의5, 제20조의6 <a href="http://www.law.go.kr/법령/자원의%20절약과%20재활용촉진에%20관한%20법률%20시행규칙" target="_blank" title="새창열림" class="btn sm">관련법률 상세보기</a></li>
      <li>「고형연료제품 품질 시험·분석방법」 <a href="http://www.law.go.kr/행정규칙/고형연료제품품질시험·분석방법" target="_blank" title="새창열림" class="btn sm">관련법률 상세보기</a></li>
      <li>「고형연료제품의 품질검사 절차 및 방법 등에 관한 고시」 <a href="http://www.law.go.kr/행정규칙/고형연료제품의품질검사절차및방법등에관한고시" target="_blank" title="새창열림" class="btn sm">관련법률 상세보기</a></li>
    </ul>
  </div>

  <h2 class="title1">고형연료제품 품질검사 신청 관련서류</h2>
  <div class="tbl_wrap">
    <table>
      <caption>고형연료제품 품질검사 신청 관련서류_NO, 구분, 양식으로 구성</caption>
      <colgroup>
        <col style="width:10%">
        <col style="width:75%">
        <col style="width:15%">
      </colgroup>
      <thead>
      <tr>
        <th scope="col">NO</th>
        <th scope="col">구분</th>
        <th scope="col">양식</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>1</td>
        <td class="txt_left">재활용허가증 또는 폐기물처리시설신고필증 사본</td>
        <td></td>
      </tr>
      <tr>
        <td>2</td>
        <td class="txt_left">사업자등록증 사본</td>
        <td></td>
      </tr>
      <tr>
        <td>3</td>
        <td class="txt_left">고형연료제품 품질검사 신청서</td>
        <td>
          <br-static-file-download atchFileVldNo="c880bd07-5002-402c-9f04-0cb6859268cd" />
        </td>
      </tr>
      <tr>
        <td>4</td>
        <td class="txt_left">고형연료제품 제조시설 일반 명세서</td>
        <td>
          <br-static-file-download atchFileVldNo="b57f3fea-0922-4da2-8a4c-f37492cc8963" />
        </td>
      </tr>
      <tr>
        <td>5</td>
        <td class="txt_left">통장 사본 1부</td>
        <td></td>
      </tr>
      </tbody>
    </table>
  </div>

  <h2 class="title1">품질검사 절차 안내</h2>
  <div class="img_box_area mt_0">
    <div class="img_box top_line">폐자원에너지센터 - 최초검사</div>
    <img src="@/assets/images/sub/qualityInspection-1.png" alt="폐자원에너지센터 - 최초검사 : 1.제조자 신청서(제조 신고 30일 전) 2.접수(수수료 입금) 또는 검토(보완:3일 이내, 반려: 보완 불가 시) 3.시료 채취 계획 수립(시료 채취 계획 보고서 작성 후 5일 이내 통보) 4.시료 채취 및 분석(고형 연료 제품, 품질 시험/분석 방법) 5.품질 검사 결과서 발급(처리 기한 40일) 6.제조자 통보(단위 로트 최대 규모 SRF:1,500톤 / BIO-SRF:2,500톤) 및 지방 자치 단체 통보.">
  </div>
  <div class="img_box_area">
    <div class="img_box top_line">
      <span class="title">폐자원에너지센터 - 확인검사(정기 검사 시 해당 분기 확인 검사 시료채취 및 분석 결과 활용)</span>
      <p>검사 주기 조정(- 3년간 품질 기준 위반 사실이 없는 경우 : 매 반기 마다 1회, - 6년간 품질 기준 위반 사실이 없는 경우 : 매년 1회)</p>
    </div>
    <img src="@/assets/images/sub/qualityInspection-2.png" alt="폐자원에너지센터 - 확인검사(정기 검사 시 해당 분기 확인 검사 시료채취 및 분석 결과 활용, 검사 주기 조정 -3년간 품질 기준 위반사실이 없는 경우 : 매 반기 마다 1회. -6년간 품질 기준 위반사실이 없는 경우 : 매년 1회. ) :  1.고형 연료 제품 제조/사용/수입자 대상 2.시료 채취 계획 수립(검사 예정일 3일 전까지 통보(검사 목적을 달성 할 수 없다고 판단하는 경우 검사 일에 제지 가능)) 3.시료 채취 및 분석(고형 연료 제품, 품질 시험/분석 방법) 4.지방 자치 단체,지방 환경 관서(부적합시 통보).">
  </div>

  <h2 class="title1"> 고형연료제품의 품질표시 검사</h2>
  <div class="law_box">
    <ul>
      <li>「자원의 절약과 재활용 촉진에 관한 법률」제25조의6 <a href="https://www.law.go.kr/LSW//lsInfoP.do?lsId=000155&ancYnChk=0#0000" target="_blank" title="새창열림" class="btn sm">관련법률 상세보기</a></li>
    </ul>
    <p>수입 또는 제조하는 고형연료제품에 대하여 시험기관으로부터 품질표시 검사를 받아 항목별 품질을 표시하여야 함(반기 1회 이상)<br>
      ※ 품질표시 시험기관 : 폐자원에너지센터, 한국기계연구원, 한국에너지기술연구원, 한국산업기술시험원 등</p>
  </div>
  <div class="tbl_wrap mt_20">
    <table>
      <caption>고형연료제품 품질표시_구분, 품질표시 항목, 비고로 구성</caption>
      <colgroup>
        <col style="width:20%">
        <col style="width:60%">
        <col style="width:20%">
      </colgroup>
      <thead>
      <tr>
        <th scope="col">구분</th>
        <th scope="col">품질표시 항목</th>
        <th scope="col">비고</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td rowspan="2">필수표시 항목</td>
        <td class="txt_left">일반 항목 : 모양 및 크기, 수분, 회분, 저위발열량, 바이오매스 함량(SRF 제외)</td>
        <td rowspan="2">19개</td>
      </tr>
      <tr>
        <td class="txt_left">화학물질 함유량 : 수은, 카드뮴, 납, 비소, 크롬, 안티몬, 코발트, 구리, 망간, 니켈, 탈륨, 바나듐, 황, 염소</td>
      </tr>
      </tbody>
    </table>
  </div>

  <h3 class="title2">고형연료제품 품질표시검사 관련서류</h3>
  <div class="tbl_wrap">
    <table>
      <caption>고형연료제품 품질표시검사 관련서류_NO, 구분, 양식으로 구성</caption>
      <colgroup>
        <col style="width:10%">
        <col style="width:75%">
        <col style="width:15%">
      </colgroup>
      <thead>
      <tr>
        <th scope="col">NO</th>
        <th scope="col">구분</th>
        <th scope="col">양식</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>1</td>
        <td class="txt_left">고형연료제품 품질표시 방법(고형연료제픔 품질표시 표지판)</td>
        <td></td>
      </tr>
      <tr>
        <td>2</td>
        <td class="txt_left">(수입/제조) 고형연료제품 품질표시 검사신청서</td>
        <td>
          <br-static-file-download atchFileVldNo="93de8290-3d7b-45e0-a6da-38d7def81598" />
        </td>
      </tr>
      <tr>
        <td>3</td>
        <td class="txt_left">고형연료제품 품질표시 검사 실적</td>
        <td></td>
      </tr>
      </tbody>
    </table>
  </div>

  <h3 class="title2">품질표시검사 절차 안내</h3>
  <div class="img_box_area mt_0">
    <div class="img_box top_line">
      <span class="title">폐자원에너지센터 및 유관 기관 - 최초·확인 검사</span>
      <p>품질검사 결과의 활용(- 최초 신고·변경신고를 위해 품질검사 결과를 받은 수입·제조자는 최초 1회에 한하여 품질검사 결과를 품질표시에 활용가능(품질표시의 효율성을 위해
        폐자원에너지센터에서 품질검사와 품질표시검사 병행 수행 ≫ 기간단축, 비용절감))</p>
    </div>
    <img src="@/assets/images/sub/qualityInspection-3.png" alt="폐자원에너지센터 및 유관 기관 - 최초 · 확인검사 : 1.수입/제조자 신청서 2.접수(수수료 입금) 또는 검토(보완:3일 이내, 반려: 보완 불가 시) 3.시료 채취 계획 수립(시료 채취 계획 보고서 작성 후 5일 이내 통보) 4.시료 채취 및 분석(고형 연료 제품, 품질 시험/분석 방법) 5.품질 검사 결과서 발급(처리 기한 30일) 6.수입/제조자 통보(단위 로트 최대 규모 SRF:1,500톤 / BIO-SRF:2,500톤). *품질 검사 결과의 활용 -최초 신고·변경 신고를 위해 품질 검사 결과를 받은 수입·제조자는 최초 1회에 한하여 품질 검사 결과를 품질 표시에 활용 가능 (품질 표시의 효율성을 위해 폐자원에너지센터에서 품질 검사와 품질 표시 방향 수행 > 기간 단축, 비용 절감).">
  </div>

  <h3 class="title2">품질표시검사 전문기관</h3>
  <div class="tbl_wrap">
    <table>
      <caption>고형연료제품 전문기관_구분, 담당부서, 연락처, 주소로 구성</caption>
      <colgroup>
        <col style="width:22%">
        <col style="width:22%">
        <col style="width:20%">
        <col style="width:36%">
      </colgroup>
      <thead>
      <tr>
        <th scope="col">구분</th>
        <th scope="col">담당부서</th>
        <th scope="col">연락처</th>
        <th scope="col">주소</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>폐자원에너지센터</td>
        <td>폐자원에너지부</td>
        <td>032-590-5411</td>
        <td class="txt_left">인천광역시 서구 환경로 42</td>
      </tr>
      <tr>
        <td>한국기계연구원</td>
        <td>환경기계시스템연구실</td>
        <td>042-868-7363</td>
        <td class="txt_left">대전광역시 유성구 가정북로 156</td>
      </tr>
      <tr>
        <td>한국산업기술시험원</td>
        <td>환경평가센터</td>
        <td>02-860-1696</td>
        <td class="txt_left">서울특별시 구로구 디지털로26길 87</td>
      </tr>
      <tr>
        <td>한국에너지기술연구원</td>
        <td>품질ㆍ시험인증실</td>
        <td>042-860-3441</td>
        <td class="txt_left">대전광역시 유성구 가정로 152</td>
      </tr>
      <tr>
        <td>(재)FITI시험연구원</td>
        <td>산업환경사업팀</td>
        <td>043-711-8838<br>
          043-711-8892</td>
        <td class="txt_left">충청북도 청주시 청원구 오창읍 양청3길 21</td>
      </tr>
      <tr>
        <td>(주)대덕분석기술연구원</td>
        <td>바이오에너지팀</td>
        <td>070-4239-5407</td>
        <td class="txt_left">대전광역시 유성구 대학로 291</td>
      </tr>
      <tr>
        <td>(재)한국화학융합시험연구원</td>
        <td>건설화학환경연구소</td>
        <td>02-2092-3821</td>
        <td class="txt_left">경기도 과천시 교육원로 98</td>
      </tr>
      </tbody>
    </table>
  </div>

  <h2 class="title1">수수료 안내 <span class="s_title">품질검사 / 품질표시검사 / 품질표시 적정성검사</span></h2>
  <div class="tbl_wrap">
    <table>
      <caption>수수료 안내_구분, 부가세 포함으로 구성</caption>
      <colgroup>
        <col style="width:34%">
        <col style="width:33%">
        <col style="width:33%">
      </colgroup>
      <thead>
      <tr>
        <th scope="col" colspan="2">구분</th>
        <th scope="col">부가세 포함</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th scope="row" rowspan="2">품질검사(최초검사)</th>
        <td>SRF</td>
        <td class="txt_right">2,283,600원</td>
      </tr>
      <tr>
        <td>BIO-SRF</td>
        <td class="txt_right">2,530,000원</td>
      </tr>
      <tr>
        <th scope="row">품질표시검사</th>
        <td colspan="2">품질표시 기관별 수수료기준</td>
      </tr>
      <tr>
        <th scope="row">품질검사(확인검사)</th>
        <td colspan="2">없음</td>
      </tr>
      <tr>
        <th scope="row">품질표시 적정성검사</th>
        <td colspan="2">없음</td>
      </tr>
      </tbody>
    </table>
  </div>
  <dl class="back_info">
    <dt>입금안내</dt>
    <dd><img src="@/assets/images/sub/img_nonghyup.png" alt="농협"><span><strong>계좌번호 : </strong>317-1700-1700-41</span><span><strong>예금주 : </strong>한국환경공단</span></dd>
  </dl>
  <div class="info_box mt_40">
    <strong class="info_title">문의 안내</strong>
    <ul class="dot_list">
      <li>주소 : 인천광역시 서구 환경로 42(경서동 종합환경연구단지) 한국환경공단 폐자원에너지센터 폐자원에너지부</li>
      <li>전화 : 032-590-5411</li>
      <li>메일 : srf-qa@keco.or.kr</li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {
    download() {

    }
  }
}
</script>

<style scoped>

</style>