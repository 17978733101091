<template>
  <div class="desc_box">
    <i class="icon"><img src="@/assets/images/common/ico_desc.png" alt="이미지"></i>
    <div class="desc">
      <p>고형 폐기물 연료는 비닐, 목재, 종이 등 가연성 물질만을 걸러내어 건조, 성형 과정을 거쳐 생성된 고효율의 고체 연료입니다. <br>코르크 형태로 제작돼 화력발전소 등의 보조연료로 사용됩니다.</p>
    </div>
  </div>

  <div class="title_box">
    <span class="title">제조시설 공정도</span>
    <div class="cont">
      <img src="@/assets/images/sub/srfMakeFacProc.png" alt="원료를 투입하면 선별하여 파·분쇄한 후 비성형으로 혹은 성형기/냉각기를 거쳐 성형으로 만들어집니다.">
      <p class="mt_20">원료를 투입하면 선별하여 파·분쇄한 후 비성형으로 혹은 성형기/냉각기를 거쳐 성형으로 만들어집니다.</p>
    </div>
  </div>

  <div class="title_box">
    <span class="title">사용시설 공정도</span>
    <div class="cont">
      <img src="@/assets/images/sub/srfUseFacProc.png" alt="고형연료를 연소로(소각로)에 넣어 사용하여 폐열보일러로 연결되고 연소가스는 대기오염 방지시설을 거쳐 굴뚝으로 연소됩니다. 폐열보일러는 스팀이 되어 열 교환기를 통해 온수를 공급하고 발전시설에 사용됩니다.">
      <p class="mt_20">고형연료를 연소로(소각로)에 넣어 사용하여 폐열보일러로 연결되고 연소가스는 대기오염 방지시설을 거쳐 굴뚝으로 연소됩니다. <br>
        폐열보일러는 스팀이 되어 열 교환기를 통해 온수를 공급하고 발전시설에 사용됩니다.</p>
    </div>
  </div>

  <h2 class="title1">고형연료제품 사용시설 예시</h2>
  <div class="img_box_area">
    <img src="@/assets/images/sub/srfUseFac_A.png" alt="SRF 사용시설 A 사업장 고형연료 저장소에 모은 고형연료를 연소/소각로에 넣어 선택적 비촉매 환원법(SCNR)으로 질소산화물을 제거하고 반건식 반응탑(SDR)에서 연소과정에서 발생하는 산성가스를 제거 후 소석회와 활성탄을 투입하여 여과집진기를 거쳐 유인송풍기를 통해 대기오염제어장치를 지나 굴뚝으로 배출됩니다.">
    <div class="img_box">
      <span class="title"> SRF 사용시설 A 사업장</span>
      <p>고형연료 저장소에 모은 고형연료를 연소/소각로에 넣어 선택적 비촉매 환원법(SCNR)으로 질소산화물을 제거하고 반건식 반응탑(SDR)에서 연소과정에서 발생하는 산성가스를 제거 후
        소석회와 활성탄을 투입하여 여과집진기를 거쳐 유인송풍기를 통해 대기오염제어장치를 지나 굴뚝으로 배출됩니다.</p>
    </div>
  </div>

  <div class="img_box_area">
    <img src="@/assets/images/sub/srfUseFac_B.png" alt="SRF 사용시설 B 사업장 고형연료 저장소에 모은 고형연료를 연소/소각로에 넣어 선택적 비촉매 환원법(SCNR)으로 질소산화물을 제거하고 반건식 반응탑(SDR)에서 연소과정에서 발생하는 산성가스를 제거 후 소석회와 활성탄을 투입하여 여과집진기를 거쳐 악취제거설비(A/C Tower)를 지나 굴뚝으로 배출됩니다.">
    <div class="img_box">
      <span class="title"> SRF 사용시설 B 사업장</span>
      <p>고형연료 저장소에 모은 고형연료를 연소/소각로에 넣어 선택적 비촉매 환원법(SCNR)으로 질소산화물을 제거하고 반건식 반응탑(SDR)에서 연소과정에서 발생하는 산성가스를 제거 후
        소석회와 활성탄을 투입하여 여과집진기를 거쳐 악취제거설비(A/C Tower)를 지나 굴뚝으로 배출됩니다.</p>
    </div>
  </div>

  <div class="img_box_area">
    <img src="@/assets/images/sub/srfUseFac_C.png" alt="SRF 사용시설 C 사업장 고형연료 저장소에 모은 고형연료를 연소/소각로에 넣어 선택적 비촉매 환원법(SCNR)으로 질소산화물을 제거하고 반건식 반응탑(SDR)에서 연소과정에서 발생하는 산성가스를 제거 후 소석회와 활성탄을 투입하여 여과집진기를 거쳐 선택적 촉매 환원법(SCR)으로 인체에 무해한 질소와 수증기로 환원하여 유인송풍기를 통해 굴뚝으로 배출됩니다.">
    <div class="img_box">
      <span class="title"> SRF 사용시설 C 사업장</span>
      <p>고형연료 저장소에 모은 고형연료를 연소/소각로에 넣어 선택적 비촉매 환원법(SCNR)으로 질소산화물을 제거하고 반건식 반응탑(SDR)에서 연소과정에서 발생하는 산성가스를 제거 후
        소석회와 활성탄을 투입하여 여과집진기를 거쳐 선택적 촉매 환원법(SCR)으로 인체에 무해한 질소와 수증기로 환원하여 유인송풍기를 통해 굴뚝으로 배출됩니다.</p>
    </div>
  </div>

  <div class="img_box_area">
    <img src="@/assets/images/sub/srfUseFac_D.png" alt="SRF 사용시설 D 사업장 고형연료 저장소에 모은 고형연료를 연소/소각로에 넣어 선택적 비촉매 환원법(SCNR)으로 질소산화물을 제거하고 건식 반응탑(DR)에서 연소과정에서 발생하는 산성가스를 제거 후 활성탄을 투입하여 여과집진기를 거쳐 선택적 촉매 환원법(SCR)으로 인체에 무해한 질소와 수증기로 환원하여 유인송풍기를 통해 굴뚝으로 배출됩니다.">
    <div class="img_box">
      <span class="title"> SRF 사용시설 D 사업장</span>
      <p>고형연료 저장소에 모은 고형연료를 연소/소각로에 넣어 선택적 비촉매 환원법(SCNR)으로 질소산화물을 제거하고 건식 반응탑(DR)에서 연소과정에서 발생하는 산성가스를 제거 후
        활성탄을 투입하여 여과집진기를 거쳐 선택적 촉매 환원법(SCR)으로 인체에 무해한 질소와 수증기로 환원하여 유인송풍기를 통해 굴뚝으로 배출됩니다.</p>
    </div>
  </div>

  <div class="img_box_area">
    <img src="@/assets/images/sub/bioSrfUseFac_A.png" alt="BIO-SRF 사용시설 A 사업장 고형연료 저장소에 모은 Bio 고형연료를 연소/소각로에 넣어 선택적 비촉매 환원법(SCNR)으로 질소산화물을 제거하고 반건식 반응탑(SDR)에서 연소과정에서 발생하는 산성가스를 제거 후 소석회와 활성탄을 투입하여 여과집진기를 거쳐 유인송풍기를 통해 굴뚝으로 배출됩니다.">
    <div class="img_box">
      <span class="title"> BIO-SRF 사용시설 A 사업장</span>
      <p>고형연료 저장소에 모은 Bio 고형연료를 연소/소각로에 넣어 선택적 비촉매 환원법(SCNR)으로 질소산화물을 제거하고 반건식 반응탑(SDR)에서 연소과정에서 발생하는 산성가스를 제거 후
        소석회와 활성탄을 투입하여 여과집진기를 거쳐 유인송풍기를 통해 굴뚝으로 배출됩니다.</p>
    </div>
  </div>

  <div class="img_box_area">
    <img src="@/assets/images/sub/wtFac_A.png" alt="폐기물 A 사업장 고형연료 저장소에 모은 폐기물을 연소/소각로에 넣어 선택적 비촉매 환원법(SCNR)으로 질소산화물을 제거하고 반건식 반응탑(SDR)과 건식 반응탑(DR)에서 연소과정에서 발생하는 산성가스를 제거 후 소석회와 활성탄을 투입하여 여과집진기를 거쳐 유인송풍기를 통해 굴뚝으로 배출됩니다.">
    <div class="img_box">
      <span class="title"> 폐기물 A 사업장</span>
      <p>고형연료 저장소에 모은 폐기물을 연소/소각로에 넣어 선택적 비촉매 환원법(SCNR)으로 질소산화물을 제거하고 반건식 반응탑(SDR)과 건식 반응탑(DR)에서 연소과정에서 발생하는 산성가스를 제거 후
        소석회와 활성탄을 투입하여 여과집진기를 거쳐 유인송풍기를 통해 굴뚝으로 배출됩니다.</p>
    </div>
  </div>

  <div class="img_box_area">
    <img src="@/assets/images/sub/wtFac_B.png" alt="폐기물 B 사업장 고형연료 저장소에 모은 폐기물을 Rotary Kiln을 통해 연소/소각로에 넣어 선택적 비촉매 환원법(SCNR)으로 질소산화물을 제거하고 반건식 반응탑(SDR)에서 연소과정에서 발생하는 산성가스를 제거 후 소석회와 활성탄을 투입하여 여과집진기를 거쳐 유인송풍기를 통해 굴뚝으로 배출됩니다.">
    <div class="img_box">
      <span class="title"> 폐기물 B 사업장</span>
      <p>고형연료 저장소에 모은 폐기물을 Rotary Kiln을 통해 연소/소각로에 넣어 선택적 비촉매 환원법(SCNR)으로 질소산화물을 제거하고 반건식 반응탑(SDR)에서 연소과정에서 발생하는 산성가스를 제거 후
        소석회와 활성탄을 투입하여 여과집진기를 거쳐 유인송풍기를 통해 굴뚝으로 배출됩니다.</p>
    </div>
  </div>

  <div class="img_box_area">
    <img src="@/assets/images/sub/atmoContMatProtFac.png" alt="대기오염물질 방지시설 공정도 - 일반적인 고형연료사용 시설의 방지시설 정보 SNCR(selective non-catalytic reduction, 선택적 비촉매 환원법) : 연료 투입 후 연소 단계에서 질소산화물 제거를 위해 요소수를 사용한 비촉매 방식 SCR(selective catalytic reduction, 선택적 촉매 환원법) : 질소산화물을 절감하기 위한 시스템으로 환원제 및 촉매에 의해 질소산화물을 인체에 무해한 질소와 수증기로 환원하는 방법 SDR(반건식 반응탑) : 연소과정에서 발생하는 산성가스를 제거하는 설비 습식세정탑 : 일반 설비에서 배출되는 가스 속의 해로운 물질을 탑 내부의 연료 분사관을 통해 순환하는 물로 세척하는 장치">
    <div class="img_box mt_20">
      <span class="title">대기오염물질 방지시설 공정도 - 일반적인 고형연료사용 시설의 방지시설 정보</span>
      <ul class="dot_list txt_left">
        <li>SNCR(selective non-catalytic reduction, 선택적 비촉매 환원법) : 연료 투입 후 연소 단계에서 질소산화물 제거를 위해 요소수를 사용한 비촉매 방식</li>
        <li>SCR(selective catalytic reduction, 선택적 촉매 환원법) : 질소산화물을 절감하기 위한 시스템으로 환원제 및 촉매에 의해 질소산화물을 인체에 무해한 질소와 수증기로 환원하는 방법</li>
        <li>SDR(반건식 반응탑) : 연소과정에서 발생하는 산성가스를 제거하는 설비</li>
        <li>습식세정탑 : 일반 설비에서 배출되는 가스 속의 해로운 물질을 탑 내부의 연료 분사관을 통해 순환하는 물로 세척하는 장치</li>
      </ul>
    </div>
  </div>
</template>

<style scoped>

</style>