<template>
  <div class="member_wrap">
    <ol class="join_step_wrap">
      <li>
        <i class="step1"></i>
        <span>회원선택</span>
      </li>
      <li>
        <i class="step2"></i>
        <span>가입여부 확인</span>
      </li>
      <li>
        <i class="step3"></i>
        <span>약관동의</span>
      </li>
      <li class="active">
        <i class="step4"></i>
        <span>사용자정보 입력</span>
      </li>
      <li>
        <i class="step5"></i>
        <span>가입완료</span>
      </li>
    </ol>
    <h2 class="member_title">사용자정보 입력</h2>
    <h3 class="agree_title" v-if="storeJoinUserInfo.brno">업체정보</h3>
    <!-- 기존업체 미존재 시설 사용자-->
    <div class="board_write" v-if="['N'].includes(storeJoinUserInfo.exsBzentyYn)">
      <p class="t_caption"><span class="necessary">(<i>※</i>)</span> 필수 입력항목입니다.</p>
      <table>
        <caption>업체정보_사업자등록번호, 업체 명, 대표자 명, 업체 명(영문), 전화번호, FAX, 주소, 시도, 시군구, EPR 업체, 증빙서류로 구성</caption>
        <colgroup>
          <col style="width:18%">
          <col style="width:32%">
          <col style="width:18%">
          <col style="width:32%">
        </colgroup>
        <tbody>
        <tr>
          <th scope="row"><i class="necessary">필수입력</i>사업자등록번호</th>
          <td>
            <div class="input_group">
              <br-form-input type="text" label="사업자등록번호" v-model="storeJoinUserInfo.brno1" disabled title="사업자등록번호 앞자리" />
              <br-form-input type="text" label="사업자등록번호" v-model="storeJoinUserInfo.brno2" disabled title="사업자등록번호 가운데자리" />
              <br-form-input type="text" label="사업자등록번호" v-model="storeJoinUserInfo.brno3" disabled title="사업자등록번호 뒷자리" />
            </div>
          </td>
          <th scope="row"><i class="necessary">필수입력</i>업체 명</th>
          <td>
            <br-form-input type="text" label="업체명" v-model="param.bzentyNm" required panel="panel" />
            <span class="form_hint">사업자등록증에 명시 된 업체명을 입력해주세요.</span>
          </td>
        </tr>
        <tr>
          <th scope="row"><i class="necessary">필수입력</i>대표자 명</th>
          <td>
            <br-form-input type="text" label="대표자명" v-model="param.rprsvNm" required panel="panel" />
          </td>
          <th scope="row">업체 명(영문)</th>
          <td>
            <br-form-input type="text" label="업체명(영문)" v-model="param.engBzentyNm" @keyup="engBnmInput" />
          </td>
        </tr>
        <tr>
          <th scope="row">대표자 명(영문)</th>
          <td>
            <br-form-input type="text" label="대표자명(영문)" v-model="param.engRprsvNm" @keyup="engRnmInput" />
          </td>
          <th scope="row"><i class="necessary">필수입력</i>대표자 생년월일</th>
          <td colspan="3">
            <br-form-input type="text" label="대표자생년월일" v-model="param.rprsvBrdt" required panel="panel" @input="validateBrdt" :maxlength="8" @keyup="brdtInput"/>
            <span v-if="brdtError" class="form_hint" v-html="brdtError"></span>
          </td>
        </tr>
        <tr>
          <th scope="row"><i class="necessary">필수입력</i>전화번호</th>
          <td>
            <mbr-form-telno label="전화번호"
                            v-model:telNo1="param.telno1"
                            v-model:telNo2="param.telno2"
                            v-model:telNo3="param.telno3"
                            :required="true"
                            ref="telnoInput1"/>
          </td>
          <th scope="row">FAX</th>
          <td>
            <mbr-form-telno label="FAX"
                            v-model:telNo1="param.fxno1"
                            v-model:telNo2="param.fxno2"
                            v-model:telNo3="param.fxno3"
                            ref="fxnoInput1"/>
          </td>
        </tr>
        <tr>
          <th scope="row"><i class="necessary">필수입력</i>주소</th>
          <td colspan="3">
            <div class="form_group_addr">
              <br-form-input type="text" label="주소" v-model="allAddr" required panel="panel" disabled style="max-width: 80rem;"/>
              <br-button label="주소검색" class="btn sm ico_search txtN" @click="callPopupAddress"/>
            </div>
          </td>
        </tr>
        <tr>
          <th scope="row"><i class="necessary">필수입력</i>공공 업체</th>
          <td>
            <br-form-radio label="공공업체" :options="pubYnOptions" v-model:value="param.pubBzentyYn" panel="panel" required />
          </td>
          <th scope="row"><i class="necessary">필수입력</i>EPR 업체</th>
          <td>
            <br-form-radio label="EPR업체" :options="eprYnOptions" v-model:value="param.eprBzentyYn" panel="panel" required/>
          </td>
        </tr>
        <tr>
          <th scope="row"><i class="necessary">필수입력</i>증빙서류</th>
          <td colspan="3">
            <br-attach-file-list label="업체 첨부파일 목록"
                                 ref="attachFileList"
                                 :atchFileTaskSeCd="fileListTaskSeCd"
                                 v-model:atchFileGroupId="this.param.atchFileGroupId"
                                 :maxFileCount="10"
                                 :readonly="false"
                                 panel="panel"

            />
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <!-- 기존업체 존재 시설 사용자-->
    <div class="board_write" v-if="['Y'].includes(storeJoinUserInfo.exsBzentyYn)">
      <table>
        <caption>업체정보_사업자등록번호, 업체 명, 대표자 명, 업체 명(영문), 전화번호, FAX, 주소, 시도, 시군구, EPR 업체, 증빙서류로 구성</caption>
        <colgroup>
          <col style="width:18%">
          <col style="width:32%">
          <col style="width:18%">
          <col style="width:32%">
        </colgroup>
        <tbody>
        <tr>
          <th scope="row">사업자등록번호</th>
          <td>
            {{ readParam.brnoFm }}
          </td>
          <th scope="row">업체 명</th>
          <td>
            {{ param.bzentyNm }}
          </td>
        </tr>
        <tr>
          <th scope="row">대표자 명</th>
          <td>
            {{ param.rprsvNm }}
          </td>
          <th scope="row">업체 명(영문)</th>
          <td>
            {{ param.engBzentyNm }}
          </td>
        </tr>
        <tr>
          <th scope="row">대표자 명(영문)</th>
          <td>
            {{ param.engRprsvNm }}
          </td>
          <th scope="row">대표자 생년월일</th>
          <td>
            {{ readParam.rprsvBrdtFm }}
          </td>
        </tr>
        <tr>
          <th scope="row">전화번호</th>
          <td>
            {{ readParam.telnoFm }}
          </td>
          <th scope="row">FAX</th>
          <td>
            {{ readParam.fxnoFm }}
          </td>
        </tr>
        <tr>
          <th scope="row">주소</th>
          <td colspan="3">
            [{{ param.untyZip }}] {{ param.bzentyAddr }} {{ param.bzentyDaddr }}
          </td>
        </tr>
        <tr>
          <th scope="row">공공 업체</th>
          <td>
            {{ param.pubBzentyYn }}
          </td>
          <th scope="row">EPR 업체</th>
          <td>
            {{ param.eprBzentyYn }}
          </td>
        </tr>
        <tr>
          <th scope="row">증빙서류</th>
          <td colspan="3">
            <br-attach-file-list label="업체 첨부파일 목록"
                                 ref="attachFileList"
                                 :atchFileTaskSeCd="fileListTaskSeCd"
                                 v-model:atchFileGroupId="this.param.atchFileGroupId"
                                 :maxFileCount="10"
                                 :readonly="true"
            />
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div v-if="['Y'].includes(storeJoinUserInfo.exsBzentyYn)">
      <div class="board_info mt_40">
        <h3 class="title2">나의 관리 사업장</h3>
      </div>
      <div class="title_box" style="font-size: 15px;">
        <span class="title">※ 아래 사업장 목록에서 자신이 관리할 사업장을 선택해주세요.</span>
      </div>
      <sb-grid
          id="bplcListGrid"
          :dataSource="bplcListGridDataSource"
          :columns="bplcListGridColumns"
          :gridattr="bplcListGridAttr"
          v-model:grid="bplcListGridObject"
          :refreshGrid="bplcListGridReload"
          :countVisible="false"
          @loaded="bplcListGridLoaded"
      />
    </div>

    <h3 class="agree_title" style="margin-top: 8px;">사용자정보</h3>
    <div class="board_write">
      <p class="t_caption"><span class="necessary">(<i>※</i>)</span> 필수 입력항목입니다.</p>
      <table>
        <caption>사용자정보</caption>
        <colgroup>
          <col style="width:18%">
          <col style="width:32%">
          <col style="width:18%">
          <col style="width:32%">
        </colgroup>
        <tbody>
        <tr>
          <th scope="row"><i class="necessary">필수입력</i>이름</th>
          <td>
            <br-form-input type="text" label="이름" :modelValue="storeJoinUserInfo.userNm" required panel="panel" disabled :maxlength="100" />
          </td>
          <th scope="row"><i class="necessary">필수입력</i>아이디</th>
          <td>
            <div class="input_group">
              <br-form-input type="text" label="아이디" :modelValue="storeJoinUserInfo.userId" required panel="panel" disabled />
              <br-button label="중복확인" class="btn sm secondary" @click="callPopupIdDpctChk" />
            </div>
          </td>
        </tr>
        <tr>
          <th scope="row"><i class="necessary">필수입력</i>비밀번호</th>
          <td>
            <br-form-input type="password" label="비밀번호" v-model="param.encptPswd" @keyup="validatePassword" required panel="panel" />
            <span v-if="passwordError" class="form_hint">{{ passwordError }}</span>
          </td>
          <th scope="row"><i class="necessary">필수입력</i>비밀번호확인</th>
          <td>
            <br-form-input type="password" label="비밀번호 확인" v-model="encptPswdCh" @keyup="validatePasswordMatch" required panel="panel" />
            <span v-if="passwordMatchError" class="form_hint">{{ passwordMatchError }}</span>
          </td>
        </tr>
        <tr>
          <th scope="row"><i class="necessary">필수입력</i>전화번호</th>
          <td>
            <mbr-form-telno label="전화번호"
                            v-model:telNo1="param.userTelno1"
                            v-model:telNo2="param.userTelno2"
                            v-model:telNo3="param.userTelno3"
                            :required="true"
                            ref="telnoInput2"/>
          </td>
          <th scope="row">FAX</th>
          <td>
            <mbr-form-telno label="FAX"
                            v-model:telNo1="param.userFxno1"
                            v-model:telNo2="param.userFxno2"
                            v-model:telNo3="param.userFxno3"
                            ref="fxnoInput2"/>
          </td>
        </tr>
        <tr>
          <th scope="row"><i class="necessary">필수입력</i>이메일</th>
          <td>
            <br-form-input label="이메일" v-model="param.emlAddr" panel="panel" required :maxlength="100" @input="validateEmail" @keyup="emailInput"/>
            <span v-if="emailError" class="form_hint">{{ emailError }}</span>
          </td>
          <th scope="row"><i class="necessary">필수입력</i>휴대전화번호</th>
          <td>
            <mbr-form-telno label="휴대전화번호"
                            :telNo1="storeJoinUserInfo.mblTelno?.substring(0, 3)"
                            :telNo2="storeJoinUserInfo.mblTelno?.substring(3, 7)"
                            :telNo3="storeJoinUserInfo.mblTelno?.substring(7, 11)"
                            :phone-type="'MOBILE'"
                            ref="mTelnoInput"
                            required
                            disabled
            />
          </td>
        </tr>
        <tr>
          <th scope="row"><i class="necessary">필수입력</i>부서</th>
          <td>
            <br-form-input label="부서" v-model="param.deptNm" :maxlength="200" panel="panel" required />
          </td>
          <th scope="row">직급/직책</th>
          <td>
            <br-form-input label="직급/직책" v-model="param.jbgdNm" :maxlength="100" />
          </td>
        </tr>
        <tr>
          <th scope="row"><i class="necessary">필수입력</i>담당업무</th>
          <td colspan="3">
<!--            <br-form-select label="담당업무" :options="authrtOptions" v-model:value="param.sysAuthrtCd" panel="panel" required style="width: 31rem;" />-->
            <br-form-select label="담당업무" :options="authrtOptions" v-model:value="param.sysAuthrtCd" panel="panel" required />
          </td>
        </tr>
        <tr v-if="['04-01', '04-02', '04-03'].includes(param.sysAuthrtCd)">
          <th scope="row"><i class="necessary">필수입력</i>환경청</th>
          <td colspan="3">
            <br-form-select label="환경청" groupCd="COM426"  v-model:value="param.rbeoCd" panel="panel" required includeSelectOption style="width: 31rem;" />
          </td>
        </tr>
        <tr v-if="['task'].includes(storeJoinUserInfo.userMngSe) && ['03-01', '03-02', '03-03', '03-04', '03-05', '03-06'].includes(param.sysAuthrtCd)">
          <th scope="row"><i class="necessary">필수입력</i>관할시도</th>
          <td>
            <br-form-select label="관할시도" :options="ctpvList" v-model:value="param.cmptncCtpvStdgCd" panel="panel" required/>
          </td>
          <th scope="row"><i class="necessary" v-if="['03-01', '03-03', '03-04'].includes(param.sysAuthrtCd)">필수입력</i>관할시군구</th>
          <td>
            <br-form-select label="관할시군구" :options="sggList" v-model:value="param.cmptncSggStdgCd" panel="panel" :disabled="['03-02', '03-05', '03-06'].includes(param.sysAuthrtCd)" :required="['03-01', '03-03', '03-04'].includes(param.sysAuthrtCd)" />
          </td>
        </tr>
        <tr>
          <th scope="row">알림수신채널</th>
          <td colspan="3">
            <div class="chk_area">
              <div class="form_check">
                <input type="checkbox" v-model="param.emailUseYn" id="emailUseYn" true-value="Y" false-value="N">
                <label for="emailUseYn">이메일</label>
              </div>
              <div class="form_check">
                <input type="checkbox" v-model="param.ntctlkUseYn" id="ntctlkUseYn" true-value="Y" false-value="N">
                <label for="ntctlkUseYn">알림톡(카카오 & SMS)</label>
              </div>
            </div>
          </td>
        </tr>
        <tr v-if="storeJoinUserInfo.brno">
          <th scope="row"><i class="necessary">필수입력</i>위탁운영업체 사용자</th>
          <td colspan="3">
            <br-form-radio label="위탁운영업체" :options="cnsgnYnOptions" v-model:value="param.cnsgnOperBzentyUserYn" panel="panel" required/>
          </td>
        </tr>
        <!-- <tr v-if="['task'].includes(userMngSe)">
          <th scope="row">내부 IP주소</th>
          <td>
              <br-form-input type="text" label="내부 IP주소" v-model="param.insdIpAddr" panel="panel" @input="ipHint" />
              <span v-if="ipCheck" class="form_hint">{{ ipCheck }}</span>
          </td>
          <th scope="row">외부 IP주소</th>
          <td>
              <br-form-input type="text" label="외부 IP주소" v-model="param.otsdIpAddr" panel="panel" @input="ipHintOtsd"/>
              <span v-if="ipCheckOtsd" class="form_hint">{{ ipCheckOtsd }}</span>
          </td>
        </tr>
        <tr v-if="['task'].includes(userMngSe)">
          <td colspan="4" style="border-right:1px solid #e0e0e0">
            <div class="info_box" style="border:0">
              <strong class="info_title">IP 주소 확인 방법</strong>
              <ul class="dot_list">
                <li>내부 IP 주소 : 1. 윈도우 화면 좌측 하단 찾기에서 cmd 검색 후 클릭 2. ipconfig 입력 후 IPv4 주소 확인</li>
                <li>외부 IP 주소 : 네이버 검색창에 “IP 주소 조회” 검색</li>
              </ul>
            </div>
          </td>
        </tr> -->
        <tr v-if="storeJoinUserInfo.brno || param.sysAuthrtCd?.startsWith('05')">
          <th scope="row"><i class="necessary">필수입력</i>증빙서류</th>
          <td colspan="3">
            <br-attach-file-list label="시설사용자 첨부파일 목록"
                                 ref="attachFileListUser"
                                 :atchFileTaskSeCd="fileListTaskSeCd1"
                                 v-model:atchFileGroupId="this.param.userAtchFileGroupId"
                                 :maxFileCount="10"
                                 :readonly="false"
            />
            <span class="form_hint">※ 주민번호가 포함된 경우 마스킹 처리 후 올려주세요.</span>
          </td>
        </tr>
        <tr v-if="param.sysAuthrtCd?.startsWith('03') || param.sysAuthrtCd?.startsWith('04')">
          <th scope="row"><i class="necessary">필수입력</i>증빙서류</th>
          <td colspan="3">
            <br-attach-file-list label="업무관리사용자 첨부파일 목록"
                                 ref="attachFileListUser"
                                 :atchFileTaskSeCd="fileListTaskSeCd2"
                                 v-model:atchFileGroupId="this.param.userAtchFileGroupId"
                                 :maxFileCount="10"
                                 :readonly="false"
            />
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="btn_area center">
      <br-button label="다음" class="btn md primary" validate panel="panel" @click="create"/>
    </div>
  </div>
  <address-popup @input-button-clicked="selectedAddressByPopup"></address-popup>
  <id-dpct-chk-popup @select-button-clicked="updateUserId"></id-dpct-chk-popup>
</template>

<script>
import MbrFormTelno from "../mbr-mng/components/MbrFormTelno.vue";
import {storeSwitch} from "@/js/store/store-switch";
import AddressPopup from "@/views/mbr-mng/components/MbrFormAddress.vue";
import IdDpctChkPopup from "@/views/mbr-mng/components/popup/IdDpctChkPopup.vue";
import {storeSession} from "@/js/store/store-session";
import SbGrid from "@/components/SbGrid.vue";
import { buildUri } from "@/js/utils/uri-builder";
import storeRegister from "@/js/store/store-register";

const ROUTE = {
  JOIN: "/user/MbrJoin",
}

const API = {
  AUTHRT_CODE : "/join/code",
  JOIN : "/join",
  BZENTY_INFO : "/join/bzenty",
  PSWD_CRITERIA : "/join/pswdCriteria",
  BPLC_INFO : "/join/bplc/{bzentyId}",
  OCR: "/file/ocr",
}

export default {
  components : {MbrFormTelno, AddressPopup, IdDpctChkPopup, SbGrid},
  data() {
    return {
      param : {
        // 업체
        brno : "",
        brno1 : "",
        brno2 : "",
        brno3 : "",
        bzentyId : "",
        bzentyNm : "",
        engBzentyNm : "",
        rprsvNm : "",
        engRprsvNm : "",
        rprsvBrdt : "",
        telno : "",
        telno1 : "",
        telno2 : "",
        telno3 : "",
        fxno : "",
        fxno1 : "",
        fxno2 : "",
        fxno3 : "",
        untyZip : "",
        bzentyAddr : "",
        bzentyDaddr : "",
        ctpvStdgCd : "",
        sggStdgCd : "",
        pubBzentyYn : "N",
        eprBzentyYn : "N",
        atchFileGroupId : "",
        atchFileVldNo : "",
        bzentySttsCd : "01",  // 업체상태코드 01 운영중
        aprvYn : "N",
        trlBzentyYn : "N",
        useYn : "Y",

        // 회원
        authrtNm : "",
        sysAuthrtCd : "",
        userNm : "",
        userId : "",
        brdt : "",
        encptPswd : "",
        userTelno : "", // 업체랑 동일한 컬럼명 사용으로 user+컬럼명
        userTelno1 : "",
        userTelno2 : "",
        userTelno3 : "",
        mblTelno : "",
        mblTelno1 : "",
        mblTelno2 : "",
        mblTelno3 : "",
        userFxno : "",
        userFxno1 : "",
        userFxno2 : "",
        userFxno3 : "",
        emlAddr : "",
        ci : "",
        di : "",
        deptNm : "",
        jbgdNm : "",
        rbeoCd : "",
        smsUseYn : "N",
        emailUseYn : "Y",
        ntctlkUseYn : "Y",
        userAtchFileGroupId : "",   // 회원 첨부파일
        cnsgnOperBzentyUserYn : "N",
        lastCntnIpAddr : "", // 최종접속IP주소
        pswdChgDmndYmd: "", // 비밀번호 변경 요청 일자
        chcAgreYn: "",

        // 업무관리사용자
        cmptncCtpvStdgCd : "",
        cmptncSggStdgCd : "",
        insdIpAddr : "",
        otsdIpAddr : "",

        // 사업장
        bplcId : "",
        bplcNm : "",
        bplcKndCd : "",
        bplcAddr : "",
        bplcIdList : [],
      },
      readParam : {
        brnoFm : "",
        rprsvBrdtFm : "",
        telnoFm : "",
        fxnoFm : "",
      },
      userMngSe : "",
      exsBzentyYn : "",
      userinfo: storeSession.getMemberInfo(),
      encptPswdCh : "",
      passwordError: "",  // 비밀번호 오류 메시지
      passwordMatchError: "",  // 비밀번호 일치 여부 오류 메시지
      emailError: "", // 이메일 오류 메시지
      brdtError: "",
      authrtOptions : [],
      ctpvList : [],
      sggList : [{ text: "선택", value: "" }],
      allAddr : "",
      pubYnOptions : [
        {value : "Y", text : "대상"},
        {value : "N", text : "비대상"},
      ],
      eprYnOptions : [
        {value : "Y", text : "대상"},
        {value : "N", text : "비대상"},
      ],
      cnsgnYnOptions : [
        {value : "Y", text : "대상"},
        {value : "N", text : "비대상"},
      ],
      fileListTaskSeCd: 'BET',
      fileListTaskSeCd1: 'FUSR',
      fileListTaskSeCd2: 'LCGV',
      fileListGroupId: '',
      pswdCriteria: '',
      ipCheck : "",
      ipCheckOtsd : "",

      // 그리드
      bzentyId: "",
      bplcKndCdList: [],
      bplcListGridObject: null,
      bplcListGridReload: false,
      bplcListGridColumns: [],
      bplcListGridAttr: {
        checkable: true,
        showRowNo: false,
        pageable: false, // Grid 페이징처리여부
        editable: false, // Grid 편집가능여부
        height: 150,
      },
      bplcListGridDataSource : {},
    };
  },
  computed: {
    storeJoinUserInfo() {
      return storeRegister.getters.getJoinUserInfo;
    },
    filteredCtpvList() {
      const allCtpvList = this.$store.getters.getSggList({ type: 'ctpv' });
      const filteredList = allCtpvList
          .filter(item => item.useYn === 'Y') // useYn이 'Y'인 데이터만 필터링
          .map(item => ({
            value: item.sggStdgCd,
            text: item.sggNm
          }));
      return [{text : "선택", value: ""}, ...filteredList];
    }
  },
  watch: {
    'param.cmptncCtpvStdgCd': function(newCtpvStdgCd) {
      this.fetchSggList(newCtpvStdgCd);
    },

    'param.sysAuthrtCd'(newVal, oldVal) {
      if (['03-02', '03-05', '03-06'].includes(newVal) &&
          ['03-01', '03-03', '03-04'].includes(oldVal)) {
        this.param.cmptncSggStdgCd = '';
      }
    },
  },
  created() {
    this.userMngSe = this.$route.query.userMngSe;
    this.param.brno = this.$route.query.brno;
    this.exsBzentyYn = this.$route.query.exsBzentyYn;

    this.loadUrlData();

  },
  mounted() {
    this.initialize();
    if(storeRegister.state.joinUserInfo.exsBzentyYn === "Y"){
      this.read();
    }
  },
  methods: {
    async initialize() {
      await this.loadAuthrtCd()   // 담당업무 불러옴
      await this.loadPswdCriteria();
      this.ctpvList = this.filteredCtpvList;

      if(storeRegister.state.joinUserInfo.userMngSe === "task"){
        this.ipHint();
        this.ipHintOtsd();
      }
    },
    read() {
      this.$apiCall.get(API.BZENTY_INFO + "/" + storeRegister.state.joinUserInfo.brno, {}, this.success, this.error);
    },
    success(data) {
      if (!data.result) {
        this.error();
        return;
      }

      this.param = data.result;
      this.bzentyId = this.param.bzentyId;

      this.loadUrlData();

      this.readParam.brnoFm = `${this.param.brno.slice(0, 3)}-${this.param.brno.slice(3, 5)}-${this.param.brno.slice(5, 10)}`;

      if(this.param.rprsvBrdt){
        this.readParam.rprsvBrdtFm = `${this.param.rprsvBrdt.slice(0, 4)}.${this.param.rprsvBrdt.slice(4, 6)}.${this.param.rprsvBrdt.slice(6, 8)}`;
      }

      if(this.param.telno){
        if(this.param.telno?.startsWith('02')){
          this.readParam.telnoFm = `${this.param.telno.slice(0, 2)}-${this.param.telno.slice(3, this.param.telno.length-4)}-${this.param.telno.slice(-4)}`;
        } else {
          this.readParam.telnoFm = `${this.param.telno.slice(0, 3)}-${this.param.telno.slice(3, this.param.telno.length-4)}-${this.param.telno.slice(-4)}`;
        }
      }

      if(this.param.fxno){
        if(this.param.fxno?.startsWith('02')){
          this.readParam.fxnoFm = `${this.param.fxno.slice(0, 2)}-${this.param.fxno.slice(3, this.param.fxno.length-4)}-${this.param.fxno.slice(-4)}`;
        } else {
          this.readParam.fxnoFm = `${this.param.fxno.slice(0, 3)}-${this.param.fxno.slice(3, this.param.fxno.length-4)}-${this.param.fxno.slice(-4)}`;
        }
      }

      this.param.cnsgnOperBzentyUserYn = "N";
      this.bplcKndCdList = this.$store.getters.getCodeList({groupCd: 'MBR006', useYn: 'Y'});

      this.initializeGridColumns();
      this.initializeGrid();
      this.initializeGridAttr();
    },
    loadUrlData(){
      this.param.di = storeRegister.state.joinUserInfo.di;
      this.param.ci = decodeURIComponent(storeRegister.state.joinUserInfo.ci);
      this.param.brdt = storeRegister.state.joinUserInfo.brdt;
      this.param.mblTelno = storeRegister.state.joinUserInfo.mblTelno;
      this.param.userNm = storeRegister.state.joinUserInfo.userNm;
      this.param.chcAgreYn = storeRegister.state.joinUserInfo.chcAgreYn;

      this.param.mblTelno1 = storeRegister.state.joinUserInfo.mblTelno?.substring(0, 3);
      this.param.mblTelno2 = storeRegister.state.joinUserInfo.mblTelno?.substring(3, 7);
      this.param.mblTelno3 = storeRegister.state.joinUserInfo.mblTelno?.substring(7, 11);

      if (this.storeJoinUserInfo?.brno) {
        this.param.brno1 = storeRegister.state.joinUserInfo.brno?.slice(0, 3);
        this.param.brno2 = storeRegister.state.joinUserInfo.brno?.slice(3, 5);
        this.param.brno3 = storeRegister.state.joinUserInfo.brno?.slice(5);
      }
    },
    ipHint() {
      this.param.insdIpAddr = this.param.insdIpAddr.replace(/[^0-9.]/g, '');
      if (!this.param.insdIpAddr) {
        this.ipCheck = 'IP주소 미입력 시 외부에서 접속이 불가능합니다.';
      } else {
        this.ipCheck = '';
      }
    },
    ipHintOtsd() {
      this.param.otsdIpAddr = this.param.otsdIpAddr.replace(/[^0-9.]/g, '');
      if (!this.param.otsdIpAddr) {
        this.ipCheckOtsd  = 'IP주소 미입력 시 외부에서 접속이 불가능합니다.';
      } else {
        this.ipCheckOtsd  = '';
      }
    },
    initializeGrid() {
      this.bplcListGridDataSource = {
        ajaxConfig: {
          select: { url: this.userBplcUriForGrid() },
          paging: false,
        },
        schema: {
          id: 'bplcId'
        }
      };
      this.bplcListGridReload = true;
    },
    userBplcUriForGrid() {
      let userBplcUrl = '';
      userBplcUrl = buildUri(API.BPLC_INFO, {bzentyId : this.bzentyId});

      return userBplcUrl;
    },
    initializeGridColumns() {
      this.bplcListGridColumns = [
        { field: 'bplcId', caption: '사업장아이디', width: 0, align: 'left', hidden: true},
        { field: 'bplcNm', caption: '사업장명', width: 25, unit: '%', align: 'left'},
        { field: 'bplcKndCd', caption: '사업장종류', width: 25, unit: '%', type: 'combo', items: this.bplcKndCdList, comboLabel: 'dtlCdNm', comboValue: 'dtlCd', align: 'center'},
        { field: 'bplcAddr', caption: '사업장주소', width: 50, unit: '%', align: 'left'},
      ]
    },
    bplcListGridLoaded() {
      this.bplcListGridReload = false;
    },
    initializeGridAttr() {
      this.bplcListGridAttr.rowCss = (data) => {
        const keys = window.SBGrid3.getCheckedKeys(this.bplcListGridObject);

        if (keys.includes(data.bplcId)) {
          return 'grid-good';
        }

        return '';
      }
    },
    loadAuthrtCd() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.AUTHRT_CODE, {userMngSe : storeRegister.state.joinUserInfo.userMngSe}, data => {
          if (data.result) {
            this.authrtOptions = data.result
                .filter(item => item.sysAuthrtCd !== "99-98")
                .map(item => ({
                  text: item.authrtNm,
                  value: item.sysAuthrtCd
                }));
            this.authrtOptions.unshift({ text: "선택", value: "" });
            resolve();
          } else {
            reject(new Error("Failed to load authrt codes."));
          }
        });
      });
    },
    loadPswdCriteria() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.PSWD_CRITERIA, {}, data => {
          if (data.result) {
            this.pswdCriteria = data.result.mainRfrncVl

            const currentDate = new Date()

            // 현재 날짜에서 기준 날짜를 설정
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth();
            const day = currentDate.getDate();

            // 새로운 월과 연도를 계산
            const newDate = new Date(year, month + Number(this.pswdCriteria), day);

            // YYYYMMDD 형식으로 변환
            const newYear = newDate.getFullYear();
            const newMonth = String(newDate.getMonth() + 1).padStart(2, '0');
            const newDay = String(newDate.getDate()).padStart(2, '0');

            this.param.pswdChgDmndYmd = `${newYear}${newMonth}${newDay}`;

            resolve();
          } else {
            reject(new Error("Failed to load pswd criteria."));
          }
        });
      });
    },
    navigateTo(path) {
      if (path) {
        this.$router.push(path)
            .catch(() => {
              console.error("Navigation Error");
            });
      }
    },
    fetchSggList(ctpvStdgCd) {
      // 시군구 목록
      //this.param.cmptncSggStdgCd = '';
      //this.sggList = [];
      this.sggList.unshift({ text: "선택", value: "" });

      if (!ctpvStdgCd) {
        // 시도 코드가 없으면 시군구 리스트를 초기화
        return;
      }
      const allSggList = this.$store.getters.getSggList({ upSggStdgCd: ctpvStdgCd });
      this.sggList = allSggList
          .filter(item => item.useYn === 'Y')
          .map(item => ({
            value: item.sggStdgCd, // value는 시군구 코드
            text: item.sggNm // text는 시군구 이름
          }));
      this.sggList.unshift({ text: "선택", value: "" });
    },
    create() {

      // 전화번호 합치기
      const fullTelno = `${this.param.telno1}${this.param.telno2}${this.param.telno3}`;
      const fullMblTelno = `${this.param.mblTelno1}${this.param.mblTelno2}${this.param.mblTelno3}`;
      const fullFxno = `${this.param.fxno1}${this.param.fxno2}${this.param.fxno3}`;

      const userFullTelno = `${this.param.userTelno1}${this.param.userTelno2}${this.param.userTelno3}`;
      const userFullFxno = `${this.param.userFxno1 || ''}${this.param.userFxno2 || ''}${this.param.userFxno3 || ''}`;

      this.param.telno = fullTelno;
      this.param.fxno = fullFxno;

      this.param.userTelno = userFullTelno;
      this.param.mblTelno = fullMblTelno;
      this.param.userFxno = userFullFxno;

      this.param.emailUseYn = this.param.emailUseYn || "N";
      this.param.ntctlkUseYn = this.param.ntctlkUseYn || "N";
      this.param.smsUseYn = "N";

      this.param.exsBzentyYn = this.exsBzentyYn;

      // 전화번호 유효성 체크
      const telnoInput2 = this.$refs.telnoInput2;
      if (telnoInput2 && !telnoInput2.isValid()){
        alert("사용자 전화번호를 올바르게 입력해 주세요.");
        return;
      }

      const mTelnoInput = this.$refs.mTelnoInput;
      if (mTelnoInput && !mTelnoInput.isPhoneValid()){
        alert("휴대전화번호를 올바르게 입력해 주세요.");
        return;
      }

      const fxnoInput2 = this.$refs.fxnoInput2;
      if (userFullFxno && !fxnoInput2.isValid()){
        alert("사용자 팩스번호를 올바르게 입력해 주세요.");
        return;
      }

      // 기존업체 미존재인 시설 사용자의 경우 업체 전화번호, 팩스도 체크해야 함
      if(storeRegister.state.joinUserInfo.exsBzentyYn == "N"){
        const telnoInput1 = this.$refs.telnoInput1;
        if (telnoInput1 && !telnoInput1.isValid()){
          alert("업체 전화번호를 올바르게 입력해 주세요.");
          return;
        }

        const fxnoInput1 = this.$refs.fxnoInput1;
        if (fullFxno && !fxnoInput1.isValid()){
          alert("업체 팩스번호를 올바르게 입력해 주세요.");
          return;
        }
      }

      // 비밀번호 체크
      if(this.passwordError || this.passwordMatchError) {
        alert("비밀번호가 유효하지 않습니다.");
        return;
      }

      // 대표자 생년월일 체크
      if(this.param.rprsvBrdt){ // 대표자생년월일 입력했는지 체크
        if(this.param.rprsvBrdt.length !== 8){ // 8자리체크
          alert("YYYYMMDD 형식으로 입력해주세요. (예 : 19900101)");
          return;
        }
      }

      // 그리드 선택 항목 넘기기 (기존업체 존재)
      if(storeRegister.state.joinUserInfo.exsBzentyYn == 'Y'){
        const checkedItem = window.SBGrid3.getCheckedRows(this.bplcListGridObject);
        const checked = checkedItem.map(item => item.key);
        this.param.bplcIdList = checked;
      }

      // 기존업체가 있거나, 지자체, 환경청 업무관리자인 경우 회원 파일만 저장
      const authrtCd = this.param.sysAuthrtCd;
      if(storeRegister.state.joinUserInfo.exsBzentyYn == "Y" || authrtCd?.startsWith('03') || authrtCd?.startsWith('04') || authrtCd?.startsWith('05')){
        this.$refs.attachFileListUser.save().then(atchFileGroupId => {  // 비동기 작업
          this.param.userAtchFileGroupId = atchFileGroupId;

          this.$apiCall.post(
              API.JOIN,
              {...this.param, ...this.storeJoinUserInfo},
              () => {
                alert("정상적으로 가입신청이 완료되었습니다. 시스템 이용을 위해서는 관리자의 승인이 필요합니다.");
                storeRegister.dispatch('updateJoinUserInfo', {authrtCd: this.param.sysAuthrtCd}); // user정보
                storeRegister.dispatch('nextStep'); // 회원가입 단계처리
                this.navigateTo(`/user/JoinCmptn`);
                window.scrollTo({ top: 0, behavior: 'smooth' });
              },
              (error) => {
                if(error?.message){
                  alert(error.message);
                  this.$router.push(ROUTE.JOIN).catch(() => {});
                }else{
                  alert("저장에 실패하였습니다.");
                }
              }
          );
        });
      } else if (storeRegister.state.joinUserInfo.brno && storeRegister.state.joinUserInfo.exsBzentyYn == "N") {  // 신규업체
        let savedAtchFileGroupId = this.param.atchFileGroupId;
        let savedAtchFileVldNo = this.param.atchFileVldNo;
        this.$refs.attachFileList.save(false, true).then(({atchFileGroupId, atchFileVldNo}) => {
          this.param.atchFileGroupId = atchFileGroupId;
          this.param.atchFileVldNo = atchFileVldNo
          this.$refs.attachFileListUser.save().then(atchFileGroupId => {
            this.param.userAtchFileGroupId = atchFileGroupId;

            // ocr
            this.$apiCall.get(
                `${API.OCR}/${this.param.atchFileVldNo}/KOR_BUSINESS_LICENSE`,
                {},
                (data) => {
                  if(data) {
                    const ocrBzentyNm = data.result.formList[3][2];
                    const ocrRprsvNm = data.result.formList[4][2];
                    if(ocrBzentyNm.replace(/\s+/g, '') != this.param.bzentyNm.replace(/\s+/g, '')){
                      if(!confirm("첨부한 사업자등록증의 업체 명과 작성한 업체 명이 일치하지 않습니다. 회원가입을 계속 진행하시겠습니까?")){
                        this.param.atchFileGroupId = savedAtchFileGroupId;
                        this.param.atchFileVldNo = savedAtchFileVldNo;
                        return;
                      }
                    }
                    if(ocrRprsvNm.replace(/\s+/g, '') != this.param.rprsvNm.replace(/\s+/g, '')){
                      if(!confirm("첨부한 사업자등록증의 대표자 명과 작성한 대표자 명이 일치하지 않습니다. 회원가입을 계속 진행하시겠습니까?")){
                        this.param.atchFileGroupId = savedAtchFileGroupId;
                        this.param.atchFileVldNo = savedAtchFileVldNo;
                        return;
                      }
                    }
                    this.$apiCall.post(
                        API.JOIN,
                        {...this.param, ...this.storeJoinUserInfo},
                        () => {
                          alert("정상적으로 가입신청이 완료되었습니다. 시스템 이용을 위해서는 관리자의 승인이 필요합니다.");
                          storeRegister.dispatch('updateJoinUserInfo', {authrtCd: this.param.sysAuthrtCd}); // user정보
                          storeRegister.dispatch('nextStep'); // 회원가입 단계처리
                          this.navigateTo(`/user/JoinCmptn`);
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        },
                        (error) => {
                          if(error?.message){
                            alert(error.message);
                            this.$router.push(ROUTE.JOIN).catch(() => {});
                          }else{
                            alert("저장에 실패하였습니다.");
                          }
                        }
                    );
                  }
                },
                () => {
                  console.log("OCR 처리 실패");
                  this.$apiCall.post(
                      API.JOIN,
                      {...this.param, ...this.storeJoinUserInfo},
                      () => {
                        alert("정상적으로 가입신청이 완료되었습니다. 시스템 이용을 위해서는 관리자의 승인이 필요합니다.");
                        storeRegister.dispatch('updateJoinUserInfo', {authrtCd: this.param.sysAuthrtCd}); // user정보
                        storeRegister.dispatch('nextStep'); // 회원가입 단계처리
                        this.navigateTo(`/user/JoinCmptn`);
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                      },
                      (error) => {
                        if(error?.message){
                          alert(error.message);
                          this.$router.push(ROUTE.JOIN).catch(() => {});
                        }else{
                          alert("저장에 실패하였습니다.");
                        }
                      }
                  );
                }
            )
          });
        });
      } else {  // 파일 없이 저장
        this.$apiCall.post(
            API.JOIN,
            {...this.param, ...this.storeJoinUserInfo},
            () => {
              alert("정상적으로 가입신청이 완료되었습니다. 시스템 이용을 위해서는 관리자의 승인이 필요합니다.");
              storeRegister.dispatch('updateJoinUserInfo', {authrtCd: this.param.sysAuthrtCd}); // user정보
              storeRegister.dispatch('nextStep'); // 회원가입 단계처리
              this.navigateTo(`/user/JoinCmptn`);
              window.scrollTo({ top: 0, behavior: 'smooth' });
            },
            (error) => {
              if(error?.message){
                alert(error.message);
                this.$router.push(ROUTE.JOIN).catch(() => {});
              }else{
                alert("저장에 실패하였습니다.");
              }
            }
        );
      }
    },
    callPopupIdDpctChk() { // 아이디 중복확인 팝업 호출
      storeSwitch.on('idDpctChkPopup');
    },
    updateUserId(userId) {
      storeRegister.dispatch('updateJoinUserInfo', {userId: userId}); // user정보
      this.param.userId = userId;
    },
    callPopupAddress() {
      storeSwitch.on('addressPopup');
    },
    selectedAddressByPopup(data) {
      this.param.untyZip = data.zipNo;
      this.param.bzentyAddr = data.roadAddr;
      this.param.bzentyDaddr = data.roadDetailAddr;
      if(data.admCd) {
        const stdgCd = data.admCd;
        this.param.ctpvStdgCd = stdgCd.slice(0, 2) + '0'.repeat(stdgCd.length - 2);
        this.param.sggStdgCd = stdgCd.slice(0, 5) + '0'.repeat(stdgCd.length - 5);
      }
      this.allAddr = `[${data.zipNo}] ${data.roadAddr} ${data.roadDetailAddr}`;
    },
    validatePassword(password) {
      const passwordPattern = /^(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{9,}$/;

      if (password && !passwordPattern.test(password)) {
        this.passwordError = '비밀번호는 대/소문자, 숫자, 특수문자를 포함한 9자 이상이어야 합니다.';
      } else {
        this.passwordError = '';
      }
    },
    validatePasswordMatch(passwordConfirm) {
      const password = this.param.encptPswd;

      if (passwordConfirm && password !== passwordConfirm) {
        this.passwordMatchError = '비밀번호가 일치하지 않습니다.';
      } else {
        this.passwordMatchError = '';
      }
    },
    validateEmail() { // 이메일 유효성 검사
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (this.param.emlAddr && !emailPattern.test(this.param.emlAddr)) {
        this.emailError = '유효한 이메일 주소를 입력해주세요.';
      } else {
        this.emailError = '';
      }
    },
    validateBrdt() {  // 생년월일 유효성
      const brdtPattern = /^(19|20|21|22|23|24|25|26|27|28|29|30)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])$/;
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();

      if(this.param.rprsvBrdt && !brdtPattern.test(this.param.rprsvBrdt)) {
        this.brdtError = 'YYYYMMDD 형식으로 입력해주세요. <br>(예 : 19900101)';
      } else if (this.param.rprsvBrdt) {
        const year = parseInt(this.param.rprsvBrdt.substring(0, 4), 10);
        if (year > currentYear) {
          this.brdtError = '미래의 날짜는 입력할 수 없습니다.';
        } else {
          this.brdtError = '';
        }
      }else {
        this.brdtError = '';
      }
    },
    brdtInput() {
      this.validateBrdt();

      if (this.param.rprsvBrdt) {
        this.param.rprsvBrdt = this.param.rprsvBrdt.replace(/[^0-9]/g,'')
      }
    },
    engBnmInput() {
      if (this.param.engBzentyNm) {
        this.param.engBzentyNm = this.param.engBzentyNm.replace(/[^A-Za-z0-9 ]/g, '')
      }
    },
    engRnmInput() {
      if (this.param.engRprsvNm) {
        this.param.engRprsvNm = this.param.engRprsvNm.replace(/[^A-Za-z ]/g, '')
      }
    },
    emailInput() {
      this.validateEmail();
    }
  }
}
</script>

<style>


.form_hint{
  color: red;
  font-size: 15px;
}
</style>