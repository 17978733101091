<template>
  <div class="desc_box">
    <i class="icon"><img src="@/assets/images/common/ico_desc.png" alt="이미지"></i>
    <div class="desc">
      <strong class="desc_title">폐자원에너지 종합정보관리시스템 사용 요약도</strong>
      <p>제조·사용·수입업체는 검사와 실적을 관리하고, 폐기물에너지 생산(회수) 이용·판매자는 시설현황과 실적을 등록하며, 지자체와 환경관서는 이를 검토·확인하는 시스템입니다.
      </p>
    </div>
  </div>
  <div class="img_box_area">
    <img src="@/assets/images/sub/system_configuration.png" alt="폐자원에너지 종합정보관리시스템 사용 요약도 - 1. 제조 시설·사용 시설·수입 업체는 사용자 등록, 검사 신청 (시설(최초 또는 계속)또는 품질(최초) 검사), 품질 표시검사 현황 입력, 시설 상태 입력(가동 또는 미 가동), 제조(제조량 및 판매량)/사용(인수량 및 사용량)/수입(수입량 및 공급량) 실적 입력을 폐자원에너지 종합정보관리시스템에  업로드(입력) 하면 사용 승인 및 정보 확인, 시설 실적 입력 관리, 검사 결과 통보(시설 또는 품질 검사), 검사 대상 알림(시설, 품질(적정성)검사)등의 서비스를 제공합니다. 2. 폐기물에너지 생산(회수)이용·판매자(매립 가스, 바이오 가스, 소각 여열, 폐기물 가스 화의생산(회수) 이용·판매자) 는 폐자원에너지 종합정보관리시스템에 사용자 등록, 해당 시설 현황 입력, 실적 입력 등의 폐기물 반입 및 에너지 생산(회수)·이용·판매실적을 업로드(입력)하여야 합니다. 3. 지방자치단체/지방 환경관서는 폐자원에너지 종합정보관리시스템에 업무 담당자 등록, 월간 실적·검사 결과 확인, 신고(변경) 처리 결과 입력(고형 연료 제품 제조·사용·수입), 금지 명령 및 개선·이행 결과 입력(고형 연료 제품 제조·사용·수입)을 업로드(입력)하여야 합니다.">
  </div>
</template>

<style scoped>

</style>