<template>
    <!-- s: 콘텐츠 영역 -->
    <head>
      <meta name="description" content="이 페이지는 정기검사 신청 상세정보를 조회 합니다.">
    </head>

    <div v-if="resultObj.inspPrgrsSttsCd === '30'" class="desc_box progress_box"> 
        <i class="icon"><img src="@/assets/images/common/ico_noti.png" alt="이미지"></i>
        <div class="progress">
            <div>
                <strong>서류보완요청</strong>중입니다. 
                <span>{{resultObj.taskPrcsRsn}}</span>
            </div>
            <button type="button" class="btn sm primary line" @click.prevent="navigateFcltInspAplyCreatePage()">서류보완</button>
        </div>
    </div>

    <h2 class="title1">신청정보</h2>
    <div class="board_write">
        <p class="t_caption"><span class="necessary">(<i>※</i>)</span> 필수 입력항목입니다.</p>
        <table>
            <caption>신청정보 사업장번호, 사업장명, 처리업구분, 검사진행상태, 접수번호, 입금(예정)자명, 선입금여부, 검사희망일자, 첨부파일로 구성</caption>
            <colgroup>
                <col style="width:18%">
                <col style="width:32%">
                <col style="width:18%">
                <col style="width:32%">
            </colgroup>
            <tbody>
                <tr>
                    <th scope="row">사업장번호</th>
                    <td>{{resultObj.bplcId}}</td>
                    <th scope="row">사업장명</th>
                    <td>{{resultObj.bplcNm}}</td>
                </tr>
                <tr>
                    <th scope="row">처리업구분</th>
                    <td>{{resultObj.recyBzentyList}}</td>
                    <th scope="row">시설명</th>
                    <td>{{resultObj.fcltNm}}</td>
                </tr>
                <tr>
                    <th scope="row">검사진행상태</th>
                      <td>
                        <div class="input_group">
                             {{resultObj.inspPrgrsSttsNm}} 
                             <button type="button" class="btn xsm" @click.prevent="readFcltInspAplyHstry(resultObj.inspAplySn)">이력보기</button>
                        </div>
                    </td>
                    <th scope="row">접수번호</th>
                    <td><div class="input_group">{{resultObj.inspRcptId}}</div></td> 
                </tr>
                <tr>
                    <th scope="row">입금(예정)자 명</th>
                    <td>{{resultObj.pyrNm}}</td>
                    <th scope="row">선입금여부</th>
                    <td>{{resultObj.dpstYn}}</td>
                </tr>
                <tr>
                    <th scope="row">입금(예정)일자</th>
                    <td>{{resultObj.dpstPrnmntYmd}}</td>
                    <th scope="row">입금확인일자</th>
                    <td>{{resultObj.dpstIdntyYmd}}</td>
                </tr>
                <tr>
                    <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>검사희망일자</th>
                    <td colspan="3">{{resultObj.inspHopeYmd}}</td>
                </tr>

                <!-- s: 제조시설 검사 제출 서류 -->
                <tr v-if="this.resultObj.bplcKndCd === '01-01'">
                    <th scope="row">파일</th>
                    <td colspan="3">
                        <div class="file_upload_list">
                            <!-- <li> -->
                          <br-attach-file-list label="정기검사 신청 첨부파일 목록"
                                               ref="attachFileList"
                                               atchFileTaskSeCd="FAC"
                                               v-model:atchFileGroupId="resultObj.inspAplyAtchFileGroupId"
                                               :allowedExtensions="fileAllowedExtensions"
                                               :textAreaWidth="fileTextAreaWidth"
                                               :readonly="true"
                                               :useEtcFile="false"
                                               :unusedAtchFileList="['001', '002', '003', '004', '005', '006', '007', '008', '010', '011', '013','014', '015', '016', '054','053','999']"
                          />
                          <!-- 003:사업자등록증 -->
                          <br-attach-file-list label="정기검사 업체 첨부파일 목록"
                                               ref="attachFileList"
                                               atchFileTaskSeCd="FAC"
                                               v-model:atchFileGroupId="resultObj.inspAplyAtchFileGroupId"
                                               :allowedExtensions="fileAllowedExtensions"
                                               :textAreaWidth="fileTextAreaWidth"
                                               :readonly="true"
                                               :useEtcFile="false"
                                               :unusedAtchFileList="['001', '002', '004', '005', '006', '007', '008', '010', '011', '012', '013','014', '015', '016','048', '054','053','999']"
                          />
                          <!-- 001:재활용허가증 002:대기배출시설설치 허가증 006:통장사본 007:신고확인증(신고허가증) -->
                          <br-attach-file-list label="정기검사 업체 첨부파일 목록"
                                               ref="attachFileList"
                                               atchFileTaskSeCd="FAC"
                                               v-model:atchFileGroupId="resultObj.inspAplyAtchFileGroupId"
                                               :allowedExtensions="fileAllowedExtensions"
                                               :textAreaWidth="fileTextAreaWidth"
                                               :readonly="true"
                                               :useEtcFile="false"
                                               :unusedAtchFileList="['003', '004', '005','008', '010', '011', '012', '013','014', '015', '016','048','999']"
                          />
                          <!-- 004:시설설치명세서 005:시설의가동및유지 관리명세서 -->
                          <br-attach-file-list label="정기검사 업체 첨부파일 목록"
                                               ref="attachFileList"
                                               atchFileTaskSeCd="FAC"
                                               v-model:atchFileGroupId="resultObj.inspAplyAtchFileGroupId"
                                               :allowedExtensions="fileAllowedExtensions"
                                               :textAreaWidth="fileTextAreaWidth"
                                               :readonly="true"
                                               :useEtcFile="false"
                                               :unusedAtchFileList="['001', '002','003', '006', '007', '008', '010', '011', '012', '013','014', '015', '016','048', '054','053','999']"
                          />
                          <!-- 999:기타서류 -->
                          <br-attach-file-list label="정기검사 업체 첨부파일 목록"
                                               ref="attachFileList"
                                               atchFileTaskSeCd="FAC"
                                               v-model:atchFileGroupId="resultObj.inspAplyAtchFileGroupId"
                                               :allowedExtensions="fileAllowedExtensions"
                                               :textAreaWidth="fileTextAreaWidth"
                                               :readonly="true"
                                               :useEtcFile="true"
                                               :unusedAtchFileList="['001', '002', '003', '004', '005', '006', '007', '008', '010', '011', '012', '013','014', '015', '016','048', '054','053']"
                          />
                          <!-- </li>  -->
                        </div>
                    </td>
                </tr>
                <!-- e: 제조시설 검사 제출 서류 --> 

                <!-- s: 사용시설 검사 제출 서류 -->
                <tr v-if="this.resultObj.bplcKndCd === '01-02'">
                    <th scope="row">파일</th>
                    <td colspan="3">
                        <div class="file_upload_list">
                          <!-- 012:정기검사 신청서, 048:협조공문 -->
                          <br-attach-file-list label="정기검사 신청 첨부파일 목록"
                                               ref="attachFileList"
                                               atchFileTaskSeCd="FAC"
                                               v-model:atchFileGroupId="resultObj.inspAplyAtchFileGroupId"
                                               :allowedExtensions="fileAllowedExtensions"
                                               :textAreaWidth="fileTextAreaWidth"
                                               :readonly="true"
                                               :useEtcFile="false"
                                               :unusedAtchFileList="['001', '002', '003', '004', '005', '006', '007', '008', '010', '011', '013', '015', '016', '054','053','999']"
                            />
                            <!-- 003:사업자등록증 -->
                            <br-attach-file-list label="정기검사 업체 첨부파일 목록"
                                                 ref="attachFileList"
                                                 atchFileTaskSeCd="FAC"
                                                 v-model:atchFileGroupId="resultObj.inspAplyAtchFileGroupId"
                                                 :allowedExtensions="fileAllowedExtensions"
                                                 :textAreaWidth="fileTextAreaWidth"
                                                 :readonly="true"
                                                 :useEtcFile="false"
                                                 :unusedAtchFileList="['001', '002', '004', '005', '006', '007', '008', '010', '011', '012', '013','014', '015', '016','048', '054','053','999']"
                            />
                            <!-- 001:재활용허가증 002:대기배출시설설치 허가증 006:통장사본 007:신고확인증(신고허가증) -->
                            <br-attach-file-list label="정기검사 업체 첨부파일 목록"
                                                 ref="attachFileList"
                                                 atchFileTaskSeCd="FAC"
                                                 v-model:atchFileGroupId="resultObj.inspAplyAtchFileGroupId"
                                                 :allowedExtensions="fileAllowedExtensions"
                                                 :textAreaWidth="fileTextAreaWidth"
                                                 :readonly="true"
                                                 :useEtcFile="false"
                                                 :unusedAtchFileList="['003', '004', '005', '010', '011', '012', '013','014', '015', '016','048','999']"
                            />
                            <!-- 004:시설설치명세서 005:시설의가동및유지 관리명세서 -->
                            <br-attach-file-list label="정기검사 업체 첨부파일 목록"
                                                 ref="attachFileList"
                                                 atchFileTaskSeCd="FAC"
                                                 v-model:atchFileGroupId="resultObj.inspAplyAtchFileGroupId"
                                                 :allowedExtensions="fileAllowedExtensions"
                                                 :textAreaWidth="fileTextAreaWidth"
                                                 :readonly="true"
                                                 :useEtcFile="false"
                                                 :unusedAtchFileList="['001', '002','003', '006', '007', '008', '010', '011', '012', '013','014', '015', '016','048', '054','053','999']"
                            />
                            <!-- 999:기타서류 -->
                            <br-attach-file-list label="정기검사 업체 첨부파일 목록"
                                               ref="attachFileList"
                                               atchFileTaskSeCd="FAC"
                                               v-model:atchFileGroupId="resultObj.inspAplyAtchFileGroupId"
                                               :allowedExtensions="fileAllowedExtensions"
                                               :textAreaWidth="fileTextAreaWidth"
                                               :readonly="true"
                                               :useEtcFile="true"
                                               :unusedAtchFileList="['001', '002', '003', '004', '005', '006', '007', '008', '010', '011', '012', '013','014', '015', '016','048', '054','053']"
                            />
                        </div>
                    </td>
                </tr>
                <!-- e: 사용시설 검사 제출 서류 --> 

            </tbody>
        </table>
    </div>

    <h2 class="title1">검사 정보</h2>
    <div class="board_write">
        <table>
            <caption>검사 정보_검사(예정)일자, 검사원 명으로 구성</caption>
            <colgroup>
                <col style="width:18%">
                <col style="width:32%">
                <col style="width:18%">
                <col style="width:32%">
            </colgroup>
            <tbody>
                <tr>
                    <th scope="row">검사(예정)일자</th>
                    <td>
                        <div class="input_group">
                            {{resultObj.inspPrnmntYmd}} 
                            <button v-if="resultObj.inspPrgrsSttsCd !== '70'" 
                                    type="button" 
                                    class="btn xsm primary line" 
                                    @click.prevent="openModalInspSpnsAply(resultObj.inspAplySn, resultObj.inspSpnsPrlgSqno, resultObj.inspPrnmntYmd)"
                            >
                                연기{{ resultObj.inspSpnsSttsNm }}
                            </button>
                        </div>
                    </td>
                    <th scope="row">검사원 명</th>
                    <td>{{resultObj.inspcList}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="btn_area right">
        <br-button v-if="resultObj.inspPrgrsSttsCd === '10'" label="수정" title="수정" format="primary" validate @click="navigateFcltInspAplyCreatePage()"/>
        <br-button label="목록" title="목록" format="tertiary"  validate @click="navigateTo('/sfli/FcltInspAplyStat')"/>
    </div>
    <!-- e: 콘텐츠 영역 -->

    <!-- s: modal popup 검사신청 이력 -->
    <div :class="['modal', { visible: isModalInspAplyHstryVisible }]" id="modalInspAplyHstry">
        <div class="popup_box" tabindex="0">
            <h1 class="popup_title">검사신청 이력</h1>
            <div class="popup_cont">
                <h2 class="title1 mt_0">검사신청 이력</h2>
                        
                <div class="tbl_wrap">
                    <table>
                        <caption>검사신청이력_번호, 진행상태, 사유, 처리일자, 처리자로 구성</caption>
                        <colgroup>
                            <col style="width:7%">
                            <col style="width:18%">
                            <col style="width:40%">
                            <col style="width:15%">
                            <col style="width:20%">
                        </colgroup>
                        <thead>
                            <tr>
                                <th scope="col">번호</th>
                                <th scope="col">진행상태</th>
                                <th scope="col">사유</th>
                                <th scope="col">처리일자</th>
                                <th scope="col">처리자</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(histItem, histIndex) in histObj.list" :key="histIndex">
                                <td>{{histItem.rowNum}}</td>
                                <td><span class="badge bg3">{{histItem.inspPrgrsSttsNm}}</span></td>
                                <td class="txt_left">{{histItem.inspRsn}}</td>
                                <td>{{histItem.prcsYmd}}</td>
                                <td>{{histItem.prcsUserNm}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="btn_area center">
                    <button type="button" class="btn md" @click="closeModal">닫기</button> 
                </div>
            </div>
            <button type="button" class="modal_close" @click="closeModal">팝업 닫기</button>
        </div>
    </div>
    <!-- e: modal popup 검사신청 이력 -->

    <!-- s: modal popup 검사연기 -->
    <div :class="['modal', { visible: isModalInspSpnsVisible }]" id="modalInspSpns"> 
      <div class="popup_box" tabindex="0">
      <h1 class="popup_title">검사연기</h1>
      <div class="popup_cont" style="overflow-y: hidden;">
          <div class="desc_box progress_box">
              <i class="icon"><img src="@/assets/images/common/ico_noti.png" alt="이미지"></i>
              <div class="progress">
                  <div><strong>검사연기신청</strong></div>
              </div>
          </div>
          <div class="board_write">
              <table>
                  <caption>검사연기신청_변경일자, 증빙서류, 신청사유로 구성</caption>
                  <colgroup>
                      <col style="width:15%">
                      <col style="width:85%">
                  </colgroup>
                  <tbody>
                      <tr>
                          <th scope="row"><i v-if="inspSpnsObj.pageType === 'new' || inspSpnsObj.inspSpnsSttsCd === '03'" class="necessary">필수입력</i>변경일자</th>
                          <td>
                            <span v-if="inspSpnsObj.pageType === 'new' || inspSpnsObj.inspSpnsSttsCd === '03'">
                              <br-date-picker label="변경일자" title="변경일자" v-model:value="inspSpnsObj.chgYmd" format="YYYY-MM-DD" panel="panel" required />
                            </span>
                            <span v-else>
                              {{inspSpnsObj.chgYmd}} 
                            </span>
                          </td>
                      </tr>
                      <tr v-if="inspSpnsObj.pageType === 'new' || inspSpnsObj.inspSpnsSttsCd === '03'">
                        <th scope="row">증빙서류</th>
                        <td>
                          <div class="file_upload_list" :class="{'file-upload-visible': isFileComponentVisible}">
                            <li v-if="isModalInspSpnsVisible" >
                              <br-attach-file-list label="정기검사 연기 첨부파일 목록"
                                          ref="extAttachFileList"
                                          atchFileTaskSeCd="EXT" 
                                          v-model:atchFileGroupId="this.inspSpnsObj.inspAplyStatAtchFileGroupId"
                                          v-model:fileProcessingData="this.inspSpnsObj.fileProcessingData"
                                          :readonly="false" 
                                          :useEtcFile="false" 
                                          panel="panel"
                                          required
                              /> 
                            </li> 
                          </div>
                        </td>
                      </tr>

                      <tr v-else>
                        <th scope="row">증빙서류</th>
                        <td>
                          <div class="file_upload_list" :class="{'file-upload-visible': isFileComponentVisible}">
                            <li>
                              <br-attach-file-list label="정기검사 연기 첨부파일 목록"
                                          ref="extAttachFileList"
                                          atchFileTaskSeCd="EXT" 
                                          v-model:atchFileGroupId="this.inspSpnsObj.inspAplyStatAtchFileGroupId"
                                          v-model:fileProcessingData="this.inspSpnsObj.fileProcessingData"
                                          :readonly="true" 
                                          :useEtcFile="false" 
                              /> 
                            </li> 
                          </div>
                        </td>
                      </tr>

                      <tr>
                          <th scope="row"><i v-if="inspSpnsObj.pageType === 'new' || inspSpnsObj.inspSpnsSttsCd === '03'" class="necessary">필수입력</i>신청사유</th>
                          <td style="padding-top: 30px; padding-bottom: 30px;">
                            <span v-if="inspSpnsObj.pageType === 'new' || inspSpnsObj.inspSpnsSttsCd === '03'">
                              <br-form-input label="신청사유" title="신청사유" v-model="inspSpnsObj.inspSpnsAplyRsn" panel="panel" required/>
                            </span>
                            <span v-else>
                              {{inspSpnsObj.inspSpnsAplyRsn}}
                            </span>
                          </td>
                      </tr>
                  </tbody>
              </table>

              <table v-if="inspSpnsObj.inspSpnsSttsCd === '03'">
                <caption>검사연기신청_변경일자, 증빙서류, 신청사유로 구성</caption>
                  <colgroup>
                      <col style="width:15%">
                      <col style="width:35%">
                      <col style="width:15%">
                      <col style="width:35%">
                  </colgroup>
                  <tbody>
                    <tr>
                        <th scope="row">반려사유</th>
                        <td colspan="3" style="padding-top: 30px; padding-bottom: 30px;">
                          <span>{{inspSpnsObj.chgRsn}}</span>
                        </td>
                    </tr>
                    <tr>
                      <th scope="row">처리자</th>
                      <td colspan="3">{{inspSpnsObj.userNm}}</td>
                    </tr>
                  </tbody>
              </table>
          </div>

          <div class="btn_area center">
              <br-button v-if="inspSpnsObj.pageType === 'new' || inspSpnsObj.aplyPsblYn === 'Y' || inspSpnsObj.inspSpnsSttsCd === '03'" 
                        label="신청" title="신청" class="btn md primary" panel="panel" validate @click="createFcltInspSpnsAply()"/>
              <br-button label="닫기" title="닫기" class="btn md" @click="closeModal" />
          </div>
      </div>
      <button type="button" class="modal_close" @click="closeModal">팝업 닫기</button>
    </div>
  </div>
  <!-- e: modal popup 검사연기 -->
  <!-- s: modal popup 필수 첨부파일 -->
  <div :class="['modal', { visible: isBeforeFileCheckPopupVisible }]" id="modalFcltInspAplyGuide">
    <div class="popup_box" tabindex="0">
      <h1 class="popup_title">정기검사 신청 전 필수 첨부파일 안내</h1>
      <div class="popup_cont">
        <div class="desc_box progress_box">
          <i class="icon"><img src="@/assets/images/common/ico_noti.png" alt="이미지"></i>
          <div class="progress">
            <div><strong>정기검사</strong>시 필요한 첨부파일이 누락되어 있습니다. <span>마이오피스에서 확인해주시기 바랍니다.</span></div>
          </div>
        </div>
        <div class="tbl_wrap">
          <table>
            <caption>검사신청이력_번호, 진행상태, 사유, 처리일자, 처리자로 구성</caption>
            <colgroup>
              <col style="width:65%">
              <col style="width:20%">
              <col style="width:15%">
            </colgroup>
            <thead>
            <tr>
              <th scope="col">첨부파일 종류</th>
              <th scope="col">회원구분</th>
              <th scope="col">존재여부</th>
            </tr>
            </thead>
            <tbody>
            <!--  -->
            <tr>
              <td>사업자등록증</td>
              <td>업체</td>
              <td>{{resultObj.atchFile003}}</td>
            </tr>
            <tr>
              <td>재활용허가증 또는 폐기물처리시설신고필증</td>
              <td>사업장</td>
              <td>{{resultObj.atchFile001}}</td>
            </tr>
            <tr>
              <td>통장사본</td>
              <td>사업장</td>
              <td>{{resultObj.atchFile006}}</td>
            </tr>
            <tr v-if="this.resultObj.bplcKndCd === '01-02'">
              <td>제조·사용 신고확인증(신고허가증)</td>
              <td>사업장</td>
              <td>{{resultObj.atchFile007}}</td>
            </tr>
            <tr>
              <td>시설 설치명세서</td>
              <td>시설</td>
              <td>{{resultObj.atchFile004}}</td>
            </tr>
            <tr>
              <td>시설의 가동 및 유지·관리 명세서</td>
              <td>시설</td>
              <td>{{resultObj.atchFile005}}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="btn_area center">
          <button type="button" class="btn md primary" @click="navigateTo('/my-office/BizFacilityInfo')">마이오피스로 이동하기</button>
          <button type="button" class="btn md" @click="closeModal">닫기</button>
        </div>
      </div>
      <button type="button" class="modal_close" @click="closeModal">팝업 닫기</button>
    </div>
  </div>
  <!-- e: modal popup 필수 첨부파일 -->
</template>

<script>
/**
 * 정기검사 상세 정보를 조회하는 화면 
 *
 * 작성자 : CHOSUNGHO
 * 작성일 : 24. 10. 31. 
 * 버전 : 1.0
 */
import {buildUri} from "@/js/utils/uri-builder";

const API = {
    FCLT_INSP_DETAIL : "/fcltInsp/{inspAplySn}/detail",
    FCLT_INSP_HISTORY : "/fcltInsp/history/{inspAplySn}",
    FCLT_INSP_SPNS_APLY : "/fcltInsp/spnsAply",
    FCLT_INSP_SPNS_DETAIL : "/fcltInsp/spnsRsct/{inspAplySn}/sqno/{inspSpnsPrlgSqno}",
} 

export default {
  computed : {
    fcltInspDetailObj() {
        // Local Storage에서 데이터 불러오기
        const fcltInspDetailObj = localStorage.getItem('fcltInspDetailObj');
        return fcltInspDetailObj ? JSON.parse(fcltInspDetailObj) : {};
    },
  },
  data() {
    return {
        resultObj : {},
        histObj : {
            rowNum : "",
            inspPrgrsSttsNm : "", 
            inspRsn : "" 
        },
        inspSpnsObj : {
            inspSpnsAplySn : "",
            chgBfrYmd : "",
            chgYmd : "", 
            inspSpnsAplyRsn : "", 
            inspAplyStatAtchFileGroupId : "", 
            inspSpnsPrlgSeCd : "",
            inspSpnsSttsCd : "",
            pageType : "",
            atchFileGroupId : "",
            aplyPsblYn : "",
            fileProcessingData: { type: Object, default: () => ({}) },
            needFileYn :"",
            atchFile003 :"",
            atchFile001 :"",
            atchFile002 :"",
            atchFile006 :"",
            atchFile007 :"",
            atchFile004 :"",
            atchFile005 :""
        },
        fileListTaskSeCd: "FAC",
        inspSpnsFileListTaskSeCd: "EXT",
        fileProcessingData : {},
        isModalInspAplyHstryVisible: false, 
        isModalInspSpnsVisible: false,
        isBeforeFileCheckPopupVisible :false,
        fileAllowedExtensions: ['jpg', 'jpeg', 'gif', 'png', 'tif', 'bmp', 'hwp', 'pdf', 'xlsx', 'xls', 'doc', 'docx', 'ppt', 'pptx'],
        fileTextAreaWidth: "360px", 
    };
  },
  watch : {},
  created() {},
  mounted() { 
    this.readFcltInspDetailByinspAplySn();
  },
  beforeMount (){},
  methods : {
    readFcltInspDetailByinspAplySn() {
        this.$apiCall.get(buildUri(API.FCLT_INSP_DETAIL, {inspAplySn : this.fcltInspDetailObj.inspAplySnValue}), {}, this.readFcltInspDetailByinspAplySnSuccess, this.error);
    },
    readFcltInspDetailByinspAplySnSuccess(data) {
      if (!data.result) {
        this.error();
        return;
      }
      this.resultObj = data.result;
      console.dir("inspSpnsObj.atchFile003 : ?? : " + data.result.atchFile003);
    },
    readFcltInspAplyHstry(pInspAplySn) {
        /**
         * 정기검사 이력 조회 
         */
        this.$apiCall.get(buildUri(API.FCLT_INSP_HISTORY, {inspAplySn : pInspAplySn}), {}, this.readFcltInspAplyHstrySuccess, this.error);
    },
    readFcltInspAplyHstrySuccess(data) {
      if (!data.result) {
        this.error();
        return;
      }

      this.histObj = data.result;
      this.openModal('#modalInspAplyHstry'); // 정기검사 이력 Modal 
    },
    error() {
      alert("데이터를 가져오는 중 오류가 발생했습니다.");
    },
    createFcltInspSpnsAply() {
        if (!confirm("검사 연기 신청하시겠습니까?")){
            return; 
        }

        this.inspSpnsObj.inspSpnsPrlgSeCd = "01-00-00";
        this.inspSpnsObj.inspSpnsSttsCd = "01"; 

        this.$refs.extAttachFileList.save().then(atchFileGroupId => {  
            this.inspSpnsObj.atchFileGroupId = atchFileGroupId;

            this.$apiCall.post(
                API.FCLT_INSP_SPNS_APLY, 
                this.inspSpnsObj,
                () => {
                    alert("검사 연기 신청이 완료 되었습니다.");
                    this.$router.go(0);
                    this.closeModal(); 
                },
                () => {
                    alert("저장에 실패하였습니다.");
                }
            );
        });
    }, 
    openModalInspSpnsAply(pInspAplySn, pInspSpnsPrlgSqno, pInspPrnmntYmd) { 
      this.inspSpnsObj.inspSpnsAplySn = pInspAplySn;

      /**
       * 정기 검사연기 신청 Modal 
       */
      if (!pInspSpnsPrlgSqno) { // 신규 등록 
        this.isModalInspSpnsVisible = true;
        this.inspSpnsObj.chgBfrYmd = pInspPrnmntYmd;
        this.inspSpnsObj.chgYmd = "";
        this.inspSpnsObj.inspSpnsAplyRsn = "";
        this.inspSpnsObj.inspAplyStatAtchFileGroupId = "";
        this.inspSpnsObj.atchFileGroupId = "";
        this.inspSpnsObj.fileProcessingData = {};
        this.inspSpnsObj.inspSpnsPrlgSeCd = "01-00-00";
        this.inspSpnsObj.inspSpnsSttsCd = "01"; 
        this.inspSpnsObj.pageType = "new"; 

      } else { // 상세 조회 
        this.inspSpnsObj.pageType = "search"; 
        this.readInspSpnsDetail(pInspAplySn, pInspSpnsPrlgSqno);
      } 
    },
    readInspSpnsDetail(pInspAplySn, pInspSpnsPrlgSqno) {
        // 정기검사 연기 신청 정보 조회 
        this.$apiCall.get(buildUri(API.FCLT_INSP_SPNS_DETAIL, {inspAplySn : pInspAplySn, inspSpnsPrlgSqno : pInspSpnsPrlgSqno}), {}, this.readInspSpnsDetailSuccess, this.error);
    },
    readInspSpnsDetailSuccess(data) {
      if (!data.result) {
        this.error();
        return;
      }

      this.inspSpnsObj = data.result;
      this.inspSpnsObj.inspSpnsAplySn = data.result.inspAplySn;
      this.inspSpnsObj.chgBfrYmd = data.result.chgYmd.replaceAll('-', '');
      this.isModalInspSpnsVisible = true;
    },
    openModal(pModalId) { 
      switch (pModalId) {
        case '#modalInspAplyHstry':
          this.isModalInspAplyHstryVisible = true;
          break; 
          
        default:
          break;
      }
    },
    closeModal() { 
        this.isModalInspAplyHstryVisible = false; 
        this.isModalInspSpnsVisible = false;
        this.isBeforeFileCheckPopupVisible = false;
    },
    navigateTo(navigatePath) {
      if (!navigatePath) {
        alert('페이지 정보가 존재하지 않습니다.');
        return;
      } 

      this.$router.push(
        {
          path : navigatePath
        }
      ).catch(
        () => { 
          console.error("Navigation Error"); 
        }
      );
    },
    navigateFcltInspAplyCreatePage() {
      const dataObj = {
        inspAplySnValue: this.fcltInspDetailObj.inspAplySnValue,        /* 검사신청일련번호 */ 
        inspSeCdValue: '',   /* 검사구분코드 빈 값 적용 (검사신청일련번호 기준으로 조회) */ 
        bplcIdValue: '',       /* 사업장 아이디 빈 값 적용 (검사신청일련번호 기준으로 조회) */
        fcltSqnoValue: '',   /* 정기순번 빈 값 적용 (검사신청일련번호 기준으로 조회) */
        bplcKndCdValue: ''  /* 사업장종류코드 빈 값 적용 (검사신청일련번호 기준으로 조회) */
      };
      if(this.resultObj.needFileYn === "Y"){
        if (!this.fcltInspDetailObj.inspAplySnValue) {
          alert("정기검사신청일련번호 데이터가 존재하지 않습니다.");
          return;
        }

        // 데이터를 Local Storage에 저장
        localStorage.setItem('fcltInspAplyObj', JSON.stringify(dataObj));

        this.$router.push(
            {
              path : "/sfli/fclt-insp-aply/FcltInspAplyCreate"
            }
        ).catch(
            () => {
              console.error("Navigation Error");
            }
        );
      }else{
        this.isBeforeFileCheckPopupVisible = true;
      }
    },
  }
}
</script>

<style scoped>
  .tbl_wrap table tbody td {
    vertical-align: middle !important;
  }
</style>