<template>

  <h2 class="title1 mt_0">실적보고 상세</h2>
  <div class="board_write">
    <table>
      <caption>실적연도, 실적상태, 사업장명, 사업장종류</caption>
      <colgroup>
        <col style="width:18%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:32%">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">실적연월</th>
        <td>{{ formattedPrfmncYm }}</td>
        <th scope="row">실적상태</th>
        <td>{{ bioGasDtlObj.prfmncMst.prfmncSttsNm || "작성중" }}</td>
      </tr>
      <tr>
        <th scope="row">사업장명</th>
        <td>{{ bioGasDtlObj.prfmncMst.bplcNm }}</td>
        <th scope="row">사업장종류</th>
        <td>{{ bioGasDtlObj.prfmncMst.bplcKndNm }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <rjct-rsn-area :prfmncBasicInfo="bioGasDtlObj.prfmncMst" />
  <prfmnc-mdfcn-prm-area :prfmncBasicInfo="bioGasDtlObj.prfmncMst" @dmnd-rtrcn="dmndRtrcnCmptn" />

  <div class="board_info mt_40">
    <h3 class="title2" tabindex="-1" ref="prfmncDtlBioGasWtCrynTitle">폐기물 반입 현황</h3>
  </div>

  <div class="grid_search">
    <h4>반입 폐기물 종류는 마이오피스 > 사업장정보에서 관리할 수 있습니다.</h4>
  </div>
  <sb-grid
      id="prfmncDtlBioGasWtCrynGrid"
      :dataSource="dataSourceBioGasWtCryn"
      :columns="gridColumnsBioGasWtCryn"
      :gridattr="gridAttrBioGasWtCryn"
      v-model:grid="prfmncBioGasWtCrynGridObject"
      @loaded="gridLoadedBioGasWtCryn"
      :refreshGrid="gridReloadBioGasWtCryn"
      @change="changeBioGasWtCryn"
  />

  <div class="board_info mt_40">
    <h3 class="title2">바이오가스시설 가동 현황</h3>
  </div>
  <h4 class="title3">소화조 가동 현황(%)</h4>
  <div class="board_write thead">
    <table>
      <caption>소화조 가동 현황</caption>
      <colgroup>
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
      </colgroup>
      <thead>
      <tr>
        <th scope="col">구분</th>
        <th scope="col">휘발성고형물(VS)</th>
        <th scope="col">부유고형물(SS)</th>
        <th scope="col">생화학적 산소 요구량(BOD)</th>
        <th scope="col">총 고형물(TS)</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th scope="row">소화 전 농도</th>
        <td class="txt_right" aria-label="휘발성고형물(VS)">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.digstnBfrVsDens" :maxlength="15" validator="number" align="right" unit="%" panel="login" required />
        </td>
        <td class="txt_right" aria-label="부유고형물(SS)">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.digstnBfrSsDens" :maxlength="15" validator="number" align="right" unit="%" panel="login" required />
        </td>
        <td class="txt_right" aria-label="생화학적 산소 요구량(BOD)">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.digstnBfrBodDens" :maxlength="15" validator="number" align="right" unit="%" panel="login" required />
        </td>
        <td class="txt_right" aria-label="총 고형물(TS)">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.digstnBfrTsDens" :maxlength="15" validator="number" align="right" unit="%" panel="login" required />
        </td>
      </tr>
      <tr>
        <th scope="row">소화 후 농도</th>
        <td class="txt_right" aria-label="휘발성고형물(VS)">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.digstnAftrVsDens" :maxlength="15" validator="number" align="right" unit="%" panel="login" required />
        </td>
        <td class="txt_right" aria-label="부유고형물(SS)">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.digstnAftrSsDens" :maxlength="15" validator="number" align="right" unit="%" panel="login" required />
        </td>
        <td class="txt_right" aria-label="생화학적 산소 요구량(BOD)">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.digstnAftrBodDens" :maxlength="15" validator="number" align="right" unit="%" panel="login" required />
        </td>
        <td class="txt_right" aria-label="총 고형물(TS)">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.digstnAftrTsDens" :maxlength="15" validator="number" align="right" unit="%" panel="login" required />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h4 class="title3">가스정제설비 보유 현황</h4>
  <div class="board_write thead">
    <table>
      <caption>가스정제설비 보유 현황</caption>
      <colgroup>
        <col style="width: 20%;">
        <col style="width: 30%;">
        <col style="width: 20%;">
        <col style="width: 30%;">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">전 처리 설비 현황</th>
        <td class="txt_right" aria-label="전 처리 설비 현황">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.prprcsEquipCn" :maxlength="1000" validator="" align="right" unit="" panel="login" required />
        </td>
        <th scope="row">고질화 방법</th>
        <td class="txt_right" aria-label="고질화 방법">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.bgspgrMthdCn" :maxlength="500" validator="" align="right" unit="" panel="login" required />
        </td>
      </tr>
      <tr>
        <th scope="row">고질화 전 메탄농도</th>
        <td class="txt_right" aria-label="고질화 전 메탄농도">
          <br-form-input ref="bgspgrBfrMthnDensInput" label="" v-model="bioGasDtlObj.prfmncBioGas.bgspgrBfrMthnDens" :maxlength="8" validator="number" align="right" unit="%" panel="login" required />
        </td>
        <th scope="row">고질화 후 메탄농도</th>
        <td class="txt_right" aria-label="고질화 후 메탄농도">
          <br-form-input ref="bgspgrAftrMthnDensInput" label="" v-model="bioGasDtlObj.prfmncBioGas.bgspgrAftrMthnDens" :maxlength="8" validator="number" align="right" unit="%" panel="login" required />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h4 class="title3">소화 후 발생 폐기물 - 탈수케익</h4>
  <div class="board_write thead">
    <table>
      <caption>소화 후 발생 폐기물 - 탈수케익</caption>
      <colgroup>
        <col style="width: 20%;">
        <col style="width: 30%;">
        <col style="width: 20%;">
        <col style="width: 30%;">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">발생량</th>
        <td class="txt_right" aria-label="발생량">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.dwscPrdqty" :maxlength="15" validator="number" align="right" unit="톤" panel="login" required />
        </td>
        <th scope="row">발생량 함수율</th>
        <td class="txt_right" aria-label="발생량 함수율">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.dwscPrdctnMstcnt" :maxlength="6" validator="number" align="right" unit="%" panel="login" required />
        </td>
      </tr>
      <tr>
        <th scope="row">처리량</th>
        <td class="txt_right" aria-label="처리량">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.dwscPrcqty" :maxlength="15" validator="number" align="right" unit="톤" panel="login" required />
        </td>
        <th scope="row">처리방법</th>
        <td class="txt_right" aria-label="처리방법">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.dwscPrcsMthdCn" :maxlength="1000" validator="" align="right" unit="" panel="login" required />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h4 class="title3">소화 후 발생 폐기물 - 폐수</h4>
  <div class="board_write thead">
    <table>
      <caption>소화 후 발생 폐기물 - 폐수</caption>
      <colgroup>
        <col style="width: 20%;">
        <col style="width: 30%;">
        <col style="width: 20%;">
        <col style="width: 30%;">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">정화</th>
        <td class="txt_right" aria-label="정화">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.prfctnWstwtrPrdqty" :maxlength="15" validator="number" align="right" unit="톤" panel="login" required />
        </td>
        <th scope="row">액비</th>
        <td class="txt_right" aria-label="액비">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.liquidWstwtrPrdqty" :maxlength="15" validator="number" align="right" unit="톤" panel="login" required />
        </td>
      </tr>
      <tr>
        <th scope="row">위탁처리 방법</th>
        <td class="txt_right" aria-label="위탁처리 방법">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.wstwtrCnsgnPrcsMthdCn" :maxlength="500" validator="" align="right" unit="" panel="login" required />
        </td>
        <th scope="row">위탁처리시 설명</th>
        <td class="txt_right" aria-label="위탁처리시 설명">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.wstwtrCnsgnPrcsCn" :maxlength="500" validator="" align="right" unit="" panel="login" required />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h4 class="title3">시설운영 외부에너지 사용량</h4>
  <div class="board_write thead">
    <table>
      <caption>시설운영 외부에너지 사용량</caption>
      <colgroup>
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
      </colgroup>
      <thead>
      <tr>
        <th scope="col">유류(㎘)</th>
        <th scope="col">LNG(㎥)</th>
        <th scope="col">전기(MWh)</th>
        <th scope="col">기타(Gcal)</th>
        <th scope="col">기타종류</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="txt_right" aria-label="유류(㎘)">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.oilAsstFuelUsqty" :maxlength="15" validator="number" align="right" unit="㎘" panel="login" required />
        </td>
        <td class="txt_right" aria-label="LNG(㎥)">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.lngAsstFuelUsqty" :maxlength="15" validator="number" align="right" unit="㎥" panel="login" required />
        </td>
        <td class="txt_right" aria-label="전기(MWh)">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.prvtmAsstFuelUsqty" :maxlength="15" validator="number" align="right" unit="MWh" panel="login" required />
        </td>
        <td class="txt_right" aria-label="기타(Gcal)">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.etcAsstFuelUsqty" :maxlength="15" validator="number" align="right" unit="Gcal" panel="login" required />
        </td>
        <td class="txt_right" aria-label="기타종류">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.etcAsstFuelKndCn" :maxlength="500" validator="" align="right" unit="" panel="login" required />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info mt_40">
    <h3 class="title2">바이오가스 생산·이용 현황</h3>
  </div>
  <h4 class="title3">유량계 정보</h4>
  <div class="board_write thead">
    <table>
      <caption>유량계 정보</caption>
      <colgroup>
        <col style="width: 12%;">
        <col style="width: 22%;">
        <col style="width: 12%;">
        <col style="width: 20%;">
        <col style="width: 12%;">
        <col style="width: 22%;">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">유량계위치</th>
        <td class="txt_right" aria-label="유량계위치">
          <br-form-select label="유량계위치" groupCd="SIS071" v-model:value="bioGasDtlObj.prfmncBioGasPrdctn.flwmtrPstnCd" />
        </td>
        <th scope="row">Nm3여부</th>
        <td class="txt_right" aria-label="Nm3여부">
          <br-form-select label="Nm3여부" groupCd="COM412" v-model:value="bioGasDtlObj.prfmncBioGasPrdctn.nm3Yn" />
        </td>
        <th scope="row">유량계 교정일자</th>
        <td class="txt_right" aria-label="유량계 교정일자">
          <br-date-picker label="유량계 교정일자" v-model:value="bioGasDtlObj.prfmncBioGasPrdctn.flwmtrCrctYmd" format="YYYYMMDD" />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h4 class="title3">바이오가스 생산 현황</h4>
  <div class="board_write thead">
    <table>
      <caption>바이오가스 생산 현황</caption>
      <colgroup>
        <col style="width: 12%;">
        <col style="width: 88%;">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">총 생산량</th>
        <td class="txt_left" aria-label="총 생산량">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.enePrdqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" readonly required />
<!--          <div class="tooltip">-->
<!--            <button type="button" class="btn_tool">도움말</button>-->
<!--            <div class="tool_cont" style="display: none;">-->
<!--              <p>총 이용량(A) + 미이용(단순처리)(B)</p>-->
<!--              <button type="button" class="tool_close">닫기</button>-->
<!--            </div>-->
<!--          </div>-->
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h4 class="title3">바이오가스 이용 현황</h4>
  <div class="board_write thead">
    <table>
      <caption>바이오가스 이용 현황</caption>
      <colgroup>
        <col style="width: 12%;">
        <col style="width: 38%;">
        <col style="width: 20%;">
        <col style="width: 30%;">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">총 이용량(A)</th>
        <td class="txt_left" aria-label="총 이용량(A)">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.eneUtzqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" readonly required />
          <!--          <div class="tooltip">-->
          <!--            <button type="button" class="btn_tool">도움말</button>-->
          <!--            <div class="tool_cont" style="display: none;">-->
          <!--              <p>총 이용량(A) + 미이용(단순처리)(B)</p>-->
          <!--              <button type="button" class="tool_close">닫기</button>-->
          <!--            </div>-->
          <!--          </div>-->
        </td>
        <th scope="row">미이용(단순처리)(B)</th>
        <td class="txt_left" aria-label="미이용(단순처리)(B)">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.eneUnuqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" required />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h4 class="title3">자체사용 현황</h4>
  <div class="board_write thead">
    <table>
      <caption>자체사용 현황</caption>
      <colgroup>
        <col style="width: 15%;">
        <col>
        <col style="width: 15%;">
        <col>
        <col style="width: 15%;">
        <col>
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">자체이용 합계(C)</th>
        <td class="txt_left" colspan="5" aria-label="자체이용 합계(C)">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.gasStotUsqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" readonly required />
        </td>
      </tr>
      <tr>
        <th scope="row">발전</th>
        <td class="txt_left" aria-label="발전">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.elctgnUsgGasUsqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
        <th scope="row">소화조 가온용</th>
        <td class="txt_left" aria-label="소화조 가온용">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.dgstrWrmngUsgGasUsqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
        <th scope="row">슬러지 건조용</th>
        <td class="txt_left" aria-label="슬러지 건조용">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.sldgDhmdfyUsgGasUsqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
      </tr>
      <tr>
        <th scope="row">슬러지 소각용</th>
        <td class="txt_left" aria-label="슬러지 소각용">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.sldgFireUsgGasUsqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
        <th scope="row">음식물 건조용</th>
        <td class="txt_left" aria-label="음식물 건조용">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.foodDhmdfyUsgGasUsqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
        <th scope="row">악취제거</th>
        <td class="txt_left" aria-label="악취제거">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.bsmlRmvlUsgGasUsqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
      </tr>
      <tr>
        <th scope="row">스팀</th>
        <td class="txt_left" aria-label="스팀">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.stUsgGasUsqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
        <th scope="row">스팀용도</th>
        <td class="txt_left" colspan="3" aria-label="스팀용도">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.stUsgGasUseCn" :maxlength="500" validator="" align="right" unit="" panel="login" />
        </td>
      </tr>
      <tr>
        <th scope="row">기타</th>
        <td class="txt_left" aria-label="기타">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.etcUsgGasUsqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
        <th scope="row">기타설명</th>
        <td class="txt_left" colspan="3" aria-label="기타설명">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.etcUsgGasUseCn" :maxlength="500" validator="" align="right" unit="" panel="login" />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h4 class="title3">
    외부공급 현황
    <span class="txt_hint">※ 외부공급은 자체시설이 아닌 외부시설에 보낸 바이오가스 양 입력해 주세요.</span>
  </h4>
  <div class="board_write thead">
    <table>
      <caption>외부공급 현황</caption>
      <colgroup>
        <col style="width: 15%;">
        <col>
        <col style="width: 15%;">
        <col>
        <col style="width: 15%;">
        <col>
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">외부공급 합계(C)</th>
        <td class="txt_left" colspan="5" aria-label="외부공급 합계(C)">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.gasStotSupqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" readonly required />
        </td>
      </tr>
      <tr>
        <th scope="row">발전</th>
        <td class="txt_left" aria-label="발전">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.elctgnUsgGasSupqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
        <th scope="row">소화조 가온용</th>
        <td class="txt_left" aria-label="소화조 가온용">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.dgstrWrmngUsgGasSupqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
        <th scope="row">슬러지 건조용</th>
        <td class="txt_left" aria-label="슬러지 건조용">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.sldgDhmdfyUsgGasSupqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
      </tr>
      <tr>
        <th scope="row">슬러지 소각용</th>
        <td class="txt_left" aria-label="슬러지 소각용">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.sldgFireUsgGasSupqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
        <th scope="row">음식물 건조용</th>
        <td class="txt_left" aria-label="음식물 건조용">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.foodDhmdfyUsgGasSupqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
        <th scope="row">악취제거</th>
        <td class="txt_left" aria-label="악취제거">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.bsmlRmvlUsgGasSupqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
      </tr>
      <tr>
        <th scope="row">스팀</th>
        <td class="txt_left" aria-label="스팀">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.stUsgGasSupqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
        <th scope="row">스팀용도</th>
        <td class="txt_left" colspan="3" aria-label="스팀용도">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.stUsgGasSplyCn" :maxlength="500" validator="" align="right" unit="" panel="login" />
        </td>
      </tr>
      <tr>
        <th scope="row">기타</th>
        <td class="txt_left" aria-label="기타">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.etcUsgGasSupqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
        <th scope="row">기타설명</th>
        <td class="txt_left" colspan="3" aria-label="기타설명">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.etcUsgGasSplyCn" :maxlength="500" validator="" align="right" unit="" panel="login" />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info mt_40">
    <h3 class="title2">에너지 생산·판매량 현황</h3>
    <div>
      <br-button label="행 추가" size="xsm" format="primary line mr_5" @click="addNtslStts" />
      <br-button label="행 삭제" size="xsm" format="point line" @click="deleteNtslStts" />
    </div>
  </div>
  <div class="grid_search">
    <h4>
      이용자별 판매량 현황
      <span>(※ 도시가스사업법에 따른 1차 판매자를 입력해주세요.) , 판매량 현황 추가는 행추가 버튼을 눌러서 입력해 주세요.</span>
    </h4>
  </div>
  <sb-grid
      id="prfmncBioGasNtslGrid"
      :dataSource="dataSourceBioGasNtsl"
      :columns="gridColumnsBioGasNtsl"
      :gridattr="gridAttrBioGasNtsl"
      v-model:grid="prfmncBioGasNtslGridObject"
      @loaded="gridLoadedBioGasNtsl"
      :refreshGrid="gridReloadBioGasNtsl"
      @change="changeBioGasNtsl"
  />

  <h4 class="title3">전기 생산 ·판매량(MWh)</h4>
  <div class="board_write thead">
    <table>
      <caption>전기 생산 ·판매량(MWh)</caption>
      <colgroup>
        <col style="width: 12%;">
        <col style="width: 88%;">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">생산량</th>
        <td class="txt_left" aria-label="생산량">
          <div class="input_group">
          <br-form-input label="" v-model="bioGasDtlObj.bioGasEnePrfmnc.prvtmPrdqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" readonly />
          ( 자체이용
          <br-form-input label="" v-model="bioGasDtlObj.bioGasEnePrfmnc.prvtmUsqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
          + 판매량
          <br-form-input label="" v-model="bioGasDtlObj.bioGasEnePrfmnc.prvtmSumNsqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" readonly />
          )
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h4 class="title3">
    가스 생산 ·판매량(Gcal)
    <span class="txt_hint">※ 열량계가 있을 경우 생산된 열량 표시, 없을 경우 메탄농도로 환산해서 입력해 주세요.</span>
  </h4>
  <div class="board_write thead">
    <table>
      <caption>가스 생산 ·판매량(Gcal)</caption>
      <colgroup>
        <col style="width: 12%;">
        <col style="width: 38%;">
        <col style="width: 12%;">
        <col style="width: 38%;">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">생산량</th>
        <td class="txt_left" aria-label="생산량">
          <div class="input_group">
          <br-form-input label="" v-model="bioGasDtlObj.bioGasEnePrfmnc.gasPrdctnNm3" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
          <br-form-input label="" v-model="bioGasDtlObj.bioGasEnePrfmnc.gasPrdctnGcal" :maxlength="15" validator="number" align="right" unit="Gcal" panel="login" />
          </div>
        </td>
        <th scope="row">판매량</th>
        <td class="txt_left" aria-label="판매량">
          <div class="input_group">
          <br-form-input label="" v-model="bioGasDtlObj.bioGasEnePrfmnc.gasSumNsqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" readonly />
          <br-form-input label="" v-model="bioGasDtlObj.bioGasEnePrfmnc.gasNtslGcal" :maxlength="15" validator="number" align="right" unit="Gcal" panel="login" />
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h4 class="title3">
    증기 생산 ·판매량(Gcal)
    <span class="txt_hint">※ 열량계가 있을 경우 생산된 열량 표시, 없을 경우 포화증기 온도, 압력으로 환산해서 입력해 주세요.</span>
  </h4>
  <div class="board_write thead">
    <table>
      <caption>증기 생산 ·판매량(Gcal)</caption>
      <colgroup>
        <col style="width: 12%;">
        <col style="width: 38%;">
        <col style="width: 12%;">
        <col style="width: 38%;">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">생산량</th>
        <td class="txt_left" aria-label="생산량">
          <div class="input_group">
            <br-form-input label="" v-model="bioGasDtlObj.bioGasEnePrfmnc.vaporPrdctnTon" :maxlength="15" validator="number" align="right" unit="스팀톤" panel="login" />
            <br-form-input label="" v-model="bioGasDtlObj.bioGasEnePrfmnc.vaporPrdctnGcal" :maxlength="15" validator="number" align="right" unit="Gcal" panel="login" />
          </div>
        </td>
        <th scope="row">판매량</th>
        <td class="txt_left" aria-label="판매량">
          <div class="input_group">
            <br-form-input label="" v-model="bioGasDtlObj.bioGasEnePrfmnc.vaporSumNsqty" :maxlength="15" validator="number" align="right" unit="스팀톤" panel="login" readonly />
            <br-form-input label="" v-model="bioGasDtlObj.bioGasEnePrfmnc.vaporNtslGcal" :maxlength="15" validator="number" align="right" unit="Gcal" panel="login" />
          </div>
        </td>
      </tr>
      <tr>
        <th scope="row">포화증기온도</th>
        <td class="txt_left" aria-label="생산량">
          <br-form-input label="" v-model="bioGasDtlObj.bioGasEnePrfmnc.srtrtVaporTp" :maxlength="15" validator="number" align="right" unit="°C" panel="login" />
        </td>
        <th scope="row">포화증기압력</th>
        <td class="txt_left" aria-label="판매량">
          <br-form-input label="" v-model="bioGasDtlObj.bioGasEnePrfmnc.srtrtVaporPrssVl" :maxlength="15" validator="number" align="right" unit="kgf/㎠" panel="login" />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h4 class="title3">온수 생산 ·판매량(Gcal)</h4>
  <div class="board_write thead">
    <table>
      <caption>온수 생산 ·판매량(Gcal)</caption>
      <colgroup>
        <col style="width: 12%;">
        <col style="width: 38%;">
        <col style="width: 12%;">
        <col style="width: 38%;">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">생산량</th>
        <td class="txt_left" aria-label="생산량">
          <div class="input_group">
            <br-form-input label="" v-model="bioGasDtlObj.bioGasEnePrfmnc.htwtrPrdctnTon" :maxlength="15" validator="number" align="right" unit="스팀톤" panel="login" />
            <br-form-input label="" v-model="bioGasDtlObj.bioGasEnePrfmnc.htwtrPrdctnGcal" :maxlength="15" validator="number" align="right" unit="Gcal" panel="login" />
          </div>
        </td>
        <th scope="row">판매량</th>
        <td class="txt_left" aria-label="판매량">
          <div class="input_group">
            <br-form-input label="" v-model="bioGasDtlObj.bioGasEnePrfmnc.htwtrSumNsqty" :maxlength="15" validator="number" align="right" unit="스팀톤" panel="login" readonly />
            <br-form-input label="" v-model="bioGasDtlObj.bioGasEnePrfmnc.htwtrNtslGcal" :maxlength="15" validator="number" align="right" unit="Gcal" panel="login" />
          </div>
        </td>
      </tr>
      <tr>
        <th scope="row">포화증기온도</th>
        <td class="txt_left" aria-label="생산량">
          <br-form-input label="" v-model="bioGasDtlObj.bioGasEnePrfmnc.htwtrSrtrtVaporTp" :maxlength="15" validator="number" align="right" unit="°C" panel="login" />
        </td>
        <th scope="row">포화증기압력</th>
        <td class="txt_left" aria-label="판매량">
          <br-form-input label="" v-model="bioGasDtlObj.bioGasEnePrfmnc.htwtrSrtrtVaporPrssVl" :maxlength="15" validator="number" align="right" unit="kgf/㎠" panel="login" />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h4 class="title3">기타 생산 ·판매량(Gcal)</h4>
  <div class="board_write thead">
    <table>
      <caption>기타 생산 ·판매량(Gcal)</caption>
      <colgroup>
        <col style="width: 12%;">
        <col style="width: 38%;">
        <col style="width: 12%;">
        <col style="width: 38%;">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">생산량</th>
        <td class="txt_left" aria-label="생산량">
          <div class="input_group">
            <br-form-input label="" v-model="bioGasDtlObj.bioGasEnePrfmnc.etcEnePrdctnTon" :maxlength="15" validator="number" align="right" unit="스팀톤" panel="login" />
            <br-form-input label="" v-model="bioGasDtlObj.bioGasEnePrfmnc.etcEnePrdctnGcal" :maxlength="15" validator="number" align="right" unit="Gcal" panel="login" />
          </div>
        </td>
        <th scope="row">판매량</th>
        <td class="txt_left" aria-label="판매량">
            <br-form-input label="" v-model="bioGasDtlObj.bioGasEnePrfmnc.etcEneSumNsqty" :maxlength="15" validator="number" align="right" unit="Gcal" panel="login" readonly />
        </td>
      </tr>
      <tr>
        <th scope="row">기타 설명</th>
        <td class="txt_left" colspan="3" aria-label="생산량">
          <br-form-input label="" v-model="bioGasDtlObj.bioGasEnePrfmnc.etcEnePrdctnNtslCn" :maxlength="500" validator="" align="left" panel="login" />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <!-- 실적상태이력 현황 (신규등록인 경우 노출안함) -->
  <prfmnc-stts-hstry v-if="bioGasDtlObj.prfmncMst.prfmncId" :prfmncId="bioGasDtlObj.prfmncMst.prfmncId" />

  <!-- 수정허용요청 이력 현황 (신규등록인 경우 노출안함) -->
  <prfmnc-mdfcn-prm-hstry v-if="bioGasDtlObj.prfmncMst.prfmncId" :prfmncId="bioGasDtlObj.prfmncMst.prfmncId" />

  <!-- 버튼 -->
  <common-button-area
      :prfmncBasicInfo="bioGasDtlObj.prfmncMst"
      @save="prtmncRptDtlSave"
      @submission="prtmncRptDtlSave(true)"
  />
</template>

<script>
import SbGrid from "@/components/SbGrid.vue";
import utils from "@/js/utils/utils";
import commonButtonArea from "@/views/prfmnc-rpt/dtl/components/CommonButtonArea.vue";
import prfmncSttsHstry from "@/views/prfmnc-rpt/components/PrfmncSttsHstry.vue";
import PrfmncMdfcnPrmHstry from "@/views/prfmnc-rpt/components/PrfmncMdfcnPrmHstry.vue";
import PrfmncMdfcnPrmArea from "@/views/prfmnc-rpt/dtl/components/PrfmncMdfcnPrmArea.vue";
import RjctRsnArea from "@/views/prfmnc-rpt/dtl/components/RjctRsnArea.vue";

const ROUTE = {
  PERFORMANCE_LIST: "/prfmnc-rpt/PrfmncRpt",
  PERFORMANCE_DTL: "/prfmnc-rpt/dtl/BioGas"
};

const API = {
  PRFMNC_DTL_SAVE: "/prfmnc/bioGas", // 실적 합성가스 상세저장
};

const CODE = {
  PERFORMANCE_STATUS: "PFM001", // 실적상태코드
  ASSISTANCE_FUEL_KIND_GROUP: "PFM007", // 보조연료종류그룹코드
  ASSISTANCE_FUEL_KIND: "PFM008", // 보조연료종류코드
  ASSISTANCE_FUEL_UNIT: "PFM009", // 보조연료단위코드
  LOW_CALORIFIC_POWER_UNIT: "PFM010", // 저위발열량단위코드
  ENE_TYPE: "SIS068", // 에너지유형코드
  NTSL_TYPE: "SIS069", // 판매유형코드
  NTSL_SE: "SIS070", // 판매구분코드
  ITEM: "MBR010", // 품목코드
};

export default {
  components: {
    RjctRsnArea, PrfmncMdfcnPrmArea,
    SbGrid,
    commonButtonArea,
    prfmncSttsHstry,
    PrfmncMdfcnPrmHstry
  },
  props: ['prfmncBioGas', 'prfmncBioGasPrdctn', 'bioGasEnePrfmnc', 'prfmncBioGasWtCrynList', 'prfmncBioGasWtInputList', 'prfmncBioGasNtslList', 'prfmncMst', 'prfmncFclt', 'bioGasNtslEneSplyBzenty', 'asstFuelInputInfo', 'showDetail'],
  data() {
    return {
      asstFuelKndGroupCdList: [], // 보조연료종류그룹코드
      asstFuelKndCdList: [], // 보조연료종류코드
      asstFuelUnitCdList: [], // 보조연료단위코드
      lcpUnitCdList: [], // 저위발열량단위코드
      eneTypeCdList: [], // 에너지유형코드
      ntslTypeCdList: [], // 판매유형코드
      ntslSeCdList: [], // 판매구분코드
      itemCdList: [], // 품목코드

      // 실적 바이오가스 상세정보 데이터
      bioGasDtlObj:{
        totalPrvtmNsqty: 0,  // 에너지 생산·판매량 현황 전기판매량 합계
        totalGasNsqty: 0,  // 에너지 생산·판매량 현황 가스판매량 합계
        totalVaporNsqty: 0,  // 에너지 생산·판매량 현황 증기판매량 합계
        totalHtwtrNsqty: 0,  // 에너지 생산·판매량 현황 온수판매량 합계
        totalEtcNsqty: 0,  // 에너지 생산·판매량 현황 기타판매량 합계

        prfmncMst: {},
        prfmncBioGasWtCrynList : [], // 폐기물반입현황
        prfmncBioGasWtInputList : [], // 폐기물투입현황
        prfmncBioGasNtslList : [], // 바이오가스판매현황
        prfmncBioGas: {  // [바이오가스실적]
          digstnBfrVsDens: 0,  // 소화이전휘발성고형물농도
          digstnBfrSsDens: 0,  // 소화이전부유물질량농도
          digstnBfrBodDens: 0,  // 소화이전산소요구량농도
          digstnBfrTsDens: 0,  // 소화이전총고형물농도
          digstnAftrVsDens: 0,  // 소화이후휘발성고형물농도
          digstnAftrSsDens: 0,  // 소화이후부유물질량농도
          digstnAftrBodDens: 0,  // 소화이후산소요구량농도
          digstnAftrTsDens: 0,  // 소화이후총고형물농도
          prprcsEquipCn: '',  // 전처리설비내용
          bgspgrBfrMthnDens: 0,  // 바이오가스고질화이전메탄농도
          bgspgrMthdCn: '',  // 바이오가스고질화방법내용
          bgspgrAftrMthnDens: 0,  // 바이오가스고질화이후메탄농도
          dwscPrdqty: 0,  // 탈수케이크생산량
          dwscPrdctnMstcnt: 0,  // 탈수케이크생산함수율
          dwscPrcqty: 0,  // 탈수케이크처리량
          dwscPrcsMthdCn: '',  // 탈수케이크처리방법내용
          prfctnWstwtrPrdqty: 0,  // 정화폐수생산량
          liquidWstwtrPrdqty: 0,  // 액비폐수생산량
          wstwtrCnsgnPrcsMthdCn: '',  // 폐수위탁처리방법내용
          wstwtrCnsgnPrcsCn: '',  // 폐수위탁처리내용
          oilAsstFuelUsqty: 0,  // 유류보조연료사용량
          lngAsstFuelUsqty: 0,  // LNG보조연료사용량
          prvtmAsstFuelUsqty: 0,  // 전기보조연료사용량
          etcAsstFuelUsqty: 0,  // 기타보조연료사용량
          etcAsstFuelKndCn: '',  // 기타보조연료종류내용
        },
        prfmncBioGasPrdctn: {  // [바이오가스생산실적]
          flwmtrPstnCd: '',  // 유량계위치코드
          flwmtrPstnNm: '',  // 유량계위치코드명
          nm3Yn: '',  // 노멀세제곱미터여부
          flwmtrCrctYmd: '',  // 유량계보정일자
          gasStotUsqty: 0,  // 가스소계사용량
          elctgnUsgGasUsqty: 0,  // 발전용도가스사용량
          dgstrWrmngUsgGasUsqty: 0,  // 소화조가온용도가스사용량
          sldgDhmdfyUsgGasUsqty: 0,  // 슬러지건조용도가스사용량
          sldgFireUsgGasUsqty: 0,  // 슬러지소각용도가스사용량
          foodDhmdfyUsgGasUsqty: 0,  // 식품건조용도가스사용량
          bsmlRmvlUsgGasUsqty: 0,  // 악취제거용도가스사용량
          stUsgGasUsqty: 0,  // 스팀용도가스사용량
          stUsgGasUseCn: '',  // 스팀용도가스사용내용
          etcUsgGasUsqty: 0,  // 기타용도가스사용량
          etcUsgGasUseCn: '',  // 기타용도가스사용내용
          gasStotSupqty: 0,  // 가스소계공급량
          elctgnUsgGasSupqty: 0,  // 발전용도가스공급량
          dgstrWrmngUsgGasSupqty: 0,  // 소화조가온용도가스공급량
          sldgDhmdfyUsgGasSupqty: 0,  // 슬러지건조용도가스공급량
          sldgFireUsgGasSupqty: 0,  // 슬러지소각용도가스공급량
          foodDhmdfyUsgGasSupqty: 0,  // 식품건조용도가스공급량
          bsmlRmvlUsgGasSupqty: 0,  // 악취제거용도가스공급량
          stUsgGasSupqty: 0,  // 스팀용도가스공급량
          stUsgGasSplyCn: '',  // 스팀용도가스공급내용
          etcUsgGasSupqty: 0,  // 기타용도가스공급량
          etcUsgGasSplyCn: '',  // 기타용도가스공급내용
          enePrdqty: 0,  // 에너지생산량
          eneUtzqty: 0,  // 에너지이용량
          eneUnuqty: 0,  // 에너지미이용량
        },
        bioGasEnePrfmnc: {
          prvtmPrdqty: 0,  // 전기생산량
          prvtmUsqty: 0,  // 전기사용량
          prvtmSumNsqty: 0,  // 전기합계판매량
          prvtmNtslSumAmt: 0,  // 전기판매합계금액 (화면미노출이나 저장필요)
          gasPrdctnNm3: 0,  // 가스생산노멀세제곱미터
          gasPrdctnGcal: 0,  // 가스생산기가칼로리
          gasSumNsqty: 0,  // 가스합계판매량
          gasNtslGcal: 0,  // 가스판매기가칼로리
          gasNtslSumAmt: 0,  // 가스판매합계금액 (화면미노출이나 저장필요)
          vaporPrdctnTon: 0,  // 증기생산톤수
          vaporPrdctnGcal: 0,  // 증기생산기가칼로리
          vaporSumNsqty: 0,  // 증기합계판매량
          vaporNtslGcal: 0,  // 증기판매기가칼로리
          srtrtVaporTp: 0,  // 포화증기온도
          srtrtVaporPrssVl: 0,  // 포화증기압력값
          vaporNtslSumAmt: 0,  // 증기판매합계금액 (화면미노출이나 저장필요)
          htwtrPrdctnTon: 0,  // 온수생산톤수
          htwtrPrdctnGcal: 0,  // 온수생산기가칼로리
          htwtrSumNsqty: 0,  // 온수합계판매량
          htwtrNtslGcal: 0,  // 온수판매기가칼로리
          htwtrSrtrtVaporTp: 0,  // 온수포화증기온도
          htwtrSrtrtVaporPrssVl: 0,  // 온수포화증기압력값
          htwtrNtslSumAmt: 0,  // 온수판매합계금액 (화면미노출이나 저장필요)
          etcEnePrdctnTon: 0,  // 기타에너지생산톤수
          etcEnePrdctnGcal: 0,  // 기타에너지생산기가칼로리
          etcEneSumNsqty: 0,  // 기타에너지합계판매량
          etcEnePrdctnNtslCn: '',  // 기타에너지생산판매내용
          etcEneNtslSumAmt: 0,  // 기타에너지판매합계금액 (화면미노출이나 저장필요)
        },
        asstFuelInputInfo: {  // [보조연료 투입현황]
        },
      },


      /**************** S : 폐기물반입현황 Grid ****************/
      dataSourceBioGasWtCryn: [],
      gridColumnsBioGasWtCryn: [],
      gridAttrBioGasWtCryn: {
        checkable: false,
        pageable: false,
        editable: {eventType: 'mouseup'},
        showRowNo : false,
        mergeRow: true,
        excelExport: {
          fileName: '폐기물반입현황(바이오가스).xlsx',
          includeHeader: true,
          keepMerge: true,
        },
        showStatus:true
      },
      prfmncBioGasWtCrynGridObject: null,
      gridReloadBioGasWtCryn: false,
      /**************** E : 폐기물반입현황 Grid ****************/

      /**************** S : 보조연료 투입 현황 Grid ****************/
      asstFuelInputGridReload: false,
      asstFuelInputGridObject: null,
      asstFuelInputDataSource: [],
      asstFuelInputGridColumns: [],
      asstFuelInputGridAttr: {
        showRowNo: false,
        checkable: true,
        pageable: false,
        editable: {eventType: 'mouseup'},
        toolBar: [],
        hideDeleted: true,
        showStatus:true
      },
      /**************** E : 보조연료 투입 현황 Grid ****************/

      /**************** S : 바이오가스판매현황 Grid ****************/
      dataSourceBioGasNtsl: [],
      gridColumnsBioGasNtsl: [],
      gridAttrBioGasNtsl: {
        checkable: true,
        pageable: false,
        editable: {eventType: 'mouseup'},
        showRowNo : false,
        excelExport: {
          fileName: '에너지생산판매량현황(바이오가스).xlsx',
          includeHeader: true,
          includeFormula: true,
        },
        showStatus:true
      },
      prfmncBioGasNtslGridObject: null,
      gridReloadBioGasNtsl: false,
      /**************** E : 바이오가스판매현황 Grid ****************/

      
      lastSum : '', // 반입현황 유효성 체크
    };
  },
  computed: {
    /**
     * [실적보고상세 - 실적연월]
     * YYYY-MM 포맷처리
     */
    formattedPrfmncYm() {
      return (this.bioGasDtlObj.prfmncMst.prfmncYm?.substring(0, 4) ?? "") + "-" + (this.bioGasDtlObj.prfmncMst.prfmncYm?.substring(4, 6) ?? "");
    },
    // 폐기물 투입현황 투입량검증 초과용량(톤) 계산
    excessCapacityList() {
      if (this.bioGasDtlObj.prfmncBioGasWtInputList && this.bioGasDtlObj.prfmncBioGasWtInputList.inputItemList) {
        return this.bioGasDtlObj.prfmncBioGasWtInputList.inputItemList.map(item => {
          const facilityCapacity = item.dilyFcltCap || 0; // 시설용량
          const inputQuantity = item.wtInpqty || 0; // 투입량
          const excessCapacity = Math.max(0, inputQuantity - facilityCapacity); // 초과용량 계산 (음수인 경우 0으로 처리)
          return {
            ...item,
            excessCapacity: excessCapacity
          };
        });
      } else {
        return 0;
      }
    },
    // 폐기물투입현황 기타종류 값 처리
    etcWtKndCn: {
      get() {
        const item = this.bioGasDtlObj.prfmncBioGasWtInputList.inputItemList?.find(item => item.inputItemCd.includes('9999'));
        return item?.etcWtKndCn ?? '';
      },
      set(value) {
        const item = this.bioGasDtlObj.prfmncBioGasWtInputList.inputItemList?.find(item => item.inputItemCd.includes('9999'));
        if (item) {
          item.etcWtKndCn = value;
        }
      }
    }
  },
  watch: {
    prfmncMst: {
      handler(newVal) {
        this.bioGasDtlObj.prfmncMst = {...this.bioGasDtlObj.prfmncMst, ...newVal};
      },
      immediate: true,
      deep:true,
    },
    prfmncBioGas() {
      this.bioGasDtlObj.prfmncBioGas = {...this.bioGasDtlObj.prfmncBioGas, ...this.prfmncBioGas};
    },
    prfmncBioGasPrdctn() {
      this.bioGasDtlObj.prfmncBioGasPrdctn = {...this.bioGasDtlObj.prfmncBioGasPrdctn, ...this.prfmncBioGasPrdctn};
    },
    bioGasEnePrfmnc() {
      this.bioGasDtlObj.bioGasEnePrfmnc = {...this.bioGasDtlObj.bioGasEnePrfmnc, ...this.bioGasEnePrfmnc};
    },
    prfmncBioGasWtCrynList() {
      const bioGasWtCrynListCopy = this.prfmncBioGasWtCrynList.list.map(item => ({ ...item }));

      // `0020`, `0021`, `0022`로 시작하는 값들의 최대값 계산
      const keysToCheck = ['0020', '0021', '0022'];
      const maxValues = {};

      keysToCheck.forEach(keyPrefix => {
        maxValues[keyPrefix] = Math.max(
            ...bioGasWtCrynListCopy.map(item => {
              // keyPrefix로 시작하는 키를 찾아 해당 값을 가져옴
              const key = Object.keys(item).find(k => k.startsWith(keyPrefix));
              return key ? (item[key] || 0) : 0;
            })
        );
      });

      // 각 항목에 최대값 설정
      const modifiedBioGasWtCrynList = bioGasWtCrynListCopy.map(item => {
        keysToCheck.forEach(keyPrefix => {
          const key = Object.keys(item).find(k => k.startsWith(keyPrefix));
          if (key) {
            item[key] = maxValues[keyPrefix]; // 최대값 설정
          }
        });
        return item;
      });

      this.dataSourceBioGasWtCryn = { data: modifiedBioGasWtCrynList, schema: { id: 'sggStdgCd'}};
      this.initializeprfmncBioGasWtCrynGridColumns();
      const itemCount = this.prfmncBioGasWtCrynList.list.length;
      this.gridAttrBioGasWtCryn.height = Math.max((itemCount * 30) + 150, 210);
      this.gridReloadBioGasWtCryn = true;
    },
    prfmncBioGasWtInputList() {
      this.bioGasDtlObj.prfmncBioGasWtInputList = {...this.bioGasDtlObj.prfmncBioGasWtInputList, ...this.prfmncBioGasWtInputList};
    },
    prfmncBioGasNtslList() {
      this.dataSourceBioGasNtsl = this.prfmncBioGasNtslList.list;
      this.initializeprfmncBioGasNtslGridColumns();
      const itemCount = this.prfmncBioGasNtslList.list.length;
      this.gridAttrBioGasNtsl.height = Math.max((itemCount * 30) + 190, 270);
      this.gridReloadBioGasNtsl = true;
    },

    asstFuelInputInfo(){
      this.asstFuelInputDataSource = this.asstFuelInputInfo.list;
      this.initializeAsstFuelInputGridColumns();
      const itemCount = this.asstFuelInputInfo.list.length;
      // this.asstFuelInputGridAttr.height = (itemCount * 30) + 136;
      this.asstFuelInputGridAttr.height = Math.max((itemCount * 30) + 190, 210);
      this.asstFuelInputGridReload = true;
    },

    // 총이용량 값 변경 시 총생산량 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.eneUtzqty': 'updateEnePrdqty',
    // 미이용(단순처리) 값 변경 시 총생산량 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.eneUnuqty': 'updateEnePrdqty',

    // 자체사용합계 값 변경 시 총이용량 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.gasStotUsqty': 'updateEneUtzqty',
    // 외부공급합계 값 변경 시 총이용량 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.gasStotSupqty': 'updateEneUtzqty',


    // 자체사용 발전 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.elctgnUsgGasUsqty': 'updateGasStotUsqty',
    // 자체사용 소화조가온용 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.dgstrWrmngUsgGasUsqty': 'updateGasStotUsqty',
    // 자체사용 슬러지건조용 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.sldgDhmdfyUsgGasUsqty': 'updateGasStotUsqty',
    // 자체사용 슬러지소각용 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.sldgFireUsgGasUsqty': 'updateGasStotUsqty',
    // 자체사용 음식물건조용 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.foodDhmdfyUsgGasUsqty': 'updateGasStotUsqty',
    // 자체사용 악취제거 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.bsmlRmvlUsgGasUsqty': 'updateGasStotUsqty',
    // 자체사용 스팀 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.stUsgGasUsqty': 'updateGasStotUsqty',
    // 자체사용 기타 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.etcUsgGasUsqty': 'updateGasStotUsqty',

    // 외부공급 발전 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.elctgnUsgGasSupqty': 'updateGasStotSupqty',
    // 외부공급 소화조가온용 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.dgstrWrmngUsgGasSupqty': 'updateGasStotSupqty',
    // 외부공급 슬러지건조용 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.sldgDhmdfyUsgGasSupqty': 'updateGasStotSupqty',
    // 외부공급 슬러지소각용 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.sldgFireUsgGasSupqty': 'updateGasStotSupqty',
    // 외부공급 음식물건조용 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.foodDhmdfyUsgGasSupqty': 'updateGasStotSupqty',
    // 외부공급 악취제거 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.bsmlRmvlUsgGasSupqty': 'updateGasStotSupqty',
    // 외부공급 스팀 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.stUsgGasSupqty': 'updateGasStotSupqty',
    // 외부공급 기타 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.etcUsgGasSupqty': 'updateGasStotSupqty',

    // 전기판매량 값 변경 시 전기생산량 재계산
    'bioGasDtlObj.bioGasEnePrfmnc.prvtmUsqty': 'updatePrvtmPrdqty',
    // 전기자체이용 값 변경 시 전기생산량 재계산
    'bioGasDtlObj.bioGasEnePrfmnc.prvtmSumNsqty': 'updatePrvtmPrdqty',
  },
  created() {
    this.initialize();
  },
  mounted() {
  },
  methods: {
    dmndRtrcnCmptn() {
      location.reload();
    },
    // 에너지 생산·판매량 현황 onChange
    changeBioGasNtsl(values){
      values.forEach(item => {

        if (item.field === 'eneBzentySn') {
          // 그리드의 판매업체명 변경 시 visible false로 설정되어있는 항목(실적아이디, 사업장아이디, 시도법정동코드, 시군구법정동코드) 필드 값 처리
          const filteredBzentyList = [...this.bioGasNtslEneSplyBzenty, ...this.prfmncBioGasNtslList.list].filter(o => o.eneBzentySn === item.value);
          const changeDataList = [
            { key: item.key, field: 'prfmncId', value: this.bioGasDtlObj.prfmncMst.prfmncId },
            { key: item.key, field: 'bplcId', value: this.bioGasDtlObj.prfmncMst.bplcId },
            { key: item.key, field: 'ctpvStdgCd', value: filteredBzentyList[0].ctpvStdgCd },
            { key: item.key, field: 'sggStdgCd', value: filteredBzentyList[0].sggStdgCd }
          ];
          window.SBGrid3.setValues(this.prfmncBioGasNtslGridObject, changeDataList);
        }

        if (item.field === 'prvtmNsqty') {
          // 그리드의 모든 데이터를 가져와서 전기판매량 항목의 모든 값의 합계를 계산
          const ntslAllItems = window.SBGrid3.getAllItems(this.prfmncBioGasNtslGridObject);
          const totalPrvtmNsqty = ntslAllItems.reduce((sum, item) => {
            return sum + (item.prvtmNsqty || 0);
          }, 0);

          this.bioGasDtlObj.bioGasEnePrfmnc.prvtmSumNsqty = totalPrvtmNsqty;
        }

        if (item.field === 'gasNsqty') {
          // 그리드의 모든 데이터를 가져와서 가스판매량 항목의 모든 값의 합계를 계산
          const ntslAllItems = window.SBGrid3.getAllItems(this.prfmncBioGasNtslGridObject);
          const totalGasNsqty = ntslAllItems.reduce((sum, item) => {
            return sum + (item.gasNsqty || 0);
          }, 0);

          this.bioGasDtlObj.bioGasEnePrfmnc.gasSumNsqty = totalGasNsqty;
        }

        if (item.field === 'vaporNsqty') {
          // 그리드의 모든 데이터를 가져와서 증기판매량 항목의 모든 값의 합계를 계산
          const ntslAllItems = window.SBGrid3.getAllItems(this.prfmncBioGasNtslGridObject);
          const totalVaporNsqty = ntslAllItems.reduce((sum, item) => {
            return sum + (item.vaporNsqty || 0);
          }, 0);

          this.bioGasDtlObj.bioGasEnePrfmnc.vaporSumNsqty = totalVaporNsqty;
        }

        if (item.field === 'htwtrNsqty') {
          // 그리드의 모든 데이터를 가져와서 온수판매량 항목의 모든 값의 합계를 계산
          const ntslAllItems = window.SBGrid3.getAllItems(this.prfmncBioGasNtslGridObject);
          const totalHtwtrNsqty = ntslAllItems.reduce((sum, item) => {
            return sum + (item.htwtrNsqty || 0);
          }, 0);

          this.bioGasDtlObj.bioGasEnePrfmnc.htwtrSumNsqty = totalHtwtrNsqty;
        }

        if (item.field === 'etcEneNsqty') {
          // 그리드의 모든 데이터를 가져와서 가스판매량 항목의 모든 값의 합계를 계산
          const ntslAllItems = window.SBGrid3.getAllItems(this.prfmncBioGasNtslGridObject);
          const totalEtcNsqty = ntslAllItems.reduce((sum, item) => {
            return sum + (item.etcEneNsqty || 0);
          }, 0);

          this.bioGasDtlObj.bioGasEnePrfmnc.etcEneSumNsqty = totalEtcNsqty;
        }
      });
    },
    // 에너지 생산·판매량 현황 행추가
    addNtslStts() {
      const addRowData = {
        ntslStotAmt: 0,
        prvtmNsqty: 0,
        prvtmNtslAmt: 0,
        gasNsqty: 0,
        gasNtslAmt: 0,
        vaporNsqty: 0,
        vaporNtslAmt: 0,
        htwtrNsqty: 0,
        htwtrNtslAmt: 0,
        etcEneNsqty: 0,
        etcEneNtslAmt: 0,
      };

      window.SBGrid3.addRow(this.prfmncBioGasNtslGridObject, '', addRowData);
    },
    // 에너지 생산·판매량 현황 행삭제
    deleteNtslStts() {
      const deleteNtslInputKeys = window.SBGrid3.getCheckedKeys(this.prfmncBioGasNtslGridObject);

      if (deleteNtslInputKeys.length === 0) {
        alert("선택된 에너지 생산·판매량 현황 정보가 없습니다.");
        return;
      }

      window.SBGrid3.deleteRows(this.prfmncBioGasNtslGridObject, deleteNtslInputKeys);
    },
    // 바이오가스생산현황 (총생산량 계산)
    updateEnePrdqty(){
      this.bioGasDtlObj.prfmncBioGasPrdctn.enePrdqty =
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.eneUtzqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.eneUnuqty);
    },
    // 바이오가스이용현황 (총이용량 계산)
    updateEneUtzqty() {
      this.bioGasDtlObj.prfmncBioGasPrdctn.eneUtzqty =
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.gasStotUsqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.gasStotSupqty);
    },
    // 자체사용현황 (자체이용합계 계산)
    updateGasStotUsqty() {
      this.bioGasDtlObj.prfmncBioGasPrdctn.gasStotUsqty =
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.elctgnUsgGasUsqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.dgstrWrmngUsgGasUsqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.sldgDhmdfyUsgGasUsqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.sldgFireUsgGasUsqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.foodDhmdfyUsgGasUsqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.bsmlRmvlUsgGasUsqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.stUsgGasUsqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.etcUsgGasUsqty);
    },
    // 외부공급현황 (외부공급합계 계산)
    updateGasStotSupqty() {
      this.bioGasDtlObj.prfmncBioGasPrdctn.gasStotSupqty =
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.elctgnUsgGasSupqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.dgstrWrmngUsgGasSupqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.sldgDhmdfyUsgGasSupqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.sldgFireUsgGasSupqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.foodDhmdfyUsgGasSupqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.bsmlRmvlUsgGasSupqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.stUsgGasSupqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.etcUsgGasSupqty);
    },
    // 에너지 생산·판매량 현황 (총이용량 계산)
    updatePrvtmPrdqty() {
      this.bioGasDtlObj.bioGasEnePrfmnc.prvtmPrdqty =
          parseFloat(this.bioGasDtlObj.bioGasEnePrfmnc.prvtmUsqty) +
          parseFloat(this.bioGasDtlObj.bioGasEnePrfmnc.prvtmSumNsqty);
    },
    async initialize() {
      this.loadAsstFuelKndGroupCd();
      this.loadAsstFuelKndCd();
      this.loadAsstFuelUnitCd();
      this.loadLcpUnitCd();
      this.loadEneTypeCd();
      this.loadNtslTypeCd();
      this.loadNtslSeCd();
      this.loadItemCd();
    },
    // 보조연료종류그룹코드(PFM007) 로드
    loadAsstFuelKndGroupCd() {
      const asstFuelKndGroupCdList = this.$store.getters.getCodeList({ groupCd: CODE.ASSISTANCE_FUEL_KIND_GROUP, useYn: 'Y' });
      this.asstFuelKndGroupCdList = asstFuelKndGroupCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    // 보조연료종류코드(PFM008) 로드
    loadAsstFuelKndCd() {
      const asstFuelKndCdList = this.$store.getters.getCodeList({ groupCd: CODE.ASSISTANCE_FUEL_KIND, useYn: 'Y' });
      this.asstFuelKndCdList = asstFuelKndCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd,
        frstRefVl: item.frstRefVl,
      }));
    },
    // 보조연료단위코드(PFM009) 로드
    loadAsstFuelUnitCd() {
      const asstFuelUnitCdList = this.$store.getters.getCodeList({ groupCd: CODE.ASSISTANCE_FUEL_UNIT, useYn: 'Y' });
      this.asstFuelUnitCdList = asstFuelUnitCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd,
        frstRefVl: item.frstRefVl,
      }));
    },
    // 저위발열량단위코드(PFM010) 로드
    loadLcpUnitCd() {
      const lcpUnitCdList = this.$store.getters.getCodeList({ groupCd: CODE.LOW_CALORIFIC_POWER_UNIT, useYn: 'Y' });
      this.lcpUnitCdList = lcpUnitCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd,
        frstRefVl: item.frstRefVl,
      }));
    },
    // 에너지유형코드(SIS068) 로드
    loadEneTypeCd() {
      const cdList = this.$store.getters.getCodeList({ groupCd: CODE.ENE_TYPE, useYn: 'Y' });
      this.eneTypeCdList = cdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd,
        frstRefVl: item.frstRefVl,
      }));
    },
    // 판매유형코드(SIS069) 로드
    loadNtslTypeCd() {
      const cdList = this.$store.getters.getCodeList({ groupCd: CODE.NTSL_TYPE, useYn: 'Y' });
      this.ntslTypeCdList = cdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd,
        frstRefVl: item.frstRefVl,
      }));
    },
    // 판매구분코드(SIS070) 로드
    loadNtslSeCd() {
      const cdList = this.$store.getters.getCodeList({ groupCd: CODE.NTSL_SE, useYn: 'Y' });
      this.ntslSeCdList = cdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd,
        frstRefVl: item.frstRefVl,
      }));
    },
    // 품목코드(MBR010) 로드
    loadItemCd() {
      const cdList = this.$store.getters.getCodeList({ groupCd: CODE.ITEM, useYn: 'Y' });
      this.itemCdList = cdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd,
        frstRefVl: item.frstRefVl,
        scdRefVl: item.scdRefVl,
        thdRefVl: item.thdRefVl,
      }));
    },
    // 폐기물반입현황 그리드 컬럼 세팅
    initializeprfmncBioGasWtCrynGridColumns() {
      const columnCount = this.prfmncBioGasWtCrynList.inputItemList.length + 3;
      const columnWidth = columnCount > 0 ? (100 / columnCount).toFixed(2) : 100;

      this.gridColumnsBioGasWtCryn = [
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        {
          caption: '반입',
          columns : [
            { field: 'ctpvStdgNm', caption: '시·도', minWidth: 100, width:columnWidth, unit: '%', editable: false, },
            { field: 'sggStdgNm', caption: '시·군·구', minWidth: 100, width:columnWidth, unit: '%', editable: false, }
          ],
          total: {
            aggregates: [],
            header: [{ template: '계', align: 'right' }],
            headerCss:'grid-header',
          },
        },
      ];

      if (this.prfmncBioGasWtCrynList.inputItemList && this.prfmncBioGasWtCrynList.inputItemList.length > 0) {

        const inputItemCds = this.prfmncBioGasWtCrynList.inputItemList.filter(o => !(o.frstRefVl && !o.scdRefVl && o.thdRefVl)).map(item => item.inputItemCd);

        this.gridColumnsBioGasWtCryn.push({
          field: 'wtCrynSum', caption: '반입량 합계(톤)',
          editable: false,
          minWidth: 100, width:columnWidth, unit: '%',
          colCss: 'grid-disabled', align: 'right', format: '#,##0.00',
          total: {
            aggregates: [{
              func: (items) => {
                // 필터링된 inputItemList에서 조건을 만족하는 key들 추출
                const excludedKeys = this.prfmncBioGasWtCrynList.inputItemList
                    .filter(item => item.thdRefVl !== 'GRP_SLDG') // 조건: thdRefVl !== 'GRP_SLDG'
                    .map(item => item.inputItemCd);

                // items 배열을 순회하며 excludedKeys의 값을 합산
                const totalSum = items.reduce((sum, item) => {
                  excludedKeys.forEach(key => {
                    if (item[key] !== undefined && typeof item[key] === 'number') {
                      sum += item[key]; // 해당 key에 해당하는 값 합산
                    }
                  });

                  if (item.seq === 0) {
                    Object.keys(item).forEach(key => {
                      if (
                          /^002(0|1|2)/.test(key) && // 키가 '0020', '0021', '0022'로 시작
                          typeof item[key] === 'number' // 값이 숫자인지 확인
                      ) {
                        sum += item[key]; // 해당 값 합산
                      }
                    });
                  }

                  return sum;
                }, 0);

                this.lastSum = totalSum;
                return totalSum;
              },
              field: 'total'
            }],
            header: [{ template: '{{total}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
          calc: {
            eval: (data) => {
              // inputItemCd가 0020, 0021, 0022로 시작하는 것을 제외하고 더함
              return inputItemCds.reduce((sum, key) => {
                if (!/^002[0-2]/.test(key)) {
                  const value = data[key] || 0; // 값이 없으면 0으로 처리
                  return sum + value;
                }
                return sum; // 제외된 항목은 더하지 않음
              }, 0);
            },
            nullAs: 0,
            dataType: 'number',
          },
        });

        this.prfmncBioGasWtCrynList.inputItemList.forEach(item => {

          if(item.frstRefVl === 'BIOWT' && item.thdRefVl === 'GRP_SLDG'){
            return;
          }

          let columnsObj = {};

          if(item.inputItemCd.includes('9999')){
            columnsObj = {
              caption: '기타',
              columns : [
                {
                  field: item.inputItemCd,
                  caption: item.inputItemNm+'(톤)',
                  minWidth: 100,
                  width: columnWidth,
                  unit: '%',
                  dataType: 'number',
                  total: {
                    aggregates: [ {func: 'sum', nullAs: 0} ],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                  align: 'right',
                  format: '#,##0.00'
                },
                {
                  field: 'etcWtKndCn',
                  caption: '기타종류',
                  minWidth: 100,
                  width: columnWidth,
                  unit: '%',
                  align: 'left',
                  total: {
                    aggregates: [],
                    header: [],
                    headerCss: 'grid-header',
                  },
                },
              ]
            }
          } else {
            if (item.thdRefVl === 'GRP_SLDG') {
              columnsObj = {
                field: item.inputItemCd,
                caption: item.inputItemNm + '(톤)',
                width: columnWidth,
                unit: '%',
                dataType: 'number',
                total: {
                  aggregates: [{func: 'max', nullAs: 0}],
                  header: [{template: '{{max}}', format: '#,##0.00'}],
                  headerCss: 'grid-header',
                },
                align: 'right',
                format: '#,##0.00',
                mergeRow: true,
                editable: (data) => data.seq === 0
              }
            } else {
              columnsObj = {
                field: item.inputItemCd,
                caption: item.inputItemNm + '(톤)',
                width: columnWidth,
                unit: '%',
                dataType: 'number',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0}],
                  header: [{template: '{{sum}}', format: '#,##0.00'}],
                  headerCss: 'grid-header',
                },
                align: 'right',
                format: '#,##0.00',
              }
            }
          }

          this.gridColumnsBioGasWtCryn.push(columnsObj);
        });
      }
    },
    // 에너지 생산·판매량 현황 그리드 컬럼 세팅
    initializeprfmncBioGasNtslGridColumns() {
      this.gridColumnsBioGasNtsl = [
        { field: 'prfmncId', caption: '실적아이디', visible: false, editable: false },
        { field: 'bplcId', caption: '사업장아이디', visible: false, editable: false },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        {
          caption: '판매업체',
          part: 'head',
          columns : [
            {
              field: 'eneBzentySn',
              caption: '판매업체명',
              width: 250,
              align: 'left',
              type: 'combo',
              items: [...this.bioGasNtslEneSplyBzenty, ...this.prfmncBioGasNtslList.list].reduce((acc, curr) => {
                const index = acc.findIndex(item => {
                  return item._custom ? item._custom.value.eneBzentySn === curr.eneBzentySn : item.eneBzentySn === curr.eneBzentySn;
                });

                if (index === -1) {
                  // 중복 안되는 경우 추가
                  acc.push(curr);
                } else {
                  // 중복되는 경우 값 변경
                  acc[index] = curr;
                }
                return acc;
              }, []),
              comboLabel: 'bzentyNm',
              comboValue: 'eneBzentySn',
              setValue: {

              }
            },
            {
              field: 'eneTypeCd',
              caption: '에너지유형',
              width: 100,
              type: 'combo',
              items: this.eneTypeCdList,
              comboLabel: 'text',
              comboValue: 'value',
            },
            {
              field: 'ntslTypeCd',
              caption: '판매유형',
              width: 100,
              type: 'combo',
              items: this.ntslTypeCdList,
              comboLabel: 'text',
              comboValue: 'value',
            },
            {
              field: 'ntslSeCd',
              caption: '판매구분',
              width: 100,
              type: 'combo',
              items: this.ntslSeCdList,
              comboLabel: 'text',
              comboValue: 'value',
            },
          ],
          total: {
            aggregates: [
            ],
            header: [
              { template: '계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        {
          caption: '에너지 판매현황',
          columns : [
            {
              field: 'ntslStotAmt',
              caption: '총 판매금액(원)',
              width: 100,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              calc: {
                require: ['prvtmNtslAmt', 'gasNtslAmt', 'vaporNtslAmt', 'htwtrNtslAmt', 'etcEneNtslAmt'],
                eval: 'sum',
                nullAs: 0,
                dataType: 'number',
              },
              format: '#,##0.00',
            },
            {
              caption: '전기(MWh,원)', captionCss:'gridh-bg-electricity',
              columns : [
                {
                  field: 'prvtmNsqty',
                  caption: '판매량',
                  width: 100,
                  align: 'right',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      { func: 'sum', nullAs: 0 },
                    ],
                    header: [
                      { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-electricity',
                },
                {
                  field: 'prvtmNtslAmt',
                  caption: '판매금액',
                  width: 100,
                  align: 'right',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      { func: 'sum', nullAs: 0 },
                    ],
                    header: [
                      { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-electricity',
                },
              ]
            },
            {
              caption: '가스(N㎥,원)', captionCss:'gridh-bg-gas',
              columns : [
                {
                  field: 'gasNsqty',
                  caption: '판매량',
                  width: 100,
                  align: 'right',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      { func: 'sum', nullAs: 0 },
                    ],
                    header: [
                      { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-gas',
                },
                {
                  field: 'gasNtslAmt',
                  caption: '판매금액',
                  width: 100,
                  align: 'right',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      { func: 'sum', nullAs: 0 },
                    ],
                    header: [
                      { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-gas',
                },
              ]
            },
            {
              caption: '증기(스팀톤,원)', captionCss:'gridh-bg-vapor',
              columns : [
                {
                  field: 'vaporNsqty',
                  caption: '판매량',
                  width: 100,
                  align: 'right',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      { func: 'sum', nullAs: 0 },
                    ],
                    header: [
                      { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-vapor',
                },
                {
                  field: 'vaporNtslAmt',
                  caption: '판매금액',
                  width: 100,
                  align: 'right',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      { func: 'sum', nullAs: 0 },
                    ],
                    header: [
                      { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-vapor',
                },
              ]
            },
            {
              caption: '온수(스팀톤,원)', captionCss:'gridh-bg-hot-water',
              columns : [
                {
                  field: 'htwtrNsqty',
                  caption: '판매량',
                  width: 100,
                  align: 'right',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      { func: 'sum', nullAs: 0 },
                    ],
                    header: [
                      { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-hot-water',
                },
                {
                  field: 'htwtrNtslAmt',
                  caption: '판매금액',
                  width: 100,
                  align: 'right',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      { func: 'sum', nullAs: 0 },
                    ],
                    header: [
                      { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-hot-water',
                },
              ]
            },
            {
              caption: '기타(Gcal,원)', captionCss:'gridh-bg-etc',
              columns : [
                {
                  field: 'etcEneNsqty',
                  caption: '판매량',
                  width: 100,
                  align: 'right',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      { func: 'sum', nullAs: 0 },
                    ],
                    header: [
                      { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-etc',
                },
                {
                  field: 'etcEneNtslAmt',
                  caption: '판매금액',
                  width: 100,
                  align: 'right',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      { func: 'sum', nullAs: 0 },
                    ],
                    header: [
                      { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-etc',
                },
              ]
            },
          ],
        },
      ]
    },
    initializeAsstFuelInputGridColumns() {
      this.asstFuelInputGridColumns = [
        { field: 'fcltSqno', caption: '시설명', width: 200,
          type: 'combo',
          // items: this.fcltList,
          items: this.asstFuelInputInfo.fcltList,
          comboLabel: 'fcltNm',
          comboValue: 'fcltSqno',
        },
        { caption: '보조연료 정보',
          columns: [
            { field: 'asstFuelKndGroupCd', caption: '연료구분', width: 150,
              type: 'combo',
              items: this.asstFuelKndGroupCdList,
              comboLabel: 'text',
              comboValue: 'value',
            },
            { field: 'asstFuelKndCd', caption: '연료종류', width: 150,
              type: 'combo',
              items: this.asstFuelKndCdList,
              comboLabel: 'text',
              comboValue: 'value',
              master: 'asstFuelKndGroupCd',
              masterField: 'frstRefVl',
              useFilter: true,
            },
            { field: 'asstFuelKndCn', caption: '기타종류명', width: 150 },
            { field: 'asstFuelInpqty', caption: '연료투입량', dataType: 'number', align: 'right', format: '#,##0.00', width: 150 },
            { field: 'asstFuelUnitCd', caption: '연료단위', width: 150,
              type: 'combo',
              items: this.asstFuelUnitCdList,
              comboLabel: 'text',
              comboValue: 'value',
              master: 'asstFuelKndGroupCd',
              masterField: 'frstRefVl',
              useFilter: true,
            },
            { field: 'asstFuelLcp', caption: '저위발열량', dataType: 'number', align: 'right', format: '#,##0.00', width: 150 },
            { field: 'lcpUnitCd', caption: '저위발열량 단위', width: 150,
              type: 'combo',
              items: this.lcpUnitCdList,
              comboLabel: 'text',
              comboValue: 'value',
              master: 'asstFuelUnitCd',
              masterField: 'frstRefVl',
              useFilter: true,
            },
          ]
        },
      ]
    },
    gridLoadedBioGasWtCryn() {
      this.gridReloadBioGasWtCryn = false;
    },
    gridLoadedBioGasNtsl() {
      this.gridReloadBioGasNtsl = false;
    },
    asstFuelInputGridLoaded() {
      this.asstFuelInputGridReload = false;
    },
    changeBioGasWtCryn(values) {
      values.forEach(item => {
        if (/^002[0-2]/.test(item.field)) {
          this.setCrynSldg(item);
        }
      });
    },
    setCrynSldg(item) {
      const rowData = window.SBGrid3.getRowData(this.prfmncBioGasWtCrynGridObject, item.key);
      if (rowData.seq === 0) {
        // 모든 슬러지 값을 동일하게 설정
        const wtCrynAllItems = window.SBGrid3.getAllItems(this.prfmncBioGasWtCrynGridObject);
        wtCrynAllItems.forEach(wtItem => {
          if (wtItem.seq !== 0) {
            window.SBGrid3.setValue(this.prfmncBioGasWtCrynGridObject, wtItem._key_, item.value, item.field);
          }
        });
      }
    },
    formatNumber(number) {
      return utils.formatNumberWithComma(number);
    },
    // 실적보고상세저장 호출
    async prtmncRptDtlSave(isSubmit = false){
      // 유효성 체크
      const validationMessage = this.validate(true);

      if(validationMessage){
        alert(validationMessage);
        return;
      }

      if (!isSubmit && !confirm("저장하시겠습니까?")) {
        return;
      }else if (isSubmit && !confirm("제출하시겠습니까?")) {
        return;
      }

      /*if (!validationMessage && confirm("필수입력 조건이 만족하여 제출가능한상태입니다.\n제출하시겠습니까?")) {
        isSubmit = true; // 제출상태로 변경
      }else{
        if(isSubmit){
          return;
        }
      }*/

      const { updated: wtCrynUpdated } = window.SBGrid3.getSaveData(this.prfmncBioGasWtCrynGridObject, false, true, false);
      // seq가 0이 아닌 데이터에서 key가 0020, 0021, 0022로 시작하는 값 0으로 변경
      const processedWtCrynUpdated = wtCrynUpdated.map(item => {
        if (item.seq !== 0) {
          Object.keys(item).forEach(key => {
            if (/^002[0-2]/.test(key)) {
              item[key] = 0; // 값 0으로 설정
            }
          });
        }
        return item;
      });

      let asstFuelInputList = [];
      if(parseInt(this.bioGasDtlObj.prfmncMst.prfmncYr) >= 2025) {
        const {inserted, updated, deleted} = window.SBGrid3.getSaveData(this.asstFuelInputGridObject);
        asstFuelInputList = [...inserted, ...updated, ...deleted];
      }
      const { inserted: ntslInserted, updated: ntslUpdated, deleted: ntslDeleted } = window.SBGrid3.getSaveData(this.prfmncBioGasNtslGridObject, true, true, true);
      const ntslList = [...ntslInserted, ...ntslUpdated, ...ntslDeleted];

      this.$apiCall.post(
          API.PRFMNC_DTL_SAVE,
          {
            wtCrynUpdated: processedWtCrynUpdated,
            prfmncMst: Object.assign({}, this.bioGasDtlObj.prfmncMst, isSubmit ? { prfmncSttsCd: "SUB" } : {}),
            prfmncBioGas: this.bioGasDtlObj.prfmncBioGas,
            prfmncBioGasWtInputList: this.bioGasDtlObj.prfmncBioGasWtInputList.inputItemList,
            asstFuelInputList: asstFuelInputList,
            prfmncBioGasPrdctn: this.bioGasDtlObj.prfmncBioGasPrdctn,
            bioGasEnePrfmnc: this.bioGasDtlObj.bioGasEnePrfmnc,
            prfmncBioGasNtslList: ntslList,
          },
          (data) => {
            alert((isSubmit ? '제출' : '저장') + "이 완료되었습니다.");
            let routerChuck = {path: ROUTE.PERFORMANCE_LIST};

            if(!isSubmit) {
              routerChuck = {path: ROUTE.PERFORMANCE_DTL, query: {prfmncId: data.result.prfmncId}};
            }

            this.$router.push(routerChuck)
                .then(() => !isSubmit && location.reload())
                .catch((err) => {
                  console.error('Navigation failed:', err);
                });
          },
          () => {
            alert((isSubmit ? '제출' : '저장') + "에 실패하였습니다.");
          }
      );
    },
    validate(focusOnError = false) {
      // 폐기물반입현황 - 반입량합계 필수값 체크

      // 생슬러지, 잉여슬러지, 탈수케이크만 입력 시 값 0으로 처리되어 주석
      // const wtCrynAllItems = window.SBGrid3.getAllItems(this.prfmncBioGasWtCrynGridObject);
      // const totalwtCrynSum = wtCrynAllItems.reduce((sum, item) => {
      //   return sum + (item.wtCrynSum || 0);
      // }, 0);

      if(this.lastSum === 0) {
        if (focusOnError) {
          this.$refs.prfmncDtlBioGasWtCrynTitle.focus();
        }
        return "폐기물 반입 정보를 입력해주세요.";
      }

      // 고질화방법이 없으면 고질화 전 메탄농도 필수 값 체크
      if(!this.bioGasDtlObj.prfmncBioGas.bgspgrBfrMthnDens && !this.bioGasDtlObj.prfmncBioGas.bgspgrAftrMthnDens){
        if (focusOnError) {
          this.$refs.bgspgrBfrMthnDensInput.focus();
        }
        return "메탄농도는 고질화 전/후 둘 중 하나는 반드시 입력해주시기 바랍니다.";
      }

      // 고질화방법이 있으면 고질화 후 메탄농도 필수 값 체크
      if(this.bioGasDtlObj.prfmncBioGas.bgspgrMthdCn && !this.bioGasDtlObj.prfmncBioGas.bgspgrAftrMthnDens){
        if (focusOnError) {
          this.$refs.bgspgrAftrMthnDensInput.focus();
        }
        return "고질화 방법을 입력하신 경우에는, 고질화 후 메탄농도를 반드시 입력해주시기 바랍니다.";
      }

      return "";
    },
  } // END methods
} // END export
</script>

<style scoped>

</style>