<template>
  <div class="grid_area_wrap">
    <div class="grid_area" style="flex: 1.1;">
      <div class="board_info">
        <h3 class="title2">반입업체 현황</h3>
        <div>
          <br-anchor-button label="반입업체검색" size="sm" format="tertiary" icon="ico_search" @click="showMnftrBplcPopup" />
        </div>
      </div>
      <sb-grid
          id="mnfrtGrid"
          :data-source="mnfrtDataSource"
          :columns="mnfrtGridColumns"
          :gridattr="mnfrtGridAttr"
          :param="searchParam"
          v-model:grid="mnfrtGridObject"
          @loaded="mnfrtGridLoaded"
          :refreshGrid="mnfrtGridReload"
          @change="changeUseMnftrCnptUseYn"
          fileName="고형연료사용 반입업체 현황.xlsx"
      />
    </div>
    <div class="grid_area" style="flex: 0.9;">
      <div class="board_info">
        <h3 class="title2">판매(공급)업체 현황</h3>
        <div>
          <br-anchor-button label="판매업체검색" size="sm" format="tertiary" icon="ico_search" @click="showEneSplyBzentyPopup" />
        </div>
      </div>
      <sb-grid
          id="splyGrid"
          :dataSource="splyDataSource"
          :columns="splyGridColumns"
          :gridattr="splyGridAttr"
          :param="searchParam"
          v-model:grid="splyGridObject"
          @loaded="splyGridLoaded"
          :refreshGrid="splyGridReload"
          @change="changeEneSplyCnptUseYn"
          fileName="고형연료사용 판매(공급)업체 현황.xlsx"
      />
    </div>
  </div>

  <use-mnftr-bplc-popup bplcKndCd="01-01" @select-bplc="saveUseMnftrCnpt" />
  <ene-sply-bzenty-popup @select-bzenty="saveEneSplyCnpt" />
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";
import SbGrid from "@/components/SbGrid.vue";
import UseMnftrBplcPopup from "@/views/prfmnc-rpt/components/popup/UseMnftrBplcPopup.vue";
import EneSplyBzentyPopup from "@/views/prfmnc-rpt/components/popup/EneSplyBzentyPopup.vue";

const API = {
  USE_MANUFACTURE_COUNTERPARTY: "/useMnftr/cnpt",
  ENE_SPLY_COUNTERPARTY : "/eneSply/cnpt",
};

export default {
  components: {
    EneSplyBzentyPopup,
    UseMnftrBplcPopup,
    SbGrid
  },
  props: ['param'],
  data() {
    return {
      searchParam: {
        ...this.param,
        crynNtslSeCd: '01'
      },
      ctpvCdList: [],
      sggCdList: [],
      mnfrtGridReload: false,
      mnfrtGridObject: null,
      mnfrtDataSource: {
        ajaxConfig: {
          select: { url: API.USE_MANUFACTURE_COUNTERPARTY },
          paging: false,
        },
        schema: {
          id: 'mnftrBplcId'
        }
      },
      mnfrtGridColumns: [],
      mnfrtGridAttr: {
        pageable: false,
        editable: true,
        sortable: true,
        mergeRow: true,
      },
      splyGridReload: false,
      splyGridObject: null,
      splyDataSource: {
        ajaxConfig: {
          select: { url: API.ENE_SPLY_COUNTERPARTY },
          paging: false,
        },
        schema: {
          id: 'eneBzentySn'
        }
      },
      splyGridColumns: [],
      splyGridAttr: {
        pageable: false,
        editable: true,
        sortable: true,
        mergeRow:true
      },
    };
  },
  computed: {},
  watch: {
    param: {
      handler(newVal) {
        this.searchParam = { ...newVal, crynNtslSeCd: '01' };
      },
      deep: true,
    }
  },
  created() {
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loadCtpvCd();
      this.loadSggCd();
      this.initializeMnftrGridColumns();
      this.initializeSplyGridColumns();
      this.mnfrtGridReload = true;
      this.splyGridReload = true;
    },
    loadCtpvCd() {
      const ctpvList = this.$store.getters.getSggList({ type: 'ctpv' });
      this.ctpvCdList = ctpvList.map(item => ({
        text: item.sggNm,
        value: item.sggStdgCd
      }));
    },
    loadSggCd() {
      const sggList = this.$store.getters.getSggList({ type: 'sgg' });
      this.sggCdList = sggList.map(item => ({
        text: item.sggNm,
        value: item.sggStdgCd,
        upSggStdgCd: item.upSggStdgCd
      }));
    },
    initializeMnftrGridColumns() {
      this.mnfrtGridColumns = [
        { field: 'mnftrBplcId', caption: '제조사업장아이디', visible: false },
        { field: 'useBplcId', caption: '사용사업장아이디', visible: false },
        { field: 'crynNtslSeCd', caption: '반입판매구분코드', visible: false },
        { field: 'mnftrBplcCtpvStdgCd', caption: '시·도', sortable : false,
          type: 'combo',
          items: this.ctpvCdList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 100,
          editable: false,
          mergeRow:true,
          exportLabel : true
        },
        { field: 'mnftrBplcSggStdgCd', caption: '시·군·구', sortable : false,
          type: 'combo',
          items: this.sggCdList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 110,
          editable: false,
          mergeRow:true,
          exportLabel : true
        },
        { field: 'mnftrBplcNm', caption: '사업장명', minWidth: 145, width:100, unit:'%', editable: false, align: 'left', sortable : false },
        { field: 'prdctnPrdctKnds', caption: '생산제품', width: 170, align: 'left', editable: false, sortable : false },
        { field: 'useYn', caption: '사용여부', part: 'sticky',
          type: 'check',
          checkValue: 'Y',
          checkLabel: '사용',
          unCheckValue: 'N',
          unCheckLabel: '미사용',
          checkMode:'cell',
          directApplyEdit : true,
          width: 80,
          exportLabel : true
        },
      ]
    },
    initializeSplyGridColumns() {
      this.splyGridColumns = [
        { field: 'bplcId', caption: '사업장아이디', visible: false },
        { field: 'eneBzentySn', caption: '에너지공급업체일련번호', visible: false },
        { field: 'ctpvStdgCd', caption: '시·도', sortable : false,
          type: 'combo',
          items: this.ctpvCdList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 100,
          editable: false,
          mergeRow:true,
          exportLabel : true
        },
        { field: 'sggStdgCd', caption: '시·군·구', sortable : false,
          type: 'combo',
          items: this.sggCdList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 110,
          editable: false,
          mergeRow:true,
          exportLabel : true
        },
        { field: 'bzentyNm', caption: '업체명', minWidth: 145, width: 100, unit:'%', editable: false, align: 'left', sortable : false },
        { field: 'useYn', caption: '사용여부', part: 'sticky',
          type: 'check',
          checkValue: 'Y',
          checkLabel: '사용',
          unCheckValue: 'N',
          unCheckLabel: '미사용',
          checkMode:'cell',
          directApplyEdit : true,
          width: 80,
          exportLabel : true
        },
      ]
    },
    mnfrtGridLoaded() {
      this.mnfrtGridReload = false;
    },
    splyGridLoaded() {
      this.splyGridReload = false;
    },
    showMnftrBplcPopup() {
      storeSwitch.on('BplcPopup', this.param.bplcId);
    },
    showEneSplyBzentyPopup() {
      storeSwitch.on('EneSplyBzentyPopup', this.param.bplcId);
    },
    saveUseMnftrCnpt(list) {
      const mnfrtAllItems = window.SBGrid3.getAllItems(this.mnfrtGridObject);

      // mnftrBplcId를 추출하여 배열로 생성
      const existingMnftrBplcIds = mnfrtAllItems.map(item => item.mnftrBplcId);

      // list에서 기존 항목에 포함되지 않은 값만 필터링
      const filteredList = list.filter(item => !existingMnftrBplcIds.includes(item));

      if (filteredList.length === 0) {
        alert("이미 등록된 사업장입니다.");
        return;
      }

      const formattedList = filteredList.map(item => ({
        mnftrBplcId: item,
        useBplcId: this.param.bplcId,
        crynNtslSeCd: '01'
      }));

      this.$apiCall.post(
          API.USE_MANUFACTURE_COUNTERPARTY,
          { list: formattedList },
          () => {
            alert("저장되었습니다.");
            storeSwitch.off("BplcPopup");
            window.SBGrid3.reload(this.mnfrtGridObject);
          },
          (error) => {
            alert(error.message);
          }
      );
    },
    saveEneSplyCnpt(list) {
      const splyAllItems = window.SBGrid3.getAllItems(this.splyGridObject);

      // eneBzentySn를 추출하여 배열로 생성
      const existingEneBzentySns = splyAllItems.map(item => item.eneBzentySn);

      // list에서 기존 항목에 포함되지 않은 값만 필터링
      const filteredList = list.filter(item => !existingEneBzentySns.includes(item));

      if (filteredList.length === 0) {
        alert("이미 등록된 업체입니다.");
        return;
      }

      this.$apiCall.post(
          API.ENE_SPLY_COUNTERPARTY,
          { bplcId: this.param.bplcId, list: filteredList },
          () => {
            alert("저장되었습니다.");
            storeSwitch.off("EneSplyBzentyPopup");
            window.SBGrid3.reload(this.splyGridObject);
          },
          (error) => {
            alert(error.message);
          }
      );
    },
    changeUseMnftrCnptUseYn(values) {
      values.forEach(item => {
        if (item.field === 'useYn') {
          const rowData = window.SBGrid3.getRowData(this.mnfrtGridObject, item.key);

          if (!confirm(`${rowData.mnftrBplcNm}의 사용여부를 변경하시겠습니까?`)) {
            window.SBGrid3.clearSaveData(this.mnfrtGridObject);
            window.SBGrid3.reload(this.mnfrtGridObject);
            return;
          }

          this.$apiCall.put(
              API.USE_MANUFACTURE_COUNTERPARTY,
              { mnftrBplcId: rowData.mnftrBplcId, useBplcId: rowData.useBplcId, crynNtslSeCd: rowData.crynNtslSeCd, useYn: rowData.useYn },
              () => {
                alert("사용여부가 수정되었습니다.");
                window.SBGrid3.clearSaveData(this.mnfrtGridObject);
                window.SBGrid3.reload(this.mnfrtGridObject);
              },
              (error) => {
                alert(error.message);
              }
          );
        }
      });
    },
    changeEneSplyCnptUseYn(values) {
      values.forEach(item => {
        if (item.field === 'useYn') {
          const rowData = window.SBGrid3.getRowData(this.splyGridObject, item.key);

          if (!confirm(`${rowData.bzentyNm}의 사용여부를 변경하시겠습니까?`)) {
            window.SBGrid3.clearSaveData(this.splyGridObject);
            window.SBGrid3.reload(this.splyGridObject);
            return;
          }

          this.$apiCall.put(
              API.ENE_SPLY_COUNTERPARTY,
              { bplcId: rowData.bplcId, eneBzentySn: rowData.eneBzentySn, useYn: item.value },
              () => {
                alert("사용여부가 수정되었습니다.");
                window.SBGrid3.reload(this.splyGridObject);
              },
              (error) => {
                alert(error.message);
              }
          );
        }
      });
    },
    search() {
      window.SBGrid3.reload(this.mnfrtGridObject);
      window.SBGrid3.reload(this.splyGridObject);
    }
  }
}
</script>

<style scoped>

</style>