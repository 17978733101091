<template>
  <div class="member_wrap">
      <div class="user_info ico_out"><span>회원탈퇴를 신청하기 전 다음 내용을 꼭 확인해주세요.</span></div>
      <div class="member_box">
          <p>
            검사 진행 중인 시설이 존재하거나, 업체의 마지막 회원인 경우 회원 탈퇴가 불가합니다.<br>
            사용하고 계신 아이디 <span class="txt_point">{{this.userinfo.userId}}</span>은 탈퇴할 경우 재사용 및 복구가 불가능합니다.
          </p>
          <p class="mt_20">회원 탈퇴를 진행하시겠습니까?</p>
      </div>
  </div>
  <div class="btn_area center">
      <br-button label="메인으로" class="btn md tertiary" @click="navigateTo('/')" />
      <br-button label="탈퇴" class="btn md primary" @click="whdwl" />
  </div>
    
  <simple-auth-modal @callback="simpleAuthCallback" @fail="simpleAuthFail"/>
</template>
    
<script>
import {storeSession} from "@/js/store/store-session";
import SimpleAuthModal from "@/components/SimpleAuthModal.vue";
import {storeSwitch} from "@/js/store/store-switch";

const API = {
    WHDWL_INFO : "/user/whdwl",
    HOME : "/",
}

export default {
  components: {SimpleAuthModal},
  data() {
    return {
      param : {
        userCnt : "",
        inspCnt : "",
      },
      userinfo: storeSession.getMemberInfo(),
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  mounted() {
    this.simpleAuthOpen();
    this.read();
  },
  methods: {
    read() {
      this.$apiCall.get(API.WHDWL_INFO + "/" + this.userinfo.userId, {}, this.success, this.error);
    },
    success(data) {
      if (!data.result) {
        this.error();
        return;
      }

      this.param = data.result;
      console.log(this.param);
    },
    error() {
      alert('warning', "데이터를 가져오는 중 오류가 발생했습니다.");
    },
    navigateTo(path) {
      if (path) {
        this.$router.push(path).catch(() => {
            console.error("Navigation Error");
        });
      }
      window.scrollTo({ top: 0 });
    },
    whdwl() {   // 탈퇴
      const authrtCd = this.userinfo.sysAuthrtCd;

      if(authrtCd.startsWith('02')){
        if(this.param.userCnt < 2 && this.param.inspCnt > 0){
            alert("검사중인 시설이 존재하는 업체의 마지막 회원으로 탈퇴가 불가능합니다.");
            return;
        }
      } 
      if(confirm("탈퇴하시겠습니까?")) {
        this.$apiCall.put(
            API.WHDWL_INFO + "/" + this.userinfo.userId,
            this.param, 
            () => {
                alert("탈퇴되었습니다.");
                this.$router.push(API.HOME).catch(() => {});
                storeSession.removeSession();
            },
            () => {
                alert("탈퇴 실패하였습니다.");
            }
        );
      }   
    },
    simpleAuthOpen() {
      storeSwitch.on('SimpleAuthModal', {isReToMain: true});
    },
    simpleAuthCallback(result) {
      console.log("simpleAuthCallback", result);

      if(!result.bzentyUsers){
        if(this.userinfo.userId != result.userId){
          alert('로그인계정과 간편인증 정보가 다릅니다.');
          this.navigateTo('/my-office/MyOfficeMain');
        } else {
          alert("인증이 성공적으로 완료되었습니다.");
        }
      } else {
        const exists = result.bzentyUsers.some(user => user.userId === this.userinfo.userId); // 배열에서 존재하는지 찾아줌
        if(!exists){
          alert('로그인계정과 간편인증 정보가 다릅니다.');
          this.navigateTo('/my-office/MyOfficeMain');
        } else {
          alert("인증이 성공적으로 완료되었습니다.");
        }
      }
    },
    simpleAuthFail(error) {
      console.log("simpleAuthFail", error);
    },
  },   
}
</script>

<style scoped>

</style>