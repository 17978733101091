<template>
    <!-- s: 콘텐츠 영역 -->
    <div class="member_wrap">
        <ol v-if="this.isEdit" class="join_step_wrap cede">
            <li :class="{ active: currentStep === 'selectType' }">
                <i class="step1"></i>
                <span>승계유형선택</span>
            </li>
            <li :class="{ active: currentStep === 'detailWrite' }">
                <i class="step2"></i>
                <span>신고서 작성</span>
            </li>
            <li :class="{ active: currentStep === 'completion' }">
                <i class="step3"></i>
                <span>신고완료</span>
            </li>
        </ol>
    
        <!-- s: 승계 유형 선택 --> 
        <div v-if="currentStep === 'selectType'">
            <h2 class="member_title">승계 유형 선택</h2>
            <div class="tit_box">다른 업체(=사업자등록번호)로 양도하는 경우, 승계인의 회원가입 및 업체정보 등록/승인이 필요합니다.</div>
            <div class="type_area cede">
                <a href="javascript:void(0);" class="type_box" @click="setStep('detailWrite', 'bzenty')">
                    <i class="icon1"></i>
                    <h3>업체 승계</h3>
                    <p>피승계인의 시설 전체를 승계하는 경우</p>
                    <span class="btn sm tertiary" @click="setStep('detailWrite', 'bzenty')">선택</span>
                </a>
                <a href="javascript:void(0);" class="type_box" @click="setStep('detailWrite', 'bplc')">
                    <i class="icon2"></i>
                    <h3>사업장 승계</h3>
                    <p>피승계인의 사업장 중 일부만 승계하는 경우</p>
                    <span class="btn sm tertiary" @click="setStep('detailWrite', 'bplc')">선택</span>
                </a>
            </div>
        </div>
        <!-- e: 승계 유형 선택 --> 

        <!-- s: 상세 -->
        <div v-if="currentStep === 'detailWrite'">
            <h2 v-if="this.isEdit" class="member_title">신고서 작성</h2>
            <h2 v-if="!this.isEdit" class="member_title">신고서 상세정보</h2>
            <div v-if="this.isEdit" class="tit_box">
                피승계인, 승계인의 업체를 선택하세요
            </div>
            <div class="cede_area cede_write">
                <div class="cede_box">
                    <button v-if="this.rghtDutyScsnDclrParamObj.scsnDclrTypeCd === '01' && this.isEdit === true" type="button" class="btn sm tertiary" @click="openPopup('RghtScsnDclrBzentyPopup', 'opspBzentyObj')">업체선택</button>
                    <button v-else-if="this.rghtDutyScsnDclrParamObj.scsnDclrTypeCd === '02' && this.isEdit === true" type="button" class="btn sm tertiary" @click="openPopup('RghtScsnDclrBplcPopup', 'opspBzentyObj')">사업장선택</button>
                    <div class="title">피승계인</div>
                    <div class="section">
                        <dl>
                            <dt>업체명</dt> <dd>{{ opspBzentyObj.opsnBzentyNm }}</dd>
                        </dl>
                        <dl>
                            <dt>사업자등록번호</dt> <dd>{{ formatBrno(opspBzentyObj.opsnBrno) }}</dd>
                        </dl>
                        <dl>
                            <dt>대표자명</dt> <dd>{{ opspBzentyObj.opsnRprsvNm }}</dd>
                        </dl>
                        <dl>
                            <dt>업체주소</dt> <dd>{{ opspBzentyObj.opsnAddr }} {{ opspBzentyObj.opsnDaddr }}</dd>
                        </dl>
                    </div>
                    <div class="section">
                        <h3>사업장 목록</h3>
                        <ul v-if="opspBplcObj.length" class="dot_list">
                            <li v-for="opspBplc in opspBplcObj" :key="opspBplc.bplcId">({{ opspBplc.bplcKndNm }}) {{ opspBplc.bplcNm }}</li> 
                        </ul>
                    </div>
                    <div class="section">
                        <h3>시설 목록</h3>
                        <ul v-if="opspFcltObj.length" class="dot_list">
                            <li v-for="opspFclt in opspFcltObj" :key="`${opspFclt.bplcId}-${opspFclt.fcltSqno}`">({{ opspFclt.bplcNm }}) {{ opspFclt.fcltNm }}</li> 
                        </ul>
                    </div>
                </div>
                <div class="cede_box successor">
                    <button v-if="this.isEdit === true" type="button" class="btn sm tertiary" @click="openPopup('RghtScsnDclrBzentyPopup', 'scsrBzentyObj')">업체선택</button>
                    <div class="title">승계인</div>
                    <div class="section">
                        <dl>  
                            <dt>업체명</dt> 
                            <dd v-if="opspBzentyObj.opsnBzentyId === scsrBzentyObj.scsnBzentyId && this.isEdit">
                                <br-form-input label="승계인 업체명" title="승계인 업체명" v-model="aplyParamObj.scsnBzentyNm" panel="panel" required/> 
                            </dd>
                            <dd v-else>
                                {{ scsrBzentyObj.scsnBzentyNm }}
                            </dd>
                        </dl>
                        <dl>
                            <dt>사업자등록번호</dt> <dd>{{ formatBrno(scsrBzentyObj.scsnBrno) }}</dd> 
                        </dl>
                        <dl>
                            <dt>대표자명</dt> 
                            <dd v-if="opspBzentyObj.opsnBzentyId === scsrBzentyObj.scsnBzentyId && this.isEdit">
                              <br-form-input label="승계인 대표자명" title="승계인 대표자명" v-model="aplyParamObj.scsnRprsvNm" panel="panel" required/> 
                            </dd>
                            <dd v-else>
                                {{ scsrBzentyObj.scsnRprsvNm }}
                            </dd>
                        </dl>
                        <dl>
                            <dt>업체주소</dt> 
                            <dd v-if="opspBzentyObj.opsnBzentyId === scsrBzentyObj.scsnBzentyId && this.isEdit">
                                <div class="search_cont"> 
                                    <input
                                        type="text"
                                        class="search_input"
                                        placeholder="주소를 입력해주세요"
                                        v-model="aplyParamObj.scsnAllAddr"
                                        disabled
                                        panel="panel" 
                                        required
                                    />
                                    <button
                                        label="주소검색" 
                                        type="button"
                                        class="btn sm txtN ico_search"
                                        @click="callPopupAddress" 
                                    />
                                </div>
                            </dd>
                            <dd v-else>
                                {{ scsrBzentyObj.scsnAddr }} {{ scsrBzentyObj.scsnDaddr }}
                            </dd>
                        </dl>
                    </div>
                    <div class="section">
                        <h3>사업장 목록</h3>
                        <ul class="dot_list">
                            <!-- <ul v-if="viewType === 'detail' && scsrBplcObj.length" class="dot_list"> -->
                            <!-- <ul v-if="scsrBplcObj.length" class="dot_list">
                                <li v-for="scsrBplc in scsrBplcObj" :key="scsrBplc.bplcId">
                                    <span v-if="scsrBplc.rghtDutyScsnYn === 'Y'" class="badge bg2">신규</span> 
                                    ({{ scsrBplc.bplcKndNm }}) {{ scsrBplc.bplcNm }}
                                </li> 
                            </ul> -->

                            <ul v-if="scsrBplcObj.length" class="dot_list">
                                <li 
                                    v-for="scsrBplc in filteredScsrBplcObj" 
                                    :key="scsrBplc.bplcId">
                                    <span v-if="scsrBplc.rghtDutyScsnYn === 'Y' && opspBzentyObj.opsnBzentyId !== scsrBzentyObj.scsnBzentyId" class="badge bg2">신규</span> 
                                    ({{ scsrBplc.bplcKndNm }}) {{ scsrBplc.bplcNm }}
                                </li> 
                            </ul>

                            <ul v-if="viewType === 'update' && changeBplcCnt > 1 && opspBplcObj.length && this.opspBzentyObj.opsnBzentyId !== this.scsrBzentyObj.scsnBzentyId && this.rghtDutyScsnDclrParamObj.scsnDclrSttsCd !== '02'" class="dot_list">
                                <li v-for="opspBplc in opspBplcObj" :key="opspBplc.bplcId">
                                    <span class="badge bg2">신규</span>({{ opspBplc.bplcKndNm }}) {{ opspBplc.bplcNm }}
                                </li>
                            </ul> 
                        </ul>
                    </div>
                    <div class="section">
                        <h3>시설 목록</h3>
                        <ul class="dot_list">
                            <!-- <ul v-if="viewType === 'detail' && scsrFcltObj.length" class="dot_list"> -->
                            <!-- <ul v-if="scsrFcltObj.length" class="dot_list">
                                <li v-for="scsrFclt in scsrFcltObj" :key="`${scsrFclt.bplcId}-${scsrFclt.fcltSqno}`">
                                    <span v-if="scsrFclt.rghtDutyScsnYn === 'Y'" class="badge bg2">신규</span> 
                                    ({{ scsrFclt.bplcNm }}) {{ scsrFclt.fcltNm }}</li> 
                            </ul>  -->

                            <ul v-if="scsrFcltObj.length" class="dot_list">
                                <li 
                                    v-for="scsrFclt in filteredScsrFcltObj" 
                                    :key="`${scsrFclt.bplcId}-${scsrFclt.fcltSqno}`">
                                    <span v-if="scsrFclt.rghtDutyScsnYn === 'Y' && opspBzentyObj.opsnBzentyId !== scsrBzentyObj.scsnBzentyId" class="badge bg2">신규</span> 
                                    ({{ scsrFclt.bplcNm }}) {{ scsrFclt.fcltNm }}
                                </li> 
                            </ul>

                            <ul v-if="viewType === 'update' && changeBplcCnt > 1 && opspFcltObj.length && this.opspBzentyObj.opsnBzentyId !== this.scsrBzentyObj.scsnBzentyId && this.rghtDutyScsnDclrParamObj.scsnDclrSttsCd !== '02'" class="dot_list">
                                <li v-for="opspFclt in opspFcltObj" :key="`${opspFclt.bplcId}-${opspFclt.fcltSqno}`"><span class="badge bg2">신규</span>({{ opspFclt.bplcNm }}) {{ opspFclt.fcltNm }}</li> 
                            </ul>
                      </ul>
                    </div>
                    <p class="t_caption" style="margin-top: 7px !important;">
                        <span class="necessary">(<i>※</i>)</span> 
                        차세대 이후 승계된 사업장, 시설 목록에는 <strong>'신규'</strong> 아이콘이 표시 됩니다. 
                    </p>
                </div>
            </div>

            <h2 class="title1">신고내용</h2>
            <div class="board_write">
                <p class="t_caption"><span class="necessary">(<i>※</i>)</span> 필수 입력항목입니다.</p>
                <table>
                    <caption>신고내_업종, 신고번호, 승계사유, 승계일자, 기타 참고사항으로 구성</caption>
                    <colgroup>
                        <col style="width:18%">
                        <col style="width:32%">
                        <col style="width:18%">
                        <col style="width:32%">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>업종</th>
                            <td v-if="this.isEdit === true"> 
                                <br-form-input label="업종" title="업종" v-model="rghtDutyScsnDclrParamObj.scsnTrgtTpbizNm" panel="panel" required/> 
                            </td>
                            <td v-else>
                                {{ rghtDutyScsnDclrParamObj.scsnTrgtTpbizNm }}
                            </td>
                            <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>신고번호</th>
                            <td v-if="this.isEdit === true">
                                <br-form-input label="신고번호" title="신고번호" v-model="rghtDutyScsnDclrParamObj.scsnTrgtDclrPrmsnNo" panel="panel" required/> 
                            </td>
                            <td v-else> 
                                {{ rghtDutyScsnDclrParamObj.scsnTrgtDclrPrmsnNo }}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" id="scsnRsnArea"><i class="necessary">※<span class="sr_only">필수입력</span></i>승계사유</th>
                            <td v-if="this.isEdit === true"> 
                                <!-- <br-form-select ref="selScsnRsnCd" label="승계사유" groupCd="SIS009" v-model:value="rghtDutyScsnDclrParamObj.scsnRsnCd" @change="updateSelectedReasonText" allSelectable panel="panel" required />  -->
                                <br-form-select ref="selScsnRsnCd" label="승계사유" groupCd="SIS009" v-model:value="rghtDutyScsnDclrParamObj.scsnRsnCd" allSelectable panel="panel" required /> 
                            </td>
                            <td v-else> 
                                {{ rghtDutyScsnDclrParamObj.scsnRsnNm }}
                            </td>
                            <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>승계일자</th>
                            <td v-if="this.isEdit === true"> 
                                <br-date-picker label="승계일자" title="승계일자" v-model:value="rghtDutyScsnDclrParamObj.scsnYmd" format="YYYY-MM-DD" panel="panel" required /> 
                            </td>
                            <td v-else> 
                                {{ rghtDutyScsnDclrParamObj.scsnYmd }}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">기타 참고사항</th>
                            <td v-if="this.isEdit === true" colspan="3"> 
                                <br-form-input label="기타 참고사항" title="기타 참고사항" v-model="rghtDutyScsnDclrParamObj.etcRefCn" /> 
                            </td>
                            <td v-else colspan="3"> 
                                {{ rghtDutyScsnDclrParamObj.etcRefCn }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="board_info mt_40">
                <h2 class="title1">제출서류</h2>
            </div>
            <div class="board_write">
                <table>
                    <caption>첨부파일로 구성</caption>
                    <colgroup>
                        <col style="width:18%">
                        <col>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th scope="row">파일</th>
                            <td colspan="3">
                                <div class="file_upload_list">
                                    <li v-if="this.isEdit === true"> 
                                        <br-attach-file-list label="권리의무승계신고 첨부파일 목록"
                                                    ref="rghtDutyScsnDclrAttachFileList"
                                                    atchFileTaskSeCd="RGT" 
                                                    v-model:atchFileGroupId="this.rghtDutyScsnDclrParamObj.atchFileGroupId"
                                                    v-model:fileProcessingData="this.rghtDutyScsnDclrParamObj.fileProcessingData"
                                                    :allowedExtensions="this.rghtDutyScsnDclrParamObj.fileAllowedExtensions" 
                                                    :maxFileCount="10"
                                                    :readonly="false" 
                                                    :useEtcFile="true"
                                        /> 
                                    </li> 

                                    <li v-else> 
                                        <br-attach-file-list label="권리의무승계신고 첨부파일 목록"
                                                    ref="rghtDutyScsnDclrAttachFileList"
                                                    atchFileTaskSeCd="RGT" 
                                                    v-model:atchFileGroupId="this.rghtDutyScsnDclrParamObj.atchFileGroupId"
                                                    v-model:fileProcessingData="this.rghtDutyScsnDclrParamObj.fileProcessingData"
                                                    :allowedExtensions="this.rghtDutyScsnDclrParamObj.fileAllowedExtensions" 
                                                    :maxFileCount="10"
                                                    :readonly="true" 
                                                    :useEtcFile="true"
                                        /> 
                                    </li>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="txt_box mt_10">
                <ul class="dot_list">
                    <li>양도나 합병의 경우에는 양도ㆍ양수 계약서 또는 합병계약서 등 양도사실이나 합병사실을 확인할 수 있는 서류를, </li>
                    <li>사망의 경우에는 가족관계증명서 등 상속사실을 확인할 수 있는 서류를 제출합니다.</li>
                </ul>
            </div>     

            <h2 v-if="this.rghtDutyScsnDclrParamObj.scsnDclrSttsCd === '02'" class="title1">승인내용</h2>
            <div v-if="this.rghtDutyScsnDclrParamObj.scsnDclrSttsCd === '02'" class="board_write">
                <table>
                    <caption>승인내용내 승인자명, 승인일시로 구성</caption>
                    <colgroup>
                        <col style="width:18%">
                        <col style="width:32%">
                        <col style="width:18%">
                        <col style="width:32%">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th scope="row">승인자명</th>
                            <td>
                                {{ rghtDutyScsnDclrParamObj.aprvUserNm }} 
                            </td>
                            <th scope="row">승인일시</th>
                            <td> 
                                {{ rghtDutyScsnDclrParamObj.aprvDt }}
                            </td>
                        </tr>
                    </tbody> 
                </table>
            </div>
            
            <h2 v-if="this.rghtDutyScsnDclrParamObj.scsnDclrSttsCd === '03'" class="title1">반려내용</h2>
            <div v-if="this.rghtDutyScsnDclrParamObj.scsnDclrSttsCd === '03'" class="board_write">
                <table>
                    <caption>반려내용내 반려사유로 구성</caption>
                    <colgroup>
                        <col style="width:18%">
                        <col style="width:32%">
                        <col style="width:18%">
                        <col style="width:32%">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th scope="row">반려자명</th>
                            <td>
                                {{ rghtDutyScsnDclrParamObj.rjctUserNm }} 
                            </td>
                            <th scope="row">반려일시</th>
                            <td> 
                                {{ rghtDutyScsnDclrParamObj.rjctDt }}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">반려사유</th>
                            <td colspan="3" style="height: 150px;"> 
                                <div v-html="rghtDutyScsnDclrParamObj.rjctRsn"></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
            <!-- <div class="tit_box mt_40">
                피승계인 업체 직원들의 계정정보도 같이 승계하시겠습니까?
                <div class="chk_area btn_area center mt_20">
                    <div class="form_check">
                        <input type="radio" name="sch_chk0" id="sch_chk0_1" checked="">
                        <label for="sch_chk0_1">예</label>
                    </div>
                    <div class="form_check">
                        <input type="radio" name="sch_chk0" id="sch_chk0_2">
                        <label for="sch_chk0_2">아니오</label>
                    </div>
                </div>
            </div> -->
            <div class="btn_area right">
                <br-button v-if="this.isAplyOn" label="승인요청" title="승인요청" class="btn md primary" format="primary" panel="panel" validate @click="createRghtScsnDclrAprvDmnd()"/> 
                <br-button v-if="this.isModifyOn && !this.isAplyOn" label="수정" title="수정" class="btn md primary" format="primary" @click="updateRghtScsnDclrAprvDmnd()"/> 
                <br-button label="목록" title="목록" class="btn md tertiary" @click="navigate()" /> 
            </div>
        </div>
        <!-- e: 상세 -->
         
        <!-- s: 승인요청완료 -->
        <div v-if="currentStep === 'completion'">
            <div class="join_finish">
                <img src="@/assets/images/sub/ico_cede_finish.png" alt="이미지">
                <strong>승인요청이 완료되었습니다.</strong>
                <p>
                    관리자의 승인 후 승계 작업이 완료됩니다.
                </p>
            </div>
            <!-- <div class="member_box">
                <p>(신고번호 {{this.rghtDutyScsnDclrParamObj.scsnTrgtDclrPrmsnNo}}) {{formatDate(this.rghtDutyScsnDclrParamObj.scsnYmd)}} 에 {{this.rghtDutyScsnDclrParamObj.scsnTrgtTpbizNm}} {{this.rghtDutyScsnDclrParamObj.opsnBzentyNm}} 은(는) <span class="txt_point">{{this.rghtDutyScsnDclrParamObj.scsnRsnNm}}</span> 승계합니다.</p>
            </div> -->
            <div class="cede_area">
                <div class="cede_box">
                    <div class="title">피승계인</div>
                    <div class="section">
                        <dl>
                            <dt>업체명</dt> <dd>{{ opspBzentyObj.opsnBzentyNm }}</dd>
                        </dl>
                        <dl>
                            <dt>사업자등록번호</dt> <dd>{{ formatBrno(this.opspBzentyObj.opsnBrno) }}</dd>
                        </dl>
                        <dl>
                            <dt>대표자명</dt> <dd>{{ opspBzentyObj.opsnRprsvNm }}</dd>
                        </dl>
                        <dl>
                            <dt>업체주소</dt> <dd>{{ opspBzentyObj.opsnAddr }} {{ opspBzentyObj.opsnDaddr }}</dd>
                        </dl>
                    </div>
                    <div class="section">
                        <h3>사업장 목록</h3>
                        <ul v-if="opspBplcObj.length" class="dot_list">
                            <li v-for="opspBplc in opspBplcObj" :key="opspBplc.bplcId">({{ opspBplc.bplcKndNm }}) {{ opspBplc.bplcNm }}</li> 
                        </ul>
                    </div>
                    <div class="section">
                        <h3>시설 목록</h3>
                        <ul v-if="opspFcltObj.length" class="dot_list">
                            <li v-for="opspFclt in opspFcltObj" :key="`${opspFclt.bplcId}-${opspFclt.fcltSqno}`">({{ opspFclt.bplcNm }}) {{ opspFclt.fcltNm }}</li> 
                        </ul>
                    </div>
                </div>
                <div class="cede_box successor">
                    <div class="title">승계인</div>
                        <div class="section">
                            <dl>  
                                <!-- <dt>업체명</dt> <dd>{{ scsrBzentyObj.scsnBzentyNm }}</dd> -->
                                <dt>업체명</dt> 
                                <dd v-if="opspBzentyObj.opsnBzentyId === scsrBzentyObj.scsnBzentyId && this.isEdit">
                                    {{ aplyParamObj.scsnBzentyNm }} 
                                </dd>
                                <dd v-else>
                                    {{ scsrBzentyObj.scsnBzentyNm }}
                                </dd>
                                </dl>
                                <dl>
                                    <dt>사업자등록번호</dt> <dd>{{ formatBrno(scsrBzentyObj.scsnBrno) }}</dd> 
                                </dl>
                                <dl>
                                    <!-- <dt>대표자명</dt> <dd>{{ scsrBzentyObj.scsnRprsvNm }}</dd> -->
                                    <dt>대표자명</dt>
                                    <dd v-if="opspBzentyObj.opsnBzentyId === scsrBzentyObj.scsnBzentyId && this.isEdit">
                                        {{ aplyParamObj.scsnRprsvNm }}
                                    </dd>
                                    <dd v-else>
                                        {{ scsrBzentyObj.scsnRprsvNm }}
                                    </dd>
                                </dl>
                                <dl>
                                    <!-- <dt>업체주소</dt> <dd>{{ scsrBzentyObj.scsnAddr }} {{ scsrBzentyObj.scsnDaddr }}</dd>  -->
                                    <dt>업체주소</dt> 
                                    <dd v-if="opspBzentyObj.opsnBzentyId === scsrBzentyObj.scsnBzentyId && this.isEdit">
                                        {{ aplyParamObj.scsnAllAddr }}
                                    </dd>
                                    <dd v-else>
                                        {{ scsrBzentyObj.scsnAddr }} {{ scsrBzentyObj.scsnDaddr }}
                                    </dd> 
                                </dl>
                            </div>
                            <div class="section">
                                <h3>사업장 목록</h3>
                                <!-- <ul class="dot_list">
                                    <ul v-if="scsrBplcObj.length" class="dot_list">
                                        <li v-for="scsrBplc in scsrBplcObj" :key="scsrBplc.bplcId">({{ scsrBplc.bplcKndNm }}) {{ scsrBplc.bplcNm }}</li> 
                                    </ul>

                                    <ul v-if="opspBplcObj.length && this.opspBzentyObj.opsnBzentyId !== this.scsrBzentyObj.scsnBzentyId && this.rghtDutyScsnDclrParamObj.scsnDclrSttsCd !== '02'" class="dot_list">
                                        <li v-for="opspBplc in opspBplcObj" :key="opspBplc.bplcId"><span class="badge bg2">신규</span>({{ opspBplc.bplcKndNm }}) {{ opspBplc.bplcNm }}</li>
                                    </ul> 
                                </ul> -->
                                <ul v-if="scsrBplcObj.length" class="dot_list">
                                    <li 
                                        v-for="scsrBplc in filteredScsrBplcObj" 
                                        :key="scsrBplc.bplcId">
                                        <span v-if="scsrBplc.rghtDutyScsnYn === 'Y' && opspBzentyObj.opsnBzentyId !== scsrBzentyObj.scsnBzentyId" class="badge bg2">신규</span> 
                                        ({{ scsrBplc.bplcKndNm }}) {{ scsrBplc.bplcNm }}
                                    </li> 
                                </ul>

                                <ul v-if="viewType === 'update' && changeBplcCnt > 1 && opspBplcObj.length && this.opspBzentyObj.opsnBzentyId !== this.scsrBzentyObj.scsnBzentyId && this.rghtDutyScsnDclrParamObj.scsnDclrSttsCd !== '02'" class="dot_list">
                                    <li v-for="opspBplc in opspBplcObj" :key="opspBplc.bplcId"><span class="badge bg2">신규</span>({{ opspBplc.bplcKndNm }}) {{ opspBplc.bplcNm }}</li>
                                </ul> 
                            </div>
                            <div class="section">
                                <h3>시설 목록</h3>
                                <ul class="dot_list">
                                    <!-- <ul v-if="scsrFcltObj.length" class="dot_list">
                                        <li v-for="scsrFclt in scsrFcltObj" :key="`${scsrFclt.bplcId}-${scsrFclt.fcltSqno}`">({{ scsrFclt.bplcNm }}) {{ scsrFclt.fcltNm }}</li> 
                                    </ul> 

                                    <ul v-if="opspFcltObj.length && this.opspBzentyObj.opsnBzentyId !== this.scsrBzentyObj.scsnBzentyId" class="dot_list">
                                        <li v-for="opspFclt in opspFcltObj" :key="`${opspFclt.bplcId}-${opspFclt.fcltSqno}`"><span class="badge bg2">신규</span>({{ opspFclt.bplcNm }}) {{ opspFclt.fcltNm }}</li> 
                                    </ul> -->
                                    <ul v-if="scsrFcltObj.length" class="dot_list">
                                        <li 
                                            v-for="scsrFclt in filteredScsrFcltObj" 
                                            :key="`${scsrFclt.bplcId}-${scsrFclt.fcltSqno}`">
                                            <span v-if="scsrFclt.rghtDutyScsnYn === 'Y' && opspBzentyObj.opsnBzentyId !== scsrBzentyObj.scsnBzentyId" class="badge bg2">신규</span> 
                                            ({{ scsrFclt.bplcNm }}) {{ scsrFclt.fcltNm }}
                                        </li> 
                                    </ul>

                                    <ul v-if="viewType === 'update' && changeBplcCnt > 1 && opspFcltObj.length && this.opspBzentyObj.opsnBzentyId !== this.scsrBzentyObj.scsnBzentyId && this.rghtDutyScsnDclrParamObj.scsnDclrSttsCd !== '02'" class="dot_list">
                                        <li v-for="opspFclt in opspFcltObj" :key="`${opspFclt.bplcId}-${opspFclt.fcltSqno}`"><span class="badge bg2">신규</span>({{ opspFclt.bplcNm }}) {{ opspFclt.fcltNm }}</li> 
                                    </ul>
                                </ul>
                            </div>
                        </div>
                    </div>
                <div class="btn_area right">
                    <br-button label="확인" title="확인" class="btn md tertiary" @click="navigate()" /> 
                </div>
        </div>
        <!-- e: 승인요청완료 --> 

    </div>
    <!-- e: 콘텐츠 영역 -->

    <!-- 피승계자 업체 조회 -->
    <!-- <rght-scsn-dclr-bzenty-popup @select-bzenty="setSelectedBzenty" /> -->
    <rght-scsn-dclr-bzenty-popup @select-bzenty="handleSelectBzenty" />
    <rght-scsn-dclr-bplc-popup @select-bplc="handleSelectBzenty" />
    <address-popup @input-button-clicked="selectedAddressByPopup"></address-popup>

  </template>
  
  <script>
/**
 * 권리 의무승계 신고 신청하는 화면 
 *
 * 작성자 : CHOSUNGHO 
 * 작성일 : 24. 11. 18 
 * 버전 : 1.0
 */ 

  import { storeSwitch } from "@/js/store/store-switch";
  import { buildUri } from "@/js/utils/uri-builder";
  import RghtScsnDclrBzentyPopup from "@/views/my-office/rght-scsn-dclr/RghtScsnDclrBzentyPopup.vue";
  import RghtScsnDclrBplcPopup from "@/views/my-office/rght-scsn-dclr/RghtScsnDclrBplcPopup.vue";
  import AddressPopup from "@/views/mbr-mng/components/MbrFormAddress.vue";

  const API = {
    RGHT_SCSN_DCLR : "/rghtScsnDclr", 
    RGHT_SCSN_DCLR_DETAIL : "/rghtScsnDclr/{scsnDclrSn}", 
    RGHT_SCSN_DCLR_BPLC : "/rghtScsnDclr/bplcByBzentyId", 
    RGHT_SCSN_DCLR_FCLT : "/rghtScsnDclr/fcltByBzentyId",  
  };
  
  export default {
    components: {
      RghtScsnDclrBzentyPopup,
      RghtScsnDclrBplcPopup,
      AddressPopup 
    },
    data() {
      return {
        currentStep: 'selectType', // 초기 단계 설정
        targetPopup: "", 
        viewType: "", 
        opspBzentyObj: {
          scsnDclrSn: "", 
          srchBzentyId: "", 
          opsnBzentyId: "", 
          opsnBzentyNm: "",
          opsnBrno: "",
          opsnRprsvNm: "",
          opsnAddr: "",
          opsnDaddr: "",
          scsnDclrTypeCd: "",
          bplcIds: null, 
        }, 
        opspBplcObj: {
          bplcId: "", 
          bplcKndNm: "", 
          bplcNm: "",  
        }, 
        opspFcltObj: {
          bplcId: "", 
          fcltSqno: "",
          bplcNm: "",  
          fcltNm: "",  
        }, 
        scsrBzentyObj: {
            scsnDclrSn: "", 
            srchBzentyId: "", 
            scsnBzentyId: null, 
            scsnBzentyNm: "",
            scsnBrno: "",
            scsnRprsvNm: "",
            scsnAddr: "",
            scsnDaddr: "",
            scsnDclrTypeCd: "", 
            bplcIds: null, 
        }, 
        scsrBplcObj: {
          bplcId: "", 
          bplcKndNm: "", 
          bplcNm: "",  
        }, 
        scsrFcltObj: {
          bplcId: "", 
          fcltSqno: "", 
          bplcNm: "", 
          fcltNm: "",  
        }, 
        aplyParamObj: {
          scsnBzentyNm: "",  
          scsnRprsvNm: "",  
          scsnAddr: "",  
          scsnDaddr: "",  
          scsnAllAddr: "",  
        },
        rghtDutyScsnDclrParamObj: {
            bzentyInsdScsnYn: "N",      // 피승계인, 승계인 동일 업체일 경우 'Y' 
            changeBzentyAddrYn: "N",    // 피승계인, 승계인 동일 업체일 경우 주소 변경 여부 
            scsnTarget: "",			    // 승계대상 (업체, 사업장) 
            scsnDclrSn: "",             // 승계신고일련번호 
            scsnDclrTypeCd: "",         // 승계신고유형코드 
            scsnDclrSttsCd: "",         // 승계신고상태코드 
            opsnBzentyId: "", 		    // 피승계업체아이디 (저장용)
            srchBzentyId: "",           // 피승계업체아이디 (공통 사업장 조회 쿼리 사용)
            scsnTrgtTpbizNm: "",        // 승계대상업종명 
            scsnTrgtDclrPrmsnNo: "",	// 승계대상신고허가번호 
            scsnBzentyId: "",           // 승계업체아이디 
            scsnBzentyNm: "",           // 승계업체명 
            scsnRprsvNm: "",            // 승계대표자명 
            scsnUntyZip: "",            // 승계통합우편번호 
            scsnAddr: "",               // 승계주소 
            scsnDaddr: "",              // 승계상세주소  
            scsnRsnCd: "",              // 승계사유코드 
            scsnRsnNm: "",              // 승계사유명
            scsnYmd: "",                // 승계일자 
            etcRefCn: "",               // 기타참고내용 
            bplcIds: null,              // 사업장 승계 목록 
            siNm: "",                   // 승계인  변경 업체주소 시도 명 
            sggNm: "",                  // 승계인 변경 업체주소 시군구 명 
            atchFileGroupId: "",	    // 첨부파일 
			fileProcessingData: {}, 
            fileAllowedExtensions: ['jpg', 'jpeg', 'gif', 'png', 'tif', 'bmp', 'hwp', 'pdf', 'xlsx', 'xls', 'doc', 'docx', 'ppt', 'pptx'],
        }, 
        changeBplcCnt: 0, 
        isModifyOn: false, // 수정 버튼 표시 여부 
        isAplyOn: false, // 승인요청 버튼 표시 여부 
        isEdit: false, // 수정 가능 여부 
      };
    },
    computed: {
        rghtScsnDclrByScsnDclrSn() {
            // Session Storage에서 데이터 불러오기
            const rghtScsnDclrByScsnDclrSn = sessionStorage.getItem('rghtScsnDclrByScsnDclrSn');
            return rghtScsnDclrByScsnDclrSn ? rghtScsnDclrByScsnDclrSn : '';
        },
        rghtScsnDclrByScsnDclrTypeCd() {
            // Session Storage에서 데이터 불러오기
            const rghtScsnDclrByScsnDclrTypeCd = sessionStorage.getItem('rghtScsnDclrByScsnDclrTypeCd');
            return rghtScsnDclrByScsnDclrTypeCd ? rghtScsnDclrByScsnDclrTypeCd : '';
        },
        filteredScsrBplcObj() {
            return this.changeBplcCnt > 1 
                ? this.scsrBplcObj.filter(scsrBplc => scsrBplc.rghtDutyScsnYn !== 'Y') 
                : this.scsrBplcObj;
        }, 
        filteredScsrFcltObj() {
            return this.changeBplcCnt > 1 
                ? this.scsrFcltObj.filter(scsrFclt => scsrFclt.rghtDutyScsnYn !== 'Y') 
                : this.scsrFcltObj;
        }
    },
    watch: {
      param: {
      }
    },
    created() {
    },
    beforeMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    mounted() {
        // 승계신고일련번호 존재할 경우 
        if (this.rghtScsnDclrByScsnDclrSn) {
            this.rghtDutyScsnDclrParamObj.scsnDclrSn = this.rghtScsnDclrByScsnDclrSn; 
            this.rghtDutyScsnDclrParamObj.scsnDclrTypeCd = this.rghtScsnDclrByScsnDclrTypeCd; 

            this.opspBzentyObj.scsnDclrSn = this.rghtScsnDclrByScsnDclrSn;
            this.scsrBzentyObj.scsnDclrSn = this.rghtScsnDclrByScsnDclrSn;

            this.currentStep = 'detailWrite';
            this.targetPopup = 'detailAllBzentyObj'; 
            this.viewType = 'detail'; 
            this.readRghtDutyScsnDclrByScsnDclrSn();
        } else {
            this.viewType = 'update'; 
            this.isAplyOn = true; // 승인요청 버튼 표시 여부 
            this.isEdit = true; // 수정 가능 여부 
        }
    },
    methods: {
      setStep(step, scsnTarget) {
        this.currentStep = step;
        this.rghtDutyScsnDclrParamObj.scsnTarget = scsnTarget; 

        // 업체 승계 
        if (scsnTarget === 'bzenty') { 
            this.rghtDutyScsnDclrParamObj.scsnDclrTypeCd = '01'; 

        // 사업장 승계 
        } else if (scsnTarget === 'bplc') {
            this.rghtDutyScsnDclrParamObj.scsnDclrTypeCd = '02'; 

        // 그 외 빈 값 적용 
        } else {
            this.rghtDutyScsnDclrParamObj.scsnDclrTypeCd = ''; 
        }

      },
      openPopup(popupName, target) {
        this.targetPopup = target;
        storeSwitch.on(popupName);
      },
      handleSelectBzenty(data) { 
        this.changeBplcCnt += 1; 

        this.opspBzentyObj.changeBplcCnt = this.changeBplcCnt;
        this.scsrBzentyObj.changeBplcCnt = this.changeBplcCnt;

        // 업체 정보 조회 
        if (this.targetPopup === 'opspBzentyObj') {

            // 피승계인 데이터 적용 
            this.opspBzentyObj.srchBzentyId = data.bzentyId;
            this.opspBzentyObj.opsnBzentyId = data.bzentyId;
            this.opspBzentyObj.opsnBzentyNm = data.bzentyNm;
            this.opspBzentyObj.opsnBrno = data.brno;
            this.opspBzentyObj.opsnRprsvNm = data.rprsvNm;
            this.opspBzentyObj.opsnAddr = data.bzentyAddr;
            this.opspBzentyObj.opsnDaddr = data.bzentyDaddr;
            
            this.rghtDutyScsnDclrParamObj.opsnBzentyId = data.bzentyId; // 저장용 

            // 업체 승계일 경우 
            if (this.rghtDutyScsnDclrParamObj.scsnDclrTypeCd === '01') { 
                this.opspBzentyObj.scsnDclrTypeCd = '01'; 
                this.opspBzentyObj.bplcIds = null; // 전체 사업장 조회 

            // 사업장 승계일 경우 
            } else if (this.rghtDutyScsnDclrParamObj.scsnDclrTypeCd === '02') {
                this.opspBzentyObj.scsnDclrTypeCd = '02'; // 전체 사업장 조회 여부 ('N' 일 경우 사업장 승계)
                this.opspBzentyObj.bplcIds = data.bplcIds; // 선택한 사업장 목록 

                // 사업장 승계 신청 저장 데이터 
                this.rghtDutyScsnDclrParamObj.bplcIds = data.bplcIds; 
            } 

        } else if (this.targetPopup === 'scsrBzentyObj') { 
            // 승계인 데이터 적용 
            this.scsrBzentyObj.scsnDclrTypeCd = '01'; // 전체 사업장 조회 여부 ('N' 일 경우 사업장 승계)
            this.scsrBzentyObj.srchBzentyId = data.bzentyId;
            this.scsrBzentyObj.scsnBzentyId = data.bzentyId;
            this.scsrBzentyObj.scsnBzentyNm = data.bzentyNm;
            this.scsrBzentyObj.scsnBrno = data.brno;
            this.scsrBzentyObj.scsnRprsvNm = data.rprsvNm;
            this.scsrBzentyObj.scsnAddr = data.bzentyAddr;
            this.scsrBzentyObj.scsnDaddr = data.bzentyDaddr;

            this.scsrBzentyObj.bplcIds = null; // 전체 사업장 조회 

            this.rghtDutyScsnDclrParamObj.scsnBzentyId = data.bzentyId; 

        } else if (this.targetPopup === 'detailAllBzentyObj') { 
            // 상세 조회 

            // 피승계인 데이터 적용 
            this.opspBzentyObj.srchBzentyId = data.result.opsnBzentyId;
            this.opspBzentyObj.opsnBzentyId = data.result.opsnBzentyId;
            this.opspBzentyObj.opsnBzentyNm = data.result.opsnBzentyNm;
            this.opspBzentyObj.opsnBrno = data.result.opsnBrno;
            this.opspBzentyObj.opsnRprsvNm = data.result.opsnRprsvNm;
            this.opspBzentyObj.opsnAddr = data.result.opsnAddr;
            this.opspBzentyObj.opsnDaddr = data.result.opsnDaddr;

            // 업체 승계일 경우 
            if (data.result.scsnDclrTypeCd === '01') { 
                this.opspBzentyObj.scsnDclrTypeCd = '01'; 
                this.opspBzentyObj.bplcIds = null; // 전체 사업장 조회 

            // 사업장 승계일 경우 
            } else if (data.result.scsnDclrTypeCd === '02') {
                this.opspBzentyObj.scsnDclrTypeCd = '02'; 
                this.opspBzentyObj.bplcIds = data.result.bplcIds; // 선택한 사업장 목록 

                // 사업장 승계 신청 저장 데이터 
                this.rghtDutyScsnDclrParamObj.bplcIds = data.result.bplcIds; 
            } 

            // 승계인 데이터 적용 
            this.scsrBzentyObj.srchBzentyId = data.result.scsnBzentyId; // 승계인 업체 조회용 
            this.scsrBzentyObj.scsnBzentyId = data.result.scsnBzentyId;
            this.scsrBzentyObj.scsnBzentyNm = data.result.scsnBzentyNm;
            this.scsrBzentyObj.scsnBrno = data.result.scsnBrno;
            this.scsrBzentyObj.scsnRprsvNm = data.result.scsnRprsvNm;
            this.scsrBzentyObj.scsnAddr = data.result.scsnAddr; 
            this.scsrBzentyObj.scsnDaddr = data.result.scsnDaddr; 

            this.scsrBzentyObj.bplcIds = null; // 전체 사업장 조회 

            // 사용자 등록 정보 
            this.rghtDutyScsnDclrParamObj.scsnTrgtTpbizNm = data.result.scsnTrgtTpbizNm; // 업종 
            this.rghtDutyScsnDclrParamObj.scsnTrgtDclrPrmsnNo = data.result.scsnTrgtDclrPrmsnNo; // 신고번호 
            this.rghtDutyScsnDclrParamObj.scsnRsnCd = data.result.scsnRsnCd;  // 승계사유 
            this.rghtDutyScsnDclrParamObj.scsnYmd = data.result.scsnYmd;  // 승계일자 
            this.rghtDutyScsnDclrParamObj.etcRefCn = data.result.etcRefCn; // 기타 참고사항 
            this.rghtDutyScsnDclrParamObj.atchFileGroupId = data.result.atchFileGroupId; // 첨부파일 
            
            this.rghtDutyScsnDclrParamObj = data.result; // 전체 데이터 

            this.rghtDutyScsnDclrParamObj.srchBzentyId = data.result.opsnBzentyId; // 조회용 

            // 신청 저장용 사업장 승계 목록 
            if (data.result.bplcIds) {
                this.rghtDutyScsnDclrParamObj.bplcIds = data.result.bplcIds.replace(/'/g, '').replace(/\[|\]/g, '').split(',').map(item => Number(item.trim())); 
            }

            // 승인대기 
            if (data.result.scsnDclrSttsCd === '01' ) { 
                this.isModifyOn = true; // 수정 버튼 표시 여부 
            } 
        } 

        // 피승계인 업체와 승계인 업체가 동일한 경우 
        if (this.opspBzentyObj.opsnBzentyId === this.scsrBzentyObj.scsnBzentyId) { 
          this.aplyParamObj.scsnBzentyNm = this.scsrBzentyObj.scsnBzentyNm; 
          this.aplyParamObj.scsnRprsvNm = this.scsrBzentyObj.scsnRprsvNm; 
          this.aplyParamObj.scsnAddr = this.scsrBzentyObj.scsnAddr; 
          this.aplyParamObj.scsnDaddr = this.scsrBzentyObj.scsnDaddr; 
          this.aplyParamObj.scsnAllAddr = `${this.scsrBzentyObj.scsnAddr||''} ${this.scsrBzentyObj.scsnDaddr||''}`; 
          
          // this.rghtDutyScsnDclrParamObj.bzentyInsdScsnYn = 'Y'; 

        // 피승계인 업체와 승계인 업체가 다른 경우 
        } else {
          this.aplyParamObj.scsnBzentyNm = ''; 
          this.aplyParamObj.scsnRprsvNm = ''; 
          this.aplyParamObj.scsnAddr = ''; 
          this.aplyParamObj.scsnDaddr = ''; 
          this.aplyParamObj.scsnAllAddr = ''; 

          // this.rghtDutyScsnDclrParamObj.bzentyInsdScsnYn = 'N'; 
          // this.rghtDutyScsnDclrParamObj.changeBzentyAddrYn = 'N';
        }

        // 피승계인 업체와 승계인 업체가 동일한 경우 초기화 
        if (this.opspBzentyObj.opsnBzentyId === this.scsrBzentyObj.scsnBzentyId) { 
          this.rghtDutyScsnDclrParamObj.bzentyInsdScsnYn = 'Y'; 
          this.rghtDutyScsnDclrParamObj.changeBzentyAddrYn = 'N'; 

        // 피승계인 업체와 승계인 업체가 다른 경우 초기화 
        } else {
          this.rghtDutyScsnDclrParamObj.bzentyInsdScsnYn = 'N'; 
          this.rghtDutyScsnDclrParamObj.changeBzentyAddrYn = 'N';
        }

        this.readRghtScsnDclrBplcByBzentyId(this.targetPopup); // 사업장 목록 조회 
      },
      readRghtScsnDclrBplcByBzentyId(pTargetSe) {

        // 사업장 목록 조회 
        if (pTargetSe === 'opspBzentyObj') { 
            // 피승계인 데이터 적용 
            this.$apiCall.get(API.RGHT_SCSN_DCLR_BPLC, this.opspBzentyObj, data => this.readRghtScsnDclrBplcByBzentyIdSuccess(data, pTargetSe), this.error);

        } else if (pTargetSe === 'scsrBzentyObj') {
            // 승계인 데이터 적용 
            this.$apiCall.get(API.RGHT_SCSN_DCLR_BPLC, this.scsrBzentyObj, data => this.readRghtScsnDclrBplcByBzentyIdSuccess(data, pTargetSe), this.error);
        } else if (pTargetSe === 'detailAllBzentyObj') { 
            // 피승계인 데이터 적용 
            this.$apiCall.get(API.RGHT_SCSN_DCLR_BPLC, this.opspBzentyObj, data => this.readRghtScsnDclrBplcByBzentyIdSuccess(data, 'opspBzentyObj'), this.error);

            // 승계인 데이터 적용 
            this.$apiCall.get(API.RGHT_SCSN_DCLR_BPLC, this.scsrBzentyObj, data => this.readRghtScsnDclrBplcByBzentyIdSuccess(data, 'scsrBzentyObj'), this.error);
        }

      },
      readRghtScsnDclrBplcByBzentyIdSuccess(data, pTargetSe) {

        // 사업장 목록 조회 결과 
        if (!data.result) {
          this.error();
          return;
        }
        if (pTargetSe === 'opspBzentyObj') { 
            // 피승계인 데이터 적용 
            this.opspBplcObj = data.result.list; 

        } else if (pTargetSe === 'scsrBzentyObj') { 
            // 승계인 데이터 적용 
            this.scsrBplcObj = data.result.list; 

        } else if (pTargetSe === 'detailAllBzentyObj') { 
            // 피승계인 데이터 적용 
            this.opspBplcObj = data.result.list; 

            // 승계인 데이터 적용 
            this.scsrBplcObj = data.result.list; 
        } 

        this.readRghtScsnDclrFcltByBzentyId(pTargetSe);
      }, 
      readRghtScsnDclrFcltByBzentyId(pTargetSe) {
        // 시설 목록 조회 
        // let searchApiInfo = ''; 

        if (pTargetSe === 'opspBzentyObj') { 
            // 피승계인 데이터 적용 
            this.$apiCall.get(API.RGHT_SCSN_DCLR_FCLT, this.opspBzentyObj, data => this.readRghtScsnDclrFcltByBzentyIdSuccess(data, pTargetSe), this.error);

        } else if (pTargetSe === 'scsrBzentyObj') {
            // 승계인 데이터 적용 
            this.$apiCall.get(API.RGHT_SCSN_DCLR_FCLT, this.scsrBzentyObj, data => this.readRghtScsnDclrFcltByBzentyIdSuccess(data, pTargetSe), this.error);
        
        } else if (pTargetSe === 'detailAllBzentyObj') { 
            // 피승계인 데이터 적용 
            this.$apiCall.get(API.RGHT_SCSN_DCLR_FCLT, this.opspBzentyObj, data => this.readRghtScsnDclrFcltByBzentyIdSuccess(data, pTargetSe), this.error);

            // 승계인 데이터 적용 
            this.$apiCall.get(API.RGHT_SCSN_DCLR_FCLT, this.scsrBzentyObj, data => this.readRghtScsnDclrFcltByBzentyIdSuccess(data, pTargetSe), this.error);
        }

      },
      readRghtScsnDclrFcltByBzentyIdSuccess(data, pTargetSe) { 
        // 시설 목록 결과 데이터 
        if (!data.result) {
          this.error();
          return;
        }

        if (pTargetSe === 'opspBzentyObj') { 
            // 피승계인 데이터 적용 
            this.opspFcltObj = data.result.list; 

        } else if (pTargetSe === 'scsrBzentyObj') {
            // 승계인 데이터 적용 
            this.scsrFcltObj = data.result.list; 

        } else if (pTargetSe === 'detailAllBzentyObj') { 
            // 피승계인 데이터 적용 
            this.opspFcltObj = data.result.list; 

            // 승계인 데이터 적용 
            this.scsrFcltObj = data.result.list; 
        }
      },

      createRghtScsnDclrAprvDmnd() {
        if (!this.opspBzentyObj.opsnBzentyId) {
            alert("피승계인 업체를 선택해주세요.");
            return;
        } 

        if (!this.scsrBzentyObj.scsnBzentyId) {
            alert("승계인 업체를 선택해주세요.");
            return;
        } 

        // this.rghtDutyScsnDclrParamObj.bzentyInsdScsnYn 

        const bzentyInsdScsnYnVal = this.rghtDutyScsnDclrParamObj.bzentyInsdScsnYn; 

        const opsnBzentyNmVal = this.opspBzentyObj.opsnBzentyNm || ''; 
        const opsnRprsvNmVal = this.opspBzentyObj.opsnRprsvNm || ''; 
        const opsnAddrVal = this.opspBzentyObj.opsnAddr || ''; 
        const opsnDaddrVal = this.opspBzentyObj.opsnDaddr || ''; 

        const scsnBzentyNmVal = this.aplyParamObj.scsnBzentyNm || ''; 
        const scsnRprsvNmVal = this.aplyParamObj.scsnRprsvNm || ''; 
        const scsnAddrVal = this.scsrBzentyObj.scsnAddr || ''; 
        const scsnDaddrVal = this.scsrBzentyObj.scsnDaddr || ''; 

        const opsnAllVal = opsnBzentyNmVal + opsnRprsvNmVal + opsnAddrVal + opsnDaddrVal;
        const scsnAllVal = scsnBzentyNmVal + scsnRprsvNmVal + scsnAddrVal + scsnDaddrVal;

        if (bzentyInsdScsnYnVal === 'Y' && opsnAllVal.replace(/\s+/g, '') === scsnAllVal.replace(/\s+/g, '')) {
            alert("피승계인과 승계인의 업체명, 대표자명, 주소가 동일합니다."); 
            return; 
        } 

        // 승인요청 
        let txtMsg = "승인요청 하시겠습니까?";

        if (!confirm(txtMsg)){ 
            return; 
        }

        if (bzentyInsdScsnYnVal === 'Y') { 
            this.rghtDutyScsnDclrParamObj.scsnBzentyNm = scsnBzentyNmVal;
            this.rghtDutyScsnDclrParamObj.scsnRprsvNm = scsnRprsvNmVal;

            this.scsrBzentyObj.scsnBzentyNm = scsnBzentyNmVal;
            this.scsrBzentyObj.scsnRprsvNm = scsnRprsvNmVal;
        } 

        this.rghtDutyScsnDclrParamObj.scsnDclrSttsCd = '01'; // 승인대기 

        this.$refs.rghtDutyScsnDclrAttachFileList.save().then(atchFileGroupId => {  
            this.rghtDutyScsnDclrParamObj.atchFileGroupId = atchFileGroupId;

            this.$apiCall.post(
                API.RGHT_SCSN_DCLR, 
                this.rghtDutyScsnDclrParamObj,
                () => {
                    alert("승인요청이 완료되었습니다.");
                    this.currentStep = 'completion'; 
                    window.scrollTo({ top: 0, behavior: 'smooth' }); 
                },
                () => {
                    alert("저장에 실패하였습니다.");
                }
            );
          });
      },

      updateRghtScsnDclrAprvDmnd() {
        this.isEdit = true;
        this.isAplyOn = true;
        this.viewType = 'update';
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 

      readRghtDutyScsnDclrByScsnDclrSn() {
        // 권리의무승계 정보 조회 
        this.$apiCall.get(buildUri(API.RGHT_SCSN_DCLR_DETAIL, {scsnDclrSn : this.rghtScsnDclrByScsnDclrSn}), {}, data => this.handleSelectBzenty(data), this.error);
      },

      navigate() {
        this.$router.push({path : '/my-office/RghtScsnDclr'})
            .catch(() => {
                console.error("Navigation Error")
            }
            );
        },

        formatDate(dateString) {
            if (!dateString) return ''; // 값이 없으면 빈 문자열 반환
            return `${dateString.slice(0, 4)}.${dateString.slice(4, 6)}.${dateString.slice(6, 8)}`;
        },

        formatBrno(brnoString) {
            if (brnoString && typeof brnoString === 'string') {
                let cleanValue = brnoString.replace(/\D/g, '');
                if (cleanValue.length > 10) {
                    cleanValue = cleanValue.substring(0, 10);
                }
                return cleanValue.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
            }
            return brnoString;
        }, 
        updateSelectedReasonText() {
            // selectbox 공통 기능에서 id, ref 속성 추가해도 변경됨 
            // 'scsnRsnArea' id를 가진 태그를 기준으로 가장 가까운 td 태그를 찾아서 셀렉트박스 값을 가져옴
            const scsnRsnArea = document.getElementById('scsnRsnArea');
            const tdElement = scsnRsnArea.closest('tr').querySelector('td'); 
            const selectBox = tdElement.querySelector('select');
            
            // selectBox에서 선택된 텍스트 가져오기 
            const selectedText = selectBox ? selectBox.options[selectBox.selectedIndex].text : '';
            
            this.rghtDutyScsnDclrParamObj.scsnRsnNm = selectedText;
        },
        callPopupAddress() {
            storeSwitch.on('addressPopup');
        },

        selectedAddressByPopup(data) {
            this.rghtDutyScsnDclrParamObj.changeBzentyAddrYn = 'Y';        // 주소 변경 여부 
            this.rghtDutyScsnDclrParamObj.siNm = data.siNm;            // 시도 명
            this.rghtDutyScsnDclrParamObj.sggNm = data.sggNm;           // 시군구 명 

            this.rghtDutyScsnDclrParamObj.scsnUntyZip = data.zipNo;        // 우편번호 
            this.rghtDutyScsnDclrParamObj.scsnAddr = data.roadAddr;        // 주소 
            this.rghtDutyScsnDclrParamObj.scsnDaddr = data.roadDetailAddr; // 상세 주소 
            this.aplyParamObj.scsnAllAddr = `[${data.zipNo}] ${data.roadAddr} ${data.roadDetailAddr}`;   // 전체 주소 
        },
      
    }
  }
  </script>
  
  <style scoped>
  
  </style>