<template>
  <rjct-rsn-area :prfmncBasicInfo="prfmncBasicInfo" />
  <prfmnc-mdfcn-prm-area :prfmncBasicInfo="prfmncBasicInfo" />

  <h3 class="title1">고형연료제품 반입 현황(톤/월)</h3>
  <sb-grid
      id="sdflCrynGrid"
      :dataSource="sdflCrynDataSource"
      :columns="sdflCrynGridColumns"
      :gridattr="sdflCrynGridAttr"
      v-model:grid="sdflCrynGridObject"
      @loaded="sdflCrynGridLoaded"
      :refreshGrid="sdflCrynGridReload"
  />

  <h3 class="title1">고형연료제품 처리 현황</h3>
  <h2 class="title2">시설별 고형연료제품 투입 및 에너지 생산 현황</h2>
  <sb-grid
      id="sdflInputGrid"
      :dataSource="sdflInputDataSource"
      :columns="sdflInputGridColumns"
      :gridattr="sdflInputGridAttr"
      v-model:grid="sdflInputGridObject"
      @loaded="sdflInputGridLoaded"
      :refreshGrid="sdflInputGridReload"
  />

  <h2 class="title2">고형연료제품 종류별 처리 현황</h2>
  <sb-grid
      id="sdflPrcsGrid"
      :dataSource="sdflPrcsDataSource"
      :columns="sdflPrcsGridColumns"
      :gridattr="sdflPrcsGridAttr"
      v-model:grid="sdflPrcsGridObject"
      @loaded="sdflPrcsGridLoaded"
      :refreshGrid="sdflPrcsGridReload"
  />

  <h3 class="title1">폐자원에너지 생산/이용 현황</h3>
  <div class="board_write thead mb_10">
    <table>
      <caption>폐자원에너지 생산량, 자체사용량, 외부판매량으로 구성</caption>
      <colgroup>
        <col style="width: 16.66%;">
        <col style="width: 16.66%;">
        <col style="width: 16.66%;">
        <col style="width: 16.66%;">
        <col style="width: 16.66%;">
        <col >
      </colgroup>
      <thead>
      <tr>
        <th scope="col" colspan="2">폐자원에너지 생산량</th>
        <th scope="col" colspan="2">자체사용량</th>
        <th scope="col" colspan="2">외부판매량</th>
      </tr>
      <tr>
        <th scope="col" class="gridh-bg-vapor">스팀(st/톤)</th>
        <th scope="col" class="gridh-bg-electricity">전기(MWh)</th>
        <th scope="col" class="gridh-bg-vapor">스팀(st/톤)</th>
        <th scope="col" class="gridh-bg-electricity">전기(MWh)</th>
        <th scope="col" class="gridh-bg-vapor">스팀(st/톤)</th>
        <th scope="col" class="gridh-bg-electricity">전기(MWh)</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td aria-label="폐자원에너지 생산량-스팀(st/톤)" class="text-end">{{ formatNumber(usePrfmncInfo.stSumPrdqty) }}</td>
        <td aria-label="폐자원에너지 생산량-전기(MWh)" class="text-end">{{ formatNumber(usePrfmncInfo.prvtmSumPrdqty) }}</td>
        <td aria-label="자체사용량-스팀(st/톤)" class="text-end">{{ formatNumber(usePrfmncInfo.stUsqty) }}</td>
        <td aria-label="자체사용량-전기(MWh)" class="text-end">{{ formatNumber(usePrfmncInfo.prvtmSumUsqty) }}</td>
        <td aria-label="외부판매량-스팀(st/톤)" class="text-end">{{ formatNumber(usePrfmncInfo.stSumNsqty) }}</td>
        <td aria-label="외부판매량-전기(MWh)" class="text-end">{{ formatNumber(usePrfmncInfo.prvtmSumNsqty) }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <h3 class="title1">폐자원에너지 업체별 판매 현황</h3>
  <sb-grid
      id="ntslGrid"
      :dataSource="ntslDataSource"
      :columns="ntslGridColumns"
      :gridattr="ntslGridAttr"
      v-model:grid="ntslGridObject"
      @loaded="ntslGridLoaded"
      :refreshGrid="ntslGridReload"
  />

  <common-button-area
      :prfmncBasicInfo="prfmncBasicInfo"
  />
</template>

<script>
import SbGrid from "@/components/SbGrid.vue";
import utils from "@/js/utils/utils";
import RjctRsnArea from "@/views/prfmnc-rpt/dtl/components/RjctRsnArea.vue";
import PrfmncMdfcnPrmArea from "@/views/prfmnc-rpt/dtl/components/PrfmncMdfcnPrmArea.vue";
import commonButtonArea from "@/views/prfmnc-rpt/dtl/components/CommonButtonArea.vue";

const CODE = {
  PRODUCT: "MBR010",
  ACCEPTANCE_DIFFERENCE_REASON: "COM450", // 인계/인수차이 사유코드
};

export default {
  props: ['prfmncBasicInfo', 'sdflCrynInfo', 'sdflInputInfo', 'sdflPrcsInfo', 'usePrfmncInfo', 'ntslInfo'],
  components: {
    commonButtonArea,
    RjctRsnArea,
    PrfmncMdfcnPrmArea,
    SbGrid,
  },
  data() {
    return {
      sdflCrynGridReload: false,
      sdflCrynGridObject: null,
      sdflCrynDataSource: [],
      sdflCrynGridColumns: [],
      sdflCrynGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        excelExport: {
          fileName: '고형연료제품 반입 현황(톤/월).xlsx',
          includeHeader: true,
          adjustCaption: (value) => {
            return value?.toString().includes('<br>') ? value.replace(/<br\s*\/?>/gi, '\n') : value;
          },
        },
        captionHeight: 40
      },
      sdflInputGridReload: false,
      sdflInputGridObject: null,
      sdflInputDataSource: [],
      sdflInputGridColumns: [],
      sdflInputGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        toolBar: [],
      },
      sdflPrcsGridReload: false,
      sdflPrcsGridObject: null,
      sdflPrcsDataSource: [],
      sdflPrcsGridColumns: [],
      sdflPrcsGridAttr: {
        showRowNo: false,
        pageable: false,
        toolBar: [],
      },
      ntslGridReload: false,
      ntslGridObject: null,
      ntslDataSource: [],
      ntslGridColumns: [],
      ntslGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        toolBar: [],
      },
      prdctItemCdList: [],
      hnovAcptnDiffRsnCdList: [],
    }
  },
  computed: {
  },
  watch: {
    sdflCrynInfo() {
      this.initializeSdflCryn();
    },
    sdflInputInfo() {
      this.initializeSdflInput();
    },
    sdflPrcsInfo() {
      this.initializeSdflPrcs();
    },
    ntslInfo() {
      this.initializeNtsl();
    }
  },
  created() {
    this.initialize();
  },
  mounted() {
  },
  methods: {
    initialize() {
      this.loadPrdctItemCd();
      this.loadHnovAcptnDiffRsnCd();
    },
    loadPrdctItemCd() {
      const prdctItemCdList = this.$store.getters.getCodeList({ groupCd: CODE.PRODUCT, useYn: 'Y', frstRefVl: "SRF" });
      this.prdctItemCdList = prdctItemCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    loadHnovAcptnDiffRsnCd() {
      const hnovAcptnDiffRsnCdList = this.$store.getters.getCodeList({ groupCd: CODE.ACCEPTANCE_DIFFERENCE_REASON, useYn: 'Y' });
      this.hnovAcptnDiffRsnCdList = hnovAcptnDiffRsnCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    initializeSdflCryn() {
      this.sdflCrynDataSource = this.sdflCrynInfo.list;
      this.initializeSdflCrynGridColumns();
      this.initializeSdflCrynGridAttr();
      this.sdflCrynGridReload = true;
    },
    initializeSdflInput() {
      this.sdflInputDataSource = this.sdflInputInfo.list;
      this.initializeSdflInputGridColumns();
      this.initializeSdflInputGridAttr();
      this.sdflInputGridReload = true;
    },
    initializeSdflPrcs() {
      this.sdflPrcsDataSource = this.sdflPrcsInfo.list;
      this.initializeSdflPrcsGridColumns();
      this.initializeSdflPrcsGridAttr();
      this.sdflPrcsGridReload = true;
    },
    initializeNtsl() {
      this.ntslDataSource = this.ntslInfo.list;
      this.initializeNtslGridColumns();
      this.initializeNtslGridAttr();
      this.ntslGridReload = true;
    },
    initializeSdflCrynGridColumns() {
      const list = this.sdflCrynInfo.list;
      const uniquePrdctItemCdList = [...new Set(list.map(item => item.prdctItemCd))];
      const filteredPrdctItemList = this.prdctItemCdList
          .filter(item => uniquePrdctItemCdList.includes(item.value));

      const prdctItemList = filteredPrdctItemList.length === 1
        ? []
        : filteredPrdctItemList.map(item => ({ text: item.text, value: item.value }));

      this.sdflCrynGridColumns = [
        { field: 'bplcId', caption: '사업장아이디', visible: false },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false },
        { field: 'ctpvStdgNm', caption: '시·도', width: 100, mergeRow: true, total: { headerCss:'grid-header' } },
        { field: 'sggStdgNm', caption: '시·군·구', width: 100,
          mergeRow: (data) => data.ctpvStdgCd + "." + data.sggStdgCd,
          total: { headerCss:'grid-header' }
        },
        { field: 'bplcKndNm', caption: '사업장종류', width: 100,
          mergeRow: (data) => data.ctpvStdgCd + "." + data.sggStdgCd + "." + data.bplcKndNm,
          total: { headerCss:'grid-header' }
        },
        { field: 'bplcNm', caption: '사업장명', part: 'sticky', width: 200, align: 'left', total: { headerCss:'grid-header' } },
        { field: 'bzentyNm', caption: '업체명', width: 200, align: 'left', total: { headerCss:'grid-header' } },
        { field: 'prdctItemNm', caption: '구분', width: 120,
          total: {
            aggregates: [],
            header: [
              { template: '합계', align: 'right' },
              ...prdctItemList.map(item => ({ template: item.text, align: 'right' }))
            ],
            headerCss:'grid-header',
          }
        },
        { field: 'realtmPrdctHndqty', caption: '실시간인계량(톤)<br>(A)', width: 100,
          type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', colCss: 'grid-disabled',
          total: {
            aggregates: [
                {func: 'sum', require: 'realtmPrdctHndqty'},
              ...prdctItemList.map(item => ({
                func: (items) => items
                    .filter(entry => entry.prdctItemCd === item.value)
                    .reduce((sum, entry) => sum + (entry.realtmPrdctHndqty || 0), 0),
                field: `realtmPrdctHndqty${item.value}`
              }))
            ],
            header: [
                { template: '{{sum}}', format: '#,##0.00' },
              ...prdctItemList.map(item => ({
                template: `{{realtmPrdctHndqty${item.value}}}`, format: '#,##0.00'
              }))
            ],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctHndqty', caption: '저장된인계량(톤)', width: 100,
          type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', colCss: 'grid-disabled',
          total: {
            aggregates: [
              {func: 'sum', require: 'prdctHndqty'},
              ...prdctItemList.map(item => ({
                func: (items) => items
                    .filter(entry => entry.prdctItemCd === item.value)
                    .reduce((sum, entry) => sum + (entry.prdctHndqty || 0), 0),
                field: `prdctHndqty${item.value}`
              }))
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00' },
              ...prdctItemList.map(item => ({
                template: `{{prdctHndqty${item.value}}}`, format: '#,##0.00'
              }))
            ],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctAcpqty', caption: '인수량(톤)<br>(B)', width: 100,
          type: 'number',
          dataType: 'number',
          align: 'right',
          format: '#,##0.00',
          total: {
            aggregates: [
              {func: 'sum', require: 'prdctAcpqty'},
              ...prdctItemList.map(item => ({
                func: (items) => items
                    .filter(entry => entry.prdctItemCd === item.value)
                    .reduce((sum, entry) => sum + (entry.prdctAcpqty || 0), 0),
                field: `prdctAcpqty${item.value}`
              }))
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00' },
              ...prdctItemList.map(item => ({
                template: `{{prdctAcpqty${item.value}}}`, format: '#,##0.00'
              }))
            ],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctDscdCrtqty', caption: '폐기반출량(톤)', width: 100,
          type: 'number',
          dataType: 'number',
          align: 'right',
          format: '#,##0.00',
          total: {
            aggregates: [
              {func: 'sum', require: 'prdctDscdCrtqty'},
              ...prdctItemList.map(item => ({
                func: (items) => items
                    .filter(entry => entry.prdctItemCd === item.value)
                    .reduce((sum, entry) => sum + (entry.prdctDscdCrtqty || 0), 0),
                field: `prdctDscdCrtqty${item.value}`
              }))
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00' },
              ...prdctItemList.map(item => ({
                template: `{{prdctDscdCrtqty${item.value}}}`, format: '#,##0.00'
              }))
            ],
            headerCss:'grid-header',
          },
        },
        { field: 'diff', caption: '차이량(톤)<br>(A - B)', width: 150, align: 'right',
          dataType: 'number', format: '#,##0.00', editable: false,
          total: {
            aggregates: [
              {
                func: (items) => {
                  const totalRealtmPrdctHndqty = items.reduce((sum, item) => sum + (item.realtmPrdctHndqty || 0), 0);
                  const totalPrdctAcpqty = items.reduce((sum, item) => sum + (item.prdctAcpqty || 0), 0);
                  return Math.abs(utils.round2(totalRealtmPrdctHndqty - totalPrdctAcpqty));
                  // const totalPrdctDscdCrtqty = items.reduce((sum, item) => sum + (item.prdctDscdCrtqty || 0), 0);
                  // return Math.abs(totalRealtmPrdctHndqty - totalPrdctAcpqty - totalPrdctDscdCrtqty);
                },
                field: 'diffTotal'
              },
              ...prdctItemList.map(item => ({
                func: (items) => {
                  const filteredItems = items.filter(entry => entry.prdctItemCd === item.value);

                  const totalRealtmPrdctHndqty = filteredItems.reduce((sum, entry) => sum + (entry.realtmPrdctHndqty || 0), 0);
                  const totalPrdctAcpqty = filteredItems.reduce((sum, entry) => sum + (entry.prdctAcpqty || 0), 0);
                  return Math.abs(utils.round2(totalRealtmPrdctHndqty - totalPrdctAcpqty));
                  // const totalPrdctDscdCrtqty = filteredItems.reduce((sum, entry) => sum + (entry.prdctDscdCrtqty || 0), 0);
                  // return Math.abs(totalRealtmPrdctHndqty - totalPrdctAcpqty - totalPrdctDscdCrtqty);
                },
                field: `diff${item.value}`
              }))
            ],
            header: [
              { template: '{{diffTotal}}', format: '#,##0.00' },
              ...prdctItemList.map(item => ({
                template: `{{diff${item.value}}}`, format: '#,##0.00'
              }))
            ],
            headerCss:'grid-header',
          },
          getValue: (value, field, rowItem) => {
            const realtmPrdctHndqty = rowItem.data.realtmPrdctHndqty || 0
            const prdctAcpqty = rowItem.data.prdctAcpqty || 0;
            const difference = Math.abs(utils.round2(realtmPrdctHndqty - prdctAcpqty));
            // const prdctDscdCrtqty = rowItem.data.prdctDscdCrtqty || 0;
            // const difference = realtmPrdctHndqty - prdctAcpqty - prdctDscdCrtqty;

            return difference !== 0 ? Math.abs(difference) : '';
          },
          colCss: (data) => {
            const realtmPrdctHndqty = data.realtmPrdctHndqty || 0
            const prdctAcpqty = data.prdctAcpqty || 0;
            // 음수, 양수에 따른 클래스 처리로 인해 절대값처리 x
            const difference = utils.round2(realtmPrdctHndqty - prdctAcpqty);
            // const prdctDscdCrtqty = data.prdctDscdCrtqty || 0;
            // const difference = realtmPrdctHndqty - prdctAcpqty - prdctDscdCrtqty;

            if (difference !== 0) {
              if(difference < 0){
                return 'grid-danger';
              }else{
                return 'grid-warning';
              }
            } else {
              return 'grid-disabled';
            }
          }
        },
        { field: 'hnovAcptnDiffRsnCd', caption: '비고(사유)', width: 184, align: 'left',
          type: 'combo',
          items: this.hnovAcptnDiffRsnCdList,
          comboLabel: 'text',
          comboValue: 'value',
          total: { headerCss:'grid-header' },
        },
        { field: 'hnovAcptnDiffRmrkCn', caption: '기타내용', width: 200, align: 'left', total: { headerCss:'grid-header' } },
      ]
    },
    initializeSdflCrynGridAttr() {
      const itemCount = this.sdflCrynInfo.list.length;
      let uniqueItemCount = new Set(this.sdflCrynInfo.list.map(item => item.prdctItemCd)).size;
      uniqueItemCount = uniqueItemCount === 1 ? 0 : uniqueItemCount;

      this.sdflCrynGridAttr.height = Math.max(((itemCount + uniqueItemCount) * 30) + 150, 210);
    },
    initializeSdflInputGridColumns() {
      this.sdflInputGridColumns = [
        { field: 'fcltSqno', caption: '시설순번', visible: false },
        { field: 'fcltNm', caption: '시설명', minWidth: 100, maxWidth: 1000, width: 100, unit: '%', mergeRow: true, align: 'left', total: { headerCss:'grid-header' } },
        { field: 'prdctItemCd', caption: '제품품목코드', visible: false },
        { field: 'prdctItemNm', caption: '고형연료제품<br>종류', width: 150,
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          }
        },
        { field: 'prdctInpqty', caption: '고형연료제품<br>투입량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          total: {
            aggregates: [{func: 'sum', require: 'prdctInpqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctLcp', caption: '저위발열량<br>(kcal/kg)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          total: {
            aggregates: [
              { func: (items) => {
                  const filteredItems = items.filter(item => item.prdctLcp > 0);
                  const sumPrdqty = filteredItems.reduce((total, item) => total + (item.prdqty || 0), 0);
                  const sumPrdqtyLcp = filteredItems.reduce((total, item) => {
                    const prdqty = item.prdqty || 0;
                    const prdctLcp = item.prdctLcp || 0;
                    return total + (prdqty * prdctLcp);
                  }, 0);

                  return sumPrdqty > 0 ? utils.round2(sumPrdqtyLcp / sumPrdqty) : 0;
                },
                field: 'prdctLcpAvg'
              }
            ],
            header: [{ template: '{{prdctLcpAvg}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { caption: '에너지생산량',
          columns: [
            { field: 'stPrdqty', caption: '스팀(st/톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
              captionCss:'gridh-bg-vapor',
              total: {
                aggregates: [{func: 'sum', require: 'stPrdqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'prvtmPrdqty', caption: '전기(MWh)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
              captionCss:'gridh-bg-electricity',
              total: {
                aggregates: [{func: 'sum', require: 'prvtmPrdqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
          ]
        },
      ];
    },
    initializeSdflInputGridAttr() {
      const itemCount = this.sdflInputInfo.list.length;

      this.sdflInputGridAttr.height = Math.max((itemCount  * 30) + 110, 150);
    },
    initializeSdflPrcsGridColumns() {
      this.sdflPrcsGridColumns = [
        { field: 'prdctItemCd', caption: '제품품목코드', visible: false },
        { field: 'prdctItemNm', caption: '고형연료제품 종류', minWidth: 100, maxWidth: 1000, width: 100, unit: '%',
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          }
        },
        { field: 'prdctCrfqty', caption: '이월량(톤)', dataType: 'number', align: 'right', format: '#,##0.00',
          width: 150, colCss: 'grid-disabled',
          total: {
            aggregates: [{func: 'sum', require: 'prdctCrfqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctSumAcpqty', caption: '인수량(톤)', dataType: 'number', align: 'right', format: '#,##0.00',
          width: 150, colCss: 'grid-disabled',
          total: {
            aggregates: [{func: 'sum', require: 'prdctSumAcpqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctSumInpqty', caption: '투입량(톤)', dataType: 'number', align: 'right', format: '#,##0.00',
          width: 150, colCss: 'grid-disabled',
          total: {
            aggregates: [{func: 'sum', require: 'prdctSumInpqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctRsdqty', caption: '잔재물(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          total: {
            aggregates: [{func: 'sum', require: 'prdctRsdqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctRmnqty', caption: '잔량(톤)', dataType: 'number', align: 'right', format: '#,##0.00',
          width: 150, colCss: 'grid-disabled',
          total: {
            aggregates: [{func: 'sum', require: 'prdctRmnqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
      ]
    },
    initializeSdflPrcsGridAttr() {
      const itemCount = this.sdflPrcsInfo.list.length;

      this.sdflPrcsGridAttr.height = Math.max((itemCount  * 30) + 90, 150);
    },
    initializeNtslGridColumns() {
      this.ntslGridColumns = [
        { field: 'eneBzentySn', caption: '에너지공급업체일련번호', visible: false },
        { field: 'ctpvStdgNm', caption: '시·도', width: 120, mergeRow: true, total: { headerCss:'grid-header' } },
        { field: 'sggStdgNm', caption: '시·군·구', width: 120, mergeRow: true, total: { headerCss:'grid-header' } },
        { field: 'bzentyNm', caption: '업체명', minWidth: 100, maxWidth: 1000, width: 100, unit: '%', align: 'left',
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          }
        },
        { caption: '외부판매량',
          columns: [
            { field: 'stNsqty', caption: '스팀(st/톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
              captionCss:'gridh-bg-vapor',
              total: {
                aggregates: [{func: 'sum', require: 'stNsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'prvtmNsqty', caption: '전기(MWh)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
              captionCss:'gridh-bg-electricity',
              total: {
                aggregates: [{func: 'sum', require: 'prvtmNsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
          ]
        }
      ]
    },
    initializeNtslGridAttr() {
      const itemCount = this.ntslInfo.list.length;

      this.ntslGridAttr.height = Math.max((itemCount  * 30) + 110, 150);
    },
    sdflCrynGridLoaded() {
      this.sdflCrynGridReload = false;
    },
    sdflInputGridLoaded() {
      this.sdflInputGridReload = false;
    },
    sdflPrcsGridLoaded() {
      this.sdflPrcsGridReload = false;
    },
    ntslGridLoaded() {
      this.ntslGridReload = false;
    },
    formatNumber(number) {
      return utils.formatNumberWithComma(number);
    },
  }
}
</script>

<style scoped>
  .grid_box { margin-bottom: 5px; }
</style>