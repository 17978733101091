<template>
  <div class="sch_form_wrap">
    <div class="sch_form row2">
      <span>
        <label>구분</label>
        <br-form-select label="선택"
            v-model:value="selected" :options="options" @change="(data) => this.type=data.value"/>
        <br-form-input label="검색어" v-model="keyword" @enterKeyup="search"/>
      </span>
      <span>
      </span>
    </div>
    <div class="btn_area">
      <br-anchor-button label="검색" role="button" size="sm" format="sch" @click="searchBtn" />
      <br-anchor-button label="새로고침" role="button" size="sm" format="txtN" icon="ico_refresh" @click="reset" />
    </div>
  </div>
  <div class="board_info">
    <p class="page">
      <span class="total">전체 <strong>{{ this.count }}</strong></span>
      <span>페이지 <strong>{{ this.searchParam.page }}/{{ this.maxPage }}</strong></span>
    </p>
  </div>
  <div class="board_list">
    <ul>
      <li v-for="(item, index) in listObj" :key="index">
        <a href="" onclick="return false;" @click="toDetail(item)">
          <span v-if="item.pstNtcYn=='Y'" class="num badge">공지</span>
          <span v-else class="num num">{{item.pstSn}}</span>
          <span class="title"><strong>{{item.pstTtl}}</strong></span>
          <span class="date">{{item.regDt}}</span>
        </a>
      </li>
    </ul>
  </div>
  <insp-pagination :total="count" :size="searchParam.size" v-model:page="searchParam.page" v-model:maxPage="maxPage" @clickedPage="search"> </insp-pagination>
</template>

<script>
import inspPagination from "@/views/sfli/components/inspPagination.vue";
import {storeSession} from "@/js/store/store-session";

const API = {
  POST: "/post/",
  POST_ACS_CHK: "/post/detailAcsChk",
};

const PAGE_SIZE = 10;

export default {
  components: {inspPagination},
  data() {
    return {
      options: [
          {text: "제목", value: "1"},
          {text: "내용", value: "2"},
          // {text: "작성자", value: "3"},
          {text: "제목 + 내용", value: "4"}
      ],
      listObj: [],
      selected: "4",
      keyword: "",
      type: "4",
      searchParam: {
        pstTtl: "",
        bbsPstCn: "",
        rgtrNm: "",
        bbsSn: "",
        page: 1,
      },
      userinfo: storeSession.getMemberInfo(),
      count: 0,
      maxPage: 1,
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  beforeMount() {
    this.searchParam.bbsSn = this.$route.params.bbsSn;
  },
  mounted() {
    window.scrollTo(0, 0);
    this.initialize();
  },
  methods: {
    initialize() {
      this.search();
    },
    searchBtn(){
      this.searchParam.page = 1;
      this.search();
    },
    search(){
      this.searchParam.size = PAGE_SIZE;
      this.chage();
      this.$apiCall.get(API.POST, this.searchParam, this.success, this.error);
    },
    success(data){
      if(!data.result){
        return;
      }
      this.listObj = data.result.list;
      this.count = data.result.total;
    },
    error() {
      alert("데이터를 가져오는 중 오류가 발생했습니다.");
    },
    reset() {
      this.selected = 4;
      this.keyword = "";
    },
    chage(){
      this.searchParam.pstTtl = ""
      this.searchParam.bbsPstCn = ""
      this.searchParam.rgtrNm = ""
      if(this.type==""){
        this.keyword = ""
      }else if(this.type==1){
        this.searchParam.pstTtl = this.keyword
      }else if(this.type==2){
        this.searchParam.bbsPstCn = this.keyword
      }else if(this.type==3){
        this.searchParam.rgtrNm = this.keyword
      }else if(this.type==4){
        this.searchParam.pstTtl = this.keyword
        this.searchParam.bbsPstCn = this.keyword
      }
    },
    toDetail(item){
      this.$apiCall.get(
          API.POST_ACS_CHK,
          {
            pstSn : item.pstSn
          },
          (data) => {
            if(data.result > 0){
              this.$router.push({path : `/cust-sprt/PostDetail/`+this.searchParam.bbsSn, query : {pstSn : item.pstSn}})
                  .catch(() => {
                    console.error("Navigation Error");
                  });
            }
          },
          (error) => {
            alert(error.message);
          }
      );
    }
  }
}
</script>

<style scoped>

</style>
