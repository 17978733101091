const MENU_LIST = [
    {
        menuGrpNm: "정기검사 신청",
        path: "/sfli/FcltInspAply",
        items: [
            {
                menuNm: "정기검사 신청 등록",
                path: "/sfli/fclt-insp-aply/FcltInspAplyCreate",
            }
        ],
        isPublic: true,
    },
    {
        menuGrpNm: "정기검사 현황",
        path: "/sfli/FcltInspAplyStat",
        items: [
            {
                menuNm: "정기검사 상세",
                path: "/sfli/fclt-insp-aply/FcltInspAplyDetail",
            }
        ],
        isPublic: true,
    },
    {
        menuGrpNm: "품질검사 신청",
        path: "/sfli/QltyInspAply",
        items: []
    },
    {
        menuGrpNm: "품질검사 현황",
        path: "/sfli/QltyInspStat",
        items: [
            {
                menuNm: "품질검사 상세",
                path: "/sfli/qlty-insp-stat/QltyInspStatDetail",
                hidden: true,
            },
        ]
    },
    {
        menuGrpNm: "수입품질검사 신청",
        path: "/sfli/IncmQltyInspAply",
        items: []
    },
    {
        menuGrpNm: "수입품질검사 현황",
        path: "/sfli/IncmQltyInspStat",
        items: [
            {
                menuNm: "수입품질검사 상세",
                path: "/sfli/incm-qlty-insp-stat/IncmQltyInspStatDetail",
                hidden: true,
            },
        ]
    },
    {
        menuGrpNm: "외부기관 품질표시검사결과 등록",
        path: "/sfli/OtsdQltyIndctRslt",
        items: [
            {
                menuNm: "외부기관 품질표시검사결과 상세",
                path: "/sfli/otsdQltyIndctRslt/OtsdQltyIndctRsltDetail",
            },
            {
                menuNm: "외부기관 품질표시검사결과 등록",
                path: "/sfli/otsdQltyIndctRslt/OtsdQltyIndctRsltCreate",
            }
        ]
    },
];

export default {
    getMenus: function (){
        return {
            groupName: "고형연료검사",
            menuList: MENU_LIST,
        };
    },
};