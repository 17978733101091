<template>
  <div id="allMenu" :class="{ active: allMenuActive }">
    <div class="m_head">
      <ul class="header_util">
        <li><a href="javascript:void(0);" role="link">관리자</a></li>
        <li><a href="javascript:void(0);" role="link" target="_blank">ENGLISH</a></li>
      </ul>
      <div v-if="isLoggedIn" class="m_gnb_login">
        <span class="name">{{ userinfo?.userNm || '' }}님</span>
        <a href="javascript:void(0);" role="link" @click.prevent="logout">로그아웃</a>
      </div>
    </div>
    <ul class="inner">
      <li class="depth1">
        <a href="javascript:void(0);" role="link">마이페이지</a>
        <ul class="depth2">
          <li><a href="javascript:void(0);" role="link">사용자 승인</a></li>
          <li><a href="javascript:void(0);" role="link">개선/금지명령</a></li>
          <li><a href="javascript:void(0);" role="link">회원정보 변경</a></li>
        </ul>
      </li>
      <li v-for="group in groups" :key="group.menuSn" class="depth1" :class="{ active: activeMobileGroup === group.menuSn }">
        <a href="javascript:void(0);" role="link" @click.prevent="handleMobileGroup(group.menuSn)">{{ group.groupName }}</a>
        <ul class="depth2">
          <li v-for="menuGrp in group.menuList" :key="menuGrp.menuSn">
            <a href="javascript:void(0);" role="link" @click.prevent="handleMobileMenuGrp(menuGrp)">{{ menuGrp.menuGrpNm }}</a>
            <ul v-if="menuGrp.items && menuGrp.items.length" class="depth3">
              <template v-for="item in menuGrp.items" :key="item.menuSn">
                <li v-if="!item.hidden"
                    @click.prevent="handleMobileMenuItem(item)"
                >
                  <a href="">{{ item.menuNm }}</a>
                </li>
              </template>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <button type="button" class="menu-close" @click="allMenuClose">전체메뉴 닫기</button>
  </div>
</template>

<script>
import { storeSession } from "@/js/store/store-session";

export default {
  props: ['userinfo', 'groups', 'menu', 'allMenuActive', 'changeBookmark'],
  emits: ['update:allMenuActive', 'logout', 'menu-select'],
  data() {
    return {
      activeMobileGroup: null,
      activeMobileMenuGrp: null,
      activeMobileItem: null,
    };
  },
  computed: {
    isLoggedIn() {
      return storeSession.hasLogin();
    },
  },
  watch: {
    menu() {
      this.setActiveMobileMenu();
    }
  },
  created() {
    this.setActiveMobileMenu();
  },
  mounted() {
  },
  methods: {
    handleMobileGroup(menuSn) {
      this.activeMobileGroup = menuSn;
    },
    handleMobileMenuGrp(menuGrp) {
      this.activeMobileMenuGrp = menuGrp.menuSn;
      if (menuGrp.path) {
        this.$emit("menu-select", menuGrp.path);
        this.allMenuClose();
      }
    },
    handleMobileMenuItem(item) {
      this.activeMobileItem = item.menuSn;
      if (item.path) {
        this.$emit("menu-select", item.path);
        this.allMenuClose();
      }
    },
    allMenuClose() {
      document.documentElement.style.overflow = '';
      this.$emit('update:allMenuActive', false);
    },
    setActiveMobileMenu() {
      const { menuSn, menuLevel } = this.menu || {};

      if (!menuSn) return;

      if (menuLevel === 1) {
        this.activeMobileGroup = menuSn;
        this.activeMobileMenuGrp = null;
        this.activeMobileItem = null;
      } else if (menuLevel === 2) {
        this.groups.forEach(group => {
          group.menuList.forEach(menuGrp => {
            if (menuGrp.menuSn === menuSn) {
              this.activeMobileGroup = group.menuSn;
              this.activeMobileMenuGrp = menuSn;
            }
          });
        });
      } else if (menuLevel === 3) {
        this.groups.forEach(group => {
          group.menuList.forEach(menuGrp => {
            menuGrp.items.forEach(item => {
              if (item.menuSn === menuSn) {
                this.activeMobileGroup = group.menuSn;
                this.activeMobileMenuGrp = menuGrp.menuSn;
                this.activeMobileItem = menuSn;
              }
            });
          });
        });
      }
    },
    logout() {
      this.$emit('logout');
    }
  }
}
</script>

<style scoped>

</style>