<template>
  <div class="chk_area">
    <div v-if="onAllSelect" class="form_check">
      <input type="checkbox" :id="prefix" :name="prefix" :checked="allSelect" @click="allClick" :disabled="onDisabled" />
      <label :for="prefix">전체</label>
    </div>
    <div v-for="option of checkboxOptions" :key="option.value" class="form_check">
      <input type="checkbox" :id="prefix + option.value" :name="prefix" :checked="isChecked(option)" @click="selectOption(option)" :disabled="onDisabled" />
      <label :for="prefix + option.value">{{ option.text }}</label>
    </div>
  </div>
</template>

<script>
import { storeValidator } from "@/js/store/store-validator";
import pp from "@/js/postposition";
import { v4 as uuidv4 } from "uuid";
import { mapGetters } from "vuex";

export default {
  props: {
    label: {type: String, required: true},
    options: {type: Array, default: () => []},
    groupCd: {type: String, default: null},
    values: {type: Array, default: () => []},
    modelValue: {type: Array, default: () => []},
    required: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    readonly: { type: Boolean, default: false },
    panel: {type: String, default: ''},
    allSelectable: { type: Boolean, default: false },
  },
  emits: ["update:modelValue", "update:values", "selected"],
  data() {
    return {
      prefix: "checkbox-" + uuidv4(),
      checkboxOptions: [],
      hasError: false,
      errorMessage: "",
      selectedOptions: new Map(),
      allSelect: false,
    };
  },
  computed: {
    ...mapGetters(['getCodeList']),
    filteredCodeList() {
      return this.getCodeList({groupCd: this.groupCd, useYn: 'Y'});
    },
    onDisabled() {
      return this.disabled || this.readonly;
    },
    onAllSelect() {
      return this.allSelectable;
    },
  },
  watch: {
    values() {
      this.updateSelectedOptions(this.values);
    },
    modelValue() {
      this.updateSelectedOptions(this.values);
    },
    options(newVal) {
      this.checkboxOptions = newVal;
      this.updateSelectedOptions(this.values);
    },
  },
  created() {
    if (this.groupCd) {
      this.setComCd();
    } else {
      this.checkboxOptions = this.options;
    }
    this.updateSelectedOptions(this.values);
    this.updateModelValue();
    if(this.required) {
      storeValidator.add(this.$route.path, this.panel, this);
    }
  },
  beforeUnmount() {
    if (this.required) {
      storeValidator.remove(this.$route.path, this.panel, this.label);
    }
  },
  methods: {
    setComCd() {
      this.checkboxOptions = [];
      const codeList = this.filteredCodeList;
      if (codeList.length > 0) {
        this.checkboxOptions = codeList.map(item => ({
          text: item.dtlCdNm,
          value: item.dtlCd
        }));
      } else {
        this.setError();
      }
    },
    setError() {
      this.checkboxOptions.push({ text: "", value: "" });
    },
    updateSelectedOptions(values) {
      this.selectedOptions.clear();
      values?.forEach(value => {
        const option = this.findOption(value);
        if (option) {
          this.selectedOptions.set(option.value, option);
        }
      });
      this.isAllChecked();
      this.validate();
    },
    allClick() {
      this.allSelect = !this.allSelect;
      if (this.allSelect) {
        this.checkboxOptions.forEach(option => this.selectedOptions.set(option.value, option));
      } else {
        this.selectedOptions.clear();
      }
      this.updateModelValue();
    },
    isAllChecked() {
      if (!this.allSelectable) return;
      this.allSelect = this.checkboxOptions.length === this.selectedOptions.size;
    },
    isChecked(option) {
      return this.selectedOptions.has(option.value);
    },
    selectOption(option) {
      if (this.selectedOptions.has(option.value)) {
        this.selectedOptions.delete(option.value);
      } else {
        this.selectedOptions.set(option.value, option);
      }
      this.updateModelValue();
    },
    updateModelValue() {
      const values = Array.from(this.selectedOptions.values());
      const valueKeys = values.map(option => option.value);
      this.$emit("update:modelValue", values);
      this.$emit("update:values", valueKeys);
      this.$emit("selected", values);
      this.validate();
    },
    validate() {
      if (this.required && this.selectedOptions.size === 0) {
        this.hasError = true;
        this.errorMessage = `${pp.eunNn(this.label)} 필수 선택값 입니다.`;
      } else {
        this.hasError = false;
        this.errorMessage = "";
      }
    },
    findOption(value) {
      return this.checkboxOptions.find(option => option.value === value) || null;
    },
  }
}
</script>

<style scoped>

</style>