<template>
  <div class="inner">
    <h1 class="h1_logo" v-show="userinfo.scrtyPlcyYn !== 'N'">
      <a href="/home"><img src="@/assets/images/common/h1_logo.png" alt="폐자원에너지 종합정보관리시스템"></a>
    </h1>
    <h1 class="h1_logo" v-show="userinfo.scrtyPlcyYn === 'N'">
      <img src="@/assets/images/common/h1_logo.png" alt="폐자원에너지 종합정보관리시스템">
    </h1>
    <ul class="header_util">
<!--      <li class="allmenu"><a href="javascript:void(0);">전체메뉴</a></li>-->
      <li v-if="isAdminUser">
        <a href="javascript:void(0);" @click.prevent="moveAdmin">업무페이지 접속하기</a>
      </li>
      <li><a href="https://hc119.com/srf-info" target='_blank' class="remote">원격지원</a></li>
      <li class="drop_wrap zoom_drop" :class="{ active: isZoomMenuOpen }" ref="zoomMenu">
        <button type="button" class="drop_btn plus" :title="zoomMenuTitle" @click="toggleZoomMenu">화면크기</button>
        <div class="drop_menu">
            <ul class="drop_list">
              <li>
                  <a href="javascript:void(0);" role="link" @click.prevent="setSiteResizing(80, 'xsm')" :class="{ active: activeClass === 'xsm', xsm: true }">작게</a>
              </li>
              <li>
                  <a href="javascript:void(0);" role="link" @click.prevent="setSiteResizing(100, 'sm')" :class="{ active: activeClass === 'sm', sm: true }">보통</a>
              </li>
              <li>
                  <a href="javascript:void(0);" role="link" @click.prevent="setSiteResizing(120, 'md')" :class="{ active: activeClass === 'md', md: true }">조금 크게</a>
              </li>
              <li>
                  <a href="javascript:void(0);" role="link" @click.prevent="setSiteResizing(140, 'lg')" :class="{ active: activeClass === 'lg', lg: true }">크게</a>
              </li>
              <li>
                  <a href="javascript:void(0);" role="link" @click.prevent="setSiteResizing(160, 'xlg')" :class="{ active: activeClass === 'xlg', xlg: true }">가장 크게</a>
              </li>
          </ul>
          <div class="drop_btm_btn">
            <button type="button" class="btn sm ico_reset" @click.prevent="setSiteResizing(100, 'sm')" >초기화</button>
          </div>
        </div>
      </li>
    </ul>
    <div class="btn_navi_wrap">
      <template v-if="!isLoggedIn">
        <button type="button" class="btn_navi login" @click="login">로그인</button>
        <button type="button" class="btn_navi join" @click="join">회원가입</button>
      </template>
      <template v-else>
        <div class="drop_wrap my_drop">
          <button type="button" class="btn_navi drop_btn my plus" @click="toMyOfficeMain">마이오피스</button>
        </div>
        <div class="my_info">
          <p class="my_name">{{ userinfo?.userNm || ''}}님 <button class="my_time" @click.prevent="refreshToken"><span class="time">{{ remainTime }}</span>연장</button>
          </p>
          <div class="info_btm_btn">
            <button type="button" class="btn sm ico_logout" @click="logout">로그아웃</button>
          </div>
          <div v-if="isProxyLogin" >
            <button type="button" class="btn sm" @click="proxyAdmin">관리자 돌아가기</button>
          </div>
        </div>
      </template>
      <button type="button" class="btn_navi all" @click="openAllMenu">전체메뉴</button>
    </div>
  </div>
  <session-timeout-pop
      :remainSeconds="remainSeconds"
      @refresh="refreshToken"
      @change-visible="changeVisible"
      @close-popup="closeTimeoutPopup"
  ></session-timeout-pop>
</template>

<script>
import { storeSession } from "@/js/store/store-session";
import { storeSwitch } from "@/js/store/store-switch";
import SessionTimeoutPop from "@/views/main/components/SessionTimeoutPop.vue";
import refreshToken from "@/js/refresh-token";

const ROUTE = {
  LOGIN: "/user/login",
  JOIN: "/user/MbrJoin",
  TIMEOUT: "/timeout",
  MYOFFICE: "/my-office/MyOfficeMain"
}

const API = {
  AUTH_PROXY_PUBLISH_TOKEN: "/auth/proxy/publish-token",
  LOGOUT: "/auth/remove-token",
}

export default {
  components: {SessionTimeoutPop},
  props: ['userinfo', 'allMenuActive'],
  emits: ['logout', 'update:allMenuActive', 'toMyOffice'],
  data() {
    return {
      isZoomMenuOpen: false,
      isRelatedSystemOpen: false,
      intervalId: null,
      zoomLevel: 100,
      activeClass: '',
      timeoutPopupVisible: false,
      isClosePopup: false, // 세션타임아웃팝업 상태
      remainTime: '',
      remainSeconds: 600,
    };
  },
  computed: {
    proxyId() {
      return storeSession.getProxyId();
    },
    isProxyLogin() {
      return storeSession.hasProxy();
    },
    isLoggedIn() {
      return storeSession.hasLogin();
    },
    isAdminUser() {
      return storeSession.isAdminUser();
    },
    zoomMenuTitle() {
      return this.isZoomMenuOpen ? "접기" : "펼치기";
    },
    relatedSystemTitle() {
      return this.isRelatedSystemOpen ? '접기' : '펼치기';
    },
  },
  watch: {
    userinfo(newVal) {
      if (newVal && this.isLoggedIn) {
        this.startCountdown();
      } else {
        this.stopCountdown();
      }
    },
    remainSeconds(newVal) {
      if (newVal <= 0) {
        this.timeout();
      } else if (newVal <= 120) {
        this.showTimeoutPop();
      }

      this.displayRemainTime();
    },
    '$route'() {
      this.refreshToken();
    }
  },
  created() {
    if (this.isLoggedIn) {
      this.startCountdown();
    }

    this.refreshToken();

    const savedZoomLevel = sessionStorage.getItem('zoomLevel');
    this.zoomLevel = savedZoomLevel ? parseInt(savedZoomLevel) : 100; 
    document.body.style.zoom = this.zoomLevel + "%"; 
  },
  methods: {
    toggleZoomMenu() {
      this.isZoomMenuOpen = !this.isZoomMenuOpen;
    },
    toggleRelatedSystemMenu() {
      this.isRelatedSystemOpen = !this.isRelatedSystemOpen;
    },
    alertReady() {
      alert("준비중입니다.");
    },
    startCountdown() {
      if (this.intervalId) {
        return;
      }

      this.intervalId = setInterval(() => {
        this.setRemainSeconds();
      }, 1000);
    },
    stopCountdown() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    setRemainSeconds() {
      this.remainSeconds = storeSession.getRemainSeconds();
    },
    displayRemainTime() {
      const minutes = Math.floor(this.remainSeconds / 60);
      const seconds = this.remainSeconds % 60;

      this.remainTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
    async refreshToken() {
      try {
        await refreshToken.renew();
      } catch (e) {
        console.error(e);
      } finally {
        this.isClosePopup = false;
      }
    },
    timeout() {
      // this.$apiCall.post(API.LOGOUT, {}, () => {}, () => {});

      document.documentElement.style.overflow = '';
      storeSession.removeSession();
      storeSwitch.off('session-timeout-pop');
      this.$router.push(ROUTE.TIMEOUT).catch(() => {});
    },
    login() {
      this.$router.push(ROUTE.LOGIN).catch(() => {});
    },
    logout() {
      this.$emit('logout');
    },
    join() {
      this.$router.push(ROUTE.JOIN).catch(() => {});
    },
    toMyOfficeMain() {
      this.$emit('toMyOffice', ROUTE.MYOFFICE);
    },
    openAllMenu() {
      document.documentElement.style.overflow = 'hidden';
      this.$emit('update:allMenuActive', true);
    },
    showTimeoutPop() {
      if (this.isClosePopup) {
        return;
      }

      if (refreshToken.isRefreshing()) {
        return;
      }

      storeSwitch.on('session-timeout-pop');
    },
    setSiteResizing(sizeLevel, className) {
      this.activeClass = className;
      this.zoomLevel = sizeLevel;
      document.body.style.zoom = this.zoomLevel + "%";

      sessionStorage.setItem('zoomLevel', this.zoomLevel);
    },
    proxyAdmin() {
      const proxyParam = {
        subject : this.proxyId,
        isProxyLogin : true
      };
      if (confirm("관리자로 돌아가시겠습니까?")) {
        this.$apiCall.post(API.AUTH_PROXY_PUBLISH_TOKEN, proxyParam, this.proxyLoginSuccess, this.proxyLoginFail);
      }
    },
    proxyLoginSuccess(data) {
      if (!data.result) {
        alert("관리자로 돌아가는데에 실패하였습니다.");
        return;
      }

      const result = data.result;

      if (result.isAlreadyAdminToken) {
        if (confirm("이미 관리자 로그인이 되어있습니다.\n계속 하시겠습니까?")) {
          this.proxyLoginOpen(result.code);
        }

        return;
      }

      this.proxyLoginOpen(result.code);
    },
    proxyLoginFail(data) {
      console.error(data);
    },
    proxyLoginOpen(code) {
      const url = `${process.env.VUE_APP_PROXY_LOGIN_URL}?code=${code}`;
      location.replace(url);
    },
    changeVisible(visible) {
      this.timeoutPopupVisible = visible;
    },
    closeTimeoutPopup() { // 세션시간만료팝업에서 닫기 버튼 클릭시
      this.isClosePopup = true;
    },
    moveAdmin() {
      const url = `${process.env.VUE_APP_ADMIN_URL}`;
      location.replace(url);
    }
  },
  unmounted() {
    this.stopCountdown();
  },
}
</script>

<style scoped>

</style>