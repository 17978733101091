<template>
  <!-- 서류보완요청 알림 영역 -->
  <div v-if="qltyInspStatData.inspPrgrsSttsCd === '30'" class="desc_box progress_box">
    <i class="icon"><img src="../../../../assets/images/common/ico_noti.png" alt="이미지"></i>
    <div class="progress">
      <div><strong>서류보완요청</strong>중입니다 <span>{{ this.qltyInspStatData.taskPrcsRsn }}</span></div>
    </div>
  </div>
  <!-- 신청정보 영역 -->
  <h2 class="title1 mt_0">신청정보</h2>
  <div class="board_write">
    <p class="t_caption"><span class="necessary">(<i>※</i>)</span> 필수 입력항목입니다.</p>
    <table>
      <caption>신청정보_시설 명, 위탁업체 명, 처리업구분, 검사진행상태, 검사구분, 건조시설유무, 품질표시검사여부, 품질표시검사항목, 입금(예정)일자,
        입금(예정)자명, 선입금여부, 검사희망일자, 첨부파일로 구성</caption>
      <colgroup>
        <col style="width:18%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:32%">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">사업장번호</th>
        <td>{{ qltyInspStatData.bplcId }}</td>
        <th scope="row">상호</th>
        <td>{{ qltyInspStatData.bzentyNm }}</td>
      </tr>
      <tr>
        <th scope="row">시설종류</th>
        <td colspan="3">{{ qltyInspStatData.bplcKndNm }}</td>
      </tr>
      <tr>
        <th scope="row">제조업체(영문)</th>
        <td colspan="3">
          <div class="form_group_addr" style="align-items: center;">
            {{ qltyInspStatData.mnftrBzentyNm }}
            <br-button label="제조시설 찾기" class="btn sm ico_search" @click="callPopupIncmFclt" v-show="canModify"/>
            <br-anchor-button v-if="canModify" label="제조시설 추가" size="sm" format="primary line" icon="ico_plus" @click="findMnftrFclt" />
           <!-- <br-anchor-button v-if="canModify" label="수입시설 추가" size="sm" format="primary line" icon="ico_plus" @click="addMnftrFclt" /> -->
          </div>
        </td>
      </tr>
      <tr>
        <th scope="row">제조국(수입국)</th>
        <td>
          {{ qltyInspStatData.mnftrNtnNm }}
        </td>
        <th scope="row">도시명</th>
        <td>
          {{ qltyInspStatData.ctyNm }}
        </td>
      </tr>
      <tr>
        <th scope="row">제조시설(주소)</th>
        <td colspan="3">
          {{ qltyInspStatData.fcltAddr }}
        </td>
      </tr>
      <tr>
        <th scope="row">보관시설(주소)</th>
        <td colspan="3">
          {{ qltyInspStatData.prdctCstdyPlcCn }}
        </td>
      </tr>
      <tr>
        <th scope="row">제품종류</th>
        <td colspan="3">
          <div class="chk_area">
            <div class="form_check">
              <input type="checkbox" id="srf_chk1" v-model="qltyInspStatData.srfPtYn" true-value="Y" false-value="N" disabled>
              <label for="srf_chk1">SRF 성형</label>
            </div>
            <div class="form_check">
              <input type="checkbox" id="srf_chk2" v-model="qltyInspStatData.srfNptYn" true-value="Y" false-value="N" disabled>
              <label for="srf_chk2">SRF 비성형</label>
            </div>
            <div class="form_check">
              <input type="checkbox" id="srf_chk3" v-model="qltyInspStatData.bioSrfPtYn" true-value="Y" false-value="N" disabled>
              <label for="srf_chk3">BIO-SRF 성형</label>
            </div>
            <div class="form_check">
              <input type="checkbox" id="srf_chk4" v-model="qltyInspStatData.bioSrfNptYn" true-value="Y" false-value="N" disabled>
              <label for="srf_chk4">BIO-SRF 비성형</label>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>실사여부</th>
        <td colspan="3">
          <br-form-radio label="실사여부" :options="acinspYnOpt" v-model:value="qltyInspStatData.incmInspAcinspYn" :disabled="!canModify"/>
        </td>
      </tr>
      <tr>
        <th scope="row">입금(예정)일자
        </th>
        <td>
          <br-date-picker label="입금(예정)일자" v-model:value="qltyInspStatData.dpstPrnmntYmd" :disabled="!canModify"/>
        </td>
        <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>검사희망일자
        </th>
        <td>
          <br-date-picker label="검사희망일자" v-model:value="qltyInspStatData.inspHopeYmd" futureonly :futureDaysOffset="30" :disabled="!canModify"/>
          <span class="txt_hint">30일 이후로 입력</span>
        </td>
      </tr>
      <tr>
        <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i><label for="hskCd">HSK.NO</label></th>
        <td colspan="3">
          <br-form-select id="hskCd" label="HSK.NO" :customStyle="{'max-width':'none'}" v-model:value="qltyInspStatData.hskCd" :options="hskCdOpt" :disabled="!canModify"/>
        </td>
      </tr>
      <tr>
        <th scope="row">제조가능물량(톤)
        </th>
        <td>
          <br-form-input aria-label="제조가능물량(톤)" label="제조가능물량(톤)" v-model="qltyInspStatData.maxMnfqty" :maxlength="11" :disabled="true" validator="number" align="right"/>
        </td>
        <th scope="row">수입가능물량(톤)
        </th>
        <td>
          <br-form-input aria-label="수입가능물량(톤)" label="수입가능물량(톤)" v-model="qltyInspStatData.incmPmtqty" :maxlength="11" :disabled="true" validator="number" align="right"/>
        </td>
      </tr>
      <tr>
        <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>신청물량(톤)</th>
        <td>
          <br-form-input aria-label="신청물량(톤)" label="신청물량(톤)" v-model="qltyInspStatData.incmAplqty" :maxlength="11" :disabled="!canModify" validator="number" align="right"/>
        </td>
        <th scope="row">검사물량(톤)
        </th>
        <td>
          <br-form-input aria-label="검사물량(톤)" label="검사물량(톤)" v-model="qltyInspStatData.incmInspqty" :maxlength="11" :disabled="true" validator="number" align="right"/>
        </td>
      </tr>
      <tr>
        <th scope="row">검사진행상태</th>
        <td colspan="3">
          <div class="input_group">
            {{ qltyInspStatData.inspPrgrsSttsNm }}
            <br-anchor-button label="이력보기" size="xsm" format="" @click="popInspHist"/>
          </div>
        </td>
      </tr>
      <tr>
        <th scope="row">품질검사항목</th>
        <td colspan="3">
          <br-form-radio label="품질검사항목" :options="qltyInspArtclOpt" v-model:value="qltyInspStatData.qltyInspArtclCd" @selected="onChangedQltyInspArtclCd" :disabled="true"/>
        </td>
      </tr>
      <tr>
        <th scope="row">품질표시검사여부</th>
        <td colspan="3">
          <div class="chk_area">
            <br-form-radio label="품질표시검사여부" :options="qltyIndctInspYnList" v-model:value="qltyInspStatData.qltyIndctInspYn" @click="setInspCpstCd(qltyInspStatData.qltyInspItemCd)" :disabled="isSelectedOnlyMoistureItem || !canModify"/>
          </div>
        </td>
      </tr>
      <tr>
        <th scope="row"><i class="necessary">필수입력</i>검사항목</th>
        <td colspan="3">
          <!-- <div class="chk_area">
            <br-form-checkbox label="품질검사항목" :options="inspCpstCdOpt" v-model:values="qltyInspStatData.inspCpstCdList" allSelectable :disabled="qltyInspStatData.qltyIndctInspYn === 'N' || !canModify"/>
            <label for="etcArtclNm">기타항목 상세</label>
            <input type="text" name="etcArtclNm" id="etcArtclNm" maxlength="50" v-model="qltyInspStatData.etcArtclNm"  :disabled="qltyInspStatData.qltyIndctInspYn === 'N' || !canModify" />
          </div> -->
          <div class="form_check">
              <input type="checkbox" name="inspCpstCdAll" id="inspCpstCdAll" value="chkAll" v-model="allSelected">
              <label for="inspCpstCdAll">전체</label>
          </div>
          <table>
            <caption>검사항목</caption>
            <colgroup>
              <col style="width:9%">
              <col style="width:91%">
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">품질검사</th>
                <td>
                  <div class="chk_area">
                    <div class="form_check">
                        <input type="checkbox" name="inspCpstCd150" id="inspCpstCd150" value="150" v-model="this.qltyInspStatData.inspCpstCdList">
                        <label for="inspCpstCd150">모양 및 크기</label>
                    </div>
                    <div class="form_check">
                        <input type="checkbox" name="inspCpstCd160" id="inspCpstCd160" value="160" v-model="this.qltyInspStatData.inspCpstCdList">
                        <label for="inspCpstCd160">수분(wt.%)</label>
                    </div>
                    <div class="form_check">
                        <input type="checkbox" name="inspCpstCd180" id="inspCpstCd180" value="180" v-model="this.qltyInspStatData.inspCpstCdList">
                        <label for="inspCpstCd180">(저위)발열량(kcal/kg)</label>
                    </div>
                    <div class="form_check">
                        <input type="checkbox" name="inspCpstCd170" id="inspCpstCd170" value="170" v-model="this.qltyInspStatData.inspCpstCdList">
                        <label for="inspCpstCd170">회분(wt.%)</label>
                    </div>
                    <div class="form_check">
                        <input type="checkbox" name="inspCpstCd140" id="inspCpstCd140" value="140" v-model="this.qltyInspStatData.inspCpstCdList">
                        <label for="inspCpstCd140">염소(wt.%)</label>
                    </div>
                    <div class="form_check">
                        <input type="checkbox" name="inspCpstCd130" id="inspCpstCd130" value="130" v-model="this.qltyInspStatData.inspCpstCdList">
                        <label for="inspCpstCd130">항분(wt.%)</label>
                    </div>
                    <div class="form_check">
                        <input type="checkbox" name="inspCpstCd190" id="inspCpstCd190" value="190" v-model="this.qltyInspStatData.inspCpstCdList" :disabled="inspCpstBioMassDisabled">
                        <label for="inspCpstCd190">바이오매스(wt.%)</label>
                    </div>                            
                    <div class="form_check">
                        <input type="checkbox" name="inspCpstCd80" id="inspCpstCd80" value="80" v-model="this.qltyInspStatData.inspCpstCdList">
                        <label for="inspCpstCd80">수은(Hg)</label>
                    </div>
                    <div class="form_check">
                        <input type="checkbox" name="inspCpstCd90" id="inspCpstCd90" value="90" v-model="this.qltyInspStatData.inspCpstCdList">
                        <label for="inspCpstCd90">카드뮴(Cd)</label>
                    </div>
                    <div class="form_check">
                        <input type="checkbox" name="inspCpstCd100" id="inspCpstCd100" value="100" v-model="this.qltyInspStatData.inspCpstCdList">
                        <label for="inspCpstCd100">납(Pb)</label>
                    </div>
                    <div class="form_check">
                        <input type="checkbox" name="inspCpstCd110" id="inspCpstCd110" value="110" v-model="this.qltyInspStatData.inspCpstCdList">
                        <label for="inspCpstCd110">비소(As)</label>
                    </div>
                    <div class="form_check">
                        <input type="checkbox" name="inspCpstCd200" id="inspCpstCd200" value="200" v-model="this.qltyInspStatData.inspCpstCdList">
                        <label for="inspCpstCd200">기타항목</label>
                        <input type="text" name="etcArtclNm" id="etcArtclNm" maxlength="50" v-model="this.qltyInspStatData.etcArtclNm"/>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">품질검사</th>
                <td>
                  ※ 바이오 고형연료제품에 대한 품질검사인 경우 크로뮴(Cr)은 품질검사 항목에 해당될 수 있습니다.
                  <div class="chk_area">
                    <div class="form_check">
                      <input type="checkbox" name="inspCpstCd120" id="inspCpstCd120" value="120" v-model="this.qltyInspStatData.inspCpstCdList">
                      <label for="inspCpstCd120">크로뮴(Cr)</label>
                    </div>
                    <div class="form_check">
                      <input type="checkbox" name="inspCpstCd10" id="inspCpstCd10" value="10" v-model="this.qltyInspStatData.inspCpstCdList">
                      <label for="inspCpstCd10">안티몬(Sb)</label>
                    </div>
                    <div class="form_check">
                      <input type="checkbox" name="inspCpstCd20" id="inspCpstCd20" value="20" v-model="this.qltyInspStatData.inspCpstCdList">
                      <label for="inspCpstCd20">코발트</label>
                    </div>
                    <div class="form_check">
                      <input type="checkbox" name="inspCpstCd40" id="inspCpstCd40" value="40" v-model="this.qltyInspStatData.inspCpstCdList">
                      <label for="inspCpstCd40">망간(Mn)</label>
                    </div>
                    <div class="form_check">
                      <input type="checkbox" name="inspCpstCd50" id="inspCpstCd50" value="50" v-model="this.qltyInspStatData.inspCpstCdList">
                      <label for="inspCpstCd50">니켈(Ni)</label>
                    </div>
                    <div class="form_check">
                      <input type="checkbox" name="inspCpstCd60" id="inspCpstCd60" value="60" v-model="this.qltyInspStatData.inspCpstCdList">
                      <label for="inspCpstCd60">탈륨(Tl)</label>
                    </div>
                    <div class="form_check">
                      <input type="checkbox" name="inspCpstCd70" id="inspCpstCd70" value="70" v-model="this.qltyInspStatData.inspCpstCdList">
                      <label for="inspCpstCd70">바나듐(V)</label>
                    </div>
                    <div class="form_check">
                      <input type="checkbox" name="inspCpstCd30" id="inspCpstCd30" value="30" v-model="this.qltyInspStatData.inspCpstCdList">
                      <label for="inspCpstCd30">구리</label>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <th scope="row">첨부파일</th>
        <td colspan="3">
          <div class="file_upload_list">
            <br-attach-file-list v-if="canModify" 
                  label="사업자등록증"
                  ref="attachFileList"
                  atchFileTaskSeCd="BET"
                  v-model:atchFileGroupId="qltyInspStatData.bzAtchFileGroupId"
                  :useEtcFile="false"
                  :maxFileCount="1"
                  :readonly="true"
                  :unusedAtchFileList= "[]"
            />
            <br-attach-file-list v-if="canModify" 
                  label="통장사본"
                  ref="attachFileList"
                  atchFileTaskSeCd="BPCD"
                  v-model:atchFileGroupId="qltyInspStatData.ofcBpAtchFileGroupId"
                  :useEtcFile="false"
                  :maxFileCount="2"
                  :readonly="true"
                  :unusedAtchFileList= "['001','002', '007', '008', '049', '053', '054']"
            />
            
            <br-attach-file-list v-if="!canModify" 
                                 label="수입품질검사 신청한 사업자등록증과 통장 사본 첨부 파일"
                                 ref="attachFileList"
                                 atchFileTaskSeCd="IQT"
                                 v-model:atchFileGroupId="qltyInspStatData.inspAplyAtchFileGroupId"
                                 :useEtcFile="false"
                                 :maxFileCount="5"
                                 :readonly="true"
                                 :unusedAtchFileList= "['010','011','015','016','017','018','019','020', '033', '036']"
            />

            <br-attach-file-list label="품질검사 신청 첨부파일 목록"
                                 ref="attachFileList"
                                 atchFileTaskSeCd="IQT"
                                 v-model:atchFileGroupId="qltyInspStatData.inspAplyAtchFileGroupId"
                                 :useEtcFile="false"
                                 :maxFileCount="5"
                                 :readonly="!canModify"
                                 :unusedAtchFileList= "['003', '006', '010', '011', '016', '033', '036']"
            />
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <!-- 검사정보 영역 -->
  <!-- <template v-if="isInspOnGoing">
    <div class="board_info mt_40">
      <h2 class="title1">검사 정보</h2>
      <br-anchor-button v-if="!canModify" label="시료체취계획서 출력" size="sm" format="" icon="ico_print" @click="printSpcmPicPlan" />
    </div>
    <div class="board_write">
      <table>
        <caption>검사 정보_검사(예정)일자, 검사원 명으로 구성</caption>
        <colgroup>
          <col style="width:18%">
          <col style="width:32%">
          <col style="width:18%">
          <col style="width:32%">
        </colgroup>
        <tbody>
        <tr>
          <th scope="row">검사(예정)일자</th>
          <td>
            <div class="input_group">
              {{ qltyInspStatData.inspPrnmntYmd }}
            </div>
          </td>
          <th scope="row">검사원 명</th>
          <td>{{ qltyInspStatData.inspectors }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </template> -->
  <!-- 최하단 버튼 영역 -->
  <div class="btn_area">
    <div>
      <br-anchor-button v-if="!canModify" label="검사신청서 출력" size="md" format="" icon="ico_print" @click="printInspApplctnForm"/>
    </div>
    <div style="display: flex;gap: 5px;">
      <br-anchor-button v-if="canModify" label="저장" size="md" format="primary" @click="save" validate/>
      <br-anchor-button v-if="canModify" label="검사신청" size="md" format="primary line" @click="applyInsp"/>
      <br-anchor-button label="목록" size="md" format="tertiary" @click="goList"/>
    </div>
  </div>
  <qlty-insp-apply-hist-popup :inspAplySn="inspAplySn"/>
  <ovrs-incm-cnpt-mng-popup :bplcId="this.bplcId" @select-button-clicked="onSelectedOvrsIncmCnptMng"/>
  <ovrs-incm-mnftr-fclt-popup ref="ovrsIncmMnftrFcltPopup" @select-button-clicked="setOvrsIncmMnftrFcltInfo" :bplcId="this.bplcId"></ovrs-incm-mnftr-fclt-popup>

</template>

<script>
/**
 * 수입품질검사 현황의 상세(신청정보)에 대한 화면
 *
 * 작성자 : jhkim
 * 작성일 : 11/06/2024
 * 버전 : 1.0
 */
import {storeSwitch} from "@/js/store/store-switch";
import QltyInspApplyHistPopup from "@/views/sfli/components/popup/QltyInspApplyHistPopup.vue";
import OvrsIncmCnptMngPopup from "@/views/mbr-mng/components/popup/OvrsIncmCnptMngPopup.vue";
import OvrsIncmMnftrFcltPopup from "@/views/sfli/components/popup/OvrsIncmMnftrFcltPopup.vue";

const API = {
  INCM_QLTY_INSP_STAT : "/incm-qlty-insp-stat",
  FOR_APPLYING : "/incm-qlty-insp-stat/apply",
}
const CODE = {
  ITEM_KND_CD: 'MBR010',
  HSK_CD: 'COM417',
  // INSP_SE_CD: 'COM429',
  // INSP_CPST_CD: 'SIS011',
}

export default {
  components: {QltyInspApplyHistPopup, OvrsIncmCnptMngPopup, OvrsIncmMnftrFcltPopup},
  props: {
    inspAplySn: {type: String, required: true},
    bplcId: {type: String, required: true},
    modelValue: {type: Object, default: Object},
  },
  emits: ['update:modelValue', 'print-incm-qlty-insp-report'],
  computed : {
    canModify() {
      return this.qltyInspStatData.inspPrgrsSttsCd === '00' || this.qltyInspStatData.inspPrgrsSttsCd === '30';
    },
    isInspOnGoing() {
      return this.qltyInspStatData.inspPrgrsSttsCd === '40' || this.qltyInspStatData.inspPrgrsSttsCd === '60' || this.qltyInspStatData.inspPrgrsSttsCd === '70';
    },
    allSelected: {
      //getter
      get: function() {
        //고형연료 품질검사인경우 제외되는 성분
        const excludeForSrf = ["10", "20", "30", "40", "50", "60", "70", "120", "190"];
        //고형연료 품질표시검사
        const excludeForSrfAndQltyIndct = ["190"];
        //바이오고형연료
        const excludeForBioSrf = ["10", "20", "30", "40", "50", "60", "70"];
        //고형연료 코드
        const srfCdList = ["0001", "0002"];
        //바이오고형연료 코드
        const bioSrfCdList = ["0003", "0004"];

        let inspCpstOptionsLength = this.inspCpstOtions.length;
        let inspCpstCdListLength = 0;

        if(this.qltyInspStatData.qltyIndctInspYn == 'Y'){ //품질표시검사 여부
          if(srfCdList.includes(this.qltyInspStatData.qltyInspItemCd)){ //고형연료
            inspCpstOptionsLength = this.inspCpstOtions.filter(item => !excludeForSrfAndQltyIndct.includes(item.value)).length;
          }else if(bioSrfCdList.includes(this.qltyInspStatData.qltyInspItemCd)){  //바이오고형연료
            inspCpstOptionsLength = this.inspCpstOtions.length;
          }
        }else{
          if(srfCdList.includes(this.qltyInspStatData.qltyInspItemCd)){ //고형연료
            inspCpstOptionsLength = this.inspCpstOtions.filter(item => !excludeForSrf.includes(item.value)).length;
          }else if(bioSrfCdList.includes(this.qltyInspStatData.qltyInspItemCd)){  //바이오고형연료
            inspCpstOptionsLength = this.inspCpstOtions.filter(item => !excludeForBioSrf.includes(item.value)).length;
          }
        }

        if(this.qltyInspStatData.inspCpstCdList != null && this.qltyInspStatData.inspCpstCdList != undefined){
          inspCpstCdListLength = this.qltyInspStatData.inspCpstCdList.length;
        }

        return inspCpstOptionsLength === inspCpstCdListLength;
      },
      //setter
      set: function(e) {
        if(e){
          //검사항목 자동 세팅
          this.setInspCpstCd(this.qltyInspStatData.qltyInspItemCd);
          this.qltyInspStatData.inspCpstCdList.push("200");
        }else{
          this.qltyInspStatData.inspCpstCdList = [];
        }

      },
    },
  },
  data() {
    return {
      qltyInspStatDataOriginal: {},
      qltyInspStatData: {},
      inspSeCdList: [],
      prdctKndCdOpt: [],
      hskCdOpt: [],
      acinspYnOpt: [
        {value: "Y", text: "Y"},
        {value: "N", text: "N"},
      ],
      qltyInspArtclOpt: [
        {value: "01-00-00", text: "전체"},
        {value: "02-00-00", text: "수분항목만"},
      ],
      qltyIndctInspYnList: [
        {value: "Y", text: "신청"},
        {value: "N", text: "미신청"},
      ],
      isSelectedOnlyMoistureItem: false,
      // inspCpstCdOpt: [],
      // inspCpstCdList: [],
      // mnftrFcltInfo: {
      //     bplcId: "",
      //     incmFcltSn: "",
      //     bzentyNm: "",
      //     mnftrNtnCd: "",
      //     ovrsBzmnIdntfNo: "",
      //     mnftrNtnNm: "",
      //     ctyNm: "",
      //     fcltAddr: "",
      //     prdctCstdyPlcCn: "",
      //     srfPtYn: "",
      //     srfNptYn: "",
      //     bioSrfPtYn: "",
      //     bioSrfNptYn: "",
      //     mnfqty: "",
      // },
      // srfItemValues: [],
      inspCpstBioMassDisabled: false,
      inspCpstOtions: [
        {value: "10", text: "안티몬(Sb)"},
        {value: "20", text: "코발트(Co)"},
        {value: "30", text: "구리(Cu)"},
        {value: "40", text: "망간(Mn)"},
        {value: "50", text: "니켈(Ni)"},
        {value: "60", text: "탈륨(Tl)"},
        {value: "70", text: "바나듐(V)"},
        {value: "80", text: "수은(Hg)"},
        {value: "90", text: "카드뮴(Cd)"},
        {value: "100", text: "납(Pb)"},
        {value: "110", text: "비소(As)"},
        {value: "120", text: "크로뮴(Cr)"},
        {value: "130", text: "황분 함유량(wt.%)"},
        {value: "140", text: "염소 함유량(wt.%)"},
        {value: "150", text: "모양 및 크기"},
        {value: "160", text: "수분 함유량(wt.%)"},
        {value: "170", text: "회분 함유량(wt.%)"},
        {value: "180", text: "(저위)발열량(kcal/kg)"},
        {value: "190", text: "바이오매스(wt.%)"},
        {value: "200", text: "기타 항목"},
      ],
    };
  },
  watch : {
    'qltyInspStatData.qltyIndctInspYn'(newVal) {
      if( newVal === 'N' ) {
        this.qltyInspStatData.inspCpstCdList = [];
        this.qltyInspStatData.etcArtclNm = "";
      }

      // //검사항목 자동 세팅
      // this.setInspCpstCd(this.qltyInspStatData.qltyInspItemCd);
    },
    "qltyInspStatData.incmInspAcinspYn": function(val) {
      const incmAplqty = this.qltyInspStatData.incmAplqty == null ? 0 : this.qltyInspStatData.incmAplqty;

      //수입가능물량
      this.qltyInspStatData.incmPmtqty = val === "Y" ? incmAplqty * 10 : incmAplqty;
      //검사물량
      this.qltyInspStatData.incmInspqty = incmAplqty;

    },
    "qltyInspStatData.incmAplqty": function(val){
      //수입가능물량
      this.qltyInspStatData.incmPmtqty = this.qltyInspStatData.incmInspAcinspYn === "Y" ? parseFloat(val) * 10 : val;
      //검사물량
      this.qltyInspStatData.incmInspqty = parseFloat(val);
    },
  },
  created() {},
  beforeMount (){},
  mounted() {
    this.initialize();
  },
  methods : {
    initialize() {
      this.prdctKndCdOpt= this.$store.getters.getCodeList({ groupCd: CODE.ITEM_KND_CD, useYn: 'Y', frstRefVl: 'SRF' }).map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      })).filter(item => item.value === '0003' || item.value === '0004');
      this.hskCdOpt= this.$store.getters.getCodeList({ groupCd: CODE.HSK_CD, useYn: 'Y' }).map(item => ({
        text: '('+item.dtlCd+')'+item.dtlCdNm,
        value: item.dtlCd
      }));
      // this.inspSeCdList = this.$store.getters.getCodeList({ groupCd: CODE.INSP_SE_CD, useYn: 'Y', frstRefVl: '20' }).map(item => ({
      //   text: item.dtlCdNm,
      //   value: item.dtlCd
      // }));
      // this.inspCpstCdOpt = this.$store.getters.getCodeList({ groupCd: CODE.INSP_CPST_CD, useYn: 'Y' }).map(item => ({
      //   text: item.dtlCdNm,
      //   value: item.dtlCd
      // }));
      this.search();
    },
    search() {
      this.$apiCall.get(`${API.INCM_QLTY_INSP_STAT}/${this.inspAplySn}`, {}, data => {
        if (data.result) {
          this.qltyInspStatData = data.result;
          this.qltyInspStatData.inspCpstCdList = this.qltyInspStatData.inspCpstCdList.map(item => item.inspCpstCd);
          this.qltyInspStatDataOriginal = {...data.result};
          this.$emit('update:modelValue', this.qltyInspStatData);
        } else {
          this.qltyInspStatData = [];
        }
      }, data => {
        // console.log(data);
        alert(data.message);
        this.goList();
      });
    },
    save() {
        if( this.hasValidRequired() ) {
          if(confirm("신청서를 임시저장하시겠습니까?\n수입품질검사현황에서 품질검사신청을 할 수 있습니다")) {
            this.$refs.attachFileList.save().then(atchFileGroupId => {
              this.qltyInspStatData.atchFileGroupId = atchFileGroupId;
              this.$apiCall.post(
                  API.INCM_QLTY_INSP_STAT,
                  {...this.qltyInspStatData},
                  () => {
                    alert("신청서 정보가 저장되었습니다.");
                    this.search();
                  },
                  (error) => {
                    alert(error.message);
                  }
              );
            });
          }
        }
    },
    applyInsp() {
      if( this.hasValidRequired() ) {
        if(confirm("품질검사신청하시겠습니까?")) {
          this.$apiCall.post(
              API.FOR_APPLYING,
              {inspAplySn: this.qltyInspStatData.inspAplySn},
              () => {
                alert("검사신청이 완료되었습니다.\n검사결과의 진행상태는 알림톡을 통해 안내됩니다.");
                // this.$router.go(-1);
                this.$router.push("/sfli/IncmQltyInspStat");
              },
              (error) => {
                alert(error.message);
              }
          );
        }
      }else{
        alert("필수체크 된 항목을 입력하시고 저장 후 검사신청을 하여 주십시오.");
      }
    },
    goList() {
      //this.$router.go(-1);
      this.$router.push("/sfli/IncmQltyInspStat");
    },
    printInspApplctnForm() {
      this.$emit('print-incm-qlty-insp-report', 'reportIncmQltyInspAply');
    },
    popInspHist() {
      storeSwitch.on('QltyInspApplyHistPopup');
    },
    // printSpcmPicPlan() {
    //   alert('시료채취계획서 출력')
    // },
    onChangedQltyInspArtclCd(param) {
      if(param.value && param.value === '02-00-00') {
        this.qltyInspStatData.qltyIndctInspYn = 'N';
        this.isSelectedOnlyMoistureItem = true;
        this.qltyInspStatData.inspCpstCdList = [];
      } else {
        this.isSelectedOnlyMoistureItem = false;
      }
    },
    hasValidRequired() {
      if( !this.qltyInspStatData.inspHopeYmd ) {
        alert('검사희망일자는 필수입력입니다.')
        return false;
      }

      if( !this.qltyInspStatData.hskCd ) {
        alert('HSK.NO는 필수입력입니다.')
        return false;
      }

      if( !this.qltyInspStatData.incmAplqty || this.qltyInspStatData.incmAplqty === "0" ) {
        alert('신청물량는 필수입력입니다.\n 0이상의 값을 입력하십시오.')
        return false;
      }

      if(this.qltyInspStatData.inspCpstCdList == null || this.qltyInspStatData.inspCpstCdList.length == 0){
        alert("검사항목을 선택하여 주십시오.");
        return;
      }

      if(this.qltyInspStatData.inspCpstCdList.includes("200") && !this.qltyInspStatData.etcArtclNm){
        alert('품질표시검사항목 중 기타 항목이 선택되었습니다.\n기타항목 상세 내용을 입력하십시오.')
        return false;
      }

      return true;
    },
    findMnftrFclt() {
      storeSwitch.on('ovrsIncmCnptMngPopup');
    },
    onSelectedOvrsIncmCnptMng(data) {
      this.qltyInspStatData.incmFcltSn = data.incmFcltSn;
      this.qltyInspStatData.mnftrBzentyNm = data.bzentyNm;
      this.qltyInspStatData.mnftrNtnCd = data.mnftrNtnCd;
      this.qltyInspStatData.mnftrNtnNm = data.mnftrNtnNm;
      this.qltyInspStatData.fcltAddr = data.fcltAddr;
      this.qltyInspStatData.srfPtYn = data.srfPtYn;
      this.qltyInspStatData.srfNptYn = data.srfNptYn;
      this.qltyInspStatData.bioSrfPtYn = data.bioSrfPtYn;
      this.qltyInspStatData.bioSrfNptYn = data.bioSrfNptYn;
      this.qltyInspStatData.prdctCstdyPlcCn = data.prdctCstdyPlcCn;

      //검사신청정보
      this.qltyInspStatData.fcltSqno = data.incmFcltSn;    //NotNull 때문에 어쩔수 없이 넣음
      if(data.srfPtYn == "Y"){
        this.qltyInspStatData.qltyInspItemCd = "0001";
      }else if(data.srfNptYn == "Y"){
        this.qltyInspStatData.qltyInspItemCd = "0002"
      }else if(data.bioSrfPtYn == "Y"){
        this.qltyInspStatData.qltyInspItemCd = "0003"
      }else if(data.bioSrfNptYn == "Y"){
        this.qltyInspStatData.qltyInspItemCd = "0004"
      }

      //검사항목 자동 세팅
      this.setInspCpstCd();
    },
    /** 해외수입시설 팝업 호출 */
    callPopupIncmFclt(){
      storeSwitch.on('ovrsIncmMnftrFcltPopup');

      this.$refs.ovrsIncmMnftrFcltPopup.reset();
    },
    /* 해외제조시설 정보 세팅 */
    setOvrsIncmMnftrFcltInfo(mnftrFcltInfo){

      this.qltyInspStatData.mnftrBzentyNm = mnftrFcltInfo.bzentyNm;  //제조업체(영문)
      this.qltyInspStatData.mnftrNtnNm = mnftrFcltInfo.mnftrNtnNm;    //제조국(수입국)
      this.qltyInspStatData.ctyNm = mnftrFcltInfo.ctyNm;         //도시명
      this.qltyInspStatData.fcltAddr = mnftrFcltInfo.fcltAddr;      //제조시설(주소)
      this.qltyInspStatData.prdctCstdyPlcCn = mnftrFcltInfo.prdctCstdyPlcCn; //보관시설(주소)
      //제품종류
      this.qltyInspStatData.srfPtYn = mnftrFcltInfo.srfPtYn;     //SRF 성형
      this.qltyInspStatData.srfNptYn = mnftrFcltInfo.srfNptYn;    //SRF 비성형
      this.qltyInspStatData.bioSrfPtYn = mnftrFcltInfo.bioSrfPtYn;  //BIO-SRF 성형
      this.qltyInspStatData.bioSrfNptYn = mnftrFcltInfo.bioSrfNptYn; //BIO-SRF 비성형

      //제조가능물량
      this.qltyInspStatData.maxMnfqty = mnftrFcltInfo.mnfqty;

      //검사신청정보
      this.qltyInspStatData.incmFcltSn = mnftrFcltInfo.incmFcltSn;
      this.qltyInspStatData.fcltSqno = mnftrFcltInfo.incmFcltSn;    //NotNull 때문에 어쩔수 없이 넣음
      if(mnftrFcltInfo.srfPtYn == "Y"){
        this.qltyInspStatData.qltyInspItemCd = "0001";
      }else if(mnftrFcltInfo.srfNptYn == "Y"){
        this.qltyInspStatData.qltyInspItemCd = "0002"
      }else if(mnftrFcltInfo.bioSrfPtYn == "Y"){
        this.qltyInspStatData.qltyInspItemCd = "0003"
      }else if(mnftrFcltInfo.bioSrfNptYn == "Y"){
        this.qltyInspStatData.qltyInspItemCd = "0004"
      }

      //검사항목 자동 세팅
      this.setInspCpstCd();
    },
    setInspCpstCd(){
      const prdctnPrdctKndCd = this.qltyInspStatData.qltyInspItemCd;
      this.qltyInspStatData.inspCpstCdList = [];

      if(this.qltyInspStatData.qltyIndctInspYn == 'Y'){
        if(prdctnPrdctKndCd == '0001' || prdctnPrdctKndCd == '0002'){ //고형연료
          this.qltyInspStatData.inspCpstCdList = this.inspCpstOtions.filter(item => item.value !== "190" && item.value !== "200")
                                                 .map(item => item.value);
        }else if(prdctnPrdctKndCd == '0003' || prdctnPrdctKndCd == '0004'){ //바이오 고형연료
          this.qltyInspStatData.inspCpstCdList = this.inspCpstOtions.filter(item => item.value !== "200").map(item => item.value);
        }else{
          this.qltyInspStatData.inspCpstCdList = this.inspCpstOtions.map(item => item.value);
        }
      }else{
        if(prdctnPrdctKndCd == '0001' || prdctnPrdctKndCd == '0002'){ //고형연료
          this.qltyInspStatData.inspCpstCdList = this.inspCpstOtions
                                                 .filter(item => item.value !== "200"
                                                                 && item.value !== "190"
                                                                 && item.value !== "120"
                                                                 && item.value !== "10"
                                                                 && item.value !== "20"
                                                                 && item.value !== "40"
                                                                 && item.value !== "50"
                                                                 && item.value !== "60"
                                                                 && item.value !== "70"
                                                                 && item.value !== "30")
                                                 .map(item => item.value);
        }else if(prdctnPrdctKndCd == '0003' || prdctnPrdctKndCd == '0004'){ //바이오 고형연료
          this.qltyInspStatData.inspCpstCdList = this.inspCpstOtions
                                                 .filter(item => item.value !== "10"
                                                                 && item.value !== "20"
                                                                 && item.value !== "40"
                                                                 && item.value !== "50"
                                                                 && item.value !== "60"
                                                                 && item.value !== "70"
                                                                 && item.value !== "30"
                                                                 && item.value !== "200"
                                                                 )
                                                 .map(item => item.value);
        }else{
          this.qltyInspStatData.inspCpstCdList = this.inspCpstOtions.map(item => item.value);
        }
      }

      ///바이오매스 검사항목 활성화여부
      this.inspCpstBioMassDisabled = (prdctnPrdctKndCd == '0003' || prdctnPrdctKndCd == '0004') ? false : true;
    },
  }
}
</script>

<style scoped>

</style>