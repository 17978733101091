<template>
  <template v-if="prfmncBasicInfo.prfmncSttsCd === 'REJ'">
    <h3 class="title1">반려정보</h3>
    <div class="board_write">
      <table>
        <caption>반려정보</caption>
        <colgroup>
          <col style="width:20%">
          <col style="width:80%">
        </colgroup>
        <tbody>
        <tr>
          <th scope="row">반려일자</th>
          <td>{{ formattedRejectDate }}</td>
        </tr>
        <tr>
          <th scope="row">반려사유</th>
          <td v-html="prfmncBasicInfo.rjctRsn ? prfmncBasicInfo.rjctRsn.replace(/\n/g, '<br>') : ''"></td>
        </tr>
        </tbody>
      </table>
    </div>
  </template>
</template>

<script>
export default {
  props: ['prfmncBasicInfo'],
  computed: {
    formattedRejectDate() {
      const rawDate = this.prfmncBasicInfo.rjctYmd;
      if (!rawDate) return '';
      if (rawDate.length !== 8) return rawDate;
      const year = rawDate.substring(0, 4);
      const month = rawDate.substring(4, 6);
      const day = rawDate.substring(6, 8);
      return `${year}-${month}-${day}`;
    }
  }
}
</script>

<style scoped>

</style>