<template>
  <div v-if="isVisible" class="modal visible">
    <div class="popup_box" tabindex="0">
      <h1 class="popup_title">실적 수정 허용 요청</h1>
      <div class="popup_cont">
        <h3 class="title2">업체 정보</h3>
        <div class="board_write">
          <table>
            <colgroup>
              <col style="width:18%">
              <col style="width:32%">
              <col style="width:18%">
              <col style="width:32%">
            </colgroup>
            <tbody>
            <tr>
              <th scope="row">업체명</th>
              <td>{{ bplcInfo?.bzentyNm }}</td>
              <th scope="row">대표자명</th>
              <td>{{ bplcInfo?.rprsvNm }}</td>
            </tr>
            <tr>
              <th scope="row">사업자등록번호</th>
              <td colspan="3">{{ formatBusinessNumber(bplcInfo?.brno) }}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <h3 class="title2">사업장 정보</h3>
        <div class="board_write">
          <table>
            <colgroup>
              <col style="width:18%">
              <col style="width:32%">
              <col style="width:18%">
              <col style="width:32%">
            </colgroup>
            <tbody>
            <tr>
              <th scope="row">사업장명</th>
              <td>{{ bplcInfo?.bplcNm }}</td>
              <th scope="row">전화번호</th>
              <td>{{ formatPhoneNumber(bplcInfo?.telno) }}</td>
            </tr>
            <tr>
              <th scope="row">담당자명</th>
              <td colspan="3">{{ bplcInfo?.picNm }}</td>
            </tr>
            <tr>
              <th scope="row">시·도</th>
              <td>{{ bplcInfo?.ctpvStdgNm }}</td>
              <th scope="row">시·군·구</th>
              <td>{{ bplcInfo?.sggStdgNm }}</td>
            </tr>
            <tr>
              <th scope="row">주소</th>
              <td colspan="3">{{ bplcInfo?.bplcAddr }} {{ bplcInfo?.bplcDaddr }}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <h3 class="title2">요청 정보</h3>
        <div class="board_write">
          <table>
            <colgroup>
              <col style="width:18%">
              <col >
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">작성자명</th>
                <td>{{ userinfo?.userNm || '정보 없음' }}</td>
              </tr>
              <tr>
                <th scope="row">휴대전화번호</th>
                <td>{{ formatPhoneNumber(userinfo?.mblTelno) }}</td>
              </tr>
              <tr>
                <th scope="row">요청 실적년월</th>
                <td>
                  <div v-for="(months, year) in this.prfmncYmArr" :key="year">
                    <strong>{{ year }}년</strong>
                    <span v-for="(month, index) in months" :key="index" style="margin-left: 10px">
                      {{ month }}월<span v-if="index !== months.length - 1">, </span>
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">요청사유</th>
                <td>
                  <br-form-textarea label="요청사유" v-model="prfmncMdfcnInfo.mdfcnPrmDmndRsn" panel="prfmncMdfPrm" required />
                </td>
              </tr>
              <tr>
                <th scope="row">증빙자료</th>
                <td>
                  <br-form-checkbox label="단순 오입력 수정" v-model:values="fileRequiredChk" :options="fileRequiredChkList" @change="chgFileRequired" />
                  <br-attach-file label="증빙자료"
                                  atchFileTaskSeCd="PMP"
                                  v-model:atchFileGroupId="prfmncMdfcnInfo.atchFileGroupId"
                                  v-model:fileProcessingData="prfmncMdfcnInfo.fileProcessingData"
                                  :maxFileCount="1"
                                  template="01"
                                  panel="prfmncMdfPrm"
                                  :required="isFileRequired"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="grid_box pt_15 pb_20 txt_center">
          <p>본인은 위의 사유로 인해 실적을 수정하고자 허용 요청을 신청하는 바입니다.</p>
          <p class="mt_5">{{ currentDate }}</p>
          <p class="mt_15">신청자 : <strong>{{ userinfo?.userNm || '정보 없음' }}</strong></p>
        </div>
        <div class="btn_area center">
          <br-button label="간편인증" size="md" format="secondary" @click="onslfCert" panel="prfmncMdfPrm" validate />
          <br-button label="닫기" size="md" @click="hide" />
        </div>
        <button type="button" class="modal_close" @click="hide">팝업 닫기</button>
      </div>
    </div>
  </div>

  <simple-auth-modal :hasLogin="true" @callback="simpleAuthCallback" @fail="simpleAuthFail" />
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";
import { storeSession } from "@/js/store/store-session";
import utils from "@/js/utils/utils";
import SimpleAuthModal from "@/components/SimpleAuthModal.vue";

const API = {
  BUSINESS_PLACE_INFO: "/bplc",
  PRFMNC_CRTR_INFO: "/prfmnc/crtr-info",
  PERFORMANCE_MODIFIER_PERMIT: "/prfmnc/prfmnc-mdfcn-prm"
}

export default {
  components: {SimpleAuthModal},
  emits: ['completed'],
  data() {
    return {
      isVisible: false,
      isFileRequired:true,
      fileRequiredChk: [],
      fileRequiredChkList: [{
        text: '단순 오입력 수정 (증빙자료 첨부 예외 시 체크)',
        value: 'Y'
      }],
      userinfo: storeSession.getMemberInfo(),
      bplcInfo: null,
      prfmncYmArr: null,
      prfmncMdfcnInfo: {
        bplcId: "",
        items:[],
        prfmncIds: [],
        /*prfmncYr: "",
        prfmncMm: "",
        prfmncId: "",*/
        mdfcnPrmDmndRsn: "",
        atchFileGroupId: "",
        fileProcessingData: {},
      },
      currentDate: utils.getCurrentDateFormatted(),
      simpleAuthAutoYn : 'Y',
    };
  },
  computed: {},
  watch: {
  },
  created() {
    storeSwitch.setBulb('PrfmncMdfcnPrmPopup', this);
  },
  mounted() {
  },
  methods: {
    loadBplcInfo(param) {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(
            API.BUSINESS_PLACE_INFO,
            param,
            data => {
              if (data.result) {
                this.bplcInfo = data.result;
              } else {
                this.bplcInfo = null;
              }
              resolve();
            },
            () => {
              this.bplcInfo = null;
              reject(new Error("Failed to load bplc info."));
            }
        );
      });
    },
    loadPrfmncCrtrInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(
            API.PRFMNC_CRTR_INFO,
            {"crtrInfoCd":"004013"},
            data => {
              if (data.result) {
                this.simpleAuthAutoYn = data.result.mainRfrncVl;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load prfmnc crtr info."));
            }
        );
      });
    },
    async show(param) {
      this.reset(param);
      await this.loadBplcInfo({bplcId:param.bplcId});
      await this.loadPrfmncCrtrInfo();

      document.documentElement.style.overflow = 'hidden';
      this.isVisible = true;
    },
    groupedCheckedItems() {
      // 연도별로 그룹화
      return this.prfmncMdfcnInfo.items.reduce((acc, item) => {
        if (!acc[item.prfmncYr]) {
          acc[item.prfmncYr] = [];
        }
        acc[item.prfmncYr].push(item.prfmncMm);
        return acc;
      }, {});
    },
    hide() {
      this.isVisible = false;
      document.documentElement.style.overflow = '';
    },
    formatPhoneNumber(number) {
      return utils.formatPhoneNumber(number);
    },
    formatBusinessNumber(businessNumber) {
      return utils.formatBusinessNumber(businessNumber);
    },
    reset(param) {
      this.bplcInfo = null;
      this.prfmncMdfcnInfo.bplcId = param.bplcId;
      this.prfmncMdfcnInfo.items = param.items;
      this.prfmncMdfcnInfo.prfmncIds = param.prfmncIds;
      this.prfmncMdfcnInfo.mdfcnPrmDmndRsn = "";
      this.prfmncMdfcnInfo.atchFileGroupId = "";
      this.prfmncMdfcnInfo.fileProcessingData = {};
      this.prfmncYmArr = this.groupedCheckedItems();
    },
    chgFileRequired(){
      if(this.fileRequiredChk.length > 0){
        this.isFileRequired = false;
      }else{
        this.isFileRequired = true;
      }
    },
    simpleAuthCallback(result) {
      if (!result.isLoginUser) {
        alert("간편인증 정보와 로그인 계정이 일치하지 않습니다.\r\n보안을 위해 동일한 계정으로 인증해주세요.");
        storeSwitch.off('SimpleAuthModal');
        return;
      }

      this.$apiCall.post(
          API.PERFORMANCE_MODIFIER_PERMIT,
          this.prfmncMdfcnInfo,
          () => {
            this.$emit('completed');
            alert("실적수정허용 요청이 완료되었습니다.");
            storeSwitch.off('SimpleAuthModal');
            this.hide();
          },
          () => {
            alert("실적수정허용 요청에 실패하였습니다.");
          }
      );
    },
    simpleAuthFail(error) {
      alert("간편인증 중 에러가 발생하였습니다.");
      storeSwitch.off('SimpleAuthModal');
      console.error("simpleAuthFail", error);
    },
    onslfCert() {

      if(this.simpleAuthAutoYn === 'Y') {
        storeSwitch.on('SimpleAuthModal');
      }else{
        //기준정보 값 체크 후
        this.$apiCall.post(
          API.PERFORMANCE_MODIFIER_PERMIT,
          this.prfmncMdfcnInfo,
          () => {
            this.$emit('completed');
            alert("실적수정허용 요청이 완료되었습니다.");
            this.hide();
          },
          () => {
            alert("실적수정허용 요청에 실패하였습니다.");
          }
      );
      }
    }
  }
}
</script>

<style scoped>

</style>