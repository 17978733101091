<template>
    <div v-show="isList">
      <qlty-insp-aply-list ref="QltyInspAplyList" @qlty-insp-aply-form="goToDetail"></qlty-insp-aply-list>
    </div>
    <div v-show="isDetail">
      <qlty-insp-aply-detail  ref="QltyInspAplyDetail" @qlty-insp-aply-list="goToList" :parentData="this.parentData"></qlty-insp-aply-detail>
    </div>
  </template>

<script>

// import { storeSnackbar } from "@/js/store/store-snackbar";
// import { storeSwitch } from "@/js/store/store-switch";
// import { storeComponent } from "@/js/store/store-component";
import QltyInspAplyList from "@/views/sfli/QltyInspAplyList.vue";
import QltyInspAplyDetail from "@/views/sfli/QltyInspAplyDetail.vue";

export default {
  components: {QltyInspAplyList, QltyInspAplyDetail},
  data() {
    return {
      menu: this.$menusTotal.getRouteMenu(this.$route.path),
      isList: true,
      isDetail: false,
      parentData: {
        bzentyId: "",
        bplcId: "",
        fcltSqno: "",
        fcltInspAplySn: "",
        inspSeCd: "",
      },
    };
  },
  computed: {
    qltyInspAplyObj() {
        // Local Storage에서 데이터 불러오기
        const qltyInspAplyObj = localStorage.getItem('qltyInspAplyObj');
        return qltyInspAplyObj ? JSON.parse(qltyInspAplyObj) : {};
    },
  },
  watch: {
  },
  created() {
  },
  beforeMount() {
  },
  mounted() {
    this.initialize();
  },
  beforeUnmount() {
    // 컴포넌트 전체 데이터 저장하기
    // storeComponent.store(this.menu, this);
  },
  methods: {
    async initialize() {
        
        //병행검사일 경우 상세화면으로 이동
        if(this.qltyInspAplyObj.fcltInspAplySn){
          // console.log("this.qltyInspAplyObj:>>>> " + this.qltyInspAplyObj);
          this.goToDetail(this.qltyInspAplyObj);
        }
    },
    goToList(){
        this.isList = true;
        this.isDetail = false;

        this.$refs.QltyInspAplyList.reset();
    },
    goToDetail(param){
        //
        this.parentData.bzentyId = param.bzentyId;
        this.parentData.bplcId = param.bplcId;
        this.parentData.fcltSqno = param.fcltSqno;
        this.parentData.fcltInspAplySn = param.fcltInspAplySn;
        this.parentData.inspSeCd = param.inspSeCd == null ? "20-10" : param.inspSeCd;

        this.isList = false;
        this.isDetail = true;

        //병행검사 관련 데이터 삭제
        localStorage.removeItem('qltyInspAplyObj');

        this.$refs.QltyInspAplyDetail.reset();
        
    },
  }
}
</script>