<template>
    <div v-if="isVisible" class="popup-overlay" @click.self="closePopup">
      <div class="popup_box popup-content">
            <h3>품질검사결과</h3>
            <div class="popup_cont">
                <div class="board_list">
                    <table>
                        <caption>품질(확인)검사 결과-시험항목, 품질기준, 시험결과</caption>
                        <colgroup>
                            <col style="width:20%">
                            <col>
                            <col style="width:32%">
                            <col style="width:25%">
                        </colgroup>
                        <thead>
                            <tr>
                                <th scope="col" colspan="2">시험항목</th>
                                <th scope="col">품질기준</th>
                                <th scope="col">시험결과</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td scope="col" colspan="2">검사기관</td>
                                <td scope="col" colspan="2">{{qltyInspRsl.inspInstNm}}</td>
                            </tr>
                            <tr>
                                <td scope="col" colspan="2">검사일자</td>
                                <td scope="col" colspan="2">{{ formatDate(qltyInspRsl.inspBgngYmd) }}</td>
                            </tr>
                            <tr>
                                <td rowspan="3" aria-label="시험항목">모양 및 크기</td>
                                <td aria-label="시험항목">
                                    <span v-if="prdctKndGb === 1 || prdctKndGb === 3">직경(mm)</span>
                                    <span v-else-if="prdctKndGb === 2 || prdctKndGb === 4">가로(mm)</span>
                                    <span v-else>-</span>
                                </td>
                                <td aria-label="품질기준">
                                    <span v-if="prdctKndGb === 1">50 이하</span>
                                    <span v-else-if="prdctKndGb === 2">50 이하</span>
                                    <span v-else-if="prdctKndGb === 3">50 이하</span>
                                    <span v-else-if="prdctKndGb === 4">120 이하</span>
                                    <span v-else>-</span>
                                </td>
                                <td aria-label="시험결과">{{ qltyInspRsl.dmtrMsrmtVl }}</td>
                            </tr>
                            <tr>
                                <td aria-label="시험항목">
                                    <span v-if="prdctKndGb === 1 || prdctKndGb === 3">길이(mm)</span>
                                    <span v-else-if="prdctKndGb === 2 || prdctKndGb === 4">세로(mm)</span>
                                    <span v-else>-</span>
                                </td>
                                <td aria-label="품질기준">
                                    <span v-if="prdctKndGb === 1">100 이하</span>
                                    <span v-else-if="prdctKndGb === 2">50 이하</span>
                                    <span v-else-if="prdctKndGb === 3">100 이하</span>
                                    <span v-else-if="prdctKndGb === 4">120 이하</span>
                                    <span v-else>-</span>
                                </td>
                                <td aria-label="시험결과">{{ qltyInspRsl.lenMsrmtVl }}</td>
                            </tr>
                            <tr>
                                <td aria-label="시험항목">투과율(%)</td>
                                <td aria-label="품질기준">
                                    <span v-if="prdctKndGb === 1">95% 이상</span>
                                    <span v-else>비대상</span>
                                </td>
                                <td aria-label="시험결과">{{ qltyInspRsl.svpsrt }}</td>
                            </tr>
                            <tr>
                                <td colspan="2" aria-label="시험항목">(저위)발열량(kcal/kg)</td>
                                <td aria-label="품질기준">
                                    <span v-if="prdctKndGb === 1 || prdctKndGb === 2">
                                        {{ qltyInspRsl.bplcKndCd === '01-01' ? '3,500 이상' : '3,650 이상' }}
                                    </span>
                                    <span v-else-if="prdctKndGb === 3 || prdctKndGb === 4">
                                        {{ qltyInspRsl.bplcKndCd === '01-01' ? '3,000 이상' : '3,150 이상' }}
                                    </span>
                                    <span v-else>-</span>
                                </td>
                                <td aria-label="시험결과">{{ formatNumberWithComma(qltyInspRsl.rsltVl180) }}</td>
                            </tr>
                            <tr>
                                <td colspan="2" aria-label="시험항목">수분 함유량(wt. %)</td>
                                <td aria-label="품질기준">
                                    <span v-if="prdctKndGb === 1 || prdctKndGb === 3">
                                        10 이하
                                    </span>
                                    <span v-else-if="prdctKndGb === 2 || prdctKndGb === 4">
                                        25 이하
                                    </span>
                                    <span v-else>-</span>
                                </td>
                                <td aria-label="시험결과">{{ qltyInspRsl.rsltVl160 }}</td>
                            </tr>
                            <tr>
                                <td rowspan="5" aria-label="시험항목">금속성분<br>(mg/kg,건기준)</td>
                                <td aria-label="시험항목">수은(Hg)</td>
                                <td aria-label="품질기준">
                                    <span v-if="prdctKndGb === 1 || prdctKndGb === 2">
                                        1.0 이하
                                    </span>
                                    <span v-else-if="prdctKndGb === 3 || prdctKndGb === 4">
                                        0.6 이하
                                    </span>
                                    <span v-else>-</span>
                                </td>
                                <td aria-label="시험결과">{{ qltyInspRsl.rsltVl80 === undefined ? 0.0 : qltyInspRsl.rsltVl80 }}</td>
                            </tr>
                            <tr>
                                <td aria-label="시험항목">카드뮴(Cd)</td>
                                <td aria-label="품질기준">5.0이하</td>
                                <td aria-label="시험결과">{{ qltyInspRsl.rsltVl90 === undefined ? 0.0 : qltyInspRsl.rsltVl90 }}</td>
                            </tr>
                            <tr>
                                <td aria-label="시험항목">납(Pb)</td>
                                <td aria-label="품질기준">
                                    <span v-if="prdctKndGb === 1 || prdctKndGb === 2">
                                        150 이하
                                    </span>
                                    <span v-else-if="prdctKndGb === 3 || prdctKndGb === 4">
                                        100 이하
                                    </span>
                                    <span v-else>-</span>
                                </td>
                                <td aria-label="시험결과">{{ qltyInspRsl.rsltVl100 === undefined ? 0 : qltyInspRsl.rsltVl100 }}</td>
                            </tr>
                            <tr>
                                <td aria-label="시험항목">비소(As)</td>
                                <td aria-label="품질기준">
                                    <span v-if="prdctKndGb === 1 || prdctKndGb === 2">
                                        13.0 이하
                                    </span>
                                    <span v-else-if="prdctKndGb === 3 || prdctKndGb === 4">
                                        5.0 이하
                                    </span>
                                    <span v-else>-</span>
                                </td>
                                <td aria-label="시험결과">{{ qltyInspRsl.rsltVl110 === undefined ? 0.0 : qltyInspRsl.rsltVl110 }}</td>
                            </tr>
                            <tr>
                                <td aria-label="시험항목">크로뮴(Cr)</td>
                                <td aria-label="품질기준">
                                    <span v-if="prdctKndGb === 1 || prdctKndGb === 2">
                                        비대상
                                    </span>
                                    <span v-else>70.0 이하</span>
                                </td>
                                <td aria-label="시험결과">{{ qltyInspRsl.rsltVl120 === undefined ? 0.0 : qltyInspRsl.rsltVl120 }}</td>
                            </tr>
                            <tr>
                                <td colspan="2" aria-label="시험항목">회분 함유량(wt. %)</td>
                                <td aria-label="품질기준">
                                    <span v-if="prdctKndGb === 1 || prdctKndGb === 2">
                                        20 이하
                                    </span>
                                    <span v-else-if="prdctKndGb === 3 || prdctKndGb === 4">
                                        15 이하
                                    </span>
                                    <span v-else>-</span>
                                </td>
                                <td aria-label="시험결과">{{ qltyInspRsl.rsltVl170 === undefined ? 0 : qltyInspRsl.rsltVl170 }}</td>
                            </tr>
                            <tr>
                                <td colspan="2" aria-label="시험항목">염소 함유량(wt. %)</td>
                                <td aria-label="품질기준">
                                    <span v-if="prdctKndGb === 1 || prdctKndGb === 2">
                                        2.0 이하
                                    </span>
                                    <span v-else-if="prdctKndGb === 3 || prdctKndGb === 4">
                                        0.5 이하
                                    </span>
                                    <span v-else>-</span>
                                </td>
                                <td aria-label="시험결과">{{ qltyInspRsl.rsltVl140 === undefined ? 0.0 : qltyInspRsl.rsltVl140 }}</td>
                            </tr>
                            <tr>
                                <td colspan="2" aria-label="시험항목">황분 함유량(wt. %){{ qltyInspRsl.wsttrUseYn === 'Y' ? '(폐타이어만으로 제조)' : '' }}</td>
                                <td aria-label="품질기준">
                                    {{ qltyInspRsl.wsttrUseYn === 'Y' ? '2.0 이하' : '0.6 이하' }}
                                </td>
                                <td aria-label="시험결과">
                                    <span v-if="qltyInspRsl.rsltVl130 === undefined || qltyInspRsl.rsltVl130 === '0'">
                                        <span v-if="prdctKndGb === 1 || prdctKndGb === 2">0.0</span>
                                        <span v-else>0.00</span>
                                    </span>
                                    <span v-else>{{ qltyInspRsl.rsltVl130 }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" aria-label="시험항목">바이오매스</td>
                                <td aria-label="품질기준">
                                    <span v-if="prdctKndGb === 3 || prdctKndGb === 4">
                                        95 이상
                                    </span>
                                    <span v-else>비대상</span>
                                </td>
                                <td aria-label="시험결과">
                                    <span v-if="prdctKndGb === 3 || prdctKndGb === 4">
                                        {{ qltyInspRsl.rsltVl190 === undefined ? 0 : qltyInspRsl.rsltVl190 }}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <button @click="closePopup">닫기</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isVisible: {
        type: Boolean,
        required: true
      },
      searchParam: {
        type: Object,
        required: true
      },
    },
    data() {
      return {
        qltyInspRsl: {},
        prdctKndGb: 0,
      };
    },
    mounted() {
    },
    methods: {
      async initialize() {
      },
      search() {
        this.qltyInspRsl = {};
        this.$apiCall.get('/qltyGrd/qltyInspRsl', { ...this.searchParam }, data => {
          if(data.result.qltyInspRsl) {
            this.qltyInspRsl = data.result.qltyInspRsl;
            if(this.qltyInspRsl.prdctKndCd === '0001') {
              //SRF 성형
              this.prdctKndGb = 1;
            }
            if(this.qltyInspRsl.prdctKndCd === '0002') {
              //SRF 비성형
              this.prdctKndGb = 2;
            }
            if(this.qltyInspRsl.prdctKndCd === '0003') {
              //BIO-SRF 성형
              this.prdctKndGb = 3;
            }
            if(this.qltyInspRsl.prdctKndCd === '0004') {
              //BIO-SRF 비성형
              this.prdctKndGb = 4;
            }
            console.log(data.result.qltyInspRsl);
          }else {
            console.log("Error qltyInspRsl Call");
          }
        });
      },
      closePopup() {
        this.$emit('close');
      },
      formatNumberWithComma(value) {
        if (value == null || value === '') {
        return '0';
        }

        // 정수부와 소수부 분리
        const [integer, decimal] = value.toString().split('.');
        const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        return decimal ? `${formattedInteger}.${decimal}` : formattedInteger;
      },
      formatDate(value) {
        if (!value || value.length !== 8) {
        return value;
        }

        const year = value.substring(0, 4);
        const month = value.substring(4, 6);
        const day = value.substring(6, 8);

        return `${year}-${month}-${day}`; // YYYY-MM-DD 형식으로 반환
      },
    }
  };
  </script>
  
  <style scoped>
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 600px;
    max-width: 90%;
    text-align: center;
  }

  .board_list {
    font-size: 14px; /* 원하는 크기로 조정 */
  }

  .board_list table tr th,
  .board_list table tr td {
    line-height: 1.8; /* 행의 높이 줄이기 */
    padding: 4px 8px; /* 상하 좌우 여백 줄이기 */
  }
  </style>
  