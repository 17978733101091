<template>
  <div v-show="isVisible" class="modal visible">
    <div class="popup_box" tabindex="0">
      <h1 class="popup_title">
        사업장 선택 
      </h1>
      <div class="popup_cont">
        <div class="sch_form_wrap">
          <div class="sch_form row2">
            <span>
              <label style="width: 12rem;">사업장명</label>
              <br-form-input label="사업장명" v-model="rghtScsnDclrBplcSearchParam.bplcNm" @enter-keyup="search" />
            </span>
            <span>
              <label style="width: 12rem;">시·도/시·군·구</label>
              <br-form-select label="시·도" v-model:value="rghtScsnDclrBplcSearchParam.ctpvStdgCd" :options="ctpvCdList" allSelectable @change="search" />
              <br-form-select label="시·군·구" v-model:value="rghtScsnDclrBplcSearchParam.sggStdgCd" :options="filteredSggCdList" allSelectable @change="search" />
            </span>
            <span>
              <label style="width: 12rem;">업체명</label>
              <br-form-input label="업체명" v-model="rghtScsnDclrBplcSearchParam.bzentyNm" @enter-keyup="search" />
            </span>
            <span style="padding-right: 2.5rem">
              <label style="width: 12rem;">사업자번호</label>
              <br-form-input label="사업자등록번호" v-model="rghtScsnDclrBplcSearchParam.brno" @enter-keyup="search" />
            </span>
          </div> 
        </div>
        <div class="grid_area">
          <div class="board_info">
            <h3 class="title2">사업장 현황</h3>
            <div class="btn_area right" style="margin-top: 0px; margin-bottom: 0px;"> 
              <br-anchor-button label="검색" size="sm" format="sch" @click="search" />
              <br-anchor-button label="새로고침" size="sm" format="txtN" icon="ico_refresh" @click="reset" />
            </div>
          </div>
          <sb-grid
              id="bplcGrid"
              :dataSource="bplcDataSource"
              :columns="bplcGridColumns"
              :gridattr="bplcGridAttr"
              :param="rghtScsnDclrBplcSearchParam"
              v-model:grid="bplcGridObject"
              @loaded="bplcGridLoaded"
              :refreshGrid="bplcGridReload"
          />
        </div>
        <div class="btn_area right" style="margin-top: 0px; margin-bottom: 0px;">
          <br-button label="선택" size="md" format="primary" @click="selectBplc" />
          <br-button label="닫기" size="md" @click="hide" />
        </div>
      </div>
      <button type="button" class="modal_close" @click="hide">팝업 닫기</button>
    </div>
  </div>
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";
import SbGrid from "@/components/SbGrid.vue";

const API = {
  RGHT_SCSN_DCLR_BPLC : "/rghtScsnDclr/bplc",
};

export default {
  components: {SbGrid},
  props: ['bplcKndCd'],
  emits: ['select-bplc'],
  data() {
    return {
      isVisible: false,
      rghtScsnDclrBplcSearchParam: {
        ctpvStdgCd : "",
        sggStdgCd: "",
        bplcSttsCd: "",
        bplcNm: "",
        bplcKndCd: this.bplcKndCd,
      },
      ctpvCdList: [],
      sggCdList: [],
      bplcSttsList: [],
      bplcGridReload: false,
      bplcGridObject: null,
      bplcDataSource: {
        ajaxConfig: {
          select: { url: API.RGHT_SCSN_DCLR_BPLC },
          paging: true,
        },
        schema: {
          id: 'bplcId'
        }
      },
      bplcGridColumns: [],
      bplcGridAttr: {
        checkable: true,
        pageable: true,
      },
    };
  },
  computed: {
    filteredSggCdList() {
      return this.sggCdList.filter(item => item.upSggStdgCd === this.rghtScsnDclrBplcSearchParam.ctpvStdgCd);
    },
  },
  watch: {},
  created() {
    storeSwitch.setBulb('RghtScsnDclrBplcPopup', this);
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loadCtpvCd();
      this.loadSggCd();
      this.initializeBplcGridColumns();
      this.initializeBplcGridAttr();
    },
    loadCtpvCd() {
      const ctpvList = this.$store.getters.getSggList({ type: 'ctpv' });
      this.ctpvCdList = ctpvList.map(item => ({
        text: item.sggNm,
        value: item.sggStdgCd
      }));
    },
    loadSggCd() {
      const sggList = this.$store.getters.getSggList({ type: 'sgg' });
      this.sggCdList = sggList.map(item => ({
        text: item.sggNm,
        value: item.sggStdgCd,
        upSggStdgCd: item.upSggStdgCd
      }));
    },
    initializeBplcGridColumns() {
      this.bplcGridColumns = [
        { field: 'bplcId', caption: '사업장아이디', visible: false },
        { field: 'bzentyId', caption: '업체아이디', visible: false  }, 
        { field: 'bzentyNm', caption: '업체명', width: 150 }, 
        { field: 'bplcKndNm', caption: '사업장종류', width: 150 }, 
        { field: 'bplcNm', caption: '사업장명', width: 150 }, 
        { field: 'bplcAddr', caption: '사업장주소', width: 250, align: 'left'  }, 
        { field: 'bplcDaddr', caption: '사업장주소', width: 250, align: 'left'  }, 
        { field: 'ctpvStdgCd',
          caption: '시·도',
          type: 'combo',
          items: this.ctpvCdList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 150,
          exportLabel : true 
        },
        { field: 'sggStdgCd',
          caption: '시·군·구',
          type: 'combo',
          items: this.sggCdList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 150,
          exportLabel : true
        },
        { field: 'brno', caption: '사업자등록번호', width: 120, 
          format:(value) => {
              if(value && typeof value === 'string') {
                let cleanValue = value.replace(/\D/g, '');

                if (cleanValue.length > 10) {
                  cleanValue = cleanValue.substring(0, 10);
                }

                return cleanValue.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
              }
              return value;
            },
         }, 
         { field: 'rprsvNm', caption: '대표자명', width: 100 }, 
         { field: 'bzentyAddr', caption: '업체주소', width: 100, visible: false }, 
         { field: 'bzentyDaddr', caption: '업체상세주소', width: 100, visible: false }, 
      ]
    },
    initializeBplcGridAttr() {
      this.bplcGridAttr.rowCss = (data) => {
        const keys = window.SBGrid3.getCheckedKeys(this.bplcGridObject);

        if (keys.includes(data.bplcId)) {
          return 'grid-good';
        }

        return '';
      }
    },
    show() {
      this.isVisible = true;
      document.documentElement.style.overflow = 'hidden';
      this.bplcGridReload = true;
    },
    hide() {
      this.isVisible = false;
      document.documentElement.style.overflow = '';
    },
    search() {
      window.SBGrid3.clearSaveData(this.bplcGridObject);
      window.SBGrid3.reload(this.bplcGridObject);
    },
    reset() {
      this.rghtScsnDclrBplcSearchParam.ctpvStdgCd = "";
      this.rghtScsnDclrBplcSearchParam.sggStdgCd = "";
      this.rghtScsnDclrBplcSearchParam.bplcNm = "";
      this.rghtScsnDclrBplcSearchParam.bzentyNm = ""; 
      this.rghtScsnDclrBplcSearchParam.brno = ""; 
      this.search();
    },
    bplcGridLoaded() {
      this.bplcGridReload = false;
    },
    selectBplc() {
      const checkedBplcItem = window.SBGrid3.getCheckedRows(this.bplcGridObject);

      if (checkedBplcItem.length === 0) {
        alert("선택된 사업장이 없습니다.");
        return;
      }

      // 모든 bzentyId 값을 추출
      const bzentyIds = checkedBplcItem.map(item => item.data.bzentyId);

      // bzentyId가 모두 동일한지 확인
      const isSameBzentyId = bzentyIds.every(id => id === bzentyIds[0]);

      if (!isSameBzentyId) {
        alert("선택한 사업장의 업체가 다릅니다.\r동일한 업체의 사업장을 선택해주세요.");
        return;
      }

      const confirmMessage = `선택된 ${checkedBplcItem.length}개의 사업장을 등록하시겠습니까?`;

      if (confirm(confirmMessage)) {
        // 첫 번째 선택된 행 데이터
        const selectedRow = checkedBplcItem[0].data;

        // bplcId 배열 생성
        const bplcIds = checkedBplcItem.map(item => item.data.bplcId);

        // 결과 데이터 객체
        const selected = {
          bzentyId: selectedRow.bzentyId,
          bzentyNm: selectedRow.bzentyNm,
          brno: selectedRow.brno,
          rprsvNm: selectedRow.rprsvNm,
          bzentyAddr: selectedRow.bzentyAddr,
          bzentyDaddr: selectedRow.bzentyDaddr,
          bplcIds: bplcIds, // 선택된 bplcId 배열
        };

        // 부모로 데이터 전달
        this.$emit("select-bplc", selected);
        this.hide();
      }
    }

  }
}
</script>

<style scoped>
.sch_form_wrap {
  margin-bottom: 2rem;
}
</style>