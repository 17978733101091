<template>
  <footer id="footer">
    <div class="footer_quick">
      <ul class="inner">
        <li class="group" :class="{ active: isQuickDetailOpen }">
          <button type="button" @click="toggleQuickDetail" :title="isQuickDetailOpen ? '상세 링크 닫기' : '상세 링크 펼치기'">관련사이트 바로가기</button>
          <div class="quick_detail" v-if="isQuickDetailOpen">
            <div class="inner">
              <div class="list_box">
                <strong class="title">관련사이트 바로가기</strong>
                <ul class="list">
                  <li><a href="https://www.me.go.kr" target="_blank">환경부</a></li>
                  <li><a href="https://www.nier.go.kr" target="_blank">국립환경과학원</a></li>
                  <li><a href="https://stat.me.go.kr" target="_blank">환경통계포털</a></li>
                  <li><a href="https://www.me.go.kr/hg" target="_blank">한강유역환경청</a></li>
                  <li><a href="https://www.me.go.kr/ndg" target="_blank">낙동강유역환경청</a></li>
                  <li><a href="https://www.me.go.kr/gg" target="_blank">금강유역환경청</a></li>
                  <li><a href="https://www.me.go.kr/ysg" target="_blank">영산강유역환경청</a></li>
                  <li><a href="https://www.me.go.kr/wonju" target="_blank">원주지방환경청</a></li>
                  <li><a href="https://www.me.go.kr/daegu" target="_blank">대구지방환경청</a></li>
                  <li><a href="https://www.me.go.kr/smg" target="_blank">새만금지방환경청</a></li>
                  <li><a href="https://www.forest.go.kr/" target="_blank">산림청</a></li>
                  <li><a href="https://portal.budamgum.or.kr/cmb/" target="_blank">EPR민원처리시스템</a></li>
                  <li><a href="https://www.re.or.kr/" target="_blank">순환자원정보센터</a></li>
                  <li><a href="https://www.recycling-info.or.kr/" target="_blank">자원순환정보시스템</a></li>
                  <li><a href="http://www.kora.or.kr/" target="_blank">한국순환자원유통지원센터</a></li>
                </ul>
                <button type="button" class="popup_close" @click="closeQuickDetail">레이어 닫기</button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="inner">
      <div class="footer_logo"><img src="@/assets/images/common/footer_logo.png" alt="한국환경공단 로고"></div>
      <div class="footer_info">
        <div class="addr">
          <address>(22689) 인천광역시 서구 환경로 42 (오류동, 종합환경연구단지)</address>
          <p><strong>고객지원 및 홈페이지 오류신고 : <a href="tel:1588-6446">1588-6446</a></strong> (평일 09:00~18:00)</p>
          <p><strong>시설검사문의 : <a href="tel:032-590-5427">032-590-5427</a></strong></p>
          <p><strong>품질검사문의 : <a href="tel:032-590-5411">032-590-5411/5416</a></strong></p>
          <p><strong>실적보고문의 : <a href="tel:032-590-5404">032-590-5404/5406/5411/5416</a></strong></p>
        </div>
        <div class="link_area">
          <div v-if="isLoggedIn" class="log">
            <p>사용자명 : {{ userinfo?.userNm || '정보 없음' }}</p>
            <p>접속IP : {{ userinfo?.currentIp || '정보 없음' }}</p>
            <p>이전로그인일시 : {{ formatDate(userinfo?.lastCntnDt) || '정보 없음' }}</p>
          </div>
          <div class="link_sns">
            <a href="https://blog.naver.com/NBlogTop.naver?isHttpsRedirect=true&blogId=kecoprumy" target="_blank" class="blog" aria-label="네이버 블로그 바로가기">
              <span class="hidden">블로그</span>
            </a>
            <a href="https://www.youtube.com/user/Kecopr" target="_blank" class="youtube" aria-label="유튜브 바로가기">
              <span class="hidden">유튜브</span>
            </a>
            <a href="https://x.com/kecotwt" target="_blank" class="twitter" aria-label="트위터 바로가기">
              <span class="hidden">트위터</span>
            </a>
            <a href="https://www.instagram.com/keco_puru/#" target="_blank" class="instagram" aria-label="인사트그램 바로가기">
              <span class="hidden">인스타그램</span>
            </a>
            <a href="https://www.facebook.com/kecopr" target="_blank" class="facebook" aria-label="페이스북 바로가기">
              <span class="hidden">페이스북</span>
            </a>
          </div>
        </div>
      </div>
      <div class="footer_bottom">
        <ul>
          <li class="point"><a href="javascript:void(0);" role="link" style="color: #b30000;" @click="openPopup('INFO')">개인정보처리방침</a></li>
          <li><a href="javascript:void(0);" role="link" @click="openPopup('COPYRIGHT')">홈페이지저작권정책</a></li>
          <li><a href="javascript:void(0);" role="link" @click="openPopup('EMAIL')">이메일무단수집거부</a></li>
        </ul>
        <p class="copyright">Copyright 2025. K-eco. All Rights Reserved.</p>
      </div>
    </div>
  </footer>
</template>

<script>
import { storeSession } from "@/js/store/store-session";

export default {
  components: {},
  props: {
    userinfo: {
      type: Object,
      default: () => ({
        userNm: '정보 없음',
        currentIp: '정보 없음',
        lastCntnIpAddr: '정보 없음',
        lastCntnDt: '정보 없음'
      })
    }
  },
  data() {
    return {
      isQuickDetailOpen: false,
    };
  },
  computed: {
    isLoggedIn() {
      return storeSession.hasLogin();
    },
  },
  watch: {},
  created() {
  },
  mounted() {

    window.onpopstate = () => {
      if (this.isQuickDetailOpen) {
        this.isQuickDetailOpen = false;
        document.documentElement.style.overflow = '';
        
        // 페이지를 강제로 /home으로 이동
        window.location.href = '/home';  // 여기서 페이지 강제 리디렉션
      } else {
        // 팝업이 열려 있지 않으면 상태를 계속 유지
        history.pushState(null, null, location.href);
      }
    };

    // 최초 페이지 방문 시에도 상태를 기록
    history.pushState(null, null, location.href);
  },
  methods: {
    toggleQuickDetail() {
      this.isQuickDetailOpen = !this.isQuickDetailOpen;
      if (this.isQuickDetailOpen) {
        document.documentElement.style.overflow = 'hidden';
      } else {
        document.documentElement.style.overflow = '';
      }
    },
    closeQuickDetail() {
      this.isQuickDetailOpen = false;
      document.documentElement.style.overflow = '';
      history.pushState(null, null, location.href);
    },
    openPopup(type) {
      if(confirm('새 창을 열어야합니다.\r\n계속 진행하시겠습니까?')) {
        switch (type) {
          case 'INFO':
            window.open('https://www.keco.or.kr/web/lay1/S1T6C1348/contents.do', 'privacy', 'width=600, height=800');
            break;
          case 'COPYRIGHT':
            window.open('https://www.keco.or.kr/web/lay1/S1T6C1349/contents.do', 'privacy', 'width=600, height=800');
            break;
          case 'EMAIL':
            window.open('https://www.keco.or.kr/web/lay1/S1T6C1350/contents.do', 'privacy', 'width=600, height=800');
            break;
        }
      }
    },
    formatDate(dateString) {
      if (!dateString) return null;

      const date = new Date(dateString); 

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

      return formattedDate;
    },
  }
}
</script>

<style scoped>
.contact-item {
  text-indent: 7em; /* 왼쪽 들여쓰기 */
}
</style>