<template>
  <div id="wrap" :class="{ scrUp: isScrolledUp }" ref="wrap">
    <header id="header" ref="header" :class="{ menu_on: mobileMenuActive }">
      <header-top :userinfo="userinfo" v-model:allMenuActive="mobileMenuActive" @logout="logout"></header-top>
      <top-menu v-if="userinfo.scrtyPlcyYn != 'N'" :groups="groups" @menu-select="navigateTo"></top-menu>
      <mobile-menu
          :userinfo="userinfo"
          :groups="groups"
          :menu="selectedMenu"
          v-model:allMenuActive="mobileMenuActive"
          @menu-select="navigateTo"
          @logout="logout"
      ></mobile-menu>
    </header>
    <div id="container" ref="container">
      <div class="inner login_area">
        <main id="contents">
          <router-view v-slot="{ Component }">
            <component :is="Component" :key="$route.path" />
          </router-view>
        </main>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<script>
import HeaderTop from "@/views/main/components/HeaderTop.vue";
import TopMenu from "@/views/main/components/TopMenu.vue";
import MobileMenu from "@/views/main/components/MobileMenu.vue";
import { storeSession } from "@/js/store/store-session";
import { storeProhibit } from "@/js/store/store-prohibit";
import MainFooter from "@/views/main/components/MainFooter.vue";

const ROUTE = {
  HOME: "/",
  LOGIN: "/user/login",
}

export default {
  components: {MainFooter, TopMenu, MobileMenu, HeaderTop},
  data() {
    return {
      system: process.env.VUE_APP_SYS_CLS_NM,
      groups: [],
      userinfo: storeSession.getMemberInfo(),
      selectedMenu: null,
      mobileMenuActive: false,
      lastScroll: 0,
      isScrolledUp: false,
    };
  },
  watch: {
    '$route'() {
      this.userinfo = storeSession.getMemberInfo();
    }
  },
  created() {
    document.title = this.system ? this.system : "폐자원에너지 종합정보관리시스템";
    this.groups = this.$menusTotal.getAuthMenus();
    this.selectedMenu = this.$menusTotal.getRouteMenu(this.$route.path);
    storeProhibit.setProhibit();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    navigateTo(path) {
      if (path) {
        this.$router.push(path)
            .catch(() => {
              console.error("Navigation Error");
            });
      }
    },
    home() {
      this.navigateTo(ROUTE.HOME);
    },
    logout() {
      if(confirm("로그아웃 하시겠습니까?")) {
        this.$router.push(ROUTE.HOME).catch(() => {});
        storeSession.removeSession();
      }
    },
    handleScroll() {
      const st = window.scrollY || document.documentElement.scrollTop;
      const stopPoint = this.$refs.container.offsetTop - this.$refs.header.offsetHeight;

      if (st > this.lastScroll) {
        // 스크롤 내릴 때
        this.isScrolledUp = false;
      } else {
        // 스크롤 올릴 때
        this.isScrolledUp = st >= stopPoint;
      }
      this.lastScroll = st;
    },
  }
}
</script>

<style scoped>

</style>