<template>
  <div class="board_write">
    <table>
      <caption>실적년도, 사업장명, 사업장종류, 실적상태로 구성</caption>
      <colgroup>
        <col style="width:18%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:32%">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">실적년도</th>
        <td>{{ prfmncBasicInfo.prfmncYr }}-{{ prfmncBasicInfo.prfmncMm }}</td>
        <th scope="row">사업장명</th>
        <td>{{ prfmncBasicInfo.bplcNm }}</td>
      </tr>
      <tr>
        <th scope="row">사업장종류</th>
        <td>{{ prfmncBasicInfo.bplcKndNm }}</td>
        <th scope="row">실적상태</th>
        <td>{{ prfmncBasicInfo.prfmncSttsNm }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <rjct-rsn-area :prfmncBasicInfo="prfmncBasicInfo" />
  <prfmnc-mdfcn-prm-area :prfmncBasicInfo="prfmncBasicInfo" @dmnd-rtrcn="dmndRtrcnCmptn" />

  <div class="board_info mt_40">
    <h3 class="title1" tabindex="-1" ref="rawmtrlCrynTitle">원재료 반입 현황(톤/월)</h3>
    <br-button label="엑셀업로드" size="xsm" format="secondary" @click="openExcelUploadPopup" />
  </div>

  <div class="grid_search">
    <h4>반입 원재료 종류는 마이오피스 > 사업장정보에서 관리할 수 있습니다.</h4>
  </div>
  <sb-grid
      id="rawmtrlCrynGrid"
      :dataSource="rawmtrlCrynDataSource"
      :columns="rawmtrlCrynGridColumns"
      :gridattr="rawmtrlCrynGridAttr"
      v-model:grid="rawmtrlCrynGridObject"
      @loaded="rawmtrlCrynGridLoaded"
      :refreshGrid="rawmtrlCrynGridReload"
      @row-clicked="rawmtrlCrynClick"
  />

  <div class="grid_area_wrap">
    <div class="grid_area">
      <div class="board_info">
        <h3 class="title1">폐기물 항목 정보</h3>
        <br-button label="복사" size="sm" @click="copyWtArtcl" />
      </div>
      <sb-grid
          id="wtArtclGrid"
          :dataSource="wtArtclDataSource"
          :columns="wtArtclGridColumns"
          :gridattr="wtArtclGridAttr"
          v-model:grid="wtArtclGridObject"
          @loaded="wtArtclGridLoaded"
          :refreshGrid="wtArtclGridReload"
      />
    </div>

    <div class="grid_area">
      <div class="board_info">
        <h3 class="title1">폐기물 반입 정보</h3>
        <br-button label="행삭제" size="sm" @click="deleteWtCryn" />
      </div>
      <sb-grid
          id="wtCrynGrid"
          :dataSource="wtCrynDataSource"
          :columns="wtCrynGridColumns"
          :gridattr="wtCrynGridAttr"
          v-model:grid="wtCrynGridObject"
          @loaded="wtCrynGridLoaded"
          :refreshGrid="wtCrynGridReload"
          @change="changeWtCryn"
      />
    </div>
  </div>

  <h2 class="title1">원재료 현황(톤/월)</h2>
  <div class="board_write thead">
    <table>
      <caption>폐기물 반입/투입량 - 이월량, 반입량, 투입량, 폐기반출량, 잔량으로 구성</caption>
      <colgroup>
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col>
      </colgroup>
      <thead>
      <tr>
        <th scope="col">이월량(톤)</th>
        <th scope="col">*반입량(톤)</th>
        <th scope="col">*투입량(톤)</th>
        <th scope="col">폐기반출량(톤)</th>
        <th scope="col">잔량(톤)</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td aria-label="이월량" class="text-end">
          <br-form-input label="이월량" v-model="mnftrPrfmncInfo.wtCrfqty" unit="톤" validator="number" align="right" placeholder="0" disabled />
        </td>
        <td aria-label="반입량" class="text-end">
          <br-form-input label="반입량" v-model="mnftrPrfmncInfo.wtSumCryqty" unit="톤" validator="number" align="right" placeholder="0" disabled />
        </td>
        <td aria-label="투입량" class="text-end">
          <br-form-input label="투입량" v-model="mnftrPrfmncInfo.wtSumInpqty" unit="톤" validator="number" align="right" placeholder="0" disabled />
        </td>
        <td aria-label="폐기반출량" class="text-end">
          <br-form-input label="폐기반출량" v-model="mnftrPrfmncInfo.wtDscdCrtqty" unit="톤" validator="number" align="right" placeholder="0" />
        </td>
        <td aria-label="잔량" class="text-end">
          <br-form-input label="잔량" v-model="mnftrPrfmncInfo.wtRmnqty" unit="톤" validator="number" align="right" placeholder="0" disabled />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info mt_40">
    <h2 class="title1" tabindex="-1" ref="wtSumInpqtyInput">원재료 투입 현황(톤/월) <span>* 투입량 작성이 불가능한 경우, 폐기물 반입량으로 작성</span></h2>
    <div>
      <br-button label="반입량을 투입량으로 적용" size="xsm" format="tertiary" @click="cryQtyAplcn" />
    </div>
  </div>
  <sb-grid
      id="rawmtrlInputGrid"
      :dataSource="rawmtrlInputDataSource"
      :columns="rawmtrlInputGridColumns"
      :gridattr="rawmtrlInputGridAttr"
      v-model:grid="rawmtrlInputGridObject"
      @loaded="rawmtrlInputGridLoaded"
      :refreshGrid="rawmtrlInputGridReload"
      @change="changeRawmtrlInput"
  />

  <h2 class="title1" tabindex="-1" ref="prdcntTitle">시설별 생산 현황(톤/월)</h2>
  <div class="grid_search">
    <h4>
      {{ prfmncBasicInfo.prfmncYr }}년 생산량 합계: {{ formatNumber(tprdqty) }}톤
      <span>※ 연간시설용량 합계 : {{ formatNumber(prdctnInfo.tAnlFcltCap) }}(톤/년)</span>
    </h4>
  </div>
  <sb-grid
      id="prdctnGrid"
      :dataSource="prdctnDataSource"
      :columns="prdctnGridColumns"
      :gridattr="prdctnGridAttr"
      v-model:grid="prdctnGridObject"
      @loaded="prdctnGridLoaded"
      :refreshGrid="prdctnGridReload"
      @change="changePrdcntn"
  />
  <h5>※ 생산수율(%) : (생산량 / 투입량) * 100</h5>

  <h2 class="title1">고형연료제품 판매 현황</h2>
  <h2 class="title2" tabindex="-1" ref="ntslDtlTitle">판매 사업장별 판매 현황 (톤/월)</h2>
  <sb-grid
      id="ntslDtlGrid"
      :dataSource="ntslDtlDataSource"
      :columns="ntslDtlGridColumns"
      :gridattr="ntslDtlGridAttr"
      v-model:grid="ntslDtlGridObject"
      @loaded="ntslDtlGridLoaded"
      :refreshGrid="ntslDtlGridReload"
      @change="changeNtslDtl"
  />

  <h2 class="title2" tabindex="-1" ref="ntslTitle">고형연료제품별 판매 현황 (톤/월)</h2>
  <sb-grid
      id="ntslGrid"
      :dataSource="ntslDataSource"
      :columns="ntslGridColumns"
      :gridattr="ntslGridAttr"
      v-model:grid="ntslGridObject"
      @loaded="ntslGridLoaded"
      :refreshGrid="ntslGridReload"
      @change="changeNtsl"
  />
  <div>
    <h5>※ 판매가격은 고형연료제품 정책을 위한 정부 참고자료로만 활용되며, 외부로 공개되지 않습니다.</h5>
    <h5>※ 저위발열량은 최근 합격된 품질검사 정보를 자동으로 입력하고 있습니다. 반드시 검토하시고 잘못된 정보인 경우 수정하여 제출 바랍니다.</h5>
  </div>

  <template v-if="prfmncBasicInfo.prfmncId">
    <prfmnc-stts-hstry :prfmncId="prfmncBasicInfo.prfmncId" />
    <prfmnc-mdfcn-prm-hstry :prfmncId="prfmncBasicInfo.prfmncId" />
  </template>

  <common-button-area
      :prfmncBasicInfo="prfmncBasicInfo"
      @save="save"
      @submission="submission"
  />

  <mnfrt-cryn-prcs-popup @cryn-prcs-aplcn="crynPrcsAplcn" />
  <sdfl-mnfrt-prfmnc-chg-dscnt-popup @eblc-aplcn="saveConfirmed" />
</template>
<script>
import { storeSwitch } from "@/js/store/store-switch";
import utils from "@/js/utils/utils";
import SbGrid from "@/components/SbGrid.vue";
import RjctRsnArea from "@/views/prfmnc-rpt/dtl/components/RjctRsnArea.vue";
import PrfmncMdfcnPrmArea from "@/views/prfmnc-rpt/dtl/components/PrfmncMdfcnPrmArea.vue";
import prfmncSttsHstry from "@/views/prfmnc-rpt/components/PrfmncSttsHstry.vue";
import PrfmncMdfcnPrmHstry from "@/views/prfmnc-rpt/components/PrfmncMdfcnPrmHstry.vue";
import commonButtonArea from "@/views/prfmnc-rpt/dtl/components/CommonButtonArea.vue";
import MnfrtCrynPrcsPopup from "@/views/prfmnc-rpt/components/popup/MnfrtCrynPrcsPopup.vue";
import SdflMnfrtPrfmncChgDscntPopup from "@/views/prfmnc-rpt/dtl/components/popup/SdflMnfrtPrfmncChgDscntPopup.vue";

const ROUTE = {
  PERFORMANCE_LIST: "/prfmnc-rpt/PrfmncRpt"
};
const API = {
  MANUFACTURE_PERFORMANCE: "/prfmnc/sdflMnfrt",
  MANUFACTURE_PERFORMANCE_CHANGE_CHECK: "/prfmnc/sdflMnfrt/chg-chck",
};
const CODE = {
  SRF_MANUFACTURE_MATERIAL: "SIS054",
  SALES_SECTION: "SIS070",
};

export default {
  props: ['prfmncBasicInfo', 'rawmtrlCrynInfo', 'rawmtrlPrcsInfo', 'rawmtrlInpuInfo', 'prdctnInfo', 'ntslDtlInfo', 'ntslInfo'],
  components: {
    MnfrtCrynPrcsPopup,
    SdflMnfrtPrfmncChgDscntPopup,
    SbGrid,
    RjctRsnArea,
    PrfmncMdfcnPrmArea,
    prfmncSttsHstry,
    PrfmncMdfcnPrmHstry,
    commonButtonArea
  },
  data() {
    return {
      srfMnftrMtralCdList: [],
      rawmtrlCrynGridReload: false,
      rawmtrlCrynGridObject: null,
      rawmtrlCrynDataSource: [],
      rawmtrlCrynGridColumns: [],
      rawmtrlCrynGridAttr: {
        showRowNo: false,
        pageable: false,
        excelExport: {
          fileName: '원재료 반입 현황(톤/월).xlsx',
          includeHeader: true,
          includeFormula: true,
        }
      },
      wtArtclGridReload: false,
      wtArtclGridObject: null,
      wtArtclDataSource: [],
      wtArtclGridColumns: [],
      wtArtclGridAttr: {
        showRowNo: false,
        pageable: false,
        checkable: true,
        mergeRow: true,
        toolBar: [],
      },
      wtCrynGridReload: false,
      wtCrynGridObject: null,
      wtCrynDataSource: [],
      wtCrynGridColumns: [],
      wtCrynGridAttr: {
        showRowNo: false,
        pageable: false,
        checkable: true,
        editable: {eventType: 'mouseup'},
        mergeRow: true,
        toolBar: [],
      },
      rawmtrlInputGridReload: false,
      rawmtrlInputGridObject: null,
      rawmtrlInputDataSource: [],
      rawmtrlInputGridColumns: [],
      rawmtrlInputGridAttr: {
        showRowNo: false,
        pageable: false,
        editable: {eventType: 'mouseup'},
        toolBar: [],
      },
      prdctnGridReload: false,
      prdctnGridObject: null,
      prdctnDataSource: [],
      prdctnGridColumns: [],
      prdctnGridAttr: {
        showRowNo: false,
        pageable: false,
        editable: {eventType: 'mouseup'},
        toolBar: [],
      },
      ntslDtlGridReload: false,
      ntslDtlGridObject: null,
      ntslDtlDataSource: [],
      ntslDtlGridColumns: [],
      ntslDtlGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        editable: {eventType: 'mouseup'},
        toolBar: [],
      },
      ntslGridReload: false,
      ntslGridObject: null,
      ntslDataSource: [],
      ntslGridColumns: [],
      ntslGridAttr: {
        showRowNo: false,
        pageable: false,
        editable: {eventType: 'mouseup'},
        toolBar: [],
      },
      ntslSeCdList: [],
      rawmtrlCrynList: [],
      wtCrynList: [],
      selectedCtpvStdgCd: "",
      mnftrPrfmncInfo: {}, // 원재료 현황(톤/월)
      tprdqty: 0, // 실적연도 총생산량
      rawmtrlCrynGridInitialized: false,  // 최초 첫 행 선택을 관리하기 위한 플래그
      wtCrynGridInitialized: false, // 최초 첫 행 선택을 관리하기 위한 플래그
    };
  },
  computed: {
  },
  watch: {
    rawmtrlCrynInfo() {
      this.initializeRawmtrlCryn();
    },
    rawmtrlPrcsInfo(newValue) {
      this.mnftrPrfmncInfo = newValue;
    },
    'mnftrPrfmncInfo.wtDscdCrtqty'() {
      this.setWtRmnqty();
    },
    rawmtrlInpuInfo() {
      this.initializeRawmtrlInput();
    },
    prdctnInfo() {
      this.initializePrdctn();
    },
    ntslDtlInfo() {
      this.initializeNtslDtl();
    },
    ntslInfo() {
      this.loadPrfmncSttsCd();
      this.initializeNtsl();
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    loadPrfmncSttsCd() {
      const ntslSeCdList = this.$store.getters.getCodeList({ groupCd: CODE.SALES_SECTION, useYn: 'Y' });
      this.ntslSeCdList = ntslSeCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    initializeRawmtrlCryn() {
      this.rawmtrlCrynList = this.rawmtrlCrynInfo.list;
      this.rawmtrlCrynDataSource = { data: this.rawmtrlCrynInfo.list, schema: { id: 'ctpvStdgCd'}};
      this.wtCrynList = this.rawmtrlCrynInfo.wtCrynList;
      this.initializeRawmtrlCrynGridColumns();
      this.initializeRawmtrlCrynGridAttr();
      this.rawmtrlCrynGridReload = true;
      this.initializeWtArtcl();
      this.initializeWtCryn();
    },
    initializeWtArtcl() {
      const inputItemList = Array.isArray(this.rawmtrlCrynInfo?.inputItemList) ? this.rawmtrlCrynInfo.inputItemList : [];

      this.srfMnftrMtralCdList = this.$store.getters.getCodeList({ groupCd: CODE.SRF_MANUFACTURE_MATERIAL, useYn: 'Y' })
          .filter(item => { return inputItemList.some(inputItem => inputItem.inputItemCd === item.frstRefVl);
      });

      this.srfMnftrMtralCdList.sort((a, b) => {
        if (a.thdRefVl < b.thdRefVl) return -1;
        if (a.thdRefVl > b.thdRefVl) return 1;
        if (a.frstRefVl < b.frstRefVl) return -1;
        if (a.frstRefVl > b.frstRefVl) return 1;
        if (a.dtlCd < b.dtlCd) return -1;
        if (a.dtlCd > b.dtlCd) return 1;
        return 0;
      });

      this.wtArtclDataSource = this.srfMnftrMtralCdList;
      this.initializeWtArtclGridColumns();
      this.initializeWtArtclGridAttr();
      this.wtArtclGridReload = true;
    },
    initializeWtCryn() {
      this.initializeWtCrynGridColumns();
      this.initializeWtCrynGridAttr();
      this.wtCrynGridReload = true;
    },
    initializeRawmtrlInput() {
      this.rawmtrlInputDataSource = { data: this.rawmtrlInpuInfo.list, schema: { id: 'fcltSqno'}};
      this.initializeRawmtrlInputGridColumns();
      this.initializeRawmtrlInputGridAttr();
      this.rawmtrlInputGridReload = true;
    },
    initializePrdctn() {
      this.tprdqty = this.prdctnInfo.tprdqty || 0;
      this.prdctnDataSource = { data: this.prdctnInfo.list, schema: { id: 'fcltSqno'}};
      this.initializePrdctnGridColumns();
      this.initializePrdctnGridAttr();
      this.prdctnGridReload = true;
    },
    initializeNtslDtl() {
      this.ntslDtlDataSource = this.ntslDtlInfo.list;
      this.initializeNtslDtlGridColumns();
      this.initializeNtslDtlGridAttr();
      this.ntslDtlGridReload = true;
    },
    initializeNtsl() {
      this.ntslDataSource = { data: this.ntslInfo.list, schema: { id: 'prdctItemCd'}};
      this.initializeNtslGridColumns();
      this.initializeNtslGridAttr();
      this.ntslGridReload = true;
    },
    initializeRawmtrlCrynGridColumns() {

      const columnCount = this.rawmtrlCrynInfo.inputItemList.length+2;
      const columnWidth = columnCount > 0 ? (100 / columnCount).toFixed(2) : 100;

      this.rawmtrlCrynGridColumns = [
        { field: 'ctpvStdgNm', caption: '시·도', minWidth: 150, width:columnWidth, unit:'%',
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          },
        },
        { caption: '원재료 종류(톤)' },
      ];

      if (this.rawmtrlCrynInfo.inputItemList && this.rawmtrlCrynInfo.inputItemList.length > 0) {
        this.rawmtrlCrynGridColumns[1].columns = [];

        const inputItemCds = this.rawmtrlCrynInfo.inputItemList.map(item => item.inputItemCd);

        this.rawmtrlCrynGridColumns[1].columns.push({
          field: 'sum', caption: '합계(톤)', minWidth: 150, width:columnWidth, unit:'%',
          total: {
            aggregates: [ {func: 'sum', require: 'sum'} ],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
          calc: {
            require: inputItemCds,
            eval: 'sum',
            nullAs: 0,
            dataType: 'number',
          },
          align: 'right',
          format: '#,##0.00'
        });

        this.rawmtrlCrynInfo.inputItemList.forEach(item => {
          this.rawmtrlCrynGridColumns[1].columns.push({
            field: item.inputItemCd,
            caption: item.inputItemNm + '(톤)',
            minWidth: 100,
            width: columnWidth,
            unit: '%',
            total: {
              aggregates: [ {func: 'sum', require: item.inputItemCd} ],
              header: [{ template: '{{sum}}', format: '#,##0.00' }],
              headerCss:'grid-header',
            },
            align: 'right',
            format: '#,##0.00'
          });
        });
      }
    },
    initializeRawmtrlCrynGridAttr() {
      const itemCount = this.rawmtrlCrynInfo.list.length;
      this.rawmtrlCrynGridAttr.height = Math.max((itemCount * 30) + 150, 150);

      this.rawmtrlCrynGridAttr.rowCss = (data) => {
        const focusedRowKey = window.SBGrid3.getFocusedKey(this.rawmtrlCrynGridObject);

        if (focusedRowKey === data._key_) {
          return 'grid-good';
        }

        return '';
      }
    },
    initializeWtArtclGridColumns() {
      this.wtArtclGridColumns = [
        { field: 'frstRefVl', caption: '폐기물분류코드', visible: false },
        { field: 'frthRefVl', caption: '폐기물분류', width: 100, mergeRow: true },
        { field: 'scdRefVl', caption: '폐기물구분',  width: 100, mergeRow: true },
        { field: 'dtlCd', caption: '폐기물코드',  width: 100 },
        { field: 'dtlCdNm', caption: '폐기물코드명', minWidth: 100, width: 100, unit: '%', align: 'left',
          tooltip: { cell: { placement: 'right'}  }
        },
      ];
    },
    initializeWtArtclGridAttr() {
      this.wtArtclGridAttr.rowCss = (data) => {
        const keys = window.SBGrid3.getCheckedKeys(this.wtArtclGridObject);

        if (keys.includes(data._key_)) {
          return 'grid-good';
        }

        return '';
      }
    },
    initializeWtCrynGridColumns() {
      this.wtCrynGridColumns = [
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'ctpvStdgNm', caption: '시·도', width: 90, mergeRow: true, editable: false },
        { field: 'wtItemCd', caption: '폐기물품목코드', visible: false, editable: false },
        { field: 'wtItemNm', caption: '폐기물구분', width: 100, mergeRow: true, editable: false, tooltip: { cell: { placement: 'left'} } },
        { field: 'srfMnftrMtralCd', caption: '폐기물코드', width: 80, editable: false },
        { field: 'srfMnftrMtralNm', caption: '폐기물코드명', minWidth: 100, width: 100, unit: '%', editable: false,
          tooltip: { cell: { placement: 'left'} }
        },
        { field: 'prvmmWtCryqty', caption: '전월 반입량(톤)', width: 100, editable: false,
          dataType: 'number',
          align: 'right',
          format: '#,##0.00',
          colCss: 'grid-disabled'
        },
        { field: 'wtCryqty', caption: '당월 반입량(톤)', width: 100, part: 'sticky',
          type: 'number',
          dataType: 'number',
          align: 'right',
          format: '#,##0.00'
        },
      ];
    },
    initializeWtCrynGridAttr() {
      this.wtCrynGridAttr.rowCss = (data) => {
        const keys = window.SBGrid3.getCheckedKeys(this.wtCrynGridObject);

        if (keys.includes(data._key_)) {
          return 'grid-danger';
        }

        return '';
      }
    },
    initializeRawmtrlInputGridColumns() {

      const columnCount = this.rawmtrlInpuInfo.inputItemList.length + 3;
      const columnWidth = columnCount > 0 ? (100 / columnCount).toFixed(2) : 100;

      this.rawmtrlInputGridColumns = [
        { field: 'fcltSqno', caption: '시설순번', visible: false, editable: false },
        { field: 'fcltNm', caption: '시설명', minWidth: 205, width:columnWidth, unit:'%', editable: false, align: 'left', total: { headerCss:'grid-header' } },
        { field: 'prdctItemCd', caption: '제품품목코드', visible: false, editable: false },
        { field: 'prdctItemNm', caption: '고형연료제품종류', minWidth: 120, width:columnWidth, unit:'%', editable: false,
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          },
        },
        { caption: '원재료 투입 종류(톤)' },
      ];

      if (this.rawmtrlInpuInfo.inputItemList && this.rawmtrlInpuInfo.inputItemList.length > 0) {
        this.rawmtrlInputGridColumns[4].columns = [];

        const inputItemCds = this.rawmtrlInpuInfo.inputItemList.map(item => item.inputItemCd);

        this.rawmtrlInputGridColumns[4].columns.push({
          field: 'sum', caption: '합계', minWidth:150, width: columnWidth, unit: '%',
          total: {
            aggregates: [ {func: 'sum', require: 'sum', nullAs: 0} ],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
          calc: {
            require: inputItemCds,
            eval: 'sum',
            nullAs: 0.00,
            dataType: 'number',
          },
          align: 'right',
          format: '#,##0.00',
          editable: false
        });

        const inputItemInfo = this.rawmtrlInpuInfo.inputItemInfo || {}; // 투입품목데이터
        this.rawmtrlInpuInfo.inputItemList.forEach(item => {
          this.rawmtrlInputGridColumns[4].columns.push({
            field: item.inputItemCd,
            caption: item.inputItemNm,
            type: 'number',
            minWidth: 100, width: columnWidth, unit: '%',
            dataType: 'number', format: '#,##0.00',
            align: 'right',
            editable : (data) => {
              const inputItemData = inputItemInfo[data._key_];
              return inputItemData && Object.prototype.hasOwnProperty.call(inputItemData, item.inputItemCd);
            },
            total: {
              aggregates: [ {func: 'sum', require: item.inputItemCd, nullAs: 0} ],
              header: [{ template: '{{sum}}', format: '#,##0.00' }],
              headerCss:'grid-header',
            },
            colCss : (data) => {
              const inputItemData = inputItemInfo[data._key_];

              if (inputItemData && Object.prototype.hasOwnProperty.call(inputItemData, item.inputItemCd)) {
                return '';
              } else {
                if (data[item.inputItemCd] && data[item.inputItemCd] > 0) {
                  return 'grid-danger';
                } else {
                  return 'grid-disabled';
                }
              }
            }
          });
        });
      }
    },
    initializeRawmtrlInputGridAttr() {
      const itemCount = this.rawmtrlInpuInfo.list.length;

      this.rawmtrlInputGridAttr.height = Math.max((itemCount * 30) + 120, 150);
    },
    initializePrdctnGridColumns() {
      this.prdctnGridColumns = [
        { field: 'fcltSqno', caption: '시설순번', visible: false, editable: false, },
        { field: 'fcltNm', caption: '시설명', minWidth: 205, width:100, unit:'%', editable: false, align: 'left', total: { headerCss:'grid-header' } },
        { field: 'prdctItemCd', caption: '제품품목코드', visible: false },
        { field: 'prdctItemNm', caption: '고형연료제품종류', width: 120, editable: false,
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          },
        },
        { caption: '생산정보',
          columns: [
            { field: 'prdctPrdqty', caption: '생산량(톤)', type: 'number', dataType: 'number', width: 150, align: 'right', format: '#,##0.00',
              total: {
                aggregates: [{ func: 'sum', require: 'prdctPrdqty', nullAs: 0}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'prdctRsdqty', caption: '잔재량(톤)', type: 'number', dataType: 'number', width: 150, align: 'right', format: '#,##0.00',
              total: {
                aggregates: [ {func: 'sum', require: 'prdctRsdqty', nullAs: 0} ],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'prdctPrdctnYldrt', caption: '생산수율(%)', dataType: 'number', width: 150, align: 'right', format: '#,##0.00',
              editable: false,
              colCss: 'grid-disabled',
              total: { headerCss:'grid-header' },
            },
          ]
        },
        { caption: '생산량 검증', captionCss:'bg-green-light',
          columns: [
            { field: 'mthlyFcltCap', caption: '월간시설용량(톤/월)', type: 'number', dataType: 'number', width: 150, align: 'right', captionCss:'bg-green-light',
              format: '#,##0.00', editable: false, visible: false,
              total: {
                aggregates: [{ func: 'sum', require: 'mthlyFcltCap'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
              colCss: 'grid-disabled'
            },
            { field: 'mthlyexcsCap', caption: '초과용량(톤/월)', dataType: 'number', width: 150, align: 'right', captionCss:'bg-green-light',
              format: '#,##0.00', editable: false, visible: false,
              total: {
                aggregates: [{
                  func: (items) => {
                    return this.sanitizeFloatingPointError(items.reduce((sum, item) => {
                      const difference = (item.prdctPrdqty || 0) - (item.mthlyFcltCap || 0);
                      return sum + (difference > 0 ? difference : 0);
                    }, 0));
                  },
                  field: 'excsCapTotal'
                }],
                header: [{ template: '{{excsCapTotal}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
              getValue: (value, field, rowItem) => {
                const prdctPrdqty = rowItem.data.prdctPrdqty || 0
                const mthlyFcltCap = rowItem.data.mthlyFcltCap || 0;
                const difference = prdctPrdqty - mthlyFcltCap;
                return difference > 0 ? difference : '';
              },
              colCss: (data) => {
                const prdctPrdqty = data.prdctPrdqty || 0;
                const mthlyFcltCap = data.mthlyFcltCap || 0;

                if (prdctPrdqty > mthlyFcltCap) {
                  return 'grid-danger';
                } else {
                  return 'grid-disabled';
                }
              }
            },
            { field: 'vrfcRslt', caption: '검증결과', width: 150, captionCss:'bg-green-light',
              editable: false, visible: false,
              total: { headerCss:'grid-header' },
              getValue: (value, field, rowItem) => {
                const prdctPrdqty = rowItem.data.prdctPrdqty || 0
                const mthlyFcltCap = rowItem.data.mthlyFcltCap || 0;
                return prdctPrdqty > mthlyFcltCap ? "초과" : "적합";
              },
              colCss: (data) => {
                const prdctPrdqty = data.prdctPrdqty || 0;
                const mthlyFcltCap = data.mthlyFcltCap || 0;

                if (prdctPrdqty > mthlyFcltCap) {
                  return 'grid-danger';
                } else {
                  return 'grid-good';
                }
              }
            },
          ]
        },
      ];
    },
    initializePrdctnGridAttr() {
      const itemCount = this.prdctnInfo.list.length;

      this.prdctnGridAttr.height = Math.max((itemCount * 30) + 130, 150);
    },
    initializeNtslDtlGridColumns() {
      const list = this.ntslDtlInfo.list;
      const uniqueInputItemCdList = [...new Set(list.map(item => item.inputItemCd))];

      const inputItemCdNames = {
        '0001': 'SRF 성형',
        '0002': 'SRF 비성형',
        '0003': 'BIO-SRF 성형',
        '0004': 'BIO-SRF 비성형',
      };

      this.ntslDtlGridColumns = [
        { field: 'bplcId', caption: '사업장아이디', visible: false, editable: false },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'ctpvStdgNm', caption: '시·도', width: 120, mergeRow: true, editable: false, total: { headerCss:'grid-header' } },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        { field: 'sggStdgNm', caption: '시·군·구', width: 120, mergeRow: true, editable: false, total: { headerCss:'grid-header' } },
        { field: 'bplcNm', caption: '사업장명', mergeRow: true, minWidth: 250, width:100, unit:'%', editable: false, align: 'left', total: { headerCss:'grid-header' } },
        { field: 'inputItemCd', caption: '고형연료제품코드', visible: false, editable: false },
        { field: 'inputItemNm', caption: '고형연료제품종류', width: 150, editable: false,
          total: {
            aggregates: [],
            header: [
              { template: '합계', align: 'right' },
              ...uniqueInputItemCdList.map(cd => ({
                template: inputItemCdNames[cd] || '', align: 'right'
              })),
            ],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctNsqty', caption: '판매량(톤)', type:'number', width: 130, align: 'right',
          dataType:'number', format: '#,##0.00',
          total: {
            aggregates: [
              {func: 'sum', require: 'prdctNsqty', nullAs: 0},
              ...this.sanitizeFloatingPointError(uniqueInputItemCdList.map(cd => ({
                func: (items) => items
                    .filter(item => item.inputItemCd === cd)
                    .reduce((sum, item) => sum + (item.prdctNsqty || 0), 0),
                field: `prdctNsqty${cd}`
              })))
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00' },
              ...uniqueInputItemCdList.map(cd => ({
                template: `{{prdctNsqty${cd}}}`, format: '#,##0.00'
              }))
            ],
            headerCss:'grid-header',
          },
        },
        { caption: '판매량 검증 (판매사업장의 실적 등록정보)', captionCss:'bg-green-light',
          columns: [
            { field: 'prdctAcpqty', caption: '검증 판매량(톤)', width: 150, align: 'right', captionCss:'bg-green-light',
              dataType: 'number', format: '#,##0.00', editable: false,
              total: {
                aggregates: [
                  {func: 'sum', require: 'prdctAcpqty'},
                  ...this.sanitizeFloatingPointError(uniqueInputItemCdList.map(cd => ({
                    func: (items) => items
                        .filter(item => item.inputItemCd === cd)
                        .reduce((sum, item) => sum + (item.prdctAcpqty || 0), 0),
                    field: `prdctAcpqty${cd}`
                  })))
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00' },
                  ...uniqueInputItemCdList.map(cd => ({
                    template: `{{prdctAcpqty${cd}}}`, format: '#,##0.00'
                  }))
                ],
                headerCss:'grid-header',
              },
              colCss: 'grid-disabled'
            },
            { field: 'diff', caption: '차이량(톤)', width: 150, align: 'right', captionCss:'bg-green-light',
              dataType: 'number', format: '#,##0.00', editable: false,
              total: {
                aggregates: [
                  {
                    func: (items) => {
                      const totalNsqty = this.sanitizeFloatingPointError(items.reduce((sum, item) => sum + (item.prdctNsqty || 0), 0));
                      const totalAcpqty = this.sanitizeFloatingPointError(items.reduce((sum, item) => sum + (item.prdctAcpqty || 0), 0));
                      return Math.abs(totalNsqty - totalAcpqty);
                    },
                    field: 'diffTotal'
                  },
                  ...uniqueInputItemCdList.map(cd => ({
                    func: (items) => {
                      const totalNsqtyCd = this.sanitizeFloatingPointError(items
                          .filter(item => item.inputItemCd === cd)
                          .reduce((sum, item) => sum + (item.prdctNsqty || 0), 0));
                      const totalAcpqtyCd = this.sanitizeFloatingPointError(items
                          .filter(item => item.inputItemCd === cd)
                          .reduce((sum, item) => sum + (item.prdctAcpqty || 0), 0));

                      return Math.abs(totalNsqtyCd - totalAcpqtyCd);
                    },
                    field: `diff${cd}`
                  }))
                ],
                header: [
                  { template: '{{diffTotal}}', format: '#,##0.00' },
                  ...uniqueInputItemCdList.map(cd => ({
                    template: `{{diff${cd}}}`, format: '#,##0.00'
                  }))
                ],
                headerCss:'grid-header',
              },
              getValue: (value, field, rowItem) => {
                const prdctNsqty = rowItem.data.prdctNsqty || 0
                const prdctAcpqty = rowItem.data.prdctAcpqty || 0;
                const difference = prdctNsqty - prdctAcpqty;

                return difference !== 0 ? Math.abs(prdctNsqty - prdctAcpqty) : '';
              },
              colCss: (data) => {
                const prdctNsqty = data.prdctNsqty || 0
                const prdctAcpqty = data.prdctAcpqty || 0;

                if (prdctNsqty !== prdctAcpqty) {
                  return 'grid-danger';
                } else {
                  return 'grid-disabled';
                }
              }
            },
          ]
        },
      ];
    },
    initializeNtslDtlGridAttr() {
      const itemCount = this.ntslDtlInfo.list.length;
      const uniqueItemCount = new Set(this.ntslDtlInfo.list.map(item => item.inputItemCd)).size;

      this.ntslDtlGridAttr.height = Math.max(((itemCount + uniqueItemCount) * 30) + 130, 200);
    },
    initializeNtslGridColumns() {
      this.ntslGridColumns = [
        { field: 'bplcId', caption: '사업장아이디', visible: false, editable: false },
        { field: 'prdctItemCd', caption: '고형연료제품코드', visible: false, editable: false },
        { field: 'prdctItemNm', caption: '고형연료제품종류', part:'head', width: 130, editable: false,
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctCrfqty', caption: '이월량(톤)', width: 120, align: 'right', editable: false,
          dataType:'number', format: '#,##0.00',
          total: {
            aggregates: [ {func: 'sum', require: 'prdctCrfqty', nullAs: 0} ],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
          colCss: 'grid-disabled'
        },
        { field: 'prdqty', caption: '생산량(톤)', width: 120, align: 'right', editable: false,
          dataType:'number', format: '#,##0.00',
          total: {
            aggregates: [ {func: 'sum', require: 'prdqty', nullAs: 0} ],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
          colCss: 'grid-disabled'
        },
        { field: 'prdctSumNsqty', caption: '판매량(톤)', width: 120, align: 'right', editable: false,
          dataType:'number', format: '#,##0.00',
          total: {
            aggregates: [ {func: 'sum', require: 'prdctSumNsqty', nullAs: 0} ],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
          colCss: 'grid-disabled'
        },
        { field: 'prdctDscdCrtqty', caption: '폐기량(톤)', width: 120, align: 'right',
          type: 'number', dataType:'number', format: '#,##0.00',
          total: {
            aggregates: [ {func: 'sum', require: 'prdctDscdCrtqty', nullAs: 0} ],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctRmnqty', caption: '잔량(톤)', width: 120, align: 'right', editable: false,
          dataType:'number', format: '#,##0.00',
          total: {
            aggregates: [
              { func: (items) => {
                  const totalPrdctCrfqty = this.sanitizeFloatingPointError(items.reduce((sum, item) => sum + (item.prdctCrfqty || 0), 0));
                  const totalPrdqty = this.sanitizeFloatingPointError(items.reduce((sum, item) => sum + (item.prdqty || 0), 0));
                  const totalPrdctSumNsqty = this.sanitizeFloatingPointError(items.reduce((sum, item) => sum + (item.prdctSumNsqty || 0), 0));
                  const totalPrdctDscdCrtqty = this.sanitizeFloatingPointError(items.reduce((sum, item) => sum + (item.prdctDscdCrtqty || 0), 0));
                  return utils.round2(totalPrdctCrfqty + totalPrdqty - totalPrdctSumNsqty - totalPrdctDscdCrtqty);
                },
                field: 'sum'
              }
            ],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
          colCss: 'grid-disabled',
          getValue: (value, field, rowItem) => {
            const prdctCrfqty = rowItem.data.prdctCrfqty || 0;
            const prdqty = rowItem.data.prdqty || 0
            const prdctSumNsqty = rowItem.data.prdctSumNsqty || 0;
            const prdctDscdCrtqty = rowItem.data.prdctDscdCrtqty || 0;

            return utils.round2(prdctCrfqty + prdqty - prdctSumNsqty - prdctDscdCrtqty);
          },
        },
        { field: 'prdctLcp', caption: '저위발열량(kcal/kg)', width: 130, align: 'right',
          type: 'number', dataType:'number', format: '#,##0.00',
          total: {
            aggregates: [
              { func: (items) => {
                  const filteredItems = items.filter(item => item.prdctLcp > 0);
                  const sumPrdqty = filteredItems.reduce((total, item) => total + (item.prdqty || 0), 0);
                  const sumPrdqtyLcp = filteredItems.reduce((total, item) => {
                    const prdqty = item.prdqty || 0;
                    const prdctLcp = item.prdctLcp || 0;
                    return total + (prdqty * prdctLcp);
                  }, 0);

                  return sumPrdqty > 0 ? utils.round2(sumPrdqtyLcp / sumPrdqty) : 0;
                },
                field: 'prdctLcpAvg'
              }
            ],
            header: [{ template: '{{prdctLcpAvg}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'ntslSeCd', caption: '판매구분',
          type: 'combo',
          items: this.ntslSeCdList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 80,
          total: { headerCss:'grid-header' },
        },
        { field: 'prdctNtslPrc', caption: '제품가격(원/톤)', width: 110, align: 'right',
          dataType:'number', format: '#,##0.00',
          total: { headerCss:'grid-header' },
          editable : (data) => { return data.ntslSeCd === "01" },
          colCss: (data) => {
            if (data.ntslSeCd === "01") {
              return '';
            } else {
              return 'grid-disabled';
            }
          }
        },
        { field: 'prdctTransCst', caption: '운송비용(원/톤)', width: 110, align: 'right',
          dataType:'number', format: '#,##0.00',
          total: { headerCss:'grid-header' },
          colCss: (data) => {
            if (data.ntslSeCd === "01") {
              return '';
            } else {
              return 'grid-disabled';
            }
          }
        },
        { field: 'prdctTransDistVl', caption: '거리(km)', width: 110, align: 'right',
          dataType:'number', format: '#,##0.00',
          total: { headerCss:'grid-header' },
          editable : (data) => { return data.ntslSeCd === "01" },
          colCss: (data) => {
            if (data.ntslSeCd === "01") {
              return '';
            } else {
              return 'grid-disabled';
            }
          }
        },
      ];
    },
    initializeNtslGridAttr() {
      const itemCount = this.ntslInfo.list.length;

      this.ntslGridAttr.height = Math.max((itemCount * 30) + 120, 200);
    },
    rawmtrlCrynGridLoaded() {
      this.rawmtrlCrynGridReload = false;

      // 첫 행 선택
      if (!this.rawmtrlCrynGridInitialized && this.rawmtrlCrynInfo.list && this.rawmtrlCrynInfo.list.length > 0) {
        this.selectedCtpvStdgCd = this.rawmtrlCrynInfo.list[0].ctpvStdgCd;
        this.rawmtrlCrynGridInitialized = true; // 첫 행 선택 완료 표시

        if (this.wtArtclGridObject && !this.wtCrynGridInitialized) {
          this.setWtCrynGridData();
          this.wtCrynGridInitialized = true; // 첫 행 선택 완료 표시
        }
      }

      if (this.selectedCtpvStdgCd) {
        const rowItem= window.SBGrid3.getRow(this.rawmtrlCrynGridObject, this.selectedCtpvStdgCd);
        const column = window.SBGrid3.getColumn(this.rawmtrlCrynGridObject, 0)[0];
        window.SBGrid3.moveFocus(this.rawmtrlCrynGridObject, rowItem, column);
      }
    },
    wtArtclGridLoaded() {
      this.wtArtclGridReload = false;
    },
    wtCrynGridLoaded() {
      this.wtCrynGridReload = false;

      if (!this.wtCrynGridInitialized) { // 최초 한번만 실행하도록 플래그 사용
        // 첫 행 선택
        if (!this.selectedCtpvStdgCd && this.rawmtrlCrynInfo.list && this.rawmtrlCrynInfo.list.length > 0) {
          this.selectedCtpvStdgCd = this.rawmtrlCrynInfo.list[0].ctpvStdgCd;
          this.setWtCrynGridData();
        }
        this.wtCrynGridInitialized = true; // 첫 행 선택 완료 표시
      }
    },
    rawmtrlInputGridLoaded() {
      this.rawmtrlInputGridReload = false;
    },
    prdctnGridLoaded() {
      this.prdctnGridReload = false
    },
    ntslDtlGridLoaded() {
      this.ntslDtlGridReload = false
    },
    ntslGridLoaded() {
      this.setPrdctPrdqty();
      this.ntslGridReload = false
    },
    rawmtrlCrynClick(rowData) {
      this.selectedCtpvStdgCd = rowData.ctpvStdgCd;
      this.setWtCrynGridData();
    },
    copyWtArtcl() {
      if (!this.selectedCtpvStdgCd) {
        alert("선택된 시도가 없습니다.");
        return;
      }
      const selectedCtpvInfo = window.SBGrid3.getRowData(this.rawmtrlCrynGridObject, this.selectedCtpvStdgCd);

      const rowItems = window.SBGrid3.getCheckedRows(this.wtArtclGridObject);

      const extractedItems = rowItems.map(item => ({
        ctpvStdgCd: selectedCtpvInfo.ctpvStdgCd,
        ctpvStdgNm: selectedCtpvInfo.ctpvStdgNm,
        wtItemCd: item.data.frstRefVl,
        wtItemNm: item.data.scdRefVl,
        srfMnftrMtralCd: item.data.dtlCd,
        srfMnftrMtralNm: item.data.dtlCdNm,
        prvmmWtCryqty: 0.00,
        wtCryqty: 0.00,
        status: "I",
      }));

      extractedItems.forEach(item => {
        const existingItem = this.wtCrynList.find(existingItem =>
            existingItem.ctpvStdgCd === item.ctpvStdgCd &&
            existingItem.wtItemCd === item.wtItemCd &&
            existingItem.srfMnftrMtralCd === item.srfMnftrMtralCd
        );

        if (existingItem) {
          if (existingItem.status === 'D') {
            existingItem.status = 'U';
            existingItem.wtCryqty = 0.00;
          }
        } else {
          this.wtCrynList.push(item);
        }
      });

      window.SBGrid3.reload(this.wtArtclGridObject);
      this.setWtCrynGridData();
    },
    deleteWtCryn() {
      const rowItems = window.SBGrid3.getCheckedRows(this.wtCrynGridObject);

      if (rowItems.length === 0) {
        alert("선택된 반입 정보가 없습니다.");
        return;
      }

      if (!confirm(`선택된 ${rowItems.length}개의 반입 정보를 삭제하시겠습니까?`)) {
        return;
      }

      rowItems.forEach(rowItem => {
        const rawmtrlItem = this.rawmtrlCrynList.find(rawmtrlItem =>
            rawmtrlItem.ctpvStdgCd === rowItem.data.ctpvStdgCd
        );

        if (rawmtrlItem && rawmtrlItem[rowItem.data.wtItemCd] !== undefined) {
          const wtCryqty = rowItem.data.wtCryqty || 0;
          rawmtrlItem[rowItem.data.wtItemCd] -= wtCryqty;
        }
      });
      window.SBGrid3.setClientData(this.rawmtrlCrynGridObject, this.rawmtrlCrynList);

      this.wtCrynList = this.wtCrynList.filter(wtCrynItem => {

        const matchingRowItem = rowItems.find(rowItem =>
            rowItem.data.ctpvStdgCd === wtCrynItem.ctpvStdgCd &&
            rowItem.data.wtItemCd === wtCrynItem.wtItemCd &&
            rowItem.data.srfMnftrMtralCd === wtCrynItem.srfMnftrMtralCd
        );

        if (matchingRowItem) {
          if (wtCrynItem.status === 'I') {
            return false;
          } else {
            wtCrynItem.status = 'D';
          }
        }
        return true;
      });

      // 원재료 현황 반입량 설정
      if (typeof this.mnftrPrfmncInfo.wtSumCryqty !== 'number') {
        this.mnftrPrfmncInfo.wtSumCryqty = 0;
      }

      const totalWtCryqty = this.sanitizeFloatingPointError(this.wtCrynList
          .filter(item => item.status !== 'D') // status가 'D'가 아닌 항목 필터링
          .reduce((sum, item) => sum + (item.wtCryqty || 0), 0));

      this.mnftrPrfmncInfo.wtSumCryqty = utils.round2(totalWtCryqty);

      this.setWtCrynGridData();
    },
    setWtCrynGridData() {
      const filteredItems = this.wtCrynList.filter(item =>
          item.ctpvStdgCd === this.selectedCtpvStdgCd && item.status !== 'D'
      );
      window.SBGrid3.clearSaveData(this.wtCrynGridObject);
      window.SBGrid3.setClientData(this.wtCrynGridObject ,filteredItems);
    },
    changeWtCryn(values) {
      values.forEach(item => {
        if (item.field === 'wtCryqty') {
          const data = window.SBGrid3.getRowData(this.wtCrynGridObject, item.key);
          if(!data.wtCryqty){
            data.wtCryqty = 0;
          }

          const matchingItem = this.wtCrynList.find(wtCrynItem =>
              wtCrynItem.ctpvStdgCd === data.ctpvStdgCd &&
              wtCrynItem.wtItemCd === data.wtItemCd &&
              wtCrynItem.srfMnftrMtralCd === data.srfMnftrMtralCd
          );

          if (matchingItem) {
            if (!matchingItem.prfmncId) {
              matchingItem.status = "I";
            } else {
              matchingItem.status = "U";
            }

            matchingItem.wtCryqty = data.wtCryqty;

            //25-01-14 KJM 빈값 입력 시 NaN 으로 표기되는 오류 해결
            if(!item.value){
              item.value = 0;
              matchingItem.wtCryqty = 0;
            }

            const diff = item.value - item.oldValue;

            const rawmtrlItem = this.rawmtrlCrynList.find(rawmtrlItem =>
                rawmtrlItem.ctpvStdgCd === matchingItem.ctpvStdgCd
            );

            if (rawmtrlItem) {
              if (rawmtrlItem[matchingItem.wtItemCd] !== undefined) {
                rawmtrlItem[matchingItem.wtItemCd] += diff;

                if (typeof this.mnftrPrfmncInfo.wtSumCryqty !== 'number') {
                  this.mnftrPrfmncInfo.wtSumCryqty = 0;
                }

                this.mnftrPrfmncInfo.wtSumCryqty += diff;
              } else {
                console.log(`원재료 종류가 '${matchingItem.wtItemCd}'에 해당하는 값이 존재하지 않습니다.`);
              }
            }
            window.SBGrid3.setClientData(this.rawmtrlCrynGridObject, this.rawmtrlCrynList);
          }
        }
      });
      this.setWtRmnqty();
    },
    setWtRmnqty() {
      const wtDscdCrtqty = isNaN(this.mnftrPrfmncInfo.wtDscdCrtqty) ? 0 : this.mnftrPrfmncInfo.wtDscdCrtqty; // 폐기반출량
      const wtCrfqty = isNaN(this.mnftrPrfmncInfo.wtCrfqty) ? 0 : this.mnftrPrfmncInfo.wtCrfqty; // 이월량
      const wtSumCryqty = isNaN(this.mnftrPrfmncInfo.wtSumCryqty) ? 0 : this.mnftrPrfmncInfo.wtSumCryqty; // 반입량
      const wtSumInpqty = isNaN(this.mnftrPrfmncInfo.wtSumInpqty) ? 0 : this.mnftrPrfmncInfo.wtSumInpqty; // 투입량

      const result = wtCrfqty + wtSumCryqty - wtSumInpqty - wtDscdCrtqty;

      this.mnftrPrfmncInfo.wtRmnqty = Math.round(result * 100) / 100;
    },
    changeRawmtrlInput(values) {
      //console.log("values", values);
      values.forEach(item => {
        //2025.03.17 null 인 경우 0처리
        if (item.field >= '0005' && item.field <= '9999') {
          const data = window.SBGrid3.getRowData(this.rawmtrlInputGridObject, item.key);
          if(!data[item.field]) { data[item.field] = 0; }
        }

        const currentValue = isNaN(item.value) ? 0 : item.value;
        const oldValue = isNaN(item.oldValue) ? 0 : item.oldValue;

        const difference = currentValue - oldValue;

        if (isNaN(this.mnftrPrfmncInfo.wtSumInpqty)) {
          this.mnftrPrfmncInfo.wtSumInpqty = 0;
        }

        const inputItemData = this.rawmtrlInpuInfo.inputItemInfo[item.key];
        if (inputItemData && Object.prototype.hasOwnProperty.call(inputItemData, item.field)) {
          this.mnftrPrfmncInfo.wtSumInpqty += difference;
          this.setPrdctPrdctnYldrt(item);
        } else {
          if (currentValue > 0) {
            // 해당 시설에 투입가능한 원재료가 아닐경우 0 처리
            window.SBGrid3.setValue(this.rawmtrlInputGridObject, item.key, 0, item.field);
          }
        }
      });
      this.setWtRmnqty();
    },
    cryQtyAplcn() {
      const data = window.SBGrid3.getClientData(this.rawmtrlInputGridObject);
      const ratioInfo = this.rawmtrlInpuInfo.inputItemInfo;
      const wtSumCryqty = this.mnftrPrfmncInfo.wtCrfqty + this.mnftrPrfmncInfo.wtSumCryqty; //이월량+반입량

      let result = data.map((item) => {
        const { fcltSqno, anlFcltCapRatio, _key_ } = item;
        const baseQty = wtSumCryqty * (anlFcltCapRatio / 100);

        return { fcltSqno, _key_, baseQty, adjustedValues: {} };
      });

      const totalBaseQty = result.reduce((acc, item) => acc + item.baseQty, 0);
      const adjustment = wtSumCryqty - totalBaseQty;  // 전체와 총 반입량의 차이

      let finalResult = [];

      result.forEach((item, idx) => {
        const facilityRatio = ratioInfo[item.fcltSqno];
        let totalAdjusted = 0;

        if (facilityRatio) {
          const keys = Object.keys(facilityRatio);
          keys.forEach((ratioKey, index) => {

            let value = item.baseQty * (facilityRatio[ratioKey] / 100);
            value = parseFloat(value.toFixed(2));

            // 마지막 항목에서 baseQty와의 오차를 조정
            if (index === keys.length - 1) {
              value += parseFloat((item.baseQty - (totalAdjusted + value)).toFixed(2));
            }

            finalResult.push({
              key: item._key_,
              field: ratioKey,
              value: value
            });

            totalAdjusted += value;
          });
        }

        // 마지막 시설의 baseQty에 전체 오차 adjustment 반영
        if (idx === result.length - 1) {
          item.baseQty += adjustment;
        }
      });

      window.SBGrid3.setValues(this.rawmtrlInputGridObject, finalResult);
    },
    changePrdcntn(values) {
      values.forEach(item => {
        if (item.field === 'prdctPrdqty') {
          const data = window.SBGrid3.getRowData(this.prdctnGridObject, item.key);
          if(!data[item.field]) { data[item.field] = 0; }

          this.setPrdctPrdctnYldrt(item);
          this.setPrdctPrdqty();
          this.setTprdqty(item);
        }
        //2025.03.17 null 인 경우 0처리
        if (item.field === 'prdctRsdqty') {
          const data = window.SBGrid3.getRowData(this.prdctnGridObject, item.key);
          if(!data[item.field]) { data[item.field] = 0; }
        }
      });
      this.setWtRmnqty();
    },
    /* 생산수율 입력*/
    setPrdctPrdctnYldrt(data) {
      const rawmtrlInputRowData = window.SBGrid3.getRowData(this.rawmtrlInputGridObject, data.key);
      const prdctnRowData = window.SBGrid3.getRowData(this.prdctnGridObject, data.key);
      const prdctPrdqty = prdctnRowData.prdctPrdqty || 0; // 제품생산량
      const wtInpqty = rawmtrlInputRowData.sum || 0;  //원재료투입량

      let prdctPrdctnYldrt = wtInpqty !== 0 ? parseFloat(((prdctPrdqty / wtInpqty) * 100).toFixed(2)) : 0;
      // 25.02.14 lhs 생산수율이 100%를 넘어가지 않도록 수정요청(요청자 : 이향경 주임)
      if(prdctPrdctnYldrt > 100){
        alert("생산량은 원재료 투입량을 초과할 수 없습니다.")
        if(data.field === "prdctPrdqty"){
          window.SBGrid3.setValue(this.prdctnGridObject, data.key, data.oldValue, data.field);
        }else{
          window.SBGrid3.setValue(this.rawmtrlInputGridObject, data.key, data.oldValue, data.field);
        }
      }else{
        window.SBGrid3.setValue(this.prdctnGridObject, data.key, prdctPrdctnYldrt, 'prdctPrdctnYldrt');
      }
    },
    setPrdctPrdqty() {
      const allItems = window.SBGrid3.getAllItems(this.prdctnGridObject);

      const prdctPrdqtyTotals = {};
      allItems.forEach(item => {
        const prdctItemCd = item.prdctItemCd;
        const prdctPrdqty = item.prdctPrdqty || 0;

        if (prdctPrdqtyTotals[prdctItemCd]) {
          prdctPrdqtyTotals[prdctItemCd] += prdctPrdqty;
        } else {
          prdctPrdqtyTotals[prdctItemCd] = prdctPrdqty;
        }
      });

      const finalResult = Object.keys(prdctPrdqtyTotals).map(prdctItemCd => ({
        key: prdctItemCd,
        field: 'prdqty',
        value: prdctPrdqtyTotals[prdctItemCd]
      }));

      window.SBGrid3.setValues(this.ntslGridObject, finalResult);
    },
    setTprdqty(item) { // 실적연도 총생산량 설정
      const { value = 0, oldValue = 0 } = item;
      const diff = utils.round2(value - oldValue);
      this.tprdqty = utils.round2(this.tprdqty + diff);
    },
    changeNtslDtl(values) {
      values.forEach(item => {
        if (item.field === 'prdctNsqty') {
          //2025.03.17 판매량 null인 경우 0 입력
          const data = window.SBGrid3.getRowData(this.ntslDtlGridObject, item.key);
          if(!data.prdctNsqty){
            data.prdctNsqty = 0;
          }

          this.setPrdctNsqty();
        }
      });
    },
    setPrdctNsqty() {
      const allItems = window.SBGrid3.getAllItems(this.ntslDtlGridObject);

      const prdctNsqtyTotals = {};
      allItems.forEach(item => {
        const prdctItemCd = item.inputItemCd;
        const prdctNsqty = item.prdctNsqty || 0;

        if (prdctNsqtyTotals[prdctItemCd]) {
          prdctNsqtyTotals[prdctItemCd] += prdctNsqty;
        } else {
          prdctNsqtyTotals[prdctItemCd] = prdctNsqty;
        }
      });

      const finalResult = Object.keys(prdctNsqtyTotals).map(prdctItemCd => ({
        key: prdctItemCd,
        field: 'prdctSumNsqty',
        value: prdctNsqtyTotals[prdctItemCd]
      }));

      window.SBGrid3.setValues(this.ntslGridObject, finalResult);
    },
    changeNtsl(values) {
      values.forEach(item => {
        if (item.field === 'ntslSeCd') {
          if (item.value === '02') { // 무상
            const data = [
              { key: item.key, field: 'prdctNtslPrc', value: 0 },
              { key: item.key, field: 'prdctTransCst', value: 0 },
              { key: item.key, field: 'prdctTransDistVl', value: 0 },
            ];
            window.SBGrid3.setValues(this.ntslGridObject, data);
          }
        }
        //2025.03.17 null 인경우 0처리
        if (item.field === 'prdctDscdCrtqty') {
          const data = window.SBGrid3.getRowData(this.ntslGridObject, item.key);
          if(!data.prdctDscdCrtqty){
            data.prdctDscdCrtqty = 0;
          }
        }
        //2025.03.17 null 인경우 0처리
        if (item.field === 'prdctLcp') {
          const data = window.SBGrid3.getRowData(this.ntslGridObject, item.key);
          if(!data.prdctLcp){
            data.prdctLcp = 0;
          }
        }
      });
    },
    toList() {
      this.$router.push(ROUTE.PERFORMANCE_LIST);
    },
    async save() {
      if (!confirm("저장하시겠습니까?")) {
        return;
      }

      const validationMessage = this.validate(true);
      let prfmncSttsCd = "IPG"; // 기본 상태 코드

      if (validationMessage) {
        alert(validationMessage);
        return;
      } else {
        if (confirm("필수입력 조건이 만족하여 제출가능한상태입니다.\n제출하시겠습니까?")) {
          prfmncSttsCd = "SUB";
        }
      }

      try {
        const result = await this.checkMdfcnPrm(prfmncSttsCd);

        if (result.change) {
          storeSwitch.on('SdflMnfrtPrfmncChgDscntPopup', result.list);
          return;
        }

        this.saveConfirmed(prfmncSttsCd);

      } catch (e) {
        alert("오류가 발생했습니다.");
      }
    },
    async submission() {
      if (!confirm("제출하시겠습니까?")) {
        return;
      }

      const validationMessage = this.validate(true);
      if (validationMessage) {
        alert(validationMessage);
        return;
      }

      try {
        const result = await this.checkMdfcnPrm("SUB");

        if (result.change) {
          storeSwitch.on('SdflMnfrtPrfmncChgDscntPopup', result.list);
          return;
        }

        this.saveConfirmed("SUB");

      } catch (e) {
        alert("오류가 발생했습니다.");
      }
    },
    validate(focusOnError = false) {
      const wtSumCryqty = this.mnftrPrfmncInfo.wtSumCryqty || 0; // 반입량
      const wtSumInpqty = this.mnftrPrfmncInfo.wtSumInpqty || 0; // 투입량
      const wtDscdCrtqty = this.mnftrPrfmncInfo.wtDscdCrtqty || 0;// 폐기반출량
      const calculatedWtRmnqty = (this.mnftrPrfmncInfo.wtCrfqty || 0) + wtSumCryqty - wtSumInpqty - wtDscdCrtqty; // 이월량 + 반입량 -투입량 - 폐기반출량
      const wtRmnqty = utils.round2(calculatedWtRmnqty);
      
      // if (!wtSumCryqty) {
      // 2025.02.03 0값도 임시저장&제출 되도록 수정(미가동 시설인 경우에도 실적등록하는 케이스가 있음)
      if (wtSumCryqty == null) {
        if (focusOnError) {
          this.$refs.rawmtrlCrynTitle.focus();
        }
        return "폐기물 반입 정보를 입력해주세요";
      }
      
      // if (!wtSumInpqty) {
      // 2025.02.03 0값도 임시저장&제출 되도록 수정(미가동 시설인 경우에도 실적등록하는 케이스가 있음)
      if (wtSumInpqty == null) {
        if (focusOnError) {
          this.$refs.wtSumInpqtyInput.focus();
        }
        return "원재료 투입 정보를 입력해주세요";
      }

      if (wtRmnqty < 0) {
        if (focusOnError) {
          this.$refs.wtSumInpqtyInput.focus();
        }
        return "투입량(+폐기반출량)이 반입량(+이월량)보다 초과할 수 없습니다.";
      }

      const allPrdctnItems = window.SBGrid3.getAllItems(this.prdctnGridObject);
      const totalPrdctPrdqty = this.sanitizeFloatingPointError(allPrdctnItems.reduce((sum, item) => {
        return sum + (item.prdctPrdqty || 0);
      }, 0));

      // if (!totalPrdctPrdqty) {
      // 2025.02.03 0값도 임시저장&제출 되도록 수정(미가동 시설인 경우에도 실적등록하는 케이스가 있음)
      if (totalPrdctPrdqty == null) {
        if (focusOnError) {
          this.$refs.prdcntTitle.focus();
        }
        return "시설별 생산 현황을 입력해 주세요.";
      }

      const checkFcltCap = utils.round2(this.prdctnInfo.tAnlFcltCap * 1.3);
      const tprdqty = this.tprdqty || 0;

      if (tprdqty > checkFcltCap) {
        if (focusOnError) {
          this.$refs.prdcntTitle.focus();
        }
        return "총 생산량이 연간 시설 용량의 30%를 초과했습니다.";
      }

      // const allNtslDtlItems = window.SBGrid3.getAllItems(this.ntslDtlGridObject);
      // const totalPrdctNsqty = allNtslDtlItems.reduce((sum, item) => {
      //   return sum + (item.prdctNsqty || 0);
      // }, 0);
      //
      // if (!totalPrdctNsqty) {
      //   if (focusOnError) {
      //     this.$refs.ntslDtlTitle.focus();
      //   }
      //   return "고형연료제품 판매 정보를 입력해 주세요.";
      // }

      const allNtslItems = window.SBGrid3.getAllItems(this.ntslGridObject);
      for (const item of allNtslItems) {
        // 각 항목별 잔여량 계산 및 확인
        const calculatedPrdctSumRmnqty = (item.prdctCrfqty || 0) + (item.prdqty || 0) - (item.prdctSumNsqty || 0) - (item.prdctDscdCrtqty || 0);
        const prdctSumRmnqty = utils.round2(calculatedPrdctSumRmnqty);
        if (prdctSumRmnqty < 0) {
          if (focusOnError) {
            this.$refs.ntslTitle.focus();
            const rowItem= window.SBGrid3.getRow(this.ntslGridObject, item._key_);
            const column = window.SBGrid3.getColumnByField(this.ntslGridObject, 'prdctItemNm');

            window.SBGrid3.moveFocus(this.ntslGridObject, rowItem, column);
          }

          return "판매량(+폐기량)이 생산량(+이월량)보다 초과할 수 없습니다.";
        }
      }

      const invalidItemForPrdctLcp = allNtslItems.find(item => item.prdqty > 0 && !item.prdctLcp);
      if (invalidItemForPrdctLcp) {
        if (focusOnError) {
          this.$refs.ntslTitle.focus();
          const rowItem= window.SBGrid3.getRow(this.ntslGridObject, invalidItemForPrdctLcp._key_);
          const column = window.SBGrid3.getColumnByField(this.ntslGridObject, 'prdctLcp');

          window.SBGrid3.moveFocus(this.ntslGridObject, rowItem, column);
          window.SBGrid3.columnEditable(this.ntslGridObject, invalidItemForPrdctLcp._key_, column, true);
        }

        return "생산량이 있는 경우 저위발열량은 필수 입력입니다.";
      }

      const invalidItemForNtslSeCd = allNtslItems.find(item => item.prdctSumNsqty > 0 && !item.ntslSeCd);
      if (invalidItemForNtslSeCd) {
        if (focusOnError) {
          this.$refs.ntslTitle.focus();
          const rowItem= window.SBGrid3.getRow(this.ntslGridObject, invalidItemForNtslSeCd._key_);
          const column = window.SBGrid3.getColumnByField(this.ntslGridObject, 'ntslSeCd');

          window.SBGrid3.moveFocus(this.ntslGridObject, rowItem, column);
          window.SBGrid3.columnEditable(this.ntslGridObject, invalidItemForNtslSeCd._key_, column, true);
        }

        return "판매량이 있는 경우 판매구분을 선택해주세요.";
      }

      const invalidItemForNtslPrc = allNtslItems.find(item => item.ntslSeCd === "01" && !item.prdctNtslPrc);
      if (invalidItemForNtslPrc) {
        if (focusOnError) {
          this.$refs.ntslTitle.focus();
          const rowItem= window.SBGrid3.getRow(this.ntslGridObject, invalidItemForNtslPrc._key_);
          const column = window.SBGrid3.getColumnByField(this.ntslGridObject, 'prdctNtslPrc');

          window.SBGrid3.moveFocus(this.ntslGridObject, rowItem, column);
          window.SBGrid3.columnEditable(this.ntslGridObject, invalidItemForNtslPrc._key_, column, true);
        }

        return "판매구분이 '유상'인 경우, 제품가격은 필수 입력입니다.";
      }

      return "";
    },
    checkMdfcnPrm(prfmncSttsCd) {
      return new Promise((resolve, reject) => {
        const allNtslItems = window.SBGrid3.getAllItems(this.ntslGridObject);
        const prdctSumCrfqty = this.sanitizeFloatingPointError(allNtslItems.reduce((sum, item) => {
          return sum + (item.prdctCrfqty || 0);
        }, 0));

        const totalPrdctCrfqty = this.sanitizeFloatingPointError(allNtslItems.reduce((sum, item) => sum + (item.prdctCrfqty || 0), 0));
        const totalPrdqty = this.sanitizeFloatingPointError(allNtslItems.reduce((sum, item) => sum + (item.prdqty || 0), 0));
        const totalPrdctSumNsqty = this.sanitizeFloatingPointError(allNtslItems.reduce((sum, item) => sum + (item.prdctSumNsqty || 0), 0));
        const totalPrdctDscdCrtqty = this.sanitizeFloatingPointError(allNtslItems.reduce((sum, item) => sum + (item.prdctDscdCrtqty || 0), 0));
        const prdctSumRmnqty = utils.round2(totalPrdctCrfqty + totalPrdqty - totalPrdctSumNsqty - totalPrdctDscdCrtqty);

        this.$apiCall.post(
            API.MANUFACTURE_PERFORMANCE_CHANGE_CHECK,
            {
              bplcId: this.prfmncBasicInfo.bplcId,
              prfmncYm: this.prfmncBasicInfo.prfmncYr + this.prfmncBasicInfo.prfmncMm,
              wtCrfqty: this.mnftrPrfmncInfo.wtCrfqty,
              wtRmnqty: this.mnftrPrfmncInfo.wtRmnqty,
              prdctSumCrfqty: prdctSumCrfqty,
              prdctSumRmnqty: prdctSumRmnqty,
              updatePrfmncSttsCd: prfmncSttsCd,
            },
            (data) => {
              resolve(data.result);
            },
            () => {
              alert("제출에 실패하였습니다.");
              reject();
            }
        );
      });
    },
    saveConfirmed(prfmncSttsCd, changeList = []) {
      /*
      const { updated: rawmtrlInputUpdated } = window.SBGrid3.getSaveData(this.rawmtrlInputGridObject, false, true, false);
      const { updated: prdctnUpdated } = window.SBGrid3.getSaveData(this.prdctnGridObject, false, true, false);
      const { updated: mnftrNtslPrfmncDtlUpdated } = window.SBGrid3.getSaveData(this.ntslDtlGridObject, false, true, false);
      const { updated: mnftrNtslPrfmncUpdated } = window.SBGrid3.getSaveData(this.ntslGridObject, false, true, false);

      const param = {
        prfmncId: this.prfmncBasicInfo.prfmncId,
        bplcId: this.prfmncBasicInfo.bplcId,
        prfmncYm: this.prfmncBasicInfo.prfmncYr + this.prfmncBasicInfo.prfmncMm,
        prfmncSttsCd,
        wtCrynList: this.wtCrynList.filter(item => item.status),
        wtDscdCrtqty: this.mnftrPrfmncInfo.wtDscdCrtqty || 0,
        mnftrInputPrfmncList: rawmtrlInputUpdated,
        mnftrPrdctnPrfmncList: prdctnUpdated,
        mnftrNtslPrfmncDtlList: mnftrNtslPrfmncDtlUpdated,
        mnftrNtslPrfmncList: mnftrNtslPrfmncUpdated,
        changeList,
      };
      */
      /* 2025.02.03 미가동시설도 실적등록할 수 있도록 디폴트로 조회된 0값도 저장 되도록 수정  */
      const param = {
        prfmncId: this.prfmncBasicInfo.prfmncId,
        bplcId: this.prfmncBasicInfo.bplcId,
        prfmncYm: this.prfmncBasicInfo.prfmncYr + this.prfmncBasicInfo.prfmncMm,
        prfmncSttsCd,
        wtCrynList: this.wtCrynList,
        wtDscdCrtqty: this.mnftrPrfmncInfo.wtDscdCrtqty || 0,
        mnftrInputPrfmncList: window.SBGrid3.getAllItems(this.rawmtrlInputGridObject),
        mnftrPrdctnPrfmncList: window.SBGrid3.getAllItems(this.prdctnGridObject),
        mnftrNtslPrfmncDtlList: window.SBGrid3.getAllItems(this.ntslDtlGridObject),
        mnftrNtslPrfmncList: window.SBGrid3.getAllItems(this.ntslGridObject),
        changeList,
      };

      const prfmncSttsNm = prfmncSttsCd === "SUB" ? "제출" : "저장";

      this.$apiCall.post(
          API.MANUFACTURE_PERFORMANCE,
          param,
          (data) => {
            alert(`${prfmncSttsNm}이 완료되었습니다.`);
            storeSwitch.off('SdflMnfrtPrfmncChgDscntPopup');

            if (prfmncSttsCd === "SUB") {
              this.toList();
            } else {
              this.$router.push({path : "/prfmnc-rpt/dtl/SdflMnfrt", query: {prfmncId: data.result.prfmncId}})
                  .then(() => {
                    location.reload();
                  })
                  .catch(() => {
                    console.error("Navigation Error");
                  });
            }
          },
          () => {
            alert(`${prfmncSttsNm}에 실패하였습니다.`);
          }
      );
    },
    dmndRtrcnCmptn() {
      location.reload();
    },
    openExcelUploadPopup() {
      if (!this.prfmncBasicInfo || !this.prfmncBasicInfo.bplcId || !this.prfmncBasicInfo.prfmncYr) {
        alert("실적 기본정보가 없습니다. 관리자에게 문의해 주세요.");
        return;
      }

      const param = {
        bplcId: this.prfmncBasicInfo.bplcId,
        prfmncYm: this.prfmncBasicInfo.prfmncYr + this.prfmncBasicInfo.prfmncMm
      };

      storeSwitch.on('MnfrtCrynPrcsPopup', param);
    },
    crynPrcsAplcn(data) {
      const uniqueCtpvStdgCdSet = new Set(data.wtCrynList.map(wtCryn => wtCryn.ctpvStdgCd));
      this.wtCrynList.forEach(wtCrynItem => {
        if (uniqueCtpvStdgCdSet.has(wtCrynItem.ctpvStdgCd)) {
          wtCrynItem.status = "D";
        }
      });

      data.wtCrynList.forEach(wtCryn => {
        const matchingItem = this.wtCrynList.find(wtCrynItem =>
            wtCrynItem.ctpvStdgCd === wtCryn.ctpvStdgCd &&
            wtCrynItem.wtItemCd === wtCryn.wtItemCd &&
            wtCrynItem.srfMnftrMtralCd === wtCryn.srfMnftrMtralCd &&
            wtCrynItem.status !== "D"
        );

        if (!matchingItem) {
          const newItem = {
            ...wtCryn,
            status: "I"
          };
          this.wtCrynList.push(newItem);
        }
      });

      data.rawmtrlCrynList.forEach(rawmtrlCryn => {
        const rawmtrlItem = this.rawmtrlCrynList.find(rawmtrlItem =>
            rawmtrlItem.ctpvStdgCd === rawmtrlCryn.ctpvStdgCd
        );

        if (rawmtrlItem && rawmtrlItem[rawmtrlCryn.wtItemCd] !== undefined) {
          rawmtrlItem[rawmtrlCryn.wtItemCd] = rawmtrlCryn.wtCryqty;
        }
      });

      window.SBGrid3.setClientData(this.rawmtrlCrynGridObject, this.rawmtrlCrynList);

      this.mnftrPrfmncInfo.wtSumCryqty = this.sanitizeFloatingPointError(this.wtCrynList
          .filter(item => item.status !== "D")
          .reduce((sum, item) => sum + (parseFloat(item.wtCryqty) || 0), 0));

      this.setWtRmnqty();
      this.setWtCrynGridData();

      storeSwitch.off('MnfrtCrynPrcsPopup');
    },
    formatNumber(number) {
      return utils.formatNumberWithComma(number);
    },

    // 소수점 둘쨰자리 오류 수정
    // 부동소수점(floating point) 연산의 정밀도 문제 스크립트 고유 버그
    sanitizeFloatingPointError(value){
      if (typeof value !== "number") return value; // 숫자가 아닐 경우 반환

      // 소수점 2자리까지만 유지하고 반올림하여 부동소수점 오차 제거
      return parseFloat((Math.round(value * 100) / 100).toFixed(2));

    }
  }
}
</script>

<style scoped>
  .grid_box { margin-bottom: 5px; }
</style>