<template>
  <div v-show="isOn" class="modal visible">
    <div class="popup_box" style="width: 70%; max-width: 70%" :style="{height: bodyList.length > 0? '90%' : null}">
      <h1 class="popup_title">시설 변경 이력</h1>
      <div>
        <button type="button" class="modal_close" @click.prevent="hide">팝업닫기</button>
      </div>
      <div class="popup_cont" style="max-height: calc(90% - 15px);overflow: hidden;overflow-y: auto;">
        <div class="board_info">
          <h3 class="title1">시설 변경 이력</h3>
        </div>
        <!-- 테이블 컨텐츠 영역 -->
        <div class="board_info" style="margin-bottom: 0px;">
          <p class="page" >
            <span class="total">전체 <strong>{{ this.hstryCount }}</strong></span>
            <span>페이지 <strong>{{ this.searchParam.page }}/{{ this.maxPage === 0 ? 1 : this.maxPage }}</strong></span>
          </p>
        </div>
        <div class="board_list">
          <table style="table-layout: fixed;">
            <caption>시설 변경 이력 _ 수정일시, 업체정보로 구분</caption>
            <colgroup>
              <col style="width:180px">
              <col style="width:25%">
              <col style="width:25%">
              <col style="width:25%">
              <col style="width:25%">
              <col style="width:25%">
            </colgroup>
            <thead>
            <tr v-for="(item, index) in headerList" :key="index">
              <th scope="col">수정일시</th>
              <th scope="col">{{item.col1}}</th>
              <th scope="col">{{item.col2}}</th>
              <th scope="col">{{item.col3}}</th>
              <th scope="col">{{item.col4}}</th>
              <th scope="col">{{item.col5}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="bodyList.length <= 0" >
              <td colspan="6">
                <div style="display: flex; justify-content: center;">
                  <div class="no-data-message">조회된 이력 데이터가 없습니다.</div>
                </div>
              </td>
            </tr>
            <tr v-else v-for="(item, index) in bodyList" :key="index">
              <td>{{ item.name }}</td>
              <td class="txt_al" style="word-wrap: break-word;"><span :class="{txt_point: isDifferent(item, 1)}">{{ item.col1 }}</span></td>
              <td class="txt_al" style="word-wrap: break-word;"><span :class="{txt_point: isDifferent(item, 2)}">{{ item.col2 }}</span></td>
              <td class="txt_al" style="word-wrap: break-word;"><span :class="{txt_point: isDifferent(item, 3)}">{{ item.col3 }}</span></td>
              <td class="txt_al" style="word-wrap: break-word;"><span :class="{txt_point: isDifferent(item, 4)}">{{ item.col4 }}</span></td>
              <td class="txt_al" style="word-wrap: break-word;"><span :class="{txt_point: isDifferent(item, 5)}">{{ item.col5 }}</span></td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- 그리드 페이징 영역 -->
        <insp-pagination :total="hstryCount" :size="searchParam.size" v-model:page="searchParam.page"
                         v-model:maxPage="maxPage" @clickedPage="search"></insp-pagination>

        <div class="btn_area center">
          <br-button label="닫기" class="btn sm tertiary" @click="hide" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";
import inspPagination from "@/views/sfli/components/inspPagination.vue";

const API = {
  FCLT_CHG_HSTRY: "/bizfacility/fclt-chg-hstry",
};

const PAGE_SIZE = 5;

export default {
  components: {inspPagination},
  props: {
    bplcId: {type: String, required: true},
    fcltSqno: {type: Number, required: false},
  },
  data() {
    return {
      isOn: false,
      hstryList: [],
      hstryCount: 0,
      fileListTaskSeCd: '',
      fileListGroupId: '',
      maxPage: 1,
      searchParam: {
        page: 1,
        size: 5,
      },
    };
  },
  computed: {
    headerList() {
      return this.hstryList.filter(item => item.name === '수정일시')
    },
    bodyList() {
      return this.hstryList.filter(item => item.name !== '수정일시')
    },
  },
  watch: {
    isOn(newVal) {
      if(newVal) this.search();
    }
  },
  created() {
    storeSwitch.setBulb('fcltChgHstryPopup', this);
  },
  beforeMount() {
  },
  mounted() {
    this.initialize();
  },
  beforeUnmount() {
  },
  methods: {
    /** 팝업 Show */
    show() {
      this.isOn = true;
    },
    /** 팝업 Hide */
    hide() {
      this.isOn = false;
    },
    initialize() {
      // ToDo..
    },
    search() {
      this.$apiCall.get(
          API.FCLT_CHG_HSTRY,
          {
            bplcId: this.bplcId,
            fcltSqno: this.fcltSqno,
            page: this.searchParam.page,
            size: PAGE_SIZE,
          },
          result => {
            if(result.result.list) {
              this.hstryList = result.result.list;
              this.hstryCount = result.result.total;
            } else {
              this.reset();
            }
          },
          () => {
            this.reset();
            alert("데이터 조회에 실패하였습니다.")
          }
      );
    },
    reset() {
      this.hstryList = [];
      this.hstryCount = 0;
    },
    /** 업체 상세 이력 변겅 데이터 강조 표시 */
    isDifferent(item, colNumber) {
      if(colNumber === 1) return item.col1 !== item.col2;
      if(colNumber === 2) return item.col2 !== item.col3;
      if(colNumber === 3) return item.col3 !== item.col4;
      if(colNumber === 4) return item.col4 !== item.col5;
      if(colNumber === 5) return false
    },
    formatNumber(value) {
      if (!value) return '';
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  }
}
</script>

<style scoped>
.no-data-message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  margin: 20px 0px;
  border-radius: 10px;
  background-color: #f7f7f7;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 350px;
}

</style>