import { reactive } from "vue";
import apiCall from "@/js/api-call";

const API = {
  COM_DTL_CD : "/code/detail"
}

const CODE = {
  PROHIBIT: "COM502",
}

export const storeProhibit = reactive({
    prohibits: [],

    setProhibit() {
        return new Promise((resolve) => {
            apiCall.get(API.COM_DTL_CD, {groupCd : CODE.PROHIBIT, useYn : 'Y'}, data => {
                if (data.result) {
                    this.prohibits = data.result.map(item => item.dtlCdNm);
                    resolve();
                }
            }, (error) => {
                this.prohibits = [];
                alert(error.message);
                resolve();
            });
        });
    },
    validate(value) {
        return !this.prohibits.some(word => value.toLowerCase().includes(word.toLowerCase()));
    }
})