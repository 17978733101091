<template>
  <head>
    <meta name="description" content="이 페이지는 메인화면입니다.">
  </head>
  <div id="container" class="main">
    <main id="contents">
      <div class="visual_area">
        <div class="visual_swiper swiper-fade swiper-initialized swiper-horizontal swiper-watch-progress swiper-backface-hidden">
          <swiper
              :modules="modules"
              :space-between="30"
              :centered-slides="true"
              :loop="true"
              :autoplay="swiper.autoPlay"
              @activeIndexChange="onSwiperChanged"
              @init="onInitSwiper"
          >
            <swiper-slide class="slide">
              <div class="in">
                <div class="text">
                  <p class="tit">폐자원에너지 <span>종합정보관리시스템</span></p>
                  <p class="txt">「자원의 절약과 재활용촉진에 관한 법률」에 따라 폐자원에너지화 <br>시설의 현황과 에너지 생산량을 체계적으로 관리하여 <br>자원순환사회 구축을 지원하는 종합정보시스템입니다.</p>
                  <a href="/wstrsc-ene-intro/sfl/SflGd" class="btn tertiary">고형연료 자세히 보러가기</a>
                </div>
                <div class="im">
                  <img src="@/assets/images/main/img_visual01.png" alt="이미지">
                </div>
              </div>
            </swiper-slide>
            <swiper-slide class="slide">
              <div class="in">
                <div class="text">
                  <p class="tit">버려진 자원, <span>새로운 에너지의 시작</span></p>
                  <p class="txt"><strong>품질등급제 - </strong> 고형연료제품 품질(확인)검사, 품질표시검사의 결과를 활용하여 <br>품질등급을 판정하고, 고품질의 고형연료제품 제조ㆍ사용을 유도하는 등 <br>고형연료제품의 환경적 안정성을 도모하기 위한 제도입니다.
                  </p>
                  <a href="/qlty-grd/QltyGrdIntro" class="btn tertiary">품질등급제 자세히 보러가기</a>
                </div>
                <div class="im">
                  <img src="@/assets/images/main/img_visual02.png" alt="이미지">
                </div>
              </div>
            </swiper-slide>
            <swiper-slide class="slide">
              <div class="in">
                <div class="text">
                  <p class="tit">버려지는 폐기물의 가치를 <span>에너지로 재창조!</span></p>
                  <p class="txt"><strong>인센티브제도 - </strong> 최우수 등급 70% 이상 또는 최우수·우수 등급 90% 이상의 <br>고형연료제품을 사용하는 시설에 대해 해당분기 품질확인검사를 면제하는 제도입니다.</p>
                  <a href="/qlty-grd/IcntvTrgtInq" class="btn tertiary">인센티브제도 자세히 보러가기</a>
                </div>
                <div class="im">
                  <img src="@/assets/images/main/img_visual03.png" alt="이미지">
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>

        <div class="inner">
          <div class="swiper_controls">
            <div class="swiper-pagination swiper-pagination-fraction swiper-pagination-horizontal"><span class="swiper-pagination-current">{{ this.swiper.pageIndex }}</span> / <span class="swiper-pagination-total">3</span></div>
            <div class="swiper-scrollbar swiper-scrollbar-horizontal"><div class="swiper-scrollbar-drag" style="transform: translate3d(80px, 0px, 0px); width: 40px; transition-duration: 0ms;"></div></div>
            <button type="button" :class="{'btn-stop': swiper.isPlay, 'btn-play': !swiper.isPlay}" @click.prevent="onClickBtnStopOfVisualArea"><span class="sr_only">{{ swiper.isPlay ? '정지' : '시작'}}</span></button>
          </div>
        </div>
      </div>

      <div class="inner">
        <div class="service_list" v-if="['', '01-01', '01-02', '01-03'].includes(param.bplcKndCd)">
          <a href="javascript:void(0);" role="link" class="bg01" @click.prevent="moveLink('/sfli/FcltInspAply')">
            <i></i>
            <strong>정기검사</strong>
            <span>고형연료제품 제조·사용시설의 적합성을 확인하는 법정검사입니다.</span>
            <span class="btn xsm primary line f_black">시설관리 바로가기</span>
          </a>
          <a href="javascript:void(0);" role="link" class="bg02" @click.prevent="moveLink('/sfli/QltyInspAply')">
            <i></i>
            <strong>품질검사</strong>
            <span>고형연료제품의 품질기준 부합여부를 판단하기 위해 실시하는 검사입니다.</span>
            <span class="btn xsm primary line f_black">품질검사 신청하기</span>
          </a>
          <a href="javascript:void(0);" role="link" class="bg03" @click.prevent="moveLink('/prfmnc-rpt/PrfmncRpt')">
            <i></i>
            <strong>실적보고</strong>
            <span>폐자원에너지와 고형연료제품의 월별 실적을 익월 10일까지 제출합니다.</span>
            <span class="btn xsm primary line f_black">실적보고 등록하기</span>
          </a>
          <a href="javascript:void(0);" role="link" class="bg04" @click.prevent="moveLink('/qlty-grd/QltyGrdInq')">
            <i></i>
            <strong>품질등급</strong>
            <span>고형연료 사용시설에서 필요로한 품질분석 및 시료채취 등의 업무</span>
            <span class="btn xsm primary line f_black">품질등급 신청하기</span>
          </a>
          <a href="javascript:void(0);" role="link" class="bg05" @click.prevent="moveLink('/my-office/BizFacilityInfo')">
            <i></i>
            <strong>사업장정보</strong>
            <span>사업장과 시설의 변경된 정보를 신속히 입력해 주세요.</span>
            <span class="btn xsm primary line f_black">사업장정보 바로가기</span>
          </a>
          <a href="javascript:void(0);" role="link" class="bg06" @click.prevent="onlinePopup">
            <i></i>
            <strong>온라인매뉴얼</strong>
            <span>시스템 사용이 어려우신가요? 온라인 매뉴얼을 확인하세요.</span>
            <span class="btn xsm primary line f_black">매뉴얼 보기</span>
          </a>
        </div>

        <!-- 고형연료 외 바이오 매립가스 시설 등-->
        <div class="service_list" v-if="!['', '01-01', '01-02', '01-03'].includes(param.bplcKndCd)">
          <a href="javascript:void(0);" role="link" class="bg07" @click.prevent="moveLink('/prfmnc-rpt/CrynSplyBzentyStts')">
            <i></i>
            <strong>반입/판매(공급) 업체현황</strong>
            <span>반입/판매(공급) 업체 현황을 관리합니다.</span>
            <span class="btn xsm primary line f_black">업체현황 바로가기</span>
          </a>
          <a href="javascript:void(0);" role="link" class="bg03" @click.prevent="moveLink('/prfmnc-rpt/PrfmncRpt')">
            <i></i>
            <strong>실적보고</strong>
            <span>폐자원에너지와 고형연료제품의 월별 실적을 익월 10일까지 제출합니다.</span>
            <span class="btn xsm primary line f_black">실적보고 등록하기</span>
          </a>
          <a href="javascript:void(0);" role="link" class="bg08" @click.prevent="moveLink('/prfmnc-rpt/PrfmncRptStts')">
            <i></i>
            <strong>실적보고현황</strong>
            <span>생산/판매량 정보의 실적보고한 현황을 조회합니다.</span>
            <span class="btn xsm primary line f_black">실적보고현황 바로가기</span>
          </a>
          <a href="javascript:void(0);" role="link" class="bg09" @click.prevent="moveLink('/prfmnc-rpt/PrfmncRpt')">
            <i></i>
            <strong>실적입력허용요청</strong>
            <span>실적을 수정할 수 있게 관리자에 권한을 요청합니다.</span>
            <span class="btn xsm primary line f_black">실적입력허용 요청하기</span>
          </a>
          <a href="javascript:void(0);" role="link" class="bg05" @click.prevent="moveLink('/my-office/BizFacilityInfo')">
            <i></i>
            <strong>사업장정보</strong>
            <span>사업장과 시설의 변경된 정보를 신속히 입력해 주세요.</span>
            <span class="btn xsm primary line f_black">사업장정보 바로가기</span>
          </a>
          <a href="javascript:void(0);" role="link" class="bg06" @click.prevent="onlinePopup">
            <i></i>
            <strong>온라인매뉴얼</strong>
            <span>시스템 사용이 어려우신가요? 온라인 매뉴얼을 확인하세요.</span>
            <span class="btn xsm primary line f_black">매뉴얼 보기</span>
          </a>
        </div>
        <!-- //고형연료 외 -->

        <div class="notice_area">
          <div class="tab_area">
            <ul class="tab">
              <li aria-controls="bbsTabpanel01" :class="{active: bbsTypeIndex === 1}"><button type="button" class="btn_tab" @click.prevent="bbsTypeIndex = 1">공지사항<i v-if="bbsTypeIndex === 1" class="sr_only">선택됨</i></button></li>
              <li aria-controls="bbsTabpanel02" :class="{active: bbsTypeIndex === 2}"><button type="button" class="btn_tab" @click.prevent="bbsTypeIndex = 2">자료실<i v-if="bbsTypeIndex === 2" class="sr_only">선택됨</i></button></li>
            </ul>
            <div class="tab_conts_wrap">
              <section v-show="bbsTypeIndex === 1" id="bbsTabpanel01" class="tab_conts" :class="{active: bbsTypeIndex === 1}">
                <h2 class="sr_only">공지사항</h2>
                <ul>
                  <li v-if="ntcMttrList.length > 0">
                    <span class="date"><em>{{ ntcMttrList[0].regYear }}</em>{{ ntcMttrList[0].regMd }}</span>
                    <a :href="`/cust-sprt/PostDetail/1?pstSn=${ntcMttrList[0].pstSn}`">
                      <span class="tit">{{ ntcMttrList[0].pstNtcYn }}{{ ntcMttrList[0].pstTtl }}</span>
                      <span class="txt">{{ ntcMttrList[0].bbsPstCn }}</span>
                    </a>
                  </li>
                  <li v-for="(ntcMttrList, index) in ntcMttrList.slice(1)" :key="index">
                    <a :href="`/cust-sprt/PostDetail/1?pstSn=${ntcMttrList.pstSn}`" class="tit" style="max-width: 420px;">{{ ntcMttrList.pstNtcYn }}{{ ntcMttrList.pstTtl }}</a>
                    <span class="date">{{ ntcMttrList.regDt }}</span>
                  </li>
                </ul>
                <a href="/cust-sprt/PostManage/1" class="btn_more" title="공지사항 목록 페이지로 이동">더보기</a>
              </section>

              <section v-show="bbsTypeIndex === 2" id="bbsTabpanel02" class="tab_conts" :class="{active: bbsTypeIndex === 2}">
                <h2 class="sr_only">자료실</h2>
                <ul>
                  <li v-if="dataList.length > 0">
                    <span class="date"><em>{{ dataList[0].regYear }}</em>{{ dataList[0].regMd }}</span>
                    <a :href="`/cust-sprt/PostDetail/2?pstSn=${dataList[0].pstSn}`">
                      <span class="tit">{{ dataList[0].pstNtcYn }}{{ dataList[0].pstTtl }}</span>
                      <span class="txt">{{ dataList[0].bbsPstCn }}</span>
                    </a>
                  </li>
                  <li v-for="(dataList, index) in dataList.slice(1)" :key="index">
                    <a :href="`/cust-sprt/PostDetail/2?pstSn=${dataList.pstSn}`" class="tit" style="max-width: 420px;">{{ dataList.pstNtcYn }}{{ dataList.pstTtl }}</a>
                    <span class="date">{{ dataList.regDt }}</span>
                  </li>
                </ul>
                <a href="/cust-sprt/PostManage/2" class="btn_more" title="자료실 목록 페이지로 이동">더보기</a>
              </section>
            </div>
          </div>

          <div class="popupzone">
            <h2 class="title">알림판</h2>
            <swiper
                v-if="popupZone.length > 0"
                :modules="modules"
                :space-between="30"
                :centered-slides="true"
                :loop="true"
                :autoplay="
                swiper.autoPlay"
                @activeIndexChange="onAlarmSwiperChanged"
                @init="onInitAlarmSwiper"
            >
              <swiper-slide v-for="(zone, index) in popupZone" :key="index" class="slide">
                <div class="popup_swiper swiper-initialized swiper-horizontal swiper-backface-hidden">
                    <a v-if="zone.popupLnkgSeCd === '20'" :href="zone.popupUrlAddr && zone.popupUrlAddr !== '' ? zone.popupUrlAddr : '#'">
                      <img :src="getPopupImg(zone.atchFiles)" alt="팝업 이미지" style="width: 100%; height: auto;" />
                    </a>
                    <a v-if="zone.popupLnkgSeCd ==='10'" @click.prevent="popupSelect(zone)">
                      <img :src="getPopupImg(zone.atchFiles)" alt="팝업 이미지" style="width: 100%; height: auto;" />
                    </a>
                </div>
              </swiper-slide>
            </swiper>
            <div v-else class="empty-message">
              <p>준비중입니다.</p>
            </div>
            <div class="swiper_controls">
              <div class="swiper-pagination swiper-pagination-fraction swiper-pagination-horizontal">
                <span class="swiper-pagination-current">{{ this.swiperAlarm.pageIndex }}</span> 
                / 
                <span class="swiper-pagination-total">{{this.popupZone.length}}</span>
              </div>
              <button type="button" :class="{'btn-stop': swiper.isPlay, 'btn-play': !swiperAlarm.isPlay}" @click.prevent="onClickBtnStopOfVPopupZone">
                <span class="sr_only">{{ swiperAlarm.isPlay ? '정지' : '시작'}}</span>
              </button>
              <button type="button" class="swiper-button-prev" tabindex="0" aria-label="Previous slide" aria-controls="swiper-wrapper-1" id="swiper-wrapper-1"
              @click.prevent="goPreSlide">
                <span class="sr_only">이전</span>
              </button>
              <button type="button" class="swiper-button-next" tabindex="0" aria-label="Next slide" aria-controls="swiper-wrapper" id="swiper-wrapper" @click.prevent="goNextSlide">
                <span class="sr_only">다음</span>
              </button>
            </div>
          </div>
        </div>

        <div class="banner_area">
          <div class="banner_swiper swiper-initialized swiper-horizontal swiper-backface-hidden">
            <div class="swiper-wrapper" id="swiper-wrapper-5a42cda9c6b3ba710" aria-live="polite">
              <a href="https://www.recycling-info.or.kr/opeweb/" class="swiper-slide" target="_blank" title="새창열림" aria-label="1 / 6" style="width: 165.714px; margin-right: 57px;"><img src="@/assets/images/main/img_banner03.gif" alt="환경부 한국환경공단 폐기물처리시설 운영평가시스템"></a>
              <a href="https://www.allbaro.or.kr/siren" class="swiper-slide" target="_blank" title="새창열림" aria-label="2 / 6" style="width: 165.714px; margin-right: 57px;"><img src="@/assets/images/main/img_banner04.gif" alt="폐기물처리 현장정보 관리시스템"></a>
              <a href="http://me.go.kr/" class="swiper-slide" target="_blank" title="새창열림" aria-label="3 / 6" style="width: 165.714px; margin-right: 57px;"><img src="@/assets/images/main/img_banner05.gif" alt="환경부"></a>
              <a href="https://www.recycling-info.or.kr/rrs/main.do" class="swiper-slide" target="_blank" title="새창열림" aria-label="4 / 6" style="width: 165.714px; margin-right: 57px;"><img src="@/assets/images/main/img_banner08.gif" alt="자원순환마루"></a>
              <a href="http://www.kora.or.kr/front/main.do" class="swiper-slide" target="_blank" title="새창열림" aria-label="5 / 6" style="width: 165.714px; margin-right: 57px;"><img src="@/assets/images/main/img_banner09.gif" alt="한국순환자원유통지원센터"></a>
              <a href="https://www.re.or.kr/main.do" class="swiper-slide" target="_blank" title="새창열림" aria-label="6 / 6" style="width: 165.714px;"><img src="@/assets/images/main/img_banner10.gif" alt="순환자원정보센터"></a>
            </div>
            <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
          <div class="swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal swiper-pagination-lock"><span class="swiper-pagination-bullet swiper-pagination-bullet-active" aria-current="true"></span></div>
        </div>

        <div class="chat_bot">
          <a href="javascript:void(0);" role="link" @click="chatBot">
            <span class="txt"><strong>도움이 필요하실까요?</strong></span>
            <span class="bot">e 챗봇</span>
          </a>
        </div>
      </div>
    </main>
  </div>

  <!-- 메인화면 공지 팝업 -->
  <div v-if="isVisible" class="modal visible">
    <template v-for="(popup, index) in popupNtc" :key="index">
      <div v-if="!popup.isClosed" 
      id="noticePopup" class="modal_box mainNoti urgent" tabindex="0"
          :style="{
            width: popup.popupWdthSz ? popup.popupWdthSz + 'px' : '300px',
            height: popup.popupVrtcSz ? popup.popupVrtcSz + 'px' : '400px',
            left: popup.popupXcrd ? popup.popupXcrd + 'px' : '100px',
            top: popup.popupYcrd ? popup.popupYcrd + 'px' : '100px',
            position: 'fixed'
          }"
      >
        <div class="modal_content">
          <a :href="popup.popupUrlAddr" target="_blank">
            <!-- 콘텐츠 들어가는 곳 -->
            <div class="noti_cont">
                <div class="title">
                  <i><img src="@/assets/images/main/img_noti1.png" alt="이미지"></i>
                    <p>{{ popup.pstTtl }}</p>
                </div>
                <div class="cont" :id="popup.pstSn === 3012? 'new_sys_noti' : ''">
                  <div v-html="popup.bbsPstCn"></div>
                  <div v-if="popup.pstSn === 3012" class="link_to_manual" @click="linkToSomewhere('MANUAL', popup)"></div>
                  <div v-if="popup.pstSn === 3012" class="link_to_faq" @click="linkToSomewhere('FAQ', popup)"></div>
                  <div v-if="popup.pstSn === 3012" class="link_to_chatbot" @click="linkToSomewhere('CHATBOT', popup)"></div>
                  <div v-if="popup.pstSn === 3012" class="link_to_helpdesk" @click="linkToSomewhere('HELPDESK', popup)"></div>
                </div>
                <!-- <p class="bottom"><img src="@/assets/images/common/h1_logo.png" alt="폐자원에너지 종합정보관리시스템" class="w55p"></p> -->
            </div>
          </a>
          <div class="chk_sorting" style="width: 200px;">
            <div class="form_check">
              <input v-show="false" type="checkbox" v-bind:id="'chk_' + popup.pstSn" v-model="popup.isChecked" @change="checkboxChg(popup)">
              <label :for="'chk_' + popup.pstSn">오늘 하루 열지 않음</label>
            </div>
          </div>
        </div>
        <button type="button" class="modal_close" @click="hide(popup)">레이어 팝업 닫기</button>
      </div>
    </template>
  </div>
  <!-- 설문조사 팝업 -->
  <div v-if="this.userinfo.userId" >
    <div v-if="isVisibleSurvey" class="modal visible">
      <div id="popup" style="position: absolute; top: 100px; width:40%;">
        <h1 class="popup_title">설문조사 참여</h1>
        <div class="popup_cont">
          <div class="survery_area">
            <dl v-for="( item, index ) in srvyQitemList" :key="index">
              <dt>
                <span class="num">Q{{ index + 1 }}</span>
                <!--srvyTypeCd( 설문유형 = 1:라디오버튼, 2:체크박스, 3:입력 )에 따른 ui변경-->
                <span v-if="Number( item.srvyTypeCd ) === 1">{{ item.srvyQitemCn }}</span>
                <span v-if="Number( item.srvyTypeCd ) === 2">{{ item.srvyQitemCn }}(다중 선택 가능)</span>
                <span v-if="Number( item.srvyTypeCd ) === 3">{{ item.srvyQitemCn }}(최대 300자)</span>
              </dt>
              <dd>
                <template v-if="Number( item.srvyTypeCd ) === 1">
                  <div class="chk_area" v-for="( exmp, idx ) in getExmpByQitemSqno( item.srvyQitemSqno )" :key="'radio_' + idx">
                    <div class="form_check">
                      <input type="radio" 
                        :name="'survey_chk_' + item.srvyQitemSqno"  
                        :id="'survey_chk_' + item.srvyQitemSqno + '_' + idx" 
                        :value="exmp.scr"
                        @change="updateSrvyTypeCd1Val(item.srvySn + '_' + item.srvyQitemSqno, exmp.srvyQitemExmpSqno, $event)" >
                      <label :for="'survey_chk_' + item.srvyQitemSqno + '_' + idx">{{ exmp.srvyExmpCn }}</label>
                    </div>
                  </div>
                </template>
                <template v-if="Number( item.srvyTypeCd ) === 2">
                  <div class="chk_area" v-for="( exmp, idx ) in getExmpByQitemSqno( item.srvyQitemSqno )" :key="'checkbox_' + idx">
                    <div class="form_check">
                      <input type="checkbox" 
                        :name="'survey_chk_' + item.srvyQitemSqno" 
                        :id="'survey_chk_' + item.srvyQitemSqno + '_' + idx"
                        v-model="surveyPram.responses[ item.srvySn + '_' + item.srvyQitemSqno + '_' + exmp.srvyQitemExmpSqno ]"
                        :true-value="1"
                        :false-value="null"
                      >
                      <label :for="'survey_chk_' + item.srvyQitemSqno + '_' + idx">{{ exmp.srvyExmpCn }}</label>
                    </div>
                  </div>
                </template>
                <template v-if="Number( item.srvyTypeCd ) === 3">
                  <textarea class="form_control" v-model="surveyPram.responses[ `${item.srvySn}_${item.srvyQitemSqno}_opnnCn` ]" placeholder="의견을 작성하세요." maxlength="300"></textarea>
                </template>
              </dd>
            </dl>
          </div>
          <div class="member_wrap" style="margin-top: 40px; font-size: small;">
            <h3 class="agree_title" style="font-size: 1.5rem; padding: 1rem 1.6rem;" @click="togglePrivacyNotice">개인정보 수집 및 이용에 관한 안내
              <span class="txt_point">(필수)</span>
              <span class="toggle_icon" v-if="isPrivacyNoticeVisible"> ▲</span>
              <span class="toggle_icon" v-else> ▼</span>
            </h3>
            <div class="agree_txt" v-if="isPrivacyNoticeVisible">
              <prvc-agre />
            </div>
            <div class="agree_box">
              <span>개인정보 수집 이용에 대해 동의하십니까?</span>
              <div class="chk_area">
                <div class="form_check">
                  <input type="radio" name="agree_chk4" id="agree_chk33" v-model="personalAgree" value="agree" @change="checkAllAgree">
                  <label for="agree_chk33">동의함</label>
                </div>
                <div class="form_check">
                  <input type="radio" name="agree_chk4" id="agree_chk34" v-model="personalAgree" value="disagree" @change="checkAllAgree">
                  <label for="agree_chk34">동의하지않음</label>
                </div>
              </div>
              <p style="font-size: 1.2rem;">
                위의 개인정보 수집·이용에 대한 동의를 거부할 권리가 있습니다. <br>
                다만 동의를 거부하실 경우 서비스 이용이 제한될 수 있습니다.
              </p>
            </div>
          </div>
          <div class="btn_area center">
            <button type="button" class="btn md primary" @click="create()" :auth="[ 'C' ]">설문조사제출</button>
            <button type="button" class="btn md" @click="srvyPopupHide()">닫기</button>
          </div>
        </div>
        <button type="button" class="popup_close" @click="srvyPopupHide()">팝업 닫기</button>
      </div>
    </div>
  </div>

  <!-- s: 온라인 매뉴얼 -->
  <div class="modal visible" id="modal_popup3" v-if="isVisibleOnline">
    <div class="modal_box manual-pop" tabindex="0">
        <div class="modal_content">
            <!-- 콘텐츠 들어가는 곳 -->
            <p class="title">동영상으로 알아보는<strong>폐자원에너지 종합정보관리시스템 <em>온라인 매뉴얼</em></strong><span>동영상을 시청 후 시스템을 보다 쉽고 편리하게 이용해 보세요!</span></p>

            <div class="tab_area">
                <ul class="tab">
                    <li aria-controls="manualTabpanel01" :class="{ active: activeTab === 1 }" @click="setActiveTab(1)">
                      <button type="button" class="btn_tab">시스템소개</button>
                    </li>
                    <li aria-controls="manualTabpanel02" :class="{ active: activeTab === 2 }" @click="setActiveTab(2)">
                      <button type="button" class="btn_tab">사업장·시설관리</button>
                    </li>
                    <li aria-controls="manualTabpanel03" :class="{ active: activeTab === 3 }" @click="setActiveTab(3)">
                      <button type="button" class="btn_tab">정기검사</button>
                    </li>
                    <li aria-controls="manualTabpanel04" :class="{ active: activeTab === 4 }" @click="setActiveTab(4)">
                      <button type="button" class="btn_tab">품질(확인)검사</button>
                    </li>
                    <li aria-controls="manualTabpanel05" :class="{ active: activeTab === 5 }" @click="setActiveTab(5)">
                      <button type="button" class="btn_tab">실적보고</button>
                    </li>
                </ul>

                <div class="tab_conts_wrap">
                    <section v-if="activeTab === 1" id="manualTabpanel01" class="tab_conts active">
                        <div class="mov_area">
                          <video width="100%" controls autoplay :src="sysVideoSrc">
                            동영상을 지원하지 않는 브라우저에서는 해당 텍스트가 표시됩니다.
                          </video>
                        </div>
                    </section>
                    <section v-if="activeTab === 2" id="manualTabpanel02" class="tab_conts">
                        <div class="mov_area">
                          <video width="100%" controls autoplay :src="joinVideoSrc">
                            동영상을 지원하지 않는 브라우저에서는 해당 텍스트가 표시됩니다.
                          </video>
                        </div>
                    </section>
                    <section v-if="activeTab === 3" id="manualTabpanel03" class="tab_conts">
                        <div class="mov_area">
                          <video width="100%" controls autoplay :src="fcltVideoSrc">
                            동영상을 지원하지 않는 브라우저에서는 해당 텍스트가 표시됩니다.
                          </video>
                        </div>
                    </section>
                    <section v-if="activeTab === 4" id="manualTabpanel04" class="tab_conts">
                        <div class="mov_area">
                          <video width="100%" controls autoplay :src="qltyVideoSrc">
                            동영상을 지원하지 않는 브라우저에서는 해당 텍스트가 표시됩니다.
                          </video>
                        </div>
                    </section>
                    <section v-if="activeTab === 5" id="manualTabpanel05" class="tab_conts">
                      <!-- 운영 -->
                      <ul class="result_list" v-if="this.prodYn == 'Y'">
                        <li>
                            <a href="javascript:void(0);">
                              <img src="@/assets/images/main/img_manual01.png" alt="이미지">
                              <br-static-file-download atchFileVldNo="6dfad245-ea96-4a61-a874-27099b7b2e3f" label="고형연료 제조 실적보고 매뉴얼 다운로드"/>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <img src="@/assets/images/main/img_manual02.png" alt="이미지">
                              <br-static-file-download atchFileVldNo="1b1c97a2-a66f-4e4e-8f0c-16f5126cc019" label="고형연료 사용 실적보고 매뉴얼 다운로드"/>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <img src="@/assets/images/main/img_manual03.png" alt="이미지">
                              <br-static-file-download atchFileVldNo="a8ecff91-c15d-4730-8721-bdbe5f91dafe" label="매립가스 실적보고 매뉴얼 다운로드"/>
                            </a>
                            </li>
                          <li>
                            <a href="javascript:void(0);">
                              <img src="@/assets/images/main/img_manual04.png" alt="이미지">
                              <br-static-file-download atchFileVldNo="f1cc0568-205a-4960-a188-601085a5640d" label="바이오가스 실적보고 매뉴얼 다운로드"/>
                            </a>
                          </li>
                      </ul>
                      <!-- 로컬 -->
                      <ul class="result_list" v-if="this.prodYn != 'Y'">
                        <li>
                            <a href="javascript:void(0);">
                              <img src="@/assets/images/main/img_manual01.png" alt="이미지">
                              <br-static-file-download atchFileVldNo="836ca24e-8eb3-46cf-8e48-303b2d62ec5f" label="고형연료 제조 실적보고 매뉴얼 다운로드"/>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <img src="@/assets/images/main/img_manual02.png" alt="이미지">
                              <br-static-file-download atchFileVldNo="2a8eaaf9-c642-4769-a98d-a0aacb97ee60" label="고형연료 사용 실적보고 매뉴얼 다운로드"/>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <img src="@/assets/images/main/img_manual03.png" alt="이미지">
                              <br-static-file-download atchFileVldNo="d402da6d-e566-4cfb-836a-1a8ef163c3d9" label="매립가스 실적보고 매뉴얼 다운로드"/>
                            </a>
                            </li>
                          <li>
                            <a href="javascript:void(0);">
                              <img src="@/assets/images/main/img_manual04.png" alt="이미지">
                              <br-static-file-download atchFileVldNo="64a5588d-7b9f-4b92-b2da-8719dd94cbe1" label="바이오가스 실적보고 매뉴얼 다운로드"/>
                            </a>
                          </li>
                      </ul>
                    </section>
                </div>
            </div>
        </div>
        <button type="button" @click.prevent="onlinePopupClose" class="modal_close">레이어 팝업 닫기</button>
    </div>
  </div>
  <!-- e: 온라인 매뉴얼 -->

  <!-- 품질표시 미등록 관리 공지 팝업 -->
  <div v-if="isVisibleQltyIndctRegMng" class="modal visible">
    <div id="popup" style="position: absolute; top: 100px;">
      <h1 class="popup_title">품질표시검사</h1>
      <div class="popup_cont">
        <div class="survery_area">
          <p>품질표시검사 결과를 등록해 주시기 바랍니다.</p>
          <p v-for="(item, index) in qltyIndctRegMngPopupNtc" :key="index">
            성적서 번호 : {{ item.qltyIndctIssuNo }}
          </p>
          <div class="btn_area center">
            <button class="btn md primary" @click="navigateToQltyIndctReg">품질표시검사 결과 등록화면 이동</button>
          </div>
        </div>
      </div>
      <button type="button" class="popup_close" @click="hideQltyIndctRegMng()">팝업 닫기</button>
    </div>
  </div>

  <!-- 팝업존 팝업 -->
  <div v-if="isVisiblePopupZonePopup" class="modal visible">
    <template v-for="(popup, index) in popupZonePopup" :key="index">
      <div 
      id="zonePopup" class="modal_box mainNoti urgent" tabindex="0"
          :style="{
            width: popup.popupWdthSz ? popup.popupWdthSz + 'px' : '300px',
            height: popup.popupVrtcSz ? popup.popupVrtcSz + 'px' : '400px',
            left: popup.popupXcrd ? popup.popupXcrd + 'px' : '200px',
            top: popup.popupYcrd ? popup.popupYcrd + 'px' : '200px',
            position: 'fixed'
          }"
      >
        <div class="modal_content">
          <a :href="popup.popupUrlAddr" target="_blank">
            <div class="noti_cont">
                <div class="title">
                  <i><img src="@/assets/images/main/img_noti1.png" alt="이미지"></i>
                    <p>{{ popup.popupNm }}</p>
                </div>
                <div class="cont">
                    <div v-html="popup.popupCn"></div>
                </div>
            </div>
          </a>
        </div>
        <button type="button" class="modal_close" @click="hidePopupZone()">레이어 팝업 닫기</button>
      </div>
    </template>
  </div>


  <div class="system-notice" v-show="clickedCnt <= 10 && isShowSystemNotice">
    <div style="text-align : center; margin-top: 200px;">
      <img src="@/assets/images/main/maintenance.png" alt="팝업 이미지" >
    </div>
    <button @click="closeSystemNotice"></button>
  </div>
</template>

<script>
import { Pagination, Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import storeRegister from "@/js/store/store-register";
import { storeSession } from "@/js/store/store-session";
import { storeSwitch } from "@/js/store/store-switch";
import moment from "moment";
import PrvcAgre from "../join/components/PrvcAgre.vue";
import {buildUri} from "@/js/utils/uri-builder";
import storeAccount from "@/js/store/store-account";

const API = {
  NTC_DATA : "/ntcData",
  POPUP : "/popup",
  POPUP_ZONE : "/popupZone",
  BPLC_KND : "/bplcKnd",
  POPUP_SRVY : "/Survey",
  ONLINE_SURVEY : "/Survey/{srvySn}",
  POPUP_QLTY_INDCT_REG_MNG : "/otsdQltyIndctRslt/qltyIndctRegMngNtctlk",
  DOWNLOAD: "/api/file/download/{atchFileVldNo}",
  POPUP_CRITERIA : "/popupCriteria",
}


export default {
  components: {Swiper, SwiperSlide, PrvcAgre},
  data() {
    return {
      modules: [Pagination, Navigation, Autoplay],
      swiper: {
        pageIndex: 0,
        isPlay: true,
        autoPlay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        swiperInstance: null
      },
      swiperAlarm: {
        pageIndex: 0,
        isPlay: true,
        autoPlay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        swiperInstance: null
      },
      userinfo: storeSession.getMemberInfo(),
      mainSwiper: {},
      alarmSwiper: {},
      bbsTypeIndex: 1,
      ntcMttrList: [],  // 공지사항
      dataList: [], // 자료실
      popupNtc: [],
      popupZone: [],
      popupZonePopup: [],
      srvyQitemList: [],      // 설문문항리스트
      srvyQitemExList: [],    // 설문문항보기리스트
      srvyList:[],
      isVisible: false,
      isVisiblePopupZonePopup: false,  // 팝업존 팝업
      isVisibleSurvey: false,  // 설문조사 팝업
      isPrivacyNoticeVisible: false, // 개인정보 안내가 처음에 닫혀 있는 상태로 설정
      personalAgree: null, // 동의 여부
      surveyPram: {
        srvySn: '',             // 설문일련번호
        srvyPtcpUserId: '',     // 설문참여사용자아이디
        ansDt: '',              // 답변일시
        srvyQitemSqno: '',      // 설문문항순번
        srvyQitemExmpSqno: '',  // 설문문항보기순번
        evlScr: '',             // 평가점수
        opnnCn: '',             // 의견내용
        responses: {}           // 설문 응답 (각 문항별 응답 저장)
      },
      isVisibleOnline: false, // 온라인매뉴얼 팝업
      activeTab: 1, // 온라인매뉴얼 팝업 탭
      param: {
        bplcKndCd: '',
      },
      qltyIndctRegMngPopupNtc: [],
      isVisibleQltyIndctRegMng: false,
      sysVideoSrc: "",
      joinVideoSrc: "",
      fcltVideoSrc: "",
      qltyVideoSrc: "",
      clickedCnt: 0,
      prodYn: "",
      callfor: '',
      systemNoticePopup: '',
      noticePopupYn: '',
    };
  },
  computed: {
    isShowSystemNotice() {
      return moment().isBefore(moment('202501130830', 'YYYYMMDDHHmm'))
    },
  },
  watch: {
    popupNtc: {
      handler() {
        this.checkIfAllPopupsClosed();
      },
      deep: true,
    },
  },
  created() {
    storeRegister.dispatch('resetRegister'); // 회원가입 진행여부 초기화
    storeAccount.dispatch('resetFindAccount'); // 계정찾기 진행여부 초기화
  },
  beforeMount() {},
  mounted() {
    this.callfor = this.$route.query.callfor;

    this.prodCheck();
    this.readBplcKnd();
    this.readNtcMttr();
    this.readData();
    this.readNtcPopup();
    this.readZonePopup();
    this.surveyPopup();
    this.readQltyIndctRegMng();
    this.readVideo();

    window.sysNtcPopLinkToSomewhere = this.linkToSomewhere;
    window.saveTodayClose = this.saveTodayClose;

    if(this.callfor === 'ONLINE_MANUAL') {
      this.onlinePopup();

      this.$router.replace({
        path: this.$route.path,
        query: {},
      });
    }

    // 오늘 날짜 확인
    const today = moment().format('YYYYMMDD')
    const popupClosedToday = localStorage.getItem('popupClosedToday');

    this.readPopupCriteria().then(() => {
      if(this.noticePopupYn == 'Y' && popupClosedToday !== today){
        this.systemNoticePopup = window.open('', 'SystemNotice', 'width=600, height=650');
        if (this.systemNoticePopup) {
          this.systemNoticePopup.document.open();
          this.systemNoticePopup.document.write(`
            <!DOCTYPE html>
            <html>
            <head>
              <title>Popup</title>
              <meta charset="UTF-8">
              <style>
                body {font-family: Arial, sans-serif; margin: 0; padding: 0;}
                .container {width: 100%; display: flex; flex-direction: column; align-items: center;
                }
                #new_sys_noti { position: relative; width: 600px;}
                #new_sys_noti .link_to_manual { position: absolute; left: 46px; top: 301px; width: 244px; height: 124px; border-radius: 10px; cursor: pointer; }
                #new_sys_noti .link_to_faq { position: absolute; left: 300px; top: 301px; width: 244px; height: 124px; border-radius: 10px; cursor: pointer; }
                #new_sys_noti .link_to_chatbot { position: absolute; left: 46px; top: 435px; width: 244px; height: 124px; border-radius: 10px; cursor: pointer; }
                #new_sys_noti .link_to_helpdesk { position: absolute; left: 300px; top: 435px; width: 244px; height: 124px; border-radius: 10px; }
                #today_close_button { margin-top: 2px; margin-left: 220px; padding: 8px 16px; border: 1px solid #ccc; border-radius: 4px; cursor: pointer; background-color: #f8f9fa;
                }
              </style>
            </head>
            <body>
              <div id="new_sys_noti">
                <img src="https://www.srf-info.or.kr/api/file/download/c71e3f5e-7fe2-4fc4-9430-a813a4a698bd" alt="차세대 시스템전환 공지 이미지">
                <div class="link_to_manual" onClick="window.opener.sysNtcPopLinkToSomewhere('MANUAL', '', 'POPUP');"></div>
                <div class="link_to_faq" onClick="window.opener.sysNtcPopLinkToSomewhere('FAQ');"></div>
                <div class="link_to_chatbot" onClick="window.opener.sysNtcPopLinkToSomewhere('CHATBOT');"></div>
                <div class="link_to_helpdesk"></div>
              </div>
              <button id="today_close_button" onclick="window.opener.saveTodayClose();">오늘 하루 열지 않음</button>
            </body>
            </html>`);
        }
        this.systemNoticePopup.document.close();
      }
    })
  },
  beforeUnmount() {},
  methods: {
    onClickBtnStopOfVisualArea() {
      this.swiper.isPlay = !this.swiper.isPlay;

      if( this.swiper.isPlay ) {
        this.mainSwiper.autoplay.start()
      } else {
        this.mainSwiper.autoplay.stop()
      }
    },
    onClickBtnStopOfVPopupZone() {
      this.swiperAlarm.isPlay = !this.swiperAlarm.isPlay;

      if( this.swiperAlarm.isPlay ) {
        this.alarmSwiper.autoplay.start()
      } else {
        this.alarmSwiper.autoplay.stop()
      }
    },
    onSwiperChanged(event) {
      this.swiper.pageIndex = (event.activeIndex % 3 === 0)? 3 : event.activeIndex % 3;
    },
    onAlarmSwiperChanged(event) {
      const popLen = this.popupZone.length;
      this.swiperAlarm.pageIndex = (event.activeIndex % popLen === 0)? popLen : event.activeIndex % popLen;
    },
    onInitSwiper(swiper) {
      this.mainSwiper = swiper;
    },
    onInitAlarmSwiper(swiper) {
      this.alarmSwiper = swiper;
      this.swiperAlarm.swiperInstance = swiper;
    },
    goPreSlide() {
      if (this.swiperAlarm.swiperInstance) {
        this.swiperAlarm.swiperInstance.slidePrev();
      }
    },
    goNextSlide() {
      if (this.swiperAlarm.swiperInstance) {
        this.swiperAlarm.swiperInstance.slideNext();
      }
    },
    readNtcMttr() {
      this.$apiCall.get(API.NTC_DATA, {bbsSn : "1"}, this.ntcSuccess, this.error);
    },
    readData() {
      this.$apiCall.get(API.NTC_DATA, {bbsSn : "2"}, this.dataSuccess, this.error);
    },
    ntcSuccess(data) {  // 공지사항
      if (!data.result) {
        this.error();
        return;
      }

      this.ntcMttrList = data.result;
    },
    dataSuccess(data) { // 자료실
      if (!data.result) {
        this.error();
        return;
      }

      this.dataList = data.result;
    },
    readNtcPopup() {  // 공지팝업
      this.$apiCall.get(API.POPUP, {}, this.popupSuccess, this.error);
    },
    popupSuccess(data) {
      if (!data.result) {
        this.error();
        return;
      }

      this.popupNtc = data.result;
      this.popupNtc.forEach(popup => {
        popup.isChecked = false;
        popup.isClosed = false;
      });

      this.loadPopupState();

      if(this.callfor === 'ONLINE_MANUAL') {
        // 공지팝업 X
      } else {
        this.isVisible = true;
      }
    },
    readZonePopup() {  // 팝업존
      this.$apiCall.get(API.POPUP_ZONE, {}, data => {
        if (data.result.list) {
          this.popupZone = data.result.list;

          if (this.popupZone.length > 0) {
            if (this.swiperAlarm.swiperInstance) {
              this.swiperAlarm.swiperInstance.slideTo(1, 0);
            }
          } else {
            this.swiperAlarm.swiperInstance.autoplay.stop();  
          }
        } else {
          this.popupZone = [];
          console.log("팝업존 데이터 없음")
        }
      });
    },
    getPopupImg(atchFiles) {
      if (atchFiles.length === 0 || !atchFiles[0].atchFileVldNo) {
        return "";
      }

      let imageUrl = buildUri(API.DOWNLOAD, { atchFileVldNo : atchFiles[0].atchFileVldNo });

      return process.env.VUE_APP_ENV === 'local'
          ? `${process.env.VUE_APP_API_PROXY_URL}${imageUrl}`
          : `${imageUrl}`;
    },
    error() {
      alert("데이터를 가져오는 중 오류가 발생했습니다.");
    },
    // 설문팝업
    surveyPopup() { 
      this.$apiCall.get( API.POPUP_SRVY, {}, this.surveyPopupSuccess,
          (error) => {
            console.error(error.message);
          }
      );
    },
    surveyPopupSuccess( data ) {
      if ( !data.result ) {
        this.error();
        return;
      }
      this.srvyList.DataCount = data.result.total;
      this.srvyQitemList = data.result.srvyQitemList;      // 문항 리스트 
      this.srvyQitemExList = data.result.srvyQitemExList;  // 문항보기 리스트

      // 참여해야할 설문 cnt 값이 0 이상인 경우 팝업을 보여줌
      if (data.result.total > 0) {
        this.isVisibleSurvey = true;
      }
    },
    // 해당 문항 번호에 맞는 보기들만 반환
    getExmpByQitemSqno( sqno ) {
      return this.srvyQitemExList.filter( exmp => exmp.srvyQitemSqno === sqno );
    },
    updateSrvyTypeCd1Val(key, srvyQitemExmpSqno, event){
      // key 값을 포함하는 모든 항목 삭제
      Object.keys(this.surveyPram.responses).forEach(responseKey => {
        if (responseKey.includes(key)) {
          delete this.surveyPram.responses[responseKey];
        }
      });
      this.surveyPram.responses[key+"_"+srvyQitemExmpSqno] = event.target.value;
    },

    // 설문조사제출 버튼 클릭
    create() {
      // 필수 문항 검증
      const missingResponses = this.srvyQitemList.filter(item => {
        // 필수 여부가 'Y'이고, 응답이 없는 경우
        if (item.esntlYn === 'Y') {
          const responseKey = Object.keys(this.surveyPram.responses).find(key =>
            key.startsWith(`${item.srvySn}_${item.srvyQitemSqno}`)
          );
          return !responseKey || !this.surveyPram.responses[responseKey]; // 응답이 없으면 true 반환
        }
        return false;
      });

      if (missingResponses.length > 0) {
        const missingQuestions = missingResponses.map(item => `Q${item.srvyQitemSqno}`).join(', ');
        alert(`다음 문항은 필수 입력 사항입니다: ${missingQuestions}`);
        return; // 제출 중단
      }
      if ( this.personalAgree === 'disagree' || !this.personalAgree ){
        alert('개인정보 수집 및 이용안내 동의는 필수 입니다.\n 동의함에 체크 해주세요.');
        return;
      }

      // 제출데이터 구성: 선택된 값만 추출
      const requestData = Object.keys( this.surveyPram.responses ).filter( key => {
          // 값이 존재하는 key만 필터링
          return this.surveyPram.responses[key] !== null && this.surveyPram.responses[key] !== undefined;
        }).map(key => {
          // 해당 key에 대한 평가점수 (scr) 추출
          // 의견과 점수를 구분하여 값 설정
          const [ srvySn, srvyQitemSqno, srvyQitemExmpSqnoOrType ] = key.split('_');
          const isOpinion = srvyQitemExmpSqnoOrType === "opnnCn";
        
          // 의견인 경우 srvyQitemExmpSqno는 1로 설정, 그렇지 않으면 원래 값 사용
          const srvyQitemExmpSqno = isOpinion ? 1 : ( srvyQitemExmpSqnoOrType === "opnnCn" ? 1 : srvyQitemExmpSqnoOrType );
          const evlScr = isOpinion ? 0 : this.surveyPram.responses[key];      // 의견이 아닌 경우 평가점수 사용
          const opnnCn = isOpinion ? this.surveyPram.responses[key] : null;   // 의견인 경우 의견 내용 사용

        // 필요한 값을 객체에 추가하거나 API 호출을 위한 데이터 구성
        console.log(`srvySn: ${srvySn}, srvyQitemSqno: ${srvyQitemSqno}, srvyQitemExmpSqno: ${srvyQitemExmpSqno}, evlScr: ${evlScr}, opnnCn:${opnnCn}`);
        return {
          srvyQitemSqno: srvyQitemSqno,           // 설문문항일련번호
          srvyQitemExmpSqno: srvyQitemExmpSqno,   // 설문문항보기일련번호
          evlScr: evlScr,                         // 점수
          opnnCn: opnnCn                          // 의견내용
        }
      });
      if(confirm("제출 후 변경이 불가합니다. 제출하시겠습니까?")) {
        this.$apiCall.post(
          API.ONLINE_SURVEY,
          {surveyResults: requestData, srvySn: this.srvyQitemList[0].srvySn, srvyPtcpUserId: this.userinfo.userId },
          () => {
            alert("저장되었습니다.");
            this.isVisibleSurvey = false;
          },
          () => {
            alert("저장에 실패하였습니다.");
          }
        );
      }
    },
    togglePrivacyNotice() {
      this.isPrivacyNoticeVisible = !this.isPrivacyNoticeVisible;
    },
    checkAllAgree() {
      if ( this.personalAgree === 'disagree' ) {
        if(confirm("개인정보 수집 및 이용에 동의하지 않으시면 설문참여가 불가합니다. \n동의하시겠습니까?")) {  
          this.personalAgree = 'agree';
          () => {
          }
        }
      }
    },
    hide(popup) {
      popup.isClosed = true;
      this.checkIfAllPopupsClosed(); 
    },
    srvyPopupHide() {
      if(confirm("설문진행사항이 초기화 됩니다. 현재 페이지를 닫겠습니까?")) {  
          this.isVisibleSurvey = false;
          this.checkIfAllPopupsClosed();
        () => {
        }
      }
    },
    checkIfAllPopupsClosed() {
      if (this.popupNtc.every(popup => popup.isClosed && !this.isSurveyPopupOpen)) {
        this.isVisible = false; // 모든 팝업이 닫혔으면 모달 배경 숨기기
      }
    },
    checkboxChg(popup) {
      // 체크박스 상태가 변경되면 로컬스토리지에 해당 팝업 정보를 저장
      if (popup.isChecked) {
        // 오늘 하루 열지 않음 체크 시, 로컬스토리지에 정보 저장
        localStorage.setItem(`popupClosed_${popup.pstSn}`, moment().format('YYYYMMDD'));
      } else {
        // 체크 해제 시 로컬스토리지에서 정보 삭제
        localStorage.removeItem(`popupClosed_${popup.pstSn}`);
      }
    },
    checkAllPopupsClosed() {
      this.isVisible = !this.popupNtc.some(popup => !popup.isClosed);
    },
    loadPopupState() {
      this.popupNtc.forEach(popup => {
        const closedToday = localStorage.getItem(`popupClosed_${popup.pstSn}`);
        if (closedToday === moment().format('YYYYMMDD')) {
          popup.isChecked = true;  
          popup.isClosed = true; 
        } else {
          popup.isChecked = false;
          popup.isClosed = false;
        }
      });
    },
    readBplcKnd(){
      this.$apiCall.get(API.BPLC_KND, {userId : this.userinfo.userId}, this.bplcSuccess, this.error);
    },
    bplcSuccess(data){
      if (!data.result) {
        return;
      }

      this.param = data.result;
    },
    onlinePopup(){
      this.isVisibleOnline = true;
    },
    onlinePopupClose(){
      this.isVisibleOnline = false;
    },
    setActiveTab(tabIndex) {
      this.activeTab = tabIndex;
    },
    readQltyIndctRegMng() {  
      // 품질표시 미등록 관리 팝업
      if(this.userinfo.userId) {
        this.$apiCall.get(API.POPUP_QLTY_INDCT_REG_MNG, {userId : this.userinfo.userId}, this.qltyIndctRegMngPopupSuccess, this.error);  
      }
    },
    qltyIndctRegMngPopupSuccess(data) {
      if (data.result.list.length < 1) {
        return;
      }
      this.qltyIndctRegMngPopupNtc = data.result.list;
      this.isVisibleQltyIndctRegMng = true;
    },
    hideQltyIndctRegMng() {
      this.isVisibleQltyIndctRegMng = false;
    },
    navigateToQltyIndctReg() {
      this.$router.push(
        {path: "/sfli/otsdQltyIndctRslt/OtsdQltyIndctRsltCreate"}
      )
      .catch(() => {
        console.error("Navigation Error");
      });
    },
    moveLink(link) {
      if (this.userinfo.aprvPrcsSttsCd === '01') {
        alert("회원가입 승인이 완료되지 않았습니다. 관리자의 승인 후 이용가능합니다.");
      } else {
        window.location.href = link;
      }
    },
    chatBot() {
      storeSwitch.on('chatBot');
    },
    readVideo() {
      const filePath = `${process.env.VUE_APP_IMAGE_RESOURCE}/api/image`; // 현재 서버 경로
      
      const fileNameSys = 'sys.mp4';
      this.sysVideoSrc = `${filePath}/${fileNameSys}`;

      const fileNameJoin = 'join.mp4';
      this.joinVideoSrc = `${filePath}/${fileNameJoin}`;

      const fileNamefclt = 'fclt.mp4';
      this.fcltVideoSrc = `${filePath}/${fileNamefclt}`;

      const fileNameQlty = 'qlty.mp4';
      this.qltyVideoSrc = `${filePath}/${fileNameQlty}`;
    },
    downReady() {
      alert('준비중입니다.');
    },
    closeSystemNotice() {
      this.clickedCnt++;

    },
    popupSelect(zone) { // 팝업존 팝업 띄우기
      this.popupZonePopup = [zone];
      this.isVisiblePopupZonePopup = true;
    },
    hidePopupZone() {
      this.isVisiblePopupZonePopup = false;
    },
    prodCheck() {
      if(`${process.env.VUE_APP_ENV}` === 'prod'){
        this.prodYn = 'Y'
      } else {
        this.prodYn = 'N'
      }
    },
    linkToSomewhere(callType, popup, from) {
      switch (callType) {
        case 'MANUAL':
          if(from && from === 'POPUP') {
            if(this.$route.path === "/home") {
              this.onlinePopup();
            } else {
              this.$router.push({path: '/home', query: {callfor: 'ONLINE_MANUAL'}});
            }
          } else {
            this.onlinePopup();
            popup.isClosed = true;
            this.checkIfAllPopupsClosed();
          }
          break;
        case 'FAQ':
          this.$router.push('/cust-sprt/FaqManage');
          popup.isClosed = true;
          this.checkIfAllPopupsClosed();
          break;
        case 'CHATBOT':
            this.chatBot();
          popup.isClosed = true;
          this.checkIfAllPopupsClosed();
          break;
        case 'HELPDESK':
          // 헬프데스크는 link 연결 X
          // popup.isClosed = true;
          // this.checkIfAllPopupsClosed();
          break;
      }
    },
    readPopupCriteria() {  // 하드코딩 팝업 사용여부 불러옴
      return new Promise((resolve) => {
        this.$apiCall.get(API.POPUP_CRITERIA, {}, data => {
          if (data.result) {
            this.noticePopupYn = data.result;
            resolve(data.result);
          } else {
            resolve(null);
          }
        });
      });
    },
    saveTodayClose() {
      localStorage.setItem('popupClosedToday', moment().format('YYYYMMDD'));
      this.systemNoticePopup.close();
    }
  }
}
</script>

<style scoped>

.popup-image {
  width: 100%; 
  height: auto; 
  object-fit: contain; /* 비율을 유지하면서 이미지가 왜곡되지 않도록 조정 */
}
/* 온라인설문 태블릿, 모바일에서는 설문팝업 가로사이즈 100% */
@media (max-width: 768px) {
  #popup {
    width: 100% !important;
  }
}

.system-notice { position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; background-color: #fff; z-index: 99999; }
.system-notice .content { position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 95%; height: 95%; background-color: #ffffff; border: 2px solid #0d0d81; border-radius: 10px; padding: 20px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); text-align: center; font-family: Arial, sans-serif; font-size: 40px; z-index: 99999; display: flex; justify-content: center; align-items: center; flex-direction: column; }
.system-notice h1 { font-size: 40px; color: #080860; margin-bottom: 50px; font-weight: bold; }
.system-notice p { font-size: 16px; color: #000000; margin-bottom: 15px; }
.system-notice button { position: fixed; top: 0px; left: 0px; width: 50px; height: 30px; z-index: 99999; cursor: default; }

#new_sys_noti { position: relative; }

#new_sys_noti .link_to_manual { position: absolute; left: 65px; top: 316px; width: 235px; height: 124px; border-radius: 10px; cursor: pointer; }
#new_sys_noti .link_to_faq { position: absolute; left: 311px; top: 316px; width: 235px; height: 124px; border-radius: 10px; cursor: pointer; }
#new_sys_noti .link_to_chatbot { position: absolute; left: 65px; top: 450px; width: 235px; height: 124px; border-radius: 10px; cursor: pointer; }
#new_sys_noti .link_to_helpdesk { position: absolute; left: 311px; top: 450px; width: 235px; height: 124px; border-radius: 10px; }

.empty-message {
  padding: 2rem 1.5rem 23.7rem;
  border-radius: 2rem 2rem 0 2rem;
  border: 1px solid var(--gray40);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

</style>