<template>
  <h2 class="page_title">{{ menuInfo.menuNm ? menuInfo.menuNm : menuInfo.menuGrpNm }}</h2>
  <ol class="breadcrumb">
    <li class="home"><a href="javascript:void(0);" role="link" @click.prevent="home">홈</a></li>
    <li>
<!--      <a href="javascript:void(0);" @click.prevent="navigateGroup">{{ menuInfo.groupName }}</a>-->
      <p>{{ menuInfo.groupName }}</p>
    </li>
    <li>
      <a href="javascript:void(0);" role="link" @click.prevent="navigateMenu">{{ menuInfo.menuGrpNm }}</a>
<!--      <p>{{ menuInfo.menuGrpNm }}</p>-->
      <menu-manual v-if="!menuInfo.menuNm" :menuNm="menuInfo.menuGrpNm" :menuSn="menuSn" />
    </li>
    <li v-if="menuInfo.menuNm">
      <p>{{ menuInfo.menuNm }}</p>
      <menu-manual :menuNm="menuInfo.menuNm" :menuSn="menuSn" />
    </li>
  </ol>
</template>

<script>
import MenuManual from "@/views/main/components/MenuManual.vue";

export default {
  components : {MenuManual},
  props: ['groups', 'menu'],
  emits: ['home'],
  data() {
    return {
      menuSn: 0,
      menuInfo: {
        groupName: '',
        menuGrpNm: '',
        menuNm: '',
      }
    };
  },
  computed: {},
  watch: {
    menu() {
      this.setMenuInfo();
    }
  },
  created() {
  },
  mounted() {
    this.setMenuInfo();
  },
  methods: {
    home() {
      this.$emit("home");
    },
    setMenuInfo() {
      const { menuSn } = this.menu || {};

      this.menuInfo = {
        groupName: '',
        menuGrpNm: '',
        menuNm: '',
      }

      if (!menuSn) return;

      this.menuSn = menuSn;

      const group = this.groups.find(group => group.menuSn === menuSn || group.menuList.some(menuGrp => menuGrp.menuSn === menuSn || menuGrp.items.some(item => item.menuSn === menuSn)));

      if (group) {
        this.group = group
        this.menuInfo.groupName = group.groupName;

        const menuGrp = group.menuList.find(menuGrp => menuGrp.menuSn === menuSn || menuGrp.items.some(item => item.menuSn === menuSn));
        if (menuGrp) {
          this.menuInfo.menuGrpNm = menuGrp.menuGrpNm;
          this.menuGrp = menuGrp

          const item = menuGrp.items.find(item => item.menuSn === menuSn);
          if (item) {
            this.menuInfo.menuNm = item.menuNm;
          }
        }
      }
    },
    navigateGroup(){
      this.$router.push({path : this.group.menuUrl})
        .catch(() => {
          console.error("Navigation Error")
        });
    },
    navigateMenu(){
      this.$router.push({path : this.menuGrp.path})
        .catch(() => {
          console.error("Navigation Error")
        });
    }
  }
}
</script>

<style scoped>

</style>