<template>
  <div v-if="isOn" class="modal visible" >
    <div class="popup_box" tabindex="0" style="width: 80%; max-width: none;">
      <h1 class="popup_title">파일 뷰어</h1>
      <div class="popup_cont">
        <iframe title="파일뷰어" v-if="viewUrl" :src="viewUrl" style="width: 100%; height: 1000px"></iframe>
      </div>
      <button type="button" class="modal_close" @click="hide">팝업 닫기</button>
    </div>
  </div>
</template>

<script>
import {storeSwitch} from "@/js/store/store-switch";
import {buildUri} from "@/js/utils/uri-builder";

const API = {
  VIEWER: "/file/viewer/{atchFileVldNo}"
}

export default {
  data() {
    return {
      isOn : false,
      viewUrl : "",
    };
  },
  computed : {},
  watch : {
  },
  created() {
    storeSwitch.setBulb('SynapViewer', this);
  },
  mounted() {
  },
  methods : {
    show(atchFileVldNo) {
      console.log(atchFileVldNo);
      this.viewerLoad(atchFileVldNo);
    },
    popupShow() {
      document.documentElement.style.overflow = 'hidden';
      this.isOn = true;
    },
    hide() {
      this.viewUrl = "";
      this.isOn = false;
      document.documentElement.style.overflow = '';
    },
    viewerLoad(atchFileVldNo) {
      if (!atchFileVldNo) {
        alert('첨부파일이 존재하지 않습니다.');
        return;
      }

      this.$apiCall.read(buildUri(API.VIEWER, { atchFileVldNo: atchFileVldNo }), {}, this.success, this.fail);
    },
    success(data) {
      const result = data.result;

      if (!result.viewUrl) {
        alert('뷰어를 로드하는데 실패하였습니다.');
        return;
      }

      this.viewUrl = data.result.viewUrl;
      this.popupShow();
    },
    fail(error) {
      console.error(error);
      alert(error.message);
    },
  },
}
</script>

<style scoped>

</style>