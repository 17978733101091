<template>
  <rjct-rsn-area :prfmncBasicInfo="prfmncBasicInfo" />
  <prfmnc-mdfcn-prm-area :prfmncBasicInfo="prfmncBasicInfo" />

  <h3 class="title1">고형연료제품 반입 현황(톤/월)</h3>
  <sb-grid
      id="sdflCrynGrid"
      :dataSource="sdflCrynDataSource"
      :columns="sdflCrynGridColumns"
      :gridattr="sdflCrynGridAttr"
      v-model:grid="sdflCrynGridObject"
      @loaded="sdflCrynGridLoaded"
      :refreshGrid="sdflCrynGridReload"
  />

  <h3 class="title1">고형연료제품 처리 현황</h3>
  <h2 class="title2">시설별 고형연료제품 투입 및 에너지 생산 현황</h2>
  <sb-grid
      id="sdflInputGrid"
      :dataSource="sdflInputDataSource"
      :columns="sdflInputGridColumns"
      :gridattr="sdflInputGridAttr"
      v-model:grid="sdflInputGridObject"
      @loaded="sdflInputGridLoaded"
      :refreshGrid="sdflInputGridReload"
  />

  <h2 class="title2">시설별 고형연료제품 투입량 검증 결과</h2>
  <sb-grid
      id="sdflInputVrfcGrid"
      :dataSource="sdflInputVrfcDataSource"
      :columns="sdflInputVrfcGridColumns"
      :gridattr="sdflInputVrfcGridAttr"
      v-model:grid="sdflInputVrfcGridObject"
      @loaded="sdflInputVrfcGridLoaded"
      :refreshGrid="sdflInputVrfcGridReload"
  />
  <!-- <h5>※ 월간시설용량(톤/월) = 각 시설의 일간시설용량(톤/일) * 30</h5> -->

  <h2 class="title2">고형연료제품 종류별 처리 현황</h2>
  <sb-grid
      id="sdflPrcsGrid"
      :dataSource="sdflPrcsDataSource"
      :columns="sdflPrcsGridColumns"
      :gridattr="sdflPrcsGridAttr"
      v-model:grid="sdflPrcsGridObject"
      @loaded="sdflPrcsGridLoaded"
      :refreshGrid="sdflPrcsGridReload"
  />

  <h2 class="title2">시설별 보조연료 투입 현황</h2>
  <sb-grid
      id="asstFuelInputGrid"
      :dataSource="asstFuelInputDataSource"
      :columns="asstFuelInputGridColumns"
      :gridattr="asstFuelInputGridAttr"
      v-model:grid="asstFuelInputGridObject"
      @loaded="asstFuelInputGridLoaded"
      :refreshGrid="asstFuelInputGridReload"
  />

  <h3 class="title1">폐자원에너지 생산/이용 현황</h3>
  <sb-grid
      id="prdctnGrid"
      :dataSource="prdctnDataSource"
      :columns="prdctnGridColumns"
      :gridattr="prdctnGridAttr"
      v-model:grid="prdctnGridObject"
      @loaded="prdctnGridLoaded"
      :refreshGrid="prdctnGridReload"
  />

  <h3 class="title1">폐자원에너지 업체별 판매 현황</h3>
  <sb-grid
      id="ntslGrid"
      :dataSource="ntslDataSource"
      :columns="ntslGridColumns"
      :gridattr="ntslGridAttr"
      v-model:grid="ntslGridObject"
      @loaded="ntslGridLoaded"
      :refreshGrid="ntslGridReload"
  />

  <common-button-area
      :prfmncBasicInfo="prfmncBasicInfo"
  />
</template>

<script>
import SbGrid from "@/components/SbGrid.vue";
import utils from "@/js/utils/utils";
import RjctRsnArea from "@/views/prfmnc-rpt/dtl/components/RjctRsnArea.vue";
import PrfmncMdfcnPrmArea from "@/views/prfmnc-rpt/dtl/components/PrfmncMdfcnPrmArea.vue";
import commonButtonArea from "@/views/prfmnc-rpt/dtl/components/CommonButtonArea.vue";

const CODE = {
  PRODUCT: "MBR010",
  ACCEPTANCE_DIFFERENCE_REASON: "COM450", // 인계/인수차이 사유코드
  ASSISTANCE_FUEL_KIND_GROUP: "PFM007", // 보조연료종류그룹코드
  ASSISTANCE_FUEL_KIND: "PFM008", // 보조연료종류코드
  ASSISTANCE_FUEL_UNIT: "PFM009", // 보조연료단위코드
  LOW_CALORIFIC_POWER_UNIT: "PFM010", // 저위발열량단위코드
};

export default {
  props: ['prfmncBasicInfo', 'sdflCrynInfo', 'sdflInputInfo', 'sdflPrcsInfo', 'asstFuelInputInfo', 'prdctnInfo', 'ntslInfo'],
  components: {
    commonButtonArea,
    RjctRsnArea,
    PrfmncMdfcnPrmArea,
    SbGrid,
  },
  data() {
    return {
      sdflCrynGridReload: false,
      sdflCrynGridObject: null,
      sdflCrynDataSource: [],
      sdflCrynGridColumns: [],
      sdflCrynGridAttr: {
        showRowNo: false,
        pageable: false,
        excelExport: {
          fileName: '고형연료제품 반입 현황(톤/월).xlsx',
          includeHeader: true,
          adjustCaption: (value) => {
            return value?.toString().includes('<br>') ? value.replace(/<br\s*\/?>/gi, '\n') : value;
          },
        },
        captionHeight: 40
      },
      sdflInputGridReload: false,
      sdflInputGridObject: null,
      sdflInputDataSource: [],
      sdflInputGridColumns: [],
      sdflInputGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        toolBar: [],
      },
      sdflInputVrfcGridReload: false,
      sdflInputVrfcGridObject: null,
      sdflInputVrfcDataSource: [],
      sdflInputVrfcGridColumns: [],
      sdflInputVrfcGridAttr: {
        showRowNo: false,
        pageable: false,
        toolBar: [],
      },
      sdflPrcsGridReload: false,
      sdflPrcsGridObject: null,
      sdflPrcsDataSource: [],
      sdflPrcsGridColumns: [],
      sdflPrcsGridAttr: {
        showRowNo: false,
        pageable: false,
        toolBar: [],
      },
      asstFuelInputGridReload: false,
      asstFuelInputGridObject: null,
      asstFuelInputDataSource: [],
      asstFuelInputGridColumns: [],
      asstFuelInputGridAttr: {
        showRowNo: false,
        pageable: false,
        toolBar: [],
      },
      prdctnGridReload: false,
      prdctnGridObject: null,
      prdctnDataSource: [],
      prdctnGridColumns: [],
      prdctnGridAttr: {
        showRowNo: false,
        pageable: false,
        toolBar: [],
      },
      ntslGridReload: false,
      ntslGridObject: null,
      ntslDataSource: [],
      ntslGridColumns: [],
      ntslGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        toolBar: [],
      },
      prdctItemCdList: [],
      hnovAcptnDiffRsnCdList: [],
      asstFuelKndGroupCdList: [],
      asstFuelKndCdList: [],
      asstFuelUnitCdList: [],
      lcpUnitCdList: [],
    }
  },
  computed: {
  },
  watch: {
    sdflCrynInfo() {
      this.initializeSdflCryn();
    },
    sdflInputInfo() {
      this.initializeSdflInput();
    },
    sdflPrcsInfo() {
      this.initializeSdflPrcs();
    },
    asstFuelInputInfo() {
      this.initializeAsstFuelInput();
    },
    prdctnInfo() {
      this.initializePrdctn();
    },
    ntslInfo() {
      this.initializeNtsl();
    }
  },
  created() {
    this.initialize();
  },
  mounted() {
  },
  methods: {
    initialize() {
      this.loadPrdctItemCd();
      this.loadHnovAcptnDiffRsnCd();
      this.loadAsstFuelKndGroupCd();
      this.loadAsstFuelKndCd();
      this.loadAsstFuelUnitCd();
      this.loadLcpUnitCd();
    },
    loadPrdctItemCd() {
      const prdctItemCdList = this.$store.getters.getCodeList({ groupCd: CODE.PRODUCT, useYn: 'Y', frstRefVl: "SRF" });
      this.prdctItemCdList = prdctItemCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    loadHnovAcptnDiffRsnCd() {
      const hnovAcptnDiffRsnCdList = this.$store.getters.getCodeList({ groupCd: CODE.ACCEPTANCE_DIFFERENCE_REASON, useYn: 'Y' });
      this.hnovAcptnDiffRsnCdList = hnovAcptnDiffRsnCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    loadAsstFuelKndGroupCd() {
      const asstFuelKndGroupCdList = this.$store.getters.getCodeList({ groupCd: CODE.ASSISTANCE_FUEL_KIND_GROUP, useYn: 'Y' });
      this.asstFuelKndGroupCdList = asstFuelKndGroupCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    loadAsstFuelKndCd() {
      const asstFuelKndCdList = this.$store.getters.getCodeList({ groupCd: CODE.ASSISTANCE_FUEL_KIND, useYn: 'Y' });
      this.asstFuelKndCdList = asstFuelKndCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    loadAsstFuelUnitCd() {
      const asstFuelUnitCdList = this.$store.getters.getCodeList({ groupCd: CODE.ASSISTANCE_FUEL_UNIT, useYn: 'Y' });
      this.asstFuelUnitCdList = asstFuelUnitCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    loadLcpUnitCd() {
      const lcpUnitCdList = this.$store.getters.getCodeList({ groupCd: CODE.LOW_CALORIFIC_POWER_UNIT, useYn: 'Y' });
      this.lcpUnitCdList = lcpUnitCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    initializeSdflCryn() {
      this.sdflCrynDataSource = this.sdflCrynInfo.list;
      this.initializeSdflCrynGridColumns();
      this.initializeSdflCrynGridAttr();
      this.sdflCrynGridReload = true;
    },
    initializeSdflInput() {
      this.sdflInputDataSource = this.sdflInputInfo.list;
      this.sdflInputVrfcDataSource = this.sdflInputInfo.vrfcRsltList;
      this.initializeSdflInputGridColumns();
      this.initializeSdflInputGridAttr();
      this.initializeSdflInputVrfcGridColumns();
      this.initializeSdflInputVrfcGridAttr();
      this.sdflInputGridReload = true;
      this.sdflInputVrfcGridReload = true;
    },
    initializeSdflPrcs() {
      this.sdflPrcsDataSource = this.sdflPrcsInfo.list;
      this.initializeSdflPrcsGridColumns();
      this.initializeSdflPrcsGridAttr();
      this.sdflPrcsGridReload = true;
    },
    initializeAsstFuelInput() {
      this.asstFuelInputDataSource = this.asstFuelInputInfo.list;
      this.initializeAsstFuelInputGridColumns();
      this.initializeAsstFuelInputGridAttr();
      this.asstFuelInputGridReload = true;
    },
    initializePrdctn() {
      this.prdctnDataSource = this.prdctnInfo.list;
      this.initializePrdctnGridColumns();
      this.initializePrdctnGridAttr();
      this.prdctnGridReload = true;
    },
    initializeNtsl() {
      this.ntslDataSource = this.ntslInfo.list;
      this.initializeNtslGridColumns();
      this.initializeNtslGridAttr();
      this.ntslGridReload = true;
    },
    initializeSdflCrynGridColumns() {
      const list = this.sdflCrynInfo.list;
      const uniquePrdctItemCdList = [...new Set(list.map(item => item.prdctItemCd))];
      const filteredPrdctItemList = this.prdctItemCdList
          .filter(item => uniquePrdctItemCdList.includes(item.value));

      const prdctItemList = filteredPrdctItemList.length === 1
          ? []
          : filteredPrdctItemList.map(item => ({ text: item.text, value: item.value }));

      this.sdflCrynGridColumns = [
        { field: 'bplcId', caption: '사업장아이디', visible: false },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false },
        { field: 'ctpvStdgNm', caption: '시·도', width: 100, mergeRow: true, total: { headerCss:'grid-header' } },
        { field: 'sggStdgNm', caption: '시·군·구', width: 100,
          mergeRow: (data) => data.ctpvStdgCd + "." + data.sggStdgCd,
          total: { headerCss:'grid-header' }
        },
        { field: 'bplcKndNm', caption: '사업장종류', width: 100,
          mergeRow: (data) => data.ctpvStdgCd + "." + data.sggStdgCd + "." + data.bplcKndNm,
          total: { headerCss:'grid-header' }
        },
        { field: 'bplcNm', caption: '사업장명', part: 'sticky', width: 200, align: 'left', total: { headerCss:'grid-header' } },
        { field: 'bzentyNm', caption: '업체명', width: 200, align: 'left', total: { headerCss:'grid-header' } },
        { field: 'prdctItemNm', caption: '구분', width: 120,
          total: {
            aggregates: [],
            header: [
              { template: '합계', align: 'right' },
              ...prdctItemList.map(item => ({ template: item.text, align: 'right' }))
            ],
            headerCss:'grid-header',
          }
        },
        { field: 'realtmPrdctHndqty', caption: '실시간인계량(톤)<br>(A)', width: 100,
          type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', colCss: 'grid-disabled',
          total: {
            aggregates: [
              {func: 'sum', require: 'realtmPrdctHndqty'},
              ...prdctItemList.map(item => ({
                func: (items) => items
                    .filter(entry => entry.prdctItemCd === item.value)
                    .reduce((sum, entry) => sum + (entry.realtmPrdctHndqty || 0), 0),
                field: `realtmPrdctHndqty${item.value}`
              }))
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00' },
              ...prdctItemList.map(item => ({
                template: `{{realtmPrdctHndqty${item.value}}}`, format: '#,##0.00'
              }))
            ],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctHndqty', caption: '저장된인계량(톤)', width: 100,
          type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', colCss: 'grid-disabled',
          total: {
            aggregates: [
              {func: 'sum', require: 'prdctHndqty'},
              ...prdctItemList.map(item => ({
                func: (items) => items
                    .filter(entry => entry.prdctItemCd === item.value)
                    .reduce((sum, entry) => sum + (entry.prdctHndqty || 0), 0),
                field: `prdctHndqty${item.value}`
              }))
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00' },
              ...prdctItemList.map(item => ({
                template: `{{prdctHndqty${item.value}}}`, format: '#,##0.00'
              }))
            ],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctAcpqty', caption: '인수량(톤)<br>(B)', width: 100,
          type: 'number',
          dataType: 'number',
          align: 'right',
          format: '#,##0.00',
          total: {
            aggregates: [
              {func: 'sum', require: 'prdctAcpqty'},
              ...prdctItemList.map(item => ({
                func: (items) => items
                    .filter(entry => entry.prdctItemCd === item.value)
                    .reduce((sum, entry) => sum + (entry.prdctAcpqty || 0), 0),
                field: `prdctAcpqty${item.value}`
              }))
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00' },
              ...prdctItemList.map(item => ({
                template: `{{prdctAcpqty${item.value}}}`, format: '#,##0.00'
              }))
            ],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctDscdCrtqty', caption: '폐기반출량(톤)', width: 100,
          type: 'number',
          dataType: 'number',
          align: 'right',
          format: '#,##0.00',
          total: {
            aggregates: [
              {func: 'sum', require: 'prdctDscdCrtqty'},
              ...prdctItemList.map(item => ({
                func: (items) => items
                    .filter(entry => entry.prdctItemCd === item.value)
                    .reduce((sum, entry) => sum + (entry.prdctDscdCrtqty || 0), 0),
                field: `prdctDscdCrtqty${item.value}`
              }))
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00' },
              ...prdctItemList.map(item => ({
                template: `{{prdctDscdCrtqty${item.value}}}`, format: '#,##0.00'
              }))
            ],
            headerCss:'grid-header',
          },
        },
        { field: 'diff', caption: '차이량(톤)<br>(A - B)', width: 150, align: 'right',
          dataType: 'number', format: '#,##0.00', editable: false,
          total: {
            aggregates: [
              {
                func: (items) => {
                  const totalRealtmPrdctHndqty = items.reduce((sum, item) => sum + (item.realtmPrdctHndqty || 0), 0);
                  const totalPrdctAcpqty = items.reduce((sum, item) => sum + (item.prdctAcpqty || 0), 0);
                  return Math.abs(utils.round2(totalRealtmPrdctHndqty - totalPrdctAcpqty));
                  // const totalPrdctDscdCrtqty = items.reduce((sum, item) => sum + (item.prdctDscdCrtqty || 0), 0);
                  // return Math.abs(totalRealtmPrdctHndqty - totalPrdctAcpqty - totalPrdctDscdCrtqty);
                },
                field: 'diffTotal'
              },
              ...prdctItemList.map(item => ({
                func: (items) => {
                  const filteredItems = items.filter(entry => entry.prdctItemCd === item.value);

                  const totalRealtmPrdctHndqty = filteredItems.reduce((sum, entry) => sum + (entry.realtmPrdctHndqty || 0), 0);
                  const totalPrdctAcpqty = filteredItems.reduce((sum, entry) => sum + (entry.prdctAcpqty || 0), 0);
                  return Math.abs(utils.round2(totalRealtmPrdctHndqty - totalPrdctAcpqty));
                  // const totalPrdctDscdCrtqty = filteredItems.reduce((sum, entry) => sum + (entry.prdctDscdCrtqty || 0), 0);
                  // return Math.abs(totalRealtmPrdctHndqty - totalPrdctAcpqty - totalPrdctDscdCrtqty);
                },
                field: `diff${item.value}`
              }))
            ],
            header: [
              { template: '{{diffTotal}}', format: '#,##0.00' },
              ...prdctItemList.map(item => ({
                template: `{{diff${item.value}}}`, format: '#,##0.00'
              }))
            ],
            headerCss:'grid-header',
          },
          getValue: (value, field, rowItem) => {
            const realtmPrdctHndqty = rowItem.data.realtmPrdctHndqty || 0
            const prdctAcpqty = rowItem.data.prdctAcpqty || 0;
            const difference = Math.abs(utils.round2(realtmPrdctHndqty - prdctAcpqty));
            // const prdctDscdCrtqty = rowItem.data.prdctDscdCrtqty || 0;
            // const difference = realtmPrdctHndqty - prdctAcpqty - prdctDscdCrtqty;

            return difference !== 0 ? Math.abs(difference) : '';
          },
          colCss: (data) => {
            const realtmPrdctHndqty = data.realtmPrdctHndqty || 0
            const prdctAcpqty = data.prdctAcpqty || 0;
            // 음수, 양수에 따른 클래스 처리로 인해 절대값처리 x
            const difference = utils.round2(realtmPrdctHndqty - prdctAcpqty);
            // const prdctDscdCrtqty = data.prdctDscdCrtqty || 0;
            // const difference = realtmPrdctHndqty - prdctAcpqty - prdctDscdCrtqty;

            if (difference !== 0) {
              if(difference < 0){
                return 'grid-danger';
              }else{
                return 'grid-warning';
              }
            } else {
              return 'grid-disabled';
            }
          }
        },
        { field: 'hnovAcptnDiffRsnCd', caption: '비고(사유)', width: 184, align: 'left',
          type: 'combo',
          items: this.hnovAcptnDiffRsnCdList,
          comboLabel: 'text',
          comboValue: 'value',
          total: { headerCss:'grid-header' },
        },
        { field: 'hnovAcptnDiffRmrkCn', caption: '기타내용', width: 200, align: 'left', total: { headerCss:'grid-header' } },
      ]
    },
    initializeSdflCrynGridAttr() {
      const itemCount = this.sdflCrynInfo.list.length;
      let uniqueItemCount = new Set(this.sdflCrynInfo.list.map(item => item.prdctItemCd)).size;
      uniqueItemCount = uniqueItemCount === 1 ? 0 : uniqueItemCount;

      this.sdflCrynGridAttr.height = Math.max(((itemCount + uniqueItemCount) * 30) + 150, 150);
    },
    initializeSdflInputGridColumns() {
      this.sdflInputGridColumns = [
        { field: 'fcltSqno', caption: '시설순번', visible: false },
        { field: 'fcltNm', caption: '시설명', part: 'head', width: 200, mergeRow: true, align: 'left', total: { headerCss:'grid-header' } },
        { field: 'prdctItemCd', caption: '제품품목코드', visible: false },
        { field: 'prdctItemNm', caption: '고형연료제품<br>종류', width: 150,
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          }
        },
        { field: 'prdctInpqty', caption: '고형연료제품<br>투입량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          total: {
            aggregates: [{func: 'sum', require: 'prdctInpqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctLcp', caption: '저위발열량<br>(kcal/kg)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          total: {
            aggregates: [
              { func: (items) => {
                  const filteredItems = items.filter(item => item.prdctLcp > 0);
                  const sumPrdctInpqty = filteredItems.reduce((total, item) => total + (item.prdctInpqty || 0), 0);
                  const sumPrdqtyLcp = filteredItems.reduce((total, item) => {
                    const prdctInpqty = item.prdctInpqty || 0;
                    const prdctLcp = item.prdctLcp || 0;
                    return total + (prdctInpqty * prdctLcp);
                  }, 0);

                  return sumPrdctInpqty > 0 ? utils.round2(sumPrdqtyLcp / sumPrdctInpqty) : 0;
                },
                field: 'prdctLcpAvg'
              }
            ],
            header: [{ template: '{{prdctLcpAvg}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { caption: '에너지생산량',
          columns: [
            { field: 'vaporPrdqty', caption: '증기(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150, captionCss:'gridh-bg-vapor',
              total: {
                aggregates: [{func: 'sum', require: 'vaporPrdqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'vaporTp', caption: '증기온도(℃)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150, captionCss:'gridh-bg-vapor',
              total: {
                aggregates: [{func: 'sum', require: 'vaporTp'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'vaporPrssVl', caption: '증기압력(kgf/cm2)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150, captionCss:'gridh-bg-vapor',
              total: {
                aggregates: [{func: 'sum', require: 'vaporPrssVl'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'htwtrPrdqty', caption: '온수(Gcal)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150, captionCss:'gridh-bg-hot-water',
              total: {
                aggregates: [{func: 'sum', require: 'htwtrPrdqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'prvtmPrdqty', caption: '전기(MWh)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150, captionCss:'gridh-bg-electricity',
              total: {
                aggregates: [{func: 'sum', require: 'prvtmPrdqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
          ]
        },
      ]
    },
    initializeSdflInputGridAttr() {
      const itemCount = this.sdflInputInfo.list.length;

      this.sdflInputGridAttr.height = Math.max((itemCount  * 30) + 130, 150);
    },
    initializeSdflInputVrfcGridColumns() {
      this.sdflInputVrfcGridColumns = [
        { field: 'fcltSqno', caption: '시설순번', visible: false },
        { field: 'fcltNm', caption: '시설명', maxWidth: 1000, width: 100, unit: '%', align: 'left',
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          }
        },
        { field: 'prdctInpqtySum', caption: '고형연료제품 투입량(톤)', dataType: 'number', align: 'right', format: '#,##0.00',
          width: 150, colCss: 'grid-disabled',
          total: {
            aggregates: [{func: 'sum', require: 'prdctInpqtySum'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { caption: '투입량 검증', captionCss:'gridh-bg-validation',
          columns: [
            { field: 'mthlyFcltCap', caption: '월간시설용량(톤/월)', dataType: 'number', align: 'right', format: '#,##0.00', captionCss:'gridh-bg-validation',
              width: 150, colCss: 'grid-disabled',
              total: {
                aggregates: [{func: 'sum', require: 'mthlyFcltCap'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
              visible: false,
            },
            { field: 'mthlyexcsCap', caption: '초과용량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150, captionCss:'gridh-bg-validation',
              total: {
                aggregates: [{
                  func: (items) => {
                    return items.reduce((sum, item) => {
                      const difference = (item.prdctInpqtySum || 0) - (item.mthlyFcltCap || 0);
                      return sum + (difference > 0 ? difference : 0);
                    }, 0);
                  },
                  field: 'excsCapTotal'
                }],
                header: [{ template: '{{excsCapTotal}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
              getValue: (value, field, rowItem) => {
                const prdctInpqtySum = rowItem.data.prdctInpqtySum || 0
                const mthlyFcltCap = rowItem.data.mthlyFcltCap || 0;
                const difference = prdctInpqtySum - mthlyFcltCap;
                return difference > 0 ? difference : '';
              },
              colCss: (data) => {
                const prdctInpqtySum = data.prdctInpqtySum || 0;
                const mthlyFcltCap = data.mthlyFcltCap || 0;

                if (prdctInpqtySum > mthlyFcltCap) {
                  return 'grid-danger';
                } else {
                  return 'grid-disabled';
                }
              },
              visible: false,
            },
            { field: 'vrfcRslt', caption: '검증결과', width: 150, captionCss:'gridh-bg-validation',
              total: { headerCss:'grid-header' },
              getValue: (value, field, rowItem) => {
                const prdctInpqtySum = rowItem.data.prdctInpqtySum || 0
                const mthlyFcltCap = rowItem.data.mthlyFcltCap || 0;
                return prdctInpqtySum > mthlyFcltCap ? "초과" : "적합";
              },
              colCss: (data) => {
                const prdctInpqtySum = data.prdctInpqtySum || 0;
                const mthlyFcltCap = data.mthlyFcltCap || 0;

                if (prdctInpqtySum > mthlyFcltCap) {
                  return 'grid-danger';
                } else {
                  return 'grid-good';
                }
              },
              visible: false,
            }
          ]
        }
      ];
    },
    initializeSdflInputVrfcGridAttr() {
      const itemCount = this.sdflInputInfo.vrfcRsltList.length;

      this.sdflInputVrfcGridAttr.height = Math.max((itemCount  * 30) + 120, 150);
    },
    initializeSdflPrcsGridColumns() {
      this.sdflPrcsGridColumns = [
        { field: 'prdctItemCd', caption: '제품품목코드', visible: false },
        { field: 'prdctItemNm', caption: '고형연료제품 종류', minWidth: 100, width: 100, unit: '%',
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          }
        },
        { field: 'prdctCrfqty', caption: '이월량(톤)', dataType: 'number', align: 'right', format: '#,##0.00',width: 150,
          colCss: 'grid-disabled',
          total: {
            aggregates: [{func: 'sum', require: 'prdctCrfqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctSumAcpqty', caption: '인수량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          colCss: 'grid-disabled',
          total: {
            aggregates: [{func: 'sum', require: 'prdctSumAcpqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctSumInpqty', caption: '투입량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          colCss: 'grid-disabled',
          total: {
            aggregates: [{func: 'sum', require: 'prdctSumInpqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctRsdqty', caption: '잔재물(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          total: {
            aggregates: [{func: 'sum', require: 'prdctRsdqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctRmnqty', caption: '잔량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          colCss: 'grid-disabled',
          total: {
            aggregates: [{func: 'sum', require: 'prdctRmnqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
      ]
    },
    initializeSdflPrcsGridAttr() {
      const itemCount = this.sdflPrcsInfo.list.length;

      this.sdflPrcsGridAttr.height = Math.max((itemCount  * 30) + 90, 150);
    },
    initializeAsstFuelInputGridColumns() {
      this.asstFuelInputGridColumns = [
        { field: 'fcltSqno', caption: '시설순번', visible: false },
        { field: 'fcltNm', caption: '시설명', minWidth: 100, width: 100, unit: '%', mergeRow: true, align: 'left' },
        { caption: '보조연료 정보',
          columns: [
            { field: 'asstFuelKndGroupCd', caption: '연료구분', width: 150,
              type: 'combo',
              items: this.asstFuelKndGroupCdList,
              comboLabel: 'text',
              comboValue: 'value',
            },
            { field: 'asstFuelKndCd', caption: '연료종류', width: 150,
              type: 'combo',
              items: this.asstFuelKndCdList,
              comboLabel: 'text',
              comboValue: 'value',
            },
            { field: 'asstFuelKndCn', caption: '기타종류명', width: 150,
              getValue: (value, field, rowItem) => {
                return rowItem.data.asstFuelKndGroupCd === '99' ? value : ''
              },
              colCss: (data) => {
                return data.asstFuelKndGroupCd === '99' ? '' : 'grid-disabled'
              }
            },
            { field: 'asstFuelInpqty', caption: '연료투입량', dataType: 'number', align: 'right', format: '#,##0.00', width: 150 },
            { field: 'asstFuelUnitCd', caption: '연료단위', width: 150,
              type: 'combo',
              items: this.asstFuelUnitCdList,
              comboLabel: 'text',
              comboValue: 'value',
            },
            { field: 'asstFuelLcp', caption: '저위발열량', dataType: 'number', align: 'right', format: '#,##0.00', width: 150 },
            { field: 'lcpUnitCd', caption: '저위발열량 단위', width: 150,
              type: 'combo',
              items: this.lcpUnitCdList,
              comboLabel: 'text',
              comboValue: 'value',
            },
          ]
        },
      ]
    },
    initializeAsstFuelInputGridAttr() {
      const itemCount = this.asstFuelInputInfo.list.length;

      this.asstFuelInputGridAttr.height = Math.max((itemCount  * 30) + 110, 150);
    },
    initializePrdctnGridColumns() {
      this.prdctnGridColumns = [
        { field: 'fcltSqno', caption: '시설순번', visible: false },
        { field: 'fcltNm', caption: '시설명', part: 'sticky', width: 200, align: 'left',
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          }
        },
        { caption: '에너지 이용 정보',
          columns: [
            { caption: '증기(톤)', captionCss:'gridh-bg-vapor',
              columns: [
                { caption: '자체이용', captionCss:'gridh-bg-vapor',
                  columns: [
                    { field: 'stUsgVaporUsqty', caption: '스팀이용(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
                      captionCss:'gridh-bg-vapor',
                      total: {
                        aggregates: [{func: 'sum', require: 'stUsgVaporUsqty'}],
                        header: [{ template: '{{sum}}', format: '#,##0.00' }],
                        headerCss:'grid-header',
                      },
                    },
                    { field: 'htwtrUsgVaporUsqty', caption: '온수이용(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
                      captionCss:'gridh-bg-vapor',
                      total: {
                        aggregates: [{func: 'sum', require: 'htwtrUsgVaporUsqty'}],
                        header: [{ template: '{{sum}}', format: '#,##0.00' }],
                        headerCss:'grid-header',
                      },
                    },
                    { field: 'elctgnUsgVaporUsqty', caption: '발전이용(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
                      captionCss:'gridh-bg-vapor',
                      total: {
                        aggregates: [{func: 'sum', require: 'elctgnUsgVaporUsqty'}],
                        header: [{ template: '{{sum}}', format: '#,##0.00' }],
                        headerCss:'grid-header',
                      },
                    },
                  ]
                },
                { field: 'vaporNsqty', caption: '외부판매', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
                  captionCss:'gridh-bg-vapor',
                  total: {
                    aggregates: [{func: 'sum', require: 'vaporNsqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
              ]
            },
            { caption: '온수(Gcal)', captionCss:'gridh-bg-hot-water',
              columns: [
                { field: 'htwtrUsqty', caption: '자체이용(Gcal)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
                  captionCss:'gridh-bg-hot-water',
                  total: {
                    aggregates: [{func: 'sum', require: 'htwtrUsqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'htwtrNsqty', caption: '외부판매(Gcal)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
                  captionCss:'gridh-bg-hot-water',
                  total: {
                    aggregates: [{func: 'sum', require: 'htwtrNsqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
              ]
            },
            { caption: '전기(MWh)', captionCss:'gridh-bg-electricity',
              columns: [
                { field: 'prvtmUsqty', caption: '자체이용(MWh)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
                  captionCss:'gridh-bg-electricity',
                  total: {
                    aggregates: [{func: 'sum', require: 'prvtmUsqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'prvtmNsqty', caption: '외부판매(MWh)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
                  captionCss:'gridh-bg-electricity',
                  total: {
                    aggregates: [{func: 'sum', require: 'prvtmNsqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
              ]
            }
          ]
        }
      ]
    },
    initializePrdctnGridAttr() {
      const itemCount = this.prdctnInfo.list.length;

      this.prdctnGridAttr.height = Math.max((itemCount  * 30) + 200, 250);
    },
    initializeNtslGridColumns() {
      this.ntslGridColumns = [
        { field: 'eneBzentySn', caption: '에너지공급업체일련번호', visible: false },
        { field: 'ctpvStdgNm', caption: '시·도', width: 120, mergeRow: true, total: { headerCss:'grid-header' } },
        { field: 'sggStdgNm', caption: '시·군·구', width: 120, mergeRow: true, total: { headerCss:'grid-header' } },
        { field: 'bzentyNm', caption: '판매업체명', minWidth:100, maxWidth: 1000, width: 100, unit: '%', align: 'left',
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          }
        },
        { caption: '외부판매량',
          columns: [
            { field: 'vaporNsqty', caption: '증기(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
              captionCss:'gridh-bg-vapor',
              total: {
                aggregates: [{func: 'sum', require: 'vaporNsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'htwtrNsqty', caption: '온수(Gcal)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
              captionCss:'gridh-bg-hot-water',
              total: {
                aggregates: [{func: 'sum', require: 'htwtrNsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'prvtmNsqty', caption: '전기(MWh)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
              captionCss:'gridh-bg-electricity',
              total: {
                aggregates: [{func: 'sum', require: 'prvtmNsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
          ]
        }
      ]
    },
    initializeNtslGridAttr() {
      const itemCount = this.ntslInfo.list.length;

      this.ntslGridAttr.height = Math.max((itemCount  * 30) + 110, 150);
    },
    sdflCrynGridLoaded() {
      this.sdflCrynGridReload = false;
    },
    sdflInputGridLoaded() {
      this.sdflInputGridReload = false;
    },
    sdflInputVrfcGridLoaded() {
      this.sdflInputVrfcGridReload = false;
    },
    sdflPrcsGridLoaded() {
      this.sdflPrcsGridReload = false;
    },
    asstFuelInputGridLoaded() {
      this.asstFuelInputGridReload = false;
    },
    prdctnGridLoaded() {
      this.prdctnGridReload = false;
    },
    ntslGridLoaded() {
      this.ntslGridReload = false;
    },
    formatNumber(number) {
      return utils.formatNumberWithComma(number);
    },
  }
}
</script>

<style scoped>
  .grid_box { margin-bottom: 5px; }
</style>