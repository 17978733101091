<template>
    <div v-show="isOn" class="modal visible">
        <div class="popup_box">
            <h1 class="popup_title">OCR 적용</h1>
            <div>
                <button type="button" class="modal_close" @click.prevent="hide">팝업닫기</button>
            </div>
            <div class="popup_cont">
                <div class="board_info">
                    <h2 class="title1">OCR 적용 하시겠습니까?</h2>
                    <div class="btn_area center">
                        <br-anchor-button class="btn sm sch" label="적용" @click="ocrY"/>
                        <br-anchor-button class="btn sm sch" label="미적용" @click="ocrN"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";

export default {
    props: {
        ocrAplcnYn: {type: String, default: 'N'},
    },
    created() {
        storeSwitch.setBulb('OcrYnPopup', this);
    },
    data() {
        return{
            isOn: false,
        }
    },
    methods: {
        show() {
            this.isOn = true;
        },
        hide() {
            this.isOn = false;
        },
        ocrY() {
            this.$emit('update:ocrAplcnYn', 'Y');
            this.hide();
        },
        ocrN() {
            this.$emit('update:ocrAplcnYn', 'N');
            this.hide();
        },
    }
}
</script>
