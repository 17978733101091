<template>
  <div class="tab_area">
    <ul class="tab">
      <li
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{active: activeTab === index}"
          @click="selectTab(index)"
      >
        <button type="button" class="btn_tab">
          {{ tab.name }}
          <i v-if="activeTab === index" class="sr_only">선택됨</i>
        </button>
      </li>
    </ul>
    <div class="tab_conts_wrap">
      <section class="tab_conts" v-show="activeTab === 0">
        <h3 class="title2">실적 현황</h3>
        <sb-grid
            id="prfmncGrid"
            :dataSource="prfmncDataSource"
            :columns="prfmncGridColumns"
            :gridattr="prfmncGridAttr"
            v-model:grid="prfmncGridObject"
            @loaded="prfmncGridLoaded"
            :refreshGrid="prfmncGridReload"
        />
      </section>
      
      <section class="tab_conts" v-show="activeTab === 1">
        <h3 class="title2">폐기물 지역별 반입 현황</h3>
        <sb-grid
            id="crynGrid"
            :dataSource="crynDataSource"
            :columns="crynGridColumns"
            :gridattr="crynGridAttr"
            v-model:grid="crynGridObject"
            @loaded="crynGridLoaded"
            :refreshGrid="crynGridReload"
        />
      </section>
      
      <section class="tab_conts" v-show="activeTab === 2">
        <h3 class="title2">사업장별 에너지 판매 현황</h3>
        <sb-grid
            id="ntslDtlGrid"
            :dataSource="ntslDtlDataSource"
            :columns="ntslDtlGridColumns"
            :gridattr="ntslDtlGridAttr"
            v-model:grid="ntslDtlGridObject"
            @loaded="ntslDtlGridLoaded"
            :refreshGrid="ntslDtlGridReload"
        />
      </section>
    </div>
  </div>
</template>

<script>
import SbGrid from "@/components/SbGrid.vue";
import { storeSwitch } from "@/js/store/store-switch";

const API = {
  PERFORMANCE_STATUS: "/prfmnc/fillGas/prfmnc-stts",
  CARRY_IN_STATUS: "/prfmnc/fillGas/cryn-stts",
  NET_SALES_DETAILS_STATUS: "/prfmnc/fillGas/ntsl-dtl-stts",
};

export default {
  components: {SbGrid},
  props: ['param'],
  data() {
    return {
      tabs: [
        { name: '실적현황' },
        { name: '반입상세현황' },
        { name: '판매상세현황' },
      ],
      activeTab: 0,
      prfmncGridReload: false,
      prfmncGridObject: null,
      prfmncDataSource: [],
      prfmncGridColumns: [],
      prfmncGridAttr: {
        showRowNo: false,
        pageable: false,
        excelExport: {
          fileName: '실적 현황.xlsx',
          includeHeader: true,
        },
        height: 515,
      },
      crynGridReload: false,
      crynGridObject: null,
      crynDataSource: [],
      crynGridColumns: [],
      crynGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        excelExport: {
          fileName: '폐기물 지역별 반입 현황.xlsx',
          keepMerge: true,
        },
      },
      ntslDtlGridReload: false,
      ntslDtlGridObject: null,
      ntslDtlDataSource: [],
      ntslDtlGridColumns: [],
      ntslDtlGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        excelExport: {
          fileName: '업체별 에너지 판매 현황.xlsx',
          includeHeader: true,
          keepMerge: true,
        }
      },
      prfmncInfo: {},
      crynInfo: {},
      ntslDtlInfo: {},
    };
  },
  computed: {},
  watch: {
    activeTab() {
      this.search();
    }
  },
  created() {
    this.initialize();
  },
  mounted() {
  },
  methods: {
    async initialize() {
      storeSwitch.on("loading");
      await this.loadPrfmncInfo();
      this.initializePrfmncInfo();
      await this.loadCrynInfo();
      this.initializeCrynInfo();
      await this.loadNtslDtlInfo();
      this.initializeNtslDtl();
      storeSwitch.off("loading");
    },
    loadPrfmncInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.PERFORMANCE_STATUS,
            { bplcId: this.param.bplcId, prfmncYr: this.param.prfmncYr },
            data => {
              if (data.result) {
                this.prfmncInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load prfmnc info."));
            }
        );
      });
    },
    loadCrynInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.CARRY_IN_STATUS,
            { bplcId: this.param.bplcId, prfmncYr: this.param.prfmncYr },
            data => {
              if (data.result) {
                this.crynInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load cryn info."));
            }
        );
      });
    },
    loadNtslDtlInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.NET_SALES_DETAILS_STATUS,
            { bplcId: this.param.bplcId, prfmncYr: this.param.prfmncYr },
            data => {
              if (data.result) {
                this.ntslDtlInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load ntsl dtl info."));
            }
        );
      });
    },
    initializePrfmncInfo() {
      this.prfmncDataSource = this.prfmncInfo.list;
      this.initializePrfmncGridColumns();
      this.initializePrfmncGridAttr();
      this.prfmncGridReload = true;
    },
    initializeCrynInfo() {
      this.crynDataSource = this.crynInfo.list;
      this.initializeCrynGridColumns();
      this.initializeCrynGridAttr();
      this.crynGridReload = true;
    },
    initializeNtslDtl() {
      this.ntslDtlDataSource = this.ntslDtlInfo.list;
      this.initializeNtslDtlGridColumns();
      this.initializeNtslDtlGridAttr();
      this.ntslDtlGridReload = true;
    },
    initializePrfmncGridColumns() {
      this.prfmncGridColumns = [
        { field: 'prfmncId', caption: '실적아이디', visible: false },
        { field: 'prfmncYm', caption: '실적연월', type: 'date', width: 100, part: 'head',
          total: { headerCss:'grid-header' },
          format: (date) => {
            if (date && date.length === 6) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              return `${yyyy}-${mm}`;
            }
            return date;
          },
        },
        { field: 'prfmncSttsNm', caption: '실적상태', type: 'date', width: 100,
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          },
        },/*
        { field: 'fillWtTon', caption: '매립량(톤)', width: 140, align: 'right',
          dataType:'number', format: '#,##0.00',
          total: {
            aggregates: [{func: 'sum', require: 'fillWtTon'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },*/
        { caption: '폐기물 반입현황',
          columns: [
            { field: 'wtStotSumCryqty', caption: '반입량 합계', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'wtStotSumCryqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'bplcDschrgSumWstqty', caption: '배출시 설계', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'bplcDschrgSumWstqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'hmeLifeSumWstqty', caption: '가정생활', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'hmeLifeSumWstqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'bplcLifeSumWstqty', caption: '사업장생활', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'bplcLifeSumWstqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'largeSumWstqty', caption: '대형 폐기물', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'largeSumWstqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'foodSumWstqty', caption: '음식물 폐기물', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'foodSumWstqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'tmbrSumWstqty', caption: '목재', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'tmbrSumWstqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'inmtSumWstqty', caption: '소각재', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'inmtSumWstqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'wstvnlSumWstqty', caption: '영농 폐비닐', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'wstvnlSumWstqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'etcSumWstqty', caption: '기타', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'etcSumWstqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
          ]
        },
        { caption: '가스 포집현황',
          columns: [
            { field: 'ocrnGasClcqty', caption: '발생가스총포집량', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'ocrnGasClcqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'mthnGasClcqty', caption: '메탄가스 포집량', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'mthnGasClcqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'mthnGasDens', caption: '메탄가스 농도', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'mthnGasDens'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
          ]
        },
        { caption: '에너지 생산',
          columns: [
            { field: 'gasStotUsqty', caption: '생산량 합계', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'gasStotUsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'elctgnUsgGasUsqty', caption: '발전', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'elctgnUsgGasUsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'cdysmUsgGasUsqty', caption: '냉난방', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'cdysmUsgGasUsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'gasSplyUsgGasUsqty', caption: '가스공급', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'gasSplyUsgGasUsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'vhclFuelUsgGasUsqty', caption: '차량연료', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'vhclFuelUsgGasUsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'etcUsgGasUsqty', caption: '기타', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'etcUsgGasUsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
          ]
        },
        { field: 'enePrdctnExcqty', caption: '에너지 생산 외', width: 140, align: 'right',
          dataType:'number', format: '#,##0.00',
          total: {
            aggregates: [{func: 'sum', require: 'enePrdctnExcqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { caption: '에너지 판매',
          columns: [
            { field: 'gasStotSumNsqty', caption: '판매량 합계', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'gasStotSumNsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'elctgnUsgGasSumNsqty', caption: '발전', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'elctgnUsgGasSumNsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'cdysmUsgGasSumNsqty', caption: '냉난방', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'cdysmUsgGasSumNsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'gasSplyUsgGasSumNsqty', caption: '가스공급', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'gasSplyUsgGasSumNsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'vhclFuelUsgGasSumNsqty', caption: '차량연료', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'vhclFuelUsgGasSumNsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'etcUsgGasSumNsqty', caption: '기타', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'etcUsgGasSumNsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
          ]
        },
      ]
    },
    initializePrfmncGridAttr() {
    },
    initializeCrynGridColumns() {
      this.crynGridColumns = [
        { field: 'prfmncId', caption: '실적아이디', visible: false },
        { field: 'prfmncYm', caption: '실적연월', type: 'date', width: 100, mergeRow: true, part: 'head',
          total: { headerCss:'grid-header' },
          format: (date) => {
            if (date && date.length === 6) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              return `${yyyy}-${mm}`;
            }
            return date;
          },
        },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false },
        { field: 'ctpvStdgNm', caption: '시·도', width: 110,
          mergeRow: (data) => data.prfmncYm + '.' + data.ctpvStdgCd,
          total: { headerCss:'grid-header' }
        },
        {
          field: 'sggStdgNm', caption: '시·군·구', width: 110,
          total: {
            aggregates: [
            ],
            header: [
              { template: '합계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        { caption: '반입량(톤)',
          columns: [
            { field: 'wtStotSumCryqty', caption: '반입량 합계', width: 140, align: 'right', dataType:'number', format: '#,##0.00',
              total: this.gridTotalSubSumObj('wtStotSumCryqty')
            },
            { field: 'bplcDschrgSumWstqty', caption: '배출시설계', width: 140, align: 'right', dataType:'number', format: '#,##0.00',
              total: this.gridTotalSubSumObj('bplcDschrgSumWstqty')
            },
            { field: 'hmeLifeSumWstqty', caption: '가정생활', width: 140, align: 'right', dataType:'number', format: '#,##0.00',
              total: this.gridTotalSubSumObj('hmeLifeSumWstqty')
            },
            { field: 'bplcLifeSumWstqty', caption: '사업장생활', width: 140, align: 'right', dataType:'number', format: '#,##0.00',
              total: this.gridTotalSubSumObj('bplcLifeSumWstqty')
            },
            { field: 'largeSumWstqty', caption: '대형폐기물', width: 140, align: 'right', dataType:'number', format: '#,##0.00',
              total: this.gridTotalSubSumObj('largeSumWstqty')
            },
            { field: 'foodSumWstqty', caption: '음식류폐기물', width: 140, align: 'right', dataType:'number', format: '#,##0.00',
              total: this.gridTotalSubSumObj('foodSumWstqty')
            },
            { field: 'tmbrSumWstqty', caption: '폐목재', width: 140, align: 'right', dataType:'number', format: '#,##0.00',
              total: this.gridTotalSubSumObj('tmbrSumWstqty')
            },
            { field: 'inmtSumWstqty', caption: '소각재', width: 140, align: 'right', dataType:'number', format: '#,##0.00',
              total: this.gridTotalSubSumObj('inmtSumWstqty')
            },
            { field: 'wstvnlSumWstqty', caption: '영농폐비닐', width: 140, align: 'right', dataType:'number', format: '#,##0.00',
              total: this.gridTotalSubSumObj('wstvnlSumWstqty')
            },
            { field: 'etcSumWstqty', caption: '기타', width: 140, align: 'right', dataType:'number', format: '#,##0.00',
              total: this.gridTotalSubSumObj('etcSumWstqty')
            },
          ]
        }
      ]
    },
    initializeCrynGridAttr() {
    },
    initializeNtslDtlGridColumns() {
      this.ntslDtlGridColumns = [
        { field: 'prfmncId', caption: '실적아이디', visible: false },
        { field: 'prfmncYm', caption: '실적연월', type: 'date', width: 100, mergeRow: true, part: 'head',
          total: { headerCss:'grid-header' },
          format: (date) => {
            if (date && date.length === 6) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              return `${yyyy}-${mm}`;
            }
            return date;
          },
        },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false },
        { field: 'ctpvStdgNm', caption: '시·도', width: 110,
          mergeRow: (data) => data.prfmncYm + '.' + data.ctpvStdgCd,
          total: { headerCss:'grid-header' },
        },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false },
        { field: 'sggStdgNm', caption: '시·군·구', width: 110,
          mergeRow: (data) => data.prfmncYm + '.' + data.ctpvStdgCd + "." + data.sggStdgCd,
          total: { headerCss:'grid-header' },
        },
        { field: 'bzentyNm', caption: '업체명', width: 200, align: 'left',
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          },
        },
        { caption: '에너지 판매',
          columns: [
            { field: 'gasStotNsqty', caption: '판매량 합계', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'gasStotNsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'elctgnUsgGasNsqty', caption: '발전', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'elctgnUsgGasNsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'cdysmUsgGasNsqty', caption: '냉난방', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'cdysmUsgGasNsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'gasSplyUsgGasNsqty', caption: '가스공급', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'gasSplyUsgGasNsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'vhclFuelUsgGasNsqty', caption: '차량연료', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'vhclFuelUsgGasNsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'etcUsgGasNsqty', caption: '기타', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'etcUsgGasNsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
          ]
        },
      ]
    },
    initializeNtslDtlGridAttr() {
    },
    prfmncGridLoaded() {
      this.prfmncGridReload = false;
    },
    crynGridLoaded() {
      this.crynGridReload = false;
    },
    ntslDtlGridLoaded() {
      this.ntslDtlGridReload = false;
    },
    selectTab(index) {
      this.activeTab = index;
    },
    async prfmncSearch() {
      await this.loadPrfmncInfo();
      window.SBGrid3.setClientData(this.prfmncGridObject, this.prfmncInfo.list || []);
    },
    async crynSearch() {
      await this.loadCrynInfo();
      window.SBGrid3.setClientData(this.crynGridObject, this.crynInfo.list || []);
    },
    async ntslDtlSearch() {
      await this.loadNtslDtlInfo();
      window.SBGrid3.setClientData(this.ntslDtlGridObject, this.ntslDtlInfo.list || []);
    },
    async search() {
      storeSwitch.on("loading");

      switch (this.activeTab) {
        case 0: // 실적현황
            await this.prfmncSearch();
          break;
        case 1: // 반입상세현황
            await this.crynSearch();
          break;
        case 2: // 판매상세현황
            await this.ntslDtlSearch();
          break;
      }

      storeSwitch.off("loading");
    },
    gridTotalSubSumObj(field){
      return {
        aggregates: [
          {
            func: (items) => items
                .filter(entry => entry.rowType === 'SUB_SUM')
                .reduce((sum, entry) => sum + (entry[field] || 0), 0),
            field: `${field}Total`
          }
        ],
        header: [
          { template: `{{${field}Total}}`, format: '#,##0.00' }
        ],
        headerCss:'grid-header',
      }
    }
  }
}
</script>

<style scoped>

</style>