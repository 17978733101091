<template>
  <div v-if="isVisible" class="modal visible">
    <div class="popup_box" tabindex="0">
      <h1 class="popup_title">본인 인증 안내</h1>
      <div class="popup_cont">
        <div class="desc_box progress_box">
          <i class="icon"><img src="@/assets/images/common/ico_noti.png" alt="이미지"></i>
          <div class="progress">
            <div>회원님의 원활한 서비스 이용을 위해 본인인증이 필요합니다. 지금 바로 인증하겠습니까?</div>
          </div>
        </div>
        <div class="btn_area center">
          <br-button label="본인인증" format="primary" size="md" @click="identityAuthOpen" />
          <br-button label="다음에 하기" format="primary line" size="md" @click="gotoMain"/>
        </div>
      </div>
      <button type="button" class="modal_close" @click="gotoMain">팝업 닫기</button>
    </div>
  </div>
  <identity-auth-modal :hasLogin="true" :isPopup="false" usageCode="01002" @callback="identityAuthCallback" @fail="identityAuthFail"/>
</template>
<script>
import {storeSwitch} from "@/js/store/store-switch";
import IdentityAuthModal from "@/components/IdentityAuthModal.vue";

const ROUTE = {
  MAIN: "/",
};

const API = {
  IDENTITY: "/auth/identity"
}

export default {
  components: {IdentityAuthModal},
  data() {
    return {
      isVisible: false,
    };
  },
  created() {
    storeSwitch.setBulb('IdentityAuthPopup', this);
  },
  mounted() {
  },
  methods : {
    async show() {
      this.popupShow();
    },
    hide() {
      this.isVisible = false;
      document.documentElement.style.overflow = '';
    },
    popupShow() {
      document.documentElement.style.overflow = 'hidden';
      this.isVisible = true;
    },
    identityAuthOpen() {
      storeSwitch.on('IdentityAuthModal');
    },
    identityAuthCallback(result) {
      console.log("identityAuthCallback", result);

      if (!result.isLoginUser) {
        alert('로그인 정보와 일치하지 않습니다.');
        this.gotoMain();
        return;
      }

      this.$apiCall.put(API.IDENTITY, { "provider": result.provider }, this.identityUpdateSuccess, this.identityUpdateFail);
    },
    identityAuthFail(error) {
      console.log("identityAuthFail", error);

      alert("본인인증에 실패했습니다.");
      this.gotoMain();
    },
    gotoMain() {
      this.hide();
      this.$router.push(ROUTE.MAIN).catch(() => {});
    },
    identityUpdateSuccess() {
      alert("본인인증이 완료되었습니다.");
      this.gotoMain();
    },
    identityUpdateFail(error) {
      console.log("identityUpdateFail", error);

      alert("본인인증에 실패했습니다.");
      this.gotoMain();
    }
  }
}
</script>
