<template>
  <div v-show="isOn" class="modal visible">
    <div class="popup_box" style="width: 70%; max-width: inherit">
      <h1 class="popup_title">사업장 검색</h1>
      <div>
        <button type="button" class="modal_close" @click.prevent="hide" >팝업닫기</button>
      </div>
      <div class="popup_cont">
        <div class="sch_form_wrap">
          <div class="sch_form row2">
            <span>
              <label for="">사업장명</label>
              <br-form-input class="form_control" label="사업장명" v-model="searchParam.bplcNm" @enterKeyup="search" />
            </span>
            <span>
              <label for="">사업자등록번호</label>
              <br-form-input class="form_control w40p" label="사업자등록번호" v-model="searchParam.brno" @enterKeyup="search" />
            </span>
          </div>
          <div class="btn_area">
            <br-anchor-button class="btn sm txtN ico_refresh" label="새로고침"  icon="reset" @click="reset" />
            <br-anchor-button class="btn sm sch" label="조회" icon="icon_search" @click="search" />
          </div>
        </div>
        <div class="board_info">
          <div class="board_info">
            <h3 class="title2">사업장 목록</h3>
          </div>
        </div>
          <sb-grid
              id="incmUseBplcPopupGrid"
              :dataSource="incmUseBplcPopupDataSource"
              :columns="incmUseBplcPopupGridColumns"
              :gridattr="incmUseBplcPopupGridAttr"
              v-model:grid="incmUseBplcPopupGridObject"
              :refreshGrid="incmUseBplcPopupGridReload"
              @loaded="incmUseBplcPopupGridLoaded"
              @row-clicked="onRowChangedIncm"
              @row-double-clicked="onRowDoubleClickedIncm"
              @add="addedOvrsIncmCnptSetData"
              :param="searchParam"
              :excel-btn-visible="false"
          />
       
          <div class="btn_area center">
            <br-button label="선택" class="btn md tertiary" @click="onClickedSelectButton" />
            <br-button label="닫기" class="btn md tertiary" @click="hide" />
          </div>
        </div>

      </div>
  </div>
</template>

<script>
/**
 * 공급처 사업장 목록 검색
 *
 * 작성자 : K
 * 작성일 : 24. 12. 12.
 * 버전 : 1.0
 */
import { storeSwitch } from "@/js/store/store-switch";
import SbGrid from "@/components/SbGrid.vue";

const API = {
  SRF_USE : "/incm-qlty-insp-stat/srf-use",
};


export default {
  components: {SbGrid},
  props: {
    bplcId: {type: String, required: true},
    userPopup: {type: Boolean, required: false},
  },
  emits: [ 'select-button-clicked' ],
  data() {
    return {
      values: [],
      isOn: false,
      bizfaciltyIncm: {
        bplcId : '',
        bplcNm: '',
        telno: '',
        brno: '',
        bplcAddr : ''
      },
      incmUseBplcPopupDataSource: {
        ajaxConfig: {
          select: { url: API.SRF_USE },
          paging: true,
        },
        schema: {
          id: 'bplcId'
        }
      },
      incmUseBplcPopupGridColumns: [],
      incmUseBplcPopupGridAttr: {
        checkable: false,
        showStatus:false,
        showRowNo: true,
        pageable: true,
        editable: false,
        height: 350,
      },
      incmUseBplcPopupGridObject: null,
      incmUseBplcPopupGridReload: false,
      rowData: [],
      searchParam: {
        bplcNm: '',
        brno: '',
      },
    };
  },
  computed: {
  },
  watch: {
    isOn(newVal) {
      if (!newVal) {
        this.reset();
      } else {
        window.SBGrid3.reload(this.incmUseBplcPopupGridObject);
      }
    },
  },
  created() {
    storeSwitch.setBulb('incmUseBplcPopup', this);
  },
  beforeMount() {

  },
  mounted() {
    this.initialize();
  },
  beforeUnmount() {},
  methods: {
    /** 팝업 Show */
    show() {
      this.reset();
      this.isOn = true;
    },
    /** 팝업 Hide */
    hide() {
      this.isOn = false;
    },
    /** 화면 초기화 */
    async initialize() {
      // 그리드 설정
      this.initializeIncmUseBplcPopupGridColumns();
      this.incmUseBplcPopupGridReload = true;
    },
    /** 그리드 컬럼 초기화 */
    initializeIncmUseBplcPopupGridColumns() {
      this.incmUseBplcPopupGridColumns = [      
        { field: 'bplcId', caption: '사업장아이디', part: '', width: 100, type: 'text', editable: false, visible: true, align: 'center' },
        { field: 'bplcNm', caption: '사업장명', part: '', width: 200, type: 'text', editable: false, visible: true, align: 'center' },
        { field: 'bzentyNm', caption: '업체명', part: '', width: 120, type: 'text', editable: false, visible: true, align: 'center' },
        { field: 'telno', caption: '전화번호', part: '', width: 120, editable: false, visible: true, align: 'center'},
        { field: 'brno', caption: '사업자등록번호', part: '', width: 120, type: 'text', editable: false, visible: true, align: 'center' },
        { field: 'bplcAddr', caption: '사업장주소', part: '', width: 350, type: 'text', editable: false, visible: true, align: 'left' },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', part: '', width: 100, type: 'text', editable: false, visible: false, align: 'left' },
        { field: 'sggStdgCd', caption: '시군구법정동코드', part: '', width: 100, type: 'text', editable: false, visible: false, align: 'left' },        
        { field: 'bplcSttsCd', caption: '사업장상태코드', part: '', width: 100, type: 'text', editable: false, visible: false, align: 'left' },
      ]
    },
    /** 그리드 데이터로드 완료 이벤트 */
    incmUseBplcPopupGridLoaded() {
      this.incmUseBplcPopupGridReload = false;
    },
    /** 사업장 정보 선택 */
    onRowChangedIncm(data) {
      this.bizfaciltyIncm =  {...data};
    },
   
    /** 선택 버튼 클릭 */
    onClickedSelectButton() {
      if(this.bizfaciltyIncm.bplcId) {
        this.$emit('select-button-clicked', this.bizfaciltyIncm);
        this.isOn = false;
        this.reset();
      } else {
        alert('선택된 사업장이 없습니다.')
      }
    },
    
    onRowDoubleClickedIncm(data) {
      this.bizfaciltyIncm =  {...data};
      this.onClickedSelectButton();
    },
     /** 데이터 조회 */
     search() {
      window.SBGrid3.reload(this.incmUseBplcPopupGridObject);
    },
    reset() {
      for (const key of Object.keys(this.searchParam)) {
        this.searchParam[key] = '';
      }
      this.rowData = [];
    },
  }
}
</script>

<style scoped>

</style>