<template>
  <div class="desc_box">
    <i class="icon"><img src="@/assets/images/common/ico_desc.png" alt="이미지"></i>
    <div class="desc">
      <strong class="desc_title">고형연료제품 품질 향상을 위한 원료 선별 요령을 알려드립니다.</strong>
      <p>고형연료제품의 제조를 위해서는 품질(시설) 검사와 신고가 필요합니다.<br>(관련근거 : 자원의 절약과 재활용 촉진에 관한 법률 제25의 4)</p>
    </div>
  </div>

  <h2 class="title1 mt_0">안전한 원료</h2>
  <p>안전한 원료 사용에 의한 제품의 품질 향상이 귀사의 가치를 높입니다.</p>
  <ul class="material_tip">
    <li>
      <div class="thumb"><img src="@/assets/images/srfEneIntro/material_selection_tips_01.png" alt="각종 식품 포장재"></div>
      <span>각종 식품 포장재</span>
    </li>
    <li>
      <div class="thumb"><img src="@/assets/images/srfEneIntro/material_selection_tips_02.png" alt="커피 포장재"></div>
      <span>커피 포장재</span>
    </li>
    <li>
      <div class="thumb"><img src="@/assets/images/srfEneIntro/material_selection_tips_03.png" alt="라면/과자 포장재"></div>
      <span>라면/과자 포장재</span>
    </li>
  </ul>

  <h2 class="title1">부적정 원료</h2>
  <p><strong>(분쇄장치 등 기기고장 유발)</strong> 부적정 원료의 사용은 제품 생산 효율 저하와 유해 물질을 발생시킵니다.</p>
  <ul class="material_tip">
    <li>
      <div class="thumb"><img src="@/assets/images/srfEneIntro/material_selection_tips_04.png" alt="고무 장갑"></div>
      <span>고무 장갑</span>
    </li>
    <li>
      <div class="thumb"><img src="@/assets/images/srfEneIntro/material_selection_tips_05.png" alt="비닐 끈"></div>
      <span>비닐 끈</span>
    </li>
    <li>
      <div class="thumb"><img src="@/assets/images/srfEneIntro/material_selection_tips_06.png" alt="이불"></div>
      <span>이불</span>
    </li>
    <li>
      <div class="thumb"><img src="@/assets/images/srfEneIntro/material_selection_tips_07.png" alt="옷걸이 등 철사류"></div>
      <span>옷걸이 등 철사류</span>
    </li>
    <li>
      <div class="thumb"><img src="@/assets/images/srfEneIntro/material_selection_tips_08.png" alt="신발류"></div>
      <span>신발류</span>
    </li>
  </ul>
  <p class="mt_40"><strong>(중금속 등 유해물질 함유 우려)</strong> 부적정 원료의 사용은 제품 생산 효율 저하와 유해 물질을 발생시킵니다.</p>
  <ul class="material_tip">
    <li>
      <div class="thumb"><img src="@/assets/images/srfEneIntro/material_selection_tips_09.png" alt="마대자루(중금속)"></div>
      <span>마대자루(중금속)</span>
    </li>
    <li>
      <div class="thumb"><img src="@/assets/images/srfEneIntro/material_selection_tips_10.png" alt="고무호스(황)"></div>
      <span>고무호스(황)</span>
    </li>
    <li>
      <div class="thumb"><img src="@/assets/images/srfEneIntro/material_selection_tips_11.png" alt="인조가죽(황)"></div>
      <span>인조가죽(황)</span>
    </li>
  </ul>

  <h2 class="title1">완전 배제 원료</h2>
  <p>잠시만요! 이런 원료는 절대 안돼요!</p>
  <ul class="material_tip">
    <li>
      <div class="thumb"><img src="@/assets/images/srfEneIntro/material_selection_tips_12.png" alt="PVC(납, 염소)"></div>
      <span>PVC(납, 염소)</span>
    </li>
    <li>
      <div class="thumb"><img src="@/assets/images/srfEneIntro/material_selection_tips_13.png" alt="전선피복(염소)"></div>
      <span>전선피복(염소)</span>
    </li>
    <li>
      <div class="thumb"><img src="@/assets/images/srfEneIntro/material_selection_tips_14.png" alt="수액세트(염소)"></div>
      <span>수액세트(염소)</span>
    </li>
    <li>
      <div class="thumb"><img src="@/assets/images/srfEneIntro/material_selection_tips_15.png" alt="음식물(염소)"></div>
      <span>음식물(염소)</span>
    </li>
    <li>
      <div class="thumb"><img src="@/assets/images/srfEneIntro/material_selection_tips_16.png" alt="잔재물 포함된 음식물 용기(염소)"></div>
      <span>잔재물 포함된 음식물 용기(염소)</span>
    </li>
    <li>
      <div class="thumb"><img src="@/assets/images/srfEneIntro/material_selection_tips_17.png" alt="망간/리튬/수은전지(카드뮴, 수은)"></div>
      <span>망간/리튬/수은전지(카드뮴, 수은)</span>
    </li>
    <li>
      <div class="thumb"><img src="@/assets/images/srfEneIntro/material_selection_tips_18.png" alt="전자 제품(중금속)"></div>
      <span>전자 제품(중금속)</span>
    </li>
    <li>
      <div class="thumb"><img src="@/assets/images/srfEneIntro/material_selection_tips_19.png" alt="방부목(비소)"></div>
      <span>방부목(비소)</span>
    </li>
  </ul>

  <div class="info_box mt_40">
    <strong class="info_title">문의 안내</strong>
    <ul class="dot_list">
      <li>주소 : 인천광역시 서구 환경로 42(경서동 종합환경연구단지) 한국환경공단 폐자원에너지센터 폐자원에너지검사부</li>
      <li>전화 : 032-590-5427</li>
      <li>메일 : srf-fa@keco.or.kr</li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {}
}
</script>

<style scoped>

</style>