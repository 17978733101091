<template>
  <div class="tab_conts_wrap">
    <section id="dep1Tabpanel02" class="tab_conts active">
      <template v-if="bzentyAprvYn === 'Y'">
        <!-- 나의 관리 사업장 -->
        <div class="board_info mt_40" style="position: relative;">
          <h3 class="title2">나의 관리 사업장</h3>
          <div class="page_button_area">
            <br-button label="나의 사업장 관리" format="tertiary" size="sm" icon="search" @click="callPopupMyBplcMng"/>
          </div>
        </div>
        <div class="board_info" style="margin-bottom: 0px;">
          <p class="page" >
            <span class="total">전체 <strong>{{ this.bplcMyMngBplcCount }}</strong></span>
            <span>페이지 <strong>{{ this.searchParam.page }}/{{ this.maxPage === 0 ? 1 : this.maxPage }}</strong></span>
          </p>
        </div>
        <div class="board_list scroll">
          <table style="table-layout: fixed; width: 102rem" >
            <caption>나의 관리 사업장</caption>
            <colgroup>
              <col style="width:220px">
              <col style="width:15rem">
              <col style="width:50rem">
              <col style="width:15rem">
            </colgroup>
            <thead>
            <tr>
              <th scope="col">사업장명</th>
              <th scope="col">사업장종류</th>
              <th scope="col">주소</th>
              <th scope="col">관리권한</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="bplcMyMngBplcList.length <= 0">
              <td colspan="12">
                <div style="display: flex; justify-content: center;">
                  <div class="no-data-message">조회조건에 해당하는 데이터가 없습니다.</div>
                </div>
              </td>
            </tr>
            <tr v-else v-for="(item, index) in bplcMyMngBplcList" :key="index"
                :class="{ selected: selectedRowIndex === index }" @click="selectedRow(item, index)">
              <td aria-label="사업장명">{{ item.bplcNm }}</td>
              <td aria-label="사업장종류" style="text-align: center">{{ getCdName(this.bplcKndCdList, item.bplcKndCd) }}</td>
              <td aria-label="주소" style="text-align: left"> {{ item.untyZip ? `[${item.untyZip}] ${item.bplcAddr} ${item.bplcDaddr ?? ''}` : `${item.bplcAddr} a${item.bplcDaddr ?? ''}` }}</td>
              <td aria-label="승인상태" style="text-align: center">{{ getCdName(this.bplcMngPicSttsCdList, item.bplcMngPicSttsCd)  }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <insp-pagination :total="bplcMyMngBplcCount" :size="searchParam.size" v-model:page="searchParam.page" v-model:maxPage="maxPage" @clickedPage="searchMyMngBplc"/>
        <!-- 보완요청/반려 안내문구 -->
        <template v-if="['02', '03'].includes(bizfaciltyBplc.bplcChgAplySttsCd)">
          <div class="desc_box progress_box">
            <i class="icon"><img src="@/assets/images/common/ico_noti.png" alt="이미지"></i>
            <div class="progress">
              <div><strong>{{ bizfaciltyBplc.bplcChgAplySttsNm }}</strong>상태입니다.<span>{{ bizfaciltyBplc.bplcChgAplySttsNm }} 사유를 확인해주세요.</span></div>
            </div>
          </div>
        </template>
        <!-- 사업장 정보변경 이력 -->
        <template v-if="bizfaciltyBplc.bplcId && this.bplcMngPicSttsCd === '02'">
          <div class="board_info mt_40" style="position: relative;">
            <h3 class="title2">사업장 정보변경신청 이력</h3>
            <div class="page_button_area">
              <br-button format="tertiary" size="sm" icon="search" label="사업장 정보변경 이력" @click="callPopupBplcChgHstryPopup" />
            </div>
          </div>
          <div class="board_info" style="margin-bottom: 0px;">
            <p class="page" >
              <span class="total">전체 <strong>{{ this.bplcInfoChgHstryCount }}</strong></span>
              <span>페이지 <strong>{{ this.hstrySearchParam.page }}/{{ this.hstryMaxPage === 0 ? 1 : this.hstryMaxPage }}</strong></span>
            </p>
          </div>
          <div class="board_list">
            <table style="table-layout: fixed; ">
              <caption> 사업장정보 변경이력</caption>
              <colgroup>
                <col style="width:210px">
                <col style="width:14rem">
                <col style="width:24rem">
                <col style="width:27rem">
                <col style="width:12rem">
              </colgroup>
              <thead>
              <tr>
                <th scope="col">일시</th>
                <th scope="col">처리유형</th>
                <th scope="col">상세처리상태</th>
                <th scope="col">처리사유</th>
                <th scope="col">처리자</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="bplcInfoChgHstryList.length <= 0">
                <td colspan="12">
                  <div style="display: flex; justify-content: center;">
                    <div class="no-data-message">조회조건에 해당하는 데이터가 없습니다.</div>
                  </div>
                </td>
              </tr>
              <tr v-else v-for="(item, index) in bplcInfoChgHstryList" :key="index">
                <td aria-label="일시">{{item.prcsDt }}</td>
                <td aria-label="처리유형"> {{ getCdName(this.chgAplySeCdList, item.chgAplySeCd) }}</td>
                <td aria-label="상세처리상태" style="text-align: center">
                  {{ getCdName(this.chgAplyPrcsSttsCdList, item.dtlChgAplyPrcsSttsCd) }}
                  <br-button v-if="item.dtlChgAplyPrcsSttsCd === '02' && index === 0" label="조치하기" class="ml_5 xsm tertiary" style="height: 2.6rem;" @click="takeAction(item)"/>
                  <br-button v-if="['01', '04'].includes(item.dtlChgAplyPrcsSttsCd)" label="변경정보확인" class="ml_5 xsm tertiary" style="height: 2.6rem;" @click="callPopupBplcChgAplyInfo(item)"/>
                </td>
                <td aria-label="처리사유" style="text-align: left;">{{item.prcsRsn}}</td>
                <td aria-label="처리자">{{item.userNm}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <insp-pagination :total="bplcInfoChgHstryCount" :size="hstrySearchParam.size" v-model:page="hstrySearchParam.page" v-model:maxPage="hstryMaxPage" @clickedPage="searchChgHstry"/>
        </template>
        <!-- 사업장 정보 -->
        <div class="board_info">
          <h3 class="title2">사업장 정보</h3>
          <div>
            <br-button label="신규 사업장 등록" format="tertiary" size="sm" icon="search" @click="callPopupCreateBplc"/>
          </div>
        </div>
        <template v-if="bizfaciltyBplc.bplcId && this.bplcMngPicSttsCd === '02'">
          <div class="board_write">
            <table>
              <caption></caption>
              <colgroup>
                <col style="width:15%">
                <col style="width:35%">
                <col style="width:15%">
                <col style="width:35%">
              </colgroup>
              <tbody>
              <tr>
                <th scope="row">사업장 명</th>
                <td>
                  {{this.bizfaciltyBplc.bplcNm}}
                </td>
                <th scope="row">사업장 명(영문)</th>
                <td>
                  {{this.bizfaciltyBplc.engBplcNm}}
                </td>
              </tr>
              <!--  초기 데이터 시작 -->
              <template v-if="!isEditMode">
                <tr>
                  <th scope="row">사업장 종류</th>
                  <td>
                    {{getCdName(this.bplcKndCdList, this.bizfaciltyBplc.bplcKndCd)}}
                  </td>

                  <th scope="row">담당자 명</th>
                  <td>
                    {{this.bizfaciltyBplc.picNm}}
                  </td>
                </tr>
                <tr>
                  <th scope="row">전화번호</th>
                  <td>
                    <div class="input_group">
                      {{ this.bizfaciltyBplc.telno === '--' ? '' : this.bizfaciltyBplc.telno }}
                    </div>
                  </td>
                  <th scope="row">FAX</th>
                  <td>
                    <div class="input_group">
                      {{ this.bizfaciltyBplc.fxno === '--' ? '' : this.bizfaciltyBplc.fxno }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">주소</th>
                  <td colspan="3">
                    {{ `[${this.bizfaciltyBplc.untyZip}] ${this.bizfaciltyBplc.bplcAddr} ${this.bizfaciltyBplc.bplcDaddr}` }}
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income">
                  <th scope="row">위탁운영관리</th>
                  <td>
                    {{ this.bizfaciltyBplc.cnsgnOperMngYn ==='Y' ? '대상' : '대상아님'}}
                  </td>
                  <th scope="row">사업장 승인상태</th>
                  <td :style="bizfaciltyBplc.aprvYn === 'N' ? 'color: red' : '' ">
                    {{getCdName(this.aprvYnCdList, bizfaciltyBplc.aprvYn)}}
                  </td>
                </tr>
                <tr v-if="'Y' === bizfaciltyBplc.cnsgnOperMngYn">
                  <th scope="row">위탁업체명</th>
                  <td>
                    {{this.bizfaciltyBplc.cnsgnBzentyNm}}
                  </td>
                  <th scope="row">위탁업체 사업자등록번호</th>
                  <td>
                    {{this.bizfaciltyBplc.cnsgnBzentyBrno}}
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income">
                  <th scope="row">설치신고 여부</th>
                  <td colspan="3">
                    <div class="chk_area">
                      <div class="form_check">
                        <input type="checkbox" v-model="bizfaciltyBplc.prcsFcltInstlDclrYn" id="agree_chk21" true-value="Y" false-value="N" disabled>
                        <label for="agree_chk21">처리시설 설치신고</label>
                      </div>
                      <div class="form_check">
                        <input type="checkbox" v-model="bizfaciltyBplc.atmoDschrgFcltInstlDclrYn" id="agree_chk22" true-value="Y" false-value="N" disabled>
                        <label for="agree_chk22">대기배출시설 설치신고</label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income">
                  <th scope="row">재활용 업체 여부</th>
                  <td colspan="3">
                    <div class="chk_area">
                      <div class="form_check">
                        <input type="checkbox" v-model="bizfaciltyBplc.mdRecyBzentyYn" id="agree_chk21" true-value="Y" false-value="N" disabled>
                        <label for="agree_chk21">중간 재활용업체</label>
                      </div>
                      <div class="form_check">
                        <input type="checkbox" v-model="bizfaciltyBplc.lastRecyBzentyYn" id="agree_chk22" true-value="Y" false-value="N" disabled>
                        <label for="agree_chk22">최종 재활용업체</label>
                      </div>
                      <div class="form_check">
                        <input type="checkbox" v-model="bizfaciltyBplc.snthsRecyBzentyYn" id="agree_chk23" true-value="Y" false-value="N" disabled>
                        <label for="agree_chk23">종합 재활용업체</label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income">
                  <th scope="row">처분 업체 여부</th>
                  <td colspan="3">
                    <div class="chk_area">
                      <div class="form_check">
                        <input type="checkbox" v-model="bizfaciltyBplc.mdDspsBzentyYn" id="agree_chk21" true-value="Y" false-value="N" disabled>
                        <label for="agree_chk21">중간 처분업체</label>
                      </div>
                      <div class="form_check">
                        <input type="checkbox" v-model="bizfaciltyBplc.lastDspsBzentyYn" id="agree_chk22" true-value="Y" false-value="N" disabled>
                        <label for="agree_chk22">최종 처분업체</label>
                      </div>
                      <div class="form_check">
                        <input type="checkbox" v-model="bizfaciltyBplc.snthsDspsBzentyYn" id="agree_chk23" true-value="Y" false-value="N" disabled>
                        <label for="agree_chk23">종합 처분업체</label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income">
                  <th scope="row">설치운영허가번호</th>
                  <td>
                    {{this.bizfaciltyBplc.instlOperPrmsnNo}}
                  </td>
                  <th scope="row">설치운영허가일자</th>
                  <td>
                    {{this.bizfaciltyBplc.instlOperPrmsnYmd}}
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income">
                  <th scope="row">신고허가번호</th>
                  <td>
                    {{this.bizfaciltyBplc.dclrPrmsnNo}}
                  </td>
                  <th scope="row">신고허가일자</th>
                  <td>
                    {{this.bizfaciltyBplc.dclrPrmsnYmd}}
                  </td>
                </tr>
                <tr v-if="conds.showWes && !conds.income">
                  <th scope="row">자원순환시설 ID</th>
                  <td colspan="3">
                    {{this.bizfaciltyBplc.rsrcRcyclnFcltId}}
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income" >
                  <th scope="row">인원수</th>
                  <td>
                    {{formatNumber(this.bizfaciltyBplc.nope)}}
                  </td>
                  <th scope="row">운영금액</th>
                  <td>
                    {{formatNumber(this.bizfaciltyBplc.operAmt)}}
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income" >
                  <th scope="row">편익시설 종류</th>
                  <td>
                    {{this.bizfaciltyBplc.cnvncFcltKndNm}}
                  </td>
                  <th scope="row">편익시설 설치비용</th>
                  <td>
                    {{formatNumber(this.bizfaciltyBplc.cnvncFcltInstlCst)}}
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income" >
                  <th scope="row">편익시설 운영기관</th>
                  <td>
                    {{this.bizfaciltyBplc.cnvncFcltOperInstNm}}
                  </td>
                  <th scope="row">편익시설 비고</th>
                  <td>
                    {{this.bizfaciltyBplc.cnvncFcltRmrkCn}}
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income" >
                  <th scope="row">총 사업비</th>
                  <td>
                    {{formatNumber(this.bizfaciltyBplc.tpcoAmt)}}
                  </td>
                  <th scope="row">사업장 상태</th>
                  <td>
                    {{getCdName(this.bplcSttsCdList , this.bizfaciltyBplc.bplcSttsCd)}}
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income" >
                  <th scope="row">사업기간</th>
                  <td colspan="3">
                    {{this.bizfaciltyBplc.bizBgngYmd}} <span v-if="this.bizfaciltyBplc.bizBgngYmd && this.bizfaciltyBplc.bizEndYmd">~</span> {{this.bizfaciltyBplc.bizEndYmd}}
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income" >
                  <th scope="row">국고금액</th>
                  <td>
                    {{formatNumber(this.bizfaciltyBplc.gvcfrAmt)}}
                  </td>
                  <th scope="row">지방비금액</th>
                  <td>
                    {{formatNumber(this.bizfaciltyBplc.lgscAmt)}}
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income" >
                  <th scope="row">민자금액</th>
                  <td>
                    {{formatNumber(this.bizfaciltyBplc.pvtcptAmt)}}
                  </td>
                  <th scope="row">기타금액</th>
                  <td>
                    {{formatNumber(this.bizfaciltyBplc.etcAmt)}}
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income" >
                  <th scope="row">사용자 메모 내용</th>
                  <td colspan="3">
                    {{this.bizfaciltyBplc.userMemoCn}}
                  </td>
                </tr>
                <!--  초기 데이터 끝 -->
              </template>
              <template v-else>
                <!--  modifiedData 시작  -->
                <tr>
                  <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>사업장 종류</th>
                  <td>
                    {{getCdName(this.bplcKndCdList, modifiableData.bplcKndCd)}}
                  </td>
                  <th scope="row">담당자 명</th>
                  <td>
                    <br-form-input label="담당자명" v-model="modifiableData.picNm"/>
                  </td>
                </tr>
                <tr>
                  <th scope="row"><i class="necessary">필수입력</i>전화번호</th>
                  <td>
                    <mbr-form-telno label="전화번호"
                                    v-model:telNo1="modifiableData.telno1"
                                    v-model:telNo2="modifiableData.telno2"
                                    v-model:telNo3="modifiableData.telno3"
                                    :required="true"
                                    ref="telnoInput"/>
                  </td>
                  <th scope="row">FAX</th>
                  <td>
                    <mbr-form-telno label="FAX"
                                    v-model:telNo1="modifiableData.fxno1"
                                    v-model:telNo2="modifiableData.fxno2"
                                    v-model:telNo3="modifiableData.fxno3"
                                    ref="fxnoInput"/>
                  </td>
                </tr>
                <tr>
                  <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>주소</th>
                  <td colspan="3">
                    <div class="form_group_addr" style="display: flex; flex-direction: column;">
                      <div class="input_group" style="max-width: calc(50% - .6rem);">
                        <br-form-input label="우편번호" class="form_control addr_zip" style="text-align: left;" v-model="modifiableData.untyZip" disabled></br-form-input>
                        <br-button label="주소검색" format="primary" size="sm" @click="callPopupAddress" />
                      </div>
                      <div class="input_group">
                        <br-form-input label="주소" class="form_control" style="text-align: left;" v-model="modifiableData.bplcAddr" panel="panel4" required disabled></br-form-input>
                        <br-form-input label="상세주소" class="form_control" style="text-align: left;" v-model="modifiableData.bplcDaddr"></br-form-input>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">위탁운영관리</th>
                  <td colspan="3">
                    <div class="chk_area">
                      <br-form-radio label="위탁운영관리여부"  v-model:value="modifiableData.cnsgnOperMngYn" :options="radioBtnYnList" value-field="value" text-field="text" @change="setUnusedAtchFileList"/>
                    </div>
                  </td>
                </tr>
                <tr v-if="modifiableData.cnsgnOperMngYn === 'Y'">
                  <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>위탁업체명</th>
                  <td>
                    <br-form-input label="위탁업체명" :disabled="modifiableData.cnsgnOperMngYn === 'N'" v-model="modifiableData.cnsgnBzentyNm" panel="panel4" :required="modifiableData.cnsgnOperMngYn === 'Y'"/>
                  </td>
                  <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>위탁업체 사업자등록번호</th>
                  <td>
                    <br-form-input label="위탁업체사업자등록번호" :disabled="modifiableData.cnsgnOperMngYn === 'N'" v-model="modifiableData.cnsgnBzentyBrno" validator="brno" :maxlength="10" panel="panel4" :required="modifiableData.cnsgnOperMngYn === 'Y'"/>
                  </td>
                </tr>
                <tr>
                  <th scope="row">사업장 상태</th>
                  <td :colspan="conds.showWes && conds.uncheck? '' : 3">
                    <br-form-radio label="사업장상태" groupCd="MBR004" v-model:value="modifiableData.bplcSttsCd"/>
                  </td>
                  <th scope="row" v-if="conds.showWes && conds.uncheck">자원순환시설 ID</th>
                  <td style="display: flex; gap: 5px" v-if="conds.showWes && conds.uncheck">
                    <br-form-input style="width: 70%" label="자원순환시설ID"  v-model="modifiableData.rsrcRcyclnFcltId" readonly/>
                    <br-button label="시설검색" format="tertiary" size="sm" icon="search" style="width: 30%;" @click="callRsrcRcyclnFcltPopup"/>
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income">
                  <th scope="row">대기배출시설설치신고</th>
                  <td>
                    <div class="chk_area">
                      <br-form-radio label="대기배출시설설치신고여부"  v-model:value="modifiableData.atmoDschrgFcltInstlDclrYn" :options="radioBtnYnList" value-field="value" text-field="text"/>
                    </div>
                  </td>
                  <th scope="row">중간재활용업체</th>
                  <td>
                    <div class="chk_area">
                      <br-form-radio label="중간재활용업체여부"  v-model:value="modifiableData.mdRecyBzentyYn" :options="radioBtnYnList" value-field="value" text-field="text"/>
                    </div>
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income">
                  <th scope="row">종합재활용업체</th>
                  <td>
                    <div class="chk_area">
                      <br-form-radio label="중간재활용업체여부"  v-model:value="modifiableData.snthsRecyBzentyYn" :options="radioBtnYnList" value-field="value" text-field="text"/>
                    </div>
                  </td>
                  <th scope="row">최종재활용업체</th>
                  <td>
                    <div class="chk_area">
                      <br-form-radio label="최종재활용업체여부"  v-model:value="modifiableData.lastRecyBzentyYn" :options="radioBtnYnList" value-field="value" text-field="text"/>
                    </div>
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income">
                  <th scope="row">중간처분업체</th>
                  <td>
                    <div class="chk_area">
                      <br-form-radio label="중간처분업체여부"  v-model:value="modifiableData.mdDspsBzentyYn" :options="radioBtnYnList" value-field="value" text-field="text"/>
                    </div>
                  </td>
                  <th scope="row">최종처분업체</th>
                  <td>
                    <div class="chk_area">
                      <br-form-radio label="최종처분업체여부"  v-model:value="modifiableData.lastDspsBzentyYn" :options="radioBtnYnList" value-field="value" text-field="text"/>
                    </div>
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income">
                  <th scope="row">종합처분업체</th>
                  <td colspan="3">
                    <div class="chk_area">
                      <br-form-radio label="종합처분업체여부"  v-model:value="modifiableData.snthsDspsBzentyYn" :options="radioBtnYnList" value-field="value" text-field="text"/>
                    </div>
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income">
                  <th scope="row"><i v-if="conds.showAllSrf" class="necessary">*<span class="sr_only">필수입력</span></i>설치운영허가번호</th>
                  <td>
                    <br-form-input label="설치운영허가번호" v-model="modifiableData.instlOperPrmsnNo" panel="panel4" :required="conds.showAllSrf" />
                  </td>
                  <th scope="row" style="text-align: left;">
                    <div style="display: flex; align-items: center;">
                      <i v-if="conds.showAllSrf" class="necessary" style="margin-right: 5px;">필수입력</i>설치운영<br/>허가일자
                    </div>
                  </th>
                  <td>
                    <br-date-picker label="설치운영허가일자" v-model:value="modifiableData.instlOperPrmsnYmd" panel="panel4" :required="conds.showAllSrf"/>
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income">
                  <th scope="row">신고허가번호</th>
                  <td>
                    <br-form-input label="신고허가번호" v-model="modifiableData.dclrPrmsnNo"/>
                  </td>
                  <th scope="row">신고허가일자</th>
                  <td>
                    <br-date-picker label="신고허가일자" v-model:value="modifiableData.dclrPrmsnYmd"/>
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income">
                  <th scope="row">처리시설설치신고</th>
                  <td>
                    <div class="chk_area">
                      <br-form-radio label="처리시설설치신고여부"  v-model:value="modifiableData.prcsFcltInstlDclrYn" :options="radioBtnYnList" value-field="value" text-field="text"/>
                    </div>
                  </td>
                  <th scope="row">총 사업비</th>
                  <td>
                    <br-form-input label="총사업비" align="right" v-model="modifiableData.tpcoAmt" validator="number"/>
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income">
                  <th scope="row">인원수</th>
                  <td>
                    <br-form-input label="인원수" align="right" v-model="modifiableData.nope" validator="number"/>
                  </td>
                  <th scope="row">운영금액</th>
                  <td>
                    <br-form-input label="운영금액" align="right" v-model="modifiableData.operAmt" validator="number"/>
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income">
                  <th scope="row">편익시설 종류</th>
                  <td>
                    <br-form-input label="편익시설종류" v-model="modifiableData.cnvncFcltKndNm"/>
                  </td>
                  <th scope="row">편익시설 설치비용</th>
                  <td>
                    <br-form-input label="편익시설설치비용" align="right" v-model="modifiableData.cnvncFcltInstlCst" validator="number"/>
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income">
                  <th scope="row">편익시설 운영기관</th>
                  <td>
                    <br-form-input label="편익시설운영기관" v-model="modifiableData.cnvncFcltOperInstNm"/>
                  </td>
                  <th scope="row">편익시설 비고</th>
                  <td>
                    <br-form-input label="편익시설비고" v-model="modifiableData.cnvncFcltRmrkCn"/>
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income">
                  <th scope="row">사업기간</th>
                  <td colspan="3">
                    <br-date-from-to label="사업기간" v-model:fromDate="modifiableData.bizBgngYmd" v-model:toDate="modifiableData.bizEndYmd"/>
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income">
                  <th scope="row">국고금액</th>
                  <td>
                    <br-form-input label="국고금액" align="right" v-model="modifiableData.gvcfrAmt" validator="number"/>
                  </td>
                  <th scope="row">지방비금액</th>
                  <td>
                    <br-form-input label="지방비금액" align="right" v-model="modifiableData.lgscAmt" validator="number"/>
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income">
                  <th scope="row">민자금액</th>
                  <td>
                    <br-form-input label="민자금액" align="right" v-model="modifiableData.pvtcptAmt" validator="number"/>
                  </td>
                  <th scope="row">기타금액</th>
                  <td>
                    <br-form-input label="기타금액" align="right" v-model="modifiableData.etcAmt" validator="number"/>
                  </td>
                </tr>
                <tr v-if="conds.uncheck && !conds.income">
                  <th scope="row">사용자 메모 내용</th>
                  <td colspan="3">
                    <br-form-textarea label="사용자 메모 내용" v-model="modifiableData.userMemoCn" style="height: 100px;"/>
                  </td>
                </tr>
                <!--  modifiedData 끝  -->
              </template>
              </tbody>
            </table>
          </div>
          <!-- 첨부서류 -->
          <h3 class="title2">첨부서류</h3>
          <br-attach-file-list label="사업장 첨부파일 목록"
                               ref="attachFileList"
                               :atchFileTaskSeCd="atchFileTaskSeCd"
                               v-model:atchFileGroupId="atchFileGroupId"
                               :maxFileCount="10"
                               :unusedAtchFileList="unusedAtchFileList"
                               :readonly="!isEditMode"
                               :useEtcFile="true"
          />
          <div class="btn_area center">
            <br-button v-if="bplcMngPicSttsCd === '02' && !isEditMode && (['05', '03'].includes(bizfaciltyBplc.bplcChgAplySttsCd) || !bizfaciltyBplc.bplcChgAplySttsCd)" label="정보 변경" class="btn md" @click="onClickInfoChg" />
            <br-button v-if="bplcMngPicSttsCd === '02' && isEditMode" :label="(bizfaciltyBplc.bplcChgAplySeCd === '01' && bizfaciltyBplc.bplcChgAplySttsCd === '03') ? '재승인 신청' : '변경 신청'" class="btn md primary"  @click="callPopupBplcChgAply" panel="panel4" validate/>
            <br-button v-if="bplcMngPicSttsCd === '02' && isEditMode" label="취소" class="btn md point"  @click="cancelModify"/>
          </div>
        </template>
        <template v-else>
            <div class="desc_box progress_box">
              <i class="icon"><img src="@/assets/images/common/ico_noti.png" alt="이미지"></i>
              <div class="progress">
                <span>업체에 등록된 사업장이 없거나, <strong style="color:var(--point);">승인완료</strong>된 나의 관리 사업장을 선택하지 않으셨습니다.<br>신규 사업장을 등록하거나 승인완료된 나의 관리 사업장을 선택해주세요.</span>
              </div>
            </div>
          </template>
      </template>
      <template v-else>
        <div class="desc_box progress_box">
          <i class="icon"><img src="@/assets/images/common/ico_noti.png" alt="이미지"></i>
          <div class="progress">
            <div><span>업체 승인 후 사업장 등록이 가능합니다.</span></div>
          </div>
        </div>
      </template>
    </section>
  </div>
  <!-- 주소검색 팝업 -->
  <address-popup
      @input-button-clicked="selectedAddress"
      :id="addressPopId"
  />
  <myBplcMngPopup
      @completedMyBplcMng="myMngBplcGridReload"
  />
  <createBplcPopup
      :bzentyId="this.bizfaciltyBplc.bzentyId ? this.bizfaciltyBplc.bzentyId : this.bzentyId"
      :bplcMyMngBplcList="this.bplcMyMngBplcList"
      @completed="searchByCreatedBplc"
  />
  <chgAplyPopup
      :id="chgAplyPopId"
      @chgAplyClick="processChangeRequest"
  />
  <bplcChgHstryPopup
      :bplcId="this.bizfaciltyBplc.bplcId"
      :bzentyId="this.bzentyId"
  />
  <bplcSplmntPopup
      :bplcId="this.bizfaciltyBplc.bplcId"
      :chgAplySn="this.chgAplySn"
      :prcsRsn="this.prcsRsn"
      :chgAplySeCd="this.chgAplySeCd"
      @complete="chgHstryGridReload"
  />
  <bplcChgAplyInfo
    :bplcId="this.bizfaciltyBplc.bplcId"
    :chgAplySn="this.chgAplySn"
/>
  <rsrcRcyclnFcltPopup
      @select-button-clicked="selectedRsrcRcyclnFcltPopup"
      :id="rsrcRcyclnFcltPopId"
  />
</template>

<script>
import { buildUri } from "@/js/utils/uri-builder";
import { storeComponent } from "@/js/store/store-component";
import { storeSwitch } from "@/js/store/store-switch";
import {storeSession} from "@/js/store/store-session";

import AddressPopup from "@/views/mbr-mng/components/MbrFormAddress.vue";
import SbGrid from "@/components/SbGrid.vue";
import MbrFormTelno from "@/views/mbr-mng/components/MbrFormTelno.vue";
import RsrcRcyclnFcltPopup from "@/views/my-office/components/popup/RsrcRcyclnFcltPopup.vue";
import BrFormRadio from "@/components/BrFormRadio.vue";
import BrDatePicker from "@/components/BrDatePicker.vue";
import inspPagination from "@/views/sfli/components/inspPagination.vue";
import MyBplcMngPopup from "@/views/my-office/components/popup/MyBplcMngPopup.vue";
import CreateBplcPopup from "@/views/my-office/components/popup/CreateBplcPopup.vue";
import ChgAplyPopup from "@/views/my-office/components/popup/ChgAplyPopup.vue";
import BrButton from "@/components/BrButton.vue";
import BplcChgHstryPopup from "@/views/my-office/components/popup/BplcChgHstryPopup.vue";
import BzentySplmntPopup from "@/views/my-office/components/popup/BzentySplmntPopup.vue";
import BplcSplmntPopup from "@/views/my-office/components/popup/BplcSplmntPopup.vue";
import BplcChgAplyInfo from "@/views/my-office/components/popup/BplcChgAplyInfo.vue";

const API = {
  BPLC_INFO: "/bizfacility/bplc-info/{bplcId}",
  BPLC_CHG_INFO: "/bizfacility/bplc-chg-info",
  BPLC_INFO_UPDATE: "/bizfacility/bplc-info",
  BPLC_CHG_HSTRY: "/bizfacility/bplc-chg-hstry/{bplcId}",
  MY_MNG_BPLC: "/bizfacility/my-mng-bplc",
};

const CODE = {
  ARPV_YN_CD: "SIS020", // 승인여부코드
  BPLC_STTS_CD: "MBR004", // 사업장상태코드
  BPLC_KND_CD: "MBR006", // 사업장종류코드
  MBR_STTS_CD: "COM428",  // 회원상태코드
  CHG_APLY_TYPE_CD: "MBR008", //변경신청유형코드
  CHG_APLY_PRCS_STTS_CD: "MBR009",  //변경신청처리상태코드
  BPLC_MNG_PIC_STTS_CD: "MBR023",  //사업장관리담당자상태코드
  CHG_APLY_SE_CD: "MBR025",  //변경신청구분코드
}

const initialBizfaciltyBplc = {
  bplcChgAplySeCd: '',
  bplcChgAplySttsCd: '',
  bplcChgAplySttsNm: '',
  bplcId: '',
  bplcNm: '',
  engBplcNm: '',
  bzentyId: 0,
  bplcKndCd: '',
  cnsgnOperMngYn: 'N',
  cnsgnBzentyNm: '',
  cnsgnBzentyBrno: '',
  cnsgnBzentyScndwpNo: '',
  picNm: '',
  telno: '',
  telno1: '',
  telno2: '',
  telno3: '',
  fxno: '',
  fxno1: '',
  fxno2: '',
  fxno3: '',
  untyZip: '',
  bplcAddr: '',
  bplcDaddr: '',
  ctpvStdgCd: '',
  sggStdgCd: '',
  cmptncRgnHqCd: '',
  lat: '',
  lot: '',
  incmQltyTaskIssuNo: '',
  snthsRecyBzentyYn: 'N',
  lastRecyBzentyYn: 'N',
  mdRecyBzentyYn: 'N',
  prcsFcltInstlDclrYn: 'N',
  atmoDschrgFcltInstlDclrYn: 'N',
  mdDspsBzentyYn: 'N',
  lastDspsBzentyYn: 'N',
  snthsDspsBzentyYn: 'N',
  instlOperPrmsnNo: '',
  instlOperPrmsnYmd: '',
  dclrPrmsnNo: '',
  dclrPrmsnYmd: '',
  atchFileGroupId: '',
  bplcSttsCd: '',
  aprvYn: '',
  rsrcRcyclnFcltId: '',
  mngrMemoCn: '',
  userMemoCn: '',
  useYn: '',
  nope: '',
  operAmt: '',
  rmrkCn: '',
  cnvncFcltKndNm: '',
  cnvncFcltInstlCst: '',
  cnvncFcltOperInstNm: '',
  cnvncFcltRmrkCn: '',
  tpcoAmt: '',
  bizBgngYmd: '',
  bizEndYmd: '',
  gvcfrAmt: '',
  lgscAmt: '',
  pvtcptAmt: '',
  etcAmt: '',
  fillAftrUsePlanCn: '',
  regDt: '',
  rgtrId: '',
  mdfcnDt: '',
  mdfrId: '',
  bplcCtpvCd: '',
  userId: '',
  rnMgtSn: '',
};

const initialUnusedAtchFileList = ['053', '054', '008'];

const PAGE_SIZE = 5;

export default {
  components: {
    BplcSplmntPopup,
    BzentySplmntPopup,
    BrButton,
    ChgAplyPopup,
    inspPagination,
    BrDatePicker,
    BrFormRadio,
    SbGrid,
    MbrFormTelno,
    AddressPopup,
    MyBplcMngPopup,
    CreateBplcPopup,
    BplcChgHstryPopup,
    RsrcRcyclnFcltPopup,
    BplcChgAplyInfo
},
  props: {
    menu: {type: Object, required: true},
    bzentyAprvYn: {type: String, required: true},
    bzentyId: {type: Number, required: true},
  },
  emits: ['isIncome'],
  expose: ['initialize', 'reset', 'searchMyMngBplc'],
  data() {
    return {
      rsrcRcyclnFcltPopId: 'rsrcRcyclnFcltPopupForBplc',
      addressPopId: 'addressPopupForBplc',
      chgAplyPopId: 'chgAplyPopupForBplc',
      isEditMode: false,
      unusedAtchFileList: [...initialUnusedAtchFileList],
      bplcMngPicSttsCd: '',
      prcsRsn: '',
      chgAplyPrcsSttsCd:'',
      chgAplySn: 0,
      chgAplySeCd: '',
      sysAuthrtCd: storeSession.getMemberInfo().sysAuthrtCd,
      userInfo: storeSession.getMemberInfo(),

      atchFileTaskSeCd: '',
      maxPage: 1,
      hstryMaxPage: 1,
      searchParam: {
        page: 1,
        size: 5,
      },
      hstrySearchParam: {
        page: 1,
        size: 5,
      },
      selectedRowData: '',           // 선택된 행의 데이터를 저장할 변수
      selectedRowIndex: '',       // 선택된 행의 인덱스를 저장할 변수
      bplcMyMngBplcList: [],
      bplcMyMngBplcCount: 0,
      bplcInfoChgHstryList: [],
      bplcInfoChgHstryCount: 0,
      bizfaciltyBplc: { ...initialBizfaciltyBplc },
      modifiableData: {
      },
      conds: {
        showWes: false, //사업장종류 = 폐자원에너지
        income: false,  //사업장종류 = 고형연료수입
        uncheck: false, //사업장종류 = 미관리 종류(uncheckList)
        showAllSrf: false,  //고형연료 제조, 사용
        showSrfMnf: false,  //고형연료 제조
        showSrfUse: false,  //고형연료 사용
      },
      uncheckList: [  // 미관리 종류
        '06-01',  //열분해유
        '07-01',  //재생연료유
        '08-01',  //정제연료유
        '09-01',  //시멘트소성로 보조연료
        '10-01',  //유기성오니 보조연료
      ],
      bplcKndCdList: [],
      aprvYnCdList: [],
      cnsgnOperMngYnList: [],
      bplcCtpvCdList: [],
      prdctnPrdctKndCdList: [],
      fcltSttsCdCdList: [],
      bplcSttsCdList: [],
      bplcMngPicSttsCdList: [],
      mbrSttsCdList: [],
      chgAplyTypeCdList: [],
      chgAplyPrcsSttsCdList: [],
      inspPrgrsSttsCdList: [],
      chgAplySeCdList: [],
      radioBtnYnList: [
        { value: "Y", text: "대상" },
        { value: "N", text: "비대상" },
      ],
    };
  },
  computed: {
    // 사업장 정보 수정여부 확인하기
    isChanged(){
      return JSON.stringify(this.bizfaciltyBplc) !== JSON.stringify(this.modifiableData);
    },
    atchFileGroupId: {
      get() {
        return this.isEditMode ? this.modifiableData.atchFileGroupId : this.bizfaciltyBplc.atchFileGroupId;
      },
      set(value) {
        if (this.isEditMode) {
          this.modifiableData.atchFileGroupId = value;
        } else {
          this.bizfaciltyBplc.atchFileGroupId = value;
        }
      }
    },
  },
  watch: {
    'bizfaciltyBplc.cnsgnOperMngYn'(newVal) {
      if (newVal === 'N') {
        this.bizfaciltyBplc.cnsgnBzentyNm = '';
      }
    },
    'conds.income'(newVal){
        this.$emit('isIncome', newVal);
    },
  },
  created() {
  },
  beforeMount() {
    // 컴포넌트 전체 데이터 가져오기
    storeComponent.restore(this.menu, this);
  },
  mounted() {},
  beforeUnmount() {
    // 컴포넌트 전체 데이터 저장하기
    storeComponent.store(this.menu, this);
  },
  methods: {
    initialize() {
        this.bplcSttsCdList = this.$store.getters.getCodeList({groupCd: CODE.BPLC_STTS_CD, useYn: 'Y'});       // 사업장상태코드
        this.bplcKndCdList = this.$store.getters.getCodeList({groupCd: CODE.BPLC_KND_CD, useYn: 'Y'});         // 사업장종류코드
        this.aprvYnCdList = this.$store.getters.getCodeList({groupCd: CODE.ARPV_YN_CD, useYn: 'Y'});           // 승인여부코드
        this.mbrSttsCdList = this.$store.getters.getCodeList({groupCd: CODE.MBR_STTS_CD, useYn: 'Y'});   // 회원상태코드
        this.chgAplyPrcsSttsCdList = this.$store.getters.getCodeList({groupCd: CODE.CHG_APLY_PRCS_STTS_CD, useYn: 'Y'});           // 변경신청처리상태코드
        this.chgAplyTypeCdList = this.$store.getters.getCodeList({groupCd: CODE.CHG_APLY_TYPE_CD, useYn: 'Y'});           // 변경신청유형코드
        this.inspPrgrsSttsCdList = this.$store.getters.getCodeList({groupCd: CODE.INSP_PRGRS_STTS_CD, useYn: 'Y'});   // 검사진행상태코드
        this.bplcMngPicSttsCdList = this.$store.getters.getCodeList({groupCd: CODE.BPLC_MNG_PIC_STTS_CD, useYn: 'Y'});   // 사업장관리담당자상태코드
        this.chgAplySeCdList = this.$store.getters.getCodeList({groupCd: CODE.CHG_APLY_SE_CD, useYn: 'Y'});           // 변경신청구분코드
        this.searchMyMngBplc();
    },
    async readBplcInfo() {
      const bplcInfoUrl = await buildUri(API.BPLC_INFO, {bplcId : this.bizfaciltyBplc.bplcId});
      await this.$apiCall.get(bplcInfoUrl, {},  async data => {
        if (data.result) {
          for (const key of Object.keys(this.bizfaciltyBplc)) {
            //null 값일 경우
            this.bizfaciltyBplc[key] = data.result?.[key] ?? '';
          }
          this.modifiableData = JSON.parse(JSON.stringify(this.bizfaciltyBplc));
          await this.setConditions(this.bizfaciltyBplc.bplcKndCd);
          await this.setUnusedAtchFileList();
          setTimeout(() => {
            this.$refs.attachFileList.load(this.atchFileTaskSeCd, this.bizfaciltyBplc.atchFileGroupId);
          }, 300);
        }
      });
    },
    getCdName(list, code) {
      const status = list.find(item => item.dtlCd === code);
      return status ? status.dtlCdNm : '';
    },
    validateTelno() {
      const telnoInput = this.$refs.telnoInput;
      if (telnoInput && !telnoInput.isValid()) {
        alert("전화번호를 올바르게 입력해 주세요.")
        return false;
      }
      const fxnoInput = this.$refs.fxnoInput;
      if (this.bizfaciltyBplc.fxno && !fxnoInput.isValid()) {
        alert("팩스번호를 올바르게 입력해 주세요.")
        return false ;
      }
      return true;
    },
    searchMyMngBplc(){
      this.searchParam.size = PAGE_SIZE;
      this.$apiCall.get(API.MY_MNG_BPLC, this.searchParam, async data => {
        if (data.result.list) {
          this.bplcMyMngBplcCount = data.result.total;
          this.bplcMyMngBplcList = data.result.list;
          await this.setMaxPage();
          await this.selectFirstRow();
        } else {
          this.bplcMyMngBplcList = [];
        }
      });
    },
    setMaxPage() {
      const totalPage = Math.ceil(this.bplcMyMngBplcCount / this.searchParam.size);
      this.maxPage = totalPage === 0 ? 1 : totalPage;
    },
    searchChgHstry(){
      const bplcChgHstryUrl = buildUri(API.BPLC_CHG_HSTRY, {bplcId: this.bizfaciltyBplc.bplcId, page: this.hstrySearchParam.page, size: this.hstrySearchParam.size});

      this.hstrySearchParam.size = PAGE_SIZE;

      this.$apiCall.get(bplcChgHstryUrl, this.hstrySearchParam, data => {
        if (data.result.list) {
          this.bplcInfoChgHstryCount = data.result.total;
          this.bplcInfoChgHstryList = data.result.list;
        } else {
          this.bplcInfoChgHstryList = [];
        }
      });
    },
    setConditions(bplcKndCd){
      const mainCd = bplcKndCd.slice(0, 2);
      const subCd = bplcKndCd.slice(3, 5);

      // 초기화
      this.conds = {
        showWes: mainCd !== '01',
        uncheck: !this.uncheckList.includes(bplcKndCd),
        income: mainCd === '01' && subCd === '03',
        showAllSrf: mainCd === '01' ,  //고형연료 제조, 사용
        showSrfMnf: bplcKndCd === '01-01',  //고형연료 제조
        showSrfUse: bplcKndCd === '01-02',  //고형연료 사용
      };

      // 고형연료 및 수입연료 조건 처리
      if (mainCd === '01') {
        if (subCd === '01' || subCd === '02') {
          switch (subCd){
            case '01' :
              this.atchFileTaskSeCd = 'BPCA'
              break;
            case '02' :
              this.atchFileTaskSeCd = 'BPCC'
              break;
          }
          this.conds.income = false;
        } else if (subCd === '03') {
          this.conds.income = true;
          this.atchFileTaskSeCd = 'BPCD'
        }
      }else{
        this.atchFileTaskSeCd = 'BPCB';
      }
    },
    selectFirstRow() {
      if (this.bplcMyMngBplcList && this.bplcMyMngBplcList.length > 0) {
        const firstRow = this.bplcMyMngBplcList[0];
        this.selectedRowIndex = 0;
        this.selectedRow(firstRow, this.selectedRowIndex);
        // this.handleRowSelection(firstRow);
      }
      else{
        // 사업장이 존재하지 않으면, 사업장정보 초기화 ( ex. 나의 사업장을 모두 해지 후 팝업 닫았을 때)
        this.reset();
      }
    },
    handleRowSelection(rowData) {
      this.isEditMode = false;

      this.bizfaciltyBplc.bplcId = rowData.bplcId;
      this.bizfaciltyBplc.bzentyId = rowData.bzentyId;
      this.bplcMngPicSttsCd = rowData.bplcMngPicSttsCd;

      if(rowData.bplcMngPicSttsCd === '02') {
        // 사업장 관리 담당자로 승인 받은 경우에만 데이터 조회
        this.readBplcInfo();
        this.searchChgHstry();
      }
    },
    selectedRow(item, index) {
      this.selectedRowData = item;   // 선택된 데이터를 저장
      this.selectedRowIndex = index; // 선택된 행의 인덱스를 저장
      this.handleRowSelection(item);
    },
    selectedAddress(data) {
      this.modifiableData.untyZip = data.zipNo;
      this.modifiableData.bplcAddr = data.roadAddrPart1;
      this.modifiableData.bplcDaddr = data.roadDetailAddr;
      if(data.admCd) {
        const stdgCd = data.admCd;
        this.bizfaciltyBplc.ctpvStdgCd = stdgCd.slice(0, 2) + '0'.repeat(stdgCd.length - 2);
        this.bizfaciltyBplc.sggStdgCd = stdgCd.slice(0, 5) + '0'.repeat(stdgCd.length - 5);
      }
    },
    selectedRsrcRcyclnFcltPopup(data){
      if(data.bplcId) this.modifiableData.rsrcRcyclnFcltId = data.bplcId;
      else this.modifiableData.rsrcRcyclnFcltId = '';
    },
    processChangeRequest(prcsRsn){

      this.modifiableData.prcsRsn = prcsRsn;

      this.$refs.attachFileList.save(true, false).then(atchFileGroupId => {

        this.modifiableData.atchFileGroupId = atchFileGroupId;

        // 승인신청(01) 상태에서 반려(03)당한 후 정보변경신청을 하는 경우엔 정보변경구분코드를 승인신청(01)으로 다시 넣어준다.
        if( this.modifiableData.bplcChgAplySeCd === '01' && this.modifiableData.bplcChgAplySttsCd === '03' ) {
          this.modifiableData.chgAplySeCd = '01';
        } else {
          //변경신청구분코드 -> 02 정보변경신청
          this.modifiableData.chgAplySeCd = '02';
        }

        //변경신청처리상태코드  -> 01 승인대기
        this.modifiableData.chgAplyPrcsSttsCd = '01';
        //상세변경신청처리상태코드 -> 01 승인대기
        this.modifiableData.dtlChgAplyPrcsSttsCd = '01';
        //변경신청유형코드  -> 02 사업장
        this.modifiableData.chgAplyTypeCd = '02';

        this.$apiCall.post(API.BPLC_CHG_INFO, this.modifiableData, () => {
              alert("변경 신청되었습니다.");
              //사업장 정보 재조회
              this.readBplcInfo();
              //사업장 정보변경이력 재조회
              this.searchChgHstry();
              this.isEditMode = false;
            },
            () => {
              alert('변경 신청에 실패하였습니다.');
            }
        );
      });
    },
    setUnusedAtchFileList(){
      if(this.isEditMode){
        if(this.modifiableData.cnsgnOperMngYn === 'N'){
          this.unusedAtchFileList = [...initialUnusedAtchFileList];
          this.$refs.attachFileList.load(this.atchFileTaskSeCd, this.modifiableData.atchFileGroupId);
        }else{
          this.unusedAtchFileList = [];
          this.$refs.attachFileList.load(this.atchFileTaskSeCd, this.modifiableData.atchFileGroupId);
        }
      }

      if(!this.isEditMode){
        if(this.bizfaciltyBplc.cnsgnOperMngYn === 'N'){
          this.unusedAtchFileList = [...initialUnusedAtchFileList];
        }else{
          this.unusedAtchFileList = [];
        }
        this.$refs.attachFileList.load(this.atchFileTaskSeCd, this.bizfaciltyBplc.atchFileGroupId);
      }
    },
    reset(){
      this.isEditMode = false;
      // this.bizfaciltyBplc = { ...initialBizfaciltyBplc };
    },
    onClickInfoChg(){
      this.isEditMode  = true;
    },
    cancelModify(){
      this.readBplcInfo();
      this.isEditMode = false;
    },
    takeAction(item){
      this.chgAplyPrcsSttsCd = item.chgAplyPrcsSttsCd;
      this.chgAplySn = item.chgAplySn;
      this.prcsRsn = item.prcsRsn;
      this.callPopupBplcSplmnt();
    },
    searchByCreatedBplc(){
      this.searchMyMngBplc();
    },
    callPopupAddress() {
      storeSwitch.on(this.addressPopId);
    },
    callPopupBplcChgAplyInfo(item){
      this.chgAplySn = item.chgAplySn;
      storeSwitch.on('bplcChgAplyInfoPopup');
    },
    callPopupBplcSplmnt(){
      storeSwitch.on('bplcSplmntPopup');
    },
    callPopupCreateBplc(){
      storeSwitch.on("createBplcPopup");
    },
    callRsrcRcyclnFcltPopup(){
      // alert('준비중 입니다.')
      storeSwitch.on(this.rsrcRcyclnFcltPopId);
    },
    callPopupBplcChgHstryPopup(){
      storeSwitch.on('bplcChgHstryPopup');
    },
    callPopupMyBplcMng(){
      storeSwitch.on('myBplcMngPopup');
    },
    callPopupBplcChgAply(){
      this.modifiableData.telno = `${this.modifiableData.telno1}${this.modifiableData.telno2}${this.modifiableData.telno3}`
      this.modifiableData.fxno = `${this.modifiableData?.fxno1 || ''}${this.modifiableData?.fxno2 || ''}${this.modifiableData?.fxno3 || ''}`

      if(this.validateTelno){
        storeSwitch.on(this.chgAplyPopId);
      }
    },
    formatNumber(value) {
      if (!value) return '';
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    myMngBplcGridReload(){
      this.searchMyMngBplc();
    },
    chgHstryGridReload(){
      this.searchChgHstry();
    },
  }
}
</script>

<style scoped>
.selected {
  background-color: #f0f8ff; /* 선택 시 배경색 */
}
.board_info .page_button_area {position: absolute; top: 0px; right: 0px;}
</style>