<template>
  <div v-show="isOn" class="modal visible">
    <div class="popup_box" style="width: 70%; max-width: 850px; height: 80vh; max-height: 700px;">
      <h1 class="popup_title">주소검색</h1>
      <div class="popup_cont" style="height: 88%;">
        <div class="search_wrap">
          <div class="search_cont">
            <label style="display: none" for="addressInput">주소검색</label>
            <input
              id="addressInput"
              type="text"
              class="search_input"
              placeholder="주소를 입력해주세요"
              v-model="keyword"
              @keydown.enter.prevent="callApiAddressSearching(1, 'NEW')" />
            <button
                type="button"
                class="btn sm txtN ico_search"
                @click="callApiAddressSearching(1, 'NEW')" />
          </div>
          <div class="info_cont">
            <div class="search_check">
              <input type="checkbox" id="변동된주소정보포함" v-model="isCheckedHstrYn">
              <label for="변동된주소정보포함">변동된 주소정보 포함</label>
            </div>
            <div class="search_info">
              예시: 도로명(반포대로 58), 건물명(독립기념관), 지번(삼성동 25)
            </div>
          </div>
        </div>
        <div class="address_wrap" v-if="isSearched && !isSelected">
          <div>도로명주소 검색결과 <strong style="color: #186bb9">({{ Number(this.totalCount).toLocaleString() }}건)</strong></div>
          <div class="board_list">
            <table class="address_table">
              <caption>주소검색 결과 목록</caption>
              <colgroup>
                <col style="width:80px">
                <col>
              </colgroup>
              <thead>
                <tr>
                  <th>우편번호</th>
                  <th>도로명주소</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(juso, index) in jusoList" :key="index" @click="onClickedRow(index)">
                  <td>{{ juso.zipNo }}</td>
                  <td>{{ juso.roadAddr }}</td>
                </tr>
                <tr v-if="totalCount <= 0">
                  <td colspan="2">'{{ this.searchedKeyword }}'에 해당되는 주소가 없습니다</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="page_cont" v-show="totalCount > 0">
            <div class="pagination">
              <button :disabled="currentPageGroup <= 1" @click="onClickedPrevious">◀</button>
              <button v-for="page in visiblePageList" :key="page" :class="{active: page === this.currentPage}" @click="onClickedPage(page)">{{ page }}</button>
              <button :disabled="visiblePageList.length < 10" @click="onClickedNext">▶</button>
            </div>
          </div>
        </div>
        <div class="address_input_wrap" v-if="isSelected">
          <div>상세주소 입력</div>
          <div class="board_list">
            <table class="address_table">
              <caption>상세주소 입력</caption>
              <colgroup>
                <col style="width:130px">
                <col>
              </colgroup>
              <tbody>
              <tr>
                <th>도로명 주소</th>
                <td>{{ selectedJuso.roadAddr }}</td>
              </tr>
              <tr>
                <th>상세주소 입력</th>
                <td>
                  <input type="text" class="form_control" placeholder="상세주소를 입력해주세요" v-model="selectedJuso.roadDetailAddr" @keyup.enter.prevent="onClickedAddressInput" />
                </td>
              </tr>
              </tbody>
            </table>
            <div class="address_input">
              <br-button label="주소입력" size="sm" @click="onClickedAddressInput" />
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="modal_close" @click.prevent="hide" >팝업닫기</button>
    </div>
  </div>
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";

const API = {
  // ADDRESS_SEARCHING_API: 'https://business.juso.go.kr/addrlink/addrLinkApi.do?confmKey=devU01TX0FVVEgyMDI0MDkyMTIwMjAwMjExNTA5ODk=',
  ADDRESS_SEARCHING_API: '/address',
};


export default {
  components: {},
  props: {
    id: {type: String, default: 'addressPopup'},
  },
  emits: [ 'input-button-clicked' ],
  data() {
    return {
      isOn: false,
      isSearched: false,
      isSelected: false,
      currentPage: 1,
      keyword: '',
      searchedKeyword: '',
      isCheckedHstrYn: false,
      totalCount: 0,
      totalPage: 1,
      jusoList: [],
      selectedJuso: {},
      ctpvCdList: [],
      sggCdList: [],
      searchParam: {
        currentPage: '',
        countPerPage: '',
        keyword: '',
        hstryYn: '',
      },
    };
  },
  computed: {
    currentPageGroup() {
      return Math.ceil(this.currentPage / 10);
    },
    visiblePageList() {
      const groupStart = (this.currentPageGroup - 1) * 10 + 1;
      const groupEnd = Math.min(this.currentPageGroup * 10, this.totalPage);
      return Array.from({ length: groupEnd - groupStart + 1 }, (_, i) => groupStart + i);
    },
  },
  watch: {
    isOn(newValue) {
      if(newValue) {
        this.isSearched = false;
        this.initialize();
      }
    }
  },
  created() {
    storeSwitch.setBulb(this.id, this);
  },
  beforeMount() {
  },
  mounted() {
    this.initialize();
  },
  beforeUnmount() {
  },
  methods: {
    /** 팝업 Show */
    show() {
      this.isOn = true;
    },
    /** 팝업 Hide */
    hide() {
      this.isOn = false;
    },
    /** 화면 초기화 */
    initialize() {
      this.loadCtpvCd();
      this.loadSggCd();
      this.keyword = '';
      this.searchedKeyword = '';
      this.isSearched = false;
      this.isSelected = false;
      this.isCheckedHstrYn = false;
      this.totalCount = 0;
      this.totalPage = 1;
      this.jusoList = [];
      this.selectedJuso = {};
    },
    loadCtpvCd() {
      const ctpvList = this.$store.getters.getSggList({ type: 'ctpv' });
      this.ctpvCdList = ctpvList.map(item => ({
        text: item.sggNm,
        value: item.sggStdgCd
      }));
    },
    loadSggCd() {
      const sggList = this.$store.getters.getSggList({ type: 'sgg' });
      this.sggCdList = sggList.map(item => ({
        text: item.sggNm,
        value: item.sggStdgCd,
        upSggStdgCd: item.upSggStdgCd
      }));
    },
    /** 주소검색 API 호출 */
    callApiAddressSearching(pageNumber, callType) {
      this.currentPage = pageNumber;

      if(callType === 'NEW') {
        this.currentPage = 1;
        this.isSearched = false;
        this.isSelected = false;
        this.selectedJuso = {};
      }

      /* 2024.12.23, kjh 수정, 주소검색 key 노출 문제로, backEnd 통해서 조회하도록 로직 변경
      const apiUrl = [
          `${API.ADDRESS_SEARCHING_API}`
        , `currentPage=${this.currentPage}`
        , 'countPerPage=10'
        , `keyword=${this.keyword}`
        , 'resultType=json'
        , `hstryYn=${this.isCheckedHstrYn? 'Y' : 'N'}`
      ].join('&');

      return new Promise((resolve, reject) => {
        this.$axios
            .get(apiUrl, {headers: {"Content-Type": "application/json"}})
            .then((response) => {
              if(response.data.results) {
                const result = response.data.results;
                this.totalCount = result.common.totalCount;
                this.totalPage = Math.ceil(result.common.totalCount / 10);
                this.jusoList = result.juso;
              }

              this.isSearched = true;
              this.searchedKeyword = this.keyword;

              resolve();
            })
            .catch((error) => {
              reject(error);
            });
      });*/

      this.searchParam.currentPage = this.currentPage;
      this.searchParam.countPerPage = 10;
      this.searchParam.keyword = this.keyword;
      this.searchParam.hstryYn = this.isCheckedHstrYn? 'Y' : 'N';

      this.$apiCall.get(API.ADDRESS_SEARCHING_API, this.searchParam, data => {
        if (data.result) {
          this.totalCount = data.result.common.totalCount;
          this.totalPage = Math.ceil(data.result.common.totalCount / 10);
          this.jusoList = data.result.juso;
        } else {
          this.totalCount = 0;
          this.totalPage = 1;
          this.jusoList = [];
        }

        this.isSearched = true;
        this.searchedKeyword = this.keyword;
      });
    },
    /** 특정 주소 클릭 */
    onClickedRow(index) {
      this.selectedJuso = this.jusoList[index];
      this.isSelected = true;
    },
    /** 이전 주소록 조회 */
    onClickedPrevious() {
      const previousPage = (this.currentPageGroup - 1) * 10;
      if(previousPage > 0) {
        this.callApiAddressSearching(previousPage);
      }
    },
    /** 다음 주소록 조회 */
    onClickedNext() {
      const nextPage = (this.currentPageGroup * 10) + 1;
      if(nextPage <= this.totalPage) {
        this.callApiAddressSearching(nextPage);
      }
    },
    /** 특정 주소록 조회 */
    onClickedPage(page) {
      this.callApiAddressSearching(page);
    },
    /** 주소입력 버튼 클릭 */
    onClickedAddressInput() {
      const matchingCtpvNm = this.ctpvCdList.find(item => item.text === this.selectedJuso.siNm);
      this.selectedJuso.ctpvStdgCd = matchingCtpvNm ? matchingCtpvNm.value : '';

      const matchingSggNm = this.sggCdList.find(item =>
          item.upSggStdgCd === this.selectedJuso.ctpvStdgCd &&
          item.text === this.selectedJuso.sggNm
      );
      this.selectedJuso.sggStdgCd = matchingSggNm ? matchingSggNm.value : '';

      this.selectedJuso.roadDetailAddr = this.selectedJuso.roadDetailAddr? this.selectedJuso.roadDetailAddr : '';
      this.$emit('input-button-clicked', this.selectedJuso);
      this.isOn = false;
    },
  }
}
</script>

<style scoped>

</style>