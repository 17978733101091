<template>
  <div class="desc_box">
    <i class="icon"><img src="@/assets/images/common/ico_desc.png" alt="이미지"></i>
    <div class="desc">
      <strong class="desc_title">고형연료제품 수입·제조·사용 신고 및 제도란?</strong>
      <p>2014년 7월 22일부터 고형연료제품의 수입·제조·사용을 위해서는 품질(시설)검사와 신고가 필요합니다</p>
    </div>
  </div>

  <h2 class="title1">고형연료제품의 수입·제조신고</h2>
  <div class="title_box">
    <span class="title">자원의 절약과 재활용촉진에 관한 법률 제25조의 4<i class="dot"></i></span>
    <ul>
      <li>고형연료제품을 수입하려는 자는 환경부령으로 정하는 바에 따라 환경부장관에게 신고하여야 한다.(변경신고 포함)</li>
      <li>고형연료제품을 제조하려는 자는 다음 각 호의 어느 하나에 해당하는 자이어야 하며, 환경부령으로 정하는 바에 따라
        특별자치시장 · 특별자치도지사 · 시장 · 군수 · 구청장에게 신고하여야 한다.(변경신고 포함)</li>
    </ul>
  </div>
  <ul class="blue_dot_list">
    <li>「폐기물관리법」제25조제5항제6호에 따른 폐기물 최종재활용업 허가를 받은 자</li>
    <li>「폐기물관리법」제25조제5항제7호에 따른 폐기물 종합재활용업 허가를 받은 자</li>
  </ul>

  <h2 class="title1">고형연료제품의 사용허가</h2>
  <div class="title_box">
    <span class="title">자원의 절약과 재활용 촉진에 관한 법률 제25조의 7<i class="dot"></i></span>
    <p>고형연료제품을 사용하려는 자는 다음 각 호의 요건을 갖추어 특별자치시장 · 특별자치도지사 · 시장 · 군수 · 구청장의 허가를 받아야 한다.<br>
      (최초개시, 중지 후 재사용, 변경)</p>
  </div>
  <ul class="blue_dot_list">
    <li>고형연료제품 사용시설(이하 "사용시설"이라 한다)이 환경부령으로 정하는 기준에 적합할 것</li>
    <li>사용하려는 고형연료제품이 제 25조의5제1항에 따른 품질기준에 적합할 것</li>
    <li>고형연료제품의 사용 · 보관 · 처리 계획이 적절할 것</li>
    <li>그 밖에 환경보호를 위하여 환경부령으로 정하는 기준을 갖출 것</li>
  </ul>

  <h2 class="title1">고형연료제품 수입자·제조자 및 사용자의 준수사항</h2>
  <div class="title_box">
    <span class="title">자원의 절약과 재활용 촉진에 관한 법률 제25조의 9<i class="dot"></i></span>
    <ul>
      <li>고형연료제품 수입자 · 제조자 및 사용자는 고형연료제품 보관과정이나 제조시설 · 사용시설 운영과정에서의 먼지 날림 방지 등
        환경관리를 위하여 환경부령으로 정하는 사항을 준수하여야 한다.</li>
      <li>고형연료제품 사용자는 환경부령으로 정하는 다이옥신 배출허용기준을 준수하여야 한다.</li>
    </ul>
  </div>

  <h2 class="title1">고형연료제품 관련 법규</h2>
  <div class="file_upload_list law">
    <div class="list">
      <span class="file_name">폐기물관리법 <span>자원순환정책과</span></span>
      <div>
        <a href="http://www.law.go.kr/법령/폐기물%20관리법" class="btn xsm" target="_blank" title="새창열림">법령 보기</a>
        <a href="http://www.law.go.kr/법령/폐기물관리법%20시행령" class="btn xsm" target="_blank" title="새창열림">시행령 보기</a>
        <a href="http://www.law.go.kr/법령/폐기물관리법%20시행규칙" class="btn xsm" target="_blank" title="새창열림">시행규칙 보기</a>
      </div>
    </div>
    <div class="list">
      <span class="file_name">자원의 절약과 재활용촉진에 관한 법률 <span>폐자원에너지과</span></span>
      <div>
        <a href="http://www.law.go.kr/법령/자원의%20절약과%20재활용촉진에%20관한%20법률" class="btn xsm" target="_blank" title="새창열림">법령 보기</a>
        <a href="http://www.law.go.kr/법령/자원의%20절약과%20재활용촉진에%20관한%20법률%20시행령" class="btn xsm" target="_blank" title="새창열림">시행령 보기</a>
        <a href="http://www.law.go.kr/법령/자원의%20절약과%20재활용촉진에%20관한%20법률%20시행규칙" class="btn xsm" target="_blank" title="새창열림">시행규칙 보기</a>
      </div>
    </div>
    <div class="list">
      <span class="file_name">폐기물의 국가간 이동 및 그 처리에 관한 법률 <span>자원순환정책과</span></span>
      <div>
        <a href="http://www.law.go.kr/법령/폐기물의%20국가간%20이동%20및%20그%20처리에%20관한%20법률" class="btn xsm" target="_blank" title="새창열림">법령 보기</a>
        <a href="http://www.law.go.kr/법령/폐기물의%20국가간%20이동%20및%20그%20처리에%20관한%20법률%20시행령" class="btn xsm" target="_blank" title="새창열림">시행령 보기</a>
      </div>
    </div>
    <div class="list">
      <span class="file_name">폐기물 처리시설 설치촉진 및 주변지역지원 등에 관한 법률 <span>폐자원에너지과</span></span>
      <div>
        <a href="http://www.law.go.kr/법령/폐기물%20처리시설%20설치%20촉진%20및%20주변지역지원등에%20관한%20법률" class="btn xsm" target="_blank" title="새창열림">법령 보기</a>
        <a href="http://www.law.go.kr/법령/폐기물처리시설%20설치촉진%20및%20주변지역지원%20등에%20관한%20법률%20시행령" class="btn xsm" target="_blank" title="새창열림">시행령 보기</a>
      </div>
    </div>
  </div>

  <h2 class="title1">고형연료제품 관련 고시</h2>
  <div class="file_upload_list law">
    <div class="list">
      <span class="file_name">고형연료제품의 품질검사 절차 및 방법 등에 관한 고시</span>
      <div>
        <a href="https://www.law.go.kr/LSW//admRulLsInfoP.do?admRulId=2074547&efYd=0" class="btn xsm" target="_blank" title="새창열림">품질검사 절차 자세히 보기</a>
      </div>
    </div>
    <div class="list">
      <span class="file_name">고형연료제품의 품질표시 방법 및 품질등급 구분 등에 관한 고시</span>
      <div>
        <a href="https://www.law.go.kr/행정규칙/고형연료제품의품질표시방법및품질등급구분등에관한고시/(2020-221,20201027)" class="btn xsm" target="_blank" title="새창열림">품질표시 방법 자세히 보기</a>
      </div>
    </div>
    <div class="list">
      <span class="file_name">고형연료제품 제조시설 및 사용시설의 정기검사 절차 및 방법 등에 관한 고시</span>
      <div>
        <a href="https://www.law.go.kr/행정규칙/고형연료제품제조시설및사용시설의정기검사절차및방법등에관한고시/(2020-218,20201027)" class="btn xsm" target="_blank" title="새창열림">정기검사 절차 자세히 보기</a>
      </div>
    </div>
    <div class="list">
      <span class="file_name">고형연료제품의 사용에 적합하다고 인정하는 시설 고시</span>
      <div>
        <a href="https://www.law.go.kr/행정규칙/고형연료제품의사용에적합하다고인정하는시설고시/(2018-156,20181015)" class="btn xsm" target="_blank" title="새창열림">적합 시설 자세히 보기</a>
      </div>
    </div>
    <div class="list">
      <span class="file_name">고형연료제품 품질 시험·분석방법</span>
      <div>
        <a href="https://www.law.go.kr/LSW//admRulLsInfoP.do?admRulId=2036845&efYd=0" class="btn xsm" target="_blank" title="새창열림">시험·분석방법 자세히 보기</a>
      </div>
    </div>
    <div class="list">
      <span class="file_name">폐자원에너지 종합정보관리시스템의 입력방법 등에 관한 고시</span>
      <div>
        <a href="https://www.law.go.kr/행정규칙/폐자원에너지종합정보관리시스템의입력방법등에관한고시/(2020-222,20201027)" class="btn xsm" target="_blank" title="새창열림">시스템 입력방법 자세히 보기</a>
      </div>
    </div>
  </div>

  <h2 class="title1">벌칙</h2>
  <div class="img_box top_line law">
    <h3>3년 이하 징역 또는 3천만원 이하의 벌금<span>자원의 절약과 재활용촉진에 관한 법률 제6장 39조</span></h3>
    <a href="https://www.law.go.kr/법령/자원의절약과재활용촉진에관한법률" class="btn xsm" target="_blank" title="새창열림">관련법령 자세히 보기</a>
  </div>
  <ul class="blue_dot_list">
    <li>제25조의4제1항 또는 제2항을 위반하여 거짓이나 그 밖의 부정한 방법으로 신고를 하고 고형연료제품을 수입 또는 제조한 자</li>
    <li>제25조의5제1항을 위반하여 거짓이나 그 밖의 부정한 방법으로 품질검사를 받고 고형연료제품을 수입 또는 제조한 자</li>
    <li>제25조의7제1항을 위반하여 허가받지 아니하고 고형연료제품을 사용한 자</li>
    <li>거짓이나 그 밖의 부정한 방법으로 제25조의7제1항에 따른 고형연료제품 사용허가를 받은 자</li>
    <li>제25조의10제1항 또는 같은 조 제2항 본문에따른 고형연료제품의 수입·제조 또는 사용금지명령을 위반하여 금지기간 중에 고형연료제품을
      수입·제조 또는 사용한 자</li>
  </ul>
  <div class="img_box top_line law mt_30">
    <h3>1년 이하 징역 또는 1천만원 이하의 벌금<span>자원의 절약과 재활용촉진에 관한 법률 제6장 39조의2</span></h3>
    <a href="https://www.law.go.kr/법령/자원의절약과재활용촉진에관한법률" class="btn xsm" target="_blank" title="새창열림">관련법령 자세히 보기</a>
  </div>
  <ul class="blue_dot_list">
    <li>제9조의4제2항에 따른 중단명령을 받은 포장재·제품을 제조·수입 및 판매한 자</li>
    <li>제25조의4제7항을 위반하여 다른 사람에게 자기의 상호나 성명을 사용하여 수입 또는 제조를 하게 하거나 신고확인증을 빌려 준 자</li>
    <li>제25조의5제1항에 따른 품질기준(수분기준은 제외한다)에 적합하지 아니한 고형연료제품을 수입 또는 제조한 자</li>
    <li>제25조의6제2항을 위반하여 품질표시에 필요한 시험을 의뢰하지 아니한 자 또는 같은 항에 따른 시험 결과와 다른 내용을 표시한 자</li>
    <li>제25조의7제2항을 위반하여 변경허가를 받지 아니하고 고형연료제품 사용의 허가사항을 변경한 자</li>
    <li>제25조의7제3항의 조건을 위반한 자</li>
    <li>제25조의8제1항을 위반하여 제조시설 및 사용시설 정기검사를 받지 아니한 자</li>
    <li>제25조의8제3항을 위반하여 개선명령을 이행하지 아니하고 해당 시설을 이용하여 고형연료제품을 제조 또는 사용한 자</li>
    <li>제25조의9제2항을 위반하여 다이옥신 배출 허용기준을 준수하지 아니한 자</li>
  </ul>

  <div class="cont_section mt_40">
    <div class="board_info">
      <h2 class="title1">과태료<span class="s_title">300만원 이하의 과태료</span></h2>
      <a href="https://www.law.go.kr/법령/자원의절약과재활용촉진에관한법률" class="btn xsm" target="_blank">관련법령 자세히 보기</a>
    </div>
    <div class="tbl_wrap">
      <table>
        <caption>과태료_내용, 1차 위반, 2차 위반, 3차 위반으로 구성</caption>
        <colgroup>
          <col style="width:55%">
          <col style="width:15%">
          <col style="width:15%">
          <col style="width:15%">
        </colgroup>
        <thead>
        <tr>
          <th scope="col">내용</th>
          <th scope="col">1차 위반</th>
          <th scope="col">2차 위반</th>
          <th scope="col">3차 위반</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th scope="row" class="txt_left">법 제25조의4제1항 또는 제2항을 위반하여 신고를 하지 않고 고형연료제품을 수입 또는 제조한 경우</th>
          <td>200만원</td>
          <td>250만원</td>
          <td>300만원</td>
        </tr>
        <tr>
          <th scope="row" class="txt_left">법 제25조의4제4항을 위반하여 변경신고를 하지 않은 경우</th>
          <td>100만원</td>
          <td>200만원</td>
          <td>300만원</td>
        </tr>
        <tr>
          <th scope="row" class="txt_left">법 제25조의6제1항을 위반하여 품질표시를 하지 않은 경우</th>
          <td>50만원</td>
          <td>150만원</td>
          <td>300만원</td>
        </tr>
        <tr>
          <th scope="row" class="txt_left">법 제25조의7제2항을 위반하여 변경신고를 하지 않고 고형연료제품 사용의 허가사항을 변경한 경우</th>
          <td>100만원</td>
          <td>200만원</td>
          <td>300만원</td>
        </tr>
        <tr>
          <th scope="row" class="txt_left">법 제25조의9제1항을 위반하여 환경관리를 위한 사항을 준수하지 않은 경우</th>
          <td>100만원</td>
          <td>200만원</td>
          <td>300만원</td>
        </tr>
        <tr>
          <th scope="row" class="txt_left">법 제25조의13제2항을 위반하여 권리·의무 승계신고를 하지 않은 경우</th>
          <td>100만원</td>
          <td>150만원</td>
          <td>200만원</td>
        </tr>
        <tr>
          <th scope="row" class="txt_left">법 제25조의14제2항을 위반하여 정보관리시스템에 정보를 입력하지 않은 경우</th>
          <td>100만원</td>
          <td>200만원</td>
          <td>300만원</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div style="padding-top: 3rem;text-align: right;">
      <img src="@/assets/images/sub/bg_lawInfo.png" alt="이미지">
    </div>
  </div>
</template>

<style scoped>

</style>
<script setup lang="ts">
</script>