<template>
  <h3 class="title2">사업체정보</h3>
  <div class="board_write">
    <table>
      <caption>사업체정보</caption>
      <colgroup>
        <col style="width:20%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:30%">
      </colgroup>
      <tbody>
        <tr>
          <th scope="col">사업체명</th>
          <td class="txt_al">
            {{bzentyParam.bzentyNm}}
          </td>
          <th scope="col">사업자등록번호</th>
          <td class="txt_al">
            {{bzentyParam.brno}}
          </td>
        </tr>
      <tr>
        <th scope="col">소재지</th>
        <td class="txt_al">
          {{bzentyParam.bzentyAddr}}
        </td>
        <th scope="col">위탁운영관리 여부</th>
        <td class="txt_al">
          {{bzentyParam.cnsgnOperMngYn}}
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h3 class="title2">응답자정보</h3>
  <div class="board_write">
    <table>
      <caption>응답자정보</caption>
      <colgroup>
        <col style="width:20%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:30%">
      </colgroup>
      <tbody>
        <tr>
          <th scope="col">소속</th>
          <td class="txt_al"> {{bzentyParam.bzentyNm}} </td>
          <th scope="col">부서</th>
          <td class="txt_al"> {{userParam.deptNm}} </td>
        </tr>
        <tr>
          <th scope="col">직위</th>
          <td class="txt_al"> {{userParam.jbgdNm}} </td>
          <th scope="col">연락처</th>
          <td class="txt_al"> {{userParam.telno}} </td>
        </tr>
        <tr>
          <th scope="col">성명</th>
          <td class="txt_al"> {{userParam.userNm}} </td>
          <th scope="col">E-mail</th>
          <td class="txt_al"> {{userParam.emlAddr}} </td>
        </tr>
      </tbody>
    </table>
  </div>

  <h3 class="title2">폐기물투입</h3>
  <div class="board_write">
    <table>
      <caption>폐기물투입</caption>
      <colgroup>
        <col style="width:20%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:30%">
      </colgroup>
      <tbody>
        <tr>
          <th scope="col">폐기물투입총량</th>
          <td class="txt_al">
            <br-form-input label="폐기물투입총량" style="text-align: right;" v-model="bzentyParam.wtInpqty" :maxlength="13" :readonly="isReadOnly" @input="limitDecimalPlaces" validator="number"/>
          </td>
          <th scope="col">톤/년</th>
          <td class="txt_al">고형연료제품 생산을 위해 투입한 연간 폐기물 총량</td>
        </tr>
        <tr>
          <th scope="col">① 폐타이어</th>
          <td class="txt_al">
            <br-form-input label="폐타이어" style="text-align: right;" v-model="bzentyParam.wsttrtInpqty" :maxlength="13" :readonly="isReadOnly" @input="limitDecimalPlaces" validator="number"/>
          </td>
          <th scope="col" rowspan="6">톤/년</th>
          <td class="txt_al" rowspan="6">
            투입된 폐기물 종류별 해당 폐기물의 투입량
            (폐기물 종류별 투입량 작성이 불가능한 경우 폐기물 반입량으로 작성)
            기타 : ①~④를 제외한 폐기물로 생활폐기물, 종량제봉투 등 포함
          </td>
        </tr>
        <tr>
          <th scope="col">② 폐합성수지</th>
          <td class="txt_al">
              <br-form-input label="폐합성수지" style="text-align: right;" v-model="bzentyParam.wstsrtInpqty" :maxlength="13" :readonly="isReadOnly" @input="limitDecimalPlaces" validator="number"/>
          </td>
        </tr>
        <tr>
          <th scope="col">③ 폐합성섬유</th>
          <td class="txt_al">
            <br-form-input label="폐합성섬유" style="text-align: right;" v-model="bzentyParam.wstsotInpqty" :maxlength="13" :readonly="isReadOnly" @input="limitDecimalPlaces" validator="number"/>
          </td>
        </tr>
        <tr>
          <th scope="col">④ 폐고무</th>
          <td class="txt_al">
            <br-form-input label="폐고무" style="text-align: right;" v-model="bzentyParam.wstrbtInpqty" :maxlength="13" :readonly="isReadOnly" @input="limitDecimalPlaces" validator="number"/>
          </td>
        </tr>
        <tr>
          <th scope="col">⑤ 기타 명</th>
          <td class="txt_al">
            <br-form-input label="기타 명" class="txt_al" v-model="bzentyParam.etcInputWtNm" :maxlength="130" :readonly="isReadOnly"/>
          </td>
        </tr>
        <tr>
          <th scope="col">⑤ 기타 투입량</th>
          <td class="txt_al">
            <br-form-input label="기타 투입량" style="text-align: right;" v-model="bzentyParam.etcWtInpqty" :maxlength="13" :readonly="isReadOnly" @input="limitDecimalPlaces" validator="number"/>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <h3 class="title2">제품생산</h3>
  <div class="board_write">
    <table>
      <caption>폐기물투입</caption>
      <colgroup>
        <col style="width:20%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:30%">
      </colgroup>
      <tbody>
        <tr>
          <th scope="col">생산량</th>
          <td class="txt_al">
            <br-form-input label="생산량" style="text-align: right;" v-model="bzentyParam.prdctPrdqty" :maxlength="13" :readonly="isReadOnly" @input="limitDecimalPlaces" validator="number"/>
          </td>
          <th scope="col">톤/년</th>
          <td class="txt_al">연간 고형연료제품 생산량</td>
        </tr>
        <tr>
          <th scope="col">이월량</th>
          <td class="txt_al">
            <br-form-input label="이월량" style="text-align: right;" v-model="bzentyParam.prdctCrfqty" :maxlength="13" :readonly="isReadOnly" @input="limitDecimalPlaces" validator="number"/>
          </td>
          <th scope="col">톤/년</th>
          <td class="txt_al">이전연도 고형연료제품 이월량(재고량)</td>
        </tr>
        <tr>
          <th scope="col">저위발열량</th>
          <td class="txt_al">
            <br-form-input label="저위발열량" style="text-align: right;" v-model="bzentyParam.prdctLcp" :maxlength="13" :readonly="isReadOnly" @input="limitDecimalPlaces" validator="number"/>
          </td>
          <th scope="col">Kcal/kg</th>
          <td class="txt_al">생산된 제품의 연 평균 저위발열량</td>
        </tr>
      </tbody>
    </table>
  </div>

  <h3 class="title2">제품판매</h3>
  <div class="board_write">
    <table>
      <caption>제품판매</caption>
      <colgroup>
        <col style="width:20%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:30%">
      </colgroup>
      <tbody>
        <tr>
          <th scope="col">판매량</th>
          <td class="txt_al">
            <br-form-input label="판매량" style="text-align: right;" v-model="bzentyParam.prdctNsqty" :maxlength="13" :readonly="isReadOnly" @input="limitDecimalPlaces" validator="number"/>
          </td>
          <th scope="col">톤/년</th>
          <td class="txt_al">연간 고형연료제품 판매량</td>
        </tr>
      </tbody>
    </table>
  </div>

  <h3 class="title2">증빙자료</h3>
  <div class="board_write">
    <table>
      <caption>증빙자료</caption>
      <colgroup>
        <col style="width:20%">
        <col style="width:80%">
      </colgroup>
      <tbody>
        <tr>
          <th scope="col">증빙자료</th>
          <td class="txt_al">
            <br-attach-file label="첨부파일"
                            v-model:atchFileGroupId="bzentyParam.atchFileGroupId"
                            v-model:fileProcessingData="fileProcessingData"
                            atchFileTaskSeCd="RST"
                            :files="bzentyParam.bzentyAtchFiles"
                            :readonly="isReadOnly"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  
  <div class="btn_area between">
    <div>
      <br-button label="목록" @click="parentScrollToWorkSheet('listDiv')" v-if="listDivYn"/>
    </div>
    <div>
      <br-button label="저장" format="primary" style="margin-right: 5px;" @click="updateRtrvlStatsRslt"/>
      <br-button label="제출" format="primary" @click="updateRtrvlStatsSbmsn"/>
    </div>
  </div>

</template>

<script>
/**
 * TODO : 회수통계조사 고형연료제조
 *
 * 작성자 : yw
 * 작성일 : 2024.10.14
 * 버전 : 1.0
 */

//import SbGrid from "@/components/SbGrid.vue";
//import {storeSnackbar} from "@/js/store/store-snackbar";
//import {storeComponent} from "@/js/store/store-component";
//import {storeSwitch} from "@/js/store/store-switch";

const API = {
  RTRVLSTATS_EXMN: "/rtrvlStatsExmn",
  RTRVLSTATS_EXMN_WORKSHEET : "/rtrvlStatsExmn/readRtrvlStatsExmnWorkSheet",
}

export default {
  props :{
    parentData: {
      type: Object,
      required: true
    }
  },
  components : {},
  data() {
    return {
      bzentyParam : {
        bzentyNm: '',
        brno: '',
        bzentyAddr: '',
        cnsgnOperMngYn: '',
        mnftrPrdctKndCd: '',/*제조제품종류코드*/
        wtInpqty: '',		    /*폐기물투입량*/
        wsttrtInpqty: '',		/*폐타이어류투입량*/
        wstsrtInpqty: '',		/*폐합성수지류투입량*/
        wstsotInpqty: '',		/*폐합성유류투입량*/
        wstrbtInpqty: '',		/*폐고무류투입량*/
        etcInputWtNm: '',		/*기타투입폐기물명*/
        etcWtInpqty: '',	  /*기타폐기물투입량*/
        prdctPrdqty: '',		/*제품생산량*/
        prdctCrfqty: '',		/*제품이월량*/
        prdctLcp: '',		    /*제품저위발열량*/
        prdctNsqty: '',		  /*제품판매량*/
        atchFileGroupId: '',/*첨부파일그룹아이디*/
        sbmsnUserId: '',		/*제출사용자아이디*/
        sbmsnYmd: '',	 	    /*제출일자*/
        bplcKndCd: '',
        bzentyAtchFiles: [],
      },
      fileProcessingData: {},
      userParam : {
        deptNm: '',
        jbgdNm: '',
        telno: '',
        userNm: '',
        emlAddr: '',
      },
      isReadOnly: true,
      listDivYn: false,
    };
  },
  computed: {},
  watch : {},
  created() {
  },
  beforeMount() {
  },
  mounted() {
    this.initialize();
  },
  beforeUnmount() {
  },
  methods : {
    /**화면 초기화 */
    async initialize() {
      if(this.parentData.updateYn){
        if(this.parentData.updateYn === 'Y'){
          this.isReadOnly = false;
        }
      }

      if (this.parentData.rtrvlStatsSn && this.parentData.bzentyId && this.parentData.bplcId && this.parentData.fcltSqno) {
        this.readSrfMnftrRslt();
      }
      
      setTimeout(() => {
         this.parentScrollToWorkSheet('workSheetDiv');
      }, 200)      
    },
    /**결과 조회 */
    readSrfMnftrRslt() {
      return new Promise((resolve) => {
        this.$apiCall.get(API.RTRVLSTATS_EXMN_WORKSHEET, 
        {
          rtrvlStatsSn : this.parentData.rtrvlStatsSn,
          bzentyId : this.parentData.bzentyId,
          bplcId : this.parentData.bplcId,
          fcltSqno : this.parentData.fcltSqno,
          bplcKndCd : this.parentData.bplcKndCd
        }, data => {
          if (data.result) {
            
            ///업체정보
            if(data.result.bzentyInfo){
              this.bzentyParam = data.result.bzentyInfo;
            }

            //작성자정보
            if(data.result.userInfo){
              this.userParam = data.result.userInfo;
            }
            resolve();
          } else {
            //reject(new Error("데이터를 가져오는 중 오류가 발생했습니다."));
          }
        });
      });
    },

    /**결과 저장 */
    updateRtrvlStatsRslt() {
      this.bzentyParam.rtrvlStatsSn = this.parentData.rtrvlStatsSn; /*회수통계일련번호*/
      this.bzentyParam.bzentyId = this.parentData.bzentyId;         /*업체아이디*/
      this.bzentyParam.bplcId = this.parentData.bplcId;             /*사업장아이디*/
      this.bzentyParam.fcltSqno = this.parentData.fcltSqno;         /*시설순번*/
      this.bzentyParam.bplcKndCd = this.parentData.bplcKndCd;       /*사업장종류코드*/

      if(confirm("저장하시겠습니까?")) {
        this.$apiCall.put(
          API.RTRVLSTATS_EXMN + "/updateRtrvlStatsRslt",
          {rsltParam: this.bzentyParam, fileProcessingData: this.fileProcessingData},
          () => {
            alert("저장되었습니다.");
            this.readSrfMnftrRslt();
          },
          () => {
            alert("저장에 실패하였습니다.");
          }
        );
      }
    },

    /**제출 버튼 클릭 이벤트 */
    updateRtrvlStatsSbmsn() {
      this.bzentyParam.rtrvlStatsSn = this.parentData.rtrvlStatsSn; /*회수통계일련번호*/
      this.bzentyParam.bzentyId = this.parentData.bzentyId;         /*업체아이디*/
      this.bzentyParam.bplcId = this.parentData.bplcId;             /*사업장아이디*/
      this.bzentyParam.fcltSqno = this.parentData.fcltSqno;         /*시설순번*/
      this.bzentyParam.bplcKndCd = this.parentData.bplcKndCd;       /*사업장종류코드*/

      if(confirm("제출하시겠습니까?")) {
        this.$apiCall.put(
          API.RTRVLSTATS_EXMN + "/updateRtrvlStatsSbmsn",
          {rsltParam: this.bzentyParam, fileProcessingData: this.fileProcessingData, param: this.parentData},
          () => {
            alert("제출되었습니다.");
            this.readSrfMnftrRslt();
          },
          () => {
            alert("제출에 실패하였습니다.");
          }
        );
      }
    },

    /**숫자, 소수점2자리 */
    limitDecimalPlaces(event) {
      const value = event.target.value.toString();
      let newVal = value?.replace(/^[^0-9]|[^\d.]/g, '');
      const regExp = /^\d+(?:\.\d{0,2})?|\d*\./g;
      const match = newVal?.match(regExp);
      event.target.value = match ? match[0] : newVal;
    },

    parentScrollToWorkSheet(obj) {
      this.$nextTick(() => {
        if(this.$parent.$refs[obj]) {
          this.$parent.$refs[obj].scrollIntoView({ behavior: 'smooth' });
          this.listDivYn= true;
        }

      });
    },

  }
}
</script>

<style scoped>

</style>