<template>
  <div v-show="isOn" class="modal visible">
    <div class="popup_box"  style="width: 80%">
      <h1 class="popup_title">업체 변경신청 정보</h1>
      <div>
        <button type="button" class="modal_close" @click.prevent="hide">팝업닫기</button>
      </div>
      <div class="popup_cont" style="max-height: calc(90% - 15px);overflow: hidden;overflow-y: auto;">
        <!-- 업체 정보 -->
        <div class="board_info">
          <h3 class="title1">업체 정보</h3>
        </div>
        <div class="board_write">
          <table>
            <caption>업체정보로 구성</caption>
            <colgroup>
              <col style="width:18%">
              <col style="width:32%">
              <col style="width:18%">
              <col style="width:32%">
            </colgroup>
            <tbody>
            <tr>
              <th scope="row">사업자등록번호</th>
              <td>
                {{this.bizfaciltyBzenty.brno }}
              </td>
              <th scope="row">업체 승인상태</th>
              <td :style="bizfaciltyBzenty.aprvYn === 'N' ? 'color: red' : '' ">
                {{getCdName(this.aprvYnCdList, bizfaciltyBzenty.aprvYn)}}
              </td>
            </tr>
            <tr>
              <th scope="row">업체 명</th>
              <td>
                {{this.bizfaciltyBzenty.bzentyNm }}
              </td>
              <th scope="row">업체 명(영문)</th>
              <td>
                {{this.bizfaciltyBzenty.engBzentyNm }}
              </td>
            </tr>
            <tr>
              <th scope="row">대표자 명</th>
              <td>
                {{this.bizfaciltyBzenty.rprsvNm }}
              </td>
              <th scope="row">대표자 명(영문)</th>
              <td>
                {{this.bizfaciltyBzenty.engRprsvNm }}
              </td>
            </tr>
            <tr>
              <th scope="row">전화번호</th>
              <td>
                {{this.bizfaciltyBzenty.telno}}
              </td>
              <th scope="row">FAX</th>
              <td>
                {{this.bizfaciltyBzenty.fxno}}
              </td>
            </tr>
            <tr>
              <th scope="row">주소</th>
              <td colspan="3">
                {{ `${this.bizfaciltyBzenty.untyZip} ${this.bizfaciltyBzenty.bzentyAddr} ${this.bizfaciltyBzenty.bzentyDaddr}` }}
              </td>
            </tr>
            <tr>
              <th scope="row">EPR 업체</th>
              <td>
                {{'Y' === this.bizfaciltyBzenty.eprBzentyYn ? '대상' : '대상아님'}}
              </td>
              <th scope="row">업체 상태</th>
              <td>
                {{getCdName(this.bzentySttsCdList, this.bizfaciltyBzenty.bzentySttsCd)}}
              </td>
            </tr>
            <tr>
              <th scope="row">첨부서류</th>
              <td colspan="3">
                <br-attach-file-list label="업체 첨부파일 목록"
                                     ref="attachFileList"
                                     :atchFileTaskSeCd="atchFileTaskSeCd"
                                     v-model:atchFileGroupId="bizfaciltyBzenty.atchFileGroupId"
                                     :maxFileCount="10"
                                     :readonly="true"
                                     :use-etc-file="true"
                />
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="btn_area center">
          <br-button label="닫기" class="btn sm tertiary" @click="hide" />
        </div>
      </div>
  </div>
    </div>
</template>

<script>
import {storeSwitch} from "@/js/store/store-switch";
import SbGrid from "@/components/SbGrid.vue";
import MbrFormTelno from "@/views/mbr-mng/components/MbrFormTelno.vue";
import {storeSession} from "@/js/store/store-session";
import BrFormRadio from "@/components/BrFormRadio.vue";
import BrButton from "@/components/BrButton.vue";
import BrAttachFileList from "@/components/BrAttachFileList.vue";
import BrFormInput from "@/components/BrFormInput.vue";

const API = {
  BZENTY_CHG_INFO: "/bizfacility/bzenty-chg-info",
};

const CODE = {
  ARPV_YN_CD: "SIS020", // 승인여부코드(업체)
  BZENTY_STTS_CD: "MBR003", // 업체상태코드
  BPLC_KND_CD: "MBR006", // 사업장종류코드
  PRDCTN_PRDCT_KND_CD: "MBR010", // 생산제품종류코드
  ARPV_PRCS_STTS_CD: "MBR019",  //승인처리상태코드(사용자)
  CHG_APLY_PRCS_STTS_CD: "MBR009",  //변경신청처리상태코드
};


export default {
  components: {
    BrFormInput,
    BrAttachFileList,
    BrButton,
    BrFormRadio, MbrFormTelno, SbGrid
  },
  props: {
    bzentyId: {type: Number, required: true},
    chgAplySn: {type: Number, required: true},
  },
  data() {
    return {
      userInfo: storeSession.getMemberInfo(),
      isOn: false,
      atchFileTaskSeCd: 'BET',
      bizfaciltyBzenty: {
        bzentyId: 0,
        bzentyNm: '',
        bzentyNmDp: '',
        engBzentyNm: '',
        brno: '',
        rprsvNm: '',
        engRprsvNm: '',
        rprsvBrdt: '',
        telno: '',
        telno1: '',
        telno2: '',
        telno3: '',
        fxno: '',
        fxno1: '',
        fxno2: '',
        fxno3: '',
        untyZip: '',
        bzentyAddr: '',
        bzentyDaddr: '',
        pubBzentyYn: '',
        atchFileGroupId: '',
        bzentySttsCd: '',
        aprvYn: '',
        eprBzentyYn: '',
        trlBzentyYn: '',
        useYn: '',
        regDt: '',
        rgtrId: '',
        mdfcnDt: '',
        mdfrId: '',
      },
      bzentySttsCdList: [],
      bplcKndCdList: [],
      prdctnPrdctKndCdList: [],
      aprvYnCdList: [],
      eprBzentyYnlist : [
        { value: "Y", text: "대상" },
        { value: "N", text: "대상아님" },
      ],
    };
  },
  computed: {
  },
  watch: {
    isOn(newVal){
      if(newVal){
        this.initialize();
      }
    }
  },
  created() {
    storeSwitch.setBulb('bzentyChgAplyInfoPopup', this);
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeUnmount() {
  },
  methods: {
    /** 팝업 Show */
    show() {
      this.isOn = true;
    },
    /** 팝업 Hide */
    hide() {
      this.isOn = false;
    },
    /** 화면 초기화 */
    async initialize() {
      // 공통코드 초기화
      this.bzentySttsCdList = this.$store.getters.getCodeList({groupCd: CODE.BZENTY_STTS_CD, useYn: 'Y'});   // 업체상태코드
      this.bplcKndCdList = this.$store.getters.getCodeList({groupCd: CODE.BPLC_KND_CD, useYn: 'Y'});         // 사업장종류코드
      this.prdctnPrdctKndCdList = this.$store.getters.getCodeList({groupCd: CODE.PRDCTN_PRDCT_KND_CD, useYn: 'Y'});   // 생산제품종류코드
      this.aprvYnCdList = this.$store.getters.getCodeList({groupCd: CODE.ARPV_YN_CD, useYn: 'Y'});           // 승인여부코드

      // 업체정보 조회
      this.readBzentyInfo();

    },
    /** 승인신청, 정보변경신청, 보완후 재신청 데이터 조회 */
    readBzentyInfo() {
      this.$apiCall.get(
          API.BZENTY_CHG_INFO,
          {
            bzentyId: this.bzentyId,
            chgAplySn: this.chgAplySn
          },
          data => {
            if (data.result) {
              for (const key of Object.keys(this.bizfaciltyBzenty)) {
                this.bizfaciltyBzenty[key] = data.result?.[key] ?? '';
              }
            }
          });
    },
    /** 코드명 출력  */
    getCdName(list, code) {
      const status = list.find(item => item.dtlCd === code);
      return status ? status.dtlCdNm : '';
    },
  }
}
</script>

<style scoped>
.subtitle-note {
  font-size: 15px;      /* 폰트 크기 설정 */
  font-weight: normal;  /* 굵기 설정 (기본 또는 normal) */
  color: #cc0000;       /* 원하는 색상 코드로 변경 */
  margin-left: 10px;
}
</style>