import { createStore } from 'vuex';
import apiCall from "@/js/api-call";

const API = {
    COM_DTL_CD : "/code/detail-all",
    SGG_CD : "/sgg/all"
}

export default new createStore({
    state: {
        codeList: [],
        sggList: [],
    },
    mutations: {
        setCodeList(state, fetchedCodeList) {
            state.codeList = fetchedCodeList;
        },
        setSggList(state, fetchedSggList) {
            state.sggList = fetchedSggList;
        },
    },
    actions: {
        fetchCodeList({ state, commit }) {
            return new Promise((resolve, reject) => {
                if (state.codeList && state.codeList.length > 0) {
                    resolve();
                } else {
                    apiCall.get(API.COM_DTL_CD, {}, data => {
                        if (data.result) {
                            commit('setCodeList', data.result);
                        }
                        resolve();
                    }, (error) => {
                        reject(error);
                    });
                }
            });
        },
        fetchSggList({ state, commit }) {
            return new Promise((resolve, reject) => {
                if (state.sggList && state.sggList.length > 0) {
                    resolve();
                } else {
                    apiCall.get(API.SGG_CD, {useYn: 'Y'}, data => {
                        if (data.result) {
                            commit('setSggList', data.result);
                        }
                        resolve();
                    }, (error) => {
                        reject(error);
                    });
                }
            });
        }
    },
    getters: {
        getCodeList: (state) => (params) => {
            const { groupCd, useYn, frstRefVl, scdRefVl, thdRefVl, frthRefVl, fthRefVl } = params;

            if (!groupCd) {
                return [];
            }

            const filteredCodeList = state.codeList.filter(item => {
                return (
                    (!groupCd || item.groupCd === groupCd) &&
                    (!useYn || item.useYn === useYn) &&
                    (!frstRefVl || item.frstRefVl === frstRefVl) &&
                    (!scdRefVl || item.scdRefVl === scdRefVl) &&
                    (!thdRefVl || item.thdRefVl === thdRefVl) &&
                    (!frthRefVl || item.frthRefVl === frthRefVl) &&
                    (!fthRefVl || item.fthRefVl === fthRefVl)
                );
            });

            return JSON.parse(JSON.stringify(filteredCodeList));
        },
        getDtlCdNm: (state) => (groupCd, dtlCd) => {
            if (!groupCd || !dtlCd) {
                return "";
            }

            const matchedItem = state.codeList.find(item => item.groupCd === groupCd && item.dtlCd === dtlCd);
            return matchedItem ? matchedItem.dtlCdNm : "";
        },
        getSggList: (state) => (params) => {
            const { upSggStdgCd, type } = params;

            const filteredSggList = state.sggList.filter(item => {
                if (type === "ctpv") {  // 상위 시군구 코드가 없는 경우 (광역시/도)
                    return !item.upSggStdgCd;
                } else if (type === "sgg") {  // 상위 시군구 코드가 있는 경우 (시/군/구)
                    return !!item.upSggStdgCd;
                } else if (upSggStdgCd) {  // 특정 상위 시군구 코드로 필터링
                    return item.upSggStdgCd === upSggStdgCd;
                }
            });

            return JSON.parse(JSON.stringify(filteredSggList));
        },
        getSggNm: (state) => (sggStdgCd) => {
            if (!sggStdgCd) {
                return "";
            }

            const matchedItem = state.sggList.find(item => item.sggStdgCd === sggStdgCd);
            return matchedItem ? matchedItem.sggNm : "";
        }
    }
})