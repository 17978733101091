<template>
  <div v-show="isOn" class="modal visible">
    <div class="popup_box"  style="width: 80%;">
      <h1 class="popup_title">사업장 보완요청 정보</h1>
      <div>
        <button type="button" class="modal_close" @click.prevent="hide">팝업닫기</button>
      </div>
      <div class="popup_cont" style="max-height: calc(90vh - 15px); overflow: hidden; overflow-y: auto;">
        <div class="board_info">
          <h3 class="title1">보완요청 사유<span class="subtitle-note">*은 필수입력 항목 입니다.</span></h3>
        </div>
        <div class="board_write">
          <div class="splmnt-message">
            {{ this.prcsRsn }}
          </div>
        </div>

        <!-- 사업장 정보 -->
        <h3 class="title1">사업장 정보</h3>
        <div class="board_write">
          <table>
            <caption></caption>
            <colgroup>
              <col style="width:15%">
              <col style="width:35%">
              <col style="width:15%">
              <col style="width:35%">
            </colgroup>
            <tbody>
            <tr>
              <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>사업장 명</th>
              <td>
                {{this.bizfaciltyBplc.bplcNm}}
              </td>
              <th scope="row">사업장 명(영문)</th>
              <td>
                {{this.bizfaciltyBplc.engBplcNm}}
              </td>
            </tr>
            <tr>
              <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>사업장 종류</th>
              <td>
                {{getCdName(this.bplcKndCdList, modifiableData.bplcKndCd)}}
              </td>
              <th scope="row">담당자 명</th>
              <td>
                <br-form-input label="담당자명" v-model="modifiableData.picNm"/>
              </td>
            </tr>
            <tr>
              <th scope="row"><i class="necessary">필수입력</i>전화번호</th>
              <td>
                <mbr-form-telno label="전화번호"
                                v-model:telNo1="modifiableData.telno1"
                                v-model:telNo2="modifiableData.telno2"
                                v-model:telNo3="modifiableData.telno3"
                                :required="true"
                                ref="telnoInput"/>
              </td>
              <th scope="row">FAX</th>
              <td>
                <mbr-form-telno label="FAX"
                                v-model:telNo1="modifiableData.fxno1"
                                v-model:telNo2="modifiableData.fxno2"
                                v-model:telNo3="modifiableData.fxno3"
                                ref="fxnoInput"/>
              </td>
            </tr>
            <tr>
              <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>주소</th>
              <td colspan="3">
                <div class="form_group_addr" style="display: flex; flex-direction: column;">
                  <div class="input_group" style="max-width: calc(50% - .6rem);">
                    <br-form-input label="우편번호" class="form_control addr_zip" style="text-align: left;" v-model="modifiableData.untyZip" disabled></br-form-input>
                    <br-button label="주소검색" format="primary" size="sm" @click="callPopupAddress" />
                  </div>
                  <div class="input_group">
                    <br-form-input label="주소" class="form_control" style="text-align: left;" v-model="modifiableData.bplcAddr" panel="panel7" required disabled></br-form-input>
                    <br-form-input label="상세주소" class="form_control" style="text-align: left;" v-model="modifiableData.bplcDaddr"></br-form-input>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">위탁운영관리</th>
              <td colspan="3">
                <div class="chk_area">
                  <br-form-radio label="위탁운영관리여부"  v-model:value="modifiableData.cnsgnOperMngYn" :options="radioBtnYnList" value-field="value" text-field="text" @change="setUnusedAtchFileList"/>
                </div>
              </td>
            </tr>
            <tr v-if="modifiableData.cnsgnOperMngYn === 'Y'">
              <th scope="row"><i  class="necessary">*<span class="sr_only">필수입력</span></i>위탁업체명</th>
              <td>
                <br-form-input label="위탁업체명" :disabled="modifiableData.cnsgnOperMngYn === 'N'" v-model="modifiableData.cnsgnBzentyNm" panel="panel7" :required="modifiableData.cnsgnOperMngYn === 'Y'" />
              </td>
              <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>위탁업체 사업자등록번호</th>
              <td>
                <br-form-input label="위탁업체사업자등록번호" :disabled="modifiableData.cnsgnOperMngYn === 'N'" v-model="modifiableData.cnsgnBzentyBrno" panel="panel7" :required="modifiableData.cnsgnOperMngYn === 'Y'" :maxlength="10" validator="brno"/>
              </td>
            </tr>
            <tr>
              <th scope="row">사업장 상태</th>
              <td :colspan="conds.showWes && conds.uncheck? '' : 3">
                <br-form-radio label="사업장상태" groupCd="MBR004" v-model:value="modifiableData.bplcSttsCd"/>
              </td>
              <th scope="row" v-if="conds.showWes && conds.uncheck">자원순환시설 ID</th>
              <td style="display: flex; gap: 5px" v-if="conds.showWes && conds.uncheck">
                <br-form-input style="width: 70%" label="자원순환시설ID"  v-model="modifiableData.rsrcRcyclnFcltId" readonly/>
                <br-button label="시설검색" format="tertiary" size="sm" icon="search" style="width: 30%;" @click="callRsrcRcyclnFcltPopup"/>
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row">대기배출시설설치신고</th>
              <td>
                <div class="chk_area">
                  <br-form-radio label="대기배출시설설치신고여부"  v-model:value="modifiableData.atmoDschrgFcltInstlDclrYn" :options="radioBtnYnList" value-field="value" text-field="text"/>
                </div>
              </td>
              <th scope="row">중간재활용업체</th>
              <td>
                <div class="chk_area">
                  <br-form-radio label="중간재활용업체여부"  v-model:value="modifiableData.mdRecyBzentyYn" :options="radioBtnYnList" value-field="value" text-field="text"/>
                </div>
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row">종합재활용업체</th>
              <td>
                <div class="chk_area">
                  <br-form-radio label="중간재활용업체여부"  v-model:value="modifiableData.snthsRecyBzentyYn" :options="radioBtnYnList" value-field="value" text-field="text"/>
                </div>
              </td>
              <th scope="row">최종재활용업체</th>
              <td>
                <div class="chk_area">
                  <br-form-radio label="최종재활용업체여부"  v-model:value="modifiableData.lastRecyBzentyYn" :options="radioBtnYnList" value-field="value" text-field="text"/>
                </div>
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row">중간처분업체</th>
              <td>
                <div class="chk_area">
                  <br-form-radio label="중간처분업체여부"  v-model:value="modifiableData.mdDspsBzentyYn" :options="radioBtnYnList" value-field="value" text-field="text"/>
                </div>
              </td>
              <th scope="row">최종처분업체</th>
              <td>
                <div class="chk_area">
                  <br-form-radio label="최종처분업체여부"  v-model:value="modifiableData.lastDspsBzentyYn" :options="radioBtnYnList" value-field="value" text-field="text"/>
                </div>
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row">종합처분업체</th>
              <td colspan="3">
                <div class="chk_area">
                  <br-form-radio label="종합처분업체여부"  v-model:value="modifiableData.snthsDspsBzentyYn" :options="radioBtnYnList" value-field="value" text-field="text"/>
                </div>
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row"><i v-if="conds.showAllSrf" class="necessary">*<span class="sr_only">필수입력</span></i>설치운영허가번호</th>
              <td>
                <br-form-input label="설치운영허가번호" v-model="modifiableData.instlOperPrmsnNo" panel="panel7" :required="conds.showAllSrf"/>
              </td>
              <th scope="row" style="text-align: left;">
                <div style="display: flex; align-items: center;">
                  <i v-if="conds.showAllSrf" class="necessary" style="margin-right: 5px;">필수입력</i>설치운영<br/>허가일자
                </div>
              </th>
              <td>
                <br-date-picker label="설치운영허가일자" v-model:value="modifiableData.instlOperPrmsnYmd" panel="panel7" :required="conds.showAllSrf"/>
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row">신고허가번호</th>
              <td>
                <br-form-input label="신고허가번호" v-model="modifiableData.dclrPrmsnNo"/>
              </td>
              <th scope="row">신고허가일자</th>
              <td>
                <br-date-picker label="신고허가일자" v-model:value="modifiableData.dclrPrmsnYmd"/>
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row">처리시설설치신고</th>
              <td>
                <div class="chk_area">
                  <br-form-radio label="처리시설설치신고여부"  v-model:value="modifiableData.prcsFcltInstlDclrYn" :options="radioBtnYnList" value-field="value" text-field="text"/>
                </div>
              </td>
              <th scope="row">총 사업비</th>
              <td>
                <br-form-input label="총사업비" v-model="modifiableData.tpcoAmt" align="right" validator="number"/>
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row">인원수</th>
              <td>
                <br-form-input label="인원수" v-model="modifiableData.nope" align="right" validator="number"/>
              </td>
              <th scope="row">운영금액</th>
              <td>
                <br-form-input label="운영금액" v-model="modifiableData.operAmt" align="right" validator="number"/>
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row">편익시설 종류</th>
              <td>
                <br-form-input label="편익시설종류" v-model="modifiableData.cnvncFcltKndNm"/>
              </td>
              <th scope="row">편익시설 설치비용</th>
              <td>
                <br-form-input label="편익시설설치비용" v-model="modifiableData.cnvncFcltInstlCst" align="right" validator="number"/>
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row">편익시설 운영기관</th>
              <td>
                <br-form-input label="편익시설운영기관" v-model="modifiableData.cnvncFcltOperInstNm"/>
              </td>
              <th scope="row">편익시설 비고</th>
              <td>
                <br-form-input label="편익시설비고" v-model="modifiableData.cnvncFcltRmrkCn"/>
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row">사업기간</th>
              <td colspan="3">
                <br-date-from-to label="사업기간" v-model:fromDate="modifiableData.bizBgngYmd" v-model:toDate="modifiableData.bizEndYmd"/>
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row">국고금액</th>
              <td>
                <br-form-input label="국고금액" v-model="modifiableData.gvcfrAmt" align="right" validator="number"/>
              </td>
              <th scope="row">지방비금액</th>
              <td>
                <br-form-input label="지방비금액" v-model="modifiableData.lgscAmt" align="right" validator="number"/>
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row">민자금액</th>
              <td>
                <br-form-input label="민자금액" v-model="modifiableData.pvtcptAmt" align="right" validator="number"/>
              </td>
              <th scope="row">기타금액</th>
              <td>
                <br-form-input label="기타금액" v-model="modifiableData.etcAmt" align="right" validator="number"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <h3 class="title2">첨부서류</h3>
        <br-attach-file-list label="사업장 첨부파일 목록"
                             ref="attachFileList"
                             :atchFileTaskSeCd="atchFileTaskSeCd"
                             v-model:atchFileGroupId="modifiableData.atchFileGroupId"
                             :maxFileCount="10"
                             :unusedAtchFileList="unusedAtchFileList"
                             :useEtcFile="true"
        />
        <div class="btn_area center">
          <br-button label="변경 재신청" class="btn md primary"  @click="processChangeRequest" panel="panel7" validate/>
          <br-button label="닫기" class="btn md point"  @click="cancelModify"/>
        </div>
      </div>
    </div>
  </div>
  <address-popup
      @input-button-clicked="selectedAddress"
      :id="addressPopId"
  />
  <rsrcRcyclnFcltPopup
      @select-button-clicked="selectedRsrcRcyclnFcltPopup"
      :id="rsrcRcyclnFcltPopId"
  />
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";
import {storeSession} from "@/js/store/store-session";
import AddressPopup from "@/views/mbr-mng/components/MbrFormAddress.vue";
import SbGrid from "@/components/SbGrid.vue";
import MbrFormTelno from "@/views/mbr-mng/components/MbrFormTelno.vue";
import BrFormRadio from "@/components/BrFormRadio.vue";
import BrDatePicker from "@/components/BrDatePicker.vue";
import BrButton from "@/components/BrButton.vue";
import RsrcRcyclnFcltPopup from "@/views/my-office/components/popup/RsrcRcyclnFcltPopup.vue";

const API = {
  BPLC_CHG_INFO: "/bizfacility/bplc-chg-info",
};

const CODE = {
  ARPV_YN_CD: "SIS020", // 승인여부코드
  BPLC_STTS_CD: "MBR004", // 사업장상태코드
  BPLC_KND_CD: "MBR006", // 사업장종류코드
  MBR_STTS_CD: "COM428",  // 회원상태코드
  CHG_APLY_TYPE_CD: "MBR008", //변경신청유형코드
  CHG_APLY_PRCS_STTS_CD: "MBR009",  //변경신청처리상태코드
  BPLC_MNG_PIC_STTS_CD: "MBR023",  //사업장관리담당자상태코드
}

const initialUnusedAtchFileList = ['053', '054', '008'];

export default {
  components: {
    RsrcRcyclnFcltPopup,
    BrButton,
    BrDatePicker,
    BrFormRadio,
    SbGrid, MbrFormTelno, AddressPopup,
  },
  emits: ['complete'],
  props: {
    bplcId: {type: String, required: true},
    chgAplySn: {type: Number, required: true},
    chgAplySeCd: {type: String, required: true},
    prcsRsn: {type: String, default: ''},
  },
  data() {
    return {
      isOn: false,
      addressPopId: 'addressPopupForBplcSplmnt',
      rsrcRcyclnFcltPopId: 'rsrcRcyclnFcltPopupForBplcSplmnt',
      userInfo: storeSession.getMemberInfo(),
      atchFileTaskSeCd: '',
      unusedAtchFileList: [...initialUnusedAtchFileList],
      bizfaciltyBplc: {
        bplcId: '',
        bplcNm: '',
        engBplcNm: '',
        bzentyId: 0,
        bplcKndCd: '',
        cnsgnOperMngYn: 'N',
        cnsgnBzentyNm: '',
        cnsgnBzentyBrno: '',
        cnsgnBzentyScndwpNo: '',
        picNm: '',
        telno: '',
        telno1: '',
        telno2: '',
        telno3: '',
        fxno: '',
        fxno1: '',
        fxno2: '',
        fxno3: '',
        untyZip: '',
        bplcAddr: '',
        bplcDaddr: '',
        ctpvStdgCd: '',
        sggStdgCd: '',
        cmptncRgnHqCd: '',
        lat: '',
        lot: '',
        snthsRecyBzentyYn: '',
        lastRecyBzentyYn: '',
        mdRecyBzentyYn: '',
        prcsFcltInstlDclrYn: '',
        atmoDschrgFcltInstlDclrYn: '',
        mdDspsBzentyYn: '',
        lastDspsBzentyYn: '',
        snthsDspsBzentyYn: '',
        instlOperPrmsnNo: '',
        instlOperPrmsnYmd: '',
        dclrPrmsnNo: '',
        dclrPrmsnYmd: '',
        atchFileGroupId: '',
        bplcSttsCd: '',
        aprvYn: '',
        mngrMemoCn: '',
        userMemoCn: '',
        useYn: '',
        nope: '',
        operAmt: '',
        rmrkCn: '',
        cnvncFcltKndNm: '',
        cnvncFcltInstlCst: '',
        cnvncFcltOperInstNm: '',
        cnvncFcltRmrkCn: '',
        tpcoAmt: '',
        bizBgngYmd: '',
        bizEndYmd: '',
        gvcfrAmt: '',
        lgscAmt: '',
        pvtcptAmt: '',
        etcAmt: '',
        fillAftrUsePlanCn: '',
        regDt: '',
        rgtrId: '',
        mdfcnDt: '',
        mdfrId: '',
        bplcCtpvCd: '',
        userId: '',
        bplcMngPicSttsCd: '',
        rnMgtSn: '',
      },
      modifiableData: {
      },
      conds: {
        showWes: false, //사업장종류 = 폐자원에너지
        income: false,  //사업장종류 = 고형연료수입
        uncheck: false, //사업장종류 = 미관리 종류(uncheckList)
        showAllSrf: false,  //고형연료 제조, 사용
        showSrfMnf: false,  //고형연료 제조
        showSrfUse: false,  //고형연료 사용
      },
      uncheckList: [  // 미관리 종류
        '06-01',  //열분해유
        '07-01',  //재생연료유
        '08-01',  //정제연료유
        '09-01',  //시멘트소성로 보조연료
        '10-01',  //유기성오니 보조연료
      ],
      allowedAuthCodeList: [
        '90',   //한국환경공단
        '07-04',  //한국환경공단 담당자
        '09-01',  //한국환경공단의 시설업무 담당자
        '09-02',  //폐자원에너지검사부 담당자
        '09-03',  //폐자원에너지검사부 담당자
        '09-04',  //폐자원에너지부 담당자
        '09-06',  //정책업무운영자(임시)
        '09-09',  //시스템 보안 담당자
        '10-01'   //시스템 관리자
      ],
      bplcKndCdList: [],
      radioBtnYnList: [
        { value: "Y", text: "대상" },
        { value: "N", text: "비대상" },
      ],
    };
  },
  computed: {
    // 업체 정보 수정여부 확인하기
    isChanged(){
      return JSON.stringify(this.bizfaciltyBplc) !== JSON.stringify(this.modifiableData);
    },
  },
  watch: {
    isOn(newVal){
      if(newVal){
        this.initialize();
      }else{
        this.reset();
      }
    }
  },
  created() {
    storeSwitch.setBulb('bplcSplmntPopup', this);
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeUnmount() {
  },
  methods: {
    show() {
      this.isOn = true;
    },
    hide() {
      this.isOn = false;
    },
    async initialize() {
      this.bplcKndCdList = this.$store.getters.getCodeList({groupCd: CODE.BPLC_KND_CD, useYn: 'Y'});         // 사업장종류코드
      this.readBplcInfo();
    },
    readBplcInfo() {
      this.$apiCall.get(
          API.BPLC_CHG_INFO,
          {
            bplcId: this.bplcId,
            chgAplySn: this.chgAplySn
          },
          async data => {
        if (data.result) {
            for (const key of Object.keys(this.bizfaciltyBplc)) {
            //null 값일 경우
            this.bizfaciltyBplc[key] = data.result?.[key] ?? '';
          }
          this.modifiableData = JSON.parse(JSON.stringify(this.bizfaciltyBplc));
          await this.setConditions(this.bizfaciltyBplc.bplcKndCd);
          await this.setUnusedAtchFileList()
        }
      });
    },
    getCdName(list, code) {
      const status = list.find(item => item.dtlCd === code);
      return status ? status.dtlCdNm : '';
    },
    validateTelno() {
      const telnoInput = this.$refs.telnoInput;
      if (telnoInput && !telnoInput.isValid()) {
        alert("전화번호를 올바르게 입력해 주세요.")
        return false;
      }
      const fxnoInput = this.$refs.fxnoInput;
      if (this.bizfaciltyBplc.fxno && !fxnoInput.isValid()) {
        alert("팩스번호를 올바르게 입력해 주세요.")
        return false ;
      }
      return true;
    },
    setConditions(bplcKndCd){
      const mainCd = bplcKndCd.slice(0, 2);
      const subCd = bplcKndCd.slice(3, 5);

      // 초기화
      this.conds = {
        showWes: mainCd !== '01',
        uncheck: !this.uncheckList.includes(bplcKndCd),
        income: mainCd === '01' && subCd === '03',
        showAllSrf: mainCd === '01' ,  //고형연료 제조, 사용
        showSrfMnf: bplcKndCd === '01-01',  //고형연료 제조
        showSrfUse: bplcKndCd === '01-02',  //고형연료 사용
      };

      // 고형연료 및 수입연료 조건 처리
      if (mainCd === '01') {
        if (subCd === '01' || subCd === '02') {
          switch (subCd){
            case '01' :
              this.atchFileTaskSeCd = 'BPCA'
              break;
            case '02' :
              this.atchFileTaskSeCd = 'BPCC'
              break;
          }
          this.conds.uncheck = true;
          this.conds.income = false;
        } else if (subCd === '03') {
          this.conds.uncheck = true;
          this.conds.income = true;
          this.atchFileTaskSeCd = 'BPCD'
        }
      }else{
        this.atchFileTaskSeCd = 'BPCB';
      }
    },
    selectedAddress(data) {
      this.bizfaciltyBplc.untyZip = data.zipNo;
      this.bizfaciltyBplc.bplcAddr = data.roadAddrPart1;
      this.bizfaciltyBplc.bplcDaddr = data.roadDetailAddr;

      if(data.admCd) {
        const stdgCd = data.admCd;
        this.bizfaciltyBplc.ctpvStdgCd = stdgCd.slice(0, 2) + '0'.repeat(stdgCd.length - 2);
        this.bizfaciltyBplc.sggStdgCd = stdgCd.slice(0, 5) + '0'.repeat(stdgCd.length - 5);
      }
    },
    selectedRsrcRcyclnFcltPopup(data){
      if( data.bplcId ) this.modifiableData.rsrcRcyclnFcltId = data.bplcId;
      else this.modifiableData.rsrcRcyclnFcltId = '';
    },
    processChangeRequest(){

      this.modifiableData.telno = `${this.modifiableData.telno1}${this.modifiableData.telno2}${this.modifiableData.telno3}`
      this.modifiableData.fxno = `${this.modifiableData?.fxno1 || ''}${this.modifiableData?.fxno2 || ''}${this.modifiableData?.fxno3 || ''}`

      if(!this.validateTelno()){
        return;
      }

      if(confirm("변경 재신청하시겠습니까?")){
        this.$refs.attachFileList.save(true, false).then(atchFileGroupId => {

          this.modifiableData.atchFileGroupId = atchFileGroupId;

          //변경신청일련번호, 변경신청구분코드, 변경신청처리상태코드, 상세변경신청처리상태코드, 변경신청유형코드
          this.modifiableData.chgAplySn = this.chgAplySn;
          this.modifiableData.chgAplySeCd = this.chgAplySeCd;
          this.modifiableData.chgAplyPrcsSttsCd = '04'; //보완 후 재신청
          this.modifiableData.dtlChgAplyPrcsSttsCd = '04';  //보완 후 재신청
          this.modifiableData.chgAplyTypeCd = '02'; //사업장

          this.$apiCall.post(API.BPLC_CHG_INFO, this.modifiableData, () => {
                alert("변경 신청되었습니다.");
                this.$emit('complete')
                this.isOn = false;
              },
              () => {
                alert('변경 신청에 실패하였습니다.');
              }
          );
        });
      }
    },
    setUnusedAtchFileList(){
      if(this.modifiableData.cnsgnOperMngYn === 'N'){
        this.unusedAtchFileList = [...initialUnusedAtchFileList];
      }else{
        this.unusedAtchFileList = [];
      }
      this.$refs.attachFileList.load(this.atchFileTaskSeCd, this.modifiableData.atchFileGroupId)
    },
    reset(){
      Object.keys(this.modifiableData).forEach(key => {
        delete this.modifiableData[key];
      });
    },
    numberValidate(name){
      if (this.bizfaciltyBplc[name]) {
        this.bizfaciltyBplc[name] = String(this.bizfaciltyBplc[name]).replace(/[^0-9]/g, ''); // 숫자만 허용
      }else{
        this.bizfaciltyBplc[name] = '';
      }
    },
    cancelModify(){
      this.isOn  = false;
    },
    callPopupAddress() {
      storeSwitch.on(this.addressPopId);
    },
    callRsrcRcyclnFcltPopup(){
      // alert('준비중 입니다.')
      storeSwitch.on(this.rsrcRcyclnFcltPopId);
    },
  }
}
</script>

<style scoped>
.subtitle-note {
  font-size: 15px;      /* 폰트 크기 설정 */
  font-weight: normal;  /* 굵기 설정 (기본 또는 normal) */
  color: #888888;       /* 원하는 색상 코드로 변경 */
  margin-left: 10px;
}

.splmnt-message {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 20px;
  margin: 5px 0px;
  border-radius: 10px;
  background-color: #f7f7f7;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #ddd;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}
</style>