<template>
  <div class="error_page">
    <img src="@/assets/images/sub/img_error01.png" alt="이미지">
    <div class="error_title">로그인 <br><span class="txt_primary">세션이 만료</span>되었습니다.</div>
    <div class="error_cont">서비스를 이용하시려면 다시 로그인 해 주시기 바랍니다.</div>
    <div class="btn_area">
      <button type="button" class="btn primary" @click="goHome">메인으로</button>
      <button type="button" class="btn primary line" @click="login">로그인 페이지로</button>
    </div>
  </div>
</template>

<script>
const ROUTE = {
  HOME: "/",
  LOGIN: "/user/login"
}

export default {
  methods: {
    goHome() {
      this.$router.push(ROUTE.HOME);
    },
    login() {
      this.$router.push(ROUTE.LOGIN);
    }
  }
}
</script>

<style scoped>

</style>