<template>
  <div v-show="isVisible" class="modal visible">
    <div class="popup_box full" tabindex="0">
      <h1 class="popup_title">제조실적 폐기물 반입 정보 엑셀업로드</h1>
      <div class="popup_cont">
        <div class="grid_area">
          <div class="board_info">
            <h2 class="title1 mt_0">폐기물 반입 정보 업로드 검증</h2>
            <div class="btn_area">
              <br-button label="양식다운" size="sm" @click="formDownload" />
              <input type="file"
                     accept=".xls,.xlsx"
                     ref="crynPrcsExcelFile"
                     @change="handleFile"
                     style="display: none;"
              />
              <br-button label="업로드" size="sm" @click="triggerFileUpload" />
            </div>
          </div>
          <sb-grid
              id="eneSplyBzentyGrid"
              :dataSource="dataSource"
              :columns="gridColumns"
              :gridattr="gridAttr"
              v-model:grid="crynPrcsGridObject"
              @loaded="gridLoaded"
              :refreshGrid="gridReload"
              :excelBtnVisible="false"
          />
        </div>
        <h5>※ 이미 저장된 반입현황 정보는 초기화 후 본 목록의 정보만 적용 됩니다.</h5>
        <div class="btn_area right">
          <br-button label="폐기물반입정보 적용" size="md" format="primary" @click="crynPrcsAplcn" />
          <br-button label="닫기" size="md" @click="hide" />
        </div>
      </div>
      <button type="button" class="modal_close" @click="hide">팝업 닫기</button>
    </div>
  </div>
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";
import SbGrid from "@/components/SbGrid.vue";

const API = {
  FORM_DOWNLOAD : "/prfmnc/sdflMnfrt/form-download",
  UPLOAD: "/prfmnc/sdflMnfrt/upload",
};

export default {
  components: {SbGrid},
  emits: ['cryn-prcs-aplcn'],
  data() {
    return {
      isVisible: false,
      searchParam: {
        bplcId: "",
        prfmncYm: "",
      },
      gridReload: false,
      crynPrcsGridObject: null,
      dataSource: [],
      gridColumns: [],
      gridAttr: {
        pageable: false,
      },
      result: "00",
      wtCrynList: [],
      rawmtrlCrynList: [],
    };
  },
  computed: {
  },
  watch: {},
  created() {
    storeSwitch.setBulb('MnfrtCrynPrcsPopup', this);
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.initializeBzentyGridColumns();
      this.initializeBzentyGridAttr();
    },
    initializeBzentyGridColumns() {
      this.gridColumns = [
        { caption: '반입업체정보',
          columns: [
            { field: 'ctpvStdgNm', caption: '시·도', width: 110 },
            { field: 'wtClctBzentyNm', caption: '업체명', width: 200, align: 'left', },
            { field: 'brno', caption: '사업자등록번호', width: 120,
              format:(value) => {
                if(value && typeof value === 'string') {
                  let cleanValue = value.replace(/\D/g, '');

                  if (cleanValue.length > 10) {
                    cleanValue = cleanValue.substring(0, 10);
                  }

                  return cleanValue.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
                }
                return value;
              },
            },
          ]
        },
        { field: 'srfMnftrMtralCd', caption: '폐기물코드',  width: 100 },
        { field: 'srfMnftrMtralNm', caption: '폐기물코드명',  width: 200, align: 'left' },
        { field: 'wtCryqty', caption: '당월 반입량', width: 100,
          dataType: 'number', format: '#,##0.00', align: 'right',
        },
        { caption: '검증결과', captionCss:'bg-green-light',
          columns: [
            { field: 'result', caption: '결과',  width: 100, captionCss:'bg-green-light',
              colCss: (data) => {
                const rsltCd = data.rsltCd;

                if (rsltCd === "02") {
                  return 'grid-danger';
                } else {
                  return 'grid-good';
                }
              }
            },
            { field: 'errRsn', caption: '오류사유',  minWidth: 150, width:100, unit:'%', captionCss:'bg-green-light',
              colCss: (data) => {
                const rsltCd = data.rsltCd;

                if (rsltCd === "02") {
                  return 'grid-danger';
                } else {
                  return '';
                }
              }
            },
          ]
        }
      ]
    },
    initializeBzentyGridAttr() {
    },
    gridLoaded() {
      this.gridReload = false;
    },
    show(param) {
      this.searchParam = param;
      this.isVisible = true;
      document.documentElement.style.overflow = 'hidden';
      this.gridReload = true;
    },
    hide() {
      this.isVisible = false;
      document.documentElement.style.overflow = '';
    },
    formDownload() {
      this.$apiCall.download(
          API.FORM_DOWNLOAD,
          this.searchParam
      );
    },
    triggerFileUpload() {
      this.$refs.crynPrcsExcelFile.click();
    },
    handleFile(event) {
      const fileInput = event.target;
      const file = event.target.files[0];
      if (!file) {
        alert("파일이 선택되지 않았습니다.");
        return;
      }

      storeSwitch.on("loading");

      const formData = new FormData();
      formData.append("bplcId", this.searchParam.bplcId ? this.searchParam.bplcId : "");
      formData.append("prfmncYm", this.searchParam.prfmncYm ? this.searchParam.prfmncYm : "");
      formData.append("file", file);

      this.$apiCall.upload(API.UPLOAD, formData, this.onSuccess, this.onFail);

      fileInput.value = "";
    },
    onSuccess(response) {
      const result = response.data.result;

      this.result = result.hasError ? "02" : "01";
      this.wtCrynList = result.wtCrynList || [];
      this.rawmtrlCrynList = result.rawmtrlCrynList || [];

      window.SBGrid3.setClientData(this.crynPrcsGridObject, result.list);
      storeSwitch.off("loading");
    },
    onFail(error) {
      alert("업로드 처리 중 에러가 발생했습니다.");
      this.wtCrynList = [];
      this.rawmtrlCrynList = [];
      console.error(error);
      storeSwitch.off("loading");
    },
    crynPrcsAplcn() {
      if (this.result === "00") {
        alert("검증된 데이터가 존재하지 않습니다.");
        return;
      } else if (this.result === "02") {
        alert("오류가 발생한 데이터가 존재합니다.\r\n오류 데이터 수정 후 다시 업로드 해주시기 바랍니다.");
        return;
      }

      if (!confirm("이미 저장된 반입현황 정보는 초기화 후 적용 됩니다.\n업로드한 반입 정보를 적용하시겠습니까?")) {
        return;
      }

      const param = {
        wtCrynList: this.wtCrynList,
        rawmtrlCrynList: this.rawmtrlCrynList,
      }

      this.$emit('cryn-prcs-aplcn', param);
    }
  }
}
</script>

<style scoped>
.sch_form_wrap {
  margin-bottom: 2rem;
}
</style>