import { reactive } from "vue";
const EXPIRED = 1 * 60 * 60 * 1000;  // 1 hours

export const storeComponent = reactive({
    cleared: new Map(),
    storage: new Map(),

    /**
     * 컴포넌트 데이터를 저장하는 함수
     * @param {Object} menu - 메뉴 객체
     * @param {Object} component - Vue 컴포넌트 인스턴스
     */
    store(menu, component) {
        this.clearExpired();  // 만료된 데이터 제거
        if (this.cleared.has(menu.urlAddr)) {  // 이전에 클리어된 경로인지 확인
            this.cleared.delete(menu.urlAddr);
            return;
        }
        let keys = Object.keys(component.$data);
        let data = new Map();
        for (let property of keys) {
            try {
                // 객체나 배열인 경우 JSON 문자열로 변환하여 저장
                if (typeof (component.$data[property]) === Object || Array.isArray(component.$data[property])) {
                    data.set(property, JSON.stringify(component.$data[property]));
                } else {
                    data.set(property, component.$data[property]);
                }
            } catch (error) {
                console.error(`Failed to stringify property ${property}: `, error);
            }
        }
        // 경로에 해당하는 데이터 저장
        let parent = this.storage.get(menu.urlAddr) || new Map();
        parent.set(JSON.stringify(keys), {data: data, timestamp: Date.now()});
        this.storage.set(menu.urlAddr, parent);
    },

    /**
     * 저장된 데이터를 복원하는 함수
     * @param {Object} menu - 메뉴 객체
     * @param {Object} component - Vue 컴포넌트 인스턴스
     * @returns {boolean} - 복원 성공 여부
     */
    restore(menu, component) {
        this.clearExpired();  // 만료된 데이터 제거
        let parent = this.storage.get(menu.urlAddr);
        if (parent == null) {
            return false;
        }
        let keys = Object.keys(component.$data);
        let value = parent.get(JSON.stringify(keys));
        // 데이터가 없거나 만료된 경우 복원 실패
        if (value == null || value.timestamp < (Date.now() - EXPIRED)) {
            parent.delete(JSON.stringify(keys));
            return false;
        }
        // 데이터를 복원
        for (let property of keys) {
            try {
                if (typeof (component.$data[property]) === Object || Array.isArray(component.$data[property])) {
                    component.$data[property] = JSON.parse(value.data.get(property));
                } else {
                    component.$data[property] = value.data.get(property);
                }
            } catch (error) {
                console.error(`Failed to parse property ${property}: `, error);
            }
        }
        return true;
    },

    /**
     * 특정 메뉴 경로에 해당하는 데이터를 삭제하는 함수
     *
     * 해당 경로로 시작하고 언더스코어('_')로 이어지는 키들(첨부파일 데이터)도 삭제합니다.
     *
     * @param {Object} menu - 메뉴 객체
     */
    clear(menu) {
        const pathToDelete = menu.path || menu.urlAddr;

        this.storage.delete(pathToDelete);
        this.cleared.set(pathToDelete, null);

        const prefixToDelete = pathToDelete + '_';
        for (let key of this.storage.keys()) {
            if (key.startsWith(prefixToDelete)) {
                this.storage.delete(key);
                this.cleared.set(key, null);
            }
        }
    },

    /**
     * 모든 데이터를 삭제하는 메소드
     */
    clearAll() {
        this.cleared.clear();
        this.storage.clear();
    },

    /**
     * sessionStorage에 데이터를 저장하는 함수
     * @param {String} name - 저장할 데이터의 이름
     * @param {any} value - 저장할 값
     */
    set(name, value) {
        let data = {type: typeof value, content: value};
        sessionStorage.setItem(window.btoa(encodeURIComponent(name)), window.btoa(encodeURIComponent(JSON.stringify(data))));
    },

    /**
     * sessionStorage에서 데이터를 가져오는 함수
     * @param {String} name - 가져올 데이터의 이름
     * @returns {any} - 저장된 값
     */
    get(name) {
        let value = sessionStorage.getItem(window.btoa(encodeURIComponent(name)));
        if (value != null) {
            let data = JSON.parse(decodeURIComponent(window.atob(value)));
            return data.content;
        }
        return null;
    },

    /**
     * sessionStorage에 저장된 특정 데이터를 삭제하는 함수
     * @param {String} name - 삭제할 데이터의 이름
     */
    clearStorageData(name) {
        sessionStorage.removeItem(window.btoa(encodeURIComponent(name)));
    },

    /**
     * 만료된 데이터를 제거하는 함수
     */
    clearExpired() {
        const now = Date.now();
        for (let [path, parent] of this.storage.entries()) {
            for (let [keys, value] of parent.entries()) {
                if (value.timestamp < (now - EXPIRED)) {
                    parent.delete(keys);
                }
            }
            if (parent.size === 0) {
                this.storage.delete(path);
            }
        }
    },
})