<template>
  <div v-show="isOn" class="modal visible">
    <div class="popup_box" style="width: 90%; max-width: 1000px; height: 95%; max-height: 95%;">
      <h1 class="popup_title">리포트</h1>
      <template v-if="isOn">
        <report-form
            :id="rdSetObj.rdFileName"
            :path="rdSetObj.rdFileName"
            :param="rdParam"
            :rd-save-api-type="rdSaveObj.rdSaveApiType" 
            :rd-save-api="rdSaveObj.rdSaveApi" 
            :rd-save-data-add="rdSaveDataAddObj" 
            @submit="submit"
            @cancel="cancel"
          />
       </template>
      <button type="button" class="modal_close" @click.prevent="hide" >팝업닫기</button>
    </div>
  </div>
</template>

<script>
/**
 * 품질등급 업무 리포트 팝업 
 *
 * 작성자 : CHOSUNGHO
 * 작성일 : 24. 11. 14. 
 * 버전 : 1.0
 */

import { storeSwitch } from "@/js/store/store-switch";
import { createRdAgent } from "@/js/store/store-rdagent";
import ReportForm from "@/components/ReportForm.vue";

const API = {
  RD_SAVE : "/qltyGrd/putQltyGrdOtptNocs", // e-Form 입력 값 저장할 Rest API 정보      
}

export default {
  components: { ReportForm }, 
  props: {
    rdParamFileName: {
      type: String,
      required: true
    },
    rdParamKey: {
      type: String,
      required: true
    },
    rdParamValue: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isOn: false,
      rdSetObj: { 
          rdFileName: "",         // 이폼/리포트 파일명 (.mrd 제외) 
          rdKey1: "",             // 이폼/리포트 조회 데이터 Key 명칭 
          rdValue1: "",           // 이폼/리포트 조회 데이터 Key에 대한 Value 
          isFormMode: false,      // 이폼 여부 ( true : 이폼, false : 리포트 ) 
          pdfFileName: "",    // 이폼 제출 시 서버에 저장되는 PDF 파일명 ( .pdf 제외한 파일명 ) 
          rdWidth: "99.5%",       // 뷰어 너비 
          rdHeighte: "90%",       // 뷰어 높이
        },
        rdSaveObj: {
          rdSaveApi: API.RD_SAVE,               // API 주소 뒤에 매개변수 값 존재할 경우 : API.RD_SAVE + "/" + 매개변수 값 
          rdSaveApiType: "put",                 // 수정 : put / 등록 : post 
        },
        rdSaveDataAddObj: {                     // 이폼 입력 항목 외 추가 저장 Key : Value 추가
          qltyGrdRsltSn: "",
        }
      };
  },
  computed: {
    // this.rdParam();

    rdParam() {
        /** 
        * 이폼/리포트 조회 Parameter 
        * 
        * createRdAgent(Key Value, 이폼여부, PDF 파일명); 
        * 예시 : createRdAgent(\`inspAplySn [18130]\`, true, 'PDF 파일명')
        */
        return createRdAgent(
          // 조회 Parameter 3개일 경우 
          // `${this.rdSetObj.rdKey1} [${this.rdSetObj.rdValue1}] ${this.rdSetObj.rdKey2} [${this.rdSetObj.rdValue2}] ${this.rdSetObj.rdKey3} [${this.rdSetObj.rdValue3}]`, 

          // 조회 Parameter 1개일 경우 
           `${this.rdSetObj.rdKey1} [${this.rdSetObj.rdValue1}]`, 
          this.rdSetObj.isFormMode,
          this.rdSetObj.pdfFileName  
        );
      }
  },
  watch: {
  },
  created() {
    storeSwitch.setBulb('reportQltyGrdPopup', this);
  },
  mounted() {

  },
  beforeMount() {

    // 전달받은 Param 
    this.rdSetObj.rdFileName = this.rdParamFileName;
    this.rdSetObj.rdKey1 = this.rdParamKey;
    this.rdSetObj.rdValue1 = this.rdParamValue;
  },
  
  methods: {
    /** 팝업 Show */
    show() {
      this.isOn = true;
    },
    /** 팝업 Hide */
    hide() {
      this.isOn = false;
    },
  }
}
</script>

<style scoped>

</style>