<template>
  <div class="modal visible" v-show="isOn">
    <div id="popup" style="width: 70%; max-width: 1000px;">
      <h1 class="popup_title">검사신청 이력</h1>
      <div class="popup_cont">
        <h2 class="title1 mt_0">검사신청 이력</h2>
        <div class="tbl_wrap">
          <table>
            <caption>검사신청이력_번호, 진행상태, 사유, 처리일자, 처리자로 구성</caption>
            <colgroup>
              <col style="width:10%">
              <col style="width:15%">
              <col style="width:50%">
              <col style="width:15%">
              <col style="width:10%">
            </colgroup>
            <thead>
            <tr>
              <th scope="col">번호</th>
              <th scope="col">진행상태</th>
              <th scope="col">사유</th>
              <th scope="col">처리일자</th>
              <th scope="col">처리자</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="qltyInspHist.length <= 0" >
              <td colspan="5">
                <div style="display: flex; justify-content: center;">
                  <div class="no-data-message">검사신청 이력 데이터가 없습니다.</div>
                </div>
              </td>
            </tr>
            <tr v-else v-for="(item, index) in qltyInspHist" :key="index">
              <td>{{ item.inspAplyHstrySqno }}</td>
              <td><span v-if="item.inspPrgrsSttsNm" class="badge" :class="{ bg1: item.inspPrgrsSttsCd === '70', bg3: this.isBg3BadgeClass(item.inspPrgrsSttsCd) }">{{ item.inspPrgrsSttsNm }}</span></td>
              <td class="txt_left">{{ item.inspRsn }}</td>
              <td>{{ item.prcsYmd }}</td>
              <td>{{ item.userNm }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="btn_area center">
          <button type="button" class="btn md" @click="hide">닫기</button>
        </div>
      </div>
      <button type="button" class="popup_close" @click="hide">팝업 닫기</button>
    </div>
  </div>
</template>

<script>
/**
 * 품질검사 이력 조회 팝업 화면
 *
 * 작성자 : jhkim
 * 작성일 : 11/03/2024
 * 버전 : 1.0
 */
import { storeSwitch } from "@/js/store/store-switch";
import inspPagination from "@/views/sfli/components/inspPagination.vue";

const API = {
  QLTY_INSP_HIST : "/qlty-insp-stat/hist",
}

export default {
  components: {inspPagination},
  props: {
    inspAplySn: {type: String, required: true},
  },
  emits: [],
  expose: [],
  data() {
    return {
      isOn: false,
      qltyInspHist: [],
      qltyInspDataCount: 0,
    }
  },
  computed: {},
  watch: {
    isOn(newVal) {
      if(newVal) {
        this.search();
      } else {
        //목록 리셋
        this.qltyInspHist = [];
      }
    }
  },
  created() {
    storeSwitch.setBulb("QltyInspApplyHistPopup", this);
  },
  beforeMount() {},
  mounted() {},
  beforeUnmount() {},
  methods: {
    show() {
      this.isOn = true;
    },
    hide() {
      this.isOn = false;
    },
    search() {
      this.$apiCall.get(`${API.QLTY_INSP_HIST}/${this.inspAplySn}`, {}, data => {
        if (data.result) {
          this.qltyInspHist = data.result;
        } else {
          this.qltyInspHist = [];
        }
      });
    },
    isBg3BadgeClass(inspPrgrsSttsCd) {
      let result = false;
      switch (inspPrgrsSttsCd) {
        case '00':  // 작성중
        case '10':  // 신청
        case '30':  // 서류보완요청
        case '50':  // 반려
        case '80':  // 사전취소
        case '90':  // 검사취하
          result = true;
          break;
      }
      return result;
    },
  },
}
</script>

<style scoped>
.no-data-message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  margin: 20px 0px;
  border-radius: 10px;
  background-color: #f7f7f7;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 350px;
}

.no-data-message::before { content:''; width:2rem; height:2rem; background:url(@/assets/images/common/icon_search.png) no-repeat; background-size: 2rem; margin-right: 8px;}
</style>