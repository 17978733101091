import sysOtln from "./menus-sys-otln";
import wstrscEneIntro from "./menus-wstrsc-ene-intro"
import qltyGrd from "./menus-qlty-grd";
import sfli from "./menus-sfli";
import prfmncRpt from "./menus-prfmnc-rpt";
import custSprt from "./menus-cust-sprt";
import myOffice from "./menus-my-office";

import { storeSession } from "@/js/store/store-session";

const GROUPS = [];

const routeMenus = new Map();
const privacyMenuPaths = new Set();

function setupMenus(){
    GROUPS.push(sysOtln.getMenus());
    GROUPS.push(wstrscEneIntro.getMenus());
    GROUPS.push(sfli.getMenus());
    GROUPS.push(prfmncRpt.getMenus());
    GROUPS.push(qltyGrd.getMenus());
    GROUPS.push(custSprt.getMenus());
    GROUPS.push(myOffice.getMenus());
}

function copyGroupList() {
    let groups = [];
    for (let i = 0; i < GROUPS.length; i++) {
        let group = {...GROUPS[i]};
        group.menuList = [];
        for (let j = 0; j < GROUPS[i].menuList.length; j++) {
            let menuGrp = {...GROUPS[i].menuList[j]};
            menuGrp.items = [];
            for (let k = 0; k < GROUPS[i].menuList[j].items.length; k++) {
                let item = {...GROUPS[i].menuList[j].items[k]};
                menuGrp.items.push(item);
            }
            group.menuList.push(menuGrp);
        }
        groups.push(group);
    }
    return groups;
}

setupMenus();

function pathsMatch(path, urlAddr) {
    return (path === "" && urlAddr === undefined) || path === urlAddr;
}

export default {
    getAuthMenus: function(){
        let groupList = copyGroupList();
        const authMenus = new Map();
        routeMenus.clear();

        // 세션 메뉴 목록
        let list = storeSession.getMenuList();

        list.forEach(menu => {
            authMenus.set(menu.menuSn, menu);

            if (menu.urlAddr) {
                routeMenus.set(menu.urlAddr, menu);

                if (menu.prvcPopupUseYn === 'Y') {
                    privacyMenuPaths.add(menu.urlAddr);
                }
            }

            if (menu.menuLevel === 1) {
                const group = groupList.find(g => g.groupName === menu.menuNm);
                if (group) {
                    group.menuSn = menu.menuSn;
                    group.topMenuUseYn = menu.topMenuUseYn;
                    group.menuUrl = menu.urlAddr;
                }
            } else if (menu.menuLevel === 2) {
                groupList.forEach(group => {
                    const menuGroup = group.menuList.find(mg => mg.menuGrpNm === menu.menuNm && pathsMatch(mg.path, menu.urlAddr));
                    if (menuGroup) {
                        menuGroup.menuSn = menu.menuSn;
                        menuGroup.groupMenuSn = menu.upMenuSn;
                        menuGroup.groupName = group.groupName;
                        menuGroup.topMenuUseYn = menu.topMenuUseYn;
                    }
                });
            } else {
                groupList.forEach(group => {
                    group.menuList.forEach(menuGroup => {
                        const item = menuGroup.items.find(i => i.menuNm === menu.menuNm && pathsMatch(i.path, menu.urlAddr));
                        if (item) {
                            item.menuSn = menu.menuSn;
                            item.groupName = group.groupName;
                            item.menuGrpSn = menu.upMenuSn;
                            item.menuGrpNm = menuGroup.menuGrpNm;
                            const groupMenu = list.find(upper => upper.menuSn === menu.upMenuSn);
                            if (groupMenu) {
                                item.groupMenuSn = groupMenu.upMenuSn;
                            }
                            item.hidden = menu.hidden;
                        }
                    });
                });
            }
        });

        // 권한이 없는 메뉴 제거
        groupList = groupList.filter(group => authMenus.has(group.menuSn))
            .map(group => ({
                ...group,
                menuList: group.menuList.filter(menuGrp => authMenus.has(menuGrp.menuSn))
                    .map(menuGrp => ({
                        ...menuGrp,
                        items: menuGrp.items.filter(item => authMenus.has(item.menuSn))
                    }))
            }));

        return groupList;
    },
    getRouteMenu(path){
        if (routeMenus.size === 0) {
            this.getAuthMenus();
        }

        return routeMenus.get(path) || { groupName: "unknown", menuGrpNm: "unknown", menuNm: path, urlAddr: path };
    },
    getMenus(){
        return GROUPS.flatMap(group =>
            group.menuList.flatMap(menuGrp => {
                const menus = [];
                if (menuGrp.path) {
                    menus.push({ menuNm: menuGrp.menuGrpNm, path: menuGrp.path, isPublic: menuGrp.isPublic });
                }
                menuGrp.items.forEach(item => {
                    if (item.path) {
                        menus.push({ menuNm: item.menuNm, path: item.path, isPublic: item.isPublic });
                    }
                });
                return menus;
            })
        );
    },
    getPrivacyMenuPaths() {
        if (routeMenus.size === 0) {
            this.getAuthMenus();
        }

        return privacyMenuPaths;
    }
}