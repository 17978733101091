<template>
  <div class="sch_form_wrap">
      <div class="sch_form row2">
          <span>
              <label for="">승계사유</label>
              <br-form-select label="승계사유" groupCd="SIS009" v-model:value="searchParam.scsnRsnCd" allSelectable @change="search" />
          </span>
          <span>
              <label for="">승계일자</label>
              <br-date-from-to label="승계일자" v-model:fromDate="searchParam.scsnYmdStartDate" v-model:toDate="searchParam.scsnYmdEndDate"/>
          </span>
          <span>
              <label for="">승인상태</label>
              <br-form-select label="승인상태" groupCd="MBR002" v-model:value="searchParam.scsnDclrSttsCd" allSelectable @change="search" />
          </span>
          <span>
              <label for="">피승계인</label>
              <br-form-input label="피승계인" v-model="searchParam.opsnBzentyNm" @enterKeyup="search"/>
          </span>
          <span style="padding-right: 0px;">
              <label for="">시도/시군구</label>
              <br-form-select label="시·도" v-model:value="searchParam.opsnCtpvStdgCd" :options="ctpvCdList" allSelectable @change="search" style="width: 13rem;" />
              <br-form-select label="시·군·구" v-model:value="searchParam.opsnSggStdgCd" :options="rghtScsnDclrBzentyFilteredSggCdList" allSelectable @change="search" style="width: 12.9rem;" />
            </span>
      </div>
      <div class="btn_area">
          <br-anchor-button label="초기화" role="button" class="btn sm txtN ico_refresh"  @click="reset"/>
          <br-anchor-button label="검색" role="button" class="btn sm sch" @click="search"/>
      </div>
  </div> 

  <div class="board_info">
      <p class="page">
          <span class="total">전체 <strong>{{ this.count }}</strong></span>
          <span>페이지 <strong>{{ this.searchParam.page }}/{{ this.maxPage }}</strong></span>
      </p>
      <div class="btn_area">
            <a href="javascript:void(0);" role="button" class="btn sm" @click.prevent="navigateRghtScsnDclrCreatePage()">권리의무승계 신고</a>
            <a href="javascript:void(0);" role="button" class="btn sm ico_excel" @click.prevent="excelDownload()">엑셀다운로드</a>
      </div>
  </div>
  <div class="board_list scroll">
      <table>
          <caption>시설검사 현황 조회-검사신청번호, 진행상태, 검사구분, 사업장명, 사업장종류, 신청일자, 접수일자, 검사(예정)일자, 검사연기신청, 연기반려사유, 처리기한연장, 검사결과로 구성</caption>
          <colgroup>
              <col style="width:22%">
              <col style="width:15%">
              <col style="width:15%">
              <col style="width:15%">
              <col style="width:15%">
              <col style="width:15%">
          </colgroup>
          <thead>
              <tr>
                  <th scope="col">피승계인</th>
                  <th scope="col">시도</th>
                  <th scope="col">시군구</th>
                  <th scope="col">승계일자</th>
                  <th scope="col">승계사유</th>
                  <th scope="col">승인상태</th>
              </tr>
          </thead>
          <tbody>
            <tr v-if="this.count <= 0">
                <td colspan="12">
                    <div style="display: flex; justify-content: center;">
                    <div class="no-data-message">조회조건에 해당하는 데이터가 없습니다.</div>
                    </div>
                </td>
            </tr>
            <tr v-else v-for="(item, index) in listObj.list" :key="index" @dblclick.prevent="navigateRghtScsnDclrDetailPage(item.scsnDclrSn, item.scsnDclrTypeCd)">
                <td aria-label="피승계인">{{item.opsnBzentyNm}}</td>
                <td aria-label="시도">{{item.opsnCtpvStdgNm}}</td>
                <td aria-label="시군구">{{item.opsnSggStdgNm}}</td>
                <td aria-label="승계일자">{{item.scsnYmd}}</td>
                <td aria-label="승계사유">{{item.scsnRsnNm}}</td>
                <td aria-label="승인상태">{{item.scsnDclrSttsNm}}</td>
            </tr>
        </tbody>
      </table>
  </div>
  <insp-pagination v-if="this.count > 0" 
    :total="count" 
    :size="searchParam.size"            
    v-model:page="searchParam.page" 
    v-model:maxPage="maxPage" 
    @clickedPage="search"
  >
  </insp-pagination>
  
</template>
  
  <script> 
  /**
 * 권리 의무승계 신고 내역을 조회하는 화면 
 *
 * 작성자 : CHOSUNGHO 
 * 작성일 : 24. 11. 18 
 * 버전 : 1.0
 */ 

  import {storeSession} from "@/js/store/store-session";
  import inspPagination from "@/views/sfli/components/inspPagination.vue";

  const API = {
      RGHT_SCSN_DCLR : "/rghtScsnDclr",
      RGHT_SCSN_DCLR_EXCEL : "/rghtScsnDclr/excel-download", 
  }
  const PAGE_SIZE = 10;
  
  export default {
    components: {inspPagination},
    data() {
      return {
        searchParam : {   // 조회조건
            scsnRsnCd : "", 
            scsnYmdStartDate : "", 
            scsnYmdEndDate : "", 
            scsnDclrSttsCd : "", 
            opsnBzentyNm : "", 
            opsnCtpvStdgCd  : "", 
            opsnSggStdgCd  : "", 
            page : 1,
        },
        listObj : {   // 목록
            opsnBzentyNm : "",
            opsnCtpvStdgNm : "",
            opsnSggStdgNm : "",
            scsnYmd : "",
            scsnRsnNm : "",
            scsnDclrSttsNm : "",
            scsnDclrTypeCd : "", 
        },
        userinfo: storeSession.getMemberInfo(),
        count : 0,
        maxPage: null,
        ctpvCdList: [],
        rghtScsnDclrBzentySggCdList: [],
      };
    },
    computed: {
      rghtScsnDclrBzentyFilteredSggCdList() {
        return this.rghtScsnDclrBzentySggCdList.filter(item => item.upSggStdgCd === this.searchParam.opsnCtpvStdgCd);
      },
    },
    watch: {
    },
    created() {
    },
    mounted() {
      window.scrollTo({ top: 0, behavior: 'smooth' }); 
      this.initialize();
      this.search();
    },
    methods: {
      initialize() {
        this.loadCtpvCd();
        this.loadSggCd();
      },
      loadCtpvCd() {
        const ctpvList = this.$store.getters.getSggList({ type: 'ctpv' });
        this.ctpvCdList = ctpvList.map(item => ({
          text: item.sggNm,
          value: item.sggStdgCd
        }));
      },
      loadSggCd() {
        const sggList = this.$store.getters.getSggList({ type: 'sgg' });
        this.rghtScsnDclrBzentySggCdList = sggList.map(item => ({
          text: item.sggNm,
          value: item.sggStdgCd,
          upSggStdgCd: item.upSggStdgCd
        }));
      },
      navigateTo(path) {
        if (path) {
          this.$router.push(path).catch(() => {
              console.error("Navigation Error");
          });
        }
        window.scrollTo({ top: 0 });
      },
      excelDownload() {
          const date = new Date();

          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
          const day = String(date.getDate()).padStart(2, '0');

          this.$apiCall.download(API.RGHT_SCSN_DCLR_EXCEL, {
              "fileName" : "권리의무승계내역_" + `${year}${month}${day}` + ".xlsx", "userId" : this.userinfo.userId
          });
      },
      navigateRghtScsnDclrCreatePage() {
        sessionStorage.removeItem('rghtScsnDclrByScsnDclrSn');

        this.$router.push(
            {
                path : "/my-office/rght-scsn-dclr/RghtScsnDclrDetail"
            }
        ).catch(
            () => { 
            console.error("Navigation Error"); 
            }
        );
      },
      navigateRghtScsnDclrDetailPage(pScsnDclrSn, pScsnDclrTypeCd) {

        sessionStorage.setItem('rghtScsnDclrByScsnDclrSn', pScsnDclrSn);
        sessionStorage.setItem('rghtScsnDclrByScsnDclrTypeCd', pScsnDclrTypeCd);

        this.$router.push(
            {
                path : "/my-office/rght-scsn-dclr/RghtScsnDclrDetail"
            }
        ).catch(
            () => { 
            console.error("Navigation Error"); 
            }
        );
      },
      search() {
          this.searchParam.size = PAGE_SIZE;
          this.$apiCall.get(API.RGHT_SCSN_DCLR, this.searchParam, this.success, this.error);
      },
      success(data) {
          if (!data.result) {
              this.error();
              return;
          }
          this.listObj = data.result;
          this.count = data.result.total;
          this.maxPage = Math.ceil(this.count / this.searchParam.size);
      },
      error() {
          alert("데이터를 가져오는 중 오류가 발생했습니다.");
      },
      reset() {
          this.searchParam.scsnYmdStartDate = "";
          this.searchParam.scsnYmdEndDate = "";
          this.searchParam.scsnDclrSttsCd = ""; 
          this.searchParam.opsnBzentyNm = ""; 
          this.searchParam.opsnCtpvStdgCd = ""; 
          this.searchParam.opsnSggStdgCd = ""; 
      },
    },   
  }
  </script>
  
  <style scoped>

  .board_list.scroll > table {
    width: 150rem;
  }

  .underline {
    text-decoration: underline;
    cursor: pointer; /* 클릭할 수 있는 느낌을 주기 위해 커서 변경 */
  }   

  </style>