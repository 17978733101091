<template>
  <div class="sch_form_wrap">
    <div class="sch_form row2">
      <span>
        <label>구분</label>
        <br-form-select label="선택"
            v-model:value="selected" :options="options" @change="(data) => this.type=data.value"/>
        <br-form-input label="검색어" v-model="keyword" @enterKeyup="searchBtn"/>
      </span>
      <span>
      </span>
    </div>
    <div class="btn_area">
      <br-anchor-button label="검색" role="button" size="sm" format="sch" @click="searchBtn" />
      <br-anchor-button label="새로고침" role="button" size="sm" format="txtN" icon="ico_refresh" @click="reset" />
    </div>
  </div>
  <div class="board_info">
    <p class="page">
      <span class="total">전체 <strong>{{ this.count }}</strong></span>
      <span>페이지 <strong>{{ this.searchParam.page }}/{{ this.maxPage }}</strong></span>
    </p>
  </div>
  <div class="board_list">
    <ul>
      <li v-for="(item, index) in listObj" :key="index">
        <a href="" onclick="return false;" @click="toDetail(item)">
          <span class="num num">{{item.onlnDscsnSn}}</span>
          <span class="title"><strong class="strong1">{{item.dscsnTtl}}</strong></span>
          <span class="w120">{{item.regDt}}</span>
          <span class="w50" v-if="item.rlsYn=='Y'">공개</span>
          <span class="w50" v-else>비공개</span>
        </a>
      </li>
    </ul>
  </div>
  <div class="btn_area right">
    <br-button label="등록" format="primary" @click="navigateCreate" :auth="['C']"/>
  </div>
  <insp-pagination :total="count" :size="searchParam.size" v-model:page="searchParam.page" v-model:maxPage="maxPage" @clickedPage="search"> </insp-pagination>
</template>

<script>
import inspPagination from "@/views/sfli/components/inspPagination.vue";
import {storeSession} from "@/js/store/store-session";

const API = {
  ONLINEDISCUSSION: "/onlinediscussion",
  ONLINEDISCUSSION_ACS_CHK: "/onlinediscussion/detailAcsChk",
};

const PAGE_SIZE = 10;

export default {
  components: {inspPagination},
  data() {
    return {
      options: [
          {text: "제목", value: "1"},
          {text: "내용", value: "2"},
          {text: "작성자", value: "3"},
          {text: "제목 + 내용", value: "4"}
      ],
      listObj: [],
      selected: "4",
      keyword: "",
      type: "4",
      searchParam: {
        dscsnTtl: "",
        onlnDscsnQstnCn: "",
        rgtrNm: "",
        page: 1,
      },
      userinfo: storeSession.getMemberInfo(),
      count: 0,
      maxPage: 1,
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  beforeMount() {
  },
  mounted() {
    window.scrollTo(0, 0);
    this.initialize();
  },
  methods: {
    initialize() {
      this.search();
    },
    searchBtn(){
      this.searchParam.page = 1;
      this.search();
    },
    search(){
      this.searchParam.size = PAGE_SIZE;
      this.chage();
      this.$apiCall.get(API.ONLINEDISCUSSION, this.searchParam, this.success, this.error);
    },
    success(data){
      if(!data.result){
        return;
      }
      this.listObj = data.result.list;
      this.count = data.result.total;
    },
    error() {
      alert("데이터를 가져오는 중 오류가 발생했습니다.");
    },
    reset() {
      this.selected=""
      this.keyword="";
      this.search();
    },
    chage(){
      this.searchParam.dscsnTtl = ""
      this.searchParam.onlnDscsnQstnCn = ""
      this.searchParam.rgtrNm = ""
      if(this.type==""){
        this.keyword = ""
      }else if(this.type==1){
        this.searchParam.dscsnTtl = this.keyword
      }else if(this.type==2){
        this.searchParam.onlnDscsnQstnCn = this.keyword
      }else if(this.type==3){
        this.searchParam.rgtrNm = this.keyword
      }else if(this.type==4){
        this.searchParam.dscsnTtl = this.keyword
        this.searchParam.onlnDscsnQstnCn = this.keyword
      }
    },
    toDetail(item){
      this.$apiCall.get(
          API.ONLINEDISCUSSION_ACS_CHK,
          {
            onlnDscsnSn : item.onlnDscsnSn
          },
          (data) => {
            if(data.result > 0){
              this.$router.push({path : `/cust-sprt/OnlineDiscussionDetail`, query : {onlnDscsnSn : item.onlnDscsnSn}})
                  .catch(() => {
                    console.error("Navigation Error");
                  });
            }
          },
          (error) => {
            alert(error.message);
          }
      );
    },
    navigateCreate(){
      if(this.userinfo.userNm == null){
        alert("로그인 후 등록 가능합니다")
        return
      }
      this.$router.push({path : `/cust-sprt/OnlineDiscussionCreate`})
        .catch(() => {
          console.error("Navigation Error")
        });
    }
  }
}
</script>

<style scoped>
.strong1 * {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
