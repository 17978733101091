<template>
  <br-loading />
  <br-pending-alert />
  <chat-bot />
  <router-view></router-view>
</template>

<script>
import BrLoading from "@/components/BrLoading.vue";
import BrPendingAlert from "@/components/BrPendingAlert.vue";
import ChatBot from "@/components/ChatBot.vue";

export default {
  components: {
    BrLoading,
    BrPendingAlert,
    ChatBot
  }
}
</script>