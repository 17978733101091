<template>
  <div v-show="isOn" class="modal visible">
    <div class="popup_box" style="width: 70%; max-width: inherit">
      <h1 class="popup_title">해외수입시설 추가</h1>
      <div>
        <button type="button" class="modal_close" @click.prevent="hide" >팝업닫기</button>
      </div>
      <div class="popup_cont">

        <div class="board_info">
          <h2 class="title1">해외수입시설 조회</h2>
        </div>

        <div class="sch_form_wrap">
          <div class="sch_form row2">

            <span>
              <label for="">업체 명</label>
              <br-form-input class="form_control" label="업체명" v-model="searchParam.bzentyNm" @enterKeyup="search" />
            </span>
            <span>
              <label for="">국가</label>
              <br-form-select class="form_control" label="국가" groupCd="COM415" allSelectable v-model:value="searchParam.mnftrNtnCd" @enterKeyup="search" />
            </span>
            <span>
              <label for="">사업자등록번호</label>
              <br-form-input class="form_control" label="사업자등록번호" v-model="searchParam.ovrsBzmnIdntfNo" @enterKeyup="search" />
            </span>
          </div>
          <div class="btn_area">
            <br-anchor-button role="link" class="btn sm txtN ico_refresh" label="새로고침"  icon="reset" @click="reset" />
            <br-anchor-button role="link" class="btn sm sch" label="조회" icon="icon_search" @click="search" />
          </div>
        </div>


        <div class="board_info">
          <div class="board_info">
            <h3 class="title2">수입 거래처 목록
              <span class="txt_hint"></span>
            </h3>
          </div>
          <div class="btn_area right">
            <br-button label="추가" class="btn sm primary line ico_plus" @click="addOvrsIncmFclt" />
            <br-button v-show="'01-01' !== sysAuthrtCd" label="삭제" class="btn sm point ico_minus" @click="deleteOvrsIncmFclt" />
            <br-button label="저장" class="btn sm primary" @click="saveOvrsIncmFclt" />
          </div>
        </div>
        <sb-grid
            id="ovrsIncmFcltPopupGrid"
            :dataSource="ovrsIncmFcltPopupDataSource"
            :columns="ovrsIncmFcltPopupGridColumns"
            :gridattr="ovrsIncmFcltPopupGridAttr"
            :param="searchParam"
            v-model:grid="ovrsIncmFcltPopupGridObject"
            :refreshGrid="ovrsIncmFcltPopupGridReload"
            @loaded="ovrsIncmFcltPopupGridLoaded"
            @row-clicked="onRowChangedIncmFclt"
        />
        <div class="btn_area center">
          <br-button label="선택" class="btn sm tertiary" @click="onClickedSelectButton" />
          <br-button label="닫기" class="btn sm tertiary" @click="hide" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 해외수입시설
 *
 * 작성자 : lhs
 * 작성일 : 24. 10. 25.
 * 버전 : 1.0
 */
import { storeSwitch } from "@/js/store/store-switch";
import SbGrid from "@/components/SbGrid.vue";
import {buildUri} from "@/js/utils/uri-builder";
import {storeSession} from "@/js/store/store-session";

const API = {
  COM_DTL_CD: "/code/detail",
  OVRS_INCM_FCLT: "/bizfacility/ovrs-incm-fclt/{bplcId}",
  OVRS_INCM_FCLT_SAVE: "/bizfacility/ovrs-incm-fclt",
  OVRS_INCM_FCLT_DELETE: "/bizfacility/ovrs-incm-fclt",
};

const CODE = {
  MNFTR_NTN_CD: "COM415", // 국가코드
}

export default {
  components: {SbGrid},
  props: {
    bplcId: {type: String, required: true},
  },
  emits: [ 'select-button-clicked' ],
  data() {
    return {
      sysAuthrtCd: storeSession.getMemberInfo().sysAuthrtCd,
      isOn: false,
      searchParam: {
        bzentyNm: '',
        bplcNm: '',
        fcltNm: '',
        incmFcltSn: '',
        fcltAddr: '',
        mnftrNtnCd: '',
        ovrsBzmnIdntfNo: '',
        bzentyAddr: '',
        useYn: '',
        regDt: '',
        rgtrId: '',
        mdfcnDt: '',
        mdfrId: '',
      },
      bzentyCtpvCdList: [],
      bzentySggCdList: [],
      bzentySttsCdList: [],
      pubBzentyYnCdList: [],
      bplcCtpvCdList: [],
      bplcSggCdList: [],
      bplcSttsCdList: [],
      bplcKndCdList: [],
      ntnCdList: [],
      ovrsIncmFcltPopupDataSource: {
        ajaxConfig: {
          select: { url: this.incmFcltBuildUriForGrid('OVRS_INCM_FCLT') },
          paging: true,
        },
        schema: {
          id: 'incmFcltSn'
        }
      },
      ovrsIncmFcltPopupGridColumns: [],
      ovrsIncmFcltPopupGridAttr: {
        checkable: true,
        showStatus: true,
        showRowNo: false,
        pageable: true,
        editable: {
          eventType: 'mouseup',
          keyEdit: true,
        },
        height: 350,
      },
      ovrsIncmFcltPopupGridObject: null,
      ovrsIncmFcltPopupGridReload: false,
      rowData: [],
    };
  },
  computed: {},
  watch: {
    isOn(newVal) {
      if(!newVal) {
        this.reset();
      } else {
        this.ovrsIncmFcltPopupDataSource.ajaxConfig.select.url = buildUri(API.OVRS_INCM_FCLT, {bplcId : this.bplcId});
        window.SBGrid3.reload(this.ovrsIncmFcltPopupGridObject);
      }
    }
  },
  created() {
    storeSwitch.setBulb('ovrsIncmFcltPopup', this);
  },
  beforeMount() {

  },
  mounted() {
    this.initialize();
  },
  beforeUnmount() {},
  methods: {
    /** 팝업 Show */
    show() {
      this.isOn = true;
    },
    /** 팝업 Hide */
    hide() {
      this.isOn = false;
    },
    /** 화면 초기화 */
    async initialize() {
      // 공통코드 초기화
      this.mnftrNtnCdList = this.$store.getters.getCodeList({ groupCd: CODE.MNFTR_NTN_CD });         // 제조국가코드

      // 그리드 설정
      this.initializeOvrsIncmFcltPopupGridColumns();

      this.ovrsIncmFcltPopupGridReload = true;

    },
    /** 데이터 조회 */
    search() {
      window.SBGrid3.reload(this.ovrsIncmFcltPopupGridObject);
    },
    /** 조회조건 초기화 */
    reset() {
      for (const key of Object.keys(this.searchParam)) {
        this.searchParam[key] = '';
      }
      this.rowData = [];
    },
    /** 그리드 컬럼 초기화 */
    initializeOvrsIncmFcltPopupGridColumns() {
      this.ovrsIncmFcltPopupGridColumns = [
        { field: 'incmFcltSn', caption: '수입시설일련번호', width: 0, type: 'number', editable: false, visible: false, align: 'center'},
        { field: 'bzentyNm', caption: '업체명', width: 200, editable: true, visible: true, align: 'left' },
        { field: 'fcltNm', caption: '시설명', width: 200, editable: true, visible: true, align: 'left' },
        { field: 'fcltAddr', caption: '시설주소', width: 250, editable: true, visible: true, align: 'left' },
        { field: 'mnftrNtnCd', caption: '제조국가', width: 100, type: 'combo', items: this.mnftrNtnCdList, comboLabel: 'dtlCdNm', comboValue: 'dtlCd', editable: true, visible: true, align: 'center' },
        { field: 'ovrsBzmnIdntfNo', caption: '해외사업자식별번호', width: 120, editable: true, visible: true, align: 'center' },
        { field: 'bzentyAddr', caption: '업체주소', width: 250, editable: true, visible: true, align: 'left' },
        { field: 'useYn', caption: '사용여부', width: 80, type: 'check', checkValue: 'Y', checkLabel: '사용', unCheckValue: 'N', unCheckLabel: '미사용', editable: true, visible: true, align: 'center' },
        { field: 'regDt', caption: '등록일시', width: 150, type: 'date', editable: false, visible: true, align: 'center' },
        { field: 'rgtrId', caption: '등록자아이디', width: 100, editable: false, visible: true, align: 'center' },
        { field: 'mdfcnDt', caption: '수정일시', width: 150, type: 'date', editable: false, visible: true, align: 'center' },
        { field: 'mdfrId', caption: '수정자아이디', width: 100, editable: false, visible: true, align: 'center' },
        // { field: 'rowEditableYn', caption: '수정가능여부', width: 0, editable: false, visible: false, align: 'center'},
      ]
    },
    /** 그리드 데이터로드 완료 이벤트 */
    ovrsIncmFcltPopupGridLoaded() {
      this.ovrsIncmFcltPopupGridReload = false;
    },
    incmFcltBuildUriForGrid(type){
      let incmFcltInfoUrl = '';
      if(type){
        incmFcltInfoUrl = buildUri(API.OVRS_INCM_FCLT, {bplcId : this.bplcId});
      }
      return incmFcltInfoUrl;
    },
    /** 업체 정보 선택 */
    onRowChangedIncmFclt(data) {
      this.rowData = data;
    },
    /** 선택 버튼 클릭 */
    onClickedSelectButton() {
      if(this.rowData.incmFcltSn) {
        this.$emit('select-button-clicked', this.rowData);
        this.isOn = false;
      } else {
        alert("선택된 시설이 없습니다.");
      }
    },
    addOvrsIncmFclt(){
      const key = window.SBGrid3.getFocusedKey(this.ovrsIncmFcltPopupGridObject);
      if (key) {
        window.SBGrid3.appendRow(this.ovrsIncmFcltPopupGridObject, key, {useYn: 'Y'});
      } else {
        window.SBGrid3.insertRow(this.ovrsIncmFcltPopupGridObject, null, {useYn: 'Y'});
      }
    },
    deleteOvrsIncmFclt() {
      const checkedOvrsIncmFcltItem = window.SBGrid3.getCheckedRows(this.ovrsIncmFcltPopupGridObject);

      if (checkedOvrsIncmFcltItem.length === 0) {
        alert("선택된 업체가 없습니다.");
        return;
      }

      const confirmMessage = `선택된 ${checkedOvrsIncmFcltItem.length}개의 업체를 정말 삭제하시겠습니까? \r\n삭제 시 관련된 거래처관리 정보가 모두 삭제됩니다`;

      if(confirm(confirmMessage)){
        const deleted = checkedOvrsIncmFcltItem.map(item => item.data.incmFcltSn);
        const ovrsIncmFcltDeleteUrl = buildUri(API.OVRS_INCM_FCLT_DELETE);

        this.$apiCall.delete(
            ovrsIncmFcltDeleteUrl,
            {list : deleted},
            () => {
              alert("삭제되었습니다.")
              window.SBGrid3.clearSaveData(this.ovrsIncmFcltPopupGridObject);
              window.SBGrid3.reload(this.ovrsIncmFcltPopupGridObject);
            },
            () => {
              alert("삭제에 실패하였습니다.")
            }
        );
      }
    },
    saveOvrsIncmFclt() {
      const { inserted, updated } = window.SBGrid3.getSaveData(this.ovrsIncmFcltPopupGridObject, true, true, false);

      const list = [...inserted, ...updated];

      if (list.length === 0) {
        alert("추가되거나 수정된 업체가 없습니다.");
        return;
      }

      if(!this.validateOvrsIncmFclt(list)) return;

      const ovrsIncmFcltDeleteUrl = buildUri(API.OVRS_INCM_FCLT_SAVE);

      if(confirm("변경 사항을 저장하시겠습니까?")){
        this.$apiCall.post(
            ovrsIncmFcltDeleteUrl,
            { inserted, updated },
            () => {
              alert("저장되었습니다.")
              window.SBGrid3.clearSaveData(this.ovrsIncmFcltPopupGridObject);
              window.SBGrid3.reload(this.ovrsIncmFcltPopupGridObject);
            },
            (error) => {
              alert(error)
            }
        );
      }
    },
    validateOvrsIncmFclt(list) {
      const uniqueChecker = new Set();

      for (const item of list) {
        if(!item.bzentyNm) {
          alert("업체명이 비어 있는 시설이 있습니다.\r\n확인 후 다시 저장해 주세요.",
              this.moveFocus, {item, field: 'bzentyNm'});
          return false;
        }
        if(!item.fcltNm) {
          alert("시설명이 비어 있는 시설이 있습니다.\r\n확인 후 다시 저장해 주세요.",
              this.moveFocus, {item, field: 'fcltNm'});
          return false;
        }
        if (!item.fcltAddr) {
          alert("시설주소가 비어 있는 시설이 있습니다.\r\n확인 후 다시 저장해 주세요.",
              this.moveFocus, {item, field: 'fcltAddr'});
          return false;
        }
        if(!item.ovrsBzmnIdntfNo) {
          alert("해외사업자식별번호가 비어 있는 시설이 있습니다.\r\n확인 후 다시 저장해 주세요.",
              this.moveFocus, {item, field: 'ovrsBzmnIdntfNo'});
          return false;
        }
        if(!item.bzentyAddr) {
          alert("업체주소가 비어 있는 시설이 있습니다.\r\n확인 후 다시 저장해 주세요.",
              this.moveFocus, {item, field: 'bzentyAddr'});
          return false;
        }
        if(!item.mnftrNtnCd) {
          alert("제조국가가 비어 있는 시설이 있습니다.\r\n확인 후 다시 저장해 주세요.",
              this.moveFocus, {item, field: 'mnftrNtnCd'});
          return false;
        }

        const uniqueKey = `${item.bzentyNm}-${item.fcltAddr}-${item.ovrsBzmnIdntfNo}-${item.bzentyAddr}-${item.mnftrNtnCd}`;
        if (uniqueChecker.has(uniqueKey)) {
          alert("동일한 데이터가 중복으로 입력된 업체가 있습니다.\r\n확인 후 다시 저장해 주세요.");
          return false;
        }
        uniqueChecker.add(uniqueKey);

      }
      return true;
    },
    moveFocus(param) {
      const item = param.item;
      const fieldName = param.field;

      const key = item._key_;
      const column = window.SBGrid3.getColumnByField(this.ovrsIncmFcltPopupGridObject, fieldName);

      window.SBGrid3.columnEditable(this.ovrsIncmFcltPopupGridObject, key, column, true);
    }
  }
}
</script>

<style scoped>

</style>