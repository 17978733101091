<template>

  <h2 class="title1 mt_0">실적보고 수정</h2>
  <div class="board_write">
    <table>
      <caption>실적연도, 실적상태, 사업장명, 사업장종류</caption>
      <colgroup>
        <col style="width:18%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:32%">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">실적연월</th>
        <td>{{ formattedPrfmncYm }}</td>
        <th scope="row">실적상태</th>
        <td>{{ fireRtrvlDtlObj.prfmncMst.prfmncSttsNm || "작성중" }}</td>
      </tr>
      <tr>
        <th scope="row">사업장명</th>
        <td>{{ fireRtrvlDtlObj.prfmncMst.bplcNm }}</td>
        <th scope="row">사업장종류</th>
        <td>{{ fireRtrvlDtlObj.prfmncMst.bplcKndNm }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div v-show="!showDetail" class="txt_box mt_10">
    <ul class="dot_list">
      <li>실적 등록 시, 반드시 톤 단위로 환산하여 입력 부탁드립니다.</li>
    </ul>
  </div>

  <rjct-rsn-area :prfmncBasicInfo="fireRtrvlDtlObj.prfmncMst" />
  <prfmnc-mdfcn-prm-area :prfmncBasicInfo="fireRtrvlDtlObj.prfmncMst" @dmnd-rtrcn="dmndRtrcnCmptn" />

  <div class="board_info mt_40">
    <h3 class="title2" tabindex="-1" ref="prfmncDtlFireRtrvlWtCrynGridTitle">폐기물 반입 현황</h3>
  </div>

  <sb-grid
      id="prfmncDtlFireRtrvlWtCrynGrid"
      :dataSource="dataSourceFireRtrvlWtCryn"
      :columns="gridColumnsFireRtrvlWtCryn"
      :gridattr="gridAttrFireRtrvlWtCryn"
      v-model:grid="prfmncFireRtrvlWtCrynGridObject"
      @loaded="gridLoadedFireRtrvlWtCryn"
      :refreshGrid="gridReloadFireRtrvlWtCryn"
      @change="changeFireRtrvlWtCryn"
  />

  <div class="board_info mt_40">
    <h3 class="title2">자원순환정보시스템의 소각(처분)사업장 반입처리 등록 현황</h3>
    <div>
      <br-button label="자원순환정보시스템 정보적용" size="sm" format="btn" @click="rsrcRcyclnCrynInfoApply" />
    </div>
  </div>

  <div class="grid_search">
    <span class="txt_point">※ 자원순환정보시스템에 입력된 반입정보입니다.</span>
  </div>

  <sb-grid
      id="prfmncDtlFireRtrvlRsrcRcyclnCrynGrid"
      :dataSource="dataSourceFireRtrvlRsrcRcyclnCryn"
      :columns="gridColumnsFireRtrvlRsrcRcyclnCryn"
      :gridattr="gridAttrFireRtrvlRsrcRcyclnCryn"
      :param="fireRtrvlDtlObj.prfmncMst"
      v-model:grid="prfmncFireRtrvlRsrcRcyclnCrynGridObject"
      @loaded="gridLoadedFireRtrvlRsrcRcyclnCryn"
      :refreshGrid="gridReloadFireRtrvlRsrcRcyclnCryn"
  />

  <div class="board_info mt_40">
    <h3 class="title2" tabindex="-1" ref="prfmncDtlFireRtrvlWtTitle">폐기물 처리 현황</h3>
  </div>
  <div class="grid_search">
    <h4>폐기물 처리 현황 <span>※ 전처리량(톤):전처리되어 소각로에 투입되지 않는 폐기물</span></h4>
  </div>
  <div class="board_write thead">
    <table>
      <caption>폐기물 처리 현황</caption>
      <colgroup>
        <col style="width: 20%;" v-if="this.fireRtrvlDtlObj.prfmncMst.prfmncYr === '2024'">
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;" v-if="this.fireRtrvlDtlObj.prfmncMst.prfmncYr === '2024'">
      </colgroup>
      <thead>
      <tr>
        <th scope="col" v-if="this.fireRtrvlDtlObj.prfmncMst.prfmncYr === '2024'">이월량</th>
        <th scope="col">반입량</th>
        <th scope="col">전처리량</th>
        <th scope="col">투입량</th>
        <th scope="col" v-if="this.fireRtrvlDtlObj.prfmncMst.prfmncYr === '2024'">잔량</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="txt_center" aria-label="이월량" v-if="this.fireRtrvlDtlObj.prfmncMst.prfmncYr === '2024'">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.wtCrfqty" :maxlength="15" validator="number" align="right" unit="톤" panel="login" />
        </td>
        <td class="txt_center" aria-label="반입량">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.wtSumCryqty" :maxlength="15" validator="number" align="right" unit="톤" panel="login" readonly required />
        </td>
        <td class="txt_center" aria-label="전처리량">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.prprcsWstqty" :maxlength="15" validator="number" align="right" unit="톤" panel="login"  />
        </td>
        <td class="txt_center" aria-label="투입량">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.wtSumInpqty" :maxlength="15" validator="number" align="right" unit="톤" panel="login" readonly required />
        </td>
        <td class="txt_center" aria-label="잔량" v-if="this.fireRtrvlDtlObj.prfmncMst.prfmncYr === '2024'">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.wtRmnqty" :maxlength="15" validator="number" align="right" unit="톤" panel="login" readonly />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h4 class="title3" tabindex="-1" ref="prfmncDtlFireRtrvlPrdctnTitle">시설별 투입/생산 현황</h4>

  <div class="grid_search">
    <h4>시설별 투입/생산 현황 <span>※ 소각기 정보는 마이오피스 화면에서 관리 할 수 있습니다.</span></h4>
  </div>
  <sb-grid
      id="prfmncDtlFireRtrvlPrdctnGrid"
      :dataSource="dataSourceFireRtrvlPrdctn"
      :columns="gridColumnsFireRtrvlPrdctn"
      :gridattr="gridAttrFireRtrvlPrdctn"
      :param="fireRtrvlDtlObj.prfmncMst"
      v-model:grid="prfmncFireRtrvlPrdctnGridObject"
      @loaded="gridLoadedFireRtrvlPrdctn"
      :refreshGrid="gridReloadFireRtrvlPrdctn"
      @change="changeFireRtrvlPrdctn"
  />

  <div class="board_write thead">
    <table>
      <caption>평균 발열량</caption>
      <colgroup>
        <col style="width: 20%;">
        <col style="width: 30%;">
        <col style="width: 50%;">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">평균 발열량</th>
        <td class="" aria-label="평균 발열량">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.fireAvgLcp" :maxlength="15" validator="number" align="right" unit="kcal/kg" panel="login" required />
        </td>
        <td class="txt_left" aria-label="평균 발열량">
          * 설비에 투입한 폐기물의 평균 저위발열량
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info mt_40">
    <h3 class="title2">보조에너지 사용량</h3>
  </div>
  <div class="grid_search">
    <h4>보조에너지 사용량 <span>※ 외부반입(한전) : 해당월 전기사용량(MWh)를 입력하시면 됩니다.</span></h4>
  </div>
  <div class="board_write thead">
    <table>
      <caption>보조에너지 사용량</caption>
      <colgroup>
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
      </colgroup>
      <thead>
      <tr>
        <th scope="col" colspan="3">보조연료 사용량</th>
        <th scope="col" colspan="2">전기 사용량(MWh)</th>
      </tr>
      <tr>
        <th scope="col">유류(㎘)</th>
        <th scope="col">LNG(㎥)</th>
        <th scope="col">기타(TOE)</th>
        <th scope="col">외부반입(한전)</th>
        <th scope="col">자체생산</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="txt_center" aria-label="유류(㎘)">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.oilAsstFuelUsqty" :maxlength="15" validator="number" align="right" unit="㎘" panel="login" required />
        </td>
        <td class="txt_center" aria-label="LNG(㎥)">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.lngAsstFuelUsqty" :maxlength="15" validator="number" align="right" unit="㎥" panel="login" required />
        </td>
        <td class="txt_center" aria-label="기타(TOE)">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.etcAsstFuelUsqty" :maxlength="15" validator="number" align="right" unit="TOE" panel="login" required />
        </td>
        <td class="txt_center" aria-label="외부반입(한전)">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.crynPrvtmAsstFuelUsqty" :maxlength="15" validator="number" align="right" unit="MWh" panel="login" required />
        </td>
        <td class="txt_center" aria-label="자체생산">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.prdctnPrvtmAsstFuelUsqty" :maxlength="15" validator="number" align="right" unit="MWh" panel="login" required />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info mt_40">
    <h3 class="title2">에너지 회수 현황</h3>
  </div>

  <div class="board_list">
    <table>
      <caption>에너지 회수 현황</caption>
      <colgroup>
        <col :style="{width: `calc(100% / 6)`}">
        <col :style="{width: `calc(100% / 6)`}">
        <col :style="{width: `calc(100% / 6)`}">
        <col :style="{width: `calc(100% / 6)`}">
        <col :style="{width: `calc(100% / 6)`}">
        <col :style="{width: `calc(100% / 6)`}">
      </colgroup>
      <thead>
      <tr>
        <th scope="col">총 에너지 발생가능량<br />(Gcal)</th>
        <th scope="col">총 에너지 회수량(Gcal)<br />(X + Y)</th>
        <th scope="col">에너지 발생량(Gcal)<br />(X)</th>
        <th scope="col">미이용(Gcal)<br />(Y)</th>
        <th scope="col">에너지 이용량(Gcal)<br />(A + B + C)</th>
        <th scope="col">에너지 이용효율(%)</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="txt_center" aria-label="총 에너지 발생가능량(Gcal)">
          <br-form-input label="" v-model="fireRtrvlDtlObj.totalEneOcrnPsbltyQty" :maxlength="15" validator="number" align="right" unit="Gcal" panel="login" readonly />
        </td>
        <td class="txt_center" aria-label="총 에너지 회수량 (Gcal) (X + Y)">
          <br-form-input ref="eneRtrqtyInput" label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.eneRtrqty" :maxlength="15" validator="number" align="right" unit="Gcal" panel="login" readonly required />
        </td>
        <td class="txt_center" aria-label="에너지 발생량(Gcal) (X)">
          <br-form-input label="" v-model="fireRtrvlDtlObj.eneOcrnQtyTotal" :maxlength="15" validator="number" align="right" unit="Gcal" panel="login" readonly />
        </td>
        <td class="txt_center" aria-label="미이용(Gcal) (Y)">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.eneUnuqty" :maxlength="15" validator="number" align="right" unit="Gcal" panel="login" />
        </td>
        <td class="txt_center" aria-label="에너지 이용량(Gcal) (A + B + C)">
          <br-form-input label="" v-model="fireRtrvlDtlObj.eneutzQtyTotal" :maxlength="15" validator="number" align="right" unit="Gcal" panel="login" readonly />
        </td>
        <td class="txt_center" aria-label="에너지 이용효율(%)">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.eneUtztnEfcnc" :maxlength="15" validator="number" align="right" unit="%" panel="login" readonly />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info mt_40">
    <h3 class="title2">에너지 이용 현황</h3>
  </div>

  <div class="board_list">
    <table>
      <caption>에너지 이용 현황</caption>
      <colgroup>
        <col :style="{width: `calc(100% / 7)`}">
        <col :style="{width: `calc(100% / 7)`}">
        <col :style="{width: `calc(100% / 7)`}">
        <col :style="{width: `calc(100% / 7)`}">
        <col :style="{width: `calc(100% / 7)`}">
        <col :style="{width: `calc(100% / 7)`}">
        <col :style="{width: `calc(100% / 7)`}">
      </colgroup>
      <thead>
      <tr>
        <th scope="col" colspan="3">자체사용</th>
        <th scope="col" colspan="3">외부공급</th>
        <th scope="col" rowspan="2" class="bg-blue-light">발전설비투입량(Gcal)<br />(C)</th>
      </tr>
      <tr>
        <th scope="col">소계(A)</th>
        <th scope="col" class="bg-orange-light">열사용(Gcal)</th>
        <th scope="col" class="bg-yellow-light">기타(Gcal)</th>
        <th scope="col">소계(B)</th>
        <th scope="col" class="bg-orange-light">열공급(Gcal)</th>
        <th scope="col" class="bg-yellow-light" style="border-right: 1px solid #e0e0e0;">기타(Gcal)</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="txt_center" aria-label="[자체사용] 소계(A)">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.stotUsqty" :maxlength="15" validator="number" align="right" unit="Gcal" panel="login" readonly />
        </td>
        <td class="txt_center" aria-label="[자체사용] 열사용(Gcal)">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.htsrcUsqty" :maxlength="15" validator="number" align="right" unit="Gcal" panel="login" />
        </td>
        <td class="txt_center" aria-label="[자체사용] 기타(Gcal)">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.etcUsgUsqty" :maxlength="15" validator="number" align="right" unit="Gcal" panel="login" />
        </td>
        <td class="txt_center" aria-label="[외부공급] 소계(B)">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.stotNsqty" :maxlength="15" validator="number" align="right" unit="Gcal" panel="login" readonly />
        </td>
        <td class="txt_center" aria-label="[외부공급] 열공급(Gcal)">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.htsrcSumNsqty" :maxlength="15" validator="number" align="right" unit="Gcal" panel="login" readonly />
        </td>
        <td class="txt_center" aria-label="[외부공급] 기타(Gcal)">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.etcUsgSumNsqty" :maxlength="15" validator="number" align="right" unit="Gcal" panel="login" readonly />
        </td>
        <td class="txt_center" aria-label="발전설비투입량(Gcal) (C)">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.elctgnEquipInpqty" :maxlength="15" validator="number" align="right" unit="Gcal" panel="login" />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h4 class="title3">전력발전 생산/이용 현황</h4>
  <div class="board_write thead">
    <table>
      <caption>전력발전 생산/이용 현황</caption>
      <colgroup>
        <col style="width:25%">
        <col style="width:25%">
        <col style="width:25%">
        <col style="width:25%">
      </colgroup>
      <thead>
      <tr>
        <th scope="col" class="bg-blue-light">발전량 합계(MWh)</th>
        <th scope="col" class="bg-blue-light">자체사용량(MWh)</th>
        <th scope="col" class="bg-blue-light">외부판매량(MWh)</th>
        <th scope="col" class="bg-blue-light">기타(MWh)</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="txt_center" aria-label="발전량 합계(MWh)">
          <br-form-input label="" v-model="fireRtrvlDtlObj.elctgnQtyTotal" :maxlength="15" validator="number" align="right" unit="MWh" panel="login" readonly />
        </td>
        <td class="txt_center" aria-label="자체사용량(MWh)">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.prvtmSumUsqty" :maxlength="15" validator="number" align="right" unit="MWh" panel="login" />
        </td>
        <td class="txt_center" aria-label="외부판매량(MWh)">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.prvtmSumNsqty" :maxlength="15" validator="number" align="right" unit="MWh" panel="login" readonly />
        </td>
        <td class="txt_center" aria-label="기타(MWh)">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.etcPrvtmUsqty" :maxlength="15" validator="number" align="right" unit="MWh" panel="login" />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h4 class="title3">판매 상세 현황</h4>
  <div class="grid_search">
    <h4>판매 상세 현황 <span>※ 판매업체 목록은 정보등록 화면의 에너지공급사업장 현황에서 관리 할 수 있습니다.</span></h4>
  </div>
  <sb-grid
      id="prfmncFireRtrvlNtslGrid"
      :dataSource="dataSourceFireRtrvlNtsl"
      :columns="gridColumnsFireRtrvlNtsl"
      :gridattr="gridAttrFireRtrvlNtsl"
      v-model:grid="prfmncFireRtrvlNtslGridObject"
      @loaded="gridLoadedFireRtrvlNtsl"
      :refreshGrid="gridReloadFireRtrvlNtsl"
      @change="changeFireRtrvlNtsl"
  />

  <!-- 실적상태이력 현황 (신규등록인 경우 노출안함) -->
  <prfmnc-stts-hstry v-if="fireRtrvlDtlObj.prfmncMst.prfmncId" :prfmncId="fireRtrvlDtlObj.prfmncMst.prfmncId" />

  <!-- 수정허용요청 이력 현황 (신규등록인 경우 노출안함) -->
  <prfmnc-mdfcn-prm-hstry v-if="fireRtrvlDtlObj.prfmncMst.prfmncId" :prfmncId="fireRtrvlDtlObj.prfmncMst.prfmncId" />

  <!-- 버튼 -->
  <common-button-area
      :prfmncBasicInfo="fireRtrvlDtlObj.prfmncMst"
      @save="prtmncRptDtlSaveChk"
      @submission="prtmncRptDtlSaveChk(true)"
  />

  <fire-rtrvl-prcs-prfmnc-chg-dscnt-popup @eblc-aplcn="prtmncRptDtlSave" />
</template>

<script>
import SbGrid from "@/components/SbGrid.vue";
import utils from "@/js/utils/utils";
import commonButtonArea from "@/views/prfmnc-rpt/dtl/components/CommonButtonArea.vue";
import prfmncSttsHstry from "@/views/prfmnc-rpt/components/PrfmncSttsHstry.vue";
import PrfmncMdfcnPrmHstry from "@/views/prfmnc-rpt/components/PrfmncMdfcnPrmHstry.vue";
import FireRtrvlPrcsPrfmncChgDscntPopup from "@/views/prfmnc-rpt/dtl/components/popup/FireRtrvlPrcsPrfmncChgDscntPopup.vue";
import {storeSwitch} from "@/js/store/store-switch";
import PrfmncMdfcnPrmArea from "@/views/prfmnc-rpt/dtl/components/PrfmncMdfcnPrmArea.vue";
import RjctRsnArea from "@/views/prfmnc-rpt/dtl/components/RjctRsnArea.vue";

const ROUTE = {
  PERFORMANCE_LIST: "/prfmnc-rpt/PrfmncRpt",
  PERFORMANCE_DTL: "/prfmnc-rpt/dtl/FireRtrvl"
};

const API = {
  PRFMNC_DTL_SAVE: "/prfmnc/fireRtrvl", // 실적 매립가스 상세저장
  USE_PROCESS_PERFORMANCE_CHANGE_CHECK: "/prfmnc/fireRtrvl/prcs-chg-chck",
};

const CODE = {
  PERFORMANCE_STATUS: "PFM001", // 실적상태코드
};

export default {
  components: {
    RjctRsnArea, PrfmncMdfcnPrmArea,
    SbGrid,
    commonButtonArea,
    prfmncSttsHstry,
    PrfmncMdfcnPrmHstry,
    FireRtrvlPrcsPrfmncChgDscntPopup
  },
  props: ['prfmncFireRtrvl', 'prvPrfmncFireRtrvl', 'prfmncFireRtrvlPrdctnList', 'prfmncFireRtrvlWtCrynList', 'prfmncFireRtrvlRsrcRcyclnList', 'prfmncFireRtrvlNtslList', 'prfmncMst', 'prfmncFclt', 'showDetail'],
  data() {
    return {
      prfmncSttsList: [], // 실적상태코드

      updatedWtCrynData: [], // 폐기물반입현황 수정된 행
      insertedWtCrynData: [], // 폐기물반입현황 추가된 행

      // 실적 매립가스 상세정보 데이터
      fireRtrvlDtlObj:{
        wtStotCryqtyTotal: 0, // 반입량 합계
        elctgnQtyTotal: 0, // 발전량 합계 (자체사용량 + 외부판매량 + 기타)
        totalEneOcrnPsbltyQty: 0, // 총에너지발생가능량
        wtInpqtyTotal: 0, // 소각처리량 합계
        eneOcrnQtyTotal: 0, // 에너지발생량 합계
        eneutzQtyTotal: 0, // 에너지이용량 합계
        totalGasStotNsqty: 0, // 메탄가스 총 판매량 합계
        prfmncMst: {},
        prfmncFireRtrvlWtCrynList : [], // 폐기물반입현황
        prfmncFireRtrvlNtslList : [], // 소각판매현황
        prfmncFireRtrvl: {
          wtCrfqty: 0, // 폐기물이월량
          wtSumCryqty: 0, // 폐기물합계반입량
          prprcsWstqty: 0, // 전처리폐기물량
          wtSumInpqty: 0, // 폐기물합계투입량
          wtRmnqty: 0, // 폐기물잔량
          fireAvgLcp: 0, // 소각평균저위발열량
          eneRtrqty: 0, // 에너지회수량
          eneUnuqty: 0, // 에너지미이용량
          eneUtztnEfcnc: 0, // 에너지이용효율
          stotUsqty: 0, // 소계사용량
          htsrcUsqty: 0, // 열원사용량
          etcUsgUsqty: 0, // 기타용도사용량
          elctgnUsgUsqty: 0, // 발전용도사용량
          stotNsqty: 0, // 소계판매량
          htsrcSumNsqty: 0, // 열원합계판매량
          etcUsgSumNsqty: 0, // 기타용도합계판매량
          elctgnUsgNsqty: 0, // 발전용도판매량
          elctgnEquipInpqty: 0, // 발전설비투입량
          prvtmSumNsqty: 0, // 전기합계판매량
          prvtmSumUsqty: 0, // 전기합계사용량
          etcPrvtmUsqty: 0, // 기타전기사용량
          oilAsstFuelUsqty: 0, // 유류보조연료사용량
          lngAsstFuelUsqty: 0, // LNG보조연료사용량
          etcAsstFuelUsqty: 0, // 기타보조연료사용량
          crynPrvtmAsstFuelUsqty: 0, // 반입전기보조연료사용량
          prdctnPrvtmAsstFuelUsqty: 0, // 생산전기보조연료사용량
        }, // 매립가스실적 (메탄가스생산현황, 메탄가스사용현황)
        prvPrfmncFireRtrvl: {
          wtCrfqty: 0, // 폐기물이월량
          wtSumCryqty: 0, // 폐기물합계반입량
          prprcsWstqty: 0, // 전처리폐기물량
          wtSumInpqty: 0, // 폐기물합계투입량
          wtRmnqty: 0, // 폐기물잔량
          fireAvgLcp: 0, // 소각평균저위발열량
          eneRtrqty: 0, // 에너지회수량
          eneUnuqty: 0, // 에너지미이용량
          eneUtztnEfcnc: 0, // 에너지이용효율
          stotUsqty: 0, // 소계사용량
          htsrcUsqty: 0, // 열원사용량
          etcUsgUsqty: 0, // 기타용도사용량
          elctgnUsgUsqty: 0, // 발전용도사용량
          stotNsqty: 0, // 소계판매량
          htsrcSumNsqty: 0, // 열원합계판매량
          etcUsgSumNsqty: 0, // 기타용도합계판매량
          elctgnUsgNsqty: 0, // 발전용도판매량
          elctgnEquipInpqty: 0, // 발전설비투입량
          prvtmSumNsqty: 0, // 전기합계판매량
          prvtmSumUsqty: 0, // 전기합계사용량
          etcPrvtmUsqty: 0, // 기타전기사용량
          oilAsstFuelUsqty: 0, // 유류보조연료사용량
          lngAsstFuelUsqty: 0, // LNG보조연료사용량
          etcAsstFuelUsqty: 0, // 기타보조연료사용량
          crynPrvtmAsstFuelUsqty: 0, // 반입전기보조연료사용량
          prdctnPrvtmAsstFuelUsqty: 0, // 생산전기보조연료사용량
        } // 전월 매립가스실적
      },


      /**************** S : 폐기물반입현황 Grid ****************/
      dataSourceFireRtrvlWtCryn: [],
      gridColumnsFireRtrvlWtCryn: [],
      gridAttrFireRtrvlWtCryn: {
        checkable: false,
        pageable: false,
        editable: {eventType: 'mouseup'},
        showRowNo : false,
        excelExport: {
          fileName: '폐기물반입현황(' + this.prfmncMst.bplcKndNm + ').xlsx',
          includeHeader: true,
          includeFormula: true,
        },
        showStatus:true,
      },
      prfmncFireRtrvlWtCrynGridObject: null,
      gridReloadFireRtrvlWtCryn: false,
      /**************** E : 폐기물반입현황 Grid ****************/

      /**************** S : 자원순환정보시스템 매립사업장 반입처리 등록현황 Grid ****************/
      dataSourceFireRtrvlRsrcRcyclnCryn: [],
      gridColumnsFireRtrvlRsrcRcyclnCryn: [],
      gridAttrFireRtrvlRsrcRcyclnCryn: {
        checkable: false,
        pageable: false,
        editable : false,
        showRowNo : false,
        showStatus:false,
        toolBar:[],
      },
      prfmncFireRtrvlRsrcRcyclnCrynGridObject: null,
      gridReloadFireRtrvlRsrcRcyclnCryn: false,
      /**************** E : 자원순환정보시스템 매립사업장 반입처리 등록현황 Grid ****************/

      /**************** S : 시설별 투입/생산 현황 Grid ****************/
      dataSourceFireRtrvlPrdctn: [],
      gridColumnsFireRtrvlPrdctn: [],
      gridAttrFireRtrvlPrdctn: {
        checkable: false,
        pageable: false,
        editable: {eventType: 'mouseup'},
        showRowNo : false,
        excelExport: {
          fileName: '시설별 투입생산현황(' + this.prfmncMst.bplcKndNm + ').xlsx',
          includeHeader: true,
          includeFormula: true,
        },
        showStatus:true
      },
      prfmncFireRtrvlPrdctnGridObject: null,
      gridReloadFireRtrvlPrdctn: false,
      /**************** E : 시설별 투입/생산 현황 Grid ****************/

      /**************** S : 메탄가스판매현황 Grid ****************/
      dataSourceFireRtrvlNtsl: [],
      gridColumnsFireRtrvlNtsl: [],
      gridAttrFireRtrvlNtsl: {
        checkable: false,
        pageable: false,
        editable: {eventType: 'mouseup'},
        showRowNo : false,
        excelExport: {
          fileName: '판매상세현황(' + this.prfmncMst.bplcKndNm + ').xlsx',
          includeHeader: true,
          includeFormula: true,
        },
        showStatus:true
      },
      prfmncFireRtrvlNtslGridObject: null,
      gridReloadFireRtrvlNtsl: false,
      /**************** E : 메탄가스판매현황 Grid ****************/
    };
  },
  computed: {
    /**
     * [실적보고상세 - 실적연월]
     * YYYY-MM 포맷처리
     */
    formattedPrfmncYm() {
      return (this.fireRtrvlDtlObj.prfmncMst.prfmncYm?.substring(0, 4) ?? "") + "-" + (this.fireRtrvlDtlObj.prfmncMst.prfmncYm?.substring(4, 6) ?? "");
    },
  },
  watch: {
    prfmncMst: {
      handler(newVal) {
        this.fireRtrvlDtlObj.prfmncMst = {...this.fireRtrvlDtlObj.prfmncMst, ...newVal};
      },
      immediate: true,
      deep:true,
    },
    prfmncFireRtrvl() {
      this.fireRtrvlDtlObj.prfmncFireRtrvl = {...this.fireRtrvlDtlObj.prfmncFireRtrvl, ...this.prfmncFireRtrvl};
    },
    prvPrfmncFireRtrvl() {
      this.fireRtrvlDtlObj.prvPrfmncFireRtrvl = {...this.fireRtrvlDtlObj.prvPrfmncFireRtrvl, ...this.prvPrfmncFireRtrvl};

      // 신규 등록(실적아이디 존재여부로 구분)을 하려는 상태인 경우 폐기물처리현황의 이월량은 전월의 실적잔량을 가져온다.
      if(!this.fireRtrvlDtlObj.prfmncMst.prfmncId){
        this.fireRtrvlDtlObj.prfmncFireRtrvl.wtCrfqty = this.fireRtrvlDtlObj.prvPrfmncFireRtrvl.wtRmnqty;
      }
    },
    prfmncFireRtrvlPrdctnList() {
      this.dataSourceFireRtrvlPrdctn = this.prfmncFireRtrvlPrdctnList;
      this.initializePrfmncFireRtrvlPrdctnGridColumns();
      const itemCount = this.prfmncFireRtrvlPrdctnList.length;
      this.gridAttrFireRtrvlPrdctn.height = (itemCount * 30) + 186;
      this.gridReloadFireRtrvlPrdctn = true;

      // 소각처리량(톤) 합계
      this.fireRtrvlDtlObj.wtInpqtyTotal = this.prfmncFireRtrvlPrdctnList.reduce((acc, item) => acc + (item.wtInpqty || 0), 0);

      // 화면 초기 진입 시 시설별 투입/생산 현황의 소각처리량(톤) 합계값을 이용한 계산식을 통해 에너지회수현황 총에너지발생가능량을 노출
      this.fireRtrvlDtlObj.totalEneOcrnPsbltyQty = (parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.fireAvgLcp) * parseFloat(this.fireRtrvlDtlObj.wtInpqtyTotal)) / 1000;

      // 화면 초기 진입 시 시설별 투입/생산 현황의 에너지발생량합계를 에너지회수현황 에너지발생량에 노출
      this.fireRtrvlDtlObj.eneOcrnQtyTotal = this.prfmncFireRtrvlPrdctnList.reduce((acc, item) => acc + (item.enePrdqty || 0), 0);
    },
    prfmncFireRtrvlWtCrynList() {
      this.dataSourceFireRtrvlWtCryn = { data: this.prfmncFireRtrvlWtCrynList.list, schema: { id: 'sggStdgCd'}};
      this.initializePrfmncFireRtrvlWtCrynGridColumns();
      const itemCount = this.prfmncFireRtrvlWtCrynList.list.length;
      this.gridAttrFireRtrvlWtCryn.height = Math.max((itemCount * 30) + 190, 210);
      this.gridReloadFireRtrvlWtCryn = true;
    },
    prfmncFireRtrvlRsrcRcyclnList() {
      this.dataSourceFireRtrvlRsrcRcyclnCryn = { data: this.prfmncFireRtrvlRsrcRcyclnList.list, schema: { id: 'sggStdgCd'}};
      this.initializePrfmncFireRtrvlRsrcRcyclnGridColumns();
      const itemCount = this.prfmncFireRtrvlRsrcRcyclnList.list.length;
      this.gridAttrFireRtrvlRsrcRcyclnCryn.height = Math.max((itemCount * 30) + 190, 210);
      this.gridReloadFireRtrvlRsrcRcyclnCryn = true;
    },
    prfmncFireRtrvlNtslList() {
      this.dataSourceFireRtrvlNtsl = this.prfmncFireRtrvlNtslList.list;
      this.initializePrfmncFireRtrvlNtslGridColumns();
      const itemCount = this.prfmncFireRtrvlNtslList.list.length;
      this.gridAttrFireRtrvlNtsl.height = Math.max((itemCount * 30) + 190, 210);
      this.gridReloadFireRtrvlNtsl = true;
    },

    // 반입 그리드에서 반입량 합계가 변경되면 폐기물처리현황의 반입량항목에 적용
    'fireRtrvlDtlObj.wtStotCryqtyTotal': {
      handler(newValue) {
        this.fireRtrvlDtlObj.prfmncFireRtrvl.wtSumCryqty = newValue;
      },
      immediate: true
    },

    // 소각처리량(톤) 합계가 변경 시 이벤트
    'fireRtrvlDtlObj.wtInpqtyTotal': {
      handler(newValue) {
        this.fireRtrvlDtlObj.prfmncFireRtrvl.wtSumInpqty = newValue; // 폐기물처리현황의 투입량항목에 적용
        this.updateEneOcrnPsbltyQtyTotal(); // 에너지회수현황의 총에너지발생가능량 재계산
      },
      immediate: true
    },

    // 폐기물처리현황-이월량 값 변경 시 잔량 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.wtCrfqty': 'updateWtRmnqty',
    // 폐기물처리현황-반입량 값 변경 시 잔량 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.wtSumCryqty': 'updateWtRmnqty',
    // 폐기물처리현황-전처리량 값 변경 시 잔량 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.prprcsWstqty': 'updateWtRmnqty',
    // 폐기물처리현황-투입량 값 변경 시 잔량 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.wtSumInpqty': 'updateWtRmnqty',

    // 에너지이용현황-자체사용소계 값 변경 시 에너지이용량 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.stotUsqty': 'updateEneUtzqty',
    // 에너지이용현황-외부공급소계 값 변경 시 에너지이용량 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.stotNsqty': 'updateEneUtzqty',
    // 에너지이용현황-발전설비투입량 값 변경 시 에너지이용량 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.elctgnEquipInpqty': 'updateEneUtzqty',

    // 평균발열량 값 변경 시 에너지회수현황의 총에너지발생가능량 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.fireAvgLcp': 'updateEneOcrnPsbltyQtyTotal',
    // // 소각처리량(톤) 합계 값 변경 시 에너지회수현황의 총에너지발생가능량 재계산
    // 'fireRtrvlDtlObj.wtInpqtyTotal': 'updateEneOcrnPsbltyQtyTotal',

    // 에너지회수현황-총에너지회수량 값 변경 시 에너지이용효율 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.eneRtrqty': 'updateEneUtztnEfcnc',
    // 에너지회수현황-에너지이용량 값 변경 시 에너지이용효율 재계산
    'fireRtrvlDtlObj.eneutzQtyTotal': 'updateEneUtztnEfcnc',

    // 에너지회수현황-에너지발생량 값 변경 시 총에너지회수량 재계산
    'fireRtrvlDtlObj.eneOcrnQtyTotal': 'updateEneRtrqty',
    // 에너지회수현황-미이용 값 변경 시 총에너지회수량 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.eneUnuqty': 'updateEneRtrqty',

    // 자체사용-열사용 값 변경 시 자체사용 소계 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.htsrcUsqty': 'updateStotUsqty',
    // 자체사용-기타 값 변경 시 자체사용 소계 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.etcUsgUsqty': 'updateStotUsqty',

    // 외부공급-열공급 값 변경 시 외부공급 소계 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.htsrcSumNsqty': 'updateStotNsqty',
    // 외부공급-기타 값 변경 시 외부공급 소계 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.etcUsgSumNsqty': 'updateStotNsqty',

    // 자체사용량 값 변경 시 발전량합계 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.prvtmSumUsqty': 'updateElctgnQtyTotal',
    // 외부판매량 값 변경 시 발전량합계 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.prvtmSumNsqty': 'updateElctgnQtyTotal',
    // 기타 값 변경 시 발전량합계 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.etcPrvtmUsqty': 'updateElctgnQtyTotal',
  },
  created() {
    this.initialize();
  },
  mounted() {
  },
  methods: {
    dmndRtrcnCmptn() {
      location.reload();
    },
    // 폐기물처리현황의 잔량 계산
    updateWtRmnqty() {
      this.fireRtrvlDtlObj.prfmncFireRtrvl.wtRmnqty = this.sanitizeFloatingPointError(
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.wtCrfqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.wtSumCryqty) -
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.prprcsWstqty) -
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.wtSumInpqty));
    },
    // 에너지회수현황의 총에너지발생가능량 계산
    updateEneOcrnPsbltyQtyTotal() {
      this.fireRtrvlDtlObj.totalEneOcrnPsbltyQty =
          (parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.fireAvgLcp) * parseFloat(this.fireRtrvlDtlObj.wtInpqtyTotal)) / 1000;
    },
    // 에너지회수현황의 에너지이용효율 계산
    updateEneUtztnEfcnc() {
      if(!this.fireRtrvlDtlObj.prfmncFireRtrvl.eneRtrqty || isNaN(this.fireRtrvlDtlObj.prfmncFireRtrvl.eneRtrqty)){
        this.fireRtrvlDtlObj.prfmncFireRtrvl.eneUtztnEfcnc = 0;
      }else {
        this.fireRtrvlDtlObj.prfmncFireRtrvl.eneUtztnEfcnc =
            (parseFloat(this.fireRtrvlDtlObj.eneutzQtyTotal) / parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.eneRtrqty)) * 100;
      }
    },
    // 에너지회수현황의 에너지이용량 계산
    updateEneUtzqty() {
      this.fireRtrvlDtlObj.eneutzQtyTotal =
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.stotUsqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.stotNsqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.elctgnEquipInpqty);
    },
    // 에너지회수현황의 총에너지회수량 계산
    updateEneRtrqty() {
      this.fireRtrvlDtlObj.prfmncFireRtrvl.eneRtrqty =
          parseFloat(this.fireRtrvlDtlObj.eneOcrnQtyTotal) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.eneUnuqty);
    },
    // 에너지이용현황의 자체사용 소계 계산
    updateStotUsqty() {
      this.fireRtrvlDtlObj.prfmncFireRtrvl.stotUsqty =
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.htsrcUsqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.etcUsgUsqty);
    },
    // 에너지이용현황의 외부공급 소계 계산
    updateStotNsqty() {
      this.fireRtrvlDtlObj.prfmncFireRtrvl.stotNsqty =
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.htsrcSumNsqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.etcUsgSumNsqty);
    },
    // 전력발전 생산/이용 현황의 발전량 합계 계산
    updateElctgnQtyTotal() {
      this.fireRtrvlDtlObj.elctgnQtyTotal =
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.prvtmSumUsqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.prvtmSumNsqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.etcPrvtmUsqty);
    },
    // 폐기물 반입현황 총 반입량 가져오기
    changeFireRtrvlWtCryn(values){
      values.forEach(() => {
        // 그리드의 모든 데이터를 가져와서 반입량 항목의 모든 값의 합계를 계산
        const wtCrynAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlWtCrynGridObject);
        const total = wtCrynAllItems.reduce((sum, item) => {
          return sum + (item.wtStotCryqty || 0);
        }, 0);

        this.fireRtrvlDtlObj.wtStotCryqtyTotal = total;
      });
    },
    // 시설별 투입/생산 현황 변경 이벤트
    changeFireRtrvlPrdctn(values){
      // data.some(item => item.field === "aaaaa")
      // 변경된 그리드 데이터 중 반입량이 변경된 경우 이벤트
      values.forEach(item => {
        if (item.field === 'wtInpqty') {
          // 그리드의 모든 데이터를 가져와서 소각처리량(톤) 항목의 모든 값의 합계를 계산
          const prdctnAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlPrdctnGridObject);
          const total = prdctnAllItems.reduce((sum, item) => {
            return sum + (item.wtInpqty || 0);
          }, 0);

          this.fireRtrvlDtlObj.wtInpqtyTotal = total;
        }
        if (item.field === 'enePrdqty') {
          // 그리드의 모든 데이터를 가져와서 에너지발생량 항목의 모든 값의 합계를 계산
          const prdctnAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlPrdctnGridObject);
          const total = prdctnAllItems.reduce((sum, item) => {
            return sum + (item.enePrdqty || 0);
          }, 0);

          this.fireRtrvlDtlObj.eneOcrnQtyTotal = total;
        }
      });
    },
    // 소각 판매현황 총 판매량 가져오기
    changeFireRtrvlNtsl(values){
      // 변경된 그리드 데이터 중 판매량이 변경된 경우 이벤트
      values.forEach(item => {
        if (item.field === 'htsrcNsqty') {
          // 그리드의 모든 데이터를 가져와서 열 판매량 항목의 모든 값의 합계를 계산 후 에너지이용현황(외부공급-열 공급) 항목에 적용
          const ntslAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlNtslGridObject);
          const total = ntslAllItems.reduce((sum, item) => {
            return sum + (item.htsrcNsqty || 0);
          }, 0);

          this.fireRtrvlDtlObj.prfmncFireRtrvl.htsrcSumNsqty = total;
        }
        if (item.field === 'prvtmNsqty') {
          // 그리드의 모든 데이터를 가져와서 전력 판매량 항목의 모든 값의 합계를 계산 후 전력발전 생산/이용 현황(외부판매량) 항목에 적용
          const ntslAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlNtslGridObject);
          const total = ntslAllItems.reduce((sum, item) => {
            return sum + (item.prvtmNsqty || 0);
          }, 0);

          this.fireRtrvlDtlObj.prfmncFireRtrvl.prvtmSumNsqty = total;
        }
        if (item.field === 'etcEneNsqty') {
          // 그리드의 모든 데이터를 가져와서 기타 판매량 항목의 모든 값의 합계를 계산 후 에너지이용현황(외부공급-기타) 항목에 적용
          const ntslAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlNtslGridObject);
          const total = ntslAllItems.reduce((sum, item) => {
            return sum + (item.etcEneNsqty || 0);
          }, 0);

          this.fireRtrvlDtlObj.prfmncFireRtrvl.etcUsgSumNsqty = total;
        }
      });
    },
    async initialize() {
      await this.loadPrfmncSttsCd();
    },
    // 공통코드(실적상태코드) 로드
    async loadPrfmncSttsCd() {
      const prfmncSttsList = this.$store.getters.getCodeList({groupCd: CODE.PERFORMANCE_STATUS, useYn: 'Y'});
      this.prfmncSttsList = prfmncSttsList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    // 폐기물 반입현황 그리드 컬럼 세팅
    initializePrfmncFireRtrvlWtCrynGridColumns() {
      this.gridColumnsFireRtrvlWtCryn = [
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        {
          caption: '반입',
          width: 200,
          part: 'head',
          columns : [
            {
              field: 'ctpvStdgNm',
              caption: '시·도',
              width: 100,
              editable: false,
            },
            {
              field: 'sggStdgNm',
              caption: '시·군·구',
              width: 100,
              editable: false,
            }
          ],
          total: {
            aggregates: [
            ],
            header: [
              { template: '계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        {
          field: 'wtStotCryqty',
          caption: '반입량 합계(톤)',
          unit: '%',
          align: 'right',
          dataType: 'number',
          editable: false,
          colCss: 'grid-disabled',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          calc: {
            require: ['bplcDschrgWstqty', 'hmeLifeWstqty', 'bplcLifeWstqty', 'largeWstqty', 'wstsrtWstqty', 'wstwdtWstqty', 'wstsftWstqty', 'wstvnlWstqty', 'etcWstqty'],
            eval: 'sum',
            nullAs: 0,
            dataType: 'number',
          },
          format: '#,##0.00',
        },
        {
          field: 'bplcDschrgWstqty',
          caption: '배출사업장계(톤)',
          width: 100,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
        {
          caption: '종량제에 의한 혼합배출',
          columns : [
            {
              field: 'hmeLifeWstqty',
              caption: '가정생활(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'bplcLifeWstqty',
              caption: '사업장생활(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            }
          ]
        },
        {
          caption: '종량제 외 배출',
          columns : [
            {
              field: 'largeWstqty',
              caption: '대형폐기물(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'wstsrtWstqty',
              caption: '폐합성수지류(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'wstwdtWstqty',
              caption: '폐목재(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'wstsftWstqty',
              caption: '폐섬유(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'wstvnlWstqty',
              caption: '영농폐비닐(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'etcWstqty',
              caption: '기타(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            }
          ]
        }
      ]
    },
    // 자원순환정보시스템 반입처리 등록현황 그리드 컬럼 세팅
    initializePrfmncFireRtrvlRsrcRcyclnGridColumns() {
      this.gridColumnsFireRtrvlRsrcRcyclnCryn = [
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        {
          caption: '반입',
          width: 200,
          part: 'head',
          columns : [
            {
              field: 'ctpvStdgNm',
              caption: '시·도',
              width: 100,
              editable: false,
            },
            {
              field: 'sggStdgNm',
              caption: '시·군·구',
              width: 100,
              editable: false,
            }
          ],
          total: {
            aggregates: [
            ],
            header: [
              { template: '계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        {
          field: 'wtStotCryqty',
          caption: '반입량 합계(톤)',
          unit: '%',
          align: 'right',
          dataType: 'number',
          editable: false,
          colCss: 'grid-disabled',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          calc: {
            require: ['bplcDschrgWstqty', 'hmeLifeWstqty', 'bplcLifeWstqty', 'largeWstqty', 'wstsrtWstqty', 'wstwdtWstqty', 'wstsftWstqty', 'wstvnlWstqty', 'etcWstqty'],
            eval: 'sum',
            nullAs: 0,
            dataType: 'number',
          },
          format: '#,##0.00',
        },
        {
          field: 'bplcDschrgWstqty',
          caption: '배출사업장계(톤)',
          width: 100,
          align: 'right',
          dataType: 'number',
          editable: false,
          colCss: 'grid-disabled',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
        {
          caption: '종량제에 의한 혼합배출',
          columns : [
            {
              field: 'hmeLifeWstqty',
              caption: '가정생활(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'bplcLifeWstqty',
              caption: '사업장생활(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            }
          ]
        },
        {
          caption: '종량제 외 배출',
          columns : [
            {
              field: 'largeWstqty',
              caption: '대형폐기물(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'wstsrtWstqty',
              caption: '폐합성수지류(톤)',
              minWidth: 250,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'wstwdtWstqty',
              caption: '폐목재(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'wstsftWstqty',
              caption: '폐섬유(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'wstvnlWstqty',
              caption: '영농폐비닐(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'etcWstqty',
              caption: '기타(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            }
          ]
        }
      ]
    },
    // 시설별 투입/생산 현황 그리드 컬럼 세팅
    initializePrfmncFireRtrvlPrdctnGridColumns() {
      this.gridColumnsFireRtrvlPrdctn = [
        { field: 'fcltSqno', caption: '시설순번', visible: false, editable: false },
        {
          field: 'fcltNm',
          caption: '시설명',
          width: 450,
          unit: 'px',
          align: 'left',
          part: 'head',
          editable: false,
          total: {
            aggregates: [
            ],
            header: [
              { template: '계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        {
          field: 'wtInpqty',
          caption: '소각처리량(톤)',
          width: 420,
          unit: 'px',
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
        {
          field: 'enePrdqty',
          caption: '에너지발생량(Gcal)',
          width: 100,
          unit: '%',
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
      ]
    },
    // 메탄가스판매현황 그리드 컬럼 세팅
    initializePrfmncFireRtrvlNtslGridColumns() {
      this.gridColumnsFireRtrvlNtsl = [
        { field: 'eneBzentySn', caption: '에너지업체일련번호', visible: false, editable: false },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        {
          caption: '판매 업체',
          width: 700,
          part: 'head',
          columns : [
            {
              field: 'ctpvStdgNm',
              caption: '시·도',
              width: 200,
              editable: false,
            },
            {
              field: 'sggStdgNm',
              caption: '시·군·구',
              width: 200,
              editable: false,
            },
            {
              field: 'bzentyNm',
              caption: '업체명',
              width: 300,
              align: 'left',
              editable: false,
            },
          ],
          total: {
            aggregates: [
            ],
            header: [
              { template: '계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        {
          field: 'htsrcNsqty',
          caption: '열 판매량(Gcal)',
          captionCss:'bg-orange-light',
          width: 170,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
        {
          field: 'prvtmNsqty',
          caption: '전력 판매량(MWh)',
          captionCss:'bg-blue-light',
          width: 170,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
        {
          field: 'etcEneNsqty',
          caption: '기타 판매량(Gcal)',
          captionCss:'bg-yellow-light',
          unit: '%',
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
      ]
    },
    gridLoadedFireRtrvlWtCryn() {
      this.gridReloadFireRtrvlWtCryn = false;
    },
    gridLoadedFireRtrvlRsrcRcyclnCryn() {
      this.gridReloadFireRtrvlRsrcRcyclnCryn = false;
    },
    gridLoadedFireRtrvlPrdctn() {
      this.gridReloadFireRtrvlPrdctn = false;
    },
    gridLoadedFireRtrvlNtsl() {
      this.gridReloadFireRtrvlNtsl = false;
    },
    formatNumber(number) {
      return utils.formatNumberWithComma(number);
    },
    // 실적보고상세저장 호출
    async prtmncRptDtlSaveChk(isSubmit = false){
      let prfmncSttsCd = "IPG"; // 저장 시 기본 작성중 상태처리
      const validationMessage = this.validate(true);

      if(validationMessage){
        alert(validationMessage);
        return;
      }

      if (!isSubmit && !confirm("저장하시겠습니까?")) {
        return;
      }else if (isSubmit && !confirm("제출하시겠습니까?")) {
        return;
      }

      if(isSubmit){
        prfmncSttsCd = "SUB"; // 제출 시 실적상태코드 제출코드로 전송함
      }

      /* 소각은 제출과 승인을 진행하지 않으므로 주석처리
      if (!validationMessage) {
        if (confirm("필수입력 조건이 만족하여 제출가능한상태입니다.\n제출하시겠습니까?")) {
          prfmncSttsCd = "SUB"; // 제출 시 실적상태코드 제출코드로 전송함
        }else{
          if(isSubmit){
            return;
          }
        }
      }
      */

      /* 25-01-14 소각 잔량 24년도까지는 띄우지 않음. 24년 실적은 이월량 수정되도록 수정요청됨(고민정 대리)
      try {
        const result = await this.checkFirePrcsPrfmc(prfmncSttsCd);
        if (result.change) {
          storeSwitch.on('FireRtrvlPrcsPrfmncChgDscntPopup', {dataList: result.list, prfmncMst: this.prfmncMst});
          return;
        }
      } catch (e) {
        alert("오류가 발생했습니다.");
        return;
      }
      */

      // 25-01-14 KJM 24년 12월 실적이 아니면 0으로 저장되도록 수정
      if(this.fireRtrvlDtlObj.prfmncMst.prfmncYr !== '2024'){
        this.fireRtrvlDtlObj.prfmncFireRtrvl.wtCrfqty = 0;
        this.fireRtrvlDtlObj.prfmncFireRtrvl.wtRmnqty = 0;
      }

      await this.prtmncRptDtlSave(prfmncSttsCd);
    },
    // 실적보고상세저장 호출
    async prtmncRptDtlSave(prfmncSttsCd, changeList = []){

      console.log("prtmncRptDtlSave");

      const { updated: wtCrynUpdated } = window.SBGrid3.getSaveData(this.prfmncFireRtrvlWtCrynGridObject, false, true, false);
      const { updated: ntslUpdated } = window.SBGrid3.getSaveData(this.prfmncFireRtrvlNtslGridObject, false, true, false);
      const { updated: prdctnUpdated } = window.SBGrid3.getSaveData(this.prfmncFireRtrvlPrdctnGridObject, false, true, false);

      this.$apiCall.post(
          API.PRFMNC_DTL_SAVE,
          {
            wtCrynUpdated,
            ntslUpdated,
            prdUpdated : prdctnUpdated,
            prfmncMst: Object.assign({}, this.fireRtrvlDtlObj.prfmncMst,{ prfmncSttsCd: prfmncSttsCd }),
            prfmncFireRtrvl: this.fireRtrvlDtlObj.prfmncFireRtrvl,
            prfmncSttsCd,
            changeList
          },
          (data) => {
            alert((prfmncSttsCd === "SUB" ? '제출' : '저장') + "이 완료되었습니다.");
            storeSwitch.off('FireRtrvlPrcsPrfmncChgDscntPopup');
            let routerChuck = {path: ROUTE.PERFORMANCE_LIST}; // 실적보고목록

            if(prfmncSttsCd !== "SUB") {
              routerChuck = {path: ROUTE.PERFORMANCE_DTL, query: {prfmncId: data.result.prfmncId}}; //상세화면
            }

            this.$router.push(routerChuck)
                .then(() => prfmncSttsCd !== "SUB" && location.reload())
                .catch((err) => {
                  console.error('Navigation failed:', err);
                });
          },
          () => {
            alert((prfmncSttsCd === "SUB" ? '제출' : '저장') + "에 실패하였습니다.");
          }
      );
    },
    validate(focusOnError = false) {
      // 폐기물반입현황 - 반입량합계 필수값 체크
      const wtCrynAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlWtCrynGridObject);
      const totalWtStotCryqty = wtCrynAllItems.reduce((sum, item) => {
        return sum + (item.wtStotCryqty || 0);
      }, 0);
      if(totalWtStotCryqty === 0) {
        if (focusOnError) {
          this.$refs.prfmncDtlFireRtrvlWtCrynGridTitle.focus();
        }
        return "폐기물 반입 정보를 입력해주세요.";
      }

      // 잔량이 0보다 작은 경우 체크
      /* 25-01-14 KJM 잔량 음수 값 체크 제외
      if(this.fireRtrvlDtlObj.prfmncMst.prfmncYr === '2024' && this.fireRtrvlDtlObj.prfmncFireRtrvl.wtRmnqty < 0){
        if (focusOnError) {
          this.$refs.prfmncDtlFireRtrvlWtTitle.focus();
        }
        return "투입량(+전처리량)이 반입량(+이월량)보다 초과할 수 없습니다.";
      }*/

      // 시설별 투입/생산 현황 - 소각처리량(톤) 필수값 체크
      const prdctnAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlPrdctnGridObject);
      const totalWtInpqty = prdctnAllItems.reduce((sum, item) => {
        return sum + (item.wtInpqty || 0);
      }, 0);
      if(totalWtInpqty === 0) {
        if (focusOnError) {
          this.$refs.prfmncDtlFireRtrvlPrdctnTitle.focus();
        }
        return "시설별 투입/생산 현황의 소각처리량(톤)을 입력해주세요.";
      }

      // 에너지 회수 현황 - 총 에너지 회수량(Gcal) 필수값 체크
      /* 25-01-14 KJM 에너지회수량 필수 체크 제외
      if(!this.fireRtrvlDtlObj.prfmncFireRtrvl.eneRtrqty){
        if (focusOnError) {
          this.$refs.eneRtrqtyInput.focus();
        }
        return "총 에너지 회수량(Gcal)은 필수입니다.\n시스템 투입/생산 현황의 에너지발생량(Gcal) 및 에너지회수현황의 미이용(Gcal)을 입력해주세요.";
      }*/

      return "";
    },
    checkFirePrcsPrfmc(prfmncSttsCd) {
      return new Promise((resolve, reject) => {

        const wtCrfqty = this.fireRtrvlDtlObj.prfmncFireRtrvl.wtCrfqty; // 이월량
        const wtRmnqty = this.fireRtrvlDtlObj.prfmncFireRtrvl.wtRmnqty; // 잔량

        this.$apiCall.post(
            API.USE_PROCESS_PERFORMANCE_CHANGE_CHECK,
            {
              bplcId: this.fireRtrvlDtlObj.prfmncMst.bplcId,
              prfmncYm: this.fireRtrvlDtlObj.prfmncMst.prfmncYm,
              wtCrfqty: wtCrfqty,
              wtRmnqty: wtRmnqty,
              updatePrfmncSttsCd: prfmncSttsCd,
            },
            (data) => {
              resolve(data.result);
            },
            () => {
              alert("제출에 실패하였습니다.");
              reject();
            }
        );
      });
    },
    // 자원순환정보시스템 정보적용
    rsrcRcyclnCrynInfoApply(){
      // 그리드1과 그리드2의 데이터 가져오기
      const grid1Data = window.SBGrid3.getAllItems(this.prfmncFireRtrvlWtCrynGridObject);
      const grid2Data = window.SBGrid3.getAllItems(this.prfmncFireRtrvlRsrcRcyclnCrynGridObject);

      if(!grid2Data || (grid2Data?.length || 0) === 0){
        alert("자원순환정보시스템의 반입정보가 존재하지 않아 적용할 수 없습니다.");
        return;
      }

      // 그리드2 데이터 맵 생성 (키값으로 빠르게 접근)
      const grid2DataMap = new Map(
          grid2Data.map(item => [`${item.ctpvStdgCd}_${item.sggStdgCd}`, item])
      );

      // 그리드1 데이터를 반복하면서 그리드2와 비교
      grid1Data.forEach((grid1Item) => {
        const key = `${grid1Item.ctpvStdgCd}_${grid1Item.sggStdgCd}`;

        if (grid2DataMap.has(key)) {
          // 그리드1과 그리드2에 모두 존재하는 경우: 그리드2의 데이터로 덮어쓰기
          // Object.assign(item, grid2DataMap.get(key));

          const grid2Item = grid2DataMap.get(key);

          // 그리드2에 있는 데이터로 덮어쓰기 전 변경이 있는지 확인
          const isModified = this.compareObjects(grid1Item, grid2Item);
          if (isModified) {
            this.updatedWtCrynData.push({ ...grid2Item });
            Object.assign(grid1Item, grid2Item);

          }
        }
      });

      // 그리드2에만 있는 데이터는 그리드1에 추가
      grid2Data.forEach(item => {
        const key = `${item.ctpvStdgCd}_${item.sggStdgCd}`;

        if (!grid1Data.some(grid1Item => `${grid1Item.ctpvStdgCd}_${grid1Item.sggStdgCd}` === key)) {
          // 그리드1에 존재하지 않으면 데이터 추가
          this.insertedWtCrynData.push( item );
          grid1Data.push({ ...item });
        }
      });

      window.SBGrid3.setClientData(this.prfmncFireRtrvlWtCrynGridObject, grid1Data);
    },

    compareObjects(obj1, obj2) {
      const allKeys = [...new Set([...Object.keys(obj1), ...Object.keys(obj2)])];

      const differences = allKeys.filter(key => {
        if (key === '_key_') return false; // _key_ 속성은 비교에서 제외
        return obj1[key] !== obj2[key];
      });

      if (differences.length > 0) {
        differences.forEach(key => {
          console.log(`${key}: grid1Data = ${obj1[key]}, grid2Data = ${obj2[key]}`);
        });
      }

      return differences.length > 0;
    },
    // 소수점 둘쨰자리 오류 수정
    // 부동소수점(floating point) 연산의 정밀도 문제 스크립트 고유 버그
    sanitizeFloatingPointError(value){
      if (typeof value !== "number") return value; // 숫자가 아닐 경우 반환

      // 소수점 2자리까지만 유지하고 반올림하여 부동소수점 오차 제거
      return parseFloat((Math.round(value * 100) / 100).toFixed(2));

    }
  }
}
</script>

<style scoped>

</style>