<template>
  <div class="desc_box">
    <i class="icon"><img src="@/assets/images/common/ico_desc.png" alt="이미지"></i>
    <div class="desc">
      <strong class="desc_title">폐자원에너지 종합정보관리시스템 사용 절차 안내</strong>
      <p>폐자원에너지 종합정보관리시스템은 간단한 회원가입으로 시작됩니다. 먼저 업체정보와 사용자정보를 입력하여 회원가입을 신청하시면, 폐자원에너지센터 담당자의 정보 검토와 승인 절차를 거쳐 회원가입이 완료됩니다. 이후 사업장 정보와 시설정보를 등록하여 폐자원에너지센터의 추가 승인을 받으시면, 정기검사와 품질검사를 신청하실 수 있습니다. 검사를 받은 이후 실적보고가 가능합니다.
      </p>
    </div>
  </div>
  <h2 class="title1">회원가입 이전</h2>
  <div class="system_box before">
      <ol>
          <li>
               <a role="link"><em>01</em><div>회원가입<span>(업체, 사용자 정보 작성)</span></div></a>
          </li>
          <li>
               <a role="link"><em>02</em><div>회원가입 승인 요청</div></a>
          </li>
          <li class="strong">
               <a role="link"><em>03</em><div>관리자 승인</div></a>
          </li>
      </ol>
  </div>

  <h2 class="title1">회원 승인 이후</h2>
  <div class="system_box">
      <h3>제조업체, 사용업체</h3>
      <ol>
          <li>
               <a role="link"><em>01</em><div>사업장 정보 작성</div></a>
          </li>
          <li>
               <a role="link"><em>02</em><div>사업장 승인 요청</div></a>
          </li>
          <li class="strong">
               <a role="link"><em>03</em><div>관리자 승인</div></a>
          </li>
      </ol>
      <ol>
          <li>
               <a role="link"><em>01</em><div>시설 정보 작성</div></a>
          </li>
          <li>
               <a role="link"><em>02</em><div>시설 승인 요청</div></a>
          </li>
          <li class="strong">
               <a role="link"><em>03</em><div>관리자 승인</div></a>
          </li>
          <li>
               <a role="link"><em>04</em><div>시설검사 + 품질검사</div></a>
          </li>
          <li>
               <a role="link"><em>05</em><div>실적 보고</div></a>
          </li>
          <li>
               <a role="link"><em>06</em><div>실적 현황</div></a>
          </li>
      </ol>
  </div>

  <div class="system_box">
    <h3>수입업체</h3>
    <ol>
        <li>
             <a role="link"><em>01</em><div>사업장 정보 작성</div></a>
        </li>
        <li>
             <a role="link"><em>02</em><div>사업장 승인 요청</div></a>
        </li>
        <li class="strong">
             <a role="link"><em>03</em><div>관리자 승인</div></a>
        </li>
        <li>
             <a role="link"><em>04</em><div>해외 제조시설 등록</div></a>
        </li>
        <li>
             <a role="link"><em>05</em><div>수입품질검사 신청</div></a>
        </li>
        <li class="strong">
             <a role="link"><em>06</em><div>수입품질검사 실시</div></a>
        </li>
        <li>
             <a role="link"><em>07</em><div>수입신고정보 등록</div></a>
        </li>
        <li>
             <a role="link"><em>08</em><div>환경청 수입신고</div></a>
        </li>
        <li>
             <a role="link"><em>09</em><div>실적 보고</div></a>
        </li>
    </ol>
  </div>

  <div class="system_box">
    <h3>매립가스시설, 소각(처분)시설, 소각열회수(재활용)시설, 바이오가스화시설, 폐기물가스화시설</h3>
    <ol>
        <li>
             <a role="link"><em>01</em><div>사업장 정보 작성</div></a>
        </li>
        <li>
             <a role="link"><em>02</em><div>사업장 승인 요청</div></a>
        </li>
        <li class="strong">
             <a role="link"><em>03</em><div>관리자 승인</div></a>
        </li>
    </ol>
    <ol>
        <li>
             <a role="link"><em>04</em><div>시설 정보 작성</div></a>
        </li>
        <li>
             <a role="link"><em>05</em><div>시설 승인 요청</div></a>
        </li>
        <li class="strong">
             <a role="link"><em>06</em><div>관리자 승인</div></a>
        </li>
        <li>
             <a role="link"><em>07</em><div>실적 보고</div></a>
        </li>
        <li>
             <a role="link"><em>08</em><div>실적 현황</div></a>
        </li>
    </ol>
  </div>

  <div class="system_box">
    <h3>지자체/환경청</h3>
    <ol>
        <li>
             <a role="link"><em>01</em><div>회원가입</div></a>
        </li>
        <li>
             <a role="link"><em>02</em><div>담당자 승인</div></a>
        </li>
        <li>
             <a role="link"><em>03</em><div>월간실적·검사결과 확인</div></a>
        </li>
        <li>
             <a role="link"><em>04</em><div>금지명령 및 개선이행결과 입력</div></a>
        </li>
    </ol>
  </div>

  <div class="intro_box bg1">
    <div class="cont">
      <h3>지방환경관서의 주요업무</h3>
      <ul class="dot_list">
        <li>고형연료제품의 수입(변경)신고 접수</li>
        <li>수입(변경)신고 접수사항 검토</li>
        <li>수입 신고확인증 발급 및 변경사항 수정</li>
        <li>고형연료제품의 수입(변경)신고내용, 금지명령, 개선명령 및 이행결과를 센터에 알림</li>
      </ul>
    </div>
  </div>
  <div class="intro_box bg2">
    <div class="cont">
      <h3>지방자치단체(특별자치시장, 특별자치도, 시장, 군수, 구청장)의 주요업무</h3>
      <ul class="dot_list">
        <li>고형연료제품의 제조·사용(변경)신고 접수</li>
        <li>제조·사용(변경)신고 접수사항 검토</li>
        <li>제조·사용 신고확인증 발급 및 변경사항 수정</li>
        <li>고형연료제품의 제조·사용(변경)신고내용, 금지명령, 개선명령 및 이행결과를 센터에 알림</li>
      </ul>
    </div>
  </div>
  <div class="intro_box bg3">
    <div class="cont">
      <h3>폐자원에너지센터의 주요업무</h3>
      <ul class="dot_list">
        <li>고형연료제품의 제조·사용시설 정기검사신청서의 접수 및 결과서 발급</li>
        <li>고형연료제품 품질검사신청서의 접수 및 결과서 발급(제조·수입 신고전)</li>
        <li>고형연료제품의 이송·보관·사용 중 품질검사</li>
        <li>폐자원에너지 종합정보관리시스템 운영 및 지방환경관서, 지방자치단체에 검사 결과 알림</li>
      </ul>
    </div>
  </div>
</template>

<style scoped>

</style>