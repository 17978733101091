import { createStore } from 'vuex';

export default createStore({
    state: {
        isFindAccount: false, // 계정찾기 진행여부
        findAccountStep: 0, // 계정찾기 단계
        findAccountInfo: {
            provider: '',
            findType: '', // id, pswd
            userId: '',
            bzentyYn: '',
        },
    },
    mutations: {
        START_FIND_ACCOUNT(state) {
            state.isFindAccount = true;
            state.findAccountStep = 1;
        },
        NEXT_STEP(state) {
            state.findAccountStep += 1;
        },
        SET_FIND_ACCOUNT_INFO(state, findAccountInfo) {
            state.findAccountInfo = {...state.findAccountInfo, ...findAccountInfo};
        },
        RESET_FIND_ACCOUNT(state) {
            state.isFindAccount = false;
            state.findAccountStep = 0;
            state.findAccountInfo = {
                provider: '',
                findType: '', // id, pswd
                userId: '',
                bzentyYn: '',
            };
        }
    },
    actions: {
        startFindAccount({ commit }) {
            commit('START_FIND_ACCOUNT');
        },
        nextStep({ commit }) {
            commit('NEXT_STEP');
        },
        updateFindAccountInfo({ commit }, findAccountInfo) {
            commit('SET_FIND_ACCOUNT_INFO', findAccountInfo);
        },
        resetFindAccount({ commit }) {
            commit('RESET_FIND_ACCOUNT');
        }
    },
    getters: {
        getIsFindAccount: (state) => state.isFindAccount,
        getFindAccountStep: (state) => state.findAccountStep,
        getFindAccountInfo: (state) => state.findAccountInfo,
    }
});