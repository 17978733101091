<template>
  <div class="chat_frm" v-show="isActive">
    <div class="botA">
      <button type="button" @click="hide">e챗봇 닫기
    </button></div>
    <iframe title="챗봇" src="https://frogue.danbee.ai/toad?chatbot_id=75687d8e-4451-4392-88a1-db7cba5ae276&force_welcome=Y"
            allow="microphone; autoplay" allowusermedia="true"
            style="z-index: 1000000001; position: fixed; right: 110px; bottom: 40px; width: 420px; height: 720px; border-radius: 20px; border: 0; box-shadow: rgba(79, 98, 114, 0.2) 0px 6px 60px 0px;"></iframe>
  </div>
</template>

<script>
import {storeSwitch} from "@/js/store/store-switch";

export default {
  data() {
    return {
      isActive: false
    };
  },
  created() {
    storeSwitch.setBulb('chatBot', this);
  },
  mounted() {
    // 스크립트를 동적으로 추가
    this.init(document, "script", "frogue-embed");
  },
  methods: {
    init(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }

      js = d.createElement(s);
      js.id = id;
      js.src = "https://frogue.danbee.ai/js/embed/v20220906/frogue-embed.min.js";

      fjs.parentNode.insertBefore(js, fjs);
    },
    show() {
      this.isActive = true;
    },
    hide() {
      this.isActive = false
    },
  }
};
</script>