<template>
    <h2 class="title1 mt_0">품질검사 신청</h2>
    <div class="board_write">
        <p class="t_caption"><span class="necessary">(<i>※</i>)</span> 필수 입력항목입니다.</p>
        <table>
            <caption>
                신청정보_시설종류, 시설구분, 시설 명, 위탁업체 명, 품질표시검사항목, 검사구분, 건조시설유무, 품질표시검사여부, 품질표시검사항목, 입금(예정)일자,
                입금(예정)자명,
                선입금여부, 검사희망일자, 첨부파일로 구성
            </caption>
            <colgroup>
                <col style="width:18%">
                <col style="width:32%">
                <col style="width:18%">
                <col style="width:32%">
            </colgroup>
            <tbody>
                <tr>
                    <th scope="row">사업장 종류</th>
                    <td>{{ this.qltyInspFcltInfo.bplcKndNm }}</td>
                    <th scope="row">사업장 구분</th>
                    <td v-if="this.qltyInspFcltInfo.pubBzentyYn === 'Y'">공공시설</td>
                    <td v-else>민간시설</td>
                </tr>
                <tr>
                    <th scope="row">사업장 명</th>
                    <td>{{ this.qltyInspFcltInfo.bplcNm }}</td>
                    <th scope="row">위탁업체명</th>
                    <td>{{ this.qltyInspFcltInfo.cnsgnBzentyNm }}</td>
                </tr>
                <tr>
                    <th scope="row">제품 종류</th>
                    <td colspan="3">{{ this.qltyInspFcltInfo.prdctnPrdctKndNm }}</td>
                </tr>
                <tr>
                    <th scope="row">품질검사항목</th>
                    <td colspan="3">
                        <div class="chk_area">
                            <div class="form_check">
                              <br-form-radio label="품질검사항목" 
                                              :options="this.qltyInspArtclOpt"
                                              v-model:value="this.qltyInspAply.qltyInspArtclCd"
                              />
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th scope="row">검사구분</th>
                    <td v-if="this.parentData.inspSeCd === '20-30'">개선명령</td>
                    <td v-else-if="this.parentData.inspSeCd === '20-40'">변경검사</td>
                    <td v-else>최초검사</td>
                    <th scope="row">건조시설유무</th>
                    <td v-if="this.qltyInspFcltInfo.dhmdfyFcltHldYn === 'Y'">유</td>
                    <td v-else>무</td>
                </tr>
                <tr>
                    <th scope="row">품질표시검사여부</th>
                    <td colspan="3">
                        <div class="chk_area">
                            <!-- <div class="form_check">
                                <input type="radio" name="qltyIndctInspY" id="qltyIndctInspY" value="Y" v-model="this.qltyInspAply.qltyIndctInspYn">
                                <label for="qltyIndctInspY">신청</label>
                            </div>
                            <div class="form_check">
                                <input type="radio" name="qltyIndctInspN" id="qltyIndctInspN"  value="N" v-model="this.qltyInspAply.qltyIndctInspYn">
                                <label for="qltyIndctInspN">미신청</label>
                            </div> -->
                            <div class="form_check">
                              <br-form-radio label="품질표시검사여부" 
                                              :options="this.qltyIndctInspYnOpt"
                                              v-model:value="this.qltyInspAply.qltyIndctInspYn"
                                              :disabled="this.qltyIndctInspYnDisabled"
                              />
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th scope="row"><i class="necessary">필수입력</i>검사항목</th>
                    <td colspan="3">
                        <div class="form_check">
                            <input type="checkbox" name="inspCpstCdAll" id="inspCpstCdAll" value="chkAll" v-model="allSelected" >
                            <label for="inspCpstCdAll">전체</label>
                        </div>
                        <!-- 퍼블 디자인을 유지하기 위해 체크박스 컴포넌트 사용하지 않음 -->
                        <table>
                          <caption>검사항목</caption>
                          <colgroup>
                              <col style="width:9%">
                              <col style="width:91%">
                          </colgroup>
                          <tbody>
                              <tr>
                                <th scope="row">품질검사</th>
                                <td>
                                  <div class="chk_area">
                                      <div class="form_check">
                                          <input type="checkbox" name="inspCpstCd150" id="inspCpstCd150" value="150" v-model="this.qltyInspAply.inspCpstCdList">
                                          <label for="inspCpstCd150">모양 및 크기</label>
                                      </div>
                                      <div class="form_check">
                                          <input type="checkbox" name="inspCpstCd160" id="inspCpstCd160" value="160" v-model="this.qltyInspAply.inspCpstCdList">
                                          <label for="inspCpstCd160">수분(wt.%)</label>
                                      </div>
                                      <div class="form_check">
                                          <input type="checkbox" name="inspCpstCd180" id="inspCpstCd180" value="180" v-model="this.qltyInspAply.inspCpstCdList">
                                          <label for="inspCpstCd180">(저위)발열량(kcal/kg)</label>
                                      </div>
                                      <div class="form_check">
                                          <input type="checkbox" name="inspCpstCd170" id="inspCpstCd170" value="170" v-model="this.qltyInspAply.inspCpstCdList">
                                          <label for="inspCpstCd170">회분(wt.%)</label>
                                      </div>
                                      <div class="form_check">
                                          <input type="checkbox" name="inspCpstCd140" id="inspCpstCd140" value="140" v-model="this.qltyInspAply.inspCpstCdList">
                                          <label for="inspCpstCd140">염소(wt.%)</label>
                                      </div>
                                      <div class="form_check">
                                          <input type="checkbox" name="inspCpstCd130" id="inspCpstCd130" value="130" v-model="this.qltyInspAply.inspCpstCdList">
                                          <label for="inspCpstCd130">항분(wt.%)</label>
                                      </div>
                                      <div class="form_check">
                                          <input type="checkbox" name="inspCpstCd190" id="inspCpstCd190" value="190" v-model="this.qltyInspAply.inspCpstCdList" :disabled="inspCpstBioMassDisabled">
                                          <label for="inspCpstCd190">바이오매스(wt.%)</label>
                                      </div>                            
                                      <div class="form_check">
                                          <input type="checkbox" name="inspCpstCd80" id="inspCpstCd80" value="80" v-model="this.qltyInspAply.inspCpstCdList">
                                          <label for="inspCpstCd80">수은(Hg)</label>
                                      </div>
                                      <div class="form_check">
                                          <input type="checkbox" name="inspCpstCd90" id="inspCpstCd90" value="90" v-model="this.qltyInspAply.inspCpstCdList">
                                          <label for="inspCpstCd90">카드뮴(Cd)</label>
                                      </div>
                                      <div class="form_check">
                                          <input type="checkbox" name="inspCpstCd100" id="inspCpstCd100" value="100" v-model="this.qltyInspAply.inspCpstCdList">
                                          <label for="inspCpstCd100">납(Pb)</label>
                                      </div>
                                      <div class="form_check">
                                          <input type="checkbox" name="inspCpstCd110" id="inspCpstCd110" value="110" v-model="this.qltyInspAply.inspCpstCdList">
                                          <label for="inspCpstCd110">비소(As)</label>
                                      </div>
                                      <div class="form_check">
                                          <input type="checkbox" name="inspCpstCd200" id="inspCpstCd200" value="200" v-model="this.qltyInspAply.inspCpstCdList">
                                          <label for="inspCpstCd200">기타항목</label>
                                          <input type="text" name="etcArtclNm" id="etcArtclNm" maxlength="50" v-model="this.qltyInspAply.etcArtclNm"/>
                                          <label for="etcArtclNm" style="display:none;">기타항목</label>
                                      </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">품질표시검사</th>
                                <td>
                                  ※ 바이오 고형연료제품에 대한 품질검사인 경우 크로뮴(Cr)은 품질검사 항목에 해당될 수 있습니다.
                                  <div class="chk_area">
                                    <div class="form_check">
                                      <input type="checkbox" name="inspCpstCd120" id="inspCpstCd120" value="120" v-model="this.qltyInspAply.inspCpstCdList">
                                      <label for="inspCpstCd120">크로뮴(Cr)</label>
                                    </div>
                                    <div class="form_check">
                                      <input type="checkbox" name="inspCpstCd10" id="inspCpstCd10" value="10" v-model="this.qltyInspAply.inspCpstCdList">
                                      <label for="inspCpstCd10">안티몬(Sb)</label>
                                    </div>
                                    <div class="form_check">
                                      <input type="checkbox" name="inspCpstCd20" id="inspCpstCd20" value="20" v-model="this.qltyInspAply.inspCpstCdList">
                                      <label for="inspCpstCd20">코발트</label>
                                    </div>
                                    <div class="form_check">
                                      <input type="checkbox" name="inspCpstCd40" id="inspCpstCd40" value="40" v-model="this.qltyInspAply.inspCpstCdList">
                                      <label for="inspCpstCd40">망간(Mn)</label>
                                    </div>
                                    <div class="form_check">
                                      <input type="checkbox" name="inspCpstCd50" id="inspCpstCd50" value="50" v-model="this.qltyInspAply.inspCpstCdList">
                                      <label for="inspCpstCd50">니켈(Ni)</label>
                                    </div>
                                    <div class="form_check">
                                      <input type="checkbox" name="inspCpstCd60" id="inspCpstCd60" value="60" v-model="this.qltyInspAply.inspCpstCdList">
                                      <label for="inspCpstCd60">탈륨(Tl)</label>
                                    </div>
                                    <div class="form_check">
                                      <input type="checkbox" name="inspCpstCd70" id="inspCpstCd70" value="70" v-model="this.qltyInspAply.inspCpstCdList">
                                      <label for="inspCpstCd70">바나듐(V)</label>
                                    </div>
                                    <div class="form_check">
                                      <input type="checkbox" name="inspCpstCd30" id="inspCpstCd30" value="30" v-model="this.qltyInspAply.inspCpstCdList">
                                      <label for="inspCpstCd30">구리(Cu)</label>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                          </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <th scope="row">입금(예정)일자</th>
                    <td>
                      <br-date-picker label="입금(예정)일자" v-model:value="this.qltyInspAply.dpstPrnmntYmd" />
                    </td>
                    <th scope="row"><i class="necessary">필수입력</i>입금(예정)자 명</th>
                    <td>
                        <div class="input_group">
                            <br-form-input label="입금(예정)자 명" placeholder="입금(예정)자 명" 
                                            v-model="this.qltyInspAply.pyrNm"
                                            :maxlength="30"
                                            panel="panel1"
                                            required
                            />
                            <div class="form_check">
                                <!-- <input type="checkbox" name="dpstYn" id="dpstYn" v-model="this.dpstYn"> -->
                                <input type="checkbox" name="dpstYn" id="dpstYn" v-model="this.isCheckedDpstYn">
                                <label for="dpstYn">선입급여부</label>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>검사희망일자</th>
                    <td colspan="3">
                        <br-date-picker label="검사희망일자" futureonly :futureDaysOffset="15" v-model:value="this.qltyInspAply.inspHopeYmd" panel="panel1" :disableWeekends="true" required/>
                        <span class="txt_hint">15일 이후로 입력</span>
                    </td>
                </tr>
                <tr>
                    <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>첨부파일</th>
                    <td colspan="3">
                        <div class="file_upload_list">
                          <template v-if="isAtchFileShow">
                            <li>
                              <br-attach-file-list label="품질검사 사업장 첨부파일 목록"
                                                        ref="attachFileList"
                                                        atchFileTaskSeCd="BPCA" 
                                                        v-model:atchFileGroupId="qltyInspFcltInfo.bplcAtchFileGroupId"
                                                        :textAreaWidth="fileTextAreaWidth"
                                                        :readonly="true" 
                                                        :useEtcFile="false"
                                                        :unusedAtchFileList="['002', '049', '007', '008', '053', '054']" 
                                      /> 
                            </li>
                            <li>
                                <br-attach-file-list label="품질검사 업체 첨부파일 목록"
                                                        ref="attachFileList"
                                                        atchFileTaskSeCd="BET" 
                                                        v-model:atchFileGroupId="qltyInspFcltInfo.bzentyAtchFileGroupId"
                                                        :textAreaWidth="fileTextAreaWidth"
                                                        :readonly="true" 
                                                        :useEtcFile="false"
                                /> 
                            </li>
                            <dl class="list">
                                <dt><i class="necessary">※<span class="sr_only">필수입력</span></i>고형연료제품 품질검사 신청서</dt>
                                <dd>
                                    <!-- <div class="file_box"> -->
                                      <br-attach-file label="고형연료제품 품질검사 신청서"
                                                    atchFileTaskSeCd="QLT"
                                                    v-model:atchFileGroupId="this.qltyInspAply.inspAplyAtchFileGroupId"
                                                    v-model:fileProcessingData="this.fileProcessingData"
                                                    atchFileSeCd="015"
                                                    panel="panel1"
                                                    required
                                                    />
                                    <!-- </div> -->
                                </dd>
                            </dl>
                            <dl class="list">
                                <dt><i class="necessary">※<span class="sr_only">필수입력</span></i>고형연료제품 제조시설 일반 명세서</dt>
                                <dd>
                                    <!-- <div class="file_box"> -->
                                      <br-attach-file label="고형연료제품 제조시설 일반 명세서"
                                                    atchFileTaskSeCd="QLT"
                                                    v-model:atchFileGroupId="this.qltyInspAply.inspAplyAtchFileGroupId"
                                                    v-model:fileProcessingData="this.fileProcessingData"
                                                    atchFileSeCd="009"
                                                    panel="panel1"
                                                    required
                                                    />
                                    <!-- </div> -->
                                </dd>
                            </dl>
                            <dl class="list" v-if="this.parentData.inspSeCd === '20-30'">
                                <dt style="width:260px;"><i class="necessary">※<span class="sr_only">필수입력</span></i>관할 지자체의 개선명령 공문</dt>
                                <dd>
                                    <!-- <div class="file_box"> -->
                                      <br-attach-file label="관할 지자체의 개선명령 공문"
                                                    atchFileTaskSeCd="QLT"
                                                    v-model:atchFileGroupId="this.qltyInspAply.inspAplyAtchFileGroupId"
                                                    v-model:fileProcessingData="this.fileProcessingData"
                                                    atchFileSeCd="036"
                                                    panel="panel1"
                                                    required
                                                    />
                                    <!-- </div> -->
                                </dd>
                            </dl>
                          </template>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="btn_area right">
        <br-button label="신청"
                  :class="'btn md primary line'"
                  @click="save('10')" 
                  panel="panel1"
                  validate 
        />
        <br-button label="임시 저장"
                  :class="'btn md primary line'"
                  @click="save('00')" 
                  panel="panel1"
                  validate 
        />
        <a role="link" href="javascript:void(0);" class="btn md point" @click="cancel">취소</a>
    </div>
</template>

<script>
// import { storeSnackbar } from "@/js/store/store-snackbar";
import { storeSwitch } from "@/js/store/store-switch";
// import { storeComponent } from "@/js/store/store-component";


const API = {
    QLTY_INSP_APLY_FORM: "/qltyInspAply/form",
    QLTY_INSP_APLY: "/qltyInspAply/aply",
}

// const CODE = {
// };

export default {
  components: {},
  emits: ['qlty-insp-aply-list'],
  expose: ['reset'],
  props: {
    parentData: {
        type: Object,
        required: true,
    }
  },
  data() {
    return {
    //   menu: this.$menusTotal.getRouteMenu(this.$route.path),
      qltyInspArtclOpt: [
        {value: "01-00-00", text: "전체"},
        {value: "02-00-00", text: "수분항목만"},
      ],
      qltyIndctInspYnOpt: [
        {value: "Y", text: "신청"},
        {value: "N", text: "미신청"},
      ],
      qltyIndctInspYnDisabled: false,
      inspCpstOtions: [
        {value: "10", text: "안티몬(Sb)"},
        {value: "20", text: "코발트(Co)"},
        {value: "30", text: "구리(Cu)"},
        {value: "40", text: "망간(Mn)"},
        {value: "50", text: "니켈(Ni)"},
        {value: "60", text: "탈륨(Tl)"},
        {value: "70", text: "바나듐(V)"},
        {value: "80", text: "수은(Hg)"},
        {value: "90", text: "카드뮴(Cd)"},
        {value: "100", text: "납(Pb)"},
        {value: "110", text: "비소(As)"},
        {value: "120", text: "크로뮴(Cr)"},
        {value: "130", text: "황분 함유량(wt.%)"},
        {value: "140", text: "염소 함유량(wt.%)"},
        {value: "150", text: "모양 및 크기"},
        {value: "160", text: "수분 함유량(wt.%)"},
        {value: "170", text: "회분 함유량(wt.%)"},
        {value: "180", text: "(저위)발열량(kcal/kg)"},
        {value: "190", text: "바이오매스(wt.%)"},
        {value: "200", text: "기타 항목"},
      ],
      inspCpstDisabled: false,
      isRequiredForEtcArtclNm: false,
      isCheckedDpstYn: false,
      //검사신청정보
      qltyInspAply: {
          bplcId: "",
          fcltSqno: "",
          fcltInspAplySn: "",
          qltyInspArtclCd: "01-00-00",
          inspClsfCd: "20",
          inspSeCd: "20-10",
          qltyIndctInspYn: "Y",
          inspCpstCdList: [],
          etcArtclNm: "",
          dpstPrnmntYmd: "",
          pyrNm: "",
          dpstYn: "N",
          inspHopeYmd: "",
          qltyInspItemCd: "",
          inspAplyAtchFileGroupId: "",
          inspAplyYmd: "",
          fileProcessingData: {},
      },
      //사업장 및 시설
      qltyInspFcltInfo: {
          bplcId: "",
          bplcKndCd: "",
          bplcKndNm: "",
          bplcNm: "",
          pubBzentyYn: "",
          bzentyId: "",
          bzentyNm: "",
          cnsgnBzentyNm: "",
          fcltSqno: "",
          prdctnPrdctKndCd: "",
          prdctnPrdctKndNm: "",
          dhmdfyFcltHldYn: "",
          bzentyAtchFileGroupId: "",
          bplcAtchFileGroupId: "",
          inspPrgrsSttsCd: "",
      },
      fileProcessingData: {},
      isAtchFileShow: true,
      fileAllowedExtensions: ['jpg', ' jpeg', ' gif', ' png', ' tif', ' bmp', ' hwp', ' pdf', ' xlsx', ' xls', ' doc', ' docx', ' ppt', ' pptx'],
      fileTextAreaWidth: "260px", 
      inspCpstBioMassDisabled: false,
    };
  },
  computed: {
    allSelected: {
      //getter
      get: function() {
        // return this.inspCpstOtions.length === this.qltyInspAply.inspCpstCdList.length;

        //고형연료 품질검사인경우 제외되는 성분
        const excludeForSrf = ["10", "20", "30", "40", "50", "60", "70", "120", "190"];
        //고형연료 품질표시검사
        const excludeForSrfAndQltyIndct = ["190"];
        //바이오고형연료
        const excludeForBioSrf = ["10", "20", "30", "40", "50", "60", "70"];
        //고형연료 코드
        const srfCdList = ["0001", "0002"];
        //바이오고형연료 코드
        const bioSrfCdList = ["0003", "0004"];

        let inspCpstOptionsLength = this.inspCpstOtions.length;
        let inspCpstCdListLength = 0;
        
        if(this.qltyInspAply.qltyInspArtclCd == '02-00-00'){ //수분항목만
          inspCpstOptionsLength = this.inspCpstOtions.filter(item => item.value === "160" || item.value === "200").length;
        }else if(this.qltyInspAply.qltyIndctInspYn == 'Y'){ //품질표시검사 여부
          if(srfCdList.includes(this.qltyInspFcltInfo.prdctnPrdctKndCd)){ //고형연료
            inspCpstOptionsLength = this.inspCpstOtions.filter(item => !excludeForSrfAndQltyIndct.includes(item.value)).length;
          }else if(bioSrfCdList.includes(this.qltyInspFcltInfo.prdctnPrdctKndCd)){  //바이오고형연료
            inspCpstOptionsLength = this.inspCpstOtions.length;
          }
        }else{
          if(srfCdList.includes(this.qltyInspFcltInfo.prdctnPrdctKndCd)){ //고형연료
            inspCpstOptionsLength = this.inspCpstOtions.filter(item => !excludeForSrf.includes(item.value)).length;
          }else if(bioSrfCdList.includes(this.qltyInspFcltInfo.prdctnPrdctKndCd)){  //바이오고형연료
            inspCpstOptionsLength = this.inspCpstOtions.filter(item => !excludeForBioSrf.includes(item.value)).length;
          }
        }

        if(this.qltyInspAply.inspCpstCdList != null && this.qltyInspAply.inspCpstCdList != undefined){
          inspCpstCdListLength = this.qltyInspAply.inspCpstCdList.length;
        }

        return inspCpstOptionsLength === inspCpstCdListLength;

      },
      //setter
      set: function(e) {
        // this.qltyInspAply.inspCpstCdList = e ? this.inspCpstOtions.map(item => item.value) : [];
        if(e){
          //검사항목 자동 세팅
          this.setInspCpstCd(this.qltyInspFcltInfo.prdctnPrdctKndCd);
          this.qltyInspAply.inspCpstCdList.push("200");
        }else{
          this.qltyInspAply.inspCpstCdList = [];
        }

      },
    },
    // qltyInspAplyObj() {
    //     // Local Storage에서 데이터 불러오기
    //     const qltyInspAplyObj = localStorage.getItem('qltyInspAplyObj');
    //     return qltyInspAplyObj ? JSON.parse(qltyInspAplyObj) : {};
    // },
  },
  watch: {
    //품질검사항목
    "qltyInspAply.qltyInspArtclCd": function(val){
        const bExecSetInspCpst = this.qltyInspAply.qltyIndctInspYn === "N" ? true : false;

        this.qltyInspAply.qltyIndctInspYn = (val === "02-00-00") ? "N" : "Y";
        this.qltyIndctInspYnDisabled = (val === "02-00-00") ? true : false;

        //품질확인검사여부가 이미 N으로 세팅되어 있으면 검사항목을 자동세팅하도록 한다.
        if(bExecSetInspCpst){
          //검사항목 자동 세팅
          this.setInspCpstCd(this.qltyInspFcltInfo.prdctnPrdctKndCd);
        }
    },
    //품질표시검사여부
    "qltyInspAply.qltyIndctInspYn": function(val){
      this.inspCpstDisabled = (val === "N") ? true : false;
      this.qltyInspAply.inspCpstCdList = [];
      this.qltyInspAply.etcArtclNm = "";

      //검사항목 자동 세팅
      this.setInspCpstCd(this.qltyInspFcltInfo.prdctnPrdctKndCd);
    },
    "qltyInspAply.inspCpstCdList": function(){
      this.isRequiredForEtcArtclNm = this.qltyInspAply.inspCpstCdList.includes("200");
    },
    isCheckedDpstYn: function(val){
      this.qltyInspAply.dpstYn = val ? "Y" : "N";
    },
  },
  created() {
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeUnmount() {
    // 컴포넌트 전체 데이터 저장하기
    // storeComponent.store(this.menu, this);
  },
  methods: {
    async initialize() {

      this.qltyIndctInspYnDisabled = false;
      this.inspCpstDisabled = false;
      this.isRequiredForEtcArtclNm = false;
      this.isCheckedDpstYn = false;
      this.qltyInspAply = {
          bplcId: "",
          fcltSqno: "",
          fcltInspAplySn: "",
          qltyInspArtclCd: "01-00-00",
          inspClsfCd: "20",
          inspSeCd: "",
          qltyIndctInspYn: "Y",
          inspCpstCdList: [],
          etcArtclNm: "",
          dpstPrnmntYmd: "",
          pyrNm: "",
          dpstYn: "N",
          inspHopeYmd: "",
          qltyInspItemCd: "",
          inspAplyAtchFileGroupId: "",
          inspAplyYmd: "",
          fileProcessingData: {},
      };
      this.qltyInspFcltInfo = {
          bplcId: "",
          bplcKndCd: "",
          bplcKndNm: "",
          bplcNm: "",
          pubBzentyYn: "",
          bzentyId: "",
          bzentyNm: "",
          cnsgnBzentyNm: "",
          fcltSqno: "",
          prdctnPrdctKndCd: "",
          prdctnPrdctKndNm: "",
          dhmdfyFcltHldYn: "",
          bzentyAtchFileGroupId: "",
          bplcAtchFileGroupId: "",
          inspPrgrsSttsCd: "",
      };
      this.fileProcessingData = {};
      this.files = null;
      //스크롤 최상단으로 이동
      window.scrollTo({top: 0});
    },
    read() {
      this.$apiCall.post(API.QLTY_INSP_APLY_FORM
                            , this.parentData
                            , this.success
                            , this.error);
    },
    success(data) {
      if (!data.result) {
        this.error();
        return;
      }
      
      this.qltyInspFcltInfo = data.result.inspFcltDetail;

      //사업장 시설정보의 생상제품코드를 신청정보에 입력
      this.qltyInspAply.qltyInspItemCd = this.qltyInspFcltInfo.prdctnPrdctKndCd;

      //검사항목 자동 세팅
      this.setInspCpstCd(this.qltyInspFcltInfo.prdctnPrdctKndCd);
    },
    error() {
      storeSwitch.alert('warning', "데이터를 가져오는 중 오류가 발생했습니다.");
    },
    gotoList(){
      this.isAtchFileShow = false;
      this.$emit('qlty-insp-aply-list', null);
    },
    reset() {
      this.isAtchFileShow = true;
      
      this.initialize();

      //전달받은 파라미터 set
      // this.qltyInspAply.bplcId = this.qltyInspAplyObj.bplcId == null ? this.parentData.bplcId : this.qltyInspAplyObj.bplcId;
      // this.qltyInspAply.fcltSqno = this.qltyInspAplyObj.fcltSqno == null ? this.parentData.fcltSqno : this.qltyInspAplyObj.fcltSqno;
      // this.qltyInspAply.fcltInspAplySn = this.qltyInspAplyObj.fcltInspAplySn == null ? this.parentData.fcltInspAplySn : this.qltyInspAplyObj.fcltInspAplySn;

      this.qltyInspAply.bplcId = this.parentData.bplcId;
      this.qltyInspAply.fcltSqno = this.parentData.fcltSqno;
      this.qltyInspAply.fcltInspAplySn = this.parentData.fcltInspAplySn;
      this.qltyInspAply.inspSeCd = this.parentData.inspSeCd;

      if(this.parentData.inspSeCd == "20-30"){
        this.qltyInspAply.qltyInspArtclCd = "02-00-00";
      }

      //사업장 및 시설 상세 정보 조회
      this.read();
    },
    save(sttsCd){

      const msg = sttsCd === "00" ? "신청서를 임시저장하시겠습니까?\n품질검사현황에서 품질검사신청을 할 수 있습니다." : "품질검사 신청을 진행하시겠습니까?";
      
      if(this.qltyInspAply.inspCpstCdList == null || this.qltyInspAply.inspCpstCdList.length == 0){
        alert("검사항목을 선택하여 주십시오.");
        return;
      }

      if(this.isRequiredForEtcArtclNm && this.qltyInspAply.etcArtclNm == "" ){
        alert("품질검사항목 중 선택된 기타항목의 상세내용을 입력하여주십시오.");
        return;
      }

      if(confirm(msg)) {
        
        //검사신청이면 검사신청일자 세팅
        if(sttsCd === "10"){
          let date = new Date();
          this.qltyInspAply.inspAplyYmd = date.toISOString().slice(0, 10).replace(/-/g, "");
        }

        //검사진행상태코드 세팅
        this.qltyInspAply.inspPrgrsSttsCd = sttsCd;
        //첨부파일 승인처리를 위해 파일정보 세팅
        this.qltyInspAply.fileProcessingData = this.fileProcessingData;

        //검사신청 또는 임시저장 처리
        this.$apiCall.post(
            API.QLTY_INSP_APLY,
            {...this.qltyInspAply},
            () => {
              //this.goToQltyInspStatus(sttsCd)
              const msg = sttsCd === "00" ? "품질검사 신청정보가 임시저장되었습니다." : "검사신청이 완료되었습니다.\n검사결과의 진행상태는 알림톡을 통해 안내됩니다.";
              alert(msg);
              this.$router.push("/sfli/QltyInspStat");
            },
            () => {
              // this.saveErr;
              alert("품질검사 신청정보 저장 중 오류가 발생했습니다.");
            }
        );

      }
      
    },
    goToQltyInspStatus(sttsCd){
      const msg = sttsCd === "00" ? "품질검사 신청정보가 임시저장되었습니다." : "검사신청이 완료되었습니다.\n검사결과의 진행상태는 알림톡을 통해 안내됩니다.";
      alert(msg);

      //임시로 목록으로 가도록 함.. 추후 현황 목록 화면으로 이동하도록 변경 예정
      //this.gotoList();
      this.$router.push("/sfli/QltyInspStat");
    },
    saveErr(){
      alert("품질검사 신청정보 저장 중 오류가 발생했습니다.");
    },
    cancel(){

      if(!confirm("작성중인 품질검사 신청을 취소하시겠습니까?")){
        return false;
      }

      this.gotoList();
    },
    setInspCpstCd(prdctnPrdctKndCd){
      this.qltyInspAply.inspCpstCdList = [];

      ///바이오매스 검사항목 활성화여부
      this.inspCpstBioMassDisabled = (prdctnPrdctKndCd == '0003' || prdctnPrdctKndCd == '0004') ? false : true;

      if( this.qltyInspAply.qltyInspArtclCd == "02-00-00"){
        //품질검사항목이 "수분항목만"일 경우 수분만 자동 선택
        this.qltyInspAply.inspCpstCdList.push("160");
        return;
      }

      if(this.qltyInspAply.qltyIndctInspYn == 'Y'){
        if(prdctnPrdctKndCd == '0001' || prdctnPrdctKndCd == '0002'){ //고형연료
          this.qltyInspAply.inspCpstCdList = this.inspCpstOtions.filter(item => item.value !== "190" && item.value !== "200")
                                                 .map(item => item.value);
        }else if(prdctnPrdctKndCd == '0003' || prdctnPrdctKndCd == '0004'){ //바이오 고형연료
          this.qltyInspAply.inspCpstCdList = this.inspCpstOtions.filter(item => item.value !== "200").map(item => item.value);
        }else{
          this.qltyInspAply.inspCpstCdList = this.inspCpstOtions.map(item => item.value);
        }
      }else{
        if(prdctnPrdctKndCd == '0001' || prdctnPrdctKndCd == '0002'){ //고형연료
          this.qltyInspAply.inspCpstCdList = this.inspCpstOtions
                                                 .filter(item => item.value !== "200"
                                                                 && item.value !== "190"
                                                                 && item.value !== "120"
                                                                 && item.value !== "10"
                                                                 && item.value !== "20"
                                                                 && item.value !== "40"
                                                                 && item.value !== "50"
                                                                 && item.value !== "60"
                                                                 && item.value !== "70"
                                                                 && item.value !== "30")
                                                 .map(item => item.value);
        }else if(prdctnPrdctKndCd == '0003' || prdctnPrdctKndCd == '0004'){ //바이오 고형연료
          this.qltyInspAply.inspCpstCdList = this.inspCpstOtions
                                                 .filter(item => item.value !== "10"
                                                                 && item.value !== "20"
                                                                 && item.value !== "40"
                                                                 && item.value !== "50"
                                                                 && item.value !== "60"
                                                                 && item.value !== "70"
                                                                 && item.value !== "30"
                                                                 && item.value !== "200"
                                                                 )
                                                 .map(item => item.value);
        }else{
          this.qltyInspAply.inspCpstCdList = this.inspCpstOtions.map(item => item.value);
        }
        
      }


    },
  }
}
</script>