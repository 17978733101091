<template>
  <div v-if="isVisible" class="modal visible">
    <div class="popup_box" tabindex="0">
      <h1 class="popup_title">본인 인증</h1>
      <div class="popup_cont">
        <div class="desc_box progress_box">
          <i class="icon"><img src="@/assets/images/common/ico_noti.png" alt="이미지"></i>
          <div class="progress">
            <div>본인인증이 필요합니다. 본인인증을 완료해주세요</div>
          </div>
        </div>
        <div class="btn_area center">
          <br-button :label="label" format="primary" size="md" @click="identityAuth" :disabled="disabled"/>
        </div>
      </div>
      <button type="button" class="modal_close" @click="hide">팝업 닫기</button>
    </div>
  </div>
</template>
<script>
import {v4 as uuidv4} from "uuid";
import {storeSwitch} from "@/js/store/store-switch";

const ROUTE = {
  MAIN: "/",
};

const API = {
  LOGIN_USER: "/auth/login-user"
}

export default {
  props: {
    isPopup : {type : Boolean, default : true},
    hasLogin: {type: Boolean, default: false},
    usageCode: {type: String, required: true},
    /*
    01001 : 회원가입,
    01002 : 정보변경,
    01003 : ID찾기,
    01004 : 비밀번호찾기,
    01005 : 본인확인용,
    01006 : 성인인증,
    01007 : 상품구매/결제,
    01999 : 기타
    */
  },
  emits: [ 'callback', 'fail' ],
  data() {
    return {
      isVisible: false,
      label : "본인인증 하러가기",
      disabled : false,
      uuid : uuidv4(),
      isReToMain: false,
      result: {}
    };
  },
  created() {
    storeSwitch.setBulb('IdentityAuthModal', this);
  },
  mounted() {
    window.identityAuthResult = this.identityAuthResult;
    this.init();
  },
  methods : {
    identityAuth() {
      window.MOBILEOK.process(`/api/mok/request?usageCode=${this.usageCode}&code=${this.uuid}`, "WB", "identityAuthResult");
      this.process();
    },
    process() {
      this.label = "인증을 완료해주세요";
      this.disabled = true;
    },
    init() {
      this.label = "본인인증 하러가기"
      this.disabled = false;
    },
    identityAuthResult(value) {
      const data = JSON.parse(value);
      const code = data.code;
      const message = data.message;

      if (code === 0 && this.hasLogin) {
        this.result = data.result;
        this.loginUser();
        return;
      }

      if (code === 0) {
        this.isReToMain = false;
        this.$emit("callback", data.result);
        this.init();
        this.hide();
        return;
      }

      console.error(code + " " + message);
      alert("본인인증에 실패하였습니다.");

      this.$emit("fail", data);
      this.init();
    },
    async show(param) {
      this.init();
      this.popupShow(param);

      if (this.isPopup === false) {
        this.identityAuth();
      }
    },
    hide() {
      if (this.isPopup === false) return;

      this.isVisible = false;
      document.documentElement.style.overflow = '';

      // isReToMain 값이 true일 경우 메인 화면으로 이동
      if (this.isReToMain) {
        this.$router.push(ROUTE.MAIN).catch(() => {});
      }
    },
    popupShow(param) {
      if (this.isPopup === false) return;

      document.documentElement.style.overflow = 'hidden';

      if (param && param.isReToMain !== undefined) {
        this.isReToMain = param.isReToMain;
      }

      this.isVisible = true;
    },
    loginUser() {
      this.$apiCall.post(API.LOGIN_USER, { "provider": this.result.provider }, this.loginUserSuccess, this.loginUserFail);
    },
    loginUserSuccess(data) {
      this.$emit("callback", { ...this.result, ...data.result });
    },
    loginUserFail(error) {
      alert(error.message);
      this.$emit("fail", this.result);
    }
  }
}
</script>
