<template>
  <div v-if="isOn" class="modal visible" style="z-index: 1000000001;">
    <div class="modal_box" tabindex="0">
      <div class="modal_content">
        <div class="logout_time_info">
          <i><img src="@/assets/images/sub/img_logout_time_info.png" alt="로그아웃 타임 이미지"></i>
          <strong>요청을 처리중입니다. 잠시만 기다려주세요!</strong>
        </div>
      </div>
      <div class="btn_area center">
        <button type="button" class="btn md" @click.stop="hide">닫기</button>
      </div>
    </div>
  </div>
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";

export default {
  components: {},
  data() {
    return {
      isOn: false,
    };
  },
  created() {
    storeSwitch.setBulb('pendingAlert', this);
  },
  methods: {
    show() {
      this.isOn = true;
    },
    hide() {
      this.isOn = false;
    }
  }
}
</script>

<style scoped>

</style>