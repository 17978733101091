import { createRouter, createWebHistory } from "vue-router";

import { storeSession } from "./store/store-session";
import storeRegister from "./store/store-register";
import storeAccount from "./store/store-account";
import menusTotal from "@/js/menu/menus-total";
import apiCall from "@/js/api-call";
import storeCode from "./store/store-code";

import sessionTimeout from "@/views/main/SessionTimeout.vue";
import unknownRoute from "@/views/main/UnknownRoute.vue";
import ProxyRedirect from "@/views/main/ProxyRedirect.vue";
import SystemLogin from "@/views/login/SystemLogin.vue";
import SystemMain from "@/views/main/SystemMain.vue";
import ServiceHome from "@/views/main/ServiceHome.vue";

import PostManage from "@/views/cust-sprt/PostManage.vue";
import PostDetail from "@/views/cust-sprt/PostDetail.vue";


const ROUTE_MAIN = "/";
const API = {
    USER_LOGIN: "/auth/access-token",
};

let isUserLoginRequestInProgress = false;

function getUnauthenticatedSession() {
    return new Promise((resolve, reject) => {
        if (isUserLoginRequestInProgress) {
            return;
        }

        isUserLoginRequestInProgress = true; // 요청 시작

        apiCall.write(API.USER_LOGIN, { isGuest: true }, (data) => {
            isUserLoginRequestInProgress = false; // 요청 완료

            if (data.result) {
                storeSession.setSession(data.result);
                resolve();
            } else {
                reject(new Error("Failed to UnauthenticatedSession"));
            }
        },
        () => {
            isUserLoginRequestInProgress = false; // 요청 실패 시 플래그 초기화
            reject(new Error("Failed to UnauthenticatedSession"));
        });
    });
}

const mainRoutes = [];
buildMainRoutes();

function loadRoute(item){
    let route = null;
    try {
        if (item.path.startsWith("/cust-sprt/PostManage")) {
            route = {
                name: item.path,
                path: "/cust-sprt/PostManage/:bbsSn",
                component: PostManage,
                meta: { isPublic: true }
            }
        } else if (item.path.startsWith("/cust-sprt/PostDetail")) {
            route = {
                name: item.path,
                path: "/cust-sprt/PostDetail/:bbsSn",
                component: PostDetail,
                meta: { isPublic: true }
            }
        }else{
            require(`@/views${item.path}.vue`);
            route = {
                name: item.path,
                path: item.path,
                component: () => import(`@/views${item.path}.vue`),
                meta: { isPublic: item.isPublic || false }
            }
        }
    } catch (error) {
        console.log(`Error: Cannot find module '@/views${item.path}.vue'`);
        route = {
            path: item.path,
            component: unknownRoute,
            meta: { isPublic: false }
        }
    }
    return route;
}

function buildMainRoutes(){
    let items = menusTotal.getMenus();
    for(let item of items){
        let route = loadRoute(item);
        mainRoutes.push(route);
    }

    const home = {
        name: "home",
        path: "/home",
        component: ServiceHome,
    };
    mainRoutes.push(home);
}

const requireFindAccount = (step, findType) => (to, from, next) => {
    if (storeAccount.state.isFindAccount && step === storeAccount.state.findAccountStep && storeAccount.state.findAccountInfo.findType === findType) {
        next();
    } else {
        alert("비정상적인 접근입니다.\n홈 화면으로 이동됩니다.");
        next('/home'); // home으로 이동
    }
};

const requireRegistration = (step) => (to, from, next) => {
    if (storeRegister.state.isRegistering && step === storeRegister.state.registerStep) {
        next();
    } else {
        alert("비정상적인 접근입니다.\n홈 화면으로 이동됩니다.");
        next('/home'); // home으로 이동
    }
};

let routes = [
    {
        name: "user",
        path: "/user",
        component: SystemLogin,
        children: [
            { name: "login", path: "login", component: () => import(`@/views/login/components/UserLogin.vue`), meta: { isPublic: true } },
            { name: "IdPswdFnd", path: "IdPswdFnd", component: () => import(`@/views/login/components/UserIdPswdFnd.vue`), meta: { isPublic: true } },
            { name: "UserIdInq", path: "UserIdInq", beforeEnter: requireFindAccount(1, 'id'), component: () => import(`@/views/login/components/UserIdInq.vue`), meta: { isPublic: true } },
            { name: "UserPswdChg", path: "UserPswdChg", beforeEnter: requireFindAccount(1, 'pswd'), component: () => import(`@/views/login/components/UserPswdChg.vue`), meta: { isPublic: true } },
            { name: "ExpryPswd", path: "ExpryPswd", component: () => import(`@/views/login/components/UserExpryPswd.vue`), meta: { isPublic: true } },
            { name: "MbrJoin", path: "MbrJoin", component: () => import(`@/views/join/MbrSelect.vue`), meta: { isPublic: true } },
            { name: "JoinCheck", path: "JoinCheck", beforeEnter: requireRegistration(1), component: () => import(`@/views/join/JoinCheck.vue`), meta: { isPublic: true } },
            { name: "TermsAgre", path: "TermsAgre", beforeEnter: requireRegistration(2), component: () => import(`@/views/join/TermsAgre.vue`), meta: { isPublic: true } },
            { name: "UserInfo", path: "UserInfo", beforeEnter: requireRegistration(3), component: () => import(`@/views/join/UserInfo.vue`), meta: { isPublic: true } },
            { name: "JoinCmptn", path: "JoinCmptn", beforeEnter: requireRegistration(4), component: () => import(`@/views/join/JoinCmptn.vue`), meta: { isPublic: true } },
        ]
    },
    {
        name: "main",
        path: "/",
        component: SystemMain,
        // redirect: "/",
        children: mainRoutes,
    },
    {
        name: "timeout",
        path: "/timeout",
        component: sessionTimeout,
    },
    {
        name: "unknown",
        path: "/unknown",
        component: unknownRoute,
    },
    {
        name: "proxy",
        path: "/proxy",
        component: ProxyRedirect,
        meta: { isPublic: true }
    },
    {
        name: "unknownRoute",
        path: "/:pathMatch(.*)*",
        component: unknownRoute,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {

    if (to.path === '/proxy') {
        next();
        return;
    }

    if (!storeSession.hasSession() && to.path !== '/home') {
        try {
            await getUnauthenticatedSession();
        } catch (error) {
            console.error("Error initializing session", error);
            console.log(to.path)
            if (!to.meta?.isPublic && to.path !== ROUTE_MAIN) {
                if (to.path !== "/user/login") {
                    next("/user/login");
                    return;
                }
            }
        }
    }

    if (to.path === ROUTE_MAIN) {
        next('/home');
        return;
    }

    // 권한에 따른 메뉴 경로가 아닐경우
    if (to.matched[0] && to.matched[0].name === "main" && to.path !== '/home') {
        const menu = menusTotal.getRouteMenu(to.path);

        if (menu.groupName === "unknown") {
            next("/unknown");
            return;
        }
    }

    const isLoggedIn = storeSession.hasLogin();
    const isPublic = to.meta?.isPublic || false;

    if (!isLoggedIn && !isPublic && to.path !== '/home') {
        if (to.path !== "/user/login") {
            next("/user/login");
            return;
        }
    }

    await storeCode.dispatch('fetchCodeList');
    await storeCode.dispatch('fetchSggList');

    next();
});

router.afterEach((to) => {
    if (to.matched.some(record => record.name === "main")) {
        const menu = menusTotal.getRouteMenu(to.path);
        storeSession.setRouteMenu(menu);

        if (menu.menuSn) {
            apiCall.post("/log/menu", { menuSn : menu.menuSn }, () => {},() => {});
        }
    }
});

export default router;