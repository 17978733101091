import axios from "axios";
import { storeSession } from "@/js/store/store-session";
import router from "@/js/router";

let isRefreshing = false; // 리프레시 상태 플래그
let pendingSubscribers = []; // 대기 중인 구독자

// 구독자들에게 새로운 토큰 전달
function notifySubscribers(accessToken) {
    pendingSubscribers.forEach(callback => callback(accessToken));
    pendingSubscribers = [];
}

// 세션 만료 처리
function handleSessionExpiration() {
    storeSession.removeSession();
    router.push("/timeout");
}

// 리프레시 토큰 요청
async function fetchNewAccessToken(refreshToken) {
    const response = await axios.post(`/api/auth/refresh-token`, {}, {
        headers: { Authorization: refreshToken },
        withCredentials: true
    });

    return Promise.resolve(response);
}

export default {
    // 구독자 추가
    addSubscriber(callback) {
        pendingSubscribers.push(callback);
    },

    // 리프레시 상태 진행 확인
    isRefreshing() {
        return isRefreshing;
    },

    // 토큰 갱신
    async renew() {
        const refreshToken = storeSession.getTokenInfo()?.refreshToken;
        if (!refreshToken) {
            pendingSubscribers = [];
            return;
        }

        if (isRefreshing) {
            return; // 이미 리프레시 요청 중이라면 종료
        }

        try {
            isRefreshing = true;

            const response = await fetchNewAccessToken(refreshToken);

            const newToken = response.data.result;
            storeSession.setSession({
                ...storeSession.getSession(),
                token: newToken
            });

            notifySubscribers(newToken.accessToken); // 구독자에게 알림
        } catch (error) {
            alert("중복 로그인 접속이거나, 이전 로그인 세션이 만료되었습니다.");
            handleSessionExpiration();
        } finally {
            isRefreshing = false; // 리프레시 상태 초기화
        }
    }
};