<template>
  <div v-show="isOn" class="modal visible">
    <div class="popup_box" style="width: 80%;">
      <h1 class="popup_title">사업장 변경신청 정보</h1>
      <div>
        <button type="button" class="modal_close" @click.prevent="hide">팝업닫기</button>
      </div>
      <div class="popup_cont" style="max-height: calc(90vh - 15px); overflow: hidden;overflow-y: auto;">
        <!-- 사업장 정보 -->
        <h3 class="title2">사업장 정보</h3>
        <div class="board_write">
          <table>
            <caption></caption>
            <colgroup>
              <col style="width:15%">
              <col style="width:35%">
              <col style="width:15%">
              <col style="width:35%">
            </colgroup>
            <tbody>
            <tr>
              <th scope="row">사업장 명</th>
              <td>
                {{this.bizfaciltyBplc.bplcNm}}
              </td>
              <th scope="row">사업장 명(영문)</th>
              <td>
                {{this.bizfaciltyBplc.engBplcNm}}
              </td>
            </tr>
            <tr>
              <th scope="row">사업장 종류</th>
              <td>
                {{getCdName(this.bplcKndCdList, this.bizfaciltyBplc.bplcKndCd)}}
              </td>

              <th scope="row">담당자 명</th>
              <td>
                {{this.bizfaciltyBplc.picNm}}
              </td>
            </tr>
            <tr>
              <th scope="row">위탁운영관리</th>
              <td colspan="3">
                {{ this.bizfaciltyBplc.cnsgnOperMngYn ==='Y' ? '대상' : '대상아님'}}
              </td>
            </tr>
            <tr v-if="bizfaciltyBplc.cnsgnOperMngYn === 'Y'">
              <th scope="row">위탁업체명</th>
              <td>
                {{this.bizfaciltyBplc.cnsgnBzentyNm}}
              </td>
              <th scope="row">위탁업체 사업자등록번호</th>
              <td>
                {{this.bizfaciltyBplc.cnsgnBzentyBrno}}
              </td>
            </tr>
            <tr>
              <th scope="row">전화번호</th>
              <td>
                <div class="input_group">
                  {{this.bizfaciltyBplc.telno}}
                </div>
              </td>
              <th scope="row">FAX</th>
              <td>
                <div class="input_group">
                  {{ this.bizfaciltyBplc.fxno === '--' ? '' : this.bizfaciltyBplc.fxno }}
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">주소</th>
              <td colspan="3">
                {{ `[${this.bizfaciltyBplc.untyZip}] ${this.bizfaciltyBplc.bplcAddr} ${this.bizfaciltyBplc.bplcDaddr}` }}
              </td>
            </tr>
            <tr>
              <th scope="row">설치신고 여부</th>
              <td colspan="3">
                <div class="chk_area">
                  <div class="form_check">
                    <input type="checkbox" v-model="bizfaciltyBplc.prcsFcltInstlDclrYn" id="agree_chk21" true-value="Y" false-value="N" disabled>
                    <label for="agree_chk21">처리시설 설치신고</label>
                  </div>
                  <div class="form_check">
                    <input type="checkbox" v-model="bizfaciltyBplc.atmoDschrgFcltInstlDclrYn" id="agree_chk22" true-value="Y" false-value="N" disabled>
                    <label for="agree_chk22">대기배출시설 설치신고</label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">재활용 업체 여부</th>
              <td colspan="3">
                <div class="chk_area">
                  <div class="form_check">
                    <input type="checkbox" v-model="bizfaciltyBplc.mdRecyBzentyYn" id="agree_chk21" true-value="Y" false-value="N" disabled>
                    <label for="agree_chk21">중간 재활용업체</label>
                  </div>
                  <div class="form_check">
                    <input type="checkbox" v-model="bizfaciltyBplc.lastRecyBzentyYn" id="agree_chk22" true-value="Y" false-value="N" disabled>
                    <label for="agree_chk22">최종 재활용업체</label>
                  </div>
                  <div class="form_check">
                    <input type="checkbox" v-model="bizfaciltyBplc.snthsRecyBzentyYn" id="agree_chk23" true-value="Y" false-value="N" disabled>
                    <label for="agree_chk23">종합 재활용업체</label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">처분 업체 여부</th>
              <td colspan="3">
                <div class="chk_area">
                  <div class="form_check">
                    <input type="checkbox" v-model="bizfaciltyBplc.mdDspsBzentyYn" id="agree_chk21" true-value="Y" false-value="N" disabled>
                    <label for="agree_chk21">중간 처분업체</label>
                  </div>
                  <div class="form_check">
                    <input type="checkbox" v-model="bizfaciltyBplc.lastDspsBzentyYn" id="agree_chk22" true-value="Y" false-value="N" disabled>
                    <label for="agree_chk22">최종 처분업체</label>
                  </div>
                  <div class="form_check">
                    <input type="checkbox" v-model="bizfaciltyBplc.snthsDspsBzentyYn" id="agree_chk23" true-value="Y" false-value="N" disabled>
                    <label for="agree_chk23">종합 처분업체</label>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row">설치운영허가번호</th>
              <td>
                {{this.bizfaciltyBplc.instlOperPrmsnNo}}
              </td>
              <th scope="row">설치운영허가일자</th>
              <td>
                {{this.bizfaciltyBplc.instlOperPrmsnYmd}}
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row">신고허가번호</th>
              <td>
                {{this.bizfaciltyBplc.dclrPrmsnNo}}
              </td>
              <th scope="row">신고허가일자</th>
              <td>
                {{this.bizfaciltyBplc.dclrPrmsnYmd}}
              </td>
            </tr>
            <tr v-if="conds.showWes">
              <th scope="row">자원순환시설 ID</th>
              <td colspan="3">
                {{this.bizfaciltyBplc.rsrcRcyclnFcltId}}
              </td>
            </tr>
            <tr v-if="conds.uncheck" >
              <th scope="row">인원수</th>
              <td>
                {{formatNumber(this.bizfaciltyBplc.nope)}}
              </td>
              <th scope="row">운영금액</th>
              <td>
                {{formatNumber(this.bizfaciltyBplc.operAmt)}}
              </td>
            </tr>
            <tr v-if="conds.uncheck" >
              <th scope="row">편익시설 종류</th>
              <td>
                {{this.bizfaciltyBplc.cnvncFcltKndNm}}
              </td>
              <th scope="row">편익시설 설치비용</th>
              <td>
                {{formatNumber(this.bizfaciltyBplc.cnvncFcltInstlCst)}}
              </td>
            </tr>
            <tr v-if="conds.uncheck" >
              <th scope="row">편익시설 운영기관</th>
              <td>
                {{this.bizfaciltyBplc.cnvncFcltOperInstNm}}
              </td>
              <th scope="row">편익시설 비고</th>
              <td>
                {{this.bizfaciltyBplc.cnvncFcltRmrkCn}}
              </td>
            </tr>
            <tr v-if="conds.uncheck" >
              <th scope="row">총 사업비</th>
              <td>
                {{formatNumber(this.bizfaciltyBplc.tpcoAmt)}}
              </td>
              <th scope="row">사업장 상태</th>
              <td>
                {{getCdName(this.bplcSttsCdList , this.bizfaciltyBplc.bplcSttsCd)}}
              </td>
            </tr>
            <tr v-if="conds.uncheck" >
              <th scope="row">사업기간</th>
              <td>
                {{this.bizfaciltyBplc.bizBgngYmd}} <span v-if="this.bizfaciltyBplc.bizBgngYmd && this.bizfaciltyBplc.bizEndYmd">~</span> {{this.bizfaciltyBplc.bizEndYmd}}
              </td>
              <th scope="row">사업장 승인상태</th>
              <td :style="bizfaciltyBplc.aprvYn === 'N' ? 'color: red' : '' ">
                {{getCdName(this.aprvYnCdList, bizfaciltyBplc.aprvYn)}}
              </td>
            </tr>
            <tr v-if="conds.uncheck" >
              <th scope="row">국고금액</th>
              <td>
                {{formatNumber(this.bizfaciltyBplc.gvcfrAmt)}}
              </td>
              <th scope="row">지방비금액</th>
              <td>
                {{formatNumber(this.bizfaciltyBplc.lgscAmt)}}
              </td>
            </tr>
            <tr v-if="conds.uncheck" >
              <th scope="row">민자금액</th>
              <td>
                {{formatNumber(this.bizfaciltyBplc.pvtcptAmt)}}
              </td>
              <th scope="row">기타금액</th>
              <td>
                {{formatNumber(this.bizfaciltyBplc.etcAmt)}}
              </td>
            </tr>
            <tr v-if="conds.uncheck" >
              <th scope="row">사용자 메모 내용</th>
              <td colspan="3">
                {{this.bizfaciltyBplc.userMemoCn}}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <h3 class="title2">첨부서류</h3>
        <br-attach-file-list label="사업장 첨부파일 목록"
                             ref="attachFileList"
                             :atchFileTaskSeCd="atchFileTaskSeCd"
                             :atchFileGroupId="bizfaciltyBplc.atchFileGroupId"
                             :unusedAtchFileList="unusedAtchFileList"
                             readonly
                             :useEtcFile="true"

        />
        <div class="btn_area center">
          <br-button label="닫기" class="btn sm tertiary" @click="hide" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";
import BrFormRadio from "@/components/BrFormRadio.vue";
import BrDatePicker from "@/components/BrDatePicker.vue";
import BrButton from "@/components/BrButton.vue";

const API = {
  BPLC_CHG_INFO: "/bizfacility/bplc-chg-info",
};

const CODE = {
  ARPV_YN_CD: "SIS020", // 승인여부코드
  BPLC_STTS_CD: "MBR004", // 사업장상태코드
  BPLC_KND_CD: "MBR006", // 사업장종류코드
}

const initialBizfaciltyBplc = {
      bplcId: '',
      bplcNm: '',
      engBplcNm: '',
      bzentyId: 0,
      bplcKndCd: '',
      cnsgnOperMngYn: 'N',
      cnsgnBzentyNm: '',
      cnsgnBzentyBrno: '',
      cnsgnBzentyScndwpNo: '',
      picNm: '',
      telno: '',
      telno1: '',
      telno2: '',
      telno3: '',
      fxno: '',
      fxno1: '',
      fxno2: '',
      fxno3: '',
      untyZip: '',
      bplcAddr: '',
      bplcDaddr: '',
      ctpvStdgCd: '',
      sggStdgCd: '',
      cmptncRgnHqCd: '',
      lat: '',
      lot: '',
      snthsRecyBzentyYn: 'N',
      lastRecyBzentyYn: 'N',
      mdRecyBzentyYn: 'N',
      prcsFcltInstlDclrYn: 'N',
      atmoDschrgFcltInstlDclrYn: 'N',
      mdDspsBzentyYn: 'N',
      lastDspsBzentyYn: 'N',
      snthsDspsBzentyYn: 'N',
      instlOperPrmsnNo: '',
      instlOperPrmsnYmd: '',
      dclrPrmsnNo: '',
      dclrPrmsnYmd: '',
      atchFileGroupId: '',
      bplcSttsCd: '',
      aprvYn: '',
      rsrcRcyclnFcltId: '',
      mngrMemoCn: '',
      userMemoCn: '',
      useYn: '',
      nope: '',
      operAmt: '',
      rmrkCn: '',
      cnvncFcltKndNm: '',
      cnvncFcltInstlCst: '',
      cnvncFcltOperInstNm: '',
      cnvncFcltRmrkCn: '',
      tpcoAmt: '',
      bizBgngYmd: '',
      bizEndYmd: '',
      gvcfrAmt: '',
      lgscAmt: '',
      pvtcptAmt: '',
      etcAmt: '',
      fillAftrUsePlanCn: '',
      regDt: '',
      rgtrId: '',
      mdfcnDt: '',
      mdfrId: '',
      bplcCtpvCd: '',
      userId: '',
      bplcMngPicSttsCd: '',
      rnMgtSn: '',
    };

const initialUnusedAtchFileList = ['053', '054', '008'];

export default {
  components: {
    BrButton,
    BrDatePicker,
    BrFormRadio,
  },
  props: {
    bplcId: {type: String, required: true},
    chgAplySn: {type: Number, required: true},
  },
  data() {
    return {
      isOn: false,
      atchFileTaskSeCd: '',
      unusedAtchFileList: [...initialUnusedAtchFileList],
      bizfaciltyBplc: { ...initialBizfaciltyBplc },
      conds: {
        showWes: false, //사업장종류 = 폐자원에너지
        income: false,  //사업장종류 = 고형연료수입
        uncheck: false, //사업장종류 = 미관리 종류(uncheckList)
      },
      uncheckList: [  // 미관리 종류
        '06-01',  //열분해유
        '07-01',  //재생연료유
        '08-01',  //정제연료유
        '09-01',  //시멘트소성로 보조연료
        '10-01',  //유기성오니 보조연료
      ],
      bplcKndCdList: [],
      bplcSttsCdList: [],
      aprvYnCdList: [],
      radioBtnYnList: [
        { value: "Y", text: "대상" },
        { value: "N", text: "비대상" },
      ],
    };
  },
  computed: {
  },
  watch: {
    isOn(newVal){
      if(newVal){
        this.initialize();
      }else{
        this.reset();
      }
    }
  },
  created() {
    storeSwitch.setBulb('bplcChgAplyInfoPopup', this);
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeUnmount() {
  },
  methods: {
    /** 팝업 Show */
    show() {
      this.isOn = true;
    },
    /** 팝업 Hide */
    hide() {
      this.isOn = false;
    },
    /** 화면 초기화 */
    initialize() {
      this.bplcKndCdList = this.$store.getters.getCodeList({groupCd: CODE.BPLC_KND_CD, useYn: 'Y'});         // 사업장종류코드
      this.bplcSttsCdList = this.$store.getters.getCodeList({groupCd: CODE.BPLC_STTS_CD, useYn: 'Y'});       // 사업장상태코드
      this.aprvYnCdList = this.$store.getters.getCodeList({groupCd: CODE.ARPV_YN_CD, useYn: 'Y'});           // 승인여부코드
      this.readBplcInfo();
    },
    /** 사업장정보 조회 */
    readBplcInfo() {
      this.$apiCall.get(
          API.BPLC_CHG_INFO,
          {
            bplcId: this.bplcId,
            chgAplySn: this.chgAplySn
          },
          data => {
            if (data.result) {
                for (const key of Object.keys(this.bizfaciltyBplc)) {
                  this.bizfaciltyBplc[key] = data.result?.[key] ?? '';
                }
              this.setConditions(this.bizfaciltyBplc.bplcKndCd);
              this.setUnusedAtchFileList();
            }
          });
    },
    setUnusedAtchFileList(){
      if(this.bizfaciltyBplc.cnsgnOperMngYn === 'N'){
        this.unusedAtchFileList = [...initialUnusedAtchFileList];
      }else{
        this.unusedAtchFileList = [];
      }
      this.$refs.attachFileList.load(this.atchFileTaskSeCd, this.bizfaciltyBplc.atchFileGroupId);
    },
    /** 코드명 출력  */
    getCdName(list, code) {
      const status = list.find(item => item.dtlCd === code);
      return status ? status.dtlCdNm : '';
    },
    /* 사업장종류별 조건 설정*/
    setConditions(bplcKndCd){
      const mainCd = bplcKndCd.slice(0, 2);
      const subCd = bplcKndCd.slice(3, 5);

      // 초기화
      this.conds = {
        showWes: mainCd !== '01',
        uncheck: !this.uncheckList.includes(bplcKndCd),
        income: mainCd === '01' && subCd === '03',
      };

      // 고형연료 및 수입연료 조건 처리
      if (mainCd === '01') {
        if (subCd === '01' || subCd === '02') {
          switch (subCd){
            case '01' :
              this.atchFileTaskSeCd = 'BPCA'
              break;
            case '02' :
              this.atchFileTaskSeCd = 'BPCC'
              break;
          }
          this.conds.uncheck = true;
          this.conds.income = false;
        } else if (subCd === '03') {
          this.conds.uncheck = true;
          this.conds.income = true;
          this.atchFileTaskSeCd = 'BPCD'
        }
      }else{
        this.atchFileTaskSeCd = 'BPCB';
      }
    },
    reset(){
        this.bizfaciltyBplc = { ...initialBizfaciltyBplc };
    },
    formatNumber(value) {
      if (!value) return '';
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  }
}
</script>

<style scoped>
.subtitle-note {
  font-size: 15px;      /* 폰트 크기 설정 */
  font-weight: normal;  /* 굵기 설정 (기본 또는 normal) */
  color: #888888;       /* 원하는 색상 코드로 변경 */
  margin-left: 10px;
}
</style>