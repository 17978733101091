<template>
  <div class="chk_area">
    <div v-for="option of radioOptions" :key="option.value" class="form_check">
      <input
          type="radio"
          :id="prefix + option.value"
          :name="prefix"
          :checked="isChecked(option)"
          :disabled="onDisabled"
          @click="selectOption(option)"
      />
      <label :for="prefix + option.value">{{ option.text }}</label>
    </div>
  </div>
</template>

<script>
import { storeValidator } from "@/js/store/store-validator";
import pp from '@/js/postposition';
import { v4 as uuidv4 } from "uuid";
import { mapGetters } from "vuex";

export default {
  props: {
    label: { type: String, required: true },
    options: { type: Array, default: () => [] },
    groupCd: {type: String, default: null},
    value: { type: String, default: '' },
    text: { type: String, default: '' },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    panel: { type: String, default: '' },
    allSelectable: { type: Boolean, default: false },
  },
  emits: [ 'update:value', 'update:text', 'selected' ],
  data() {
    return {
      prefix: "radio-" + uuidv4(),
      radioOptions: [],
      hasError: false,
      errorMessage: "",
      selectedOption: null,
    };
  },
  computed: {
    ...mapGetters(['getCodeList']),
    filteredCodeList() {
      return this.getCodeList({groupCd: this.groupCd, useYn: 'Y'});
    },
    onDisabled() {
      return this.disabled || this.readonly;
    },
    isChecked() {
      return (option) => this.selectedOption === option;
    },
  },
  watch: {
    value() {
      this.updateSelectedOption();
    },
    options(newVal) {
      this.radioOptions = newVal;
      this.updateSelectedOption();
    }
  },
  created() {
    if (this.groupCd) {
      this.setComCd();
    } else {
      this.radioOptions = this.options;
      if (this.allSelectable && !this.radioOptions.some(opt => opt.value === "")) {
        this.radioOptions.unshift({ text: "전체", value: "" });
      }
    }
    this.updateSelectedOption();
    if (this.required) {
      storeValidator.add(this.$route.path, this.panel, this);
    }
  },
  beforeUnmount() {
    if (this.required) {
      storeValidator.remove(this.$route.path, this.panel, this.label);
    }
  },
  methods: {
    setComCd() {
      this.radioOptions = [];
      const codeList = this.filteredCodeList;
      if (codeList.length > 0) {
        this.radioOptions = codeList.map(item => ({
          text: item.dtlCdNm,
          value: item.dtlCd
        }));
        if (this.allSelectable) {
          this.radioOptions.unshift({ text: "전체", value: "" });
        }
      } else {
        this.setError();
      }
    },
    setError() {
      this.radioOptions.push({ text: "", value: "" });
    },
    validate() {
      if (this.required && !this.selectedOption.value) {
        this.hasError = true;
        this.errorMessage = `${pp.eunNn(this.label)} 필수 선택값 입니다.`;
      } else {
        this.hasError = false;
        this.errorMessage = "";
      }
    },
    selectOption(option) {
      this.selectedOption = option;
      this.$emit('update:value', this.selectedOption.value);
      this.$emit('update:text', this.selectedOption.text);
      this.$emit('selected', this.selectedOption);
      this.validate();
    },
    findOption(value) {
      return this.radioOptions.find(opt => opt.value === value) || { text: "", value: "" };
    },
    updateSelectedOption() {
      this.selectedOption = this.findOption(this.value);
      this.$emit('update:text', this.selectedOption.text);
      this.validate();
    }
  }
}
</script>

<style scoped>

</style>