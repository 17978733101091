<template>
  <div class="sch_form_wrap">
    <div class="sch_form row2">
      <span>
        <label>검사년도</label>
        <br-year-picker label="검사년도" v-model:value="searchParam.inspYear"/>
      </span>
      <span>
        <label>사업장명</label>
        <br-form-select label="사업장명" v-model:value="searchParam.bplcId" :options="bplcOptions" allSelectable/>
      </span>
      <span>
        <label>승인여부</label>
        <br-form-select label="승인여부" v-model:value="searchParam.aprvYn" allSelectable groupCd="SIS020"/>
      </span>
    </div>
    <div class="btn_area right">
      <br-anchor-button role="link" label="검색" size="sm" format="sch" @click="searchBtn" />
      <br-anchor-button role="link" label="새로고침" size="sm" format="txtN" icon="ico_refresh" @click="reset" />
    </div>
  </div>
  <div class="board_info">
    <p class="page">
      <span class="total">전체 <strong>{{ this.dataCount }}</strong></span>
      <span>페이지 <strong>{{ this.searchParam.page }}/{{ this.maxPage }}</strong></span>
    </p>
    <div class="btn_area right">
      <br-anchor-button role="link" label="엑셀다운로드" size="sm" icon="ico_excel" @click="excelDownload"/>
      <br-anchor-button role="link" size="sm" label="등록" @click="createNaviagteTo"/>
    </div>
  </div>
  <!-- 그리드 컨텐츠 영역 -->
  <div class="board_list scroll">
    <table>
      <caption>시설검사신청 현황 조회-검사신청번호, 검사구분, 시설 명, 시설종류, 신청일자, 접수일자, 검사(예정)일자, 진행상태, 검사결과로 구성
      </caption>
      <colgroup>
        <col style="width:80px">
        <col style="width:80px">
        <col style="width:115px">
        <col style="width:200px">
        <col style="width:110px">
        <col style="width:120px">
        <col style="width:120px">
        <col style="width:100px">
        <col style="width:">
      </colgroup>
      <thead>
      <tr>
        <th scope="col">검사년도</th>
        <th scope="col">검사분기</th>
        <th scope="col">사업장종류</th>
        <th scope="col">사업장명</th>
        <th scope="col">시설종류</th>
        <th scope="col">의뢰일자</th>
        <th scope="col">발급일자</th>
        <th scope="col">검사기관</th>
        <th scope="col">승인여부</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="dataList.length <= 0" >
        <td colspan="12">
          <div style="display: flex; justify-content: center;">
            <div class="no-data-message">조회조건에 해당하는 데이터가 없습니다.</div>
          </div>
        </td>
      </tr>
      <tr v-else v-for="(item, index) in dataList" :key="index" @dblclick="navigateTo(item)">
        <td aria-label="검사년도">{{ item.inspYear }}</td>
        <td aria-label="검사분기">{{ item.inspQtr }}</td>
        <td aria-label="사업장종류">{{ item.bplcKndNm }}</td>
        <td aria-label="사업장명">{{ item.bplcNm }}</td>
        <td aria-label="시설종류">{{ item.prdctKndNm }}</td>
        <td aria-label="의뢰일자">{{ item.aplyYmd }}</td>
        <td aria-label="발급일자">{{ item.prcsYmd }}</td>
        <td aria-label="검사기관">{{ item.qltyIndctInsofcNm }}</td>
        <td aria-label="승인여부">{{ item.aprvNm }}</td>
      </tr>
      </tbody>
    </table>
  </div>
  <!-- 그리드 페이징 영역 -->
  <insp-pagination :total="dataCount" :size="searchParam.size" v-model:page="searchParam.page" v-model:maxPage="maxPage" @clickedPage="search"></insp-pagination>
  
</template>

<script>
import { storeComponent } from "@/js/store/store-component";
import inspPagination from "@/views/sfli/components/inspPagination.vue";
import { storeSession } from "@/js/store/store-session";

const API = {
  OTSDQLTYINDCT : "/otsdQltyIndctRslt",
};

export default{
  components: {inspPagination},
  data() {
    return {
      menu: this.$menusTotal.getRouteMenu(this.$route.path),
      userInfo : storeSession.getMemberInfo(),
      searchParam: {
        inspYear: "",
        bplcId: "",
        aprvYn: "",
        userId: "",
        page: 1,
        size: 10,
      },
      maxPage: 10,
      dataList: [],
      dataCount: 0,
      bplcOptions: [],
      
    }
  },
  created() {
  },
  beforeMount() {
    // 컴포넌트 전체 데이터 가져오기
    //storeComponent.restore(this.menu, this);
  },
  beforeUnmount() {
    // 컴포넌트 전체 데이터 저장하기
    storeComponent.store(this.menu, this);
  },
  mounted() {
    window.scrollTo(0, 0);
    this.searchParam.userId = this.userInfo.userId
    this.initialize();
  },
  methods: {
    async initialize() {
      this.getBplc();
      this.search();
    },
    reset() {
      this.searchParam.page = 1
      this.searchParam.inspYear = ""
      this.searchParam.bplcCd = ""
      this.searchParam.aprvYn = ""

      this.search();
    },
    search() {
      this.$apiCall.get(
        API.OTSDQLTYINDCT,
        this.searchParam,
        (data) => {
          this.dataCount = Number(data.result.total);
          this.dataList = data.result.list;
        },
        () => {

        }
      )
    },
    navigateTo(data) {
      if (!data.qltyInspRsltSn) {
        alert("품질검사결과번호가 존재하지 않습니다.");
        return;
      }
      this.$router.push(
        {path: "/sfli/otsdQltyIndctRslt/OtsdQltyIndctRsltDetail", 
        query: {
            qltyInspRsltSn: data.qltyInspRsltSn,
            bplcId: data.bplcId,
            inspAplySn: data.inspAplySn,
            inspId: data.inspId,
            bplcKndCd: data.bplcKndCd,
            bzentyNm: data.bzentyNm,
            bplcNm: data.bplcNm,
            fcltSqno: data.fcltSqno,
            fcltNm: data.fcltNm,
          }}
        )
        .catch(() => {
          console.error("Navigation Error");
        });
    },
    createNaviagteTo() {
      this.$router.push(
        {path: "/sfli/otsdQltyIndctRslt/OtsdQltyIndctRsltCreate"}
      )
        .catch(() => {
          console.error("Navigation Error");
        });
    },
    getBplc() {
      this.bplcOptions = [];
      this.$apiCall.get(
        API.OTSDQLTYINDCT + "/bplc",
        {userId : this.userInfo.userId},
        (data) => {
          if(data.result){
            for (let bplc of data.result){
              this.bplcOptions.push({value: bplc.bplcId, text: bplc.bplcNm + '(' + bplc.bplcKndNm + ')'});
            }
          }
        }
      );
    },
    excelDownload() {
      let param = this.searchParam
      param.fileName = '외부기관 품질표시검사결과.xlsx'
      this.$apiCall.download(
        API.OTSDQLTYINDCT + '/excel-download',
        param
      );
    },
    searchBtn() {
      this.searchParam.page = 1
      this.search();
    }
  },

}
</script>

<style scoped>
.board_list.scroll > table { table-layout: fixed; width:100%;}

.no-data-message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  margin: 20px 0px;
  border-radius: 10px;
  background-color: #f7f7f7;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 350px;
}

.no-data-message::before { content:''; width:2rem; height:2rem; background:url(@/assets/images/common/icon_search.png) no-repeat; background-size: 2rem; margin-right: 8px;}

.board_list.scroll > table tbody tr:hover{ background:var(--bg-gray10)}
</style>
