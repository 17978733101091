<template>
  <div v-show="isVisible" class="modal visible">
    <div class="popup_box" tabindex="0">
      <h1 class="popup_title">업체 선택</h1>
      <div class="popup_cont">
        <div class="sch_form_wrap">
          <div class="sch_form row2">
            <span>
              <label style="width: 13rem;">업체명</label>
              <br-form-input label="업체명" v-model="rghtScsnDclrBzentySearchParam.bzentyNm" @enter-keyup="search" />
            </span>
            <span>
              <label>사업자번호</label>
              <br-form-input label="사업자등록번호" v-model="rghtScsnDclrBzentySearchParam.brno" @enter-keyup="search" />
            </span>
            <span>
              <label style="width: 13rem;">시·도/시·군·구</label>
              <br-form-select label="시·도" v-model:value="rghtScsnDclrBzentySearchParam.ctpvStdgCd" :options="ctpvCdList" allSelectable @change="search" />
              <br-form-select label="시·군·구" v-model:value="rghtScsnDclrBzentySearchParam.sggStdgCd" :options="rghtScsnDclrBzentyFilteredSggCdList" allSelectable @change="search" />
            </span>
          </div>
          <div class="btn_area">
            <br-anchor-button label="검색" size="sm" format="sch" @click="search" />
            <br-anchor-button label="새로고침" size="sm" format="txtN" icon="ico_refresh" @click="reset" />
          </div>
        </div>
        <div class="grid_area">
          <sb-grid
              id="rghtScsnDclrBzentyGrid"
              :dataSource="rghtScsnDclrBzentyDataSource"
              :columns="rghtScsnDclrBzentyGridColumns"
              :gridattr="rghtScsnDclrBzentyGridAttr"
              :param="rghtScsnDclrBzentySearchParam"
              v-model:grid="rghtScsnDclrBzentyGridObject"
              @loaded="rghtScsnDclrBzentyGridLoaded"
              :refreshGrid="rghtScsnDclrBzentyGridReload"
          />
        </div>
        <div class="btn_area right">
          <br-button label="선택" size="md" format="primary" @click="selectBzenty" />
          <br-button label="닫기" size="md" @click="hide" />
        </div>
      </div>
      <button type="button" class="modal_close" @click="hide">팝업 닫기</button>
    </div>
  </div>
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";
import SbGrid from "@/components/SbGrid.vue";

const API = {
  RGHT_SCSN_DCLR_BZENTY : "/rghtScsnDclr/bzenty",
};

export default {
  components: {SbGrid},
  emits: ['select-bzenty'],
  data() {
    return {
      isVisible: false,
      rghtScsnDclrBzentySearchParam: {
        bzentyNm: "",
        brno: "", 
        ctpvStdgCd : "",
        sggStdgCd: "",
      },
      ctpvCdList: [],
      rghtScsnDclrBzentySggCdList: [],
      rghtScsnDclrBzentyGridReload: false,
      rghtScsnDclrBzentyGridObject: null,
      rghtScsnDclrBzentyDataSource: {
        ajaxConfig: {
          select: { url: API.RGHT_SCSN_DCLR_BZENTY },
          paging: true,
        },
        schema: {
          id: 'bzentyId'
        }
      },
      rghtScsnDclrBzentyGridColumns: [],
      rghtScsnDclrBzentyGridAttr: {
        showRadio:true,
        showStatus:false,
        pageable: true,
        editable: false,
      },
    };
  },
  computed: {
    rghtScsnDclrBzentyFilteredSggCdList() {
      return this.rghtScsnDclrBzentySggCdList.filter(item => item.upSggStdgCd === this.rghtScsnDclrBzentySearchParam.ctpvStdgCd);
    },
  },
  watch: {},
  created() {
    storeSwitch.setBulb('RghtScsnDclrBzentyPopup', this);
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loadCtpvCd();
      this.loadSggCd();
      this.initializeBzentyGridColumns();
      this.initializeBzentyGridAttr();
    },
    loadCtpvCd() {
      const ctpvList = this.$store.getters.getSggList({ type: 'ctpv' });
      this.ctpvCdList = ctpvList.map(item => ({
        text: item.sggNm,
        value: item.sggStdgCd
      }));
    },
    loadSggCd() {
      const sggList = this.$store.getters.getSggList({ type: 'sgg' });
      this.rghtScsnDclrBzentySggCdList = sggList.map(item => ({
        text: item.sggNm,
        value: item.sggStdgCd,
        upSggStdgCd: item.upSggStdgCd
      }));
    },
    initializeBzentyGridColumns() {
      this.rghtScsnDclrBzentyGridColumns = [
        { field: 'bzentyId', caption: '업체아이디', visible: false },
        { field: 'bzentyNm', caption: '업체명', width: 120 },
        { field: 'brno', caption: '사업자등록번호', width: 120,
          format:(value) => {
            if(value && typeof value === 'string') {
              let cleanValue = value.replace(/\D/g, '');

              if (cleanValue.length > 10) {
                cleanValue = cleanValue.substring(0, 10);
              }

              return cleanValue.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
            }
            return value;
          },
        },
        { field: 'rprsvNm', caption: '대표자명', width: 120 },
        { field: 'bzentyAddr', caption: '주소', width: 200, align: 'left' }, 
        { field: 'bzentyDaddr', caption: '상세주소', width: 100, align: 'left' }, 
        { field: 'ctpvStdgCd',
          caption: '시·도',
          type: 'combo',
          items: this.ctpvCdList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 150,
          exportLabel : true,
        },
        { field: 'sggStdgCd',
          caption: '시·군·구',
          type: 'combo',
          items: this.rghtScsnDclrBzentySggCdList,
          comboLabel: 'text',
          comboValue: 'value',
          master: 'ctpvStdgCd',
          masterField: 'upSggStdgCd',
          useFilter: true,
          width: 150,
          exportLabel : true,
        },
      ]
    },
    initializeBzentyGridAttr() {
      this.rghtScsnDclrBzentyGridAttr.rowCss = (data) => {
        const keys = window.SBGrid3.getCheckedKeys(this.rghtScsnDclrBzentyGridObject);

        if (keys.includes(data.bzentyId)) {
          return 'grid-good';
        }

        return '';
      }
    },
    show() {
      this.isVisible = true;
      document.documentElement.style.overflow = 'hidden';
      this.rghtScsnDclrBzentyGridReload = true;
    },
    hide() {
      this.isVisible = false;
      document.documentElement.style.overflow = '';
    },
    search() {
      window.SBGrid3.clearSaveData(this.rghtScsnDclrBzentyGridObject);
      window.SBGrid3.reload(this.rghtScsnDclrBzentyGridObject);
    },
    reset() {
      this.rghtScsnDclrBzentySearchParam.bzentyNm = '';
      this.rghtScsnDclrBzentySearchParam.brno = '';
      this.rghtScsnDclrBzentySearchParam.ctpvStdgCd = '';
      this.rghtScsnDclrBzentySearchParam.sggStdgCd = '';
      this.search();
    },
    rghtScsnDclrBzentyGridLoaded() {
      this.rghtScsnDclrBzentyGridReload = false;
    },
    selectBzenty() {
      const selectedRow = window.SBGrid3.getFocusedRow(this.rghtScsnDclrBzentyGridObject);

      if (!selectedRow) {
        alert("선택된 업체가 없습니다.");
        return;
      }

      const formattedBrno = (() => {
        const value = selectedRow.data.brno;
        if (value && typeof value === 'string') {
          let cleanValue = value.replace(/\D/g, '');
          if (cleanValue.length > 10) {
            cleanValue = cleanValue.substring(0, 10);
          }
          return cleanValue.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
        }
        return value;
      })();

      const selectedData = {
        bzentyId: selectedRow.data.bzentyId,
        bzentyNm: selectedRow.data.bzentyNm,
        brno: formattedBrno,
        rprsvNm: selectedRow.data.rprsvNm,
        bzentyAddr: selectedRow.data.bzentyAddr, 
        bzentyDaddr: selectedRow.data.bzentyDaddr, 
      };

      this.$emit('select-bzenty', selectedData);
      this.hide();
    }
  }
}
</script>

<style scoped>
  .sch_form_wrap {
    margin-bottom: 2rem;
  }

  .sch_form > span:last-child {
    padding-right: 0rem;
  }
</style>