<template>
    <h2 class="title1" style="margin-top: 0px;">문의내용</h2>
    <div class="board_write">
        <table>
            <caption>나의 문의 내용</caption>
            <colgroup>
                <col style="width:18%">
                <col style="width:32%">
                <col style="width:18%">
                <col style="width:32%">
            </colgroup>
            <tbody>
                <tr>
                    <th scope="row">문의유형</th>
                    <td colspan="3">{{ param.onlnDscsnTaskSeNm }}</td>
                </tr>
                <tr>
                    <th scope="row">제목</th>
                    <td colspan="3">{{ param.dscsnTtl }}</td>
                </tr>
                <tr>
                    <th scope="row">내용</th>
                    <td colspan="3">
                        <div v-html="param.onlnDscsnQstnCn"></div>
                    </td>
                </tr>
                <tr>
                    <th scope="row">상담 첨부파일</th>
                    <td colspan="3" v-show="param.files.length != 0">
                        <br-attach-file label="상담첨부파일"
                                  atchFileTaskSeCd="ODC"
                                  v-model:atchFileGroupId="param.dscsnAtchFileGroupId"
                                  v-model:fileProcessingData="param.fileProcessingData"
                                  :files="param.files"
                                  :readonly="true"
                        />
                    </td>
                    <td colspan="3" v-show="param.files.length == 0">
                        첨부파일이 없습니다.
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <h2 class="title1">답변내용</h2>
    <div class="board_write">
        <table>
            <caption>나의 문의 답변 내용</caption>
            <colgroup>
                <col style="width:18%">
                <col style="width:32%">
                <col style="width:18%">
                <col style="width:32%">
            </colgroup>
            <tbody>
                <tr>
                    <th scope="row">답변내용</th>
                    <td colspan="3">
                        <div v-html="param.onlnDscsnAnsCn"></div>
                    </td>
                </tr>
                <tr>
                    <th scope="row">답변 첨부파일</th>
                    <td colspan="3" v-show="param.ansFiles.length != 0">
                        <br-attach-file label="답변첨부파일"
                                  atchFileTaskSeCd="ODC"
                                  v-model:atchFileGroupId="param.ansAtchFileGroupId"
                                  v-model:fileProcessingData="param.fileProcessingData2"
                                  :files="param.ansFiles"
                                  :readonly="true"
                        />
                    </td>
                    <td colspan="3" v-show="param.ansFiles.length == 0">
                        첨부파일이 없습니다.
                    </td>
                </tr>
                <tr>
                    <th scope="row">답변일자</th>
                    <td colspan="3">{{ param.ansYmd }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="btn_area right">
        <a href="/my-office/MyInqry" class="btn md tertiary">목록</a>
    </div>

</template>
    
    <script>
    import {storeSession} from "@/js/store/store-session";
  
    const API = {
        MY_INQRY : "/user/inqry",
        HOME : "/",
        MY_INQRY_EXCEL : "user/inqry",
    }
    
    export default {
      components: {},
      data() {
        return {
          param : {   // 조회조건
            onlnDscsnSn : "",
            dscsnTtl : "",
            onlnDscsnQstnCn : "",
            onlnDscsnTaskSeCd : "",
            onlnDscsnTaskSeNm : "",
            regDt : "",
            onlnDscsnSttsCd : "",
            onlnDscsnSttsNm : "",
            dscsnUserId : "",
            dscsnAtchFileGroupId : "",
            ansAtchFileGroupId : "",
            onlnDscsnAnsCn : "",
            ansYmd : "",

            fileProcessingData: {},
            fileProcessingData2: {},
            files: [],
            ansFiles: [],
          },
          userinfo: storeSession.getMemberInfo(),
          fileListTaskSeCd: 'ODC',
        };
      },
      computed: {
      },
      watch: {
      },
      created() {
        this.param.onlnDscsnSn = this.$route.query.onlnDscsnSn;
      },
      mounted() {
        this.inqryDetail();
      },
      methods: {
        navigateTo(path) {
          if (path) {
            this.$router.push(path).catch(() => {
                console.error("Navigation Error");
            });
          }
          window.scrollTo({ top: 0 });
        },
        excelDownload() {
            this.$apiCall.download(API.MY_INQRY_EXCEL, {
                "fileName" : "나의문의내역.xlsx"
            });
        },
        inqryDetail() {
            this.$apiCall.get(API.MY_INQRY + "/" + this.param.onlnDscsnSn, {}, this.success, this.error);
        },
        success(data) {
            if (!data.result) {
                this.error();
                return;
            }

            //this.param = data.result;

            const json = JSON.parse(JSON.stringify(data.result));
            this.param = json;

            this.param.files = []
            if(data.result.files){
                this.param.files = data.result.files;
            }
            
            this.param.ansFiles = []
            if(data.result.ansFiles){
                this.param.ansFiles = data.result.ansFiles;
            }
        },
        error() {
            alert("데이터를 가져오는 중 오류가 발생했습니다.");
        },
      },   
    }
    </script>
    
    <style scoped>
 

    </style>