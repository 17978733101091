<template>
  <div class="board_write">
    <table>
      <caption>실적년도, 사업장명, 사업장종류, 실적상태로 구성</caption>
      <colgroup>
        <col style="width:18%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:32%">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">실적년도</th>
        <td>{{ prfmncBasicInfo.prfmncYr }}-{{ prfmncBasicInfo.prfmncMm }}</td>
        <th scope="row">사업장명</th>
        <td>{{ prfmncBasicInfo.bplcNm }}</td>
      </tr>
      <tr>
        <th scope="row">사업장종류</th>
        <td>{{ prfmncBasicInfo.bplcKndNm }}</td>
        <th scope="row">실적상태</th>
        <td>{{ prfmncBasicInfo.prfmncSttsNm }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <rjct-rsn-area :prfmncBasicInfo="prfmncBasicInfo" />
  <prfmnc-mdfcn-prm-area :prfmncBasicInfo="prfmncBasicInfo" />

  <h3 class="title1">원재료 반입 현황(톤/월)</h3>
  <ul class="tab">
    <li :class="{ active: activeTab === 0 }" @click="setActiveTab(0, $event)">
      <button type="button" class="btn_tab">시도별 요약</button>
    </li>
    <li :class="{ active: activeTab === 1 }" @click="setActiveTab(1, $event)">
      <button type="button" class="btn_tab">폐기물 항목별 상세</button>
    </li>
  </ul>
  <section v-show="activeTab === 0">
    <div class="tab_conts">
      <sb-grid
          id="rawmtrlCrynGrid"
          :dataSource="rawmtrlCrynDataSource"
          :columns="rawmtrlCrynGridColumns"
          :gridattr="rawmtrlCrynGridAttr"
          v-model:grid="rawmtrlCrynGridObject"
          @loaded="rawmtrlCrynGridLoaded"
          :refreshGrid="rawmtrlCrynGridReload"
      />
    </div>
  </section>

  <section v-show="activeTab === 1">
    <div class="tab_conts">
      <sb-grid
          id="crynMtralGridReload"
          :dataSource="crynMtralDataSource"
          :columns="crynMtralGridColumns"
          :gridattr="crynMtralGridAttr"
          v-model:grid="crynMtralGridObject"
          @loaded="crynMtralGridLoaded"
          :refreshGrid="crynMtralGridReload"
          :countVisible="false"
      />
    </div>
  </section>

  <h2 class="title1">원재료 현황(톤/월)</h2>
  <div class="board_write thead">
    <table>
      <caption>폐기물 반입/투입량 - 이월량, 반입량, 투입량, 폐기반출량, 잔량으로 구성</caption>
      <colgroup>
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col>
      </colgroup>
      <thead>
      <tr>
        <th scope="col">이월량(톤)</th>
        <th scope="col">반입량(톤)</th>
        <th scope="col">투입량(톤)</th>
        <th scope="col">폐기반출량(톤)</th>
        <th scope="col">잔량(톤)</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td aria-label="이월량" class="text-end">{{ formatNumber(rawmtrlPrcsInfo.wtCrfqty) || "0.00" }} 톤</td>
        <td aria-label="반입량" class="text-end">{{ formatNumber(rawmtrlPrcsInfo.wtSumCryqty) || "0.00" }} 톤</td>
        <td aria-label="투입량" class="text-end">{{ formatNumber(rawmtrlPrcsInfo.wtSumInpqty) || "0.00" }} 톤</td>
        <td aria-label="폐기반출량" class="text-end">{{ formatNumber(rawmtrlPrcsInfo.wtDscdCrtqty) || "0.00" }} 톤</td>
        <td aria-label="잔량" class="text-end">{{ formatNumber(rawmtrlPrcsInfo.wtRmnqty) || "0.00" }} 톤</td>
      </tr>
      </tbody>
    </table>
  </div>

  <template v-if="prfmncBasicInfo.prfmncYr > 2024">
  <h2 class="title1">원재료 투입 현황(톤/월)</h2>
  <sb-grid
      id="rawmtrlInputGrid"
      :dataSource="rawmtrlInputDataSource"
      :columns="rawmtrlInputGridColumns"
      :gridattr="rawmtrlInputGridAttr"
      v-model:grid="rawmtrlInputGridObject"
      @loaded="rawmtrlInputGridLoaded"
      :refreshGrid="rawmtrlInputGridReload"
  />
  </template>

  <h2 class="title1">시설별 생산 현황(톤/월)</h2>
  <div class="grid_search">
    <h4><span>※ 연간시설용량 합계 : {{ formatNumber(prdctnInfo.tAnlFcltCap) }}(톤/년)</span></h4>
  </div>
  <sb-grid
      id="prdctnGrid"
      :dataSource="prdctnDataSource"
      :columns="prdctnGridColumns"
      :gridattr="prdctnGridAttr"
      v-model:grid="prdctnGridObject"
      @loaded="prdctnGridLoaded"
      :refreshGrid="prdctnGridReload"
  />

  <h2 class="title1">고형연료제품 판매 현황</h2>
  <h2 class="title2">판매 사업장별 판매 현황 (톤/월)</h2>
  <sb-grid
      id="ntslDtlGrid"
      :dataSource="ntslDtlDataSource"
      :columns="ntslDtlGridColumns"
      :gridattr="ntslDtlGridAttr"
      v-model:grid="ntslDtlGridObject"
      @loaded="ntslDtlGridLoaded"
      :refreshGrid="ntslDtlGridReload"
  />

  <h2 class="title2">고형연료제품별 판매 현황 (톤/월)</h2>
  <sb-grid
      id="ntslGrid"
      :dataSource="ntslDataSource"
      :columns="ntslGridColumns"
      :gridattr="ntslGridAttr"
      v-model:grid="ntslGridObject"
      @loaded="ntslGridLoaded"
      :refreshGrid="ntslGridReload"
  />

  <prfmnc-stts-hstry :prfmncId="prfmncBasicInfo.prfmncId" />
  <prfmnc-mdfcn-prm-hstry :prfmncId="prfmncBasicInfo.prfmncId" />

  <common-button-area
      :prfmncBasicInfo="prfmncBasicInfo"
  />
</template>

<script>
import SbGrid from "@/components/SbGrid.vue";
import RjctRsnArea from "@/views/prfmnc-rpt/dtl/components/RjctRsnArea.vue";
import PrfmncMdfcnPrmArea from "@/views/prfmnc-rpt/dtl/components/PrfmncMdfcnPrmArea.vue";
import prfmncSttsHstry from "@/views/prfmnc-rpt/components/PrfmncSttsHstry.vue";
import PrfmncMdfcnPrmHstry from "@/views/prfmnc-rpt/components/PrfmncMdfcnPrmHstry.vue";
import CommonButtonArea from "@/views/prfmnc-rpt/dtl/components/CommonButtonArea.vue";
import utils from "@/js/utils/utils";

const CODE = {
  SALES_SECTION: "SIS070"
};

export default {
  props: ['prfmncBasicInfo', 'rawmtrlCrynInfo', 'rawmtrlPrcsInfo' ,'rawmtrlInpuInfo', 'prdctnInfo', 'ntslDtlInfo', 'ntslInfo', 'crynMtralDtlInfo'],
  components: {
    SbGrid,
    RjctRsnArea,
    PrfmncMdfcnPrmArea,
    PrfmncMdfcnPrmHstry,
    prfmncSttsHstry,
    CommonButtonArea,
  },
  data() {
    return {
      activeTab: 0, // 초기 활성 탭 설정
      rawmtrlCrynGridReload: false,
      rawmtrlCrynGridObject: null,
      rawmtrlCrynDataSource: [],
      rawmtrlCrynGridColumns: [],
      rawmtrlCrynGridAttr: {
        showRowNo: false,
        pageable: false,
        excelExport: {
          fileName: '원재료 반입 현황(시도).xlsx',
          includeHeader: true,
          includeFormula: true,
        }
      },
      crynMtralGridReload: false,
      crynMtralGridObject: null,
      crynMtralDataSource: [],
      crynMtralGridColumns: [],
      crynMtralGridAttr: {
        showRowNo: false,
        pageable: false,
        excelExport: {
          fileName: '원재료 반입 현황(폐기물항목).xlsx',
          includeHeader: true,
          includeFormula: true,
        }
      },
      rawmtrlInputGridReload: false,
      rawmtrlInputGridObject: null,
      rawmtrlInputDataSource: [],
      rawmtrlInputGridColumns: [],
      rawmtrlInputGridAttr: {
        showRowNo: false,
        pageable: false,
        toolBar: [],
      },
      prdctnGridReload: false,
      prdctnGridObject: null,
      prdctnDataSource: [],
      prdctnGridColumns: [],
      prdctnGridAttr: {
        showRowNo: false,
        pageable: false,
        toolBar: [],
      },
      ntslDtlGridReload: false,
      ntslDtlGridObject: null,
      ntslDtlDataSource: [],
      ntslDtlGridColumns: [],
      ntslDtlGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        toolBar: [],
      },
      ntslGridReload: false,
      ntslGridObject: null,
      ntslDataSource: [],
      ntslGridColumns: [],
      ntslGridAttr: {
        showRowNo: false,
        pageable: false,
        toolBar: [],
      },
      ntslSeCdList: [],
    }
  },
  computed: {
  },
  watch: {
    rawmtrlCrynInfo() {
      this.initializeRawmtrlCryn();
      this.initializeCrynMtralDtl();
    },
    rawmtrlInpuInfo() {
      this.initializeRawmtrlInput();
    },
    prdctnInfo() {
      this.initializePrdctn();
    },
    ntslDtlInfo() {
      this.initializeNtslDtl();
    },
    ntslInfo() {
      this.loadPrfmncSttsCd();
      this.initializeNtsl();
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    loadPrfmncSttsCd() {
      const ntslSeCdList = this.$store.getters.getCodeList({ groupCd: CODE.SALES_SECTION, useYn: 'Y' });
      this.ntslSeCdList = ntslSeCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    initializeRawmtrlCryn() {
      this.rawmtrlCrynDataSource = this.rawmtrlCrynInfo.list;
      this.initializeRawmtrlCrynGridColumns();
      this.initializeRawmtrlCrynGridAttr();
      this.rawmtrlCrynGridReload = true;
    },
    initializeRawmtrlInput() {
      this.rawmtrlInputDataSource = this.rawmtrlInpuInfo.list;
      this.initializeRawmtrlInputGridColumns();
      this.initializeRawmtrlInputGridAttr();
      this.rawmtrlInputGridReload = true;
    },
    initializePrdctn() {
      this.prdctnDataSource = this.prdctnInfo.list;
      this.initializePrdctnGridColumns();
      this.initializePrdctnGridAttr();
      this.prdctnGridReload = true;
    },
    initializeNtslDtl() {
      this.ntslDtlDataSource = this.ntslDtlInfo.list;
      this.initializeNtslDtlGridColumns();
      this.initializeNtslDtlGridAttr();
      this.ntslDtlGridReload = true;
    },
    initializeNtsl() {
      this.ntslDataSource = { data: this.ntslInfo.list, schema: { id: 'prdctItemCd'}};
      this.initializeNtslGridColumns();
      this.initializeNtslGridAttr();
      this.ntslGridReload = true;
    },
    initializeCrynMtralDtl() {
      this.crynMtralDataSource = this.crynMtralDtlInfo.list;
      this.initializeCrynMtralGridColumns();
      this.initializeCrynMtralGridAttr();
      this.crynMtralGridReload = true;
    },
    initializeRawmtrlCrynGridColumns() {
      const columnCount = this.rawmtrlCrynInfo.inputItemList.length + 2;
      const columnWidth = columnCount > 0 ? (100 / columnCount).toFixed(2) : 100;

      this.rawmtrlCrynGridColumns = [
        { field: 'ctpvStdgNm', caption: '시·도', minWidth:150, width: columnWidth, unit: '%',
          total: {
            aggregates: [],
            header: [
              { template: '합계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        { caption: '원재료 종류(톤)' },
      ];

      if (this.rawmtrlCrynInfo.inputItemList && this.rawmtrlCrynInfo.inputItemList.length > 0) {
        this.rawmtrlCrynGridColumns[1].columns = [];

        const inputItemCds = this.rawmtrlCrynInfo.inputItemList.map(item => item.inputItemCd);

        this.rawmtrlCrynGridColumns[1].columns.push({
          field: 'sum', caption: '합계(톤)', minWidth:150, width: columnWidth, unit: '%',
          total: {
            aggregates: [ {func: 'sum', require: 'sum'} ],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
          calc: {
            require: inputItemCds,
            eval: 'sum',
            nullAs: 0,
            dataType: 'number',
          },
          align: 'right',
          format: '#,##0.00'
        });

        this.rawmtrlCrynInfo.inputItemList.forEach(item => {
          this.rawmtrlCrynGridColumns[1].columns.push({
            field: item.inputItemCd,
            caption: item.inputItemNm+'(톤)',
            minWidth: 100, width: columnWidth, unit: '%',
            total: {
              aggregates: [ {func: 'sum', require: item.inputItemCd} ],
              header: [{ template: '{{sum}}', format: '#,##0.00' }],
              headerCss:'grid-header',
            },
            align: 'right',
            format: '#,##0.00'
          });
        });
      }
    },
    initializeRawmtrlCrynGridAttr() {
      const itemCount = this.rawmtrlCrynInfo.list.length;

      this.rawmtrlCrynGridAttr.height = Math.max((itemCount * 30) + 150, 150)
    },
    initializeCrynMtralGridColumns() {
      this.crynMtralGridColumns = [
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false },
        { field: 'ctpvStdgNm', caption: '시도', width: 200, mergeRow: true
        },
        { field: 'wtItemCd', caption: '폐기물품목코드', visible: false },
        { field: 'wtItemNm', caption: '폐기물 구분', width: 200,
          mergeRow: (data) => data.ctpvStdgCd + '.' + data.wtItemCd
        },
        { field: 'srfMnftrMtralCd', caption: '폐기물 코드', width: 200 },
        { field: 'srfMnftrMtralNm', caption: '폐기물 코드명', maxWidth:1000, width:100, unit:'%', align: 'left'},
        { field: 'wtCryqty', caption: '반입량(톤)', width: 200, dataType: 'number', align: 'right', type:'number', format: (value) => {
          if(value && typeof value === 'number') {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          return value;}, total: { headerCss :'gridh-bg-total', aggregates: [{ func: 'sum'},], header: { template: '{{sum}}' , format : '#,##0.00' }, calc: { nullAs: 0, dataType : 'number' },},
        },
      ];
    },
    initializeCrynMtralGridAttr() {
      const itemCount = this.crynMtralDtlInfo.list.length;

      this.crynMtralGridAttr.height = Math.max((itemCount * 30) + 108, 150);
    },
    initializeRawmtrlInputGridColumns() {
      const columnCount = this.rawmtrlInpuInfo.inputItemList.length + 3;
      const columnWidth = columnCount > 0 ? (100 / columnCount).toFixed(2) : 100;

      this.rawmtrlInputGridColumns = [
        { field: 'fcltSqno', caption: '시설순번', visible: false },
        { field: 'fcltNm', caption: '시설명', minWidth:205, width: columnWidth, unit: '%', total: { headerCss:'grid-header' } },
        { field: 'prdctItemCd', caption: '제품품목코드', visible: false },
        { field: 'prdctItemNm', caption: '고형연료제품종류', minWidth:120, width: columnWidth, unit: '%', total: { headerCss:'grid-header' } },
        { caption: '원재료 투입 종류(톤)' },
      ];

      if (this.rawmtrlInpuInfo.inputItemList && this.rawmtrlInpuInfo.inputItemList.length > 0) {
        this.rawmtrlInputGridColumns[4].columns = [];

        const inputItemCds = this.rawmtrlInpuInfo.inputItemList.map(item => item.inputItemCd);

        this.rawmtrlInputGridColumns[4].columns.push({
          field: 'sum', caption: '합계', minWidth:150, width: columnWidth, unit: '%',
          total: { headerCss:'grid-header' },
          calc: {
            require: inputItemCds,
            eval: 'sum',
            nullAs: 0.00,
            dataType: 'number',
          },
          align: 'right',
          format: '#,##0.00'
        });

        this.rawmtrlInpuInfo.inputItemList.forEach(item => {
          this.rawmtrlInputGridColumns[4].columns.push({
            field: item.inputItemCd,
            caption: item.inputItemNm,
            minWidth: 100,
            width: columnWidth,
            unit: '%',
            dataType: 'number',
            align: 'right',
            format: '#,##0.00',
            total: { headerCss:'grid-header' },
          });
        });
      }
    },
    initializeRawmtrlInputGridAttr() {
      const itemCount = this.rawmtrlInpuInfo.list.length;

      this.rawmtrlInputGridAttr.height = Math.max((itemCount * 30) + 80, 150);
    },
    initializePrdctnGridColumns() {
      const is2025OrLater = this.prfmncBasicInfo.prfmncYr >= 2025;

      this.prdctnGridColumns = [
        { field: 'fcltSqno', caption: '시설순번', visible: false },
        { field: 'fcltNm', caption: '시설명', minWidth: 100, width: is2025OrLater ? 100 : 40, unit: '%', align: 'left', total: { headerCss:'grid-header' }},
        { field: 'prdctItemCd', caption: '제품품목코드', visible: false },
        { field: 'prdctItemNm', caption: '고형연료제품종류', width: 120,
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          },
        },
        { caption: '생산정보',
          columns: [
            { field: 'prdctPrdqty', caption: '생산량(톤)', dataType: 'number', align: 'right', format: '#,##0.00',
              width: is2025OrLater ? 150 : 20, ...(is2025OrLater ? {} : { unit: '%' }),
              total: {
                aggregates: [{ func: 'sum', require: 'prdctPrdqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'prdctRsdqty', caption: '잔재량(톤)', dataType: 'number', align: 'right', format: '#,##0.00',
              width: is2025OrLater ? 150 : 20, ...(is2025OrLater ? {} : { unit: '%' }),
              total: {
                aggregates: [ {func: 'sum', require: 'prdctRsdqty'} ],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'prdctPrdctnYldrt', caption: '생산수율(%)', dataType: 'number', align: 'right', format: '#,##0.00',
              width: is2025OrLater ? 150 : 20, ...(is2025OrLater ? {} : { unit: '%' }),
              colCss: 'grid-disabled',
              total: { headerCss:'grid-header' },
            },
          ]
        },
        ...(is2025OrLater ? [
          { caption: '생산량 검증', captionCss:'bg-green-light',
            columns: [
              { field: 'mthlyFcltCap', caption: '월간시설용량(톤/월)', dataType: 'number', width: 150, align: 'right', format: '#,##0.00', captionCss:'bg-green-light',
                total: {
                  aggregates: [{ func: 'sum', require: 'mthlyFcltCap'}],
                  header: [{ template: '{{sum}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
                colCss: 'grid-disabled',
                visible: false,
              },
              { field: 'mthlyexcsCap', caption: '초과용량(톤/월)', dataType: 'number', width: 150, align: 'right', captionCss:'bg-green-light',
                format: '#,##0.00',
                total: {
                  aggregates: [{
                    func: (items) => {
                      return items.reduce((sum, item) => {
                        const difference = (item.prdctPrdqty || 0) - (item.mthlyFcltCap || 0);
                        return sum + (difference > 0 ? difference : 0);
                      }, 0);
                    },
                    field: 'excsCapTotal'
                  }],
                  header: [{ template: '{{excsCapTotal}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
                getValue: (value, field, rowItem) => {
                  const prdctPrdqty = rowItem.data.prdctPrdqty || 0
                  const mthlyFcltCap = rowItem.data.mthlyFcltCap || 0;
                  const difference = prdctPrdqty - mthlyFcltCap;
                  return difference > 0 ? difference : '';
                },
                colCss: (data) => {
                  const prdctPrdqty = data.prdctPrdqty || 0;
                  const mthlyFcltCap = data.mthlyFcltCap || 0;

                  if (prdctPrdqty > mthlyFcltCap) {
                    return 'grid-danger';
                  } else {
                    return 'grid-disabled';
                  }
                },
                visible: false,
              },
              { field: 'vrfcRslt', caption: '검증결과', width: 150, captionCss:'bg-green-light',
                total: { headerCss:'grid-header' },
                getValue: (value, field, rowItem) => {
                  const prdctPrdqty = rowItem.data.prdctPrdqty || 0
                  const mthlyFcltCap = rowItem.data.mthlyFcltCap || 0;
                  return prdctPrdqty > mthlyFcltCap ? "초과" : "적합";
                },
                colCss: (data) => {
                  const prdctPrdqty = data.prdctPrdqty || 0;
                  const mthlyFcltCap = data.mthlyFcltCap || 0;

                  if (prdctPrdqty > mthlyFcltCap) {
                    return 'grid-danger';
                  } else {
                    return 'grid-good';
                  }
                },
                visible: false,
              },
            ]
          }
        ] : []),
      ];
    },
    initializePrdctnGridAttr() {
      const itemCount = this.prdctnInfo.list.length;

      this.prdctnGridAttr.height = Math.max((itemCount * 30) + 130, 150);
    },
    initializeNtslDtlGridColumns() {
      const list = this.ntslDtlInfo.list;
      const uniqueInputItemCdList = [...new Set(list.map(item => item.inputItemCd))];

      const inputItemCdNames = {
        '0001': 'SRF 성형',
        '0002': 'SRF 비성형',
        '0003': 'BIO-SRF 성형',
        '0004': 'BIO-SRF 비성형',
      };

      this.ntslDtlGridColumns = [
        { field: 'bplcId', caption: '사업장아이디', visible: false },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false },
        { field: 'ctpvStdgNm', caption: '시·도', width: 120, mergeRow: true, total: { headerCss:'grid-header' } },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false },
        { field: 'sggStdgNm', caption: '시·군·구', width: 120, mergeRow: true, total: { headerCss:'grid-header' } },
        { field: 'bplcNm', caption: '사업장명', mergeRow: true, minWidth: 250, width:100, unit:'%', align: 'left', total: { headerCss:'grid-header' } },
        { field: 'inputItemCd', caption: '고형연료제품코드', visible: false },
        { field: 'inputItemNm', caption: '고형연료제품종류', width: 120,
          total: {
            aggregates: [],
            header: [
              { template: '합계', align: 'right' },
              ...uniqueInputItemCdList.map(cd => ({
                template: inputItemCdNames[cd] || '', align: 'right'
              })),
            ],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctNsqty', caption: '판매량(톤)', type:'number', width: 130, align: 'right',
          dataType:'number', format: '#,##0.00',
          total: {
            aggregates: [
              {func: 'sum', require: 'prdctNsqty'},
              ...uniqueInputItemCdList.map(cd => ({
                func: (items) => items
                    .filter(item => item.inputItemCd === cd)
                    .reduce((sum, item) => sum + (item.prdctNsqty || 0), 0),
                field: `prdctNsqty${cd}`
              }))
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00' },
              ...uniqueInputItemCdList.map(cd => ({
                template: `{{prdctNsqty${cd}}}`, format: '#,##0.00'
              }))
            ],
            headerCss:'grid-header',
          },
        },
        { caption: '판매량 검증 (판매사업장의 실적 등록정보)', captionCss:'bg-green-light',
          columns: [
            { field: 'prdctAcpqty', caption: '검증 판매량(톤)', width: 150, align: 'right', captionCss:'bg-green-light',
              dataType: 'number', format: '#,##0.00',
              total: {
                aggregates: [
                  {func: 'sum', require: 'prdctAcpqty'},
                  ...uniqueInputItemCdList.map(cd => ({
                    func: (items) => items
                        .filter(item => item.inputItemCd === cd)
                        .reduce((sum, item) => sum + (item.prdctAcpqty || 0), 0),
                    field: `prdctAcpqty${cd}`
                  }))
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00' },
                  ...uniqueInputItemCdList.map(cd => ({
                    template: `{{prdctAcpqty${cd}}}`, format: '#,##0.00'
                  }))
                ],
                headerCss:'grid-header',
              },
              colCss: 'grid-disabled'
            },
            { field: 'diff', caption: '차이량', width: 150, align: 'right', captionCss:'bg-green-light',
              dataType: 'number', format: '#,##0.00',
              total: {
                aggregates: [
                  {
                    func: (items) => {
                      const totalNsqty = items.reduce((sum, item) => sum + (item.prdctNsqty || 0), 0);
                      const totalAcpqty = items.reduce((sum, item) => sum + (item.prdctAcpqty || 0), 0);
                      return Math.abs(totalNsqty - totalAcpqty);
                    },
                    field: 'diffTotal'
                  },
                  ...uniqueInputItemCdList.map(cd => ({
                    func: (items) => {
                      const totalNsqtyCd = items
                          .filter(item => item.inputItemCd === cd)
                          .reduce((sum, item) => sum + (item.prdctNsqty || 0), 0);
                      const totalAcpqtyCd = items
                          .filter(item => item.inputItemCd === cd)
                          .reduce((sum, item) => sum + (item.prdctAcpqty || 0), 0);

                      return Math.abs(totalNsqtyCd - totalAcpqtyCd);
                    },
                    field: `diff${cd}`
                  }))
                ],
                header: [
                  { template: '{{diffTotal}}', format: '#,##0.00' },
                  ...uniqueInputItemCdList.map(cd => ({
                    template: `{{diff${cd}}}`, format: '#,##0.00'
                  }))
                ],
                headerCss:'grid-header',
              },
              getValue: (value, field, rowItem) => {
                const prdctNsqty = rowItem.data.prdctNsqty || 0
                const prdctAcpqty = rowItem.data.prdctAcpqty || 0;
                const difference = prdctNsqty - prdctAcpqty;

                return difference !== 0 ? Math.abs(prdctNsqty - prdctAcpqty) : '';
              },
              colCss: (data) => {
                const prdctNsqty = data.prdctNsqty || 0
                const prdctAcpqty = data.prdctAcpqty || 0;

                if (prdctNsqty !== prdctAcpqty) {
                  return 'grid-danger';
                } else {
                  return 'grid-disabled';
                }
              }
            },
          ]
        },
      ];
    },
    initializeNtslDtlGridAttr() {
      const itemCount = this.ntslDtlInfo.list.length;
      const uniqueItemCount = new Set(this.ntslDtlInfo.list.map(item => item.inputItemCd)).size;

      this.ntslDtlGridAttr.height = Math.max(((itemCount + uniqueItemCount) * 30) + 120, 200);
    },
    initializeNtslGridColumns() {
      let colAddWidth = 8;
      if (this.prfmncBasicInfo.prfmncYr <= 2024) {
        colAddWidth = 20;
      }
      console.log("colAddWidth", colAddWidth);

      this.ntslGridColumns = [
        { field: 'bplcId', caption: '사업장아이디', visible: false },
        { field: 'prdctItemCd', caption: '고형연료제품코드', visible: false },
        { field: 'prdctItemNm', caption: '고형연료제품종류', part: 'head', width: 130+colAddWidth,
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctCrfqty', caption: '이월량(톤)', width: 110+colAddWidth, align: 'right',
          dataType:'number', format: '#,##0.00',
          total: {
            aggregates: [ {func: 'sum', require: 'prdctCrfqty'} ],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
          colCss: 'grid-disabled'
        },
        { field: 'prdqty', caption: '생산량(톤)', width: 110+colAddWidth, align: 'right',
          dataType:'number', format: '#,##0.00',
          total: {
            aggregates: [ {func: 'sum', require: 'prdqty'} ],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
          colCss: 'grid-disabled'
        },
        { field: 'prdctSumNsqty', caption: '판매량(톤)', width: 110+colAddWidth, align: 'right',
          dataType:'number', format: '#,##0.00',
          total: {
            aggregates: [ {func: 'sum', require: 'prdctSumNsqty'} ],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
          colCss: 'grid-disabled'
        },
        { field: 'prdctDscdCrtqty', caption: '폐기량(톤)', width: 110+colAddWidth, align: 'right',
          dataType:'number', format: '#,##0.00',
          total: {
            aggregates: [ {func: 'sum', require: 'prdctDscdCrtqty'} ],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctRmnqty', caption: '잔량(톤)', width: 110+colAddWidth, align: 'right',
          dataType:'number', format: '#,##0.00',
          total: {
            aggregates: [ {func: 'sum', require: 'prdctRmnqty'} ],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
          colCss: 'grid-disabled'
        },
        { field: 'ntslSeCd', caption: '판매구분',
          type: 'combo',
          items: this.ntslSeCdList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 90+colAddWidth,
          total: { headerCss:'grid-header' },
        },
        { field: 'prdctNtslPrc', caption: '제품가격(원/톤)', width: 100+colAddWidth, align: 'right',
          dataType:'number', format: '#,##0.00',
          total: { headerCss:'grid-header' },
          colCss: (data) => {
            if (data.ntslSeCd === "01") {
              return '';
            } else {
              return 'grid-disabled';
            }
          }
        },
        { field: 'prdctTransCst', caption: '운송비용(원/톤)', width: 100+colAddWidth, align: 'right',
          dataType:'number', format: '#,##0.00',
          total: { headerCss:'grid-header' },
          colCss: (data) => {
            if (data.ntslSeCd === "01") {
              return '';
            } else {
              return 'grid-disabled';
            }
          }
        },
        { field: 'prdctTransDistVl', caption: '거리(km)', width: 100+colAddWidth, align: 'right',
          dataType:'number', format: '#,##0.00',
          total: { headerCss:'grid-header' },
          colCss: (data) => {
            if (data.ntslSeCd === "01") {
              return '';
            } else {
              return 'grid-disabled';
            }
          }
        },
      ];

      if (this.prfmncBasicInfo.prfmncYr > 2024) {
        this.ntslGridColumns.splice(8, 0, {
          field: 'prdctLcp', caption: '저위발열량(kcal/kg)', width: 120, align: 'right',
          dataType: 'number', format: '#,##0.00',
          total: {
            aggregates: [
              { func: (items) => {
                  const filteredItems = items.filter(item => item.prdctLcp > 0);
                  const sumPrdqty = filteredItems.reduce((total, item) => total + (item.prdqty || 0), 0);
                  const sumPrdqtyLcp = filteredItems.reduce((total, item) => {
                    const prdqty = item.prdqty || 0;
                    const prdctLcp = item.prdctLcp || 0;
                    return total + (prdqty * prdctLcp);
                  }, 0);

                  return sumPrdqty > 0 ? utils.round2(sumPrdqtyLcp / sumPrdqty) : 0;
                },
                field: 'prdctLcpAvg'
              }
            ],
            header: [{ template: '{{prdctLcpAvg}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        });
      }
    },
    initializeNtslGridAttr() {
      const itemCount = this.ntslInfo.list.length;

      this.ntslGridAttr.height = Math.max((itemCount * 30) + 110, 150);
    },
    rawmtrlCrynGridLoaded() {
      this.rawmtrlCrynGridReload = false;
    },
    rawmtrlInputGridLoaded() {
      this.rawmtrlInputGridReload = false;
    },
    prdctnGridLoaded() {
      this.prdctnGridReload = false
    },
    ntslDtlGridLoaded() {
      this.ntslDtlGridReload = false
    },
    ntslGridLoaded() {
      this.setPrdctPrdqty();
      this.ntslGridReload = false
    },
    setPrdctPrdqty() {
      const allItems = window.SBGrid3.getAllItems(this.prdctnGridObject);

      const prdctPrdqtyTotals = {};
      allItems.forEach(item => {
        const prdctItemCd = item.prdctItemCd;
        const prdctPrdqty = item.prdctPrdqty || 0;

        if (prdctPrdqtyTotals[prdctItemCd]) {
          prdctPrdqtyTotals[prdctItemCd] += prdctPrdqty;
        } else {
          prdctPrdqtyTotals[prdctItemCd] = prdctPrdqty;
        }
      });

      const finalResult = Object.keys(prdctPrdqtyTotals).map(prdctItemCd => ({
        key: prdctItemCd,
        field: 'prdqty',
        value: prdctPrdqtyTotals[prdctItemCd]
      }));

      window.SBGrid3.setValues(this.ntslGridObject, finalResult);
      window.SBGrid3.clearSaveData(this.ntslGridObject);
    },
    formatNumber(number) {
      return utils.formatNumberWithComma(number);
    },
    setActiveTab(tabIndex, event) {
      event.preventDefault(); // 클릭 시 포커스 이동 방지
      this.activeTab = tabIndex;
    },
  }
}
</script>

<style scoped>
  .grid_box { margin-bottom: 5px; }
</style>