<template>
 <!-- 화면 타이틀 영역 -->
 <div class="board_info">
    <h2 class="title1">사용시설 인센티브 조회</h2>
  </div>
  <!-- 조회조건 영역 -->
  <div class="sch_form_wrap">
    <div class="sch_form row2">
      <span>
        <label for="select-bplcId">사업장</label>
        <br-form-select label="사업장" id="select-bplcId" v-model:value="searchParam.bplcId" :options="bplcIdOptions" />
      </span>
      <span>
          <label for="">검사기간</label>
          <div class="input_group">
            <br-form-select label="검사기간 (연도)" aria-label="검사기간 (연도)" v-model:value="searchParam.prdYear" :options="prdYearOptions" style="width: 100px;" />
            <br-form-select label="검사기간 (분기)" aria-label="검사기간 (분기)" v-model:value="searchParam.prdQtr" :options="prdQtrOptions" style="width: 100px;" />
          </div>
      </span>
    </div>
    <div class="btn_area">
      <br-anchor-button role="link" accesskey="a" label="검색" size="sm" format="sch" @click="searchBtn" />
      <br-anchor-button role="link" accesskey="b" label="새로고침" size="sm" format="txtN" icon="ico_refresh" @click="reset" />
    </div>
  </div>
  <!-- 그리드 소계 및 버튼 영역 -->
  <div class="board_info">
    <p class="page">
      <span class="total">전체 <strong>{{ this.icntvDataCount }}</strong></span>
      <span>페이지 <strong>{{ this.searchParam.page }}/{{ this.lastPage }}</strong></span>
    </p>
    <div>
      <br-anchor-button role="link" accesskey="c" label="엑셀다운로드" size="sm" icon="ico_excel" @click="excelDownload"/>
    </div>
  </div>
  <div class="board_list scroll">
    <table style="width: 100%;">
        <caption>연도, 분기, 제품종류, 최우수, 우수, 인센티브여부</caption>
        <colgroup>
            <col style="width:17%">
            <col style="width:17%">
            <col style="width:17%">
            <col style="width:16%">
            <col style="width:16%">
            <col style="width:17%">
        </colgroup>
        <thead>
            <tr>
                <th scope="col">실적연도</th>
                <th scope="col">실적분기</th>
                <th scope="col">제품종류</th>
                <th scope="col">최우수</th>
                <th scope="col">우수</th>
                <th scope="col">인센티브여부</th>
            </tr>
        </thead>
        <tbody>
          <tr v-if="icntvDataList.length <= 0" >
            <td colspan="5">
              <div style="display: flex; justify-content: center;">
                <div class="no-data-message">조회조건에 해당하는 데이터가 없습니다.</div>
              </div>
            </td>
          </tr>
          <template v-for="(item, index) in icntvDataList" :key="index">
              <!-- 첫 번째 조건: icntvPrgrsSttsCd가 '30'일 경우 -->
              <tr v-if="item.icntvPrgrsSttsCd === '30'" @dblclick="openPopup(item)">
                  <td aria-label="실적연도">{{ item.inspYr }}</td>
                  <td aria-label="실적분기">{{ item.inspQtr }}</td>
                  <td aria-label="제품종류">{{ item.prdctKndNm }}</td>
                  <td aria-label="최우수">{{ item.one }}</td>
                  <td aria-label="우수">{{ item.two }}</td>
                  <td aria-label="인센티브여부">{{ item.icntvTrgtYnNm }}</td>
              </tr>
              <!-- 기본값: 다른 모든 경우 -->
              <tr v-else >
                <td aria-label="실적연도">{{ item.inspYr }}</td>
                  <td aria-label="실적분기">{{ item.inspQtr }}</td>
                  <td aria-label="제품종류">{{ item.prdctKnmNm }}</td>
                  <td aria-label="최우수" colspan="3">심사중 입니다.</td>
              </tr>
          </template>
        </tbody> 
    </table>
  </div>
  <!-- 그리드 페이징 영역 -->
  <insp-pagination :total="icntvDataCount" 
                   :size="searchParam.size" 
                   v-model:page="searchParam.page" 
                   v-model:maxPage="lastPage" 
                   @clickedPage="search"></insp-pagination>

    <!-- s: modal popup 인센티브상세 -->
    <!-- <div :class="['modal', { visible: isModalIcntvDetailVisible }]" id="modalIcntvDetail">
        <div class="popup_box" tabindex="0">
            <h1 class="popup_title">인센티브 상세</h1>
            <div class="popup_cont">
                <h2 class="title1 mt_0">인센티브 상세</h2>
                        
                <div class="tbl_wrap">
                    <table>
                        <caption>접수번호, 공공업체여부, 반입사업장, 사업장종류, 제품종류, 품질등급발급번호, 품질등급, 등급점수, 반입량(톤), 총반입량(톤)</caption>
                        <colgroup>
                            <col style="width:15%">
                            <col style="width:10%">
                            <col style="width:10%">
                            <col style="width:10%">
                            <col style="width:10%">
                            <col style="width:15%">
                            <col style="width:7%">
                            <col style="width:5%">
                            <col style="width:7%">
                            <col style="width:7%">
                        </colgroup>
                        <thead>
                            <tr>
                                <th scope="col">접수번호</th>
                                <th scope="col">공공업체여부</th>
                                <th scope="col">반입사업장</th>
                                <th scope="col">사업장종류</th>
                                <th scope="col">제품종류</th>
                                <th scope="col">품질등급발급번호</th>
                                <th scope="col">품질등급</th>
                                <th scope="col">등급점수</th>
                                <th scope="col">반입량(톤)</th>
                                <th scope="col">총반입량(톤)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in icntvDetailObj.list" :key="index">
                                <td v-if="index === 0" :rowspan="icntvDetailObj.list.length">{{item.icntvInspRcptNo}}</td>
                                <td v-if="index === 0 || icntvDetailObj.list[index - 1].pubBzentyYn !== item.pubBzentyYn" 
                                :rowspan="icntvDetailObj.list.filter(i => i.pubBzentyYn === item.pubBzentyYn).length">{{item.pubBzentyYn}}</td>
                                <td>{{item.crynBplcNm}}</td>
                                <td v-if="index === 0 || icntvDetailObj.list[index - 1].bplcKndCd !== item.bplcKndCd" 
                                :rowspan="icntvDetailObj.list.filter(i => i.bplcKndCd === item.bplcKndCd).length">{{item.bplcKndCd}}</td>
                                <td v-if="index === 0 || icntvDetailObj.list[index - 1].prdctKndCd !== item.prdctKndCd" 
                                :rowspan="icntvDetailObj.list.filter(i => i.prdctKndCd === item.prdctKndCd).length">{{item.prdctKndCd}}</td>
                                <td>{{item.qltyGrdIssuNo}}</td>
                                <td>{{item.qltyGrdCd}}</td>
                                <td>{{item.qltyGrdScr}}</td>
                                <td>{{item.prdctAcpqty}}</td>
                                <td v-if="index === 0" :rowspan="icntvDetailObj.list.length">{{item.totalAcpqty}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="btn_area center">
                    <button type="button" class="btn md" @click="closeModal">닫기</button> 
                </div>
            </div>
            <button type="button" class="modal_close" @click="closeModal">팝업 닫기</button>
        </div>
    </div> -->
    <!-- e: modal popup 인센티브상세 -->
    <list-popup @list-button-clicked="openList" :icntvInfo="icntvInfo"></list-popup>
</template>

<script>
/**
 * 사용사업장의 인센티브에 대한 현황 조회 및 검사신청 처리 화면
 *
 * 작성자 : 김지환
 * 작성일 : 11/04/2024
 * 버전 : 1.0
 */
import { storeSwitch } from "@/js/store/store-switch";
// import {buildUri} from "@/js/utils/uri-builder";
import inspPagination from "@/views/sfli/components/inspPagination.vue";
import ListPopup from "@/views/qlty-grd/IcntvListPopup.vue";
const PAGE_SIZE = 10;
export default {
  components: {inspPagination,ListPopup},
  data() {
    return {
      icntvDetailObj : {},
      searchParam: {
        bplcId: "",
        prdYear: new Date().getFullYear(),
        prdQtr: Math.ceil((new Date().getMonth() + 1) / 3).toString().padStart(2, '0'), // 현재 월 기준으로 분기 계산
        page: 1,
      },
      lastPage: 1,
      icntvDataCount: 0,
      icntvDataList: [],
      bplcIdOptions: [],
      icntvInfo: {}, 
      prdYearOptions: this.generateYearOptions(),
      prdQtrOptions : [
          {value : "01", text : "1분기"},
          {value : "02", text : "2분기"},
          {value : "03", text : "3분기"},
          {value : "04", text : "4분기"},
      ],
      isModalIcntvDetailVisible: false,
    };
  },
  computed: {},
  watch: {
    'searchParam.prdYear': 'updateYearQtr',
    'searchParam.prdQtr': 'updateYearQtr',
  },
  created() {
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
        await this.generatebplcCdOptions();
        await this.updateYearQtr();
        await this.search();
    },
    generatebplcCdOptions() {
      return new Promise((resolve, reject) => {
          this.$apiCall.get('/icntv/bplcList', {}, data => {
            if (data.result) {
              const allList = data.result.bplcList;
              this.bplcIdOptions =  allList
                .map(item => ({
                  value: item.bplcId, // value는 시군구 코드
                  text: item.bplcNm // text는 시군구 이름
              }));

              if (this.bplcIdOptions && this.bplcIdOptions.length > 0) {
                this.searchParam.bplcId = this.bplcIdOptions[0].value;
              }
              resolve();
            } else {
              reject(new Error("Failed to load generatebplcCdOptions section codes."));
            }
          });
        });
    },
    generateYearOptions() {
      const currentYear = new Date().getFullYear() + 1;
      const startYear = 2018;
      const yearOptions = [];

      for (let year = currentYear; year >= startYear; year--) {
          yearOptions.push({ value: String(year), text: `${year}년` });
      }

      return yearOptions;
    },    
    updateYearQtr() {
        let year = parseInt(this.searchParam.prdYear);
        let qtr = parseInt(this.searchParam.prdQtr);

        // 전 분기 계산
        if (qtr === 1) {
          year -= 1;
          qtr = 4;
        } else {
          qtr -= 1;
        }

        this.searchParam.prfmncQtr = `${year}${qtr.toString().padStart(2, '0')}`;
    },
    search() {
      this.searchParam.size = PAGE_SIZE;
      storeSwitch.on("loading");  // ============로딩바 시작
      this.$apiCall.get('/icntv', this.searchParam, data => {
        if (data.result.list) {
          this.icntvDataCount = data.result.total;
          this.icntvDataList = data.result.list;
        } else {
          this.icntvDataList = [];
        }
      });
      storeSwitch.off("loading");  // ===========로딩바 종료
    },
    openPopup(data) {
        this.icntvInfo = {};
        this.icntvInfo = {prfmncQtr: data.inspYr + '' + data.inspQtr.toString().padStart(2, '0'), 
                          bplcId: data.bplcId,
                          prdctKndCd: data.prdctKndCd === undefined ? '' : data.prdctKndCd,
                          chkPop: 'Y'};
        /** 팝업창 호출 */
        storeSwitch.on('listPopup');
    },   
    // openModal(pModalId) { 
    //   switch (pModalId) {
    //     case '#modalIcntvDetail':
    //       this.isModalIcntvDetailVisible = true;
    //       break; 
    //     default:
    //       break;
    //   }
    // },
    // closeModal() { 
    //     this.isModalIcntvDetailVisible = false; 
    // },
    // goDetail(item) {
    //   this.$apiCall.get(buildUri('/icntv/icntvInfoList', {}), 
    //                             {
    //                               prfmncQtr: item.inspYr + '' + item.inspQtr.toString().padStart(2, '0'), 
    //                               bplcId: item.bplcId,                                  
    //                             }, this.readIcntvDetailSuccess, this.error);
    // },
    // readIcntvDetailSuccess(data) {
    //   if (!data.result) {
    //     this.error();
    //     return;
    //   }

    //   this.icntvDetailObj = data.result;
    //   this.openModal('#modalIcntvDetail'); 
    // },
    // error() {
    //   alert("데이터를 가져오는 중 오류가 발생했습니다.");
    // },
    excelDownload() {
      let searchParam =  { ...this.searchParam };
      this.$apiCall.download(
        "/icntv/excel-download", 
        searchParam
      );
    },
    reset() {
      this.searchParam.prdYear = new Date().getFullYear();
      this.searchParam.prdQtr = Math.ceil((new Date().getMonth() + 1) / 3).toString().padStart(2, '0');
    },
    searchBtn() {
      this.searchParam.page = 1
      this.search();
    },
  }
}
</script>

<style scoped>
  .tbl_wrap {
      width: 100%;
      font-size: 12px; /* 모든 자손 요소 텍스트 크기 14px */
  }
  .board_list.scroll > table { table-layout: fixed; width:120rem;}

.no-data-message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  margin: 20px 0px;
  border-radius: 10px;
  background-color: #f7f7f7;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 350px;
}

.no-data-message::before { content:''; width:2rem; height:2rem; background:url(@/assets/images/common/icon_search.png) no-repeat; background-size: 2rem; margin-right: 8px;}

.board_list.scroll > table tbody tr:hover{ background:var(--bg-gray10)}
</style>