import axios from "axios";
import { storeSession } from "./store/store-session";
import { storeSwitch } from "./store/store-switch";
import router from "./router";
import token from "./refresh-token";
import { v4 as uuidv4 } from "uuid";

const EXCLUDE_URLS = [
    "/auth/access-token",
    "/proxy/access-token",
];

let isAlertVisible = false;  // 현재 alert 상태
let pendingRequests = [];    // 5초 이상 걸린 요청을 저장하는 큐 (Queue)

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    config => {
        const requestId = uuidv4();
        config.metadata = { requestId };

        const timeout = setTimeout(() => {
            pendingRequests.push(requestId);

            if (!isAlertVisible) {
                isAlertVisible = true;
                storeSwitch.on("pendingAlert");
            }
        }, 5000);

        if (!config.url.startsWith("/api")) {
            config.url = `/api${config.url}`;
        }

        const isExcludedUrl  = EXCLUDE_URLS.some(url => config.url.includes(url));

        if (!isExcludedUrl) {
            const accessToken = storeSession.getTokenInfo()?.accessToken;
            if (accessToken) {
                config.headers["Authorization"] = `${accessToken}`;
            }
        }

        config.metadata.timeout = timeout;
        return config;
    },
    error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    response => {
        handleRequestEnd(response.config.metadata.requestId, response.config.metadata.timeout);
        return response;
    },
    error => {
        if (error.config?.metadata) {
            handleRequestEnd(error.config.metadata.requestId, error.config.metadata.timeout);
        }

        if (error.response.status === 401) {
            storeSession.removeSession();
            router.push("/timeout");
            return Promise.reject(error);
        }

        if (error.response.status === 409) {
            return refreshToken(error);
        }

        // 권한 예외
        if (error.response.status === 403) {
            const data = error.response.data;

            if (!data) {
                return Promise.reject(error);
            }

            error.isNotBusinessError = true;
            alert(data.message);
        }

        return Promise.reject(error);
    }
);

function handleRequestEnd(requestId, timeout) {
    clearTimeout(timeout); // 타이머 해제
  
    // pendingRequests에서 해당 요청 제거
    pendingRequests = pendingRequests.filter(id => id !== requestId);
  
    // 모든 요청이 끝나면 alert 닫기
    if (pendingRequests.length === 0) {
      isAlertVisible = false;
      storeSwitch.off("pendingAlert");
    }
}

async function refreshToken(error) {
    const data = error.response.data;

    if (!data || data.code !== 2030) {
        return Promise.reject(error);
    }

    try {
        const originalRequest = new Promise((resolve) => {
            token.addSubscriber((accessToken) => {
                error.config.headers["Authorization"] = `${accessToken}`;
                resolve(axiosInstance(error.config));
            });
        })

        await token.renew();

        return await originalRequest;
    } catch (e) {
        return Promise.reject(e);
    }

}

function HEADER_JSON() {
    return {
        headers: {
            "Content-Type": "application/json",
            "X-WES-UI-Menu": storeSession.getMenuSn(),
            "X-WES-UI-User": storeSession.getUserId(),
        }
    }
}

function HEADER_DOWNLOAD() {
    return {
        headers: {
            "Content-Type": "application/json",
            "X-WES-UI-Menu": storeSession.getMenuSn(),
            "X-WES-UI-User": storeSession.getUserId(),
        },
        responseType: "blob",
    }
}

function HEADER_UPLOAD() {
    return {
        headers: {
            "Content-Type": "multipart/form-data",
            "X-WES-UI-Menu": storeSession.getMenuSn(),
            "X-WES-UI-User": storeSession.getUserId(),
        },
    }
}

function PARAMETER(path, para, onSuccess, onFail) {
    return {
        parameters: {
            path: path,
            para: para,
            onSuccess: onSuccess,
            onFail: onFail
        }
    }
}

const RESULT = {
    SUCCESS: 0,
    FAIL: 1,
};

function checkSession() {
    if (!storeSession.hasSession()) {
        console.log("checkSession");
        router.push("/timeout");
        return;
    }
    // toggleLoadingBar(true);
}

function doResponse(path, response, onSuccess, onFail, para){
    // toggleLoadingBar(false);
    let data = response.data;
    if (data.code === RESULT.SUCCESS) {
        if (typeof onSuccess === "function") {
            onSuccess(data, para);
        }
    } else {
        if (typeof onFail === "function") {
            let alert = {
                title: "서비스 에러",
                code: data.code,
                message: data.message,
            };
            onFail(alert, para);
        } else {
            alert("API 호출 과정에 에러가 발생하였습니다.");
        }
    }
}

function toggleLoadingBar(state) {
    if (state) {
        storeSwitch.on("loading");
    } else {
        storeSwitch.off("loading");
    }
}

function doError(error, onFail, para) {
    toggleLoadingBar(false);

    if (error.isNotBusinessError) {
        return;
    }

    const data = error.response?.data || error;

    if (typeof onFail === "function") {
        onFail(data, para);
    }
}

export default {
    get: function(path, para, onSuccess, onFail) {
        checkSession();
        let url = path + "?";
        if (para instanceof Object) {
            for (let property in para) {
                url += `${property}=${para[property]}&`;
            }
        }

        const config = {
            ...HEADER_JSON(),
            ...PARAMETER(path, para, onSuccess, onFail)
        };

        axiosInstance
            .get(url, config)
            .then((response) => doResponse(path, response, onSuccess, onFail, para))
            .catch((error) => doError(error, onFail, para));
    },
    post: function(path, para, onSuccess, onFail) {
        checkSession();

        const config = {
            ...HEADER_JSON(),
            ...PARAMETER(path, para, onSuccess, onFail)
        };

        axiosInstance
            .post(path, para, config)
            .then((response) => doResponse(path, response, onSuccess, onFail, para))
            .catch((error) => doError(error, onFail, para));
    },
    put: function(path, para, onSuccess, onFail) {
        checkSession();

        const config = {
            ...HEADER_JSON(),
            ...PARAMETER(path, para, onSuccess, onFail)
        };

        axiosInstance
            .put(path, para, config)
            .then((response) => doResponse(path, response, onSuccess, onFail, para))
            .catch((error) => doError(error, onFail, para));
    },
    delete: function(path, para, onSuccess, onFail) {
        checkSession();

        const config = {
            data: para,
            ...HEADER_JSON(),
            ...PARAMETER(path, para, onSuccess, onFail)
        };

        axiosInstance
            .delete(path, config)
            .then((response) => doResponse(path, response, onSuccess, onFail, para))
            .catch((error) => doError(error, onFail, para));
    },
    download: function(path, para, filename, onFail) {
        checkSession();
        let url = path + "?";
        if (para instanceof Object) {
            for (let property in para) {
                url += `${property}=${para[property]}&`;
            }
        }
        axiosInstance
            .get(url, HEADER_DOWNLOAD())
            .then((response) => {
                toggleLoadingBar(false);

                const contentDisposition = response.headers['content-disposition'];
                if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
                    const filenameMatch = contentDisposition.match(/filename="([^"]+)"/);
                    if (filenameMatch.length === 2) {
                        filename = decodeURIComponent(filenameMatch[1]);
                    }
                }

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();

                // 다운로드 완료 후 <a> 요소 제거
                document.body.removeChild(link);

                // 임시 URL 해제, 메모리 회수
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                toggleLoadingBar(false);
                onFail(error, para);
            });
    },
    downloadPost: function(path, para, filename, onFail) {
        checkSession();

        const config = {
            ...HEADER_DOWNLOAD(),
        };

        axiosInstance
            .post(path, para, config)
            .then((response) => {
                toggleLoadingBar(false);

                const contentDisposition = response.headers['content-disposition'];
                if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
                    const filenameMatch = contentDisposition.match(/filename="([^"]+)"/);
                    if (filenameMatch.length === 2) {
                        filename = decodeURIComponent(filenameMatch[1]);
                    }
                }

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();

                // 다운로드 완료 후 <a> 요소 제거
                document.body.removeChild(link);

                // 임시 URL 해제, 메모리 회수
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                toggleLoadingBar(false);
                onFail(error, para);
            });
    },
    upload: function(path, formData, onSuccess, onFail) {
        checkSession();
        axiosInstance
            .post(path, formData, HEADER_UPLOAD())
            .then((response) => {
                toggleLoadingBar(false);
                onSuccess(response, formData);
            })
            .catch((error) => {
                toggleLoadingBar(false);
                onFail(error, formData);
            });
    },
    read: function(path, para, onSuccess, onFail) {
        let url = path + "?";
        if (para instanceof Object) {
            for (let property in para) {
                url += `${property}=${para[property]}&`;
            }
        }
        axiosInstance
            .get(url, HEADER_JSON())
            .then((response) => doResponse(path, response, onSuccess, onFail, para))
            .catch((error) => doError(error, onFail, para));
    },
    write: function(path, para, onSuccess, onFail) {
        axiosInstance
            .post(path, para, HEADER_JSON())
            .then((response) => doResponse(path, response, onSuccess, onFail, para))
            .catch((error) => doError(error, onFail, para));
    }
};