<template>

  <h2 class="title1 mt_0">실적보고 상세</h2>
  <div class="board_write">
    <table>
      <caption>실적연도, 실적상태, 사업장명, 사업장종류</caption>
      <colgroup>
        <col style="width:18%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:32%">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">실적연월</th>
        <td>{{ formattedPrfmncYm }}</td>
        <th scope="row">실적상태</th>
        <td>{{ fillGasDtlObj.prfmncMst.prfmncSttsNm || "작성중" }}</td>
      </tr>
      <tr>
        <th scope="row">사업장명</th>
        <td>{{ fillGasDtlObj.prfmncMst.bplcNm }}</td>
        <th scope="row">사업장종류</th>
        <td>{{ fillGasDtlObj.prfmncMst.bplcKndNm }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <rjct-rsn-area :prfmncBasicInfo="fillGasDtlObj.prfmncMst" />
  <prfmnc-mdfcn-prm-area :prfmncBasicInfo="fillGasDtlObj.prfmncMst" />

  <div class="board_info mt_40">
    <h3 class="title2">폐기물 반입 현황</h3>
  </div>

  <sb-grid
      id="prfmncDtlFillGasWtCrynGrid"
      :dataSource="dataSourceFillGasWtCryn"
      :columns="gridColumnsFillGasWtCryn"
      :gridattr="gridAttrFillGasWtCryn"
      v-model:grid="prfmncFillGasWtCrynGridObject"
      @loaded="gridLoadedFillGasWtCryn"
      :refreshGrid="gridReloadFillGasWtCryn"
  />

  <div class="board_info mt_40">
    <h3 class="title2">폐기물 매립 현황</h3>
  </div>
  <div class="board_write thead">
    <table>
      <caption>누적 매립 현황</caption>
      <colgroup>
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 15%;">
        <col style="width: 15%;">
        <col style="width: 10%;">
      </colgroup>
      <thead>
      <tr>
        <th scope="col" rowspan="2">
          이전 누적 매립량(톤)
          <div class="tooltip">
            <button type="button" class="btn_tool" @mouseover="handleTooltip" @mouseout="handleTooltip">도움말</button>
            <div class="tool_cont" style="display: none;">
              <p>당월 매립량을 포함하지 않은<br/>이전 실적까지의 누적 매립량</p>
            </div>
          </div>
          <br />
          (A)
        </th>
        <th scope="col" rowspan="2">당월 매립량(톤)<br />(B)</th>
        <th scope="col" rowspan="2">
          총 누적 매립량(톤)
          <div class="tooltip">
            <button type="button" class="btn_tool" @mouseover="handleTooltip" @mouseout="handleTooltip">도움말</button>
            <div class="tool_cont" style="display: none;">
              <p>당월 매립량을 포함한<br/>현재 실적까지의 누적 매립량</p>
            </div>
          </div>
          <br />
          (A + B)
        </th>
        <th scope="col" colspan="3" class="gridh-bg-validation">총 누적 매립량 검증</th>
      </tr>
      <tr>
        <th scope="row" class="gridh-bg-validation" style="border-left: 1px solid #e0e0e0;">최대 매립용량(톤)</th>
        <th scope="row" class="gridh-bg-validation">초과용량(톤)</th>
        <th scope="row" class="gridh-bg-validation">검증결과</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="txt_right" aria-label="이전 누적 매립량(톤)(A)">{{ formatNumber(prfmncFillGasPrevMonthSumFillWtTon?.prevMonthSumFillWtTon || 0) }} 톤</td>
        <td class="txt_right" aria-label="당월 매립량(톤)(B)">{{ formatNumber(fillGasDtlObj.prfmncFillGas.fillWtTon) }} 톤</td>
        <td class="txt_right" aria-label="총 누적 매립량(톤)(A + B)">{{ formatNumber(totalFillWtTon) }} 톤</td>
        <td class="txt_right" aria-label="최대 매립용량(톤)">{{ formatNumber(fillGasDtlObj.prfmncFillGas.maxFillCapTon) }} 톤</td>
        <td class="txt_right" aria-label="초과용량(톤)" :class="fillGasDtlObj.prfmncFillGas.excsFillTon > 0 ? 'not-good' : ''">{{ formatNumber(fillGasDtlObj.prfmncFillGas.excsFillTon) }} 톤</td>
        <td class="txt_center" aria-label="검증결과" :class="fillVrfcRslt === '초과' ? 'not-good' : ''">{{ fillVrfcRslt }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info mt_40">
    <h3 class="title2">메탄가스 생산 현황</h3>
  </div>
  <div class="board_write mb_25">
    <table>
      <caption>발생가스(Land Fill Gas)포집량 - 발생가스 총 포집량, 발생가스 총 포집량 중 메탄가스량, 메탄가스농도로 구성</caption>
      <colgroup>
        <col style="width: 15%;">
        <col :style="{width: `calc(55% / 3)`}">
        <col style="width: 15%;">
        <col :style="{width: `calc(55% / 3)`}">
        <col style="width: 15%;">
        <col :style="{width: `calc(55% / 3)`}">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">발생가스 총 포집량</th>
        <td class="txt_right">{{ formatNumber(fillGasDtlObj.prfmncFillGas.ocrnGasClcqty) }} N㎥</td>
        <th scope="row">발생가스 총 포집량 중 메탄가스량</th>
        <td class="txt_right">{{ formatNumber(fillGasDtlObj.prfmncFillGas.mthnGasClcqty) }} N㎥</td>
        <th scope="row">메탄가스농도</th>
        <td class="txt_right">{{ formatNumber(fillGasDtlObj.prfmncFillGas.mthnGasDens) }} %</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info mt_40">
    <h3 class="title2">메탄가스 이용 현황</h3>
  </div>
  <h4 class="title3">메탄가스 이용 현황</h4>
  <div class="board_write thead">
    <table>
      <caption>메탄가스 이용 현황</caption>
      <colgroup>
        <col style="width: 20%;">
        <col style="width: 30%;">
        <col style="width: 20%;">
        <col style="width: 30%;">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">
          총 이용량
          <div class="tooltip">
            <button type="button" class="btn_tool" @mouseover="handleTooltip" @mouseout="handleTooltip">도움말</button>
            <div class="tool_cont" style="display: none;">
              <p>자체사용합계(A)+외부공급합계(B)</p>
            </div>
          </div>
        </th>
        <td class="txt_right" aria-label="총 이용량">
          {{ formatNumber(fillGasDtlObj.prfmncFillGas.gasStotUsqty) }} N㎥
        </td>
        <th scope="row">미이용(단순처리)</th>
        <td class="txt_right" aria-label="미이용(단순처리)">
          {{ formatNumber(fillGasDtlObj.prfmncFillGas.enePrdctnExcqty) }} N㎥
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h4 class="title3">자체사용 현황</h4>
  <div class="board_write thead">
    <table>
      <caption>자체사용 현황</caption>
      <colgroup>
        <col style="width: 15%;">
        <col :style="{width: `calc(55% / 3)`}">
        <col style="width: 15%;">
        <col :style="{width: `calc(55% / 3)`}">
        <col style="width: 15%;">
        <col :style="{width: `calc(55% / 3)`}">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">자체사용 합계(A)</th>
        <td class="txt_left" colspan="5" aria-label="자체사용 합계(A)">
          {{ formatNumber(totalSlfUtztn) }} N㎥
        </td>
      </tr>
      <tr>
        <th scope="row">
          전기
          <div class="tooltip">
            <button type="button" class="btn_tool" @mouseover="handleTooltip" @mouseout="handleTooltip">도움말</button>
            <div class="tool_cont" style="display: none;">
              <p>전기 생산을 위해 활용한</p>
              <p>월간 매립가스량</p>
            </div>
          </div>
        </th>
        <td class="txt_right" aria-label="전기">
          {{ formatNumber(fillGasDtlObj.prfmncFillGas.elctgnUsgGasUsqty) }} N㎥
        </td>
        <th scope="row">
          열원
          <div class="tooltip">
            <button type="button" class="btn_tool" @mouseover="handleTooltip" @mouseout="handleTooltip">도움말</button>
            <div class="tool_cont" style="display: none;">
              <p>증기(스팀), 열에너지 이용을 위해</p>
              <p>활용한 월간 매립가스량</p>
              <p>(보일러, 건조기, 소화설비 등 이용)</p>
            </div>
          </div>
        </th>
        <td class="txt_right" aria-label="열원">
          {{ formatNumber(fillGasDtlObj.prfmncFillGas.cdysmUsgGasUsqty) }} N㎥
        </td>
        <th scope="row">
          기타
          <div class="tooltip">
            <button type="button" class="btn_tool" @mouseover="handleTooltip" @mouseout="handleTooltip">도움말</button>
            <div class="tool_cont" style="display: none;">
              <p>열원, 전기 활용 목적 외 수소가스, 천연가스(CNG), 도시가스 생산을 위해 활용한 월간 매립가스량</p>
            </div>
          </div>
        </th>
        <td class="txt_right" aria-label="기타">
          {{ formatNumber(fillGasDtlObj.prfmncFillGas.etcUsgGasUsqty) }} N㎥
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info mt_40">
    <h3 class="title2">메탄가스 판매 현황</h3>
  </div>
  <div class="grid_search">
    <h4><strong class="mr_5">(메탄가스(CH₄) 판매량)</strong>업체별 판매량 현황
      <span>※ 판매업체 목록은 에너지공급업체 현황에서 관리 할 수 있습니다.</span>
    </h4>
  </div>
  <sb-grid
      id="prfmncFillGasNtslGrid"
      :dataSource="dataSourceFillGasNtsl"
      :columns="gridColumnsFillGasNtsl"
      :gridattr="gridAttrFillGasNtsl"
      v-model:grid="prfmncFillGasNtslGridObject"
      @loaded="gridLoadedFillGasNtsl"
      :refreshGrid="gridReloadFillGasNtsl"
  />

  <!-- 실적상태이력 현황 -->
  <prfmnc-stts-hstry :prfmncId="prfmncMst.prfmncId" />

  <!-- 수정허용요청 이력 현황 -->
  <prfmnc-mdfcn-prm-hstry :prfmncId="prfmncMst.prfmncId" />

  <!-- 버튼 -->
  <common-button-area
      :prfmncBasicInfo="fillGasDtlObj.prfmncMst"
  />

</template>
<script>
import SbGrid from "@/components/SbGrid.vue";
import utils from "@/js/utils/utils";
import commonButtonArea from "@/views/prfmnc-rpt/dtl/components/CommonButtonArea.vue";
import prfmncSttsHstry from "@/views/prfmnc-rpt/components/PrfmncSttsHstry.vue";
import PrfmncMdfcnPrmHstry from "@/views/prfmnc-rpt/components/PrfmncMdfcnPrmHstry.vue";
import PrfmncMdfcnPrmArea from "@/views/prfmnc-rpt/dtl/components/PrfmncMdfcnPrmArea.vue";
import RjctRsnArea from "@/views/prfmnc-rpt/dtl/components/RjctRsnArea.vue";

export default {
  components: {
    RjctRsnArea, PrfmncMdfcnPrmArea,
    SbGrid,
    commonButtonArea,
    prfmncSttsHstry,
    PrfmncMdfcnPrmHstry
  },
  props: ['prfmncFillGas', 'prfmncFillGasPrevMonthSumFillWtTon', 'prfmncFillGasWtCrynList', 'prfmncFillGasNtslList', 'prfmncMst', 'prfmncFclt', 'showDetail'],
  data() {
    return {
      isPrfmncFillGasUpdated: false, // prfmncFillGas watch 처리 여부
      isPrfmncFcltUpdated: false, // prfmncFclt watch 처리 여부
      // 실적 매립가스 상세정보 데이터
      fillGasDtlObj:{
        totalGasStotNsqty: 0, // 메탄가스 총 판매량 합계
        prfmncMst: {},
        prfmncFillGasWtCrynList : [], // 폐기물반입현황
        prfmncFillGasNtslList : [], // 메탄가스판매현황
        prfmncFillGas: {
          fillWstqty: 0, // 매립폐기물량
          fillWtTon: 0, // 매립폐기물톤수
          maxFillCapTon: 0, // 최대매립용량(톤)
          excsFillTon: 0, // 초과용량(톤)
          ocrnGasClcqty: 0, // 발생가스수집량
          mthnGasClcqty: 0, // 메탄가스수집량
          mthnGasDens: 0, // 메탄가스농도
          gasStotUsqty: 0, // 가스소계사용량
          elctgnUsgGasUsqty: 0, // 발전용도가스사용량
          cdysmUsgGasUsqty: 0, // 냉난방용도가스사용량
          gasSplyUsgGasUsqty: 0, // 가스공급용도가스사용량
          vhclFuelUsgGasUsqty: 0, // 차량연료용도가스사용량
          etcUsgGasUsqty: 0, // 기타용도가스사용량
          enePrdctnExcqty: 0, // 에너지생산제외량
        }, // 매립가스실적 (메탄가스생산현황, 메탄가스사용현황)
      },

      /**************** S : 폐기물반입현황 Grid ****************/
      dataSourceFillGasWtCryn: [],
      gridColumnsFillGasWtCryn: [],
      gridAttrFillGasWtCryn: {
        checkable: false,
        pageable: false,
        editable : false,
        showRowNo : false,
        showStatus:false,
        excelExport: {
          fileName: '폐기물반입현황(매립가스).xlsx',
          includeHeader: true,
          includeFormula: true,
        },
      },
      prfmncFillGasWtCrynGridObject: null,
      gridReloadFillGasWtCryn: false,
      /**************** E : 폐기물반입현황 Grid ****************/

      /**************** S : 메탄가스판매현황 Grid ****************/
      dataSourceFillGasNtsl: [],
      gridColumnsFillGasNtsl: [],
      gridAttrFillGasNtsl: {
        checkable: false,
        pageable: false,
        editable: false,
        showRowNo : false,
        showStatus:false,
        excelExport: {
          fileName: '메탄가스판매현황(매립가스).xlsx',
          includeHeader: true,
          includeFormula: true,
        },
      },
      prfmncFillGasNtslGridObject: null,
      gridReloadFillGasNtsl: false,
      /**************** E : 메탄가스판매현황 Grid ****************/
    };
  },
  computed: {
    // 총 누적매립량 계산
    totalFillWtTon(){
      const prevMonthSumFillWtTon = parseFloat(this.prfmncFillGasPrevMonthSumFillWtTon?.prevMonthSumFillWtTon || 0); // 이전 누적 매립량
      const fillWtTon = parseFloat(this.fillGasDtlObj?.prfmncFillGas?.fillWtTon || 0); // 당월 매립량

      return (
          (isNaN(prevMonthSumFillWtTon) ? 0 : prevMonthSumFillWtTon) +
          (isNaN(fillWtTon) ? 0 : fillWtTon)
      );
    },
    // 자체사용합계 계산
    totalSlfUtztn() {
      const elctgnUsgGasUsqty = parseFloat(this.fillGasDtlObj?.prfmncFillGas?.elctgnUsgGasUsqty || 0); // 자체사용 전기
      const cdysmUsgGasUsqty = parseFloat(this.fillGasDtlObj?.prfmncFillGas?.cdysmUsgGasUsqty || 0); // 자체사용 열원
      const etcUsgGasUsqty = parseFloat(this.fillGasDtlObj?.prfmncFillGas?.etcUsgGasUsqty || 0); // 자체사용 기타

      return (
          (isNaN(elctgnUsgGasUsqty) ? 0 : elctgnUsgGasUsqty) +
          (isNaN(cdysmUsgGasUsqty) ? 0 : cdysmUsgGasUsqty) +
          (isNaN(etcUsgGasUsqty) ? 0 : etcUsgGasUsqty)
      );
    },
    /**
     * [폐기물매립현황 - 검증결과]
     * 초과용량 > 0 인 경우 부적합. 이 외 적합.
     */
    fillVrfcRslt() {
      return this.fillGasDtlObj.prfmncFillGas.excsFillTon > 0 ? "초과" : "적합";
    },

    /**
     * [실적보고상세 - 실적연월]
     * YYYY-MM 포맷처리
     */
    formattedPrfmncYm() {
      return (this.fillGasDtlObj.prfmncMst.prfmncYm?.substring(0, 4) ?? "") + "-" + (this.fillGasDtlObj.prfmncMst.prfmncYm?.substring(4, 6) ?? "");
    },
  },
  watch: {
    prfmncMst: {
      handler(newVal) {
        this.fillGasDtlObj.prfmncMst = {...this.fillGasDtlObj.prfmncMst, ...newVal};
      },
      immediate: true,
      deep:true,
    },
    prfmncFillGas() {
      this.fillGasDtlObj.prfmncFillGas = {...this.fillGasDtlObj.prfmncFillGas, ...this.prfmncFillGas};
      this.isPrfmncFillGasUpdated = true;
      this.checkPrfmncDependencies();
    },
    prfmncFillGasWtCrynList() {
      this.dataSourceFillGasWtCryn = { data: this.prfmncFillGasWtCrynList.list, schema: { id: 'sggStdgCd'}};
      this.initializePrfmncFillGasWtCrynGridColumns();
      const itemCount = this.prfmncFillGasWtCrynList.list.length;
      this.gridAttrFillGasWtCryn.height = Math.max((itemCount * 30) + 190, 210);
      this.gridReloadFillGasWtCryn = true;

      // 화면 초기 진입 시 반입량합계를 폐기물매립현황(당월매립량)에 노출 - 이후 반입량 변경 시 changeFillGasWtCryn 메서드로 계산
      // const totalWtStotCryqty = this.prfmncFillGasWtCrynList.list.reduce((acc, item) => acc + (item.wtStotCryqty || 0), 0);
      // this.fillGasDtlObj.prfmncFillGas.fillWtTon = totalWtStotCryqty;
    },
    prfmncFillGasNtslList() {
      this.dataSourceFillGasNtsl = this.prfmncFillGasNtslList.list;
      this.initializePrfmncFillGasNtslGridColumns();
      const itemCount = this.prfmncFillGasNtslList.list.length;
      this.gridAttrFillGasNtsl.height = Math.max((itemCount * 30) + 190, 210);
      this.gridReloadFillGasNtsl = true;

      // 화면 초기 진입 시 총 판매량 합계값 계산
      this.fillGasDtlObj.totalGasStotNsqty = this.prfmncFillGasNtslList.list.reduce((acc, item) => acc + (item.gasStotNsqty || 0), 0);
    },
    prfmncFclt() {
      // 매립가스의 경우 시설테이블의 일간용량컬럼에 저장되어있는 데이터가 최대매립가능시설용량이다.
      const maxFillCapTon = parseFloat(this.prfmncFclt.totfillCap);
      this.fillGasDtlObj.prfmncFillGas.maxFillCapTon = maxFillCapTon;
      this.isPrfmncFcltUpdated = true;
      this.checkPrfmncDependencies();
    },
  },
  created() {

  },
  mounted() {
  },
  methods: {
    checkPrfmncDependencies() {
      if (this.isPrfmncFillGasUpdated && this.isPrfmncFcltUpdated) {
        const excsFillTon =
            parseFloat(this.totalFillWtTon) -
            parseFloat(this.fillGasDtlObj.prfmncFillGas.maxFillCapTon);
        this.fillGasDtlObj.prfmncFillGas.excsFillTon = excsFillTon < 0 ? 0 : excsFillTon;
      }
    },
    handleTooltip(evt) {
      const button = evt.currentTarget; // mouse over, out 대상 버튼
      const toolCont = button.nextElementSibling?.matches('div.tool_cont') ? button.nextElementSibling : null; // 노출 대상 DIV

      if (toolCont && toolCont.classList.contains('tool_cont')) {
        if (event.type === 'mouseover') {
          toolCont.style.display = 'block';
        } else if (event.type === 'mouseout') {
          toolCont.style.display = 'none';
        }
      }
    },
    // 폐기물 반입현황 그리드 컬럼 세팅
    initializePrfmncFillGasWtCrynGridColumns() {
      this.gridColumnsFillGasWtCryn = [
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        {
          caption: '반입',
          width: 200,
          part: 'head',
          columns : [
            {
              field: 'ctpvStdgNm',
              caption: '시·도',
              width: 100,
              editable: false,
            },
            {
              field: 'sggStdgNm',
              caption: '시·군·구',
              width: 100,
              editable: false,
            }
          ],
          total: {
            aggregates: [
            ],
            header: [
              { template: '계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        {
          field: 'wtStotCryqty',
          caption: '반입량 합계(톤)',
          minWidth: 150,
          unit: '%',
          align: 'right',
          dataType: 'number',
          editable: false,
          colCss: 'grid-disabled',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
        {
          field: 'bplcDschrgWstqty',
          caption: '배출사업장계(톤)',
          width: 100,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
        {
          caption: '종량제에 의한 혼합배출',
          columns : [
            {
              field: 'hmeLifeWstqty',
              caption: '가정생활(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'bplcLifeWstqty',
              caption: '사업장생활(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            }
          ]
        },
        {
          caption: '종량제 외 배출',
          columns : [
            {
              field: 'largeWstqty',
              caption: '대형폐기물(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'foodWstqty',
              caption: '음식류폐기물(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'tmbrWstqty',
              caption: '목재(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'inmtWstqty',
              caption: '소각재(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: (value) => {
                return (value ?? 0) === 0 ? '0.00' : this.formatNumber(value);
              }
            },
            {
              field: 'wstvnlWstqty',
              caption: '영농폐비닐(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'etcWstqty',
              caption: '기타(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            }
          ]
        }
      ]
    },
    // 외부판매현황 그리드 컬럼 세팅
    initializePrfmncFillGasNtslGridColumns() {
      this.gridColumnsFillGasNtsl = [
        { field: 'prfmncId', caption: '실적아이디', visible: false, editable: false },
        { field: 'bplcId', caption: '사업장아이디', visible: false, editable: false },
        { field: 'eneBzentySn', caption: '에너지업체일련번호', visible: false, editable: false },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        {
          field: 'bzentyNm',
          caption: '판매 업체명',
          width: 300,
          part: 'head',
          align: 'left',
          editable: false,
          total: {
            aggregates: [
            ],
            header: [
              { template: '계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        {
          field: 'gasStotNsqty',
          caption: '외부판매 합계(N㎥)(B)',
          unit: '%',
          align: 'right',
          dataType: 'number',
          editable: false,
          colCss: 'grid-disabled',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          calc: {
            require: ['elctgnUsgGasNsqty', 'cdysmUsgGasNsqty', 'etcUsgGasNsqty'],
            eval: 'sum',
            nullAs: 0,
            dataType: 'number',
          },
          format: '#,##0.00',
        },
        {
          field: 'elctgnUsgGasNsqty',
          caption: '전기(N㎥)',
          width: 250,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
        {
          field: 'cdysmUsgGasNsqty',
          caption: '열원(N㎥)',
          width: 250,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
        {
          field: 'etcUsgGasNsqty',
          caption: '기타(N㎥)',
          width: 250,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
      ]
    },
    gridLoadedFillGasWtCryn() {
      this.gridReloadFillGasWtCryn = false;
    },
    gridLoadedFillGasNtsl() {
      this.gridReloadFillGasNtsl = false;
    },
    formatNumber(number) {
      return utils.formatNumberWithComma(number);
    },
  }
}
</script>

<style scoped>

</style>