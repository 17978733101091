<template>
  <div class="desc_box">
    <i class="icon"><img src="@/assets/images/common/ico_desc.png" alt="이미지"></i>
    <div class="desc">
      <strong class="desc_title">한국환경공단 폐자원사업처(폐자원에너지센터)</strong>
      <p>한국환경공단 폐자원에너지센터는 폐자원에너지부와 폐자원에너지검사부로 나뉘어 있습니다. </p>
    </div>
  </div>

  <div class="org_chart">
    <div class="org_center">한국환경공단 폐자원사업처<br>(폐자원에너지센터)</div>
    <ul class="org_dep1">
      <li>
        <span>폐자원에너지부</span>
        <ul class="org_dep2">
          <li>업무 총괄</li>
          <li>정책 지원</li>
          <li>수입</li>
          <li>제조자 품질 검사/표시</li>
          <li>제조/사용 확인 검사</li>
        </ul>
      </li>
      <li>
        <span>폐자원에너지검사부</span>
        <ul class="org_dep2">
          <li>제조 시설 정기 검사</li>
          <li>사용 시설 정기 검사</li>
        </ul>
      </li>
    </ul>
  </div>

  <div class="cont_section"
       :style="{ paddingBottom: '6rem', background: `url(${require('@/assets/images/sub/bg_orgCht.png')}) no-repeat bottom right`, backgroundSize: '32rem' }">
    <h2 class="title1">자원의 절약과 재활용촉진에 관한 법률<span class="s_title">제25조의 15(폐자원에너지센터)</span></h2>
    <ul class="blue_dot_list">
      <li>환경부장관은 폐자원에너지 이용 활성화 및 고형연료제품 품질관리 등을 위한 다음 각 호의 업무를 수행하기 위하여 폐자원에너지센터를 설치ㆍ운영할 수 있다.
        <ul class="dot_list">
          <li>품질검사 및 품질등급 관리</li>
          <li>품질표시의 적정성 검사</li>
          <li>제조시설ㆍ사용시설의 정기검사 및 운영실태 조사</li>
          <li>고형연료제품의 이용실태 조사</li>
          <li>고형연료제품 수입의 현황 및 동향 조사</li>
          <li>폐자원에너지 관련 기술지원 및 제도 연구</li>
          <li>폐자원에너지 관련 기술의 연구 및 개발</li>
          <li>폐자원에너지 관련 선진사례의 조사 및 폐자원에너지 활성화에 관한 홍보</li>
          <li>정보관리시스템 구축ㆍ운영</li>
          <li>그 밖에 폐자원에너지 활성화에 필요한 업무</li>
        </ul>
      </li>
      <li>환경부장관은 폐자원에너지센터의 운영 업무를 공단에 위탁할 수 있다.</li>
      <li>제1항 및 제2항에서 규정한 사항 외에 폐자원에너지센터의 운영에 필요한 사항은 환경부령으로 정한다.</li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {}
}
</script>

<style scoped>

</style>