<template>
    <label style="display: none" :for="label">{{ label }}</label>
    <date-picker
        ref="datePicker"
        v-model:value="selectedDate"
        value-type="format"
        range
        :format="format"
        :placeholder="computedPlaceholder"
        type="date"
        :lang="lang"
        :disabled="disabled"
        :editable="false"
        :disabled-date="notBeforeToday"
        @change="onChange"
    >
      <template #footer="{ emit }">
        <button class="mx-btn mx-btn-text" @click="selectNextThreeDay(emit)">다음 {{ daysToAdd }}일 선택</button>
      </template>
    </date-picker>
</template>

<script>
import DatePicker from 'vue-datepicker-next';
import '@/css/vue-datepicker-next.css';
import ko from 'vue-datepicker-next/locale/ko';
import { storeValidator } from "@/js/store/store-validator";
import pp from '@/js/postposition';

export default {
  components: {
    DatePicker
  },
  props: {
    label: {type: String, required: true},
    value: { type: Array, default: () => [] },
    placeholder: {type: String, default: ''},
    format: { type: String, default: 'YYYY-MM-DD' },
    futureonly: {type: Boolean, default: false},
    futureDaysOffset: { type: Number, default: 0 },
    daysToAdd: { type: Number, default: 3 },
    required: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    panel: {type: String, default: ''},
    defaultValue: { type: String, default: "" }
  },
  emits: ['update:value', 'change'],
  data() {
    return {
      hasError: false,
      errorMessage: "",
      lang: ko,
      selectedDate: this.value,
    };
  },
  computed: {
    computedPlaceholder() {
      return !this.disabled && !this.placeholder ? `${this.label} 선택` : this.placeholder;
    }
  },
  watch: {
    selectedDate(newValue) {
      this.$emit('update:value', newValue);
      this.validate();
    },
    value() {
      if (Array.isArray(this.value) && this.value.every(item => item === null)) {
        this.selectedDate = [this.defaultValue, this.defaultValue];
      } else {
        this.selectedDate = this.value;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      const inputEl = this.$refs.datePicker.querySelector("input");
      
      if (inputEl) {
        inputEl.id = this.label;
      }
    });
  },
  created() {
    if (this.value.length === 0) {
      this.selectedDate = [this.defaultValue, this.defaultValue];
    }

    if (this.required) {
      storeValidator.add(this.$route.path, this.panel, this);
    }
    this.validate();
  },
  beforeUnmount() {
    if (this.required) {
      storeValidator.remove(this.$route.path, this.panel, this.label);
    }
  },
  methods: {
    validate() {
      if (this.required && (!this.selectedDate || this.selectedDate.every(date => date === "") || this.selectedDate.every(date => date === null))) {
        this.hasError = true;
        this.errorMessage = `${pp.eunNn(this.label)} 필수 선택값 입니다.`;
      } else {
        this.hasError = false;
        this.errorMessage = "";
      }
    },
    onChange(date) {
      this.$emit('change', date);
    },
    notBeforeToday(date) {
      if (this.futureonly) {
        const futureDate = new Date();
        futureDate.setDate(futureDate.getDate() + this.futureDaysOffset);
        return date < new Date(futureDate.setHours(0, 0, 0, 0));
      }
      return false;
    },
    selectNextThreeDay(emit) {
      const start = new Date();
      const end = new Date();
      end.setDate(end.getDate() + this.daysToAdd);
      const date = [start, end];
      emit(date);
    },
  }
}
</script>

<style scoped>

</style>