<template>
  <!-- s: 콘텐츠 영역 -->
  <head>
    <meta name="description" content="이 페이지는 정기검사 신청 대상을 조회 합니다.">
  </head>
  <div class="desc_box">
    <i class="icon"><img src="@/assets/images/common/ico_desc.png" alt="이미지"></i>
    <div class="desc">
      <strong class="desc_title">정기검사 신청</strong>
      <ul class="dot_list">
        <li>검사일자가 도래하면 3개월전에 "계속검사신청" 버튼이 활성화 됩니다.</li>
        <li>검사일자 도래 전에 제조시설 폐업,휴업 중에 정보가 갱신되어 변경검사가 필요한 경우 "변경검사신청" 을 클릭하여 검사를 신청해 주세요.</li>
        <li>검사일자 도래전에 사용시설 소각기에 제원이 추가되거나 변동사항이 있을 시 "변경검사신청"을 클릭하여 검사를 신청해주세요.</li>
        <li><b>정기검사 신청 시 필수 첨부파일이 존재하지 않는 경우</b>, 마이오피스에서 첨부파일을 먼저 제출하신 후 정기검사 신청해주세요.</li>
      </ul>
      <div class="btn_area left">
        <!-- <a href="#modalQltyInspAplyGuide" class="btn xsm helper ico_helper modal_open" @click.prevent="openModal('#modalQltyInspAplyGuide')">품질검사안내</a> -->
        <a href="#modalFcltInspAplyGuide" class="btn xsm secondary ico_helper modal_open" @click.prevent="openModal('#modalFcltInspAplyGuide')">정기검사안내</a>
      </div>
    </div>
  </div>

  <h2 class="title1">정기검사 신청서 정보</h2>
  <ul v-if="param.list && param.list.length > 0" class="card_list">
    <li v-for="(item, index) in param.list" :key="index">
      <div class="card_top">
        <strong class="c_tit">({{item.bplcId}}) {{item.bplcNm}}</strong>
        <span v-if="(item.inspAplyTrgtYn === 'Y' && item.inspPrgrsSttsCd === '70')
                    || (item.frstInspPassYn === 'N' && item.inspPrgrsSttsCd === null)
                    || (item.frstInspPassYn === 'N' && item.inspPrgrsSttsCd === undefined)"
                    :class="getBadgeClass('Y')">
          신청대상
        </span>
        <span v-else :class="getBadgeClass(item.inspPrgrsSttsCd)">{{item.inspPrgrsSttsNm}}</span>
      </div>
      <div class="card_body">
        <ul class="txt_list">
          <li>사업장종류<strong>{{item.bplcKndNm}}</strong></li>
          <li>업체구분<strong>{{item.pubBzentyYn}}</strong></li>
          <li>시설명<strong>{{item.fcltNm}}</strong></li>
          <li>제품종류<strong>{{item.prdctKndNm}}</strong></li>
          <li>이전검사 완료일자<strong>{{item.bfrInspEndYmd}}</strong></li>
          <li>설치허가일자<strong>{{item.instlOperPrmsnYmd}}</strong></li>
          <li>다음검사일자<strong>{{item.nextInspEndYmd}}</strong></li>
        </ul>
      </div>
      <div class="card_btn">
        <button v-if="item.frstInspPassYn === 'N'"
                type="button"
                class="btn sm primary line"
                @click="beforeNavigateCheck('10-10', item.bplcId, item.fcltSqno, item.bplcKndCd, item.needFileYn
                , item.atchFile003, item.atchFile001, item.atchFile002, item.atchFile006, item.atchFile007, item.atchFile004, item.atchFile005 )"
                :disabled="item.replayInspAplyYn === 'N'"

        >
          최초검사신청
        </button>
        <button v-if="item.frstInspPassYn === 'Y'"
                type="button"
                class="btn sm primary line"
                @click="beforeNavigateCheck('10-20', item.bplcId, item.fcltSqno, item.bplcKndCd, item.needFileYn
                , item.atchFile003, item.atchFile001, item.atchFile002, item.atchFile006, item.atchFile007, item.atchFile004, item.atchFile005 )"
                :disabled="(item.inspAplyTrgtYn !== 'Y' || item.frstInspPassYn === 'N') && item.replayInspAplyYn === 'N'"
        > <!--navigateFcltInspAplyPage('10-20', item.bplcId, item.fcltSqno, item.bplcKndCd)-->
          계속검사신청
        </button>
        <button type="button"
                class="btn sm secondary"
                @click="beforeNavigateCheck('10-30', item.bplcId, item.fcltSqno, item.bplcKndCd, item.needFileYn
                , item.atchFile003, item.atchFile001, item.atchFile002, item.atchFile006, item.atchFile007, item.atchFile004, item.atchFile005 )"
                :disabled="item.chgInspTrgtYn !== 'Y' || item.fcltSttsCd ==='03'"
                >
          변경검사신청
        </button>
      </div>
    </li>
  </ul>

  <ul v-else>
    <li>
      <div style="display: flex; justify-content: center;">
        <div class="no-data-message">정기검사 대상 정보가 없습니다.</div>
      </div>
    </li>
  </ul>
  <!-- e: 콘텐츠 영역 -->

<!-- s: modal popup 품질검사안내 -->
<div :class="['modal', { visible: isModalQltyInspAplyGuideVisible }]" id="modalQltyInspAplyGuide">
    <div class="popup_box" tabindex="0">
      <h1 class="popup_title">품질검사 신청 안내</h1>
      <div class="popup_cont">
        <div class="desc_box progress_box">
          <i class="icon"><img src="@/assets/images/common/ico_noti.png" alt="이미지"></i>
          <div class="progress">
            <div><strong>품질검사</strong>는 폐자원으로 생산한 고형연료의 품질 여부를 검사합니다. <span>아래 절차대로 진행됩니다.</span></div>
          </div>
        </div>
        <h2 class="title1">최초 검사</h2>
        <div class="img_box_area mt_10">
          <img src="@/assets/images/sub/img_info_pop1-1.png" alt="품질 검사 절차">
        </div>

        <h2 class="title1">확인 검사<span class="info_title">정기 검사 시 해당 분기 확인 검사 시료채취 및 분석 결과 활용</span></h2>
            <div class="img_box_area mt_10">
                <img src="@/assets/images/sub/img_info_pop1-2.png" alt="1. 제조자/사용자 고형 연료 제품, 2. 시료 채취 및 계획 수립 검사 예정일 3일 전까지 통보, 3. 시료 채취 및 분석 고형 연료 제품, 품질 실험/분석 방법, 4. 품질 검사 결과서 발급, 5. 지방 자치 단체 통보(부적합 시 통보)">
            </div>
        <div class="btn_area center">
          <button type="button" class="btn md primary" @click="navigateTo('/wstrsc-ene-intro/sfl/QltyInspGd')">자세히 보기</button>
          <button type="button" class="btn md" @click="closeModal">닫기</button>
        </div>
      </div>
      <button type="button" class="modal_close" @click="closeModal">팝업 닫기</button>
    </div>
  </div>
  <!-- e: modal popup 품질검사안내 -->

  <!-- s: modal popup 정기검사안내 -->
  <div :class="['modal', { visible: isModalFcltInspAplyGuideVisible }]" id="modalFcltInspAplyGuide">
    <div class="popup_box" tabindex="0">
      <h1 class="popup_title">정기검사 신청 안내</h1>
      <div class="popup_cont">
        <div class="desc_box progress_box">
          <i class="icon"><img src="@/assets/images/common/ico_noti.png" alt="이미지"></i>
          <div class="progress">
            <div><strong>정기검사</strong>는 폐자원에너지 재생, 생산에 필요한 시설 여부를 검사합니다. <span>아래 절차대로 진행됩니다.</span></div>
          </div>
        </div>
        <div class="img_box_area mt_10">
          <img src="@/assets/images/sub/img_info_pop2.png" alt="정기 검사 절차">
        </div>
        <div class="btn_area center">
          <button type="button" class="btn md primary" @click="navigateTo('/wstrsc-ene-intro/sfl/FcltInspGd')">자세히 보기</button>
          <button type="button" class="btn md" @click="closeModal">닫기</button>
        </div>
      </div>
      <button type="button" class="modal_close" @click="closeModal">팝업 닫기</button>
    </div>
  </div>
  <!-- e: modal popup 정기검사안내 -->

  <!-- s: modal popup 필수 첨부파일 -->
  <div :class="['modal', { visible: isBeforeFileCheckPopupVisible }]" id="modalFcltInspAplyGuide">
    <div class="popup_box" tabindex="0">
      <h1 class="popup_title">정기검사 신청 전 필수 첨부파일 안내</h1>
      <div class="popup_cont">
        <div class="desc_box progress_box">
          <i class="icon"><img src="@/assets/images/common/ico_noti.png" alt="이미지"></i>
          <div class="progress">
            <div><strong>정기검사</strong>시 필요한 첨부파일이 누락되어 있습니다. <span>마이오피스에서 확인해주시기 바랍니다.</span></div>
          </div>
        </div>
        <div class="tbl_wrap">
          <table>
            <caption>검사신청이력_번호, 진행상태, 사유, 처리일자, 처리자로 구성</caption>
            <colgroup>
              <col style="width:65%">
              <col style="width:20%">
              <col style="width:15%">
            </colgroup>
            <thead>
            <tr>
              <th scope="col">첨부파일 종류</th>
              <th scope="col">회원구분</th>
              <th scope="col">존재여부</th>
            </tr>
            </thead>
            <tbody>
            <!--  -->
            <tr>
              <td>사업자등록증</td>
              <td>업체</td>
              <td>{{param.atchFile003}}</td>
            </tr>
            <tr>
              <td>재활용허가증 또는 폐기물처리시설신고필증</td>
              <td>사업장</td>
              <td>{{param.atchFile001}}</td>
            </tr>
            <tr>
              <td>통장사본</td>
              <td>사업장</td>
              <td>{{param.atchFile006}}</td>
            </tr>
            <tr v-if="param.bplcKndCd === '01-02'">
              <td>제조·사용 신고확인증(신고허가증)</td>
              <td>사업장</td>
              <td>{{param.atchFile007}}</td>
            </tr>
            <tr>
              <td>시설 설치명세서</td>
              <td>시설</td>
              <td>{{param.atchFile004}}</td>
            </tr>
            <tr>
              <td>시설의 가동 및 유지·관리 명세서</td>
              <td>시설</td>
              <td>{{param.atchFile005}}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="btn_area center">
          <button type="button" class="btn md primary" @click="navigateTo('/my-office/BizFacilityInfo')">마이오피스로 이동하기</button>
          <button type="button" class="btn md" @click="closeModal">닫기</button>
        </div>
      </div>
      <button type="button" class="modal_close" @click="closeModal">팝업 닫기</button>
    </div>
  </div>
  <!-- e: modal popup 필수 첨부파일 -->

</template>

<script>


/**
 * 시설에 대하여 최초, 계속, 변경 검사신청 가능여부를 조회하는 화면
 *
 * 작성자 : CHOSUNGHO
 * 작성일 : 24. 10. 17.
 * 버전 : 1.0
 */

const API = {
  FCLT_INSP : "/fcltInsp/list"
}

export default {
  components :{
  },
  computed : {
  },
  data() {
    return {
      param : {
        prdctnPrdctKndCd : "",
        prdctKndNm : "",
        inspQtr : "",
        frstInspPassYn : "",
        bplcKndCd : "",
        bplcKndNm : "",
        chgInspTrgtYn : "",
        inspPrgrsSttsCd : "",
        inspPrgrsSttsNm : "",
        inspBgngYmd : "",
        inspYr : "",
        useFcltKndCd : "",
        useFcltKndNm : "",
        instlOperPrmsnYmd : "",
        inspRsltCd :"",
        replayInspAplyYn :"",
        fcltNm : "",
        fcltSqno : "",
        bplcNm : "",
        inspEndYmd : "",
        bfrInspEndYmd : "",
        bplcId : "",
        needFileYn :"",
        fcltSttsCd :"",
        atchFile003 :"",
        atchFile001 :"",
        atchFile002 :"",
        atchFile006 :"",
        atchFile007 :"",
        atchFile004 :"",
        atchFile005 :""
      },
      isModalQltyInspAplyGuideVisible: false,
      isModalFcltInspAplyGuideVisible: false,
      isBeforeFileCheckPopupVisible: false
    };
  },
  watch : {},
  created() {
    //console.log("🚀 BeforeFileCheckPopup 컴포넌트가 생성됨!");
  },
  mounted() {
    this.readFcltInspByUserId();
    //console.log("✅ BeforeFileCheckPopup 컴포넌트가 마운트됨!");
  },
  beforeMount (){

  },
  methods : {
    readFcltInspByUserId() {
      this.$apiCall.get(API.FCLT_INSP, {}, this.readFcltInspByUserIdSuccess, this.error);
    },
    readFcltInspByUserIdSuccess(data) {
      if (!data.result) {
        this.error();
        return;
      }
      this.param = data.result;
    },
    error() {
      alert("데이터를 가져오는 중 오류가 발생했습니다.");
    },
    getBadgeClass(inspPrgrsSttsCd) {
      /**
       * inspPrgrsSttsCd 검사진행상태코드 정보
       * 00:작성중, 10:신청, 20:서류검토중, 30:서류보완요청
       * 40:접수, 50:반려, 60:검사진행중, 70:완료
       * 80:사전취소, 90:검사취하, 100:검사연기, 110:처리기한연장
       * Y:신청대상 (화면에서만 사용 용도)
       */
      if (['10', '20', '40', '60', '70', 'Y'].includes(inspPrgrsSttsCd)) {
        return 'badge'; // 청색
      } else if (['30', '50', '80', '90', '100', '110'].includes(inspPrgrsSttsCd)) {
        return 'badge bg2'; // 적색
      } else {
        return 'badge bg1'; // 회색
      }
    },

    // 페이지 이동 전 첨부파일이 모두 존재하는지 확인, pass 시 검사신청페이지로 이동.
    beforeNavigateCheck(inspSeCd, bplcId, fcltSqno, bplcKndCd
        , needFileYn, atchFile003,atchFile001,atchFile002,atchFile006,atchFile007,atchFile004,atchFile005){

      this.param.bplcKndCd = bplcKndCd;
      this.param.needFileYn = needFileYn;
      this.param.atchFile003 = atchFile003;
      this.param.atchFile001 = atchFile001;
      this.param.atchFile002 = atchFile002;
      this.param.atchFile006 = atchFile006;
      this.param.atchFile007 = atchFile007;
      this.param.atchFile004 = atchFile004;
      this.param.atchFile005 = atchFile005;

      if(this.param.needFileYn === "Y"){
        this.navigateFcltInspAplyPage(inspSeCd, bplcId, fcltSqno, bplcKndCd);
      }else{
        this.isBeforeFileCheckPopupVisible = true;
      }


      // this.isModalFcltInspAplyGuideVisible = true;

      //this.navigateFcltInspAplyPage(inspSeCd, bplcId, fcltSqno, bplcKndCd);
    },
    navigateFcltInspAplyPage(pInspSeCd, pBplcId, pFcltSqno, pBplcKndCd) {
      /**
       * pInspSeCd (검사구분코드) :
       * '10-10' = 최초검사
       * '10-20' = 계속검사
       * '10-30' = 변경검사
       */

      const dataObj = {
        inspAplySnValue: '',        /* 검사신청일련번호 빈 값 적용 ( 신규 등록 ) */
        inspSeCdValue: pInspSeCd,   /* 검사구분코드 */
        bplcIdValue: pBplcId,       /* 사업장 아이디 */
        fcltSqnoValue: pFcltSqno,   /* 시설순번 */
        bplcKndCdValue: pBplcKndCd  /* 사업장종류코드 */
      };

      if (!pInspSeCd || !pBplcId || !pFcltSqno) {
        alert("시설번호 및 정기검사 신청 구분 데이터가 존재하지 않습니다.");
        return;
      }

      // 데이터를 Local Storage에 저장
      localStorage.setItem('fcltInspAplyObj', JSON.stringify(dataObj));

      this.$router.push(
        {
          path : "/sfli/fclt-insp-aply/FcltInspAplyCreate"
        }
      ).catch(
        () => {
          console.error("Navigation Error");
        }
      );
    },
    openModal(modalId) {
      if (modalId === '#modalQltyInspAplyGuide') {
        this.isModalQltyInspAplyGuideVisible = true;
      } else if (modalId === '#modalFcltInspAplyGuide') {
        this.isModalFcltInspAplyGuideVisible = true;
      }
    },
    closeModal() {
      this.isModalQltyInspAplyGuideVisible = false;
      this.isModalFcltInspAplyGuideVisible = false;
      this.isBeforeFileCheckPopupVisible = false;
    },
    navigateTo(navigatePath) {
      if (!navigatePath) {
        alert('페이지 정보가 존재하지 않습니다.');
        return;
      }

      this.$router.push(
        {
          path : navigatePath
        }
      ).catch(
        () => {
          console.error("Navigation Error");
        }
      );
    },
  }
}
</script>

<style scoped>
  .no-data-message {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 5px;
    margin: 20px 0px;
    border-radius: 10px;
    background-color: #f7f7f7;
    color: #333;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #ddd;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    width: 350px;
  }
</style>