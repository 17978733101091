<template>
  <!-- s: 콘텐츠 영역 -->
  <head>
    <meta name="description" content="이 페이지는 정기검사 신청 현황을 조회 합니다.">
  </head>

  <div class="desc_box">
    <i class="icon"><img src="@/assets/images/common/ico_desc.png" alt="이미지"></i>
    <div class="desc">
        <strong class="desc_title">정기검사 현황</strong>
        <ul class="dot_list">
            <li>정기검사 신청 목록이 조회 됩니다.</li>
            <li>검사 완료 시에는 검사결과를 조회 할 수 있습니다.</li>
            <li>신규로 검사를 신청하는 경우에는 "검사신청" 메뉴 클릭하여 신청해 주시기 바랍니다. 담당자의 검토 과정을 거친 이후 "수수료 납부" 안내가 
                나갑니다. 수수료를 납부하시면 "접수" 처리가 되며 검사 일정을 안내해드립니다.</li>
            <li>상태가 서류보완의 경우 사유를 확인하시고 자료를 보충하신 후 재 제출 해주시기 바랍니다.</li>
            <li>신고서 출력은 상세페이지에서 출력 할 수 있습니다. </li>
        </ul>
        <div class="btn_area left">
            <!-- <a role="link" href="#modalQltyInspAplyGuide" class="btn xsm helper ico_helper modal_open" @click.prevent="openModal('#modalQltyInspAplyGuide')">품질검사안내</a> -->
            <a role="link" href="#modalFcltInspAplyGuide" class="btn xsm secondary ico_helper modal_open" @click.prevent="openModal('#modalFcltInspAplyGuide')">정기검사안내</a>
        </div>
    </div>
  </div>

  <div class="board_info">
    <p class="page">
        <span class="total">전체 <strong>{{ Number(this.listObj.fcltInspStatDataCount).toLocaleString() }}</strong></span>
        <span>페이지 <strong>{{ this.searchParam.page }}/{{ this.lastPage }}</strong></span>
    </p>
    <div class="btn_area">
        <a role="link" href="javascript:void(0);" class="btn sm" @click.prevent="openReportForm('reportFcltInspResult')">정기검사 결과서 출력</a>
        <a role="link" href="javascript:void(0);" class="btn sm" @click.prevent="openReportForm('reportSrfMnftrDclr')">제조 신고서 양식 출력</a>
        <a role="link" href="javascript:void(0);" class="btn sm" @click.prevent="openReportForm('reportSrfUseDclr')">사용허가 신청서 양식 출력</a>
        <a role="link" href="javascript:void(0);" class="btn sm ico_excel" @click.prevent="excelDownload()">엑셀다운로드</a>
    </div>
  </div>
  <div class="board_list scroll">
    <table>
        <caption>선택, 검사신청번호, 검사결과, 진행상태, 검사구분, 사업장명, 사업장종류, 신청일자, 접수일자, 검사(예정)일자, 검사연기신청, 처리기한연장 구성</caption>
        <colgroup>
            <col style="width:5%">
            <col style="width:7%">
            <col style="width:7%">
            <col style="width:7%">
            <col style="width:7%">
            <col style="width:15%">
            <col style="width:10%">
            <col style="width:7%">
            <col style="width:8%">
            <col style="width:8%">
            <col style="width:9%">
            <col style="width:7%">
            <col style="width:7%">
            <col style="width:7%">
        </colgroup>
        <thead>
            <tr>
                <th scope="col">선택</th>
                <th scope="col">검사신청번호</th>
                <th scope="col">검사결과</th>
                <th scope="col">진행상태</th>
                <th scope="col">검사구분</th>
                <th scope="col">사업장명</th>
                <th scope="col">시설명</th>
                <th scope="col">사업장종류</th>
                <th scope="col">신청일자</th>
                <th scope="col">접수일자</th>
                <th scope="col">검사(예정)일자</th>
                <th scope="col">검사연기신청</th>
                <th scope="col">처리기한연장</th>
                <th scope="col">수수료<br/>납부여부</th>
            </tr>
        </thead>
        <tbody>
          <tr v-if="listObj.fcltInspStatDataCount <= 0">
            <td colspan="12">
              <div style="display: flex; justify-content: center;">
                <div class="no-data-message">조회조건에 해당하는 데이터가 없습니다.</div>
              </div>
            </td>
          </tr>
          <tr v-else v-for="(item, index) in listObj.list" :key="index" @dblclick.prevent="navigateFcltInspDetailPage(item.inspAplySn)" style="cursor: pointer;">
            <td aria-label="선택">
              <div>
                <!-- <input type="checkbox" name="" :id="'chk' + index"><label :for="'chk' + index" class="hide">선택</label> -->
                <input type="radio" v-model="selectedInspAplySn" :value="item.inspAplySn" @change="selectRowData(item)" :id="'radio' + index" /> 
                <label :for="'radio' + index" class="hide">선택</label> 
              </div>
              </td>
            <td aria-label="검사신청번호">{{item.inspAplySn}}</td>
            <td aria-label="검사결과">
              <span :class="getInspRsltClass(item.inspRsltCd)">{{item.inspRsltNm}}</span>
            </td>
            <td aria-label="진행상태">
              <span :class="getInspPrgrsSttsClass(item.inspPrgrsSttsCd)">{{item.inspPrgrsSttsNm}}</span>
            </td>
            <td aria-label="검사구분">{{item.inspSeNm}}</td>
            <td aria-label="사업장명" class="txt_left">{{item.bplcNm}}</td>
            <td aria-label="시설명" class="txt_left">{{item.fcltNm}}</td>
            <td aria-label="사업장종류">{{item.bplcKndNm}}</td>
            <td aria-label="신청일자">{{item.inspAplyYmd}}</td>
            <td aria-label="접수일자">{{item.inspRcptYmd}}</td>
            <td aria-label="검사(예정)일자">{{item.inspPrnmntYmd}}</td>
            <td aria-label="검사연기신청">
              <div v-if="item.inspPrgrsSttsCd !== '70'" 
                    class="btn xsm secondary" 
                    @click.stop="openModalInspSpnsAply(item.inspAplySn, item.inspSpnsPrlgSqno, item.inspPrnmntYmd)"
              >
                {{ item.inspSpnsSttsNm }}
              </div>
                <!-- <div class="btn xsm secondary" @click.prevent="readFcltInspAplyHstry(item.inspAplySn)">검사신청이력</div> --> 
            </td>
            <td aria-label="처리기한연장">{{item.prcsTermPrlg}}</td>
            <td aria-label="수수료납부여부">{{item.dpstYnNm}}</td>
          </tr>
        </tbody>
    </table>
  </div>
  <!-- 그리드 페이징 영역 -->
  <insp-pagination 
    v-if="listObj.fcltInspStatDataCount > 0" 
    :total="listObj.fcltInspStatDataCount" 
    :size="searchParam.size" 
    v-model:page="searchParam.page" 
    v-model:maxPage="lastPage" 
    @clickedPage="readAllFcltInspStat">
  </insp-pagination>
  <!-- e: 콘텐츠 영역 -->

  <!-- s: modal popup 품질검사안내 -->
  <div :class="['modal', { visible: isModalQltyInspAplyGuideVisible }]" id="modalQltyInspAplyGuide">
    <div class="popup_box" tabindex="0">
      <h1 class="popup_title">품질검사 신청 안내</h1>
      <div class="popup_cont">
        <div class="desc_box progress_box">
          <i class="icon"><img src="@/assets/images/common/ico_noti.png" alt="이미지"></i>
          <div class="progress">
            <div><strong>품질검사</strong>는 폐자원으로 생산한 고형연료의 품질 여부를 검사합니다. <span>아래 절차대로 진행됩니다.</span></div>
          </div>
        </div>
        <h2 class="title1">최초 검사</h2>
        <div class="img_box_area mt_10">
          <img src="@/assets/images/sub/img_info_pop1-1.png" alt="품질 검사 절차">
        </div>

        <h2 class="title1">확인 검사<span class="info_title">정기 검사 시 해당 분기 확인 검사 시료채취 및 분석 결과 활용</span></h2>
            <div class="img_box_area mt_10">
                <img src="@/assets/images/sub/img_info_pop1-2.png" alt="1. 제조자/사용자 고형 연료 제품, 2. 시료 채취 및 계획 수립 검사 예정일 3일 전까지 통보, 3. 시료 채취 및 분석 고형 연료 제품, 품질 실험/분석 방법, 4. 품질 검사 결과서 발급, 5. 지방 자치 단체 통보(부적합 시 통보)">
            </div>
        <div class="btn_area center">
          <button type="button" class="btn md primary" @click="navigateTo('/wstrsc-ene-intro/sfl/QltyInspGd')">자세히 보기</button>
          <button type="button" class="btn md" @click="closeModal">닫기</button>
        </div>
      </div>
      <button type="button" class="modal_close" @click="closeModal">팝업 닫기</button>
    </div>
  </div>
  <!-- e: modal popup 품질검사안내 -->

  <!-- s: modal popup 정기검사안내 -->
  <div :class="['modal', { visible: isModalFcltInspAplyGuideVisible }]" id="modalFcltInspAplyGuide">
    <div class="popup_box" tabindex="0">
      <h1 class="popup_title">정기검사 신청 안내</h1>
      <div class="popup_cont">
        <div class="desc_box progress_box">
          <i class="icon"><img src="@/assets/images/common/ico_noti.png" alt="이미지"></i>
          <div class="progress">
            <div><strong>정기검사</strong>는 폐자원에너지 재생, 생산에 필요한 시설 여부를 검사합니다. <span>아래 절차대로 진행됩니다.</span></div>
          </div>
        </div>
        <div class="img_box_area mt_10">
          <img src="@/assets/images/sub/img_info_pop2.png" alt="정기 검사 절차">
        </div>
        <div class="btn_area center">
          <button type="button" class="btn md primary" @click="navigateTo('/wstrsc-ene-intro/sfl/FcltInspGd')">자세히 보기</button>
          <button type="button" class="btn md" @click="closeModal">닫기</button>
        </div>
      </div>
      <button type="button" class="modal_close" @click="closeModal">팝업 닫기</button>
    </div>
  </div>
  <!-- e: modal popup 정기검사안내 -->

  <!-- s: modal popup 검사신청 이력 -->
  <div :class="['modal', { visible: isModalInspAplyHstryVisible }]" id="modalInspAplyHstry">
    <div class="popup_box" tabindex="0">
      <h1 class="popup_title">검사신청 이력</h1>
      <div class="popup_cont">
          <h2 class="title1 mt_0">검사신청 이력</h2>
                  
          <div class="tbl_wrap">
              <table>
                  <caption>검사신청이력_번호, 진행상태, 사유, 처리일자, 처리자로 구성</caption>
                  <colgroup>
                      <col style="width:10%">
                      <col style="width:15%">
                      <col style="width:50%">
                      <col style="width:15%">
                      <col style="width:10%">
                  </colgroup>
                  <thead>
                      <tr>
                          <th scope="col">번호</th>
                          <th scope="col">진행상태</th>
                          <th scope="col">사유</th>
                          <th scope="col">처리일자</th>
                          <th scope="col">처리자</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-for="(histItem, histIndex) in histObj.list" :key="histIndex">
                          <td>{{histItem.rowNum}}</td>
                          <td><span class="badge bg3">{{histItem.inspPrgrsSttsNm}}</span></td>
                          <td class="txt_left">{{histItem.inspRsn}}</td>
                          <td>{{histItem.prcsYmd}}</td>
                          <td>예정</td>
                      </tr>
                  </tbody>
              </table>
          </div>

          <div class="btn_area center">
              <button type="button" class="btn md" @click="closeModal">닫기</button> 
          </div>
      </div>
      <button type="button" class="modal_close" @click="closeModal">팝업 닫기</button>
    </div>
  </div>
  <!-- e: modal popup 검사신청 이력 -->

  <!-- s: modal popup 검사연기 -->
  <div :class="['modal', { visible: isModalInspSpnsVisible }]" id="modalInspSpns"> 
    <div class="popup_box" tabindex="0">
    <h1 class="popup_title">검사연기</h1>
    <div class="popup_cont" style="overflow-y: hidden;">
        <div class="desc_box progress_box">
            <i class="icon"><img src="@/assets/images/common/ico_noti.png" alt="이미지"></i>
            <div class="progress">
                <div><strong>검사연기신청</strong></div>
            </div>
        </div>
        <div class="board_write">
            <table>
                <caption>검사연기신청_변경일자, 증빙서류, 신청사유로 구성</caption>
                <colgroup>
                    <col style="width:15%">
                    <col style="width:85%">
                </colgroup>
                <tbody>
                    <tr>
                        <th scope="row"><i v-if="inspSpnsObj.pageType === 'new' || inspSpnsObj.inspSpnsSttsCd === '03'" class="necessary">필수입력</i>변경일자</th>
                        <td>
                          <span v-if="inspSpnsObj.pageType === 'new' || inspSpnsObj.inspSpnsSttsCd === '03'">
                            <br-date-picker label="변경일자" title="변경일자" v-model:value="inspSpnsObj.chgYmd" format="YYYY-MM-DD" panel="panel" required />
                          </span>
                          <span v-else>
                            {{inspSpnsObj.chgYmd}} 
                          </span>
                        </td>
                    </tr>
                    <tr v-if="inspSpnsObj.pageType === 'new' || inspSpnsObj.inspSpnsSttsCd === '03'">
                      <th scope="row">증빙서류</th>
                      <td>
                        <!-- <div class="file_upload_list" :class="{'file-upload-visible': isFileComponentVisible}"> -->
                        <div class="file_upload_list">
                          <li v-if="isModalInspSpnsVisible" >
                            <br-attach-file-list label="정기검사 연기 첨부파일 목록"
                                        ref="extAttachFileList"
                                        atchFileTaskSeCd="EXT" 
                                        v-model:atchFileGroupId="this.inspSpnsObj.inspAplyStatAtchFileGroupId"
                                        v-model:fileProcessingData="this.inspSpnsObj.fileProcessingData"
                                        :readonly="false" 
                                        :useEtcFile="false" 
                                        panel="panel"
                                        required
                            /> 
                          </li> 
                        </div>
                      </td>
                    </tr>

                    <tr v-else>
                      <th scope="row">증빙서류</th>
                      <td>
                        <!-- <div class="file_upload_list" :class="{'file-upload-visible': isFileComponentVisible}"> -->
                        <div class="file_upload_list">
                          <li>
                            <br-attach-file-list label="정기검사 연기 첨부파일 목록"
                                        ref="extAttachFileList"
                                        atchFileTaskSeCd="EXT" 
                                        v-model:atchFileGroupId="this.inspSpnsObj.inspAplyStatAtchFileGroupId"
                                        v-model:fileProcessingData="this.inspSpnsObj.fileProcessingData"
                                        :readonly="true" 
                                        :useEtcFile="false" 
                            /> 
                          </li> 
                        </div>
                      </td>
                    </tr>

                    <tr>
                        <th scope="row"><i v-if="inspSpnsObj.pageType === 'new' || inspSpnsObj.inspSpnsSttsCd === '03'" class="necessary">필수입력</i>신청사유</th>
                        <td style="padding-top: 30px; padding-bottom: 30px;">
                          <span v-if="inspSpnsObj.pageType === 'new' || inspSpnsObj.inspSpnsSttsCd === '03'">
                            <br-form-input label="신청사유" title="신청사유" v-model="inspSpnsObj.inspSpnsAplyRsn" panel="panel" required/>
                          </span>
                          <span v-else>
                            {{inspSpnsObj.inspSpnsAplyRsn}}
                          </span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <table v-if="inspSpnsObj.inspSpnsSttsCd === '03'">
              <caption>검사연기신청_변경일자, 증빙서류, 신청사유로 구성</caption>
                <colgroup>
                    <col style="width:15%">
                    <col style="width:35%">
                    <col style="width:15%">
                    <col style="width:35%">
                </colgroup>
                <tbody>
                  <tr>
                      <th scope="row">반려사유</th>
                      <td colspan="3" style="padding-top: 30px; padding-bottom: 30px;">
                        <span>{{inspSpnsObj.chgRsn}}</span>
                      </td>
                  </tr>
                  <tr>
                    <th scope="row">처리자</th>
                    <td colspan="3">{{inspSpnsObj.userNm}}</td>
                  </tr>
                </tbody>
            </table>
        </div>

        <div class="btn_area center">
            <br-button v-if="inspSpnsObj.pageType === 'new' || inspSpnsObj.aplyPsblYn === 'Y' || inspSpnsObj.inspSpnsSttsCd === '03'"
                       label="신청" title="신청" class="btn md primary" panel="panel" validate @click="createFcltInspSpnsAply()"/>
            <br-button label="닫기" title="닫기" class="btn md" @click="closeModal" />
        </div>
    </div>
    <button type="button" class="modal_close" @click="closeModal">팝업 닫기</button>
  </div>
</div>
  <!-- e: modal popup 검사연기 --> 
  
  <component  v-if="reportParamObj.selectedPopupComponent" 
            :is="reportParamObj.selectedPopupComponent" 
            :rd-param-file-name="reportParamObj.rdParamFileName" 
            :rd-param-key="reportParamObj.rdParamKey" 
            :rd-param-value="reportParamObj.rdParamValue" 
            :rd-param-pdf-file-name="reportParamObj.pdfFileName" 
  />

  
</template>

<script>
/**
 * 정기검사 신청에 대하여 현황 목록을 조회하는 화면 
 *
 * 작성자 : CHOSUNGHO
 * 작성일 : 24. 10. 17. 
 * 버전 : 1.0
 */
 import { buildUri } from "@/js/utils/uri-builder";
 import { storeSwitch } from "@/js/store/store-switch";
 import utils from "@/js/utils/utils";
 import inspPagination from "@/views/sfli/components/inspPagination.vue";
 import reportFcltInspPopup from "@/views/sfli/fclt-insp-aply/popup/ReportFcltInspPopup.vue";

const API = {
  FCLT_INSP_STAT : "/fcltInsp",
  FCLT_INSP_HISTORY : "/fcltInsp/history/{inspAplySn}",
  FCLT_INSP_SPNS_APLY : "/fcltInsp/spnsAply",
  FCLT_INSP_SPNS_DETAIL : "/fcltInsp/spnsRsct/{inspAplySn}/sqno/{inspSpnsPrlgSqno}",
  FCLT_INSP_STAT_EXCEL : "/fcltInsp/excel-download",
}

const PAGE_SIZE = 10;

export default {
  components: {inspPagination, reportFcltInspPopup}, 
  computed : {},
  data() {
    return {
      searchParam: {
        page: 1
      },
      inspSpnsObj : {
        inspSpnsAplySn : "",
        chgBfrYmd : "",
        chgYmd : "", 
        inspSpnsAplyRsn : "", 
        inspAplyStatAtchFileGroupId : "", 
        inspSpnsPrlgSeCd : "",
        inspSpnsSttsCd : "",
        pageType : "",
        atchFileGroupId : "",
        aplyPsblYn : "",
        fileProcessingData: { type: Object, default: () => ({}) }
      },
      listObj : {
        inspAplySn : "",
        inspSeCd : "",
        inspSeNm : "",
        bplcId : "",
        bplcNm : "",
        bplcKndNm : "", 
        fcltNm : "",
        prdctnPrdctKndCd : "",
        prdctnPrdctKndNm : "",
        inspAplyYmd : "",
        inspRcptYmd : "",
        inspPrnmntYmd : "",
        chgYmd : "",
        inspPrgrsSttsCd : "",
        inspPrgrsSttsNm : "",
        prcsTermPrlg : "",
        inspRsltCd : "",
        inspRsltNm : "",
        inspSpnsPrlgSqno : "",
        inspSpnsSttsCd : "", 
        inspSpnsSttsNm : "", 
        inspSpnsPrlgSeCd : "", 
        bzentyRsltRptpOtptRrmYn : "",
        page: 1, 
        fcltInspStatDataCount : 0,
        dpstYnNm: "",
      },
      histObj : {
        rowNum : "",
        inspPrgrsSttsNm : "", 
        inspRsn : "" 
      },
      reportParamObj : {
        selectedPopupComponent: null, // 선택된 리포트/이폼 화면 구분 ID 
        rdParamKey: "",
        rdParamValue: "",
        rdParamFileName: "", 
        pdfFileName: "", 
      }, 
      inspSpnsFileListTaskSeCd: "EXT", 
      lastPage: 1,
      isModalQltyInspAplyGuideVisible: false, 
      isModalFcltInspAplyGuideVisible: false, 
      isModalInspAplyHstryVisible: false, 
      isModalInspSpnsVisible: false, 
      fileTextAreaWidth: "160px",  
      fileAllowedExtensions: ['jpg', 'jpeg', 'gif', 'png', 'tif', 'bmp', 'hwp', 'pdf', 'xlsx', 'xls', 'doc', 'docx', 'ppt', 'pptx'],
      selectedInspAplySn: null, // 선택 행 검사신청일련번호 
      selectedInspAplyData: null, // 선택한 행 전체 데이터 저장 
    };
  },
  watch : {},
  created() {},
  mounted() {
    this.readAllFcltInspStat();
  },
  beforeMount (){

  },
  methods : {
    readAllFcltInspStat() {
      const radios = document.querySelectorAll('input[type="radio"]');
      radios.forEach(radio => radio.checked = false);
      
      this.searchParam.size = PAGE_SIZE;
      this.$apiCall.get(API.FCLT_INSP_STAT, this.searchParam, this.readAllFcltInspStatSuccess, this.error);
    },
    readAllFcltInspStatSuccess(data) {
      if (!data.result) {
        this.error();
        return;
      }

      this.listObj = data.result;
      this.listObj.fcltInspStatDataCount = data.result.total;
      this.lastPage = Math.ceil(this.listObj.fcltInspStatDataCount / this.searchParam.size);
    },
    readFcltInspAplyHstry(pInspAplySn) {
        // 정기검사신청 이력 조회 
        this.$apiCall.get(buildUri(API.FCLT_INSP_HISTORY, {inspAplySn : pInspAplySn}), {}, this.readFcltInspAplyHstrySuccess, this.error);
    },
    readFcltInspAplyHstrySuccess(data) {
      if (!data.result) {
        this.error();
        return;
      }

      this.histObj = data.result;
      this.openModal('#modalInspAplyHstry');
    },
    createFcltInspSpnsAply() {
        if (!confirm("검사 연기 신청하시겠습니까?")){
            return; 
        }

        this.inspSpnsObj.inspSpnsPrlgSeCd = "01-00-00";
        this.inspSpnsObj.inspSpnsSttsCd = "01"; 

        this.$refs.extAttachFileList.save().then(atchFileGroupId => {  
          this.inspSpnsObj.atchFileGroupId = atchFileGroupId;

            this.$apiCall.post(
                API.FCLT_INSP_SPNS_APLY, 
                this.inspSpnsObj,
                () => {
                    alert("검사 연기 신청이 완료 되었습니다.");
                    this.readAllFcltInspStat();
                    this.closeModal(); 
                },
                () => {
                    alert("저장에 실패하였습니다.");
                }
            );
        });
    }, 
    readInspSpnsDetail(pInspAplySn, pInspSpnsPrlgSqno) {
        // 정기검사 연기 신청 정보 조회 
        this.$apiCall.get(buildUri(API.FCLT_INSP_SPNS_DETAIL, {inspAplySn : pInspAplySn, inspSpnsPrlgSqno : pInspSpnsPrlgSqno}), {}, this.readInspSpnsDetailSuccess, this.error);
    },
    readInspSpnsDetailSuccess(data) {
      if (!data.result) {
        this.error();
        return;
      }

      this.inspSpnsObj = data.result;
      this.inspSpnsObj.inspSpnsAplySn = data.result.inspAplySn;
      this.inspSpnsObj.chgBfrYmd = data.result.chgYmd.replaceAll('-', '');
      this.isModalInspSpnsVisible = true;
    },
    error() {
      alert("데이터를 가져오는 중 오류가 발생했습니다.");
    },
    getInspPrgrsSttsClass(pInspPrgrsSttsCd) {
      /** 
       * 검사진행상태코드 
       * 00:작성중, 10:신청, 20:서류검토중, 30:서류보완요청, 40:접수, 50:반려, 
       * 60:검사진행중, 70:완료, 80:사전취소, 90:검사취하, 100:검사연기, 110:처리기한연장
       */ 
      if (['10', '70'].includes(pInspPrgrsSttsCd)) {
        return 'badge'; // 청색 
      } else if (['80', '90'].includes(pInspPrgrsSttsCd)) { 
        return 'badge bg1'; // 회색 
      } else if (['30', '50'].includes(pInspPrgrsSttsCd)) { 
        return 'badge bg2'; // 적색 
      } else if (['00', '20', '40', '60', '100', '110'].includes(pInspPrgrsSttsCd)) { 
        return 'badge bg3'; // 백색 
      }
    }, 
    getInspRsltClass(pInspRsltCd) {
      /** 
       * 검사결과코드 
       * 10:합격, 20:불합격, 30:업체 취소, 40:면허반납, 50:폐업
       * 60:품질검사팀 진행, 70:휴업예정, 80:미사용, 90:휴업, 100:품질팀 진행
      * 110:미검 종료, 120:검사 취소, 130:보고서 작성중
       */ 
      if (['10'].includes(pInspRsltCd)) {
        return 'badge'; // 청색 
      } else if (['30', '40', '50', '70', '80', '90', '110', '120'].includes(pInspRsltCd)) { 
        return 'badge bg1'; // 회색 
      } else if (['20'].includes(pInspRsltCd)) { 
        return 'badge bg2'; // 적색 
      } else if (['60', '100', '130'].includes(pInspRsltCd)) { 
        return 'badge bg3'; // 백색 
      }
    },
    openModal(pModalId) { 
      switch (pModalId) {
        case '#modalQltyInspAplyGuide':
          this.isModalQltyInspAplyGuideVisible = true;
          break; 

        case '#modalFcltInspAplyGuide':
          this.isModalFcltInspAplyGuideVisible = true;
          break; 

        case '#modalInspAplyHstry':
          this.isModalInspAplyHstryVisible = true;
          break; 
          
        default:
          // 다른 처리 또는 기본값 설정
          break;
      }
    },
    openModalInspSpnsAply(pInspAplySn, pInspSpnsPrlgSqno, pInspPrnmntYmd) { 
      this.inspSpnsObj.inspSpnsAplySn = pInspAplySn;

      /**
       * 정기 검사연기 신청 Modal 
       */
      if (!pInspSpnsPrlgSqno) { // 신규 등록 
        this.isModalInspSpnsVisible = true;
        this.inspSpnsObj.chgBfrYmd = pInspPrnmntYmd;
        this.inspSpnsObj.chgYmd = "";
        this.inspSpnsObj.inspSpnsAplyRsn = "";
        this.inspSpnsObj.inspAplyStatAtchFileGroupId = "";
        this.inspSpnsObj.atchFileGroupId = "";
        this.inspSpnsObj.fileProcessingData = {};
        this.inspSpnsObj.inspSpnsPrlgSeCd = "01-00-00";
        this.inspSpnsObj.inspSpnsSttsCd = "01"; 
        this.inspSpnsObj.pageType = "new"; 

      } else { // 상세 조회 
        this.inspSpnsObj.pageType = "search"; 
        this.readInspSpnsDetail(pInspAplySn, pInspSpnsPrlgSqno);
      } 
    },
    closeModal() { 
      this.isModalQltyInspAplyGuideVisible = false;
      this.isModalFcltInspAplyGuideVisible = false;
      this.isModalInspAplyHstryVisible = false;
      this.isModalInspSpnsVisible = false;
    },
    navigateTo(navigatePath) {
      if (!navigatePath) {
        alert('페이지 정보가 존재하지 않습니다.');
        return;
      } 

      this.$router.push(
        {
          path : navigatePath
        }
      ).catch(
        () => { 
          console.error("Navigation Error"); 
        }
      );
    },
    openTest () {
      alert('양식 사용여부 확인 후 구현 예정입니다.');
    },
    excelDownload() {
      this.$apiCall.download(API.FCLT_INSP_STAT_EXCEL, {
        "fileName" : "정기검사현황.xlsx"
      });
    },
    navigateFcltInspDetailPage(pInspAplySn) {
      /**
       * 정기검사 신청 상세 화면 이동 
       */
      
      if (!pInspAplySn) {
        alert("검사신청일련번호 데이터가 존재하지 않습니다.");
        return;
      }
      
      const dataObj = {
        inspAplySnValue: pInspAplySn, /* 검사구분코드 */
      };
      
      // 데이터를 Local Storage에 저장 
      localStorage.setItem('fcltInspDetailObj', JSON.stringify(dataObj));  

      this.$router.push(
        {
          path : "/sfli/fclt-insp-aply/FcltInspAplyDetail"
        }
      ).catch(
        () => { 
          console.error("Navigation Error"); 
        }
      );
    },
    selectRowData(item) { // 선택한 행의 데이터를 저장하는 기능 
      this.selectedInspAplyData = item;
    },
    openReportForm(rdFimeName) { // 리포트/이폼 화면 열기 
      if (!this.selectedInspAplySn) {
        alert("출력할 대상을 선택해주세요."); 
        return;
      }

      if (!this.selectedInspAplyData.inspRsltCd && rdFimeName === "reportFcltInspResult") {
        alert("검사결과가 없습니다."); 
        return;
      } 

      if (!this.selectedInspAplyData.inspRsltCd && rdFimeName === "reportFcltInspResult" || this.selectedInspAplyData.bzentyRsltRptpOtptRrmYn === "N") {
        alert("출력이 허용되지 않은 정기검사 결과서입니다."); 
        return;
      }

      this.reportParamObj.selectedPopupComponent = null; // 컴포넌트 리셋
      this.openLayerPopup(rdFimeName);

      /*
       * 재호출 후 팝업에서 노출되는 현상으로 재호출 
       */ 
      setTimeout(() => {
        this.openLayerPopup(rdFimeName);
      }, 50); 
    },
    openLayerPopup(rdFimeName) { // 레이어 팝업 기능 

      setTimeout(() => {
        this.reportParamObj.selectedPopupComponent = 'reportFcltInspPopup'; // 정기검사 신청 내역 화면내 팝업 컴포넌트 

        // rd Param 초기화 
        this.reportParamObj.rdParamFileName = rdFimeName;
        this.reportParamObj.rdParamKey = "";
        this.reportParamObj.rdParamValue = "";
        
        // rd 파일명 기준으로 Param 적용 
        switch (rdFimeName) {
          case 'reportFcltInspResult': // 시설 정기검사 결과서 
            this.reportParamObj.rdParamFileName = rdFimeName;                         // 리포트 mrd 파일명 
            this.reportParamObj.rdParamKey = 'inspAplySn';                            // 리포트 화면 Param Key 
            this.reportParamObj.rdParamValue = this.selectedInspAplyData.inspAplySn;  // 리포트 화면 Param Key에 대한 Value  
            this.reportParamObj.pdfFileName = '정기검사결과서(' + this.selectedInspAplyData.bplcNm + ')_' + utils.getCurrentDateFormatted(); // 다운로드 시 파일명 
            break;

          case 'reportSrfMnftrDclr': // 제조(변경)신고서 양식 
            this.reportParamObj.rdParamFileName = rdFimeName;                     // 리포트 mrd 파일명 
            this.reportParamObj.rdParamKey = 'bplcId';                            // 리포트 화면 Param Key 
            this.reportParamObj.rdParamValue = this.selectedInspAplyData.bplcId;  // 리포트 화면 Param Key에 대한 Value  
            this.reportParamObj.pdfFileName = '제조신고서(' + this.selectedInspAplyData.bplcNm + ')_' + utils.getCurrentDateFormatted(); // 다운로드 시 파일명 

            break;

          case 'reportSrfUseDclr': // 사용허가 신청서 양식
            this.reportParamObj.rdParamFileName = rdFimeName;                     // 리포트 mrd 파일명 
            this.reportParamObj.rdParamKey = 'bplcId';                            // 리포트 화면 Param Key 
            this.reportParamObj.rdParamValue = this.selectedInspAplyData.bplcId;  // 리포트 화면 Param Key에 대한 Value  
            this.reportParamObj.pdfFileName = '사용허가신청서(' + this.selectedInspAplyData.bplcNm + ')_' + utils.getCurrentDateFormatted(); // 다운로드 시 파일명 

            break;

          default:
            break;
        }

        storeSwitch.on(this.reportParamObj.selectedPopupComponent); 
      }, 0); // 0ms 딜레이로 컴포넌트 강제 리렌더링 (리포트 리렌더링 안 되는 케이스가 존재하여 추가)
    },
  } 
}
</script>

<style scoped>
  .board_list.scroll > table {
    width: 170rem;
  }

  @media (max-width: 600px) {
    .board_list.scroll > table {
      width: 100%;
    }
  }

  .file-upload-visible dd {
    width: 560px !important;
  }
</style>