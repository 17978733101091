<template>
  <div class="tab_area">
    <ul class="tab">
      <li
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{active: activeTab === index}"
          @click="selectTab(index)"
      >
        <button type="button" class="btn_tab">
          {{ tab.name }}
          <i v-if="activeTab === index" class="sr_only">선택됨</i>
        </button>
      </li>
    </ul>
    <div class="tab_conts_wrap">
      <section class="tab_conts" v-show="activeTab === 0">
        <h3 class="title2">사업장별 고형연료제품 반입 현황</h3>
        <sb-grid
            id="sdflCrynGrid"
            :dataSource="sdflCrynDataSource"
            :columns="sdflCrynGridColumns"
            :gridattr="sdflCrynGridAttr"
            v-model:grid="sdflCrynGridObject"
            @loaded="sdflCrynGridLoaded"
            :refreshGrid="sdflCrynGridReload"
        />
      </section>

      <section class="tab_conts" v-show="activeTab === 1">
        <h3 class="title2">고형연료제품 종류별 투입 현황</h3>
        <sb-grid
            id="sdflInputGrid"
            :dataSource="sdflInputDataSource"
            :columns="sdflInputGridColumns"
            :gridattr="sdflInputGridAttr"
            v-model:grid="sdflInputGridObject"
            @loaded="sdflInputGridLoaded"
            :refreshGrid="sdflInputGridReload"
        />
      </section>

      <section class="tab_conts" v-show="activeTab === 2">
        <h3 class="title2">에너지 생산/판매 현황</h3>
        <sb-grid
            id="prdctnNtslGrid"
            :dataSource="prdctnNtslDataSource"
            :columns="prdctnNtslGridColumns"
            :gridattr="prdctnNtslGridAttr"
            v-model:grid="prdctnNtslGridObject"
            @loaded="prdctnNtslGridLoaded"
            :refreshGrid="prdctnNtslGridReload"
        />
      </section>

      <section class="tab_conts" v-show="activeTab === 3">
        <h3 class="title2">업체별 에너지 판매 현황</h3>
        <sb-grid
            id="ntslDtlGrid"
            :dataSource="ntslDtlDataSource"
            :columns="ntslDtlGridColumns"
            :gridattr="ntslDtlGridAttr"
            v-model:grid="ntslDtlGridObject"
            @loaded="ntslDtlGridLoaded"
            :refreshGrid="ntslDtlGridReload"
        />
      </section>
    </div>
  </div>
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";
import SbGrid from "@/components/SbGrid.vue";

const CODE = {
  PRODUCT: "MBR010",
};
const API = {
  SOLID_FUEL_CARRY_IN_STATUS: "/prfmnc/sdflUse/sdfl-cryn-stts",
  SOLID_FUEL_INPUT_STATUS: "/prfmnc/sdflUse/sdfl-input-stts",
  PRODUCTION_NET_SALES_STATUS: "/prfmnc/sdflUse/prdctn-ntsl-stts",
  NET_SALES_DETAILS_STATUS: "/prfmnc/sdflUse/ntsl-dtl-stts",
};

export default {
  components: {SbGrid},
  props: ['param'],
  data() {
    return {
      tabs: [
        { name: '반입현황' },
        { name: '투입현황' },
        { name: '생산/판매현황' },
        { name: '판매상세현황' },
      ],
      activeTab: 0,
      sdflCrynGridReload: false,
      sdflCrynGridObject: null,
      sdflCrynDataSource: [],
      sdflCrynGridColumns: [],
      sdflCrynGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        excelExport: {
          fileName: '사업장별 고형연료제품 반입 현황.xlsx',
          includeHeader: true,
          includeFormula: true,
          keepMerge: true,
        },
      },
      sdflInputGridReload: false,
      sdflInputGridObject: null,
      sdflInputDataSource: [],
      sdflInputGridColumns: [],
      sdflInputGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        excelExport: {
          fileName: '고형연료제품 종류별 투입 현황.xlsx',
          includeHeader: true,
          keepMerge: true,
        },
      },
      prdctnNtslGridReload: false,
      prdctnNtslGridObject: null,
      prdctnNtslDataSource: [],
      prdctnNtslGridColumns: [],
      prdctnNtslGridAttr: {
        showRowNo: false,
        pageable: false,
        excelExport: {
          fileName: '에너지 생산/판매 현황.xlsx',
          includeHeader: true,
          keepMerge: true,
        },
        height: 541,
      },
      ntslDtlGridReload: false,
      ntslDtlGridObject: null,
      ntslDtlDataSource: [],
      ntslDtlGridColumns: [],
      ntslDtlGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        excelExport: {
          fileName: '업체별 에너지 판매 현황.xlsx',
          includeHeader: true,
          keepMerge: true,
        }
      },
      prdctItemCdList: [],
      sdflCrynInfo: {},
      sdflInputInfo: {},
      prdctnNtslInfo: {},
      ntslDtlInfo: {},
    };
  },
  computed: {},
  watch: {
    activeTab() {
      this.search();
    }
  },
  created() {
    this.initialize();
  },
  mounted() {
  },
  methods: {
    async initialize() {
      storeSwitch.on("loading");
      this.loadPrdctItemCd();
      await this.loadSdflCrynInfo();
      this.initializeSdflCryn();
      await this.loadSdflInputInfo();
      this.initializeSdflInput();
      await this.loadPrdctnNtslInfo();
      this.initializePrdctnNtsl();
      await this.loadNtslDtlInfo();
      this.initializeNtslDtl();
      storeSwitch.off("loading");
    },
    loadPrdctItemCd() {
      const prdctItemCdList = this.$store.getters.getCodeList({ groupCd: CODE.PRODUCT, useYn: 'Y', frstRefVl: "SRF" });
      this.prdctItemCdList = prdctItemCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    loadSdflCrynInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.SOLID_FUEL_CARRY_IN_STATUS,
            { bplcId: this.param.bplcId, prfmncYr: this.param.prfmncYr },
            data => {
              if (data.result) {
                this.sdflCrynInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load prfmnc cryn info."));
            }
        );
      });
    },
    loadSdflInputInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.SOLID_FUEL_INPUT_STATUS,
            { bplcId: this.param.bplcId, prfmncYr: this.param.prfmncYr },
            data => {
              if (data.result) {
                this.sdflInputInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load prfmnc input info."));
            }
        );
      });
    },
    loadPrdctnNtslInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.PRODUCTION_NET_SALES_STATUS,
            { bplcId: this.param.bplcId, prfmncYr: this.param.prfmncYr },
            data => {
              if (data.result) {
                this.prdctnNtslInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load prdct ntsl info."));
            }
        );
      });
    },
    loadNtslDtlInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.NET_SALES_DETAILS_STATUS,
            { bplcId: this.param.bplcId, prfmncYr: this.param.prfmncYr },
            data => {
              if (data.result) {
                this.ntslDtlInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load ntsl dtl info."));
            }
        );
      });
    },
    initializeSdflCryn() {
      this.sdflCrynDataSource = this.sdflCrynInfo.list;
      this.initializeSdflCrynGridColumns();
      this.initializeSdflCrynGridAttr();
      this.sdflCrynGridReload = true;
    },
    initializeSdflInput() {
      this.sdflInputDataSource = this.sdflInputInfo.list;
      this.initializeSdflInputGridColumns();
      this.initializeSdflInputGridAttr();
      this.sdflInputGridReload = true;
    },
    initializePrdctnNtsl() {
      this.prdctnNtslDataSource = this.prdctnNtslInfo.list;
      this.initializePrdctnNtslGridColumns();
      this.initializePrdctnNtslGridAttr();
      this.prdctnNtslGridReload = true;
    },
    initializeNtslDtl() {
      this.ntslDtlDataSource = this.ntslDtlInfo.list;
      this.initializeNtslDtlGridColumns();
      this.initializeNtslDtlGridAttr();
      this.ntslDtlGridReload = true;
    },
    initializeSdflCrynGridColumns() {
      this.sdflCrynGridColumns = [
        { field: 'prfmncId', caption: '실적아이디', visible: false },
        { field: 'prfmncYm', caption: '실적연월', type: 'date', width: 100, mergeRow: true,
          total: { headerCss:'grid-header' },
          format: (date) => {
            if (date && date.length === 6) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              return `${yyyy}-${mm}`;
            }
            return date;
          },
        },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false },
        { field: 'ctpvStdgNm', caption: '시·도', width: 110,
          mergeRow: (data) => data.prfmncYm + '.' + data.ctpvStdgCd,
          total: { headerCss:'grid-header' },
        },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false },
        { field: 'sggStdgNm', caption: '시·군·구', width: 110,
          mergeRow: (data) => data.prfmncYm + '.' + data.ctpvStdgCd + "." + data.sggStdgCd,
          total: { headerCss:'grid-header' },
        },
        { field: 'bplcNm', caption: '사업장명', minWidth: 100, width: 100, unit: '%', align: 'left',
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          },
        },
        { caption: '고형연료제품 반입정보',
          columns: [
            { field: 'prdctSumHndqty', caption: '인계량(톤)', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [
                  {func: 'sum', require: 'prdctSumHndqty'},
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00' },
                ],
                headerCss:'grid-header',
              },
            },
            { field: 'prdctSumAcpqty', caption: '인수량(톤)', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [
                  {func: 'sum', require: 'prdctSumAcpqty'},
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00' },
                ],
                headerCss:'grid-header',
              },
            },
            { field: 'prdctSumDscdCrtqty', caption: '폐기반출량(톤)', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [
                  {func: 'sum', require: 'prdctSumDscdCrtqty'},
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00' },
                ],
                headerCss:'grid-header',
              },
            },
          ]
        },
      ]
    },
    initializeSdflCrynGridAttr() {
    },
    initializeSdflInputGridColumns() {
      const list = this.sdflInputInfo.list || [];
      const uniquePrdctItemCdList = [...new Set(list.map(item => item.prdctItemCd))];
      const filteredPrdctItemList = this.prdctItemCdList
          .filter(item => uniquePrdctItemCdList.includes(item.value));
      const prdctItemList = filteredPrdctItemList.length === 1
          ? []
          : filteredPrdctItemList.map(item => ({ text: item.text, value: item.value }));

      this.sdflInputGridColumns = [
        { field: 'prfmncId', caption: '실적아이디', visible: false },
        { field: 'prfmncYm', caption: '실적연월', type: 'date', width: 100, mergeRow: true, part: 'head',
          total: { headerCss:'grid-header' },
          format: (date) => {
            if (date && date.length === 6) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              return `${yyyy}-${mm}`;
            }
            return date;
          },
        },
        { field: 'prdctItemCd', caption: '제품품목코드', visible: false },
        { field: 'prdctItemNm', caption: '고형연료제품종류', width: 150, part: 'head',
          total: {
            aggregates: [],
            header: [
              { template: '합계' },
              ...prdctItemList.map(item => ({ template: item.text }))
            ],
            headerCss:'grid-header',
          }
        },
        { caption: '고형연료제품 반입/투입 현황',
          columns: [
            { field: 'prdctCrfqty', caption: '이월량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
              total: {
                aggregates: [],
                header: [
                  { template: '{{sum}}', format: '#,##0.00' },
                  ...prdctItemList.map(item => ({
                    template: `{{prdctCrfqty${item.value}}}`, format: '#,##0.00'
                  }))
                ],
                headerCss:'grid-header',
              },
            },
            { field: 'prdctSumAcpqty', caption: '인수량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
              total: {
                aggregates: [
                  {func: 'sum', require: 'prdctSumAcpqty'},
                  ...prdctItemList.map(item => ({
                    func: (items) => items
                        .filter(entry => entry.prdctItemCd === item.value)
                        .reduce((sum, entry) => sum + (entry.prdctSumAcpqty || 0), 0),
                    field: `prdctSumAcpqty${item.value}`
                  }))
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00' },
                  ...prdctItemList.map(item => ({
                    template: `{{prdctSumAcpqty${item.value}}}`, format: '#,##0.00'
                  }))
                ],
                headerCss:'grid-header',
              },
            },
            { field: 'prdctSumInpqty', caption: '투입량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
              total: {
                aggregates: [
                  {func: 'sum', require: 'prdctSumInpqty'},
                  ...prdctItemList.map(item => ({
                    func: (items) => items
                        .filter(entry => entry.prdctItemCd === item.value)
                        .reduce((sum, entry) => sum + (entry.prdctSumInpqty || 0), 0),
                    field: `prdctSumInpqty${item.value}`
                  }))
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00' },
                  ...prdctItemList.map(item => ({
                    template: `{{prdctSumInpqty${item.value}}}`, format: '#,##0.00'
                  }))
                ],
                headerCss:'grid-header',
              },
            },
            { field: 'prdctRsdqty', caption: '잔재물량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
              total: {
                aggregates: [
                  {func: 'sum', require: 'prdctRsdqty'},
                  ...prdctItemList.map(item => ({
                    func: (items) => items
                        .filter(entry => entry.prdctItemCd === item.value)
                        .reduce((sum, entry) => sum + (entry.prdctRsdqty || 0), 0),
                    field: `prdctRsdqty${item.value}`
                  }))
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00' },
                  ...prdctItemList.map(item => ({
                    template: `{{prdctRsdqty${item.value}}}`, format: '#,##0.00'
                  }))
                ],
                headerCss:'grid-header',
              },
            },
            { field: 'prdctRmnqty', caption: '잔량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
              total: {
                aggregates: [],
                header: [
                  { template: '{{sum}}', format: '#,##0.00' },
                  ...prdctItemList.map(item => ({
                    template: `{{prdctRmnqty${item.value}}}`, format: '#,##0.00'
                  }))
                ],
                headerCss:'grid-header',
              },
            },
            { field: 'prdctSumLcp', caption: '저위발열량(kcal/kg)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
              total: {
                aggregates: [],
                header: [
                  { template: '{{sum}}', format: '#,##0.00' },
                  ...prdctItemList.map(item => ({
                    template: `{{prdctSumLcp${item.value}}}`, format: '#,##0.00'
                  }))
                ],
                headerCss:'grid-header',
              },
            },
          ]
        }
      ]
    },
    initializeSdflInputGridAttr() {
    },
    initializePrdctnNtslGridColumns() {
      const is2025OrLater = this.param.prfmncYr >= 2025;

      this.prdctnNtslGridColumns = [
        { field: 'prfmncId', caption: '실적아이디', visible: false },
        { field: 'prfmncYm', caption: '실적연월', type: 'date', width: 100, mergeRow: true, part: 'head',
          format: (date) => {
            if (date && date.length === 6) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              return `${yyyy}-${mm}`;
            }
            return date;
          },
          total: {
            aggregates: [],
            header: [ { template: '합계', align: 'right' }],
            headerCss:'grid-header',
          },
        },
        ...(is2025OrLater ? [
          { caption: '보조연료' },
        ] : []),
        { caption: '에너지 생산',
          columns: [
            ...(!is2025OrLater ? [
              { field: 'stSumPrdqty', caption: '스팀(st/톤)', type:'number', width: 16.6, unit: '%', align: 'right',captionCss:'gridh-bg-vapor',
                dataType:'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', require: 'stSumPrdqty'}],
                  header: [{ template: '{{sum}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
              }
            ] : []),
            ...(is2025OrLater ? [
              { field: 'vaporSumPrdqty', caption: '증기(톤)', type:'number', width: 100, align: 'right',captionCss:'gridh-bg-vapor',
                dataType:'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', require: 'vaporSumPrdqty'}],
                  header: [{ template: '{{sum}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
              },
            ] : []),
            { field: 'prvtmSumPrdqty', caption: '전기(MWh)', dataType:'number', format: '#,##0.00', align: 'right',captionCss:'gridh-bg-electricity',
              width: is2025OrLater ? 100 : 16.6,
              ...(is2025OrLater ? {} : { unit: '%' }),
              total: {
                aggregates: [{func: 'sum', require: 'prvtmSumPrdqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
          ]
        },
        { caption: '에너지 사용',
          columns: [
            ...(!is2025OrLater ? [
              { field: 'stUsqty', caption: '스팀(st/톤)', dataType:'number', format: '#,##0.00', align: 'right', width: 16.6, unit: '%',captionCss:'gridh-bg-vapor',
                total: {
                  aggregates: [{func: 'sum', require: 'stUsqty'}],
                  header: [{ template: '{{sum}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
              },
              { field: 'prvtmSumUsqty', caption: '전기(MWh)', dataType:'number', format: '#,##0.00', align: 'right', width: 16.6, unit: '%',captionCss:'gridh-bg-electricity',
                total: {
                  aggregates: [{func: 'sum', require: 'prvtmSumUsqty'}],
                  header: [{ template: '{{sum}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
              },
            ] : []),
            ...(is2025OrLater ? [
              { caption: '증기(톤)',captionCss:'gridh-bg-vapor',
                columns: [
                  { field: 'stUsgVaporSumUsqty', caption: '스팀이용(톤)', dataType:'number', format: '#,##0.00', align: 'right', width: 100,captionCss:'gridh-bg-vapor',
                    total: {
                      aggregates: [{func: 'sum', require: 'stUsgVaporSumUsqty'}],
                      header: [{ template: '{{sum}}', format: '#,##0.00' }],
                      headerCss:'grid-header',
                    },
                  },
                  { field: 'htwtrUsgVaporSumUsqty', caption: '온수이용(톤)', dataType:'number', format: '#,##0.00', align: 'right', width: 100,captionCss:'gridh-bg-vapor',
                    total: {
                      aggregates: [{func: 'sum', require: 'htwtrUsgVaporSumUsqty'}],
                      header: [{ template: '{{sum}}', format: '#,##0.00' }],
                      headerCss:'grid-header',
                    },
                  },
                  { field: 'elctgnUsgVaporSumUsqty', caption: '발전이용(톤)', dataType:'number', format: '#,##0.00', align: 'right', width: 100,captionCss:'gridh-bg-vapor',
                    total: {
                      aggregates: [{func: 'sum', require: 'elctgnUsgVaporSumUsqty'}],
                      header: [{ template: '{{sum}}', format: '#,##0.00' }],
                      headerCss:'grid-header',
                    },
                  },
                ]
              },
              { field: 'htwtrSumUsqty', caption: '온수(Gcal)', dataType:'number', format: '#,##0.00', align: 'right', width: 100,captionCss:'gridh-bg-hot-water',
                total: {
                  aggregates: [{func: 'sum', require: 'htwtrSumUsqty'}],
                  header: [{ template: '{{sum}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
              },
              { field: 'prvtmUsqty', caption: '전기(MWh)', dataType:'number', format: '#,##0.00', align: 'right', width: 100,captionCss:'gridh-bg-electricity',
                total: {
                  aggregates: [{func: 'sum', require: 'prvtmUsqty'}],
                  header: [{ template: '{{sum}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
              },
            ] : []),
          ]
        },
        { caption: '에너지 판매',
          columns: [
            ...(!is2025OrLater ? [
              { field: 'stSumNsqty', caption: '스팀(st/톤)', type:'number', width: 16.6, unit: '%', align: 'right',captionCss:'gridh-bg-vapor',
                dataType:'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', require: 'stSumNsqty'}],
                  header: [{ template: '{{sum}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
              }
            ] : []),
            ...(is2025OrLater ? [
              { field: 'vaporSumNsqty', caption: '증기(톤)', type:'number', width: 100, align: 'right',captionCss:'gridh-bg-vapor',
                dataType:'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', require: 'vaporSumNsqty'}],
                  header: [{ template: '{{sum}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
              },
              { field: 'htwtrSumNsqty', caption: '온수(Gcal)', type:'number', width: 100, align: 'right',captionCss:'gridh-bg-hot-water',
                dataType:'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', require: 'htwtrSumNsqty'}],
                  header: [{ template: '{{sum}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
              },
            ] : []),
            { field: 'prvtmSumNsqty', caption: '전기(MWh)', dataType:'number', format: '#,##0.00', align: 'right',captionCss:'gridh-bg-electricity',
              width: is2025OrLater ? 100 : 16.6,
              ...(is2025OrLater ? {} : { unit: '%' }),
              total: {
                aggregates: [{func: 'sum', require: 'prvtmSumNsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
          ]
        }
      ];

      if (is2025OrLater) {
        if (this.prdctnNtslInfo.asstFuelKndGroupList && this.prdctnNtslInfo.asstFuelKndGroupList.length > 0) {
          this.prdctnNtslGridColumns[2].columns = [];

          this.prdctnNtslInfo.asstFuelKndGroupList.forEach(item => {
            this.prdctnNtslGridColumns[2].columns.push({
              field: item.asstFuelKndGroupCd,
              caption: item.asstFuelKndGroupNm,
              width: 110,
              total: {
                aggregates: [{func: 'sum', require: item.asstFuelKndGroupCd}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
              align: 'right',
              format: '#,##0.00'
            });
          });
        }else{
          this.prdctnNtslGridColumns[2] = {...this.prdctnNtslGridColumns[2], total: { headerCss:'grid-header' } }
        }
      }
    },
    initializePrdctnNtslGridAttr() {
    },
    initializeNtslDtlGridColumns() {
      const is2025OrLater = this.param.prfmncYr >= 2025;

      this.ntslDtlGridColumns = [
        { field: 'prfmncId', caption: '실적아이디', visible: false },
        { field: 'prfmncYm', caption: '실적연월', type: 'date', width: 100, mergeRow: true, part: 'head',
          total: { headerCss:'grid-header' },
          format: (date) => {
            if (date && date.length === 6) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              return `${yyyy}-${mm}`;
            }
            return date;
          },
        },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false },
        { field: 'ctpvStdgNm', caption: '시·도', width: 130,
          mergeRow: (data) => data.prfmncYm + '.' + data.ctpvStdgCd,
          total: { headerCss:'grid-header' },
        },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false },
        { field: 'sggStdgNm', caption: '시·군·구', width: 130,
          mergeRow: (data) => data.prfmncYm + '.' + data.ctpvStdgCd + "." + data.sggStdgCd,
          total: { headerCss:'grid-header' },
        },
        { field: 'ntslBplcId', caption: '판매사업장아이디', visible: false },
        { field: 'bzentyNm', caption: '업체명', minWidth: 100, width: 100, unit: '%', align: 'left',
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          },
        },
        { caption: '판매량',
          columns: [
            ...(!is2025OrLater ? [
              { field: 'stNsqty', caption: '스팀(st/톤)', type:'number', width: 140, align: 'right',captionCss:'gridh-bg-vapor',
                dataType:'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', require: 'stNsqty'}],
                  header: [{ template: '{{sum}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
              }
            ] : []),
            ...(is2025OrLater ? [
              { field: 'vaporNsqty', caption: '증기(톤)', type:'number', width: 140, align: 'right',captionCss:'gridh-bg-vapor',
                dataType:'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', require: 'vaporNsqty'}],
                  header: [{ template: '{{sum}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
              },
              { field: 'htwtrNsqty', caption: '온수(Gcal)', type:'number', width: 140, align: 'right',captionCss:'gridh-bg-hot-water',
                dataType:'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', require: 'htwtrNsqty'}],
                  header: [{ template: '{{sum}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
              },
            ] : []),
            { field: 'prvtmNsqty', caption: '전기(MWh)', type:'number', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',captionCss:'gridh-bg-electricity',
              total: {
                aggregates: [{func: 'sum', require: 'prvtmNsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
          ]
        },
      ];
    },
    initializeNtslDtlGridAttr() {
    },
    sdflCrynGridLoaded() {
      this.sdflCrynGridReload = false;
    },
    sdflInputGridLoaded() {
      this.sdflInputGridReload = false;
    },
    prdctnNtslGridLoaded() {
      this.prdctnNtslGridReload = false;
    },
    ntslDtlGridLoaded() {
      this.ntslDtlGridReload = false;
    },
    selectTab(index) {
      this.activeTab = index;
    },
    async crynSearch() {
      await this.loadSdflCrynInfo();
      window.SBGrid3.setClientData(this.sdflCrynGridObject, this.sdflCrynInfo.list || []);
    },
    async inputSearch() {
      await this.loadSdflInputInfo();
      this.initializeSdflInput();
    },
    async prdctnNtslSearch() {
      await this.loadPrdctnNtslInfo();
      this.initializePrdctnNtsl();
    },
    async ntslDtlSearch() {
      await this.loadNtslDtlInfo();
      this.initializeNtslDtl();
    },
    async search() {
      storeSwitch.on("loading");

      switch (this.activeTab) {
        case 0: // 반입현황
            await this.crynSearch();
          break;
        case 1: // 투입현황
            await this.inputSearch();
          break;
        case 2: // 생산/판매현황
            await this.prdctnNtslSearch();
          break;
        case 3: // 판매상세현황
            await this.ntslDtlSearch();
          break;
      }

      storeSwitch.off("loading");
    }
  }
}
</script>

<style scoped>

</style>