<template>
    <div class="desc_box">
        <i class="icon"><img src="@/assets/images/common/ico_desc.png" alt="이미지"></i>
        <div class="desc">
            <strong class="desc_title">품질검사 신청</strong>
            <ul class="dot_list">
                <li>신규 시설이 등록되면 품질검사신청 버튼이 활성화고 개선명령 검사신청 버튼은 비활성화 됩니다.</li>
                <li>지자체의 개선명령을 받고 이를 조치하여 품질검사가 필요한 경우 "개선명령 품질검사"버튼을 클릭하여 신청하여 주십시오.</li>
                <li>확인검사(정기검사) 신청은 해당 분기마다 폐자원에너지센터 담당자 관리하에 자동으로 진행되며, 각 업체에 통보가 됩니다.</li>
            </ul>
            <div class="btn_area left">
                <!-- <button type="button" class="btn xsm helper ico_helper" @click="qltyInspGuideView">품질검사안내</button> -->
                <a role="link" href="#modalQltyInspAplyGuide" class="btn xsm helper ico_helper modal_open" @click.prevent="openModal('#modalQltyInspAplyGuide')">품질검사안내</a>
            </div>
        </div>
    </div>

    <h2 class="title1">품질검사 대상 시설 정보</h2>
    <ul class="card_list" v-if="fcltList.length">
        <li v-for="(item, index) in fcltList" v-bind:key="index">
            <div class="card_top">
                <strong class="c_tit">({{item.bplcId}}) {{ item.bplcNm }}</strong>
                <span class="badge" v-if="item.qltyInspTrgtYn === 'Y'">신청가능</span>
                <span class="badge" v-else-if="item.qltyInspTrgtYn === 'N' && !['50','70'].includes(item.inspPrgrsSttsCd)">{{ item.inspPrgrsSttsNm }}</span>
                <span class="badge" v-else>신청대상아님</span>
            </div>
            <div class="card_body">
                <ul class="txt_list">
                    <li>사업장종류<strong>{{ item.bplcKndNm }}</strong></li>
                    <li v-if="item.pubBzentyYn === 'Y'">사업장구분<strong>공공시설</strong></li>
                    <li v-else>사업장구분<strong>민간시설</strong></li>
                    <li>최초검사 완료일자<strong>{{ item.frstInspYmd }}</strong></li>
                    <li>생산제품<strong>{{ item.prdctnPrdctKndNm }}</strong></li>
                </ul>
            </div>
            <div class="card_btn">
                <button type="button" class="btn sm primary line" v-if="item.qltyInspTrgtYn === 'Y'" @click="writeInspAplyForm(index, '20-10')">품질검사신청</button>
                <!-- <button type="button" class="btn sm primary line" @click="writeInspAplyForm(index, '20-10')">품질검사신청</button> -->
                <button type="button" class="btn sm primary line" v-if="item.qltyInspTrgtYn !== 'Y'" @click="writeInspAplyForm(index, '20-40')">변경검사신청</button>
                <button type="button" class="btn sm secondary" v-bind:disabled="item.qltyInspTrgtYn === 'Y'" @click="writeInspAplyForm(index, '20-30')">개선명령 검사신청</button>
            </div>
        </li>
    </ul>
    <ul v-else>
      <li>
        <div style="display: flex; justify-content: center;">
          <div class="no-data-message">품질검사 대상 정보가 없습니다.</div>
        </div>
      </li> 
    </ul>

  <!-- s: modal popup 품질검사안내 -->
  <div :class="['modal', { visible: isModalQltyInspAplyGuideVisible }]" id="modalQltyInspAplyGuide">
    <div class="popup_box" tabindex="0">
      <h1 class="popup_title">품질검사 신청 안내</h1>
      <div class="popup_cont">
        <div class="desc_box progress_box">
          <i class="icon"><img src="@/assets/images/common/ico_noti.png" alt="이미지"></i>
          <div class="progress">
            <div><strong>품질검사</strong>는 폐자원으로 생산한 고형연료의 품질 여부를 검사합니다. <span>아래 절차대로 진행됩니다.</span></div>
          </div>
        </div>
        <h2 class="title1">최초 검사</h2>
        <div class="img_box_area mt_10">
          <img src="@/assets/images/sub/img_info_pop1-1.png" alt="품질 검사 절차">
        </div>

        <h2 class="title1">확인 검사<span class="info_title">정기 검사 시 해당 분기 확인 검사 시료채취 및 분석 결과 활용</span></h2>
            <div class="img_box_area mt_10">
                <img src="@/assets/images/sub/img_info_pop1-2.png" alt="1. 제조자/사용자 고형 연료 제품, 2. 시료 채취 및 계획 수립 검사 예정일 3일 전까지 통보, 3. 시료 채취 및 분석 고형 연료 제품, 품질 실험/분석 방법, 4. 품질 검사 결과서 발급, 5. 지방 자치 단체 통보(부적합 시 통보)">
            </div>
        <div class="btn_area center">
          <button type="button" class="btn md primary" @click="navigateTo('/wstrsc-ene-intro/sfl/QltyInspGd')">자세히 보기</button>
          <button type="button" class="btn md" @click="closeModal">닫기</button>
        </div>
      </div>
      <button type="button" class="modal_close" @click="closeModal">팝업 닫기</button>
    </div>
  </div>
  <!-- e: modal popup 품질검사안내 -->

</template>

<script>
// import { storeSnackbar } from "@/js/store/store-snackbar";
import { storeSwitch } from "@/js/store/store-switch";
import { storeComponent } from "@/js/store/store-component";


const API = {
    QLTY_INSP_FCLT_LIST: "/qltyInspAply",
}

export default {
  components: {},
  emits: ['qlty-insp-aply-form'],
  expose: ['reset'],
  data() {
    return {
      menu: this.$menusTotal.getRouteMenu(this.$route.path),
      fcltList: [
          {
            bzentyId: "",
            pubBzentyYn: "",
            bplcId: "",
            bplcNm: "",
            bplcKndCd: "",
            bplcKndNm: "",
            fcltSqno: "",
            fcltNm: "",
            prdctnPrdctKndCd: "",
            prdctnPrdctKndNm: "",
            frstInspYmd: "",
            frstInspPassYn: "",
            chgInspTrgtYn: "",
            fcltSttsCd: "",
            fcltSttsN: "",
            inspAplySn: "",
            inspPrgrsSttsCd: "",
            inspPrgrsSttsNm: "",
            qltyInspTrgtYn: "",
          }
        ],
        isModalQltyInspAplyGuideVisible: false, 
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  beforeMount() {
  },
  mounted() {
    this.initialize();
  },
  beforeUnmount() {
    // 컴포넌트 전체 데이터 저장하기
    storeComponent.store(this.menu, this);
  },
  methods: {
    async initialize() {
        await this.read();
    },
    read() {
      this.$apiCall.get(API.QLTY_INSP_FCLT_LIST, "", this.success, this.error);
    },
    success(data) {
      if (!data.result) {
        this.error();
        return;
      }
      
      this.fcltList = data.result.inspFcltList;
      
    },
    error() {
      storeSwitch.alert('warning', "데이터를 가져오는 중 오류가 발생했습니다.");
    },
    writeInspAplyForm(index, inspSeCd){
        const searchKey = {bzentyId: this.fcltList[index].bzentyId, bplcId: this.fcltList[index].bplcId, fcltSqno: this.fcltList[index].fcltSqno, inspSeCd: inspSeCd};
        this.$emit('qlty-insp-aply-form', searchKey);
        
    },
    reset() {
        this.initialize();
    },
    openModal(pModalId) { 
      switch (pModalId) {
        case '#modalQltyInspAplyGuide':
          this.isModalQltyInspAplyGuideVisible = true;
          break; 
          
        default:
          // 다른 처리 또는 기본값 설정
          break;
      }
    },
    closeModal() { 
      this.isModalQltyInspAplyGuideVisible = false;
    },
    navigateTo(navigatePath) {
      if (!navigatePath) {
        alert('페이지 정보가 존재하지 않습니다.');
        return;
      } 

      // window.open(navigatePath, '_blank');
      this.$router.push(navigatePath);
    },
  }
}
</script>

<style scoped>
  .no-data-message {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 5px;
    margin: 20px 0px;
    border-radius: 10px;
    background-color: #f7f7f7;
    color: #333;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #ddd;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    width: 350px;
  }
</style>