<template>
  <h3 class="title2">사업체정보</h3>
  <div class="board_write">
    <table>
      <caption>사업체정보</caption>
      <colgroup>
        <col style="width:20%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:30%">
      </colgroup>
      <tbody>
        <tr>
          <th scope="col">사업체명</th>
          <td class="txt_al">
            {{bzentyParam.bzentyNm}}
          </td>
          <th scope="col">사업자등록번호</th>
          <td class="txt_al">
            {{bzentyParam.brno}}
          </td>
        </tr>
      <tr>
        <th scope="col">소재지</th>
        <td class="txt_al">
          {{bzentyParam.bzentyAddr}}
        </td>
        <th scope="col">위탁운영관리 여부</th>
        <td class="txt_al">
          {{bzentyParam.cnsgnOperMngYn}}
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h3 class="title2">응답자정보</h3>
  <div class="board_write">
    <table>
      <caption>응답자정보</caption>
      <colgroup>
        <col style="width:20%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:30%">
      </colgroup>
      <tbody>
        <tr>
          <th scope="col">소속</th>
          <td class="txt_al"> {{bzentyParam.bzentyNm}} </td>
          <th scope="col">부서</th>
          <td class="txt_al"> {{userParam.deptNm}} </td>
        </tr>
        <tr>
          <th scope="col">직위</th>
          <td class="txt_al"> {{userParam.jbgdNm}} </td>
          <th scope="col">연락처</th>
          <td class="txt_al"> {{userParam.telno}} </td>
        </tr>
        <tr>
          <th scope="col">성명</th>
          <td class="txt_al"> {{userParam.userNm}} </td>
          <th scope="col">E-mail</th>
          <td class="txt_al"> {{userParam.emlAddr}} </td>
        </tr>
      </tbody>
    </table>
  </div>

  <h3 class="title2">설비현황</h3>
  <div class="board_write">
    <table>
      <caption>설비현황</caption>
      <colgroup>
        <col style="width:20%">
        <col style="width:18%">
        <col style="width:14%">
        <col style="width:18%">
        <col style="width:17%">
        <col style="width:13%">
      </colgroup>
      <tbody>
        <tr>
          <th scope="col">보일러 용량</th>
          <td class="txt_al"> {{fcltParam.anlFcltCap  }} </td>
          <td class="txt_al">증기톤/시간</td>
          <th scope="col">증기 엔탈피</th>
          <td class="txt_al"> {{fcltParam.vaporEnthlpVl  }} </td>
          <td class="txt_al">Kcal/kg</td>
        </tr>
        <tr>
          <th scope="col">일 평균 실 가동시간</th>
          <td class="txt_al"> {{fcltParam.dilyOprtngHr  }} </td>
          <td class="txt_al">시간/일</td>
          <th scope="col">보일러 부하율</th>
          <td class="txt_al"> {{fcltParam.blrLdrt  }} </td>
          <td class="txt_al">%</td>
        </tr>
        <tr>
          <th scope="col">연간 실 가동시간</th>
          <td class="txt_al"> {{fcltParam.anlOprtngDayCnt  }} </td>
          <td class="txt_al">일/년</td>
          <th scope="col">보일러 효율</th>
          <td class="txt_al"> {{fcltParam.blrEfcnc  }} </td>
          <td class="txt_al">%</td>
        </tr>
      </tbody>
    </table>
  </div>

  <h3 class="title2">폐기물투입</h3>
  <div class="board_write">
    <table>
      <caption>폐기물투입</caption>
      <colgroup>
        <col style="width:20%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:30%">
      </colgroup>
      <tbody>
        <tr>
          <th scope="col">투입폐기물종류</th>
          <td class="txt_al">
              <div class="form_check">
                <input type="checkbox" id="bzentyParam.lifeWtYn" v-model="isCheckedLifeWtYnYn" :readonly="isReadOnly" @change="fncLifeWtYnChange"/>
                <label for="bzentyParam.lifeWtYn">생활폐기물</label>
              </div>
              <div class="form_check">
                <input type="checkbox" id="bzentyParam.bplcWtYn" v-model="isCheckedBplcWtYnYn" :readonly="isReadOnly" @change="fncBplcWtYnChange"/>
                <label for="bzentyParam.bplcWtYn">사업장폐기물</label>
              </div>
          </td>
          <th scope="col">-</th>
          <td class="txt_al">설비에 투입한 폐기물의 종류</td>
        </tr>
        <tr>
          <th scope="col">폐기물투입량</th>
          <td class="txt_al">
            <br-form-input label="폐기물투입량" style="text-align: right;" v-model="bzentyParam.wtInpqty" :maxlength="13" :readonly="isReadOnly" @input="limitDecimalPlaces" validator="number"/>
          </td>
          <th scope="col">톤/년</th>
          <td class="txt_al">설비에 투입한 폐기물의 연 평균 저위발열량</td>
        </tr>
        <tr>
          <th scope="col">폐기물투입 저위발열량</th>
          <td class="txt_al">
            <br-form-input label="저위발열량" style="text-align: right;" v-model="bzentyParam.wtInputLcp" :maxlength="13" :readonly="isReadOnly" @input="limitDecimalPlaces" validator="number"/>
          </td>
          <th scope="col">Kcal/kg</th>
          <td class="txt_al">설비에 투입한 폐기물의 연 평균 저위발열량</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info" style="margin-top: 10px;">
    <h3 class="title2">보조연료</h3>
    <div>
        <br-button label="+" format="primary" @click="fncAddAsstFuel"  class="btn sm primary line ico_plus txtN"/>
    </div>
  </div>
  <div class="board_write">
    <table v-for="(asstFuel, index) in asstFuelList" :key="index">
      <caption>보조연료</caption>
      <colgroup>
        <col style="width:20%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:25%">
        <col style="width:5%">
      </colgroup>
      <tbody>
        <tr>
          <th scope="col">보조연료종류</th>
          <td class="txt_al">
            <br-form-select label="보조연료그룹" groupCd="PFM007" v-model:value="asstFuel.asstFuelKndGroupCd" :customStyle="{'width':'10rem'}"/>
            <br-form-select label="보조연료" groupCd="PFM008" v-model:value="asstFuel.fireAsstFuelKndCd" :customStyle="{'width':'30rem'}"/>
          </td>
          <th scope="col"></th>
          <td class="txt_al">추가로 투입한 연료 종류</td>
          <td class="txt_ac" rowspan="3">
            <br-button label="-" format="primary" @click="fncDelAsstFuel(asstFuel, index)" class="btn sm point ico_minus txtN"/>
          </td>
        </tr>
        <tr>
          <th scope="col">보조연료투입량</th>
          <td class="txt_al">
              <br-form-input label="보조연료투입량" style="text-align: right;" v-model="asstFuel.asstFuelInpqty" :maxlength="13" :readonly="isReadOnly" @input="limitDecimalPlaces" validator="number"/>
          </td>
          <th scope="col">
              <br-form-select label="보조연료투입량" groupCd="PFM009" v-model:value="asstFuel.asstFuelInpqtyUnitCd" />
          </th>
          <td class="txt_al">추가로 투입한 연료의 총량</td>
        </tr>
        <tr>
          <th scope="col">보조연료 저위발열량</th>
          <td class="txt_al">
              <br-form-input label="저위발열량" style="text-align: right;" v-model="asstFuel.asstFuelLcp" :maxlength="13" :readonly="isReadOnly" @input="limitDecimalPlaces" validator="number"/>
          </td>
          <th scope="col">
              <br-form-select label="보조연료 저위발열량" groupCd="PFM010" v-model:value="asstFuel.asstFuelLcpUnitCd" />
          </th>
          <td class="txt_al">해당 연료의 연 평균 저위발열량</td>
        </tr>
      </tbody>
    </table>
  </div>

  <h3 class="title2">에너지생산</h3>
  <div class="board_write">
    <table>
      <caption>에너지생산</caption>
      <colgroup>
        <col style="width:20%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:30%">
      </colgroup>
      <tbody>
        <tr>
          <th scope="col">증기 생산량</th>
          <td class="txt_al">
            <br-form-input label="증기 생산량" style="text-align: right;" v-model="bzentyParam.enePrdqty" :maxlength="13" :readonly="isReadOnly" @input="limitDecimalPlaces" validator="number"/>
          </td>
          <th scope="col">증기톤/년</th>
          <td class="txt_al">연간 생산된 총 증기량</td>
        </tr>
        <tr>
          <th scope="col">중기온도</th>
          <td class="txt_al">
            <br-form-input label="중기온도" style="text-align: right;" v-model="bzentyParam.vaporTp" :maxlength="5" :readonly="isReadOnly" @input="limitDecimalPlaces" validator="number"/>
          </td>
          <th scope="col">℃</th>
          <td class="txt_al">생산된 증기의 온도</td>
        </tr>
        <tr>
          <th scope="col">증기압력</th>
          <td class="txt_al">
            <br-form-input label="증기압력" style="text-align: right;" v-model="bzentyParam.vaporPrssVl" :maxlength="13" :readonly="isReadOnly" @input="limitDecimalPlaces" validator="number"/>
          </td>
          <th scope="col">
              <br-form-select label="증기압력단위" groupCd="PFM011" v-model:value="bzentyParam.vaporPrssUnitCd" readonly/>
          </th>
          <td class="txt_al">생산된 증기의 압력</td>
        </tr>
      </tbody>
    </table>
  </div>

  <h3 class="title2">에너지판매 및 이용</h3>
  <div class="board_write">
    <table>
      <caption>에너지판매 및 이용</caption>
      <colgroup>
        <col style="width:5%">
        <col style="width:15%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:30%">
      </colgroup>
      <tbody>
        <tr>
          <th scope="col" rowspan="4">증기</th>
          <th scope="col">자체이용 스팀</th>
          <td class="txt_al">
            <br-form-input label="자체이용 스팀" style="text-align: right;" v-model="bzentyParam.vaporStSlfUsqty" :maxlength="13" :readonly="isReadOnly" @input="limitDecimalPlaces" validator="number"/>
          </td>
          <th scope="col" rowspan="4">증기톤/년</th>
          <td class="txt_al">연간 자체 이용한 증가량(사업체 내 공정 등에 활용한 증기량)</td>
        </tr>
        <tr>
          <th scope="col">자체이용 온수</th>
          <td class="txt_al">
            <br-form-input label="자체이용 온수" style="text-align: right;" v-model="bzentyParam.vaporHtwtrSlfUsqty" :maxlength="13" :readonly="isReadOnly" @input="limitDecimalPlaces" validator="number"/>
          </td>
          <td class="txt_al">연간 온수 생산을 위해 이용한 증기량 (열교환기 등에 활용한 증기량)</td>
        </tr>
        <tr>
          <th scope="col">자체이용 발전</th>
          <td class="txt_al">
            <br-form-input label="자체이용 발전" style="text-align: right;" v-model="bzentyParam.vaporElctgnSlfUsqty" :maxlength="13" :readonly="isReadOnly" @input="limitDecimalPlaces" validator="number"/>
          </td>
          <td class="txt_al">연간 전기 생산을 위해 이용한 증기량 (발전기 가동을 위해 활용한 증기량)</td>
        </tr>
        <tr>
          <th scope="col">외부공급(판매)</th>
          <td class="txt_al">
            <br-form-input label="외부공급(판매)" style="text-align: right;" v-model="bzentyParam.vaporNsqty" :maxlength="13" :readonly="isReadOnly" @input="limitDecimalPlaces" validator="number"/>
          </td>
          <td class="txt_al">연간 외부공급(판매)한 증기량</td>
        </tr>
        <tr>
          <th scope="col" rowspan="2">온수</th>
          <th scope="col">자체이용</th>
          <td class="txt_al">
            <br-form-input label="자체이용" style="text-align: right;" v-model="bzentyParam.htwtrSlfUsqty" :maxlength="13" :readonly="isReadOnly" @input="limitDecimalPlaces" validator="number"/>
          </td>
          <th scope="col" rowspan="2">Gcal/년</th>
          <td class="txt_al">연간 실제 자체이용한 온수 열량</td>
        </tr>
        <tr>
          <th scope="col">외부공급(판매)</th>
          <td class="txt_al">
            <br-form-input label="외부공급(판매)" style="text-align: right;" v-model="bzentyParam.htwtrNsqty" :maxlength="13" :readonly="isReadOnly" @input="limitDecimalPlaces" validator="number"/>
          </td>
          <td class="txt_al">연간 실제 외부공급(판매)한 온수 열량</td>
        </tr>
        <tr>
          <th scope="col" rowspan="2">전기</th>
          <th scope="col">자체이용</th>
          <td class="txt_al">
            <br-form-input label="자체이용" style="text-align: right;" v-model="bzentyParam.prvtmSlfUsqty" :maxlength="13" :readonly="isReadOnly" @input="limitDecimalPlaces" validator="number"/>
          </td>
          <th scope="col" rowspan="2">MWh/년</th>
          <td class="txt_al">연간 스팀터빈을 통해 생산된 전력량 중 자체이용량</td>
        </tr>
        <tr>
          <th scope="col">외부공급(판매)</th>
          <td class="txt_al">
            <br-form-input label="외부공급(판매)" style="text-align: right;" v-model="bzentyParam.prvtmNsqty" :maxlength="13" :readonly="isReadOnly" @input="limitDecimalPlaces" validator="number"/>
          </td>
          <td class="txt_al">연간 스팀터빈을 통해 생산된 전력량 중 외부공급(판매)량</td>
        </tr>
      </tbody>
    </table>
  </div>

  <h3 class="title2">증빙자료</h3>
  <div class="board_write">
    <table>
      <caption>증빙자료</caption>
      <colgroup>
        <col style="width:20%">
        <col style="width:80%">
      </colgroup>
      <tbody>
        <tr>
          <th scope="col">증빙자료</th>
          <td class="txt_al">
            <br-attach-file label="첨부파일"
                            v-model:atchFileGroupId="bzentyParam.atchFileGroupId"
                            v-model:fileProcessingData="fileProcessingData"
                            atchFileTaskSeCd="RST"
                            :files="bzentyParam.bzentyAtchFiles"
                            :readonly="isReadOnly"
              />
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="btn_area between">
    <div>
      <br-button label="목록" @click="parentScrollToWorkSheet('listDiv')" v-if="listDivYn"/>
    </div>
    <div>
      <br-button label="저장" format="primary" style="margin-right: 5px;" @click="updateRtrvlStatsRslt"/>
      <br-button label="제출" format="primary" @click="updateRtrvlStatsSbmsn"/>
    </div>
  </div>

</template>

<script>
/**
 * TODO : 회수통계조사 소각열
 *
 * 작성자 : yw
 * 작성일 : 2024.10.14
 * 버전 : 1.0
 */

//import SbGrid from "@/components/SbGrid.vue";
//import {storeSnackbar} from "@/js/store/store-snackbar";
//import {storeComponent} from "@/js/store/store-component";
//import {storeSwitch} from "@/js/store/store-switch";

  const API = {
    RTRVLSTATS_EXMN: "/rtrvlStatsExmn",
    RTRVLSTATS_EXMN_WORKSHEET : "/rtrvlStatsExmn/readRtrvlStatsExmnWorkSheet",
  }

  export default {
    props :{
      parentData: {
        type: Object,
        required: true
      }
    },
    components : {},
    data() {
      return {
        bzentyParam : {
          bzentyNm: '',
          brno: '',
          bzentyAddr: '',
          cnsgnOperMngYn: '',
          lifeWtYn: '',             /*생활폐기물여부*/
          bplcWtYn: '',             /*사업장폐기물여부*/
          wtInpqty: '',             /*폐기물투입량*/
          wtInputLcp: '',           /*폐기물투입저위발열량*/
          enePrdqty: '',            /*에너지생산량*/
          vaporTp: '',              /*증기온도*/
          vaporPrssVl: '',          /*증기압력값*/
          vaporPrssUnitCd: '01',      /*증기압력단위코드*/
          vaporStSlfUsqty: '',      /*증기스팀자체사용량*/
          vaporHtwtrSlfUsqty: '',   /*증기온수자체사용량*/
          vaporElctgnSlfUsqty: '',  /*증기발전자체사용량*/
          vaporNsqty: '',           /*증기판매량*/
          htwtrSlfUsqty: '',        /*온수자체사용량*/
          htwtrNsqty: '',           /*온수판매량*/
          prvtmSlfUsqty: '',        /*전기자체사용량*/
          prvtmNsqty: '',           /*전기판매량*/
          atchFileGroupId: '',      /*첨부파일그룹아이디*/
          sbmsnUserId: '',		      /*제출사용자아이디*/
          sbmsnYmd: '',	 	          /*제출일자*/
          bplcKndCd: '',
          bzentyAtchFiles: [],
        },
        fileProcessingData : {},
        userParam : {
          deptNm: '',
          jbgdNm: '',
          telno: '',
          userNm: '',
          emlAddr: '',
        },
        asstFuelList: [],
        asstFuel:{
          asstFuelKndGroupCd: '',
          fireAsstFuelKndCd: '',
          asstFuelInpqty: '',
          asstFuelInpqtyUnitCd: '',
          asstFuelLcp: '',
          asstFuelLcpUnitCd: '',
        },
        fcltParam: {
          anlFcltCap: '',			  /*연간시설용량*/
          anlOprtngDayCnt: '',	/*연간가동일수*/
          dilyOprtngHr: '',		  /*일간가동시간*/
          vaporEnthlpVl: '',		/*증기엔탈피값*/
          blrLdrt: '',				  /*보일러부하율*/
          blrEfcnc: '',				  /*보일러효율*/
        },
        isReadOnly: true,
        isCheckedLifeWtYnYn: false,
        isCheckedBplcWtYnYn: false,
        delAsstFuel: [],
        listDivYn: false,
      };
    },
  computed: {},
  watch : {},
  created() {},
  beforeMount() {},
  mounted() {
    this.initialize();
  },
  beforeUnmount() {},
  methods : {
    /**화면 초기화 */
    async initialize() {
      if(this.parentData.updateYn){
        if(this.parentData.updateYn === 'Y'){
          this.isReadOnly = false;
        }
      }
      
      if (this.parentData.rtrvlStatsSn && this.parentData.bzentyId && this.parentData.bplcId && this.parentData.fcltSqno) {
        this.readFireRslt();
      }
      
      setTimeout(() => {
         this.parentScrollToWorkSheet('workSheetDiv');
      }, 200)

    },

    /**결과 조회 */
    readFireRslt() {
      return new Promise((resolve) => {
        this.$apiCall.get(API.RTRVLSTATS_EXMN_WORKSHEET, 
        {
          rtrvlStatsSn : this.parentData.rtrvlStatsSn,
          bzentyId : this.parentData.bzentyId,
          bplcId : this.parentData.bplcId,
          fcltSqno : this.parentData.fcltSqno,
          bplcKndCd : this.parentData.bplcKndCd
        }, data => {
          if (data.result) {
            
            ///업체정보
            if(data.result.bzentyInfo){
              this.bzentyParam = data.result.bzentyInfo;

              this.isCheckedLifeWtYnYn = this.bzentyParam.lifeWtYn === 'Y' ? true : false;
              this.isCheckedBplcWtYnYn = this.bzentyParam.bplcWtYn === 'Y' ? true : false;
              this.bzentyParam.vaporPrssUnitCd = '01';
            }

            //작성자정보
            if(data.result.userInfo){
              this.userParam = data.result.userInfo;
            }

            //시설정보
            if(data.result.fcltInfo){
              this.fcltParam = data.result.fcltInfo;
            }

            //보조연료
            if(data.result.asstFuelInfo){
                this.asstFuelList = data.result.asstFuelInfo;
            }

            resolve();
          } else {
            //reject(new Error("데이터를 가져오는 중 오류가 발생했습니다."));
          }
        });
      });
    },

    /**결과 저장 */
    updateRtrvlStatsRslt() {
      this.bzentyParam.rtrvlStatsSn = this.parentData.rtrvlStatsSn; /*회수통계일련번호*/
      this.bzentyParam.bzentyId = this.parentData.bzentyId;         /*업체아이디*/
      this.bzentyParam.bplcId = this.parentData.bplcId;             /*사업장아이디*/
      this.bzentyParam.fcltSqno = this.parentData.fcltSqno;         /*시설순번*/
      this.bzentyParam.bplcKndCd = this.parentData.bplcKndCd;       /*사업장종류코드*/

      this.bzentyParam.lifeWtYn = this.isCheckedLifeWtYnYn === true ? 'Y' : 'N';
      this.bzentyParam.bplcWtYn = this.isCheckedBplcWtYnYn === true ? 'Y' : 'N';

      //보조연료체크
      for(let asstFuel of this.asstFuelList){
        if(asstFuel.asstFuelInpqty != "") {
          
          if(asstFuel.asstFuelKndGroupCd == "") {
            alert("보조연료종류를 선택해주세요.");
            return;
          }
          
          if(asstFuel.srfUseAsstFuelKndCd == "") {
            alert("보조연료종류를 선택해주세요.");
            return;
          }

          if(asstFuel.asstFuelInpqtyUnitCd == "") {
            alert("보조연료투입량 단위를 선택해주세요.");
            return;
          }

          if(asstFuel.asstFuelLcp == "") {
            alert("보조연료 저위발열량을 입력해주세요.");
            return;
          }
  
          if(asstFuel.asstFuelLcpUnitCd == "") {
            alert("보조연료 저위발열량 단위를 선택해주세요.");
            return;
          }
        }
      }

      if(confirm("저장하시겠습니까?")) {
        this.$apiCall.put(
          API.RTRVLSTATS_EXMN + "/updateRtrvlStatsRslt",
          {rsltParam: this.bzentyParam, asstFuelList: this.asstFuelList, fileProcessingData: this.fileProcessingData, delAsstFuel: this.delAsstFuel},
          () => {
            alert("저장되었습니다.");
            this.readFireRslt();
          },
          () => {
            alert("저장에 실패하였습니다.");
          }
        );
      }
    },

    /**제출 버튼 클릭 이벤트 */
    updateRtrvlStatsSbmsn() {
      this.bzentyParam.rtrvlStatsSn = this.parentData.rtrvlStatsSn; /*회수통계일련번호*/
      this.bzentyParam.bzentyId = this.parentData.bzentyId;         /*업체아이디*/
      this.bzentyParam.bplcId = this.parentData.bplcId;             /*사업장아이디*/
      this.bzentyParam.fcltSqno = this.parentData.fcltSqno;         /*시설순번*/
      this.bzentyParam.bplcKndCd = this.parentData.bplcKndCd;       /*사업장종류코드*/

      this.bzentyParam.lifeWtYn = this.isCheckedLifeWtYnYn === true ? 'Y' : 'N';
      this.bzentyParam.bplcWtYn = this.isCheckedBplcWtYnYn === true ? 'Y' : 'N';

      //보조연료체크
      for(let asstFuel of this.asstFuelList){
        if(asstFuel.asstFuelInpqty != "") {
          
          if(asstFuel.asstFuelKndGroupCd == "") {
            alert("보조연료종류를 선택해주세요.");
            return;
          }
          
          if(asstFuel.srfUseAsstFuelKndCd == "") {
            alert("보조연료종류를 선택해주세요.");
            return;
          }

          if(asstFuel.asstFuelInpqtyUnitCd == "") {
            alert("보조연료투입량 단위를 선택해주세요.");
            return;
          }

          if(asstFuel.asstFuelLcp == "") {
            alert("보조연료 저위발열량을 입력해주세요.");
            return;
          }
  
          if(asstFuel.asstFuelLcpUnitCd == "") {
            alert("보조연료 저위발열량 단위를 선택해주세요.");
            return;
          }
        }
      }

      if(confirm("제출하시겠습니까?")) {
        this.$apiCall.put(
          API.RTRVLSTATS_EXMN + "/updateRtrvlStatsSbmsn",
          {rsltParam: this.bzentyParam, asstFuelList: this.asstFuelList, fileProcessingData: this.fileProcessingData, delAsstFuel: this.delAsstFuel, param: this.parentData},
          () => {
            alert("제출되었습니다.");
            this.readFireRslt();
          },
          () => {
            alert("제출에 실패하였습니다.");
          }
        );
      }
    },

    /**숫자, 소수점2자리 */
    limitDecimalPlaces(event) {
      const value = event.target.value.toString();
      let newVal = value?.replace(/^[^0-9]|[^\d.]/g, '');
      const regExp = /^\d+(?:\.\d{0,2})?|\d*\./g;
      const match = newVal?.match(regExp);
      event.target.value = match ? match[0] : newVal;
    },

    fncLifeWtYnChange(){
      if(this.isCheckedLifeWtYnYn){
        this.isCheckedBplcWtYnYn = false;
      }
    },

    fncBplcWtYnChange(){
      if(this.isCheckedBplcWtYnYn){
        this.isCheckedLifeWtYnYn = false;
      }
    },

    fncAddAsstFuel() {
      if(this.asstFuelList.length < 3){
        this.asstFuelList.push(
            {"rtrvlStatsSn": this.parentData.rtrvlStatsSn
            , "bzentyId": this.parentData.bzentyId
            , "bplcId": this.parentData.bplcId
            , "fcltSqno": this.parentData.fcltSqno
            , "asstFuelSqno": ''
            , "asstFuelKndGroupCd": ''
            , "fireAsstFuelKndCd": ''
            , "asstFuelInpqty": ''
            , "asstFuelInpqtyUnitCd": ''
            , "asstFuelLcp": ''
            , "asstFuelLcpUnitCd": ''}
        );
      }
    },
    
    fncDelAsstFuel(asstFuel, index) {
      this.delAsstFuel.push(asstFuel);
      this.asstFuelList.splice(index, 1);
    },
    
    parentScrollToWorkSheet(obj) {
      this.$nextTick(() => {
        if(this.$parent.$refs[obj]) {
          this.$parent.$refs[obj].scrollIntoView({ behavior: 'smooth' });
          this.listDivYn= true;
        }

      });
    },

  }
}
</script>

<style scoped>

</style>