<template>

  <h2 class="title1 mt_0">실적보고 상세</h2>
  <div class="board_write">
    <table>
      <caption>실적연도, 실적상태, 사업장명, 사업장종류</caption>
      <colgroup>
        <col style="width:18%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:32%">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">실적연월</th>
        <td>{{ formattedPrfmncYm }}</td>
        <th scope="row">실적상태</th>
        <td>{{ synthGasDtlObj.prfmncMst.prfmncSttsNm || "작성중" }}</td>
      </tr>
      <tr>
        <th scope="row">사업장명</th>
        <td>{{ synthGasDtlObj.prfmncMst.bplcNm }}</td>
        <th scope="row">사업장종류</th>
        <td>{{ synthGasDtlObj.prfmncMst.bplcKndNm }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <rjct-rsn-area :prfmncBasicInfo="synthGasDtlObj.prfmncMst" />
  <prfmnc-mdfcn-prm-area :prfmncBasicInfo="synthGasDtlObj.prfmncMst" />

  <div class="board_info mt_40">
    <h3 class="title2">원료 반입 현황</h3>
  </div>

  <sb-grid
      id="prfmncDtlSynthGasMtralCrynGrid"
      :dataSource="dataSourceSynthGasMtral"
      :columns="gridColumnsSynthGasMtral"
      :gridattr="gridAttrSynthGasMtral"
      v-model:grid="prfmncSynthGasMtralGridObject"
      @loaded="gridLoadedSynthGasMtral"
      :refreshGrid="gridReloadSynthGasMtral"
  />

  <div class="board_info mt_40">
    <h3 class="title2">원료 투입 현황 </h3>
  </div>
  <div class="board_write thead">
    <table>
      <caption>원료 투입 현황</caption>
      <colgroup>
        <col style="width: 14%;">
        <col style="width: 14%;">
        <col style="width: 14%;">
        <col style="width: 14%;">
        <col style="width: 14%;">
        <col style="width: 15%;">
        <col style="width: 15%;">
      </colgroup>
      <thead>
      <tr>
        <th scope="row" rowspan="2">투입량 합계(톤)</th>
        <th scope="row" rowspan="2">탄화수소(톤)</th>
        <th scope="row" rowspan="2">폐플라스틱(톤)</th>
        <th scope="row" rowspan="2">바이오매스(톤)</th>
        <th scope="row" rowspan="2">천연가스(톤)</th>
        <th scope="col" colspan="2">기타원료</th>
      </tr>
      <tr>
        <th scope="row" style="border-left: 1px solid #e0e0e0;">기타원료 투입량(톤)</th>
        <th scope="row">기타종류</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="txt_right" aria-label="투입량 합계(톤)">{{ formatNumber(totalMtralInpqty) }} 톤</td>
        <td class="txt_right" aria-label="탄화수소(톤)">{{ formatNumber(synthGasDtlObj.prfmncSynthGas.hdrcbtInpqty) }} 톤</td>
        <td class="txt_right" aria-label="폐플라스틱(톤)">{{ formatNumber(synthGasDtlObj.prfmncSynthGas.wstpltInpqty) }} 톤</td>
        <td class="txt_right" aria-label="바이오매스(톤)">{{ formatNumber(synthGasDtlObj.prfmncSynthGas.bioMsstpInpqty) }} 톤</td>
        <td class="txt_right" aria-label="천연가스(톤)">{{ formatNumber(synthGasDtlObj.prfmncSynthGas.natrGstpInpqty) }} 톤</td>
        <td class="txt_right" aria-label="기타원료 투입량(톤)">{{ formatNumber(synthGasDtlObj.prfmncSynthGas.etcMtralInpqty) }} 톤</td>
        <td class="txt_right" aria-label="기타종류">{{ synthGasDtlObj.prfmncSynthGas.etcMtralInputCn }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info mt_40">
    <h3 class="title2">합성가스 생산 현황</h3>
  </div>
  <div class="board_write mb_25">
    <table>
      <caption>합성가스 생산 현황</caption>
      <colgroup>
        <col style="width: 20%;">
        <col style="width: 30%;">
        <col style="width: 20%;">
        <col style="width: 30%;">
      </colgroup>
      <tbody>
      <tr>
        <th scope="col">가스생산량</th>
        <td class="txt_right">{{ formatNumber(synthGasDtlObj.prfmncSynthGas.synthGasPrdqty) }} N㎥</td>
        <th scope="col">저위발열량</th>
        <td class="txt_right">{{ formatNumber(synthGasDtlObj.prfmncSynthGas.synthGasLcp) }} Kcal/kg</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info mt_40">
    <h3 class="title2">합성가스 이용 현황</h3>
  </div>
  <div class="board_write thead">
    <table>
      <caption>합성가스 생산 현황</caption>
      <colgroup>
        <col style="width: 16%;">
        <col style="width: 14%;">
        <col style="width: 14%;">
        <col style="width: 14%;">
        <col style="width: 14%;">
        <col style="width: 14%;">
        <col style="width: 14%;">
      </colgroup>
      <thead>
      <tr>
        <th scope="row" rowspan="2">합성가스 총 이용량(N㎥)<br />(A + B)</th>
        <th scope="col" colspan="3">열원(N㎥)</th>
        <th scope="col" colspan="3">전기(N㎥)</th>
      </tr>
      <tr>
        <th scope="row" style="border-left: 1px solid #e0e0e0;">열원 이용량 합계(A)</th>
        <th scope="row">자체이용(N㎥)</th>
        <th scope="row">외부공급(판매)(N㎥)</th>
        <th scope="row">전기 이용량 합계(B)</th>
        <th scope="row">자체이용(N㎥)</th>
        <th scope="row">외부공급(판매)(N㎥)</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="txt_right" aria-label="합성가스 총 이용량(N㎥)">{{ formatNumber(totalSynthGasUsqty) }} N㎥</td>

        <td class="txt_right" aria-label="열원 이용량 합계(A)">{{ formatNumber(totalHtsrcUsgGasUsqty) }} N㎥</td>
        <td class="txt_right" aria-label="자체이용(N㎥)">{{ formatNumber(synthGasDtlObj.prfmncSynthGas.htsrcUsgGasUsqty) }} N㎥</td>
        <td class="txt_right" aria-label="외부공급(판매)(N㎥)">{{ formatNumber(this.synthGasDtlObj.totalHtsrcUsgGasNsqty) }} N㎥</td>

        <td class="txt_right" aria-label="전기 이용량 합계(B)">{{ formatNumber(totalElctgnUsgGasUsqty) }} N㎥</td>
        <td class="txt_right" aria-label="자체이용(N㎥)">{{ formatNumber(synthGasDtlObj.prfmncSynthGas.elctgnUsgGasUsqty) }} N㎥</td>
        <td class="txt_right" aria-label="외부공급(판매)(N㎥)">{{ formatNumber(this.synthGasDtlObj.totalElctgnUsgGasNsqty) }} N㎥</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info mt_40">
    <h3 class="title2">합성가스 판매 현황</h3>
  </div>
  <div class="grid_search">
    <h4><strong class="mr_5">(합성가스 판매량)</strong>외부공급(판매) 상세현황
      <span>※ 판매업체 목록은 에너지공급업체 현황에서 관리 할 수 있습니다.</span>
    </h4>
  </div>
  <sb-grid
      id="prfmncSynthGasNtslGrid"
      :dataSource="dataSourceSynthGasNtsl"
      :columns="gridColumnsSynthGasNtsl"
      :gridattr="gridAttrSynthGasNtsl"
      v-model:grid="prfmncSynthGasNtslGridObject"
      @loaded="gridLoadedSynthGasNtsl"
      :refreshGrid="gridReloadSynthGasNtsl"
      @change="changeSynthGasNtsl"
  />

  <!-- 실적상태이력 현황 (신규등록인 경우 노출안함) -->
  <prfmnc-stts-hstry v-if="synthGasDtlObj.prfmncMst.prfmncId" :prfmncId="synthGasDtlObj.prfmncMst.prfmncId" />

  <!-- 수정허용요청 이력 현황 (신규등록인 경우 노출안함) -->
  <prfmnc-mdfcn-prm-hstry v-if="synthGasDtlObj.prfmncMst.prfmncId" :prfmncId="synthGasDtlObj.prfmncMst.prfmncId" />

  <!-- 버튼 -->
  <common-button-area
      :prfmncBasicInfo="synthGasDtlObj.prfmncMst"
  />
</template>

<script>
import SbGrid from "@/components/SbGrid.vue";
import utils from "@/js/utils/utils";
import commonButtonArea from "@/views/prfmnc-rpt/dtl/components/CommonButtonArea.vue";
import prfmncSttsHstry from "@/views/prfmnc-rpt/components/PrfmncSttsHstry.vue";
import PrfmncMdfcnPrmHstry from "@/views/prfmnc-rpt/components/PrfmncMdfcnPrmHstry.vue";
import PrfmncMdfcnPrmArea from "@/views/prfmnc-rpt/dtl/components/PrfmncMdfcnPrmArea.vue";
import RjctRsnArea from "@/views/prfmnc-rpt/dtl/components/RjctRsnArea.vue";

const CODE = {
  PERFORMANCE_STATUS: "PFM001", // 실적상태코드
};

export default {
  components: {
    RjctRsnArea, PrfmncMdfcnPrmArea,
    SbGrid,
    commonButtonArea,
    prfmncSttsHstry,
    PrfmncMdfcnPrmHstry
  },
  props: ['prfmncSynthGas', 'prfmncSynthGasMtralCrynList', 'prfmncSynthGasNtslList', 'prfmncMst', 'showDetail'],
  data() {
    return {
      prfmncSttsList: [], // 실적상태코드

      updatedMtralCrynData: [], // 원료반입현황 수정된 행
      insertedMtralCrynData: [], // 원료반입현황 추가된 행

      // 실적 매립가스 상세정보 데이터
      synthGasDtlObj:{
        mthnTotal: 0, // 메탄가스 총 이용량 + 에너지생산 외 + 메탄가스 총 판매량
        totalHdrcbtCryqty: 0, // 원료투입현황 탄화수소(톤) 합계
        totalWstpltCryqty: 0, // 원료투입현황 폐플라스틱(톤) 합계
        totalBioMsstpCryqty: 0, // 원료투입현황 바이오매스(톤) 합계
        totalNatrGstpCryqty: 0, // 원료투입현황 천연가스(톤) 합계
        totalEtcMtralCryqty: 0, // 원료투입현황 기타원료 투입량(톤) 합계

        totalHtsrcUsgGasNsqty: 0, // 합성가스판매현황 열원판매량 합계
        totalElctgnUsgGasNsqty: 0, // 합성가스판매현황 전기판매량 합계
        prfmncMst: {},
        prfmncSynthGasMtralCrynList : [], // 원료반입현황
        prfmncSynthGasNtslList : [], // 합성가스판매현황
        prfmncSynthGas: {
          vrzMtralStotInpqty: 0, // 가상화원료소계투입량 (투입량 합계(톤))
          hdrcbtInpqty: 0, // 탄화수소류투입량 (탄화수소(톤))
          wstpltInpqty: 0, // 폐플라스틱류투입량 (폐플라스틱(톤))
          bioMsstpInpqty: 0, // BIO매스류투입량 (바이오매스(톤))
          natrGstpInpqty: 0, // 천연가스류투입량 (천연가스(톤))
          etcMtralInpqty: 0, // 기타원료투입량 (기타원료투입량(톤))
          etcMtralInputCn: "", // 기타원료투입내용 (기타종류)
          synthGasPrdqty: 0, // 합성가스생산량
          synthGasLcp: 0, // 합성가스저위발열량
          htsrcUsgGasUsqty: 0, // 열원용도가스사용량
          elctgnUsgGasUsqty: 0, // 발전용도가스사용량
        }, // 합성가스실적 (메탄가스생산현황, 메탄가스사용현황)
      },


      /**************** S : 원료반입현황 Grid ****************/
      dataSourceSynthGasMtral: [],
      gridColumnsSynthGasMtral: [],
      gridAttrSynthGasMtral: {
        checkable: false,
        pageable: false,
        editable : false,
        showRowNo : false,
        excelExport: {
          fileName: '원료반입현황(합성가스).xlsx',
          includeHeader: true,
          includeFormula: true,
        },
        showStatus:false,
      },
      prfmncSynthGasMtralGridObject: null,
      gridReloadSynthGasMtral: false,
      /**************** E : 원료반입현황 Grid ****************/

      /**************** S : 합성가스판매현황 Grid ****************/
      dataSourceSynthGasNtsl: [],
      gridColumnsSynthGasNtsl: [],
      gridAttrSynthGasNtsl: {
        checkable: false,
        pageable: false,
        editable: false,
        showRowNo : false,
        excelExport: {
          fileName: '합성가스판매현황(합성가스).xlsx',
          includeHeader: true,
          includeFormula: true,
        },
        showStatus:false,
      },
      prfmncSynthGasNtslGridObject: null,
      gridReloadSynthGasNtsl: false,
      /**************** E : 합성가스판매현황 Grid ****************/
    };
  },
  computed: {
    /**
     * [원료투입현황 - 투입량 합계(톤)]
     * 탄화수소 + 폐플라스틱 + 바이오매스 + 천연가스 + 기타원료 투입량
     */
    totalMtralInpqty() {
      const total = (
          parseFloat(this.synthGasDtlObj.prfmncSynthGas.hdrcbtInpqty) +
          parseFloat(this.synthGasDtlObj.prfmncSynthGas.wstpltInpqty) +
          parseFloat(this.synthGasDtlObj.prfmncSynthGas.bioMsstpInpqty) +
          parseFloat(this.synthGasDtlObj.prfmncSynthGas.natrGstpInpqty) +
          parseFloat(this.synthGasDtlObj.prfmncSynthGas.etcMtralInpqty)
      ) ?? 0;
      return parseFloat(total.toFixed(2));
    },
    /**
     * [합성가스이용현황 - 열원 이용량 합계]
     * 열원자체이용 + 열원외부공급판매
     */
    totalHtsrcUsgGasUsqty() {
      const total = (parseFloat(this.synthGasDtlObj.prfmncSynthGas.htsrcUsgGasUsqty) + parseFloat(this.synthGasDtlObj.totalHtsrcUsgGasNsqty)) ?? 0;
      return parseFloat(total.toFixed(2));
    },
    /**
     * [합성가스이용현황 - 전기 이용량 합계]
     * 전기자체이용 + 전기외부공급판매
     */
    totalElctgnUsgGasUsqty() {
      const total = (parseFloat(this.synthGasDtlObj.prfmncSynthGas.elctgnUsgGasUsqty) + parseFloat(this.synthGasDtlObj.totalElctgnUsgGasNsqty)) ?? 0;
      return parseFloat(total.toFixed(2));
    },
    /**
     * [합성가스이용현황 - 총 이용량 합계]
     * 열원 이용량 합계 + 전기 이용량 합계
     */
    totalSynthGasUsqty() {
      return this.totalHtsrcUsgGasUsqty + this.totalElctgnUsgGasUsqty;
    },
    /**
     * [실적보고상세 - 실적연월]
     * YYYY-MM 포맷처리
     */
    formattedPrfmncYm() {
      return (this.synthGasDtlObj.prfmncMst.prfmncYm?.substring(0, 4) ?? "") + "-" + (this.synthGasDtlObj.prfmncMst.prfmncYm?.substring(4, 6) ?? "");
    },
  },
  watch: {
    prfmncMst: {
      handler(newVal) {
        this.synthGasDtlObj.prfmncMst = {...this.synthGasDtlObj.prfmncMst, ...newVal};
      },
      immediate: true,
      deep:true,
    },
    prfmncSynthGas() {
      this.synthGasDtlObj.prfmncSynthGas = {...this.synthGasDtlObj.prfmncSynthGas, ...this.prfmncSynthGas};
    },
    prfmncSynthGasMtralCrynList() {
      this.dataSourceSynthGasMtral = { data: this.prfmncSynthGasMtralCrynList.list, schema: { id: 'sggStdgCd'}};
      this.initializeprfmncSynthGasMtralGridColumns();
      const itemCount = this.prfmncSynthGasMtralCrynList.list.length;
      this.gridAttrSynthGasMtral.height = Math.max((itemCount * 30) + 170, 210);
      this.gridReloadSynthGasMtral = true;

      // this.synthGasMtralCrynTotal();
      // this.totalGetAllItems(this.prfmncSynthGasMtralGridObject, "hdrcbtCryqty", "totalHdrcbtCryqty");
      // 화면 초기 진입 시 반입량합계를 폐기물매립현황(당월매립량)에 노출 - 이후 반입량 변경 시 changeSynthGasMtral 메서드로 계산
      // const totalWtStotCryqty = this.prfmncSynthGasMtralCrynList.list.reduce((acc, item) => acc + (item.wtStotCryqty || 0), 0);
      // this.synthGasDtlObj.prfmncSynthGas.fillWtTon = totalWtStotCryqty;
    },
    prfmncSynthGasNtslList() {
      this.dataSourceSynthGasNtsl = this.prfmncSynthGasNtslList.list;
      this.initializeprfmncSynthGasNtslGridColumns();
      const itemCount = this.prfmncSynthGasNtslList.list.length;
      this.gridAttrSynthGasNtsl.height = Math.max((itemCount * 30) + 170, 210);
      this.gridReloadSynthGasNtsl = true;

      // 화면 초기 진입 시 열원 판매량 합계값 계산 - 이후 판매량 변경 시 changeSynthGasNtsl 메서드로 계산
      this.synthGasDtlObj.totalHtsrcUsgGasNsqty = this.prfmncSynthGasNtslList.list.reduce((acc, item) => acc + (item.htsrcUsgGasNsqty || 0), 0);

      // 화면 초기 진입 시 전기 판매량 합계값 계산 - 이후 판매량 변경 시 changeSynthGasNtsl 메서드로 계산
      this.synthGasDtlObj.totalElctgnUsgGasNsqty = this.prfmncSynthGasNtslList.list.reduce((acc, item) => acc + (item.elctgnUsgGasNsqty || 0), 0);
    },
  },
  created() {
    this.initialize();
  },
  mounted() {
  },
  methods: {
    // 그리드 내 특정 항목 데이터 합계 구하기
    totalGetAllItems(gridObj, field, targetObjStr){
      const allItems = window.SBGrid3.getAllItems(gridObj);
      const total = allItems.reduce((sum, item) => {
        return sum + (item[field] || 0);
      }, 0);

      this.synthGasDtlObj[targetObjStr] = total;
    },
    // 합성가스 판매현황 onChange
    changeSynthGasNtsl(values){
      values.forEach(item => {

        if (item.field === 'htsrcUsgGasNsqty') {
          // 그리드의 모든 데이터를 가져와서 열원 판매량 항목의 모든 값의 합계를 계산
          const ntslAllItems = window.SBGrid3.getAllItems(this.prfmncSynthGasNtslGridObject);
          const totalHtsrcUsgGasNsqty = ntslAllItems.reduce((sum, item) => {
            return sum + (item.htsrcUsgGasNsqty || 0);
          }, 0);

          this.synthGasDtlObj.totalHtsrcUsgGasNsqty = totalHtsrcUsgGasNsqty;
        }

        if (item.field === 'elctgnUsgGasNsqty') {
          // 그리드의 모든 데이터를 가져와서 전기 판매량 항목의 모든 값의 합계를 계산
          const ntslAllItems = window.SBGrid3.getAllItems(this.prfmncSynthGasNtslGridObject);
          const totalElctgnUsgGasNsqty = ntslAllItems.reduce((sum, item) => {
            return sum + (item.elctgnUsgGasNsqty || 0);
          }, 0);

          this.synthGasDtlObj.totalElctgnUsgGasNsqty = totalElctgnUsgGasNsqty;
        }
      });
    },
    async initialize() {
      await this.loadPrfmncSttsCd();
    },
    // 공통코드(실적상태코드) 로드
    async loadPrfmncSttsCd() {
      const prfmncSttsList = this.$store.getters.getCodeList({groupCd: CODE.PERFORMANCE_STATUS, useYn: 'Y'});
      this.prfmncSttsList = prfmncSttsList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    // 원료 반입현황 그리드 컬럼 세팅
    initializeprfmncSynthGasMtralGridColumns() {
      this.gridColumnsSynthGasMtral = [
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        {
          caption: '반입',
          width: 200,
          part: 'head',
          columns : [
            {
              field: 'ctpvStdgNm',
              caption: '시·도',
              width: 100,
              editable: false,
            },
            {
              field: 'sggStdgNm',
              caption: '시·군·구',
              width: 100,
              editable: false,
            }
          ],
          total: {
            aggregates: [
            ],
            header: [
              { template: '계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        {
          field: 'vrzMtralStotCryqty',
          caption: '반입량 합계(톤)',
          unit: '%',
          align: 'right',
          dataType: 'number',
          editable: false,
          colCss: 'grid-disabled',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          calc: {
            require: ['hdrcbtCryqty', 'wstpltCryqty', 'bioMsstpCryqty', 'natrGstpCryqty', 'etcMtralCryqty'],
            eval: 'sum',
            nullAs: 0,
            dataType: 'number',
          },
          format: '#,##0.00',
        },
        {
          field: 'hdrcbtCryqty',
          caption: '탄화수소(톤)',
          width: 150,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
        {
          field: 'wstpltCryqty',
          caption: '폐플라스틱(톤)',
          width: 150,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
        {
          field: 'bioMsstpCryqty',
          caption: '바이오매스(톤)',
          width: 150,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
        {
          field: 'natrGstpCryqty',
          caption: '천연가스(톤)',
          width: 150,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
        {
          caption: '기타원료',
          columns : [
            {
              field: 'etcMtralCryqty',
              caption: '기타원료 투입량(톤)',
              width: 150,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'etcMtralCrynCn',
              caption: '기타종류',
              width: 150,
              align: 'left',
              dataType: 'string',
              total: {
                headerCss:'grid-header',
              },
            }
          ]
        },
      ]
    },
    // 합성가스판매현황 그리드 컬럼 세팅
    initializeprfmncSynthGasNtslGridColumns() {
      this.gridColumnsSynthGasNtsl = [
        { field: 'prfmncId', caption: '실적아이디', visible: false, editable: false },
        { field: 'bplcId', caption: '사업장아이디', visible: false, editable: false },
        { field: 'eneBzentySn', caption: '에너지업체일련번호', visible: false, editable: false },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        {
          field: 'bzentyNm',
          caption: '판매 업체명',
          width: 320,
          align: 'left',
          part: 'head',
          editable: false,
          total: {
            aggregates: [
            ],
            header: [
              { template: '계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        {
          field: 'totalUsgGasNsqty',
          caption: '총 판매량',
          width: 250,
          unit: '%',
          align: 'right',
          dataType: 'number',
          editable: false,
          colCss: 'grid-disabled',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          calc: {
            require: ['htsrcUsgGasNsqty', 'elctgnUsgGasNsqty'],
            eval: 'sum',
            nullAs: 0,
            dataType: 'number',
          },
          format: '#,##0.00',
        },
        {
          field: 'htsrcUsgGasNsqty',
          caption: '열원 판매량',
          width: 290,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
        {
          field: 'elctgnUsgGasNsqty',
          caption: '전기 판매량',
          width: 290,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
      ]
    },
    gridLoadedSynthGasMtral() {
      this.gridReloadSynthGasMtral = false;
    },
    gridLoadedSynthGasNtsl() {
      this.gridReloadSynthGasNtsl = false;
    },
    formatNumber(number) {
      return utils.formatNumberWithComma(number);
    },
  } // END methods
} // END export
</script>

<style scoped>

</style>