<template>
  <div class="file_upload_list">
    <dl class="list" v-for="(file, index) in files" :key="index">
      <dt>
        <i v-if="file.esntlYn === 'Y'" class="necessary">필수입력</i>
        <label :for="file.atchFileSeCdNm">{{ file.atchFileSeCdNm }}</label>
      </dt>
      <dd>
        <!-- <div class="file_box"> -->
          <template v-if="!!(file.size === 0? true : file.size) && file.size >= 0">
            <!-- s: 2024-12-03 CHOSUNGHO 기존 코드 주석 (미리보기 간격 및 삭제, 미리보기, 다운로드 버튼 아이콘 수정) -->
            <!-- <div class="name_area">
              <span class="file_name">{{ file.name }} ({{ file.friendlySize }})</span>
              <button v-if="!readonly" type="button" class="delete" @click="removeById(file.id)">삭제</button>
            </div>
            <button v-show="hasAtchFileGroupId" type="button" class="btn xsm mr_5" @click="viewer(file)">미리보기</button>
            <button v-show="hasAtchFileGroupId" type="button" class="btn xsm tertiary ico_down" @click="download(file)">다운로드</button> -->
            <!-- e: 2024-12-03 CHOSUNGHO 기존 코드 주석 (미리보기 간격 및 삭제, 미리보기, 다운로드 버튼 아이콘 수정) --> 

            <!-- s: 미리보기, 삭제, 다운로드 버튼 아이콘 수정 --> 
            <span class="file_name">{{ file.name }} ({{ file.friendlySize }})</span>
            <div class="file_r_area">
              <button v-if="!readonly" type="button" class="btn xsm point ico_minus" @click="removeById(file.id)" style="width:36px;">삭제</button>
              <button v-show="hasAtchFileGroupId" type="button" class="btn xsm ico_file" @click="viewer(file)" style="width:36px; margin-right:5px;">미리보기</button>
              <button v-show="hasAtchFileGroupId" type="button" class="btn xsm tertiary ico_down" @click="download(file)" style="width:36px;">다운로드</button>
            </div>
            <!-- e: 미리보기, 삭제, 다운로드 버튼 아이콘 수정 --> 
          </template>
          <template v-else>
            <span class="file_name">파일을 선택하세요.</span>
            <button v-if="!readonly" type="button" class="btn xsm secondary" :disabled="readonly" @click="triggerFileUpload(index)">찾아보기</button>
              <input type="file"
                    :id="file.atchFileSeCdNm"
                    :accept="fileAccept"
                    :ref="'upload_' + index"
                    @change="(event) => handleFiles(file.id, event)"
                    style="display: none;"
              />
          </template>
        <!-- </div> -->
      </dd>
    </dl>
    <div v-if="!readonly && useEtcFile" class="list file_btn_box">
      <button type="button" class="btn xsm tertiary" @click="addRow">추가</button>
      <button type="button" class="btn xsm tertiary" @click="deleteRow" :disabled="!isAvailableDeleteRow">삭제</button>
    </div>
    <div v-if="!readonly && useFirFile" class="btn_area">
      <button type="button" class="btn xsm tertiary" @click="addRowForFir">추가</button>
      <button type="button" class="btn xsm tertiary" @click="deleteRow" :disabled="!isAvailableDeleteRowForFir">삭제</button>
    </div>
  </div>
  <synap-viewer ref="synapViewer"/>
</template>

<script>
import { storeComponent } from "@/js/store/store-component";
import { storeValidator } from "@/js/store/store-validator";
import common from "@/js/filemanager/common";
import { FMFileItem, FMFileList } from "@/js/filemanager/engine";
import {buildUri} from "@/js/utils/uri-builder";
import SynapViewer from "@/components/SynapViewer.vue";

const MAX_FILE_SIZE = 25 * 1024 * 1024;

const API = {
  LIST_UPLOAD: "/file/list/upload",
  DOWNLOAD: "/file/download/{atchFileVldNo}",
  LIST_LOAD: "/file/list",
}

export default {
  components: {SynapViewer},
  props: {
    label: { type: String, required: true },
    maxFileCount: { type: Number, default: 1 },
    allowedExtensions: { type: Array, default: () => ['txt', 'csv', 'log', 'jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'mp4', 'avi', 'mov', 'wmv', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'hwp', 'hwpx'] },
    allowedNoExtension: { type: Boolean, default: false },
    minFileSize: { type: Number, default: -1 },
    maxFileSize: { type: Number, default: MAX_FILE_SIZE },
    readonly: { type: Boolean, default: false },
    atchFileTaskSeCd: { type: String, required: true },
    atchFileEditMode: { type: String, default: 'N' },
    atchFileGroupId: { type: String, default: null },
    panel: {type: String, default: ''},
    useEtcFile: { type: Boolean, default: false },
    useFirFile: { type: Boolean, default: false },
    unusedAtchFileList: { type: Array, default: () => [] },
  },
  emits: [ 'update:atchFileGroupId', 'loaded', 'changed' ],
  expose: ['save', 'load'],
  data() {
    return {
      id: this.$route.path + '_' + this.label,
      cumulative: 0,
      files: new FMFileList(),
      hasError: false,
      errorMessage: '',
      deletedFileSqnos: new Set(),
      dupChk: [],
    };
  },
  computed: {
    fileAccept() {
      if (this.allowedExtensions.length === 0) {
        return '';
      }
      return this.allowedExtensions.map(ext => `.${ext}`).join(',');
    },
    isAvailableDeleteRow() {
      const lastFileIndex = this.files.length - 1;
      return this.files[lastFileIndex] && this.files[lastFileIndex].atchFileSeCd === '999' && !this.files[lastFileIndex].name;
    },
    isAvailableDeleteRowForFir() {
      const lastFileIndex = this.files.length - 1;
      return this.files[lastFileIndex] && (this.files[lastFileIndex].atchFileSeCd === '999' || this.files[lastFileIndex].atchFileSeCd === '050') && !this.files[lastFileIndex].name;
    },
    hasAtchFileGroupId() {
      return !!this.atchFileGroupId;
    },
  },
  watch: {
    atchFileGroupId() {
      this.load();
    },
  },
  beforeMount() {
    storeComponent.restore({ urlAddr: this.id }, this);
    this.convertToFMFileList('files');
  },
  mounted() {
    this.$emit('loaded');
    this.load();
  },
  beforeUnmount() {
    if (this.required) {
      storeValidator.remove(this.$route.path, this.panel, this.label);
    }
    storeComponent.store({ urlAddr: this.id }, this);
  },
  methods: {
    triggerFileUpload(index) {
      this.$refs['upload_' + index][0].click();
    },
    handleFiles(id, event) {
      let selectedFile = Array.from(event.target.files)[0];

      // 파일 입력 요소 초기화
      event.target.value = '';

      if (!this.isAllowedFileExtension(selectedFile)) {
        alert('해당 파일은 허가되지 않은 파일 형식입니다.');
        return false;
      }

      if (this.isSmallerThanAllowedSize(selectedFile)) {
        alert(`해당 파일은 허용된 최소 용량(${common.getFriendlySize(this.minFileSize)})에 미달되어 첨부할 수 없습니다.`);
        return false;
      }

      if (this.isBiggerThanAllowedSize(selectedFile)) {
        alert(`해당 파일은 허용된 최대 용량(${common.getFriendlySize(this.maxFileSize)})를 초과하여 첨부할 수 없습니다.`);
        return false;
      }

      if (!this.isAllowedCount()) {
        alert(`업로드할 수 있는 파일의 최대 개수(${this.maxFileCount})를 넘었습니다`);
        return false;
      }

      this.setFile(id, selectedFile);
    },
    isAllowedFileExtension(file) {
      const ext = common.getFileExtension(file.name).toLowerCase();
      if (ext === "" && this.allowedNoExtension === false) {
        return false;
      } else if (ext === ".") {
        return false;
      }

      return (ext && this.allowedExtensions.length > 0 && this.allowedExtensions.indexOf(ext) >= 0);
    },
    isSmallerThanAllowedSize(file) {
      return (this.minFileSize >= 0 && this.minFileSize > file.size);
    },
    isBiggerThanAllowedSize(file) {
      return (this.maxFileSize >= 0 && this.maxFileSize < file.size);
    },
    isAllowedCount() {
      // return (this.maxFileCount >= 0 && this.maxFileCount >= this.files.getLength(false));

       // 파일 목록을 `atchFileSeCd`별로 그룹화
      const fileCountsByType = this.files.reduce((acc, file) => {
          const type = file.atchFileSeCd;
          if (!acc[type]) {
              acc[type] = 0;
          }
          acc[type]++;
          return acc;
      }, {});

      // 각 항목별 파일 개수와 `maxFileCount` 비교
      const isAllWithinLimit = Object.values(fileCountsByType).every(count => count <= this.maxFileCount);

      return isAllWithinLimit;
    },
    addFile(file) {
      let item = null, blob = null;

      const adjustInteger = function (value, permitMinus, defValue) {
        let num = parseInt(value, 10);
        if (permitMinus) return num; else if (num < 0) return defValue; else return num
      };

      if (common.isFile(file)) {
        item = this.createFI(this.createId(++this.cumulative), file, "", "", "", "FILE", file.name, file.size);
        item.ext = common.getFileExtension(file.name);
        item.mime = file.type;
        item.atchFileSeCd = file.atchFileSeCd;
        item.atchFileSeCdNm = file.atchFileSeCdNm;
        item.esntlYn = file.esntlYn;
        item.eventType = '';
      } else if (file) {
        blob = new Blob([], {type: "application/octet-stream"});
        item = this.createFI(this.createId(++this.cumulative), blob, file.atchFileGroupId, file.atchFileSqno, file.atchFileVldNo, "VIRTUAL", file.orgnlFileNm, typeof file.atchFileSz === "undefined" ? -1 : adjustInteger(file.atchFileSz, false, -1));
        item.ext = file.atchFileExtnVl;
        item.mime = "application/octet-stream";
        item.atchFileSeCd = file.atchFileSeCd;
        item.atchFileSeCdNm = file.atchFileSeCdNm;
        item.esntlYn = file.esntlYn;
        item.eventType = '';
      } else {
        return null;
      }
      this.files.push(item);
      this.$emit('changed');
      return item;
    },
    setFile(id, fileObject) {
      const adjustInteger = function (value, permitMinus, defValue) {
        let num = parseInt(value, 10);
        if (permitMinus) return num; else if (num < 0) return defValue; else return num
      };

      const toSetFile = this.files.find(file => file.id === id);
      if (toSetFile) {
        toSetFile.ofile = fileObject
        toSetFile.mdate = new Date;
        toSetFile.type = "FILE";
        toSetFile.name = fileObject.name;
        toSetFile.size = typeof fileObject.size === "undefined" ? -1 : adjustInteger(fileObject.size, false, -1);
        toSetFile.friendlySize = common.getFriendlySize(toSetFile.size);
        toSetFile.status = "WAIT";
        toSetFile.eventType = (toSetFile.eventType === '' && !toSetFile.atchFileSqno)? 'C' : 'U';
        toSetFile.ext = common.getFileExtension(fileObject.name);
        toSetFile.mime = fileObject.type;
        this.$emit('changed');
      }
    },
    createId(seed) {
      return "FM-" + (new Date).getTime() + "-" + seed.toString();
    },
    createFI(id, file, atchFileGroupId, atchFileSqno, atchFileVldNo, type, name, size) {
      const f = new FMFileItem();
      f.id = id;
      f.ofile = file;
      if (file.lastModified) {
        f.mdate = new Date(file.lastModified);
      } else {
        f.mdate = file.lastModified || new Date;
      }
      f.atchFileGroupId = atchFileGroupId;
      f.atchFileSqno = atchFileSqno;
      f.atchFileVldNo = atchFileVldNo;
      f.type = type;
      f.name = name;
      f.size = size;
      f.friendlySize = common.getFriendlySize(size);
      f.status = type === "VIRTUAL" ? "DONE" : "WAIT";
      return f;
    },
    validate() {
      if (!this.isAllowedCount()) {
        alert(`업로드할 수 있는 파일의 최대 개수(${this.maxFileCount})를 넘었습니다`);
        return false;
      }

      for (const file of this.files) {
        if (file.esntlYn === 'Y' && !file.name) {
          alert(`[${file.atchFileSeCdNm}]는 필수 첨부파일 입니다.\n파일등록 후 다시 저장해주세요.`);
          return false;
        }
      }

      return true;
    },
    removeById(id) {
      const fileIndex = this.files.findIndex(file => file.id === id);
      if (fileIndex === -1) return alert("해당 파일을 찾을 수 없습니다.");
      if (confirm("이 파일을 삭제하시겠습니까?")) {
        const file = this.files[fileIndex];
        if(file.atchFileSeCd === '999'){
          this.deletedFileSqnos = new Set([...this.deletedFileSqnos, file.atchFileSqno]);
        }
        if (file.atchFileSqno !== null && file.atchFileSqno !== undefined){
          file.status = 'WAIT';
          file.name = '';
          file.ext = '';
          file.size = -1;
          file.friendlySize = '-1 bytes';
          file.ofile = {};
          file.ofile = 'application/octet-stream';
          file.eventType = 'D';
        } else if (file.atchFileSeCd !== '999') {
          file.type = 'VIRTUAL';
          file.status = 'DONE';
          file.name = '';
          file.ext = '';
          file.size = -1;
          file.friendlySize = '-1 bytes';
          file.ofile = {};
          file.ofile = 'application/octet-stream';
          file.eventType = '';
        } else {
          this.files.splice(fileIndex, 1);
        }
      }
    },
    download(file) {
      let fileInfo = {
        atchFileVldNo: file.atchFileVldNo,
      }
      this.$apiCall.download(buildUri(API.DOWNLOAD, fileInfo), {}, file.name, this.downloadFailure);
    },
    viewer(file) {
      this.$refs.synapViewer.popupShow();
      this.$refs.synapViewer.show(file.atchFileVldNo);
    },
    downloadFailure() {
      alert("파일 다운로드 중 오류가 발생했습니다.")
    },
    convertToFMFileList(propertyName) {
      if (this[propertyName].toString() === '[object FMFileList]') {
        return;
      }
      if (Array.isArray(this[propertyName])) {
        const fmFileList = new FMFileList();
        this[propertyName].forEach(item => {
          const fileItem = Object.assign(new FMFileItem(), item);
          fmFileList.push(fileItem);
        });
        this[propertyName] = fmFileList;
      }
    },
    addRow() {
      const newFile = {
        atchFileSeCd: '999',
        atchFileSeCdNm: '기타서류',
        groupCd: 'COM442',
      };
      this.addFile(newFile);
    },
    addRowForFir() {
      const newFile = {
        atchFileSeCd: '050',
        atchFileSeCdNm: '검사기록지',
        groupCd: 'COM442',
      };
      this.addFile(newFile);
    },
    deleteRow() {
      if( this.isAvailableDeleteRow ) {
        this.files.splice(this.files.length - 1, 1);
        this.$emit('changed');
      }
    },
    load(taskSeCd, groupId) {
      this.$apiCall.get(API.LIST_LOAD
          , {
            atchFileGroupId: groupId ? groupId : this.atchFileGroupId,
            atchFileTaskSeCd: taskSeCd ? taskSeCd : this.atchFileTaskSeCd,
            atchFileEditMode: this.atchFileEditMode,
            useEtcFile: this.useEtcFile? 'Y' : 'N',
          }
          , (data) => {
            this.files = new FMFileList();

            const fileList = data.result;
            if (fileList.length > 0) {
              fileList.forEach(file => {
                if(!this.unusedAtchFileList.includes(file.atchFileSeCd)) {
                  this.addFile(file);
                }
              });
            }
          }, () => {
            this.files = new FMFileList();
            alert("파일 목록을 가져오는 중 오류가 발생했습니다.");
          });
    },
    save(canSaveNothing = false, reAtchFileVldNo = false) {
      return new Promise((resolve, reject) => {
        if( this.validate() ) {
          if( this.files.length > 0 ) {
            let toSaveFilesCnt = 0;
            const formData = new FormData();
            formData.append("atchFileTaskSeCd", this.atchFileTaskSeCd ? this.atchFileTaskSeCd : "");
            formData.append("atchFileGroupId", this.atchFileGroupId ? this.atchFileGroupId : "");

            this.files
                .filter(file => file.status === 'WAIT' && file.type === 'FILE' && file.eventType !== 'D')
                .forEach(file => {
                  toSaveFilesCnt++;
                  formData.append("files", file.ofile);
                  formData.append("ids", file.id);
                  formData.append("atchFileSeCds", file.atchFileSeCd);
                  formData.append("atchFileSqnos", file.atchFileSqno >= 0 ? file.atchFileSqno : '');
                  this.dupChk.push(file.atchFileSqno);
                });

            // 기타서류 삭제 후 행 삭제 시, file 객체가 존재하지 않아 삭제가 되지않아서 추가 25.03.17 lhs
            Array.from(this.deletedFileSqnos)
                .filter(atchFileSqno => !this.dupChk.includes(atchFileSqno))
                .forEach((atchFileSqno) => {
                  toSaveFilesCnt++;
                  formData.append("deleteAtchFileSqnos", atchFileSqno);
                });

            this.files
                .filter(file => file.status === 'WAIT' && file.type === 'VIRTUAL' && file.eventType === 'D' )
                .forEach(file => {
                  toSaveFilesCnt++;
                  formData.append("deleteAtchFileSqnos", file.atchFileSqno);
                });

            if(toSaveFilesCnt <= 0) {
              resolve(this.atchFileGroupId);
            } else {
              this.$apiCall.upload(API.LIST_UPLOAD
                  , formData
                  , response => {
                    const { atchFileGroupId, fileDetails } = response.data.result;
                    fileDetails.forEach(detail => {
                      const file = this.files.find(file => file.id === detail.id);
                      if (file) {
                        file.atchFileGroupId = atchFileGroupId;
                        file.atchFileSqno = detail.atchFileSqno;
                        file.atchFileVldNo = detail.atchFileVldNo;
                        file.status = "DONE";
                      }
                    });

                    this.$emit('update:atchFileGroupId', atchFileGroupId);
                    if(reAtchFileVldNo){
                      resolve({ atchFileGroupId, atchFileVldNo: fileDetails.length > 0 ? fileDetails[0].atchFileVldNo : null });
                    } else {
                      resolve(atchFileGroupId);
                    }
                  }
                  , error => {
                    alert(error.message);
                    reject(error);
                  }
              );
            }
          } else {
            // 저장목록이 아무것도 없어도 save() 함수 호출이 되어야하는 경우 -> 무조건 정상처리된 것으로 간주
            if(canSaveNothing) {
              resolve('');
            } else {
              /*const errMsg = '저장할 파일 목록이 없습니다.';
              alert(errMsg);*/
              resolve('');
            }
          }
        }
      });
    },
  }
}
</script>

<style scoped>
  .file_r_area .ico_down, .ico_file, .ico_minus{ font-size: 0; padding: 0 .8rem;}
  .file_r_area .ico_file:after{ content:''; display: inline-flex; width: 1.8rem; height:2rem; background:url(@/assets/images/common/ico_file.png) no-repeat center; background-size: 2rem;}
  .file_r_area .ico_minus:before{ background-size: 1.6rem; width: 1.6rem; height: 1.8rem;}
  .file_r_area {display: flex; align-items: center; gap: 5px;}
  /* .file_box .file_name{width: 336px;} */
</style>