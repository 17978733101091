<template>
  <div class="sch_form_wrap">
    <div class="sch_form row2">
      <span>
        <label>구분</label>
        <br-form-select label="구분" v-model:value="selected" :options="options" @change="(data) => this.type=data.value"/>
        <br-form-input label="검색어" v-model="keyword" @enterKeyup="searchBtn"/>
      </span>
      <span>
      </span>
    </div>
    <div class="btn_area">
      <br-anchor-button label="검색" role="button" size="sm" format="sch" @click="searchBtn" />
      <br-anchor-button label="새로고침" role="button" size="sm" format="txtN" icon="ico_refresh" @click="reset" />
    </div>
  </div>
  <div class="board_info">
    <p class="page">
      <span class="total">전체 <strong>{{ this.count }}</strong></span>
      <span>페이지 <strong>{{ this.searchParam.page }}/{{ this.maxPage }}</strong></span>
    </p>
  </div>
  <div class="board_list">
    <ul class="photo">
      <template v-for="(item, index) in listObj" :key="index">
        <li>
          <a href="" onclick="return false;" @click="toDetail(item)">
            <span class="thumb" v-if="item.atchFiles"><img :src="getPopupImg(item.atchFiles)" alt="팝업 이미지" ></span>
            <span class="thumb" v-else><img src="@/assets/images/sub/img_error02.png" alt="팝업 이미지" ></span>
            <span class="title" style="white-space: nowrap;"><strong class="strong1">{{item.popupNm}}</strong></span>
            <span class="date">{{ item.regDt }}</span>
          </a>
        </li>
      </template>
    </ul>
  </div>
  <insp-pagination :total="count" :size="searchParam.size" v-model:page="searchParam.page" v-model:maxPage="maxPage" @clickedPage="search"> </insp-pagination>
</template>

<script>

import inspPagination from "@/views/sfli/components/inspPagination.vue";
import {storeSession} from "@/js/store/store-session";
import {buildUri} from "@/js/utils/uri-builder";

const API = {
  POPUP: "/popupmanage/",
  DOWNLOAD: "/api/file/download/{atchFileVldNo}"
};

const PAGE_SIZE = 9;

export default {
  components: {inspPagination},
  data() {
    return {
      options: [
          {text: "제목", value: "1"},
          {text: "내용", value: "2"},
          // {text: "작성자", value: "3"},
          {text: "제목 + 내용", value: "4"}
      ],
      selected: "4",
      type: "4",
      keyword: "",
      searchParam: {
        popupNm: "",
        popupCn: "",
        rgtrNm: "",
        page: 1
      },
      fileProcessingData: {},
      fileCd: "POP",
      activeIndex: null,
      userinfo: storeSession.getMemberInfo(),
      count: 0,
      maxPage: 1,
      files : [{
        atchFileGroupId : {},
        atchFileSqno : {},
        atchFileVldNo : {} // 추가
      }],
      listObj: [],
      defaultImg: '@/assets/images/sub/ico_join_finish.png',
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  beforeMount() {
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.search();
    },
    reset() {
      this.selected="4";
      this.type="4";
      this.keyword="";
      this.search();
    },
    searchBtn(){
      this.searchParam.page = 1;
      this.search();
    },
    async search(){
      this.change();
      this.activeIndex = null;
      this.searchParam.size = PAGE_SIZE;
      this.$apiCall.get(API.POPUP, this.searchParam, (data) =>{
        if(!data.result){
          this.error();
          return;
        }
        this.listObj = data.result.list;
        this.count = data.result.total;
        // 이미지 로드 방식 변경으로 미사용
        // for(const row of this.listObj){
        //   if(row.files){
        //     if(row.files.length > 0) {
        //       try {
        //         row.imgSrc = require("@/" + row.files[0].strgFilePathNm + "/" + row.files[0].strgFileNm);
        //       }catch(e){
        //         row.imgSrc = require(`@/assets/images/sub/img_error02.png`);
        //       }
        //     }
        //     this.$forceUpdate();
        //   }
        // }
      }, this.error);
    },
    error() {
      alert("데이터를 가져오는 중 오류가 발생했습니다.");
    },
    change(){
      this.searchParam.popupNm = ""
      this.searchParam.popupCn = ""
      this.searchParam.rgtrNm = ""
      if(this.type==""){
        this.keyword = ""
      }else if(this.type==1){
        this.searchParam.popupNm = this.keyword
      }else if(this.type==2){
        this.searchParam.popupCn = this.keyword
      }else if(this.type==3){
        this.searchParam.rgtrNm = this.keyword
      }else if(this.type==4){
        this.searchParam.popupNm = this.keyword
        this.searchParam.popupCn = this.keyword
      }
    },
    toDetail(data){
      this.$router.push({path : `/cust-sprt/PopupDetail`, query : {popupSn : data.popupSn}})
        .catch(() => {
          console.error("Navigation Error");
        });
    },
    getPopupImg(atchFiles) {
      if (atchFiles.length === 0 || !atchFiles[0].atchFileVldNo) {
        return "";
      }

      let imageUrl = buildUri(API.DOWNLOAD, { atchFileVldNo : atchFiles[0].atchFileVldNo });

      return process.env.VUE_APP_ENV === 'local'
        ? `${process.env.VUE_APP_API_PROXY_URL}${imageUrl}`
        : `${imageUrl}`;
    },
  }
}
</script>

<style scoped>
.strong1 * {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
