<template>
  <div class="board_write">
    <table>
      <caption>실적년도, 사업장명, 사업장종류, 실적상태로 구성</caption>
      <colgroup>
        <col style="width:18%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:32%">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">실적년도</th>
        <td>{{ prfmncBasicInfo.prfmncYr }}-{{ prfmncBasicInfo.prfmncMm }}</td>
        <th scope="row">사업장명</th>
        <td>{{ prfmncBasicInfo.bplcNm }}</td>
      </tr>
      <tr>
        <th scope="row">사업장종류</th>
        <td>{{ prfmncBasicInfo.bplcKndNm }}</td>
        <th scope="row">실적상태</th>
        <td>{{ prfmncBasicInfo.prfmncSttsNm }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <template v-if="showDetail && parseInt(prfmncYr) <= 2024">
    <by2024-sdfl-use-detail
        :prfmncBasicInfo="prfmncBasicInfo"
        :sdflCrynInfo="sdflCrynInfo"
        :sdflInputInfo="sdflInputInfo"
        :sdflPrcsInfo="sdflPrcsInfo"
        :usePrfmncInfo="usePrfmncInfo"
        :ntslInfo="ntslInfo"
    />
  </template>

  <template v-else-if="!showDetail && parseInt(prfmncYr) <= 2024">
    <by2024-sdfl-use-update
        :prfmncBasicInfo="prfmncBasicInfo"
        :sdflCrynInfo="sdflCrynInfo"
        :sdflInputInfo="sdflInputInfo"
        :sdflPrcsInfo="sdflPrcsInfo"
        :usePrfmncInfo="usePrfmncInfo"
        :ntslInfo="ntslInfo"
    />
  </template>

  <template v-else-if="showDetail && parseInt(prfmncYr) > 2024">
    <sdfl-use-detail
        :prfmncBasicInfo="prfmncBasicInfo"
        :sdflCrynInfo="sdflCrynInfo"
        :sdflInputInfo="sdflInputInfo"
        :sdflPrcsInfo="sdflPrcsInfo"
        :asstFuelInputInfo="asstFuelInputInfo"
        :prdctnInfo="prdctnInfo"
        :ntslInfo="ntslInfo"
    />
  </template>

  <template v-else>
    <sdfl-use-update
        :prfmncBasicInfo="prfmncBasicInfo"
        :sdflCrynInfo="sdflCrynInfo"
        :sdflInputInfo="sdflInputInfo"
        :sdflPrcsInfo="sdflPrcsInfo"
        :asstFuelInputInfo="asstFuelInputInfo"
        :prdctnInfo="prdctnInfo"
        :ntslInfo="ntslInfo"
    />
  </template>

</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";
import By2024SdflUseDetail from "@/views/prfmnc-rpt/dtl/sdfl-use/By2024SdflUseDetail.vue";
import By2024SdflUseUpdate from "@/views/prfmnc-rpt/dtl/sdfl-use/By2024SdflUseUpdate.vue";
import SdflUseDetail from "@/views/prfmnc-rpt/dtl/sdfl-use/SdflUseDetail.vue";
import SdflUseUpdate from "@/views/prfmnc-rpt/dtl/sdfl-use/SdflUseUpdate.vue";

const API = {
  BUSINESS_PLACE_INFO: "/bplc",
  PERFORMANCE_BASIC_INFORMATION: "/prfmnc",
  CHECK_PERMITTED_BUSINESS: "/prfmnc/chck-prm-bplc",
  SOLID_FUEL_CARRY_IN_INFORMATION: "/prfmnc/sdflUse/sdfl-cryn-info",
  SOLID_FUEL_INPUT_INFORMATION: "/prfmnc/sdflUse/sdfl-input-info",
  SOLID_FUEL_PROCESS_INFORMATION: "/prfmnc/sdflUse/sdfl-prcs-info",
  ASSISTANCE_FUEL_INPUT_INFORMATION: "/prfmnc/sdflUse/asst-fuel-input-info",
  USE_PERFORMANCE_INFORMATION: "/prfmnc/sdflUse/use-prfmnc-info",
  PRODUCTION_INFORMATION: "/prfmnc/sdflUse/prdctn-info",
  NET_SALES_INFORMATION: "/prfmnc/sdflUse/ntsl-info",
  PERFORMANCE_REGISTER_POSIBILITY_IDENTIFY: "/prfmnc/reg-psblty-idnty", // 실적 신규등록 가능상태 체크
};

const ROUTE = {
  LIST: "/prfmnc-rpt/PrfmncRpt"
};

export default {
  components: {
    By2024SdflUseDetail,
    By2024SdflUseUpdate,
    SdflUseDetail,
    SdflUseUpdate,
  },
  data() {
    return {
      bplcId: this.$route.query.bplcId || "",
      prfmncId: this.$route.query.prfmncId || "",
      prfmncYr: this.$route.query.prfmncYm?.substring(0, 4),
      prfmncMm: this.$route.query.prfmncYm?.substring(4, 6),
      prfmncYm: this.$route.query.prfmncYm || "",
      detailStatusCodes: ['SUB', 'REC', 'APR'], // 제출, 접수, 승인
      prfmncBasicInfo: {},
      sdflCrynInfo: {},
      sdflInputInfo: {},
      sdflPrcsInfo: {},
      asstFuelInputInfo: {},
      usePrfmncInfo: {},
      prdctnInfo: {},
      ntslInfo: {},
      mode: "U",
    };
  },
  computed: {
    showDetail() {
      return this.prfmncId && this.detailStatusCodes.includes(this.prfmncBasicInfo.prfmncSttsCd);
    }
  },
  watch: {
    showDetail(newVal) {
      if (newVal) {
        this.mode = 'R';
      } else {
        this.mode = 'U';
      }
    }
  },
  created() {
    this.initialize();
  },
  mounted() {
  },
  methods: {
    async initialize() {
      storeSwitch.on("loading");
      if(!this.showDetail && Boolean(!this.prfmncId)) await this.registerChk();
      await this.loadBplcInfo();
      await this.loadPrfmncBasicInfo();

      const result = await this.checkPermittedBplc();
      if (!result) {
        alert("허용되지 않은 사업장입니다.");
        this.$router.push(ROUTE.LIST);
        storeSwitch.off("loading");
        return;
      }

      // bplcKndCd 체크 후 리디렉션
      if (this.prfmncBasicInfo.bplcKndCd !== "01-02") {
        this.$router.push(ROUTE.LIST);
        storeSwitch.off("loading");
        return; // 리디렉션 후 나머지 초기화 작업을 중단
      }

      await this.loadSdflCrynInfo();
      await this.loadSdflInputInfo();
      await this.loadSdflPrcsInfo();
      await this.loadAsstFuelInputInfo();
      await this.loadUsePrfmncInfo();
      await this.loadPrdctnInfo();
      await this.loadNtslInfo();
      storeSwitch.off("loading");
    },
    async registerChk() {
      this.$apiCall.post(
          API.PERFORMANCE_REGISTER_POSIBILITY_IDENTIFY,
          { bplcId : this.bplcId, prfmncYr : this.prfmncYr, prfmncMm : this.prfmncMm },
          () => {},
          (error) => {
            alert(error.message);
            this.$router.push(ROUTE.LIST);
          }
      );
    },
    loadBplcInfo() {
      return new Promise((resolve, reject) => {
        if (!this.bplcId) {
          resolve();
          return;
        }

        this.$apiCall.get(API.BUSINESS_PLACE_INFO,
            { bplcId : this.bplcId },
            data => {
              if (data.result) {
                this.prfmncBasicInfo = {
                  prfmncId: this.prfmncId,
                  bplcId: data.result.bplcId,
                  bplcNm: data.result.bplcNm,
                  bplcKndNm: data.result.bplcKndNm,
                  prfmncYr: this.prfmncYm?.substring(0, 4),
                  prfmncMm: this.prfmncYm?.substring(4, 6),
                  prfmncSttsCd: "NEW",
                  prfmncSttsNm: "미작성",
                  prfmncMdfcnPrmSttsCd: "",
                  bplcKndCd: data.result.bplcKndCd,
                };
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load prfmnc basic info."));
            }
        );
      });
    },
    loadPrfmncBasicInfo() {
      return new Promise((resolve, reject) => {
        if (!this.prfmncId) {
          resolve();
          return;
        }

        this.$apiCall.get(API.PERFORMANCE_BASIC_INFORMATION,
            { prfmncId : this.prfmncId },
            data => {
              if (data.result.prfmncMst) {
                this.prfmncBasicInfo = data.result.prfmncMst;
                this.prfmncYm = data.result.prfmncMst.prfmncYm;
                this.prfmncYr = data.result.prfmncMst.prfmncYm?.substring(0, 4);
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load prfmnc basic info."));
            }
        );
      });
    },
    checkPermittedBplc() {
      return new Promise((resolve) => {
        this.$apiCall.get(API.CHECK_PERMITTED_BUSINESS,
            { bplcId: this.prfmncBasicInfo.bplcId },
            data => {
              resolve(data.result);
            },
            () => {
              resolve(false);
            }
        );
      });
    },
    loadSdflCrynInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.SOLID_FUEL_CARRY_IN_INFORMATION,
            { prfmncId: this.prfmncId, bplcId: this.prfmncBasicInfo.bplcId, prfmncYm: this.prfmncYm, mode: this.mode },
            data => {
              if (data.result) {
                this.sdflCrynInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load prfmnc cryn info."));
            }
        );
      });
    },
    loadSdflInputInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.SOLID_FUEL_INPUT_INFORMATION,
            { prfmncId: this.prfmncId, bplcId: this.prfmncBasicInfo.bplcId, prfmncYm: this.prfmncYm, mode: this.mode },
            data => {
              if (data.result) {
                this.sdflInputInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load prfmnc input info."));
            }
        );
      });
    },
    loadSdflPrcsInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.SOLID_FUEL_PROCESS_INFORMATION,
            { prfmncId: this.prfmncId, bplcId: this.prfmncBasicInfo.bplcId, prfmncYm: this.prfmncYm, mode: this.mode },
            data => {
              if (data.result) {
                this.sdflPrcsInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load prfmnc prcs info."));
            }
        );
      });
    },
    loadAsstFuelInputInfo() {
      return new Promise((resolve, reject) => {
        if (parseInt(this.prfmncYr) > 2024) {
          this.$apiCall.get(API.ASSISTANCE_FUEL_INPUT_INFORMATION,
              { prfmncId: this.prfmncId, bplcId: this.prfmncBasicInfo.bplcId, mode: this.mode },
              data => {
                if (data.result) {
                  this.asstFuelInputInfo = data.result;
                }
                resolve();
              },
              () => {
                reject(new Error("Failed to load prfmnc prcs info."));
              }
          );
        } else {
          resolve();
        }
      });
    },
    loadUsePrfmncInfo() {
      return new Promise((resolve, reject) => {
        if (parseInt(this.prfmncYr) <= 2024) {
          this.$apiCall.get(API.USE_PERFORMANCE_INFORMATION,
              { prfmncId: this.prfmncId },
              data => {
                if (data.result) {
                  this.usePrfmncInfo = data.result;
                }
                resolve();
              },
              () => {
                reject(new Error("Failed to load use prfmnc info."));
              }
          );
        } else {
          resolve();
        }
      });
    },
    loadPrdctnInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.PRODUCTION_INFORMATION,
            { prfmncId: this.prfmncId, bplcId: this.prfmncBasicInfo.bplcId, mode: this.mode },
            data => {
              if (data.result) {
                this.prdctnInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load prdctn info."));
            }
        );
      });
    },
    loadNtslInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.NET_SALES_INFORMATION,
            { prfmncId: this.prfmncId, bplcId: this.prfmncBasicInfo.bplcId, mode: this.mode },
            data => {
              if (data.result) {
                this.ntslInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load prdctn info."));
            }
        );
      });
    }
  }
}
</script>

<style scoped>

</style>