/**
 * 입력된 단어가 받침을 가지고 있는지 확인하는 함수
 * @param {string} word - 확인할 단어
 * @returns {boolean} - 단어가 받침을 가지고 있으면 true, 아니면 false
 */
function hasBottom(word) {
    if (typeof word === 'string' && word.length > 0) {
        // 입력된 단어의 마지막 문자의 유니코드 값을 구함
        let unicode = word[word.length - 1].charCodeAt(0);
        // 유니코드 값이 한글 범위 내에 있는지 확인
        if (unicode >= 44032 && unicode <= 55203) {
            return (unicode - 44032) % 28 !== 0;
        }
    }
    return false;
}

/**
 * 입력된 단어의 마지막 글자가 'ㄹ' 받침을 가지고 있는지 확인하는 함수
 * @param {string} word - 확인할 단어
 * @returns {boolean} - 단어의 마지막 글자가 'ㄹ' 받침을 가지고 있으면 true, 아니면 false
 */
function hasLBottom(word) {
    if (typeof word === 'string' && word.length > 0) {
        let unicode = word[word.length - 1].charCodeAt(0);
        if (unicode >= 44032 && unicode <= 55203) {
            // 유니코드 값에서 한글 시작값을 빼고 28로 나눈 나머지가 8이면 'ㄹ' 받침
            return (unicode - 44032) % 28 === 8;
        }
    }
    return false;
}

export default {
    eulRl: function (word) {
        return hasBottom(word) ? word + "을" : word + "를";
    },
    eunNn: function (word) {
        return hasBottom(word) ? word + "은" : word + "는";
    },
    eeGa: function (word) {
        return hasBottom(word) ? word + "이" : word + "가";
    },
    waGwa: function (word) {
        return hasBottom(word) ? word + "와" : word + "과";
    },
    euRo: function (word) {
        // 'ㄹ' 받침일 때는 '로'를 사용해야 함
        if (hasBottom(word) && !hasLBottom(word)) {
            return word + "으로";
        }
        return word + "로";
    },
}