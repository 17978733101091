<template>
  <div v-show="isOn" class="modal visible">
    <div class="popup_box"  style="width: 80%; max-height: 90%;">
      <h1 class="popup_title">업체 보완요청 정보</h1>
      <div>
        <button type="button" class="modal_close" @click.prevent="hide">팝업닫기</button>
      </div>
    <div class="popup_cont" style="max-height: calc(90% - 15px);overflow: hidden;overflow-y: auto;">
      <!-- 보완요청 사유 -->
      <div class="board_info">
        <h3 class="title1">보완요청 사유<span class="subtitle-note">*은 필수입력 항목 입니다.</span></h3>
      </div>
      <div class="board_write">
        <div class="splmnt-message">
          {{ this.prcsRsn }}
        </div>
      </div>
      <!-- 업체 정보 -->
      <h3 class="title1">업체 정보</h3>
      <div class="board_write">
        <table>
          <caption>업체정보로 구성</caption>
          <colgroup>
            <col style="width:18%">
            <col style="width:32%">
            <col style="width:18%">
            <col style="width:32%">
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">사업자등록번호</th>
              <td>
                {{this.modifiableData.brno }}
              </td>
              <th scope="row">업체 승인상태</th>
              <td :style="modifiableData.aprvYn === 'N' ? 'color: red' : '' ">
                {{getCdName(this.aprvYnCdList, modifiableData.aprvYn)}}
              </td>
            </tr>
            <tr>
              <th scope="row">업체 명</th>
              <td>
                {{this.modifiableData.bzentyNm }}
              </td>
              <th scope="row">업체 명(영문)</th>
              <td>
                {{this.modifiableData.engBzentyNm }}
              </td>
            </tr>
            <tr>
              <th scope="row"><i class="necessary">필수입력</i>대표자명</th>
              <td>
                <br-form-input label="대표자명" v-model="modifiableData.rprsvNm" panel="panel8" required/>
              </td>
              <th scope="row">대표자명(영문)</th>
              <td>
                <br-form-input label="대표자명(영문)" v-model="modifiableData.engRprsvNm"/>
              </td>
            </tr>
            <tr>
              <th scope="row"><i class="necessary">필수입력</i>전화번호</th>
              <td>
                <mbr-form-telno label="전화번호"
                                v-model:telNo1="modifiableData.telno1"
                                v-model:telNo2="modifiableData.telno2"
                                v-model:telNo3="modifiableData.telno3"
                                :required="true"
                                ref="telnoInput"/>
              </td>
              <th scope="row">FAX</th>
              <td>
                <mbr-form-telno label="FAX"
                                v-model:telNo1="modifiableData.fxno1"
                                v-model:telNo2="modifiableData.fxno2"
                                v-model:telNo3="modifiableData.fxno3"
                                ref="fxnoInput"/>
              </td>
            </tr>
            <tr>
              <th scope="row">주소</th>
              <td colspan="3">
                {{ `${this.modifiableData.untyZip} ${this.modifiableData.bzentyAddr} ${this.modifiableData.bzentyDaddr}` }}
              </td>
            </tr>
            <tr>
              <th scope="row">EPR 업체</th>
              <td>
                <br-form-radio label="EPR업체여부"  v-model:value="modifiableData.eprBzentyYn" :options="eprBzentyYnlist" value-field="value" text-field="text"/>
              </td>
              <th scope="row">업체 상태</th>
              <td>
                <br-form-radio label="업체상태" groupCd="MBR003" v-model:value="modifiableData.bzentySttsCd"/>
              </td>
            </tr>
            <tr>
                <th scope="row">첨부서류</th>
                <td colspan="3">
                  <br-attach-file-list label="업체 첨부파일 목록"
                                       ref="attachFileList"
                                       :atchFileTaskSeCd="atchFileTaskSeCd"
                                       v-model:atchFileGroupId="modifiableData.atchFileGroupId"
                                       :maxFileCount="10"
                                       :useEtcFile="true"
                  />
                </td>
              </tr>
          </tbody>
        </table>
      </div>
      <div class="btn_area center">
        <br-button label="변경 재신청" class="btn md primary"  @click="processChangeRequest" panel="panel8" validate/>
        <br-button label="닫기" class="btn md point"  @click="cancelModify"/>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import {storeSwitch} from "@/js/store/store-switch";
import SbGrid from "@/components/SbGrid.vue";
import MbrFormTelno from "@/views/mbr-mng/components/MbrFormTelno.vue";
import {storeSession} from "@/js/store/store-session";
import BrFormRadio from "@/components/BrFormRadio.vue";
import BrButton from "@/components/BrButton.vue";
import BrAttachFileList from "@/components/BrAttachFileList.vue";
import BrFormInput from "@/components/BrFormInput.vue";

const API = {
  BZENTY_CHG_INFO: "/bizfacility/bzenty-chg-info",
};

const CODE = {
  ARPV_YN_CD: "SIS020", // 승인여부코드(업체)
  BZENTY_STTS_CD: "MBR003", // 업체상태코드
  BPLC_KND_CD: "MBR006", // 사업장종류코드
  PRDCTN_PRDCT_KND_CD: "MBR010", // 생산제품종류코드
  ARPV_PRCS_STTS_CD: "MBR019",  //승인처리상태코드(사용자)
  CHG_APLY_PRCS_STTS_CD: "MBR009",  //변경신청처리상태코드
};

const initBizfaciltyBzenty = {
  bzentyId: 0,
  bzentyNm: '',
  bzentyNmDp: '',
  engBzentyNm: '',
  brno: '',
  rprsvNm: '',
  engRprsvNm: '',
  rprsvBrdt: '',
  telno: '',
  telno1: '',
  telno2: '',
  telno3: '',
  fxno: '',
  fxno1: '',
  fxno2: '',
  fxno3: '',
  untyZip: '',
  bzentyAddr: '',
  bzentyDaddr: '',
  ctpvStdgCd: '',
  sggStdgCd: '',
  pubBzentyYn: '',
  atchFileGroupId: '',
  bzentySttsCd: '',
  aprvYn: '',
  eprBzentyYn: '',
  trlBzentyYn: '',
  useYn: '',
  regDt: '',
  rgtrId: '',
  mdfcnDt: '',
  mdfrId: '',
};

export default {
  components: {
    BrFormInput,
    BrAttachFileList,
    BrButton,
    BrFormRadio, MbrFormTelno, SbGrid
  },
  emits: ['complete'],
  props: {
    bzentyId: {type: Number, required: true},
    chgAplySn: {type: Number, required: true},
    chgAplySeCd: {type: String, required: true},
    chgAplyPrcsSttsCd: {type: String, required: true, default: ''},
    prcsRsn: {type: String, default: ''},
  },
  data() {
    return {
      userInfo: storeSession.getMemberInfo(),
      isOn: false,
      atchFileTaskSeCd: 'BET',
      bizfaciltyBzenty: {...initBizfaciltyBzenty},
      modifiableData: {...initBizfaciltyBzenty},
      bzentySttsCdList: [],
      bplcKndCdList: [],
      prdctnPrdctKndCdList: [],
      aprvYnCdList: [],
      eprBzentyYnlist : [
        { value: "Y", text: "대상" },
        { value: "N", text: "대상아님" },
      ],
    };
  },
  computed: {
    // 업체 정보 수정여부 확인하기
    isChanged(){
      return JSON.stringify(this.bizfaciltyBzenty) !== JSON.stringify(this.modifiableData);
    },
  },
  watch: {
    isOn(newVal){
      if(newVal){
        this.initialize();
      }else{
        this.reset();
      }
    }
  },
  created() {
    storeSwitch.setBulb('bzentySplmntPopup', this);
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeUnmount() {
  },
  methods: {
    show() {
      this.isOn = true;
    },
    hide() {
      this.isOn = false;
    },
    initialize() {
        // 공통코드 초기화
        this.bzentySttsCdList = this.$store.getters.getCodeList({groupCd: CODE.BZENTY_STTS_CD, useYn: 'Y'});   // 업체상태코드
        this.bplcKndCdList = this.$store.getters.getCodeList({groupCd: CODE.BPLC_KND_CD, useYn: 'Y'});         // 사업장종류코드
        this.prdctnPrdctKndCdList = this.$store.getters.getCodeList({groupCd: CODE.PRDCTN_PRDCT_KND_CD, useYn: 'Y'});   // 생산제품종류코드
        this.aprvYnCdList = this.$store.getters.getCodeList({groupCd: CODE.ARPV_YN_CD, useYn: 'Y'});           // 승인여부코드

        // 업체정보 조회
        this.readBzentyInfo();
    },
    readBzentyInfo() {
      this.$apiCall.get(
          API.BZENTY_CHG_INFO,
    {bzentyId: this.bzentyId,
          chgAplySn: this.chgAplySn},
data => {
            if (data.result) {
              for (const key of Object.keys(this.bizfaciltyBzenty)) {
                this.bizfaciltyBzenty[key] = data.result?.[key] ?? '';
              }
              this.modifiableData = {...this.bizfaciltyBzenty};
            }
       });
    },
    getCdName(list, code) {
      const status = list.find(item => item.dtlCd === code);
      return status ? status.dtlCdNm : '';
    },
    validateTelno() {
      const telnoInput = this.$refs.telnoInput;
      if (telnoInput && !telnoInput.isValid()) {
        alert("전화번호를 올바르게 입력해 주세요.")
        return false;
      }
      const fxnoInput = this.$refs.fxnoInput;
      if (this.modifiableData.fxno && !fxnoInput.isValid()) {
        alert("팩스번호를 올바르게 입력해 주세요.")
        return false;
      }
      return true;
    },
    reset(){
       this.modifiableData = {...initBizfaciltyBzenty};
    },
    processChangeRequest(){
      if(!this.validateTelno()){
        return;
      }
      if(confirm("변경 재신청하시겠습니까?")) {
        this.$refs.attachFileList.save().then(atchFileGroupId => {

          this.modifiableData.atchFileGroupId = atchFileGroupId;

          this.modifiableData.telno = `${this.modifiableData.telno1}${this.modifiableData.telno2}${this.modifiableData.telno3}`
          this.modifiableData.fxno = `${this.modifiableData?.fxno1 || ''}${this.modifiableData?.fxno2 || ''}${this.modifiableData?.fxno3 || ''}`

          //변경신청일련번호, 변경신청구분코드, 변경신청처리상태코드, 상세변경신청처리상태코드, 변경신청유형코드
          this.modifiableData.chgAplySn = this.chgAplySn;
          this.modifiableData.chgAplySeCd = this.chgAplySeCd;
          this.modifiableData.chgAplyPrcsSttsCd = '04'; //보완 후 재신청
          this.modifiableData.dtlChgAplyPrcsSttsCd = '04';  //보완 후 재신청
          this.modifiableData.chgAplyTypeCd = '01'; //업체

          this.$apiCall.post(API.BZENTY_CHG_INFO, this.modifiableData, () => {
                alert("재신청되었습니다.");
                this.$emit('complete');
                this.isOn = false;
              },
              () => {
                alert('재신청에 실패하였습니다.');
              }
          );
        });
      }
    },
    cancelModify(){
      this.isOn  = false;
    },
  }
}
</script>

<style scoped>
.subtitle-note {
  font-size: 15px;      /* 폰트 크기 설정 */
  font-weight: normal;  /* 굵기 설정 (기본 또는 normal) */
  color: #cc0000;       /* 원하는 색상 코드로 변경 */
  margin-left: 10px;
}
.splmnt-message {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 20px;
  margin: 5px 0px;
  border-radius: 10px;
  background-color: #f7f7f7;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #ddd;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}
</style>