<template>
  <div :id="id" :style="{ position: 'absolute', width: computedWidth, height: computedHeight, top: computedTop }"></div>
</template>

<script>
/**
 * 이폼/리포트 공통 기능 
 *
 * 작성자 : CHOSUNGHO
 * 작성일 : 24. 11. 5. 
 * 버전 : 1.0
 */

import { storeSwitch } from "@/js/store/store-switch";
import { storeSession } from "@/js/store/store-session";

const API = {
  ELDOC_CERT_HSTRY : "/eldocCertHstry"
}

export default {
  props: {
    id: { type: String, required: true, default: "html5-viewer" }, // 크로닉스 뷰어 ID 
    path: { type: String, required : true },            // mrd 파일명 (.mrd 확장자 제외한 파일명)
    param: { type: String, required : true },           // 크로닉스 뷰어 요청 주소 및 Parameter (예시 : /rf [http://주소/DataServer/rdagent.jsp] /rsn [Dev] /rv inspAplySn[19122])
    rdSaveApiType: { type: String, required: true },    // 이폼 저장 방식 (post, put) 
    rdSaveApi: { type: String, required: true },        // 이폼 저장 Rest API 주소 
    rdSaveDataAdd: { type: String, required: true },    // 이폼 입력 항목 외 추가 저장 Key : Value 
    rdWidth: { type: String, default: "100%" },         // 리포트 및 이폼 크로닉스 뷰어 css width style 값 
    rdHeight: { type: String, default: "90%" },         // 리포트 및 이폼 크로닉스 뷰어 css height style 값 
    rdTop: { type: String, default: "auto" },           // 리포트 및 이폼 크로닉스 뷰어 css top style 값 
    successFunction: { type: Function, default: null }, // 이폼 저장 성공 시 실행되는 Function명 지정 
    failFunction: { type: Function, default: null },    // 이폼 저장 실패 시 실행되는 Function명 지정 
    formMode: { type: Boolean, default: true },         // 이폼 여부 필요 시 사용 (eFormMode 변수로 사용 중) 
    btnOnslfCert: { type: Function, default: null },    // 서명자 본인서명 버튼 클릭 시 실행되는 Function 
  },
  emits: [ "submit", "cancel" ],
  created() {
  },
  computed: {
    computedWidth() {
      return this.rdWidth || "100%"; // rdWidth 값이 없으면 "100%"로 설정
    },
    computedHeight() {
      return this.rdHeight || "90%"; // rdHeight 값이 없으면 "90%"로 설정
    },
    computedTop() {
      return this.rdTop || "auto"; // rdTop 값이 없으면 "auto"로 설정
    },
  },
  data() {
    return {
      viewer : {},
      dialog : {},
      commonParam : "/rusesystemfont",
      viewerOptions : {
        showPageNum : 2,
        timeout : 300,
        defaultZoom: 1.0,
        defaultZoomCentre: 'LEFTTOP',
        formField : { 
                      signatureMethod : 'center', 
                      image: {
                              resetDialog: true 
                             }
                    }, 
        noMessageOfSubmitFail : false,
        noMessageOfSubmitSuccess : true
      },
      rdSaveDataMap : {}, 
      eFormMode : true, 
      userinfo: storeSession.getMemberInfo(),
      tmpltCd: "",
      tsaYn: "", 
      bcdYn: "", 
      hstrySection: "", 
    };
  },
  mounted() { 
    // 크로닉스 뷰어 설정 
    this.initCrownixViewer(); 

    // 크로닉스 뷰어 버튼 설정 
    this.initCrownixBtn(); 

    // 크로닉스 뷰어 문서 다운로드 파일명 설정 
    this.initCrownixDownloadFileName(); 

    // 크로닉스 버튼 클릭 이벤트 
    this.eventCrownixBtnClick(); 
  },
  methods : {
    initCrownixViewer() {
      this.eFormMode = this.path.startsWith('eForm'); 

      let smartFormServer = '';

      // 로컬호스트 
      if (process.env.VUE_APP_ENV === 'local') { 
        smartFormServer = 'http://srf-info.or.kr:19020/CreateServer/service';

      // 운영, 스테이징 
      } else { 
        smartFormServer = '/CreateServer/service'; 
      } 

      this.viewer = new window.m2soft.crownix.Viewer(
        smartFormServer, this.id
        // `${process.env.VUE_APP_REPORTING_SERVER}/CreateServer/service`, this.id
        // '/CreateServer/service', this.id
      );

      // console.log('smartForm log : ', smartFormServer); 

      this.dialog = new window.m2soft.crownix.FormDialog(`#${this.id}`);
      new window.m2soft.crownix.Layout.setTheme({toolbarWidth: "60px"}); 
    }, 
    initCrownixBtn() {
      // 저장 버튼 
      const submitIcon = { 
        index : 20,
        id : "submitIcon",
        text : "저장",
        textStyle : {
          "color" : "black",
          "font-size" : "20px",
          "font-weight" : "bold"
        },
        width : 70, 
        title : "저장 버튼",
        handler : this.saveHandler
      };

      // 수정 버튼 
      const modifyIcon = {
        index : 20,
        id : "modifyIcon",
        text : "수정",
        textStyle : {
          "color" : "black",
          "font-size" : "20px",
          "font-weight" : "bold"
        },
        width : 70, 
        title : "수정 버튼",
        handler : this.modifyHandler
      };

      // 서명자 본인인증 버튼 
      const onslfCertIcon = {
        index : 20,
        id : "onslfCertIcon",
        text : "서명자 본인인증",
        textStyle : {
          "color" : "black",
          "font-size" : "20px",
          "font-weight" : "bold"
        },
        width : 145, 
        title : "서명자 본인인증 버튼",
        handler : this.onslfCertEvent
      };

      // 이폼 
      if (this.eFormMode) { 
        this.viewer.addToolbarItem(submitIcon);
        this.viewer.addToolbarItem(modifyIcon);

        // 본인인증 버튼 Function 여부에 따라서 버튼을 표시, 숨김 처리 한다. 
        if (typeof this.btnOnslfCert === "function") { 
          this.viewer.addToolbarItem(onslfCertIcon);
          this.viewer.hideToolbarItem(["cancel","inquery","search","modifyIcon"]); 

        } else {
          this.viewer.hideToolbarItem(["cancel","inquery","search","modifyIcon","onslfCertIcon"]); 
        }

        // 이폼 화면에서 formMode 속성 값을 false로 적용 시 이폼 툴바 버튼을 표시하지 않음 
        if (!this.formMode) {
          this.viewer.hideToolbarItem(["cancel","inquery","search","submitIcon","modifyIcon","onslfCertIcon"]); 
        }

      // 리포트 
      } else { 
        this.viewer.hideToolbarItem(["cancel","inquery","search","submitIcon","modifyIcon","onslfCertIcon"]); 
      }
      
      // 뷰어 
      this.docViewerOpen(); 
    }, 
    initCrownixDownloadFileName() {
      const pdfFileName = this.param.match(/\/pdfFileName \[(.*?)\]/)?.[1] || ""; // pdfFileName 

      this.viewer.addSubmitParameters({
        "name": pdfFileName, // PDF 파일명 
        "code": "" // 조건별 분류 코드 조건별 분류 코드 ( 해당 코드 사용 시 엠투소프트 전달 필요 ) 
      });

      this.viewer.setDownloadFileName(pdfFileName); // 다운로드 파일명 
    }, 
    eventCrownixBtnClick() {
      // 제출 버튼 클릭 후 입력 데이터 Callback 
      this.viewer.bind("submit-finished", (result) => {
        if (result.status === true) {

          // 이폼일 경우 
          if (this.eFormMode === true) {
            // 이폼 입력 값 객체 생성 
            this.rdSaveDataMap = {};

            // 이폼 입력 필드, 값 
            const inputFormFields = this.viewer.getForm(this.path).getList("*"); 
            
            inputFormFields.each((field) => {
              let fieldValue = field.getValue();
              
              // 체크박스 체크된 경우 'Y' 값 적용 
              if (field.getSelected() === true) {
                fieldValue = 'Y';
              
              // 체크박스 체크되지 않은 경우 'N' 값 적용 
              } else if (field.getSelected() === false) {
                fieldValue = 'N';
              }

              this.rdSaveDataMap[field.getId()] = fieldValue;
            });

            // 이폼 입력 항목 외 추가 저장 Key : Value 추가 
            Object.assign(this.rdSaveDataMap, this.rdSaveDataAdd); 

            // s: 저장 API 정보, 데이터 확인용 ( 추후 해당 코드 삭제 예정 ) 
            // console.log('REST API 형식 = ' + this.rdSaveApiType);
            // console.log('REST API 주소 = ' + this.rdSaveApi);
            // console.log('저장 데이터 Map:');
            // Object.entries(this.rdSaveDataMap).forEach(([key, value]) => {
            //   console.log(`  ${key} : ${value}`);
            // }); 
            // e: 저장 API 정보, 데이터 확인용 ( 추후 해당 코드 삭제 예정 ) 

            if (this.rdSaveApiType === "post") {
              // 데이터 INSERT 
              this.createRdData(); 
            } else if (this.rdSaveApiType === "put") {
              // 데이터 UPDATE 
              this.updateRdData(); 
            } 
          }
        }
      });
    }, 
    saveHandler() {
      if (this.viewer.validate()) {
        this.dialog.show(
            "모든 내용을 확인하였으며,<br> 저장하시겠습니까?", {
              title : "최종 확인",
              type : "confirm",
              ok : "예",
              cancel : "아니오",
              okHandler : this.okSaveHandler,
              cancelHandler : this.cancelHandler
            }
        );
      }
    }, 
    okSaveHandler(event) {
      // console.log(`okSaveHandler ${event}`);

      let smartFormServer = '';

      // 로컬호스트 
      if (process.env.VUE_APP_ENV === 'local') { 
        smartFormServer = 'http://srf-info.or.kr:19020/InfoTalkServer/service';

      // 운영, 스테이징 
      } else { 
        smartFormServer = '/InfoTalkServer/service'; 
      } 

      // console.log('smartForm log : ', smartFormServer); 

      this.viewer.submit(smartFormServer, "sf", 6006, "");
      // this.viewer.submit(`${process.env.VUE_APP_REPORTING_SERVER}/InfoTalkServer/service`, "sf", 6006, "");
      // this.viewer.submit('/InfoTalkServer/service', "sf", 6006, "");
      this.$emit("submit", event);
    },
    modifyHandler(){
      if (this.viewer.validate()) {
        this.dialog.show(
            "수정 화면으로 다시 열립니다.<br> 수정하시겠습니까?", {
              title : "수정 확인",
              type : "confirm",
              ok : "예",
              cancel : "아니오",
              okHandler : this.okModifyHandler,
              cancelHandler : this.cancelHandler
            }
        );
      }
    },
    okModifyHandler(){
      this.viewer.showToolbarItem(["submitIcon"]); 
      this.viewer.hideToolbarItem(["modifyIcon"]); 

      // 리포트 및 이폼 뷰어 
      this.crownixViewerOpen();
    },
    cancelHandler(event) {
      // console.error(`cancelHandler ${event}`);
      this.$emit("cancel", event);
    }, 
    createRdData() {
      this.$apiCall.post(
          this.rdSaveApi,
          this.rdSaveDataMap,
          () => {
            if (typeof this.successFunction === "function") { 
              this.successFunction();
            }

            storeSwitch.alert('success', '데이터 저장 완료되었습니다.'); 
            this.eFormSaveSuccess(); 
          },
          () => {
            if (typeof this.failFunction === "function") { 
              this.failFunction();
            }

            storeSwitch.alert('warning', '저장 Restfull API 정보를 확인해주세요.제출한 데이터 저장에 실패하였습니다.'); 
          }
      );
    },
    updateRdData() {
      this.$apiCall.put(
          this.rdSaveApi, 
          this.rdSaveDataMap,
          () => {
            if (typeof this.successFunction === "function") { 
              this.successFunction();
            }

            storeSwitch.alert('success', '데이터 저장 완료되었습니다.'); 
            this.eFormSaveSuccess(); 
          }, 
          () => {
            if (typeof this.failFunction === "function") { 
              this.failFunction();
            }
            storeSwitch.alert('warning', '저장 Restfull API 정보를 확인해주세요.제출한 데이터 저장에 실패하였습니다.');
          }
      );
    }, 
    docViewerOpen() {
      switch (this.path) {
        // 시설검사결과서
        case 'reportFcltInspResult':
          this.tmpltCd = "02"; 
          this.tsaYn = "y"; 
          this.bcdYn = "y"; 
          this.hstrySection = "insp"; // 시설/품질 검사 
          break;

        // 품질검사결과서 
        case 'reportQltyInspResult':
          this.tmpltCd = "04"; 
          this.tsaYn = "y"; 
          this.bcdYn = "y"; 
          this.hstrySection = "insp"; // 시설/품질 검사 
          break;

        // 품질명세서
        case 'reportQltyStatement':
          this.tmpltCd = "05"; 
          this.tsaYn = "y"; 
          this.bcdYn = "y"; 
          this.hstrySection = "insp"; // 시설/품질 검사 
          break;

        // 수입품질검사결과서 
        case 'reportIncmQltyInspResult':
          this.tmpltCd = "07"; 
          this.tsaYn = "y"; 
          this.bcdYn = "y"; 
          this.hstrySection = "insp"; // 시설/품질 검사 
          break;

        // 교육확인증
        case 'reportOnlineEducationCfmcd':
          this.tmpltCd = "20"; 
          this.tsaYn = "y"; 
          this.bcdYn = "y"; 
          this.hstrySection = "edu"; // 온라인교육 
          break;

        // 고형연료제품 품질등급서
        case 'reportQltyGrd':
          this.tmpltCd = "33"; 
          this.tsaYn = "y"; 
          this.bcdYn = "y"; 
          this.hstrySection = "grd"; // 시설/품질 검사 
          break;

        // 고형연료제품 품질등급 의견서 
        case 'reportTmprQltyGrd':
          this.tmpltCd = "34"; 
          this.tsaYn = "y"; 
          this.bcdYn = "y"; 
          this.hstrySection = "grd"; // 품질등급
          break;

        // 고형연료제품 시료채취 계획보고서
        case 'reportSpcmPickPlan': 
          this.tmpltCd = "12"; 
          this.tsaYn = "n"; 
          this.bcdYn = "y"; 
          this.hstrySection = "insp"; // 시설/품질 검사 
          break;

        // 품질표시 적정성 검사 결과(현장점검표)(제조) 
        case 'reportQltyIndctPrprtChckListMnftr': 
          this.tmpltCd = "16"; 
          this.tsaYn = "n"; 
          this.bcdYn = "y"; 
          this.hstrySection = "insp"; // 시설/품질 검사 
          break;

        // 품질표시 적정성 검사 결과(현장점검표)(수입)
        case 'reportQltyIndctPrprtChckListIncm': 
          this.tmpltCd = "17"; 
          this.tsaYn = "n"; 
          this.bcdYn = "y"; 
          this.hstrySection = "insp"; // 시설/품질 검사 
          break;

        // 품질확인검사결과상세
        case 'reportQltyInspRslt': 
          this.tmpltCd = "35"; 
          this.tsaYn = "y"; 
          this.bcdYn = "y"; 
          this.hstrySection = "insp"; // 시설/품질 검사 
          break;

        default:
          this.tmpltCd = ""; 
          this.tsaYn = ""; 
          this.bcdYn = ""; 
          break;
      } 

      this.viewerHandler(); // 뷰어 
    },
    closeAllVisibleModals() { 
      // 열려있는 전체 Modal 팝업을 닫는다. 
      document.querySelectorAll('.modal.visible').forEach(element => {
        element.classList.remove('visible'); 
      });
    }, 
    viewerHandler(){
      // if (this.bcdYn !== "" || this.tsaYn !== "") {
      //   // 2D바코드 및 TSA 뷰어 
      //   this.voiceyeViewerOpen(this.bcdYn, this.tsaYn); 
      // } else {
      //   // 리포트 및 이폼 뷰어 
      //   this.crownixViewerOpen();   
      // } 

      // 리포트 및 이폼 뷰어 
      this.crownixViewerOpen(); 

      // 2D바코드 및 TSA 뷰어 (2D바코드 적용 여부, TSA 적용 여부) 
      if ((this.bcdYn !== "" || this.tsaYn !== "") && process.env.VUE_APP_ENV !== 'local') {
        setTimeout(()=>{
          this.voiceyeViewerOpen(); 
        }, 2000);
      }
    }, 
    voiceyeViewerOpen() { 
      // 도메인 가져오기 ( 뷰어 np_reader_view.jsp 파일에서 TSA 생성 시점 이력 저장 API를 요청할 용도 ) 
      // const domainVal = window.location.hostname; 
      // const portVal = window.location.port ? `:${window.location.port}` : "";
      // let pApiUrl = `http://${domainVal}${portVal}/api${API.ELDOC_CERT_HSTRY}`; 
      let pApiUrl = `http://www.srf-info.or.kr/api${API.ELDOC_CERT_HSTRY}`; 

      // Key, Value 가져오기 ( 뷰어 np_reader_view.jsp 파일에서 TSA 생성 시점 이력 저장 시 사용되는 Key, Value ) 
      const rvMatch = this.param.match(/\/rv\s+(\w+)\s+\[([^\]]+)\]/); // 리포트 조회 Key, Value 

      let pHstryKey = null; 
      let pHstryVal = null; 

      if (rvMatch) {
          pHstryKey = rvMatch[1]; // Key 
          pHstryVal = rvMatch[2]; // Value 
      } 

      // pdf 파일명 
      const pPdfFileName = this.param.match(/\/pdfFileName \[(.*?)\]/)?.[1] || ""; 

      // mrd 정보 
      const formId = `${this.path}.mrd`; 
      const formParam = this.param;
      const sData = "\u0004";

      let smartFormServer = '';

      // 로컬호스트 
      if (process.env.VUE_APP_ENV === 'local') { 
        smartFormServer = 'http://srf-info.or.kr:19020/CreateServer/sga_np/invoker.jsp';

      // 운영, 스테이징 
      } else { 
        smartFormServer = '/CreateServer/sga_np/invoker.jsp'; 
      } 

      // SmartForm NAS 저장되는 파일명 
      // const now = new Date();
      // const year = now.getFullYear(); // 년도
      // const month = String(now.getMonth() + 1).padStart(2, '0'); // 월 (0부터 시작하므로 +1)
      // const day = String(now.getDate()).padStart(2, '0'); // 일
      // const hours = String(now.getHours()).padStart(2, '0'); // 시
      // const minutes = String(now.getMinutes()).padStart(2, '0'); // 분
      // const seconds = String(now.getSeconds()).padStart(2, '0'); // 초
      // const milliseconds = String(now.getMilliseconds()).padStart(3, '0'); // 밀리초
      // const fileKey = this.param.match(/\/rv\s+([^\s]+)\s+\[([^\]]+)\]/)?.[2] || null; // 조회 key 
      // const exportName = `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}${this.path}${fileKey}`; 

      // console.log('exportName : ', exportName);
      
      this.$axios({
        method: "post",
        url: smartFormServer,
        // url: '/eform/CreateServer/sga_np/invoker.jsp',
        // url: `${process.env.VUE_APP_REPORTING_SERVER}/CreateServer/sga_np/invoker.jsp`,
        // url: '/CreateServer/sga_np/invoker.jsp',
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        data: `mrdpath=${formId}&mrdparam=${encodeURIComponent(formParam)}&rdata=${encodeURIComponent(sData)}`,
        timeout: 5000,
      })
        .then((response) => {
          const data = response.data;
          const dataArray = data.split("|");

          if (dataArray[0] === "0") {
            alert("invoker.jsp Error : " + dataArray[1]);
          } else {
            
            let smartFormServer = '';

            // 로컬호스트 
            if (process.env.VUE_APP_ENV === 'local') { 
              smartFormServer = 'http://srf-info.or.kr:19020/CreateServer/np_reader_forward.jsp';

            // 운영, 스테이징 
            } else { 
              smartFormServer = '/CreateServer/np_reader_forward.jsp'; 
            } 
            
            const url = smartFormServer; 
            // const url = `${process.env.VUE_APP_REPORTING_SERVER}/CreateServer/np_reader_forward.jsp`; 
            // const url = '/CreateServer/np_reader_forward.jsp';
            
            // console.log('smartForm log : ', smartFormServer); 
            // console.log("invoker.jsp result : ", data);
            // console.log("np_reader_forward.jsp 주소 : ", url);

            const jspParams = {
              docno: dataArray[1],
              mmldata: dataArray[2],
              bcd: this.bcdYn,
              tsa: this.tsaYn,
              apiurl: pApiUrl,
              hstrysection: this.hstrySection, 
              mrdfile: this.path,
              pdfdownloadname: pPdfFileName + ".pdf",
              userid: this.userinfo.userId,
              hstrykey: pHstryKey, 
              hstryval: pHstryVal, 
              tmpltcd: this.tmpltCd 
            };

            // POST 방식으로 데이터를 전송하기 위해 동적으로 form 생성 
            const form = document.createElement("form");
            form.method = "POST";
            form.action = url;
            form.target = "popupVoiceyeViewer"; // 팝업 창 이름과 매칭

            // jspParams의 각 key-value 쌍을 form 데이터로 추가
            Object.keys(jspParams).forEach((key) => {
              const input = document.createElement("input");
              input.type = "hidden";
              input.name = key;
              input.value = jspParams[key];
              form.appendChild(input);
            });

            // 팝업 크기 및 위치 설정
            const screenWidth = window.screen.availWidth; // 사용 가능한 화면 너비
            const screenHeight = window.screen.availHeight; // 사용 가능한 화면 높이
            const popupWidth = Math.min(screenWidth * 0.8, 1300); // 80% 너비, 최대 1300px
            const popupHeight = screenHeight * 0.9; // 90% 높이 
            const popupLeft = Math.max((screenWidth - popupWidth) / 2, 0); // 화면 중앙 위치, 최소 0
            const popupTop = Math.max((screenHeight - popupHeight) / 2, 0); // 화면 중앙 위치, 최소 0

            // 팝업 열기
            const popup = window.open(
              "", // URL은 form.action에 이미 지정됨
              "popupVoiceyeViewer", // 팝업 창 이름
              `width=${popupWidth},height=${popupHeight},top=${popupTop},left=${popupLeft},scrollbars=yes,resizable=yes`
            );

            if (popup) {
              // 폼 제출
              document.body.appendChild(form); // 폼을 문서에 추가
              form.submit(); // 폼 제출
              document.body.removeChild(form); // 제출 후 폼 제거
            } else {
              alert("팝업이 차단되었습니다. 팝업 설정을 확인해주세요.");
            }
          }
        });
        // .catch((error) => {
        //   console.error("Download failed:", error);
        // });
    },
    crownixViewerOpen() {
      const targetElement = document.querySelector('#' + this.id); 
      
      if (targetElement) {
        const popupBox = targetElement.closest('.popup_box'); // 상위 .popup_box 클래스 찾기 

        if (popupBox) {
          popupBox.style.height = '89%'; // 높이를 89%로 변경
        }
      } 

      this.viewer.openFile(`${this.path}.mrd`, `${this.param} ${this.commonParam}`, this.viewerOptions); 
    },
    eFormSaveSuccess() {
      this.viewer.showToolbarItem(["modifyIcon"]); 
      this.viewer.hideToolbarItem(["submitIcon"]); 
    },
    onslfCertEvent() {
      this.btnOnslfCert(); 
    }
  }
}
</script>

<style>
  #crownix-toolbar-xls {
    display:none;
  }

  #crownix-toolbar-doc {
    display:none;
  }

  #crownix-toolbar-ppt {
    display:none;
  }

  #crownix-toolbar-pdf {
    display:none;
  }

  #m2soft-crownix-container #m2soft-crownix-text input[type=text]{
    letter-spacing: 0px !important;
  }

  .crownix-prevent-user-select *,
  .crownix-prevent-user-select *::before,
  .crownix-prevent-user-select *::after{ box-sizing: content-box !important; word-break: normal !important;}
  .crownix-field-dialog-button-group .button{ padding: 5px 0; margin: 0;}
  .crownix-field-dialog-button-group{ display: flex; justify-content: center;}
</style>