<template>
    <div v-show="isList">
      <incm-qlty-insp-aply-list ref="IncmQltyInspAplyList" @incm-qlty-insp-aply-form="goToDetail"></incm-qlty-insp-aply-list>
    </div>
    <div v-show="isDetail">
      <incm-qlty-insp-aply-detail  ref="IncmQltyInspAplyDetail" @incm-qlty-insp-aply-list="goToList" :parentData="this.parentData"></incm-qlty-insp-aply-detail>
    </div>
  </template>

<script>

// import { storeSnackbar } from "@/js/store/store-snackbar";
// import { storeSwitch } from "@/js/store/store-switch";
// import { storeComponent } from "@/js/store/store-component";
import IncmQltyInspAplyList from "@/views/sfli/IncmQltyInspAplyList.vue";
import IncmQltyInspAplyDetail from "@/views/sfli/IncmQltyInspAplyDetail.vue";

export default {
  components: {IncmQltyInspAplyList, IncmQltyInspAplyDetail},
  data() {
    return {
      menu: this.$menusTotal.getRouteMenu(this.$route.path),
      isList: true,
      isDetail: false,
      parentData: {
        bzentyId: "",
        bplcId: "",
        fcltSqno: "",
        inspSeCd: "",
      },
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  beforeMount() {
  },
  mounted() {
    this.initialize();
  },
  beforeUnmount() {
    // 컴포넌트 전체 데이터 저장하기
    // storeComponent.store(this.menu, this);
  },
  methods: {
    async initialize() {
        //
    },
    goToList(){
        this.isList = true;
        this.isDetail = false;

        this.$refs.IncmQltyInspAplyList.reset();
    },
    goToDetail(param){

        //
        this.parentData.bzentyId = param.bzentyId;
        this.parentData.bplcId = param.bplcId;
        this.parentData.fcltSqno = param.fcltSqno;
        this.parentData.inspSeCd = param.inspSeCd;

        this.isList = false;
        this.isDetail = true;

        this.$nextTick(()=> {
          this.$refs.IncmQltyInspAplyDetail.reset();
        });

    },
  }
}
</script>