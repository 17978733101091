<template>
  <div class="desc_box">
    <i class="icon"><img src="@/assets/images/common/ico_desc.png" alt="이미지"></i>
    <div class="desc">
      <strong class="desc_title">의견서를 제출해야 합니다.</strong>
      <p>품질확인검사 결과 및 품질표시명세서 내용이 품질등급 판정 세부내용과 상이할 경우 의견서를 제출합니다.</p>
    </div>
  </div>
  <h2 class="title1">신청기간</h2>
  <p>임시 품질등급 판정일로부터 10일 이내입니다(고형연료제품의 품질표시 방법 및 품질등급 구분 등에 관한 고시).</p>

  <h2 class="title1">신청방법</h2>
  <p>폐자원에너지 종합정보관리시스템의 <strong>품질등급제 | 의견서제출 게시판</strong>에 고형연료제품 품질등급의견서를 제출합니다(고형연료제품의 품질표시 방법 및
    품질등급 구분 등에 관한 고시[별지 제5호서식]).</p>
  <span class="txt_hint ml_0 mt_10">※ 구비서류 : 의견서 내용을 증빙할 수 있는 성적서 등</span>

  <h2 class="title1">진행절차</h2>
  <ol class="circle_list">
    <li>의견서를 제출합니다.</li>
    <li>검증위원회를 개최합니다.
      <ul class="dot_list">
        <li>구성 : 위원장을 포함한 5인~9인 이내 위원</li>
        <li>위원장을 포함한 재적위원 과반수 출석과 출석위원의 과반수 찬성으로 의결</li>
      </ul>
    </li>
    <li>결과를 통보합니다. (품질등급 발표일 전)</li>
  </ol>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {}
}
</script>

<style scoped>

</style>