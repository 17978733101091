<template>
  <div class="grid_area_wrap">
    <div class="grid_area">
      <div class="board_info">
        <h3 class="title2">반입업체 현황</h3>
        <div>
          <br-anchor-button label="반입업체검색" size="sm" format="tertiary" icon="ico_search" @click="showWtClctBzentyPopup" />
        </div>
      </div>
      <sb-grid
          id="crynGrid"
          :data-source="crynDataSource"
          :columns="crynGridColumns"
          :gridattr="crynGridAttr"
          :param="param"
          v-model:grid="crynGridObject"
          @loaded="crynGridLoaded"
          :refreshGrid="crynGridReload"
          @change="changeWtClctCnptUseYn"
          :fileName="getFileName('반입업체 현황')"
      />
    </div>
    <div class="grid_area">
      <div class="board_info">
        <h3 class="title2">판매(공급)업체 현황</h3>
        <div>
          <br-anchor-button label="판매업체검색" size="sm" format="tertiary" icon="ico_search" @click="showEneSplyBzentyPopup" />
        </div>
      </div>
      <sb-grid
          id="splyGrid"
          :dataSource="splyDataSource"
          :columns="splyGridColumns"
          :gridattr="splyGridAttr"
          :param="param"
          v-model:grid="splyGridObject"
          @loaded="splyGridLoaded"
          :refreshGrid="splyGridReload"
          @change="changeEneSplyCnptUseYn"
          :fileName="getFileName('판매(공급)업체 현황')"
      />
    </div>
  </div>

  <wt-clct-bzenty-popup @select-bzenty="saveWtClctCnpt" />
  <ene-sply-bzenty-popup @select-bzenty="saveEneSplyCnpt" />
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";
import SbGrid from "@/components/SbGrid.vue";
import WtClctBzentyPopup from "@/views/prfmnc-rpt/components/popup/WtClctBzentyPopup.vue";
import EneSplyBzentyPopup from "@/views/prfmnc-rpt/components/popup/EneSplyBzentyPopup.vue";

const API = {
  WASTE_COLLECTION_COUNTERPARTY : "/wtClct/cnpt",
  ENE_SPLY_COUNTERPARTY : "/eneSply/cnpt",
};
const CODE = {
  BPLC_KND_CD: "MBR006", // 사업장종류코드
}

export default {
  components: {
    EneSplyBzentyPopup,
    WtClctBzentyPopup,
    SbGrid
  },
  props: ['param'],
  data() {
    return {
      ctpvCdList: [],
      sggCdList: [],
      crynGridReload: false,
      crynGridObject: null,
      crynDataSource: {
        ajaxConfig: {
          select: { url: API.WASTE_COLLECTION_COUNTERPARTY },
          paging: false,
        },
        schema: {
          id: 'wtClctBzentySn'
        }
      },
      crynGridColumns: [],
      crynGridAttr: {
        pageable: false,
        editable: true,
        sortable: true,
        mergeRow: true,
      },
      splyGridReload: false,
      splyGridObject: null,
      splyDataSource: {
        ajaxConfig: {
          select: { url: API.ENE_SPLY_COUNTERPARTY },
          paging: false,
        },
        schema: {
          id: 'eneBzentySn'
        }
      },
      splyGridColumns: [],
      splyGridAttr: {
        pageable: false,
        editable: true,
        sortable: true,
        mergeRow:true
      },
      bplcKndCdList: [],
    };
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loadBplcKndCd();
      this.loadCtpvCd();
      this.loadSggCd();
      this.initializeCrynGridColumns();
      this.initializeSplyGridColumns();
      this.crynGridReload = true;
      this.splyGridReload = true;
    },
    loadBplcKndCd() {
      this.bplcKndCdList = this.$store.getters.getCodeList({groupCd: CODE.BPLC_KND_CD, useYn: 'Y'});
    },
    loadCtpvCd() {
      const ctpvList = this.$store.getters.getSggList({ type: 'ctpv' });
      this.ctpvCdList = ctpvList.map(item => ({
        text: item.sggNm,
        value: item.sggStdgCd
      }));
    },
    loadSggCd() {
      const sggList = this.$store.getters.getSggList({ type: 'sgg' });
      this.sggCdList = sggList.map(item => ({
        text: item.sggNm,
        value: item.sggStdgCd,
        upSggStdgCd: item.upSggStdgCd
      }));
    },
    initializeCrynGridColumns() {
      this.crynGridColumns = [
        { field: 'bplcId', caption: '사업장아이디', visible: false },
        { field: 'wtClctBzentySn', caption: '폐기물수집업체일련번호', visible: false },
        { field: 'ctpvStdgCd', caption: '시·도', sortable: false,
          type: 'combo',
          items: this.ctpvCdList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 105,
          editable: false,
          mergeRow:true,
          exportLabel : true
        },
        { field: 'sggStdgCd', caption: '시·군·구', sortable: false,
          type: 'combo',
          items: this.sggCdList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 110,
          editable: false,
          mergeRow:true,
          exportLabel : true
        },
        { field: 'wtClctBzentyNm', caption: '업체명', width: 100, unit:'%', editable: false, align: 'left', sortable: false},
        { field: 'useYn',
          caption: '사용여부',
          type: 'check',
          checkValue: 'Y',
          checkLabel: '사용',
          unCheckValue: 'N',
          unCheckLabel: '미사용',
          checkMode:'cell',
          directApplyEdit : true,
          width: 80,
          exportLabel : true
        },
      ]
    },
    initializeSplyGridColumns() {
      this.splyGridColumns = [
        { field: 'bplcId', caption: '사업장아이디', visible: false },
        { field: 'eneBzentySn', caption: '에너지공급업체일련번호', visible: false },
        { field: 'ctpvStdgCd', caption: '시·도', sortable: false,
          type: 'combo',
          items: this.ctpvCdList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 105,
          editable: false,
          mergeRow:true,
          exportLabel : true
        },
        { field: 'sggStdgCd', caption: '시·군·구', sortable: false,
          type: 'combo',
          items: this.sggCdList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 110,
          editable: false,
          mergeRow:true,
          exportLabel : true
        },
        { field: 'bzentyNm', caption: '업체명', width: 100, unit:'%', editable: false, align: 'left', sortable: false },
        {
          field: 'useYn',
          caption: '사용여부',
          type: 'check',
          checkValue: 'Y',
          checkLabel: '사용',
          unCheckValue: 'N',
          unCheckLabel: '미사용',
          checkMode:'cell',
          directApplyEdit : true,
          width: 80,
          exportLabel : true
        },
      ]
    },
    crynGridLoaded() {
      this.crynGridReload = false;
    },
    splyGridLoaded() {
      this.splyGridReload = false;
    },
    getFileName(prefix) {
      const bplcKnd = this.bplcKndCdList.find(item => item.dtlCd === this.param.bplcKndCd);
      const bplcName = bplcKnd ? bplcKnd.dtlCdNm : '사업장';
      return `${bplcName} ${prefix}.xlsx`;
    },
    showWtClctBzentyPopup() {
      storeSwitch.on('WtClctBzentyPopup', this.param.bplcId);
    },
    showEneSplyBzentyPopup() {
      storeSwitch.on('EneSplyBzentyPopup', this.param.bplcId);
    },
    saveWtClctCnpt(list) {
      const crynAllItems = window.SBGrid3.getAllItems(this.crynGridObject);

      // wtClctBzentySn를 추출하여 배열로 생성
      const existingwtClctBzentySns = crynAllItems.map(item => item.wtClctBzentySn);

      // list에서 기존 항목에 포함되지 않은 값만 필터링
      const filteredList = list.filter(item => !existingwtClctBzentySns.includes(item));

      if (filteredList.length === 0) {
        alert("이미 등록된 업체입니다.");
        return;
      }

      this.$apiCall.post(
          API.WASTE_COLLECTION_COUNTERPARTY,
          { bplcId: this.param.bplcId, list: filteredList },
          () => {
            alert("저장되었습니다.");
            storeSwitch.off("WtClctBzentyPopup");
            window.SBGrid3.reload(this.crynGridObject);
          },
          (error) => {
            alert(error.message);
          }
      );
    },
    saveEneSplyCnpt(list) {
      const splyAllItems = window.SBGrid3.getAllItems(this.splyGridObject);

      // eneBzentySn를 추출하여 배열로 생성
      const existingEneBzentySns = splyAllItems.map(item => item.eneBzentySn);

      // list에서 기존 항목에 포함되지 않은 값만 필터링
      const filteredList = list.filter(item => !existingEneBzentySns.includes(item));

      if (filteredList.length === 0) {
        alert("이미 등록된 업체입니다.");
        return;
      }

      this.$apiCall.post(
          API.ENE_SPLY_COUNTERPARTY,
          { bplcId: this.param.bplcId, list },
          () => {
            alert("저장되었습니다.");
            storeSwitch.off("EneSplyBzentyPopup");
            window.SBGrid3.reload(this.splyGridObject);
          },
          (error) => {
            alert(error.message);
          }
      );
    },
    changeWtClctCnptUseYn(values) {
      values.forEach(item => {
        if (item.field === 'useYn') {
          const rowData = window.SBGrid3.getRowData(this.crynGridObject, item.key);

          if (!confirm(`${rowData.wtClctBzentyNm}의 사용여부를 변경하시겠습니까?`)) {
            window.SBGrid3.clearSaveData(this.crynGridObject);
            window.SBGrid3.reload(this.crynGridObject);
            return;
          }

          this.$apiCall.put(
              API.WASTE_COLLECTION_COUNTERPARTY,
              { bplcId: rowData.bplcId, wtClctBzentySn: rowData.wtClctBzentySn, useYn: item.value },
              () => {
                alert("사용여부가 수정되었습니다.");
                window.SBGrid3.clearSaveData(this.crynGridObject);
                window.SBGrid3.reload(this.crynGridObject);
              },
              (error) => {
                alert(error.message);
              }
          );
        }
      });
    },
    changeEneSplyCnptUseYn(values) {
      values.forEach(item => {
        if (item.field === 'useYn') {
          const rowData = window.SBGrid3.getRowData(this.splyGridObject, item.key);

          if (!confirm(`${rowData.bzentyNm}의 사용여부를 변경하시겠습니까?`)) {
            window.SBGrid3.clearSaveData(this.splyGridObject);
            window.SBGrid3.reload(this.splyGridObject);
            return;
          }

          this.$apiCall.put(
              API.ENE_SPLY_COUNTERPARTY,
              { bplcId: rowData.bplcId, eneBzentySn: rowData.eneBzentySn, useYn: item.value },
              () => {
                alert("사용여부가 수정되었습니다.");
                window.SBGrid3.reload(this.splyGridObject);
              },
              (error) => {
                alert(error.message);
              }
          );
        }
      });
    },
    search() {
      window.SBGrid3.reload(this.crynGridObject);
      window.SBGrid3.reload(this.splyGridObject);
    }
  }
}
</script>

<style scoped>

</style>