import { reactive } from "vue";

/**
 * 팝업 상태를 저장하는 맵 객체
 * @type {Map<string, Object>}
 */
const BULBS = new Map();

/**
 * storeSwitch 객체는 팝업 상태를 관리하는 반응형 데이터 저장소입니다.
 * @type {Object}
 */
export const storeSwitch = reactive({
    /**
     * 이름과 팝업 객체를 받아 BULBS 맵에 저장합니다.
     * @param {string} name - 팝업의 이름
     * @param {Object} bulb - 팝업 객체
     */
    setBulb(name, bulb) {
        BULBS.set(name, bulb);
    },

    /**
     * 주어진 이름의 팝업을 찾아 메시지를 보여주고, 콜백 함수를 호출합니다.
     * @param {string} name - 팝업의 이름
     * @param {any} message - 팝업에 전달할 메시지
     * @param {function} callback - 콜백 함수
     * @param {any} param - 콜백 함수에 전달할 매개변수
     */
    on(name, message, callback, param) {
        let bulb = BULBS.get(name);
        if (bulb != null) {
            bulb.show(message, callback, param);
        }
    },

    /**
     * 주어진 이름의 팝업을 찾아 숨김 처리합니다.
     * @param {string} name - 팝업의 이름
     */
    off(name) {
        let bulb = BULBS.get(name);
        if (bulb != null) {
            bulb.hide();
        }
    },
})