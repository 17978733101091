<template>
  <!-- 검사정보 영역 -->
  <div class="board_info">
    <h2 class="title1">검사정보</h2>
    <div class="btn_area">
      <br-anchor-button v-if="!canPrintSpcmPicPlan" label="수입신고서 양식 출력" size="sm" icon="ico_print" @click="printIncmReporting"/>
      <br-anchor-button v-if="!canPrintSpcmPicPlan" label="시료채취계획서 출력" size="sm" icon="ico_print" @click="printSpcmPicPlan" />
    </div>
  </div>
  <div class="board_write">
    <table>
      <caption>품질검사 결과-검사(예정)일자, 검사원 명으로 구성</caption>
      <colgroup>
        <col style="width:25%">
        <col style="width:75%">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">검사(예정)일자</th>
        <td style="display: flex; align-items: center; gap: 10px">
          <br-date-picker label="검사(예정)일자" v-model:value="qltyInspResultInfo.inspPrnmntYmd" disabled/>
          <br-anchor-button v-if="!inspSpnsDsctn.inspAplySn && (qltyInspResultInfo.inspPrgrsSttsCd === '20' || qltyInspResultInfo.inspPrgrsSttsCd === '40')" label="검사연기 신청" size="xsm" format="primary line" @click="applyInspDateExtension" />
            <button v-if="inspSpnsDsctn && inspSpnsDsctn.inspSpnsSttsNm" 
                    type="button" 
                    class="btn xsm primary line" 
                    @click.prevent="openModalInspSpnsAply(inspSpnsDsctn.inspAplySn, inspSpnsDsctn.inspSpnsPrlgSqno)"
            >
                연기{{ inspSpnsDsctn.inspSpnsSttsNm }}
            </button>
        </td>
      </tr>
      <tr>
        <th scope="row">검사원 명</th>
        <td>
          <div class="input_group w100p pr_40">
            <br-form-input label="검사원 명" type="text" v-model="qltyInspResultInfo.inspectors" disabled/>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <!-- 처리기한연장 영역 -->
  <template v-if="['60', '70'].includes(qltyInspResultInfo.inspPrgrsSttsCd) && inspDateExtension">
    <div class="board_info mt_40">
      <h2 class="title1">처리기한연장</h2>
    </div>
    <div class="board_write">
      <table>
        <caption>품질검사 결과-검사(예정)일자, 검사원 명으로 구성</caption>
        <colgroup>
          <col style="width:25%">
          <col style="width:75%">
        </colgroup>
        <tbody>
        <tr>
          <th scope="row">처리일자</th>
          <td>
            <br-date-picker label="처리일자" v-model:value="inspDateExtension.chgYmd" disabled/>
          </td>
        </tr>
        <tr>
          <th scope="row">사유</th>
          <td>
            <div class="input_group w100p pr_40">
              <br-form-input label="사유" type="text" v-model="inspDateExtension.chgRsn" disabled/>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </template>
  <!-- 검사결과 영역 -->
  <template v-if="qltyInspResultInfo.inspPrgrsSttsCd === '70' || qltyInspResultInfo.inspPrgrsSttsCd === '80' || qltyInspResultInfo.inspPrgrsSttsCd === '90'">
    <div class="board_info mt_40">
      <h2 class="title1">검사결과</h2>
    </div>
    <div class="sch_form_wrap">
      <div class="sch_form ">
    <span class="w35p">
      <label for="">검사결과</label>
        <br-form-select label="검사결과" v-model:value="qltyInspResultInfo.inspRsltCd" :options="inspRsltCdList" disabled />
    </span>
        <span class="w65p pr_40">
      <label for="">사유</label>
      <div class="input_group w100p pr_40">
          <br-form-input label="사유" type="text" v-model="qltyInspResultInfo.inspRsn" disabled/>
      </div>
    </span>
      </div>
    </div>
  </template>
  <!-- 품질검사결과 영역 -->
  <template v-if="inspRsltItems.inspRsltSn">
    <div class="board_info mt_40">
      <h2 class="title1">품질검사결과</h2>
      <br-anchor-button label="품질검사결과서 출력" size="sm" icon="ico_print" @click="printQltyInspResult" />
    </div>
    <div class="board_list">
      <table>
        <caption>품질(확인)검사 결과-시험항목, 품질기준, 시험결과, 비고로 구성</caption>
        <colgroup>
          <col style="width:15%">
          <col style="width:17%">
          <col style="width:32%">
          <col style="width:18%">
          <col style="width:18%">
        </colgroup>
        <thead>
        <tr>
          <th scope="col" colspan="2">시험항목</th>
          <th scope="col">품질기준</th>
          <th scope="col">시험결과</th>
          <th scope="col">비고</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td rowspan="3" aria-label="시험항목">모양 및 크기</td>
          <td aria-label="시험항목">가로(mm)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.dmtrCrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.dmtrMsrmtVl }}</td>
          <td aria-label="비고">{{ this.inspRsltItems.dmtrRsltCdNm }}</td>
        </tr>
        <tr>
          <td aria-label="시험항목">세로(mm)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.lenCrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.lenMsrmtVl }}</td>
          <td aria-label="비고">{{ this.inspRsltItems.lenRsltCdNm }}</td>
        </tr>
        <tr>
          <td colspan="2" aria-label="품질기준">{{ this.inspRsltItems.svpsrtCrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.svpsrt }}</td>
          <td aria-label="비고">{{ this.inspRsltItems.svpsrtRsltCdNm }}</td>
        </tr>
        <tr>
          <td colspan="2" aria-label="시험항목">(저위)발열량(kcal/kg)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd180CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd180RsltVl }}</td>
          <td aria-label="비고">{{ this.inspRsltItems.cpstCd180RsltCdNm }}</td>
        </tr>
        <tr>
          <td colspan="2" aria-label="시험항목">수분 함유량(wt. %)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd160CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd160RsltVl }}</td>
          <td aria-label="비고">{{ this.inspRsltItems.cpstCd160RsltCdNm }}</td>
        </tr>
        <tr>
          <td rowspan="5" aria-label="시험항목">금속성분<br>(mg/kg,건기준)</td>
          <td aria-label="시험항목">수은(Hg)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd80CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd80RsltVl }}</td>
          <td aria-label="비고">{{ this.inspRsltItems.cpstCd80RsltCdNm }}</td>
        </tr>
        <tr>
          <td aria-label="시험항목">카드뮴(Cd)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd90CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd90RsltVl }}</td>
          <td aria-label="비고">{{ this.inspRsltItems.cpstCd90RsltCdNm }}</td>
        </tr>
        <tr>
          <td aria-label="시험항목">납(Pb)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd100CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd100RsltVl }}</td>
          <td aria-label="비고">{{ this.inspRsltItems.cpstCd100RsltCdNm }}</td>
        </tr>
        <tr>
          <td aria-label="시험항목">비소(As)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd110CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd110RsltVl }}</td>
          <td aria-label="비고">{{ this.inspRsltItems.cpstCd110RsltCdNm }}</td>
        </tr>
        <tr>
          <td aria-label="시험항목">크로뮴(Cr)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd120CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd120RsltVl }}</td>
          <td aria-label="비고">{{ this.inspRsltItems.cpstCd120RsltCdNm }}</td>
        </tr>
        <tr>
          <td colspan="2" aria-label="시험항목">회분 함유량(wt. %)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd170CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd170RsltVl }}</td>
          <td aria-label="비고">{{ this.inspRsltItems.cpstCd170RsltCdNm }}</td>
        </tr>
        <tr>
          <td colspan="2" aria-label="시험항목">염소 함유량(wt. %)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd140CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd140RsltVl }}</td>
          <td aria-label="비고">{{ this.inspRsltItems.cpstCd140RsltCdNm }}</td>
        </tr>
        <tr>
          <td colspan="2" aria-label="시험항목">황분 함유량(wt. %)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd130CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd130RsltVl }}</td>
          <td aria-label="비고">{{ this.inspRsltItems.cpstCd130RsltCdNm }}</td>
        </tr>
        <tr>
            <td colspan="2" aria-label="시험항목">바이오매스</td>
            <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd190CrtrCn }}</td>
            <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd190RsltVl }}</td>
            <td aria-label="비고">{{ this.inspRsltItems.cpstCd190RsltCdNm }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  <!-- 품질표시검사결과 영역 -->
  <template v-if="qltyInspResultInfo.qltyIndctInspExistYn === 'Y'">
    <div class="board_info mt_40">
      <h2 class="title1">품질표시검사결과</h2>
      <br-anchor-button label="품질명세서 출력" size="sm" icon="ico_print" @click="printQltySpecification" />
    </div>
    <div class="board_list">
      <table>
        <caption>품질표시검사 결과-시험항목, 품질기준, 시험결과, 비고로 구성</caption>
        <colgroup>
          <col style="width:15%">
          <col style="width:17%">
          <col style="width:32%">
          <col style="width:18%">
          <col style="width:18%">
        </colgroup>
        <thead>
        <tr>
          <th scope="col" colspan="2">시험항목</th>
          <th scope="col">품질기준</th>
          <th scope="col">시험결과</th>
          <th scope="col">비고</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td rowspan="3" aria-label="시험항목">모양 및 크기</td>
          <td aria-label="시험항목">가로(mm)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.dmtrCrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.dmtrMsrmtVl }}</td>
          <td aria-label="비고"></td>
        </tr>
        <tr>
          <td aria-label="시험항목">세로(mm)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.lenCrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.lenMsrmtVl }}</td>
          <td aria-label="비고"></td>
        </tr>
        <tr>
          <td colspan="2" aria-label="품질기준">{{ this.inspRsltItems.svpsrtCrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.svpsrt }}</td>
          <td aria-label="비고"></td>
        </tr>
        <tr>
          <td colspan="2" aria-label="시험항목">(저위)발열량(kcal/kg)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd180CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd180RsltVl }}</td>
          <td aria-label="비고"></td>
        </tr>
        <tr>
          <td colspan="2" aria-label="시험항목">수분 함유량(wt. %)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd160CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd160RsltVl }}</td>
          <td aria-label="비고"></td>
        </tr>
        <tr>
          <td rowspan="12" aria-label="시험항목">금속성분<br>(mg/kg,건기준)</td>
          <td aria-label="시험항목">수은(Hg)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd80CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd80RsltVl }}</td>
          <td aria-label="비고"></td>
        </tr>
        <tr>
          <td aria-label="시험항목">카드뮴(Cd)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd90CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd90RsltVl }}</td>
          <td aria-label="비고"></td>
        </tr>
        <tr>
          <td aria-label="시험항목">납(Pb)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd100CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd100RsltVl }}</td>
          <td aria-label="비고"></td>
        </tr>
        <tr>
          <td aria-label="시험항목">비소(As)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd110CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd110RsltVl }}</td>
          <td aria-label="비고"></td>
        </tr>
        <tr>
          <td aria-label="시험항목">크로뮴(Cr)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd120CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd120RsltVl }}</td>
          <td aria-label="비고"></td>
        </tr>
        <tr>
          <td aria-label="시험항목">안티몬(Sb)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd10CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd10RsltVl }}</td>
          <td aria-label="비고"></td>
        </tr>
        <tr>
          <td aria-label="시험항목">코발트(Co)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd20CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd20RsltVl }}</td>
          <td aria-label="비고"></td>
        </tr>
        <tr>
          <td aria-label="시험항목">구리(Cu)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd30CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd30RsltVl }}</td>
          <td aria-label="비고"></td>
        </tr>
        <tr>
          <td aria-label="시험항목">망간(Mn)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd40CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd40RsltVl }}</td>
          <td aria-label="비고"></td>
        </tr>
        <tr>
          <td aria-label="시험항목">니켈(Ni)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd50CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd50RsltVl }}</td>
          <td aria-label="비고"></td>
        </tr>
        <tr>
          <td aria-label="시험항목">탈륨(Ti)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd60CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd60RsltVl }}</td>
          <td aria-label="비고"></td>
        </tr>
        <tr>
          <td aria-label="시험항목">바나듐(V)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd70CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd70RsltVl }}</td>
          <td aria-label="비고"></td>
        </tr>
        <tr>
          <td colspan="2" aria-label="시험항목">회분 함유량(wt. %)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd170CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd170RsltVl }}</td>
          <td aria-label="비고"></td>
        </tr>
        <tr>
          <td colspan="2" aria-label="시험항목">염소 함유량(wt. %)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd140CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd140RsltVl }}</td>
          <td aria-label="비고"></td>
        </tr>
        <tr>
          <td colspan="2" aria-label="시험항목">황분 함유량(wt. %)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd130CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd130RsltVl }}</td>
          <td aria-label="비고"></td>
        </tr>
        <tr>
          <td colspan="2" aria-label="시험항목">바이오매스 함유량(wt. %)</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd190CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd190RsltVl }}</td>
          <td aria-label="비고"></td>
        </tr>
        <tr>
          <td colspan="2" aria-label="시험항목">기타 항목</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd200CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd200RsltVl }}</td>
          <td aria-label="비고"></td>
        </tr>
        <!-- <tr>
          <td colspan="2" aria-label="시험항목">건조고위발열량</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd240CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd240RsltVl }}</td>
          <td aria-label="비고"></td>
        </tr>
        <tr>
          <td colspan="2" aria-label="시험항목">습윤고위발열량</td>
          <td aria-label="품질기준">{{ this.inspRsltItems.cpstCd250CrtrCn }}</td>
          <td aria-label="시험결과">{{ this.inspRsltItems.cpstCd250RsltVl }}</td>
          <td aria-label="비고"></td>
        </tr> -->
        </tbody>
      </table>
    </div>
  </template>
  <!-- 품질표시적정성 검사 영역 -->
  <template v-if="qltyInspResultInfo.qltyIndctPrprtInspYn === 'Y'">
    <div class="board_info mt_40">
      <h2 class="title1">품질표시적정성 검사</h2>
      <br-anchor-button label="적정성검사 현장 점검표 출력" size="sm" icon="ico_print" @click="printFieldInspChecklist" />
    </div>
    <div class="board_list">
      <table>
        <caption>품질표시적정성검사-구분, 확인내용, 점검결과로 구성</caption>
        <colgroup>
          <col style="width:15%">
          <col style="width:55%">
          <col style="width:30%">
        </colgroup>
        <thead>
          <tr>
            <th scope="col">구분</th>
            <th scope="col">확인내용</th>
            <th scope="col">점검결과</th>
          </tr>
        </thead>
        <tbody>
        <tr>
          <td rowspan="2" aria-label="구분">품질표시 유무확인</td>
          <td class="txt_left" aria-label="확인내용">1.품질표시 실시 여부</td>
          <td aria-label="점검결과">
            <div class="input_group">
              <!-- <br-form-input class="w300" label="품질표시실시여부 점검결과" type="text" v-model="indctPrprtInspRsltItems.inspRsltCn010000" disabled/> -->
              <br-form-select label="점검결과" v-model:value="indctPrprtInspRsltItems.prprtInspRsltCd010000" :options="inspTestCdList" disabled />
            </div>
          </td>
        </tr>
        <tr>
          <td class="txt_left" aria-label="확인내용">2. 법정 시험기관에서 검사결과로 품질표시를 하였는지 여부</td>
          <td aria-label="점검결과">
            <div class="input_group">
              <!-- <br-form-input class="w300" label="법정 시험기관에서 검사결과로 품질표시를 하였는지 여부 점검결과" type="text" v-model="indctPrprtInspRsltItems.inspRsltCn020000" disabled/> -->
              <br-form-select label="점검결과" v-model:value="indctPrprtInspRsltItems.prprtInspRsltCd020000" :options="inspTestCdList" disabled />
            </div>
          </td>
        </tr>
        <tr>
          <td rowspan="2" aria-label="구분">품질표시 방법확인</td>
          <td class="txt_left" aria-label="확인내용">3. 필수표시항목에 대하여 표시하였는지 여부</td>
          <td aria-label="점검결과">
            <div class="input_group">
              <!-- <br-form-input class="w300" label="필수표시항목에 대하여 표시하였는지 여부 점검결과" type="text" v-model="indctPrprtInspRsltItems.inspRsltCn030000" disabled/> -->
              <br-form-select label="점검결과" v-model:value="indctPrprtInspRsltItems.prprtInspRsltCd030000" :options="inspTestCdList" disabled />
            </div>
          </td>
        </tr>
        <tr>
          <td class="txt_left" aria-label="확인내용">4. 별표 1의 품질표시방법을 준수하였는지 여부</td>
          <td aria-label="점검결과">
            <div class="input_group">
              <!-- <br-form-input class="w300" label="별표 1의 품질표시방법을 준수하였는지 여부 점검결과" type="text" v-model="indctPrprtInspRsltItems.inspRsltCn040000" disabled/> -->
              <br-form-select label="점검결과" v-model:value="indctPrprtInspRsltItems.prprtInspRsltCd040000" :options="inspTestCdList" disabled />
            </div>
          </td>
        </tr>
        <tr>
          <td rowspan="3" aria-label="구분">품질표시 내용확인</td>
          <td class="txt_left" aria-label="확인내용">5. 시험기관에서 발급받은 품질명세서와 품질표시 내용 일치 여부</td>
          <td aria-label="점검결과">
            <div class="input_group">
              <!-- <br-form-input class="w300" label="시험기관에서 발급받은 품질명세서와 품질표시 내용 일치 여부 점검결과" type="text" v-model="indctPrprtInspRsltItems.inspRsltCn050000" disabled/> -->
              <br-form-select label="점검결과" v-model:value="indctPrprtInspRsltItems.prprtInspRsltCd050000" :options="inspTestCdList" disabled />
            </div>
          </td>
        </tr>
        <tr>
          <td class="txt_left" aria-label="확인내용">6. 제 10조에 따른 품질표시 변경주기의 준수 여부</td>
          <td aria-label="점검결과">
            <div class="input_group">
              <!-- <br-form-input class="w300" label="제 10조에 따른 품질표시 변경주기의 준수 여부 점검결과" type="text" v-model="indctPrprtInspRsltItems.inspRsltCn060000" disabled/> -->
              <br-form-select label="점검결과" v-model:value="indctPrprtInspRsltItems.prprtInspRsltCd060000" :options="inspTestCdList" disabled />
            </div>
          </td>
        </tr>
        <tr>
          <td class="txt_left" aria-label="확인내용">7. 품질표시내용과 보관중인 고형연료제품의 품질확인</td>
          <td aria-label="점검결과">
            <div class="input_group">
              <!-- <br-form-input class="w300" label="품질표시내용과 보관중인 고형연료제품의 품질확인 점검결과" type="text" v-model="indctPrprtInspRsltItems.inspRsltCn070000" disabled/> -->
              <br-form-select label="점검결과" v-model:value="indctPrprtInspRsltItems.prprtInspRsltCd070000" :options="inspTestCdList" disabled />
            </div>
          </td>
        </tr>
        <tr>
          <td aria-label="구분">시료채취</td>
          <td class="txt_left" aria-label="확인내용">8. 품질검사원이 판단하여 보관중인 고형연료제품과 품질명세서의
            내용이 차이가 크다고 판단되면 품질검사를 위한 시료 채취 실시
          </td>
          <td aria-label="점검결과">
            <div class="input_group">
              <!-- <br-form-input class="w300" label="시험기관에서 발급받은 품질명세서와 품질표시 내용 일치 여부 점검결과" type="text" v-model="indctPrprtInspRsltItems.inspRsltCn080000" disabled/> -->
              <br-form-select label="점검결과" v-model:value="indctPrprtInspRsltItems.prprtInspRsltCd080000" :options="inspTestCdList" disabled />
            </div>
          </td>
        </tr>
        <tr>
          <td aria-label="구분">기타의견</td>
          <td colspan="2" aria-label="점검결과">
            <br-form-input label="기타의견" type="text" v-model="indctPrprtInspRsltItems.inspRsltCn090000" disabled/>
          </td>
        </tr>
        <tr>
          <td aria-label="구분">점검일</td>
          <td colspan="2" aria-label="점검결과">
            <br-form-input label="점검일" type="text" v-model="indctPrprtInspRsltItems.chckYmd090000" disabled/>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </template>
  <!-- 최하단 버튼 영역 -->
  <div class="btn_area right">
    <br-anchor-button label="목록" size="md" format="tertiary" @click="goList"/>
  </div>
  <qlty-insp-extension-apply-popup ref="qltyInspExtensionApplyPopup" :inspAplySn="inspAplySn" @refresh-data="initialize"/>

  <!-- s: modal popup 검사연기 -->
  <div :class="['modal', { visible: isModalInspSpnsVisible }]" id="modalInspSpns"> 
    <div class="popup_box" tabindex="0">
      <h1 class="popup_title">검사연기</h1>
      <div class="popup_cont">
          <div class="desc_box progress_box">
              <i class="icon"><img src="@/assets/images/common/ico_noti.png" alt="이미지"></i>
              <div class="progress">
                  <div><strong>검사연기 신청기준 : 검사일 3일 전까지 요청한 경우 (공휴일 제외)</strong></div>
              </div>
          </div>
          <div class="board_write">
              <table>
                  <caption>검사연기신청_변경일자, 증빙서류, 신청사유로 구성</caption>
                  <colgroup>
                      <col style="width:15%">
                      <col style="width:85%">
                  </colgroup>
                  <tbody>
                      <tr>
                          <th scope="row"><i v-if="inspSpnsObj.pageType === 'new' || inspSpnsObj.inspSpnsSttsCd === '03'" class="necessary">필수입력</i>변경일자</th>
                          <td>
                            <span v-if="inspSpnsObj.pageType === 'new' || inspSpnsObj.inspSpnsSttsCd === '03'">
                              <br-date-picker label="변경일자" title="변경일자" v-model:value="inspSpnsObj.chgYmd" format="YYYYMMDD" panel="panel" required />
                            </span>
                            <span v-else>
                              {{inspSpnsObj.chgYmd}} 
                            </span>
                          </td>
                      </tr>
                      <tr v-if="inspSpnsObj.pageType === 'new' || inspSpnsObj.inspSpnsSttsCd === '03'">
                        <th scope="row">증빙서류</th>
                        <td>
                          <div class="file_upload_list" :class="{'file-upload-visible': isFileComponentVisible}">
                            <li v-if="isModalInspSpnsVisible">
                              <br-attach-file-list label="정기검사 연기 첨부파일 목록"
                                          ref="extAttachFileList"
                                          atchFileTaskSeCd="EXT" 
                                          v-model:atchFileGroupId="this.inspSpnsObj.inspAplyStatAtchFileGroupId"
                                          v-model:fileProcessingData="this.inspSpnsObj.fileProcessingData"
                                          :allowedExtensions="fileAllowedExtensions" 
                                          :textAreaWidth="fileTextAreaWidth"
                                          :readonly="false" 
                                          :useEtcFile="false" 
                                          panel="panel"
                                          required
                              /> 
                            </li> 
                          </div>
                        </td>
                      </tr>

                      <tr v-else>
                        <th scope="row">증빙서류</th>
                        <td>
                          <div class="file_upload_list" :class="{'file-upload-visible': isFileComponentVisible}">
                            <li>
                              <br-attach-file-list label="정기검사 연기 첨부파일 목록"
                                          ref="extAttachFileList"
                                          atchFileTaskSeCd="EXT" 
                                          v-model:atchFileGroupId="this.inspSpnsObj.inspAplyStatAtchFileGroupId"
                                          v-model:fileProcessingData="this.inspSpnsObj.fileProcessingData"
                                          :allowedExtensions="fileAllowedExtensions" 
                                          :textAreaWidth="fileTextAreaWidth"
                                          :readonly="true" 
                                          :useEtcFile="false" 
                              /> 
                            </li> 
                          </div>
                        </td>
                      </tr>

                      <tr>
                          <th scope="row"><i v-if="inspSpnsObj.pageType === 'new' || inspSpnsObj.inspSpnsSttsCd === '03'" class="necessary">필수입력</i>신청사유</th>
                          <td style="padding-top: 30px; padding-bottom: 30px;">
                            <span v-if="inspSpnsObj.pageType === 'new' || inspSpnsObj.inspSpnsSttsCd === '03'">
                              <br-form-input label="신청사유" title="신청사유" v-model="inspSpnsObj.inspSpnsAplyRsn" panel="panel" required/>
                            </span>
                            <span v-else>
                              {{inspSpnsObj.inspSpnsAplyRsn}}
                            </span>
                          </td>
                      </tr>
                  </tbody>
              </table>

              <table v-if="inspSpnsObj.inspSpnsSttsCd === '03'">
                <caption>검사연기신청_변경일자, 증빙서류, 신청사유로 구성</caption>
                  <colgroup>
                      <col style="width:15%">
                      <col style="width:35%">
                      <col style="width:15%">
                      <col style="width:35%">
                  </colgroup>
                  <tbody>
                    <tr>
                        <th scope="row">반려사유</th>
                        <td colspan="3" style="padding-top: 30px; padding-bottom: 30px;">
                          <span>{{inspSpnsObj.chgRsn}}</span>
                        </td>
                    </tr>
                    <tr>
                      <th scope="row">처리일자</th>
                      <td>{{inspSpnsObj.chgPrcsYmd}}</td>
                      <th scope="row">처리자</th>
                      <td>{{inspSpnsObj.userNm}}</td>
                    </tr>
                  </tbody>
              </table>
          </div>

          <div class="btn_area center">
              <br-button v-if="inspSpnsObj.pageType === 'new' || inspSpnsObj.aplyPsblYn === 'Y'" 
                        label="신청" title="신청" class="btn md primary" panel="panel" validate @click="createFcltInspSpnsAply()"/>
              <br-button label="닫기" title="닫기" class="btn md" @click="closeModal" />
          </div>
      </div>
      <button type="button" class="modal_close" @click="closeModal">팝업 닫기</button>
    </div>
  </div>
  <!-- e: modal popup 검사연기 --> 
</template>

<script>
/**
 * 수입품질검사 검사결과 확인 화면
 *
 * 작성자 : jhkim
 * 작성일 : 11/06/2024
 * 버전 : 1.0
 */
import QltyInspExtensionApplyPopup from "@/views/sfli/components/popup/QltyInspExtensionApplyPopup.vue";
import {buildUri} from "@/js/utils/uri-builder";

const API = {
  INCM_QLTY_INSP_RSLT : "/incm-qlty-insp-stat/result",
  QLTY_INSP_SPNS_DETAIL : "/fcltInsp/spnsRsct/{inspAplySn}/sqno/{inspSpnsPrlgSqno}",
}
const CODE = {
  INSP_RSLT_CD: 'COM430',
  TEST_RSLT_CD: 'SIS019',
}

export default {
  components: {QltyInspExtensionApplyPopup},
  emits: ['print-incm-qlty-insp-report'],
  props: {
    inspAplySn: {type: String, required: true},
  },
  computed : {
    canPrintSpcmPicPlan() {
      return this.qltyInspResultInfo.inspPrgrsSttsCd === '00' || this.qltyInspResultInfo.inspPrgrsSttsCd === '30';
    },
  },
  data() {
    return {
      qltyInspResultInfo: {},
      inspRsltItems: {},
      indctPrprtInspRsltItems: {},
      inspRsltCdList: [],
      inspTestCdList: [],
      inspDateExtension: {},
      inspSpnsDsctn: {},
      inspSpnsObj : {
          inspSpnsAplySn : "",
          chgBfrYmd : "",
          chgYmd : "", 
          inspSpnsAplyRsn : "", 
          inspAplyStatAtchFileGroupId : "", 
          inspSpnsPrlgSeCd : "",
          inspSpnsSttsCd : "",
          pageType : "",
          atchFileGroupId : "",
          aplyPsblYn : "",
          fileProcessingData: {}
      }, 
      isModalInspSpnsVisible: false, 
    };
  },
  watch : {},
  created() {},
  beforeMount (){},
  mounted() {
    this.initialize();
  },
  methods : {
    initialize() {
      this.inspRsltCdList = this.$store.getters.getCodeList({ groupCd: CODE.INSP_RSLT_CD, useYn: 'Y' }).map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
      this.inspTestCdList = this.$store.getters.getCodeList({ groupCd: CODE.INSP_RSLT_CD, useYn: 'Y' }).map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
      this.search();
    },
    search() {
      this.$apiCall.get(`${API.INCM_QLTY_INSP_RSLT}/${this.inspAplySn}`, {}, data => {
        // 검사결과
        if (data.result.qltyInspResult) this.qltyInspResultInfo = data.result.qltyInspResult;
        else this.qltyInspResultInfo = {};

        // 검사결과 상세
        if (data.result.inspRsltList) this.inspRsltItems = data.result.inspRsltList;
        else this.inspRsltItems = {};

        // 품질표시검사 결과 (=품질명세서)
        // 품질표시검사는 검사결과 쿼리 조회값을 같이 사용하기로함   // 2024.11.04
        // if (data.result.indctInspRsltList) this.indctInspRsltList = data.result.indctInspRsltList;
        // else this.indctInspRsltList = {};

        // 품질표시 적정섬 검사결과
        if (data.result.indctPrprtInspRsltList) this.indctPrprtInspRsltItems = data.result.indctPrprtInspRsltList;
        else this.indctPrprtInspRsltItems = {};

        // 검사연기 내역
        if (data.result.inspSpnsDsctn) this.inspSpnsDsctn = data.result.inspSpnsDsctn;
        else this.inspSpnsDsctn = {};

        // 검사 처리기한연장 내역
        if (data.result.inspPrlgDsctn) this.inspDateExtension = data.result.inspPrlgDsctn;
        else this.inspDateExtension = {};

      });
    },
    goList() {
      //this.$router.go(-1);
      this.$router.push("/sfli/IncmQltyInspStat");
    },
    printSpcmPicPlan() {
      this.$emit('print-incm-qlty-insp-report', 'reportSpcmPickPlan');
    },
    applyInspDateExtension() {
      alert('검사일자 변경 시, 추가수수료가 발생할 수 있습니다.');
      this.$refs.qltyInspExtensionApplyPopup.show();
    },
    printQltyInspResult() {
      this.$emit('print-incm-qlty-insp-report', 'reportIncmQltyInspResult');
    },
    printQltySpecification() {
      this.$emit('print-incm-qlty-insp-report', 'reportQltyStatement');
    },
    printFieldInspChecklist() {
      this.$emit('print-incm-qlty-insp-report', 'reportQltyIndctPrprtChckListIncm');
    },
    printIncmReporting() {
      this.$emit('print-incm-qlty-insp-report', 'reportSrfIncmDclr');
    },
    openModalInspSpnsAply(pInspAplySn, pInspSpnsPrlgSqno) { 
      this.inspSpnsObj.inspSpnsAplySn = pInspAplySn;

      // 상세 조회 
      this.inspSpnsObj.pageType = "search"; 
      this.readInspSpnsDetail(pInspAplySn, pInspSpnsPrlgSqno);
    },
    readInspSpnsDetail(pInspAplySn, pInspSpnsPrlgSqno) {
        // 검사 연기 신청 정보 조회 
        this.$apiCall.get(buildUri(API.QLTY_INSP_SPNS_DETAIL, {inspAplySn : pInspAplySn, inspSpnsPrlgSqno : pInspSpnsPrlgSqno}), {}, this.readInspSpnsDetailSuccess, this.error);
    },
    readInspSpnsDetailSuccess(data) {
      if (!data.result) {
        this.error();
        return;
      }

      this.inspSpnsObj = data.result;
      this.inspSpnsObj.inspSpnsAplySn = data.result.inspAplySn;
      this.inspSpnsObj.chgBfrYmd = data.result.chgYmd.replaceAll('-', '');
      this.isModalInspSpnsVisible = true;
    },
    error() {
      alert("데이터를 가져오는 중 오류가 발생했습니다.");
    },
    closeModal() { 
        this.isModalInspAplyHstryVisible = false; 
        this.isModalInspSpnsVisible = false;
    },
  }
}
</script>

<style scoped>

</style>
<script setup lang="ts">
</script>