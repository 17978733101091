<template>
  <rjct-rsn-area :prfmncBasicInfo="prfmncBasicInfo" />
  <prfmnc-mdfcn-prm-area :prfmncBasicInfo="prfmncBasicInfo" />

  <div class="board_info mt_40">
    <h2 class="title1" tabindex="-1" ref="sdflCrynTitle">고형연료제품 반입 현황(톤/월)</h2>
    <div>
      <br-button label="인계량적용" size="xsm" format="tertiary" @click="hndqtyAplcn" />
    </div>
  </div>
  <sb-grid
      id="sdflCrynGrid"
      :dataSource="sdflCrynDataSource"
      :columns="sdflCrynGridColumns"
      :gridattr="sdflCrynGridAttr"
      v-model:grid="sdflCrynGridObject"
      @loaded="sdflCrynGridLoaded"
      :refreshGrid="sdflCrynGridReload"
      @change="changeSdflCryn"
  />

  <h3 class="title1">고형연료제품 처리 현황</h3>
  <h2 class="title2" tabindex="-1" ref="sdflInputTitle">시설별 고형연료제품 투입 및 에너지 생산 현황</h2>
  <sb-grid
      id="sdflInputGrid"
      :dataSource="sdflInputDataSource"
      :columns="sdflInputGridColumns"
      :gridattr="sdflInputGridAttr"
      v-model:grid="sdflInputGridObject"
      @loaded="sdflInputGridLoaded"
      :refreshGrid="sdflInputGridReload"
      @change="changeSdflInput"
  />
  <h5>※ 스팀(톤), 전기(MWh)량은 월별 누계값을 입력 하셔야 합니다.</h5>
  <h5>※ 저위발열량은 최근 합격된 품질검사 정보를 자동으로 입력하고 있습니다. 반드시 검토하시고 잘못된 정보인 경우 수정하여 제출 바랍니다.</h5>

  <h2 class="title2" tabindex="-1" ref="sdflPrcsTitle">고형연료제품 종류별 처리 현황</h2>
  <sb-grid
      id="sdflPrcsGrid"
      :dataSource="sdflPrcsDataSource"
      :columns="sdflPrcsGridColumns"
      :gridattr="sdflPrcsGridAttr"
      v-model:grid="sdflPrcsGridObject"
      @loaded="sdflPrcsGridLoaded"
      :refreshGrid="sdflPrcsGridReload"
  />

  <h3 class="title1">폐자원에너지 생산/이용 현황</h3>
  <div class="board_write thead mb_10">
    <table>
      <caption>폐자원에너지 생산량, 자체사용량, 외부판매량으로 구성</caption>
      <colgroup>
        <col style="width: 16.66%;">
        <col style="width: 16.66%;">
        <col style="width: 16.66%;">
        <col style="width: 16.66%;">
        <col style="width: 16.66%;">
        <col >
      </colgroup>
      <thead>
      <tr>
        <th scope="col" colspan="2">폐자원에너지 생산량</th>
        <th scope="col" colspan="2">자체사용량</th>
        <th scope="col" colspan="2">외부판매량</th>
      </tr>
      <tr>
        <th scope="col">스팀(st/톤)</th>
        <th scope="col">전기(MWh)</th>
        <th scope="col">스팀(st/톤)</th>
        <th scope="col">전기(MWh)</th>
        <th scope="col">스팀(st/톤)</th>
        <th scope="col">전기(MWh)</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td aria-label="폐자원에너지 생산량-스팀(st/톤)" class="text-end">
          <br-form-input label="스팀" v-model="newUsePrfmncInfo.stSumPrdqty" unit="st/톤" validator="number" align="right" disabled />
        </td>
        <td aria-label="폐자원에너지 생산량-전기(MWh)" class="text-end">
          <br-form-input label="전기" v-model="newUsePrfmncInfo.prvtmSumPrdqty" unit="MWh" validator="number" align="right" disabled />
        </td>
        <td aria-label="자체사용량-스팀(st/톤)" class="text-end">
          <br-form-input label="스팀" v-model="newUsePrfmncInfo.stUsqty" unit="st/톤" validator="number" align="right" />
        </td>
        <td aria-label="자체사용량-전기(MWh)" class="text-end">
          <br-form-input label="전기" v-model="newUsePrfmncInfo.prvtmSumUsqty" unit="MWh" validator="number" align="right" />
        </td>
        <td aria-label="외부판매량-스팀(st/톤)" class="text-end">
          <br-form-input label="스팀" v-model="newUsePrfmncInfo.stSumNsqty" unit="MWh" validator="number" align="right" disabled />
        </td>
        <td aria-label="외부판매량-전기(MWh)" class="text-end">
          <br-form-input label="전기" v-model="newUsePrfmncInfo.prvtmSumNsqty" unit="MWh" validator="number" align="right" disabled />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h3 class="title1" tabindex="-1" ref="sdflNtslTitle">폐자원에너지 업체별 판매 현황</h3>
  <sb-grid
      id="ntslGrid"
      :dataSource="ntslDataSource"
      :columns="ntslGridColumns"
      :gridattr="ntslGridAttr"
      v-model:grid="ntslGridObject"
      @loaded="ntslGridLoaded"
      :refreshGrid="ntslGridReload"
      @change="changeNtsl"
  />
  <h5>※ 스팀(톤), 전기(MWh)량은 월별 누계값을 입력 하셔야 합니다.</h5>

  <common-button-area
      :prfmncBasicInfo="prfmncBasicInfo"
      @save="save"
      @submission="submission"
  />

  <sdfl-use-prcs-prfmnc-chg-dscnt-popup @eblc-aplcn="saveConfirmed" />
</template>

<script>
import SbGrid from "@/components/SbGrid.vue";
import utils from "@/js/utils/utils";
import RjctRsnArea from "@/views/prfmnc-rpt/dtl/components/RjctRsnArea.vue";
import PrfmncMdfcnPrmArea from "@/views/prfmnc-rpt/dtl/components/PrfmncMdfcnPrmArea.vue";
import commonButtonArea from "@/views/prfmnc-rpt/dtl/components/CommonButtonArea.vue";
import { storeSwitch } from "@/js/store/store-switch";
import SdflUsePrcsPrfmncChgDscntPopup from "@/views/prfmnc-rpt/dtl/components/popup/SdflUsePrcsPrfmncChgDscntPopup.vue";

const CODE = {
  PRODUCT: "MBR010",
  ACCEPTANCE_DIFFERENCE_REASON: "COM450", // 인계/인수차이 사유코드
};
const ROUTE = {
  PERFORMANCE_LIST: "/prfmnc-rpt/PrfmncRpt"
};
const API = {
  USE_PERFORMANCE: "/prfmnc/sdflUse",
  USE_PROCESS_PERFORMANCE_CHANGE_CHECK: "/prfmnc/sdflUse/prcs-chg-chck",
}

export default {
  props: ['prfmncBasicInfo', 'sdflCrynInfo', 'sdflInputInfo', 'sdflPrcsInfo', 'usePrfmncInfo', 'ntslInfo'],
  components: {
    SdflUsePrcsPrfmncChgDscntPopup,
    commonButtonArea,
    RjctRsnArea,
    PrfmncMdfcnPrmArea,
    SbGrid,
  },
  data() {
    return {
      sdflCrynGridReload: false,
      sdflCrynGridObject: null,
      sdflCrynDataSource: [],
      sdflCrynGridColumns: [],
      sdflCrynGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        editable: {eventType: 'mouseup'},
        excelExport: {
          fileName: '고형연료제품 반입 현황(톤/월).xlsx',
          includeHeader: true,
          adjustCaption: (value) => {
            return value?.toString().includes('<br>') ? value.replace(/<br\s*\/?>/gi, '\n') : value;
          },
        },
        captionHeight: 40
      },
      sdflInputGridReload: false,
      sdflInputGridObject: null,
      sdflInputDataSource: [],
      sdflInputGridColumns: [],
      sdflInputGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        editable: {eventType: 'mouseup'},
        toolBar: [],
      },
      sdflPrcsGridReload: false,
      sdflPrcsGridObject: null,
      sdflPrcsDataSource: [],
      sdflPrcsGridColumns: [],
      sdflPrcsGridAttr: {
        showRowNo: false,
        pageable: false,
        editable: {eventType: 'mouseup'},
        toolBar: [],
      },
      newUsePrfmncInfo: {},
      ntslGridReload: false,
      ntslGridObject: null,
      ntslDataSource: [],
      ntslGridColumns: [],
      ntslGridAttr: {
        showRowNo: false,
        pageable: false,
        editable: {eventType: 'mouseup'},
        mergeRow: true,
        toolBar: [],
      },
      prdctItemCdList: [],
      hnovAcptnDiffRsnCdList: [],
    }
  },
  computed: {
  },
  watch: {
    sdflCrynInfo() {
      this.initializeSdflCryn();
    },
    sdflInputInfo() {
      this.initializeSdflInput();
    },
    sdflPrcsInfo() {
      this.initializeSdflPrcs();
    },
    usePrfmncInfo(newValue) {
      this.newUsePrfmncInfo = newValue;
    },
    ntslInfo() {
      this.initializeNtsl();
    }
  },
  created() {
    this.initialize();
  },
  mounted() {
  },
  methods: {
    initialize() {
      this.loadPrdctItemCd();
      this.loadHnovAcptnDiffRsnCd();
    },
    loadPrdctItemCd() {
      const prdctItemCdList = this.$store.getters.getCodeList({ groupCd: CODE.PRODUCT, useYn: 'Y', frstRefVl: "SRF" });
      this.prdctItemCdList = prdctItemCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    loadHnovAcptnDiffRsnCd() {
      const hnovAcptnDiffRsnCdList = this.$store.getters.getCodeList({ groupCd: CODE.ACCEPTANCE_DIFFERENCE_REASON, useYn: 'Y' });
      this.hnovAcptnDiffRsnCdList = hnovAcptnDiffRsnCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    initializeSdflCryn() {
      this.sdflCrynDataSource = this.sdflCrynInfo.list;
      this.initializeSdflCrynGridColumns();
      this.initializeSdflCrynGridAttr();
      this.sdflCrynGridReload = true;
    },
    initializeSdflInput() {
      this.sdflInputDataSource = this.sdflInputInfo.list;
      this.initializeSdflInputGridColumns();
      this.initializeSdflInputGridAttr();
      this.sdflInputGridReload = true;
    },
    initializeSdflPrcs() {
      this.sdflPrcsDataSource = { data: this.sdflPrcsInfo.list, schema: { id: 'prdctItemCd'}};
      this.initializeSdflPrcsGridColumns();
      this.initializeSdflPrcsGridAttr();
      this.sdflPrcsGridReload = true;
    },
    initializeNtsl() {
      this.ntslDataSource = this.ntslInfo.list;
      this.initializeNtslGridColumns();
      this.initializeNtslGridAttr();
      this.ntslGridReload = true;
    },
    initializeSdflCrynGridColumns() {
      const list = this.sdflCrynInfo.list;
      const uniquePrdctItemCdList = [...new Set(list.map(item => item.prdctItemCd))];
      const filteredPrdctItemList = this.prdctItemCdList
          .filter(item => uniquePrdctItemCdList.includes(item.value));

      const prdctItemList = filteredPrdctItemList.length === 1
          ? []
          : filteredPrdctItemList.map(item => ({ text: item.text, value: item.value }));

      this.sdflCrynGridColumns = [
        { field: 'bplcId', caption: '사업장아이디', visible: false, editable: false },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        { field: 'ctpvStdgNm', caption: '시·도', width: 100, mergeRow: true, editable: false, total: { headerCss:'grid-header' } },
        { field: 'sggStdgNm', caption: '시·군·구', width: 100, editable: false,
          mergeRow: (data) => data.ctpvStdgCd + "." + data.sggStdgCd,
          total: { headerCss:'grid-header' }
        },
        { field: 'bplcKndNm', caption: '사업장종류', width: 100, editable: false,
          mergeRow: (data) => data.ctpvStdgCd + "." + data.sggStdgCd + "." + data.bplcKndNm,
          total: { headerCss:'grid-header' }
        },
        { field: 'bplcNm', caption: '사업장명', width: 200, part: 'sticky', editable: false, align: 'left', total: { headerCss:'grid-header' } },
        { field: 'bzentyNm', caption: '업체명', width: 200, editable: false, align: 'left', total: { headerCss:'grid-header' } },
        { field: 'prdctItemCd', caption: '제품품목코드', visible: false, editable: false },
        { field: 'prdctItemNm', caption: '구분', width: 120, editable: false,
          total: {
            aggregates: [],
            header: [
              { template: '합계', align: 'right' },
              ...prdctItemList.map(item => ({ template: item.text, align: 'right' }))
            ],
            headerCss:'grid-header',
          }
        },
        { field: 'realtmPrdctHndqty', caption: '실시간인계량(톤)<br>(A)', width: 100, editable: false,
          type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', colCss: 'grid-disabled',
          total: {
            aggregates: [
              {func: 'sum', require: 'realtmPrdctHndqty'},
              ...prdctItemList.map(item => ({
                func: (items) => items
                    .filter(entry => entry.prdctItemCd === item.value)
                    .reduce((sum, entry) => sum + (entry.realtmPrdctHndqty || 0), 0),
                field: `realtmPrdctHndqty${item.value}`
              }))
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00' },
              ...prdctItemList.map(item => ({
                template: `{{realtmPrdctHndqty${item.value}}}`, format: '#,##0.00'
              }))
            ],
            headerCss:'grid-header',
          },
        },
        ...(this.prfmncBasicInfo.prfmncSttsCd !== 'NEW' ? [{
          field: 'prdctHndqty', caption: '저장된인계량(톤)', width: 100, editable: false,
          type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', colCss: 'grid-disabled',
          total: {
            aggregates: [
              { func: 'sum', require: 'prdctHndqty' },
              ...prdctItemList.map(item => ({
                func: (items) => items
                    .filter(entry => entry.prdctItemCd === item.value)
                    .reduce((sum, entry) => sum + (entry.prdctHndqty || 0), 0),
                field: `prdctHndqty${item.value}`
              }))
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00' },
              ...prdctItemList.map(item => ({
                template: `{{prdctHndqty${item.value}}}`, format: '#,##0.00'
              }))
            ],
            headerCss:'grid-header',
          },
        }] : []),
        { field: 'prdctAcpqty', caption: '인수량(톤)<br>(B)', width: 100,
          type: 'number', dataType: 'number', align: 'right', format: '#,##0.00',
          total: {
            aggregates: [
              {func: 'sum', require: 'prdctAcpqty', nullAs: 0},
              ...prdctItemList.map(item => ({
                func: (items) => items
                    .filter(entry => entry.prdctItemCd === item.value)
                    .reduce((sum, entry) => sum + (entry.prdctAcpqty || 0), 0),
                field: `prdctAcpqty${item.value}`
              }))
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00' },
              ...prdctItemList.map(item => ({
                template: `{{prdctAcpqty${item.value}}}`, format: '#,##0.00'
              }))
            ],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctDscdCrtqty', caption: '폐기반출량(톤)', width: 100,
          type: 'number', dataType: 'number', align: 'right', format: '#,##0.00',
          total: {
            aggregates: [
              {func: 'sum', require: 'prdctDscdCrtqty', nullAs: 0},
              ...prdctItemList.map(item => ({
                func: (items) => items
                    .filter(entry => entry.prdctItemCd === item.value)
                    .reduce((sum, entry) => sum + (entry.prdctDscdCrtqty || 0), 0),
                field: `prdctDscdCrtqty${item.value}`
              }))
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00' },
              ...prdctItemList.map(item => ({
                template: `{{prdctDscdCrtqty${item.value}}}`, format: '#,##0.00'
              }))
            ],
            headerCss:'grid-header',
          },
        },
        { field: 'diff', caption: '차이량(톤)<br>(A - B)', width: 150, editable: false,
          type: 'number', dataType: 'number', align: 'right', format: '#,##0.00',
          total: {
            aggregates: [
              {
                func: (items) => {
                  const totalRealtmPrdctHndqty = items.reduce((sum, item) => sum + (item.realtmPrdctHndqty || 0), 0);
                  const totalPrdctAcpqty = items.reduce((sum, item) => sum + (item.prdctAcpqty || 0), 0);
                  return Math.abs(utils.round2(totalRealtmPrdctHndqty - totalPrdctAcpqty));
                  // const totalPrdctDscdCrtqty = items.reduce((sum, item) => sum + (item.prdctDscdCrtqty || 0), 0);
                  // return utils.round2(totalRealtmPrdctHndqty - totalPrdctAcpqty - totalPrdctDscdCrtqty);
                },
                field: 'diffTotal'
              },
              ...prdctItemList.map(item => ({
                func: (items) => {
                  const filteredItems = items.filter(entry => entry.prdctItemCd === item.value);

                  const totalRealtmPrdctHndqty = filteredItems.reduce((sum, entry) => sum + (entry.realtmPrdctHndqty || 0), 0);
                  const totalPrdctAcpqty = filteredItems.reduce((sum, entry) => sum + (entry.prdctAcpqty || 0), 0);
                  console.log("totalRealtmPrdctHndqty : " + totalRealtmPrdctHndqty);
                  console.log("totalPrdctAcpqty : " + totalPrdctAcpqty);
                  return Math.abs(utils.round2(totalRealtmPrdctHndqty - totalPrdctAcpqty));
                  // const totalPrdctDscdCrtqty = filteredItems.reduce((sum, entry) => sum + (entry.prdctDscdCrtqty || 0), 0);
                  // return utils.round2(totalRealtmPrdctHndqty - totalPrdctAcpqty - totalPrdctDscdCrtqty);
                },
                field: `diff${item.value}`
              }))
            ],
            header: [
              { template: '{{diffTotal}}', format: '#,##0.00' },
              ...prdctItemList.map(item => ({
                template: `{{diff${item.value}}}`, format: '#,##0.00'
              }))
            ],
            headerCss:'grid-header',
          },
          getValue: (value, field, rowItem) => {
            const realtmPrdctHndqty = rowItem.data.realtmPrdctHndqty || 0
            const prdctAcpqty = rowItem.data.prdctAcpqty || 0;
            const difference = Math.abs(utils.round2(realtmPrdctHndqty - prdctAcpqty));
            // const prdctDscdCrtqty = rowItem.data.prdctDscdCrtqty || 0;
            // const difference = utils.round2(realtmPrdctHndqty - prdctAcpqty - prdctDscdCrtqty);

            return difference !== 0 ? difference : '';
          },
          colCss: (data) => {
            const realtmPrdctHndqty = data.realtmPrdctHndqty || 0
            const prdctAcpqty = data.prdctAcpqty || 0;
            // 음수, 양수에 따른 클래스 처리로 인해 절대값처리 x
            const difference = utils.round2(realtmPrdctHndqty - prdctAcpqty);
            // const prdctDscdCrtqty = data.prdctDscdCrtqty || 0;
            // const difference = utils.round2(realtmPrdctHndqty - prdctAcpqty - prdctDscdCrtqty);

            if (difference !== 0) {
              if(difference < 0){
                return 'grid-danger';
              }else{
                return 'grid-warning';
              }
            } else {
              return 'grid-disabled';
            }
          }
        },
        { field: 'hnovAcptnDiffRsnCd', caption: '비고(사유)', width: 184, align: 'left',
          type: 'combo',
          items: this.hnovAcptnDiffRsnCdList,
          comboLabel: 'text',
          comboValue: 'value',
          total: { headerCss:'grid-header' },
        },
        { field: 'hnovAcptnDiffRmrkCn', caption: '기타내용', width: 200, align: 'left', total: { headerCss:'grid-header' } },
      ]
    },
    initializeSdflCrynGridAttr() {
      const itemCount = this.sdflCrynInfo.list.length;
      let uniqueItemCount = new Set(this.sdflCrynInfo.list.map(item => item.prdctItemCd)).size;
      uniqueItemCount = uniqueItemCount === 1 ? 0 : uniqueItemCount;

      this.sdflCrynGridAttr.height = Math.max(((itemCount + uniqueItemCount) * 30) + 150, 150);
    },
    initializeSdflInputGridColumns() {
      this.sdflInputGridColumns = [
        { field: 'fcltSqno', caption: '시설순번', visible: false, editable: false },
        { field: 'fcltNm', caption: '시설명', minWidth: 100, maxWidth: 1000, width: 100, unit: '%', mergeRow: true, editable: false, align: 'left', total: { headerCss:'grid-header' } },
        { field: 'prdctItemCd', caption: '제품품목코드', visible: false, editable: false },
        { field: 'prdctItemNm', caption: '고형연료제품<br>종류', width: 150, editable: false,
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          }
        },
        { field: 'prdctInpqty', caption: '고형연료제품<br>투입량(톤)', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          total: {
            aggregates: [{func: 'sum', require: 'prdctInpqty', nullAs: 0}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctLcp', caption: '저위발열량<br>(kcal/kg)', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          editable : (data) => { return (data.prdctInpqty || 0) > 0 },
          total: {
            aggregates: [
              { func: (items) => {
                  const filteredItems = items.filter(item => item.prdctLcp > 0);
                  const sumPrdctInpqty = filteredItems.reduce((total, item) => total + (item.prdctInpqty || 0), 0);
                  const sumPrdqtyLcp = filteredItems.reduce((total, item) => {
                    const prdctInpqty = item.prdctInpqty || 0;
                    const prdctLcp = item.prdctLcp || 0;
                    return total + (prdctInpqty * prdctLcp);
                  }, 0);

                  return sumPrdctInpqty > 0 ? utils.round2(sumPrdqtyLcp / sumPrdctInpqty) : 0;
                },
                field: 'prdctLcpAvg'
              }
            ],
            header: [{ template: '{{prdctLcpAvg}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { caption: '에너지생산량',
          columns: [
            { field: 'stPrdqty', caption: '스팀(st/톤)', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
              captionCss:'gridh-bg-vapor',
              editable : true,/*(data) => { return (data.prdctInpqty || 0) > 0 }, 25-01-14 KJM 고객 요청으로 수정가능하도록 변경함. */
              total: {
                aggregates: [{func: 'sum', require: 'stPrdqty', nullAs: 0}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'prvtmPrdqty', caption: '전기(MWh)', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
              captionCss:'gridh-bg-electricity',
              editable : true,/*(data) => { return (data.prdctInpqty || 0) > 0 }, 25-01-14 KJM 고객 요청으로 수정가능하도록 변경함. */
              total: {
                aggregates: [{func: 'sum', require: 'prvtmPrdqty', nullAs: 0}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
          ]
        },
      ];
    },
    initializeSdflInputGridAttr() {
      const itemCount = this.sdflInputInfo.list.length;

      this.sdflInputGridAttr.height = Math.max((itemCount  * 30) + 120, 150);
    },
    initializeSdflPrcsGridColumns() {
      this.sdflPrcsGridColumns = [
        { field: 'prdctItemCd', caption: '제품품목코드', visible: false },
        { field: 'prdctItemNm', caption: '고형연료제품 종류', minWidth: 100, maxWidth: 1000, width: 100, unit: '%', editable: false,
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          }
        },
        { field: 'prdctCrfqty', caption: '이월량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          editable: false, colCss: 'grid-disabled',
          total: {
            aggregates: [{func: 'sum', require: 'prdctCrfqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctSumAcpqty', caption: '인수량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          editable: false, colCss: 'grid-disabled',
          total: {
            aggregates: [{func: 'sum', require: 'prdctSumAcpqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctSumInpqty', caption: '투입량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          editable: false, colCss: 'grid-disabled',
          total: {
            aggregates: [{func: 'sum', require: 'prdctSumInpqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctRsdqty', caption: '잔재물(톤)', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          total: {
            aggregates: [{func: 'sum', require: 'prdctRsdqty', nullAs: 0}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctRmnqty', caption: '잔량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          editable: false,
          total: {
            aggregates: [{
              func: (items) => {
                const totalPrdctCrfqty = items.reduce((sum, item) => sum + (item.prdctCrfqty || 0), 0);
                const totalPrdctSumAcpqty = items.reduce((sum, item) => sum + (item.prdctSumAcpqty || 0), 0);
                const totalPrdctSumInpqty = items.reduce((sum, item) => sum + (item.prdctSumInpqty || 0), 0);
                const totalPrdctRsdqty = items.reduce((sum, item) => sum + (item.prdctRsdqty || 0), 0);
                const totalPrdctRmnqty = totalPrdctCrfqty + totalPrdctSumAcpqty - totalPrdctSumInpqty - totalPrdctRsdqty;
                return utils.round2(totalPrdctRmnqty);
              },
              field: 'prdctRmnqtyTotal'
            }],
            header: [{ template: '{{prdctRmnqtyTotal}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
          getValue: (value, field, rowItem) => {
            const prdctCrfqty = rowItem.data.prdctCrfqty || 0;
            const prdctSumAcpqty = rowItem.data.prdctSumAcpqty || 0
            const prdctSumInpqty = rowItem.data.prdctSumInpqty || 0;
            const prdctRsdqty = rowItem.data.prdctRsdqty || 0;
            const prdctRmnqty = prdctCrfqty + prdctSumAcpqty - prdctSumInpqty - prdctRsdqty;
            return utils.round2(prdctRmnqty)
          },
          colCss: (data) => {
            const prdctCrfqty = data.prdctCrfqty || 0;
            const prdctSumAcpqty = data.prdctSumAcpqty || 0
            const prdctSumInpqty = data.prdctSumInpqty || 0;
            const prdctRsdqty = data.prdctRsdqty || 0;
            const prdctRmnqty = utils.round2(prdctCrfqty + prdctSumAcpqty - prdctSumInpqty - prdctRsdqty);

            const epsilon = 1e-10; // 허용 오차 설정
            if (prdctRmnqty < -epsilon) {
              return 'grid-danger';
            } else {
              return 'grid-disabled';
            }
          }
        },
      ]
    },
    initializeSdflPrcsGridAttr() {
      const itemCount = this.sdflPrcsInfo.list.length;

      this.sdflPrcsGridAttr.height = Math.max((itemCount  * 30) + 90, 150);
    },
    initializeNtslGridColumns() {
      this.ntslGridColumns = [
        { field: 'eneBzentySn', caption: '에너지공급업체일련번호', visible: false, editable: false },
        { field: 'useNtslSqno', caption: '사용판매순번', visible: false, editable: false },
        { field: 'ctpvStdgNm', caption: '시·도', width: 120, mergeRow: true, editable: false, total: { headerCss:'grid-header' } },
        { field: 'sggStdgNm', caption: '시·군·구', width: 120, mergeRow: true, editable: false, total: { headerCss:'grid-header' } },
        { field: 'bzentyNm', caption: '판매업체명', minWidth: 100, maxWidth: 1000, width: 100, unit: '%', editable: false, align: 'left',
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          }
        },
        { caption: '외부판매량',
          columns: [
            { field: 'stNsqty', caption: '스팀(st/톤)', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
              captionCss:'gridh-bg-vapor',
              total: {
                aggregates: [{func: 'sum', require: 'stNsqty', nullAs: 0}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'prvtmNsqty', caption: '전기(MWh)', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
              captionCss:'gridh-bg-electricity',
              total: {
                aggregates: [{func: 'sum', require: 'prvtmNsqty', nullAs: 0}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
          ]
        }
      ]
    },
    initializeNtslGridAttr() {
      const itemCount = this.ntslInfo.list.length;

      this.ntslGridAttr.height = Math.max((itemCount  * 30) + 110, 150);
    },
    sdflCrynGridLoaded() {
      this.sdflCrynGridReload = false;
    },
    sdflInputGridLoaded() {
      this.sdflInputGridReload = false;
    },
    sdflPrcsGridLoaded() {
      this.sdflPrcsGridReload = false;
    },
    ntslGridLoaded() {
      this.ntslGridReload = false;
    },
    hndqtyAplcn() {
      const data = window.SBGrid3.getAllItems(this.sdflCrynGridObject);

      const filteredData = data
          .filter(item => item.realtmPrdctHndqty && item.realtmPrdctHndqty !== 0)
          .map(item => ({
            key: item._key_,
            field: 'prdctAcpqty',
            value: item.realtmPrdctHndqty
          }));

      if (filteredData.length === 0) {
        alert("인계량을 적용할 데이터가 없습니다.");
        return;
      }

      window.SBGrid3.setValues(this.sdflCrynGridObject, filteredData);
    },
    changeSdflCryn(values) {
      values.forEach(item => {
        if (item.field === 'prdctAcpqty') {
          this.setPrdctSumAcpqty(item.key);
        }
      });
    },
    changeSdflInput(values) {
      values.forEach(item => {
        if (item.field === 'prdctInpqty') {
          this.setPrdctInpqty(item.key);
        } else if (['stPrdqty', 'prvtmPrdqty'].includes(item.field)) {
          this.setSumPrdqty(item.field);
        }
      });
    },
    changeNtsl(values) {
      values.forEach(item => {
        this.setSumNsQty(item.field);
      });
    },
    setPrdctInpqty(key) {
      const rowData = window.SBGrid3.getRowData(this.sdflInputGridObject, key);
      const prdctItemCd = rowData.prdctItemCd;

      const allItems = window.SBGrid3.getAllItems(this.sdflInputGridObject);
      const prdctSumInpqty = allItems
          .filter(item => item.prdctItemCd === prdctItemCd)
          .reduce((sum, item) => sum + (item.prdctInpqty || 0), 0);

      window.SBGrid3.setValue(this.sdflPrcsGridObject, prdctItemCd, prdctSumInpqty, 'prdctSumInpqty');
    },
    setPrdctSumAcpqty(key) {
      const rowData = window.SBGrid3.getRowData(this.sdflCrynGridObject, key);
      const prdctItemCd = rowData.prdctItemCd;

      const allItems = window.SBGrid3.getAllItems(this.sdflCrynGridObject);
      const prdctSumAcpqty = allItems
          .filter(item => item.prdctItemCd === prdctItemCd)
          .reduce((sum, item) => sum + (item.prdctAcpqty || 0), 0);

      window.SBGrid3.setValue(this.sdflPrcsGridObject, prdctItemCd, prdctSumAcpqty, 'prdctSumAcpqty');
    },
    setSumPrdqty(field) {
      const allItems = window.SBGrid3.getAllItems(this.sdflInputGridObject);

      if (field === 'stPrdqty') {
        this.newUsePrfmncInfo.stSumPrdqty = parseFloat(allItems.reduce((sum, item) => sum + (item.stPrdqty || 0), 0)).toFixed(2);
      } else if (field === 'prvtmPrdqty') {
        this.newUsePrfmncInfo.prvtmSumPrdqty = parseFloat(allItems.reduce((sum, item) => sum + (item.prvtmPrdqty || 0), 0)).toFixed(2);
      }
    },
    setSumNsQty(field) {
      const allItems = window.SBGrid3.getAllItems(this.ntslGridObject);

      if (field === 'stNsqty') {
        this.newUsePrfmncInfo.stSumNsqty = parseFloat(allItems.reduce((sum, item) => sum + (item.stNsqty || 0), 0)).toFixed(2);
      } else if (field === 'prvtmNsqty') {
        this.newUsePrfmncInfo.prvtmSumNsqty = parseFloat(allItems.reduce((sum, item) => sum + (item.prvtmNsqty || 0), 0)).toFixed(2);
      }
    },
    toList() {
      this.$router.push(ROUTE.PERFORMANCE_LIST);
    },
    async save() {
      if (!confirm("저장하시겠습니까?")) {
        return;
      }

      const validationMessage = this.validate(true);
      let prfmncSttsCd = "IPG"; // 기본 상태 코드

      if (validationMessage) {
        alert(validationMessage);
        return;
      } else {
        if (confirm("필수입력 조건이 만족하여 제출가능한상태입니다.\n제출하시겠습니까?")) {
          prfmncSttsCd = "SUB";
        }
      }

      try {
        const result = await this.checkUsePrcsPrfmc(prfmncSttsCd);

        if (result.change) {
          storeSwitch.on('SdflUsePrcsPrfmncChgDscntPopup', result.list);
          return;
        }

        this.saveConfirmed(prfmncSttsCd);

      } catch (e) {
        alert("오류가 발생했습니다.");
      }
    },
    async submission() {
      if (!confirm("제출하시겠습니까?")) {
        return;
      }

      const validationMessage = this.validate(true);
      if (validationMessage) {
        alert(validationMessage);
        return;
      }

      try {
        const result = await this.checkUsePrcsPrfmc();

        if (result.change) {
          storeSwitch.on('SdflUsePrcsPrfmncChgDscntPopup', result.list);
          return;
        }

        this.saveConfirmed("SUB");

      } catch (e) {
        alert("오류가 발생했습니다.");
      }
    },
    validate(focusOnError = false) {
      const allSdflCrynItems = window.SBGrid3.getAllItems(this.sdflCrynGridObject);
      const totalPrdctAcpqty = allSdflCrynItems.reduce((sum, item) => { return sum + (item.prdctAcpqty || 0); }, 0);
      if (!totalPrdctAcpqty) {
        if (focusOnError) {
          this.$refs.sdflCrynTitle.focus();
        }
        return "고형연료제품 반입 정보를 입력해 주세요.";
      }

      const allSdflInputItems = window.SBGrid3.getAllItems(this.sdflInputGridObject);
      const totalPrdctInpqty = allSdflInputItems.reduce((sum, item) => { return sum + (item.prdctInpqty || 0); }, 0);

      // if (!totalPrdctInpqty) {
      //2025.02.11 반입만 있고 투입이 없는 경우도 있을 수 있으니, 반입만 있어도 저장 및 제출가능하도록 요청 (요청자 : 이향경 주임)
      if (totalPrdctInpqty == null) {
        if (focusOnError) {
          this.$refs.sdflInputTitle.focus();
        }
        return "고형연료제품 투입 정보를 입력해 주세요.";
      }

      const totalEnePrdqty = allSdflInputItems.reduce((sum, item) => {
        return sum + (item.stPrdqty || 0) + (item.prvtmPrdqty || 0);
      }, 0);
      // if (!totalEnePrdqty) {
      if (totalEnePrdqty == null) {
        if (focusOnError) {
          this.$refs.sdflInputTitle.focus();
        }
        return "에너지 생산량 정보를 입력해 주세요.";
      }

      const allPrcsItems = window.SBGrid3.getAllItems(this.sdflPrcsGridObject);
      for (const item of allPrcsItems) {
        // 각 항목별 잔여량 계산 및 확인
        const value = (item.prdctCrfqty || 0) + (item.prdctSumAcpqty || 0) - (item.prdctSumInpqty || 0) - (item.prdctRsdqty || 0);
        const prdctSumRmnqty = utils.round2(value);
        if (prdctSumRmnqty < 0) {
          if (focusOnError) {
            this.$refs.sdflPrcsTitle.focus();
            const rowItem= window.SBGrid3.getRow(this.sdflPrcsGridObject, item._key_);
            const column = window.SBGrid3.getColumnByField(this.sdflPrcsGridObject, 'prdctItemNm');

            window.SBGrid3.moveFocus(this.sdflPrcsGridObject, rowItem, column);
          }

          return "투입량(+잔재물)이 인수량(+이월량)보다 초과할 수 없습니다.";
        }
      }

      const allNtslItems = window.SBGrid3.getAllItems(this.ntslGridObject);
      const stSumPrdqty = allSdflInputItems.reduce((sum, item) => sum + (item.stPrdqty || 0), 0); // 스팀 생산량
      const stUsqty = this.newUsePrfmncInfo.stUsqty || 0; // 스팀 자체사용량
      const stSumNsqty = allNtslItems.reduce((sum, item) => sum + (item.stNsqty || 0), 0); // 스팀 판매량
      const stDifference = stSumPrdqty - stUsqty - stSumNsqty;
      if (stDifference < 0) {
        if (focusOnError) {
          this.$refs.sdflNtslTitle.focus();
        }
        return "스팀 이용량(자체사용+외부판매)은 생산량보다 초과할 수 없습니다.";
      }

      const prvtmSumPrdqty = allSdflInputItems.reduce((sum, item) => sum + (item.prvtmPrdqty || 0), 0); // 전기 생산량
      const prvtmSumUsqty = this.newUsePrfmncInfo.prvtmSumUsqty || 0; // 전기 자체사용량
      const prvtmSumNsqty = allNtslItems.reduce((sum, item) => sum + (item.prvtmNsqty || 0), 0); // 전기 판매량
      const prvtmDifference = prvtmSumPrdqty - prvtmSumUsqty - prvtmSumNsqty;
      if (prvtmDifference < 0) {
        if (focusOnError) {
          this.$refs.sdflNtslTitle.focus();
        }
        return "전기 이용량(자체사용+외부판매)은 생산량보다 초과할 수 없습니다.";
      }

      return "";
    },
    checkUsePrcsPrfmc(prfmncSttsCd) {
      return new Promise((resolve, reject) => {
        const allPrcsItems = window.SBGrid3.getAllItems(this.sdflPrcsGridObject);
        const prdctSumCrfqty = allPrcsItems.reduce((sum, item) => { return sum + (item.prdctCrfqty || 0); }, 0);

        const totalPrdctCrfqty = allPrcsItems.reduce((sum, item) => sum + (item.prdctCrfqty || 0), 0);
        const totalPrdctSumAcpqty = allPrcsItems.reduce((sum, item) => sum + (item.prdctSumAcpqty || 0), 0);
        const totalPrdctSumInpqty = allPrcsItems.reduce((sum, item) => sum + (item.prdctSumInpqty || 0), 0);
        const totalPrdctRsdqty = allPrcsItems.reduce((sum, item) => sum + (item.prdctRsdqty || 0), 0);
        const prdctSumRmnqty = utils.round2(totalPrdctCrfqty + totalPrdctSumAcpqty - totalPrdctSumInpqty - totalPrdctRsdqty);

        this.$apiCall.post(
            API.USE_PROCESS_PERFORMANCE_CHANGE_CHECK,
            {
              bplcId: this.prfmncBasicInfo.bplcId,
              prfmncYm: this.prfmncBasicInfo.prfmncYr + this.prfmncBasicInfo.prfmncMm,
              prdctSumCrfqty: prdctSumCrfqty,
              prdctSumRmnqty: prdctSumRmnqty,
              updatePrfmncSttsCd: prfmncSttsCd,
            },
            (data) => {
              resolve(data.result);
            },
            () => {
              alert("제출에 실패하였습니다.");
              reject();
            }
        );
      });
    },
    saveConfirmed(prfmncSttsCd, changeList = []) {
      const { updated: sdflCrynUpdated } = window.SBGrid3.getSaveData(this.sdflCrynGridObject, false, true, false);
      const { updated: sdflInputUpdated } = window.SBGrid3.getSaveData(this.sdflInputGridObject, false, true, false);
      const { updated: ntslUpdated } = window.SBGrid3.getSaveData(this.ntslGridObject, false, true, false);
      const { updated: sdflPrcsUpdated } = window.SBGrid3.getSaveData(this.sdflPrcsGridObject, false, true, false);
      const prcsAllItems = window.SBGrid3.getAllItems(this.sdflPrcsGridObject)
      const updatedItemCodes = sdflPrcsUpdated.map(item => item.prdctItemCd); // 수정된 목록의 제품종류코드
      // 이월량이 0보다 크고 수정된 목록에 없는 데이터 필터링
      const itemsToAdd = prcsAllItems.filter(item =>
          (item.prdctCrfqty || 0) > 0 && !updatedItemCodes.includes(item.prdctItemCd)
      );
      // 변경되지 않았더라도 이월량이 있는경우 데이터 유지를 위해 추가
      itemsToAdd.forEach(item => {
        sdflPrcsUpdated.push(item);
      });

      const param = {
        prfmncId: this.prfmncBasicInfo.prfmncId,
        bplcId: this.prfmncBasicInfo.bplcId,
        prfmncYm: this.prfmncBasicInfo.prfmncYr + this.prfmncBasicInfo.prfmncMm,
        prfmncSttsCd,
        useCrynList: sdflCrynUpdated,
        useInputList: sdflInputUpdated,
        usePrcsList: sdflPrcsUpdated,
        usePrfmncInfo: this.newUsePrfmncInfo,
        useNtslList: ntslUpdated,
        changeList,
      };

      const prfmncSttsNm = prfmncSttsCd === "SUB" ? "제출" : "저장";

      this.$apiCall.post(
          API.USE_PERFORMANCE,
          param,
          (data) => {
            alert(`${prfmncSttsNm}이 완료되었습니다.`);
            storeSwitch.off('SdflUsePrcsPrfmncChgDscntPopup');

            if (prfmncSttsCd === "SUB") {
              this.toList();
            } else {
              this.$router.push({path : "/prfmnc-rpt/dtl/SdflUse", query: {prfmncId: data.result.prfmncId}})
                .then(() => {
                  location.reload();
                })
                .catch(() => {
                  console.error("Navigation Error");
                });
            }
          },
          () => {
            alert(`${prfmncSttsNm}에 실패하였습니다.`);
          }
      );
    },
    formatNumber(number) {
      return utils.formatNumberWithComma(number);
    },
  }
}
</script>

<style scoped>
  .grid_box { margin-bottom: 5px; }
</style>