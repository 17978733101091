<template>
  <h2 class="title1 mt_0">관련 법률 안내</h2>
  <p class="law_txt">한국환경공단은 <strong>『고형연료제품의 수입품질검사 관련 법률』</strong>에 근거하여 <span>폐자원에너지 종합정보관리시스템을 운영합니다.</span></p>
  <div class="law_box">
    <ul>
      <li>「자원의 절약과 재활용촉진에 관한 법률」제25조의5, 제25조의6 <a href="http://www.law.go.kr/법령/자원의%20절약과%20재활용촉진에%20관한%20법률" target="_blank" title="새창열림" class="btn sm">관련법률 상세보기</a></li>
      <li>「자원의 절약과 재활용촉진에 관한 법률」시행령 <a href="http://www.law.go.kr/법령/자원의%20절약과%20재활용촉진에%20관한%20법률%20시행령" target="_blank" title="새창열림" class="btn sm">관련법률 상세보기</a></li>
      <li>「자원의 절약과 재활용촉진에 관한 법률」시행규칙 제20조의2, 제20조의3, 제20조의4, 제20조의5, 제20조의6 <a href="http://www.law.go.kr/법령/자원의%20절약과%20재활용촉진에%20관한%20법률%20시행규칙" target="_blank" title="새창열림" class="btn sm">관련법률 상세보기</a></li>
      <li>「고형연료제품 품질 시험·분석방법」 <a href="http://www.law.go.kr/행정규칙/고형연료제품품질시험·분석방법" target="_blank" title="새창열림" class="btn sm">관련법률 상세보기</a></li>
      <li>「고형연료제품의 품질검사 절차 및 방법 등에 관한 고시」 <a href="http://www.law.go.kr/행정규칙/고형연료제품의품질검사절차및방법등에관한고시" target="_blank" title="새창열림" class="btn sm">관련법률 상세보기</a></li>
    </ul>
  </div>

  <h2 class="title1">고형연료제품 품질검사 신청 관련서류</h2>
  <div class="tbl_wrap">
    <table>
      <caption>고형연료제품 품질검사 신청 관련서류_NO, 구분, 양식으로 구성</caption>
      <colgroup>
        <col style="width:10%">
        <col style="width:75%">
        <col style="width:15%">
      </colgroup>
      <thead>
      <tr>
        <th scope="col">NO</th>
        <th scope="col">구분</th>
        <th scope="col">양식</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>1</td>
        <td class="txt_left">고형연료제품 품질검사 신청서</td>
        <td>
          <br-static-file-download atchFileVldNo="c880bd07-5002-402c-9f04-0cb6859268cd" />
        </td>
      </tr>
      <tr>
        <td>2</td>
        <td class="txt_left">고형연료제품 품질표시 검사신청서</td>
        <td>
          <br-static-file-download atchFileVldNo="93de8290-3d7b-45e0-a6da-38d7def81598" />
        </td>
      </tr>
      <tr>
        <td>3</td>
        <td class="txt_left">고형연료제품 수입·운반·보관·처리 계획서</td>
        <td>
          <br-static-file-download atchFileVldNo="08c5dfe1-4730-4eca-87c9-29806a8e1220" />
        </td>
      </tr>
      <tr>
        <td>4</td>
        <td class="txt_left">수입품질검사계획·현지자료·보관확인</td>
        <td>
          <br-static-file-download atchFileVldNo="f9b5adee-8395-4f4b-af3d-7118d109e005" />
        </td>
      </tr>
      <tr>
        <td>5</td>
        <td class="txt_left">사업자등록증 사본</td>
        <td></td>
      </tr>
      <tr>
        <td>6</td>
        <td class="txt_left">통장 사본 1부</td>
        <td></td>
      </tr>
      </tbody>
    </table>
  </div>

  <h2 class="title1">수입품질검사 안내</h2>
  <div class="img_box_area mt_0">
    <img src="@/assets/images/sub/importQualityInspection-1.png" alt="1.수입자 신청서(수입 신고 60일 전) 2.접수(수수료 입금) 또는 검토(보완:3일 이내, 반려: 보완 불가 시) 3.시료 채취 계획 수립(시료 채취 계획 보고서 작성 후 5일 이내 통보) 4.시료 채취 및 분석(고형 연료 제품, 품질 시험/분석 방법) 5.품질 검사 결과서 발급(처리 기한 40일) 6.수입자 통보(단위 로트 최대 규모 SRF:1,500톤 / BIO-SRF:2,500톤) 및 지방 환경 관서 통보.">
  </div>
  <div class="img_box_area pt_40" style="border-top:1px dashed var(--border)">
    <img src="@/assets/images/sub/importQualityInspection-2.png" alt="수입 품질 검사 절차 시스템 안내도">
  </div>

  <h2 class="title1">수수료 안내 <span class="s_title">품질검사 / 품질표시검사 / 품질표시 적정성검사</span></h2>
  <div class="tbl_wrap">
    <table>
      <caption>수수료 안내_구분, 부가세 포함으로 구성</caption>
      <colgroup>
        <col style="width:34%">
        <col style="width:33%">
        <col style="width:33%">
      </colgroup>
      <thead>
      <tr>
        <th scope="col" colspan="2">구분</th>
        <th scope="col">부가세 포함</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th scope="row" rowspan="2">품질검사(최초검사)</th>
        <td>SRF</td>
        <td class="txt_right">-</td>
      </tr>
      <tr>
        <td>BIO-SRF</td>
        <td class="txt_right">1,996,500원</td>
      </tr>
      <tr>
        <th scope="row">품질표시검사</th>
        <td colspan="2">품질표시 기관별 수수료기준</td>
      </tr>
      <tr>
        <th scope="row">품질검사(확인검사)</th>
        <td colspan="2">없음</td>
      </tr>
      <tr>
        <th scope="row">품질표시 적정성검사</th>
        <td colspan="2">없음</td>
      </tr>
      </tbody>
    </table>
  </div>
  <dl class="back_info">
    <dt>입금안내</dt>
    <dd><img src="@/assets/images/sub/img_nonghyup.png" alt="농협"><span><strong>계좌번호 : </strong>317-1700-1700-41</span><span><strong>예금주 : </strong>한국환경공단</span></dd>
  </dl>
  <div class="info_box mt_40">
    <strong class="info_title">문의 안내</strong>
    <ul class="dot_list">
      <li>주소 : 인천광역시 서구 환경로 42(경서동 종합환경연구단지) 한국환경공단 폐자원에너지센터 폐자원에너지부</li>
      <li>전화 : 032-590-5411</li>
      <li>메일 : srf-qa@keco.or.kr</li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {
    download() {

    }
  }
}
</script>

<style scoped>

</style>