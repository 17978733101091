<template>

  <template v-if="showDetail">
    <synth-gas-detail
        :prfmncMst="prfmncMst"
        :prfmncSynthGas="prfmncSynthGas"
        :prfmncSynthGasMtralCrynList="prfmncSynthGasMtralCrynList"
        :prfmncSynthGasNtslList="prfmncSynthGasNtslList"
        :showDetail="showDetail"
    />
  </template>
  <template v-else>
    <synth-gas-update
        :prfmncMst="prfmncMst"
        :prfmncSynthGas="prfmncSynthGas"
        :prfmncSynthGasMtralCrynList="prfmncSynthGasMtralCrynList"
        :prfmncSynthGasNtslList="prfmncSynthGasNtslList"
        :showDetail="showDetail"
    />
  </template>

</template>

<script>
import SbGrid from "@/components/SbGrid.vue";
import SynthGasDetail from "@/views/prfmnc-rpt/dtl/synth-gas/SynthGasDetail.vue";
import SynthGasUpdate from "@/views/prfmnc-rpt/dtl/synth-gas/SynthGasUpdate.vue";
import {storeSwitch} from "@/js/store/store-switch";

const API = {
  BUSINESS_PLACE_INFO: "/bplc", // 사업장 정보
  PRFMNC_MST: "/prfmnc",// 실적 공통정보
  CHECK_PERMITTED_BUSINESS: "/prfmnc/chck-prm-bplc",
  FACILITIES_INFO_BY_BUSINESS_PLACE: "/fclt/by-bplc", // 사업장 시설정보
  PRFMNC_CRYN_STTS: "/prfmnc/synthGas/mtralCrynStts/{prfmncId}", // 실적 매립가스 판매반입현황 조회
  SYNTH_GAS_DTL: "/prfmnc/synthGas/{prfmncId}", // 실적 매립가스 상세조회
  PRFMNC_NTSL_STTS: "/prfmnc/synthGas/ntslStts/{prfmncId}", // 실적 매립가스 판매현황 조회
  PRFMNC_DTL_SAVE: "/prfmnc/synthGas", // 실적 매립가스 상세저장
  PERFORMANCE_REGISTER_POSIBILITY_IDENTIFY: "/prfmnc/reg-psblty-idnty", // 실적 신규등록 가능상태 체크
};

const CODE = {
  PERFORMANCE_STATUS: "PFM001", // 실적상태코드
  MODIFICATION_PERMIT_STATUS: "PFM002", // 실적수정허용상태코드
};

const ROUTE = {
  LIST: "/prfmnc-rpt/PrfmncRpt"
};

export default {
  components: {
    SbGrid,
    SynthGasDetail,
    SynthGasUpdate,
  },
  data() {
    return {
      mode: "U", // U: 등록, 수정   R: 상세
      searchParam: {
        prfmncId: "",
        bplcId: "",
        prfmncYm: "",
        prfmncYr: "",
      },
      detailStatusCodes: ['REC', 'APR', 'SUB'],
      prfmncMst:{},
      prfmncFclt:{},
      prfmncSynthGas:{}, // 매립가스실적
      prfmncSynthGasMtralCrynList:[],
      prfmncSynthGasNtslList:[],
    };
  },
  computed: {
    showDetail() {
      return Boolean(this.searchParam.prfmncId) && this.detailStatusCodes.includes(this.prfmncMst.prfmncSttsCd);
    }
  },
  watch: {
    showDetail(newVal) {
      if (newVal) {
        this.mode = 'R';
      } else {
        this.mode = 'U';
      }
    }
  },
  created() {
    const prfmncId = this.$route.query.prfmncId ?? "";
    const bplcId = this.$route.query.bplcId ?? "";
    const prfmncYm = this.$route.query.prfmncYm ?? "";

    if(prfmncId){ // 수정, 상세
      this.searchParam.prfmncId = prfmncId;
    }else{ // 신규
      this.searchParam.prfmncId = prfmncId;
      this.searchParam.bplcId = bplcId ?? "";
      this.prfmncMst.bplcId = bplcId ?? "";
      this.searchParam.prfmncYm = prfmncYm ?? "";
      this.prfmncMst.prfmncYm = prfmncYm ?? "";
      this.searchParam.prfmncYr = prfmncYm?.substring(0, 4) ?? "";
      this.prfmncMst.prfmncYr = prfmncYm?.substring(0, 4) ?? "";
      this.searchParam.prfmncMm = prfmncYm?.substring(4, 6) ?? "";
    }

    this.initialize();
  },
  mounted() {
  },
  methods: {
    async initialize() {
      storeSwitch.on("loading");
      if(!this.showDetail && Boolean(!this.searchParam.prfmncId)) await this.registerChk();
      await this.loadPrfmncSttsCd();
      await this.readBplcInfo();
      await this.readPrfmncMst();

      const result = await this.checkPermittedBplc();
      if (!result) {
        alert("허용되지 않은 사업장입니다.");
        this.$router.push(ROUTE.LIST);
        storeSwitch.off("loading");
        return;
      }

      // bplcKndCd 체크 후 리디렉션
      if (this.prfmncMst.bplcKndCd !== "05-01") {
        this.$router.push(ROUTE.LIST);
        storeSwitch.off("loading");
        return; // 리디렉션 후 나머지 초기화 작업을 중단
      }

      // await this.readPrfmncFclt();
      await this.readPrfmncMtralCryn();
      await this.readPrfmncNtsl();
      await this.readPrfmncDtlSynthGas();
      storeSwitch.off("loading");
    },
    async registerChk() {
      this.$apiCall.post(
          API.PERFORMANCE_REGISTER_POSIBILITY_IDENTIFY,
          this.searchParam,
          () => {},
          (error) => {
            alert(error.message);
            this.$router.push(ROUTE.LIST);
          }
      );
    },
    async loadPrfmncSttsCd() {
      const prfmncSttsList = this.$store.getters.getCodeList({groupCd: CODE.PERFORMANCE_STATUS, useYn: 'Y'});
      this.prfmncSttsList = prfmncSttsList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    // 사업장정보 호출
    async readBplcInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(
            API.BUSINESS_PLACE_INFO,
            this.searchParam,
            (data) => {
              if(data.result) {

                // this.prfmncMst = data.result.prfmncMst;
                this.prfmncMst = {
                  ...this.prfmncMst,
                  bplcId: data.result.bplcId,
                  bplcNm: data.result.bplcNm,
                  bplcKndNm: data.result.bplcKndNm,
                  prfmncSttsCd: "IPG",
                  bplcKndCd: data.result.bplcKndCd,
                  // prfmncYr: this.prfmncYm.substring(0, 4),
                  // prfmncMm: this.prfmncYm.substring(4, 6),
                  // ...this.prfmncMst,
                  // formattedPrfmncYm: this.prfmncMst.prfmncYm.substring(0, 4) + "-" + this.prfmncMst.prfmncYm.substring(4, 6)
                };
                // this.searchParam.bplcId = this.prfmncMst.bplcId;
              }
              resolve(data);
            },
            (error) => {
              new Error("Failed to load readPrfmncMst.");
              reject(error);
            }
        );
      });
    },
    // 실적보고공통정보조회 호출
    async readPrfmncMst() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(
            API.PRFMNC_MST,
            this.searchParam,
            (data) => {
              if(data.result.prfmncMst) {
                this.prfmncMst = data.result.prfmncMst;

                this.searchParam.bplcId = this.prfmncMst.bplcId;
                this.searchParam.prfmncYm = this.prfmncMst.prfmncYm;
              }
              resolve(data);
            },
            (error) => {
              new Error("Failed to load readPrfmncMst.");
              reject(error);
            }
        );
      });
    },
    checkPermittedBplc() {
      return new Promise((resolve) => {
        this.$apiCall.get(API.CHECK_PERMITTED_BUSINESS,
            { bplcId: this.prfmncMst.bplcId },
            data => {
              resolve(data.result);
            },
            () => {
              resolve(false);
            }
        );
      });
    },
    // 실적 시설조회
    async readPrfmncFclt() {
      this.$apiCall.get(
          API.FACILITIES_INFO_BY_BUSINESS_PLACE,
          {
            bplcId: this.prfmncMst.bplcId,
            fcltSttsCd: "01",
            useYn: "Y",
          },
          (data) => {
            this.prfmncFclt = data.result;
          },
          new Error("Failed to load readPrfmncFclt.")
      );
    },
    // 합성가스실적 조회
    async readPrfmncDtlSynthGas() {
      this.$apiCall.get(
          API.SYNTH_GAS_DTL,
          this.searchParam,
          (data) => {
            this.prfmncSynthGas = data.result.synthGasPrfmnc;
          },
          new Error("Failed to load readPrfmncDtlSynthGas.")
      );
    },
    // 판매반입현황 조회
    async readPrfmncMtralCryn() {
      this.$apiCall.get(
          API.PRFMNC_CRYN_STTS,
          {...this.searchParam, mode: this.mode},
          (data) => {
            this.prfmncSynthGasMtralCrynList = data.result;
          },
          new Error("Failed to load readPrfmncMtralCryn.")
      );
    },
    // 메탄가스판매현황 조회
    async readPrfmncNtsl() {
      this.$apiCall.get(
          API.PRFMNC_NTSL_STTS,
          {...this.searchParam, mode: this.mode},
          (data) => {
            this.prfmncSynthGasNtslList = data.result;
          },
          new Error("Failed to load readPrfmncNtsl.")
      );
    },
  }
}
</script>