<template>
  <!-- 업무설명 영역 -->
  <div class="desc_box">
    <i class="icon"><img src="@/assets/images/common/ico_desc.png" alt="이미지"></i>
    <div class="desc">
      <strong class="desc_title">품질검사 현황</strong>
      <ul class="dot_list">
        <li>품질검사 신청 목록이 조회 됩니다.</li>
        <li>검사 완료 시에는 검사결과를 조회 할 수 있습니다.</li>
        <li>신규로 검사를 신청하는 경우에는 "검사신청" 메뉴 클릭하여 신청해 주시기 바랍니다.</li>
        <li>상태가 서류보안의 경우 사유를 확인하시고 자료를 보충하신 후 재 제출 해주시기 바랍니다.</li>
      </ul>
      <div class="btn_area left">
        <a role="link" href="#modalQltyInspAplyGuide" class="btn xsm helper ico_helper modal_open" @click.prevent="openModal('#modalQltyInspAplyGuide')">품질검사안내</a>
      </div>
    </div>
  </div>
  <!-- 화면 타이틀 영역 -->
  <div class="board_info">
    <h2 class="title1">품질(확인)검사 현황</h2>
  </div>
  <!-- 조회조건 영역 -->
  <div class="sch_form_wrap">
    <div class="sch_form row2">
      <span>
          <label>신청일자</label>
          <div class="input_group">
            <br-date-from-to label="신청일자" v-model:fromDate="searchParam.fromDate" v-model:toDate="searchParam.toDate" @change="search" />
          </div>
      </span>
      <!-- <span>
        <label for="">사업장종류</label>
        <br-form-select label="사업장종류" v-model:value="searchParam.bplcKndCd" groupCd="MBR006" @change="search" allSelectable />
      </span> -->
      <span>
        <label for="srchInspSe">검사구분</label>
        <br-form-select label="검사구분" id="srchInspSe" v-model:value="searchParam.inspSeCd" groupCd="COM429" frstRefVl="20" @change="search" allSelectable />
      </span>
      <span>
        <label for="">시설 명</label>
        <br-form-input label="시설명" v-model="searchParam.fcltNm" @enter-keyup="search" />
      </span>
    </div>
    <div class="btn_area">
      <br-anchor-button role="link" label="검색" size="sm" format="sch" @click="search" />
      <br-anchor-button role="link" label="새로고침" size="sm" format="txtN" icon="ico_refresh" @click="reset" />
    </div>
  </div>
  <!-- 그리드 소계 및 버튼 영역 -->
  <div class="board_info">
    <p class="page">
      <span class="total">전체 <strong>{{ this.qltyInspDataCount }}</strong></span>
      <span>페이지 <strong>{{ this.searchParam.page }}/{{ this.lastPage === 0 ? 1 : this.lastPage }}</strong></span>
    </p>
    <div>
      <br-anchor-button role="link" label="엑셀다운로드" size="sm" icon="ico_excel" @click="excelDownload"/>
    </div>
  </div>
  <!-- 그리드 컨텐츠 영역 -->
  <div class="board_list scroll">
    <table>
      <caption>시설검사신청 현황 조회-검사신청번호, 검사구분, 시설 명, 시설종류, 신청일자, 접수일자, 검사(예정)일자, 진행상태, 검사결과로 구성
      </caption>
      <colgroup>
        <col style="width:120px">
        <col style="width:120px">
        <col style="width:120px">
        <col style="width:100px">
        <col style="width:260px">
        <col style="width:180px">
        <col style="width:260px">
        <col style="width:150px">
        <col style="width:150px">
        <col style="width:150px">
        <col style="width:100px">
        <col style="width:100px">
        <col style="width:100px">
      </colgroup>
      <thead>
      <tr>
        <th scope="col">검사신청번호</th>
        <th scope="col">검사결과</th>
        <th scope="col">진행상태</th>
        <th scope="col">검사구분</th>
        <th scope="col">사업장 명</th>
        <th scope="col">사업장종류</th>
        <th scope="col">시설 명</th>
        <th scope="col">신청일자</th>
        <th scope="col">접수일자</th>
        <th scope="col">검사(예정)일자</th>
        <th scope="col">품질표시<br/>검사</th>
        <th scope="col">표시적정성<br/>검사</th>
        <th scope="col">수수료<br/>납부여부</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="qltyInspDataList.length <= 0" >
        <td colspan="12">
          <div style="display: flex; justify-content: center;">
            <div class="no-data-message">조회조건에 해당하는 데이터가 없습니다.</div>
          </div>
        </td>
      </tr>
      <tr v-else v-for="(item, index) in qltyInspDataList" :key="index" @dblclick="goDetail(item.inspAplySn)">
        <td aria-label="검사신청번호">{{ item.inspAplySn }}</td>
        <td aria-label="검사결과"><span v-if="item.inspRsltNm" class="badge" :class="{ bg2: item.inspRsltCd === '20' }">{{ item.inspRsltNm }}</span></td>
        <td aria-label="진행상태"><span v-if="item.inspPrgrsSttsNm" class="badge" :class="{ bg1: item.inspPrgrsSttsCd === '70', bg3: this.isBg3BadgeClass(item.inspPrgrsSttsCd) }">{{ item.inspPrgrsSttsNm }}</span></td>
        <td aria-label="검사구분">{{ item.inspSeNm }}</td>
        <td aria-label="사업장 명" style="text-align: left">{{ item.bplcNm }}</td>
        <td aria-label="사업장종류">{{ item.bplcKndNm }}</td>
        <td aria-label="시설 명" style="text-align: left">{{ item.fcltNm }}</td>
        <td aria-label="신청일자">{{ item.inspAplyYmd }}</td>
        <td aria-label="접수일자">{{ item.inspRcptYmd }}</td>
        <td aria-label="검사(예정)일자">{{ item.inspPrnmntYmd }}</td>
        <td aria-label="품질표시검사"><input type="checkbox" v-model="item.qltyIndctInspYn" true-value="Y" false-value="N" disabled aria-label="품질표시검사" :id="item.inspAplySn+'품질표시검사'"><label :for="item.inspAplySn+'품질표시검사'" style="display: none;">{{ item.inspAplySn }} 품질표시검사</label></td>
        <td aria-label="표시적정성검사"><input type="checkbox" v-model="item.qltyIndctPrprtInspYn" true-value="Y" false-value="N" disabled aria-label="표시적정성검사" :id="item.inspAplySn+'품질적정성검사'"><label :for="item.inspAplySn+'품질적정성검사'" style="display: none;">{{ item.inspAplySn }} 품질적정성검사</label></td>
        <td aria-label="수수료납부여부">{{ item.dpstYnNm }}</td>
      </tr>
      </tbody>
    </table>
  </div>
  <!-- 그리드 페이징 영역 -->
  <insp-pagination :total="qltyInspDataCount" :size="searchParam.size" v-model:page="searchParam.page" v-model:maxPage="lastPage" @clickedPage="search"></insp-pagination>

  <!-- s: modal popup 품질검사안내 -->
  <div :class="['modal', { visible: isModalQltyInspAplyGuideVisible }]" id="modalQltyInspAplyGuide">
    <div class="popup_box" tabindex="0">
      <h1 class="popup_title">품질검사 신청 안내</h1>
      <div class="popup_cont">
        <div class="desc_box progress_box">
          <i class="icon"><img src="@/assets/images/common/ico_noti.png" alt="이미지"></i>
          <div class="progress">
            <div><strong>품질검사</strong>는 폐자원으로 생산한 고형연료의 품질 여부를 검사합니다. <span>아래 절차대로 진행됩니다.</span></div>
          </div>
        </div>
        <h2 class="title1">최초 검사</h2>
        <div class="img_box_area mt_10">
          <img src="@/assets/images/sub/img_info_pop1-1.png" alt="품질 검사 절차">
        </div>

        <h2 class="title1">확인 검사<span class="info_title">정기 검사 시 해당 분기 확인 검사 시료채취 및 분석 결과 활용</span></h2>
        <div class="img_box_area mt_10">
          <img src="@/assets/images/sub/img_info_pop1-2.png" alt="1. 제조자/사용자 고형 연료 제품, 2. 시료 채취 및 계획 수립 검사 예정일 3일 전까지 통보, 3. 시료 채취 및 분석 고형 연료 제품, 품질 실험/분석 방법, 4. 품질 검사 결과서 발급, 5. 지방 자치 단체 통보(부적합 시 통보)">
        </div>
        <div class="btn_area center">
          <button type="button" class="btn md primary" @click="navigateTo('/wstrsc-ene-intro/sfl/QltyInspGd')">자세히 보기</button>
          <button type="button" class="btn md" @click="closeModal">닫기</button>
        </div>
      </div>
      <button type="button" class="modal_close" @click="closeModal">팝업 닫기</button>
    </div>
  </div>
  <!-- e: modal popup 품질검사안내 -->
</template>

<script>
/**
 * 품질검사에 대한 현황 조회 및 검사신청 처리 화면
 *
 * 작성자 : jhkim
 * 작성일 : 10/24/2024
 * 버전 : 1.0
 */
import moment from "moment";
import inspPagination from "@/views/sfli/components/inspPagination.vue";

const API = {
  QLTY_INSP_STAT : "/qlty-insp-stat",
  QLTY_INSP_EXCEL_DOWN : "/qlty-insp-stat/excel-download",
}
const PAGE_SIZE = 10;

export default {
  components: {inspPagination},
  computed : {},
  data() {
    return {
      searchParam: {
        fromDate: moment().startOf('year').format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
        inspSeCd: '',
        bplcKndCd: '',
        fcltNm: '',
        page: 1,
      },
      lastPage: 1,
      qltyInspDataCount: 0,
      qltyInspDataList: [],
      isModalQltyInspAplyGuideVisible: false,
    };
  },
  watch : {},
  created() {},
  mounted() {
    this.initialize();
  },
  beforeMount (){},
  methods : {
    initialize() {
      this.search();
    },
    search() {
      this.searchParam.size = PAGE_SIZE;
      this.$apiCall.get(API.QLTY_INSP_STAT, this.searchParam, data => {
        if (data.result.list) {
          this.qltyInspDataCount = data.result.total;
          this.qltyInspDataList = data.result.list;
        } else {
          this.qltyInspDataList = [];
        }
      });
    },
    reset() {
      this.searchParam.fromDate = moment().startOf('year').format('YYYY-MM-DD');
      this.searchParam.toDate = moment().format('YYYY-MM-DD');
      this.searchParam.bplcKndCd = '';
      this.searchParam.inspSeCd = '';
      this.searchParam.fcltNm = '';
    },
    isBg3BadgeClass(inspPrgrsSttsCd) {
      let result = false;
      switch (inspPrgrsSttsCd) {
          case '00':  // 작성중
          case '10':  // 신청
          case '30':  // 서류보완요청
          case '50':  // 반려
          case '80':  // 사전취소
          case '90':  // 검사취하
            result = true;
            break;
      }
      return result;
    },
    excelDownload() {
      this.$apiCall.download(API.QLTY_INSP_EXCEL_DOWN, {
        fileName : `품질검사현황(${this.searchParam.fromDate.replaceAll('-', '')}~${this.searchParam.toDate.replaceAll('-', '')}).xlsx`,
        ...this.searchParam,
      });
    },
    goDetail(inspAplySn) {
      this.$router.push({path: '/sfli/qlty-insp-stat/QltyInspStatDetail', query: {inspAplySn: inspAplySn}}).catch(() => {
        console.error("Navigation Error");
      });
    },
    openModal(modalId) {
      if (modalId === '#modalQltyInspAplyGuide') {
        this.isModalQltyInspAplyGuideVisible = true;
      }
    },
    closeModal() {
      this.isModalQltyInspAplyGuideVisible = false;
    },
    navigateTo(navigatePath) {
      if (!navigatePath) {
        alert('페이지 정보가 존재하지 않습니다.');
        return;
      } 

      // window.open(navigatePath, '_blank');
      this.$router.push(navigatePath);
    },
  }
}
</script>

<style scoped>
.board_list.scroll > table { table-layout: fixed; width:120rem;}

.no-data-message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  margin: 20px 0px;
  border-radius: 10px;
  background-color: #f7f7f7;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 350px;
}

.no-data-message::before { content:''; width:2rem; height:2rem; background:url(@/assets/images/common/icon_search.png) no-repeat; background-size: 2rem; margin-right: 8px;}

.board_list.scroll > table tbody tr:hover{ background:var(--bg-gray10)}
</style>