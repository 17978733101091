<template>
  <template v-if="qltyInspStatDetail.inspPrgrsSttsCd === '70' && qltyInspStatDetail.inspRcptId && qltyInspStatDetail.inspRsltCd === '10' && inspSn">
    <!-- 수입신고기본정보 > 화면 타이틀 및 버튼 영역 -->
    <!-- 2025.03.07 수입신고기본정보 목록 추가 -->
    <div class="board_info">
      <h2 class="title1">수입신고 목록</h2>
    </div>
    <div class="board_list scroll">
      <table>
        <caption>수입신고기본정보-수입신고번호, 신고일자, 환경청, 수입항, 수입신고량(톤), 예상수입시작/종료일, 예상공급처로 구성
        </caption>
        <colgroup>
          <col style="width:80px">
          <col style="width:180px">
          <col style="width:180px">
          <col style="width:180px">
          <col style="width:180px">
        </colgroup>
        <thead>
        <tr>
          <th scope="col">상세조회</th>
          <th scope="col">수입신고번호</th>
          <th scope="col">신고일자</th>
          <th scope="col">환경청</th>
          <th scope="col">수입항</th>
          <th scope="col">수입신고량(톤)</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="incmRptDsctnMainList.length <= 0" >
          <td colspan="6">
            <div style="display: flex; justify-content: center;">
              <div class="no-data-message">수입신고기본정보 데이터가 없습니다.</div>
            </div>
          </td>
        </tr>
        <tr v-else v-for="(item, index) in incmRptDsctnMainList" :key="index">
          <td aria-label="상세조회"><br-button label="" class="btn sm ico_search" @click="searchDetail(item.incmDclrDsctnSn)" /></td>
          <td aria-label="수입신고번호">{{ item.incmDclrNo }}</td>
          <td aria-label="신고일자">{{ item.dclrYmd }}</td>
          <td aria-label="환경청">{{ item.rbeoNm }}</td>
          <td aria-label="수입항">{{ item.dmstUnloadHburNm }}</td>
          <td aria-label="수입신고량(톤)">{{ item.incmDclqty }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="board_info  mt_40">
      <h2 class="title1">수입신고 기본정보</h2>
      <div class="btn_area">
        <br-button label="추가" class="btn sm primary line ico_plus" @click="addIncmRptDsctn" />
        <br-button label="삭제" class="btn sm point ico_minus" @click="delIncmRptDsctn" />
      </div>
    </div>
    <!-- 수입신고기본정보 > 그리드 컨텐츠 영역 -->
<!-- 
    <div class="board_list scroll">
      <table>
        <caption>수입신고기본정보-수입신고번호, 신고일자, 환경청, 수입항, 수입신고량(톤), 예상수입시작/종료일, 예상공급처로 구성
        </caption>
        <colgroup>
          <col style="width:60px">
          <col style="width:180px">
          <col style="width:180px">
          <col style="width:180px">
          <col style="width:180px">
          <col style="width:260px">
          <col style="width:150px">
          <col style="width:180px">
          <col style="width:260px">
          <col style="width:120px">
          <col style="width:180px">
        </colgroup>
        <thead>
        <tr>
          <th scope="col">선택</th>
          <th scope="col">수입신고번호</th>
          <th scope="col">신고일자</th>
          <th scope="col">환경청</th>
          <th scope="col">수입항</th>
          <th scope="col">수입신고량(톤)</th>
          <th scope="col">예상수입시작일</th>
          <th scope="col">예상수입종료일</th>
          <th scope="col" colspan="2">예상공급처</th>
          <th scope="col">판매상태</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="incmRptDsctnList.length <= 0" >
          <td colspan="9">
            <div style="display: flex; justify-content: center;">
              <div class="no-data-message">수입신고기본정보 데이터가 없습니다.</div>
            </div>
          </td>
        </tr>
        <tr v-else v-for="(item, index) in incmRptDsctnList" :key="index">
          <td aria-label="선택" @click.prevent="item.chk = (item.chk === 'Y')? 'N' : 'Y'">
            <input type="checkbox" id="chk1" v-model="item.chk" true-value="Y" false-value="N" @click.stop>
          </td>
          <td aria-label="수입신고번호">
            <br-form-input label="수입신고번호" type="text" v-model="item.incmDclrNo" :maxlength="20" @change="onChangedItem(item)"/>
          </td>
          <td aria-label="신고일자">
            <br-date-picker label="신고일자" v-model:value="item.dclrYmd" @change="onChangedItem(item)"/>
          </td>
          <td aria-label="환경청">
            <br-form-select label="환경청" v-model:value="item.rbeoCd" :options="rbeoCds" includeSelectOption @change="onChangedItem(item)"/>
          </td>
          <td aria-label="수입항">
            <br-form-select label="환경청" v-model:value="item.dmstUnloadHburCd" :options="hburCds" includeSelectOption @change="onChangedItem(item)"/>
          </td>
          <td aria-label="수입신고량(톤)">
            <br-form-input label="수입신고량(톤)" type="text" v-model="item.incmDclqty" @change="onChangedItem(item)" validator="number" align="right"/>
          </td>
          <td aria-label="예상수입시작일">
            <br-date-picker label="시작일" v-model:value="item.incmPrnmntBgngYmd" @change="onChangedItem(item)"/>
          </td>
          <td aria-label="예상수입종료일">
            <br-date-picker label="종료일" v-model:value="item.incmPrnmntEndYmd" @change="onChangedItem(item)"/>
          </td>
          <td aria-label="예상공급처">
            <br-form-hidden label="예상공급처" type="hidden" v-model="item.useBplcId" @change="onChangedItem(item)"/>
            <br-form-input label="예상공급처" type="text" v-model="item.useBplcNm" @change="onChangedItem(item)" customClass="form_select w100"  readonly/>
          </td>
          <td>
            <br-button label="찾기" class="btn sm ico_search" @click="callPopupIncmUseBplc(index, 'DSCTN')" />
          </td>
          <td aria-label="판매상태">
            <br-form-select label="판매상태" v-model:value="item.ntslSttsCd" :options="ntslSttsCds" includeSelectOption @change="onChangedItem(item)"/>
          </td>
        </tr>
        </tbody>
      </table>
    </div> 
-->

    <div class="board_write">
      <!-- <p class="t_caption"><span class="necessary">(<i>※</i>)</span> 필수 입력항목입니다.</p> -->
      <table>
        <caption></caption>
        <colgroup>
          <col style="width:18%">
          <col style="width:32%">
          <col style="width:18%">
          <col style="width:32%">
        </colgroup>
        <tbody>
          <template v-if="incmRptDsctnList.length <= 0" >
            <tr>
              <td colspan="4">
                <div style="display: flex; justify-content: center;">
                  <div class="no-data-message">수입신고기본정보 데이터가 없습니다.</div>
                </div>
              </td>
            </tr>
          </template>
          <template v-else v-for="(item, index) in incmRptDsctnList" :key="index">
            <tr>
              <th scope="row">수입신고번호</th>
              <td>
                <br-form-input label="수입신고번호" type="text" v-model="item.incmDclrNo" :maxlength="20" @change="onChangedItem(item)"/>
              </td>
              <th scope="row">신고일자</th>
              <td>
                <br-date-picker label="신고일자" v-model:value="item.dclrYmd" @change="onChangedItem(item)"/>
              </td>
            </tr>
            <tr>
              <th scope="row">환경청</th>
              <td>
                <br-form-select label="환경청" v-model:value="item.rbeoCd" :options="rbeoCds" includeSelectOption @change="onChangedItem(item)"/>
              </td>
              <th scope="row">수입항</th>
              <td>
                <br-form-select label="수입항" v-model:value="item.dmstUnloadHburCd" :options="hburCds" includeSelectOption @change="onChangedItem(item)"/>
              </td>
            </tr>
            <tr>
              <th scope="row">수입신고량(톤)</th>
              <td>
                <br-form-input label="수입신고량(톤)" type="text" v-model="item.incmDclqty" @change="onChangedItem(item)" validator="number" align="right"/>
              </td>
              <th scope="row">&nbsp;</th>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <th scope="row">예상수입시작일</th>
              <td>
                <br-date-picker label="시작일" v-model:value="item.incmPrnmntBgngYmd" @change="onChangedItem(item)"/>
              </td>
              <th scope="row">예상수입종료일</th>
              <td>
                <br-date-picker label="종료일" v-model:value="item.incmPrnmntEndYmd" @change="onChangedItem(item)"/>
              </td>
            </tr>
            <tr>
              <th scope="row">예상공급처</th>
              <td>
                <br-form-hidden label="예상공급처" type="hidden" v-model="item.useBplcId" @change="onChangedItem(item)"/>
                <br-form-input label="예상공급처" type="text" v-model="item.useBplcNm" @change="onChangedItem(item)" customClass="form_select w80"  readonly/>
                <br-button label="찾기" class="btn sm ico_search" @click="callPopupIncmUseBplc(index, 'DSCTN')" />
              </td>
              <th scope="row">판매상태</th>
              <td>
                <br-form-select label="판매상태" v-model:value="item.ntslSttsCd" :options="ntslSttsCds" includeSelectOption @change="onChangedItem(item)"/>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <!-- 수입제품 > 화면 타이틀 및 버튼 영역 -->
    <div class="board_info mt_40">
      <h2 class="title1">수입신고 제품</h2>
      <div class="btn_area">
        <br-button label="추가" class="btn sm primary line ico_plus" @click="addIncmDclrDsctnPrdct" />
        <br-button label="삭제" class="btn sm point ico_minus" @click="delIncmDclrDsctnPrdct" />
      </div>
    </div>
    <!-- 수입제품 > 그리드 컨텐츠 영역 -->
    <div class="board_list scroll">
      <table>
        <caption>수입제품-제품종류 및 형태, SK No, 원재료 구성비율(%)로 구성
        </caption>
        <colgroup>
          <col style="width:60px">
          <col style="width:25%">
          <col style="width:45%">
          <col style="width:30%">
        </colgroup>
        <thead>
        <tr>
          <th scope="col">선택</th>
          <th scope="col">제품종류 및 형태</th>
          <th scope="col">HSK No</th>
          <th scope="col">원재료 구성비율(%)</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="incmDclrDsctnPrdctList.length <= 0" >
          <td colspan="4">
            <div style="display: flex; justify-content: center;">
              <div class="no-data-message">수입제품 데이터가 없습니다.</div>
            </div>
          </td>
        </tr>
        <tr v-else v-for="(item, index) in incmDclrDsctnPrdctList" :key="index">
          <td aria-label="선택" @click.prevent="item.chk = (item.chk === 'Y')? 'N' : 'Y'">
            <input type="checkbox" id="chk1" v-model="item.chk" true-value="Y" false-value="N" @click.stop>
          </td>
          <td aria-label="제품종류 및 형태">
            <br-form-select label="제품종류 및 형태" style="max-width: 230px" v-model:value="item.prdctKndCd" :options="itemCds" includeSelectOption @change="onChangedItem(item)"/>
          </td>
          <td aria-label="HSK No">
            <br-form-select label="HSK No" style="max-width: 360px" v-model:value="item.hskCd" :options="hskCds" includeSelectOption @change="onChangedItem(item)"/>
          </td>
          <td aria-label="원재료 구성비율(%)">
            <br-form-input label="원재료 구성비율(%)" v-model="item.rawmtrlCpstRt" :maxlength="3" @change="onChangedItem(item)" validator="number" align="right"/>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <!-- 운반계획 > 화면 타이틀 및 버튼 영역 -->
    <div class="board_info mt_40">
      <h2 class="title1">수입신고 운반계획</h2>
      <div class="btn_area">
        <br-button label="추가" class="btn sm primary line ico_plus" @click="addIncmDclrDsctnTrans" />
        <br-button label="삭제" class="btn sm point ico_minus" @click="delIncmDclrDsctnTrans" />
      </div>
    </div>
    <!-- 운반계획 > 그리드 컨텐츠 영역 -->
    <div class="board_list scroll">
      <table>
        <caption>운반계획-제품종류 및 형태, SK No, 원재료 구성비율(%)로 구성
        </caption>
        <colgroup>
          <col style="width:60px">
          <col style="width:60%">
          <col style="width:40%">
        </colgroup>
        <thead>
        <tr>
          <th scope="col">선택</th>
          <th scope="col">운반자</th>
          <th scope="col">운반종류</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="incmDclrDsctnTransList.length <= 0" >
          <td colspan="3">
            <div style="display: flex; justify-content: center;">
              <div class="no-data-message">운반계획 데이터가 없습니다.</div>
            </div>
          </td>
        </tr>
        <tr v-else v-for="(item, index) in incmDclrDsctnTransList" :key="index">
          <td aria-label="선택" @click.prevent="item.chk = (item.chk === 'Y')? 'N' : 'Y'">
            <input type="checkbox" id="chk1" v-model="item.chk" true-value="Y" false-value="N" @click.stop>
          </td>
          <td aria-label="운반자">
            <br-form-input label="운반자" type="text" :maxlength="100" v-model="item.crerNm" @change="onChangedItem(item)"/>
          </td>
          <td aria-label="운반종류">
            <br-form-select label="운반종류" style="max-width: 360px" v-model:value="item.transKndCd" :options="transCds" includeSelectOption @change="onChangedItem(item)"/>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <!-- 공급계획 > 화면 타이틀 및 버튼 영역 -->
    <div class="board_info mt_40">
      <h2 class="title1">수입신고 공급계획</h2>
      <div class="btn_area">
        <br-button label="추가" class="btn sm primary line ico_plus" @click="addIncmDclrDsctnSply" />
        <br-button label="삭제" class="btn sm point ico_minus" @click="delIncmDclrDsctnSply" />
      </div>
    </div>
    <!-- 공급계획 > 그리드 컨텐츠 영역 -->
    <div class="board_list scroll">
      <table>
        <caption>공급계획-시도, 시군구, 사업장, 공급량로 구성
        </caption>
        <colgroup>
          <col style="width:60px">
          <col style="width:65%">
          <col style="width:15%">
          <col style="width:30%">
        </colgroup>
        <thead>
        <tr>
          <th scope="col">선택</th>
          <th scope="col" colspan="2">공급사업장</th>
          <th scope="col">공급량</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="incmDclrDsctnSplyList.length <= 0" >
          <td colspan="5">
            <div style="display: flex; justify-content: center;">
              <div class="no-data-message">공급계획 데이터가 없습니다.</div>
            </div>
          </td>
        </tr>
        <tr v-else v-for="(item, index) in incmDclrDsctnSplyList" :key="index">
          <td aria-label="선택" @click.prevent="item.chk = (item.chk === 'Y')? 'N' : 'Y'">
            <input type="checkbox" id="chk1" v-model="item.chk" true-value="Y" false-value="N" @click.stop>
          </td>
          <td aria-label="공급사업장">
            <br-form-hidden label="공급사업장" type="hidden" v-model="item.splyBplcId" @change="onChangedItem(item)"/>
            <br-form-input label="공급사업장" type="text" style="max-width: inherit"  v-model="item.splyBplcNm" @change="onChangedItem(item)" customClass="form_select w100"  readonly/>
            <br-form-hidden label="업체" type="hidden" v-model="item.bzentyNm" @change="onChangedItem(item)"/>
            <br-form-hidden label="시도" type="hidden" v-model="item.ctpvCd" @change="onChangedItem(item)"/>
            <br-form-hidden label="시군구" type="hidden" v-model="item.sggCd" @change="onChangedItem(item)"/>
            <br-form-hidden label="시설상태" type="hidden" v-model="item.fcltSttsCd" @change="onChangedItem(item)"/>
          </td>
          <td>
            <br-button label="찾기" class="btn sm ico_search" @click="callPopupIncmUseBplc(index, 'SPLY')" />
          </td>
          <td aria-label="공급량">
            <br-form-input label="공급량" v-model="item.prdctSupqty" @change="onChangedItem(item)" validator="number" align="right"/>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <!-- 첨부파일 > 화면 타이틀 영역 -->
    <div class="board_info mt_40">
      <h2 class="title1">수입신고 첨부파일</h2>
    </div>
    <div class="board_write">
      <table>
        <caption>파일 구성</caption>
        <colgroup>
            <col style="width:8%">
            <col style="width:42%">
            <col style="width:18%">
            <col style="width:32%">
        </colgroup>
        <tbody>
          <tr>
            <th scope="row">파일</th>
            <td colspan="3">
              <div class="file_upload_list">
                <li>
                  <br-attach-file-list label="수입신고 첨부파일 목록"
                                        ref="incmRptDsctnAttachFileList"
                                        atchFileTaskSeCd="IRD"
                                        atchFileEditMode="Y"
                                        v-model:atchFileGroupId="this.atchFileGroupId"
                                        v-model:fileProcessingData="this.fileProcessingData"
                                        :maxFileCount="10"
                                        :readonly="false" 
                                        :useEtcFile="true"
                                        :unusedAtchFileList="[]"
                    />
                </li>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  <template v-else>
    <div class="desc_box progress_box">
      <i class="icon"><img src="../../../../assets/images/common/ico_noti.png" alt="이미지"></i>
      <div class="progress">
        <div>현재 <strong>{{ qltyInspStatDetail.inspPrgrsSttsNm }}</strong>상태입니다. <span>검사상태가 '완료', 검사결과가 '합격', 수입품질검사 정보가 입력되어야만 수입신고내역을 볼 수 있습니다.</span></div>
      </div>
    </div>
  </template>
  <!-- 최하단 버튼 영역 -->
  <div class="btn_area right">
    <br-anchor-button label="저장" size="md" format="primary" @click="save" v-if="qltyInspStatDetail.inspPrgrsSttsCd === '70' && qltyInspStatDetail.inspRcptId  && qltyInspStatDetail.inspRsltCd === '10' && inspSn"/>
    <br-anchor-button label="목록" size="md" format="tertiary" @click="goList"/>
  </div>

  <incm-use-bplc-popup @select-button-clicked="onSelectedIncmUseBplc"/>
</template>

<script>
/**
 * 수입품질검사 수입신고내역 등록/확인 화면
 *
 * 작성자 : jhkim
 * 작성일 : 11/06/2024
 * 버전 : 1.0
 */
import moment from "moment";
import { storeSwitch } from "@/js/store/store-switch";
import IncmUseBplcPopup from "@/views/sfli/components/popup/IncmUseBplcPopup.vue";

const API = {
  RPT_DSCTN : "/incm-qlty-insp-stat/rpt-dsctn",
}

const CODE = {
  RBEO_CD: 'COM426',   // 유역환경청코드
  HBUR_CD: 'COM414',   // 항구코드
  ITEM_CD: 'MBR010',   // 품목코드
  HSK_CD: 'COM417',   // HSK코드
  TRANS_CD: 'COM420',   // 운송종류코드
  NTSL_CD: 'COM086',   // 판매상태코드
}

export default {
  components: {IncmUseBplcPopup},
  props: {
    inspAplySn: {type: String, required: true},
    qltyInspStatDetail: {type: Object, default: Object},
  },
  computed : {},
  data() {
    return {
      incmRptDsctnMainList: [],   //2025.03.07 수입신고 목록
      incmRptDsctnRowFrame: {
        chk: 'N',
        rowStatus: 'C',
        bplcId: '',
        incmFcltSn: '',
        incmDclrNo: '',
        inspId: '',
        dclrYmd: moment().format('YYYY-MM-DD'),
        rbeoCd: '',
        dmstUnloadHburCd: '',
        incmDclqty: '',
        incmPrnmntBgngYmd: '',
        incmPrnmntEndYmd: '',
        useBplcId: '',
        ntslSttsCd: '',
        atchFileGroupId: "",
        fileProcessingData: {},
      },
      incmRptDsctnList: [],
      incmDclrDsctnPrdctRowFrame: {
        chk: 'N',
        rowStatus: 'C',
        prdctKndCd: '',
        hskCd: '',
        rawmtrlCpstRt: '',
      },
      incmDclrDsctnPrdctList: [],
      incmDclrDsctnTransRowFrame: {
        chk: 'N',
        rowStatus: 'C',
        crerNm: '',
        transKndCd: '',
      },
      incmDclrDsctnTransList: [],
      incmDclrDsctnSplyRowFrame: {
        chk: 'N',
        rowStatus: 'C',
        ctpvCd: '',
        sggCd: '',
        splyBplcId: '',
        prdctSupqty: '',
      },
      incmDclrDsctnSplyList: [],
      rbeoCds: [],
      hburCds: [],
      itemCds: [],
      hskCds: [],
      transCds: [],
      ctpvCds: [],
      srfUseBplcList: [],
      srfUseBplcListForExpectation: [],
      srfUseBplcListForPlan: [],
      inspSn: "",
      idx: "",
      callName: "",
      atchFileGroupId: "",
      fileProcessingData: {},
    };
  },
  watch : {
    'qltyInspStatDetail.inspRcptId'(newVal) {
      if(newVal) {
        this.incmRptDsctnRowFrame.inspId = newVal;
        this.incmRptDsctnRowFrame.inspSn = this.qltyInspStatDetail.inspSn;
        this.search();
      }
    }
  },
  created() {},
  beforeMount (){},
  mounted() {
    this.initialize();
  },
  methods : {
    initialize() {
      this.loadComCodes();
    },
    loadComCodes() {
      this.rbeoCds = this.$store.getters.getCodeList({ groupCd: CODE.RBEO_CD, useYn: 'Y' }).map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));

      this.hburCds = this.$store.getters.getCodeList({ groupCd: CODE.HBUR_CD, useYn: 'Y' }).map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));

      this.ntslSttsCds = this.$store.getters.getCodeList({ groupCd: CODE.NTSL_CD, useYn: 'Y' }).map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));

      this.itemCds = this.$store.getters.getCodeList({ groupCd: CODE.ITEM_CD, useYn: 'Y', frstRefVl: 'SRF' }).map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));

      this.hskCds = this.$store.getters.getCodeList({ groupCd: CODE.HSK_CD, useYn: 'Y' }).map(item => ({
        text: `${item.dtlCd}(${item.dtlCdNm})`,
        value: item.dtlCd
      }));

      this.transCds = this.$store.getters.getCodeList({ groupCd: CODE.TRANS_CD, useYn: 'Y' }).map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));

      this.ctpvCds = this.$store.getters.getSggList({ type: 'ctpv' }).map(item => ({
        text: item.sggNm,
        value: item.sggStdgCd
      }));
    },
    search() {
      if( this.qltyInspStatDetail.inspRcptId ) {
        //this.readRptDsctn();

        //2025.03.07
        this.readAllRptDsctn();
      }
    },
    //2025.03.07 신고내역 목록 조회
    readAllRptDsctn(){
      this.$apiCall.get(`${API.RPT_DSCTN}/${this.qltyInspStatDetail.inspRcptId}`, {}, data => {
        //수입품질검사정보
        if (data.result.incmQltyInspInfo) this.inspSn = data.result.incmQltyInspInfo.inspSn;
        else this.inspSn = "";

        //수입신고기본정보 목록 조회
        if (data.result.incmRptDsctnMainList) {
          this.incmRptDsctnMainList = data.result.incmRptDsctnMainList;
        }else{
          this.incmRptDsctnMainList = [];
        }

      });
    },
    //2025.03.07 수입신고정보 상세조회
    searchDetail(incmDclrDsctnSn) {
      storeSwitch.on("loading");

      if( incmDclrDsctnSn ) {
        this.readRptDsctn(incmDclrDsctnSn);
      }

      storeSwitch.off("loading");
    },
    readRptDsctn(incmDclrDsctnSn) {
      this.$apiCall.get(`${API.RPT_DSCTN}/${this.qltyInspStatDetail.inspRcptId}/${incmDclrDsctnSn}`, {}, data => {

        //수입품질검사정보
        // 2025.03.07 주석처리(readAllRptDsctn에서 조회)
        // if (data.result.incmQltyInspInfo) this.inspSn = data.result.incmQltyInspInfo.inspSn;
        // else this.inspSn = "";

        // 수입신고기본정보
        if (data.result.incmRptDsctn) {
          this.incmRptDsctnList = [{...data.result.incmRptDsctn}];
          this.atchFileGroupId = data.result.incmRptDsctn.atchFileGroupId;
        }else{
          this.incmRptDsctnList = [];
          this.atchFileGroupId = "";
        } 

        // 수입제품
        if (data.result.incmDclrDsctnPrdct) this.incmDclrDsctnPrdctList = [{...data.result.incmDclrDsctnPrdct}];
        else this.incmDclrDsctnPrdctList = [];

        // 운반계획
        if (data.result.incmDclrDsctnTransList) this.incmDclrDsctnTransList = data.result.incmDclrDsctnTransList;
        else this.incmDclrDsctnTransList = [];

        // 공급계획
        if (data.result.incmDclrDsctnSplyList) {
          this.incmDclrDsctnSplyList = data.result.incmDclrDsctnSplyList;

          // 시도 > 시군구 > 사업장 콤보박스 목록 바인딩 문제로 예외로직 추가
          for(const row of this.incmDclrDsctnSplyList) {
            row.ctpvCds = [...this.ctpvCds];
            row.sggCds = this.$store.getters.getSggList({ upSggStdgCd: row.ctpvCd }).map(item => ({
              text: item.sggNm,
              value: item.sggStdgCd
            }));
            if( row.sggCd ) row.splyBplcIds = this.srfUseBplcList.filter(item => item.scndRefVl === row.sggCd);
            else row.splyBplcIds = this.srfUseBplcList.filter(item => item.frstRefVl === row.ctpvCd);

          }
        }
        else this.incmDclrDsctnSplyList = [];
      });
    },
    goList() {
      //this.$router.go(-1);
      this.$router.push("/sfli/IncmQltyInspStat");
    },
    rowsHandler(eventType, list, item, maxLength, errorMessage) {
      if( eventType === 'C' ) {
        if( maxLength ) {
          if( list.length < maxLength ) {
            list.push({...item});
          } else {
            alert(errorMessage);
          }
        } else {
          list.push({...item});
        }
      } else if( eventType === 'D' ) {
        const isExistCheckedRow = list.some(item => item.chk === 'Y');
        if( isExistCheckedRow ) {
          const newList = [];
          const deleteRows = [];
          for(const row of list) {
            if( row.chk === 'Y' ) {
              if( row.rowStatus === 'C' ) {
                // 신규row에 대한 삭제 시, transaction 일으킬게 없으므로, 항목 무시
              } else {
                deleteRows.push(row);
              }
            } else {
              newList.push(row);
            }
          }
          list.splice(0, list.length, ...newList);
          return deleteRows;
        } else {
          alert('삭제할 데이터가 없습니다.');
        }
      } else {
        console.log('잘못된 호출 방식');
      }
    },
    addIncmRptDsctn() {
      //2025.03.07 수입신고 상세 데이터 초기화
      this.initDetailData();

      const errorMessage = '수입신고기본정보는 최대 하나만 추가할 수 있습니다.';
      this.rowsHandler('C', this.incmRptDsctnList, this.incmRptDsctnRowFrame, 1, errorMessage);
    },
    delIncmRptDsctn() {
      //2025.03.07 삭제처리를 위해 체크된 것처럼 처리
      if(this.incmRptDsctnList.length > 0){
        this.incmRptDsctnList[0].chk = "Y";
      }

      const deleteRows = this.rowsHandler('D', this.incmRptDsctnList);
      if( deleteRows && deleteRows.length > 0 ) {
        if( confirm('수입신고기본정보 삭제 시,\r수입제품, 운반계획, 공급계획까지 모두 삭제됩니다.\r정말 삭제하시겠습니까?') ) {
          this.$apiCall.delete(
              API.RPT_DSCTN,
              { incmRptDsctnList: deleteRows },
              () => {
                alert("삭제되었습니다.");

                //2025.03.07 상세내용 초기화
                this.initDetailData();

                this.search();
              },
              (error) => {
                alert(error.message);
              }
          );
        }
      }
    },
    addIncmDclrDsctnPrdct() {
      if(this.incmRptDsctnList.length <= 0) {
        alert('수입신고기본정보가 있어야 수입제품을 추가할 수 있습니다.\r수입신고기본정보를 먼저 작성해주세요.')
        return false;
      }

      const errorMessage = '수입제품은 최대 하나만 추가할 수 있습니다.';
      this.rowsHandler('C', this.incmDclrDsctnPrdctList, this.incmDclrDsctnPrdctRowFrame, 1, errorMessage);
    },
    delIncmDclrDsctnPrdct() {
      const deleteRows = this.rowsHandler('D', this.incmDclrDsctnPrdctList);
      if( deleteRows && deleteRows.length > 0 ) {
        if( confirm('정말 삭제하시겠습니까?') ) {
          this.$apiCall.delete(
              API.RPT_DSCTN,
              { incmDclrDsctnPrdctList: deleteRows },
              () => {
                alert("삭제되었습니다.");
                this.search();
              },
              (error) => {
                alert(error.message);
              }
          );
        }
      }
    },
    addIncmDclrDsctnTrans() {
      if(this.incmRptDsctnList.length <= 0) {
        alert('수입신고기본정보가 있어야 운반계획을 추가할 수 있습니다.\r수입신고기본정보를 먼저 작성해주세요.')
        return false;
      }

      const errorMessage = '운반계획은 한번에 최대 5개까지만 추가할 수 있습니다.';
      this.rowsHandler('C', this.incmDclrDsctnTransList, this.incmDclrDsctnTransRowFrame, 5, errorMessage);
    },
    delIncmDclrDsctnTrans() {
      const deleteRows = this.rowsHandler('D', this.incmDclrDsctnTransList);
      if( deleteRows && deleteRows.length > 0 ) {
        if( confirm('정말 삭제하시겠습니까?') ) {
          this.$apiCall.delete(
              API.RPT_DSCTN,
              { incmDclrDsctnTransList: deleteRows },
              () => {
                alert("삭제되었습니다.");
                this.search();
              },
              (error) => {
                alert(error.message);
              }
          );
        }
      }
    },
    addIncmDclrDsctnSply() {
      if(this.incmRptDsctnList.length <= 0) {
        alert('수입신고기본정보가 있어야 공급계획을 추가할 수 있습니다.\r수입신고기본정보를 먼저 작성해주세요.')
        return false;
      }

      const errorMessage = '공급계획은 한번에 최대 5개까지만 추가할 수 있습니다.';
      this.rowsHandler('C', this.incmDclrDsctnSplyList, this.incmDclrDsctnSplyRowFrame, 5, errorMessage);

      // 시도 > 시군구 > 사업장 콤보박스 목록 바인딩 문제로 예외로직 추가
      this.incmDclrDsctnSplyList[this.incmDclrDsctnSplyList.length - 1].ctpvCds = [...this.ctpvCds];
      this.incmDclrDsctnSplyList[this.incmDclrDsctnSplyList.length - 1].splyBplcIds = [...this.srfUseBplcList];
    },
    delIncmDclrDsctnSply() {
      const deleteRows = this.rowsHandler('D', this.incmDclrDsctnSplyList);
      if( deleteRows && deleteRows.length > 0 ) {
        if( confirm('정말 삭제하시겠습니까?') ) {
          this.$apiCall.delete(
              API.RPT_DSCTN,
              { incmDclrDsctnSplyList: deleteRows },
              () => {
                alert("삭제되었습니다.");
                this.search();
              },
              (error) => {
                alert(error.message);
              }
          );
        }
      }
    },
    onChangedCtpvCd(param, row, index) {
      row.sggCd = '';
      row.splyBplcId = '';
      this.onChangedItem(row);

      // 시도 > 시군구 > 사업장 콤보박스 목록 바인딩 문제로 예외로직 추가
      this.incmDclrDsctnSplyList[index].sggCds = this.$store.getters.getSggList({ upSggStdgCd: param.value }).map(item => ({
        text: item.sggNm,
        value: item.sggStdgCd
      }));
      if(param.value) this.incmDclrDsctnSplyList[index].splyBplcIds = this.srfUseBplcList.filter(item => item.frstRefVl === param.value);
      else this.incmDclrDsctnSplyList[index].splyBplcIds = [...this.srfUseBplcList];
    },
    onChangedSggCd(param, row, index) {
      row.splyBplcId = '';
      this.onChangedItem(row);

      // 시도 > 시군구 > 사업장 콤보박스 목록 바인딩 문제로 예외로직 추가
      if(param.value) this.incmDclrDsctnSplyList[index].splyBplcIds = this.srfUseBplcList.filter(item => item.scndRefVl === param.value);
      else this.incmDclrDsctnSplyList[index].splyBplcIds = this.srfUseBplcList.filter(item => item.frstRefVl === row.ctpvCd);
    },
    save() {
      const incmRptDsctnCnt = this.incmRptDsctnList.length;
      const incmDclrDsctnPrdctCnt = this.incmDclrDsctnPrdctList.length;
      const incmDclrDsctnTransCnt = this.incmDclrDsctnTransList.length;
      const incmDclrDsctnSplyCnt = this.incmDclrDsctnSplyList.length;

      if( incmRptDsctnCnt > 0 || incmDclrDsctnPrdctCnt > 0 || incmDclrDsctnTransCnt > 0 || incmDclrDsctnSplyCnt > 0 ) {

        if( !this.canSave() ) return false;

        if( confirm('수입신고 내역을 저장하시겠습니까?') ) {

          this.$refs.incmRptDsctnAttachFileList.save().then(atchFileGroupId => {   //2025.03.12 첨부파일 저장 추가
            
            if(this.incmRptDsctnList[0].rowStatus === undefined && this.incmRptDsctnList[0].atchFileGroupId != atchFileGroupId){
              this.incmRptDsctnList[0].rowStatus = "U";
            }

            //첨부파일 그룹아이디 세팅
            this.incmRptDsctnList[0].atchFileGroupId = atchFileGroupId;
            this.incmRptDsctnList[0].fileProcessingData = this.fileProcessingData;

            const param = {
              inspSn: this.inspSn,
              incmRptDsctnList: this.incmRptDsctnList,
              incmDclrDsctnPrdctList: this.incmDclrDsctnPrdctList,
              incmDclrDsctnTransList: this.incmDclrDsctnTransList,
              incmDclrDsctnSplyList: this.incmDclrDsctnSplyList,
            };
            this.$apiCall.post(
                API.RPT_DSCTN,
                param,
                () => {
                  alert("저장되었습니다.");

                  //2025.03.07 수입신고 상세 데이터 초기화
                  this.initDetailData();

                  this.search();

                  //스크롤을 맨위로~
                  window.scrollTo(0, 0);
                },
                () => {
                  alert("오류가 발생했습니다.");
                }
            );
          });
        }
      } else {
        alert('저장할 데이터가 없습니다.');
      }
    },
    canSave() {
      if( this.incmRptDsctnList.length > 0 ) {
        for(const item of this.incmRptDsctnList) {
          if( !item.rbeoCd ) {
            alert('수입신고기본정보에서 환경청을 입력해주세요.')
            return false;
          }
          if( !item.incmDclqty ) {
            alert('수입신고기본정보에서 수입신고량을 입력해주세요.')
            return false;
          }
        }
      }

      if( this.incmDclrDsctnPrdctList.length > 0 ) {
        for(const item of this.incmDclrDsctnPrdctList) {
          if( !item.prdctKndCd ) {
            alert('수입제품에서 제품종류 및 형태를 입력해주세요.')
            return false;
          }
          if( !item.hskCd ) {
            alert('수입제품에서 HSK No을 입력해주세요.')
            return false;
          }
          if( !item.rawmtrlCpstRt ) {
            alert('수입제품에서 원재료 구성비율(%)을 입력해주세요.')
            return false;
          }
        }
      }

      if( this.incmDclrDsctnTransList.length > 0 ) {
        for(const item of this.incmDclrDsctnTransList) {
          if( !item.transKndCd ) {
            alert('운반계획에서 운반종류를 입력해주세요.')
            return false;
          }
        }
      }

      if( this.incmDclrDsctnSplyList.length > 0 ) {
        for(const item of this.incmDclrDsctnSplyList) {
          if( !item.splyBplcId ) {
            alert('공급계획에서 사업장을 입력해주세요.')
            return false;
          }
          if( !item.prdctSupqty ) {
            alert('공급계획에서 공급량을 입력해주세요.')
            return false;
          }
        }
      }

      return true;
    },
    onChangedItem(item) {
      if( !item.rowStatus || item.rowStatus == "undefined") item.rowStatus = 'U';
    },
    callPopupIncmUseBplc(index, callName) {
      this.idx = index;
      this.callName = callName;

      storeSwitch.on('incmUseBplcPopup');
    },
    onSelectedIncmUseBplc(data) {
     if(this.callName == "DSCTN") {
        this.incmRptDsctnList[this.idx].useBplcId = data.bplcId;
        this.incmRptDsctnList[this.idx].useBplcNm = data.bplcNm;
        this.onChangedItem(this.incmRptDsctnList[this.idx]);
     } else if(this.callName == "SPLY") {      
        this.incmDclrDsctnSplyList[this.idx].splyBplcId = data.bplcId;
        this.incmDclrDsctnSplyList[this.idx].splyBplcNm = data.bplcNm;
        this.incmDclrDsctnSplyList[this.idx].ctpvCd = data.ctpvStdgCd;     
        this.incmDclrDsctnSplyList[this.idx].sggCd = data.sggStdgCd;
        this.incmDclrDsctnSplyList[this.idx].bzentyNm = data.bzentyNm;    
        this.incmDclrDsctnSplyList[this.idx].fcltSttsCd = data.bplcSttsCd;    
            
        this.onChangedItem(this.incmDclrDsctnSplyList[this.idx]);
     }
    },
    //2025.03.07 수입신고 상세정보 데이터 초기화
    initDetailData(){
      this.incmRptDsctnRowFrame = {
        chk: 'N',
        rowStatus: 'C',
        bplcId: '',
        incmFcltSn: '',
        incmDclrNo: '',
        inspId: '',
        dclrYmd: moment().format('YYYY-MM-DD'),
        rbeoCd: '',
        dmstUnloadHburCd: '',
        incmDclqty: '',
        incmPrnmntBgngYmd: '',
        incmPrnmntEndYmd: '',
        useBplcId: '',
        ntslSttsCd: '',
        atchFileGroupId: "",
        fileProcessingData: {},
      };
      this.incmRptDsctnList = [];
      this.incmDclrDsctnPrdctRowFrame = {
        chk: 'N',
        rowStatus: 'C',
        prdctKndCd: '',
        hskCd: '',
        rawmtrlCpstRt: '',
      };
      this.incmDclrDsctnPrdctList = [];
      this.incmDclrDsctnTransRowFrame = {
        chk: 'N',
        rowStatus: 'C',
        crerNm: '',
        transKndCd: '',
      };
      this.incmDclrDsctnTransList = [];
      this.incmDclrDsctnSplyRowFrame = {
        chk: 'N',
        rowStatus: 'C',
        ctpvCd: '',
        sggCd: '',
        splyBplcId: '',
        prdctSupqty: '',
      };
      this.incmDclrDsctnSplyList = [];

      //첨부파일정보 초기화
      this.atchFileGroupId = "";
      this.fileProcessingData= {};
    },
  }
}
</script>

<style scoped>
.board_list.scroll > table { table-layout: fixed; width: 100%}

.no-data-message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  border-radius: 10px;
  background-color: #f7f7f7;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 350px;
}

.no-data-message::before { content:''; width:2rem; height:2rem; background:url(@/assets/images/common/icon_search.png) no-repeat; background-size: 2rem; margin-right: 8px;}

.board_list.scroll > table tbody tr:hover{ background:var(--bg-gray10)}
</style>