<template>
  <div class="tab_area">
    <ul class="tab">
      <li :class="{active: tabIndex === 0}" @click="tabIndex = 0"><button type="button" class="btn_tab">신청정보<i v-if="tabIndex === 0" class="sr_only">선택됨</i></button></li>
      <li :class="{active: tabIndex === 1}" @click="tabIndex = 1"><button type="button" class="btn_tab">검사현황<i v-if="tabIndex === 1" class="sr_only">선택됨</i></button></li>
      <li :class="{active: tabIndex === 2}" @click="tabIndex = 2"><button type="button" class="btn_tab">수입신고내역<i v-if="tabIndex === 2" class="sr_only">선택됨</i></button></li>
    </ul>
    <div v-show="tabIndex === 0" class="tab_conts_wrap">
      <incm-qlty-insp-apply-info :inspAplySn="inspAplySn" :bplcId="bplcId" v-model="qltyInspStatDetail" @print-incm-qlty-insp-report="openReportForm"/>
    </div>
    <div v-show="tabIndex === 1" class="tab_conts_wrap">
      <incm-qlty-insp-stat-info :inspAplySn="inspAplySn" @print-incm-qlty-insp-report="openReportForm"/>
    </div>
    <div v-show="tabIndex === 2" class="tab_conts_wrap">
      <incm-reporting-info :inspAplySn="inspAplySn" :qltyInspStatDetail="qltyInspStatDetail"/>
    </div>
  </div>
  <!--리포트-->
  <component  v-if="reportParamObj.selectedPopupComponent" 
            :is="reportParamObj.selectedPopupComponent" 
            :rd-param-file-name="reportParamObj.rdParamFileName" 
            :rd-param-key="reportParamObj.rdParamKey" 
            :rd-param-value="reportParamObj.rdParamValue" 
  />
</template>

<script>
/**
 * 수입품질검사 현황의 상세(신청정보, 검사현황, 수입신고내역) 컨트롤 화면
 *
 * 작성자 : jhkim
 * 작성일 : 11/06/2024
 * 버전 : 1.0
 */
 import {storeSwitch} from "@/js/store/store-switch";
import IncmQltyInspApplyInfo from "@/views/sfli/incm-qlty-insp-stat/detail/IncmQltyInspApplyInfo.vue";
import IncmQltyInspStatInfo from "@/views/sfli/incm-qlty-insp-stat/detail/IncmQltyInspStatInfo.vue";
import IncmReportingInfo from "@/views/sfli/incm-qlty-insp-stat/detail/IncmReportingInfo.vue";
import reportQltyInspPopup from "@/views/sfli/qlty-insp-stat/popup/ReportQltyInspPopup.vue";

export default {
  components: {IncmQltyInspApplyInfo, IncmQltyInspStatInfo, IncmReportingInfo, reportQltyInspPopup},
  computed : {},
  data() {
    return {
      inspAplySn: '',
      bplcId: '',
      tabIndex: 0,
      qltyInspStatDetail: {},
      reportParamObj : {
        selectedPopupComponent: null, // 선택된 리포트/이폼 화면 구분 ID 
        rdParamKey: "",
        rdParamValue: "",
        rdParamFileName: "", 
      },
    };
  },
  watch : {},
  created() {},
  beforeMount (){
    this.inspAplySn = this.$route.query.inspAplySn;
    this.bplcId = this.$route.query.bplcId;
    window.scrollTo({ top: 0 });
  },
  mounted() {},
  methods : {
    openReportForm(rdFimeName) { // 리포트/이폼 화면 열기 

      this.reportParamObj.selectedPopupComponent = null; // 컴포넌트 리셋
      this.openLayerPopup(rdFimeName);

      /*
      * 재호출 후 팝업에서 노출되는 현상으로 재호출 
      */ 
      setTimeout(() => {
        this.openLayerPopup(rdFimeName);
      }, 50); 
    },
    openLayerPopup(rdFimeName) { // 레이어 팝업 기능 

      setTimeout(() => {
        this.reportParamObj.selectedPopupComponent = 'reportQltyInspPopup'; // 화면내 팝업 컴포넌트 

        this.reportParamObj.rdParamFileName = rdFimeName;                         // 리포트 mrd 파일명 

        // if(rdFimeName === "reportQltyStatement"){
        //   this.reportParamObj.rdParamKey = 'inspRsltSn';                            // 리포트 화면 Param Key 
        //   this.reportParamObj.rdParamValue = this.inspRsltSn;  // 리포트 화면 Param Key에 대한 Value  
        // }else{
          this.reportParamObj.rdParamKey = 'inspAplySn';                            // 리포트 화면 Param Key 
          this.reportParamObj.rdParamValue = this.inspAplySn;  // 리포트 화면 Param Key에 대한 Value  
        // }

        storeSwitch.on(this.reportParamObj.selectedPopupComponent); 
      }, 0); // 0ms 딜레이로 컴포넌트 강제 리렌더링 (리포트 리렌더링 안 되는 케이스가 존재하여 추가)
    },
  }
}
</script>

<style scoped>

</style>