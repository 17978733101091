<template>
 <!-- 화면 타이틀 영역 -->
 <div class="board_info">
    <h2 class="title1">품질등급 조회</h2>
  </div>
  <!-- 조회조건 영역 -->
  <div class="sch_form_wrap">
    <div class="sch_form row2">
      <span>
        <label for="select-qltyGrdJgmtSeCd">구분</label>
        <br-form-select id="select-qltyGrdJgmtSeCd" v-model:value="searchParam.qltyGrdJgmtSeCd" :options="qltyGrdJgmtSeCdOptions" @change="toggleJgmtSe" />
      </span>
      <span>
        <label for="select-bplcId">사업장</label>
        <br-form-select id="select-bplcId" v-model:value="searchParam.bplcId" :options="bplcIdOptions" @change="updateBplcKndCd" />
      </span>
      <span>
        <label>조회기간</label>
        <div class="input_group">
          <br-form-select aria-label="검사기간 (연도)" v-model:value="searchParam.prdYear" :options="prdYearOptions" />
          <!-- <br-date-from-to label="기본 날짜" v-model:fromDate="searchParam.fromVldYmd" v-model:toDate="searchParam.toVldYmd" /> -->
        </div>
      </span>
      <span>
        <label for="select-prdctKndCd">시설종류</label>
        <br-form-select id="select-prdctKndCd" v-model:value="searchParam.prdctKndCd" :options="prdctKndCdOptions" allSelectable />
      </span>
      <!-- <span>
        <label>진행상태</label>
        <br-form-select v-model:value="searchParam.qltyGrdPrgrsSttsCd" :options="qltyGrdPrgrsSttsCdOptions" allSelectable />
      </span> -->
    </div>
    <div class="btn_area">
      <br-anchor-button role="link" accesskey="a" label="검색" size="sm" format="sch" @click="searchBtn" />
      <br-anchor-button role="link" accesskey="b" label="새로고침" size="sm" format="txtN" icon="ico_refresh" @click="reset" />
    </div>
  </div>
  <!-- 그리드 소계 및 버튼 영역 -->
  <div class="board_info">
    <p class="page">
      <span class="total">전체 <strong>{{ this.qltyGrdDataCount }}</strong></span>
      <span>페이지 <strong>{{ this.searchParam.page }}/{{ this.lastPage }}</strong></span>
    </p>
    <div>
      <br-anchor-button role="link" accesskey="c" label="엑셀다운로드" size="sm" icon="ico_excel" @click="excelDownload"/>
    </div>
  </div>
  <div v-show="isOnMnftr" class="board_list">
    <table>
        <caption>유효기간, 사업장종류, 제품종류, 품질등급, 진행상태, 품질등급서출력</caption>
        <colgroup>
            <!-- <col style="width:10%"> -->
            <col style="width:120px">
            <col style="width:150px">
            <col style="width:220px">
            <col style="width:150px">
            <col style="width:120px">
            <col style="width:120px">
            <col style="width:120px">
        </colgroup>
        <thead>
            <tr>
                <th scope="col">품질등급서<br>출력</th>
                <th scope="col">검사회차</th>
                <th scope="col">유효기간</th>
                <th scope="col">사업장종류</th>
                <th scope="col">제품종류</th>
                <th scope="col">품질등급</th>
                <th scope="col">진행상태</th>
            </tr>
        </thead>
        <tbody>
          <tr v-if="qltyGrdDataList.length <= 0" >
            <td colspan="7">
              <div style="display: flex; justify-content: center;">
                <div class="no-data-message">조회조건에 해당하는 데이터가 없습니다.</div>
              </div>
            </td>
          </tr>
          <template v-for="(item, index) in qltyGrdDataList" :key="index">
              <tr v-if="item.qltyGrdPrgrsSttsCd === '11' || item.qltyGrdPrgrsSttsCd === '30' || item.qltyGrdPrgrsSttsCd === '20'" @dblclick="openPopup(item)">
                <td aria-label="품질등급서출력"><span class="badge" style="cursor: pointer;" @click="openReportForm(item.aprvYn, item.qltyGrdPrgrsSttsCd, item.qltyGrdRsltSn)">출력</span></td>
                <td aria-label="검사기간">{{ item.inspHyr === '2' ? Number(item.inspYr) + 1 : item.inspYr }}년 {{ item.inspHyr === '2' ? '1' : '2' }}회차</td>
                <td aria-label="유효기간">{{ item.vldPrd }}</td>
                <td aria-label="사업장종류">{{ item.bplcKndNm }}</td>
                <td aria-label="제품종류">{{ item.prdctKndNm }}</td>
                <td aria-label="품질등급">{{ item.qltyGrdNm }}</td>
                <td aria-label="진행상태">{{ item.qltyGrdPrgrsSttsCd === '11' && item.objcAplyPrdYn === 'Y' ? '이의신청기간' : item.qltyGrdPrgrsSttsNm }}</td>
              </tr>
              <tr v-else >
                <td aria-label="품질등급서출력"></td>
                <td aria-label="검사기간">{{ item.inspHyr === '2' ? Number(item.inspYr) + 1 : item.inspYr }}년 {{ item.inspHyr === '2' ? '1' : '2' }}회차</td>
                <td aria-label="유효기간">{{ item.vldPrd }}</td>
                <td aria-label="사업장종류">{{ item.bplcKndNm }}</td>
                <td aria-label="제품종류">{{ item.prdctKndNm }}</td>
                <td aria-label="심사중" colspan="3">심사중 입니다.</td>
              </tr>
          </template>
        </tbody> 
    </table>
  </div>
  <div v-show="isOnUse" class="board_list">
    <table >
        <caption>유효기간, 사업장, 사업장종류, 제품종류, 품질등급</caption>
        <colgroup>
            <col style="width:120px">
            <col style="width:180px">
            <col style="width:150px">
            <col style="width:120px">
            <col style="width:120px">
            <col style="width:120px">
        </colgroup>
        <thead>
            <tr>
                <th scope="col">검사회차</th>
                <th scope="col">유효기간</th>
                <th scope="col">사업장</th>
                <th scope="col">사업장종류</th>
                <th scope="col">제품종류</th>
                <th scope="col">품질등급</th>
            </tr>
        </thead>
        <tbody>
          <tr v-if="qltyGrdDataList.length <= 0" >
            <td colspan="6">
              <div style="display: flex; justify-content: center;">
                <div class="no-data-message">조회조건에 해당하는 데이터가 없습니다.</div>
              </div>
            </td>
          </tr>
          <template v-for="(item, index) in qltyGrdDataList" :key="index">
              <tr>
                <td aria-label="검사기간">{{ item.inspHyr === '2' ? Number(item.inspYr) + 1 : item.inspYr }}년 {{ item.inspHyr === '2' ? '1' : '2' }}회차</td>
                <td aria-label="유효기간">{{ item.vldPrd }}</td>
                <td aria-label="사업장">{{ item.bplcNm }}</td>
                <td aria-label="사업장종류">{{ item.bplcKndNm }}</td>
                <td aria-label="제품종류">{{ item.prdctKndNm }}</td>
                <td aria-label="품질등급">{{ item.qltyGrdNm }}</td>
              </tr>
          </template>
        </tbody> 
    </table>
  </div>
  <!-- 그리드 페이징 영역 -->
  <insp-pagination :total="qltyGrdDataCount" 
                   :size="searchParam.size" 
                   v-model:page="searchParam.page" 
                   v-model:maxPage="lastPage" 
                   @clickedPage="search"></insp-pagination>
  <list-popup @list-button-clicked="openList" :qltyGrdInfo="qltyGrdInfo"></list-popup>

  <component  v-if="reportParamObj.selectedPopupComponent" 
        :is="reportParamObj.selectedPopupComponent" 
        :rd-param-file-name="reportParamObj.rdParamFileName" 
        :rd-param-key="reportParamObj.rdParamKey" 
        :rd-param-value="reportParamObj.rdParamValue" 
  />
</template>

<script>
/**
 * 품질등급에 대한 현황 조회 및 검사신청 처리 화면
 *
 * 작성자 : 김지환
 * 작성일 : 11/04/2024
 * 버전 : 1.0
 */
import { storeSwitch } from "@/js/store/store-switch";
import inspPagination from "@/views/sfli/components/inspPagination.vue";
import ListPopup from "@/views/qlty-grd/QltyGrdListPopup.vue";
import moment from "moment";
import reportQltyGrdPopup from "@/views/qlty-grd/ReportQltyGrdPopup.vue";
const PAGE_SIZE = 10;
export default {  
  components: {inspPagination,ListPopup,reportQltyGrdPopup},
  data() {
    return {
      searchParam: {
        qltyGrdJgmtSeCd : "1",
        bplcId : "",
        bplcKndCd : "",
        prdctKndCd : "",
        qltyGrdPrgrsSttsCd : "",
        inspYr : "",
        inspHyr : "",
        fromVldYmd: moment().startOf('year').format('YYYY-MM-DD'),
        toVldYmd: moment().format('YYYY-MM-DD'),
        prdYear: moment().format('YYYY'),
        page: 1,
      },
      lastPage: 1,
      qltyGrdDataCount: 0,
      bplcIdOptions: [],
      qltyGrdDataList: [],
      qltyGrdInfo : {},
      isOnMnftr : true,
      isOnUse : false,
      qltyGrdJgmtSeCdOptions : [
        {value : "1", text : "정기판정"},
        {value : "2", text : "수시판정"},
      ],
      qltyGrdPrgrsSttsCdOptions : [
        {value : "10", text : "자료생성"},
        {value : "11", text : "임시등급"},
        {value : "30", text : "최종확정"},
        {value : "20", text : "의견서심사"},
        {value : "00", text : "미등록"},
      ],
      prdctKndCdOptions : [
        {value : "0001", text : "SRF 성형"},
        {value : "0002", text : "SRF 비성형"},
        {value : "0003", text : "BIO-SRF 성형"},
        {value : "0004", text : "BIO-SRF 비성형"},
      ],
      reportParamObj : {
        selectedPopupComponent: null, // 선택된 리포트/이폼 화면 구분 ID 
        rdParamKey: "",
        rdParamValue: "",
        rdParamFileName: "", 
      }, 
      qltyGrdRsltSn : null,
      prdYearOptions: this.generateYearOptions(),
    };
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
        await this.generatebplcCdOptions();
        await this.search();
    },
    generatebplcCdOptions() {
      return new Promise((resolve, reject) => {
          this.$apiCall.get('/qltyGrd/bplcList', {}, data => {
            if (data.result) {
              const allList = data.result.bplcList;
              this.bplcIdOptions =  allList
                .map(item => ({
                  value: item.bplcId,
                  text: item.bplcNm + '(' + item.bplcKndNm + ')',
                  bplcKndCd : item.bplcKndCd,
              }));

              if (this.bplcIdOptions && this.bplcIdOptions.length > 0) {
                this.searchParam.bplcId = this.bplcIdOptions[0].value;
                this.searchParam.bplcKndCd = this.bplcIdOptions[0].bplcKndCd;
              }
              resolve();
            } else {
              reject(new Error("Failed to load generatebplcCdOptions section codes."));
            }
          });
        });
    },
    updateBplcKndCd(selected) {
      const selectedOption = this.bplcIdOptions.find(option => option.value === selected.value);
      if (selectedOption) {
        this.searchParam.bplcKndCd = selectedOption.bplcKndCd;
      }
    },
    search() {
      this.searchParam.size = PAGE_SIZE;
      storeSwitch.on("loading");  // ============로딩바 시작
      this.$apiCall.get('/qltyGrd', this.searchParam, data => {
        if (data.result.list) {
          this.qltyGrdDataCount = data.result.total;
          this.qltyGrdDataList = data.result.list;
        } else {
          this.qltyGrdDataList = [];
        }
      });
      storeSwitch.off("loading");  // ===========로딩바 종료

      if(this.searchParam.bplcKndCd == '01-02') {
        this.isOnMnftr = false;
        this.isOnUse = true;
      }else {
        this.isOnMnftr = true;
        this.isOnUse = false;
      }
    },
    openPopup(data) {
      this.qltyGrdInfo = {};
      this.qltyGrdInfo = { bplcId: data.bplcId, 
                            bplcKndCd: data.bplcKndCd,
                            prdctKndCd: data.prdctKndCd,
                            inspYr:data.inspYr, 
                            inspHyr: data.inspHyr,
                            inspPrd: data.inspYr + "" + data.inspHyr,
                            inspId: data.inspId !== undefined ? data.inspId : '',  // undefined일 경우 null로 대체
                            qltyGrdJgmtSeCd: data.qltyGrdJgmtSeCd,
                            chkPop: 'Y' };
      /** 팝업창 호출 */
      storeSwitch.on('listPopup');
    },
    excelDownload() {
      let searchParam =  { ...this.searchParam };
      this.$apiCall.download(
        "/qltyGrd/excel-download", 
        searchParam
      );
    },
    openReportForm(aprvYn, qltyGrdPrgrsSttsCd, qltyGrdRsltSn) { // 리포트/이폼 화면 열기 

      let rdFimeName = "";

      if(qltyGrdPrgrsSttsCd === '30' && aprvYn === 'Y') {
        rdFimeName = "reportQltyGrd";
      }else {
        rdFimeName = "reportTmprQltyGrd";
      }

      this.qltyGrdRsltSn = null;
      this.qltyGrdRsltSn = qltyGrdRsltSn;

      if (!this.qltyGrdRsltSn) {
        alert("출력할 대상을 선택해주세요."); 
        return;
      }

      this.reportParamObj.selectedPopupComponent = null; // 컴포넌트 리셋
      this.openLayerPopup(rdFimeName);

      /*
      * 재호출 후 팝업에서 노출되는 현상으로 재호출 
      */ 
      setTimeout(() => {
        this.openLayerPopup(rdFimeName);
      }, 50); 

      this.putOtptNocs();
    },
    openLayerPopup(rdFimeName) { // 레이어 팝업 기능 

      setTimeout(() => {
        this.reportParamObj.selectedPopupComponent = 'reportQltyGrdPopup'; // 시설검사 신청 내역 화면내 팝업 컴포넌트 

        // rd Param 초기화 
        this.reportParamObj.rdParamFileName = rdFimeName;
        this.reportParamObj.rdParamKey = "";
        this.reportParamObj.rdParamValue = "";
        
        // rd 파일명 기준으로 Param 적용 
        switch (rdFimeName) {
          case 'reportQltyGrdOpnn': // 품질등급 의견서 
            this.reportParamObj.rdParamFileName = rdFimeName;                         // 리포트 mrd 파일명 
            this.reportParamObj.rdParamKey = 'KEY_NO';                            // 리포트 화면 Param Key 
            this.reportParamObj.rdParamValue = this.qltyGrdRsltSn;  // 리포트 화면 Param Key에 대한 Value  

            break;

          case 'reportTmprQltyGrd': // 임시품질등급서 
            this.reportParamObj.rdParamFileName = rdFimeName;                     // 리포트 mrd 파일명 
            this.reportParamObj.rdParamKey = 'KEY_NO';                            // 리포트 화면 Param Key 
            this.reportParamObj.rdParamValue = this.qltyGrdRsltSn;  // 리포트 화면 Param Key에 대한 Value  

            break;

          case 'reportQltyGrd': // 품질등급서 
            this.reportParamObj.rdParamFileName = rdFimeName;                     // 리포트 mrd 파일명 
            this.reportParamObj.rdParamKey = 'KEY_NO';                            // 리포트 화면 Param Key 
            this.reportParamObj.rdParamValue = this.qltyGrdRsltSn;  // 리포트 화면 Param Key에 대한 Value  

            break;

          default:
            break;
        }

        storeSwitch.on(this.reportParamObj.selectedPopupComponent); 
      }, 0); // 0ms 딜레이로 컴포넌트 강제 리렌더링 (리포트 리렌더링 안 되는 케이스가 존재하여 추가)
    },
    putOtptNocs() {
        this.$apiCall.put(
            "/qltyGrd/putOtptNocs",
            {qltyGrdRsltSn: this.qltyGrdRsltSn},
            () => {
                console.log("출력건수 성공");
            },
            () => {
                alert("출력건수 실패");
            }
        );
    },
    generateYearOptions() {
      const currentYear = new Date().getFullYear() + 1;
      const startYear = 2020;
      const yearOptions = [];

      yearOptions.push({ value: '', text: '전체' });

      for (let year = currentYear; year >= startYear; year--) {
          yearOptions.push({ value: String(year), text: `${year}년` });
      }

      return yearOptions;
    },
    reset() {
      this.searchParam.qltyGrdJgmtSeCd = "1";
      this.searchParam.prdctKndCd = "";
      this.searchParam.qltyGrdPrgrsSttsCd = "";
      this.searchParam.inspYr = "";
      this.searchParam.inspHyr = "";
      this.searchParam.fromVldYmd = moment().startOf('year').format('YYYY-MM-DD');
      this.searchParam.toVldYmd = moment().format('YYYY-MM-DD');
      this.searchParam.prdYear = moment().format('YYYY');
    },
    searchBtn() {
      this.searchParam.page = 1
      this.search();
    },
  }
}
</script>

<style scoped>
.board_list.scroll > table { table-layout: fixed; width:120rem;}

.no-data-message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  margin: 20px 0px;
  border-radius: 10px;
  background-color: #f7f7f7;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 350px;
}

.no-data-message::before { content:''; width:2rem; height:2rem; background:url(@/assets/images/common/icon_search.png) no-repeat; background-size: 2rem; margin-right: 8px;}

.board_list.scroll > table tbody tr:hover{ background:var(--bg-gray10)}
</style>