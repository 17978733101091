<template>
  <div class="tab_area">
    <ul class="tab">
      <li
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{active: activeTab === index}"
          @click="selectTab(index)"
      >
        <button type="button" class="btn_tab">
          {{ tab.name }}
          <i v-if="activeTab === index" class="sr_only">선택됨</i>
        </button>
      </li>
    </ul>
    <div class="tab_conts_wrap">
      <section class="tab_conts" v-show="activeTab === 0">
        <h3 class="title2">고형연료제품 수입/판매 현황</h3>
        <sb-grid
            id="incmNtslGrid"
            :dataSource="incmNtslDataSource"
            :columns="incmNtslGridColumns"
            :gridattr="incmNtslGridAttr"
            v-model:grid="incmNtslGridObject"
            @loaded="incmNtslGridLoaded"
            :refreshGrid="incmNtslGridReload"
        />
      </section>
      <section class="tab_conts" v-show="activeTab === 1">
        <h3 class="title2">거래처별 고형연료제품 수입 현황</h3>
        <sb-grid
            id="incmDtlGrid"
            :dataSource="incmDtlDataSource"
            :columns="incmDtlGridColumns"
            :gridattr="incmDtlGridAttr"
            v-model:grid="incmDtlGridObject"
            @loaded="incmDtlGridLoaded"
            :refreshGrid="incmDtlGridReload"
        />
      </section>
      <section class="tab_conts" v-show="activeTab === 2">
        <h3 class="title2">사업장별 고형연료제품 판매 현황</h3>
        <sb-grid
            id="ntslDtlGrid"
            :dataSource="ntslDtlDataSource"
            :columns="ntslDtlGridColumns"
            :gridattr="ntslDtlGridAttr"
            v-model:grid="ntslDtlGridObject"
            @loaded="ntslDtlGridLoaded"
            :refreshGrid="ntslDtlGridReload"
        />
      </section>
    </div>
  </div>
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";
import SbGrid from "@/components/SbGrid.vue";

const CODE = {
  PRODUCT: "MBR010",
};
const API = {
  INCOME_NET_SALES_STATUS: "/prfmnc/sdflIncm/incm-ntsl-stts",
  INCOME_DETAIL_STATUS: "/prfmnc/sdflIncm/incm-dtl-stts",
  INCOME_NET_SALES_DETAIL_STATUS: "/prfmnc/sdflIncm/incm-ntsl-dtl-stts",
};

export default {
  components: {SbGrid},
  props: ['param'],
  data() {
    return {
      tabs: [
        { name: '수입/판매현황' },
        { name: '수입상세현황' },
        { name: '판매상세현황' },
      ],
      activeTab: 0,
      incmNtslGridReload: false,
      incmNtslGridObject: null,
      incmNtslDataSource: [],
      incmNtslGridColumns: [],
      incmNtslGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        excelExport: {
          fileName: '고형연료제품 수입/판매 현황.xlsx',
          includeHeader: true,
          keepMerge: true,
        },
      },
      incmDtlGridReload: false,
      incmDtlGridObject: null,
      incmDtlDataSource: [],
      incmDtlGridColumns: [],
      incmDtlGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        excelExport: {
          fileName: '거래처별 고형연료제품 수입 현황.xlsx',
          includeHeader: true,
          keepMerge: true,
        },
      },
      ntslDtlGridReload: false,
      ntslDtlGridObject: null,
      ntslDtlDataSource: [],
      ntslDtlGridColumns: [],
      ntslDtlGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        excelExport: {
          fileName: '사업장별 고형연료제품 판매 현황.xlsx',
          includeHeader: true,
          keepMerge: true,
        }
      },
      prdctItemCdList: [],
      incmNtslInfo: {},
      incmDtlInfo: {},
      ntslDtlInfo: {},
    };
  },
  computed: {},
  watch: {
    activeTab() {
      this.search();
    }
  },
  created() {
    this.initialize();
  },
  mounted() {
  },
  methods: {
    async initialize() {
      storeSwitch.on("loading");
      this.loadPrdctItemCd();
      await this.loadIncmNtslInfo();
      this.initializeIncmNtsl();
      await this.loadIncmDtlInfo();
      this.initializeIncmDtl();
      await this.loadNtslDtlInfo();
      this.initializeNtslDtl();
      storeSwitch.off("loading");
    },
    loadPrdctItemCd() {
      const prdctItemCdList = this.$store.getters.getCodeList({ groupCd: CODE.PRODUCT, useYn: 'Y', frstRefVl: "SRF" });
      this.prdctItemCdList = prdctItemCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    loadIncmNtslInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.INCOME_NET_SALES_STATUS,
            { bplcId: this.param.bplcId, prfmncYr: this.param.prfmncYr },
            data => {
              if (data.result) {
                this.incmNtslInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load incm ntsl info."));
            }
        );
      });
    },
    loadIncmDtlInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.INCOME_DETAIL_STATUS,
            { bplcId: this.param.bplcId, prfmncYr: this.param.prfmncYr },
            data => {
              if (data.result) {
                this.incmDtlInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load cryn prfmnc info."));
            }
        );
      });
    },
    loadNtslDtlInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.INCOME_NET_SALES_DETAIL_STATUS,
            { bplcId: this.param.bplcId, prfmncYr: this.param.prfmncYr },
            data => {
              if (data.result) {
                this.ntslDtlInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load ntsl dtl info."));
            }
        );
      });
    },
    initializeIncmNtsl() {
      this.incmNtslDataSource = this.incmNtslInfo.list;
      this.initializeIncmNtslGridColumns();
      this.initializeIncmNtslGridAttr();
      this.incmNtslGridReload = true;
    },
    initializeIncmDtl() {
      this.incmDtlDataSource = this.incmDtlInfo.list;
      this.initializeIncmDtlGridColumns();
      this.initializeIncmDtlGridAttr();
      this.incmDtlGridReload = true;
    },
    initializeNtslDtl() {
      this.ntslDtlDataSource = this.ntslDtlInfo.list;
      this.initializeNtslDtlGridColumns();
      this.initializeNtslDtlGridAttr();
      this.ntslDtlGridReload = true;
    },
    initializeIncmNtslGridColumns() {
      const list = this.incmNtslInfo.list || [];
      const uniquePrdctItemCdList = [...new Set(list.map(item => item.prdctKndCd))];
      const filteredPrdctItemList = this.prdctItemCdList
          .filter(item => uniquePrdctItemCdList.includes(item.value));
      const prdctItemList = filteredPrdctItemList.length === 1
          ? []
          : filteredPrdctItemList.map(item => ({ text: item.text, value: item.value }));

      this.incmNtslGridColumns = [
        { field: 'prfmncId', caption: '실적아이디', visible: false },
        { field: 'prfmncYm', caption: '실적연월', type: 'date', width: 100, mergeRow: true,
          total: { headerCss:'grid-header' },
          format: (date) => {
            if (date && date.length === 6) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              return `${yyyy}-${mm}`;
            }
            return date;
          },
        },
        { field: 'prdctKndCd', caption: '제품품목코드', visible: false },
        { field: 'prdctKndNm', caption: '고형연료제품종류', width: 150,
          total: {
            aggregates: [],
            header: [
              { template: '합계' },
              ...prdctItemList.map(item => ({ template: item.text }))
            ],
            headerCss:'grid-header',
          }
        },
        { caption: '고형연료제품 수입/판매 현황',
          columns: [
            { field: 'prdctSumCrfqty', caption: '이월량(톤)', dataType:'number', format: '#,##0.00', align: 'right',
              minWidth: 100, width: 16.66, unit: '%',
              total: {
                aggregates: [],
                header: [
                  { template: '{{sum}}', format: '#,##0.00' },
                  ...prdctItemList.map(item => ({
                    template: `{{prdctSumCrfqty${item.value}}}`, format: '#,##0.00'
                  }))
                ],
                headerCss:'grid-header',
              },
            },
            { field: 'prdctSumImpqty', caption: '수입량(톤)', dataType:'number', format: '#,##0.00', align: 'right',
              minWidth: 100, width: 16.66, unit: '%',
              total: {
                aggregates: [
                  {func: 'sum', require: 'prdctSumImpqty'},
                  ...prdctItemList.map(item => ({
                    func: (items) => items
                        .filter(entry => entry.prdctKndCd === item.value)
                        .reduce((sum, entry) => sum + (entry.prdctSumImpqty || 0), 0),
                    field: `prdctSumImpqty${item.value}`
                  }))
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00' },
                  ...prdctItemList.map(item => ({
                    template: `{{prdctSumImpqty${item.value}}}`, format: '#,##0.00'
                  }))
                ],
                headerCss:'grid-header',
              },
            },
            { field: 'prdctSumNsqty', caption: '판매량(톤)', dataType:'number', format: '#,##0.00', align: 'right',
              minWidth: 100, width: 16.66, unit: '%',
              total: {
                aggregates: [
                  {func: 'sum', require: 'prdctSumNsqty'},
                  ...prdctItemList.map(item => ({
                    func: (items) => items
                        .filter(entry => entry.prdctKndCd === item.value)
                        .reduce((sum, entry) => sum + (entry.prdctSumNsqty || 0), 0),
                    field: `prdctSumNsqty${item.value}`
                  }))
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00' },
                  ...prdctItemList.map(item => ({
                    template: `{{prdctSumNsqty${item.value}}}`, format: '#,##0.00'
                  }))
                ],
                headerCss:'grid-header',
              },
            },
            { field: 'prdctSumRsdqty', caption: '잔재물량(톤)', dataType:'number', format: '#,##0.00', align: 'right',
              minWidth: 100, width: 16.66, unit: '%',
              total: {
                aggregates: [
                  {func: 'sum', require: 'prdctSumRsdqty'},
                  ...prdctItemList.map(item => ({
                    func: (items) => items
                        .filter(entry => entry.prdctKndCd === item.value)
                        .reduce((sum, entry) => sum + (entry.prdctSumRsdqty || 0), 0),
                    field: `prdctSumRsdqty${item.value}`
                  }))
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00' },
                  ...prdctItemList.map(item => ({
                    template: `{{prdctSumRsdqty${item.value}}}`, format: '#,##0.00'
                  }))
                ],
                headerCss:'grid-header',
              },
            },
            { field: 'prdctSumRmnqty', caption: '잔량(톤)', dataType:'number', format: '#,##0.00', align: 'right',
              minWidth: 100, width: 16.66, unit: '%',
              total: {
                aggregates: [],
                header: [
                  { template: '{{sum}}', format: '#,##0.00' },
                  ...prdctItemList.map(item => ({
                    template: `{{prdctSumRmnqty${item.value}}}`, format: '#,##0.00'
                  }))
                ],
                headerCss:'grid-header',
              },
            },
            { field: 'prdctSumLcp', caption: '저위발열량(kcal/kg)', dataType:'number', format: '#,##0.00', align: 'right',
              minWidth: 100, width: 16.66, unit: '%',
              total: {
                aggregates: [],
                header: [
                  { template: '{{sum}}', format: '#,##0.00' },
                  ...prdctItemList.map(item => ({
                    template: `{{prdctSumLcp${item.value}}}`, format: '#,##0.00'
                  }))
                ],
                headerCss:'grid-header',
              },
            },
          ]
        },
      ];
    },
    initializeIncmNtslGridAttr() {
    },
    initializeIncmDtlGridColumns() {
      this.incmDtlGridColumns = [
        { field: 'prfmncId', caption: '실적아이디', visible: false },
        { field: 'prfmncYm', caption: '실적연월', type: 'date', width: 100, mergeRow: true, part: 'head',
          total: { headerCss:'grid-header' },
          format: (date) => {
            if (date && date.length === 6) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              return `${yyyy}-${mm}`;
            }
            return date;
          },
        },
        { field: 'mnftrNtnCd', caption: '제조국가코드', visible: false },
        { field: 'mnftrNtnNm', caption: '제조국가', width: 110,
          mergeRow: (data) => data.prfmncYm + '.' + data.mnftrNtnCd,
          total: { headerCss:'grid-header' },
        },
        { field: 'bzentyNm', caption: '업체명', width: 250, align: 'left', total: { headerCss:'grid-header' } },
        { caption: '수입 정보',
          columns: [
            { field: 'inspId', caption: '검사ID', width: 100, total: { headerCss:'grid-header' } },
            { field: 'hskCd', caption: 'HSK-NO', width: 120, total: { headerCss:'grid-header' } },
            { field: 'prdctKndNm', caption: '고형연료제품종류', width: 120, total: { headerCss:'grid-header' } },
            { field: 'rawmtrlKnd', caption: '원재료 종류', width: 100, total: { headerCss:'grid-header' } },
            { field: 'incmDclrNo', caption: '수입신고번호', width: 130, total: { headerCss:'grid-header' } },
          ],
        },
        { field: 'prdctImpqty', caption: '수입량(톤)', width: 140, align: 'right',
          dataType:'number', format: '#,##0.00',
          total: {
            aggregates: [{func: 'sum', require: 'prdctImpqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
      ]
    },
    initializeIncmDtlGridAttr() {
    },
    initializeNtslDtlGridColumns() {
      const list = this.ntslDtlInfo.list || [];
      const uniquePrdctItemCdList = [...new Set(list.map(item => item.prdctKndCd))];
      const filteredPrdctItemList = this.prdctItemCdList
          .filter(item => uniquePrdctItemCdList.includes(item.value));
      const prdctItemList = filteredPrdctItemList.length === 1
          ? []
          : filteredPrdctItemList.map(item => ({ text: item.text, value: item.value }));

      this.ntslDtlGridColumns = [
        { field: 'prfmncId', caption: '실적아이디', visible: false },
        { field: 'prfmncYm', caption: '실적연월', type: 'date', width: 100, mergeRow: true, part: 'head',
          total: { headerCss:'grid-header' },
          format: (date) => {
            if (date && date.length === 6) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              return `${yyyy}-${mm}`;
            }
            return date;
          },
        },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false },
        { field: 'ctpvStdgNm', caption: '시·도', width: 130,
          mergeRow: (data) => data.prfmncYm + '.' + data.ctpvStdgCd,
          total: { headerCss:'grid-header' },
        },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false },
        { field: 'sggStdgNm', caption: '시·군·구', width: 130,
          mergeRow: (data) => data.prfmncYm + '.' + data.ctpvStdgCd + "." + data.sggStdgCd,
          total: { headerCss:'grid-header' },
        },
        { field: 'ntslBplcId', caption: '판매사업장아이디', visible: false },
        { field: 'bplcNm', caption: '사업장명', minWidth: 100, width: 100, unit: '%', align: 'left', total: { headerCss:'grid-header' } },
        { field: 'prdctKndCd', caption: '제품종류코드', visible: false },
        { field: 'prdctKndNm', caption: '고형연료제품종류', width: 150,
          total: {
            aggregates: [],
            header: [
              { template: '합계' },
              ...prdctItemList.map(item => ({ template: item.text }))
            ],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctSumNsqty', caption: '판매량(톤)', width: 140, align: 'right',
          dataType:'number', format: '#,##0.00',
          total: {
            aggregates: [
              {func: 'sum', require: 'prdctSumNsqty'},
              ...prdctItemList.map(item => ({
                func: (items) => items
                    .filter(entry => entry.prdctKndCd === item.value)
                    .reduce((sum, entry) => sum + (entry.prdctSumNsqty || 0), 0),
                field: `prdctSumNsqty${item.value}`
              }))
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00' },
              ...prdctItemList.map(item => ({
                template: `{{prdctSumNsqty${item.value}}}`, format: '#,##0.00'
              }))
            ],
            headerCss:'grid-header',
          },
        },
      ];
    },
    initializeNtslDtlGridAttr() {
    },
    incmNtslGridLoaded() {
      this.incmNtslGridReload = false;
    },
    incmDtlGridLoaded() {
      this.incmDtlGridReload = false;
    },
    ntslDtlGridLoaded() {
      this.ntslDtlGridReload = false;
    },
    selectTab(index) {
      this.activeTab = index;
    },
    async incmNtslSearch() {
      await this.loadIncmNtslInfo();
      this.initializeIncmNtsl();
    },
    async incmDtlSearch() {
      await this.loadIncmDtlInfo();
      window.SBGrid3.setClientData(this.incmDtlGridObject, this.incmDtlInfo.list || []);
    },
    async ntslDtlSearch() {
      await this.loadNtslDtlInfo();
      this.initializeNtslDtl();
    },
    async search() {
      storeSwitch.on("loading");

      switch (this.activeTab) {
        case 0: // 수입/판매현황
            await this.incmNtslSearch();
          break;
        case 1: // 수입상세현황
            await this.incmDtlSearch();
          break;
        case 2: // 판매상세현황
            await this.ntslDtlSearch();
          break;
      }

      storeSwitch.off("loading");
    }
  }
}
</script>

<style scoped>

</style>