<template>
  <div class="desc_box">
    <i class="icon"><img src="@/assets/images/common/ico_desc.png" alt="이미지"></i>
    <div class="desc">
      <strong class="desc_title">폐자원에너지의 수입·제조·사용시설 검사와 실적정보 관리</strong>
      <p>폐자원에너지 종합정보관리시스템은 자원의 절약과 재활용촉진에 관한 법률 제25조의14에 근거한 법정 시스템으로, 고형연료제품(SRF) 등 폐자원에너지 10종의 전주기적 관리를 위한 통합 관리 플랫폼입니다. 제조·사용시설의 정기검사와 품질검사 신청 및 결과관리, 시설 인허가 정보, 품질기준 준수 여부, 개선명령 이행현황 등을 종합적으로 관리합니다. 특히 한국환경공단이 운영하는 폐자원에너지센터를 통해 시설 정기검사 업무를 수행하고, 관계 기관과 실시간 정보 공유를 통해 폐자원의 안전하고 효율적인 에너지화를 지원하며 자원순환사회 실현에 기여하고 있습니다. 
      </p>
    </div>
  </div>
  <div class="img_box_area pt_40" style="padding-bottom:7.2rem"><img src="@/assets/images/sub/img_intro.png" alt="폐자원에너지 종합정보관리시스템(폐자원에너지 수입, 제조, 사용 정보의 체계적 관리)은 제품 수입 제조, 사용 신고 정보, 제품 품질 검사 신청 정보, 제품 제조(사용) 시설 정기 검사 신청 정보, 시설, 품질 개선 이행 정보, 제품 공급 계획 정보, 폐자원에너지 생산 사용 정보 등을 제공합니다."></div>
  <div class="intro_bg"></div>
  <div class="intro_box">
    <i class="icon1"></i>
    <div class="cont">
      <h3>제품 품질관리</h3>
      <ul>
        <li>제조자의 과거 이력(생산용량, 생산량, 생산등급, 인증/인증취소현황 등)을 관리하여 적정한 품질 관리가 이루어질 수 있도록 유도</li>
        <li>시설검사, 품질검사 결과를 체계적으로 관리(데이터의 이력화)하여 지속적이고 안정적인 품질 관리 유도</li>
      </ul>
    </div>
  </div>
  <div class="intro_box">
    <i class="icon2"></i>
    <div class="cont">
      <h3>제품 유통관리</h3>
      <ul>
        <li>다양한 유통경로 확보를 통한 안정적 조업</li>
        <li>제조 규모와 수요의 정확한 판단을 통한 무분별한 신규사업자 진입 예방</li>
      </ul>
    </div>
  </div>
  <div class="intro_box">
    <i class="icon3"></i>
    <div class="cont">
      <h3>관련정보 제공</h3>
      <ul>
        <li>현재 및 향후 고형연료제품 공급량, 수요량 제공</li>
        <li>고형 연료 제품 생산자별 · 등급별 가격 공개를 통한 제품 가격 안정화 유도</li>
        <li>제조자 및 사용자 간 안정적인 시장 경제 체제 형성 (공급/수용, 제품 품질, 제품 가격 등) 유도</li>
      </ul>
    </div>
  </div>
</template>

<style scoped>

</style>