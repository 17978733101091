<template>
    <div class="member_wrap">
    <ol class="join_step_wrap">
      <li>
        <i class="step1"></i>
        <span>회원선택</span>
      </li>
      <li>
        <i class="step2"></i>
        <span>가입여부 확인</span>
      </li>
      <li class="active">
        <i class="step3"></i>
        <span>약관동의</span>
      </li>
      <li>
        <i class="step4"></i>
        <span>사용자정보 입력</span>
      </li>
      <li>
        <i class="step5"></i>
        <span>가입완료</span>
      </li>
    </ol>
  
    <h2 class="member_title">약관동의</h2>
    <div class="form_check terms_agree_all">
      <input type="checkbox" name="agree_chk0" id="agree_chk01" @change="allAgree" v-model="allAgreeChecked">
      <label for="agree_chk01">전체동의</label>
    </div>
    <h3 class="agree_title">만 14세 이상 가입 가능<span class="txt_point">(필수)</span></h3>
    <div class="agree_box">
      <span>본인은 만 14세 이상입니다.</span>
      <div class="chk_area">
        <div class="form_check">
          <input type="radio" name="agree_chk2" id="ageAgree" v-model="ageAgree" value="agree" @change="checkAllAgree">
          <label for="ageAgree">동의함</label>
        </div>
        <div class="form_check">
          <input type="radio" name="agree_chk2" id="ageAgree2" v-model="ageAgree" value="disagree" @change="checkAllAgree">
          <label for="ageAgree2">동의하지않음</label>
        </div>
      </div>
    </div>
    <h3 class="agree_title">서비스 이용약관<span class="txt_point">(필수)</span></h3>
    <div class="agree_txt">
      <srvc-agre />
    </div>
    <div class="agree_box">
      <span>서비스 이용약관에 동의하십니까?</span>
      <div class="chk_area">
        <div class="form_check">
          <input type="radio" name="agree_chk3" id="agree_chk31" v-model="serviceAgree" value="agree" @change="checkAllAgree">
          <label for="agree_chk31">동의함</label>
        </div>
        <div class="form_check">
          <input type="radio" name="agree_chk3" id="agree_chk32" v-model="serviceAgree" value="disagree" @change="checkAllAgree">
          <label for="agree_chk32">동의하지않음</label>
        </div>
      </div>
      <p>
        위의 서비스 이용약관에 대한 동의를 거부할 권리가 있습니다. <br>
        다만 동의를 거부하실 경우 이용자 가입 및 서비스 이용이 제한될 수 있습니다.
      </p>
    </div>
    <h3 class="agree_title">개인정보 수집 및 이용에 관한 안내<span class="txt_point">(필수)</span></h3>
    <div class="agree_txt">
      <prvc-agre />
    </div>
    <div class="agree_box">
      <span>개인정보 수집·이용에 대해 확인하셨습니까?</span>
      <div class="chk_area">
        <div class="form_check">
          <input type="radio" name="agree_chk4" id="agree_chk33" v-model="personalAgree" value="agree" @change="checkAllAgree">
          <label for="agree_chk33">예</label>
        </div>
      </div>
    </div>
    <h3 class="agree_title">개인정보 수집 및 이용에 관한 안내<span class="txt_point">(선택)</span></h3>
    <div class="agree_txt">
      <prvc-sel-agre />
    </div>
    <div class="agree_box">
      <span>개인정보 수집 이용에 대해 동의하십니까?</span>
      <div class="chk_area">
        <div class="form_check">
          <input type="radio" name="agree_chk5" id="agree_chk34" v-model="personalSelAgree" value="agree" @change="checkAllAgree">
          <label for="agree_chk34">동의함</label>
        </div>
        <div class="form_check">
          <input type="radio" name="agree_chk5" id="agree_chk35" v-model="personalSelAgree" value="disagree" @change="checkAllAgree">
          <label for="agree_chk35">동의하지않음</label>
        </div>
      </div>
    </div>
    <div class="btn_area center">
      <a href="javascript:void(0);" class="btn md primary" @click="agreNext($event)">다음</a>
    </div>
      
    </div>
  </template>
  
  <script>
  import SrvcAgre from "../join/components/SrvceAgre.vue";
  import PrvcAgre from "../join/components/PrvcAgre.vue";
  import PrvcSelAgre from "../join/components/PrvcSelAgre.vue";
  import storeRegister from "@/js/store/store-register";

  export default {
    components : {SrvcAgre, PrvcAgre, PrvcSelAgre},
    data() {
      return {
        brnoOpen: false,
        param : {
            brno1 : "",
            brno2 : "",
            brno3 : "",
            brno : "",
            userMngSe : "",   // 시설/업무 사용자 구분
            exsBzentyYn : "",
            di : "",
            ci : "",
            userNm: "",
            mblTelno: "",
            brdt: "",
            chcAgreYn: "",
        },
        allAgreeChecked : false,
        ageAgree : "",
        serviceAgree : "",
        personalAgree : "",
        personalSelAgree : "",
      };
    },

    computed: {},
    watch: {},
    created() {
    },
    beforeMount() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'  // 부드럽게 스크롤 이동
      });
    },
    mounted() {
    },
    methods: {
      navigateTo(path) {
        if (path) {
          this.$router.push(path)
              .catch(() => {
                console.error("Navigation Error");
              });
        }
      },
      allAgree(event) {
        if (event.target.checked) {
          this.ageAgree = 'agree';
          this.serviceAgree = 'agree';
          this.personalAgree = 'agree';
          this.personalSelAgree = 'agree';
        } else {
          this.ageAgree = '';
          this.serviceAgree = '';
          this.personalAgree = '';
          this.personalSelAgree = '';
        }
      },
      checkAllAgree() {
        if (this.ageAgree === 'disagree' || this.serviceAgree === 'disagree' || this.personalAgree === 'disagree') {
          this.allAgreeChecked = false;
        }
      },
      agreNext(event) {
        event.preventDefault();

        // 동의 유효성 체크
        if(this.ageAgree != "agree"){
          alert("만 14세 이상 가입 약관에 동의해주세요.");
          return;
        }

        if(this.serviceAgree != "agree"){
          alert("서비스 이용약관에 동의해주세요.");
          return;
        }

        if(this.personalAgree != "agree"){
          alert("개인정보 수집 및 이용에 관한 안내(필수)의 확인이 필요합니다.");
          return;
        }

        if(!this.personalSelAgree){
          alert("개인정보 수집 및 이용에 관한 안내(선택)의 선택이 필요합니다.");
          return;
        }

        if(this.personalSelAgree == "agree"){
          storeRegister.dispatch('updateJoinUserInfo', {chcAgreYn: 'Y'}); // user정보
        } else {
          storeRegister.dispatch('updateJoinUserInfo', {chcAgreYn: 'N'}); // user정보
        }

        storeRegister.dispatch('nextStep'); // 회원가입 단계처리
        this.navigateTo(`/user/UserInfo`);
        window.scrollTo({
          top: 0,
        });
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>