const MENU_LIST = [
    {
        menuGrpNm: "마이오피스현황",
        path: "/my-office/MyOfficeMain",
        items: []
    },
    {
        menuGrpNm: "회원정보",
        path: "/my-office/MbrInfo",
        items: []
    },
    {
        menuGrpNm: "사업장정보(업체/사업장/시설)",
        path: "/my-office/BizFacilityInfo",
        items: []
    },
    {
        menuGrpNm: "개선금지명령",
        path: "/my-office/ImpvProhbtCmd",
        items: [
            {
                menuNm: "개선금지명령 상세",
                path: "/my-office/ImpvProhbtCmdDetail"
            },
        ]
    },
    {
        menuGrpNm: "알림수신내역",
        path: "/my-office/NtcRcptn",
        items: [
            {
                menuNm: "알림수신내역 상세",
                path: "/my-office/NtcRcptnDetail"
            },
        ]
    },
    {
        menuGrpNm: "나의 문의내역",
        path: "/my-office/MyInqry",
        items: [
            {
                menuNm: "나의 문의내역 상세",
                path: "/my-office/MyInqryDetail"
            },
        ]
    },
    {
        menuGrpNm: "회원탈퇴",
        path: "/my-office/MbrWhdwl",
        items: []
    },
    {
        menuGrpNm: "권리의무승계신고",
        path: "/my-office/RghtScsnDclr",
        items: [
            {
                menuNm: "권리의무승계신고 상세",
                path: "/my-office/rght-scsn-dclr/RghtScsnDclrDetail",
                hidden: true,
            },
        ],
        isPublic: true,
    },
];

export default {
    getMenus: function (){
        return {
            groupName: "마이오피스",
            menuList: MENU_LIST,
        };
    },
};