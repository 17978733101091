<template>
  <label :for="uuid" style="display: none">{{ label }}</label>
  <input
      v-if="!hasUnit"
      :id="uuid"
      :type="type"
      :class="clazz"
      v-model="inputValue"
      :placeholder="computedPlaceholder"
      :disabled="disabled"
      :readonly="readonly"
      :maxlength="maxlength"
      :style="onAlign"
      @keyup.prevent="onKeyUp"
      @keydown.enter.prevent="enterKeyup()"
      @input="onInput"
      @change="onChange"
  />
  <div v-else :class="readonly || disabled ? 'input_unit_readonly' : 'input_unit'">
    <input
        :type="type"
        :id="uuid"
        :class="clazz"
        v-model="inputValue"
        :placeholder="computedPlaceholder"
        :disabled="disabled"
        :readonly="readonly"
        :maxlength="maxlength"
        :style="onAlign"
        @keyup.prevent="onKeyUp"
        @keydown.enter.prevent="enterKeyup()"
        @input="onInput"
        @change="onChange"
    />
    <i class="unit">{{ unit }}</i>
    <label :for="uuid" style="display: none">{{ label }}</label>
  </div>
</template>

<script>
import validator from "@/js/validator";
import { storeValidator } from "@/js/store/store-validator";
import { storeProhibit } from "@/js/store/store-prohibit";
import pp from '@/js/postposition';
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    clazz: {type: String, default: 'form_control'},
    label: {type: String, required: true},
    type: {type: String, default: 'text'},
    modelValue: {type: [String, Number], default: ''},
    placeholder: {type: String, default: ''},
    required: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    prohibited: {type: Boolean, default: true},
    readonly: {type: Boolean, default: false},
    unit: {type: String, default: ''},
    maxlength: {type: Number, default: null},
    validator: {type: String, default: ''},
    panel: {type: String, default: ''},
    align: {type: String, default: 'left'},
  },
  emits: [ 'update:modelValue', 'enterKeyup', 'keyup', 'change'],
  data() {
    return {
      uuid: uuidv4(),
      inputValue: this.formatValue(this.modelValue),
      hasError: false,
      errorMessage: "",
    };
  },
  computed: {
    onAlign() {
      return `text-align: ${this.align}`;
    },
    computedPlaceholder() {
      return !this.disabled && !this.placeholder ? `${this.label} 입력` : this.placeholder;
    },
    hasUnit() {
      return !!this.unit;
    }
  },
  watch: {
    modelValue() {
      this.inputValue = this.formatValue(this.modelValue);
      this.validate();
    },
  },
  created() {
    this.validate();
    storeValidator.add(this.$route.path, this.panel, this);
  },
  beforeUnmount() {
    storeValidator.remove(this.$route.path, this.panel, this.label);
  },
  methods: {
    validate() {
      this.validateEmpty();
      if (this.inputValue && this.inputValue.length > 0) {
        const inputValid = this.validateInput();
        const prohibitValid = this.validateProhibit();

        if (inputValid && prohibitValid) {
          this.hasError = false;
          this.errorMessage = "";
        }
      }
    },
    validateEmpty() {
      if (this.required && this.inputValue === "") {
        this.hasError = true;
        this.errorMessage = `${pp.eunNn(this.label)} 필수 입력값 입니다.`;
      } else {
        this.hasError = false;
        this.errorMessage = "";
      }
    },
    validateInput() {
      const valid = validator.getValidator(this.validator);
      if (valid) {
        const form = valid.format(this.inputValue);
        this.inputValue = form.value;
        if (form.completed && valid.regex.test(this.cleanValue(this.inputValue))) {
          return true;
        } else {
          this.hasError = true;
          this.errorMessage = `${this.label}의 형식이 틀립니다.`;
          return false;
        }
      } else {
        return true;
      }
    },
    validateProhibit() {
      if (storeProhibit.validate(this.inputValue)) {
        return true;
      } else {
        this.hasError = true;
        this.errorMessage = `${this.label}에 금지어가 포함되어있습니다.`;
        return false;
      }
    },
    onKeyUp(event) {
      this.$emit('keyup', event.target.value);
    },
    enterKeyup() {
      this.$emit('enterKeyup');
    },
    onInput(event) {
      const input = event.target;
      const initialCursorPosition = input.selectionStart;

      const oldValue = this.inputValue;

      // 포맷팅 후 값
      const formattedValue = this.formatValue(this.inputValue);
      this.inputValue = formattedValue;

      // 커서 위치 조정 로직
      let adjustedCursorPosition = initialCursorPosition;

      if (this.validator === 'number') {
        // 콤마 추가/제거로 인한 커서 위치 조정
        const oldCommaCount = (oldValue.match(/,/g) || []).length;
        const newCommaCount = (formattedValue.match(/,/g) || []).length;
        const commaDifference = newCommaCount - oldCommaCount;

        adjustedCursorPosition += commaDifference;

        // 커서 위치가 범위를 벗어나지 않도록 조정
        adjustedCursorPosition = Math.max(0, Math.min(adjustedCursorPosition, formattedValue.length));
      }

      this.validate();

      // Vue DOM 업데이트 후 커서 복원
      this.$nextTick(() => {
        input.setSelectionRange(adjustedCursorPosition, adjustedCursorPosition);
      });

      // 부모 컴포넌트에 업데이트된 값 전달
      this.$emit('update:modelValue', this.cleanValue(formattedValue));
    },
    formatValue(value) {
      if (this.validator && value !== null && value !== undefined) {
        const valid = validator.getValidator(this.validator);
        const format = valid.format(value);
        return format.value;
      }
      return value;
    },
    cleanValue(value) {
      if (this.validator === 'number') {
        const cleanedValue = parseFloat(value.replace(/,/g, ''));
        return isNaN(cleanedValue) ? 0 : cleanedValue;
      }
      return value;
    },
    focus() {
      const inputElement = document.getElementById(this.uuid);
      if (inputElement) {
        inputElement.focus();
      }
    },
    onChange() {
      this.$emit('change');
    },
  }
}
</script>

<style scoped>

</style>