<template>
  <div v-show="isOn" class="modal visible">
    <div class="popup_box"  style="width: 80%">
      <h1 class="popup_title">시설 변경신청 정보</h1>
      <div>
        <button type="button" class="modal_close" @click.prevent="hide">팝업닫기</button>
      </div>
      <div class="popup_cont" style="max-height: calc(90vh - 15px); overflow: hidden;overflow-y: auto;">
        <!-- 시설 정보 -->
        <div class="board_info">
          <h3 class="title1">시설 정보</h3>
        </div>
        <div class="board_write">
          <table>
            <caption></caption>
            <colgroup>
              <col style="width:15%">
              <col style="width:35%">
              <col style="width:15%">
              <col style="width:35%">
            </colgroup>
            <tbody>
            <tr>
              <th scope="row">시설명</th>
              <td :colspan="conds.showSrfMnf? '' : 3">
                {{ this.bizfaciltyFclt.fcltNm }}
              </td>
              <th scope="row" v-if="conds.showSrfMnf">생산제품종류</th>
              <td v-if="conds.showSrfMnf">
                {{getCdName(this.inputItemCdList, bizfaciltyFclt.prdctnPrdctKndCd)}}
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row">시설준공예정일자</th>
              <td>
                {{bizfaciltyFclt.fcltCmcnPrnmntYmd}}
              </td>
              <th scope="row">시설준공일자</th>
              <td>
                {{bizfaciltyFclt.fcltCmcnYmd}}
              </td>
            </tr>
            <tr v-if="conds.showAllSrf && conds.uncheck">
              <th scope="row">건조시설보유여부</th>
              <td>
                {{ this.bizfaciltyFclt.dhmdfyFcltHldYn === 'Y' ? '보유' : '미보유' }}
              </td>
              <th scope="row">변경공사예정여부</th>
              <td>
                {{ this.bizfaciltyFclt.chgCstrnPrnmntYn === 'Y' ? '예정' : '미정' }}
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row" v-if="conds.showAllSrf">시설단위</th>
              <td v-if="conds.showAllSrf" :colspan="conds.showSrfUse? '' : 3">
                {{getCdName(this.fcltUnitCdList, this.bizfaciltyFclt.fcltUnitCd)}}
              </td>
              <th scope="row" v-if="conds.showSrfUse">사용시설종류</th>
              <td v-if="conds.showSrfUse">
                {{getCdName(this.useFcltKndCdList, this.bizfaciltyFclt.useFcltKndCd)}}
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row">시설공정</th>
              <td>
                {{bizfaciltyFclt.fcltProcsCn}}
              </td>
              <th scope="row">형식</th>
              <td>
                {{bizfaciltyFclt.oprtngFrmCn}}
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row">시설상태</th>
              <td>
                {{getCdName(this.fcltSttsCdList, this.bizfaciltyFclt.fcltSttsCd)}}
              </td>
              <th scope="row">가동방식</th>
              <td>
                {{bizfaciltyFclt.oprtngMthCn}}
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row" v-if="bizfaciltyFclt.fcltSttsCd === '01'">가동시작일자</th>
              <td v-if="bizfaciltyFclt.fcltSttsCd === '01'" :colspan="bizfaciltyFclt.fcltSttsCd === '01' ? 3 : ''">
                {{bizfaciltyFclt.oprtngBgngYmd}}
              </td>
              <th scope="row" v-if="bizfaciltyFclt.fcltSttsCd === '02'">가동중지기간</th>
              <td v-if="bizfaciltyFclt.fcltSttsCd === '02'" :colspan="bizfaciltyFclt.fcltSttsCd === '02' ? 3 : ''">
                {{bizfaciltyFclt.oprtngHltBgngYmd}} <span> ~ </span>
                {{bizfaciltyFclt.oprtngHltEndYmd}}
              </td>
            </tr>
            <tr>
              <th scope="row" v-if="bizfaciltyFclt.fcltSttsCd === '03'">폐쇄일자</th>
              <td v-if="bizfaciltyFclt.fcltSttsCd === '03'">
                {{bizfaciltyFclt.clsgYmd}}
              </td>
              <th scope="row" v-if="['02','03'].includes(bizfaciltyFclt.fcltSttsCd)">가동중지/폐쇄사유</th>
              <td v-if="['02','03'].includes(bizfaciltyFclt.fcltSttsCd)" :colspan="bizfaciltyFclt.fcltSttsCd === '02' ? 3 : ''">
                {{bizfaciltyFclt.oprtngHltClsgRsn}}
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row">가동시간</th>
              <td style="white-space: nowrap;">
                <div class="form_group">
                  <div class="input_group">
                    {{bizfaciltyFclt.dilyOprtngHr}}
                    <span v-show="bizfaciltyFclt.dilyOprtngHr">hr/일</span>
                  </div>
                  <div class="input_group">
                    {{bizfaciltyFclt.anlOprtngDayCnt}}
                    <span v-show="bizfaciltyFclt.anlOprtngDayCnt">일/년</span>
                  </div>
                </div>
              </td>
              <th scope="row">시설 승인상태</th>
              <td :style="bizfaciltyFclt.aprvYn === 'N' ? 'color: red' : '' ">
                {{getCdName(this.aprvYnCdList, bizfaciltyFclt.aprvYn)}}
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row">시설용량</th>
              <td style="white-space: nowrap;" >
                <div class="form_group">
                  <div class="input_group" style="gap: 1rem">
                    {{bizfaciltyFclt.hfcapa}}
                    <span>kg/시간</span>
                  </div>
                  <div class="input_group" style="gap: 1rem">
                    {{bizfaciltyFclt.dilyFcltCap}}
                    <span>ton/일</span>
                  </div>
                  <div class="input_group" style="gap: 1rem">
                    {{formatNumber(bizfaciltyFclt.anlFcltCap)}}
                    <span>ton/년</span>
                  </div>
                </div>
              </td>
              <th scope="row">시설면적(m²)</th>
              <td>
                <div class="form_group">
                  <div class="input_group">
                    {{formatNumber(bizfaciltyFclt.fcar)}}
                    <span v-show="bizfaciltyFclt.fcar">m²</span>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="conds.showFire && conds.uncheck">
              <th scope="row">소각방식</th>
              <td>
                {{getCdName(this.fireMthCdList, this.bizfaciltyFclt.fireMthCd)}}
              </td>
              <th scope="row">소각유형</th>
              <td>
                {{getCdName(this.fireOperCdList, this.bizfaciltyFclt.fireOperCd)}}
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row">제품보관장소</th>
              <td>
                {{bizfaciltyFclt.prdctCstdyPlcCn}}
              </td>
              <th scope="row">제품보관유형</th>
              <td>
                {{getCdName(this.prdctCstdyTypeCdList, this.bizfaciltyFclt.prdctCstdyTypeCd)}}
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row">모델번호</th>
              <td>
                {{bizfaciltyFclt.mdlNo}}
              </td>
              <th scope="row">제조업체명</th>
              <td>
                {{bizfaciltyFclt.mnftrBzentyNm}}
              </td>
            </tr>
            <tr v-if="conds.showWes && conds.uncheck">
              <th scope="row">에너지자체사용여부</th>
              <td>
                {{bizfaciltyFclt.eneSlfUseYn === 'Y' ? '사용' : '미사용' }}
              </td>
              <th scope="row">발전시설 용량</th>
              <td>
                <div class="form_group">
                  <div class="input_group">
                    {{formatNumber(bizfaciltyFclt.elctgnFcltCap)}}
                    <span v-show="bizfaciltyFclt.elctgnFcltCap">kw</span>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="conds.uncheck && (conds.showFire || conds.showSrfUse)">
              <th scope="row" v-if="conds.showFire">소각폐기물발열량</th>
              <td v-if="conds.showFire">
                <div class="form_group">
                  <div class="input_group">
                    {{formatNumber(bizfaciltyFclt.fireWtCalqty)}}
                    <span v-if="bizfaciltyFclt.fireWtCalqty">kcal/kg</span>
                  </div>
                </div>
              </td>
              <th scope="row">증기엔탈피값</th>
              <td :colspan="conds.showFire ? 1 : 3">
                <div class="form_group">
                  <div class="input_group">
                    {{formatNumber(bizfaciltyFclt.vaporEnthlpVl)}}
                    <span v-show="bizfaciltyFclt.vaporEnthlpVl">Kcal/kg</span>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="(conds.showFire || conds.showSrfUse) && conds.uncheck">
              <th scope="row">보일러부하율</th>
              <td>
                <div class="form_group">
                  <div class="input_group">
                    {{bizfaciltyFclt.blrLdrt}}
                    <span v-show="bizfaciltyFclt.blrLdrt">%</span>
                  </div>
                </div>
              </td>
              <th scope="row">보일러효율</th>
              <td>
                <div class="form_group">
                  <div class="input_group">
                    {{bizfaciltyFclt.blrEfcnc}}
                    <span v-show="bizfaciltyFclt.blrEfcnc">%</span>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="conds.showBio && conds.uncheck">
              <th scope="row">BIO가스 생산량</th>
              <td>
                <div class="form_group">
                  <div class="input_group">
                    {{formatNumber(bizfaciltyFclt.bioGasPrdqty)}}
                    <span v-show="bizfaciltyFclt.bioGasPrdqty">N㎥</span>
                  </div>
                </div>
              </td>
              <th scope="row">BIO가스 공급시설 용량</th>
              <td>
                <div class="form_group">
                  <div class="input_group">
                    {{formatNumber(bizfaciltyFclt.bioGasSplyFcltCap)}}
                    <span v-show="bizfaciltyFclt.bioGasSplyFcltCap">N㎥</span>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="conds.showBio && conds.uncheck">
              <th scope="row">소화조 설계 유입량</th>
              <td>
                <div class="form_group">
                  <div class="input_group">
                    {{formatNumber(bizfaciltyFclt.dgstrDesignInsrtCap)}}
                    <span v-show="bizfaciltyFclt.dgstrDesignInsrtCap">N㎥</span>
                  </div>
                </div>
              </td>
              <th scope="row">소화조 체류일수</th>
              <td>
                <div class="form_group">
                  <div class="input_group">
                    {{bizfaciltyFclt.dgstrStayNmds}}
                    <span v-show="bizfaciltyFclt.dgstrStayNmds">일</span>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="conds.showBio && conds.uncheck">
              <th scope="row">소화조 용량</th>
              <td>
                <div class="form_group">
                  <div class="input_group">
                    {{formatNumber(bizfaciltyFclt.dgstrCap)}}
                    <span v-show="bizfaciltyFclt.dgstrCap">N㎥</span>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="conds.showSrfUse">
              <th scope="row">혼소비율</th>
              <td colspan="3">
                {{bizfaciltyFclt.synFireRtCn}}
              </td>
            </tr>
            <tr v-if="conds.showAllSrf">
              <th scope="row">시설신고허가일자</th>
              <td colspan="3">
                {{bizfaciltyFclt.fcltDclrPrmsnYmd}}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- 시설 투입품목 정보 -->
        <template v-if="['01-01', '01-02', '04-01'].includes(this.bizfaciltyFclt.bplcKndCd)">


        <div class="board_info mt_40">
          <h3 class="title2">시설 투입품목</h3>
        </div>
        <p class="page">
          <span class="total">전체 <strong>{{ this.fcltInputItemCount }}</strong>건</span>
          <span> 페이지 <strong>{{ this.searchParam.page }}/{{ this.maxPage }}</strong></span>
        </p>
        <div class="board_list">
          <table style="table-layout: fixed;">
            <caption> 시설 투입품목 - 투입원료, 투입비율, 일간투입량으로 구성 </caption>
            <colgroup>
              <col style="width:10%">
              <col style="width:50%">
              <col v-show="['01-01', '01-02'].includes(this.bizfaciltyFclt.bplcKndCd)" style="width:20%">
              <col v-show="['04-01'].includes(this.bizfaciltyFclt.bplcKndCd)" style="width:20%">
            </colgroup>
            <thead>
            <tr>
              <th scope="col">선택</th>
              <th scope="col">투입품목</th>
              <th v-show="['01-01', '01-02'].includes(this.bizfaciltyFclt.bplcKndCd)" scope="col">투입비율 (%)</th>
              <th v-show="['04-01'].includes(this.bizfaciltyFclt.bplcKndCd)" scope="col">일간투입량</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="fcltInputItemList.length <= 0">
              <td colspan="12">
                <div style="display: flex; justify-content: center;">
                  <div class="no-data-message">조회조건에 해당하는 데이터가 없습니다.</div>
                </div>
              </td>
            </tr>
            <tr v-else v-for="(item, index) in fcltInputItemList" :key="index"
                :class="{'checked-style': item.chk === 'Y'}">
              <td aria-label="선택">
                <input type="checkbox" id="chk1" v-model="item.chk"
                       true-value="Y" false-value="N" disabled
                >
              </td>
              <td aria-label="투입품목">{{ getCdName(this.inputItemCdList, item.inputItemCd)}}</td>
              <td v-show="['01-01', '01-02'].includes(this.bizfaciltyFclt.bplcKndCd)" aria-label="투입비율">{{item.inputItemCpstRt }}</td>
              <td v-show="['04-01'].includes(this.bizfaciltyFclt.bplcKndCd)" aria-label="일간투입량">{{item.dilyInpqty}}</td>
            </tr>
            </tbody>
          </table>
        </div>
          <!--  시설투입원료 그리드 끝       -->
        </template>
        <h3 class="title2">첨부서류</h3>
        <br-attach-file-list label="시설 첨부파일 목록"
                             ref="attachFileList"
                             :atchFileTaskSeCd="atchFileTaskSeCd"
                             :atchFileGroupId="bizfaciltyFclt.atchFileGroupId"
                             readonly
                             :useEtcFile="true"
        />
        <div class="btn_area center">
          <br-button label="닫기" class="btn sm tertiary" @click="hide" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";
import BrDatePicker from "@/components/BrDatePicker.vue";
import inspPagination from "@/views/sfli/components/inspPagination.vue";
import BrFormRadio from "@/components/BrFormRadio.vue";
import BrFormSelect from "@/components/BrFormSelect.vue";
import BrFormInput from "@/components/BrFormInput.vue";
import BrButton from "@/components/BrButton.vue";
import BrDateFromTo from "@/components/BrDateFromTo.vue";

const API = {
  FCLT_CHG_INFO: "/bizfacility/fclt-chg-info",
  FCLT_INPUT_ITEM: "/bizfacility/input-item-chg-info",
};

const PAGE_SIZE = 5;

const CODE = {
  ARPV_YN_CD: "SIS020", // 승인여부코드
  PRDCTN_PRDCT_KND_CD: "MBR010", // 생산제품종류코드
  INPUT_ITEM_CD: "MBR010", // 투입원료코드(품목코드)
  FCLT_STTS_CD: "MBR005", // 시설상태
  FCLT_UNIT_CD: "MBR011",   //시설단위
  USE_FCLT_KND_CD: "COM441",   //사용시설종류
  FIRE_MTH_CD: "COM122",   //소각방식
  FIRE_OPER_CD: "COM123",   //소각유형
  PRDCT_CSTDY_TYPE_CD: "COM406",   //제품보관유형
};

export default {
  components: {
    BrDateFromTo,
    BrButton,
    BrFormInput,
    BrFormSelect,
    BrFormRadio,
    inspPagination,
    BrDatePicker,
  },
  props: {
    bplcId: {type: String, required: true},
    fcltSqno: {type: Number, required: false},
    chgAplySn: {type: Number, required: true},
  },
  data() {
    return {
      isOn: false,
      maxPage: 1,
      searchParam: {
        page: 1,
        size: 5,
      },
      bplcNm: '',
      bizfaciltyFclt: {
        cmptncRgnHqCd: '',
        bplcKndCd: '',
        bplcId: '',
        fcltSqno: 0,
        fcltNm: '',
        qltyTaskIssuNo: '',
        prdctnPrdctKndCd: '',
        fcltCmcnPrnmntYmd: '',
        fcltCmcnYmd: '',
        dhmdfyFcltHldYn: '',
        chgCstrnPrnmntYn: '',
        useFcltKndCd: '',
        fcar: '',
        hfcapa: '',
        dilyFcltCap: '',
        anlFcltCap: '',
        fcltUnitCd: '',
        fcltProcsCn: '',
        oprtngFrmCn: '',
        oprtngMthCn: '',
        dilyOprtngHr: '',
        anlOprtngDayCnt: '',
        prdctCstdyPlcCn: '',
        prdctCstdyTypeCd: '',
        fcltSttsCd: '',
        aprvYn: '',
        oprtngBgngYmd: '',
        oprtngHltBgngYmd: '',
        oprtngHltEndYmd: '',
        clsgYmd: '',
        oprtngHltClsgRsn: '',
        mdlNo: '',
        mnftrBzentyNm: '',
        frstInspRsltSn: '',
        frstInspYmd: '',
        frstInspPassYn: '',
        chgInspTrgtYn: '',
        vaporEnthlpVl: '',
        blrLdrt: '',
        blrEfcnc: '',
        eneSlfUseYn: '',
        fireMthCd: '',
        fireOperCd: '',
        fireWtCalqty: '',
        bioGasPrdqty: '',
        bioGasSplyFcltCap: '',
        dgstrDesignInsrtCap: '',
        dgstrStayNmds: '',
        dgstrCap: '',
        elctgnFcltCap: '',
        synFireRtCn: '',
        atchFileGroupId: '',
        useYn: '',
        rmrkCn: '',
        regDt: '',
        rgtrId: '',
        mdfcnDt: '',
        mdfrId: '',
        inserted: '',
        updated: '',
        fcltDclrPrmsnYmd: '',
      },
      uncheckedList: [  // 미관리 종류
        '06-01',
        '07-01',
        '08-01',
        '09-01',
        '10-01',
      ],
      conds: {
        showWes: false,
        showFire: false,
        showBio: false,
        showAllSrf: false,
        showSrfMnf: false,
        showSrfUse: false,
        uncheck: false,
      },
      fcltInfoChgHstryList: [],
      fcltInfoChgHstryCount: 0,
      fcltInputItemList: [],
      fcltInputItemCount: 0,
      myMngFcltList: [],
      myMngFcltCount: 0,
      atchFileTaskSeCd: '',
      aprvYnCdList: [],
      fireMthCdList: [],
      fireOperCdList: [],
      inputItemCdList: [],
      prdctCstdyTypeCdList: [],
      fcltSttsCdList: [],
      fcltUnitCdList: [],
      useFcltKndCdList: [],
      radioBtnYnList: [
        {value: "Y", text: "대상"},
        {value: "N", text: "비대상"},
      ],
      useYnList: [
        {value: "Y", text: "사용"},
        {value: "N", text: "미사용"},
      ],
    };
  },
  computed: {
  },
  watch: {
    isOn(newVal){
      if(newVal){
        this.initialize();
      }
    }
  },
  created() {
    storeSwitch.setBulb('fcltChgAplyInfoPopup', this);
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeUnmount() {
  },
  methods: {
    show() {
      this.isOn = true;
    },
    hide() {
      this.isOn = false;
    },
    initialize() {
      this.aprvYnCdList = this.$store.getters.getCodeList({groupCd: CODE.ARPV_YN_CD, useYn: 'Y'});           // 승인여부코드
      this.fireMthCdList = this.$store.getters.getCodeList({groupCd: CODE.FIRE_MTH_CD, useYn: 'Y'});           // 소각방식
      this.fireOperCdList = this.$store.getters.getCodeList({groupCd: CODE.FIRE_OPER_CD, useYn: 'Y'});           // 소각유형
      this.prdctCstdyTypeCdList = this.$store.getters.getCodeList({groupCd: CODE.PRDCT_CSTDY_TYPE_CD, useYn: 'Y'});           // 제품보관유형
      this.fcltSttsCdList = this.$store.getters.getCodeList({groupCd: CODE.FCLT_STTS_CD, useYn: 'Y'});   // 시설상태
      this.fcltUnitCdList = this.$store.getters.getCodeList({groupCd: CODE.FCLT_UNIT_CD, useYn: 'Y'});           // 시설단위
      this.useFcltKndCdList = this.$store.getters.getCodeList({groupCd: CODE.USE_FCLT_KND_CD, useYn: 'Y'});           // 사용시설종류
      this.inputItemCdList = this.$store.getters.getCodeList({groupCd: CODE.INPUT_ITEM_CD, useYn: 'Y'});   // 투입품목코드

      this.readFcltInfo();
      this.searchFcltInputItem();
    },
    readFcltInfo() {
      this.$apiCall.get(
          API.FCLT_CHG_INFO,
          {
            bplcId: this.bplcId,
            fcltSqno: this.fcltSqno,
            chgAplySn: this.chgAplySn
          },
          data => {
            if (data.result) {
              for (const key of Object.keys(this.bizfaciltyFclt)) {
                this.bizfaciltyFclt[key] = data.result?.[key] ?? '';
              }
              this.setConditions(this.bizfaciltyFclt.bplcKndCd);
              this.$refs.attachFileList.load(this.atchFileTaskSeCd, this.bizfaciltyFclt.atchFileGroupId);
            }
          });
    },
    setConditions(bplcKndCd) {
      const mainCd = bplcKndCd.slice(0, 2);
      const subCd = bplcKndCd.slice(3, 5);

      // 초기화
      this.conds = {
        showWes: false,
        showFire: false,
        showBio: false,
        showAllSrf: false,
        showSrfMnf: false,
        showSrfUse: false,
        uncheck: !this.uncheckedList.includes(bplcKndCd),
      };

      // 조건 설정
      if (mainCd === '01') {
        if (['01', '02'].includes(subCd)) {
          switch (subCd){
            case '01' :
              this.atchFileTaskSeCd = 'FCTA'
              break;
            case '02' :
              this.atchFileTaskSeCd = 'FCTC'
              break;
          }
          this.conds.showAllSrf = true;
          this.conds.showSrfMnf = subCd === '01';
          this.conds.showSrfUse = subCd === '02';
        }
      } else {
        this.atchFileTaskSeCd = 'FCTB';
        this.conds.showWes = true;

        if (mainCd === '03') this.conds.showFire = true;
        else if (mainCd === '04') this.conds.showBio = true;
      }

      // 관리되지 않는 폐자원에너지 추가 조건 (06~10)
      if (['06', '07', '08', '09', '10'].includes(mainCd)) {
        this.conds.uncheck = true;
      }
    },
    searchFcltInputItem() {
      this.searchParam.size = PAGE_SIZE;
      this.$apiCall.get(
          API.FCLT_INPUT_ITEM,
          {
            bplcId: this.bplcId,
            fcltSqno: this.fcltSqno,
            chgAplySn: this.chgAplySn,
            page: this.searchParam.page,
            size: this.searchParam.size
          },
          data => {
            if (data.result.list) {
              this.fcltInputItemCount = data.result.total;
              this.fcltInputItemList = data.result.list;
            } else {
              this.fcltInputItemList = [];
            }
          });
    },
    getCdName(list, code) {
      if (!code) {
        return '';
      }
      const status = list.find(item => item.dtlCd === code);
      return status ? status.dtlCdNm : '';
    },
    formatNumber(value) {
      if (!value) return '';
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  }
}
</script>

<style scoped>
.selected {
  background-color: #f0f8ff; /* 선택 시 배경색 */
}

.subtitle-note {
  font-size: 15px; /* 폰트 크기 설정 */
  font-weight: normal; /* 굵기 설정 (기본 또는 normal) */
  color: #888888; /* 원하는 색상 코드로 변경 */
  margin-left: 10px;
}
</style>