<template>
  <rjct-rsn-area :prfmncBasicInfo="prfmncBasicInfo" />
  <prfmnc-mdfcn-prm-area :prfmncBasicInfo="prfmncBasicInfo" />

  <div class="board_info mt_40">
    <h3 class="title1" tabindex="-1" ref="sdflCrynTitle">고형연료제품 반입처리 현황</h3>
    <div>
      <br-button label="행 추가" size="xsm" format="primary line mr_5" @click="addCrynInfo" />
      <br-button label="행 삭제" size="xsm" format="point line" @click="deleteCrynInfo" />
    </div>
  </div>
  <sb-grid
      id="sdflCrynGrid"
      :dataSource="sdflCrynDataSource"
      :columns="sdflCrynGridColumns"
      :gridattr="sdflCrynGridAttr"
      v-model:grid="sdflCrynGridObject"
      @loaded="sdflCrynGridLoaded"
      :refreshGrid="sdflCrynGridReload"
      @change="changeSdflCryn"
  />

  <h3 class="title1" tabindex="-1" ref="sdflPrcsTitle">고형연료제품 수입/판매 현황</h3>
  <sb-grid
      id="sdflPrcsGrid"
      :dataSource="sdflPrcsDataSource"
      :columns="sdflPrcsGridColumns"
      :gridattr="sdflPrcsGridAttr"
      v-model:grid="sdflPrcsGridObject"
      @loaded="sdflPrcsGridLoaded"
      :refreshGrid="sdflPrcsGridReload"
      @row-clicked="sdflPrcsClick"
      @change="changeSdflPrcs"
  />
  <h5>※ 저위발열량은 최근 합격된 품질검사 정보를 자동으로 입력하고 있습니다. 반드시 검토하시고 잘못된 정보인 경우 수정하여 제출 바랍니다.</h5>

  <h3 class="title1">고형연료제품 판매 상세 현황 (단위:톤/월)</h3>
  <sb-grid
      id="ntslGrid"
      :dataSource="ntslDataSource"
      :columns="ntslGridColumns"
      :gridattr="ntslGridAttr"
      v-model:grid="ntslGridObject"
      @loaded="ntslGridLoaded"
      :refreshGrid="ntslGridReload"
      @change="changeNtsl"
  />

  <h3 class="title1">고형연료제품 종류별 공급 현황</h3>
  <div class="board_write thead">
    <table>
      <caption>폐기물 반입/투입량 - 이월량, 반입량, 투입량, 폐기반출량, 잔량으로 구성</caption>
      <colgroup>
        <col style="width: 25%;">
        <col style="width: 25%;">
        <col style="width: 25%;">
        <col >
      </colgroup>
      <thead>
      <tr>
        <th scope="col">SRF 성형</th>
        <th scope="col">SRF 비성형</th>
        <th scope="col">BIO-SRF 성형</th>
        <th scope="col">BIO-SRF 비성형</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td aria-label="SRF 성형" class="text-end">{{ formatNumber(prdctnKndInputInfo.srfPt) }} 톤</td>
        <td aria-label="SRF 비성형" class="text-end">{{ formatNumber(prdctnKndInputInfo.srfNpt) }} 톤</td>
        <td aria-label="BIO-SRF 성형" class="text-end">{{ formatNumber(prdctnKndInputInfo.bioSrfPt) }} 톤</td>
        <td aria-label="BIO-SRF 비성형" class="text-end">{{ formatNumber(prdctnKndInputInfo.bioSrfNpt) }} 톤</td>
      </tr>
      </tbody>
    </table>
  </div>

  <common-button-area
      :prfmncBasicInfo="prfmncBasicInfo"
      @save="save"
      @submission="submission"
  />

  <sdfl-incm-prcs-prfmnc-chg-dscnt-popup @eblc-aplcn="saveConfirmed" />
</template>

<script>
import SbGrid from "@/components/SbGrid.vue";
import utils from "@/js/utils/utils";
import RjctRsnArea from "@/views/prfmnc-rpt/dtl/components/RjctRsnArea.vue";
import PrfmncMdfcnPrmArea from "@/views/prfmnc-rpt/dtl/components/PrfmncMdfcnPrmArea.vue";
import CommonButtonArea from "@/views/prfmnc-rpt/dtl/components/CommonButtonArea.vue";
import { storeSwitch } from "@/js/store/store-switch";
import SdflIncmPrcsPrfmncChgDscntPopup from "@/views/prfmnc-rpt/dtl/components/popup/SdflIncmPrcsPrfmncChgDscntPopup.vue";

const CODE = {
  HSK: "COM417",
  CUSTODY_METHOD :"COM406", // 보관방법
}
const ROUTE = {
  PERFORMANCE_LIST: "/prfmnc-rpt/PrfmncRpt"
};
const API = {
  INCOME_PERFORMANCE: "/prfmnc/sdflIncm",
  INCOME_PROCESS_PERFORMANCE_CHANGE_CHECK: "/prfmnc/sdflIncm/prcs-chg-chck",
};

export default {
  props: ['prfmncBasicInfo', 'sdflCrynInfo', 'sdflPrcsInfo', 'ntslInfo'],
  components: {
    SdflIncmPrcsPrfmncChgDscntPopup,
    CommonButtonArea,
    RjctRsnArea,
    PrfmncMdfcnPrmArea,
    SbGrid,
  },
  data() {
    return {
      sdflCrynGridReload: false,
      sdflCrynGridObject: null,
      sdflCrynDataSource: [],
      sdflCrynGridColumns: [],
      sdflCrynGridAttr: {
        showRowNo: false,
        checkable: true,
        pageable: false,
        editable: {eventType: 'mouseup'},
        toolBar: [],
        hideDeleted: true,
        captionHeight: 40,
        height: 350,
      },
      incmRptList: [],
      inspIdList: [],
      incmDclrSnList: [],
      inspIdAndIncmDclrNoList: [],
      prdctSumImpqtyList: [],
      sdflPrcsGridReload: false,
      sdflPrcsGridObject: null,
      sdflPrcsDataSource: [],
      sdflPrcsGridColumns: [],
      sdflPrcsGridAttr: {
        showRowNo: false,
        pageable: false,
        editable: {eventType: 'mouseup'},
        hideDeleted: true,
        toolBar: [],
        height: 350,
      },
      ntslGridReload: false,
      ntslGridObject: null,
      ntslDataSource: [],
      ntslGridColumns: [],
      ntslGridAttr: {
        showRowNo: false,
        pageable: false,
        editable: {eventType: 'mouseup'},
        toolBar: [],
        height: 350,
      },
      hskCdList: [],
      cstdyMthdCdList: [],
      ntslList: [],
      selectedInfo: {
        inspSn: "",
        incmDclrSn: "",
      },
      prdctnKndInputInfo: {
        srfPt: 0,
        srfNpt: 0,
        bioSrfPt: 0,
        bioSrfNpt: 0
      },
      sdflPrcsGridInitialized: false,  // 최초 첫 행 선택을 관리하기 위한 플래그
      ntslGridInitialized: false  // 최초 첫 행 선택을 관리하기 위한 플래그
    };
  },
  computed: {
  },
  watch: {
    sdflCrynInfo() {
      this.initializeSdflCryn();
    },
    sdflPrcsInfo() {
      this.initializeSdflPrcs();
    },
    ntslInfo() {
      this.initializeNtsl();
    }
  },
  created() {
    this.initialize();
  },
  mounted() {
  },
  methods: {
    initialize() {
      this.loadHskCd();
      this.loadCstdyMthdCd();
    },
    loadHskCd() {
      const hskCdList = this.$store.getters.getCodeList({ groupCd: CODE.HSK, useYn: 'Y' });
      this.hskCdList = hskCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd,
        frstRefVl: item.frstRefVl,
      }));
    },
    loadCstdyMthdCd() {
      const cstdyMthdCdList = this.$store.getters.getCodeList({ groupCd: CODE.CUSTODY_METHOD, useYn: 'Y' });
      this.cstdyMthdCdList = cstdyMthdCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    initializeSdflCryn() {
      this.sdflCrynDataSource = this.sdflCrynInfo.list;
      this.incmRptList = this.sdflCrynInfo.incmRptList;
      this.inspIdList = this.sdflCrynInfo.inspIdList;
      this.incmDclrSnList = this.sdflCrynInfo.incmDclrSnList;
      this.inspIdAndIncmDclrNoList = this.sdflCrynInfo.inspIdAndIncmDclrNoList;
      this.initializeSdflCrynGridColumns();
      this.initializeSdflCrynGridAttr();
      this.sdflCrynGridReload = true;
    },
    initializeSdflPrcs() {
      this.sdflPrcsDataSource = { data: this.sdflPrcsInfo.list, schema: { id: (data) => data.inspSn + '-' + data.incmDclrSn }};
      this.initializeSdflPrcsGridColumns();
      this.initializeSdflPrcsGridAttr();
      this.sdflPrcsGridReload = true;
    },
    initializeNtsl() {
      this.ntslList = this.ntslInfo.list;
      this.setPrdctnKndInputInfo();
      this.initializeNtslGridColumns();
      this.initializeNtslGridAttr();
      this.ntslGridReload = true;
    },
    initializeSdflCrynGridColumns() {
      this.sdflCrynGridColumns = [
        { field: 'inspSn', caption: '검사ID', part:'head', width: 120,
          type: 'combo',
          items: this.inspIdList,
          comboLabel: 'text',
          comboValue: 'value',
        },
        { field: 'incmDclrSn', caption: '수입신고번호', part:'head', width: 145,
          type: 'combo',
          items: this.incmDclrSnList,
          comboLabel: 'text',
          comboValue: 'value',
          master: 'inspSn',
          masterField: 'inspSn',
          useFilter: true,
        },
        { field: 'hskCd', caption: 'HSK.No', width: 115, editable: false, colCss: 'grid-disabled' },
        { field: 'prdctKndNm', caption: '제품종류', width: 140, editable: false, colCss: 'grid-disabled' },
        { field: 'rawmtrlKnd', caption: '원재료 종류', width: 110, editable: false, align: 'left', colCss: 'grid-disabled' },
        { field: 'rawmtrlDtlNm', caption: '세부명칭', width: 180,  align: 'left', editable: false, colCss: 'grid-disabled',
          type: 'combo',
          items: this.hskCdList,
          comboLabel: 'frstRefVl',
          comboValue: 'value',
        },
        { field: 'incmPmtqty', caption: '수입<br>가능물량(톤)', width: 100, editable: false, colCss: 'grid-disabled',
          dataType: 'number', align: 'right', format: '#,##0.00',
        },
        { field: 'incmDclqty', caption: '수입신고량', width: 100, editable: false, colCss: 'grid-disabled',
          dataType: 'number', align: 'right', format: '#,##0.00',
        },
        { field: 'prdctSumImpqty', caption: '이전 수입량<br>합계(톤)', width: 100, editable: false, colCss: 'grid-disabled',
          dataType: 'number', align: 'right', format: '#,##0.00',
        },
        { field: 'dmstUnloadHburNm', caption: '수입항', width: 100, editable: false, colCss: 'grid-disabled' },
        { field: 'prdctImpqty', caption: '수입량(톤)', width: 100,
          type: 'number', dataType: 'number', align: 'right', format: '#,##0.00'
        },
        { field: 'incmPsbltyRmnqty', caption: '수입잔량(톤)', width: 100, editable: false,
          dataType: 'number', align: 'right', format: '#,##0.00',
          getValue: (value, field, rowItem) => {
            const incmPmtqty = rowItem.data.incmPmtqty || 0;

            const allItems = window.SBGrid3.getAllItems(this.sdflCrynGridObject);
            const prdctImpqty = this.sanitizeFloatingPointError(allItems.filter(item => item.inspSn === rowItem.data.inspSn).reduce((sum, item) => sum + (item.prdctImpqty || 0), 0));

            const prdctSumImpqty = rowItem.data.prdctSumImpqty || 0;

            return utils.round2(incmPmtqty - prdctSumImpqty - prdctImpqty);
          },
          colCss: (data) => {
            const incmPmtqty = data.incmPmtqty || 0;

            const allItems = window.SBGrid3.getAllItems(this.sdflCrynGridObject);
            const prdctImpqty = this.sanitizeFloatingPointError(allItems.filter(item => item.inspSn === data.inspSn).reduce((sum, item) => sum + (item.prdctImpqty || 0), 0));

            const prdctSumImpqty = data.prdctSumImpqty || 0;

            const result = utils.round2(incmPmtqty - prdctSumImpqty - prdctImpqty);

            if (result < 0) {
              return 'grid-danger';
            } else {
              return 'grid-disabled';
            }
          }
        },
        { field: 'dptreYmd', caption: '현지출발일', width: 100,
          type: 'date', calendarType: 'date',
          format: (date) => {
            if (date && date.length === 8) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              const dd = date.substring(6, 8);
              return `${yyyy}-${mm}-${dd}`;
            }
            return date;
          },
        },
        { field: 'arvlPrnmntYmd', caption: '도착(예정)일자', width: 100,
          type: 'date', calendarType: 'date',
          format: (date) => {
            if (date && date.length === 8) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              const dd = date.substring(6, 8);
              return `${yyyy}-${mm}-${dd}`;
            }
            return date;
          },
        },
        { field: 'prdctCstdyTypeCd', caption: '보관시설', width: 112,
          type: 'combo',
          items: this.cstdyMthdCdList,
          comboLabel: 'text',
          comboValue: 'value',
        },
        { field: 'mnftrNtnNm', caption: '제조국', width: 100, editable: false, colCss: 'grid-disabled' },
        { field: 'bzentyNm', caption: '제조업체명', width: 255, editable: false, align: 'left', colCss: 'grid-disabled' },
        { field: 'rbeoNm', caption: '환경청', width: 100, editable: false, colCss: 'grid-disabled' },
        { field: 'prdctLcp', caption: '저위발열량(kcal/kg)', visible: false, editable: false },
      ]
    },
    initializeSdflCrynGridAttr() {
      const itemCount = this.sdflCrynInfo.list.length;

      this.sdflCrynGridAttr.height = Math.max((itemCount * 30) + 110, 500);

      this.sdflCrynGridAttr.rowCss = (data) => {
        const checkedRowKey = window.SBGrid3.getCheckedKeys(this.sdflCrynGridObject);

        if (checkedRowKey.includes(data._key_)) {
          return 'grid-danger';
        }

        return '';
      }
    },
    initializeSdflPrcsGridColumns() {
      this.sdflPrcsGridColumns = [
        { field: 'inspSn', caption: '검사일련번호', visible: false },
        { field: 'incmDclrSn', caption: '수입신고일련번호', visible: false },
        { field: 'inspId', caption: '검사ID', part:'head', width: 120, editable: false, total: { headerCss:'grid-header' } },
        { field: 'incmDclrNo', caption: '수입신고번호', part:'head', width: 160, editable: false, total: { headerCss:'grid-header' } },
        { field: 'prdctKndNm', caption: '제품종류', width: 140, editable: false, colCss: 'grid-disabled', total: { headerCss:'grid-header' } },
        { field: 'rawmtrlKnd', caption: '원재료 종류', width: 110, editable: false, align: 'left', colCss: 'grid-disabled',
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          }
        },
        { field: 'prdctCrfqty', caption: '이월량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          editable: false, colCss: 'grid-disabled',
          total: {
            aggregates: [{func: 'sum', require: 'prdctCrfqty', nullAs: 0}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctImpqty', caption: '수입량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          editable: false, colCss: 'grid-disabled',
          total: {
            aggregates: [{func: 'sum', require: 'prdctImpqty', nullAs: 0}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctSumNsqty', caption: '공급량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          editable: false, colCss: 'grid-disabled',
          total: {
            aggregates: [{func: 'sum', require: 'prdctSumNsqty', nullAs: 0}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctRsdqty', caption: '잔재물(톤)', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          total: {
            aggregates: [{func: 'sum', require: 'prdctRsdqty', nullAs: 0}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctRmnqty', caption: '잔량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          editable: false,
          total: {
            aggregates: [{
              func: (items) => {
                const totalPrdctCrfqty = this.sanitizeFloatingPointError(items.reduce((sum, item) => sum + (item.prdctCrfqty || 0), 0));
                const totalPrdctImpqty = this.sanitizeFloatingPointError(items.reduce((sum, item) => sum + (item.prdctImpqty || 0), 0));
                const totalPrdctNsqty  = this.sanitizeFloatingPointError(items.reduce((sum, item) => sum + (item.prdctSumNsqty || 0), 0));
                const totalPrdctRsdqty = this.sanitizeFloatingPointError(items.reduce((sum, item) => sum + (item.prdctRsdqty || 0), 0));
                return totalPrdctCrfqty + totalPrdctImpqty - totalPrdctNsqty - totalPrdctRsdqty;
              },
              field: 'diffTotal'
            }],
            header: [{ template: '{{diffTotal}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
          getValue: (value, field, rowItem) => {
            const prdctCrfqty = rowItem.data.prdctCrfqty || 0;
            const prdctImpqty = rowItem.data.prdctImpqty || 0;
            const prdctSumNsqty = rowItem.data.prdctSumNsqty || 0;
            const prdctRsdqty = rowItem.data.prdctRsdqty || 0;

            return prdctCrfqty + prdctImpqty - prdctSumNsqty - prdctRsdqty;
          },
          colCss: (data) => {
            const prdctCrfqty = data.prdctCrfqty || 0;
            const prdctImpqty = data.prdctImpqty || 0;
            const prdctSumNsqty = data.prdctSumNsqty || 0;
            const prdctRsdqty = data.prdctRsdqty || 0;

            const result = prdctCrfqty + prdctImpqty - prdctSumNsqty - prdctRsdqty;

            if (result < 0) {
              return 'grid-danger';
            } else {
              return 'grid-disabled';
            }
          }
        },
        { field: 'prdctLcp', caption: '저위발열량(kcal/kg)', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          total: {
            aggregates: [
              { func: (items) => {
                  const filteredItems = items.filter(item => item.prdctLcp > 0);
                  const sumPrdctImpqty = filteredItems.reduce((total, item) => total + (item.prdctImpqty || 0), 0);
                  const sumPrdqtyLcp = filteredItems.reduce((total, item) => {
                    const prdctImpqty = item.prdctImpqty || 0;
                    const prdctLcp = item.prdctLcp || 0;
                    return total + (prdctImpqty * prdctLcp);
                  }, 0);

                  return sumPrdctImpqty > 0 ? utils.round2(sumPrdqtyLcp / sumPrdctImpqty) : 0;
                },
                field: 'prdctLcpAvg'
              }
            ],
            header: [{ template: '{{prdctLcpAvg}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
      ]
    },
    initializeSdflPrcsGridAttr() {
      this.sdflPrcsGridAttr.rowCss = (data) => {
        const focusedRowKey = window.SBGrid3.getFocusedKey(this.sdflPrcsGridObject);

        if (focusedRowKey === data._key_) {
          return 'grid-good';
        }

        return '';
      }
    },
    initializeNtslGridColumns() {
      this.ntslGridColumns = [
        { field: 'inspSn', caption: '검사일련번호', visible: false },
        { field: 'incmDclrSn', caption: '수입신고일련번호', visible: false },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false },
        { field: 'ntslBplcId', caption: '판매사업장아이디', visible: false },
        { caption: '판매 사업장',
          columns: [
            { field: 'inspId', caption: '검사ID', part:'head', width: 100, editable: false, total: { headerCss:'grid-header' } },
            { field: 'incmDclrNo', caption: '수입신고번호', part:'head', width: 120, editable: false, total: { headerCss:'grid-header' } },
            { field: 'prdctKndNm', caption: '제품종류', width: 140, editable: false, colCss: 'grid-disabled', total: { headerCss:'grid-header' } },
            { field: 'ctpvStdgNm', caption: '시·도', width: 120, editable: false, colCss: 'grid-disabled', total: { headerCss:'grid-header' } },
            { field: 'sggStdgNm', caption: '시·군·구', width: 120, editable: false, colCss: 'grid-disabled', total: { headerCss:'grid-header' } },
            { field: 'bplcNm', caption: '판매 사업장명', minWidth: 100, maxWidth: 1000, width: 100, unit: '%', align: 'left',
              editable: false, colCss: 'grid-disabled',
              total: {
                aggregates: [],
                header: [{ template: '합계', align: 'right' }],
                headerCss:'grid-header',
              }
            },
          ]
        },
        { field: 'prdctNsqty', caption: '공급량(톤)', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          total: {
            aggregates: [{func: 'sum', require: 'prdctNsqty', nullAs: 0}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
      ]
    },
    initializeNtslGridAttr() {
    },
    sdflCrynGridLoaded() {
      this.sdflCrynGridReload = false;
    },
    sdflPrcsGridLoaded() {
      this.sdflPrcsGridReload = false;

      if (!this.sdflPrcsGridInitialized && this.sdflPrcsInfo.list && this.sdflPrcsInfo.list.length > 0) {
        this.selectedInfo.inspSn = this.sdflPrcsInfo.list[0].inspSn;
        this.selectedInfo.incmDclrSn = this.sdflPrcsInfo.list[0].incmDclrSn;
        this.sdflPrcsGridInitialized = true; // 첫 행 선택 완료 표시
        if (this.ntslGridObject && !this.ntslGridInitialized) {
          this.setNtslGridData();
          this.ntslGridInitialized = true; // 첫 행 선택 완료 표시
        }
      }

      if (this.selectedInfo.inspSn && this.selectedInfo.incmDclrSn) {
        const key = this.selectedInfo.inspSn + '-' + this.selectedInfo.incmDclrSn;
        const rowItem= window.SBGrid3.getRow(this.sdflPrcsGridObject, key);
        const column = window.SBGrid3.getColumn(this.sdflPrcsGridObject, 0)[0];
        window.SBGrid3.moveFocus(this.sdflPrcsGridObject, rowItem, column);
      }
    },
    ntslGridLoaded() {
      this.ntslGridReload = false;

      if (!this.ntslGridInitialized) { // 최초 한번만 실행하도록 플래그 사용
        // 첫 행 선택
        if (!this.selectedInfo.inspSn && !this.selectedInfo.incmDclrSn && this.sdflPrcsInfo.list && this.sdflPrcsInfo.list.length > 0) {
          this.selectedInfo.inspSn = this.sdflPrcsInfo.list[0].inspSn;
          this.selectedInfo.incmDclrSn = this.sdflPrcsInfo.list[0].incmDclrSn;
        }

        this.setNtslGridData();
        this.ntslGridInitialized = true; // 첫 행 선택 완료
      }
    },
    changeSdflCryn(values) {
      values.forEach(item => {
        if (item.field === 'inspSn') {
          this.setCrynEmptyData(item);
          this.resetPrdctImpqty(item);
        } else if (item.field === 'incmDclrSn') {
          if (item.value) {
            this.setCrynBasicData(item.key);
            this.addPrcsData(item.key);
          }
          this.setPrdctImpqty();
        } else if (item.field === 'prdctImpqty') {
          const data = window.SBGrid3.getRowData(this.sdflCrynGridObject, item.key);
          if(!data.prdctImpqty){
            data.prdctImpqty = 0;
          }

          this.setPrdctImpqty();
        }
      });
    },
    changeNtsl(values) {
      values.forEach(item => {
        if (item.field === 'prdctNsqty') {
          const rowData = window.SBGrid3.getRowData(this.ntslGridObject, item.key);

          const matchingItem = this.ntslList.find(ntslItem =>
              ntslItem.inspSn === rowData.inspSn &&
              ntslItem.incmDclrSn === rowData.incmDclrSn &&
              ntslItem.ntslBplcId === rowData.ntslBplcId
          );

          if (matchingItem) {
            matchingItem.status = "U";
          }
          matchingItem.prdctNsqty = item.value;

          this.setPrdctSumNsqty(rowData);
          this.setPrdctnKndInputInfo();

          //2025.03.17 null 인경우 0처리
          if(!rowData.prdctNsqty){
            rowData.prdctNsqty = 0;
          }
        }
      });
    },
    setCrynEmptyData(changeItem) {
      const key = changeItem.key;
      const data = this.incmRptList.find(item => item.inspSn === changeItem.value);

      const matchingItems = this.incmDclrSnList.filter(item => item.inspSn === changeItem.value);
      const matchingCount = matchingItems.length;
      const incmDclrSnValue = matchingCount === 1 ? matchingItems[0].value : '';

      const finalResult = [
        { key: key, field: 'incmDclrSn', value: incmDclrSnValue },
        { key: key, field: 'hskCd', value: data.hskCd },
        { key: key, field: 'prdctKndNm', value: data.prdctKndNm },
        { key: key, field: 'rawmtrlKnd', value: data.rawmtrlKnd },
        { key: key, field: 'rawmtrlDtlNm', value: data.hskCd },
        { key: key, field: 'incmPmtqty', value: data.incmPmtqty },
        { key: key, field: 'incmDclqty', value: 0 },
        { key: key, field: 'dmstUnloadHburNm', value: '' },
        { key: key, field: 'prdctImpqty', value: 0 },
        { key: key, field: 'dptreYmd', value: '' },
        { key: key, field: 'arvlPrnmntYmd', value: '' },
        { key: key, field: 'prdctCstdyTypeCd', value: '' },
        { key: key, field: 'mnftrNtnNm', value: data.mnftrNtnNm },
        { key: key, field: 'bzentyNm', value: data.bzentyNm },
        { key: key, field: 'rbeoNm', value: '' },
        { key: key, field: 'prdctLcp', value: data.prdctLcp || 0 },
      ];

      window.SBGrid3.setValues(this.sdflCrynGridObject, finalResult);
    },
    setCrynBasicData(key) {
      const rowData = window.SBGrid3.getRowData(this.sdflCrynGridObject, key);
      const data = this.incmRptList.find(item => item.inspSn === rowData.inspSn && item.incmDclrSn === rowData.incmDclrSn);

      if (data) {
        const finalResult = [
          { key: key, field: 'hskCd', value: data.hskCd },
          { key: key, field: 'prdctKndNm', value: data.prdctKndNm },
          { key: key, field: 'rawmtrlKnd', value: data.rawmtrlKnd },
          { key: key, field: 'rawmtrlDtlNm', value: data.hskCd },
          { key: key, field: 'incmPmtqty', value: data.incmPmtqty },
          { key: key, field: 'incmDclqty', value: data.incmDclqty },
          { key: key, field: 'dmstUnloadHburNm', value: data.dmstUnloadHburNm },
          { key: key, field: 'prdctSumImpqty', value: data.prdctSumImpqty },
          { key: key, field: 'mnftrNtnNm', value: data.mnftrNtnNm },
          { key: key, field: 'bzentyNm', value: data.bzentyNm },
          { key: key, field: 'rbeoNm', value: data.rbeoNm },
        ];

        window.SBGrid3.setValues(this.sdflCrynGridObject, finalResult);
      }
    },
    addPrcsData(key) {
      const rowData = window.SBGrid3.getRowData(this.sdflCrynGridObject, key);
      const allSdflPrcsItems = window.SBGrid3.getAllItems(this.sdflPrcsGridObject);

      const exists = allSdflPrcsItems.some(item => item.inspSn === rowData.inspSn && item.incmDclrSn === rowData.incmDclrSn);

      if (!exists) {
        const allSdflCrynItems = window.SBGrid3.getAllItems(this.sdflCrynGridObject);
        const totalPrdctImpqty = this.sanitizeFloatingPointError(allSdflCrynItems
            .filter(item => item.inspSn === rowData.inspSn && item.incmDclrSn === rowData.incmDclrSn)
            .reduce((sum, item) => sum + (item.prdctImpqty || 0), 0));

        const data = {
          inspSn: rowData.inspSn,
          incmDclrSn: rowData.incmDclrSn,
          inspId: (this.inspIdList.find(item => item.value === rowData.inspSn) || {}).text,
          incmDclrNo: (this.incmDclrSnList.find(item => item.value === rowData.incmDclrSn) || {}).text,
          prdctKndNm: rowData.prdctKndNm,
          rawmtrlKnd: rowData.rawmtrlKnd,
          prdctCrfqty: 0,
          prdctImpqty: totalPrdctImpqty,
          prdctSumNsqty: 0,
          prdctRsdqty: 0,
          prdctRmnqty: 0,
          prdctLcp: rowData.prdctLcp,
        };

        const firstRowKey = allSdflPrcsItems.length > 0
            ? allSdflPrcsItems[0].key
            : null;

        window.SBGrid3.insertRow(this.sdflPrcsGridObject, firstRowKey, data);
      }
    },
    resetPrdctImpqty(item) {
      const allSdflPrcsItems = window.SBGrid3.getAllItems(this.sdflPrcsGridObject);

      const finalResult = allSdflPrcsItems
          .filter(prcsItem => prcsItem.inspSn === item.oldValue)
          .map(prcsItem => ({
            key: prcsItem._key_,
            field: 'prdctImpqty',
            value: 0
          }));

      // 검사ID를 변경했을경우 일단 0으로 처리
      window.SBGrid3.setValues(this.sdflPrcsGridObject, finalResult);
      this.setPrdctImpqty();
    },
    setPrdctImpqty() { // 수입/판매 현황 수입량 설정
      const allSdflCrynItems = window.SBGrid3.getAllItems(this.sdflCrynGridObject);

      const groupedTotals = allSdflCrynItems.reduce((acc, item) => {
        if (item.inspSn && item.incmDclrSn) { // 값이 존재하는 경우에만 처리
          const key = `${item.inspSn}-${item.incmDclrSn}`;
          if (!acc[key]) {
            acc[key] = {
              key: key,
              inspSn: item.inspSn,
              incmDclrSn: item.incmDclrSn,
              prdctImpqty: 0,
            };
          }
          acc[key].prdctImpqty += item.prdctImpqty || 0;
        }
        return acc;
      }, {});

      const finalResult = Object.values(groupedTotals).map(item => ({
        key: item.key,
        field: 'prdctImpqty',
        value: item.prdctImpqty
      }));

      window.SBGrid3.setValues(this.sdflPrcsGridObject, finalResult);
    },
    setPrdctSumNsqty(rowData) { // 수입/판매 현황 공급량 설정
      const prdctSumNsqty = this.sanitizeFloatingPointError(this.ntslList
          .filter(item => item.inspSn === rowData.inspSn && item.incmDclrSn === rowData.incmDclrSn)
          .reduce((sum, item) => sum + (item.prdctNsqty || 0), 0));

      const prcsKey = rowData.inspSn + '-' + rowData.incmDclrSn;
      window.SBGrid3.setValue(this.sdflPrcsGridObject, prcsKey, prdctSumNsqty, 'prdctSumNsqty');
    },
    addCrynInfo() {
      const addRowData = {
        incmPmtqty: 0,
        incmDclqty: 0,
        prdctImpqty: 0,
      };
      window.SBGrid3.addRow(this.sdflCrynGridObject, '', addRowData);
    },
    deleteCrynInfo() {
      const deleteCrynPrfmncKeys = window.SBGrid3.getCheckedKeys(this.sdflCrynGridObject);

      if (deleteCrynPrfmncKeys.length === 0) {
        alert("선택된 반입 정보가 없습니다.");
        return;
      }

      if (!confirm(`선택된 ${deleteCrynPrfmncKeys.length}개의 반입 정보를 삭제하시겠습니까?`)) {
        return;
      }

      // 삭제데이터도 보이지 않지만 선택된 상태로 남아 있으므로 체크 해제
      this.sdflCrynGridObject.rows.forEach(rowitem => {
        if (deleteCrynPrfmncKeys.includes(rowitem.data._key_)) {
          rowitem.check = false;
          rowitem.data._checked = false;
        }
      });

      window.SBGrid3.deleteRows(this.sdflCrynGridObject, deleteCrynPrfmncKeys);

      deleteCrynPrfmncKeys.forEach(key => {
        const rowData = window.SBGrid3.getRowData(this.sdflCrynGridObject, key);

        const allSdflPrcsItems = window.SBGrid3.getAllItems(this.sdflPrcsGridObject);
        const matchedItem = allSdflPrcsItems.find(
            item => item.inspSn === rowData.inspSn && item.incmDclrSn === rowData.incmDclrSn
        );

        if (matchedItem) {
          const prcsPrdctImpqty = matchedItem.prdctImpqty || 0; // 수입/판매현황 수입량
          const crynPrdctImpqty = rowData.prdctImpqty || 0; // 반입처리현황 수입량
          const difference = prcsPrdctImpqty - crynPrdctImpqty;

          window.SBGrid3.setValue(this.sdflPrcsGridObject, matchedItem._key_, difference, 'prdctImpqty');

          if (difference === 0) {
            const prdctCrfqty = matchedItem.prdctCrfqty || 0;

            if (prdctCrfqty === 0) {
              this.ntslList.forEach(item => {
                if (item.inspSn === rowData.inspSn && item.incmDclrSn === rowData.incmDclrSn) {
                  item.prdctNsqty = 0;
                  item.status = "U";
                }
              });
            }
          }
        }
      });
    },
    sdflPrcsClick(rowData) {
      this.selectedInfo.inspSn = rowData.inspSn;
      this.selectedInfo.incmDclrSn = rowData.incmDclrSn;
      this.setNtslGridData();
    },
    changeSdflPrcs(values){
      values.forEach(item => {
        //2025.03.17 null 인경우 0처리
        if (item.field === 'prdctRsdqty') {
          const data = window.SBGrid3.getRowData(this.sdflPrcsGridObject, item.key);
          if(!data.prdctRsdqty){
            data.prdctRsdqty = 0;
          }
        }
      });
    },
    setNtslGridData() {
      const filteredItems = this.ntslList.filter(item =>
          item.inspSn === this.selectedInfo.inspSn && item.incmDclrSn === this.selectedInfo.incmDclrSn
      );
      window.SBGrid3.clearSaveData(this.ntslGridObject);
      window.SBGrid3.setClientData(this.ntslGridObject ,filteredItems);
    },
    setPrdctnKndInputInfo() {
      const srfPt = this.sanitizeFloatingPointError(parseFloat(
          this.ntslList
              .filter(item => item.prdctKndCd === '0001')
              .reduce((sum, item) => sum + (item.prdctNsqty || 0), 0)
              .toFixed(2)
      ));
      const srfNpt = this.sanitizeFloatingPointError(parseFloat(
          this.ntslList
              .filter(item => item.prdctKndCd === '0002')
              .reduce((sum, item) => sum + (item.prdctNsqty || 0), 0)
              .toFixed(2)
      ));
      const bioSrfPt = this.sanitizeFloatingPointError(parseFloat(
          this.ntslList
              .filter(item => item.prdctKndCd === '0003')
              .reduce((sum, item) => sum + (item.prdctNsqty || 0), 0)
              .toFixed(2)
      ));
      const bioSrfNpt = this.sanitizeFloatingPointError(parseFloat(
          this.ntslList
              .filter(item => item.prdctKndCd === '0004')
              .reduce((sum, item) => sum + (item.prdctNsqty || 0), 0)
              .toFixed(2)
      ));
      this.prdctnKndInputInfo = {
        srfPt,
        srfNpt,
        bioSrfPt,
        bioSrfNpt
      };
    },
    toList() {
      this.$router.push(ROUTE.PERFORMANCE_LIST);
    },
    async save() {
      if (!confirm("저장하시겠습니까?")) {
        return;
      }

      const validationMessage = this.validate(true);
      let prfmncSttsCd = "IPG"; // 기본 상태 코드

      if (validationMessage) {
        alert(validationMessage);
        return;
      } else {
        if (confirm("필수입력 조건이 만족하여 제출가능한상태입니다.\n제출하시겠습니까?")) {
          prfmncSttsCd = "SUB";
        }
      }

      try {
        const result = await this.checkIncmPrcsPrfmc(prfmncSttsCd);

        if (result.change) {
          storeSwitch.on('SdflIncmPrcsPrfmncChgDscntPopup', result.list);
          return;
        }

        this.saveConfirmed(prfmncSttsCd);

      } catch (e) {
        alert("오류가 발생했습니다.");
      }
    },
    async submission() {
      if (!confirm("제출하시겠습니까?")) {
        return;
      }

      const validationMessage = this.validate(true);
      if (validationMessage) {
        alert(validationMessage);
        return;
      }

      try {
        const result = await this.checkIncmPrcsPrfmc("SUB");

        if (result.change) {
          storeSwitch.on('SdflIncmPrcsPrfmncChgDscntPopup', result.list);
          return;
        }

        this.saveConfirmed("SUB");

      } catch (e) {
        alert("오류가 발생했습니다.");
      }
    },
    validate(focusOnError = false) {
      const allSdflCrynItems = window.SBGrid3.getAllItems(this.sdflCrynGridObject);

      const inspSnData = allSdflCrynItems.reduce((acc, item) => {
        if (!item.inspSn) return acc; // inspSn이 없는 데이터는 건너뜀

        if (!acc[item.inspSn]) {
          acc[item.inspSn] = {
            prdctImpqtyTotal: 0, // prdctImpqty 총합
          };
        }

        // prdctImpqty를 숫자로 변환 후 합산
        acc[item.inspSn].prdctImpqtyTotal += Number(item.prdctImpqty) || 0;

        return acc;
      }, {});

      for (const item of allSdflCrynItems) {
        if (!item.inspSn) {
          if (focusOnError) {
            this.$refs.sdflCrynTitle.focus();
            const rowItem= window.SBGrid3.getRow(this.sdflCrynGridObject, item._key_);
            const column = window.SBGrid3.getColumnByField(this.sdflCrynGridObject, 'inspSn');

            window.SBGrid3.moveFocus(this.sdflCrynGridObject, rowItem, column);
            window.SBGrid3.columnEditable(this.sdflCrynGridObject, item._key_, column, true);
          }
          return "검사ID를 입력하지 않은 데이터가 있습니다.";
        }

        if (!item.incmDclrSn) {
          if (focusOnError) {
            this.$refs.sdflCrynTitle.focus();
            const rowItem= window.SBGrid3.getRow(this.sdflCrynGridObject, item._key_);
            const column = window.SBGrid3.getColumnByField(this.sdflCrynGridObject, 'incmDclrSn');

            window.SBGrid3.moveFocus(this.sdflCrynGridObject, rowItem, column);
            window.SBGrid3.columnEditable(this.sdflCrynGridObject, item._key_, column, true);
          }
          return "수입신고번호를 입력하지 않은 데이터가 있습니다.";
        }

        // if (!item.prdctImpqty) {
        // 2025.02.03 실적값을 0기입해도 저장 및 제출 가능하도록 수정
        if (item.prdctImpqty == null) {
          if (focusOnError) {
            this.$refs.sdflCrynTitle.focus();
            const rowItem= window.SBGrid3.getRow(this.sdflCrynGridObject, item._key_);
            const column = window.SBGrid3.getColumnByField(this.sdflCrynGridObject, 'prdctImpqty');

            window.SBGrid3.moveFocus(this.sdflCrynGridObject, rowItem, column);
            window.SBGrid3.columnEditable(this.sdflCrynGridObject, item._key_, column, true);
          }

          return "수입량을 입력하지 않은 데이터가 있습니다.";
        }

        const inspSnSummary = inspSnData[item.inspSn];
        const totalPrdctSumImpqty = utils.round2((item.prdctSumImpqty || 0) + ((inspSnSummary  && inspSnSummary.prdctImpqtyTotal) || 0));
        if (totalPrdctSumImpqty > item.incmPmtqty) {
          if (focusOnError) {
            this.$refs.sdflCrynTitle.focus();
            const rowItem = window.SBGrid3.getRow(this.sdflCrynGridObject, item._key_);
            const column = window.SBGrid3.getColumnByField(this.sdflCrynGridObject, 'prdctImpqty');

            window.SBGrid3.moveFocus(this.sdflCrynGridObject, rowItem, column);
            window.SBGrid3.columnEditable(this.sdflCrynGridObject, item._key_, column, true);
          }
          return "수입량이 수입 가능물량을 초과한 데이터가 있습니다.";
        }
      }

      const allSdflPrcsItems = window.SBGrid3.getAllItems(this.sdflPrcsGridObject);
      for (const item of allSdflPrcsItems) {
        const prdctSumRmnqty = utils.round2((item.prdctCrfqty || 0) + (item.prdctImpqty || 0) - (item.prdctSumNsqty || 0) - (item.prdctRsdqty || 0));
        if (prdctSumRmnqty < 0) {
          if (focusOnError) {
            this.$refs.sdflPrcsTitle.focus();
            const rowItem = window.SBGrid3.getRow(this.sdflPrcsGridObject, item._key_);
            const column = window.SBGrid3.getColumnByField(this.sdflPrcsGridObject, 'inspId');
            window.SBGrid3.moveFocus(this.sdflPrcsGridObject, rowItem, column);

            this.selectedInfo.inspSn = item.inspSn;
            this.selectedInfo.incmDclrSn = item.incmDclrSn;
            this.setNtslGridData();
          }
          return "공급량(+잔재물)이 수입량(+이월량)보다 초과할 수 없습니다.";
        }
      }

      return "";
    },
    checkIncmPrcsPrfmc(prfmncSttsCd) {
      return new Promise((resolve, reject) => {
        const allPrcsItems = window.SBGrid3.getAllItems(this.sdflPrcsGridObject);
        const filteredItems = allPrcsItems.filter(
            item => item.prfmncId || item._status_ === 'I' || item._status_ === 'U'
        );

        // filteredItems가 비어있는 경우 change = false, list = [] 반환
        if (filteredItems.length === 0) {
          resolve({ change: false, list: [] });
          return;
        }

        const prdctSumCrfqty = this.sanitizeFloatingPointError(filteredItems.reduce((sum, item) => { return sum + (item.prdctCrfqty || 0); }, 0));
        const totalPrdctInpqty = this.sanitizeFloatingPointError(filteredItems.reduce((sum, item) => sum + (item.prdctImpqty || 0), 0));
        const totalPrdctSumNsqty = this.sanitizeFloatingPointError(filteredItems.reduce((sum, item) => sum + (item.prdctSumNsqty || 0), 0));
        const totalPrdctRsdqty = this.sanitizeFloatingPointError(filteredItems.reduce((sum, item) => sum + (item.prdctRsdqty || 0), 0));
        const prdctSumRmnqty = utils.round2(prdctSumCrfqty + totalPrdctInpqty - totalPrdctSumNsqty - totalPrdctRsdqty);

        this.$apiCall.post(
            API.INCOME_PROCESS_PERFORMANCE_CHANGE_CHECK,
            {
              bplcId: this.prfmncBasicInfo.bplcId,
              prfmncYm: this.prfmncBasicInfo.prfmncYr + this.prfmncBasicInfo.prfmncMm,
              prdctSumCrfqty: prdctSumCrfqty,
              prdctSumRmnqty: prdctSumRmnqty,
              list: filteredItems,
              updatePrfmncSttsCd: prfmncSttsCd,
            },
            (data) => {
              resolve(data.result);
            },
            () => {
              alert("제출에 실패하였습니다.");
              reject();
            }
        );
      });
    },
    saveConfirmed(prfmncSttsCd, changeList = []) {
      const { inserted, updated, deleted } = window.SBGrid3.getSaveData(this.sdflCrynGridObject, true, true, true);
      const incmCrynList = [...inserted, ...updated, ...deleted];
      const { inserted: prcsInserted, updated: prcsUpdated, deleted: prcsDeleted } = window.SBGrid3.getSaveData(this.sdflPrcsGridObject, true, true, true);
      const incmPrcsList = [...prcsInserted, ...prcsUpdated, ...prcsDeleted];

      const param = {
        prfmncId: this.prfmncBasicInfo.prfmncId,
        bplcId: this.prfmncBasicInfo.bplcId,
        prfmncYm: this.prfmncBasicInfo.prfmncYr + this.prfmncBasicInfo.prfmncMm,
        prfmncSttsCd,
        incmCrynList: incmCrynList,
        incmNtslList: this.ntslList.filter(item => item.status === 'U'),
        incmPrcsList: incmPrcsList,
        changeList,
      };

      const prfmncSttsNm = prfmncSttsCd === "SUB" ? "제출" : "저장";

      this.$apiCall.post(
          API.INCOME_PERFORMANCE,
          param,
          (data) => {
            alert(`${prfmncSttsNm}이 완료되었습니다.`);
            storeSwitch.off('SdflIncmPrcsPrfmncChgDscntPopup');

            if (prfmncSttsCd === "SUB") {
              this.toList();
            } else {
              this.$router.push({path : "/prfmnc-rpt/dtl/SdflIncm", query: {prfmncId: data.result.prfmncId}})
                  .then(() => {
                    location.reload();
                  })
                  .catch(() => {
                    console.error("Navigation Error");
                  });
            }
          },
          () => {
            alert(`${prfmncSttsNm}에 실패하였습니다.`);
          }
      );
    },
    formatNumber(number) {
      return utils.formatNumberWithComma(number);
    },

    // 소수점 둘쨰자리 오류 수정
    // 부동소수점(floating point) 연산의 정밀도 문제 스크립트 고유 버그
    sanitizeFloatingPointError(value){
      if (typeof value !== "number") return value; // 숫자가 아닐 경우 반환

      // 소수점 2자리까지만 유지하고 반올림하여 부동소수점 오차 제거
      return parseFloat((Math.round(value * 100) / 100).toFixed(2));

    }
  }
}
</script>

<style scoped>
  .grid_box { margin-bottom: 5px; }
</style>