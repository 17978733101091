<template>
  <div v-show="isOn" class="modal visible">
    <div class="popup_box" style="width: 80%; max-width: inherit">
      <h1 class="popup_title">로그인 업체 선택</h1>
      <div>
        <button type="button" class="modal_close" @click.prevent="hide">팝업닫기</button>
      </div>
      <div class="popup_cont" style="height: 88%;">
        <!-- 화면 타이틀 영역 -->
        <div class="board_info">
          <h2 class="title1">로그인 업체 목록</h2>
        </div>
        <div class="board_list scroll">
          <table style="table-layout: fixed;">
            <caption></caption>
            <colgroup>
              <col style="width:22rem">
              <col style="width:22rem">
            </colgroup>
            <thead>
            <tr>
              <th scope="col">업체아이디</th>
              <th scope="col">업체명</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td aria-label="업체아이디" hidden>{{ item.bzentyId }}</td>
              <td aria-label="업체명">{{ item.bzentyNm }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
      items: {type: Array, default: () => []},
  },
  emits: ['select-bzenty'],
  data() {
    return {
      isOn: false,
    }
  },
  methods: {
    show() {
      this.isOn = true;
    },
    hide() {
      this.isOn = false;
    },
  }
}
</script>