<template>
  <div class="cont_box">
    <div class="title_btn_area">
      <h3 class="title">상세 정보</h3>
    </div>
    <div class="board_write">
      <table>
        <caption>상세 정보 제목, 등록자, 등록일시, 첨부파일, 내용</caption>
        <colgroup>
          <col style="width:18%">
          <col style="width:32%">
          <col style="width:18%">
          <col style="width:32%">
        </colgroup>
        <tbody>
          <tr>
            <th scope="row">제목</th>
            <td colspan="3">
              {{param.pstTtl}}
            </td>
          </tr>
          <tr>
            <th scope="row">등록자</th>
            <td>
  <!--            {{param.rgtrNm}}-->
              관리자
            </td>
            <th scope="row">등록일시</th>
            <td>
              {{param.regDt}}
            </td>
          </tr>
          <tr>
            <th scope="row">첨부파일</th>
            <td colspan="3" v-show="param.files.length != 0">
              <br-attach-file
                  label="첨부파일"
                  v-model:atchFileGroupId="param.atchFileGroupId"
                  v-model:fileProcessingData="param.fileProcessingData"
                  :files="param.files"
                  :readonly="true"
                  :maxFileCount="100"
              />
            </td>
            <td colspan="3" v-show="param.files.length == 0">
              첨부파일이 없습니다.
            </td>
          </tr>
          <tr>
            <th scope="row">내용</th>
            <td colspan="3" :id="param.pstSn === 3012? 'new_sys_noti' : ''">
              <div v-html="param.bbsPstCn"></div>
              <div v-if="param.pstSn === 3012" class="link_to_manual" @click="linkToSomewhere('MANUAL')"></div>
              <div v-if="param.pstSn === 3012" class="link_to_faq" @click="linkToSomewhere('FAQ')"></div>
              <div v-if="param.pstSn === 3012" class="link_to_chatbot" @click="linkToSomewhere('CHATBOT')"></div>
              <div v-if="param.pstSn === 3012" class="link_to_helpdesk" @click="linkToSomewhere('HELPDESK')"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="btn_area right">
      <br-button label="목록" format="tertiary line" panel="panel" @click="navigate()"/>
    </div>
  </div>
</template>

<script>
import {storeSwitch} from "@/js/store/store-switch";

const API = {
  POST: "/post",
  POST_ACS_CHK: "/post/detailAcsChk",
};

export default {
  data() {
    return {
      bbsSn: "",
      param: {
        pstTtl: "",
        pstCn: "",
        regDt: "",
        rgtrId: "",
        atchFileGroupId: "",
        fileProcessingData: {},
        files: []
      },
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.pstSn = this.$route.query.pstSn;
    this.bbsSn = this.$route.params.bbsSn;
    this.read()
  },
  methods : {
    read() {
      this.$apiCall.get(API.POST + "/detail", {pstSn: this.pstSn, bbsSn: this.bbsSn}, this.success, this.error);
    },
    async success(data) {
      if (!data.result) {
        this.error();
        return;
      }

      if(data.result.delYn == "Y") {
        alert("삭제된 게시물 입니다.");
        this.navigate();
        return;
      }
      if(data.result.pstPrvtYn=="Y"){
        alert("비공개 게시물 입니다.");
        this.navigate();
        return;
      }

      const json = JSON.parse(JSON.stringify(data.result));
      this.param = json;

      this.param.files = []
      if (data.result.files) {
        this.param.files = data.result.files;
      }
    },
    error(error) {
      if(error?.message) {
        alert(error.message);
      }else{
        alert("데이터를 가져오는 중 오류가 발생했습니다.");
      }
      this.navigate();
    },
    navigate() {
      this.$router.push({path : `/cust-sprt/PostManage/` + this.bbsSn})
        .catch(() => {
            console.error("Navigation Error")
          }
        );
    },
    linkToSomewhere(callType) {
      switch (callType) {
        case 'MANUAL':
          this.$router.push({path: '/home', query: {callfor: 'ONLINE_MANUAL'}});
          break;
        case 'FAQ':
          this.$router.push('/cust-sprt/FaqManage');
          break;
        case 'CHATBOT':
          storeSwitch.on('chatBot');
          break;
        case 'HELPDESK':
          // 헬프데스크는 link 연결 X
          break;
      }
    },
  },
}
</script>

<style scoped>
#new_sys_noti { position: relative; }

#new_sys_noti .link_to_manual { position: absolute; left: 54px; top: 309px; width: 244px; height: 124px; border-radius: 10px; cursor: pointer; }
#new_sys_noti .link_to_faq { position: absolute; left: 308px; top: 309px; width: 244px; height: 124px; border-radius: 10px; cursor: pointer; }
#new_sys_noti .link_to_chatbot { position: absolute; left: 54px; top: 443px; width: 244px; height: 124px; border-radius: 10px; cursor: pointer; }
#new_sys_noti .link_to_helpdesk { position: absolute; left: 308px; top: 443px; width: 244px; height: 124px; border-radius: 10px; }
</style>
