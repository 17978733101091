<template>
  <nav id="gnb">
    <ul @mouseleave="handleGnbMouseLeave">
      <li v-for="(group, index) in filteredGroups" :key="index"
          :class="{ active: activeGnbIndex === index, plus: hasSubMenu(group) }"
      >
        <a href="javascript:void(0);" role="link" @click.prevent="toggleMenu(index)">{{ group.groupName }}</a>
        <div class="depth2" v-if="activeGnbIndex === index">
          <h3 class="dep2_title"><a href="javascript:void(0);">{{ group.groupName }}</a></h3>
          <ul>
            <li v-for="(menuGrp, subIndex) in group.menuList" :key="subIndex"
                @click.prevent="handleNavigation(menuGrp.path)"
            >
              <a href="javascript:void(0);">{{ menuGrp.menuGrpNm }}</a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: ['groups'],
  emits: ['menu-select'],
  data() {
    return {
      activeGnbIndex: null,
    };
  },
  computed: {
    filteredGroups() {
      return this.groups
          .filter(group => group.topMenuUseYn === 'Y')
          .map(group => ({
            ...group,
            menuList: group.menuList.filter(menuGrp => menuGrp.topMenuUseYn === 'Y')
                .map(menuGrp => ({
                  ...menuGrp,
                  path: menuGrp.path || (menuGrp.items && menuGrp.items.length > 0 && this.findFirstVisiblePath(menuGrp.items)) || ''
                }))
          }));
    }
  },
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {
    toggleMenu(index) {
      if (this.activeGnbIndex === index) {
        this.activeGnbIndex = null;
      } else {
        this.activeGnbIndex = index;
      }
    },
    handleGnbMouseLeave() {
      this.activeGnbIndex = null;
    },
    hasSubMenu(group) {
      return group.menuList && group.menuList.length > 0;
    },
    handleNavigation(path) {
      this.$emit("menu-select", path);
    },
    findFirstVisiblePath(items) {
      const visibleItem = items.find(item => item.hidden !== true);
      return visibleItem ? visibleItem.path : '';
    }
  }
}
</script>

<style scoped>

</style>