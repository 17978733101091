<template>
  <div v-show="isOn" class="modal visible">
    <div class="popup_box" style="width: 90%; max-width: 1000px; height: 95%; max-height: 95%;">
      <h1 class="popup_title">리포트</h1>
      <template v-if="isOn">
        <report-form
            :id="rdSetObj.rdFileName"
            :path="rdSetObj.rdFileName"
            :param="rdParam"
            :rd-save-data-add="rdSaveDataAddObj" 
            :rd-width="rdSetObj.rdWidth" 
            :rd-height="rdSetObj.rdHeight" 
            :form-mode="rdSetObj.isFormMode"
            @submit="submit"
            @cancel="cancel"
          />
        </template>
      <button type="button" class="modal_close" @click.prevent="hide" >팝업닫기</button>
    </div>
  </div>
</template>

<script>
/**
 * 교육확인증 리포트 팝업 
 *
 * 작성자 : 정미라
 * 작성일 : 24. 11. 14. 
 * 버전 : 1.0
 */

import { storeSwitch } from "@/js/store/store-switch";
import { createRdAgent } from "@/js/store/store-rdagent";
import ReportForm from "@/components/ReportForm.vue";

export default {
  components: { ReportForm }, 
  props: {
    rdParamFileName: {
      type: String,
      required: true
    },
    rdParamKey1: {
      type: String,
      required: true
    },
    rdParamValue1: {
      type: String,
      required: true
    },
    rdParamKey2: {
      type: String,
      required: true
    },
    rdParamValue2: {
      type: String,
      required: true
    },
    rdParamKey3: {
      type: String,
      required: true
    },
    rdParamValue3: {
      type: String,
      required: true
    },
    rdParamKey4: {
      type: String,
      required: true
    },
    rdParamValue4: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isOn: false,
      rdSetObj: { 
          rdFileName: "reportOnlineEducationCfmcd",   // 이폼/리포트 파일명 (.mrd 제외) 
          rdKey1: "enuSn",                            // 이폼/리포트 조회 데이터 Key 명칭 
          rdValue1: "",                               // 이폼/리포트 조회 데이터 Key에 대한 Value 
          rdKey2: "delYn", 
          rdValue2: "",
          rdKey3: "userId", 
          rdValue3: "",
          rdKey4: "eduFnshYn", 
          rdValue4: "",
          isFormMode: false,      // 이폼 여부 ( true : 이폼, false : 리포트 ) 
          pdfFileName: "",   // 제출 시 서버에 저장되는 PDF 파일명 ( .pdf 제외한 파일명 )
          rdWidth: "99.5%",       // 뷰어 너비 
          rdHeight: "90%",        // 뷰어 높이
        },
      };
  },
  computed: {
    rdParam() {

      return createRdAgent(
        // 조회 Parameter 3개일 경우 
        `${this.rdSetObj.rdKey1} [${this.rdSetObj.rdValue1}] ${this.rdSetObj.rdKey2} [${this.rdSetObj.rdValue2}] ${this.rdSetObj.rdKey3} [${this.rdSetObj.rdValue3}] ${this.rdSetObj.rdKey4} [${this.rdSetObj.rdValue4}]`, 

        // 조회 Parameter 1개일 경우 
        // `${this.rdSetObj.rdKey1} [${this.rdSetObj.rdValue1}]`, 
        this.rdSetObj.isFormMode,
        this.rdSetObj.pdfFileName 
      );
      
    }
  },
  watch: {
    
  },
  created() {
    storeSwitch.setBulb('reportEducationPopup', this);
  },
  mounted() { },
  beforeMount() {
    // 전달받은 Param 
    this.rdSetObj.rdFileName = this.rdParamFileName;
    this.rdSetObj.rdKey1 = this.rdParamKey1;
    this.rdSetObj.rdValue1 = this.rdParamValue1;
    this.rdSetObj.rdKey2 = this.rdParamKey2;
    this.rdSetObj.rdValue2 = this.rdParamValue2;
    this.rdSetObj.rdKey3 = this.rdParamKey3;
    this.rdSetObj.rdValue3 = this.rdParamValue3;
    this.rdSetObj.rdKey4 = this.rdParamKey4;
    this.rdSetObj.rdValue4 = this.rdParamValue4;


    console.log('this.rdSetObj.rdFileName = ', this.rdSetObj.rdFileName);
    console.log('this.rdSetObj.rdKey1 = ', this.rdSetObj.rdKey1);
    console.log('this.rdSetObj.rdValue1 = ', this.rdSetObj.rdValue1);
    console.log('this.rdSetObj.rdKey2 = ', this.rdSetObj.rdKey2);
    console.log('this.rdSetObj.rdValue2 = ', this.rdSetObj.rdValue2);
    console.log('this.rdSetObj.rdKey3 = ', this.rdSetObj.rdKey3);
    console.log('this.rdSetObj.rdValue3 = ', this.rdSetObj.rdValue3);
    console.log('this.rdSetObj.rdKey4 = ', this.rdSetObj.rdKey4);
    console.log('this.rdSetObj.rdValue4 = ', this.rdSetObj.rdValue4);
  },
  methods: {
    /** 팝업 Show */
    show() {
      console.log("isFormMode:::"+this.rdSetObj.isFormMode)
      console.log("pdfFileName:::"+this.rdSetObj.pdfFileName  )
      console.log(":::"+this.rdParam)
      this.isOn = true;
    },
    /** 팝업 Hide */
    hide() {
      this.isOn = false;
    },
  }
}
</script>

<style scoped>
</style>