<template>
  <div class="board_info">
    <h2 class="title1">컨텐츠 상세</h2>
  </div>
  <div class="cont_box">
    <div class="board_view online_edu">
      <div class="b_tit">
        <h2>{{ param.contsNm }}</h2>
      </div>
      <div class="b_cont">
        <dl>
          <dt>조회수</dt>
          <dd>{{ param.inqCnt }}</dd>
        </dl>
        <dl>
          <dt>등록일자</dt>
          <dd>{{ param.regDt }}</dd>
        </dl>
        <div class="video_area" v-if="param.contsSeCd === '01' && param.contsUrlAddr">
          <iframe title="컨텐츠" width="560" height="315" :src="param.contsUrlAddr"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
          </iframe>
        </div>
        <div class="image_area" v-else-if="param.contsSeCd === '02' && param.contsImgFiles">
          <img :src="getContsImg(param.contsImgFiles)" alt="컨텐츠 이미지">
        </div>
        <div class="w100p" v-html="param.contsDtlExpln"></div>
      </div>
      <dl class="url">
        <dt>링크</dt>
        <dd>
          <div class="url_link">
            <span>{{ param.contsUrlAddr }}</span>
            <a :href="param.contsUrlAddr" class="btn xsm" target="_blank" title="새창열림">바로가기</a>
          </div>
        </dd>
      </dl>
    </div>
    <div class="btn_area right">
      <div>
        <br-button label="목록" format="tertiary line" @click="navigateTo('/cust-sprt/ContentsManage')"/>
      </div>
    </div>
  </div>
</template>

<script>
import {buildUri} from "@/js/utils/uri-builder";

const API = {
  CONTENTS: "/contents",
  DOWNLOAD: "/api/file/download/{atchFileVldNo}"
};

export default {
  data() {
    return {
      param: {
        contsNm: "",
        contsSeCd: "",
        inqCnt: "",
        regDt: "",
        thmbImgAtchFileGroupId: "",
        contsImgAtchFileGroupId: "",
        contsUrlAddr: "",
        contsDtlExpln: "",
        contsImgFiles: []
      }
    };
  },
  mounted() {
    this.contsSn = this.$route.query.contsSn;
    this.initialize();
  },
  methods: {
    initialize() {
      this.$apiCall.get(`${API.CONTENTS}/${this.contsSn}`, {}, this.success, this.error);
    },
    success(data) {
      if (!data.result) {
        alert("컨텐츠 상세를 가져오는데 실패하였습니다.");
        return
      }

      if(data.result.useYn == "N"){
        alert("미사용 게시물 입니다.");
        this.navigate();
        return;
      }
      if(data.result.delYn == "Y"){
        alert("삭제된 게시물 입니다.");
        this.navigate();
        return;
      }

      this.param = data.result;
    },
    error() {
      alert("컨텐츠 상세를 가져오는데 실패하였습니다.");
    },
    getContsImg(contsImgFiles) {
      if (contsImgFiles.length === 0 || !contsImgFiles[0].atchFileVldNo) {
        return "";
      }

      let imageUrl = buildUri(API.DOWNLOAD, { atchFileVldNo : contsImgFiles[0].atchFileVldNo });

      return process.env.VUE_APP_ENV === 'local'
          ? `${process.env.VUE_APP_API_PROXY_URL}${imageUrl}`
          : `${imageUrl}`;
    },
    navigateTo(path) {
      if (path) {
        this.$router.push(path).catch(() => {
          console.error("Navigation Error");
        });
      }
    }
  }
};
</script>

<style scoped>
.board_view .edu_list li .time {
  width: 10.2rem;
}
</style>
