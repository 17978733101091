<template>
  <div class="cont_box">
    <div class="title_btn_area">
      <h3 class="title" v-if="!param.onlnDscsnSn">온라인 상담 문의 등록</h3>
      <h3 class="title" v-else>온라인 상담 문의 수정</h3>
    </div>
    <div class="board_write">
      <table>
        <colgroup>
          <col style="width:20%">
          <col style="width:80%">
        </colgroup>
        <tbody>
        <tr>
          <th scope="row">제목</th>
          <td>
            <br-form-input label="제목" v-model="param.dscsnTtl" panel="panel" required/>
          </td>
        </tr>
        <tr>
          <th scope="row">공개여부</th>
          <td>
            <br-form-radio label="공개여부" :options="options" v-model:value="param.rlsYn" v-model="text" panel="panel" required/>
          </td>
        </tr>
        <tr>
          <th scope="row">첨부파일</th>
          <td colspan="3">
            <br-attach-file label="첨부파일" atchFileTaskSeCd="ODC"
                            ref=""
                            v-model:atchFileGroupId="param.dscsnAtchFileGroupId"
                            v-model:fileProcessingData="param.fileProcessingData"
                            :maxFileCount="1"
                            :files="param.files"
                            panel="panel"/>
          </td>
        </tr>
        <tr>
          <th scope="row">내용</th>
          <td>
            <ck-editor label="내용" v-model="param.onlnDscsnQstnCn" panel="panel" required/>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="btn_area right">
      <br-button label="저장" format="primary" panel="panel" validate @click="create"/>
      <br-button label="목록" format="tertiary line" panel="panel" @click="navigate()"/>
    </div>
  </div>
</template>

<script>

import BrFormRadio from "@/components/BrFormRadio.vue";
import CkEditor from "@/components/CkEditor.vue";

const API = {
  ONLINEDISCUSSION: "/onlinediscussion",
};

export default {
  components: {BrFormRadio, CkEditor},
  data() {
    return {
      options:[
        {"value": "Y", "text": "공개"},
        {"value": "N", "text": "비공개"},
      ],
      param: {
        onlnDscsnSn: "",
        dscsnTtl: "",
        onlnDscsnQstnCn: "",
        rlsYn: "",
        atchFileGroupId : "",
        fileProcessingData : {},
      },
      files : [],
    }
  },
  beforeMount() {
    this.param.onlnDscsnSn = this.$route.query.onlnDscsnSn;
    this.read();
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods : {
    read() {
      if (this.param.onlnDscsnSn != null && this.param.onlnDscsnSn != "") {
        this.$apiCall.get(API.ONLINEDISCUSSION + "/detail", {onlnDscsnSn: this.param.onlnDscsnSn}, this.success, this.error);
      }
    },
    async success(data) {
      if (!data.result) {
        this.error();
        return;
      }

      const json = JSON.parse(JSON.stringify(data.result));
      this.param = json;

      this.param.files = []
      if (data.result.dscsnFiles) {
        this.param.files = data.result.dscsnFiles;
      }
    },
    error() {
      alert("데이터를 가져오는 중 오류가 발생했습니다.");
    },
    create() {
      if(confirm("저장하시겠습니까?") != true) {
        return
      }
      this.$apiCall.put(
        API.ONLINEDISCUSSION,
         this.param,
        () => {
          alert("저장되었습니다.")
          this.navigate();
        },
        () => {
          alert("저장에 실패하였습니다.")
        }
      )
    },
    navigate() {
      this.$router.push({path : `/cust-sprt/OnlineDiscussionManage`})
        .catch(() => {
            console.error("Navigation Error")
          }
        );
    },
  },
}
</script>

<style scoped>

</style>
