<template>
    <div class="desc_box">
        <i class="icon"><img src="@/assets/images/common/ico_desc.png" alt="이미지"></i>
        <div class="desc">
            <strong class="desc_title">수입품질검사 신청</strong>
            <ul class="dot_list">
                <li>수입자는 검사신청을 수입신고 60일 전에 신청하여 주십시요.</li>
                <li>수수료 입금이 확인되면 검사신청이 접수되며 시료채취계획이 수립되면 5일 이내 통보가 됩니다.</li>
                <li>시료채취일로 부터 30일 이내 품질검사 결과서가 발급됩니다.</li>
                <li>지방환경관서에 수입신고 후 신고확인증을 발급받으면 수입신고 내역을 시스템에 등록하여 주십시요.</li>
            </ul>
            <!-- <div class="btn_area left">
                <button type="button" class="btn xsm helper ico_helper" @click="qltyInspGuideView">수입품질검사안내</button>
            </div> -->
        </div>
    </div>

    <h2 class="title1">수입품질검사 대상 사업장 정보</h2>
    <ul class="card_list" v-if="fcltList.length">
        <li v-for="(item, index) in fcltList" v-bind:key="index">
          <div class="card_top">
            <strong class="c_tit">({{item.bplcId}}) {{ item.bplcNm }}</strong>
          </div>
          <div class="card_body">
              <ul class="txt_list">
                <li>사업장종류<strong>{{ item.bplcKndNm }}</strong></li>
                <li v-if="item.pubBzentyYn === 'Y'">업체구분<strong>공공업체</strong></li>
                <li v-else>업체구분<strong>민간업체</strong></li>
              </ul>
              <div class="card_top">
                <strong class="c_tit">최근 수입검사정보</strong>
              </div>
              <ul class="txt_list">
                <li style="flex: 0 0 calc(100% - 1.5rem)">제조국가<strong>{{ item.mnftrNtnNm }}</strong></li>
                <li style="flex: 0 0 calc(50% - 1.5rem)">업체명<strong>{{ item.ovrsBzentyNm }}</strong></li>
                <li style="flex: 0 0 calc(50% - 1.5rem)">시설명<strong>{{ item.fcltNm }}</strong></li>
                <li>일자<strong>{{ item.inspEndYmd }}</strong></li>
                <li>진행상태<strong>{{ item.inspPrgrsSttsNm }}</strong></li>
                <li>결과<strong>{{ item.inspRsltCdNm }}</strong></li>
                <li>제품<strong>{{ item.qltyInspItemCdNm }}</strong></li>
              </ul>
          </div>
          <div class="card_btn">
              <button type="button" class="btn sm primary line" @click="writeInspAplyForm(index, '30-10')">수입품질검사신청</button>
          </div>
        </li>
    </ul>
    <ul v-else>
      <li>
        <div style="display: flex; justify-content: center;">
          <div class="no-data-message">수입품질검사 대상 정보가 없습니다.</div>
        </div>
      </li> 
    </ul>
</template>

<script>
// import { storeSnackbar } from "@/js/store/store-snackbar";
import { storeSwitch } from "@/js/store/store-switch";
// import { storeComponent } from "@/js/store/store-component";


const API = {
    INCM_QLTY_INSP_FCLT_LIST: "/incmQltyInspAply",
}

export default {
  components: {},
  emits: ['incm-qlty-insp-aply-form'],
  expose: ['reset'],
  data() {
    return {
      menu: this.$menusTotal.getRouteMenu(this.$route.path),
      fcltList: [
          {
            bzentyId: "",
            pubBzentyYn: "",
            bplcId: "",
            bplcNm: "",
            bplcKndCd: "",
            bplcKndNm: "",
            fcltSqno: "",
            prdctnPrdctKndCd: "",
            prdctnPrdctKndNm: "",
            frstInspYmd: "",
            frstInspPassYn: "",
            chgInspTrgtYn: "",
            fcltSttsCd: "",
            fcltSttsNm: "",
            inspAplySn: "",
            inspRcptId: "",
            inspPrgrsSttsCd: "",
            inspPrgrsSttsNm: "",
            inspEndYmd: "",
            inspRsltCdNm: "",
            qltyInspItemCdNm: "",
            ovrsBzentyNm: "",
            fcltNm: "",
            mnftrNtnNm: "",
          }
        ]
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  beforeMount() {
  },
  mounted() {
    this.initialize();
  },
  beforeUnmount() {
    // 컴포넌트 전체 데이터 저장하기
    // storeComponent.store(this.menu, this);
  },
  methods: {
    async initialize() {
        await this.read();
    },
    read() {
      this.$apiCall.get(API.INCM_QLTY_INSP_FCLT_LIST, "", this.success, this.error);
    },
    success(data) {
      if (!data.result) {
        this.error();
        return;
      }
      
      this.fcltList = data.result.inspFcltList;
      
    },
    error() {
      storeSwitch.alert('warning', "데이터를 가져오는 중 오류가 발생했습니다.");
    },
    writeInspAplyForm(index, inspSeCd){
        const searchKey = {
                            bzentyId: this.fcltList[index].bzentyId,
                            bplcId:   this.fcltList[index].bplcId,
                            fcltSqno: this.fcltList[index].fcltSqno,
                            inspSeCd: inspSeCd
                          };
        this.$emit('incm-qlty-insp-aply-form', searchKey);
        
    },
    reset() {
        this.initialize();
    },
    qltyInspGuideView(){
      // this.$router.push("/wstrsc-ene-intro/sfl/IncmQltyInspGd");
    },
  }
}
</script>

<style scoped>
  .no-data-message {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 5px;
    margin: 20px 0px;
    border-radius: 10px;
    background-color: #f7f7f7;
    color: #333;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #ddd;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    width: 350px;
  }
</style>