<template>

  <!-- 2024년까지의 소각 등록/수정화면 -->
  <template v-if="!showDetail && parseInt(prfmncMst.prfmncYr) <= 2024">
    <by2024-fire-rtrvl-update
        :prfmncMst="prfmncMst"
        :prfmncFclt="prfmncFclt"
        :prfmncFireRtrvl="prfmncFireRtrvl"
        :prvPrfmncFireRtrvl="prvPrfmncFireRtrvl"
        :prfmncFireRtrvlPrdctnList="prfmncFireRtrvlPrdctnList"
        :prfmncFireRtrvlRsrcRcyclnList="prfmncFireRtrvlRsrcRcyclnList"
        :prfmncFireRtrvlWtCrynList="prfmncFireRtrvlWtCrynList"
        :prfmncFireRtrvlNtslList="prfmncFireRtrvlNtslList"
        :showDetail="showDetail"
    />
  </template>
  <!-- 2024년까지의 소각 상세화면 -->
  <template v-else-if="showDetail && parseInt(prfmncMst.prfmncYr) <= 2024">
    <by2024-fire-rtrvl-detail
        :prfmncMst="prfmncMst"
        :prfmncFclt="prfmncFclt"
        :prfmncFireRtrvl="prfmncFireRtrvl"
        :prvPrfmncFireRtrvl="prvPrfmncFireRtrvl"
        :prfmncFireRtrvlPrdctnList="prfmncFireRtrvlPrdctnList"
        :prfmncFireRtrvlWtCrynList="prfmncFireRtrvlWtCrynList"
        :prfmncFireRtrvlNtslList="prfmncFireRtrvlNtslList"
        :showDetail="showDetail"
    />
  </template>
  <!-- 2025년 이후 소각 상세화면 -->
  <template v-else-if="showDetail && parseInt(prfmncMst.prfmncYr) >= 2025">
    <fire-rtrvl-detail
        :prfmncMst="prfmncMst"
        :prfmncFclt="prfmncFclt"
        :prfmncFireRtrvl="prfmncFireRtrvl"
        :prvPrfmncFireRtrvl="prvPrfmncFireRtrvl"
        :prfmncFireRtrvlWtInputList="prfmncFireRtrvlWtInputList"
        :prfmncFireRtrvlPrdctnList="prfmncFireRtrvlPrdctnList"
        :prfmncFireRtrvlWtCrynList="prfmncFireRtrvlWtCrynList"
        :prfmncFireRtrvlNtslList="prfmncFireRtrvlNtslList"
        :asstFuelInputInfo="asstFuelInputInfo"
        :showDetail="showDetail"
    />
  </template>
  <!-- 2025년 이후 소각 등록/수정화면 -->
  <template v-else>
    <fire-rtrvl-update
        :prfmncMst="prfmncMst"
        :prfmncFclt="prfmncFclt"
        :prfmncFireRtrvl="prfmncFireRtrvl"
        :prvPrfmncFireRtrvl="prvPrfmncFireRtrvl"
        :prfmncFireRtrvlWtInputList="prfmncFireRtrvlWtInputList"
        :prfmncFireRtrvlPrdctnList="prfmncFireRtrvlPrdctnList"
        :prfmncFireRtrvlRsrcRcyclnList="prfmncFireRtrvlRsrcRcyclnList"
        :prfmncFireRtrvlWtCrynList="prfmncFireRtrvlWtCrynList"
        :prfmncFireRtrvlNtslList="prfmncFireRtrvlNtslList"
        :asstFuelInputInfo="asstFuelInputInfo"
        :showDetail="showDetail"
    />
  </template>

</template>

<script>
import SbGrid from "@/components/SbGrid.vue";
import FireRtrvlDetail from "@/views/prfmnc-rpt/dtl/fire-rtrvl/FireRtrvlDetail.vue";
import FireRtrvlUpdate from "@/views/prfmnc-rpt/dtl/fire-rtrvl/FireRtrvlUpdate.vue";
import By2024FireRtrvlDetail from "@/views/prfmnc-rpt/dtl/fire-rtrvl/By2024FireRtrvlDetail.vue";
import By2024FireRtrvlUpdate from "@/views/prfmnc-rpt/dtl/fire-rtrvl/By2024FireRtrvlUpdate.vue";
import {storeSwitch} from "@/js/store/store-switch";

const API = {
  BUSINESS_PLACE_INFO: "/bplc", // 사업장 정보
  PRFMNC_MST: "/prfmnc",// 실적 공통정보
  CHECK_PERMITTED_BUSINESS: "/prfmnc/chck-prm-bplc",
  FACILITIES_INFO_BY_BUSINESS_PLACE: "/fclt/by-bplc", // 사업장 시설정보
  PRFMNC_CRYN_STTS: "/prfmnc/fireRtrvl/wtCrynStts", // 실적 소각 판매반입현황 조회
  PRFMNC_RSRC_RCYCLN: "/prfmnc/rsrcRcycln", // 실적 자원순환반입정보
  FIRE_RTRVL_DTL: "/prfmnc/fireRtrvl", // 실적 소각 상세조회
  PRFMNC_NTSL_STTS: "/prfmnc/fireRtrvl/ntslStts", // 실적 소각 판매현황 조회
  ASSISTANCE_FUEL_INPUT_INFORMATION: "/prfmnc/fireRtrvl/asst-fuel-input-info", // 시설별 보조연료투입 현황 조회
  PERFORMANCE_REGISTER_POSIBILITY_IDENTIFY: "/prfmnc/reg-psblty-idnty", // 실적 신규등록 가능상태 체크
};

const CODE = {
  PERFORMANCE_STATUS: "PFM001", // 실적상태코드
  MODIFICATION_PERMIT_STATUS: "PFM002", // 실적수정허용상태코드
};

const ROUTE = {
  LIST: "/prfmnc-rpt/PrfmncRpt"
};

export default {
  components: {
    SbGrid,
    FireRtrvlDetail,
    FireRtrvlUpdate,
    By2024FireRtrvlDetail,
    By2024FireRtrvlUpdate,
  },
  data() {
    return {
      mode: "U", // U: 등록, 수정   R: 상세
      searchParam: {
        prfmncId: "",
        bplcId: "",
        rsrcRcyclnFcltId:"",
        bplcKndCd: "",
        prfmncYm: "",
        prfmncYr: "",
        prfmncMm: "",
      },
      detailStatusCodes: ['REC', 'APR', 'SUB'],
      prfmncMst:{},
      prfmncFclt:{},
      prfmncFireRtrvl:{}, // 소각실적
      prvPrfmncFireRtrvl:{}, // 소각가스실적
      prfmncFireRtrvlRsrcRcyclnList:{}, // 소각 자원순환
      prfmncFireRtrvlPrdctnList:[], // 소각생산실적
      prfmncFireRtrvlWtCrynList:[], // 각반입실적
      prfmncFireRtrvlWtInputList:[], // 각투입실적
      prfmncFireRtrvlNtslList:[], // 각판매실적
      asstFuelInputInfo: {}, // 보조연료 투입정보
      fireRtrvlNtslEneSplyBzenty: [], // 소각판매업체
      bioGasEnePrfmnc: {}, // 바이오가스에너지실적
    };
  },
  computed: {
    showDetail() {
      return Boolean(this.searchParam.prfmncId) && this.detailStatusCodes.includes(this.prfmncMst.prfmncSttsCd);
    }
  },
  watch: {
    showDetail(newVal) {
      if (newVal) {
        this.mode = 'R';
      } else {
        this.mode = 'U';
      }
    }
  },
  created() {
    const prfmncId = this.$route.query.prfmncId ?? "";
    const bplcId = this.$route.query.bplcId ?? "";
    const prfmncYm = this.$route.query.prfmncYm ?? "";
    if(prfmncId){ // 수정, 상세
      this.searchParam.prfmncId = prfmncId;
    }else{ // 신규
      this.searchParam.prfmncId = prfmncId;
      this.searchParam.bplcId = bplcId ?? "";
      this.prfmncMst.bplcId = bplcId ?? "";
      this.searchParam.prfmncYm = prfmncYm ?? "";
      this.prfmncMst.prfmncYm = prfmncYm ?? "";
      this.searchParam.prfmncYr = prfmncYm?.substring(0, 4) ?? "";
      this.prfmncMst.prfmncYr = prfmncYm?.substring(0, 4) ?? "";
      this.searchParam.prfmncMm = prfmncYm?.substring(4, 6) ?? "";
      this.prfmncMst.prfmncMm = prfmncYm?.substring(4, 6) ?? "";
    }

    this.initialize();
  },
  mounted() {
  },
  methods: {
    async initialize() {
      console.log(1)
      storeSwitch.on("loading");
      if(!this.showDetail && Boolean(!this.searchParam.prfmncId)) await this.registerChk();
      await this.loadPrfmncSttsCd();
      await this.readBplcInfo();
      await this.readPrfmncMst();

      const result = await this.checkPermittedBplc();
      if (!result) {
        alert("허용되지 않은 사업장입니다.");
        this.$router.push(ROUTE.LIST);
        storeSwitch.off("loading");
        return;
      }

      // bplcKndCd 체크 후 리디렉션
      if (this.prfmncMst.bplcKndCd !== "03-01" && this.prfmncMst.bplcKndCd !== "03-02") {
        this.$router.push(ROUTE.LIST);
        storeSwitch.off("loading");
        return; // 리디렉션 후 나머지 초기화 작업을 중단
      }

      await this.readPrfmncFclt();
      await this.readPrfmncRsrcRcycln();
      await this.readPrfmncDtlFireRtrvl();
      await this.readPrfmncWtCryn();
      await this.readPrfmncNtsl();
      await this.loadAsstFuelInputInfo();
      storeSwitch.off("loading");
    },
    async registerChk() {
      this.$apiCall.post(
          API.PERFORMANCE_REGISTER_POSIBILITY_IDENTIFY,
          this.searchParam,
          () => {},
          (error) => {
            alert(error.message);
            this.$router.push(ROUTE.LIST);
          }
      );
    },
    async loadPrfmncSttsCd() {
      const prfmncSttsList = this.$store.getters.getCodeList({groupCd: CODE.PERFORMANCE_STATUS, useYn: 'Y'});
      this.prfmncSttsList = prfmncSttsList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    // 사업장정보 호출
    async readBplcInfo() {
      console.log('사업장정보호출 시작')
      return new Promise((resolve, reject) => {
        this.$apiCall.get(
            API.BUSINESS_PLACE_INFO,
            this.searchParam,
            (data) => {
              if(data.result) {
                this.prfmncMst = {
                  ...this.prfmncMst,
                  bplcId: data.result.bplcId,
                  bplcNm: data.result.bplcNm,
                  bplcKndNm: data.result.bplcKndNm,
                  prfmncSttsCd: "IPG",
                  bplcKndCd: data.result.bplcKndCd,
                };
                // 실적공통조회 시 신규 실적은 실적이 없으니 데이터를 아예 조회해오지 못해서 사업장종류, 자원순환시설ID가 NULL 이여서
                this.searchParam.bplcKndCd = data.result.bplcKndCd;
                this.searchParam.rsrcRcyclnFcltId = data.result.rsrcRcyclnFcltId;
                console.dir(this.searchParam)
                console.log('사업장정보호출 끝 ')
              }
              resolve(data);
            },
            (error) => {
              new Error("Failed to load readPrfmncMst.");
              reject(error);
            }
        );
      });
    },
    // 실적보고공통정보조회 호출
    async readPrfmncMst() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(
            API.PRFMNC_MST,
            this.searchParam,
            (data) => {
              if(data.result.prfmncMst) {
                this.prfmncMst = data.result.prfmncMst;
                this.searchParam.bplcId = this.prfmncMst.bplcId;
                this.searchParam.bplcKndCd = this.prfmncMst.bplcKndCd;
                this.searchParam.prfmncYm = this.prfmncMst.prfmncYm;
                this.searchParam.prfmncYr = this.prfmncMst.prfmncYr;
                this.searchParam.prfmncMm = this.prfmncMst.prfmncMm;
                this.searchParam.rsrcRcyclnFcltId = this.prfmncMst.rsrcRcyclnFcltId;
              }
              resolve(data);
              console.log('실적보고공통정보조회 시작')
              console.dir(this.searchParam);
              console.log('실적보고공통정보조회 끝')
            },
            (error) => {
              new Error("Failed to load readPrfmncMst.");
              reject(error);
            }
        );
      });
    },
    checkPermittedBplc() {
      return new Promise((resolve) => {
        this.$apiCall.get(API.CHECK_PERMITTED_BUSINESS,
            { bplcId: this.prfmncMst.bplcId },
            data => {
              resolve(data.result);
            },
            () => {
              resolve(false);
            }
        );
      });
    },
    // 실적 시설조회
    async readPrfmncFclt() {
      this.$apiCall.get(
          API.FACILITIES_INFO_BY_BUSINESS_PLACE,
          {
            bplcId: this.prfmncMst.bplcId,
            fcltSttsCd: "01",
            useYn: "Y",
          },
          (data) => {
            this.prfmncFclt = data.result;
          },
          new Error("Failed to load readPrfmncFclt.")
      );
    },
    // 소각실적 조회
    async readPrfmncDtlFireRtrvl() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(
            API.FIRE_RTRVL_DTL,
            {...this.searchParam, mode: this.mode},
            (data) => {
              this.prfmncFireRtrvl = data.result.fireRtrvlPrfmnc;
              this.prvPrfmncFireRtrvl = data.result.prvFireRtrvlPrfmnc;
              this.prfmncFireRtrvlPrdctnList = data.result.fireRtrvlPrdctnPrfmncList;
              this.prfmncFireRtrvlWtInputList = data.result.fireRtrvlInputPrfmncList;
              resolve(data);
            },
            (error) => {
              new Error("Failed to load readPrfmncDtlFireRtrvl.");
              reject(error);
            }
        );
      });
    },
    // 자원순환반입정보 조회
    async readPrfmncRsrcRcycln() {
      this.$apiCall.get(
          API.PRFMNC_RSRC_RCYCLN,
          { ...this.searchParam, mode: this.mode },
          (data) => {
            this.prfmncFireRtrvlRsrcRcyclnList = data.result;
          },
          new Error("Failed to load readPrfmncRsrcRcycln.")
      );
    },
    // 폐기물반입현황 조회
    async readPrfmncWtCryn() {
      const params = { ...this.searchParam, mode: this.mode };

      this.$apiCall.get(
          API.PRFMNC_CRYN_STTS,
          params,
          (data) => {
            this.prfmncFireRtrvlWtCrynList = data.result;
          },
          new Error("Failed to load readPrfmncWtCryn.")
      );
    },
    // 소각판매현황 조회
    async readPrfmncNtsl() {
      this.$apiCall.get(
          API.PRFMNC_NTSL_STTS,
          {...this.searchParam, mode: this.mode},
          (data) => {
            this.prfmncFireRtrvlNtslList = data.result;
          },
          new Error("Failed to load readPrfmncNtsl.")
      );
    },
    // 시설별 보조연료투입 현황 조회
    loadAsstFuelInputInfo() {
      return new Promise((resolve, reject) => {
        if (parseInt(this.searchParam.prfmncYr) >= 2025) {
          this.$apiCall.get(API.ASSISTANCE_FUEL_INPUT_INFORMATION,
              Object.assign({}, this.searchParam, {mode:this.mode}),
              data => {
                if (data.result) {
                  this.asstFuelInputInfo = data.result;
                }
                resolve();
              },
              () => {
                reject(new Error("Failed to load prfmnc prcs info."));
              }
          );
        } else {
          resolve();
        }
      });
    },
  }
}
</script>