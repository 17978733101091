<template>

  <h2 class="title1 mt_0">실적보고 상세</h2>
  <div class="board_write">
    <table>
      <caption>실적연도, 실적상태, 사업장명, 사업장종류</caption>
      <colgroup>
        <col style="width:18%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:32%">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">실적연월</th>
        <td>{{ formattedPrfmncYm }}</td>
        <th scope="row">실적상태</th>
        <td>{{ bioGasDtlObj.prfmncMst.prfmncSttsNm || "작성중" }}</td>
      </tr>
      <tr>
        <th scope="row">사업장명</th>
        <td>{{ bioGasDtlObj.prfmncMst.bplcNm }}</td>
        <th scope="row">사업장종류</th>
        <td>{{ bioGasDtlObj.prfmncMst.bplcKndNm }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <rjct-rsn-area :prfmncBasicInfo="bioGasDtlObj.prfmncMst" />
  <prfmnc-mdfcn-prm-area :prfmncBasicInfo="bioGasDtlObj.prfmncMst" @dmnd-rtrcn="dmndRtrcnCmptn" />

  <div class="board_info mt_40">
    <h3 class="title2" tabindex="-1" ref="prfmncDtlBioGasWtCrynTitle">폐기물 반입 현황</h3>
  </div>

  <div class="grid_search">
    <h4>반입 폐기물 종류는 마이오피스 > 사업장정보에서 관리할 수 있습니다.</h4>
  </div>
  <sb-grid
      id="prfmncDtlBioGasWtCrynGrid"
      :dataSource="dataSourceBioGasWtCryn"
      :columns="gridColumnsBioGasWtCryn"
      :gridattr="gridAttrBioGasWtCryn"
      v-model:grid="prfmncBioGasWtCrynGridObject"
      @loaded="gridLoadedBioGasWtCryn"
      :refreshGrid="gridReloadBioGasWtCryn"
      @change="changeBioGasWtCryn"
  />

  <div class="board_info mt_40">
    <h3 class="title2">
      폐기물 투입 현황
      <span>* 투입량 작성이 불가능한 경우, 반입량으로 작성</span>
    </h3>
    <br-button label="반입량을 투입량으로 적용" size="xsm" format="tertiary" @click="wtInpqtyAplcn" />
  </div>

  <div class="board_list">
    <table style="margin-bottom: 10px;">
      <caption>폐기물 투입 현황</caption>
      <colgroup>
        <col style="width: 10%">
        <col style="width: 10%">
        <col v-for="inputItem in filteredInputItemList"
             :key="inputItem.inputItemCd"
             :style="{
            width: `${filteredInputItemList?.some(item => item.inputItemCd.includes('9999'))
              ? `calc(70% / ${filteredInputItemList.length})`
              : `calc(80% / ${filteredInputItemList.length})`}`
              }"
        >
        <col v-if="filteredInputItemList?.some(item => item.inputItemCd.includes('9999'))" style="width: 10%">
      </colgroup>
      <thead>
      <tr>
        <th scope="col" colspan="2">투입량 합계(톤)</th>
        <th v-for="inputItem in filteredInputItemList" :key="inputItem.inputItemCd" scope="col">{{ inputItem.inputItemNm+'(톤)' }}</th>
        <th v-if="filteredInputItemList?.some(item => item.inputItemCd.includes('9999'))" scope="col">기타종류</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th scope="row" colspan="2">{{ formatNumber(totalInputNsqty) }}</th>
        <td v-for="inputItem in filteredInputItemList" :key="inputItem.inputItemCd" class="txt_right" :aria-label="inputItem.inputItemNm">
          <br-form-input :label="inputItem.inputItemNm" placeholder="0" v-model="inputItem.wtInpqty" :maxlength="15" validator="number" align="right" unit="" panel="login" />
        </td>
        <td v-if="filteredInputItemList?.some(item => item.inputItemCd.includes('9999'))" class="txt_right">
          <br-form-input label="기타종류" v-model="etcWtKndCn" :maxlength="500" align="right" panel="login" />
        </td>
      </tr>
      <tr>
        <th scope="row" rowspan="3" class="bg-green-light">투입량검증</th>
        <th class="bg-green-light">시설용량(톤/30일)</th>
        <td v-for="inputItem in filteredInputItemList" :key="inputItem.inputItemCd" class="txt_right" :aria-label="inputItem.inputItemNm">
          <br-form-input label="" placeholder=" " v-model="inputItem.mndlyDilyInpqty" :maxlength="15" validator="number" align="right" panel="login" readonly />
        </td>
        <td v-if="filteredInputItemList?.some(item => item.inputItemCd.includes('9999'))" class="txt_right">
        </td>
      </tr>
      <tr>
        <th class="bg-green-light">초과용량(톤)</th>
        <td v-for="excessItem in excessCapacityList" :key="excessItem.inputItemCd" class="txt_right" :aria-label="excessItem.inputItemNm">
          <br-form-input label="" v-model="excessItem.excessCapacity" :maxlength="15" validator="number" align="right" unit="" panel="login" :class="excessItem.excessCapacity > 0 ? 'not-good' : ''" readonly />
        </td>
        <td v-if="bioGasDtlObj.prfmncBioGasWtInputList.inputItemList?.some(item => item.inputItemCd.includes('9999'))" class="txt_right">
        </td>
      </tr>
      <tr>
        <th class="bg-green-light">검증결과</th>
        <td v-for="resultItem in validationResultList" :key="resultItem.inputItemCd" :class="resultItem.validationResult === '초과' ? 'not-good' : ''" :aria-label="resultItem.inputItemNm">
          {{ resultItem.validationResult }}
        </td>
        <td v-if="bioGasDtlObj.prfmncBioGasWtInputList.inputItemList?.some(item => item.inputItemCd.includes('9999'))" class="txt_right">
        </td>
      </tr>
      </tbody>
    </table>
    <span v-if="filteredInputItemListHs">
      * 하수슬러지 시설용량(톤/30일) : {{ filteredInputItemListHs }} <br/>
      * 생슬러지, 잉여슬러지, 탈수케이크 투입량 합계가 하수슬러지 시설용량(톤/30일)을 넘을 경우 검증결과에 초과로 표시됩니다.<br/>
      * 검증결과가 초과여도 실적 제출이 가능합니다.
    </span>

  
  </div>

  <div class="board_info mt_40">
    <h3 class="title2">바이오가스시설 가동 현황</h3>
  </div>

  <h4 class="title3">가스정제설비 보유 현황</h4>
  <div class="board_write thead">
    <table>
      <caption>가스정제설비 보유 현황</caption>
      <colgroup>
        <col style="width: 20%;">
        <col style="width: 30%;">
        <col style="width: 20%;">
        <col style="width: 30%;">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">전 처리 설비 현황</th>
        <td colspan="3" aria-label="전 처리 설비 현황">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.prprcsEquipCn" :maxlength="1000" validator="" align="left" unit="" panel="login" required />
        </td>
      </tr>
      <tr>
        <th scope="row">메탄농도</th>
        <td aria-label="메탄농도">
          <br-form-input ref="bgspgrBfrMthnDensInput" label="" v-model="bioGasDtlObj.prfmncBioGas.bgspgrBfrMthnDens" :maxlength="8" validator="number" align="right" unit="%" panel="login" required />
        </td>
        <th scope="row">고질화 방법</th>
        <td aria-label="고질화 방법">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.bgspgrMthdCn" :maxlength="500" validator="" align="left" unit="" panel="login" required />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h4 class="title3">소화 후 발생 폐기물 - 탈수케익</h4>
  <div class="board_write thead">
    <table>
      <caption>소화 후 발생 폐기물 - 탈수케익</caption>
      <colgroup>
        <col style="width: 20%;">
        <col style="width: 30%;">
        <col style="width: 20%;">
        <col style="width: 30%;">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">발생량</th>
        <td aria-label="발생량">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.dwscPrdqty" :maxlength="15" validator="number" align="right" unit="톤" panel="login" required />
        </td>
        <th scope="row">발생량 함수율</th>
        <td aria-label="발생량 함수율">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.dwscPrdctnMstcnt" :maxlength="6" validator="number" align="right" unit="%" panel="login" required />
        </td>
      </tr>
      <tr>
        <th scope="row">처리량</th>
        <td aria-label="처리량">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.dwscPrcqty" :maxlength="15" validator="number" align="right" unit="톤" panel="login" required />
        </td>
        <th scope="row">처리방법</th>
        <td aria-label="처리방법">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.dwscPrcsMthdCn" :maxlength="1000" validator="" align="left" unit="" panel="login" required />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h4 class="title3">소화 후 발생 폐기물 - 폐수</h4>
  <div class="board_write thead">
    <table>
      <caption>소화 후 발생 폐기물 - 폐수</caption>
      <colgroup>
        <col style="width: 20%;">
        <col style="width: 30%;">
        <col style="width: 20%;">
        <col style="width: 30%;">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">정화</th>
        <td aria-label="정화">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.prfctnWstwtrPrdqty" :maxlength="15" validator="number" align="right" unit="톤" panel="login" required />
        </td>
        <th scope="row">액비</th>
        <td aria-label="액비">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.liquidWstwtrPrdqty" :maxlength="15" validator="number" align="right" unit="톤" panel="login" required />
        </td>
      </tr>
      <tr>
        <th scope="row">위탁처리 방법</th>
        <td aria-label="위탁처리 방법">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.wstwtrCnsgnPrcsMthdCn" :maxlength="500" validator="" align="left" unit="" panel="login" required />
        </td>
        <th scope="row">위탁처리시 설명</th>
        <td aria-label="위탁처리시 설명">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGas.wstwtrCnsgnPrcsCn" :maxlength="500" validator="" align="left" unit="" panel="login" required />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info mt_40">
    <h3 class="title2" tabindex="-1" ref="asstFuelTitle">보조연료 투입 현황</h3>
    <div>
      <br-button label="행 추가" size="xsm" format="primary line mr_5" @click="addAsstFuelInput" />
      <br-button label="행 삭제" size="xsm" format="point line" @click="deleteAsstFuelInput" />
    </div>
  </div>
  <sb-grid
      id="asstFuelInputGrid"
      :dataSource="asstFuelInputDataSource"
      :columns="asstFuelInputGridColumns"
      :gridattr="asstFuelInputGridAttr"
      v-model:grid="asstFuelInputGridObject"
      @loaded="asstFuelInputGridLoaded"
      :refreshGrid="asstFuelInputGridReload"
      @change="changeAsstFuelInput"
  />
  <div>
    <h5>※ 보조연료 투입량은 월별 누계값을 입력 하셔야 합니다.</h5>
  </div>

  <div class="board_info mt_40">
    <h3 class="title2">바이오가스 생산·이용 현황</h3>
  </div>

  <h4 class="title3">바이오가스 생산 현황</h4>
  <div class="board_write thead">
    <table>
      <caption>바이오가스 생산 현황</caption>
      <colgroup>
        <col style="width: 12%;">
        <col style="width: 88%;">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">총 생산량</th>
        <td class="txt_left" aria-label="총 생산량">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.enePrdqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" readonly required />
<!--          <div class="tooltip">-->
<!--            <button type="button" class="btn_tool">도움말</button>-->
<!--            <div class="tool_cont" style="display: none;">-->
<!--              <p>총 이용량(A) + 미이용(단순처리)(B)</p>-->
<!--              <button type="button" class="tool_close">닫기</button>-->
<!--            </div>-->
<!--          </div>-->
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h4 class="title3">바이오가스 이용 현황</h4>
  <div class="board_write thead">
    <table>
      <caption>바이오가스 이용 현황</caption>
      <colgroup>
        <col style="width: 12%;">
        <col style="width: 38%;">
        <col style="width: 20%;">
        <col style="width: 30%;">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">총 이용량(A)</th>
        <td class="txt_left" aria-label="총 이용량(A)">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.eneUtzqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" readonly required />
          <!--          <div class="tooltip">-->
          <!--            <button type="button" class="btn_tool">도움말</button>-->
          <!--            <div class="tool_cont" style="display: none;">-->
          <!--              <p>총 이용량(A) + 미이용(단순처리)(B)</p>-->
          <!--              <button type="button" class="tool_close">닫기</button>-->
          <!--            </div>-->
          <!--          </div>-->
        </td>
        <th scope="row">미이용(단순처리)(B)</th>
        <td class="txt_left" aria-label="미이용(단순처리)(B)">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.eneUnuqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" required />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h4 class="title3">자체사용 현황</h4>
  <div class="board_write thead">
    <table>
      <caption>자체사용 현황</caption>
      <colgroup>
        <col style="width: 15%;">
        <col>
        <col style="width: 15%;">
        <col>
        <col style="width: 15%;">
        <col>
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">자체이용 합계(C)</th>
        <td class="txt_left" colspan="5" aria-label="자체이용 합계(C)">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.gasStotUsqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" readonly required />
        </td>
      </tr>
      <tr>
        <th scope="row">발전</th>
        <td class="txt_left" aria-label="발전">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.elctgnUsgGasUsqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
        <th scope="row">소화조 가온용</th>
        <td class="txt_left" aria-label="소화조 가온용">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.dgstrWrmngUsgGasUsqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
        <th scope="row">슬러지 건조용</th>
        <td class="txt_left" aria-label="슬러지 건조용">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.sldgDhmdfyUsgGasUsqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
      </tr>
      <tr>
        <th scope="row">슬러지 소각용</th>
        <td class="txt_left" aria-label="슬러지 소각용">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.sldgFireUsgGasUsqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
        <th scope="row">음식물 건조용</th>
        <td class="txt_left" aria-label="음식물 건조용">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.foodDhmdfyUsgGasUsqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
        <th scope="row">악취제거</th>
        <td class="txt_left" aria-label="악취제거">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.bsmlRmvlUsgGasUsqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
      </tr>
      <tr>
        <th scope="row">스팀</th>
        <td class="txt_left" aria-label="스팀">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.stUsgGasUsqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
        <th scope="row">스팀용도</th>
        <td class="txt_left" colspan="3" aria-label="스팀용도">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.stUsgGasUseCn" :maxlength="500" validator="" align="left" unit="" panel="login" />
        </td>
      </tr>
      <tr>
        <th scope="row">기타</th>
        <td class="txt_left" aria-label="기타">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.etcUsgGasUsqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
        <th scope="row">기타설명</th>
        <td class="txt_left" colspan="3" aria-label="기타설명">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.etcUsgGasUseCn" :maxlength="500" validator="" align="left" unit="" panel="login" />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h4 class="title3">
    외부공급 현황
    <span class="txt_hint">※ 외부공급은 자체시설이 아닌 외부시설에 보낸 바이오가스 양 입력해 주세요.</span>
  </h4>
  <div class="board_write thead">
    <table>
      <caption>외부공급 현황</caption>
      <colgroup>
        <col style="width: 15%;">
        <col>
        <col style="width: 15%;">
        <col>
        <col style="width: 15%;">
        <col>
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">외부공급 합계(C)</th>
        <td class="txt_left" colspan="5" aria-label="외부공급 합계(C)">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.gasStotSupqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" readonly required />
        </td>
      </tr>
      <tr>
        <th scope="row">발전</th>
        <td class="txt_left" aria-label="발전">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.elctgnUsgGasSupqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
        <th scope="row">소화조 가온용</th>
        <td class="txt_left" aria-label="소화조 가온용">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.dgstrWrmngUsgGasSupqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
        <th scope="row">슬러지 건조용</th>
        <td class="txt_left" aria-label="슬러지 건조용">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.sldgDhmdfyUsgGasSupqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
      </tr>
      <tr>
        <th scope="row">슬러지 소각용</th>
        <td class="txt_left" aria-label="슬러지 소각용">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.sldgFireUsgGasSupqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
        <th scope="row">음식물 건조용</th>
        <td class="txt_left" aria-label="음식물 건조용">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.foodDhmdfyUsgGasSupqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
        <th scope="row">악취제거</th>
        <td class="txt_left" aria-label="악취제거">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.bsmlRmvlUsgGasSupqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
      </tr>
      <tr>
        <th scope="row">스팀</th>
        <td class="txt_left" aria-label="스팀">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.stUsgGasSupqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
        <th scope="row">스팀용도</th>
        <td class="txt_left" colspan="3" aria-label="스팀용도">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.stUsgGasSplyCn" :maxlength="500" validator="" align="left" unit="" panel="login" />
        </td>
      </tr>
      <tr>
        <th scope="row">기타</th>
        <td class="txt_left" aria-label="기타">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.etcUsgGasSupqty" :maxlength="15" validator="number" align="right" unit="N㎥" panel="login" />
        </td>
        <th scope="row">기타설명</th>
        <td class="txt_left" colspan="3" aria-label="기타설명">
          <br-form-input label="" v-model="bioGasDtlObj.prfmncBioGasPrdctn.etcUsgGasSplyCn" :maxlength="500" validator="" align="left" unit="" panel="login" />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info mt_40">
    <h3 class="title2" tabindex="-1" ref="asstTnslTitle">에너지 생산·판매량 현황</h3>
    <div>
      <br-button label="행 추가" size="xsm" format="primary line mr_5" @click="addNtslStts" />
      <br-button label="행 삭제" size="xsm" format="point line" @click="deleteNtslStts" />
    </div>
  </div>
  <div class="grid_search">
    <h4>
      이용자별 판매량 현황
      <span>(※ 도시가스사업법에 따른 1차 판매자를 입력해주세요.) , 판매량 현황 추가는 행추가 버튼을 눌러서 입력해 주세요.</span>
    </h4>
  </div>
  <sb-grid
      id="prfmncBioGasNtslGrid"
      :dataSource="dataSourceBioGasNtsl"
      :columns="gridColumnsBioGasNtsl"
      :gridattr="gridAttrBioGasNtsl"
      v-model:grid="prfmncBioGasNtslGridObject"
      @loaded="gridLoadedBioGasNtsl"
      :refreshGrid="gridReloadBioGasNtsl"
      @change="changeBioGasNtsl"
  />

  <!-- 실적상태이력 현황 (신규등록인 경우 노출안함) -->
  <prfmnc-stts-hstry v-if="bioGasDtlObj.prfmncMst.prfmncId" :prfmncId="bioGasDtlObj.prfmncMst.prfmncId" />

  <!-- 수정허용요청 이력 현황 (신규등록인 경우 노출안함) -->
  <prfmnc-mdfcn-prm-hstry v-if="bioGasDtlObj.prfmncMst.prfmncId" :prfmncId="bioGasDtlObj.prfmncMst.prfmncId" />

  <!-- 버튼 -->
  <common-button-area
      :prfmncBasicInfo="bioGasDtlObj.prfmncMst"
      @save="prtmncRptDtlSave"
      @submission="prtmncRptDtlSave(true)"
  />
</template>

<script>
import SbGrid from "@/components/SbGrid.vue";
import utils from "@/js/utils/utils";
import commonButtonArea from "@/views/prfmnc-rpt/dtl/components/CommonButtonArea.vue";
import prfmncSttsHstry from "@/views/prfmnc-rpt/components/PrfmncSttsHstry.vue";
import PrfmncMdfcnPrmHstry from "@/views/prfmnc-rpt/components/PrfmncMdfcnPrmHstry.vue";
import PrfmncMdfcnPrmArea from "@/views/prfmnc-rpt/dtl/components/PrfmncMdfcnPrmArea.vue";
import RjctRsnArea from "@/views/prfmnc-rpt/dtl/components/RjctRsnArea.vue";

const ROUTE = {
  PERFORMANCE_LIST: "/prfmnc-rpt/PrfmncRpt",
  PERFORMANCE_DTL: "/prfmnc-rpt/dtl/BioGas"
};

const API = {
  PRFMNC_DTL_SAVE: "/prfmnc/bioGas", // 실적 합성가스 상세저장
};

const CODE = {
  PERFORMANCE_STATUS: "PFM001", // 실적상태코드
  ASSISTANCE_FUEL_KIND_GROUP: "PFM007", // 보조연료종류그룹코드
  ASSISTANCE_FUEL_KIND: "PFM008", // 보조연료종류코드
  ASSISTANCE_FUEL_UNIT: "PFM009", // 보조연료단위코드
  LOW_CALORIFIC_POWER_UNIT: "PFM010", // 저위발열량단위코드
  ENE_TYPE: "SIS068", // 에너지유형코드
  NTSL_TYPE: "SIS069", // 판매유형코드
  NTSL_SE: "SIS070", // 판매구분코드
  ITEM: "MBR010", // 품목코드
};

export default {
  components: {
    RjctRsnArea, PrfmncMdfcnPrmArea,
    SbGrid,
    commonButtonArea,
    prfmncSttsHstry,
    PrfmncMdfcnPrmHstry
  },
  props: ['prfmncBioGas', 'prfmncBioGasPrdctn', 'bioGasEnePrfmnc', 'prfmncBioGasWtCrynList', 'prfmncBioGasWtInputList', 'prfmncBioGasNtslList', 'prfmncMst', 'prfmncFclt', 'bioGasNtslEneSplyBzenty', 'asstFuelInputInfo', 'showDetail'],
  data() {
    return {
      asstFuelKndGroupCdList: [], // 보조연료종류그룹코드
      asstFuelKndCdList: [], // 보조연료종류코드
      asstFuelUnitCdList: [], // 보조연료단위코드
      lcpUnitCdList: [], // 저위발열량단위코드
      eneTypeCdList: [], // 에너지유형코드
      ntslTypeCdList: [], // 판매유형코드
      ntslSeCdList: [], // 판매구분코드
      itemCdList: [], // 품목코드

      // 실적 바이오가스 상세정보 데이터
      bioGasDtlObj:{
        totalPrvtmNsqty: 0,  // 에너지 생산·판매량 현황 전기판매량 합계
        totalGasNsqty: 0,  // 에너지 생산·판매량 현황 가스판매량 합계
        totalVaporNsqty: 0,  // 에너지 생산·판매량 현황 증기판매량 합계
        totalHtwtrNsqty: 0,  // 에너지 생산·판매량 현황 온수판매량 합계
        totalEtcNsqty: 0,  // 에너지 생산·판매량 현황 기타판매량 합계

        prfmncMst: {},
        prfmncBioGasWtCrynList : [], // 폐기물반입현황
        prfmncBioGasWtInputList : [], // 폐기물투입현황
        prfmncBioGasNtslList : [], // 바이오가스판매현황
        prfmncBioGas: {  // [바이오가스실적]
          digstnBfrVsDens: 0,  // 소화이전휘발성고형물농도
          digstnBfrSsDens: 0,  // 소화이전부유물질량농도
          digstnBfrBodDens: 0,  // 소화이전산소요구량농도
          digstnBfrTsDens: 0,  // 소화이전총고형물농도
          digstnAftrVsDens: 0,  // 소화이후휘발성고형물농도
          digstnAftrSsDens: 0,  // 소화이후부유물질량농도
          digstnAftrBodDens: 0,  // 소화이후산소요구량농도
          digstnAftrTsDens: 0,  // 소화이후총고형물농도
          prprcsEquipCn: '',  // 전처리설비내용
          bgspgrBfrMthnDens: 0,  // 바이오가스고질화이전메탄농도
          bgspgrMthdCn: '',  // 바이오가스고질화방법내용
          bgspgrAftrMthnDens: 0,  // 바이오가스고질화이후메탄농도
          dwscPrdqty: 0,  // 탈수케이크생산량
          dwscPrdctnMstcnt: 0,  // 탈수케이크생산함수율
          dwscPrcqty: 0,  // 탈수케이크처리량
          dwscPrcsMthdCn: '',  // 탈수케이크처리방법내용
          prfctnWstwtrPrdqty: 0,  // 정화폐수생산량
          liquidWstwtrPrdqty: 0,  // 액비폐수생산량
          wstwtrCnsgnPrcsMthdCn: '',  // 폐수위탁처리방법내용
          wstwtrCnsgnPrcsCn: '',  // 폐수위탁처리내용
          oilAsstFuelUsqty: 0,  // 유류보조연료사용량
          lngAsstFuelUsqty: 0,  // LNG보조연료사용량
          prvtmAsstFuelUsqty: 0,  // 전기보조연료사용량
          etcAsstFuelUsqty: 0,  // 기타보조연료사용량
          etcAsstFuelKndCn: '',  // 기타보조연료종류내용
        },
        prfmncBioGasPrdctn: {  // [바이오가스생산실적]
          flwmtrPstnCd: '',  // 유량계위치코드
          flwmtrPstnNm: '',  // 유량계위치코드명
          nm3Yn: '',  // 노멀세제곱미터여부
          flwmtrCrctYmd: '',  // 유량계보정일자
          gasStotUsqty: 0,  // 가스소계사용량
          elctgnUsgGasUsqty: 0,  // 발전용도가스사용량
          dgstrWrmngUsgGasUsqty: 0,  // 소화조가온용도가스사용량
          sldgDhmdfyUsgGasUsqty: 0,  // 슬러지건조용도가스사용량
          sldgFireUsgGasUsqty: 0,  // 슬러지소각용도가스사용량
          foodDhmdfyUsgGasUsqty: 0,  // 식품건조용도가스사용량
          bsmlRmvlUsgGasUsqty: 0,  // 악취제거용도가스사용량
          stUsgGasUsqty: 0,  // 스팀용도가스사용량
          stUsgGasUseCn: '',  // 스팀용도가스사용내용
          etcUsgGasUsqty: 0,  // 기타용도가스사용량
          etcUsgGasUseCn: '',  // 기타용도가스사용내용
          gasStotSupqty: 0,  // 가스소계공급량
          elctgnUsgGasSupqty: 0,  // 발전용도가스공급량
          dgstrWrmngUsgGasSupqty: 0,  // 소화조가온용도가스공급량
          sldgDhmdfyUsgGasSupqty: 0,  // 슬러지건조용도가스공급량
          sldgFireUsgGasSupqty: 0,  // 슬러지소각용도가스공급량
          foodDhmdfyUsgGasSupqty: 0,  // 식품건조용도가스공급량
          bsmlRmvlUsgGasSupqty: 0,  // 악취제거용도가스공급량
          stUsgGasSupqty: 0,  // 스팀용도가스공급량
          stUsgGasSplyCn: '',  // 스팀용도가스공급내용
          etcUsgGasSupqty: 0,  // 기타용도가스공급량
          etcUsgGasSplyCn: '',  // 기타용도가스공급내용
          enePrdqty: 0,  // 에너지생산량
          eneUtzqty: 0,  // 에너지이용량
          eneUnuqty: 0,  // 에너지미이용량
        },
        bioGasEnePrfmnc: {
          prvtmPrdqty: 0,  // 전기생산량
          prvtmUsqty: 0,  // 전기사용량
          prvtmSumNsqty: 0,  // 전기합계판매량
          prvtmNtslSumAmt: 0,  // 전기판매합계금액 (화면미노출이나 저장필요)
          gasPrdctnNm3: 0,  // 가스생산노멀세제곱미터
          gasPrdctnGcal: 0,  // 가스생산기가칼로리
          gasSumNsqty: 0,  // 가스합계판매량
          gasNtslGcal: 0,  // 가스판매기가칼로리
          gasNtslSumAmt: 0,  // 가스판매합계금액 (화면미노출이나 저장필요)
          vaporPrdctnTon: 0,  // 증기생산톤수
          vaporPrdctnGcal: 0,  // 증기생산기가칼로리
          vaporSumNsqty: 0,  // 증기합계판매량
          vaporNtslGcal: 0,  // 증기판매기가칼로리
          srtrtVaporTp: 0,  // 포화증기온도
          srtrtVaporPrssVl: 0,  // 포화증기압력값
          vaporNtslSumAmt: 0,  // 증기판매합계금액 (화면미노출이나 저장필요)
          htwtrPrdctnTon: 0,  // 온수생산톤수
          htwtrPrdctnGcal: 0,  // 온수생산기가칼로리
          htwtrSumNsqty: 0,  // 온수합계판매량
          htwtrNtslGcal: 0,  // 온수판매기가칼로리
          htwtrSrtrtVaporTp: 0,  // 온수포화증기온도
          htwtrSrtrtVaporPrssVl: 0,  // 온수포화증기압력값
          htwtrNtslSumAmt: 0,  // 온수판매합계금액 (화면미노출이나 저장필요)
          etcEnePrdctnTon: 0,  // 기타에너지생산톤수
          etcEnePrdctnGcal: 0,  // 기타에너지생산기가칼로리
          etcEneSumNsqty: 0,  // 기타에너지합계판매량
          etcEnePrdctnNtslCn: '',  // 기타에너지생산판매내용
          etcEneNtslSumAmt: 0,  // 기타에너지판매합계금액 (화면미노출이나 저장필요)
        },
        asstFuelInputInfo: {  // [보조연료 투입현황]
        },
      },


      /**************** S : 폐기물반입현황 Grid ****************/
      dataSourceBioGasWtCryn: [],
      gridColumnsBioGasWtCryn: [],
      gridAttrBioGasWtCryn: {
        checkable: false,
        pageable: false,
        editable: {eventType: 'mouseup'},
        showRowNo : false,
        mergeRow: true,
        excelExport: {
          fileName: '폐기물반입현황(바이오가스).xlsx',
          includeHeader: true,
          keepMerge: true,
        },
        showStatus:true
      },
      prfmncBioGasWtCrynGridObject: null,
      gridReloadBioGasWtCryn: false,
      /**************** E : 폐기물반입현황 Grid ****************/

      /**************** S : 보조연료 투입 현황 Grid ****************/
      asstFuelInputGridReload: false,
      asstFuelInputGridObject: null,
      asstFuelInputDataSource: [],
      asstFuelInputGridColumns: [],
      asstFuelInputGridAttr: {
        showRowNo: false,
        checkable: true,
        pageable: false,
        editable: {eventType: 'mouseup'},
        toolBar: [],
        hideDeleted: true,
        showStatus:true
      },
      /**************** E : 보조연료 투입 현황 Grid ****************/

      /**************** S : 바이오가스판매현황 Grid ****************/
      dataSourceBioGasNtsl: [],
      gridColumnsBioGasNtsl: [],
      gridAttrBioGasNtsl: {
        checkable: true,
        pageable: false,
        editable: {eventType: 'mouseup'},
        showRowNo : false,
        excelExport: {
          fileName: '에너지생산판매량현황(바이오가스).xlsx',
          includeHeader: true,
          includeFormula: true,
        },
        showStatus:true
      },
      prfmncBioGasNtslGridObject: null,
      gridReloadBioGasNtsl: false,
      /**************** E : 바이오가스판매현황 Grid ****************/

      lastSum : '', // 반입현황 유효성 체크
    };
  },
  computed: {
    /**
     * [실적보고상세 - 실적연월]
     * YYYY-MM 포맷처리
     */
    formattedPrfmncYm() {
      return (this.bioGasDtlObj.prfmncMst.prfmncYm?.substring(0, 4) ?? "") + "-" + (this.bioGasDtlObj.prfmncMst.prfmncYm?.substring(4, 6) ?? "");
    },
    // 폐기물 투입현황 투입량 합계 계산
    totalInputNsqty() {
      if (this.bioGasDtlObj.prfmncBioGasWtInputList && this.bioGasDtlObj.prfmncBioGasWtInputList.inputItemList) {
        return this.sanitizeFloatingPointError(
            this.bioGasDtlObj.prfmncBioGasWtInputList.inputItemList?.filter(o => !(o.frstRefVl && !o.scdRefVl && o.thdRefVl)).reduce((sum, item) => {
              return sum + (item.wtInpqty || 0);
        }, 0));
      } else {
        return 0;
      }
    },
    // 폐기물 투입현황 투입량검증 초과용량(톤) 계산
    excessCapacityList() {
      if (this.bioGasDtlObj.prfmncBioGasWtInputList && this.bioGasDtlObj.prfmncBioGasWtInputList.inputItemList) {
        let hasWeight = this.filteredInputItemListHs // 하수슬러지 시설용량 가져오기
        if(hasWeight){
          hasWeight = hasWeight.replace(/,/g, '');
        }
        let overWeight = 0; // 생슬러지, 잉여슬러지, 탈수케이크의 합
        let excessCapacity = 0;
 
        return this.bioGasDtlObj.prfmncBioGasWtInputList.inputItemList?.filter(o => !(o.frstRefVl && !o.scdRefVl && o.thdRefVl)).map(item => {

          const facilityCapacity = item.mndlyDilyInpqty || 0; // 시설용량
          const inputQuantity = item.wtInpqty || 0; // 투입량

          // 생슬러지, 잉여슬러지, 탈수케이크의 합이 하수슬러지의 시설용량을 넘으면 초과로 처리함
          if (/^002[0-2]/.test(item.inputItemCd)) {
            overWeight += item.wtInpqty || 0; // 투입량 합산
            if(inputQuantity != 0){   // 투입량이 0이 아닐때만 초과용량 표시
              excessCapacity = Math.max(0, overWeight - hasWeight);
            } else {
              excessCapacity = 0;
            }
          } else {
            excessCapacity = Math.max(0, inputQuantity - facilityCapacity); // 초과용량 계산 (음수인 경우 0으로 처리)
          }

          return {
            ...item,
            excessCapacity: excessCapacity
          };
        });
      } else {
        return 0;
      }
    },
    // 폐기물 투입현황 투입량검증 검증결과 계산
    validationResultList() {
      if (!this.excessCapacityList) {
        return [];
      }
      return this.excessCapacityList.map(item => {
        return {
          inputItemCd: item.inputItemCd,
          validationResult: item.excessCapacity > 0 ? "초과" : "적합"
        };
      });
    },
    // 폐기물투입현황 기타종류 값 처리
    etcWtKndCn: {
      get() {
        const item = this.bioGasDtlObj.prfmncBioGasWtInputList.inputItemList?.find(item => item.inputItemCd.includes('9999'));
        return item?.etcWtKndCn ?? '';
      },
      set(value) {
        const item = this.bioGasDtlObj.prfmncBioGasWtInputList.inputItemList?.find(item => item.inputItemCd.includes('9999'));
        if (item) {
          item.etcWtKndCn = value;
        }
      }
    },
    filteredInputItemList() {
      return this.bioGasDtlObj.prfmncBioGasWtInputList?.inputItemList?.filter(
          o => !(o.frstRefVl && !o.scdRefVl && o.thdRefVl)
      ) || [];
    },
    filteredInputItemListHs() {
      const filteredItems = this.bioGasDtlObj.prfmncBioGasWtInputList?.inputItemList?.filter(
        o => !o.scdRefVl
      ) || [];

      // 하수슬러지의 mndlyDilyInpqty 값만 추출
      const hwsSludge = filteredItems.find(item => item.inputItemNm === "하수슬러지");

      // 해당 값만 리턴, 숫자 포맷 적용
      if (hwsSludge && hwsSludge.mndlyDilyInpqty) {
        const formattedNumber = new Intl.NumberFormat('ko-KR').format(hwsSludge.mndlyDilyInpqty);
        return formattedNumber;
      }
      
      return null;
    }
  },
  watch: {
    prfmncMst: {
      handler(newVal) {
        this.bioGasDtlObj.prfmncMst = {...this.bioGasDtlObj.prfmncMst, ...newVal};
      },
      immediate: true,
      deep:true,
    },
    prfmncBioGas() {
      this.bioGasDtlObj.prfmncBioGas = {...this.bioGasDtlObj.prfmncBioGas, ...this.prfmncBioGas};
    },
    prfmncBioGasPrdctn() {
      this.bioGasDtlObj.prfmncBioGasPrdctn = {...this.bioGasDtlObj.prfmncBioGasPrdctn, ...this.prfmncBioGasPrdctn};
    },
    bioGasEnePrfmnc() {
      this.bioGasDtlObj.bioGasEnePrfmnc = {...this.bioGasDtlObj.bioGasEnePrfmnc, ...this.bioGasEnePrfmnc};
    },
    prfmncBioGasWtCrynList() {
      const bioGasWtCrynListCopy = this.prfmncBioGasWtCrynList.list.map(item => ({ ...item }));

      // `0020`, `0021`, `0022`로 시작하는 값들의 최대값 계산
      const keysToCheck = ['0020', '0021', '0022'];
      const maxValues = {};

      keysToCheck.forEach(keyPrefix => {
        maxValues[keyPrefix] = Math.max(
            ...bioGasWtCrynListCopy.map(item => {
              // keyPrefix로 시작하는 키를 찾아 해당 값을 가져옴
              const key = Object.keys(item).find(k => k.startsWith(keyPrefix));
              return key ? (item[key] || 0) : 0;
            })
        );
      });

      // 각 항목에 최대값 설정
      const modifiedBioGasWtCrynList = bioGasWtCrynListCopy.map(item => {
        keysToCheck.forEach(keyPrefix => {
          const key = Object.keys(item).find(k => k.startsWith(keyPrefix));
          if (key) {
            item[key] = maxValues[keyPrefix]; // 최대값 설정
          }
        });
        return item;
      });

      this.dataSourceBioGasWtCryn = { data: modifiedBioGasWtCrynList, schema: { id: 'sggStdgCd'}};
      this.initializeprfmncBioGasWtCrynGridColumns();
      const itemCount = this.prfmncBioGasWtCrynList.list.length;
      this.gridAttrBioGasWtCryn.height = Math.max((itemCount * 30) + 160, 210);
      this.gridReloadBioGasWtCryn = true;
    },
    prfmncBioGasWtInputList() {
      this.bioGasDtlObj.prfmncBioGasWtInputList = {...this.bioGasDtlObj.prfmncBioGasWtInputList, ...this.prfmncBioGasWtInputList};
    },
    prfmncBioGasNtslList() {
      this.dataSourceBioGasNtsl = this.prfmncBioGasNtslList.list;
      this.initializeprfmncBioGasNtslGridColumns();
      const itemCount = this.prfmncBioGasNtslList.list.length;
      this.gridAttrBioGasNtsl.height = Math.max((itemCount * 30) + 190, 270);
      this.gridReloadBioGasNtsl = true;
    },

    asstFuelInputInfo(){
      this.asstFuelInputDataSource = this.asstFuelInputInfo.list;
      this.initializeAsstFuelInputGridColumns();
      const itemCount = this.asstFuelInputInfo.list.length;
      // this.asstFuelInputGridAttr.height = (itemCount * 30) + 136;
      this.asstFuelInputGridAttr.height = Math.max((itemCount * 30) + 190, 210);
      this.asstFuelInputGridReload = true;
    },

    // 총이용량 값 변경 시 총생산량 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.eneUtzqty': 'updateEnePrdqty',
    // 미이용(단순처리) 값 변경 시 총생산량 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.eneUnuqty': 'updateEnePrdqty',

    // 자체사용합계 값 변경 시 총이용량 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.gasStotUsqty': 'updateEneUtzqty',
    // 외부공급합계 값 변경 시 총이용량 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.gasStotSupqty': 'updateEneUtzqty',


    // 자체사용 발전 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.elctgnUsgGasUsqty': 'updateGasStotUsqty',
    // 자체사용 소화조가온용 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.dgstrWrmngUsgGasUsqty': 'updateGasStotUsqty',
    // 자체사용 슬러지건조용 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.sldgDhmdfyUsgGasUsqty': 'updateGasStotUsqty',
    // 자체사용 슬러지소각용 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.sldgFireUsgGasUsqty': 'updateGasStotUsqty',
    // 자체사용 음식물건조용 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.foodDhmdfyUsgGasUsqty': 'updateGasStotUsqty',
    // 자체사용 악취제거 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.bsmlRmvlUsgGasUsqty': 'updateGasStotUsqty',
    // 자체사용 스팀 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.stUsgGasUsqty': 'updateGasStotUsqty',
    // 자체사용 기타 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.etcUsgGasUsqty': 'updateGasStotUsqty',

    // 외부공급 발전 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.elctgnUsgGasSupqty': 'updateGasStotSupqty',
    // 외부공급 소화조가온용 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.dgstrWrmngUsgGasSupqty': 'updateGasStotSupqty',
    // 외부공급 슬러지건조용 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.sldgDhmdfyUsgGasSupqty': 'updateGasStotSupqty',
    // 외부공급 슬러지소각용 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.sldgFireUsgGasSupqty': 'updateGasStotSupqty',
    // 외부공급 음식물건조용 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.foodDhmdfyUsgGasSupqty': 'updateGasStotSupqty',
    // 외부공급 악취제거 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.bsmlRmvlUsgGasSupqty': 'updateGasStotSupqty',
    // 외부공급 스팀 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.stUsgGasSupqty': 'updateGasStotSupqty',
    // 외부공급 기타 값 변경 시 자체이용합계 재계산
    'bioGasDtlObj.prfmncBioGasPrdctn.etcUsgGasSupqty': 'updateGasStotSupqty',

    // 전기판매량 값 변경 시 전기생산량 재계산
    'bioGasDtlObj.bioGasEnePrfmnc.prvtmUsqty': 'updatePrvtmPrdqty',
    // 전기자체이용 값 변경 시 전기생산량 재계산
    'bioGasDtlObj.bioGasEnePrfmnc.prvtmSumNsqty': 'updatePrvtmPrdqty',
  },
  created() {
    this.initialize();
  },
  mounted() {
  },
  methods: {
    dmndRtrcnCmptn() {
      location.reload();
    },
    // 폐기물투입현황 - 반입량을 투입량으로 적용 버튼
    wtInpqtyAplcn(){
      const resultTotalObj = {}; // 폐기물반입현황 품목 별 합계 데이터

      // 폐기물반입현황에 노출되어 있는 품목 별로 합계를 계산
      this.prfmncBioGasWtCrynList.inputItemList.forEach(item => {
        if (/^002[0-2]/.test(item.inputItemCd)) {
          // 최대값을 계산
          this.maxGetAllDynamicItems(this.prfmncBioGasWtCrynGridObject, item.inputItemCd, resultTotalObj);
        } else {
          // 합계를 계산
          this.totalGetAllDynamicItems(this.prfmncBioGasWtCrynGridObject, item.inputItemCd, resultTotalObj);
        }
      });

      // 폐기물반입현황의 품목 별 합계값을 가지고 폐기물투입현황 데이터에 적용
      this.bioGasDtlObj.prfmncBioGasWtInputList.inputItemList.forEach(item => {
        if (resultTotalObj[item.inputItemCd] !== undefined) {
          item.wtInpqty = resultTotalObj[item.inputItemCd]; // 0도 값 업데이트
        }
      });

      // 기타종류 처리
      const allItems = window.SBGrid3.getAllItems(this.prfmncBioGasWtCrynGridObject);
      const etcWtKndCnArr = allItems.map(item => item.etcWtKndCn).filter(Boolean);
      const etcWtCrynCnArrDistinct = [...new Set(etcWtKndCnArr)];

      this.etcWtKndCn = etcWtCrynCnArrDistinct.join(', ');
    },
    // 에너지 생산·판매량 현황 onChange
    changeBioGasNtsl(values){
      values.forEach(item => {

        if (item.field === 'eneBzentySn') {
          // 그리드의 판매업체명 변경 시 visible false로 설정되어있는 항목(실적아이디, 사업장아이디, 시도법정동코드, 시군구법정동코드) 필드 값 처리
          const filteredBzentyList = [...this.bioGasNtslEneSplyBzenty, ...this.prfmncBioGasNtslList.list].filter(o => o.eneBzentySn === item.value);
          const changeDataList = [
            { key: item.key, field: 'prfmncId', value: this.bioGasDtlObj.prfmncMst.prfmncId },
            { key: item.key, field: 'bplcId', value: this.bioGasDtlObj.prfmncMst.bplcId },
            { key: item.key, field: 'ctpvStdgCd', value: filteredBzentyList[0].ctpvStdgCd },
            { key: item.key, field: 'sggStdgCd', value: filteredBzentyList[0].sggStdgCd }
          ];
          window.SBGrid3.setValues(this.prfmncBioGasNtslGridObject, changeDataList);
        }

        if (item.field === 'prvtmNsqty') {
          // 그리드의 모든 데이터를 가져와서 전기판매량 항목의 모든 값의 합계를 계산
          const ntslAllItems = window.SBGrid3.getAllItems(this.prfmncBioGasNtslGridObject);
          const totalPrvtmNsqty = this.sanitizeFloatingPointError(
              ntslAllItems.reduce((sum, item) => {
                return sum + (item.prvtmNsqty || 0);
          }, 0));

          // this.bioGasDtlObj.totalPrvtmNsqty = totalPrvtmNsqty;
          this.bioGasDtlObj.bioGasEnePrfmnc.prvtmSumNsqty = totalPrvtmNsqty;
        }

        if (item.field === 'gasNsqty') {
          // 그리드의 모든 데이터를 가져와서 가스판매량 항목의 모든 값의 합계를 계산
          const ntslAllItems = window.SBGrid3.getAllItems(this.prfmncBioGasNtslGridObject);
          const totalGasNsqty = this.sanitizeFloatingPointError(
              ntslAllItems.reduce((sum, item) => {
                return sum + (item.gasNsqty || 0);
          }, 0));

          // this.bioGasDtlObj.totalGasNsqty = totalGasNsqty;
          this.bioGasDtlObj.bioGasEnePrfmnc.gasSumNsqty = totalGasNsqty;
        }

        if (item.field === 'vaporNsqty') {
          // 그리드의 모든 데이터를 가져와서 증기판매량 항목의 모든 값의 합계를 계산
          const ntslAllItems = window.SBGrid3.getAllItems(this.prfmncBioGasNtslGridObject);
          const totalVaporNsqty = this.sanitizeFloatingPointError(
              ntslAllItems.reduce((sum, item) => {
                return sum + (item.vaporNsqty || 0);
          }, 0));

          // this.bioGasDtlObj.totalVaporNsqty = totalVaporNsqty;
          this.bioGasDtlObj.bioGasEnePrfmnc.vaporSumNsqty = totalVaporNsqty;
        }

        if (item.field === 'htwtrNsqty') {
          // 그리드의 모든 데이터를 가져와서 온수판매량 항목의 모든 값의 합계를 계산
          const ntslAllItems = window.SBGrid3.getAllItems(this.prfmncBioGasNtslGridObject);
          const totalHtwtrNsqty = this.sanitizeFloatingPointError(
              ntslAllItems.reduce((sum, item) => {
                return sum + (item.htwtrNsqty || 0);
          }, 0));

          // this.bioGasDtlObj.totalHtwtrNsqty = totalHtwtrNsqty;
          this.bioGasDtlObj.bioGasEnePrfmnc.htwtrSumNsqty = totalHtwtrNsqty;
        }

        if (item.field === 'etcEneNsqty') {
          // 그리드의 모든 데이터를 가져와서 가스판매량 항목의 모든 값의 합계를 계산
          const ntslAllItems = window.SBGrid3.getAllItems(this.prfmncBioGasNtslGridObject);
          const totalEtcNsqty = this.sanitizeFloatingPointError(
              ntslAllItems.reduce((sum, item) => {
            return sum + (item.etcEneNsqty || 0);
          }, 0));

          // this.bioGasDtlObj.totalEtcNsqty = totalEtcNsqty;
          this.bioGasDtlObj.bioGasEnePrfmnc.etcEneSumNsqty = totalEtcNsqty;
        }

        //2025.03.17 null 인경우 0처리
        if (['prvtmNsqty', 'prvtmNtslAmt', 'gasNsqty', 'gasNtslAmt', 'vaporNsqty', 'vaporNtslAmt', 'htwtrNsqty', 'htwtrNtslAmt', 'etcEneNsqty', 'etcEneNtslAmt'].includes(item.field)) {
          const data = window.SBGrid3.getRowData(this.prfmncBioGasNtslGridObject, item.key);
          if(!data[item.field]) { data[item.field] = 0; }
        }
      });
    },
    // 그리드 내 특정 항목 데이터 합계 구하기
    totalGetAllDynamicItems(gridObj, field, resultObj){
      const allItems = window.SBGrid3.getAllItems(gridObj);
      const total = this.sanitizeFloatingPointError(
          allItems.reduce((sum, item) => {
            return sum + (item[field] || 0);
      }, 0));

      resultObj[field] = total;
    },
    // 그리드 내 특정 항목 데이터 최대값 구하기
    maxGetAllDynamicItems(gridObj, field, resultObj) {
      const allItems = window.SBGrid3.getAllItems(gridObj);
      const max = allItems.reduce((maxValue, item) => {
        return Math.max(maxValue, item[field] || 0);
      }, 0);

      resultObj[field] = max;
    },
    // 보조연료 투입 현황 행추가
    addAsstFuelInput() {
      const addRowData = {
        asstFuelInpqty: 0,
        asstFuelLcp: 0,
      };

      if (this.asstFuelInputInfo.fcltList.length === 1) {
        addRowData.fcltSqno = this.asstFuelInputInfo.fcltList[0].fcltSqno;
      }

      window.SBGrid3.addRow(this.asstFuelInputGridObject, '', addRowData);
    },
    // 보조연료 투입 현황 행삭제
    deleteAsstFuelInput() {
      const deleteAsstFuelInputKeys = window.SBGrid3.getCheckedKeys(this.asstFuelInputGridObject);

      if (deleteAsstFuelInputKeys.length === 0) {
        alert("선택된 보조연료 투입 정보가 없습니다.");
        return;
      }

      window.SBGrid3.deleteRows(this.asstFuelInputGridObject, deleteAsstFuelInputKeys);
    },
    // 에너지 생산·판매량 현황 행추가
    addNtslStts() {
      const addRowData = {
        ntslStotAmt: 0,
        prvtmNsqty: 0,
        prvtmNtslAmt: 0,
        gasNsqty: 0,
        gasNtslAmt: 0,
        vaporNsqty: 0,
        vaporNtslAmt: 0,
        htwtrNsqty: 0,
        htwtrNtslAmt: 0,
        etcEneNsqty: 0,
        etcEneNtslAmt: 0,
      };

      window.SBGrid3.addRow(this.prfmncBioGasNtslGridObject, '', addRowData);
    },
    // 에너지 생산·판매량 현황 행삭제
    deleteNtslStts() {
      const deleteNtslInputKeys = window.SBGrid3.getCheckedKeys(this.prfmncBioGasNtslGridObject);

      if (deleteNtslInputKeys.length === 0) {
        alert("선택된 에너지 생산·판매량 현황 정보가 없습니다.");
        return;
      }

      window.SBGrid3.deleteRows(this.prfmncBioGasNtslGridObject, deleteNtslInputKeys);
    },
    // 바이오가스생산현황 (총생산량 계산)
    updateEnePrdqty(){
      this.bioGasDtlObj.prfmncBioGasPrdctn.enePrdqty =
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.eneUtzqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.eneUnuqty);
    },
    // 바이오가스이용현황 (총이용량 계산)
    updateEneUtzqty() {
      this.bioGasDtlObj.prfmncBioGasPrdctn.eneUtzqty =
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.gasStotUsqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.gasStotSupqty);
    },
    // 자체사용현황 (자체이용합계 계산)
    updateGasStotUsqty() {
      this.bioGasDtlObj.prfmncBioGasPrdctn.gasStotUsqty =
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.elctgnUsgGasUsqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.dgstrWrmngUsgGasUsqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.sldgDhmdfyUsgGasUsqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.sldgFireUsgGasUsqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.foodDhmdfyUsgGasUsqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.bsmlRmvlUsgGasUsqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.stUsgGasUsqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.etcUsgGasUsqty);
    },
    // 외부공급현황 (외부공급합계 계산)
    updateGasStotSupqty() {
      this.bioGasDtlObj.prfmncBioGasPrdctn.gasStotSupqty =
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.elctgnUsgGasSupqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.dgstrWrmngUsgGasSupqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.sldgDhmdfyUsgGasSupqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.sldgFireUsgGasSupqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.foodDhmdfyUsgGasSupqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.bsmlRmvlUsgGasSupqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.stUsgGasSupqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGasPrdctn.etcUsgGasSupqty);
    },
    // 에너지 생산·판매량 현황 (총이용량 계산)
    updatePrvtmPrdqty() {
      this.bioGasDtlObj.bioGasEnePrfmnc.prvtmPrdqty =
          parseFloat(this.bioGasDtlObj.bioGasEnePrfmnc.prvtmUsqty) +
          parseFloat(this.bioGasDtlObj.bioGasEnePrfmnc.prvtmSumNsqty);
    },
    async initialize() {
      this.loadAsstFuelKndGroupCd();
      this.loadAsstFuelKndCd();
      this.loadAsstFuelUnitCd();
      this.loadLcpUnitCd();
      this.loadEneTypeCd();
      this.loadNtslTypeCd();
      this.loadNtslSeCd();
      this.loadItemCd();
    },
    // 보조연료종류그룹코드(PFM007) 로드
    loadAsstFuelKndGroupCd() {
      const asstFuelKndGroupCdList = this.$store.getters.getCodeList({ groupCd: CODE.ASSISTANCE_FUEL_KIND_GROUP, useYn: 'Y' });
      this.asstFuelKndGroupCdList = asstFuelKndGroupCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    // 보조연료종류코드(PFM008) 로드
    loadAsstFuelKndCd() {
      const asstFuelKndCdList = this.$store.getters.getCodeList({ groupCd: CODE.ASSISTANCE_FUEL_KIND, useYn: 'Y' });
      this.asstFuelKndCdList = asstFuelKndCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd,
        frstRefVl: item.frstRefVl,
      }));
    },
    // 보조연료단위코드(PFM009) 로드
    loadAsstFuelUnitCd() {
      const asstFuelUnitCdList = this.$store.getters.getCodeList({ groupCd: CODE.ASSISTANCE_FUEL_UNIT, useYn: 'Y' });
      this.asstFuelUnitCdList = asstFuelUnitCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd,
        frstRefVl: item.frstRefVl,
      }));
    },
    // 저위발열량단위코드(PFM010) 로드
    loadLcpUnitCd() {
      const lcpUnitCdList = this.$store.getters.getCodeList({ groupCd: CODE.LOW_CALORIFIC_POWER_UNIT, useYn: 'Y' });
      this.lcpUnitCdList = lcpUnitCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd,
        frstRefVl: item.frstRefVl,
      }));
    },
    // 에너지유형코드(SIS068) 로드
    loadEneTypeCd() {
      const cdList = this.$store.getters.getCodeList({ groupCd: CODE.ENE_TYPE, useYn: 'Y' });
      this.eneTypeCdList = cdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd,
        frstRefVl: item.frstRefVl,
      }));
    },
    // 판매유형코드(SIS069) 로드
    loadNtslTypeCd() {
      const cdList = this.$store.getters.getCodeList({ groupCd: CODE.NTSL_TYPE, useYn: 'Y' });
      this.ntslTypeCdList = cdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd,
        frstRefVl: item.frstRefVl,
      }));
    },
    // 판매구분코드(SIS070) 로드
    loadNtslSeCd() {
      const cdList = this.$store.getters.getCodeList({ groupCd: CODE.NTSL_SE, useYn: 'Y' });
      this.ntslSeCdList = cdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd,
        frstRefVl: item.frstRefVl,
      }));
    },
    // 품목코드(MBR010) 로드
    loadItemCd() {
      const cdList = this.$store.getters.getCodeList({ groupCd: CODE.ITEM, useYn: 'Y' });
      this.itemCdList = cdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd,
        frstRefVl: item.frstRefVl,
        scdRefVl: item.scdRefVl,
        thdRefVl: item.thdRefVl,
      }));
    },
    // 폐기물반입현황 그리드 컬럼 세팅
    initializeprfmncBioGasWtCrynGridColumns() {

      const columnCount = this.prfmncBioGasWtCrynList.inputItemList.length + 3;
      const columnWidth = columnCount > 0 ? (100 / columnCount).toFixed(2) : 100;

      this.gridColumnsBioGasWtCryn = [
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        {
          caption: '반입',
          columns : [
            { field: 'ctpvStdgNm', caption: '시·도', width: 100, editable: false, },
            { field: 'sggStdgNm', caption: '시·군·구', width: 100, editable: false, }
          ],
          total: {
            aggregates: [],
            header: [{ template: '계', align: 'right' }],
            headerCss:'grid-header',
          },
        },
      ];

      if (this.prfmncBioGasWtCrynList.inputItemList && this.prfmncBioGasWtCrynList.inputItemList.length > 0) {

        const inputItemCds = this.prfmncBioGasWtCrynList.inputItemList.filter(o => !(o.frstRefVl && !o.scdRefVl && o.thdRefVl)).map(item => item.inputItemCd);

        this.gridColumnsBioGasWtCryn.push({
          field: 'wtCrynSum', caption: '반입량 합계(톤)', minWidth: 100,  width:columnWidth, unit: '%',
          editable: false,
          colCss: 'grid-disabled',
          total: {
            aggregates: [{
              func: (items) => {
                // 필터링된 inputItemList에서 조건을 만족하는 key들 추출
                const excludedKeys = this.prfmncBioGasWtCrynList.inputItemList
                    .filter(item => item.thdRefVl !== 'GRP_SLDG') // 조건: thdRefVl !== 'GRP_SLDG'
                    .map(item => item.inputItemCd);

                // items 배열을 순회하며 excludedKeys의 값을 합산
                const totalSum = this.sanitizeFloatingPointError(
                    items.reduce((sum, item) => {
                  excludedKeys.forEach(key => {
                    if (item[key] !== undefined && typeof item[key] === 'number') {
                      sum += item[key]; // 해당 key에 해당하는 값 합산
                    }
                  });

                  if (item.seq === 0) {
                    Object.keys(item).forEach(key => {
                      if (
                          /^002(0|1|2)/.test(key) && // 키가 '0020', '0021', '0022'로 시작
                          typeof item[key] === 'number' // 값이 숫자인지 확인
                      ) {
                        sum += item[key]; // 해당 값 합산
                      }
                    });
                  }

                  return sum;
                }, 0));

                this.lastSum = totalSum;
                return totalSum;
              },
              field: 'total'
            }],
            header: [{ template: '{{total}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
          calc: {
            eval: (data) => {
              // inputItemCd가 0020, 0021, 0022로 시작하는 것을 제외하고 더함
              return inputItemCds.reduce((sum, key) => {
                if (!/^002[0-2]/.test(key)) {
                  const value = data[key] || 0; // 값이 없으면 0으로 처리
                  return sum + value;
                }
                return sum; // 제외된 항목은 더하지 않음
              }, 0);
            },
            nullAs: 0,
            type: 'number',
            dataType: 'number',
          },
          align: 'right',
          format: '#,##0.00'
        });

        this.prfmncBioGasWtCrynList.inputItemList.forEach(item => {

          if(item.frstRefVl === 'BIOWT' && item.thdRefVl === 'GRP_SLDG'){
            return;
          }

          let columnsObj = {};

          if(item.inputItemCd.includes('9999')){
            columnsObj = {
              caption: '기타',
              columns : [
                {
                  field: item.inputItemCd,
                  caption: item.inputItemNm+'(톤)',
                  width: columnWidth,
                  unit: '%',
                  type: 'number',
                  dataType: 'number',
                  total: {
                    aggregates: [ {func: 'sum', nullAs: 0} ],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                  align: 'right',
                  format: '#,##0.00'
                },
                {
                  field: 'etcWtKndCn',
                  caption: '기타종류',
                  width: columnWidth,
                  unit: '%',
                  align: 'left',
                  total: {
                    aggregates: [],
                    header: [],
                    headerCss: 'grid-header',
                  },
                },
              ]
            }
          } else {
            if (item.thdRefVl === 'GRP_SLDG') {
              columnsObj = {
                field: item.inputItemCd,
                caption: item.inputItemNm + '(톤)',
                width: columnWidth,
                unit: '%',
                type: 'number',
                dataType: 'number',
                total: {
                  aggregates: [{func: 'max', nullAs: 0}],
                  header: [{template: '{{max}}', format: '#,##0.00'}],
                  headerCss: 'grid-header',
                },
                align: 'right',
                format: '#,##0.00',
                mergeRow: true,
                editable: (data) => data.seq === 0
              }
            } else {
              columnsObj = {
                field: item.inputItemCd,
                caption: item.inputItemNm + '(톤)',
                width: columnWidth,
                unit: '%',
                type: 'number',
                dataType: 'number',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0}],
                  header: [{template: '{{sum}}', format: '#,##0.00'}],
                  headerCss: 'grid-header',
                },
                align: 'right',
                format: '#,##0.00',
              }
            }
          }

          this.gridColumnsBioGasWtCryn.push(columnsObj);
        });
      }
    },
    // 에너지 생산·판매량 현황 그리드 컬럼 세팅
    initializeprfmncBioGasNtslGridColumns() {
      this.gridColumnsBioGasNtsl = [
        { field: 'prfmncId', caption: '실적아이디', visible: false, editable: false },
        { field: 'bplcId', caption: '사업장아이디', visible: false, editable: false },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        {
          caption: '판매업체',
          part: 'head',
          columns : [
            {
              field: 'eneBzentySn',
              caption: '판매업체명',
              width: 250,
              align: 'left',
              type: 'combo',
              items: [...this.bioGasNtslEneSplyBzenty, ...this.prfmncBioGasNtslList.list].reduce((acc, curr) => {
                const index = acc.findIndex(item => {
                  return item._custom ? item._custom.value.eneBzentySn === curr.eneBzentySn : item.eneBzentySn === curr.eneBzentySn;
                });

                if (index === -1) {
                  // 중복 안되는 경우 추가
                  acc.push(curr);
                } else {
                  // 중복되는 경우 값 변경
                  acc[index] = curr;
                }
                return acc;
              }, []),
              comboLabel: 'bzentyNm',
              comboValue: 'eneBzentySn',
              setValue: {

              }
            },
            {
              field: 'eneTypeCd',
              caption: '에너지유형',
              width: 100,
              type: 'combo',
              items: this.eneTypeCdList,
              comboLabel: 'text',
              comboValue: 'value',
            },
            {
              field: 'ntslTypeCd',
              caption: '판매유형',
              width: 100,
              type: 'combo',
              items: this.ntslTypeCdList,
              comboLabel: 'text',
              comboValue: 'value',
            }
          ],
          total: {
            aggregates: [
            ],
            header: [
              { template: '' }
            ],
            headerCss:'grid-header',
          },
        },
        {
          caption: '에너지 판매현황',
          columns : [
            {
              field: 'ntslSeCd',
              caption: '판매구분',
              width: 150,
              type: 'combo',
              items: this.ntslSeCdList,
              comboLabel: 'text',
              comboValue: 'value',
              total: {
                aggregates: [
                ],
                header: [
                  { template: '계' }
                ],
                headerCss:'grid-header',
              },
            },
            {
              field: 'ntslStotAmt',
              caption: '총 판매금액(원)',
              width: 100,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              calc: {
                require: ['prvtmNtslAmt', 'gasNtslAmt', 'vaporNtslAmt', 'htwtrNtslAmt', 'etcEneNtslAmt'],
                eval: 'sum',
                nullAs: 0,
                dataType: 'number',
              },
              format: '#,##0.00',
            },
            {
              caption: '전기(MWh,원)', captionCss:'gridh-bg-electricity',
              columns : [
                {
                  field: 'prvtmNsqty',
                  caption: '판매량',
                  width: 100,
                  align: 'right',
                  type: 'number',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      { func: 'sum', nullAs: 0 },
                    ],
                    header: [
                      { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-electricity',
                },
                {
                  field: 'prvtmNtslAmt',
                  caption: '판매금액',
                  width: 100,
                  align: 'right',
                  type: 'number',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      { func: 'sum', nullAs: 0 },
                    ],
                    header: [
                      { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-electricity',
                },
              ]
            },
            {
              caption: '가스(N㎥,원)', captionCss:'gridh-bg-gas',
              columns : [
                {
                  field: 'gasNsqty',
                  caption: '판매량',
                  width: 100,
                  align: 'right',
                  type: 'number',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      { func: 'sum', nullAs: 0 },
                    ],
                    header: [
                      { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-gas',
                },
                {
                  field: 'gasNtslAmt',
                  caption: '판매금액',
                  width: 100,
                  align: 'right',
                  type: 'number',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      { func: 'sum', nullAs: 0 },
                    ],
                    header: [
                      { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-gas',
                },
              ]
            },
            {
              caption: '증기(스팀톤,원)', captionCss:'gridh-bg-vapor',
              columns : [
                {
                  field: 'vaporNsqty',
                  caption: '판매량',
                  width: 100,
                  align: 'right',
                  type: 'number',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      { func: 'sum', nullAs: 0 },
                    ],
                    header: [
                      { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-vapor',
                },
                {
                  field: 'vaporNtslAmt',
                  caption: '판매금액',
                  width: 100,
                  align: 'right',
                  type: 'number',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      { func: 'sum', nullAs: 0 },
                    ],
                    header: [
                      { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-vapor',
                },
              ]
            },
            {
              caption: '온수(스팀톤,원)', captionCss:'gridh-bg-hot-water',
              columns : [
                {
                  field: 'htwtrNsqty',
                  caption: '판매량',
                  width: 100,
                  align: 'right',
                  type: 'number',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      { func: 'sum', nullAs: 0 },
                    ],
                    header: [
                      { template: '{{sum}}', format: '#,##0.00' ,align: 'right' }
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-hot-water',
                },
                {
                  field: 'htwtrNtslAmt',
                  caption: '판매금액',
                  width: 100,
                  align: 'right',
                  type: 'number',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      { func: 'sum', nullAs: 0 },
                    ],
                    header: [
                      { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-hot-water',
                },
              ]
            },
            {
              caption: '기타(Gcal,원)', captionCss:'gridh-bg-etc',
              columns : [
                {
                  field: 'etcEneNsqty',
                  caption: '판매량',
                  width: 100,
                  align: 'right',
                  type: 'number',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      { func: 'sum', nullAs: 0 },
                    ],
                    header: [
                      { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-etc',
                },
                {
                  field: 'etcEneNtslAmt',
                  caption: '판매금액',
                  width: 100,
                  align: 'right',
                  type: 'number',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      { func: 'sum', nullAs: 0 },
                    ],
                    header: [
                      { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-etc',
                },
              ]
            },
          ],
        },
      ]
    },
    initializeAsstFuelInputGridColumns() {
      this.asstFuelInputGridColumns = [
        { field: 'fcltSqno', caption: '시설명', width: 200,
          type: 'combo',
          // items: this.fcltList,
          items: this.asstFuelInputInfo.fcltList,
          comboLabel: 'fcltNm',
          comboValue: 'fcltSqno',
        },
        { caption: '보조연료 정보',
          columns: [
            { field: 'asstFuelKndGroupCd', caption: '연료구분', width: 150,
              type: 'combo',
              items: this.asstFuelKndGroupCdList,
              comboLabel: 'text',
              comboValue: 'value',
            },
            { field: 'asstFuelKndCd', caption: '연료종류', width: 150,
              type: 'combo',
              items: this.asstFuelKndCdList,
              comboLabel: 'text',
              comboValue: 'value',
              master: 'asstFuelKndGroupCd',
              masterField: 'frstRefVl',
              useFilter: true,
            },
            { field: 'asstFuelKndCn', caption: '기타종류명', width: 150,
              editable: (rowData) => rowData.asstFuelKndGroupCd === '99',
              getValue: (value, field, rowItem) => {
                return rowItem.data.asstFuelKndGroupCd === '99' ? value : ''
              },
              setValue: (value, field, rowItem) => {
              return rowItem.data.asstFuelKndGroupCd === '99' ? value : ''
              },
              colCss: (data) => {
                return data.asstFuelKndGroupCd === '99' ? '' : 'grid-disabled'
              }
            },
            { field: 'asstFuelInpqty', caption: '연료투입량', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150 },
            { field: 'asstFuelUnitCd', caption: '연료단위', width: 150,
              type: 'combo',
              items: this.asstFuelUnitCdList,
              comboLabel: 'text',
              comboValue: 'value',
              master: 'asstFuelKndGroupCd',
              masterField: 'frstRefVl',
              useFilter: true,
            },
            { field: 'asstFuelLcp', caption: '저위발열량', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150 },
            { field: 'lcpUnitCd', caption: '저위발열량 단위', unit: '%',
              type: 'combo',
              items: this.lcpUnitCdList,
              comboLabel: 'text',
              comboValue: 'value',
              master: 'asstFuelUnitCd',
              masterField: 'frstRefVl',
              useFilter: true,
            },
          ]
        },
      ]
    },
    gridLoadedBioGasWtCryn() {
      this.gridReloadBioGasWtCryn = false;
    },
    gridLoadedBioGasNtsl() {
      this.gridReloadBioGasNtsl = false;
    },
    asstFuelInputGridLoaded() {
      this.asstFuelInputGridReload = false;
    },
    changeBioGasWtCryn(values) {
      values.forEach(item => {
        if (/^002[0-2]/.test(item.field)) {
          this.setCrynSldg(item);
        }

        if (!['etcWtKndCn'].includes(item.field)) {
          //2025.03.17 null 인경우 0처리
          const data = window.SBGrid3.getRowData(this.prfmncBioGasWtCrynGridObject, item.key);
          if(!data[item.field]) { data[item.field] = 0; }
        }
      });
    },
    setCrynSldg(item) {
      const rowData = window.SBGrid3.getRowData(this.prfmncBioGasWtCrynGridObject, item.key);
      if (rowData.seq === 0) {
        // 모든 슬러지 값을 동일하게 설정
        const wtCrynAllItems = window.SBGrid3.getAllItems(this.prfmncBioGasWtCrynGridObject);
        wtCrynAllItems.forEach(wtItem => {
          if (wtItem.seq !== 0) {
            window.SBGrid3.setValue(this.prfmncBioGasWtCrynGridObject, wtItem._key_, item.value, item.field);
          }
        });
      }
    },
    changeAsstFuelInput(values){
      values.forEach(item => {
        if (['asstFuelInpqty', 'asstFuelLcp'].includes(item.field)) {
          //2025.03.17 null 인경우 0처리
          const data = window.SBGrid3.getRowData(this.asstFuelInputGridObject, item.key);
          if(!data[item.field]) { data[item.field] = 0; }
        }
      });
    },
    formatNumber(number) {
      return utils.formatNumberWithComma(number);
    },
    // 실적보고상세저장 호출
    async prtmncRptDtlSave(isSubmit = false){
      // 유효성 체크
      const validationMessage = this.validate(true);

      if(validationMessage){
        alert(validationMessage);
        return;
      }

      if (!isSubmit && !confirm("저장하시겠습니까?")) {
        return;
      }else if (isSubmit && !confirm("제출하시겠습니까?")) {
        return;
      }

      /*if (!validationMessage && confirm("필수입력 조건이 만족하여 제출가능한상태입니다.\n제출하시겠습니까?")) {
        isSubmit = true; // 제출상태로 변경
      }else{
        if(isSubmit){
          return;
        }
      }*/

      // const { updated: wtCrynUpdated } = window.SBGrid3.getSaveData(this.prfmncBioGasWtCrynGridObject, false, true, false);
      // 2025.02.03 실적값을 0기입해도 저장 및 제출 가능하도록 수정
      // 목록의 내역을 모두 저장하도록 변경
      const wtCrynUpdated = window.SBGrid3.getAllItems(this.prfmncBioGasWtCrynGridObject);
      // seq가 0이 아닌 데이터에서 key가 0020, 0021, 0022로 시작하는 값 0으로 변경
      const processedWtCrynUpdated = wtCrynUpdated.map(item => {
        if (item.seq !== 0) {
          Object.keys(item).forEach(key => {
            if (/^002[0-2]/.test(key)) {
              item[key] = 0; // 값 0으로 설정
            }
          });
        }
        return item;
      });

      let asstFuelInputList = [];
      if(parseInt(this.bioGasDtlObj.prfmncMst.prfmncYr) >= 2025) {
        const {inserted, updated, deleted} = window.SBGrid3.getSaveData(this.asstFuelInputGridObject);
        asstFuelInputList = [...inserted, ...updated, ...deleted];
      }
      const { inserted: ntslInserted, updated: ntslUpdated, deleted: ntslDeleted } = window.SBGrid3.getSaveData(this.prfmncBioGasNtslGridObject, true, true, true);
      const ntslList = [...ntslInserted, ...ntslUpdated, ...ntslDeleted];

      this.$apiCall.post(
          API.PRFMNC_DTL_SAVE,
          {
            wtCrynUpdated: processedWtCrynUpdated,
            prfmncMst: Object.assign({}, this.bioGasDtlObj.prfmncMst, isSubmit ? { prfmncSttsCd: "SUB" } : {}),
            prfmncBioGas: this.bioGasDtlObj.prfmncBioGas,
            prfmncBioGasWtInputList: this.bioGasDtlObj.prfmncBioGasWtInputList.inputItemList,
            asstFuelInputList: asstFuelInputList,
            prfmncBioGasPrdctn: this.bioGasDtlObj.prfmncBioGasPrdctn,
            bioGasEnePrfmnc: this.bioGasDtlObj.bioGasEnePrfmnc,
            prfmncBioGasNtslList: ntslList,
          },
          (data) => {
            alert((isSubmit ? '제출' : '저장') + "이 완료되었습니다.");
            let routerChuck = {path: ROUTE.PERFORMANCE_LIST};

            if(!isSubmit) {
              routerChuck = {path: ROUTE.PERFORMANCE_DTL, query: {prfmncId: data.result.prfmncId}};
            }

            this.$router.push(routerChuck)
                .then(() => !isSubmit && location.reload())
                .catch((err) => {
                  console.error('Navigation failed:', err);
                });
          },
          () => {
            alert((isSubmit ? '제출' : '저장') + "에 실패하였습니다.");
          }
      );
    },
    validate(focusOnError = false) {
      // 폐기물반입현황 - 반입량합계 필수값 체크
      
      // 생슬러지, 잉여슬러지, 탈수케이크만 입력 시 값 0으로 처리되어 주석
      //const wtCrynAllItems = window.SBGrid3.getAllItems(this.prfmncBioGasWtCrynGridObject);
      // const totalwtCrynSum = wtCrynAllItems.reduce((sum, item) => {
      //   return sum + (item.wtCrynSum || 0);
      // }, 0);

      // if(this.lastSum === 0) {
      // 2025.02.03 0값도 입력되도록 수정(미가동 시설인 경우에도 실적등록하는 케이스가 있음)
      if(this.lastSum == null) {
        if (focusOnError) {
          this.$refs.prfmncDtlBioGasWtCrynTitle.focus();
        }
        return "폐기물 반입 정보를 입력해주세요.";
      }

      // 가스정제설비 보유 현황 - 메탄농도 필수 값 체크
      // if(!this.bioGasDtlObj.prfmncBioGas.bgspgrBfrMthnDens){
      // 2025.02.03 0값도 입력되도록 수정(미가동 시설인 경우에도 실적등록하는 케이스가 있음)
      if(this.bioGasDtlObj.prfmncBioGas.bgspgrBfrMthnDens == null){
        if (focusOnError) {
          this.$refs.bgspgrBfrMthnDensInput.focus();
        }
        return "메탄농도를 입력해주세요.";
      }

      const { inserted: insertedNtsl, updated: updatedNtsl  } = window.SBGrid3.getSaveData(this.prfmncBioGasNtslGridObject, true, true, false);
      const asstNtslInputList = [...insertedNtsl, ...updatedNtsl];

      for (const item of asstNtslInputList) {
        if (!item.eneBzentySn) {
          if (focusOnError) {
            this.$refs.asstTnslTitle.focus();
            const rowItem = window.SBGrid3.getRow(this.prfmncBioGasNtslGridObject, item._key_);
            const column = window.SBGrid3.getColumnByField(this.prfmncBioGasNtslGridObject, 'eneBzentySn');

            window.SBGrid3.moveFocus(this.prfmncBioGasNtslGridObject, rowItem, column);
            window.SBGrid3.columnEditable(this.prfmncBioGasNtslGridObject, item._key_, column, true);
            return "에너지 생산·판매량 현황의 판매업체명을 선택하지 않은 데이터가 있습니다.";
          }
        }

        if (!item.eneTypeCd) {
          if (focusOnError) {
            this.$refs.asstTnslTitle.focus();
            const rowItem = window.SBGrid3.getRow(this.prfmncBioGasNtslGridObject, item._key_);
            const column = window.SBGrid3.getColumnByField(this.prfmncBioGasNtslGridObject, 'eneTypeCd');

            window.SBGrid3.moveFocus(this.prfmncBioGasNtslGridObject, rowItem, column);
            window.SBGrid3.columnEditable(this.prfmncBioGasNtslGridObject, item._key_, column, true);
            return "에너지 생산·판매량 현황의 에너지유형을 선택하지 않은 데이터가 있습니다.";
          }
        }

        if (!item.ntslTypeCd) {
          if (focusOnError) {
            this.$refs.asstTnslTitle.focus();
            const rowItem = window.SBGrid3.getRow(this.prfmncBioGasNtslGridObject, item._key_);
            const column = window.SBGrid3.getColumnByField(this.prfmncBioGasNtslGridObject, 'ntslTypeCd');

            window.SBGrid3.moveFocus(this.prfmncBioGasNtslGridObject, rowItem, column);
            window.SBGrid3.columnEditable(this.prfmncBioGasNtslGridObject, item._key_, column, true);
            return "에너지 생산·판매량 현황의 판매유형을 선택하지 않은 데이터가 있습니다.";
          }
        }

      }

      const { inserted, updated } = window.SBGrid3.getSaveData(this.asstFuelInputGridObject, true, true, false);
      const asstFuelInputList = [...inserted, ...updated];

      for (const item of asstFuelInputList) {
        if (!item.fcltSqno) {
          if (focusOnError) {
            this.$refs.asstFuelTitle.focus();
            const rowItem = window.SBGrid3.getRow(this.asstFuelInputGridObject, item._key_);
            const column = window.SBGrid3.getColumnByField(this.asstFuelInputGridObject, 'fcltSqno');

            window.SBGrid3.moveFocus(this.asstFuelInputGridObject, rowItem, column);
            window.SBGrid3.columnEditable(this.asstFuelInputGridObject, item._key_, column, true);
            return "보조연료 투입 현황의 시설을 선택하지 않은 데이터가 있습니다.";
          }
        }

        if (!item.asstFuelKndGroupCd) {
          if (focusOnError) {
            this.$refs.asstFuelTitle.focus();
            const rowItem = window.SBGrid3.getRow(this.asstFuelInputGridObject, item._key_);
            const column = window.SBGrid3.getColumnByField(this.asstFuelInputGridObject, 'asstFuelKndGroupCd');

            window.SBGrid3.moveFocus(this.asstFuelInputGridObject, rowItem, column);
            window.SBGrid3.columnEditable(this.asstFuelInputGridObject, item._key_, column, true);
            return "보조연료 투입 현황의 연료구분을 선택하지 않은 데이터가 있습니다.";
          }
        }

        if (!item.asstFuelKndCd) {
          if (focusOnError) {
            this.$refs.asstFuelTitle.focus();
            const rowItem = window.SBGrid3.getRow(this.asstFuelInputGridObject, item._key_);
            const column = window.SBGrid3.getColumnByField(this.asstFuelInputGridObject, 'asstFuelKndCd');

            window.SBGrid3.moveFocus(this.asstFuelInputGridObject, rowItem, column);
            window.SBGrid3.columnEditable(this.asstFuelInputGridObject, item._key_, column, true);
            return "보조연료 투입 현황의 연료종류를 선택하지 않은 데이터가 있습니다.";
          }
        }

        // if (!item.asstFuelInpqty) {
        // 2025.02.03 0값도 입력되도록 수정(미가동 시설인 경우에도 실적등록하는 케이스가 있음)
        if (item.asstFuelInpqty == null) {
          if (focusOnError) {
            this.$refs.asstFuelTitle.focus();
            const rowItem = window.SBGrid3.getRow(this.asstFuelInputGridObject, item._key_);
            const column = window.SBGrid3.getColumnByField(this.asstFuelInputGridObject, 'asstFuelInpqty');

            window.SBGrid3.moveFocus(this.asstFuelInputGridObject, rowItem, column);
            window.SBGrid3.columnEditable(this.asstFuelInputGridObject, item._key_, column, true);
            // return "보조연료 투입 현황의 연료투입량이 0이거나 입력되지 않은 데이터가 있습니다.";
            return "보조연료 투입 현황의 연료투입량이 입력되지 않은 데이터가 있습니다.";
          }
        }
      }

      return "";
    },

    // 소수점 둘쨰자리 오류 수정
    // 부동소수점(floating point) 연산의 정밀도 문제 스크립트 고유 버그
    sanitizeFloatingPointError(value){
      if (typeof value !== "number") return value; // 숫자가 아닐 경우 반환

      // 소수점 2자리까지만 유지하고 반올림하여 부동소수점 오차 제거
      return parseFloat((Math.round(value * 100) / 100).toFixed(2));

    },
    as(focusOnError){
      
      const { inserted, updated } = window.SBGrid3.getSaveData(this.prfmncBioGasNtslGridObject, true, true, false);
      const asstNtsInputList = [...inserted, ...updated];

      for (const item of asstNtsInputList) {
        if (!item.eneBzentySn) {
          if (focusOnError) {
            this.$refs.asstFuelTitle.focus();
            const rowItem = window.SBGrid3.getRow(this.prfmncBioGasNtslGridObject, item._key_);
            const column = window.SBGrid3.getColumnByField(this.prfmncBioGasNtslGridObject, 'eneBzentySn');

            window.SBGrid3.moveFocus(this.prfmncBioGasNtslGridObject, rowItem, column);
            window.SBGrid3.columnEditable(this.prfmncBioGasNtslGridObject, item._key_, column, true);
            return "에너지 생산.판매량 현황의 판매업체명을 선택하지 않은 데이터가 있습니다.";
          }
        }

      }
    }
  } // END methods
} // END export
</script>

<style scoped>

</style>