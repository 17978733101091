import CryptoJS from 'crypto-js';

function getKey(secretKey) {
    return CryptoJS.SHA256(secretKey);
}

export function encrypt(text, secretKey) {
    const iv = CryptoJS.lib.WordArray.random(16);
    const key = getKey(secretKey);
    const encrypted = CryptoJS.AES.encrypt(text, key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
    });

    const combined = iv.concat(encrypted.ciphertext);
    return combined.toString(CryptoJS.enc.Base64);
}

export function decrypt(combinedText, secretKey) {
    const combined = CryptoJS.enc.Base64.parse(combinedText);
    const iv = CryptoJS.lib.WordArray.create(combined.words.slice(0, 4));
    const ciphertext = CryptoJS.lib.WordArray.create(combined.words.slice(4));

    const key = getKey(secretKey);
    const decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
}