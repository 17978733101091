<template>
    <div class="member_wrap">
      <ol class="join_step_wrap">
        <li>
          <i class="step1"></i>
          <span>회원선택</span>
        </li>
        <li>
          <i class="step2"></i>
          <span>가입여부 확인</span>
        </li>
        <li>
          <i class="step3"></i>
          <span>약관동의</span>
        </li>
        <li>
          <i class="step4"></i>
          <span>사용자정보 입력</span>
        </li>
        <li class="active">
          <i class="step5"></i>
          <span>가입완료</span>
        </li>
      </ol>
  
      <div class="join_finish">
        <img src="@/assets/images/sub/ico_join_finish.png" alt="폐자원에너지 종합정보관리시스템">
        <strong>회원가입 신청이 완료되어 승인 대기 중입니다.</strong>
        <p>
          승인 후 사업장 및 시설정보 등록을 완료해 주시기 바랍니다.<br>
          승인 결과는 등록하신 휴대폰 번호로 안내드립니다.<br>
          <br>
          승인된 사업장 및 시설정보가 있는 경우,<br> 가입 승인 완료 시 즉시 모든 서비스 이용이 가능합니다.<br><br>
          감사합니다.
        </p>
      </div>
      <div class="member_box">
        <p>{{ storeJoinUserInfo.userNm }} ({{ storeJoinUserInfo.userId }})님은 {{ utils.getCurrentDateFormatted() }} {{ pp.euRo(authrtOptions.find(o => o.value === storeJoinUserInfo.authrtCd)?.text || '') }} 가입되셨습니다.</p>
      </div>
      <div class="btn_area center">
        <a href="javascript:void(0);" class="btn md tertiary" @click.prevent="navigateTo('/')">메인으로</a>
        <a href="javascript:void(0);" class="btn md primary" @click.prevent="navigateTo('/user/login')">로그인하기</a>
      </div>
    </div>
  </template>
  
  <script>

  import storeRegister from "@/js/store/store-register";
  import utils from "@/js/utils/utils";
  import pp from '@/js/postposition';

  const API = {
    USER_INFO : "/join",
    AUTHRT_CODE : "/join/code",
}

  export default {
    data() {
      return {
        param : {
          userId : "",
          userNm : "",
          mbrJoinDt : "",
          authrtNm : "",
        },
        authrtOptions : [],
      };
    },
    computed: {
      utils() {
        return utils
      },
      pp() {
        return pp
      },
      storeJoinUserInfo() {
        return storeRegister.getters.getJoinUserInfo;
      },
    },
    watch: {},
    created() {
      this.param.userId = this.$route.query.userId;
    },
    mounted() {
      this.initialize();
      // this.read();
    },
    methods: {
      async initialize() {
        await this.loadAuthrtCd()   // 담당업무 불러옴
      },
      loadAuthrtCd() {
        return new Promise((resolve, reject) => {
          this.$apiCall.get(API.AUTHRT_CODE, {userMngSe : storeRegister.state.joinUserInfo.userMngSe}, data => {
            if (data.result) {
              this.authrtOptions = data.result
                  .filter(item => item.sysAuthrtCd !== "99-98")
                  .map(item => ({
                    text: item.authrtNm,
                    value: item.sysAuthrtCd
                  }));
              this.authrtOptions.unshift({ text: "선택", value: "" });
              resolve();
            } else {
              reject(new Error("Failed to load authrt codes."));
            }
          });
        });
      },
      navigateTo(path) {
        if (path) {
          this.$router.push(path)
              .catch(() => {
                console.error("Navigation Error");
              });
        }
      },
      read() {
        this.$apiCall.get(API.USER_INFO + "/" + this.param.userId, {}, this.success, this.error);
      },
      success(data) {
        if (!data.result) {
          this.error();
          return;
        }

        this.param = data.result;
      },
    }
  }
  </script>
  
  <style scoped>
  
  </style>