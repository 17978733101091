<template>
  <head>
    <meta name="description" content="이 페이지는 회원정보를 조회하고 수정합니다.">
  </head>
  <div class="desc_box progress_box" v-if="['01'].includes(param.aprvPrcsSttsCd)"> 
    <i class="icon"><img src="@/assets//images/common/ico_noti.png" alt="이미지"></i>
    <div class="progress">
        <div><strong>승인대기</strong>상태입니다 <span>현재 관리자가 회원정보 검토 중이므로 변경이 불가능합니다.</span></div>
    </div>
  </div> 
  <div class="desc_box progress_box" v-if="['02'].includes(param.aprvPrcsSttsCd)"> 
    <i class="icon"><img src="@/assets//images/common/ico_noti.png" alt="이미지"></i>
    <div class="progress">
        <div><strong>보완요청</strong>상태입니다 <span>{{ param.splmntDmndCn}}</span></div>
    </div>
  </div> 
  <div class="desc_box progress_box" v-if="['03'].includes(param.aprvPrcsSttsCd)"> 
    <i class="icon"><img src="@/assets//images/common/ico_noti.png" alt="이미지"></i>
    <div class="progress">
        <div><strong>반려</strong>상태입니다 <span>{{ param.rjctRsn}}</span></div>
    </div>
  </div>
  <h2 class="title1 mt_0">사용자정보</h2>
  <div class="board_write" :class="{disabled: param.aprvPrcsSttsCd === '01'}">
      <p class="t_caption"><span class="necessary">(<i>※</i>)</span> 필수 입력항목입니다.</p>
      <table>
        <caption>사용자정보</caption>
        <colgroup>
          <col style="width:18%">
          <col style="width:32%">
          <col style="width:18%">
          <col style="width:32%">
        </colgroup>
        <tbody>
        <tr>
          <th scope="row"><i class="necessary">필수입력</i>이름</th>
          <td>
              <br-form-input type="text" label="이름" v-model="param.userNm" required panel="panel" class="form_control" />
          </td>
          <th scope="row"><i class="necessary">필수입력</i>아이디</th>
          <td>
            {{ param.userId }}
          </td>
        </tr>
        <tr>
          <th scope="row"><label for="pswd">비밀번호</label></th>
          <td>
              <input type="password" v-model="param.encptPswd" class="form_control" id="pswd" style="text-align: left;" @input="validatePassword" required panel="panel" />
              <span v-if="passwordError" class="form_hint" style="color: red; font-size: 15px;">{{ passwordError }}</span>
          </td>
          <th scope="row"><label for="pswdCh">비밀번호확인</label></th>
          <td>
              <input type="password" v-model="encptPswdCh" class="form_control" id="pswdCh"  style="text-align: left;" @input="validatePasswordMatch" required panel="panel" />
            <span v-if="passwordMatchError" class="form_hint" style="color: red; font-size: 15px;">{{ passwordMatchError }}</span>
          </td>
        </tr>
        <tr>
          <th scope="row"><label for="telno"><i class="necessary">필수입력</i>전화번호</label></th>
          <td>
              <mbr-form-telno label="telno"
              v-model:telNo1="param.telno1"
              v-model:telNo2="param.telno2"
              v-model:telNo3="param.telno3"
              :required="true"
              ref="telnoInput"/>
          </td>
          <th scope="row">FAX</th>
          <td>
              <mbr-form-telno label="FAX"
              v-model:telNo1="param.fxno1"
              v-model:telNo2="param.fxno2"
              v-model:telNo3="param.fxno3"
              ref="fxnoInput"/>
          </td>
        </tr>
        <tr>
          <th scope="row"><i class="necessary">필수입력</i>이메일</th>
          <td>
              <br-form-input label="이메일" v-model="param.emlAddr" class="form_control" panel="panel" required :maxlength="100" @input="validateEmail" />
              <span v-if="emailError" class="form_hint" style="color: red; font-size: 15px;">{{ emailError }}</span>
          </td>
          <th scope="row"><i class="necessary">필수입력</i>휴대전화번호</th>
          <td>
              <mbr-form-telno label="휴대전화번호"
              v-model:telNo1="param.mblTelno1"
              v-model:telNo2="param.mblTelno2"
              v-model:telNo3="param.mblTelno3"
              :phone-type="'MOBILE'"
              :required="true"
              ref="mTelnoInput"
              />
          </td>
        </tr>
        <tr>
          <th scope="row"><i class="necessary">필수입력</i>부서</th>
          <td>
              <br-form-input label="부서" v-model="param.deptNm" class="form_control" :maxlength="200" panel="panel" required />
          </td>
          <th scope="row">직급/직책</th>
          <td>
              <br-form-input label="직급/직책" v-model="param.jbgdNm" class="form_control" :maxlength="100" />
          </td>
        </tr>
        <tr>
          <th scope="row"><i class="necessary">필수입력</i>담당업무</th>
          <td colspan="3">
              <br-form-select label="담당업무" :options="authrtOptions" v-model:value="param.aplySysAuthrtCd" customClass="form_select w300"  panel="panel" required disabled/>
          </td>
        </tr>
        <tr v-if="['04-01', '04-02', '04-03'].includes(param.sysAuthrtCd)">
          <th scope="row"><i class="necessary">필수입력</i>환경청</th>
          <td colspan="3">
              <br-form-select label="환경청" groupCd="COM426" v-model:value="param.rbeoCd" panel="panel" required includeSelectOption/>
          </td>
        </tr>
        <tr v-if="['task'].includes(userMngSe) && ['03-01', '03-02', '03-03', '03-04', '03-05', '03-06'].includes(param.sysAuthrtCd)">
          <th scope="row"><i class="necessary">필수입력</i>관할시도</th>
          <td>
            <br-form-select label="관할시도" :options="ctpvList" v-model:value="param.cmptncCtpvStdgCd" panel="panel" required/>
          </td>
          <th scope="row"><i class="necessary">필수입력</i>관할시군구</th>
          <td>
            <br-form-select label="관할시군구" :options="sggList" v-model:value="param.cmptncSggStdgCd" panel="panel" :disabled="['03-02', '03-05', '03-06'].includes(param.sysAuthrtCd)" :required="['03-01', '03-03', '03-04'].includes(param.sysAuthrtCd)"/>
          </td>
        </tr>
        <tr>
          <th scope="row">알림수신채널</th>
          <td colspan="3">
            <div class="chk_area">
              <div class="form_check">
                  <input type="checkbox" v-model="param.emailUseYn" id="emailUseYn" true-value="Y" false-value="N">
                  <label for="emailUseYn">이메일</label>
              </div>
              <div class="form_check">
                  <input type="checkbox" v-model="param.ntctlkUseYn" id="ntctlkUseYn" true-value="Y" false-value="N">
                  <label for="ntctlkUseYn">알림톡(카카오 & SMS)</label>
              </div>
            </div>
          </td>
        </tr>
        <tr v-if="['01'].includes(param.aprvPrcsSttsCd)">
          <th scope="row">승인처리상태</th>
          <td colspan="3">
            <br-form-select label="승인처리상태" groupCd="MBR019" v-model:value="param.aprvPrcsSttsCd" panel="panel" required disabled/>
          </td>
        </tr>
        <!-- <tr v-if="['02'].includes(param.aprvPrcsSttsCd)">
          <th scope="row">보완요청사유</th>
          <td colspan="3" style="height: 57px;">
            {{ param.splmntDmndCn}}
          </td>
        </tr>
        <tr v-if="['03'].includes(param.aprvPrcsSttsCd)">
          <th scope="row">반려사유</th>
          <td colspan="3" style="height: 57px;">
            {{ param.rjctRsn}}
          </td>
        </tr> -->
        <tr v-if="['user'].includes(userMngSe)">
          <th scope="row"><i class="necessary">필수입력</i>위탁운영업체 사용자</th>
          <td colspan="3">
            <br-form-radio label="위탁운영업체" :options="cnsgnYnOptions" v-model:value="param.cnsgnOperBzentyUserYn" panel="panel" required/>
          </td>
        </tr>
        <!-- <tr v-if="['task'].includes(userMngSe)">
          <th scope="row">내부 IP주소</th>
          <td>
              <br-form-input type="text" label="내부 IP주소" v-model="param.insdIpAddr" class="form_control" panel="panel" />
          </td>
          <th scope="row">외부 IP주소</th>
          <td>
              <br-form-input type="text" label="외부 IP주소" v-model="param.otsdIpAddr" class="form_control" panel="panel" />
          </td>
        </tr>
        <tr v-if="['task'].includes(userMngSe)">
          <td colspan="4" style="border-left: 0;">
            <div class="info_box" style="border:0">
              <strong class="info_title">IP 주소 확인 방법</strong>
              <ul class="dot_list">
                <li>내부 IP 주소 : 1. 윈도우 화면 좌측 하단 찾기에서 cmd 검색 후 클릭 2. ipconfig 입력 후 IPv4 주소 확인</li>
                <li>외부 IP 주소 : 네이버 검색창에 “IP 주소 조회” 검색</li>
              </ul>
            </div>
          </td>
        </tr> -->
        <tr v-if="['user'].includes(userMngSe) || param.sysAuthrtCd.startsWith('05')">
          <th scope="row">증빙서류</th>
          <td colspan="3">
            <br-attach-file-list label="시설사용자 첨부파일 목록"
                                 ref="attachFileListUser"
                                 :atchFileTaskSeCd="fileListTaskSeCd1"
                                 v-model:atchFileGroupId="this.param.atchFileGroupId"
                                 :maxFileCount="10"
                                 :readonly="false"
            />
          </td>
        </tr>
        <tr v-if="param.sysAuthrtCd.startsWith('03') || param.sysAuthrtCd.startsWith('04')">
          <th scope="row">증빙서류</th>
          <td colspan="3">
            <br-attach-file-list label="업무관리사용자 첨부파일 목록"
                                 ref="attachFileListUser"
                                 :atchFileTaskSeCd="fileListTaskSeCd2"
                                 v-model:atchFileGroupId="this.param.atchFileGroupId"
                                 :maxFileCount="10"
                                 :readonly="false"
            />
          </td>
        </tr>
        </tbody>
      </table>
  </div>
  <div class="btn_area right">
      <br-button v-if="['02', '03'].includes(param.aprvPrcsSttsCd)" label="승인요청" class="btn md primary" panel="panel" validate @click="request" />
      <br-button v-if="['04'].includes(param.aprvPrcsSttsCd)" label="수정" class="btn md primary" panel="panel" validate @click="update" />
      <a href="/" class="btn md point">취소</a>
  </div>

  <div v-show="isOn" class="modal visible">
    <div class="popup_box" style="width: 70%;">
      <h1 class="popup_title">비밀번호 입력</h1>
      <div class="popup_cont" style="height: 100%;">
        <div class="board_write">
          <table>
              <caption></caption>
              <colgroup>
                  <col style="width:30%">
                  <col style="width:70%">
              </colgroup>
              <tbody>
              <tr>
                  <th scope="row">비밀번호</th>
                  <td>
                      <br-form-input type="password" label="비밀번호" v-model="checkPswd" panel="panel" required/>
                  </td>
              </tr>
              </tbody>
          </table>
        </div>
        <div class="btn_area center">
          <br-button label="확인" class="btn md primary" @click="pswdCheck" />
          <br-button label="취소" class="btn md" @click="hide" />
        </div>
      </div>
      <button type="button" class="modal_close" @click.prevent="hide" >팝업닫기</button>
    </div>
  </div>

  <simple-auth-modal @callback="simpleAuthCallback" @fail="simpleAuthFail"/>
  </template>
  
  <script>
  import MbrFormTelno from "../mbr-mng/components/MbrFormTelno.vue";
  import {storeSession} from "@/js/store/store-session";
  import SimpleAuthModal from "@/components/SimpleAuthModal.vue";
  import {storeSwitch} from "@/js/store/store-switch";

  const API = {
    USER_INFO : "/user",
    AUTHRT_CODE : "/join/code",
    PSWD_CRITERIA : "/join/pswdCriteria",
    MY_OFFICE : "/my-office/MyOfficeMain",
    PSWD_CHECK : "/user/pswdCheck",
    USER_CHECK : "/join/authUser",
  }
  
  export default {
    components: {MbrFormTelno, SimpleAuthModal},
    data() {
      return {
        param : {
          // 회원
          authrtNm : "",
          sysAuthrtCd : "",
          aplySysAuthrtCd : "",
          userNm : "",
          userId : "",
          encptPswd : "",
          telno : "",
          telno1 : "",
          telno2 : "",
          telno3 : "",
          mblTelno : "",
          mblTelno1 : "",
          mblTelno2 : "",
          mblTelno3 : "",
          fxno : "",
          fxno1 : "",
          fxno2 : "",
          fxno3 : "",
          emlAddr : "",
          deptNm : "",
          jbgdNm : "",
          rbeoCd : "",
          emailUseYn : "",
          ntctlkUseYn : "",
          atchFileGroupId : "", 
          cnsgnOperBzentyUserYn : "",
          lastCntnIpAddr : "", // 최종접속IP주소
          pswdChgDmndYmd : "", // 비밀번호 변경 알람 개월수
          aprvPrcsSttsCd : "",
          splmntDmndCn : "",
          rjctRsn : "",
          ci : "",
          di : "",

          // 업무관리사용자
          cmptncCtpvStdgCd : "",
          cmptncSggStdgCd : "",
          insdIpAddr : "",
          otsdIpAddr : "", 

          userMngSe : "",
        },
        beParam : {},
        userinfo: storeSession.getMemberInfo(),
        encptPswdCh : "",
        passwordError : "",  // 비밀번호 오류 메시지
        passwordMatchError : "",  // 비밀번호 일치 여부 오류 메시지
        emailError : "", // 이메일 오류 메시지
        userMngSe : "",
        ctpvList: [],
        sggList: [],
        pswdCriteria: "", 
        fileListTaskSeCd1: 'FUSR',
        fileListTaskSeCd2: 'LCGV',
        cnsgnYnOptions : [
          {value : "Y", text : "대상"},
          {value : "N", text : "비대상"},
        ],
        isOn: true,
        checkPswd: "",
        proParam: [],
      };
    },
    computed: {
      filteredCtpvList() {
        const allCtpvList = this.$store.getters.getSggList({ type: 'ctpv' });
        const filteredList = allCtpvList
          .filter(item => item.useYn === 'Y')
          .map(item => ({
            value: item.sggStdgCd, // value는 시도 코드
            text: item.sggNm // text는 시도 이름
          })); 
        return [{text : "선택", value: ""}, ...filteredList];
      }
    },
    watch: {
      'param.cmptncCtpvStdgCd': function(newCtpvStdgCd) {
        this.fetchSggList(newCtpvStdgCd);
      }
    },
    created() {
    },
    mounted() {
      //this.simpleAuthOpen();
      // 비밀번호 확인
      this.initialize();
    },
    methods: {
      async initialize() {
        await this.loadAuthrtCd()
        this.loadPswdCriteria();
        this.ctpvList = this.filteredCtpvList;
        
        this.read();
      },
      read() {
        this.$apiCall.get(API.USER_INFO + "/" + `${this.userinfo.userId}`, {userMngSe : this.userMngSe}, this.success, this.error);
      },
      success(data) {
        if (!data.result) {
          this.error();
          return;
        }

        this.beParam = data.result;
      },
      error() {
        alert('warning', "데이터를 가져오는 중 오류가 발생했습니다.");
      },
      loadAuthrtCd() {
        const authrtCd = `${this.userinfo.sysAuthrtCd}`
        if (authrtCd.startsWith('03') || authrtCd.startsWith('04') || authrtCd.startsWith('05')) {
          this.userMngSe = "task";
        } else if (authrtCd.startsWith('01') || authrtCd.startsWith('02') || authrtCd.startsWith('06')) {
          this.userMngSe = "user";
        } else {
          console.log('다른 값으로 시작합니다.');
        }

        return new Promise((resolve, reject) => {
          this.$apiCall.get(API.AUTHRT_CODE, {userMngSe : this.userMngSe}, data => {
          if (data.result) {
              this.authrtOptions = data.result.map(item => ({
              text: item.authrtNm,
              value: item.sysAuthrtCd
              }));
              this.authrtOptions.unshift({ text: "선택", value: "" });
              resolve();
          } else {
              reject(new Error("Failed to load authrt codes."));
          }
          });
        });
      },
      fetchSggList(ctpvStdgCd) {
        // 시군구 목록
        this.sggList = [];
        this.sggList.unshift({ text: "선택", value: "" });
        
        if (!ctpvStdgCd) {
          // 시도 코드가 없으면 시군구 리스트를 초기화
          return;
        }
        const allSggList = this.$store.getters.getSggList({ upSggStdgCd: ctpvStdgCd });
        this.sggList = allSggList
          .filter(item => item.useYn === 'Y') 
          .map(item => ({
            value: item.sggStdgCd, // value는 시군구 코드
            text: item.sggNm // text는 시군구 이름
          }));
        this.sggList.unshift({ text: "선택", value: "" });
      },
      loadPswdCriteria() {
        return new Promise((resolve, reject) => {
          this.$apiCall.get(API.PSWD_CRITERIA, {}, data => {
          if (data.result) {
            this.pswdCriteria = data.result.mainRfrncVl

            const currentDate = new Date()

            // 현재 날짜에서 기준 날짜를 설정
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth();
            const day = currentDate.getDate();

            // 새로운 월과 연도를 계산
            const newDate = new Date(year, month + Number(this.pswdCriteria), day);

            // YYYYMMDD 형식으로 변환
            const newYear = newDate.getFullYear();
            const newMonth = String(newDate.getMonth() + 1).padStart(2, '0');
            const newDay = String(newDate.getDate()).padStart(2, '0');

            this.param.pswdChgDmndYmd = `${newYear}${newMonth}${newDay}`;

            resolve();
          } else {
            reject(new Error("Failed to load pswd criteria."));
          }
          });
        });
      },
      validateEmail() { // 이메일 유효성 검사
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (this.param.emlAddr && !emailPattern.test(this.param.emlAddr)) {
          this.emailError = '유효한 이메일 주소를 입력하세요.';
        } else {
          this.emailError = '';
        }
      },
      validatePassword() {
        const password = this.param.encptPswd;
        const passwordPattern = /^(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{9,}$/;
        
        if (password && !passwordPattern.test(password)) {
          this.passwordError = '비밀번호는 대문자, 소문자, 숫자, 특수문자를 포함한 9자 이상이어야 합니다.';
        } else {
          this.passwordError = '';
        }
      },
      validatePasswordMatch() {
        const password = this.param.encptPswd;
        const passwordConfirm = this.encptPswdCh;

        if (passwordConfirm && password !== passwordConfirm) {
          this.passwordMatchError = '비밀번호가 일치하지 않습니다.';
        } else {
          this.passwordMatchError = '';
        }
      },
      check() {
        if(this.param.encptPswd != this.param.originalPassword && this.param.encptPswd != '') {
          if(this.encptPswdCh == null || this.encptPswdCh == ''){
            alert("비밀번호 확인을 입력해주세요.");
            return false;
          }
        }

        if(this.passwordError || this.passwordMatchError) {
          alert("비밀번호가 유효하지 않습니다.");
          return false;
        }

        if (this.emailError) {
          alert("이메일 주소가 유효하지 않습니다.");
          return false;
        }

        // 전화번호 합치기
        const fullTelno = `${this.param.telno1}${this.param.telno2}${this.param.telno3}`;
        const fullMblTelno = `${this.param.mblTelno1}${this.param.mblTelno2}${this.param.mblTelno3}`;
        const fullFxno = `${this.param.fxno1 || ''}${this.param.fxno2 || ''}${this.param.fxno3 || ''}`;

        this.param.telno = fullTelno;
        this.param.mblTelno = fullMblTelno;
        this.param.fxno = fullFxno;

        // 전화번호 유효성 체크
        const telnoInput = this.$refs.telnoInput;
        if (telnoInput && !telnoInput.isValid()){
          alert("전화번호를 올바르게 입력해 주세요.");
          return false;
        }

        const mTelnoInput = this.$refs.mTelnoInput;
        if (mTelnoInput && !mTelnoInput.isPhoneValid()){
          alert("휴대전화번호를 올바르게 입력해 주세요.");
          return false;
        }

        const fxnoInput = this.$refs.fxnoInput;
        if (fullFxno && !fxnoInput.isValid()){
          alert("팩스번호를 올바르게 입력해 주세요.");
          return false;
        }

        return true;
      },
      update() {  // 수정
        const isValid = this.check(); // 유효성 검사 수행
        if(!isValid){
          return;
        }

        // 인증
        this.simpleAuthOpen();
      },
      updateInfo(){
        if(confirm("저장 하시겠습니까?")) {
          this.$refs.attachFileListUser.save().then(atchFileGroupId => {
            this.param.atchFileGroupId = atchFileGroupId;
            this.param.userMngSe = this.userMngSe;

            this.$apiCall.put(
              API.USER_INFO + "/" + this.param.userId,
              this.param, 
              () => {
                alert("저장되었습니다.");
                this.read();
              },
              () => {
                alert("저장에 실패하였습니다.");
              }
            );
          });
        }
      },
      request(){
        const isValid = this.check(); // 유효성 검사 수행
        if(!isValid){
          return;
        }

        if(confirm("승인요청 하시겠습니까?")) {
          this.$refs.attachFileListUser.save().then(atchFileGroupId => {
            this.param.atchFileGroupId = atchFileGroupId;
            this.param.userMngSe = this.userMngSe;

            this.param.aprvPrcsSttsCd = "01"; // 승인대기 상태로 변경
            this.$apiCall.put(
              API.USER_INFO + "/" + this.param.userId,
              this.param, 
              () => {
                alert("승인요청 완료되었습니다.");
                this.read();
              },
              () => {
                alert("승인요청에 실패하였습니다.");
              }
            );
          });
        }
      },
      simpleAuthOpen() {
        storeSwitch.on('SimpleAuthModal', {isReToMain: false});
      },
      simpleAuthCallback(result) {
        console.log("simpleAuthCallback", result);

        this.$apiCall.get(
          API.USER_CHECK,
          {provider : result.provider, brno : null}, 
          (data) => {
            console.log(data.result);
            this.proParam = data.result;

            if(this.proParam.userNm == this.param.userNm && 
                this.proParam.mblTelno == this.param.mblTelno){
                alert("인증이 성공적으로 완료되었습니다.");
                this.updateInfo();
            } else {
              alert("인증 실패하였습니다.");
              return;
            }
          },
          () => {
            alert("인증에 실패하였습니다.");
          }
        );
      },
      simpleAuthFail(error) {
        console.log("simpleAuthFail", error);
      },
      navigateTo(path) {
        if (path) {
          this.$router.push(path).catch(() => {
              console.error("Navigation Error");
          });
        }
        window.scrollTo({ top: 0 });
      },
      pswdCheck() {
        const allParam = {
          encptPswd: this.beParam.encptPswd,
          checkPswd: this.checkPswd,
          userId: this.userinfo.userId,
        }
        this.$apiCall.post(
          API.PSWD_CHECK, allParam, 
          () => {
            this.param = this.beParam; 
            this.isOn = false;
            
            // 기존 비밀번호를 저장
            this.param.originalPassword = this.param.encptPswd;

            this.param.encptPswd = '';

            this.encptPswdCh = ""; // 비밀번호가 비어있을 경우에만 초기화
            this.passwordError = ''; // 비밀번호 오류 메시지 초기화
            this.passwordMatchError = ''; // 비밀번호 일치 오류 메시지 초기화
          },
          () => {
            alert("비밀번호가 일치하지 않습니다.");
          }
        );
      },
      hide() {
        this.isOn = false;
        this.$router.push(API.MY_OFFICE).catch(() => {});
      },
    },   
  }
  </script>
  
  <style>

  div.board_write.disabled { pointer-events: none; opacity: 0.6;}
  
  </style>