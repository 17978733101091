<template>
  <div v-if="isVisible" class="modal visible">
    <div class="popup_box full" tabindex="0">
      <h1 class="popup_title">정보 확인</h1>
      <div class="popup_cont">
        <h3 class="title2">업체 정보</h3>
        <div class="board_write">
          <table>
            <colgroup>
              <col style="width:18%">
              <col style="width:32%">
              <col style="width:18%">
              <col style="width:32%">
            </colgroup>
            <tbody>
            <tr>
              <th scope="row">업체명</th>
              <td>{{ bplcInfo?.bzentyNm }}</td>
              <th scope="row">대표자명</th>
              <td>{{ bplcInfo?.rprsvNm }}</td>
            </tr>
            <tr>
              <th scope="row">사업자등록번호</th>
              <td colspan="3">{{ formatBusinessNumber(bplcInfo?.brno) }}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <h3 class="title2">사업장 정보</h3>
        <div class="board_write">
          <table>
            <colgroup>
              <col style="width:18%">
              <col style="width:32%">
              <col style="width:18%">
              <col style="width:32%">
            </colgroup>
            <tbody>
            <tr>
              <th scope="row">사업장명</th>
              <td>{{ bplcInfo?.bplcNm }}</td>
              <th scope="row">전화번호</th>
              <td>{{ formatPhoneNumber(bplcInfo?.telno) }}</td>
            </tr>
            <tr>
              <th scope="row">담당자명</th>
              <td colspan="3">{{ bplcInfo?.picNm }}</td>
            </tr>
            <tr>
              <th scope="row">시·도</th>
              <td>{{ bplcInfo?.ctpvStdgNm }}</td>
              <th scope="row">시·군·구</th>
              <td>{{ bplcInfo?.sggStdgNm }}</td>
            </tr>
            <tr>
              <th scope="row">주소</th>
              <td colspan="3">{{ bplcInfo.untyZip ? '[' + bplcInfo.untyZip + ']' : '' }} {{ bplcInfo?.bplcAddr }} {{ bplcInfo?.bplcDaddr }}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <h3 class="title2">시설 정보</h3>
        <!-- 고형연료제조 -->
        <div v-if="bplcInfo?.bplcKndCd === '01-01'" class="board_list">
          <table>
            <colgroup>
              <col>
              <col style="width:15rem">
              <col style="width:15rem">
              <col style="width:15rem">
              <col style="width:10rem">
              <col style="width:15rem">
            </colgroup>
            <thead>
              <tr>
                <th scope="col">시설명</th>
                <th scope="col">생산제품종류</th>
                <th scope="col">시설용량(톤/일)</th>
                <th scope="col">시설용량(톤/년)</th>
                <th scope="col">시설상태</th>
                <th scope="col">최초검사 합격여부</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!fcltInfo?.list || fcltInfo.list.length === 0">
                <td colspan="5" aria-label="시설정보없음" style="text-align: center">시설정보 없음</td>
              </tr>
              <tr v-else v-for="(item, index) in fcltInfo?.list" :key="index">
                <td aria-label="시설명" class="txt_left">{{ item.fcltNm }}</td>
                <td aria-label="생산제품종류">{{ item.prdctnPrdctKndNm }}</td>
                <td aria-label="시설용량(톤/일)" style="text-align: right">{{ formatNumber(item.dilyFcltCap) }}</td>
                <td aria-label="시설용량(톤/년)" style="text-align: right">{{ formatNumber(item.anlFcltCap) }}</td>
                <td aria-label="시설상태">{{ item.fcltSttsNm }}</td>
                <td aria-label="최초검사 합격여부">{{ item.frstInspPassYnNm }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <template v-if="bplcInfo?.bplcKndCd === '01-01'">
          <h3 class="title2">시설별 투입품목구성</h3>
          <div class="board_list">
            <table>
              <colgroup>
                <col style="width:30rem">
                <col v-for="(item, index) in fcltInputItemInfo?.inputItemList" :key="index" style="width: auto;">
              </colgroup>
              <thead>
                <tr>
                  <th scope="col" rowspan="2" class="fixed-header">시설명</th>
                  <th scope="col" colspan="100%">원재료 구성비율(%)</th>
                </tr>
                <tr>
                  <th v-for="(item, index) in fcltInputItemInfo?.inputItemList" :key="index" scope="col">
                    {{ item.inputItemNm }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!fcltInputItemInfo?.list || fcltInputItemInfo.list.length === 0">
                  <td colspan="100%" style="text-align: center">투입품목 구성 데이터 없음</td>
                </tr>
                <tr v-else v-for="(fclt, index) in fcltInputItemInfo?.list" :key="index">
                  <td aria-label="시설명" class="txt_left">{{ fclt.fcltNm }}</td>
                  <td v-for="(item, itemIndex) in fcltInputItemInfo?.inputItemList" :key="itemIndex" aria-label="투입품목" style="text-align: right">
                    {{ formatNumber(fclt[item.inputItemCd]) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>

        <!-- 고형연료사용 -->
        <div v-else-if="bplcInfo?.bplcKndCd === '01-02'" class="board_list">
          <table>
            <colgroup>
              <col>
              <col style="width:40rem">
              <col style="width:16rem">
              <col style="width:13rem">
              <col style="width:13rem">
<!--              <col style="width:11rem">-->
            </colgroup>
            <thead>
            <tr>
              <th scope="col">시설명</th>
              <th scope="col">투입제품종류</th>
              <th scope="col">시설용량(톤/일)</th>
              <th scope="col">시설용량(톤/년)</th>
              <th scope="col">시설상태</th>
<!--              <th scope="col">최초검사합격여부</th>-->
            </tr>
            </thead>
            <tbody>
            <tr v-if="!fcltInfo?.list || fcltInfo.list.length === 0">
              <td colspan="5" aria-label="시설정보없음" style="text-align: center">시설정보 없음</td>
            </tr>
            <tr v-else v-for="(item, index) in fcltInfo?.list" :key="index">
              <td aria-label="시설명" class="txt_left">{{ item.fcltNm }}</td>
              <td aria-label="투입제품종류">{{ item.inputItems }}</td>
              <td aria-label="시설용량(톤/일)" style="text-align: right">{{ formatNumber(item.dilyFcltCap) }} 톤/일</td>
              <td aria-label="시설용량(톤/년)" style="text-align: right">{{ formatNumber(item.anlFcltCap) }} 톤/년</td>
              <td aria-label="시설상태">{{ item.fcltSttsNm }}</td>
<!--              <td aria-label="최초검사합격여부">{{ item.frstInspPassYnNm }}</td>-->
            </tr>
            </tbody>
          </table>
        </div>

        <!-- 매립가스 -->
        <div v-else-if="bplcInfo?.bplcKndCd === '02-01'" class="board_write">
          <table>
            <colgroup>
              <col style="width:18%">
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">총 매립 용량(톤)</th>
                <td>{{ formatNumber((parseFloat(fcltInfo?.totfillCap) || 0)) }} 톤</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 소각/소각열회수 -->
        <div v-else-if="bplcInfo?.bplcKndCd === '03-01' || bplcInfo?.bplcKndCd === '03-02'" class="board_list">
          <table>
            <colgroup>
              <col>
              <col style="width:20rem">
              <col style="width:15rem">
              <col style="width:15rem">
              <col style="width:10rem">
            </colgroup>
            <thead>
            <tr>
              <th scope="col">시설명</th>
              <th scope="col">증기엔탈피(kcal/kg)</th>
              <th scope="col">발전시설용량(kw)</th>
              <th scope="col">시설용량(톤/일)</th>
              <th scope="col">시설상태</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="!fcltInfo?.list || fcltInfo.list.length === 0">
              <td colspan="5" aria-label="시설정보없음" style="text-align: center">시설정보 없음</td>
            </tr>
            <tr v-else v-for="(item, index) in fcltInfo?.list" :key="index">
              <td aria-label="시설명" class="txt_left">{{ item.fcltNm }}</td>
              <td aria-label="증기엔탈피(kcal/kg)">{{ formatNumber(item.vaporEnthlpVl) }}</td>
              <td aria-label="발전시설용량(kw)">{{ formatNumber(item.elctgnFcltCap) }}</td>
              <td aria-label="시설용량(톤/일)">{{ formatNumber(item.dilyFcltCap) }}</td>
              <td aria-label="시설상태">{{ item.fcltSttsNm }}</td>
            </tr>
            </tbody>
          </table>
          <p class="grid-warning" v-if="this.prfmncYr > 2024">※ 에너지 생산량(톤 -> Gcal) 및 이용효율의 자동 계산을 위해서는 증기엔탈피 값을 반드시 입력해주셔야 합니다.</p>
        </div>

        <!-- 바이오가스 -->
        <div v-else-if="bplcInfo?.bplcKndCd === '04-01'" class="board_list">
          <table>
            <colgroup>
              <col>
              <col>
            </colgroup>
            <thead>
            <tr>
              <th scope="col">투입 폐기물 종류</th>
              <th scope="col">시설용량(톤/일)</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="!fcltInfo?.list || fcltInfo.list.length === 0">
              <td colspan="5" aria-label="시설정보없음" style="text-align: center">시설정보 없음</td>
            </tr>
            <tr v-else v-for="(item, index) in fcltInfo?.list" :key="index">
              <td aria-label="투입 폐기물 종류">{{ item.inputItemNm }}</td>
              <td aria-label="시설용량(톤/일)">{{ formatNumber(item.dilyInpqty) }}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <div v-else>
          <p>시설 정보 없음</p>
        </div>

        <h3 class="title2">작성자 정보</h3>
        <div class="board_write">
          <table>
            <colgroup>
              <col style="width:18%">
              <col style="width:32%">
              <col style="width:18%">
              <col style="width:32%">
            </colgroup>
            <tbody>
            <tr>
              <th scope="row">작성자명</th>
              <td>{{ userinfo?.userNm || '정보 없음' }}</td>
              <th scope="row">휴대전화번호</th>
              <td>{{ formatPhoneNumber(userinfo?.mblTelno) }}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="grid_box pt_15 pb_20 txt_center mt_20">
          <div class="form_check">
            <input type="checkbox" id="idnty" v-model="idnty" style="display: none" />
            <label for="idnty">위의 업체/사업장/시설 정보가 최신정보임을 확인합니다.</label>
          </div>
          <p class="mt_5">변경된 정보를 현행화하지 않아 발생된 불이익에 대해 모두 책임지겠습니다.</p>
          <p class="mt_15">작성자 : <strong>{{ userinfo?.userNm || '' }}</strong></p>
        </div>
        <div class="btn_area">
          <br-button label="마이오피스 바로가기" size="md" @click="toMyOffice" />
          <br-button label="정보확인완료" size="md" format="secondary" @click="toRegister" />
        </div>
        <button type="button" class="modal_close" @click="hide">팝업 닫기</button>
      </div>
    </div>
  </div>
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";
import { storeSession } from "@/js/store/store-session";
import utils from "@/js/utils/utils";

const API = {
  BUSINESS_PLACE_INFO: "/bplc",
  FACILITIES_INFO_BY_BUSINESS_PLACE: "/fclt/by-bplc",
  FACILITIES_INPUT_ITEM_COMPOSITION: "/fclt/input-item-cpst"
};
const ROUTE = {
  MY_OFFICE: "/my-office/MyOfficeMain",
}

export default {
  components: {},
  data() {
    return {
      isVisible: false,
      userinfo: storeSession.getMemberInfo(),
      prfmncYm: "",
      prfmncYr:"",
      bplcInfo: null,
      fcltInfo: null,
      fcltInputItemInfo: null,
      idnty: false
    };
  },
  computed: {},
  watch: {},
  created() {
    storeSwitch.setBulb('BplcInfoIdntyPopup', this);
  },
  mounted() {
  },
  methods: {
    loadBplcInfo(param) {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(
            API.BUSINESS_PLACE_INFO,
            param,
            data => {
              if (data.result) {
                this.bplcInfo = data.result;
              } else {
                this.bplcInfo = null;
              }
              resolve();
            },
            () => {
              this.bplcInfo = null;
              reject(new Error("Failed to load bplc info."));
            }
        );
      });
    },
    loadFcltInfo(param) {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(
            API.FACILITIES_INFO_BY_BUSINESS_PLACE,
            param,
            data => {
              if (data.result) {
                this.fcltInfo = data.result;
              } else {
                this.fcltInfo = null;
              }
              resolve();
            },
            () => {
              this.fcltInfo = null;
              reject(new Error("Failed to load fclt info."));
            }
        );
      });
    },
    loadFcltInputItemInfo(param) {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(
            API.FACILITIES_INPUT_ITEM_COMPOSITION,
            param,
            data => {
              if (data.result) {
                this.fcltInputItemInfo = data.result;
              } else {
                this.fcltInputItemInfo = null;
              }
              resolve();
            },
            () => {
              this.fcltInputItemInfo = null;
              reject(new Error("Failed to load fclt input item info."));
            }
        );
      });
    },
    async show(param) {
      this.reset(param);
      await this.loadBplcInfo(param);
      await this.loadFcltInfo(param);

      if (this.bplcInfo?.bplcKndCd === '01-01') {
        await this.loadFcltInputItemInfo(param);
      }

      document.documentElement.style.overflow = 'hidden';
      this.isVisible = true;
    },
    hide() {
      this.isVisible = false;
      document.documentElement.style.overflow = '';
    },
    formatPhoneNumber(number) {
      return utils.formatPhoneNumber(number);
    },
    formatBusinessNumber(businessNumber) {
      return utils.formatBusinessNumber(businessNumber);
    },
    formatNumber(number) {
      return utils.formatNumberWithComma(number);
    },
    toMyOffice() {
      this.hide();

      this.$router.push(ROUTE.MY_OFFICE).catch(() => {
        console.error("Navigation Error");
      });
    },
    toRegister() {
      if (!this.idnty) {
        alert("최신정보 확인 여부를 체크해 주세요.");
        return;
      }

      const bplcId = this.bplcInfo?.bplcId;
      const bplcKndCd = this.bplcInfo?.bplcKndCd;

      let path = "";

      switch (bplcKndCd) {
        case "01-01": // 고형연료제조
          path = "/prfmnc-rpt/dtl/SdflMnfrt";
          break;
        case "01-02": // 고형연료사용
          path = "/prfmnc-rpt/dtl/SdflUse";
          break;
        case "01-03": // 고형연료수입
          path = "/prfmnc-rpt/dtl/SdflIncm";
          break;
        case "02-01": // 매립가스
          path = "/prfmnc-rpt/dtl/FillGas";
          break;
        case "03-01": // 소각
          path = "/prfmnc-rpt/dtl/FireRtrvl";
          break;
        case "03-02": // 소각열회수
          path = "/prfmnc-rpt/dtl/FireRtrvl";
          break;
        case "04-01": // 바이오가스
          path = "/prfmnc-rpt/dtl/BioGas";
          break;
        case "05-01": // 폐기물합성가스
          path = "/prfmnc-rpt/dtl/SynthGas";
          break;
        default :
          path = "";
      }

      if (path) {
        this.hide();

        this.$router.push({path : path, query : {bplcId: bplcId, prfmncYm: this.prfmncYm }}).catch(() => {
          console.error("Navigation Error");
        });
      }
    },
    reset(data) {
      this.prfmncYm = data.prfmncYr + data.prfmncMm;
      this.prfmncYr = data.prfmncYr;
      this.bplcInfo = null;
      this.fcltInfo = null;
      this.idnty = false;
    }
  }
}
</script>

<style scoped>

</style>