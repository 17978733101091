<template>

  <h2 class="title1 mt_0">실적보고 상세</h2>
  <div class="board_write">
    <table>
      <caption>실적연도, 실적상태, 사업장명, 사업장종류</caption>
      <colgroup>
        <col style="width:18%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:32%">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">실적연월</th>
        <td>{{ formattedPrfmncYm }}</td>
        <th scope="row">실적상태</th>
        <td>{{ bioGasDtlObj.prfmncMst.prfmncSttsNm || "작성중" }}</td>
      </tr>
      <tr>
        <th scope="row">사업장명</th>
        <td>{{ bioGasDtlObj.prfmncMst.bplcNm }}</td>
        <th scope="row">사업장종류</th>
        <td>{{ bioGasDtlObj.prfmncMst.bplcKndNm }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <rjct-rsn-area :prfmncBasicInfo="bioGasDtlObj.prfmncMst" />
  <prfmnc-mdfcn-prm-area :prfmncBasicInfo="bioGasDtlObj.prfmncMst" />

  <div class="board_info mt_40">
    <h3 class="title2">폐기물 반입 현황</h3>
  </div>

  <sb-grid
      id="prfmncDtlBioGasMtralCrynGrid"
      :dataSource="dataSourceBioGasWtCryn"
      :columns="gridColumnsBioGasWtCryn"
      :gridattr="gridAttrBioGasWtCryn"
      v-model:grid="prfmncBioGasWtCrynGridObject"
      @loaded="gridLoadedBioGasWtCryn"
      :refreshGrid="gridReloadBioGasWtCryn"
  />

  <div class="board_info mt_40">
    <h3 class="title2">보조에너지 사용량</h3>
  </div>
  <div class="board_write thead">
    <table>
      <caption>보조에너지 사용량</caption>
      <colgroup>
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
      </colgroup>
      <thead>
      <tr>
        <th scope="col" colspan="3">보조연료 사용량</th>
        <th scope="col" colspan="2">전기 사용량(MWh)</th>
      </tr>
      <tr>
        <th scope="row">유류(㎘)</th>
        <th scope="row">LNG(㎥)</th>
        <th scope="row">기타(TOE)</th>
        <th scope="row">외부반입(한전)</th>
        <th scope="row">자체생산</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="txt_right" aria-label="유류(㎘)">{{ formatNumber(bioGasDtlObj.prfmncBioGas.oilAsstFuelUsqty) }} ㎘</td>
        <td class="txt_right" aria-label="LNG(㎥)">{{ formatNumber(bioGasDtlObj.prfmncBioGas.lngAsstFuelUsqty) }} ㎥</td>
        <td class="txt_right" aria-label="기타(TOE)">{{ formatNumber(bioGasDtlObj.prfmncBioGas.etcAsstFuelUsqty) }} TOE</td>
        <td class="txt_right" aria-label="외부반입(한전)">{{ formatNumber(bioGasDtlObj.prfmncBioGas.crynPrvtmAsstFuelUsqty) }} MWh</td>
        <td class="txt_right" aria-label="자체생산">{{ formatNumber(bioGasDtlObj.prfmncBioGas.prdctnPrvtmAsstFuelUsqty) }} MWh</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info mt_40">
    <h3 class="title2">에너지 이용 현황</h3>
  </div>
  <div class="board_write thead">
    <table>
      <caption>에너지 이용 현황</caption>
      <colgroup>
        <col style="width: 14%;">
        <col style="width: 12%;">
        <col style="width: 12%;">
        <col style="width: 12%;">
        <col style="width: 12%;">
        <col style="width: 12%;">
        <col style="width: 12%;">
        <col style="width: 14%;">
      </colgroup>
      <thead>
      <tr>
        <th scope="row" rowspan="2">생산량<br />(A + B)</th>
        <th scope="col" colspan="6">에너지이용현황</th>
        <th scope="row" rowspan="2">미이용(단순처리)<br />(B)</th>
      </tr>
      <tr>
        <th scope="row" style="border-left: 1px solid #e0e0e0;">총 이용량(A)</th>
        <th scope="row">발전</th>
        <th scope="row">가스외부공급</th>
        <th scope="row">가스자체사용</th>
        <th scope="row">스팀생산</th>
        <th scope="row">기타</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="txt_right" aria-label="생산량(A + B)">{{ formatNumber(bioGasDtlObj.prfmncBioGas.enePrdqty) }} N㎥</td>
        <td class="txt_right" aria-label="총 이용량(A)">{{ formatNumber(bioGasDtlObj.prfmncBioGas.eneUtzqty) }} N㎥</td>
        <td class="txt_right" aria-label="발전">{{ formatNumber(bioGasDtlObj.prfmncBioGas.elctgnUsgGasUsqty) }} N㎥</td>
        <td class="txt_right" aria-label="가스외부공급">{{ formatNumber(bioGasDtlObj.prfmncBioGas.gasOtsdSupqty) }} N㎥</td>
        <td class="txt_right" aria-label="가스자체사용">{{ formatNumber(bioGasDtlObj.prfmncBioGas.gasSlfUsqty) }} N㎥</td>
        <td class="txt_right" aria-label="스팀생산">{{ formatNumber(bioGasDtlObj.prfmncBioGas.stUsgGasUsqty) }} N㎥</td>
        <td class="txt_right" aria-label="기타">{{ formatNumber(bioGasDtlObj.prfmncBioGas.etcUsgGasUsqty) }} N㎥</td>
        <td class="txt_right" aria-label="미이용(단순처리)(B)">{{ formatNumber(bioGasDtlObj.prfmncBioGas.eneUnuqty) }} N㎥</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info mt_40">
    <h3 class="title2">에너지 판매 현황</h3>
  </div>
  <div class="grid_search">
    <h4><strong class="mr_5">(에너지 판매량)</strong>외부공급(판매) 상세현황
      <span>※ 판매업체 목록은 반입/판매(공급)업체현황에서 관리할 수 있습니다.</span>
    </h4>
  </div>
  <sb-grid
      id="prfmncBioGasNtslGrid"
      :dataSource="dataSourceBioGasNtsl"
      :columns="gridColumnsBioGasNtsl"
      :gridattr="gridAttrBioGasNtsl"
      v-model:grid="prfmncBioGasNtslGridObject"
      @loaded="gridLoadedBioGasNtsl"
      :refreshGrid="gridReloadBioGasNtsl"
  />

  <!-- 실적상태이력 현황 (신규등록인 경우 노출안함) -->
  <prfmnc-stts-hstry v-if="bioGasDtlObj.prfmncMst.prfmncId" :prfmncId="bioGasDtlObj.prfmncMst.prfmncId" />

  <!-- 수정허용요청 이력 현황 (신규등록인 경우 노출안함) -->
  <prfmnc-mdfcn-prm-hstry v-if="bioGasDtlObj.prfmncMst.prfmncId" :prfmncId="bioGasDtlObj.prfmncMst.prfmncId" />

  <!-- 버튼 -->
  <common-button-area
      :prfmncBasicInfo="bioGasDtlObj.prfmncMst"
  />
</template>

<script>
import SbGrid from "@/components/SbGrid.vue";
import utils from "@/js/utils/utils";
import commonButtonArea from "@/views/prfmnc-rpt/dtl/components/CommonButtonArea.vue";
import prfmncSttsHstry from "@/views/prfmnc-rpt/components/PrfmncSttsHstry.vue";
import PrfmncMdfcnPrmHstry from "@/views/prfmnc-rpt/components/PrfmncMdfcnPrmHstry.vue";
import PrfmncMdfcnPrmArea from "@/views/prfmnc-rpt/dtl/components/PrfmncMdfcnPrmArea.vue";
import RjctRsnArea from "@/views/prfmnc-rpt/dtl/components/RjctRsnArea.vue";

const CODE = {
  PERFORMANCE_STATUS: "PFM001", // 실적상태코드
};

export default {
  components: {
    RjctRsnArea, PrfmncMdfcnPrmArea,
    SbGrid,
    commonButtonArea,
    prfmncSttsHstry,
    PrfmncMdfcnPrmHstry
  },
  props: ['prfmncBioGas', 'prfmncBioGasWtCrynList', 'prfmncBioGasNtslList', 'prfmncMst', 'prfmncFclt', 'showDetail'],
  data() {
    return {
      prfmncSttsList: [], // 실적상태코드

      updatedMtralCrynData: [], // 원료반입현황 수정된 행
      insertedMtralCrynData: [], // 원료반입현황 추가된 행

      // 실적 바이오가스 상세정보 데이터
      bioGasDtlObj:{
        prfmncMst: {},
        prfmncBioGasWtCrynList : [], // 원료반입현황
        prfmncBioGasNtslList : [], // 합성가스판매현황
        prfmncBioGas: {
          enePrdqty: 0,  // 에너지생산량
          oilAsstFuelUsqty: 0,  // 유류보조연료사용량
          lngAsstFuelUsqty: 0,  // LNG보조연료사용량
          etcAsstFuelUsqty: 0,  // 기타보조연료사용량
          crynPrvtmAsstFuelUsqty: 0,  // 반입전기보조연료사용량
          prdctnPrvtmAsstFuelUsqty: 0,  // 생산전기보조연료사용량
          eneUtzqty: 0,  // 에너지이용량
          elctgnUsgGasUsqty: 0,  // 발전용도가스사용량
          gasOtsdSupqty: 0,  // 가스외부공급량
          gasSlfUsqty: 0,  // 가스자체사용량
          stUsgGasUsqty: 0,  // 스팀용도가스사용량
          etcUsgGasUsqty: 0,  // 기타용도가스사용량
          eneUnuqty: 0,  // 에너지미이용량
          prvtmSumNsqty: 0,  // 전기합계판매량
          gasSumNsqty: 0,  // 가스합계판매량
          stSumNsqty: 0,  // 스팀합계판매량
          etcEneSumNsqty: 0,  // 기타에너지합계판매량
        }, // 합성가스실적 (메탄가스생산현황, 메탄가스사용현황)
      },


      /**************** S : 원료반입현황 Grid ****************/
      dataSourceBioGasWtCryn: [],
      gridColumnsBioGasWtCryn: [],
      gridAttrBioGasWtCryn: {
        checkable: false,
        pageable: false,
        editable : false,
        showRowNo : false,
        mergeRow: true,
        excelExport: {
          fileName: '폐기물반입현황(바이오가스).xlsx',
          includeHeader: true,
          keepMerge: true,
        },
        showStatus:false,
      },
      prfmncBioGasWtCrynGridObject: null,
      gridReloadBioGasWtCryn: false,
      /**************** E : 원료반입현황 Grid ****************/

      /**************** S : 에너지판매현황 Grid ****************/
      dataSourceBioGasNtsl: [],
      gridColumnsBioGasNtsl: [],
      gridAttrBioGasNtsl: {
        checkable: false,
        pageable: false,
        editable: false,
        showRowNo : false,
        excelExport: {
          fileName: '에너지판매현황(바이오가스).xlsx',
          includeHeader: true,
          includeFormula: true,
        },
        showStatus:false,
      },
      prfmncBioGasNtslGridObject: null,
      gridReloadBioGasNtsl: false,
      /**************** E : 에너지판매현황 Grid ****************/
    };
  },
  computed: {
    /**
     * [실적보고상세 - 실적연월]
     * YYYY-MM 포맷처리
     */
    formattedPrfmncYm() {
      return (this.bioGasDtlObj.prfmncMst.prfmncYm?.substring(0, 4) ?? "") + "-" + (this.bioGasDtlObj.prfmncMst.prfmncYm?.substring(4, 6) ?? "");
    },
  },
  watch: {
    prfmncMst: {
      handler(newVal) {
        this.bioGasDtlObj.prfmncMst = {...this.bioGasDtlObj.prfmncMst, ...newVal};
      },
      immediate: true,
      deep:true,
    },
    prfmncBioGas() {
      this.bioGasDtlObj.prfmncBioGas = {...this.bioGasDtlObj.prfmncBioGas, ...this.prfmncBioGas};
    },
    prfmncBioGasWtCrynList() {
      const bioGasWtCrynListCopy = this.prfmncBioGasWtCrynList.list.map(item => ({ ...item }));

      // `0020`, `0021`, `0022`로 시작하는 값들의 최대값 계산
      const keysToCheck = ['0020', '0021', '0022'];
      const maxValues = {};

      keysToCheck.forEach(keyPrefix => {
        maxValues[keyPrefix] = Math.max(
            ...bioGasWtCrynListCopy.map(item => {
              // keyPrefix로 시작하는 키를 찾아 해당 값을 가져옴
              const key = Object.keys(item).find(k => k.startsWith(keyPrefix));
              return key ? (item[key] || 0) : 0;
            })
        );
      });

      // 각 항목에 최대값 설정
      const modifiedBioGasWtCrynList = bioGasWtCrynListCopy.map(item => {
        keysToCheck.forEach(keyPrefix => {
          const key = Object.keys(item).find(k => k.startsWith(keyPrefix));
          if (key) {
            item[key] = maxValues[keyPrefix]; // 최대값 설정
          }
        });
        return item;
      });

      this.dataSourceBioGasWtCryn = { data: modifiedBioGasWtCrynList, schema: { id: 'sggStdgCd'}};
      this.initializeprfmncBioGasMtralGridColumns();
      const itemCount = this.prfmncBioGasWtCrynList.list.length;
      this.gridAttrBioGasWtCryn.height = Math.max((itemCount * 30) + 190, 210);
      this.gridReloadBioGasWtCryn = true;

    },
    prfmncBioGasNtslList() {
      this.dataSourceBioGasNtsl = this.prfmncBioGasNtslList.list;
      this.initializeprfmncBioGasNtslGridColumns();
      const itemCount = this.prfmncBioGasNtslList.list.length;
      this.gridAttrBioGasNtsl.height = Math.max((itemCount * 30) + 190, 210);
      this.gridReloadBioGasNtsl = true;
    },

    // 발전 값 변경 시 총이용량 재계산
    'bioGasDtlObj.prfmncBioGas.elctgnUsgGasUsqty': 'updateEneUtzqty',
    // 가스외부공급 값 변경 시 총이용량 재계산
    'bioGasDtlObj.prfmncBioGas.gasOtsdSupqty': 'updateEneUtzqty',
    // 가스자체사용 값 변경 시 총이용량 재계산
    'bioGasDtlObj.prfmncBioGas.gasSlfUsqty': 'updateEneUtzqty',
    // 스팀생산 값 변경 시 총이용량 재계산
    'bioGasDtlObj.prfmncBioGas.stUsgGasUsqty': 'updateEneUtzqty',
    // 기타 값 변경 시 총이용량 재계산
    'bioGasDtlObj.prfmncBioGas.etcUsgGasUsqty': 'updateEneUtzqty',

    // 총이용량 값 변경 시 생산량 재계산
    'bioGasDtlObj.prfmncBioGas.eneUtzqty': 'updateEnePrdqty',
    // 미이용 값 변경 시 생산량 재계산
    'bioGasDtlObj.prfmncBioGas.eneUnuqty': 'updateEnePrdqty',

  },
  created() {
    this.initialize();
  },
  mounted() {
  },
  methods: {
    // 에너지이용현황 (총이용량 계산)
    updateEneUtzqty() {
      this.bioGasDtlObj.prfmncBioGas.eneUtzqty =
          parseFloat(this.bioGasDtlObj.prfmncBioGas.elctgnUsgGasUsqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGas.gasOtsdSupqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGas.gasSlfUsqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGas.stUsgGasUsqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGas.etcUsgGasUsqty);
    },
    // 에너지이용현황 (생산량 계산)
    updateEnePrdqty() {
      this.bioGasDtlObj.prfmncBioGas.enePrdqty =
          parseFloat(this.bioGasDtlObj.prfmncBioGas.eneUtzqty) +
          parseFloat(this.bioGasDtlObj.prfmncBioGas.eneUnuqty);
    },
    async initialize() {
      await this.loadPrfmncSttsCd();
    },
    // 공통코드(실적상태코드) 로드
    async loadPrfmncSttsCd() {
      const prfmncSttsList = this.$store.getters.getCodeList({groupCd: CODE.PERFORMANCE_STATUS, useYn: 'Y'});
      this.prfmncSttsList = prfmncSttsList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    // 폐기물반입현황 그리드 컬럼 세팅
    initializeprfmncBioGasMtralGridColumns() {

      const columnCount = this.prfmncBioGasWtCrynList.inputItemList.length+3;
      const columnWidth = columnCount > 0 ? (100 / columnCount).toFixed(2) : 100;

      this.gridColumnsBioGasWtCryn = [
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        {
          caption: '반입',
          columns : [
            { field: 'ctpvStdgNm', caption: '시·도', minWidth: 100, width:columnWidth, unit: '%', editable: false, },
            { field: 'sggStdgNm', caption: '시·군·구', minWidth: 100, width:columnWidth, unit: '%', editable: false, }
          ],
          total: {
            aggregates: [],
            header: [{ template: '계', align: 'right' }],
            headerCss:'grid-header',
          },
        },
      ];

      if (this.prfmncBioGasWtCrynList.inputItemList && this.prfmncBioGasWtCrynList.inputItemList.length > 0) {

        const inputItemCds = this.prfmncBioGasWtCrynList.inputItemList.filter(o => !(o.frstRefVl && !o.scdRefVl && o.thdRefVl)).map(item => item.inputItemCd);

        this.gridColumnsBioGasWtCryn.push({
          field: 'sum', caption: '반입량 합계(톤)',
          editable: false,
          colCss: 'grid-disabled',
          minWidth: 100, width:columnWidth, unit: '%',
          total: {
            aggregates: [{
              func: (items) => {
                // 필터링된 inputItemList에서 조건을 만족하는 key들 추출
                const excludedKeys = this.prfmncBioGasWtCrynList.inputItemList
                    .filter(item => item.thdRefVl !== 'GRP_SLDG') // 조건: thdRefVl !== 'GRP_SLDG'
                    .map(item => item.inputItemCd);

                // items 배열을 순회하며 excludedKeys의 값을 합산
                const totalSum = items.reduce((sum, item) => {
                  excludedKeys.forEach(key => {
                    if (item[key] !== undefined && typeof item[key] === 'number') {
                      sum += item[key]; // 해당 key에 해당하는 값 합산
                    }
                  });

                  if (item.seq === 0) {
                    Object.keys(item).forEach(key => {
                      if (
                          /^002(0|1|2)/.test(key) && // 키가 '0020', '0021', '0022'로 시작
                          typeof item[key] === 'number' // 값이 숫자인지 확인
                      ) {
                        sum += item[key]; // 해당 값 합산
                      }
                    });
                  }

                  return sum;
                }, 0);

                return totalSum;
              },
              field: 'total'
            }],
            header: [{ template: '{{total}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
          calc: {
            eval: (data) => {
              // inputItemCd가 0020, 0021, 0022로 시작하는 것을 제외하고 더함
              return inputItemCds.reduce((sum, key) => {
                if (!/^002[0-2]/.test(key)) {
                  const value = data[key] || 0; // 값이 없으면 0으로 처리
                  return sum + value;
                }
                return sum; // 제외된 항목은 더하지 않음
              }, 0);
            },
            nullAs: 0,
            dataType: 'number',
          },
          align: 'right',
          format: '#,##0.00'
        });

        this.prfmncBioGasWtCrynList.inputItemList.forEach(item => {

          if(item.frstRefVl === 'BIOWT' && item.thdRefVl === 'GRP_SLDG'){
            return;
          }

          let columnsObj = {};

          if(item.inputItemCd.includes('9999')){
            columnsObj = {
              caption: '기타',
              columns : [
                {
                  field: item.inputItemCd,
                  caption: item.inputItemNm+'(톤)',
                  minWidth: 100,
                  width: columnWidth,
                  unit: '%',
                  dataType: 'number',
                  total: {
                    aggregates: [ {func: 'sum', nullAs: 0} ],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                  align: 'right',
                  format: '#,##0.00'
                },
                {
                  field: 'etcWtKndCn',
                  caption: '기타종류',
                  minWidth: 100,
                  width: columnWidth,
                  unit: '%',
                  align: 'left',
                  total: {
                    aggregates: [],
                    header: [],
                    headerCss: 'grid-header',
                  },
                },
              ]
            }
          } else {
            if (item.thdRefVl === 'GRP_SLDG') {
              columnsObj = {
                field: item.inputItemCd,
                caption: item.inputItemNm + '(톤)',
                width: columnWidth,
                unit: '%',
                dataType: 'number',
                total: {
                  aggregates: [{func: 'max', nullAs: 0}],
                  header: [{template: '{{max}}', format: '#,##0.00'}],
                  headerCss: 'grid-header',
                },
                align: 'right',
                format: '#,##0.00',
                mergeRow: true,
              }
            } else {
              columnsObj = {
                field: item.inputItemCd,
                caption: item.inputItemNm+'(톤)',
                minWidth: 100,
                width: columnWidth,
                unit: '%',
                dataType: 'number',
                total: {
                  aggregates: [{func: 'sum', nullAs: 0}],
                  header: [{template: '{{sum}}', format: '#,##0.00'}],
                  headerCss: 'grid-header',
                },
                align: 'right',
                format: '#,##0.00',
              }
            }
          }
          this.gridColumnsBioGasWtCryn.push(columnsObj);
        });
      }
    },
    // 에너지판매현황 그리드 컬럼 세팅
    initializeprfmncBioGasNtslGridColumns() {
      this.gridColumnsBioGasNtsl = [
        { field: 'prfmncId', caption: '실적아이디', visible: false, editable: false },
        { field: 'bplcId', caption: '사업장아이디', visible: false, editable: false },
        { field: 'eneBzentySn', caption: '에너지업체일련번호', visible: false, editable: false },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        {
          field: 'bzentyNm', caption: '판매 업체명', width: 230, align: 'left', part: 'head', editable: false,
          total: {
            aggregates: [],
            header: [{ template: '계', align: 'right' }],
            headerCss:'grid-header',
          },
        },
        {
          field: 'totalNsqty', caption: '총 판매금액(원)', width: 150, align: 'right', dataType: 'number', format: '#,##0.00', editable: false, colCss: 'grid-disabled',
          total: {
            aggregates: [{ func: 'sum', nullAs: 0 },],
            header: [{ template: '{{sum}}', format: '#,##0.00', align: 'right' }],
            headerCss:'grid-header',
          },
          calc: {
            require: ['prvtmNtslAmt', 'gasNtslAmt', 'stNtslAmt'],
            eval: 'sum',
            nullAs: 0,
            dataType: 'number',
          },
        },
        {
          caption: '전기(MWh,원)', captionCss:'gridh-bg-electricity',
          columns : [
            {
              field: 'prvtmNsqty', caption: '판매량(MWh)', minWidth: 100, width: 15, unit:'%', align: 'right', dataType: 'number', format: '#,##0.00', captionCss:'gridh-bg-electricity',
              total: {
                aggregates: [{ func: 'sum', nullAs: 0 },],
                header: [{ template: '{{sum}}', format: '#,##0.00', align: 'right' }],
                headerCss:'grid-header',
              },
            },
            {
              field: 'prvtmNtslAmt', caption: '판매금액(원)', minWidth: 100, width: 15, unit:'%', align: 'right', dataType: 'number', format: '#,##0.00', captionCss:'gridh-bg-electricity',
              total: {
                aggregates: [{ func: 'sum', nullAs: 0 },],
                header: [{ template: '{{sum}}', format: '#,##0.00', align: 'right' }],
                headerCss:'grid-header',
              },
            },
          ],
        },
        {
          caption: '가스(㎥,원)', captionCss:'gridh-bg-gas',
          columns : [
            {
              field: 'gasNsqty', caption: '판매량(㎥)', minWidth: 100, width: 15, unit:'%', align: 'right', dataType: 'number', format: '#,##0.00', captionCss: 'gridh-bg-gas',
              total: {
                aggregates: [{ func: 'sum', nullAs: 0 },],
                header: [{ template: '{{sum}}', format: '#,##0.00', align: 'right' }],
                headerCss:'grid-header',
              },
            },
            {
              field: 'gasNtslAmt', caption: '판매금액(원)', minWidth: 100, width: 15, unit:'%', align: 'right', dataType: 'number', format: '#,##0.00', captionCss:'gridh-bg-gas',
              total: {
                aggregates: [{ func: 'sum', nullAs: 0 },],
                header: [{ template: '{{sum}}', format: '#,##0.00', align: 'right' }],
                headerCss:'grid-header',
              },
            },
          ],
        },
        {
          caption: '스팀(스팀톤,원)', captionCss: 'gridh-bg-vapor',
          columns: [
            {
              field: 'stNsqty', caption: '판매량(스팀톤)', minWidth: 100, width: 15, unit:'%', align: 'right', dataType: 'number', format: '#,##0.00', captionCss:'gridh-bg-vapor',
              total: {
                aggregates: [{ func: 'sum', nullAs: 0 },],
                header: [{ template: '{{sum}}', format: '#,##0.00', align: 'right' }],
                headerCss:'grid-header',
              },
            },
            {
              field: 'stNtslAmt', caption: '판매금액(원)', minWidth: 100, width: 15, unit:'%', align: 'right', dataType: 'number', format: '#,##0.00', captionCss:'gridh-bg-vapor',
              total: {
                aggregates: [{ func: 'sum', nullAs: 0 },],
                header: [{ template: '{{sum}}', format: '#,##0.00', align: 'right' }],
                headerCss:'grid-header',
              },
            },
          ]
        },
        {
          field: 'etcEneNsqty', caption: '기타', minWidth: 100, width: 15, unit:'%', align: 'right', dataType: 'number', format: '#,##0.00', captionCss:'gridh-bg-etc',
          total: {
            aggregates: [{ func: 'sum', nullAs: 0 },],
            header: [{ template: '{{sum}}', format: '#,##0.00', align: 'right' }],
            headerCss:'grid-header',
          },
        },
      ]
    },
    gridLoadedBioGasWtCryn() {
      this.gridReloadBioGasWtCryn = false;
    },
    gridLoadedBioGasNtsl() {
      this.gridReloadBioGasNtsl = false;
    },
    formatNumber(number) {
      return utils.formatNumberWithComma(number);
    },
  } // END methods
} // END export
</script>

<style scoped>

</style>