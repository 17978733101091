<template>
  <div v-show="isOn" class="modal visible">
    <div class="popup_box" style="width: 90%; max-width: 1000px; height: 95%; max-height: 95%;">
      <h1 class="popup_title">리포트</h1>
      <template v-if="isOn">
        <report-form
            :id="rdSetObj.rdFileName"
            :path="rdSetObj.rdFileName"
            :param="rdParam"
            :rd-save-data-add="rdSaveDataAddObj" 
            :rd-width="rdSetObj.rdWidth" 
            :rd-height="rdSetObj.rdHeight" 
            :form-mode="rdSetObj.isFormMode"
            @submit="submit"
            @cancel="cancel"
          />
        </template>
      <button type="button" class="modal_close" @click.prevent="hide" >팝업닫기</button>
    </div>
  </div>
</template>

<script>
/**
 * 정기검사 신청서 리포트 팝업 
 *
 * 작성자 : CHOSUNGHO
 * 작성일 : 24. 11. 18. 
 * 버전 : 1.0
 */

import { storeSwitch } from "@/js/store/store-switch";
import { createRdAgent } from "@/js/store/store-rdagent";
import ReportForm from "@/components/ReportForm.vue";

export default {
  components: { ReportForm }, 
  props: {
    rdParamFileName: {
      type: String,
      required: true
    },
    rdParamKey1: {
      type: String,
      required: true
    },
    rdParamValue1: {
      type: String,
      required: true
    },
    rdParamKey2: {
      type: String,
      required: true
    },
    rdParamValue2: {
      type: String,
      required: true
    },
    rdParamKey3: {
      type: String,
      required: true
    },
    rdParamValue3: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isOn: false,
      rdSetObj: { 
          rdFileName: "ReportFcltInspAplyForm", // 이폼/리포트 파일명 (.mrd 제외) 
          rdKey1: "inspSeCd",                   // 이폼/리포트 조회 데이터 Key 명칭 
          rdValue1: "",                         // 이폼/리포트 조회 데이터 Key에 대한 Value 
          rdKey2: "bplcId", 
          rdValue2: "", 
          rdKey3: "fcltSqno",            
          rdValue3: "",          
          isFormMode: false,                    // 이폼 여부 ( true : 이폼, false : 리포트 ) 
          pdfFileName: "정기검사_신청서_양식",    // PDF 파일명 ( .pdf 제외한 파일명 ) 
          rdWidth: "99.5%",                     // 뷰어 너비 
          rdHeighte: "90%",                     // 뷰어 높이
        },
      };
  },
  computed: {
    rdParam() {
        /** 
        * 이폼/리포트 조회 Parameter 
        * 
        * createRdAgent(Key Value, 이폼여부, PDF 파일명); 
        * 예시 : createRdAgent(\`inspAplySn [18130]\`, true, 'PDF 파일명')
        */
        return createRdAgent(
          // 조회 Parameter 3개일 경우 
          `${this.rdSetObj.rdKey1} [${this.rdSetObj.rdValue1}] ${this.rdSetObj.rdKey2} [${this.rdSetObj.rdValue2}] ${this.rdSetObj.rdKey3} [${this.rdSetObj.rdValue3}]`, 

          // 조회 Parameter 1개일 경우 
          // `${this.rdSetObj.rdKey1} [${this.rdSetObj.rdValue1}]`, 
          this.rdSetObj.isFormMode,
          this.rdSetObj.pdfFileName  
        );
      }
  },
  watch: {
  },
  created() {
    storeSwitch.setBulb('reportFcltInspAplyFormPopup', this);
  },
  mounted() {
  },
  beforeMount() {
    // 전달받은 Param 
    this.rdSetObj.rdFileName = this.rdParamFileName;
    this.rdSetObj.rdKey1 = this.rdParamKey1;
    this.rdSetObj.rdValue1 = this.rdParamValue1;
    this.rdSetObj.rdKey2 = this.rdParamKey2;
    this.rdSetObj.rdValue2 = this.rdParamValue2;
    this.rdSetObj.rdKey3 = this.rdParamKey3;
    this.rdSetObj.rdValue3 = this.rdParamValue3;
  },
  
  methods: {
    /** 팝업 Show */
    show() {
      this.isOn = true;
    },
    /** 팝업 Hide */
    hide() {
      this.isOn = false;
    },
  }
}
</script>

<style scoped>

</style>