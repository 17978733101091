<template>
  <div id="skip_nav">
    <a href="#gnb" @keydown.enter="focusTopMenu">메뉴 바로가기</a>
    <a href="#contents" @keydown.enter="focusServiceHome">본문 바로가기</a>
  </div>
  <div id="wrap" :class="{ scrUp: isScrolledUp }" ref="wrap">
    <header id="header" ref="header" :class="{ menu_on: mobileMenuActive }">
      <header-top :userinfo="userinfo" v-model:allMenuActive="mobileMenuActive" @logout="logout" @toMyOffice="navigateTo"></header-top>
      <top-menu :groups="groups" @menu-select="navigateTo" ref="topMenu"></top-menu>
      <mobile-menu
          :userinfo="userinfo"
          :groups="groups"
          :menu="selectedMenu"
          v-model:allMenuActive="mobileMenuActive"
          @menu-select="navigateTo"
          @logout="logout"
      ></mobile-menu>
    </header>

    <div id="container" ref="container">
      <template v-if="isCheckedPathWith('/home')">
        <router-view v-slot="{ Component }">
          <component :is="Component" :key="$route.path" />
        </router-view>
      </template>
      <template v-else>
        <div class="inner">
          <left-menu :groups="groups" :menu="selectedMenu" @menu-select="navigateTo"></left-menu>
          <main id="contents">
            <menu-title :groups="groups" :menu="selectedMenu" @home="home"></menu-title>
            <router-view v-slot="{ Component }">
              <component :is="Component" :key="$route.path" />
            </router-view>
          </main>
        </div>
        <quick-menu></quick-menu>
      </template>
    </div>
    <main-footer :userinfo="userinfo"></main-footer>
  </div>
</template>

<script>
import HeaderTop from "@/views/main/components/HeaderTop.vue";
import TopMenu from "@/views/main/components/TopMenu.vue";
import LeftMenu from "@/views/main/components/LeftMenu.vue";
import MenuTitle from "@/views/main/components/MenuTitle.vue";
import QuickMenu from "@/views/main/components/QuickMenu.vue";
import MainFooter from "@/views/main/components/MainFooter.vue";
import { storeSession } from "@/js/store/store-session";
import { storeProhibit } from "@/js/store/store-prohibit";
import MobileMenu from "@/views/main/components/MobileMenu.vue";

const ROUTE = {
  HOME: "/",
  LOGIN: "/user/login",
}

const API = {
  USER_LOGIN: "/auth/access-token",
  LOGOUT: "/auth/remove-token",
}

export default {
  components: {
    MobileMenu,
    QuickMenu,
    MainFooter,
    MenuTitle,
    LeftMenu,
    TopMenu,
    HeaderTop,
  },
  data() {
    return {
      system: process.env.VUE_APP_SYS_CLS_NM,
      groups: [],
      userinfo: storeSession.getMemberInfo(),
      selectedMenu: null,
      mobileMenuActive: false,
      lastScroll: 0,
      isScrolledUp: false,
      isChatBotActive: false,
    };
  },
  watch: {
    '$route'() {
      this.selectedMenu = storeSession.menu;
    }
  },
  created() {
    document.title = this.system ? this.system : "폐자원에너지 종합정보관리시스템";
    this.groups = this.$menusTotal.getAuthMenus();
    this.selectedMenu = this.$menusTotal.getRouteMenu(this.$route.path);
    storeProhibit.setProhibit();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    navigateTo(path) {
      if (path) {
        this.$router.push(path)
          .catch(() => {
            console.error("Navigation Error");
          });
      }
    },
    home() {
      this.navigateTo(ROUTE.HOME);
    },
    async logout() {
      if(confirm("로그아웃 하시겠습니까?")) {
        try {
          await new Promise((resolve, reject) => {
            this.$apiCall.post(API.LOGOUT, {}, (response) => {
              if (response) resolve(response);
              else reject(new Error("Logout API failed"));
            });
          });

          console.log(this.$menusTotal.getAuthMenus());
        } catch (error) {
          console.error("로그아웃 처리 중 오류 발생");
        }

        try {
          storeSession.removeSession(); // 세션 제거
          await this.$router.push(ROUTE.HOME); // HOME으로 이동
          await this.getUnauthenticatedSession(); // 비로그인 세션 처리
          this.groups = this.$menusTotal.getAuthMenus();
        } catch (error) {
          console.error("로그아웃 후속 처리 중 오류 발생");
        }
      }
    },
    handleScroll() {
      const st = window.scrollY || document.documentElement.scrollTop;
      const stopPoint = this.$refs.container.offsetTop - this.$refs.header.offsetHeight;

      if (st > this.lastScroll) {
        // 스크롤 내릴 때
        this.isScrolledUp = false;
      } else {
        // 스크롤 올릴 때
        this.isScrolledUp = st >= stopPoint;
      }
      this.lastScroll = st;
    },
    isCheckedPathWith(path) {
      return this.$route.path === path;
    },
    getUnauthenticatedSession() {
      return new Promise((resolve, reject) => {
        this.$apiCall.write(API.USER_LOGIN, { isGuest: true }, (data) => {
          if (data.result) {
            storeSession.setSession(data.result);
            resolve();
          } else {
            reject(new Error("Failed to UnauthenticatedSession"));
          }
        });
      });
    },
    focusTopMenu() {
      const topMenu = this.$refs.topMenu;  // top-menu 컴포넌트 참조
      const firstMenuItem = topMenu.$el.querySelector('ul li a'); // 첫 번째 메뉴 항목 찾기
      if (firstMenuItem) {
        firstMenuItem.focus();  // 첫 번째 메뉴 항목에 포커스
      }
    },
    focusServiceHome() {
      const serviceHomeElement = document.querySelector(".swiper-wrapper"); 
      if (serviceHomeElement) {
        serviceHomeElement.setAttribute("tabindex", "-1");
        serviceHomeElement.focus(); 
        serviceHomeElement.scrollIntoView({block: "start" });
      }
    },
  },
}

</script>
