<template>
    <div class="member_wrap">
        <div class="tab_conts_wrap">
            <div class="user_info ico_id">
              <span>{{ this.param.userNm }}님의 아이디 내역입니다.
              </span>
            </div>
            <div v-if="storeFindAccountInfo.bzentyYn === 'N'">
              <div class="member_box" v-for="(userInfoList, index) in userInfoList" :key="index">
                <p>담당업무 : {{ userInfoList.authrtNm }}, 아이디 : <strong>{{ userInfoList.userId }}</strong>로 {{ userInfoList.mbrJoinDt }}에 가입하셨습니다.</p>
              </div>
            </div>
            <div v-if="storeFindAccountInfo.bzentyYn === 'Y'">
              <div class="member_box" v-for="(userInfoList, index) in userInfoList" :key="index">
                <p>업체명 : {{ userInfoList.bzentyNm }}, 아이디 : <strong>{{ userInfoList.userId }}</strong>로 {{ userInfoList.mbrJoinDt }}에 가입하셨습니다.</p>
              </div>
            </div>
            <div class="btn_area center">
              <a href="javascript:void(0);" class="btn md primary" @click.prevent="login">로그인하기</a>
              <a href="javascript:void(0);" class="btn md primary" @click.prevent="navigateTo('/user/IdPswdFnd?tab=pswd')">비밀번호 찾기</a>
            </div>
        </div>
    </div>
  </template>
  
  <script>

  import storeAccount from "@/js/store/store-account";

  const ROUTE = {
    LOGIN: "/user/login",
    ID_INFO: "/user/id",
  }
  
  export default {
    data() {
      return {
        userId: '',
        bzentyYn: '',
        param: {
          userNm: '',
        },
        userInfoList: [],
      };
    },
    computed: {
      storeFindAccountInfo() {
        return storeAccount.getters.getFindAccountInfo;
      },
    },
    watch: {},
    created() {
    },
    mounted() {
      this.read();
    },
    methods: {
      login() {
        this.navigateTo(ROUTE.LOGIN);
      },
      navigateTo(path) {
        if (path) {
          this.$router.push(path)
              .catch(() => {
                console.error("Navigation Error");
              });
        }
      },
      read(){
        this.$apiCall.get(ROUTE.ID_INFO, {userId : storeAccount.state.findAccountInfo.userId, bzentyYn : storeAccount.state.findAccountInfo.bzentyYn}, this.success, this.error);
      },
      success(data) {
        if (!data.result) {
          this.error();
          return;
        }

        this.userInfoList = data.result;
        this.param = this.userInfoList[0];
      },
      error() {
        alert("데이터를 가져오는 중 오류가 발생했습니다.");
      },
    }
  }
  </script>
  
  <style scoped>
  
  </style>