<template>
  <div class="page_title_wrap">
    <h2 class="page_title">로그인</h2>
    <p>
      <strong>폐자원에너지 종합정보관리시스템에 오신 것을 환영합니다.</strong>
      로그인을 하시면 보다 더 많은 정보와 서비스를 이용하실 수 있습니다.
    </p>
  </div>

  <div class="login_form_area">
    <div class="login_wrap">
      <h3 class="title">아이디 로그인</h3>
      <div class="login_form">
        <br-form-input label="아이디" v-model="user.username" @keydown.enter.prevent="login" panel="login" required />
        <br-form-input label="비밀번호" type="password" v-model="user.password" @keydown.enter.prevent="login" panel="login" required />
        <br-button label="로그인" size="w100p" format="primary" @click="login" panel="login" validate />
      </div>
      <ul class="link_group">
        <li><a href="javascript:void(0);" @click.prevent="navigateTo('/user/IdPswdFnd?tab=id')">아이디 찾기</a></li>
        <li><a href="javascript:void(0);" @click.prevent="navigateTo('/user/IdPswdFnd?tab=pswd')">비밀번호 찾기</a></li>
        <li><a href="javascript:void(0);" @click.prevent="navigateTo('/user/MbrJoin')">회원가입</a></li>
      </ul>
    </div>
    <div class="login_info_wrap">
      <h3 class="title">간편인증 로그인</h3>
      <p>다양한 전자서명을 이용하여 로그인하실 수 있습니다.<br>인증기관에서 발급받은 인증서가 있어야 이용이 가능합니다.</p>
      <div class="login_method">
        <a @click="simpleAuthOpen"><img src="@/assets/images/sub/ico_login_kakaopay.png" alt="카카오페이로 로그인"></a>
        <a @click="simpleAuthOpen"><img src="@/assets/images/sub/ico_login_naverpay.png" alt="네이버페이로 로그인"></a>
        <a @click="simpleAuthOpen"><img src="@/assets/images/sub/ico_login_pass.png" alt="패스로 로그인"></a>
        <a @click="simpleAuthOpen"><img src="@/assets/images/sub/ico_login_toss.png" alt="토스로 로그인"></a>
        <a @click="simpleAuthOpen"><img src="@/assets/images/sub/ico_login_payco.png" alt="페이코로 로그인"></a>
        <a @click="simpleAuthOpen"><img src="@/assets/images/sub/ico_login_kbbank.png" alt="kb국민은행로 로그인"></a>
      </div>
    </div>
  </div>

  <div class="info_box mt_40">
    <strong class="info_title">로그인에 어려움이 있으신가요?</strong>
    <ul class="dot_list">
      <li>로그인 관련 도움말이나 다른 사용자가 자주 찾는 질문을 확인해보세요.</li>
      <!--      <li>1588-3323으로 전화주세요. 서비스에 로그인할 수 있도록 도와드리겠습니다.</li>-->
    </ul>
  </div>

  <div v-show="isOn" class="modal visible">
    <div class="popup_box" style="width: 80%; max-width: inherit">
      <h1 class="popup_title">로그인 업체 선택</h1>
      <div>
        <button type="button" class="modal_close" @click.prevent="hide">팝업닫기</button>
      </div>
      <div class="popup_cont" style="height: 88%;">
        <!-- 화면 타이틀 영역 -->
        <div class="board_info">
          <h2 class="title1">로그인 업체 목록</h2>
        </div>
        <div class="board_list">
          <table style="table-layout: fixed;">
            <caption></caption>
            <colgroup>
              <col style="width:22rem">
              <col style="width:22rem">
            </colgroup>
            <thead>
            <tr>
              <th scope="col">업체아이디</th>
              <th scope="col">업체명</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item) in bzentyUsers" @click="selectBzentyUser(item)" :key="item.userId">
              <td aria-label="업체아이디">{{ item.bzentyId }}</td>
              <td aria-label="업체명">{{ item.bzentyNm }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- 간편인증 -->
  <simple-auth-modal :isPopup="false" @callback="simpleAuthCallback" @fail="simpleAuthFail"/>
  <identity-auth-popup />
</template>

<script>
import SimpleAuthModal from "@/components/SimpleAuthModal.vue";
import IdentityAuthModal from "@/components/IdentityAuthModal.vue";
import IdentityAuthPopup from "@/components/IdentityAuthPopup.vue";
import {storeSession} from "@/js/store/store-session";
import {storeSwitch} from "@/js/store/store-switch";

const ROUTE = {
  MAIN: "/",
  EXPIRED: "/user/ExpryPswd"
};

const API = {
  USER_LOGIN: "/auth/access-token",
  FORCE_LOGIN: "/auth/force/access-token",
  PROVIDER_USER_LOGIN: "/auth/provider/access-token",
  PROVIDER_FORCE_LOGIN: "/auth/provider/force/access-token",
};

export default {
  components: {
    IdentityAuthPopup,
    SimpleAuthModal,
    IdentityAuthModal,
  },
  data() {
    return {
      isOn: false,
      user: {
        username: "",
        password: "",
      },
      auth: {
        provider: "",
        bzentyUserId: ""
      },
      bzentyUsers: []
    };
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
    if (!sessionStorage.getItem("loginPageLoaded")) {
      this.forceReload();
      sessionStorage.setItem("loginPageLoaded", "true");
    }
  },
  methods: {
    navigateTo(path) {
      if (path) {
        this.$router.push(path)
            .catch(() => {
              console.error("Navigation Error");
            });
      }
    },
    login() {
      const encryptedUsername = this.user.username;
      const encryptedPassword = this.user.password;

      const param = {
        username: encryptedUsername,
        password: encryptedPassword,
      };
      this.$apiCall.write(API.USER_LOGIN, param, this.gotoMain, this.showAlert);
    },
    gotoMain(data){
      storeSession.setSession(data.result);

      if (data.result.expired || data.result.scrtyPlcyYn == "N") {
        this.$router.push(ROUTE.EXPIRED).catch(() => {});
        return;
      }

      if (data.result.isIdentityAuthPopupVisible) {
        storeSwitch.on('IdentityAuthPopup');
        return;
      }

      this.$router.push(ROUTE.MAIN).catch(() => {
      });
    },
    showAlert(error) {
      if (error.code === 2001) {
        const encryptedUsername = this.user.username;
        const encryptedPassword = this.user.password;

        const param = {
          username: encryptedUsername,
          password: encryptedPassword,
        };

        if (confirm("이미 로그인 된 세션이 있습니다.\r\n정말 로그인 하시겠습니까?")) {
          this.$apiCall.write(API.FORCE_LOGIN, param, this.gotoMain, this.showAlert);
        }
      } else {
        alert(error.message);
      }
    },
    simpleAuthOpen() {
      storeSwitch.on('SimpleAuthModal');
    },
    simpleAuthCallback(result) {
      console.log("simpleAuthCallback", result);

      if (!result.isUser) {
        alert('간편인증정보와 일치하는 사용자가 없습니다');
        return;
      }

      if (result.isDuplicate) {
        alert('관리자 계정이 존재합니다. 아이디 로그인을 이용해주세요');
        return;
      }

      this.auth.provider = result.provider;

      if (result.bzentyUsers) {
        this.bzentyUsers = result.bzentyUsers;
        this.show();
        return;
      }

      this.simpleAuthLogin();
    },
    simpleAuthLogin() {
      this.$apiCall.write(API.PROVIDER_USER_LOGIN, this.auth, this.gotoMain, this.simpleAuthAlert);
    },
    simpleAuthAlert(error) {
      if (error.code === 2001) {

        if (confirm("이미 로그인 된 세션이 있습니다.\r\n정말 로그인 하시겠습니까?")) {
          this.$apiCall.write(API.PROVIDER_FORCE_LOGIN, this.auth, this.gotoMain, this.simpleAuthAlert);
        }
      } else {
        alert(error.message);
      }
    },
    simpleAuthFail(error) {
      console.log("simpleAuthFail", error);
    },
    show() {
      this.isOn = true;
    },
    hide() {
      this.isOn = false;
    },
    selectBzentyUser(item) {
      this.auth.bzentyUserId = item.userId;

      this.simpleAuthLogin();
    },
    forceReload() {
      window.location.replace(window.location.href);
    },
  }
}
</script>

<style scoped>

</style>