<template>
  <div id="quick_menu" ref="quickMenu" :class="{ fixed: isFixed }">
    <h2 @click="changeFlag" :class="{'open_menu': this.flag, 'close_menu': !this.flag}">빠른메뉴</h2>
    <ul v-if="flag">
      <li v-for="(item, index) in menuItems" :key="index"
          @mouseover="addActiveClass(index)"
          @mouseleave="removeActiveClass(index)"
          :class="{ active: activeIndex === index }"
      >
        <a :href="item.url"><i :class="item.icon"></i>{{ item.title }}</a>
        <div class="tooltip">{{ item.tooltip }}</div>
      </li>
      <li class="cs_area">
        <a href="javascript:void(0);" @click.prevent="toggleCsLayer"><i class="icon4"></i>제도/시스템<br>문의</a>
        <div class="cs_layer" v-if="isCsLayerVisible">
          <h3>제도/시스템 문의</h3>
          <dl>
            <dt><span>시설검사</span></dt>
            <dd>032-590-5427</dd>
          </dl>
          <dl>
            <dt><span>품질검사</span></dt>
            <dd>032-590-5411</dd>
          </dl>
          <dl>
            <dt><span>품질(확인)검사</span></dt>
            <dd><span>인천, 경기, 강원</span>031-590-0666</dd>
            <dd><span>부산, 대구, 울산, 경상</span>051-366-3781</dd>
            <dd><span>대전, 충청, 전라, 제주</span>063-279-0840</dd>
          </dl>
          <dl>
            <dt><span>시스템</span></dt>
            <dd>1588-6446</dd>
          </dl>
          <button type="button" class="btn_close" @click="toggleCsLayer">닫기</button>
        </div>
      </li>
    </ul>
    <a v-if="flag" class="chatbot" @mouseover="chatbotHover(true)" @mouseleave="chatbotHover(false)" @click="chatBot">
      <span>{{ chatbotText }}</span>
    </a>
    <a v-if="flag" href="javascript:void(0);" class="btn_top" title="상단으로 이동하기" @click.prevent="scrollToTop">TOP</a>
  </div>
</template>

<script>

import {storeSwitch} from "@/js/store/store-switch";

export default {
  data() {
    return {
      isFixed: false,
      activeIndex: null,
      isCsLayerVisible: false,
      chatbotText: 'e 챗봇',
      menuItems: [
        { title: '시설검사', icon: 'icon1', tooltip: '폐자원에너지 재생, 생산에 필요한 시설 여부 검사', url: "/sfli/FcltInspAply"},
        { title: '품질검사', icon: 'icon2', tooltip: '폐자원으로 생산한 고형연료 품질 여부 검사', url: "/sfli/QltyInspAply" },
        { title: '수입품질 검사', icon: 'icon3', tooltip: '수입한 고형연료의 품질 여부 검사', url: "/sfli/IncmQltyInspAply" }
      ],
      flag: false,
    };
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      this.isFixed = scrollTop > 164;
    },
    addActiveClass(index) {
      this.activeIndex = index;
    },
    removeActiveClass() {
      this.activeIndex = null;
    },
    toggleCsLayer() {
      this.isCsLayerVisible = !this.isCsLayerVisible;
    },
    chatbotHover(isHovered) {
      this.chatbotText = isHovered ? '도움이 필요하실까요?' : 'e 챗봇';
    },
    scrollToTop() {
      const target = document.querySelector('#wrap');
      if (target) {
        target.scrollIntoView({ behavior: 'smooth' });
        target.focus();
      }
    },
    chatBot() {
      storeSwitch.on('chatBot');
    },
    changeFlag() {
      this.flag = !this.flag;
    }
  }
}
</script>

<style scoped>
.open_menu::after {
  transform: rotate(180deg);
}

.close_menu::after {
  transform: rotate(0deg);
}
</style>