<template>
  <!-- s: 콘텐츠 영역 -->
  <div class="board_info">
    <h2 class="title1">수입품질검사 신청</h2>
    <p class="t_caption"><span class="necessary">(<i>※</i>)</span> 필수 입력항목입니다.</p>
  </div>
  <div class="board_write">
      <table>
          <caption>
              시설번호, 상호, 시설종류, 제조업체(영문), 제조국(수입국), 도시명, 제조시설(주소), 보관시설(주소), 제품종류, 실사여부, 입금(예정)일자,
              검사희망일자, HSK.NO, 제조가능물량(톤), 수입가능물량(톤), 신청물량(톤), 검사물량(톤), 품질표시검사여부, 품질표시검사항목, 파일로 구성
          </caption>
          <colgroup>
              <col style="width:18%">
              <col style="width:32%">
              <col style="width:18%">
              <col style="width:32%">
          </colgroup>
          <tbody>
              <tr>
                  <th scope="row">사업장번호</th>
                  <td>{{this.qltyInspFcltInfo.bplcId}}</td>
                  <th scope="row">상호</th>
                  <td>{{this.qltyInspFcltInfo.bplcNm}}</td>
              </tr>
              <tr>
                  <th scope="row">시설종류</th>
                  <td colspan="3">수입시설</td>
              </tr>
              <tr>
                  <th scope="row">제조업체(영문)</th>
                  <td colspan="3">
                      <div class="form_group_addr">
                          <br-form-input class="form_control" label="제조업체(영문)" placeholder="제조업체(영문)" readonly v-model="mnftrFcltInfo.bzentyNm" />
                          <br-button label="제조시설 찾기" class="btn sm ico_search" @click="callPopupIncmFclt" />
                          <br-anchor-button role="link" label="제조시설 추가" size="sm" format="primary line" icon="ico_plus" @click="findMnftrFclt" />
                          <!-- <button type="button" class="btn sm primary line ico_plus txtN">추가</button> -->
                      </div>
                  </td>
              </tr>
              <tr>
                  <th scope="row">제조국(수입국)</th>
                  <td>
                    <br-form-input class="form_control" label="제조국(수입국)" placeholder="제조국(수입국)" readonly v-model="mnftrFcltInfo.mnftrNtnNm" />
                  </td>
                  <th scope="row">도시명</th>
                  <td>
                    <br-form-input class="form_control" label="도시명" placeholder="도시명" readonly v-model="mnftrFcltInfo.ctyNm" />
                  </td>

              </tr>
              <tr>
                  <th scope="row">제조시설(주소)</th>
                  <td colspan="3">
                      <br-form-input class="form_control" label="제조시설(주소)" placeholder="제조시설(주소)" readonly v-model="mnftrFcltInfo.fcltAddr" />
                  </td>
              </tr>
              <tr>
                  <th scope="row">보관시설(주소)</th>
                  <td colspan="3">
                    <br-form-input class="form_control" label="보관시설(주소)" placeholder="보관시설(주소)" readonly v-model="mnftrFcltInfo.prdctCstdyPlcCn" />
                  </td>
              </tr>
              <tr>
                  <th scope="row">제품종류
                  </th>
                  <td colspan="3">
                      <div class="chk_area">
                        <div class="form_check">
                            <input type="checkbox" name="" id="chk01" value="0001" disabled v-model="srfItemValues">
                            <label for="chk01">SRF 성형</label>
                        </div>
                        <div class="form_check">
                          <input type="checkbox" name="" id="chk02" value="0002" disabled v-model="srfItemValues">
                          <label for="chk02">SRF 비성형</label>
                        </div>
                        <div class="form_check">
                            <input type="checkbox" name="" id="chk03" value="0003" disabled v-model="srfItemValues">
                            <label for="chk03">BIO-SRF 성형</label>
                        </div>
                        <div class="form_check">
                          <input type="checkbox" name="" id="chk04" value="0004" disabled v-model="srfItemValues">
                          <label for="chk04">BIO-SRF 비성형</label>
                        </div>
                      </div>
                  </td>
              </tr>
              <tr>
                  <th scope="row">실사여부</th>
                  <td colspan="3">
                      <div class="chk_area">
                        <div class="form_check">
                              <br-form-radio label="실사여부" 
                                              :options="this.acinspYnOpt"
                                              v-model:value="this.qltyInspAply.incmInspAcinspYn"
                              />
                        </div>
                      </div>
                  </td>
              </tr>
              <tr>
                  <th scope="row">입금(예정)일자</th>
                  <td>
                    <br-date-picker label="입금(예정)일자" v-model:value="this.qltyInspAply.dpstPrnmntYmd" />
                  </td>
                  <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>검사희망일자
                  </th>
                  <td>
                    <br-date-picker label="검사희망일자" futureonly :futureDaysOffset="30" v-model:value="this.qltyInspAply.inspHopeYmd" panel="panelInspAply" required/>
                    <span class="txt_hint">30일 이후로 입력</span>
                  </td>
              </tr>
              <tr>
                  <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>HSK.NO</th>
                  <td colspan="3">
                      <br-form-select label="HSK.NO" :customStyle="{'max-width':'none'}" :options="hskCdList" v-model:value="qltyInspAply.hskCd" panel="panelInspAply" required/>
                  </td>
              </tr>
              <tr>
                  <th scope="row">제조가능물량(톤)</th>
                  <td>
                      <br-form-input label="제조가능물량(톤)" v-model="mnftrFcltInfo.mnfqty" align="right" v-bind:disabled="isMnfQtyDisabled" validator="number" />
                  </td>
                  <th scope="row">수입가능물량(톤)</th>
                  <td>
                      <br-form-input label="수입가능물량(톤)" v-model="qltyInspAply.incmPmtqty" align="right" v-bind:disabled="isIncmPmtqtyDisabled" validator="number" />
                  </td>
              </tr>
              <tr>
                  <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>신청물량(톤)</th>
                  <td>
                      <br-form-input label="신청물량(톤)" v-model="qltyInspAply.incmAplqty" align="right" panel="panelInspAply" validator="number" required/>
                  </td>
                  <th scope="row">검사물량(톤)</th>
                  <td>
                      <br-form-input label="검사물량(톤)" v-model="qltyInspAply.incmInspqty" align="right" v-bind:disabled="isIncmInspqtyDisabled" validator="number" />
                  </td>
              </tr>
              <tr>
                  <th scope="row">품질검사항목</th>
                  <td colspan="3">
                      <div class="chk_area">
                          <div class="form_check">
                            <br-form-radio label="품질검사항목" 
                                            :options="this.qltyInspArtclOpt"
                                            v-model:value="this.qltyInspAply.qltyInspArtclCd"
                                            :disabled="true"
                            />
                          </div>
                      </div>
                  </td>
              </tr>
              <tr>
                  <th scope="row">품질표시검사여부</th>
                  <td colspan="3">
                      <div class="chk_area">
                          <div class="form_check">
                            <br-form-radio label="품질표시검사여부" 
                                            :options="this.qltyIndctInspYnOpt"
                                            v-model:value="this.qltyInspAply.qltyIndctInspYn"
                                            :disabled="this.qltyIndctInspYnDisabled"
                            />
                          </div>
                      </div>
                  </td>
              </tr>
              <tr>
                  <th scope="row"><i class="necessary">필수입력</i>검사항목</th>
                  <td colspan="3">
                    <!-- 퍼블 디자인을 유지하기 위해 체크박스 컴포넌트 사용하지 않음 -->
                    <div class="form_check">
                        <input type="checkbox" name="inspCpstCdAll" id="inspCpstCdAll" value="chkAll" v-model="allSelected">
                        <label for="inspCpstCdAll">전체</label>
                    </div>
                    <table>
                      <caption>검사항목</caption>
                      <colgroup>
                        <col style="width:9%">
                        <col style="width:91%">
                      </colgroup>
                      <tbody>
                        <tr>
                          <th scope="row">품질검사</th>
                          <td>
                            <div class="chk_area">
                              <div class="form_check">
                                  <input type="checkbox" name="inspCpstCd150" id="inspCpstCd150" value="150" v-model="this.qltyInspAply.inspCpstCdList">
                                  <label for="inspCpstCd150">모양 및 크기</label>
                              </div>
                              <div class="form_check">
                                  <input type="checkbox" name="inspCpstCd160" id="inspCpstCd160" value="160" v-model="this.qltyInspAply.inspCpstCdList">
                                  <label for="inspCpstCd160">수분함유량(wt.%)</label>
                              </div>
                              <div class="form_check">
                                  <input type="checkbox" name="inspCpstCd180" id="inspCpstCd180" value="180" v-model="this.qltyInspAply.inspCpstCdList">
                                  <label for="inspCpstCd180">(저위)발열량(kcal/kg)</label>
                              </div>
                              <div class="form_check">
                                  <input type="checkbox" name="inspCpstCd170" id="inspCpstCd170" value="170" v-model="this.qltyInspAply.inspCpstCdList">
                                  <label for="inspCpstCd170">회분 함유량(wt.%)</label>
                              </div>
                              <div class="form_check">
                                  <input type="checkbox" name="inspCpstCd140" id="inspCpstCd140" value="140" v-model="this.qltyInspAply.inspCpstCdList">
                                  <label for="inspCpstCd140">염소 함유량(wt.%)</label>
                              </div>
                              <div class="form_check">
                                  <input type="checkbox" name="inspCpstCd130" id="inspCpstCd130" value="130" v-model="this.qltyInspAply.inspCpstCdList">
                                  <label for="inspCpstCd130">항분 함유량(wt.%)</label>
                              </div>
                              <div class="form_check">
                                  <input type="checkbox" name="inspCpstCd190" id="inspCpstCd190" value="190" v-model="this.qltyInspAply.inspCpstCdList" :disabled="inspCpstBioMassDisabled">
                                  <label for="inspCpstCd190">바이오매스(wt.%)</label>
                              </div>
                              <div class="form_check">
                                  <input type="checkbox" name="inspCpstCd80" id="inspCpstCd80" value="80" v-model="this.qltyInspAply.inspCpstCdList">
                                  <label for="inspCpstCd80">수은(Hg)</label>
                              </div>
                              <div class="form_check">
                                  <input type="checkbox" name="inspCpstCd90" id="inspCpstCd90" value="90" v-model="this.qltyInspAply.inspCpstCdList">
                                  <label for="inspCpstCd90">카드뮴(Cd)</label>
                              </div>
                              <div class="form_check">
                                  <input type="checkbox" name="inspCpstCd100" id="inspCpstCd100" value="100" v-model="this.qltyInspAply.inspCpstCdList">
                                  <label for="inspCpstCd100">납(Pb)</label>
                              </div>
                              <div class="form_check">
                                  <input type="checkbox" name="inspCpstCd110" id="inspCpstCd110" value="110" v-model="this.qltyInspAply.inspCpstCdList">
                                  <label for="inspCpstCd110">비소(As)</label>
                              </div>
                              <div class="form_check">
                                  <input type="checkbox" name="inspCpstCd200" id="inspCpstCd200" value="200" v-model="this.qltyInspAply.inspCpstCdList">
                                  <label for="inspCpstCd200">기타항목</label>
                                  <input type="text" name="etcArtclNm" id="etcArtclNm" maxlength="50" v-model="this.qltyInspAply.etcArtclNm" />
                                  <label for="etcArtclNm" style="display:none;">기타항목입력</label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">품질표시검사</th>
                          <td>
                            ※ 바이오 고형연료제품에 대한 품질검사인 경우 크로뮴(Cr)은 품질검사 항목에 해당될 수 있습니다.
                            <div class="chk_area">
                              <div class="form_check">
                                  <input type="checkbox" name="inspCpstCd120" id="inspCpstCd120" value="120" v-model="this.qltyInspAply.inspCpstCdList">
                                  <label for="inspCpstCd120">크로뮴(Cr)</label>
                              </div>
                              <div class="form_check">
                                  <input type="checkbox" name="inspCpstCd10" id="inspCpstCd10" value="10" v-model="this.qltyInspAply.inspCpstCdList">
                                  <label for="inspCpstCd10">안티몬(Sb)</label>
                              </div>
                              <div class="form_check">
                                  <input type="checkbox" name="inspCpstCd20" id="inspCpstCd20" value="20" v-model="this.qltyInspAply.inspCpstCdList">
                                  <label for="inspCpstCd20">코발트</label>
                              </div>
                              <div class="form_check">
                                  <input type="checkbox" name="inspCpstCd40" id="inspCpstCd40" value="40" v-model="this.qltyInspAply.inspCpstCdList">
                                  <label for="inspCpstCd40">망간(Mn)</label>
                              </div>
                              <div class="form_check">
                                  <input type="checkbox" name="inspCpstCd50" id="inspCpstCd50" value="50" v-model="this.qltyInspAply.inspCpstCdList">
                                  <label for="inspCpstCd50">니켈(Ni)</label>
                              </div>
                              <div class="form_check">
                                  <input type="checkbox" name="inspCpstCd60" id="inspCpstCd60" value="60" v-model="this.qltyInspAply.inspCpstCdList">
                                  <label for="inspCpstCd60">탈륨(Tl)</label>
                              </div>
                              <div class="form_check">
                                  <input type="checkbox" name="inspCpstCd70" id="inspCpstCd70" value="70" v-model="this.qltyInspAply.inspCpstCdList">
                                  <label for="inspCpstCd70">바나듐(V)</label>
                              </div>
                              <div class="form_check">
                                  <input type="checkbox" name="inspCpstCd30" id="inspCpstCd30" value="30" v-model="this.qltyInspAply.inspCpstCdList">
                                  <label for="inspCpstCd30">구리</label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
              </tr>
              <tr>
                  <th scope="row">파일</th>
                  <td colspan="3">
                      <div class="file_upload_list">
                        <template v-if="isAtchFileShow">
                            <br-attach-file-list 
                                  label="사업자등록증"
                                  ref="attachFileList"
                                  atchFileTaskSeCd="BET"
                                  v-model:atchFileGroupId="this.qltyInspFcltInfo.bzentyAtchFileGroupId"
                                  :useEtcFile="false"
                                  :maxFileCount="1"
                                  :readonly="true"
                                  :unusedAtchFileList= "[]"
                            />
                            <br-attach-file-list 
                                  label="통장사본"
                                  ref="attachFileList"
                                  atchFileTaskSeCd="BPCD"
                                  v-model:atchFileGroupId="this.qltyInspFcltInfo.bplcAtchFileGroupId"
                                  :useEtcFile="false"
                                  :maxFileCount="1"
                                  :readonly="true"
                                  :unusedAtchFileList= "['001','002', '007', '008', '049', '053', '054']"
                            />
                            <dl class="list">
                                <dt><i class="necessary">※<span class="sr_only">필수입력</span></i>고형연료제품 품질검사 신청서</dt>
                                <dd>
                                    <div class="file_box">
                                      <br-attach-file label="고형연료제품 품질검사 신청서"
                                                    atchFileTaskSeCd="IQT"
                                                    v-model:atchFileGroupId="this.qltyInspAply.inspAplyAtchFileGroupId"
                                                    v-model:fileProcessingData="this.fileProcessingData"
                                                    atchFileSeCd="015"
                                                    panel="panelInspAply"
                                                    required
                                                    />
                                    </div>
                                </dd>
                            </dl>
                            <dl class="list">
                                <dt><i class="necessary">※<span class="sr_only">필수입력</span></i>고형연료제품 수입ㆍ운반ㆍ보관ㆍ처리계획서</dt>
                                <dd>
                                  <!-- <div class="file_box"> -->
                                    <br-attach-file label="고형연료제품 수입ㆍ운반ㆍ보관ㆍ처리계획서"
                                                  atchFileTaskSeCd="IQT"
                                                  v-model:atchFileGroupId="this.qltyInspAply.inspAplyAtchFileGroupId"
                                                  v-model:fileProcessingData="this.fileProcessingData"
                                                  atchFileSeCd="017"
                                                  panel="panelInspAply"
                                                  required
                                                  />
                                  <!-- </div> -->
                                </dd>
                            </dl>
                            <dl class="list">
                                <dt><i class="necessary">※<span class="sr_only">필수입력</span></i>현지검사 참고자료(엑셀)</dt>
                                <dd>
                                  <!-- <div class="file_box"> -->
                                    <br-attach-file label="현지검사 참고자료(엑셀)"
                                                  atchFileTaskSeCd="IQT"
                                                  v-model:atchFileGroupId="this.qltyInspAply.inspAplyAtchFileGroupId"
                                                  v-model:fileProcessingData="this.fileProcessingData"
                                                  atchFileSeCd="018"
                                                  panel="panelInspAply"
                                                  required
                                                  />
                                  <!-- </div> -->
                                </dd>
                            </dl>
                            <dl class="list">
                                <dt><i class="necessary">※<span class="sr_only">필수입력</span></i>수입업체↔제조시설간 공급계약서(영문본 포함)</dt>
                                <dd>
                                  <!-- <div class="file_box"> -->
                                    <br-attach-file label="수입업체↔제조시설간 공급계약서(영문본 포함)"
                                                  atchFileTaskSeCd="IQT"
                                                  v-model:atchFileGroupId="this.qltyInspAply.inspAplyAtchFileGroupId"
                                                  v-model:fileProcessingData="this.fileProcessingData"
                                                  atchFileSeCd="019"
                                                  panel="panelInspAply"
                                                  required
                                                  />
                                  <!-- </div> -->
                                </dd>
                            </dl>
                            <dl class="list">
                                <dt><i class="necessary">※<span class="sr_only">필수입력</span></i>현지 제조시설 국제사업자등록증(영문본 포함)</dt>
                                <dd>
                                  <!-- <div class="file_box"> -->
                                    <br-attach-file label="현지 제조시설 국제사업자등록증(영문본 포함)"
                                                  atchFileTaskSeCd="IQT"
                                                  v-model:atchFileGroupId="this.qltyInspAply.inspAplyAtchFileGroupId"
                                                  v-model:fileProcessingData="this.fileProcessingData"
                                                  atchFileSeCd="020"
                                                  panel="panelInspAply"
                                                  required
                                                  />
                                  <!-- </div> -->
                                </dd>
                            </dl>
                        </template>
                      </div>
                  </td>
              </tr>
          </tbody>
      </table>
  </div>
  <div class="btn_area right">
      <br-button label="신청"
                  :class="'btn md primary line'"
                  @click="save('10')" 
                  panel="panelInspAply"
                  validate 
        />
        <br-button label="임시 저장"
                  :class="'btn md primary line'"
                  @click="save('00')" 
                  panel="panelInspAply"
                  validate 
        />
      <a role="link" href="javascript:void(0);" class="btn md point" @click="cancel">취소</a>
  </div>
  <!-- e: 콘텐츠 영역 -->
  <ovrs-incm-mnftr-fclt-popup ref="ovrsIncmMnftrFcltPopup" @select-button-clicked="setOvrsIncmMnftrFcltInfo" :bplcId="this.parentData.bplcId"></ovrs-incm-mnftr-fclt-popup>
  <ovrs-incm-cnpt-mng-popup @select-button-clicked="onSelectedOvrsIncmCnptMng" :bplcId="this.parentData.bplcId"/>
</template>

<script>
// import { storeSnackbar } from "@/js/store/store-snackbar";
import { storeSwitch } from "@/js/store/store-switch";
// import { storeComponent } from "@/js/store/store-component";
import OvrsIncmMnftrFcltPopup from "@/views/sfli/components/popup/OvrsIncmMnftrFcltPopup.vue";
import OvrsIncmCnptMngPopup from "@/views/mbr-mng/components/popup/OvrsIncmCnptMngPopup.vue";

const API = {
    INCM_QLTY_INSP_APLY_FORM: "/incmQltyInspAply/form",
    INCM_QLTY_INSP_APLY: "/incmQltyInspAply/aply",
}

const CODE = {
  HSK_CD: "COM417", // HSK코드
}

export default {
  components: {OvrsIncmMnftrFcltPopup, OvrsIncmCnptMngPopup},
  emits: ['incm-qlty-insp-aply-list'],
  expose: ['reset'],
  props: {
    parentData: {
        type: Object,
        required: true,
    }
  },
  data() {
    return {
    //   menu: this.$menusTotal.getRouteMenu(this.$route.path),
      qltyInspArtclOpt: [
        {value: "01-00-00", text: "전체"},
        {value: "02-00-00", text: "수분항목만"},
      ],
      acinspYnOpt: [
        {value: "Y", text: "Y"},
        {value: "N", text: "N"},
      ],
      qltyIndctInspYnOpt: [
        {value: "Y", text: "신청"},
        {value: "N", text: "미신청"},
      ],
      qltyIndctInspYnDisabled: false,
      inspCpstOtions: [
        {value: "10", text: "안티몬(Sb)"},
        {value: "20", text: "코발트(Co)"},
        {value: "30", text: "구리(Cu)"},
        {value: "40", text: "망간(Mn)"},
        {value: "50", text: "니켈(Ni)"},
        {value: "60", text: "탈륨(Tl)"},
        {value: "70", text: "바나듐(V)"},
        {value: "80", text: "수은(Hg)"},
        {value: "90", text: "카드뮴(Cd)"},
        {value: "100", text: "납(Pb)"},
        {value: "110", text: "비소(As)"},
        {value: "120", text: "크로뮴(Cr)"},
        {value: "130", text: "황분 함유량(wt.%)"},
        {value: "140", text: "염소 함유량(wt.%)"},
        {value: "150", text: "모양 및 크기"},
        {value: "160", text: "수분 함유량(wt.%)"},
        {value: "170", text: "회분 함유량(wt.%)"},
        {value: "180", text: "(저위)발열량(kcal/kg)"},
        {value: "190", text: "바이오매스(wt.%)"},
        {value: "200", text: "기타 항목"},
      ],
      inspCpstDisabled: false,
      isRequiredForEtcArtclNm: false,
      isCheckedDpstYn: false,
      isMnfQtyDisabled: true,       //제조가능물량
      isIncmPmtqtyDisabled: true,   //수입가능물량
      isIncmInspqtyDisabled: true,  //검사물량
      qltyInspAply: {
          bplcId: "",
          fcltSqno: "",
          fcltInspAplySn: "",
          qltyInspArtclCd: "01-00-00",
          inspClsfCd: "30",
          inspSeCd: "30-10",
          qltyIndctInspYn: "Y",
          inspCpstCdList: [],
          etcArtclNm: "",
          dpstPrnmntYmd: "",
          pyrNm: "",
          dpstYn: "N",
          inspHopeYmd: "",
          qltyInspItemCd: "",
          inspAplyAtchFileGroupId: "",
          useYn: "Y",
          incmInspAcinspYn: "",   /* 수입검사실사여부 */
          hskCd: "",
          incmInspqty: "0",  /* 수입검사량 = 검사물량(톤) */
          incmPmtqty: "0",   /* 수입허용량 = 수입가능물량(톤) */
          incmAplqty: "0",   /* 수입신청량 = 신청물량(톤) */
          incmFcltSn: "",   /* 수입시설일련번호 */
      },
      qltyInspFcltInfo: {
          bplcId: "",
          bplcKndCd: "",
          bplcKndNm: "",
          bplcNm: "",
          pubBzentyYn: "",
          bzentyId: "",
          bzentyNm: "",
          cnsgnBzentyNm: "",
          bzentyAtchFileGroupId: "",
          bplcAtchFileGroupId: "",
          inspPrgrsSttsCd: "",
      },
      mnftrFcltInfo: {
          bplcId: "",
          incmFcltSn: "",
          bzentyNm: "",
          mnftrNtnCd: "",
          ovrsBzmnIdntfNo: "",
          mnftrNtnNm: "",
          ctyNm: "",
          fcltAddr: "",
          prdctCstdyPlcCn: "",
          srfPtYn: "",
          srfNptYn: "",
          bioSrfPtYn: "",
          bioSrfNptYn: "",
          mnfqty: "",
      },
      srfItemValues: [],
      fileProcessingData: {},
      files: [],
      hskCdList: [],
      isAtchFileShow: true,
      inspCpstBioMassDisabled: false,
    };
  },
  computed: {
    allSelected: {
      //getter
      get: function() {
        // return this.inspCpstOtions.length === this.qltyInspAply.inspCpstCdList.length;

        //고형연료 품질검사인경우 제외되는 성분
        const excludeForSrf = ["10", "20", "30", "40", "50", "60", "70", "120", "190"];
        //고형연료 품질표시검사
        const excludeForSrfAndQltyIndct = ["190"];
        //바이오고형연료
        const excludeForBioSrf = ["10", "20", "30", "40", "50", "60", "70"];
        //고형연료 코드
        const srfCdList = ["0001", "0002"];
        //바이오고형연료 코드
        const bioSrfCdList = ["0003", "0004"];

        const prdctnPrdctKndCd = this.qltyInspAply.qltyInspItemCd;

        let inspCpstOptionsLength = this.inspCpstOtions.length;
        let inspCpstCdListLength = 0;
        
        if(this.qltyInspAply.qltyIndctInspYn == 'Y'){ //품질표시검사 여부
          if(srfCdList.includes(prdctnPrdctKndCd)){ //고형연료
            inspCpstOptionsLength = this.inspCpstOtions.filter(item => !excludeForSrfAndQltyIndct.includes(item.value)).length;
          }else if(bioSrfCdList.includes(prdctnPrdctKndCd)){  //바이오고형연료
            inspCpstOptionsLength = this.inspCpstOtions.length;
          }
        }else{
          if(srfCdList.includes(prdctnPrdctKndCd)){ //고형연료
            inspCpstOptionsLength = this.inspCpstOtions.filter(item => !excludeForSrf.includes(item.value)).length;
          }else if(bioSrfCdList.includes(prdctnPrdctKndCd)){  //바이오고형연료
            inspCpstOptionsLength = this.inspCpstOtions.filter(item => !excludeForBioSrf.includes(item.value)).length;
          }
        }

        if(this.qltyInspAply.inspCpstCdList != null && this.qltyInspAply.inspCpstCdList != undefined){
          inspCpstCdListLength = this.qltyInspAply.inspCpstCdList.length;
        }

        return inspCpstOptionsLength === inspCpstCdListLength;


      },
      //setter
      set: function(e) {
        // this.qltyInspAply.inspCpstCdList = e ? this.inspCpstOtions.map(item => item.value) : [];
        if(e){
          //검사항목 자동 세팅
          this.setInspCpstCd();
          this.qltyInspAply.inspCpstCdList.push("200");
        }else{
          this.qltyInspAply.inspCpstCdList = [];
        }
      },
    },
    
  },
  watch: {
    //품질검사항목
    "qltyInspAply.qltyInspArtclCd": function(val){
        this.qltyInspAply.qltyIndctInspYn = (val === "02-00-00") ? "N" : "Y";
        this.qltyIndctInspYnDisabled = (val === "02-00-00") ? true : false;
    },
    //품질표시검사여부
    "qltyInspAply.qltyIndctInspYn": function(val){
      this.inspCpstDisabled = (val === "N") ? true : false;
      this.qltyInspAply.inspCpstCdList = [];
      this.qltyInspAply.etcArtclNm = "";

      //검사항목 자동 세팅
      this.setInspCpstCd();
    },
    "qltyInspAply.inspCpstCdList": function(){
      this.isRequiredForEtcArtclNm = this.qltyInspAply.inspCpstCdList.includes("200");
    },
    isCheckedDpstYn: function(val){
      this.qltyInspAply.dpstYn = val ? "Y" : "N";
    },
    "qltyInspAply.incmInspAcinspYn": function(val) {
      const incmAplqty = this.qltyInspAply.incmAplqty == null ? 0 : this.qltyInspAply.incmAplqty;

      //수입가능물량
      this.qltyInspAply.incmPmtqty = val === "Y" ? incmAplqty * 10 : incmAplqty;
      //검사물량
      this.qltyInspAply.incmInspqty = incmAplqty;

    },
    "qltyInspAply.incmAplqty": function(val){
      //수입가능물량
      this.qltyInspAply.incmPmtqty = this.qltyInspAply.incmInspAcinspYn === "Y" ? parseFloat(val) * 10 : val;
      //검사물량
      this.qltyInspAply.incmInspqty = parseFloat(val);
    },
  },
  created() {

  },
  beforeMount() {
  },
  mounted() {
  },
  beforeUnmount() {
    // 컴포넌트 전체 데이터 저장하기
    // storeComponent.store(this.menu, this);
  },
  methods: {
    async initialize() {

      this.qltyIndctInspYnDisabled = false;
      this.inspCpstDisabled = false;
      this.isRequiredForEtcArtclNm = false;
      this.isCheckedDpstYn = false;
      //검사신청정보
      this.qltyInspAply = {
          bplcId: "",
          fcltSqno: "",
          fcltInspAplySn: "",
          qltyInspArtclCd: "01-00-00",
          inspClsfCd: "30",
          inspSeCd: "",
          qltyIndctInspYn: "Y",
          inspCpstCdList: [],
          etcArtclNm: "",
          dpstPrnmntYmd: "",
          pyrNm: "",
          dpstYn: "N",
          inspHopeYmd: "",
          qltyInspItemCd: "",
          inspAplyAtchFileGroupId: "",
          inspAplyYmd: "",
          fileProcessingData: {},
          incmInspAcinspYn: "N",   /* 수입검사실사여부 */
          hskCd: "",
          incmInspqty: "0",  /* 수입검사량 = 검사물량(톤) */
          incmPmtqty: "0",   /* 수입허용량 = 수입가능물량(톤) */
          incmAplqty: "0",   /* 수입신청량 = 신청물량(톤) */
          incmFcltSn: "",   /* 수입시설일련번호*/
      };
      //사업장 및 시설정보
      this.qltyInspFcltInfo = {
          bplcId: "",
          bplcKndCd: "",
          bplcKndNm: "",
          bplcNm: "",
          pubBzentyYn: "",
          bzentyId: "",
          bzentyNm: "",
          cnsgnBzentyNm: "",
          bzentyAtchFileGroupId: "",
          bplcAtchFileGroupId: "",
          inspPrgrsSttsCd: "",
      };
      //해외제조시설정보
      this.mnftrFcltInfo = {
          bplcId: "",
          incmFcltSn: "",
          bzentyNm: "",
          mnftrNtnCd: "",
          ovrsBzmnIdntfNo: "",
          mnftrNtnNm: "",
          ctyNm: "",
          fcltAddr: "",
          prdctCstdyPlcCn: "",
          srfPtYn: "",
          srfNptYn: "",
          bioSrfPtYn: "",
          bioSrfNptYn: "",
          mnfqty: "",
      };

      this.fileProcessingData = {};
      this.files = null;

      // HSK코드
      const hskCdOptList = this.$store.getters.getCodeList({ groupCd: CODE.HSK_CD });
      this.hskCdList = hskCdOptList.map(item => ({
        text: '('+item.dtlCd+')'+item.dtlCdNm,
        value: item.dtlCd
      }));
      this.hskCdList.unshift({ text: "선택", value: "" });
      
      //스크롤 최상단으로 이동
      window.scrollTo({top: 0});
    },
    read() {
      this.$apiCall.post(API.INCM_QLTY_INSP_APLY_FORM
                            , this.parentData
                            , this.success
                            , this.error);
    },
    success(data) {
      if (!data.result) {
        this.error();
        return;
      }
      
      this.qltyInspFcltInfo = data.result.inspFcltDetail;      
      
    },
    error() {
      alert('warning', "데이터를 가져오는 중 오류가 발생했습니다.");
    },
    gotoList(){



      this.isAtchFileShow = false;
      this.$emit('incm-qlty-insp-aply-list', null);
    },
    reset() {

      this.isAtchFileShow = true;

      this.initialize();

      //전달받은 파라미터 set
      this.qltyInspAply.bplcId = this.parentData.bplcId;
      this.qltyInspAply.fcltSqno = this.parentData.fcltSqno;
      this.qltyInspAply.inspSeCd = this.parentData.inspSeCd;

      //사업장 및 시설 상세 정보 조회
      this.read();
    },
    /* (임시)저장 */
    save(sttsCd){
      const msg = sttsCd === "00" ? "신청서를 임시저장하시겠습니까?\n수입품질검사현황에서 수입품질검사신청을 할 수 있습니다." : "수입품질검사 신청을 진행하시겠습니까?";
      this.qltyInspAply.inspPrgrsSttsCd = sttsCd;

      if(this.mnftrFcltInfo.incmFcltSn == null || this.mnftrFcltInfo.incmFcltSn == ""){
        alert("제조업체 정보를 입력하여 주십시오.");
        return;
      }

      if(this.qltyInspAply.incmAplqty == "0"){
        alert("신청물량이 0입니다. 확인 후 다시 입력하여 주십시오.");
        return;
      }
      
      if(this.qltyInspAply.inspCpstCdList == null || this.qltyInspAply.inspCpstCdList.length == 0){
        alert("검사항목을 선택하여 주십시오.");
        return;
      }

      if(this.isRequiredForEtcArtclNm && this.qltyInspAply.etcArtclNm == "" ){
        alert("품질검사항목 중 선택된 기타항목의 상세내용을 입력하여주십시오.");
        return;
      }

      if(confirm(msg)) {

        //검사신청이면 검사신청일자 세팅
        if(sttsCd === "10"){
          let date = new Date();
          this.qltyInspAply.inspAplyYmd = date.toISOString().slice(0, 10).replace(/-/g, "");
        }

        //검사진행상태코드 세팅
        this.qltyInspAply.inspPrgrsSttsCd = sttsCd;
        //첨부파일 승인처리를 위해 파일정보 세팅
        this.qltyInspAply.fileProcessingData = this.fileProcessingData;

        //제조가능물량 세팅
        this.qltyInspAply.maxMnfqty = this.mnftrFcltInfo.mnfqty;        

        //검사신청 또는 임시저장 처리
        // this.$apiCall.post(API.INCM_QLTY_INSP_APLY
        //                     , this.qltyInspAply
        //                     , this.goToQltyInspStatus(sttsCd)
        //                     , this.saveErr);
        this.$apiCall.post(
            API.INCM_QLTY_INSP_APLY,
            {...this.qltyInspAply},
            ()=>{
              const msg = sttsCd === "00" ? "수입품질검사 신청정보가 임시저장되었습니다." : "검사신청이 완료되었습니다.\n검사결과의 진행상태는 알림톡을 통해 안내됩니다.";
              alert(msg);
              this.$router.push("/sfli/IncmQltyInspStat");
            },
            ()=>{
              alert("수입품질검사 신청정보 저장 중 오류가 발생했습니다.");
            }
        );
      }
      
    },
    goToQltyInspStatus(sttsCd){
      const msg = sttsCd === "00" ? "수입품질검사 신청정보가 임시저장되었습니다." : "검사신청이 완료되었습니다.\n검사결과의 진행상태는 알림톡을 통해 안내됩니다.";
      alert(msg);

      //임시로 목록으로 가도록 함.. 추후 현황 목록 화면으로 이동하도록 변경 예정
      //this.gotoList();
      this.$router.push("/sfli/IncmQltyInspStat");
    },
    saveErr(){
      alert("수입품질검사 신청정보 저장 중 오류가 발생했습니다.");
    },
    /** 해외수입시설 팝업 호출 */
    callPopupIncmFclt(){
      storeSwitch.on('ovrsIncmMnftrFcltPopup');

      this.$refs.ovrsIncmMnftrFcltPopup.reset();
    },
    /* 해외제조시설 정보 세팅 */
    setOvrsIncmMnftrFcltInfo(mnftrFcltInfo){
      console.log(mnftrFcltInfo);

      //제조시설
      this.mnftrFcltInfo = mnftrFcltInfo;
      console.log(this.mnftrFcltInfo);
      console.log("mnftrNtnNm:>> " + this.mnftrFcltInfo.mnftrNtnNm);

      this.srfItemValues = [];   //초기화
      mnftrFcltInfo.srfPtYn     == "Y" ? this.srfItemValues.push("0001") : "";
      mnftrFcltInfo.srfNptYn    == "Y" ? this.srfItemValues.push("0002") : "";
      mnftrFcltInfo.bioSrfPtYn  == "Y" ? this.srfItemValues.push("0003") : "";
      mnftrFcltInfo.bioSrfNptYn == "Y" ? this.srfItemValues.push("0004") : "";

      //검사신청
      this.qltyInspAply.incmFcltSn = mnftrFcltInfo.incmFcltSn;
      this.qltyInspAply.fcltSqno = mnftrFcltInfo.incmFcltSn;    //NotNull 때문에 어쩔수 없이 넣음
      if(mnftrFcltInfo.srfPtYn == "Y"){
        this.qltyInspAply.qltyInspItemCd = "0001";
      }else if(mnftrFcltInfo.srfNptYn == "Y"){
        this.qltyInspAply.qltyInspItemCd = "0002"
      }else if(mnftrFcltInfo.bioSrfPtYn == "Y"){
        this.qltyInspAply.qltyInspItemCd = "0003"
      }else if(mnftrFcltInfo.bioSrfNptYn == "Y"){
        this.qltyInspAply.qltyInspItemCd = "0004"
      }

      //검사항목 자동 세팅
      this.setInspCpstCd();
    },
    cancel(){

      if(!confirm("작성중인 수입품질검사 신청을 취소하시겠습니까?")){
        return false;
      }

      this.gotoList();
    },
    findMnftrFclt() {
      storeSwitch.on('ovrsIncmCnptMngPopup');
    },
    onSelectedOvrsIncmCnptMng(data) {

      this.mnftrFcltInfo.incmFcltSn = data.incmFcltSn;
      this.mnftrFcltInfo.bzentyNm = data.bzentyNm;
      this.mnftrFcltInfo.mnftrNtnCd = data.mnftrNtnCd;
      this.mnftrFcltInfo.mnftrNtnNm = data.mnftrNtnNm;
      this.mnftrFcltInfo.fcltAddr = data.fcltAddr;
      this.mnftrFcltInfo.srfPtYn = data.srfPtYn;
      this.mnftrFcltInfo.srfNptYn = data.srfNptYn;
      this.mnftrFcltInfo.bioSrfPtYn = data.bioSrfPtYn;
      this.mnftrFcltInfo.bioSrfNptYn = data.bioSrfNptYn;
      this.mnftrFcltInfo.prdctCstdyPlcCn = data.prdctCstdyPlcCn;

      this.srfItemValues = [];   //초기화
      this.mnftrFcltInfo.srfPtYn     == "Y" ? this.srfItemValues.push("0001") : "";
      this.mnftrFcltInfo.srfNptYn    == "Y" ? this.srfItemValues.push("0002") : "";
      this.mnftrFcltInfo.bioSrfPtYn  == "Y" ? this.srfItemValues.push("0003") : "";
      this.mnftrFcltInfo.bioSrfNptYn == "Y" ? this.srfItemValues.push("0004") : "";

      //검사신청
      this.qltyInspAply.incmFcltSn = this.mnftrFcltInfo.incmFcltSn;
      this.qltyInspAply.fcltSqno = this.mnftrFcltInfo.incmFcltSn;    //NotNull 때문에 어쩔수 없이 넣음
      if(this.mnftrFcltInfo.srfPtYn == "Y"){
        this.qltyInspAply.qltyInspItemCd = "0001";
      }else if(this.mnftrFcltInfo.srfNptYn == "Y"){
        this.qltyInspAply.qltyInspItemCd = "0002"
      }else if(this.mnftrFcltInfo.bioSrfPtYn == "Y"){
        this.qltyInspAply.qltyInspItemCd = "0003"
      }else if(this.mnftrFcltInfo.bioSrfNptYn == "Y"){
        this.qltyInspAply.qltyInspItemCd = "0004"
      }

      //검사항목 자동 세팅
      this.setInspCpstCd();

    },
    setInspCpstCd(){
      const prdctnPrdctKndCd = this.qltyInspAply.qltyInspItemCd;
      this.qltyInspAply.inspCpstCdList = [];

      if(this.qltyInspAply.qltyIndctInspYn == 'Y'){
        if(prdctnPrdctKndCd == '0001' || prdctnPrdctKndCd == '0002'){ //고형연료
          this.qltyInspAply.inspCpstCdList = this.inspCpstOtions.filter(item => item.value !== "190" && item.value !== "200")
                                                 .map(item => item.value);
        }else if(prdctnPrdctKndCd == '0003' || prdctnPrdctKndCd == '0004'){ //바이오 고형연료
          this.qltyInspAply.inspCpstCdList = this.inspCpstOtions.filter(item => item.value !== "200").map(item => item.value);
        }else{
          this.qltyInspAply.inspCpstCdList = this.inspCpstOtions.map(item => item.value);
        }
      }else{
        if(prdctnPrdctKndCd == '0001' || prdctnPrdctKndCd == '0002'){ //고형연료
          this.qltyInspAply.inspCpstCdList = this.inspCpstOtions
                                                 .filter(item => item.value !== "200"
                                                                 && item.value !== "190"
                                                                 && item.value !== "120"
                                                                 && item.value !== "10"
                                                                 && item.value !== "20"
                                                                 && item.value !== "40"
                                                                 && item.value !== "50"
                                                                 && item.value !== "60"
                                                                 && item.value !== "70"
                                                                 && item.value !== "30")
                                                 .map(item => item.value);
        }else if(prdctnPrdctKndCd == '0003' || prdctnPrdctKndCd == '0004'){ //바이오 고형연료
          this.qltyInspAply.inspCpstCdList = this.inspCpstOtions
                                                 .filter(item => item.value !== "10"
                                                                 && item.value !== "20"
                                                                 && item.value !== "40"
                                                                 && item.value !== "50"
                                                                 && item.value !== "60"
                                                                 && item.value !== "70"
                                                                 && item.value !== "30"
                                                                 && item.value !== "200"
                                                                 )
                                                 .map(item => item.value);
        }else{
          this.qltyInspAply.inspCpstCdList = this.inspCpstOtions.map(item => item.value);
        }
      }

      ///바이오매스 검사항목 활성화여부
      this.inspCpstBioMassDisabled = (prdctnPrdctKndCd == '0003' || prdctnPrdctKndCd == '0004') ? false : true;
    },
  }
}
</script>

<style scoped>
  .max_width_43 {
    max-width: 43rem
  }
</style>