<template>
  <div class="desc_box">
    <i class="icon"><img src="@/assets/images/common/ico_desc.png" alt="이미지"></i>
    <div class="desc">
      <strong class="desc_title">고형연료제품[SRF(Solid Refuse Fuel)</strong>
      <p>음식물을 제외한 생활폐기물인 폐비닐, 폐플라스틱 등의 가연성 물질을 선별해 건조 과정 등을 거친 고형폐기물연료
        주로 발전소나 지역난방, 산업용 보일러의 보조 연료로 사용</p>
    </div>
  </div>

  <section class="cont_section"
           :style="{ background: `url(${require('@/assets/images/sub/bg_srfEneIntro01.png')}) no-repeat bottom right`, 'background-size': '24.1rem', 'padding-bottom': '6rem' }">
    <h2 class="title1">폐자원에너지란?</h2>
    <div class="title_box">
      <span class="title">자원의 절약과 재활용촉진에 관한 법률<i class="dot"></i></span>
      <p>
        <strong>폐자원에너지</strong>란<br>고형연료제품, 폐기물합성가스 등 폐기물로부터 회수된 에너지 또는 에너지를 회수할 수 있도록 전환된 물질로서 환경부령으로 정하는 것을 말한다.
      </p>
    </div>
    <ul class="blue_dot_list">
      <li>폐기물을 이용하여 만든 고형(固形)연료제품(이하 "고형연료제품"이라 한다)</li>
      <li>폐기물을 이용하여 만든 매립가스, 바이오가스 및 합성가스 등 기체연료</li>
      <li>폐기물을 이용하여 만든 정제연료유 및 재생연료유 등 액체연료</li>
      <li>폐기물로부터 회수된 소각열(燒却熱)에너지</li>
    </ul>
  </section>

  <section class="cont_section"
           :style="{ background: `url(${require('@/assets/images/sub/bg_srfEneIntro02.png')}) no-repeat bottom right`, 'background-size': '36.6rem', 'padding-bottom': '14.7rem' }">
    <h2 class="title1">고형연료제품이란?</h2>
    <div class="title_box">
      <span class="title">자원의 절약과 재활용촉진에 관한 법률 시행규칙 별표1의 10항<i class="dot"></i></span>
      <p>
        10.별표 7에 따른 고형연료제품의 품질·등급기준에 적합하게 제조한 다음 각 목의 고형연료제품
      </p>
    </div>
    <ul class="hangul_list mb_50">
      <li><strong>일반 고형연료제품[SRF(Solid Refuse Fuel)]:</strong> 「폐기물관리법」 제2조제4호의 지정폐기물이 아닌 다음의 가연성
        고형폐기물을 사용(다음의 폐기물을 서로 혼합하거나 다음의 폐기물과 나목의 바이오 고형연료제품 제조에 사용되는 폐기물을 혼합하여 사용하는
        경우를 포함한다)하여 제조한 것을 말한다.
        <ul class="blue_dot_list">
          <li>생활폐기물(음식물류폐기물은 제외한다)</li>
          <li>폐합성수지류[자동차 파쇄잔재물(ASR)은 제외한다]</li>
          <li>폐합성섬유류</li>
          <li>폐고무류(합성고무류를 포함한다)</li>
          <li>폐타이어</li>
          <li>그 밖에 에너지로 사용이 가능하다고 환경부장관이 인정하여 고시하는 가연성 고형폐기물</li>
        </ul>
      </li>
      <li><strong>바이오 고형연료제품[BIO-SRF(Biomass-Solid Refuse Fuel)]:</strong> 「폐기물관리법」 제2조제4호의 지정폐기물이 아닌
        다음의 가연성 고형폐기물을 사용(다음의 폐기물을 서로 혼합하는 경우를 포함한다)하여 제조한 것을 말한다.
        <ul class="blue_dot_list">
          <li>폐지류</li>
          <li>농업폐기물(왕겨, 쌀겨, 옥수수대 등 농작물의 부산물을 말한다)</li>
          <li>폐목재류(폐가구류 및 제재부산물을 포함하며, 철도용으로 사용된 침목과 전신주로 사용된 것은 제외한다)</li>
          <li>식물성잔재물(땅콩껍질, 호두껍질, 팜껍질, 코코넛껍질, 귤껍질, 커피찌꺼기 등을 말하며, 음식물류폐기물은 제외한다)</li>
          <li>초본류 폐기물</li>
          <li>그 밖에 에너지로 사용이 가능하다고 환경부장관이 인정하여 고시하는 바이오매스(Biomass) 폐기물</li>
        </ul>
      </li>
    </ul>

    <div class="title_box">
      <span class="title">고형연료제품 품질기준 (자원의 절약과 재활용 촉진에 관한 법률 시행규칙 별표7)<i class="dot"></i></span>
    </div>
    <div class="mb_10"><strong>1. 일반 고형연료제품 / SRF(Solid Refuse Fuel)</strong></div>
    <div class="tbl_wrap">
      <table>
        <caption>일반 고형연료제품 품질기준_품질기준, 단위, 성형, 비성형으로 구성</caption>
        <colgroup>
          <col style="width:15%">
          <col style="width:15%">
          <col style="width:14%">
          <col style="width:14%">
          <col style="width:14%">
          <col style="width:14%">
          <col style="width:14%">
        </colgroup>
        <thead>
        <tr>
          <th scope="row" colspan="2">품질기준</th>
          <th scope="row">단위</th>
          <th scope="row" colspan="2">성형</th>
          <th scope="row" colspan="2">비성형</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th scope="col" colspan="2" rowspan="2">모양 및 크기</th>
          <td rowspan="2">mm</td>
          <td>직경</td>
          <td>50 이하</td>
          <td>가로</td>
          <td>50 이하</td>
        </tr>
        <tr>
          <td>길이</td>
          <td>100 이하</td>
          <td>세로</td>
          <td>50 이하</td>
        </tr>
        <tr>
          <th scope="col" colspan="2">발열량</th>
          <td>kcal/kg</td>
          <td colspan="4">수입 고형연료제품 : 3,650 이상<br>제조 고형연료제품 : 3,500 이상</td>
        </tr>
        <tr>
          <th scope="col" colspan="2">수분 함유량</th>
          <td>wt.%</td>
          <td colspan="2">15 이하</td>
          <td colspan="2">25 이하</td>
        </tr>
        <tr>
          <th scope="col" rowspan="4">금속성분</th>
          <th scope="col">수은(Hg)</th>
          <td rowspan="4">mg/kg</td>
          <td colspan="4">1.0 이하</td>
        </tr>
        <tr>
          <th scope="col">카드뮴(Cd)</th>
          <td colspan="4">5.0 이하</td>
        </tr>
        <tr>
          <th scope="col">납(Pb)</th>
          <td colspan="4">150 이하</td>
        </tr>
        <tr>
          <th scope="col">비소(As)</th>
          <td colspan="4">13.0 이하</td>
        </tr>
        <tr>
          <th scope="col" colspan="2">회분 함유량</th>
          <td>wt.%</td>
          <td colspan="4">20 이하</td>
        </tr>
        <tr>
          <th scope="col" colspan="2">염소 함유량</th>
          <td>wt.%</td>
          <td colspan="4">2.0 이하</td>
        </tr>
        <tr>
          <th scope="col" colspan="2">황분 함유량<br>(폐타이어만으로 제조한 경우)</th>
          <td>wt.%</td>
          <td colspan="4">0.6 이하<br>(2.0 이하)</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="info_box mt_10">
      <strong class="info_title">비고</strong>
      <ul class="dot_list">
        <li>발열량은 저위발열량으로 환산한 기준을 적용한다.</li>
        <li>금속성분, 회분 함유량, 염소 함유량 및 황분 함유량은 건조된 상태를 기준으로 한다.</li>
        <li>성형제품은 펠릿으로 제조한 것을 말하며, 사용자가 주문서 또는 계약서등으로 요청한 경우에는 길이를 100㎜ 초과하여 제조할 수 있다.</li>
        <li>비성형제품으로서 고형연료제품 사용시설에 직접 사용하기 위해 같은 부지에서 제조하는 경우에는 체 구멍의 크기가 가로 120㎜, 세로 120㎜ 이하(체 구멍이 원형인 경우 면적이 14,400㎟ 이하)인 체에 통과시켰을 때 무게 기준으로 제품의 95퍼센트 이상이 통과할 수 있는 것으로 제조할 수 있다.</li>
      </ul>
    </div>
    <div class="mt_40 mb_10"><strong>2. 바이오 고형연료제 / BIO-SRF(Biomass-Solid Refuse Fuel)</strong></div>
    <div class="tbl_wrap">
      <table>
        <caption>바이오 고형연료제 품질기준_품질기준, 단위, 성형, 비성형으로 구성</caption>
        <colgroup>
          <col style="width:15%">
          <col style="width:15%">
          <col style="width:14%">
          <col style="width:14%">
          <col style="width:14%">
          <col style="width:14%">
          <col style="width:14%">
        </colgroup>
        <thead>
        <tr>
          <th scope="row" colspan="2">품질기준</th>
          <th scope="row">단위</th>
          <th scope="row" colspan="2">성형</th>
          <th scope="row" colspan="2">비성형</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th scope="col" colspan="2" rowspan="2">모양 및 크기</th>
          <td rowspan="2">mm</td>
          <td>직경</td>
          <td>50 이하</td>
          <td>가로</td>
          <td>120 이하</td>
        </tr>
        <tr>
          <td>길이</td>
          <td>100 이하</td>
          <td>세로</td>
          <td>120 이하</td>
        </tr>
        <tr>
          <th scope="col" colspan="2">발열량</th>
          <td>kcal/kg</td>
          <td colspan="4">수입 고형연료제품 : 3,150 이상<br>제조 고형연료제품 : 3,000 이상</td>
        </tr>
        <tr>
          <th scope="col" colspan="2">수분 함유량</th>
          <td>wt.%</td>
          <td colspan="2">10 이하</td>
          <td colspan="2">25 이하</td>
        </tr>
        <tr>
          <th scope="col" rowspan="5">금속성분</th>
          <th scope="col">수은(Hg)</th>
          <td rowspan="5">mg/kg</td>
          <td colspan="4">0.6 이하</td>
        </tr>
        <tr>
          <th scope="col">카드뮴(Cd)</th>
          <td colspan="4">5.0 이하</td>
        </tr>
        <tr>
          <th scope="col">납(Pb)</th>
          <td colspan="4">100 이하</td>
        </tr>
        <tr>
          <th scope="col">비소(As)</th>
          <td colspan="4">5.0 이하</td>
        </tr>
        <tr>
          <th scope="col">크로뮴(Cr)</th>
          <td colspan="4">70.0 이하</td>
        </tr>
        <tr>
          <th scope="col" colspan="2">회분 함유량</th>
          <td>wt.%</td>
          <td colspan="4">15 이하</td>
        </tr>
        <tr>
          <th scope="col" colspan="2">염소 함유량</th>
          <td>wt.%</td>
          <td colspan="4">0.5 이하</td>
        </tr>
        <tr>
          <th scope="col" colspan="2">황분 함유량</th>
          <td>wt.%</td>
          <td colspan="4">0.6 이하</td>
        </tr>
        <tr>
          <th scope="col" colspan="2">바이오매스</th>
          <td>wt.%</td>
          <td colspan="4">95 이상</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="info_box mt_10">
      <strong class="info_title">비고</strong>
      <ul class="dot_list">
        <li>발열량은 저위발열량으로 환산한 기준을 적용한다.</li>
        <li>금속성분, 회분 함유량, 염소 함유량 및 황분 함유량은 건조된 상태를 기준으로 한다.</li>
        <li>성형제품은 펠릿으로 제조한 것을 말한다.</li>
        <li>바이오매스 함유량은 고형연료제품의 함유 성분 중에서 수분과 회분을 제외한 나머지 성분 중 바이오매스의 비율을 말한다.</li>
      </ul>
    </div>
  </section>
</template>

<style scoped>

</style>