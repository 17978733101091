<template>

  <h2 class="title1 mt_0">실적보고 상세</h2>
  <div class="board_write">
    <table>
      <caption>실적연도, 실적상태, 사업장명, 사업장종류</caption>
      <colgroup>
        <col style="width:18%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:32%">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">실적연월</th>
        <td>{{ formattedPrfmncYm }}</td>
        <th scope="row">실적상태</th>
        <td>{{ fillGasDtlObj.prfmncMst.prfmncSttsNm || "작성중" }}</td>
      </tr>
      <tr>
        <th scope="row">사업장명</th>
        <td>{{ fillGasDtlObj.prfmncMst.bplcNm }}</td>
        <th scope="row">사업장종류</th>
        <td>{{ fillGasDtlObj.prfmncMst.bplcKndNm }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div v-show="!showDetail" class="txt_box mt_10">
    <ul class="dot_list">
      <li>실적 등록 시, 반드시 톤 단위로 환산하여 입력 부탁드립니다.</li>
    </ul>
  </div>

  <rjct-rsn-area :prfmncBasicInfo="fillGasDtlObj.prfmncMst" />
  <prfmnc-mdfcn-prm-area :prfmncBasicInfo="fillGasDtlObj.prfmncMst" @dmnd-rtrcn="dmndRtrcnCmptn" />

  <div class="board_info mt_40">
    <h3 class="title2" tabindex="-1" ref="prfmncDtlFillGasWtCrynTitle">폐기물 반입 현황</h3>
  </div>

  <sb-grid
      id="prfmncDtlFillGasWtCrynGrid"
      :dataSource="dataSourceFillGasWtCryn"
      :columns="gridColumnsFillGasWtCryn"
      :gridattr="gridAttrFillGasWtCryn"
      v-model:grid="prfmncFillGasWtCrynGridObject"
      @loaded="gridLoadedFillGasWtCryn"
      :refreshGrid="gridReloadFillGasWtCryn"
      @change="changeFillGasWtCryn"
  />

  <div class="board_info mt_40">
    <h3 class="title2">자원순환정보시스템의 매립사업장 반입처리 등록 현황</h3>
    <div>
      <br-button label="자원순환정보시스템 정보적용" size="sm" format="btn" @click="rsrcRcyclnCrynInfoApply" />
    </div>
  </div>

  <div class="grid_search">
    <span class="txt_point">※ 자원순환정보시스템에 입력된 반입정보입니다.</span>
  </div>

  <sb-grid
      id="prfmncDtlFillGasRsrcRcyclnCrynGrid"
      :dataSource="dataSourceFillGasRsrcRcyclnCryn"
      :columns="gridColumnsFillGasRsrcRcyclnCryn"
      :gridattr="gridAttrFillGasRsrcRcyclnCryn"
      :param="fillGasDtlObj.prfmncMst"
      v-model:grid="prfmncFillGasRsrcRcyclnCrynGridObject"
      @loaded="gridLoadedFillGasRsrcRcyclnCryn"
      :refreshGrid="gridReloadFillGasRsrcRcyclnCryn"
  />

  <div class="board_info mt_40">
    <h3 class="title2">폐기물 매립 현황</h3>
  </div>
  <div class="board_write thead">
    <table>
      <caption>누적 매립 현황</caption>
      <colgroup>
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 15%;">
        <col style="width: 15%;">
        <col style="width: 10%;">
      </colgroup>
      <thead>
      <tr>
        <th scope="col" rowspan="2">
          이전 누적 매립량(톤)
          <div class="tooltip">
            <button type="button" class="btn_tool" @mouseover="handleTooltip" @mouseout="handleTooltip">도움말</button>
            <div class="tool_cont" style="display: none;">
              <p>당월 매립량을 포함하지 않은<br/>이전 실적까지의 누적 매립량</p>
            </div>
          </div>
          <br />
          (A)
        </th>
        <th scope="col" rowspan="2">당월 매립량(톤)<br />(B)</th>
        <th scope="col" rowspan="2">
          총 누적 매립량(톤)
          <div class="tooltip">
            <button type="button" class="btn_tool" @mouseover="handleTooltip" @mouseout="handleTooltip">도움말</button>
            <div class="tool_cont" style="display: none;">
              <p>당월 매립량을 포함한<br/>현재 실적까지의 누적 매립량</p>
            </div>
          </div>
          <br />
          (A + B)
        </th>
        <th scope="col" colspan="3" class="gridh-bg-validation">총 누적 매립량 검증</th>
      </tr>
      <tr>
        <th scope="col" class="gridh-bg-validation" style="border-left: 1px solid #e0e0e0;">최대 매립용량(톤)</th>
        <th scope="col" class="gridh-bg-validation">초과용량(톤)</th>
        <th scope="col" class="gridh-bg-validation">검증결과</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="txt_right" aria-label="이전 누적 매립량(톤)(A)">{{ formatNumber(prfmncFillGasPrevMonthSumFillWtTon?.prevMonthSumFillWtTon || 0) }} 톤</td>
        <td class="txt_right" aria-label="당월 매립량(톤)(B)">
          <br-form-input label="" v-model="fillGasDtlObj.prfmncFillGas.fillWtTon" :maxlength="15" validator="number" align="right" unit="톤" />
        </td>
        <td class="txt_right" aria-label="총 누적 매립량(톤)(A + B)">{{ formatNumber(totalFillWtTon) }} 톤</td>
        <td class="txt_right" aria-label="최대 매립용량(톤)">{{ formatNumber(fillGasDtlObj.prfmncFillGas.maxFillCapTon) }} 톤</td>
        <td class="txt_right" aria-label="초과용량(톤)" :class="fillGasDtlObj.prfmncFillGas.excsFillTon > 0 ? 'not-good' : ''">{{ formatNumber(fillGasDtlObj.prfmncFillGas.excsFillTon) }} 톤</td>
        <td class="txt_center" aria-label="검증결과" :class="fillVrfcRslt === '초과' ? 'not-good' : ''">{{ fillVrfcRslt }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info mt_40">
    <h3 class="title2">메탄가스 생산 현황</h3>
  </div>
  <div class="board_write mb_25">
    <table>
      <caption>발생가스(Land Fill Gas)포집량 - 발생가스 총 포집량, 발생가스 총 포집량 중 메탄가스량, 메탄가스농도로 구성</caption>
      <colgroup>
        <col style="width: 15%;">
        <col :style="{width: `calc(55% / 3)`}">
        <col style="width: 15%;">
        <col :style="{width: `calc(55% / 3)`}">
        <col style="width: 15%;">
        <col :style="{width: `calc(55% / 3)`}">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">발생가스 총 포집량</th>
        <td>
          <br-form-input label="" v-model="fillGasDtlObj.prfmncFillGas.ocrnGasClcqty" :maxlength="15" validator="number" align="right" unit="N㎥" />
        </td>
        <th scope="row">발생가스 총 포집량 중 메탄가스량</th>
        <td>
          <br-form-input ref="mthnGasClcqtyInput" label="" v-model="fillGasDtlObj.prfmncFillGas.mthnGasClcqty" :maxlength="15" validator="number" align="right" unit="N㎥" />
        </td>
        <th scope="row">메탄가스농도</th>
        <td>
          <br-form-input ref="mthnGasDensInput" label="" v-model="fillGasDtlObj.prfmncFillGas.mthnGasDens" :maxlength="5" validator="number" align="right" unit="%" />
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="board_info mt_40">
    <h3 class="title2">메탄가스 사용 현황</h3>
  </div>
  <div class="board_write thead">
    <table>
      <caption>제품별 사용량 - 제품구분(총 사용량, 발전, 냉난방, 가스공급, 차량연료, 기타), 에너지생산 외로 구성</caption>
      <colgroup>
        <col style="width: 14.3%;">
        <col style="width: 14.3%;">
        <col style="width: 14.3%;">
        <col style="width: 14.3%;">
        <col style="width: 14.3%;">
        <col style="width: 14.3%;">
        <col>
      </colgroup>
      <thead>
      <tr>
        <th scope="col" colspan="6">제품구분</th>
        <th scope="col" rowspan="2" style="border-left: 1px solid #e0e0e0;">에너지생산 외</th>
      </tr>
      <tr>
        <th scope="col">총 사용량</th>
        <th scope="col">발전</th>
        <th scope="col">냉난방</th>
        <th scope="col">가스공급</th>
        <th scope="col">차량연료</th>
        <th scope="col">기타</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td aria-label="제품구분-총 사용량">
          <br-form-input label="" v-model="fillGasDtlObj.prfmncFillGas.gasStotUsqty" :maxlength="15" align="right" validator="number" unit="N㎥" :readonly="true" />
        </td>
        <td aria-label="제품구분-발전">
          <br-form-input label="" v-model="fillGasDtlObj.prfmncFillGas.elctgnUsgGasUsqty" :maxlength="15" align="right" validator="number" unit="N㎥" />
        </td>
        <td aria-label="제품구분-냉난방">
          <br-form-input label="" v-model="fillGasDtlObj.prfmncFillGas.cdysmUsgGasUsqty" :maxlength="15" align="right" validator="number" unit="N㎥" />
        </td>
        <td aria-label="제품구분-가스공급">
          <br-form-input label="" v-model="fillGasDtlObj.prfmncFillGas.gasSplyUsgGasUsqty" :maxlength="15" align="right" validator="number" unit="N㎥" />
        </td>
        <td aria-label="제품구분-차량연료">
          <br-form-input label="" v-model="fillGasDtlObj.prfmncFillGas.vhclFuelUsgGasUsqty" :maxlength="15" align="right" validator="number" unit="N㎥" />
        </td>
        <td aria-label="제품구분-기타">
          <br-form-input label="" v-model="fillGasDtlObj.prfmncFillGas.etcUsgGasUsqty" :maxlength="15" align="right" validator="number" unit="N㎥" />
        </td>
        <td aria-label="에너지생산 외">
          <br-form-input label="" v-model="fillGasDtlObj.prfmncFillGas.enePrdctnExcqty" :maxlength="15" align="right" validator="number" unit="N㎥" />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info mt_40">
    <h3 class="title2">메탄가스 판매 현황</h3>
  </div>
  <div class="grid_search">
    <h4><strong class="mr_5">(메탄가스(CH₄) 판매량)</strong>업체별 판매량 현황
      <span>※ 판매업체 목록은 에너지공급업체 현황에서 관리 할 수 있습니다.</span>
    </h4>
  </div>
  <sb-grid
      id="prfmncFillGasNtslGrid"
      :dataSource="dataSourceFillGasNtsl"
      :columns="gridColumnsFillGasNtsl"
      :gridattr="gridAttrFillGasNtsl"
      v-model:grid="prfmncFillGasNtslGridObject"
      @loaded="gridLoadedFillGasNtsl"
      :refreshGrid="gridReloadFillGasNtsl"
      @change="changeFillGasNtsl"
  />

  <!-- 실적상태이력 현황 (신규등록인 경우 노출안함) -->
  <prfmnc-stts-hstry v-if="fillGasDtlObj.prfmncMst.prfmncId" :prfmncId="fillGasDtlObj.prfmncMst.prfmncId" />

  <!-- 수정허용요청 이력 현황 (신규등록인 경우 노출안함) -->
  <prfmnc-mdfcn-prm-hstry v-if="fillGasDtlObj.prfmncMst.prfmncId" :prfmncId="fillGasDtlObj.prfmncMst.prfmncId" />

  <!-- 버튼 -->
  <common-button-area
      :prfmncBasicInfo="fillGasDtlObj.prfmncMst"
      @save="prtmncRptDtlSave"
      @submission="prtmncRptDtlSave(true)"
  />
</template>

<script>
import SbGrid from "@/components/SbGrid.vue";
import utils from "@/js/utils/utils";
import commonButtonArea from "@/views/prfmnc-rpt/dtl/components/CommonButtonArea.vue";
import prfmncSttsHstry from "@/views/prfmnc-rpt/components/PrfmncSttsHstry.vue";
import PrfmncMdfcnPrmHstry from "@/views/prfmnc-rpt/components/PrfmncMdfcnPrmHstry.vue";
import PrfmncMdfcnPrmArea from "@/views/prfmnc-rpt/dtl/components/PrfmncMdfcnPrmArea.vue";
import RjctRsnArea from "@/views/prfmnc-rpt/dtl/components/RjctRsnArea.vue";

const ROUTE = {
  PERFORMANCE_LIST: "/prfmnc-rpt/PrfmncRpt",
  PERFORMANCE_DTL: "/prfmnc-rpt/dtl/FillGas"
};

const API = {
  PRFMNC_DTL_SAVE: "/prfmnc/fillGas", // 실적 매립가스 상세저장
};

const CODE = {
  PERFORMANCE_STATUS: "PFM001", // 실적상태코드
};

export default {
  components: {
    RjctRsnArea, PrfmncMdfcnPrmArea,
    SbGrid,
    commonButtonArea,
    prfmncSttsHstry,
    PrfmncMdfcnPrmHstry
  },
  props: ['prfmncFillGas', 'prfmncFillGasPrevMonthSumFillWtTon', 'prfmncFillGasWtCrynList', 'prfmncFillGasRsrcRcyclnList', 'prfmncFillGasNtslList', 'prfmncMst', 'prfmncFclt', 'showDetail'],
  data() {
    return {
      isPrfmncFillGasUpdated: false, // prfmncFillGas watch 처리 여부
      isPrfmncFcltUpdated: false, // prfmncFclt watch 처리 여부
      prfmncSttsList: [], // 실적상태코드

      updatedWtCrynData: [], // 폐기물반입현황 수정된 행
      insertedWtCrynData: [], // 폐기물반입현황 추가된 행

      // 실적 매립가스 상세정보 데이터
      fillGasDtlObj:{
        totalGasStotNsqty: 0, // 메탄가스 총 판매량 합계
        prfmncMst: {},
        prfmncFillGasWtCrynList : [], // 폐기물반입현황
        prfmncFillGasNtslList : [], // 메탄가스판매현황
        prfmncFillGas: {
          fillWstqty: 0, // 매립폐기물량
          fillWtTon: 0, // 매립폐기물톤수
          maxFillCapTon: 0, // 최대매립용량(톤)
          excsFillTon: 0, // 초과용량(톤)
          ocrnGasClcqty: 0, // 발생가스수집량
          mthnGasClcqty: 0, // 메탄가스수집량
          mthnGasDens: 0, // 메탄가스농도
          gasStotUsqty: 0, // 가스소계사용량
          elctgnUsgGasUsqty: 0, // 발전용도가스사용량
          cdysmUsgGasUsqty: 0, // 냉난방용도가스사용량
          gasSplyUsgGasUsqty: 0, // 가스공급용도가스사용량
          vhclFuelUsgGasUsqty: 0, // 차량연료용도가스사용량
          etcUsgGasUsqty: 0, // 기타용도가스사용량
          enePrdctnExcqty: 0, // 에너지생산제외량
        }, // 매립가스실적 (메탄가스생산현황, 메탄가스사용현황)
      },

      /**************** S : 폐기물반입현황 Grid ****************/
      dataSourceFillGasWtCryn: [],
      gridColumnsFillGasWtCryn: [],
      gridAttrFillGasWtCryn: {
        checkable: false,
        pageable: false,
        editable: {eventType: 'mouseup'},
        showRowNo : false,
        excelExport: {
          fileName: '폐기물반입현황(매립가스).xlsx',
          includeHeader: true,
          includeFormula: true,
        },
        showStatus:true,

      },
      prfmncFillGasWtCrynGridObject: null,
      gridReloadFillGasWtCryn: false,
      /**************** E : 폐기물반입현황 Grid ****************/

      /**************** S : 자원순환정보시스템 매립사업장 반입처리 등록현황 Grid ****************/
      dataSourceFillGasRsrcRcyclnCryn: [],
      gridColumnsFillGasRsrcRcyclnCryn: [],
      gridAttrFillGasRsrcRcyclnCryn: {
        checkable: false,
        pageable: false,
        editable : false,
        showRowNo : false,
        showStatus:false,
        toolBar:[],
      },
      prfmncFillGasRsrcRcyclnCrynGridObject: null,
      gridReloadFillGasRsrcRcyclnCryn: false,
      /**************** E : 자원순환정보시스템 매립사업장 반입처리 등록현황 Grid ****************/

      /**************** S : 메탄가스판매현황 Grid ****************/
      dataSourceFillGasNtsl: [],
      gridColumnsFillGasNtsl: [],
      gridAttrFillGasNtsl: {
        checkable: false,
        pageable: false,
        editable: {eventType: 'mouseup'},
        showRowNo : false,
        excelExport: {
          fileName: '메탄가스판매현황(매립가스).xlsx',
          includeHeader: true,
          includeFormula: true,
        },
        showStatus:true
      },
      prfmncFillGasNtslGridObject: null,
      gridReloadFillGasNtsl: false,
      /**************** E : 메탄가스판매현황 Grid ****************/
    };
  },
  computed: {
    // 총 누적매립량 계산
    totalFillWtTon(){
      return (
          parseFloat(this.prfmncFillGasPrevMonthSumFillWtTon?.prevMonthSumFillWtTon || 0) +
          parseFloat(this.fillGasDtlObj.prfmncFillGas.fillWtTon)
      );
    },
    /**
     * [폐기물매립현황 - 검증결과]
     * 초과용량 > 0 인 경우 초과. 이 외 적합.
     */
    fillVrfcRslt() {
      return this.fillGasDtlObj.prfmncFillGas.excsFillTon > 0 ? "초과" : "적합";
    },

    /**
     * [실적보고상세 - 실적연월]
     * YYYY-MM 포맷처리
     */
    formattedPrfmncYm() {
      return (this.fillGasDtlObj.prfmncMst.prfmncYm?.substring(0, 4) ?? "") + "-" + (this.fillGasDtlObj.prfmncMst.prfmncYm?.substring(4, 6) ?? "");
    },
  },
  watch: {
    // 메탄가스생산현황-발전 값 변경 시 총이용량 재계산
    'fillGasDtlObj.prfmncFillGas.elctgnUsgGasUsqty': 'updateGasStotUsqty',
    // 메탄가스생산현황-냉난방 값 변경 시 총이용량 재계산
    'fillGasDtlObj.prfmncFillGas.cdysmUsgGasUsqty': 'updateGasStotUsqty',
    // 메탄가스생산현황-가스공급 값 변경 시 총이용량 재계산
    'fillGasDtlObj.prfmncFillGas.gasSplyUsgGasUsqty': 'updateGasStotUsqty',
    // 메탄가스생산현황-차량연료 값 변경 시 총이용량 재계산
    'fillGasDtlObj.prfmncFillGas.vhclFuelUsgGasUsqty': 'updateGasStotUsqty',
    // 메탄가스생산현황-기타 값 변경 시 총이용량 재계산
    'fillGasDtlObj.prfmncFillGas.etcUsgGasUsqty': 'updateGasStotUsqty',

    /**
     * [폐기물매립현황 - 총 누적매립량(톤)] 변경 시
     * 총 누적매립량검증(초과용량) 처리
     */
    totalFillWtTon: {
      handler(newVal) {
        const excsFillTon = parseFloat(newVal) - parseFloat(this.fillGasDtlObj.prfmncFillGas.maxFillCapTon);
        this.fillGasDtlObj.prfmncFillGas.excsFillTon = excsFillTon < 0 ? 0 : excsFillTon;
      },
      immediate: true,
      deep:true,
    },
    prfmncMst: {
      handler(newVal) {
        this.fillGasDtlObj.prfmncMst = {...this.fillGasDtlObj.prfmncMst, ...newVal};
      },
      immediate: true,
      deep:true,
    },
    prfmncFillGas() {
      this.fillGasDtlObj.prfmncFillGas = {...this.fillGasDtlObj.prfmncFillGas, ...this.prfmncFillGas};
      this.isPrfmncFillGasUpdated = true;
      this.checkPrfmncDependencies();
    },
    prfmncFillGasWtCrynList() {
      this.dataSourceFillGasWtCryn = { data: this.prfmncFillGasWtCrynList.list, schema: { id: 'sggStdgCd'}};
      this.initializePrfmncFillGasWtCrynGridColumns();
      const itemCount = this.prfmncFillGasWtCrynList.list.length;
      this.gridAttrFillGasWtCryn.height = Math.max((itemCount * 30) + 190, 210);
      this.gridReloadFillGasWtCryn = true;

      // 화면 초기 진입 시 반입량합계를 폐기물매립현황(당월매립량)에 노출 - 이후 반입량 변경 시 changeFillGasWtCryn 메서드로 계산
      // const totalWtStotCryqty = this.prfmncFillGasWtCrynList.list.reduce((acc, item) => acc + (item.wtStotCryqty || 0), 0);
      // this.fillGasDtlObj.prfmncFillGas.fillWtTon = totalWtStotCryqty;
    },
    prfmncFillGasRsrcRcyclnList() {
      this.dataSourceFillGasRsrcRcyclnCryn = { data: this.prfmncFillGasRsrcRcyclnList.list, schema: { id: 'sggStdgCd'}};
      this.initializePrfmncFillGasRsrcRcyclnGridColumns();
      const itemCount = this.prfmncFillGasRsrcRcyclnList.list.length;
      this.gridAttrFillGasRsrcRcyclnCryn.height = Math.max((itemCount * 30) + 190, 210);
      this.gridReloadFillGasRsrcRcyclnCryn = true;
    },
    prfmncFillGasNtslList() {
      this.dataSourceFillGasNtsl = this.prfmncFillGasNtslList.list;
      this.initializePrfmncFillGasNtslGridColumns();
      const itemCount = this.prfmncFillGasNtslList.list.length;
      this.gridAttrFillGasNtsl.height = Math.max((itemCount * 30) + 170, 210);
      this.gridReloadFillGasNtsl = true;

      // 화면 초기 진입 시 총 판매량 합계값 계산 - 이후 판매량 변경 시 changeFillGasNtsl 메서드로 계산
      this.fillGasDtlObj.totalGasStotNsqty = this.prfmncFillGasNtslList.list.reduce((acc, item) => acc + (item.gasStotNsqty || 0), 0);
    },
    prfmncFclt() {
      // 매립가스의 경우 시설테이블의 일간용량컬럼에 저장되어있는 데이터가 최대매립가능시설용량이다.
      const maxFillCapTon = parseFloat(this.prfmncFclt.totfillCap);
      this.fillGasDtlObj.prfmncFillGas.maxFillCapTon = maxFillCapTon;
      this.isPrfmncFcltUpdated = true;
      this.checkPrfmncDependencies();
    }
  },
  created() {
    this.initialize();
  },
  mounted() {
  },
  methods: {
    checkPrfmncDependencies() {
      if (this.isPrfmncFillGasUpdated && this.isPrfmncFcltUpdated) {
        const excsFillTon =
            parseFloat(this.totalFillWtTon) -
            parseFloat(this.fillGasDtlObj.prfmncFillGas.maxFillCapTon);
        this.fillGasDtlObj.prfmncFillGas.excsFillTon = excsFillTon < 0 ? 0 : excsFillTon;
      }
    },
    handleTooltip(evt) {
      const button = evt.currentTarget; // mouse over, out 대상 버튼
      const toolCont = button.nextElementSibling?.matches('div.tool_cont') ? button.nextElementSibling : null; // 노출 대상 DIV

      if (toolCont && toolCont.classList.contains('tool_cont')) {
        if (event.type === 'mouseover') {
          toolCont.style.display = 'block';
        } else if (event.type === 'mouseout') {
          toolCont.style.display = 'none';
        }
      }
    },
    dmndRtrcnCmptn() {
      location.reload();
    },
    /**
     * [메탄가스사용현황 : 총 사용량] 계산
     * 발전 + 냉난방 + 가스공급 + 차량연료 + 기타
     */
    updateGasStotUsqty() {
      this.fillGasDtlObj.prfmncFillGas.gasStotUsqty =
          parseFloat(this.fillGasDtlObj.prfmncFillGas.elctgnUsgGasUsqty) +
          parseFloat(this.fillGasDtlObj.prfmncFillGas.cdysmUsgGasUsqty) +
          parseFloat(this.fillGasDtlObj.prfmncFillGas.gasSplyUsgGasUsqty) +
          parseFloat(this.fillGasDtlObj.prfmncFillGas.vhclFuelUsgGasUsqty) +
          parseFloat(this.fillGasDtlObj.prfmncFillGas.etcUsgGasUsqty);
    },
    // 폐기물 반입현황 총 반입량 가져오기
    changeFillGasWtCryn(values){
      // 변경된 그리드 데이터 중 반입량이 변경된 경우 이벤트 (반입량의 합계를폐기물매립현황의 당월매립량(톤)에 적용하기 위함)
      values.forEach(() => {
        // 그리드의 모든 데이터를 가져와서 반입량 항목의 모든 값의 합계를 계산
        const wtCrynAllItems = window.SBGrid3.getAllItems(this.prfmncFillGasWtCrynGridObject);
        const totalWtStotCryqty = wtCrynAllItems.reduce((sum, item) => {
          return sum + (item.wtStotCryqty || 0);
        }, 0);

        this.fillGasDtlObj.prfmncFillGas.fillWtTon = totalWtStotCryqty;
      });
    },
    // 메탄가스 판매현황 총 판매량 가져오기
    changeFillGasNtsl(values){
      values.forEach(item => {
        if (['elctgnUsgGasNsqty', 'cdysmUsgGasNsqty', 'gasSplyUsgGasNsqty', 'vhclFuelUsgGasNsqty', 'etcUsgGasNsqty'].includes(item.field)) {
          // 그리드의 모든 데이터를 가져와서 총 판매량 항목의 모든 값의 합계를 계산
          const ntslAllItems = window.SBGrid3.getAllItems(this.prfmncFillGasNtslGridObject);
          const totalGasStotNsqty = ntslAllItems.reduce((sum, item) => {
            return sum + (item.gasStotNsqty || 0);
          }, 0);

          this.fillGasDtlObj.totalGasStotNsqty = totalGasStotNsqty;
        }
      });
    },
    async initialize() {
      await this.loadPrfmncSttsCd();
    },
    // 공통코드(실적상태코드) 로드
    async loadPrfmncSttsCd() {
      const prfmncSttsList = this.$store.getters.getCodeList({groupCd: CODE.PERFORMANCE_STATUS, useYn: 'Y'});
      this.prfmncSttsList = prfmncSttsList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    // 폐기물 반입현황 그리드 컬럼 세팅
    initializePrfmncFillGasWtCrynGridColumns() {
      this.gridColumnsFillGasWtCryn = [
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        {
          caption: '반입',
          width: 200,
          part: 'head',
          columns : [
            {
              field: 'ctpvStdgNm',
              caption: '시·도',
              width: 100,
              editable: false,
            },
            {
              field: 'sggStdgNm',
              caption: '시·군·구',
              width: 100,
              editable: false,
            }
          ],
          total: {
            aggregates: [
            ],
            header: [
              { template: '계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        {
          field: 'wtStotCryqty',
          caption: '합계(톤)',
          unit: '%',
          align: 'right',
          dataType: 'number',
          editable: false,
          colCss: 'grid-disabled',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          calc: {
            require: ['bplcDschrgWstqty', 'hmeLifeWstqty', 'bplcLifeWstqty', 'largeWstqty', 'foodWstqty', 'tmbrWstqty', 'inmtWstqty', 'wstvnlWstqty', 'etcWstqty'],
            eval: 'sum',
            nullAs: 0,
            dataType: 'number',
          },
          format: '#,##0.00',
        },
        {
          field: 'bplcDschrgWstqty',
          caption: '배출사업장계(톤)',
          width: 100,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
        {
          caption: '종량제에 의한 혼합배출',
          columns : [
            {
              field: 'hmeLifeWstqty',
              caption: '가정생활(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'bplcLifeWstqty',
              caption: '사업장생활(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            }
          ]
        },
        {
          caption: '종량제 외 배출',
          columns : [
            {
              field: 'largeWstqty',
              caption: '대형폐기물(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'foodWstqty',
              caption: '음식류폐기물(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'tmbrWstqty',
              caption: '목재(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'inmtWstqty',
              caption: '소각재(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'wstvnlWstqty',
              caption: '영농폐비닐(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'etcWstqty',
              caption: '기타(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            }
          ]
        }
      ]
    },
    // 자원순환정보시스템 반입처리 등록현황 그리드 컬럼 세팅
    initializePrfmncFillGasRsrcRcyclnGridColumns() {
      this.gridColumnsFillGasRsrcRcyclnCryn = [
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        {
          caption: '반입',
          width: 200,
          part: 'head',
          columns : [
            {
              field: 'ctpvStdgNm',
              caption: '시·도',
              width: 100,
              editable: false,
            },
            {
              field: 'sggStdgNm',
              caption: '시·군·구',
              width: 100,
              editable: false,
            }
          ],
          total: {
            aggregates: [
            ],
            header: [
              { template: '계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        {
          field: 'wtStotCryqty',
          caption: '합계',
          unit: '%',
          align: 'right',
          dataType: 'number',
          editable: false,
          colCss: 'grid-disabled',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          calc: {
            require: ['bplcDschrgWstqty', 'hmeLifeWstqty', 'bplcLifeWstqty', 'largeWstqty', 'foodWstqty', 'tmbrWstqty', 'inmtWstqty', 'wstvnlWstqty', 'etcWstqty'],
            eval: 'sum',
            nullAs: 0,
            dataType: 'number',
          },
          format: '#,##0.00',
        },
        {
          field: 'bplcDschrgWstqty',
          caption: '배출사업장계(톤)',
          width: 100,
          align: 'right',
          dataType: 'number',
          editable: false,
          colCss: 'grid-disabled',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
        {
          caption: '종량제에 의한 혼합배출',
          columns : [
            {
              field: 'hmeLifeWstqty',
              caption: '가정생활(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'bplcLifeWstqty',
              caption: '사업장생활(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right', }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            }
          ]
        },
        {
          caption: '종량제 외 배출',
          columns : [
            {
              field: 'largeWstqty',
              caption: '대형폐기물(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'foodWstqty',
              caption: '음식류폐기물(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'tmbrWstqty',
              caption: '목재(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'inmtWstqty',
              caption: '소각재(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right', }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'wstvnlWstqty',
              caption: '영농폐비닐(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'etcWstqty',
              caption: '기타(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            }
          ]
        }
      ]
    },
    // 메탄가스판매현황 그리드 컬럼 세팅
    initializePrfmncFillGasNtslGridColumns() {
      this.gridColumnsFillGasNtsl = [
        { field: 'prfmncId', caption: '실적아이디', visible: false, editable: false },
        { field: 'bplcId', caption: '사업장아이디', visible: false, editable: false },
        { field: 'eneBzentySn', caption: '에너지업체일련번호', visible: false, editable: false },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        {
          field: 'bzentyNm',
          caption: '판매 업체명',
          width: 250,
          align: 'left',
          part: 'head',
          editable: false,
          total: {
            aggregates: [
            ],
            header: [
              { template: '계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        {
          field: 'gasStotNsqty',
          caption: '총 판매량(N㎥)',
          unit: '%',
          align: 'right',
          dataType: 'number',
          editable: false,
          colCss: 'grid-disabled',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          calc: {
            require: ['elctgnUsgGasNsqty', 'cdysmUsgGasNsqty', 'gasSplyUsgGasNsqty', 'vhclFuelUsgGasNsqty', 'etcUsgGasNsqty'],
            eval: 'sum',
            nullAs: 0,
            dataType: 'number',
          },
          format: '#,##0.00',
        },
        {
          field: 'elctgnUsgGasNsqty',
          caption: '발전 판매량(N㎥)',
          width: 150,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right', }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
        {
          field: 'cdysmUsgGasNsqty',
          caption: '냉난방 판매량(N㎥)',
          width: 150,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
        {
          field: 'gasSplyUsgGasNsqty',
          caption: '가스공급 판매량(N㎥)',
          width: 150,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
        {
          field: 'vhclFuelUsgGasNsqty',
          caption: '차량연료 판매량(N㎥)',
          width: 150,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
        {
          field: 'etcUsgGasNsqty',
          caption: '기타 판매량(N㎥)',
          width: 150,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
      ]
    },
    gridLoadedFillGasWtCryn() {
      this.gridReloadFillGasWtCryn = false;
    },
    gridLoadedFillGasRsrcRcyclnCryn() {
      this.gridReloadFillGasRsrcRcyclnCryn = false;
    },
    gridLoadedFillGasNtsl() {
      this.gridReloadFillGasNtsl = false;
    },
    formatNumber(number) {
      return utils.formatNumberWithComma(number);
    },
    // 실적보고상세저장 호출
    async prtmncRptDtlSave(isSubmit = false){
      // 유효성 체크
      const validationMessage = this.validate(true);

      if(validationMessage){
        alert(validationMessage);
        return;
      }

      if (!isSubmit && !confirm("저장하시겠습니까?")) {
        return;
      }

      if (!validationMessage && confirm("필수입력 조건이 만족하여 제출가능한상태입니다.\n제출하시겠습니까?")) {
        isSubmit = true; // 제출상태로 변경
      }else{
        if(isSubmit){
          return;
        }
      }

      const { updated: wtCrynUpdated } = window.SBGrid3.getSaveData(this.prfmncFillGasWtCrynGridObject, false, true, false);
      const { updated: ntslUpdated } = window.SBGrid3.getSaveData(this.prfmncFillGasNtslGridObject, false, true, false);

      this.$apiCall.post(
          API.PRFMNC_DTL_SAVE,
          {
            wtCrynUpdated,
            ntslUpdated,
            prfmncMst: Object.assign({}, this.fillGasDtlObj.prfmncMst, isSubmit ? { prfmncSttsCd: "SUB" } : {}),
            prfmncFillGas: this.fillGasDtlObj.prfmncFillGas,
          },
          (data) => {
            alert((isSubmit ? '제출' : '저장') + "이 완료되었습니다.");
            let routerChuck = {path: ROUTE.PERFORMANCE_LIST};

            if(!isSubmit) {
              routerChuck = {path: ROUTE.PERFORMANCE_DTL, query: {prfmncId: data.result.prfmncId}};
            }

            this.$router.push(routerChuck)
                .then(() => !isSubmit && location.reload())
                .catch((err) => {
                  console.error('Navigation failed:', err);
                });
          },
          () => {
            alert((isSubmit ? '제출' : '저장') + "에 실패하였습니다.");
          }
      );
    },
    // 자원순환정보시스템 정보적용
    rsrcRcyclnCrynInfoApply(){
      // 그리드1과 그리드2의 데이터 가져오기
      const grid1Data = window.SBGrid3.getAllItems(this.prfmncFillGasWtCrynGridObject);
      const grid2Data = window.SBGrid3.getAllItems(this.prfmncFillGasRsrcRcyclnCrynGridObject);

      if(!grid2Data || (grid2Data?.length || 0) === 0){
        alert("자원순환정보시스템의 반입정보가 존재하지 않아 적용할 수 없습니다.");
        return;
      }

      // 그리드2 데이터 맵 생성 (키값으로 빠르게 접근)
      const grid2DataMap = new Map(
          grid2Data.map(item => [`${item.ctpvStdgCd}_${item.sggStdgCd}`, item])
      );

      // 그리드1 데이터를 반복하면서 그리드2와 비교
      grid1Data.forEach((grid1Item) => {
        const key = `${grid1Item.ctpvStdgCd}_${grid1Item.sggStdgCd}`;

        if (grid2DataMap.has(key)) {
          const grid2Item = grid2DataMap.get(key);

          // 그리드2에 있는 데이터로 덮어쓰기 전 변경이 있는지 확인
          const isModified = this.compareObjects(grid1Item, grid2Item);
          if (isModified) {
            this.updatedWtCrynData.push({ ...grid2Item });
            Object.assign(grid1Item, grid2Item);
          }
        }
      });

      // 그리드2에만 있는 데이터는 그리드1에 추가
      grid2Data.forEach(item => {
        const key = `${item.ctpvStdgCd}_${item.sggStdgCd}`;

        if (!grid1Data.some(grid1Item => `${grid1Item.ctpvStdgCd}_${grid1Item.sggStdgCd}` === key)) {
          // 그리드1에 존재하지 않으면 데이터 추가
          this.insertedWtCrynData.push( item );
          grid1Data.push({ ...item });
        }
      });

      window.SBGrid3.setClientData(this.prfmncFillGasWtCrynGridObject, grid1Data);
    },

    compareObjects(obj1, obj2) {
      const allKeys = [...new Set([...Object.keys(obj1), ...Object.keys(obj2)])];

      const differences = allKeys.filter(key => {
        if (key === '_key_') return false; // _key_ 속성은 비교에서 제외
        return obj1[key] !== obj2[key];
      });

      if (differences.length > 0) {
        differences.forEach(key => {
          console.log(`${key}: grid1Data = ${obj1[key]}, grid2Data = ${obj2[key]}`);
        });
      }

      return differences.length > 0;
    },
    validate(focusOnError = false) {
      // 메탄가스생산현황 - 발생가스 총 포집량 필수값 체크
      if(!this.fillGasDtlObj.prfmncFillGas.mthnGasClcqty){
        if (focusOnError) {
          this.$refs.mthnGasClcqtyInput.focus();
        }
        return "발생가스 총 포집량을 입력해주세요.";
      }

      // 메탄가스생산현황 - 메탄가스농도 필수값 체크
      if(!this.fillGasDtlObj.prfmncFillGas.mthnGasDens){
        if (focusOnError) {
          this.$refs.mthnGasDensInput.focus();
        }
        return "메탄가스농도를 입력해주세요.";
      }

      // [실적제출 시 체크로직] 발생가스 총 포집량 중 메탄가스량 >= 메탄가스 총 사용량 + 에너지생산 외 + 메탄가스 총 판매량
      const mthnTotal = parseFloat(this.fillGasDtlObj.prfmncFillGas.gasStotUsqty) +
          parseFloat(this.fillGasDtlObj.prfmncFillGas.enePrdctnExcqty) +
          parseFloat(this.fillGasDtlObj.totalGasStotNsqty);

      if(parseFloat(this.fillGasDtlObj.prfmncFillGas.mthnGasClcqty) < mthnTotal){
        if (focusOnError) {
          this.$refs.mthnGasClcqtyInput.focus();
        }
        return "발생가스 총 포집량 중 메탄가스량이 메탄가스 총이용량 + 에너지생산외 + 메탄가스 총판매량의 합계보다 작습니다.";
      }

      return "";
    },
  }
}
</script>

<style scoped>

</style>