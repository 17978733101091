<template>
    <div v-show="isVisible" class="modal visible">
      <div class="popup_box" style="width: 70%;">
        <div class="popup_title">
          <h1>고형연료제조 폐기물 투입열량 산정</h1>
        </div>
        <div class="popup_cont">
          <div class="board_write" style="overflow-y: auto; height: 500px">
            <img :src="getImg()" alt="에너지열량 환산기준" >
          </div>
        </div>
        <div class="popup_btn">
          <button type="button" class="modal_close" @click="hide">팝업 닫기</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { storeSwitch } from "@/js/store/store-switch";
  import {buildUri} from "@/js/utils/uri-builder";

  const API = {
    DOWNLOAD: "/api/file/download/{atchFileVldNo}"
  }
  
  export default {
    components: {},
    props: [],
    data() {
      return {
        isVisible: false,
      };
    },
    computed: {},
    watch: {
    },
    created() {
      storeSwitch.setBulb('energyPopup', this);
    },
    beforeMount() {
  
    },
    mounted() {
      this.initialize();
    },
    beforeUnmount() {},
    methods: {
      /** 팝업 Show */
      show() {
        this.isVisible = true;
        document.documentElement.style.overflow = 'hidden';
      },
      /** 팝업 Hide */
      hide() {
        this.isVisible = false;
        document.documentElement.style.overflow = '';
      },
      /** 화면 초기화 */
      async initialize() {
        // 시도 select 초기화
        
      },
      getImg() {
        let imageUrl = buildUri(API.DOWNLOAD, { atchFileVldNo : '8d5ec1dd-4315-4e7b-8df1-32fec57797ca'});


        if(process.env.VUE_APP_ENV === 'local'){
          let imageUrl = buildUri(API.DOWNLOAD, { atchFileVldNo : '7dd6e64e-1686-481e-82f9-21c9b7c5ef29'});
          return `${process.env.VUE_APP_API_PROXY_URL}${imageUrl}`
        } else if(process.env.VUE_APP_ENV === 'stg'){
          let imageUrl = buildUri(API.DOWNLOAD, { atchFileVldNo : 'bf83773e-01e9-47cc-8c2c-07775d77ba0a'});
          return `${imageUrl}`;
        }else{
          return `${imageUrl}`;
        }

        // return process.env.VUE_APP_ENV === 'local'
        //   ? `${process.env.VUE_APP_API_PROXY_URL}${imageUrl}`
        //   : `${imageUrl}`;
      },
    }
  }
  </script>
  
  <style scoped>
  </style>