<template>
    <head>
        <meta name="description" content="이 페이지는 나의문의내역을 조회합니다.">
    </head>
    <div class="sch_form_wrap">
        <div class="sch_form row2">
            <span>
                <label for="">문의일자</label>
                <br-date-from-to label="문의일자" v-model:fromDate="searchParam.startDate" v-model:toDate="searchParam.endDate"/>
            </span>
            <span>
                <label for="onlnDscsnTaskSeCd">문의유형</label>
                <br-form-select label="문의유형" groupCd="COM520" v-model:value="searchParam.onlnDscsnTaskSeCd" allSelectable @change="search" id="onlnDscsnTaskSeCd" />
            </span>
            <span>
                <label for="">검색어</label>
                <br-form-input label="검색어(제목, 내용)" v-model="searchParam.keyword" @enterKeyup="search"/>
            </span>
            <span>
                <label for="onlnDscsnSttsCd">문의상태</label>
                <br-form-select label="문의상태" groupCd="SIS008" v-model:value="searchParam.onlnDscsnSttsCd" allSelectable @change="search" id="onlnDscsnSttsCd" customClass="form_select w45p" />
            </span>
        </div>
        <div class="btn_area">
            <br-anchor-button label="검색" role="button" class="btn sm sch" @click="search"/>
            <br-anchor-button label="초기화" role="button" class="btn sm txtN ico_refresh"  @click="reset"/>
        </div>
    </div>

    <div class="board_info">
        <p class="page">
            <span class="total">전체 <strong>{{ this.count }}</strong></span>
            <span>페이지 <strong>{{ this.searchParam.page }}/{{ this.maxPage }}</strong></span>
        </p>
        <div class="btn_area">
            <a href="javascript:void(0);" role="button" class="btn sm ico_excel" @click.prevent="excelDownload()">엑셀다운로드</a>
        </div>
    </div>
    <div class="board_list scroll">
        <table>
            <caption>문의내용과 답변내용으로 구성</caption>
            <colgroup>
                <col style="width:10%">
                <col style="width:20%">
                <col style="width:30%">
                <col style="width:10%">
                <col style="width:20%">
            </colgroup>
            <thead>
                <tr>
                    <th scope="col">번호</th>
                    <th scope="col">문의유형</th>
                    <th scope="col">제목</th>
                    <th scope="col">문의상태</th>
                    <th scope="col">문의일자</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="this.count <= 0">
                    <td colspan="5">
                      <div style="display: flex; justify-content: center;">
                        <div class="no-data-message">조회조건에 해당하는 데이터가 없습니다.</div>
                      </div>
                    </td>
                </tr>
                <tr v-for="(item, index) in listObj.list" :key="index" @dblclick="detail(item.onlnDscsnSn)">
                  <td aria-label="번호">{{item.onlnDscsnSn}}</td>
                    <td aria-label="문의유형">{{item.onlnDscsnTaskSeNm}}</td>
                    <td aria-label="제목">{{item.dscsnTtl}}</td>
                    <td aria-label="문의상태">{{item.onlnDscsnSttsNm}}</td>
                    <td aria-label="문의일자">{{item.regDt}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <insp-pagination :total="count" :size="searchParam.size"            v-model:page="searchParam.page" v-model:maxPage="maxPage" @clickedPage="search"> </insp-pagination>
</template>
    
    <script>
    import {storeSession} from "@/js/store/store-session";
    import inspPagination from "@/views/sfli/components/inspPagination.vue";
  
    const API = {
        MY_INQRY : "/user/inqry",
        HOME : "/",
        INQRY_EXCEL : "/user/inqry/excel-download-inqry",
    }
    const PAGE_SIZE = 10;
    
    export default {
      components: {inspPagination},
      data() {
        return {
          searchParam : {   // 조회조건
            startDate : "",
            endDate : "",
            dscsnTtl : "",
            onlnDscsnQstnCn : "",
            onlnDscsnSttsCd : "",
            onlnDscsnTaskSeCd : "",
            keyword : "",
            dscsnUserId : "",
            page : 1,
          },
          listObj : {   // 목록
            onlnDscsnSn : "",
            dscsnTtl : "",
            onlnDscsnQstnCn : "",
            onlnDscsnTaskSeCd : "",
            onlnDscsnTaskSeNm : "",
            regDt : "",
            onlnDscsnSttsCd : "",
            onlnDscsnSttsNm : "",
            dscsnUserId : "",
          },
          userinfo: storeSession.getMemberInfo(),
          count : 0,
          maxPage: 1,
        };
      },
      computed: {
      },
      watch: {
      },
      created() {
      },
      mounted() {
        this.search();
      },
      methods: {
        navigateTo(path) {
          if (path) {
            this.$router.push(path).catch(() => {
                console.error("Navigation Error");
            });
          }
          window.scrollTo({ top: 0 });
        },
        excelDownload() {
            const date = new Date();

            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');

            this.$apiCall.download(API.INQRY_EXCEL, {
                "fileName" : "나의문의내역_" + `${year}${month}${day}` + ".xlsx", "dscsnUserId" : this.userinfo.userId
            });
        },
        search() {
            this.searchParam.size = PAGE_SIZE;
            this.searchParam.dscsnUserId = this.userinfo.userId;
            this.$apiCall.get(API.MY_INQRY, this.searchParam, this.success, this.error);
        },
        success(data) {
            if (!data.result) {
                this.error();
                return;
            }
            this.listObj = data.result;
            this.count = data.result.total;
        },
        error() {
            alert("데이터를 가져오는 중 오류가 발생했습니다.");
        },
        detail(onlnDscsnSn) {
            this.$router.push({path : "/my-office/MyInqryDetail", query : {onlnDscsnSn : onlnDscsnSn}}).catch(() => {
            console.error("NavigationMng Error");
            });
        },
        reset() {
            this.searchParam.startDate = "";
            this.searchParam.endDate = "";
            this.searchParam.onlnDscsnTaskSeCd = "",
            this.searchParam.keyword = "",
            this.searchParam.onlnDscsnSttsCd = ""

            this.search();
        },
      },   
    }
    </script>
    
    <style scoped>

    .board_list.scroll > table {
    width: 102rem;
    }

    .underline {
    text-decoration: underline;
    cursor: pointer; /* 클릭할 수 있는 느낌을 주기 위해 커서 변경 */
    }   

    </style>