<template>

  <h2 class="title1 mt_0">실적보고 상세</h2>
  <div class="board_write">
    <table>
      <caption>실적연도, 실적상태, 사업장명, 사업장종류</caption>
      <colgroup>
        <col style="width:18%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:32%">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">실적연월</th>
        <td>{{ formattedPrfmncYm }}</td>
        <th scope="row">실적상태</th>
        <td>{{ fireRtrvlDtlObj.prfmncMst.prfmncSttsNm || "작성중" }}</td>
      </tr>
      <tr>
        <th scope="row">사업장명</th>
        <td>{{ fireRtrvlDtlObj.prfmncMst.bplcNm }}</td>
        <th scope="row">사업장종류</th>
        <td>{{ fireRtrvlDtlObj.prfmncMst.bplcKndNm }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <rjct-rsn-area :prfmncBasicInfo="fireRtrvlDtlObj.prfmncMst" />
  <prfmnc-mdfcn-prm-area :prfmncBasicInfo="fireRtrvlDtlObj.prfmncMst" />

  <div class="board_info mt_40">
    <h3 class="title2">폐기물 반입 현황</h3>
  </div>

  <sb-grid
      id="prfmncDtlFireRtrvlWtCrynGrid"
      :dataSource="dataSourceFireRtrvlWtCryn"
      :columns="gridColumnsFireRtrvlWtCryn"
      :gridattr="gridAttrFireRtrvlWtCryn"
      v-model:grid="prfmncFireRtrvlWtCrynGridObject"
      @loaded="gridLoadedFireRtrvlWtCryn"
      :refreshGrid="gridReloadFireRtrvlWtCryn"
      @change="changeFireRtrvlWtCryn"
  />

  <div class="board_info mt_40">
    <h3 class="title2">폐기물 처리 현황</h3>
  </div>
  <div class="grid_search">
    <h4>폐기물 처리 현황 <span>※ 전처리량(톤):전처리되어 소각로에 투입되지 않는 폐기물</span></h4>
  </div>
  <div class="board_write thead">
    <table>
      <caption>폐기물 처리 현황</caption>
      <colgroup>
        <col style="width: 20%;" v-if="this.fireRtrvlDtlObj.prfmncMst.prfmncYr === '2024'">
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;" v-if="this.fireRtrvlDtlObj.prfmncMst.prfmncYr === '2024'">
      </colgroup>
      <thead>
      <tr>
        <th scope="col" v-if="this.fireRtrvlDtlObj.prfmncMst.prfmncYr === '2024'">이월량</th>
        <th scope="col">반입량</th>
        <th scope="col">전처리량</th>
        <th scope="col">투입량</th>
        <th scope="col" v-if="this.fireRtrvlDtlObj.prfmncMst.prfmncYr === '2024'">잔량</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="txt_right" aria-label="이월량" v-if="this.fireRtrvlDtlObj.prfmncMst.prfmncYr === '2024'">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.wtCrfqty) }} 톤
        </td>
        <td class="txt_right" aria-label="반입량">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.wtSumCryqty) }} 톤
        </td>
        <td class="txt_right" aria-label="전처리량">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.prprcsWstqty) }} 톤
        </td>
        <td class="txt_right" aria-label="투입량">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.wtSumInpqty) }} 톤
        </td>
        <td class="txt_right" aria-label="잔량" v-if="this.fireRtrvlDtlObj.prfmncMst.prfmncYr === '2024'">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.wtRmnqty) }} 톤
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h4 class="title3">시설별 투입/생산 현황</h4>

  <div class="grid_search">
    <h4>시설별 투입/생산 현황 <span>※ 소각기 정보는 마이오피스 화면에서 관리 할 수 있습니다.</span></h4>
  </div>
  <sb-grid
      id="prfmncDtlFireRtrvlPrdctnGrid"
      :dataSource="dataSourceFireRtrvlPrdctn"
      :columns="gridColumnsFireRtrvlPrdctn"
      :gridattr="gridAttrFireRtrvlPrdctn"
      :param="fireRtrvlDtlObj.prfmncMst"
      v-model:grid="prfmncFireRtrvlPrdctnGridObject"
      @loaded="gridLoadedFireRtrvlPrdctn"
      :refreshGrid="gridReloadFireRtrvlPrdctn"
      @change="changeFireRtrvlPrdctn"
  />

  <div class="board_write thead">
    <table>
      <caption>평균 발열량</caption>
      <colgroup>
        <col style="width: 20%;">
        <col style="width: 30%;">
        <col style="width: 50%;">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">평균 발열량</th>
        <td class="txt_right" aria-label="평균 발열량">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.fireAvgLcp) }} kcal/kg
        </td>
        <td class="txt_left" aria-label="평균 발열량">
          * 설비에 투입한 폐기물의 평균 저위발열량
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info mt_40">
    <h3 class="title2">보조에너지 사용량</h3>
  </div>
  <div class="board_write thead">
    <table>
      <caption>보조에너지 사용량</caption>
      <colgroup>
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
      </colgroup>
      <thead>
      <tr>
        <th scope="col" colspan="3">보조연료 사용량</th>
        <th scope="col" colspan="2">전기 사용량(MWh)</th>
      </tr>
      <tr>
        <th scope="col">유류(㎘)</th>
        <th scope="col">LNG(㎥)</th>
        <th scope="col">기타(TOE)</th>
        <th scope="col">외부반입(한전)</th>
        <th scope="col">자체생산</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="txt_right" aria-label="유류(㎘)">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.oilAsstFuelUsqty) }} ㎘
        </td>
        <td class="txt_right" aria-label="LNG(㎥)">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.lngAsstFuelUsqty) }} ㎥
        </td>
        <td class="txt_right" aria-label="기타(TOE)">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.etcAsstFuelUsqty) }} TOE
        </td>
        <td class="txt_right" aria-label="외부반입(한전)">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.crynPrvtmAsstFuelUsqty) }} MWh
        </td>
        <td class="txt_right" aria-label="자체생산">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.prdctnPrvtmAsstFuelUsqty) }} MWh
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info mt_40">
    <h3 class="title2">에너지 회수 현황</h3>
  </div>

  <div class="board_list">
    <table>
      <caption>에너지 회수 현황</caption>
      <colgroup>
        <col :style="{width: `calc(100% / 6)`}">
        <col :style="{width: `calc(100% / 6)`}">
        <col :style="{width: `calc(100% / 6)`}">
        <col :style="{width: `calc(100% / 6)`}">
        <col :style="{width: `calc(100% / 6)`}">
        <col :style="{width: `calc(100% / 6)`}">
      </colgroup>
      <thead>
      <tr>
        <th scope="col">총 에너지 발생가능량<br />(Gcal)</th>
        <th scope="col">총 에너지 회수량(Gcal)<br />(X + Y)</th>
        <th scope="col">에너지 발생량(Gcal)<br />(X)</th>
        <th scope="col">미이용(Gcal)<br />(Y)</th>
        <th scope="col">에너지 이용량(Gcal)<br />(A + B + C)</th>
        <th scope="col">에너지 이용효율(%)</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="txt_right" aria-label="총 에너지 발생가능량(Gcal)">
          {{ formatNumber(fireRtrvlDtlObj.totalEneOcrnPsbltyQty) }} Gcal
        </td>
        <td class="txt_right" aria-label="총 에너지 회수량 (Gcal) (X + Y)">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.eneRtrqty) }} Gcal
        </td>
        <td class="txt_right" aria-label="에너지 발생량(Gcal) (X)">
          {{ formatNumber(fireRtrvlDtlObj.eneOcrnQtyTotal) }} Gcal
        </td>
        <td class="txt_right" aria-label="미이용(Gcal) (Y)">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.eneUnuqty) }} Gcal
        </td>
        <td class="txt_right" aria-label="에너지 이용량(Gcal) (A + B + C)">
          {{ formatNumber(fireRtrvlDtlObj.eneutzQtyTotal) }} Gcal
        </td>
        <td class="txt_right" aria-label="에너지 이용효율(%)">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.eneUtztnEfcnc) }} %
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info mt_40">
    <h3 class="title2">에너지 이용 현황</h3>
  </div>

  <div class="board_list">
    <table>
      <caption>에너지 이용 현황</caption>
      <colgroup>
        <col :style="{width: `calc(100% / 7)`}">
        <col :style="{width: `calc(100% / 7)`}">
        <col :style="{width: `calc(100% / 7)`}">
        <col :style="{width: `calc(100% / 7)`}">
        <col :style="{width: `calc(100% / 7)`}">
        <col :style="{width: `calc(100% / 7)`}">
        <col :style="{width: `calc(100% / 7)`}">
      </colgroup>
      <thead>
      <tr>
        <th scope="col" colspan="3">자체사용</th>
        <th scope="col" colspan="3">외부공급</th>
        <th scope="col" rowspan="2" class="bg-blue-light">발전설비투입량(Gcal)<br />(C)</th>
      </tr>
      <tr>
        <th scope="col">소계(A)</th>
        <th scope="col" class="bg-orange-light">열사용(Gcal)</th>
        <th scope="col" class="bg-yellow-light">기타(Gcal)</th>
        <th scope="col">소계(B)</th>
        <th scope="col" class="bg-orange-light">열공급(Gcal)</th>
        <th scope="col" class="bg-yellow-light" style="border-right: 1px solid #e0e0e0;">기타(Gcal)</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="txt_right" aria-label="[자체사용] 소계(A)">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.stotUsqty) }} Gcal
        </td>
        <td class="txt_right" aria-label="[자체사용] 열사용(Gcal)">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.htsrcUsqty) }} Gcal
        </td>
        <td class="txt_right" aria-label="[자체사용] 기타(Gcal)">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.etcUsgUsqty) }} Gcal
        </td>
        <td class="txt_right" aria-label="[외부공급] 소계(B)">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.stotNsqty) }} Gcal
        </td>
        <td class="txt_right" aria-label="[외부공급] 열공급(Gcal)">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.htsrcSumNsqty) }} Gcal
        </td>
        <td class="txt_right" aria-label="[외부공급] 기타(Gcal)">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.etcUsgSumNsqty) }} Gcal
        </td>
        <td class="txt_right" aria-label="발전설비투입량(Gcal) (C)">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.elctgnEquipInpqty) }} Gcal
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h4 class="title3">전력발전 생산/이용 현황</h4>
  <div class="board_write thead">
    <table>
      <caption>전력발전 생산/이용 현황</caption>
      <colgroup>
        <col style="width:25%">
        <col style="width:25%">
        <col style="width:25%">
        <col style="width:25%">
      </colgroup>
      <thead>
      <tr>
        <th scope="col" class="bg-blue-light">발전량 합계(MWh)</th>
        <th scope="col" class="bg-blue-light">자체사용량(MWh)</th>
        <th scope="col" class="bg-blue-light">외부판매량(MWh)</th>
        <th scope="col" class="bg-blue-light">기타(MWh)</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="txt_right" aria-label="발전량 합계(MWh)">
          {{ formatNumber(fireRtrvlDtlObj.elctgnQtyTotal) }} MWh
        </td>
        <td class="txt_right" aria-label="자체사용량(MWh)">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.prvtmSumUsqty) }} MWh
        </td>
        <td class="txt_right" aria-label="외부판매량(MWh)">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.prvtmSumNsqty) }} MWh
        </td>
        <td class="txt_right" aria-label="기타(MWh)">
          {{ formatNumber(fireRtrvlDtlObj.prfmncFireRtrvl.etcPrvtmUsqty) }} MWh
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <h4 class="title3">판매 상세 현황</h4>
  <div class="grid_search">
    <h4>판매 상세 현황 <span>※ 판매업체 목록은 정보등록 화면의 에너지공급사업장 현황에서 관리 할 수 있습니다.</span></h4>
  </div>
  <sb-grid
      id="prfmncFireRtrvlNtslGrid"
      :dataSource="dataSourceFireRtrvlNtsl"
      :columns="gridColumnsFireRtrvlNtsl"
      :gridattr="gridAttrFireRtrvlNtsl"
      v-model:grid="prfmncFireRtrvlNtslGridObject"
      @loaded="gridLoadedFireRtrvlNtsl"
      :refreshGrid="gridReloadFireRtrvlNtsl"
      @change="changeFireRtrvlNtsl"
  />

  <!-- 실적상태이력 현황 (신규등록인 경우 노출안함) -->
  <prfmnc-stts-hstry v-if="fireRtrvlDtlObj.prfmncMst.prfmncId" :prfmncId="fireRtrvlDtlObj.prfmncMst.prfmncId" />

  <!-- 수정허용요청 이력 현황 (신규등록인 경우 노출안함) -->
  <prfmnc-mdfcn-prm-hstry v-if="fireRtrvlDtlObj.prfmncMst.prfmncId" :prfmncId="fireRtrvlDtlObj.prfmncMst.prfmncId" />

  <!-- 버튼 -->
  <common-button-area
      :prfmncBasicInfo="fireRtrvlDtlObj.prfmncMst"
  />
</template>

<script>
import SbGrid from "@/components/SbGrid.vue";
import utils from "@/js/utils/utils";
import commonButtonArea from "@/views/prfmnc-rpt/dtl/components/CommonButtonArea.vue";
import prfmncSttsHstry from "@/views/prfmnc-rpt/components/PrfmncSttsHstry.vue";
import PrfmncMdfcnPrmHstry from "@/views/prfmnc-rpt/components/PrfmncMdfcnPrmHstry.vue";
import PrfmncMdfcnPrmArea from "@/views/prfmnc-rpt/dtl/components/PrfmncMdfcnPrmArea.vue";
import RjctRsnArea from "@/views/prfmnc-rpt/dtl/components/RjctRsnArea.vue";

const CODE = {
  PERFORMANCE_STATUS: "PFM001", // 실적상태코드
};

export default {
  components: {
    SbGrid,
    commonButtonArea,
    prfmncSttsHstry,
    PrfmncMdfcnPrmHstry,
    PrfmncMdfcnPrmArea,
    RjctRsnArea
  },
  props: ['prfmncFireRtrvl', 'prvPrfmncFireRtrvl', 'prfmncFireRtrvlPrdctnList', 'prfmncFireRtrvlWtCrynList', 'prfmncFireRtrvlNtslList', 'prfmncMst', 'prfmncFclt', 'showDetail'],
  data() {
    return {
      prfmncSttsList: [], // 실적상태코드

      updatedWtCrynData: [], // 폐기물반입현황 수정된 행
      insertedWtCrynData: [], // 폐기물반입현황 추가된 행

      // 실적 매립가스 상세정보 데이터
      fireRtrvlDtlObj:{
        wtStotCryqtyTotal: 0, // 반입량 합계
        elctgnQtyTotal: 0, // 발전량 합계 (자체사용량 + 외부판매량 + 기타)
        totalEneOcrnPsbltyQty: 0, // 총에너지발생가능량
        wtInpqtyTotal: 0, // 소각처리량 합계
        eneOcrnQtyTotal: 0, // 에너지발생량 합계
        eneutzQtyTotal: 0, // 에너지이용량 합계
        totalGasStotNsqty: 0, // 메탄가스 총 판매량 합계
        prfmncMst: {},
        prfmncFireRtrvlWtCrynList : [], // 폐기물반입현황
        prfmncFireRtrvlNtslList : [], // 소각판매현황
        prfmncFireRtrvl: {
          wtCrfqty: 0, // 폐기물이월량
          wtSumCryqty: 0, // 폐기물합계반입량
          prprcsWstqty: 0, // 전처리폐기물량
          wtSumInpqty: 0, // 폐기물합계투입량
          wtRmnqty: 0, // 폐기물잔량
          fireAvgLcp: 0, // 소각평균저위발열량
          eneRtrqty: 0, // 에너지회수량
          eneUnuqty: 0, // 에너지미이용량
          eneUtztnEfcnc: 0, // 에너지이용효율
          stotUsqty: 0, // 소계사용량
          htsrcUsqty: 0, // 열원사용량
          etcUsgUsqty: 0, // 기타용도사용량
          elctgnUsgUsqty: 0, // 발전용도사용량
          stotNsqty: 0, // 소계판매량
          htsrcSumNsqty: 0, // 열원합계판매량
          etcUsgSumNsqty: 0, // 기타용도합계판매량
          elctgnUsgNsqty: 0, // 발전용도판매량
          elctgnEquipInpqty: 0, // 발전설비투입량
          prvtmSumNsqty: 0, // 전기합계판매량
          prvtmSumUsqty: 0, // 전기합계사용량
          etcPrvtmUsqty: 0, // 기타전기사용량
          oilAsstFuelUsqty: 0, // 유류보조연료사용량
          lngAsstFuelUsqty: 0, // LNG보조연료사용량
          etcAsstFuelUsqty: 0, // 기타보조연료사용량
          crynPrvtmAsstFuelUsqty: 0, // 반입전기보조연료사용량
          prdctnPrvtmAsstFuelUsqty: 0, // 생산전기보조연료사용량
        }, // 매립가스실적 (메탄가스생산현황, 메탄가스사용현황)
        prvPrfmncFireRtrvl: {
          wtCrfqty: 0, // 폐기물이월량
          wtSumCryqty: 0, // 폐기물합계반입량
          prprcsWstqty: 0, // 전처리폐기물량
          wtSumInpqty: 0, // 폐기물합계투입량
          wtRmnqty: 0, // 폐기물잔량
          fireAvgLcp: 0, // 소각평균저위발열량
          eneRtrqty: 0, // 에너지회수량
          eneUnuqty: 0, // 에너지미이용량
          eneUtztnEfcnc: 0, // 에너지이용효율
          stotUsqty: 0, // 소계사용량
          htsrcUsqty: 0, // 열원사용량
          etcUsgUsqty: 0, // 기타용도사용량
          elctgnUsgUsqty: 0, // 발전용도사용량
          stotNsqty: 0, // 소계판매량
          htsrcSumNsqty: 0, // 열원합계판매량
          etcUsgSumNsqty: 0, // 기타용도합계판매량
          elctgnUsgNsqty: 0, // 발전용도판매량
          elctgnEquipInpqty: 0, // 발전설비투입량
          prvtmSumNsqty: 0, // 전기합계판매량
          prvtmSumUsqty: 0, // 전기합계사용량
          etcPrvtmUsqty: 0, // 기타전기사용량
          oilAsstFuelUsqty: 0, // 유류보조연료사용량
          lngAsstFuelUsqty: 0, // LNG보조연료사용량
          etcAsstFuelUsqty: 0, // 기타보조연료사용량
          crynPrvtmAsstFuelUsqty: 0, // 반입전기보조연료사용량
          prdctnPrvtmAsstFuelUsqty: 0, // 생산전기보조연료사용량
        } // 전월 매립가스실적
      },


      /**************** S : 폐기물반입현황 Grid ****************/
      dataSourceFireRtrvlWtCryn: [],
      gridColumnsFireRtrvlWtCryn: [],
      gridAttrFireRtrvlWtCryn: {
        checkable: false,
        pageable: false,
        editable : false,
        showRowNo : false,
        excelExport: {
          fileName: '폐기물반입현황(' + this.prfmncMst.bplcKndNm + ').xlsx',
          includeHeader: true,
          includeFormula: true,
        },
        showStatus:false,
      },
      prfmncFireRtrvlWtCrynGridObject: null,
      gridReloadFireRtrvlWtCryn: false,
      /**************** E : 폐기물반입현황 Grid ****************/

      /**************** S : 시설별 투입/생산 현황 Grid ****************/
      dataSourceFireRtrvlPrdctn: [],
      gridColumnsFireRtrvlPrdctn: [],
      gridAttrFireRtrvlPrdctn: {
        checkable: false,
        pageable: false,
        editable : false,
        showRowNo : false,
        excelExport: {
          fileName: '시설별 투입생산현황(' + this.prfmncMst.bplcKndNm + ').xlsx',
          includeHeader: true,
          includeFormula: true,
        },
        showStatus:false,
      },
      prfmncFireRtrvlPrdctnGridObject: null,
      gridReloadFireRtrvlPrdctn: false,
      /**************** E : 시설별 투입/생산 현황 Grid ****************/

      /**************** S : 메탄가스판매현황 Grid ****************/
      dataSourceFireRtrvlNtsl: [],
      gridColumnsFireRtrvlNtsl: [],
      gridAttrFireRtrvlNtsl: {
        checkable: false,
        pageable: false,
        editable: false,
        showRowNo : false,
        excelExport: {
          fileName: '판매상세현황(' + this.prfmncMst.bplcKndNm + ').xlsx',
          includeHeader: true,
          includeFormula: true,
        },
        showStatus:false,
      },
      prfmncFireRtrvlNtslGridObject: null,
      gridReloadFireRtrvlNtsl: false,
      /**************** E : 메탄가스판매현황 Grid ****************/
    };
  },
  computed: {
    /**
     * [실적보고상세 - 실적연월]
     * YYYY-MM 포맷처리
     */
    formattedPrfmncYm() {
      return (this.fireRtrvlDtlObj.prfmncMst.prfmncYm?.substring(0, 4) ?? "") + "-" + (this.fireRtrvlDtlObj.prfmncMst.prfmncYm?.substring(4, 6) ?? "");
    },
  },
  watch: {
    prfmncMst: {
      handler(newVal) {
        this.fireRtrvlDtlObj.prfmncMst = {...this.fireRtrvlDtlObj.prfmncMst, ...newVal};
      },
      immediate: true,
      deep:true,
    },
    prfmncFireRtrvl() {
      this.fireRtrvlDtlObj.prfmncFireRtrvl = {...this.fireRtrvlDtlObj.prfmncFireRtrvl, ...this.prfmncFireRtrvl};
    },
    prvPrfmncFireRtrvl() {
      this.fireRtrvlDtlObj.prvPrfmncFireRtrvl = {...this.fireRtrvlDtlObj.prvPrfmncFireRtrvl, ...this.prvPrfmncFireRtrvl};

      // 실적아이디가 존재하지 않는 등록을 하려는 상태인 경우 폐기물처리현황의 이월량은 전월의 실적잔량을 가져온다.
      if(!this.fireRtrvlDtlObj.prfmncMst.prfmncId){
        this.fireRtrvlDtlObj.prfmncFireRtrvl.wtCrfqty = this.fireRtrvlDtlObj.prvPrfmncFireRtrvl.wtRmnqty;
      }
    },
    prfmncFireRtrvlPrdctnList() {
      this.dataSourceFireRtrvlPrdctn = this.prfmncFireRtrvlPrdctnList;
      this.initializePrfmncFireRtrvlPrdctnGridColumns();
      const itemCount = this.prfmncFireRtrvlPrdctnList.length;
      this.gridAttrFireRtrvlPrdctn.height = Math.max((itemCount * 30) + 190, 210);
      this.gridReloadFireRtrvlPrdctn = true;

      // 소각처리량(톤) 합계
      this.fireRtrvlDtlObj.wtInpqtyTotal = this.prfmncFireRtrvlPrdctnList.reduce((acc, item) => acc + (item.wtInpqty || 0), 0);

      // 화면 초기 진입 시 시설별 투입/생산 현황의 소각처리량(톤) 합계값을 이용한 계산식을 통해 에너지회수현황 총에너지발생가능량을 노출
      this.fireRtrvlDtlObj.totalEneOcrnPsbltyQty = (parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.fireAvgLcp) * parseFloat(this.fireRtrvlDtlObj.wtInpqtyTotal)) / 1000;

      // 화면 초기 진입 시 시설별 투입/생산 현황의 에너지발생량합계를 에너지회수현황 에너지발생량에 노출
      this.fireRtrvlDtlObj.eneOcrnQtyTotal = this.prfmncFireRtrvlPrdctnList.reduce((acc, item) => acc + (item.enePrdqty || 0), 0);
    },
    prfmncFireRtrvlWtCrynList() {
      this.dataSourceFireRtrvlWtCryn = { data: this.prfmncFireRtrvlWtCrynList.list, schema: { id: 'sggStdgCd'}};
      this.initializePrfmncFireRtrvlWtCrynGridColumns();
      const itemCount = this.prfmncFireRtrvlWtCrynList.list.length;
      this.gridAttrFireRtrvlWtCryn.height = Math.max((itemCount * 30) + 190, 210);
      this.gridReloadFireRtrvlWtCryn = true;
    },
    prfmncFireRtrvlNtslList() {
      this.dataSourceFireRtrvlNtsl = this.prfmncFireRtrvlNtslList.list;
      this.initializePrfmncFireRtrvlNtslGridColumns();
      const itemCount = this.prfmncFireRtrvlNtslList.list.length;
      this.gridAttrFireRtrvlNtsl.height = Math.max((itemCount * 30) + 190, 210);
      this.gridReloadFireRtrvlNtsl = true;
    },

    // 반입 그리드에서 반입량 합계가 변경되면 폐기물처리현황의 반입량항목에 적용
    'fireRtrvlDtlObj.wtStotCryqtyTotal': {
      handler(newValue) {
        this.fireRtrvlDtlObj.prfmncFireRtrvl.wtSumCryqty = newValue;
      },
      immediate: true
    },

    // 소각처리량(톤) 합계가 변경 시 이벤트
    'fireRtrvlDtlObj.wtInpqtyTotal': {
      handler(newValue) {
        this.fireRtrvlDtlObj.prfmncFireRtrvl.wtSumInpqty = newValue; // 폐기물처리현황의 투입량항목에 적용
        this.updateEneOcrnPsbltyQtyTotal(); // 에너지회수현황의 총에너지발생가능량 재계산
      },
      immediate: true
    },

    // 폐기물처리현황-이월량 값 변경 시 잔량 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.wtCrfqty': 'updateWtRmnqty',
    // 폐기물처리현황-반입량 값 변경 시 잔량 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.wtSumCryqty': 'updateWtRmnqty',
    // 폐기물처리현황-전처리량 값 변경 시 잔량 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.prprcsWstqty': 'updateWtRmnqty',
    // 폐기물처리현황-투입량 값 변경 시 잔량 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.wtSumInpqty': 'updateWtRmnqty',

    // 에너지이용현황-자체사용소계 값 변경 시 에너지이용량 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.stotUsqty': 'updateEneUtzqty',
    // 에너지이용현황-외부공급소계 값 변경 시 에너지이용량 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.stotNsqty': 'updateEneUtzqty',
    // 에너지이용현황-발전설비투입량 값 변경 시 에너지이용량 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.elctgnEquipInpqty': 'updateEneUtzqty',

    // 평균발열량 값 변경 시 에너지회수현황의 총에너지발생가능량 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.fireAvgLcp': 'updateEneOcrnPsbltyQtyTotal',
    // // 소각처리량(톤) 합계 값 변경 시 에너지회수현황의 총에너지발생가능량 재계산
    // 'fireRtrvlDtlObj.wtInpqtyTotal': 'updateEneOcrnPsbltyQtyTotal',

    // 에너지회수현황-에너지발생량 값 변경 시 총에너지회수량 재계산
    'fireRtrvlDtlObj.eneOcrnQtyTotal': 'updateEneRtrqty',
    // 에너지회수현황-미이용 값 변경 시 총에너지회수량 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.eneUnuqty': 'updateEneRtrqty',

    // 자체사용-열사용 값 변경 시 자체사용 소계 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.htsrcUsqty': 'updateStotUsqty',
    // 자체사용-기타 값 변경 시 자체사용 소계 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.etcUsgUsqty': 'updateStotUsqty',

    // 외부공급-열공급 값 변경 시 외부공급 소계 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.htsrcSumNsqty': 'updateStotNsqty',
    // 외부공급-기타 값 변경 시 외부공급 소계 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.etcUsgSumNsqty': 'updateStotNsqty',

    // 자체사용량 값 변경 시 발전량합계 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.prvtmSumUsqty': 'updateElctgnQtyTotal',
    // 외부판매량 값 변경 시 발전량합계 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.prvtmSumNsqty': 'updateElctgnQtyTotal',
    // 기타 값 변경 시 발전량합계 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.etcPrvtmUsqty': 'updateElctgnQtyTotal',
  },
  created() {
    this.initialize();
  },
  mounted() {
  },
  methods: {
    // 폐기물처리현황의 잔량 계산
    updateWtRmnqty() {
      this.fireRtrvlDtlObj.prfmncFireRtrvl.wtRmnqty =
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.wtCrfqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.wtSumCryqty) -
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.prprcsWstqty) -
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.wtSumInpqty);
    },
    // 에너지회수현황의 총에너지발생가능량 계산
    updateEneOcrnPsbltyQtyTotal() {
      this.fireRtrvlDtlObj.totalEneOcrnPsbltyQty =
          (parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.fireAvgLcp) * parseFloat(this.fireRtrvlDtlObj.wtInpqtyTotal)) / 1000;
    },
    // 에너지회수현황의 에너지이용량 계산
    updateEneUtzqty() {
      this.fireRtrvlDtlObj.eneutzQtyTotal =
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.stotUsqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.stotNsqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.elctgnEquipInpqty);
    },
    // 에너지회수현황의 총에너지회수량 계산
    updateEneRtrqty() {
      this.fireRtrvlDtlObj.prfmncFireRtrvl.eneRtrqty =
          parseFloat(this.fireRtrvlDtlObj.eneOcrnQtyTotal) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.eneUnuqty);
    },
    // 에너지이용현황의 자체사용 소계 계산
    updateStotUsqty() {
      this.fireRtrvlDtlObj.prfmncFireRtrvl.stotUsqty =
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.htsrcUsqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.etcUsgUsqty);
    },
    // 에너지이용현황의 외부공급 소계 계산
    updateStotNsqty() {
      this.fireRtrvlDtlObj.prfmncFireRtrvl.stotNsqty =
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.htsrcSumNsqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.etcUsgSumNsqty);
    },
    // 전력발전 생산/이용 현황의 발전량 합계 계산
    updateElctgnQtyTotal() {
      this.fireRtrvlDtlObj.elctgnQtyTotal =
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.prvtmSumUsqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.prvtmSumNsqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.etcPrvtmUsqty);
    },
    // 폐기물 반입현황 총 반입량 가져오기
    changeFireRtrvlWtCryn(values){
      values.forEach(() => {
        // 그리드의 모든 데이터를 가져와서 반입량 항목의 모든 값의 합계를 계산
        const wtCrynAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlWtCrynGridObject);
        const total = wtCrynAllItems.reduce((sum, item) => {
          return sum + (item.wtStotCryqty || 0);
        }, 0);

        this.fireRtrvlDtlObj.wtStotCryqtyTotal = total;
      });
    },
    // 시설별 투입/생산 현황 변경 이벤트
    changeFireRtrvlPrdctn(values){
      values.forEach(item => {
        if (item.field === 'wtInpqty') {
          // 그리드의 모든 데이터를 가져와서 소각처리량(톤) 항목의 모든 값의 합계를 계산
          const prdctnAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlPrdctnGridObject);
          const total = prdctnAllItems.reduce((sum, item) => {
            return sum + (item.wtInpqty || 0);
          }, 0);

          this.fireRtrvlDtlObj.wtInpqtyTotal = total;
        }
        if (item.field === 'enePrdqty') {
          // 그리드의 모든 데이터를 가져와서 에너지발생량 항목의 모든 값의 합계를 계산
          const prdctnAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlPrdctnGridObject);
          const total = prdctnAllItems.reduce((sum, item) => {
            return sum + (item.enePrdqty || 0);
          }, 0);

          this.fireRtrvlDtlObj.eneOcrnQtyTotal = total;
        }
      });
    },
    // 소각 판매현황 총 판매량 가져오기
    changeFireRtrvlNtsl(values){
      // 변경된 그리드 데이터 중 판매량이 변경된 경우 이벤트
      values.forEach(item => {
        if (item.field === 'htsrcNsqty') {
          // 그리드의 모든 데이터를 가져와서 열 판매량 항목의 모든 값의 합계를 계산 후 에너지이용현황(외부공급-열 공급) 항목에 적용
          const ntslAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlNtslGridObject);
          const total = ntslAllItems.reduce((sum, item) => {
            return sum + (item.htsrcNsqty || 0);
          }, 0);

          this.fireRtrvlDtlObj.prfmncFireRtrvl.htsrcSumNsqty = total;
        }
        if (item.field === 'prvtmNsqty') {
          // 그리드의 모든 데이터를 가져와서 전력 판매량 항목의 모든 값의 합계를 계산 후 전력발전 생산/이용 현황(외부판매량) 항목에 적용
          const ntslAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlNtslGridObject);
          const total = ntslAllItems.reduce((sum, item) => {
            return sum + (item.prvtmNsqty || 0);
          }, 0);

          this.fireRtrvlDtlObj.prfmncFireRtrvl.prvtmSumNsqty = total;
        }
        if (item.field === 'etcEneNsqty') {
          // 그리드의 모든 데이터를 가져와서 기타 판매량 항목의 모든 값의 합계를 계산 후 에너지이용현황(외부공급-기타) 항목에 적용
          const ntslAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlNtslGridObject);
          const total = ntslAllItems.reduce((sum, item) => {
            return sum + (item.etcEneNsqty || 0);
          }, 0);

          this.fireRtrvlDtlObj.prfmncFireRtrvl.etcUsgSumNsqty = total;
        }
      });
    },
    async initialize() {
      await this.loadPrfmncSttsCd();
    },
    // 공통코드(실적상태코드) 로드
    async loadPrfmncSttsCd() {
      const prfmncSttsList = this.$store.getters.getCodeList({groupCd: CODE.PERFORMANCE_STATUS, useYn: 'Y'});
      this.prfmncSttsList = prfmncSttsList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    // 폐기물 반입현황 그리드 컬럼 세팅
    initializePrfmncFireRtrvlWtCrynGridColumns() {
      this.gridColumnsFireRtrvlWtCryn = [
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        {
          caption: '반입',
          width: 200,
          part: 'head',
          columns : [
            {
              field: 'ctpvStdgNm',
              caption: '시·도',
              width: 100,
              editable: false,
            },
            {
              field: 'sggStdgNm',
              caption: '시·군·구',
              width: 100,
              editable: false,
            }
          ],
          total: {
            aggregates: [
            ],
            header: [
              { template: '계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        {
          field: 'wtStotCryqty',
          caption: '반입량 합계(톤)',
          unit: '%',
          align: 'right',
          dataType: 'number',
          editable: false,
          colCss: 'grid-disabled',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          calc: {
            require: ['bplcDschrgWstqty', 'hmeLifeWstqty', 'bplcLifeWstqty', 'largeWstqty', 'wstsrtWstqty', 'wstwdtWstqty', 'wstsftWstqty', 'wstvnlWstqty', 'etcWstqty'],
            eval: 'sum',
            nullAs: 0,
            dataType: 'number',
          },
          format: '#,##0.00',
        },
        {
          field: 'bplcDschrgWstqty',
          caption: '배출사업장계(톤)',
          width: 100,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
        {
          caption: '종량제에 의한 혼합배출',
          columns : [
            {
              field: 'hmeLifeWstqty',
              caption: '가정생활(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'bplcLifeWstqty',
              caption: '사업장생활(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            }
          ]
        },
        {
          caption: '종량제 외 배출',
          columns : [
            {
              field: 'largeWstqty',
              caption: '대형폐기물(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'wstsrtWstqty',
              caption: '폐합성수지류(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'wstwdtWstqty',
              caption: '폐목재(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'wstsftWstqty',
              caption: '폐섬유(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'wstvnlWstqty',
              caption: '영농폐비닐(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            },
            {
              field: 'etcWstqty',
              caption: '기타(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
            }
          ]
        }
      ]
    },
    // 시설별 투입/생산 현황 그리드 컬럼 세팅
    initializePrfmncFireRtrvlPrdctnGridColumns() {
      this.gridColumnsFireRtrvlPrdctn = [
        { field: 'fcltSqno', caption: '시설순번', visible: false, editable: false },
        {
          field: 'fcltNm',
          caption: '시설명',
          width: 450,
          unit: 'px',
          align: 'left',
          part: 'head',
          editable: false,
          total: {
            aggregates: [
            ],
            header: [
              { template: '계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        {
          field: 'wtInpqty',
          caption: '소각처리량(톤)',
          width: 420,
          unit: 'px',
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
        {
          field: 'enePrdqty',
          caption: '에너지발생량(Gcal)',
          width: 100,
          unit: '%',
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
      ]
    },
    // 메탄가스판매현황 그리드 컬럼 세팅
    initializePrfmncFireRtrvlNtslGridColumns() {
      this.gridColumnsFireRtrvlNtsl = [
        { field: 'eneBzentySn', caption: '에너지업체일련번호', visible: false, editable: false },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        {
          caption: '판매 업체',
          width: 700,
          part: 'head',
          columns : [
            {
              field: 'ctpvStdgNm',
              caption: '시·도',
              width: 200,
              editable: false,
            },
            {
              field: 'sggStdgNm',
              caption: '시·군·구',
              width: 200,
              editable: false,
            },
            {
              field: 'bzentyNm',
              caption: '업체명',
              width: 300,
              align: 'left',
              editable: false,
            },
          ],
          total: {
            aggregates: [
            ],
            header: [
              { template: '계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        {
          field: 'htsrcNsqty',
          caption: '열 판매량(Gcal)',
          captionCss:'bg-orange-light',
          width: 170,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
        {
          field: 'prvtmNsqty',
          caption: '전력 판매량(MWh)',
          captionCss:'bg-blue-light',
          width: 170,
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
        {
          field: 'etcEneNsqty',
          caption: '기타 판매량(Gcal)',
          captionCss:'bg-yellow-light',
          unit: '%',
          align: 'right',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00',
        },
      ]
    },
    gridLoadedFireRtrvlWtCryn() {
      this.gridReloadFireRtrvlWtCryn = false;
    },
    gridLoadedFireRtrvlPrdctn() {
      this.gridReloadFireRtrvlPrdctn = false;
    },
    gridLoadedFireRtrvlNtsl() {
      this.gridReloadFireRtrvlNtsl = false;
    },
    formatNumber(number) {
      return utils.formatNumberWithComma(number);
    },
  }
}
</script>

<style scoped>

</style>