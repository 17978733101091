<template>
    <div class="member_wrap">
      <ol class="join_step_wrap">
        <li class="active">
          <i class="step1"></i>
          <span>회원선택</span>
        </li>
        <li>
          <i class="step2"></i>
          <span>가입여부 확인</span>
        </li>
        <li>
          <i class="step3"></i>
          <span>약관동의</span>
        </li>
        <li>
          <i class="step4"></i>
          <span>사용자정보 입력</span>
        </li>
        <li>
          <i class="step5"></i>
          <span>가입완료</span>
        </li>
      </ol>
  
      <h2 class="member_title">회원가입 유형 선택</h2>
      <div class="tit_box">만 14세 미만은 회원가입이 불가합니다.</div>
      <div class="type_area">
        <a href="javascript:void(0);" class="type_box" style="max-width: 45rem;">
          <i class="user"></i>
          <h3>시설 사용자</h3>
          <p style="margin-bottom: 72px;">사업자등록번호가 있는 고형연료/폐자원에너지 <br/>시설 소속 사용자</p>
          <span class="btn sm tertiary" @click.prevent="userSelect">선택</span>
        </a>
        <a href="javascript:void(0);" class="type_box" style="max-width: 45rem;">
          <i class="admin"></i>
          <h3>업무 관리자</h3>
          <p>시설/품질검사, 등록실적 관리 등 폐자원에너지 업무 전반을 관리하는 지자체, 환경청, 외부시험기관 사용자
          </p>
          <br/>
          <p style="font-size: 15px; min-height: 0px; color: brown;">* 외부시험기관 사용자는 교육 목적으로만 계정을 생성합니다.</p>
          <span class="btn sm tertiary" @click.prevent="taskMngSelect">선택</span>
        </a>
      </div>

      <div v-if="brnoOpen">
        <p style="font-size: 1.9rem;" class="txt_center mb_10">시설 사용자께서는 <strong class="txt_point">사업자등록번호</strong>를 입력하신 후 회원가입 진행이 계속됩니다.</p>
        <div class="member_box">
            <br-form-input label="사업자등록번호" v-model="param.brno1" :maxlength="3" clazz="form_control w20p" panel="panel" required @keyup="onKeyupInputBrno($event, 3)" ref="txtBrno1"/> -
            <br-form-input label="사업자등록번호" v-model="param.brno2" :maxlength="2" clazz="form_control w20p" panel="panel" required @keyup="onKeyupInputBrno($event, 2)" ref="txtBrno2"/> -
            <br-form-input label="사업자등록번호" v-model="param.brno3" :maxlength="5" clazz="form_control w20p" panel="panel" required @keyup="onKeyupInputBrno($event, 5)" ref="txtBrno3"/>
            <button type="button" @click="userBrnoSelect" class="btn sm primary line ml_20" ref="btnConfirm">확인</button>
        </div>
      </div>   
    </div>
  </template>
  
  <script>

  import storeRegister from "@/js/store/store-register";

  const API = {
    EXS_CHECK: "/join/exsCheck",
  };

  export default {
    data() {
      return {
        brnoOpen: false,
        param : {
            brno1 : "",
            brno2 : "",
            brno3 : "",
            brno : "",
            userMngSe : "",   // 시설/업무 사용자 구분
            exsBzentyYn : ""  // 기존 업체 여부
        },
      };
    },
    computed: {},
    watch: {},
    created() {
      storeRegister.dispatch('resetRegister'); // 회원가입정보 초기화
    },
    beforeMount() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'  // 부드럽게 스크롤 이동
      });
    },
    methods: {
      navigateTo(path) {
        if (path) {
          this.$router.push(path)
              .catch(() => {
                console.error("Navigation Error");
              });
        }
      },
      userSelect() {    // 시설 사용자 사업자번호 입력
        this.brnoOpen = true;

        window.scrollTo({
          top: document.body.scrollHeight - 300,
          behavior: 'smooth'  // 부드럽게 스크롤되도록 설정
        });
      },
      // 사업자등록번호 유효성 검사
      checkCorporateRegiNumber(number) {
        var numberMap = number.replace(/-/gi, '').split('').map(function (d) {
          return parseInt(d, 10);
        });

        if (numberMap.length == 10) {
          var keyArr = [1, 3, 7, 1, 3, 7, 1, 3, 5];
          var chk = 0;

          keyArr.forEach(function(d, i) {
            chk += d * numberMap[i];
          });

          chk += parseInt((keyArr[8] * numberMap[8]) / 10, 10);
          return Math.floor(numberMap[9]) === ( (10 - (chk % 10)) % 10 );
        }

        return false;
      },
      userBrnoSelect() {

        if(this.param.brno1 == null || this.param.brno1 == ""){
          alert("사업자등록번호 첫째 자리를 입력해 주세요.")
        } else if(this.param.brno2 == null || this.param.brno2 == "") {
          alert("사업자등록번호 둘째 자리를 입력해 주세요.")
        } else if(this.param.brno3 == null || this.param.brno3 == "") {
          alert("사업자등록번호 셋째 자리를 입력해 주세요.")
        } else if(this.param.brno1.length != 3){
          alert("첫째 자리는 3자리로 입력해 주세요.")
        }else if(this.param.brno2.length != 2){
          alert("둘째 자리는 2자리로 입력해 주세요.")
        }else if(this.param.brno3.length != 5){
          alert("셋째 자리는 5자리로 입력해 주세요.")
        } else {
          this.param.brno = `${this.param.brno1}${this.param.brno2}${this.param.brno3}`;
          const isValid = this.checkCorporateRegiNumber(this.param.brno); // 유효성 검사

          if (!isValid) {
            alert('유효하지 않은 사업자등록번호입니다.');
            return;
          }

          return new Promise((resolve) => {
            this.$apiCall.get(API.EXS_CHECK, this.param, data => {
              storeRegister.dispatch('startRegister'); // 회원가입 진행여부 처리
              storeRegister.dispatch('updateJoinUserInfo', {...this.param, userMngSe: 'user', exsBzentyYn: data.result ? 'Y' : 'N'}); // user정보
              resolve();

              this.navigateTo(`/user/JoinCheck`);
            });
          });
        }
      },
      taskMngSelect() { // 업무관리 사용자
        storeRegister.dispatch('startRegister'); // 회원가입 진행여부 처리
        storeRegister.dispatch('updateJoinUserInfo', {...this.param, userMngSe: 'task'}); // user정보
        this.navigateTo(`/user/JoinCheck`);
      },
      onKeyupInputBrno(value, maxLength) {
        if(value.length === maxLength) {
          switch (maxLength) {
            case 3:
              this.$refs.txtBrno2.focus();
              // this.$refs.txtBrno2.$el.select(); // 스크립트 오류로 주석
              break;
            case 2:
              this.$refs.txtBrno3.focus();
              // this.$refs.txtBrno3.$el.select(); // 스크립트 오류로 주석
              break;
            case 5:
              this.$refs.btnConfirm.focus();
              break;
          }
        }
      },
    }
  }
  </script>
  
  <style>

  </style>