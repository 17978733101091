<template>
  <div class="pwCh_area">
    <div class="page_title_wrap">
      <h2 class="page_title hidden">비밀번호 변경</h2>
      <p>
        <i><img src="@/assets/images/sub/ico_pwCh.png" alt=""></i>
        <span>개인정보를 위해 <br><strong><em class="txt_point">비밀번호</em>를 변경</strong>해 주세요</span>
      </p>
    </div>

    <div class="member_wrap mt_40">
      <div class="login_wrap">
        <p class="txt_center" v-if="userinfo.scrtyPlcyYn == 'Y'">{{ userinfo?.userNm || '' }}님(<strong>{{ userinfo?.userId || '' }}</strong>)님은 6개월 이상 동일한 비밀번호를 사용하고 계십니다.<br>소중한 개인 정보보호를 위해 비밀번호를
          주기적으로
          변경해 주세요.</p>
        <p class="txt_center" v-if="userinfo.scrtyPlcyYn == 'N'">{{ userinfo?.userNm || '' }}님(<strong>{{ userinfo?.userId || '' }}</strong>)님은 신규규칙에 맞는 비밀번호로 교체가 필요합니다.<br>소중한 개인 정보보호를 위해 비밀번호를 변경해 주세요</p>
        <div class="login_form pwCh">
          <br-form-input label="현재 비밀번호" type="password" v-model="user.currentPassword" panel="updatePassword" required />
          <br-form-input label="새 비밀번호" type="password" v-model="user.updatePassword" panel="updatePassword" required />
          <br-form-input label="새 비밀번호 재입력" placeholder="새 비밀번호 확인" type="password" v-model="reTypeUpdatePassword" panel="updatePassword" required />
        </div>
      </div>

      <ul class="sch_pw_info">
        <li><span>※</span> 숫자, 영문 대/소문자, 특수문자(<span style="font-weight: bold">!@#$%^&*</span>)를 함께 사용하면(9글자 이상) 보다 안전합니다.</li>
        <li><span>※</span> 안전한 계정 사용을 위해 비밀번호는 주기적으로 변경해 주세요.</li>
        <li><span>※</span> 한국환경공단 정보보안 지침 제77조(비밀번호 관리)</li>
      </ul>

      <div class="btn_area center">
        <br-button label="비밀번호 변경" format="primary" @click="update" panel="updatePassword" validate/>
        <br-button v-if="userinfo.scrtyPlcyYn == 'Y'" label="30일 후에 변경" format="tertiary" @click="extend"/>
      </div>
    </div>
  </div>
</template>

<script>
import {encrypt} from "@/js/utils/cryptoUtil";
import {storeSession} from "@/js/store/store-session";

const ROUTE = {
  MAIN: "/",
  LOGIN: "/user/login",
};

const API = {
  LOGOUT: "/auth/remove-token",
  UPDATE_PASSWORD : "/expired-password/change",
  EXTEND_PASSWORD : "/expired-password/extend",
};

export default {
  emits: ['logout'],
  data() {
    return {
      userinfo: storeSession.getMemberInfo(),
      reTypeUpdatePassword: "",
      passwordPattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{9,}$/,
      user: {
        currentPassword: "",
        updatePassword: "",
      },
    };
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {
    update() {
      if (!this.passwordPattern.test(this.user.updatePassword)) {
        alert("비밀번호는 대/소문자, 숫자, 특수문자를 포함한 9자 이상이어야 합니다.");
        return;
      }

      if (this.user.updatePassword !== this.reTypeUpdatePassword) {
        alert("새 비밀번호가 일치하지 않습니다.");
        return;
      }

      const currentPassword = encrypt(this.user.currentPassword, process.env.VUE_APP_SECRET_KEY);
      const updatePassword  = encrypt(this.user.updatePassword, process.env.VUE_APP_SECRET_KEY);

      const param = {
        currentPassword: currentPassword,
        updatePassword: updatePassword,
      };
      this.$apiCall.put(API.UPDATE_PASSWORD, param, this.logout, this.showAlert);
    },
    extend() {
      this.$apiCall.put(API.EXTEND_PASSWORD, {}, this.gotoMain, this.showAlert);
    },
    gotoMain() {
      this.$router.push(ROUTE.MAIN).catch(() => {
      });
    },
    showAlert(error) {
      alert(error.message);
    },
    async logout() {
      await this.$apiCall.post(API.LOGOUT, {}, async () => {
        storeSession.removeSession();  // 세션을 먼저 제거
        await this.$router.push(ROUTE.LOGIN)  // 그 후에 로그인 페이지로 이동
            .then(() => {
              alert("재 로그인 해주세요.");
            })
            .catch((err) => {
              console.error("라우터 이동 실패:", err);
            });
      }, () => {
        alert("시스템에 문제가 발생했습니다.");
      });
    }
  }
}
</script>

<style scoped>

</style>