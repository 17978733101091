<template>
  <div class="tab_area">
    <ul class="tab">
      <li
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{active: activeTab === index}"
          @click="selectTab(index)"
      >
        <button type="button" class="btn_tab">
          {{ tab.name }}
          <i v-if="activeTab === index" class="sr_only">선택됨</i>
        </button>
      </li>
    </ul>
    <div class="tab_conts_wrap">
      <section class="tab_conts" v-show="activeTab === 0">
        <h3 class="title2">실적 현황</h3>
        <sb-grid
            id="prfmncGrid"
            :dataSource="prfmncDataSource"
            :columns="prfmncGridColumns"
            :gridattr="prfmncGridAttr"
            v-model:grid="prfmncGridObject"
            @loaded="prfmncGridLoaded"
            :refreshGrid="prfmncGridReload"
        />
      </section>

      <section class="tab_conts" v-show="activeTab === 1">
        <h3 class="title2">폐기물 지역별 반입 현황</h3>
        <sb-grid
            id="crynGrid"
            :dataSource="crynDataSource"
            :columns="crynGridColumns"
            :gridattr="crynGridAttr"
            v-model:grid="crynGridObject"
            @loaded="crynGridLoaded"
            :refreshGrid="crynGridReload"
        />
      </section>

      <section class="tab_conts" v-show="activeTab === 2">
        <h3 class="title2">사업장별 에너지 판매 현황</h3>
        <sb-grid
            id="ntslDtlGrid"
            :dataSource="ntslDtlDataSource"
            :columns="ntslDtlGridColumns"
            :gridattr="ntslDtlGridAttr"
            v-model:grid="ntslDtlGridObject"
            @loaded="ntslDtlGridLoaded"
            :refreshGrid="ntslDtlGridReload"
        />
      </section>
    </div>
  </div>
</template>

<script>
import SbGrid from "@/components/SbGrid.vue";
import { storeSwitch } from "@/js/store/store-switch";

const API = {
  PERFORMANCE_STATUS: "/prfmnc/bioGas/prfmnc-stts",
  CARRY_IN_STATUS: "/prfmnc/bioGas/cryn-stts",
  NET_SALES_DETAILS_STATUS: "/prfmnc/bioGas/ntsl-dtl-stts",
};

export default {
  components: {SbGrid},
  props: ['param'],
  data() {
    return {
      tabs: [
        { name: '실적현황' },
        { name: '반입상세현황' },
        { name: '판매상세현황' },
      ],
      activeTab: 0,
      prfmncGridReload: false,
      prfmncGridObject: null,
      prfmncDataSource: [],
      prfmncGridColumns: [],
      prfmncGridAttr: {
        showRowNo: false,
        pageable: false,
        excelExport: {
          fileName: '실적 현황.xlsx',
          includeHeader: true,
        },
        height: 515,
      },
      crynGridReload: false,
      crynGridObject: null,
      crynDataSource: [],
      crynGridColumns: [],
      crynGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        excelExport: {
          fileName: '폐기물 지역별 반입 현황.xlsx',
          includeHeader: true,
          keepMerge: true,
        },
      },
      ntslDtlGridReload: false,
      ntslDtlGridObject: null,
      ntslDtlDataSource: [],
      ntslDtlGridColumns: [],
      ntslDtlGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        excelExport: {
          fileName: '업체별 에너지 판매 현황.xlsx',
          includeHeader: true,
          keepMerge: true,
        }
      },
      prfmncInfo: {},
      crynInfo: {},
      ntslDtlInfo: {},
    };
  },
  computed: {},
  watch: {
    activeTab() {
      this.search();
    }
  },
  created() {
    this.initialize();
  },
  mounted() {
  },
  methods: {
    async initialize() {
      storeSwitch.on("loading");
      await this.loadPrfmncInfo();
      this.initializePrfmncInfo();
      await this.loadCrynInfo();
      this.initializeCrynInfo();
      await this.loadNtslDtlInfo();
      this.initializeNtslDtl();
      storeSwitch.off("loading");
    },
    loadPrfmncInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.PERFORMANCE_STATUS,
            { bplcId: this.param.bplcId, prfmncYr: this.param.prfmncYr },
            data => {
              if (data.result) {
                this.prfmncInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load prfmnc info."));
            }
        );
      });
    },
    loadCrynInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.CARRY_IN_STATUS,
            { bplcId: this.param.bplcId, prfmncYr: this.param.prfmncYr },
            data => {
              if (data.result) {
                this.crynInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load cryn info."));
            }
        );
      });
    },
    loadNtslDtlInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.NET_SALES_DETAILS_STATUS,
            { bplcId: this.param.bplcId, prfmncYr: this.param.prfmncYr },
            data => {
              if (data.result) {
                this.ntslDtlInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load ntsl dtl info."));
            }
        );
      });
    },
    initializePrfmncInfo() {
      this.prfmncDataSource = this.prfmncInfo.list;
      this.initializePrfmncGridColumns();
      this.initializePrfmncGridAttr();
      this.prfmncGridReload = true;
    },
    initializeCrynInfo() {
      const crynDataCopy = this.crynInfo.list.map(item => ({ ...item }));

      // `0020`, `0021`, `0022`로 시작하는 값들의 최대값 계산
      const keysToCheck = ['0020', '0021', '0022'];

      const prfmncYmMaxValues = crynDataCopy.reduce((acc, item) => {
        // ctpvStdgCd 값이 없는 데이터는 제외
        if (!item.ctpvStdgCd) {
          return acc;
        }

        // `prfmncYm`을 기준으로 그룹화
        if (!acc[item.prfmncYm]) {
          acc[item.prfmncYm] = {};
        }

        // `keysToCheck`에 해당하는 키들의 최대값 계산
        keysToCheck.forEach(key => {
          const currentValue = item[key] || 0; // 해당 키의 값이 없으면 0으로 처리
          acc[item.prfmncYm][key] = Math.max(acc[item.prfmncYm][key] || 0, currentValue);
        });

        return acc;
      }, {});

      const updatedCrynDataCopy = [];
      const seenPrfmncYm = new Set();

      crynDataCopy.forEach(item => {
        if (!item.ctpvStdgCd) {
          updatedCrynDataCopy.push(item); // ctpvStdgCd 없는 데이터는 그대로 유지
          return;
        }

        if (!seenPrfmncYm.has(item.prfmncYm)) {
          // 첫 번째 데이터에는 최대값 설정
          keysToCheck.forEach(key => {
            item[key] = prfmncYmMaxValues[item.prfmncYm][key] || 0;
          });
          seenPrfmncYm.add(item.prfmncYm);
        } else {
          // 동일한 prfmncYm의 나머지 데이터는 0으로 설정
          keysToCheck.forEach(key => {
            item[key] = 0;
          });
        }

        updatedCrynDataCopy.push(item);
      });

      this.crynDataSource = updatedCrynDataCopy;
      this.initializeCrynGridColumns();
      this.initializeCrynGridAttr();
      this.crynGridReload = true;
    },
    initializeNtslDtl() {
      this.ntslDtlDataSource = this.ntslDtlInfo.list;
      this.initializeNtslDtlGridColumns();
      this.initializeNtslDtlGridAttr();
      this.ntslDtlGridReload = true;
    },
    initializePrfmncGridColumns() {
      const is2025OrLater = this.param.prfmncYr >= 2025;

      this.prfmncGridColumns = [
        { field: 'prfmncId', caption: '실적아이디', visible: false },
        { field: 'prfmncYm', caption: '실적연월', type: 'date', width: 100, part: 'head',
          total: { headerCss:'grid-header' },
          format: (date) => {
            if (date && date.length === 6) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              return `${yyyy}-${mm}`;
            }
            return date;
          },
        },
        { field: 'prfmncSttsNm', caption: '실적상태', type: 'date', width: 100,
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          },
        },
        { caption: '폐기물 반입현황' },
        { caption: '폐기물 투입현황' },
        { caption: '소화조 가동 현황(%)',
          columns: [
            { caption: '소화 전 농도',
              columns: [
                { field: 'digstnBfrVsDens', caption: 'VS', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'digstnBfrVsDens'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'digstnBfrSsDens', caption: 'SS', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'digstnBfrSsDens'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'digstnBfrBodDens', caption: 'BOD', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'digstnBfrBodDens'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'digstnBfrTsDens', caption: 'TS', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'digstnBfrTsDens'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
              ]
            },
            { caption: '소화 후 농도',
              columns: [
                { field: 'digstnAftrVsDens', caption: 'VS', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'digstnAftrVsDens'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'digstnAftrSsDens', caption: 'SS', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'digstnAftrSsDens'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'digstnAftrBodDens', caption: 'BOD', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'digstnAftrBodDens'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'digstnAftrTsDens', caption: 'TS', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'digstnAftrTsDens'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
              ]
            },
          ]
        },
        { caption: '가스정제설비 보유 현황',
          columns: [
            { field: 'prprcsEquipCn', caption: '전처리설비 현황', width: 200, align: 'left', total: { headerCss:'grid-header' } },
            { field: 'bgspgrBfrMthnDens', caption: '고질화 전<br>메탄농도', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'bgspgrBfrMthnDens', nullAs: 0}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'bgspgrMthdCn', caption: '고질화방법', width: 200, align: 'left', total: { headerCss:'grid-header' } },
            { field: 'bgspgrAftrMthnDens', caption: '고질화 후<br>메탄농도', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'bgspgrAftrMthnDens', nullAs: 0}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
          ]
        },
        { caption: '소화 후 발생 폐기물(톤)',
          columns: [
            { caption: '탈수케익',
              columns: [
                { field: 'dwscPrdqty', caption: '발생량', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'dwscPrdqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'dwscPrdctnMstcnt', caption: '발생량 함수율(%)', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: { headerCss:'grid-header' }
                },
                { field: 'dwscPrcqty', caption: '처리량', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'dwscPrcqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'dwscPrcsMthdCn', caption: '처리방법', width: 200, align: 'left', total: { headerCss:'grid-header' } },
              ]
            },
            { caption: '폐수',
              columns: [
                { field: 'prfctnWstwtrPrdqty', caption: '정화', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'prfctnWstwtrPrdqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'liquidWstwtrPrdqty', caption: '액비', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'liquidWstwtrPrdqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'wstwtrCnsgnPrcsMthdCn', caption: '위탁처리 방법', width: 200, align: 'left', total: { headerCss:'grid-header' } },
                { field: 'wstwtrCnsgnPrcsCn', caption: '위탁처리 시 설명', width: 300, align: 'left', total: { headerCss:'grid-header' } },
              ]
            },
          ]
        },
        { caption: '시설운영 외부에너지 사용량',
          columns: [
            ...(!is2025OrLater ? [
              { field: 'bfrOilAsstFuelUsqty', caption: '유류(㎘)', width: 140, align: 'right',
                dataType:'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', require: 'bfrOilAsstFuelUsqty'}],
                  header: [{ template: '{{sum}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
              },
              { field: 'bfrLngAsstFuelUsqty', caption: 'LNG(㎥)', width: 140, align: 'right',
                dataType:'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', require: 'bfrLngAsstFuelUsqty'}],
                  header: [{ template: '{{sum}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
              },
              { field: 'bfrPrvtmAsstFuelUsqty', caption: '전기(MWh)', width: 140, align: 'right',
                dataType:'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', require: 'bfrPrvtmAsstFuelUsqty'}],
                  header: [{ template: '{{sum}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
              },
              { field: 'bfrEtcAsstFuelUsqty', caption: '기타(Gcal)', width: 140, align: 'right',
                dataType:'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', require: 'bfrEtcAsstFuelUsqty'}],
                  header: [{ template: '{{sum}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
              },
              { field: 'bfrEtcAsstFuelKndCn', caption: '기타종류', width: 140, align: 'left', total: { headerCss:'grid-header' } },
            ] : []),
            ...(is2025OrLater ? [
              { field: 'oilAsstFuelUsqty', caption: '유류', width: 140, align: 'right',
                dataType:'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', require: 'oilAsstFuelUsqty'}],
                  header: [{ template: '{{sum}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
              },
              { field: 'gasAsstFuelUsqty', caption: '가스(N㎥)', width: 140, align: 'right',
                dataType:'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', require: 'gasAsstFuelUsqty'}],
                  header: [{ template: '{{sum}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
              },
              { field: 'crynPrvtmAsstFuelUsqty', caption: '전기(MWh)', width: 140, align: 'right',
                dataType:'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', require: 'crynPrvtmAsstFuelUsqty'}],
                  header: [{ template: '{{sum}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
              },
              { field: 'etcAsstFuelUsqty', caption: '기타', width: 140, align: 'right',
                dataType:'number', format: '#,##0.00',
                total: {
                  aggregates: [{func: 'sum', require: 'etcAsstFuelUsqty'}],
                  header: [{ template: '{{sum}}', format: '#,##0.00' }],
                  headerCss:'grid-header',
                },
              },
            ] : []),
          ]
        },
        { field: 'flwmtrPstnNm', caption: '유량계 위치', width: 140, total: { headerCss:'grid-header' } },
        { field: 'nm3Yn', caption: 'N㎥ 여부', width: 70, total: { headerCss:'grid-header' } },
        { field: 'flwmtrCrctYmd', caption: '유량계 교정일자', width: 110,
          type: 'date', calendarType: 'date',
          total: { headerCss:'grid-header' },
          format: (date) => {
            if (date && date.length === 8) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              const dd = date.substring(6, 8);
              return `${yyyy}-${mm}-${dd}`;
            }
            return date;
          },
        },
        { field: 'enePrdqty', caption: '생산량(A+B)', width: 140, align: 'right',
          dataType:'number', format: '#,##0.00',
          total: {
            aggregates: [{func: 'sum', require: 'enePrdqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { caption: '바이오가스 이용현황(N㎥)',
          columns: [
            { field: 'eneUtzqty', caption: '총이용량(A)', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'eneUtzqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { caption: '자체이용 바이오가스',
              columns: [
                { field: 'gasStotUsqty', caption: '소계', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'gasStotUsqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'elctgnUsgGasUsqty', caption: '발전', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'elctgnUsgGasUsqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'dgstrWrmngUsgGasUsqty', caption: '소화조가온용', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'dgstrWrmngUsgGasUsqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'sldgDhmdfyUsgGasUsqty', caption: '슬러지건조용', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'sldgDhmdfyUsgGasUsqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'sldgFireUsgGasUsqty', caption: '슬러지소각용', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'sldgFireUsgGasUsqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'foodDhmdfyUsgGasUsqty', caption: '음실물건조용', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'foodDhmdfyUsgGasUsqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'bsmlRmvlUsgGasUsqty', caption: '악취제거', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'bsmlRmvlUsgGasUsqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'stUsgGasUsqty', caption: '스팀', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'stUsgGasUsqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'stUsgGasUseCn', caption: '스팀용도', width: 140, align: 'left', total: { headerCss:'grid-header' } },
                { field: 'etcUsgGasUsqty', caption: '기타', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'etcUsgGasUsqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'etcUsgGasUseCn', caption: '기타설명', width: 200, align: 'left', total: { headerCss:'grid-header' } },
              ]
            },
            { caption: '외부공급 바이오가스',
              columns: [
                { field: 'gasStotSupqty', caption: '소계', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'gasStotSupqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'elctgnUsgGasSupqty', caption: '발전', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'elctgnUsgGasSupqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'dgstrWrmngUsgGasSupqty', caption: '소화조가온용', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'dgstrWrmngUsgGasSupqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'sldgDhmdfyUsgGasSupqty', caption: '슬러지건조용', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'sldgDhmdfyUsgGasSupqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'sldgFireUsgGasSupqty', caption: '슬러지소각용', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'sldgFireUsgGasSupqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'foodDhmdfyUsgGasSupqty', caption: '음실물건조용', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'foodDhmdfyUsgGasSupqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'bsmlRmvlUsgGasSupqty', caption: '악취제거', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'bsmlRmvlUsgGasSupqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'stUsgGasSupqty', caption: '스팀', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'stUsgGasSupqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'stUsgGasSplyCn', caption: '스팀용도', width: 200, align: 'left', total: { headerCss:'grid-header' } },
                { field: 'etcUsgGasSupqty', caption: '기타', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'etcUsgGasSupqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'etcUsgGasSplyCn', caption: '기타설명', width: 200, align: 'left', total: { headerCss:'grid-header' } },
              ]
            },
            { field: 'eneUnuqty', caption: '미이용(단순처리)(B)', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'eneUnuqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
          ]
        },
        { caption: '전기 생산·판매량(MWh)',
          columns: [
            { field: 'prvtmPrdqty', caption: '생산량', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'prvtmPrdqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'prvtmUsqty', caption: '자체이용', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'prvtmUsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'prvtmSumNsqty', caption: '판매량', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'prvtmSumNsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
          ]
        },
        { caption: '가스 생산·판매량(Gcal)',
          columns: [
            { field: 'gasPrdctnNm3', caption: '가스생산량(N㎥)', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'gasPrdctnNm3'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'gasPrdctnGcal', caption: '가스생산량(Gcal)', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'gasPrdctnGcal'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'gasSumNsqty', caption: '가스판매량(N㎥)', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'gasSumNsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'gasNtslGcal', caption: '가스판매량(Gcal)', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'gasNtslGcal'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
          ]
        },
        { caption: '증기 생산·판매량(Gcal)',
          columns: [
            { field: 'vaporPrdctnTon', caption: '생산량(스팀톤)', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'vaporPrdctnTon'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'vaporPrdctnGcal', caption: '생산량(Gcal)', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'vaporPrdctnGcal'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'vaporSumNsqty', caption: '판매량(스팀톤)', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'vaporSumNsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'vaporNtslGcal', caption: '판매량(Gcal)', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'vaporNtslGcal'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'srtrtVaporTp', caption: '포화증기 온도(℃)', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: { headerCss:'grid-header' },
            },
            { field: 'srtrtVaporPrssVl', caption: '포화증기 압력(kgf/cm2)', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'srtrtVaporPrssVl'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
          ]
        },
        { caption: '온수 생산·판매량(Gcal)',
          columns: [
            { field: 'htwtrPrdctnTon', caption: '생산량(스팀톤)', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'htwtrPrdctnTon'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'htwtrPrdctnGcal', caption: '생산량(Gcal)', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'htwtrPrdctnGcal'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'htwtrSumNsqty', caption: '판매량(스팀톤)', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'htwtrSumNsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'htwtrNtslGcal', caption: '판매량(Gcal)', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'htwtrNtslGcal'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'htwtrSrtrtVaporTp', caption: '포화증기 온도(℃)', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: { headerCss:'grid-header' },
            },
            { field: 'htwtrSrtrtVaporPrssVl', caption: '포화증기 압력(kgf/cm2)', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'htwtrSrtrtVaporPrssVl'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
          ]
        },
        { caption: '기타 판매량(Gcal)',
          columns: [
            { field: 'etcEnePrdctnTon', caption: '생산량(스팀톤)', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'etcEnePrdctnTon'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'etcEnePrdctnGcal', caption: '생산량(Gcal)', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'etcEnePrdctnGcal'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'etcEneSumNsqty', caption: '판매량(스팀톤)', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'etcEneSumNsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'etcEnePrdctnNtslCn', caption: '기타설명', width: 200, align: 'left', total: { headerCss:'grid-header' } },
          ]
        }
      ];

      if (this.prfmncInfo.crynWtItemList && this.prfmncInfo.crynWtItemList.length > 0) {
        this.prfmncGridColumns[3].columns = [];

        this.prfmncInfo.crynWtItemList.forEach(item => {
          // 하수슬러지 제외
          if (item.wtItemCd !== '0019') {
            this.prfmncGridColumns[3].columns.push({
              field: `cryn${item.wtItemCd}`,
              caption: item.wtItemNm,
              width: 140,
              total: {
                aggregates: [{func: 'sum', require: `cryn${item.wtItemCd}`}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
              align: 'right',
              format: '#,##0.00'
            });
          }
        });
      }else{
        this.prfmncGridColumns[3] = {...this.prfmncGridColumns[3], total: { headerCss:'grid-header' } }
      }

      if (this.prfmncInfo.inputWtItemList && this.prfmncInfo.inputWtItemList.length > 0) {
        this.prfmncGridColumns[4].columns = [];

        this.prfmncInfo.inputWtItemList.forEach(item => {
          this.prfmncGridColumns[4].columns.push({
            field: `input${item.wtItemCd}`,
            caption: item.wtItemNm,
            width: 140,
            total: {
              aggregates: [{func: 'sum', require: `input${item.wtItemCd}`}],
              header: [{ template: '{{sum}}', format: '#,##0.00' }],
              headerCss:'grid-header',
            },
            align: 'right',
            format: '#,##0.00'
          });
        });
      }else{
        this.prfmncGridColumns[4] = {...this.prfmncGridColumns[4], total: { headerCss:'grid-header' } }
      }
    },
    initializePrfmncGridAttr() {
    },
    initializeCrynGridColumns() {
      this.crynGridColumns = [
        { field: 'prfmncId', caption: '실적아이디', visible: false },
        { field: 'prfmncYm', caption: '실적연월', type: 'date', width: 100, mergeRow: true, part: 'head',
          total: { headerCss:'grid-header' },
          format: (date) => {
            if (date && date.length === 6) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              return `${yyyy}-${mm}`;
            }
            return date;
          },
        },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false },
        { field: 'ctpvStdgNm', caption: '시·도', width: 110,
          mergeRow: (data) => data.prfmncYm + '.' + data.ctpvStdgCd,
          total: { headerCss:'grid-header' }
        },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false },
        { field: 'sggStdgNm', caption: '시·군·구', width: 110,
          mergeRow: (data) => data.prfmncYm + '.' + data.ctpvStdgCd + '.' + data.sggStdgCd,
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          }
        },
      ];

      if (this.crynInfo.wtItemList && this.crynInfo.wtItemList.length > 0) {
        const additionalColumns = [];

        this.crynInfo.wtItemList.forEach(item => {
          // 하수슬러지 제외
          if (item.wtItemCd !== '0019') {
            additionalColumns.push({
              field: item.wtItemCd,
              caption: item.wtItemNm,
              width: 140,
              total: {
                aggregates: [
                  { func: (items) => items
                        .filter(entry => entry.sggStdgCd !== "0000000000")
                        .reduce((sum, entry) => sum + (entry[item.wtItemCd] || 0), 0),
                    field: `sum${item.wtItemCd}`
                  },
                ],
                header: [{ template: `{{sum${item.wtItemCd}}}`, format: '#,##0.00' }],
                headerCss:'grid-header',
              },
              align: 'right',
              format: '#,##0.00',
              // mergeRow 조건 추가 (정규식 사용)
              ...( /^002(0|1|2)/.test(item.wtItemCd) ? {
                mergeRow: (data) => {
                  const ctpvStdgCdValue = data.ctpvStdgCd ? 'SAME' : '';
                  return data.prfmncYm  + '.' + ctpvStdgCdValue;
                }
              } : {})
            });
          }
        });

        // 마지막에 추가
        this.crynGridColumns.push(...additionalColumns);
      }
    },
    initializeCrynGridAttr() {
    },
    initializeNtslDtlGridColumns() {
      this.ntslDtlGridColumns = [
        { field: 'prfmncId', caption: '실적아이디', visible: false },
        { field: 'prfmncYm', caption: '실적연월', type: 'date', width: 100, mergeRow: true, part: 'head',
          total: { headerCss:'grid-header' },
          format: (date) => {
            if (date && date.length === 6) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              return `${yyyy}-${mm}`;
            }
            return date;
          },
        },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false },
        { field: 'ctpvStdgNm', caption: '시·도', width: 110,
          mergeRow: (data) => data.prfmncYm + '.' + data.ctpvStdgCd,
          total: { headerCss:'grid-header' },
        },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false },
        { field: 'sggStdgNm', caption: '시·군·구', width: 110,
          mergeRow: (data) => data.prfmncYm + '.' + data.ctpvStdgCd + "." + data.sggStdgCd,
          total: { headerCss:'grid-header' },
        },
        { field: 'bzentyNm', caption: '업체명', width: 200, align: 'left', total: { headerCss:'grid-header' } },
        { field: 'eneTypeNm', caption: '에너지유형', width: 200, total: { headerCss:'grid-header' } },
        { field: 'ntslTypeNm', caption: '판매유형', width: 200,
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          },
        },
        { caption: '에너지 판매',
          columns: [
            { field: 'ntslSeNm', caption: '판매구분', width: 200, total: { headerCss:'grid-header' } },
            { field: 'ntslStotAmt', caption: '판매급액<br>합계', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'ntslStotAmt'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { caption: '전기(MWh,원)',
              columns: [
                { field: 'prvtmNsqty', caption: '판매량', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'prvtmNsqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'prvtmNtslAmt', caption: '판매금액', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'prvtmNtslAmt'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
              ]
            },
            { caption: '가스(Nm³,원)',
              columns: [
                { field: 'gasNsqty', caption: '판매량', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'gasNsqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'gasNtslAmt', caption: '판매금액', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'gasNtslAmt'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
              ]
            },
            { caption: '증기(스팀톤,원)',
              columns: [
                { field: 'vaporNsqty', caption: '판매량', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'vaporNsqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'vaporNtslAmt', caption: '판매금액', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'vaporNtslAmt'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
              ]
            },
            { caption: '온수(스팀톤,원)',
              columns: [
                { field: 'htwtrNsqty', caption: '판매량', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'htwtrNsqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'htwtrNtslAmt', caption: '판매금액', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'htwtrNtslAmt'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
              ]
            },
            { caption: '기타(Gcal,원)',
              columns: [
                { field: 'etcEneNsqty', caption: '판매량', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'etcEneNsqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'etcEneNtslAmt', caption: '판매금액', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'etcEneNtslAmt'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
              ]
            },
          ]
        },
      ]
    },
    initializeNtslDtlGridAttr() {
    },
    prfmncGridLoaded() {
      this.prfmncGridReload = false;
    },
    crynGridLoaded() {
      this.crynGridReload = false;
    },
    ntslDtlGridLoaded() {
      this.ntslDtlGridReload = false;
    },
    async prfmncSearch() {
      await this.loadPrfmncInfo();
      this.initializePrfmncInfo()
    },
    async crynSearch() {
      await this.loadCrynInfo();
      this.initializeCrynInfo();
    },
    async ntslDtlSearch() {
      await this.loadNtslDtlInfo();
      window.SBGrid3.setClientData(this.ntslDtlGridObject, this.ntslDtlInfo.list || []);
    },
    selectTab(index) {
      this.activeTab = index;
    },
    async search() {
      storeSwitch.on("loading");
      switch (this.activeTab) {
        case 0: // 실적현황
            await this.prfmncSearch();
          break;
        case 1: // 반입상세현황
            await this.crynSearch();
          break;
        case 2: // 판매상세현황
            await this.ntslDtlSearch();
          break;
      }
      storeSwitch.off("loading");
    }
  }
}
</script>

<style scoped>

</style>