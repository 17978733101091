<template>
    <label :for="label+'시작일자'" style="display: none">{{ label }}시작일자</label>
    <date-picker
      ref="fromDatePicker"
      v-model:value="selectedFromDate"
      value-type="format"
      :format="format"
      :placeholder="computedPlaceholder"
      type="date"
      :lang="lang"
      :disabled="disabled"
      :editable="false"
      :disabled-date="notAfterToDate"
      @change="onChangeFromDate"
    >
      <template #footer="{ emit }">
        <button class="mx-btn mx-btn-text" @click="emit(new Date())">오늘</button>
      </template>
    </date-picker>
&nbsp;&nbsp;~&nbsp;&nbsp;
    <label :for="label+'종료일자'" style="display: none">{{ label }}종료일자</label>    
    <date-picker
        ref="toDatePicker"
        v-model:value="selectedToDate"
        value-type="format"
        :format="format"
        :placeholder="computedPlaceholder"
        type="date"
        :lang="lang"
        :disabled="disabled"
        :editable="false"
        :disabled-date="notBeforeFromDate"
        @change="onChangeToDate"
    >
      <template #footer="{ emit }">
        <button class="mx-btn mx-btn-text" @click="emit(new Date())">오늘</button>
      </template>
    </date-picker>
</template>
<script>
import DatePicker from 'vue-datepicker-next';
import '@/css/vue-datepicker-next.css';
import ko from 'vue-datepicker-next/locale/ko';
import { storeValidator } from "@/js/store/store-validator";
import pp from '@/js/postposition';
import moment from "moment";

export default {
  components: {
    DatePicker
  },
  props: {
    label: {type: String, required: true},
    fromDate: { type: String, default: '' },
    toDate: { type: String, default: '' },
    placeholder: {type: String, default: ''},
    format: { type: String, default: 'YYYY-MM-DD' },
    futureonly: {type: Boolean, default: false},
    futureDaysOffset: { type: Number, default: 0 },
    required: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    panel: {type: String, default: ''},
  },
  emits: [ 'update:fromDate', 'update:toDate', 'change'],
  data() {
    return {
      hasError: false,
      errorMessage: "",
      lang: ko,
      selectedFromDate: this.fromDate,
      selectedToDate: this.toDate,
    };
  },
  computed: {
    computedPlaceholder() {
      return !this.disabled && !this.placeholder ? `${this.label} 선택` : this.placeholder;
    }
  },
  watch: {
    fromDate(newValue) {
      if (!newValue) {
        this.selectedFromDate = "";
      } else {
        this.selectedFromDate = newValue;
      }
    },
    toDate(newValue) {
      if (!newValue) {
        this.selectedToDate = "";
      } else {
        this.selectedToDate = newValue;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      const fromInputEl = this.$refs.fromDatePicker.querySelector("input");
      const toInputEl = this.$refs.toDatePicker.querySelector("input");
            
      if (fromInputEl) {
        fromInputEl.id = this.label + '시작일자';
      }
      if (toInputEl) {
        toInputEl.id = this.label + '종료일자';
      }
    });
  },
  created() {
    if (this.required) {
      storeValidator.add(this.$route.path, this.panel, this);
    }
    this.validate();
  },
  beforeUnmount() {
    if (this.required) {
      storeValidator.remove(this.$route.path, this.panel, this.label);
    }
  },
  methods: {
    validate() {
      if (this.required && (!this.selectedFromDate || !this.selectedToDate)) {
        this.hasError = true;
        this.errorMessage = `${pp.eunNn(this.label)} 필수 선택값 입니다.`;
      } else {
        this.hasError = false;
        this.errorMessage = "";
      }
    },
    onChangeFromDate(date) {
      const formattedDate = moment(date, this.format);
      const selectedToDate = moment(this.selectedToDate, this.format);

      if (date && this.selectedToDate && formattedDate.isAfter(selectedToDate)) {
        this.selectedToDate = date;
      }
      this.emitChange();
    },
    onChangeToDate(date) {
      const formattedDate = moment(date, this.format);
      const selectedFromDate = moment(this.selectedFromDate, this.format);

      if (date && this.selectedFromDate && formattedDate.isBefore(selectedFromDate)) {
        this.selectedFromDate = date
      }
      this.emitChange();
    },
    emitChange() {
      this.$emit('update:fromDate', this.selectedFromDate || "");
      this.$emit('update:toDate', this.selectedToDate || "");
      this.validate();
      this.$emit('change', { fromDate: this.selectedFromDate, toDate: this.selectedToDate });
    },
    notAfterToDate(date) {
      const futureDate = moment().startOf('day').add(this.futureDaysOffset, 'days');
      const formattedDate = moment(date, this.format);

      if (this.futureonly) {
        if (this.selectedToDate) {
          const validToDate = moment(this.selectedToDate, this.format);
          return formattedDate.isBefore(futureDate) || formattedDate.isAfter(validToDate);
        }
        return formattedDate.isBefore(futureDate);
      }

      return this.selectedToDate ? formattedDate.isAfter(moment(this.selectedToDate, this.format)) : false;
    },
    notBeforeFromDate(date) {
      const futureDate = moment().startOf('day').add(this.futureDaysOffset, 'days');
      const formattedDate = moment(date, this.format);

      if (this.futureonly) {
        const validFutureDate = moment.max(futureDate, this.selectedFromDate ? moment(this.selectedFromDate, this.format).startOf('day') : moment(0));
        return formattedDate.isBefore(validFutureDate);
      }

      return this.selectedFromDate ? formattedDate.isBefore(moment(this.selectedFromDate, this.format).startOf('day')) : false;
    }
  }
}
</script>

<style scoped>

</style>