const MENU_LIST = [
    {
        menuGrpNm: "공지사항",
        path: "/cust-sprt/PostManage/1",
        items: [
            {
                menuNm: "공지사항상세",
                path: "/cust-sprt/PostDetail/1",
            },
        ],
        isPublic: true,
    },
    {
        menuGrpNm: "자료실",
        path: "/cust-sprt/PostManage/2",
        items: [
            {
                menuNm: "자료실상세",
                path: "/cust-sprt/PostDetail/2",
            },
        ],
        isPublic: true,
    },
    {
        menuGrpNm: "FAQ",
        path: "/cust-sprt/FaqManage",
        items: [
            // {
            //     menuNm: "FAQ상세",
            //     path: "/cust-sprt/FaqDetail",
            // },
        ],
        isPublic: true,
    },
    {
        menuGrpNm: "온라인문의",
        path: "/cust-sprt/OnlineDiscussionManage",
        items: [
            {
                menuNm: "온라인문의상세",
                path: "/cust-sprt/OnlineDiscussionDetail",
            },
            {
                menuNm: "온라인문의등록",
                path: "/cust-sprt/OnlineDiscussionCreate",
            },
        ],
        isPublic: true,
    },
    {
        menuGrpNm: "온라인교육",
        path: "/cust-mng/OnlineEducationManage",
        items: [
            {
                menuNm: "온라인교육",
                path: "/cust-mng/OnlineEducationManage",
            },
            {
                menuNm: "강좌상세",
                path: "/cust-mng/online-education/OnlineEducationDetail",
            },
            {
                menuNm: "강의상세",
                path: "/cust-mng/online-education/OnlineLectureDetail",
            },
            {
                menuNm: "강의시청내역",
                path: "/cust-mng/OnlineLectureWatchInq",
            }
        ]
    },
    {
        menuGrpNm: "팝업존",
        path: "/cust-sprt/PopupManage",
        items: [
            {
                menuNm: "팝업상세",
                path: "/cust-sprt/PopupDetail",
                isPublic: true,
            },
        ],
        isPublic: true,
    },
    {
        menuGrpNm: "컨텐츠",
        path: "/cust-sprt/ContentsManage",
        items: [
            {
                menuNm: "컨텐츠상세",
                path: "/cust-sprt/ContentsDetail",
                isPublic: true,
            }
        ],
        isPublic: true,
    },
    {
        menuGrpNm: "사이트맵",
        path: "/cust-sprt/SiteMap",
        items: [],
        isPublic: true,
    },
];

export default {
    getMenus: function () {
        return {
            groupName: "고객지원",
            menuList: MENU_LIST,
        };
    },
};