<template>

    <!-- s: 콘텐츠 영역 -->
    <div class="desc_box">
        <!-- <i class="icon"><img src="../images/common/ico_desc.png" alt="이미지"></i> -->
        <div class="desc">
            <strong class="desc_title">품질등급제란?</strong>
            <p>고형연료제품 품질(확인)검사, 품질표시검사의 결과를 활용하여 품질등급을 판정하고, 고품질의 고형연료제품 제조ㆍ사용을 유도하는 등 
                고형연료제품의 환경적 안정성을 도모하기 위한 제도</p>
        </div>
    </div>

    <ul class="opinion_box">
        <li>저위발열량<strong>{{inspRslt.inspRslt180Vl}}</strong></li>
        <li>염소<strong>{{inspRslt.inspRslt140Vl}}</strong></li>
        <li>수은<strong>{{inspRslt.inspRslt80Vl}}</strong></li>
        <li>황분<strong>{{inspRslt.inspRslt130Vl}}</strong></li>
        <li>품질등급<strong>{{inspRslt.qltyGrdCd}}</strong></li>
    </ul>

    <div class="board_write">
        <table>
            <caption>의견서 제출 내용_업체명, 사업자등록번호, 대표자, 전화번호, 사업장소재지, 수입·제조 신고번호, 제조국·제조업체(수입품의 경우), 첨부파일로 구성</caption>
            <colgroup>
                <col style="width:18%">
                <col style="width:32%">
                <col style="width:18%">
                <col style="width:32%">
            </colgroup>
            <tbody>
                <tr>
                    <th scope="row">업체명</th>
                    <td>{{ bplc.bzentyNm }}</td>
                    <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>사업자등록번호</th>
                    <td>{{ bplc.brno }}</td>
                </tr>
                <tr>
                    <th scope="row">대표자</th>
                    <td>{{ bplc.rprsvNm }}</td>
                    <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>전화번호</th>
                    <td>{{ bplc.bzentyTelno }}</td>
                </tr>
                <tr>
                    <th scope="row">사업장소재지</th>
                    <td colspan="3">[{{ bplc.untyZip }}] {{ bplc.bplcAddr }}</td>
                </tr>
                <tr>
                    <th scope="row">수입·제조 신고번호</th>
                    <td colspan="3">{{ bplc.dclrNo }}</td>
                </tr>
                <tr>
                    <th scope="row">제조국·제조업체(수입품의 경우)</th>
                    <td colspan="3">{{ bplc.mnftrNtnNm }} {{ bplc.incmBzentyNm }}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <h2 class="title1">의견서 제출 내용</h2>
    <div class="board_write">
        <table>
            <caption>의견서 제출 내용_제원종류, 제출일자, 신청상태, 제출 내용 및 특이사항, 첨부파일로 구성</caption>
            <colgroup>
                <col style="width:18%">
                <col style="width:32%">
                <col style="width:18%">
                <col style="width:32%">
            </colgroup>
            <tbody>
                <tr>
                    <th scope="row">제원종류</th>
                    <td colspan="3">{{ objcAply.prdctKndNm }}</td>
                </tr>
                <tr>
                    <th scope="row">제출일자</th>
                    <td>{{ objcAply.objcAplyYmd }}</td>
                    <th scope="row">진행상태</th>
                    <td>{{ objcAply.qltyGrdObjcAplySttsNm }}</td>
                </tr>
                <tr>
                    <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>의견제출 내용 및 특이사항</th>
                    <td colspan="3">
                        <br-form-textarea label="의견제출 내용 및 특이사항" v-model="objcAply.qltyGrdObjcAplyCn" required panel="panel3" />
                    </td>
                </tr>
                <tr>
                    <th scope="row">첨부파일</th>
                    <td colspan="3">
                        <br-attach-file label="신청첨부파일"
                        atchFileTaskSeCd="QGOA"
                        v-model:atchFileGroupId="aplyAtchFileGroupId"
                        v-model:fileProcessingData="aplyFileProcessingData"
                        :files="aplyFiles"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <h2 v-show="objcAply.rsltRegYmd === '03' || objcAply.rsltRegYmd === '04' || objcAply.qltyGrdObjcRsltCn" class="title1">의견서 제출 결과 내용</h2>
    <div v-show="objcAply.rsltRegYmd === '03' || objcAply.rsltRegYmd === '04' || objcAply.qltyGrdObjcRsltCn" class="board_write">
        <table>
            <caption>의견서 제출 결과 내용_진행상태, 제출결과일자, 제출결과 내용 및 특이사항, 첨부파일로 구성</caption>
            <colgroup>
                <col style="width:18%">
                <col style="width:82%">
            </colgroup>
            <tbody>
                <tr>
                    <th scope="row">진행상태</th>
                    <td>{{ objcAply.qltyGrdObjcAplySttsNm }}</td>
                </tr>
                <tr>
                    <th scope="row">결과일자</th>
                    <td>{{ objcAply.rsltRegYmd }}</td>
                </tr>
                <tr>
                    <th scope="row">심의결과 내용 및 특이사항</th>
                    <td>
                        <br-form-textarea label="심의결과 내용" readonly v-model="objcAply.qltyGrdObjcRsltCn" />
                    </td>
                </tr>
                <tr>
                    <th scope="row">첨부파일</th>
                    <td>
                        <br-attach-file label="처리첨부파일"
                        atchFileTaskSeCd="QGOA"
                        v-model:atchFileGroupId="prcsAtchFileGroupId"
                        v-model:fileProcessingData="prcsFileProcessingData"
                        :files="prcsFiles"
                        readonly
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="btn_area">
        <div></div>
        <div>
            <br-button label="등록" panel="panel3" validate class="btn md primary"
            :disabled="!(objcAply.qltyGrdObjcAplySttsCd === undefined || objcAply.qltyGrdObjcAplySttsCd === '' || objcAply.qltyGrdObjcAplySttsCd === '00')"  
            @click="objcAply.qltyGrdObjcAplySttsCd === undefined || objcAply.qltyGrdObjcAplySttsCd === '' || objcAply.qltyGrdObjcAplySttsCd === '00' ? inputInfo() : ''" 
            />
            <br-button label="삭제" class="btn md point"
            :disabled="!(objcAply.qltyGrdObjcAplySttsCd !== undefined && objcAply.qltyGrdObjcAplySttsCd !== '')"  
            @click="objcAply.qltyGrdObjcAplySttsCd !== undefined && objcAply.qltyGrdObjcAplySttsCd !== '' ? deleteInfo() : ''" 
            />
            <a href="javascript:void(0);" class="btn md tertiary" @click="navigateTo">목록</a>
        </div>
    </div>
    <!-- e: 콘텐츠 영역 -->
</template>

<script>
   /**
    * 이의신청 처리 화면
    *
    * 작성자 : 김지환
    * 작성일 : 11/04/2024
    * 버전 : 1.0
    */
   import { storeSession } from "@/js/store/store-session";
//    import { storeSwitch } from "@/js/store/store-switch";

   export default {  
     components: {},
     data() {
       return {
         userInfo : storeSession.getMemberInfo(),
         searchParam: {
            qltyGrdRsltSn : "",
         },
         objcAply : {},
         bplc : {},
         inspRslt : {},
         aplyAtchFileGroupId: "",
         aplyFileProcessingData: {},
         prcsAtchFileGroupId : "",
         prcsfileProcessingData: {},
         aplyFiles : [],
         prcsFiles : [],
       };
     },
     computed: {},
     watch: {},
     created() {
     },
     mounted() {
       this.searchParam.qltyGrdRsltSn = this.$route.query.qltyGrdRsltSn
       this.initialize();
     },
     methods: {
       async initialize() {
         this.search();
       },
       reset() {

       },
       search() {
            this.$apiCall.get(
                "/qltyGrd/objcAply",
                {'qltyGrdRsltSn' : this.searchParam.qltyGrdRsltSn},
                (data)=>{
                    this.objcAply = data.result.objcAply;
                    this.bplc = data.result.bplc;
                    this.inspRslt = data.result.inspRslt;
                    this.aplyAtchFileGroupId = this.objcAply.aplyAtchFileGroupId;
                    this.prcsAtchFileGroupId = this.objcAply.prcsAtchFileGroupId;
                    if (data.result.aplyFiles) {
                        this.aplyFiles = data.result.aplyFiles;
                    }
                    if (data.result.prcsFiles) {
                        this.prcsFiles = data.result.prcsFiles;
                    }
                },
                ()=>{
                    alert("조회에 실패하였습니다.");
                    this.objcAply = {};
                    this.bplc = {};
                    this.inspRslt = {};
                },
            )
            
       },
       navigateTo() {
            this.$router.push(
                {path: "/qlty-grd/ObjcAplyInq"})
                .catch(() => {
                    console.error("Navigation Error");
                }
            );
       },
       inputInfo() {
        //  storeSwitch.confirm('info', "정말 신청 하시겠습니까?", () => {
            this.$apiCall.put(
                "/qltyGrd/putObjcAply",
                {qltyGrdRsltSn: this.searchParam.qltyGrdRsltSn, qltyGrdObjcAplyCn: this.objcAply.qltyGrdObjcAplyCn, 
                 aplyAtchFileGroupId: this.aplyAtchFileGroupId, fileProcessingData: this.aplyFileProcessingData},
                () => {
                    alert("처리되었습니다.");
                    this.search();
                },
                () => {
                    alert("실패하였습니다.");
                }
            );
        //  });
       },
       deleteInfo() {
        //  storeSwitch.confirm('info', "정말 삭제 하시겠습니까?", () => {
            this.$apiCall.put(
                "/qltyGrd/deleteObjcAply",
                {qltyGrdRsltSn: this.searchParam.qltyGrdRsltSn, qltyGrdObjcAplyCn: this.objcAply.qltyGrdObjcAplyCn, 
                 aplyAtchFileGroupId: this.aplyAtchFileGroupId, fileProcessingData: this.aplyFileProcessingData},
                () => {
                    alert("삭제되었습니다.");
                    this.navigateTo();
                },
                () => {
                    alert("실패하였습니다.");
                }
            );
        //  });
       }
     }
   }
</script>
   
<style scoped>

</style>