<template>
  <div class="tab_area">
    <ul class="tab">
      <li
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{active: activeTab === index}"
          @click="selectTab(index)"
      >
        <button type="button" class="btn_tab">
          {{ tab.name }}
          <i v-if="activeTab === index" class="sr_only">선택됨</i>
        </button>
      </li>
    </ul>
    <div class="tab_conts_wrap">
      <section class="tab_conts" v-show="activeTab === 0">
        <h3 class="title2">원재료 반입 현황</h3>
        <sb-grid
            id="rawmtrlCrynGrid"
            :dataSource="rawmtrlCrynDataSource"
            :columns="rawmtrlCrynGridColumns"
            :gridattr="rawmtrlCrynGridAttr"
            v-model:grid="rawmtrlCrynGridObject"
            @loaded="rawmtrlCrynGridLoaded"
            :refreshGrid="rawmtrlCrynGridReload"
        />

        <div class="board_info">
          <h3 class="title2">폐기물 구분별 반입 현황<span style="color: #ea4444">&nbsp;&nbsp;(승인된 실적보고 자료만 조회 및 출력 가능합니다.)</span></h3>
          <div>
            <br-button label="리포트출력" size="sm" icon="ico_print" @click="openReportForm" />
          </div>
        </div>
        <div class="grid_search">
          <div class="sch_form">
            <span>
              <label>실적연월</label>
              <br-month-from-to label="실적연월" v-model:fromMonth="mtralParam.prfmncYmFrom" v-model:toMonth="mtralParam.prfmncYmTo" :clearable="false" @change="wtCrynSearch" />
            </span>
            <span>
              <label>폐기물구분</label>
              <br-form-select label="신청여부" v-model:value="mtralParam.wtItemCd" :options="wtItemList" allSelectable @change="wtCrynSearch" />
            </span>
            <span>
              <div class="form_check">
                <input type="checkbox" id="exclYn" v-model="mtralParam.exclYn" true-value="Y" false-value="N" style="display: none" @change="wtCrynSearch" />
                <label for="exclYn">폐목재분류번호(51-20-23) 제외</label>
              </div>
            </span>
          </div>
        </div>
        <sb-grid
            id="wtCrynGrid"
            :dataSource="wtCrynDataSource"
            :columns="wtCrynGridColumns"
            :gridattr="wtCrynGridAttr"
            v-model:grid="wtCrynGridObject"
            @loaded="wtCrynGridLoaded"
            :refreshGrid="wtCrynGridReload"
        />
      </section>

      <section class="tab_conts" v-show="activeTab === 1">
        <h3 class="title2">고형연료제품 생산/판매 현황</h3>
        <sb-grid
            id="prdctnNtslGrid"
            :dataSource="prdctnNtslDataSource"
            :columns="prdctnNtslGridColumns"
            :gridattr="prdctnNtslGridAttr"
            v-model:grid="prdctnNtslGridObject"
            @loaded="prdctnNtslGridLoaded"
            :refreshGrid="prdctnNtslGridReload"
        />
      </section>

      <section class="tab_conts" v-show="activeTab === 2">
        <h3 class="title2">사업장별 고형연료제품 판매 현황</h3>
        <sb-grid
            id="ntslDtlGrid"
            :dataSource="ntslDtlDataSource"
            :columns="ntslDtlGridColumns"
            :gridattr="ntslDtlGridAttr"
            v-model:grid="ntslDtlGridObject"
            @loaded="ntslDtlGridLoaded"
            :refreshGrid="ntslDtlGridReload"
        />
      </section>
    </div>
  </div>
  <component v-if="reportParamObj.selectedPopupComponent"
            :is="reportParamObj.selectedPopupComponent"
            :bplc-id="param.bplcId"
            :prfmnc-yr="param.prfmncYr"
            :prfmnc-ym-from="mtralParam.prfmncYmFrom"
            :prfmnc-ym-to="mtralParam.prfmncYmTo"
            :wt-item-cd="mtralParam.wtItemCd"
            :mtral-cd-wt-etc="mtralParam.exclYn"
  />
</template>

<script>
import SbGrid from "@/components/SbGrid.vue";
import { storeSwitch } from "@/js/store/store-switch";
import reportMnftrWtCrynPopup from "@/views/prfmnc-rpt/stts/popup/ReportMnftrWtCrynPopup.vue";

const API = {
  RAW_MATERIALS_CARRY_IN_STATUS: "/prfmnc/sdflMnfrt/rawmtrl-cryn-stts",
  SOLID_FUEL_MATERIALS_CARRY_IN_STATUS: "/prfmnc/sdflMnfrt/srf-mtral-cryn-stts",
  PRODUCTION_NET_SALES_STATUS: "/prfmnc/sdflMnfrt/prdctn-ntsl-stts",
  NET_SALES_DETAILS_STATUS: "/prfmnc/sdflMnfrt/ntsl-dtl-stts",
};

export default {
  components: {SbGrid, reportMnftrWtCrynPopup},
  props: ['param'],
  data() {
    return {
      gridRef: null,
      tabs: [
        { name: '반입현황' },
        { name: '생산/판매현황' },
        { name: '판매상세현황' },
      ],
      activeTab: 0,
      mtralParam: {
        exclYn: 'N',
        wtItemCd: "",
        prfmncYmFrom: "",
        prfmncYmTo: "",
      },
      prdctItemCdNames: {
        '0001': 'SRF 성형',
        '0002': 'SRF 비성형',
        '0003': 'BIO-SRF 성형',
        '0004': 'BIO-SRF 비성형'
      },
      wtItemList: [],
      rawmtrlCrynGridReload: false,
      rawmtrlCrynGridObject: null,
      rawmtrlCrynDataSource: [],
      rawmtrlCrynGridColumns: [],
      rawmtrlCrynGridAttr: {
        showRowNo: false,
        pageable: false,
        excelExport: {
          fileName: '원재료 반입 현황.xlsx',
          includeHeader: true,
          includeFormula: true,
        }
      },
      wtCrynGridReload: false,
      wtCrynGridObject: null,
      wtCrynDataSource: [],
      wtCrynGridColumns: [],
      wtCrynGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        excelExport: {
          fileName: '폐기물 구분별 반입 현황.xlsx',
          keepMerge : true
        }
      },
      prdctnNtslGridReload: false,
      prdctnNtslGridObject: null,
      prdctnNtslDataSource: [],
      prdctnNtslGridColumns: [],
      prdctnNtslGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        excelExport: {
          fileName: '고형연료제품 생산/판매 현황.xlsx',
          includeHeader: true,
        }
      },
      ntslDtlGridReload: false,
      ntslDtlGridObject: null,
      ntslDtlDataSource: [],
      ntslDtlGridColumns: [],
      ntslDtlGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        excelExport: {
          fileName: '사업장별 고형연료제품 판매 현황.xlsx',
          includeHeader: true,
        }
      },
      rawmtrlCrynInfo: {},
      wtCrynInfo: {},
      prdctnNtslInfo: {},
      ntslDtlInfo: {},
      reportParamObj : {
        selectedPopupComponent: null, // 선택된 리포트/이폼 화면 구분 ID
      },
    };
  },
  computed: {},
  watch: {
    activeTab() {
      this.search();
    },
    // 폐기물 구분별 반입 현황에 실적연월이 실적년도에 따라 바뀌지 않게 처리
    // 'param.prfmncYr'(newPrfmncYr) {
    //   const today = new Date();
    //   today.setMonth(today.getMonth() - 1); // 현재 날짜에서 -1개월로 설정
    //   const month = today.getMonth() + 1;
    //   const monthFrom = (month-2) < 1 ? 1 : month-2;  //2달 전 세팅
    //   this.mtralParam.prfmncYmFrom = `${newPrfmncYr}-${monthFrom.toString().padStart(2, '0')}`;
    //   this.mtralParam.prfmncYmTo = `${newPrfmncYr}-${month.toString().padStart(2, '0')}`;
    // },
  },
  created() {
    this.initialize();
  },
  mounted() {
  },
  methods: {
    async initialize() {
      storeSwitch.on("loading");
      await this.loadRawmtrlCrynInfo();
      this.initializeRawmtrlCryn();
      await this.loadWtCrynInfo();
      this.initializeWtCryn();
      await this.loadPrdctnNtslInfo();
      this.initializePrdctnNtsl();
      await this.loadNtslDtlInfo();
      this.initializeNtslDtl();
      storeSwitch.off("loading");
    },
    loadRawmtrlCrynInfo() {
      return new Promise((resolve, reject) => {
        const today = new Date();
        const yearTo = today.getFullYear(); // 오늘날짜의 년
        const monthTo = today.getMonth() + 1; // 오늘날짜의 월
        
        today.setMonth(today.getMonth() - 3); // 검색 시작 시점 설정 (3개월 전)
        const yearFrom = today.getFullYear(); // 3개월 전 날짜의 년
        const monthFrom = today.getMonth() + 1; // 3개월 전 날짜의 월
        
        this.mtralParam.prfmncYmFrom = `${yearFrom.toString()}-${monthFrom.toString().padStart(2, '0')}`;
        this.mtralParam.prfmncYmTo = `${yearTo.toString()}-${monthTo.toString().padStart(2, '0')}`;

        this.$apiCall.get(API.RAW_MATERIALS_CARRY_IN_STATUS,
            { bplcId: this.param.bplcId, prfmncYr: this.param.prfmncYr },
            data => {
              if (data.result) {
                this.rawmtrlCrynInfo = data.result;

                this.wtItemList = (this.rawmtrlCrynInfo.wtItemList || []).map(item => ({
                  text: item.wtItemNm,
                  value: item.wtItemCd
                }));
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load rawmtrl cryn info."));
            }
        );
      });
    },
    loadWtCrynInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.SOLID_FUEL_MATERIALS_CARRY_IN_STATUS,
            { bplcId: this.param.bplcId, prfmncYr: this.param.prfmncYr, prfmncYmFrom: this.mtralParam.prfmncYmFrom, prfmncYmTo: this.mtralParam.prfmncYmTo, wtItemCd: this.mtralParam.wtItemCd, exclYn: this.mtralParam.exclYn },
            data => {
              if (data.result) {
                this.wtCrynInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load wt cryn info."));
            }
        );
      });
    },
    loadPrdctnNtslInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.PRODUCTION_NET_SALES_STATUS,
            { bplcId: this.param.bplcId, prfmncYr: this.param.prfmncYr },
            data => {
              if (data.result) {
                this.prdctnNtslInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load prdct ntsl info."));
            }
        );
      });
    },
    loadNtslDtlInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.NET_SALES_DETAILS_STATUS,
            { bplcId: this.param.bplcId, prfmncYr: this.param.prfmncYr },
            data => {
              if (data.result) {
                this.ntslDtlInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load ntsl dtl info."));
            }
        );
      });
    },
    initializeRawmtrlCryn() {
      this.rawmtrlCrynDataSource = this.rawmtrlCrynInfo.list;
      this.initializeRawmtrlCrynGridColumns();
      this.initializeRawmtrlCrynGridAttr();
      this.rawmtrlCrynGridReload = true;
    },
    initializeWtCryn() {
      this.wtCrynDataSource = this.wtCrynInfo.list;
      this.initializeWtCrynGridColumns();
      this.initializeWtCrynGridAttr();
      this.wtCrynGridReload = true;
    },
    initializePrdctnNtsl() {
      this.prdctnNtslDataSource = this.prdctnNtslInfo.list;
      this.initializePrdctnNtslGridColumns();
      this.initializePrdctnNtslGridAttr();
      this.prdctnNtslGridReload = true;
    },
    initializeNtslDtl() {
      this.ntslDtlDataSource = this.ntslDtlInfo.list;
      this.initializeNtslDtlGridColumns();
      this.initializeNtslDtlGridAttr();
      this.ntslDtlGridReload = true;
    },
    initializeRawmtrlCrynGridColumns() {
      const is2025OrLater = Number(this.param.prfmncYr) >= 2025;

      this.rawmtrlCrynGridColumns = [
        { field: 'prfmncId', caption: '실적아이디', visible: false },
        { field: 'prfmncYm', caption: '실적연월', type: 'date', width: 100,
          total: { headerCss:'grid-header' },
          format: (date) => {
            if (date && date.length === 6) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              return `${yyyy}-${mm}`;
            }
            return date;
          },
        },
        { field: 'prfmncSttsNm', caption: '실적상태', width: 100,
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          },
        },
        { caption: '원재료 반입현황' },
      ];

      if (!is2025OrLater) {
        this.rawmtrlCrynGridColumns.push(
            { caption: '원재료 투입현황',
              columns: [
                { field: 'wtSumInpqty', caption: '투입량(톤)', dataType:'number', format: '#,##0.00', align: 'right', width: 120,
                  total: {
                    aggregates: [{func: 'sum', require: 'wtSumInpqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
              ]
            }
        );
      }

      if (this.rawmtrlCrynInfo.wtItemList && this.rawmtrlCrynInfo.wtItemList.length > 0) {
        this.rawmtrlCrynGridColumns[3].columns = [];

        const wtItemCds = this.rawmtrlCrynInfo.wtItemList.map(item => item.wtItemCd);

        this.rawmtrlCrynGridColumns[3].columns.push({
          field: 'sum', caption: '합계(톤)', width: 120,
          total: {
            aggregates: [ {func: 'sum', require: 'sum'}, ],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
          calc: {
            require: wtItemCds,
            eval: 'sum',
            nullAs: 0,
            dataType: 'number',
          },
          align: 'right',
          format: '#,##0.00'
        });

        const columnCount = this.rawmtrlCrynInfo.wtItemList.length;
        const columnWidth = columnCount > 0 ? (100 / columnCount).toFixed(2) : 100;

        this.rawmtrlCrynInfo.wtItemList.forEach(item => {
          this.rawmtrlCrynGridColumns[3].columns.push({
            field: item.wtItemCd,
            caption: item.wtItemNm+'(톤)',
            minWidth: 100,
            width: columnWidth,
            unit: '%',
            total: {
              aggregates: [ {func: 'sum', require: item.wtItemCd} ],
              header: [{ template: '{{sum}}', format: '#,##0.00' }],
              headerCss:'grid-header',
            },
            align: 'right',
            format: '#,##0.00'
          });
        });
      }else{
        this.rawmtrlCrynGridColumns[3] = {...this.rawmtrlCrynGridColumns[3], total: { headerCss:'grid-header' } }
      }
    },
    initializeRawmtrlCrynGridAttr() {
      const itemCount = this.rawmtrlCrynInfo.list.length;

      this.rawmtrlCrynGridAttr.height = Math.max((itemCount * 30) + 136, 250);
    },
    initializeWtCrynGridColumns() {
      this.wtCrynGridColumns = [
        { field: 'prfmncId', caption: '실적아이디', visible: false },
        { field: 'prfmncYm', caption: '실적연월', type: 'date', width: 100, mergeRow: true,
          format: (date) => {
            if (date && date.length === 6) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              return `${yyyy}-${mm}`;
            }
            return date;
          },
        },
        { field: 'wtItemCd', caption: '폐기물품목코드', visible: false },
        { field: 'wtItemNm', caption: '폐기물구분', width: 150,
          mergeRow: (data) => data.prfmncYm + '.' + data.wtItemCd,
        },
        { field: 'srfMnftrMtralCd', caption: '폐기물코드', width: 150 },
        { field: 'srfMnftrMtralNm', caption: '폐기물코드명', minWidth: 100, maxWidth:1000, width: 100, unit: '%', align: 'left' },
        { field: 'wtCryqty', caption: '반입량(톤)', width: 200,
          dataType: 'number',
          align: 'right',
          format: '#,##0.00'
        },
      ];
    },
    initializeWtCrynGridAttr() {
    },
    initializePrdctnNtslGridColumns() {
      const is2025OrLater = Number(this.param.prfmncYr) >= 2025;

      const list = this.prdctnNtslInfo.list;
      const uniquePrdctItemCdList = [...new Set(list.map(item => item.prdctItemCd))];

      this.prdctnNtslGridColumns = [
        { field: 'prfmncId', caption: '실적아이디', visible: false },
        { field: 'prfmncYm', caption: '실적연월', type: 'date', width: 100, mergeRow: true, part: 'head',
          total: { headerCss:'grid-header' },
          format: (date) => {
            if (date && date.length === 6) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              return `${yyyy}-${mm}`;
            }
            return date;
          },
        },
        { field: 'prfmncSttsNm', caption: '실적상태', width: 100,
          mergeRow: (data) => data.prfmncYm + '.' + data.prfmncSttsNm,
          total: { headerCss:'grid-header' },
        },
        { field: 'prdctItemCd', caption: '제품품목코드', visible: false },
        { field: 'prdctItemNm', caption: '고형연료제품종류', width: 120,
          total: {
            aggregates: [],
            header: [
              { template: '합계' },
              ...uniquePrdctItemCdList.map(cd => ({
                template: this.prdctItemCdNames[cd] || '',
              })),
            ],
            headerCss:'grid-header',
          },
        },
        ...(is2025OrLater ? [{ caption: '원재료 투입현황' }] : []),
        { caption: '고형연료제품 생산/판매현황',
          columns: [
            { field: 'prdctCrfqty', caption: '이월량(톤)', dataType:'number', format: '#,##0.00', align: 'right', width: 120,
              total: {
                aggregates: [],
                header: [
                  { template: '{{sum}}', format: '#,##0.00' },
                  ...uniquePrdctItemCdList.map(cd => ({
                    template: `{{prdctCrfqty${cd}}}`, format: '#,##0.00'
                  }))
                ],
                headerCss:'grid-header',
              },
            },
            { field: 'prdctSumPrdqty', caption: '생산량(톤)', dataType:'number', format: '#,##0.00', align: 'right', width: 120,
              total: {
                aggregates: [
                  {func: 'sum', require: 'prdctSumPrdqty'},
                  ...uniquePrdctItemCdList.map(cd => ({
                    func: (items) => items
                        .filter(item => item.prdctItemCd === cd)
                        .reduce((sum, item) => sum + (item.prdctSumPrdqty || 0), 0),
                    field: `prdctSumPrdqty${cd}`
                  }))
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00' },
                  ...uniquePrdctItemCdList.map(cd => ({
                    template: `{{prdctSumPrdqty${cd}}}`, format: '#,##0.00'
                  }))
                ],
                headerCss:'grid-header',
              },
            },
            { field: 'prdctSumRsdqty', caption: '잔재량(톤)', dataType:'number', format: '#,##0.00', align: 'right', width: 120,
              total: {
                aggregates: [
                  {func: 'sum', require: 'prdctSumRsdqty'},
                  ...uniquePrdctItemCdList.map(cd => ({
                    func: (items) => items
                        .filter(item => item.prdctItemCd === cd)
                        .reduce((sum, item) => sum + (item.prdctSumRsdqty || 0), 0),
                    field: `prdctSumRsdqty${cd}`
                  }))
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00' },
                  ...uniquePrdctItemCdList.map(cd => ({
                    template: `{{prdctSumRsdqty${cd}}}`, format: '#,##0.00'
                  }))
                ],
                headerCss:'grid-header',
              },
            },
            { field: 'prdctDscdCrtqty', caption: '폐기량(톤)', dataType:'number', format: '#,##0.00', align: 'right', width: 120,
              total: {
                aggregates: [
                  {func: 'sum', require: 'prdctDscdCrtqty'},
                  ...uniquePrdctItemCdList.map(cd => ({
                    func: (items) => items
                        .filter(item => item.prdctItemCd === cd)
                        .reduce((sum, item) => sum + (item.prdctDscdCrtqty || 0), 0),
                    field: `prdctDscdCrtqty${cd}`
                  }))
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00' },
                  ...uniquePrdctItemCdList.map(cd => ({
                    template: `{{prdctDscdCrtqty${cd}}}`, format: '#,##0.00'
                  }))
                ],
                headerCss:'grid-header',
              },
            },
            { field: 'prdctSumNsqty', caption: '판매량(톤)', dataType:'number', format: '#,##0.00', align: 'right', width: 120,
              total: {
                aggregates: [
                  {func: 'sum', require: 'prdctSumNsqty'},
                  ...uniquePrdctItemCdList.map(cd => ({
                    func: (items) => items
                        .filter(item => item.prdctItemCd === cd)
                        .reduce((sum, item) => sum + (item.prdctSumNsqty || 0), 0),
                    field: `prdctSumNsqty${cd}`
                  }))
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00' },
                  ...uniquePrdctItemCdList.map(cd => ({
                    template: `{{prdctSumNsqty${cd}}}`, format: '#,##0.00'
                  }))
                ],
                headerCss:'grid-header',
              },
            },
            { field: 'prdctRmnqty', caption: '잔량(톤)', dataType:'number', format: '#,##0.00', align: 'right', width: 120,
              total: {
                aggregates: [],
                header: [
                  { template: '{{sum}}', format: '#,##0.00' },
                  ...uniquePrdctItemCdList.map(cd => ({
                    template: `{{prdctRmnqty${cd}}}`, format: '#,##0.00'
                  }))
                ],
                headerCss:'grid-header',
              },
            },
            { field: 'prdctPrdctnYldrt', caption: '생산수율(%)', dataType:'number', format: '#,##0.00', align: 'right', width: 100, total: { headerCss:'grid-header' } },
            { field: 'prdctLcp', caption: '저위발열량(kcal/kg)', dataType:'number', format: '#,##0.00', align: 'right', width: 120,
              total: {
                aggregates: [],
                header: [
                  { template: '{{sum}}', format: '#,##0.00' },
                  ...uniquePrdctItemCdList.map(cd => ({
                    template: `{{prdctLcp${cd}}}`, format: '#,##0.00'
                  }))
                ],
                headerCss:'grid-header',
              },
            },
          ]
        },
      ];

      if (is2025OrLater) {
        if (this.prdctnNtslInfo.wtItemList && this.prdctnNtslInfo.wtItemList.length > 0) {
          this.prdctnNtslGridColumns[5].columns = [];

          const wtItemCds = this.prdctnNtslInfo.wtItemList.map(item => item.wtItemCd);

          this.prdctnNtslGridColumns[5].columns.push({
            field: 'sum', caption: '합계(톤)',
            total: {
              aggregates: [
                {func: 'sum', require: 'sum'},
                ...uniquePrdctItemCdList.map(cd => ({
                  func: (items) => items
                      .filter(i => i.prdctItemCd === cd)
                      .reduce((sum, i) => sum + (i.sum || 0), 0),
                  field: `sum_${cd}`
                }))
              ],
              header: [
                { template: '{{sum}}', format: '#,##0.00' },
                ...uniquePrdctItemCdList.map(cd => ({
                  template: `{{sum_${cd}}}`, format: '#,##0.00'
                }))
              ],
              headerCss:'grid-header',
            },
            calc: {
              require: wtItemCds,
              eval: 'sum',
              nullAs: 0,
              dataType: 'number',
            },
            align: 'right',
            format: '#,##0.00'
          });

          this.prdctnNtslInfo.wtItemList.forEach(item => {
            this.prdctnNtslGridColumns[5].columns.push({
              field: item.wtItemCd,
              caption: item.wtItemNm+'(톤)',
              width: 110,
              total: {
                aggregates: [
                  {func: 'sum', require: item.wtItemCd},
                  ...uniquePrdctItemCdList.map(cd => ({
                    func: (items) => items
                        .filter(i => i.prdctItemCd === cd)
                        .reduce((sum, i) => sum + (i[item.wtItemCd] || 0), 0),
                    field: `${item.wtItemCd}_${cd}`
                  }))
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00' },
                  ...uniquePrdctItemCdList.map(cd => ({
                    template: `{{${item.wtItemCd}_${cd}}}`, format: '#,##0.00'
                  }))
                ],
                headerCss:'grid-header',
              },
              align: 'right',
              format: '#,##0.00'
            });
          });
        }else{
          this.prdctnNtslGridColumns[5] = {...this.prdctnNtslGridColumns[5], total: { headerCss:'grid-header' } }
        }
      }
    },
    initializePrdctnNtslGridAttr() {
    },
    initializeNtslDtlGridColumns() {
      const list = this.ntslDtlInfo.list;
      const uniquePrdctItemCdList = [...new Set(list.map(item => item.prdctItemCd))];

      this.ntslDtlGridColumns = [
        { field: 'prfmncId', caption: '실적아이디', visible: false },
        { field: 'prfmncYm', caption: '실적연월', type: 'date', width: 100, mergeRow: true, part: 'head',
          total: { headerCss:'grid-header' },
          format: (date) => {
            if (date && date.length === 6) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              return `${yyyy}-${mm}`;
            }
            return date;
          },
        },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false },
        { field: 'ctpvStdgNm', caption: '시·도', width: 100,
          mergeRow: (data) => data.prfmncYm + '.' + data.ctpvStdgCd,
          total: { headerCss:'grid-header' },
        },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false },
        { field: 'sggStdgNm', caption: '시·군·구', width: 120,
          mergeRow: (data) => data.prfmncYm + '.' + data.ctpvStdgCd + "." + data.sggStdgCd,
          total: { headerCss:'grid-header' },
        },
        { field: 'ntslBplcId', caption: '판매사업장아이디', visible: false },
        { field: 'bplcNm', caption: '사업장명', minWidth: 100, width: 100, unit: '%', align: 'left',
          mergeRow: (data) => data.prfmncYm + '.' + data.ctpvStdgCd + "." + data.sggStdgCd + "." + data.ntslBplcId,
          total: { headerCss:'grid-header' },
        },
        { field: 'prdctItemCd', caption: '고형연료제품코드', visible: false },
        { field: 'prdctItemNm', caption: '고형연료 제품종류', width: 130,
          total: {
            aggregates: [],
            header: [
              { template: '합계' },
              ...uniquePrdctItemCdList.map(cd => ({
                template: this.prdctItemCdNames[cd] || '',
              })),
            ],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctNsqty', caption: '제품 판매량(톤)', type:'number', width: 140, align: 'right',
          dataType:'number', format: '#,##0.00',
          total: {
            aggregates: [
              {func: 'sum', require: 'prdctNsqty'},
              ...uniquePrdctItemCdList.map(cd => ({
                func: (items) => items
                    .filter(item => item.prdctItemCd === cd)
                    .reduce((sum, item) => sum + (item.prdctNsqty || 0), 0),
                field: `prdctNsqty${cd}`
              }))
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00' },
              ...uniquePrdctItemCdList.map(cd => ({
                template: `{{prdctNsqty${cd}}}`, format: '#,##0.00'
              }))
            ],
            headerCss:'grid-header',
          },
        },
      ];
    },
    initializeNtslDtlGridAttr() {
      const itemCount = this.ntslDtlInfo.list.length;
      const uniqueItemCount = new Set(this.ntslDtlInfo.list.map(item => item.inputItemCd)).size;
      const hFigure = ((itemCount + uniqueItemCount) * 30) + 104;
      this.ntslDtlGridAttr.height = hFigure < 300? 300: hFigure;
    },
    rawmtrlCrynGridLoaded() {
      this.rawmtrlCrynGridReload = false;
    },
    wtCrynGridLoaded() {
      this.wtCrynGridReload = false;
    },
    prdctnNtslGridLoaded() {
      this.prdctnNtslGridReload = false;
    },
    ntslDtlGridLoaded() {
      this.ntslDtlGridReload = false;
    },
    selectTab(index) {
      this.activeTab = index;
    },
    async rawmtrlCrynSearch() {
      await this.loadRawmtrlCrynInfo();
      this.initializeRawmtrlCryn();
    },
    async wtCrynSearch() {
      await this.loadWtCrynInfo();
      window.SBGrid3.setClientData(this.wtCrynGridObject, this.wtCrynInfo.list || []);
    },
    async prdctnNtslSearch() {
      await this.loadPrdctnNtslInfo();
      this.initializePrdctnNtsl();
    },
    async ntslDtlSearch() {
      await this.loadNtslDtlInfo();
      window.SBGrid3.setClientData(this.ntslDtlGridObject, this.ntslDtlInfo.list || []);
    },
    async search() {
      storeSwitch.on("loading");
      switch (this.activeTab) {
        case 0: // 반입현황
            await this.rawmtrlCrynSearch();
            await this.wtCrynSearch();
          break;
        case 1: // 생산/판매현황
            await this.prdctnNtslSearch();
          break;
        case 2: // 판매상세현황
            await this.ntslDtlSearch();
          break;
      }
      storeSwitch.off("loading");
    },
    openReportForm() { // 리포트/이폼 화면 열기
      this.reportParamObj.selectedPopupComponent = null; // 컴포넌트 리셋
      this.openLayerPopup();

      /*
       * 재호출 후 팝업에서 노출되는 현상으로 재호출
       */
      setTimeout(() => {
        this.openLayerPopup();
      }, 50);
    },
    openLayerPopup() { // 레이어 팝업 기능

      setTimeout(() => {
        this.reportParamObj.selectedPopupComponent = 'reportMnftrWtCrynPopup'; // 화면내 팝업 컴포넌트
        storeSwitch.on(this.reportParamObj.selectedPopupComponent);
      }, 0); // 0ms 딜레이로 컴포넌트 강제 리렌더링 (리포트 리렌더링 안 되는 케이스가 존재하여 추가)

    },
  }
}
</script>

<style scoped>

</style>