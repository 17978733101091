<template>
  <!-- s: 콘텐츠 영역 -->
  <head>
    <meta name="description" content="이 페이지는 정기검사 신청 정보를 등록 및 수정 합니다.">
  </head>
  <h2 class="title1 mt_0">정기검사 신청</h2>
    <div class="board_write">
        <p class="t_caption"><span class="necessary">(<i>※</i>)</span> 필수 입력항목입니다.</p>
        <table>
            <caption>사업장번호, 사업장명, 사업장종류, 업체구분, 시설명, 제품종류, 입금(예정)일자, 입금(예정)자명, 검사희망일자로 구성</caption>
            <colgroup>
                <col style="width:18%">
                <col style="width:32%">
                <col style="width:18%">
                <col style="width:32%">
            </colgroup>
            <tbody>
                <tr>
                    <th scope="row">사업장번호</th>
                    <td>{{param.bplcId}}</td>
                    <th scope="row">사업장명</th>
                    <td>{{param.bplcNm}}</td>
                </tr>
                <tr>
                    <th scope="row">사업장종류</th>
                    <td>{{param.bplcKndNm}}</td>
                    <th scope="row">업체구분</th>
                    <td>{{param.pubBzentyYn}}</td>
                </tr>
                <tr>
                    <th scope="row">시설명</th>
                    <td>{{param.fcltNm}}</td>
                    <th scope="row">제품종류</th>
                    <td>{{param.prdctnPrdctKndNm}}</td>
                </tr>
                <tr>
                    <th scope="row">입금(예정)일자</th>
                    <td><br-date-picker label="입금(예정)일자" title="입금(예정)일자" v-model:value="param.dpstPrnmntYmd" format="YYYY-MM-DD" panel="panel" /></td>
                    <th scope="row"><i class="necessary">필수입력</i>입금(예정)자명</th>
                    <td>
                        <div class="input_group">
                            <br-form-input label="입금(예정)자 명" title="입금(예정)자 명" v-model="param.pyrNm" panel="panel" class="w200" required/>
                            <div class="form_check">
                                <input type="checkbox" name="dpstYn" id="dpstYn" v-model="dpstYn" @change="dpstYnCheckBoxChg" :checked="param.dpstYn === 'Y'"><label for="dpstYn">선입급여부</label>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>검사희망일자</th>
                    <td colspan="2">
                        <br-date-picker label="검사희망일자" title="검사희망일자" v-model:value="param.inspHopeYmd" format="YYYY-MM-DD" panel="panel" futureonly :futureDaysOffset="computedFutureDaysOffset" :disableWeekends="true" required/>
                        <span class="txt_hint">최초검사가 아닌경우 30일 이후로 입력</span>
                    </td>
                    <td style="text-align: right;">
                        <div class="drop_wrap zoom_drop" :class="{ active: isZoomOpen }" ref="zoomMenu">
                            <div class="btn xsm secondary ico_helper" @click.prevent="toggleZoom">수수료 입금 계좌정보</div>
                            <div class="drop_menu" style="width: 320px;">
                                <div class="drop_list">
                                    <div style="color: var(--secondary); background: var(--secondary5); margin-bottom: 10px; text-align: left;">수수료 입금 계좌 정보</div>
                                    <div style="text-align: left;">
                                        <span>
                                            신청서 접수 후, 공단 담당자의 검토를 거쳐 이상이 없을 시 수수료 납부 안내를 드립니다.
                                            <!-- 수수료는 한국환경공단 농협은행 317-1700-1700-41 계좌번호로 입금해 주시기 바랍니다. -->
                                        </span>
                                        <br/>
                                        <span>
                                          <strong>예금주 : </strong>한국환경공단
                                        </span>
                                        <br/>
                                        <span>
                                          <strong>은행 : </strong>농협
                                        </span>
                                        <br/>
                                        <span>
                                          <strong>계좌번호 : </strong>317-1700-1700-41
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="board_info margin_top10">
        <h3><span class="txt_hint">※ 고형연료 검사 제출 서류를 공단 양식에 맞게 제출하여 주십시오.</span></h3>
        <div class="btn_area right">
            <a role="button" href="javascript:void(0);" class="btn sm secondary" @click.prevent="openReportForm('reportFcltInspAplyForm')">고형연료제품 정기검사 신청서 양식 출력</a>
        </div>
        </div>

    <div class="board_write">
        <table>
            <caption>파일 구성</caption>
            <colgroup>
                <col style="width:8%">
                <col style="width:42%">
                <col style="width:18%">
                <col style="width:32%">
            </colgroup>
            <tbody>
                <!-- s: 제조시설 검사 제출 서류 -->
                <tr v-if="this.param.bplcKndCd === '01-01'">
                <!-- <tr v-show="this.param.bplcKndCd === '01-01'"> -->
                    <th scope="row">파일</th>
                    <td colspan="3">
                        <div class="file_upload_list">
                          <li>
                            <br-attach-file-list label="정기검사 신청 첨부파일 목록"
                                                 ref="facAttachFileList"
                                                 atchFileTaskSeCd="FAC"
                                                 v-model:atchFileGroupId="this.param.inspAplyAtchFileGroupId"
                                                 v-model:fileProcessingData="this.param.fileProcessingData"
                                                 :readonly="false"
                                                 :useEtcFile="false"
                                                 :unusedAtchFileList="['001', '002', '003', '004', '005',
                                                  '006', '007', '008', '010', '011',
                                                  '013','014', '015', '016','053', '054', '999']"
                            />
                          </li>
                          <li>
                            <br-attach-file-list label="정기검사 업체 첨부파일 목록"
                                                 ref="attachFileList"
                                                 atchFileTaskSeCd="BET"
                                                 v-model:atchFileGroupId="param.bzntFileGroupId"
                                                 :readonly="true"
                                                 :useEtcFile="false"
                            />
                          </li>
                          <li>
                            <br-attach-file-list label="정기검사 사업장 첨부파일 목록"
                                                 ref="attachFileList"
                                                 atchFileTaskSeCd="BPCA"
                                                 v-model:atchFileGroupId="param.bplcFileGroupId"
                                                 :readonly="true"
                                                 :useEtcFile="false"
                            />
                          </li>
                          <li>
                            <br-attach-file-list label="정기검사 시설 첨부파일 목록"
                                                 ref="attachFileList"
                                                 atchFileTaskSeCd="FCTA"
                                                 v-model:atchFileGroupId="param.fcltFileGroupId"
                                                 :readonly="true"
                                                 :useEtcFile="false"
                                                 :unusedAtchFileList="['009']"
                            />
                          </li>
                          <li>
                            <br-attach-file-list label="정기검사 업체 기타서류 목록"
                                                 ref="attachFileList"
                                                 atchFileTaskSeCd="FSR"
                                                 v-model:atchFileGroupId="param.bzntFileGroupId"
                                                 :readonly="true"
                                                 :useEtcFile="true"
                                                 :unusedAtchFileList="['041','042','043','044','045','046','047']"
                            />
                          </li>
                          <li>
                            <br-attach-file-list label="정기검사 사업장 기타서류 목록"
                                                 ref="attachFileList"
                                                 atchFileTaskSeCd="FSR"
                                                 v-model:atchFileGroupId="param.bplcFileGroupId"
                                                 :readonly="true"
                                                 :useEtcFile="true"
                                                 :unusedAtchFileList="['041','042','043','044','045','046','047']"
                            />
                          </li>
                          <li>
                            <br-attach-file-list label="정기검사 시설 기타서류 목록"
                                                 ref="attachFileList"
                                                 atchFileTaskSeCd="FSR"
                                                 v-model:atchFileGroupId="param.fcltFileGroupId"
                                                 :readonly="true"
                                                 :useEtcFile="true"
                                                 :unusedAtchFileList="['041','042','043','044','045','046','047']"
                            />
                          </li>
                          <li>
                            <br-attach-file-list label="정기검사 신청 기타서류 목록"
                                                 ref="facEtcAttachFileList"
                                                 atchFileTaskSeCd="FAC"
                                                 atchFileEditMode="Y"
                                                 v-model:atchFileGroupId="this.param.inspAplyAtchFileGroupId"
                                                 v-model:fileProcessingData="this.param.fileProcessingData"
                                                 :maxFileCount="50"
                                                 :readonly="false"
                                                 :useEtcFile="true"
                                                 :unusedAtchFileList="['001', '002', '003', '004', '005',
                                                  '006', '007', '008', '010', '011','012',
                                                  '013', '014', '015', '016','048','053', '054']"
                            />
                          </li>
                        </div>
                    </td>
                </tr>
                <!-- e: 제조시설 검사 제출 서류 --> 

                <!-- s: 사용시설 검사 제출 서류 -->
                <tr v-if="this.param.bplcKndCd === '01-02'">
                    <th scope="row">파일</th>
                    <td colspan="3">
                        <div class="file_upload_list">

                          <li>
                              <br-attach-file-list label="정기검사 신청 첨부파일 목록"
                                                      ref="facAttachFileList"
                                                      atchFileTaskSeCd="FAC" 
                                                      v-model:atchFileGroupId="this.param.inspAplyAtchFileGroupId"
                                                      v-model:fileProcessingData="this.param.fileProcessingData"
                                                      :readonly="false" 
                                                      :useEtcFile="false"
                                                   :unusedAtchFileList="['001', '002', '003', '004', '005',
                                                  '006', '007', '008', '010','009', '011',
                                                  '013', '014', '015', '016','053', '054', '999']"
                              /> 
                          </li>
<!--                          <li>
                            <br-attach-file-list label="정기검사 시설 첨부파일 목록"
                                                 ref="attachFileList"
                                                 atchFileTaskSeCd="FCTC"
                                                 v-model:atchFileGroupId="param.fcltFileGroupId"
                                                 :readonly="true"
                                                 :useEtcFile="false"
                                                 :unusedAtchFileList="['004', '005']"
                            />
                          </li>-->
<!--                          <li>
                            <br-attach-file-list label="정기검사 업체 첨부파일 목록"
                                                 ref="attachFileList"
                                                 atchFileTaskSeCd="BET"
                                                 v-model:atchFileGroupId="param.bzntFileGroupId"
                                                 :readonly="true"
                                                 :useEtcFile="false"
                            />
                          </li>-->
                          <li>
                                <br-attach-file-list label="정기검사 업체 첨부파일 목록"
                                                        ref="attachFileList"
                                                        atchFileTaskSeCd="BET"
                                                        v-model:atchFileGroupId="param.bzntFileGroupId"
                                                        :readonly="true"
                                                        :useEtcFile="false"
                                />
                          </li>
                          <li>
                                <br-attach-file-list label="정기검사 사업장 첨부파일 목록"
                                                        ref="attachFileList"
                                                        atchFileTaskSeCd="BPCA" 
                                                        v-model:atchFileGroupId="param.bplcFileGroupId"
                                                        :readonly="true" 
                                                        :useEtcFile="false"
                                /> 
                          </li>
                          <li>
                                <br-attach-file-list label="정기검사 시설 첨부파일 목록"
                                                        ref="attachFileList"
                                                        atchFileTaskSeCd="FCTC"
                                                        v-model:atchFileGroupId="param.fcltFileGroupId"
                                                        :readonly="true" 
                                                        :useEtcFile="false"
                                                        :unusedAtchFileList="['009']"
                                /> 
                          </li>
                          <li>
                            <br-attach-file-list label="정기검사 업체 기타서류 목록"
                                                 ref="attachFileList"
                                                 atchFileTaskSeCd="FSR"
                                                 v-model:atchFileGroupId="param.bzntFileGroupId"
                                                 :readonly="true"
                                                 :useEtcFile="true"
                                                 :unusedAtchFileList="['041','042','043','044','045','046','047']"
                            />
                          </li>
                          <li>
                            <br-attach-file-list label="정기검사 사업장 기타서류 목록"
                                                 ref="attachFileList"
                                                 atchFileTaskSeCd="FSR"
                                                 v-model:atchFileGroupId="param.bplcFileGroupId"
                                                 :readonly="true"
                                                 :useEtcFile="true"
                                                 :unusedAtchFileList="['041','042','043','044','045','046','047']"
                            />
                          </li>
                          <li>
                            <br-attach-file-list label="정기검사 시설 기타서류 목록"
                                                 ref="attachFileList"
                                                 atchFileTaskSeCd="FSR"
                                                 v-model:atchFileGroupId="param.fcltFileGroupId"
                                                 :readonly="true"
                                                 :useEtcFile="true"
                                                 :unusedAtchFileList="['041','042','043','044','045','046','047']"
                            />
                          </li>
                          <li>
                                <br-attach-file-list label="정기검사 신청 기타서류 목록"
                                                        ref="facEtcAttachFileList"
                                                        atchFileTaskSeCd="FAC"
                                                        atchFileEditMode="Y"
                                                        v-model:atchFileGroupId="this.param.inspAplyAtchFileGroupId"
                                                        v-model:fileProcessingData="this.param.fileProcessingData"
                                                        :maxFileCount="50"
                                                        :readonly="false" 
                                                        :useEtcFile="true"
                                                        :unusedAtchFileList="['001', '002', '003', '004', '005',
                                                  '006', '007', '008', '010', '011','012',
                                                  '013', '014', '015', '016','048','053', '054']"
                                /> 
                          </li>
                        </div>
                    </td>
                </tr>
                <!-- e: 사용시설 검사 제출 서류 --> 
            </tbody>
        </table>
    </div>
    <div class="btn_area right">
        <br-button label="신청" title="신청" format="primary" panel="panel" validate @click="fcltInspAplyCreate()"/>
    </div>
    <!-- e: 콘텐츠 영역 -->

    <component  v-if="reportParamObj.selectedPopupComponent" 
            :is="reportParamObj.selectedPopupComponent" 
            :rd-param-file-name="reportParamObj.rdParamFileName" 
            :rd-param-key1="reportParamObj.rdParamKey1" 
            :rd-param-value1="reportParamObj.rdParamValue1" 
            :rd-param-key2="reportParamObj.rdParamKey2" 
            :rd-param-value2="reportParamObj.rdParamValue2" 
            :rd-param-key3="reportParamObj.rdParamKey3" 
            :rd-param-value3="reportParamObj.rdParamValue3" 
  />
</template>

<script>
/**
 * 시설에 대하여 최초, 계속, 변경 검사신청 상세정보를 등록하면 화면 
 *
 * 작성자 : CHOSUNGHO
 * 작성일 : 24. 10. 17. 
 * 버전 : 1.0
 */
import {buildUri} from "@/js/utils/uri-builder"; 
import { storeSwitch } from "@/js/store/store-switch";
import reportFcltInspAplyFormPopup from "@/views/sfli/fclt-insp-aply/popup/ReportFcltInspAplyFormPopup.vue";

const API = {
    FCLT_INSP_INFO : "/fcltInsp/{bplcId}/fcltSqno/{fcltSqno}",
    FCLT_INSP_APLY : "/fcltInsp", 
    FCLT_INSP_STAT_DETAIL : "/fcltInsp/{inspAplySn}/statDetail",
}

export default {
  components: {reportFcltInspAplyFormPopup}, 
  computed : {
    fcltInspAplyObj() {
        // Local Storage에서 데이터 불러오기
        const fcltInspAplyObj = localStorage.getItem('fcltInspAplyObj');
        return fcltInspAplyObj ? JSON.parse(fcltInspAplyObj) : {};
    },
    isDpstYnChecked: {
      get() {
        return this.param.dpstYn === 'Y';
      },
      set(value) {
        this.param.dpstYn = value ? 'Y' : 'N';
      }
    },
    isAvailableDeleteRow() {
      return this.etcFiles.length > 0;
    },
    computedFutureDaysOffset() {
      return this.param.inspSeCd === '10-10' ? 0 : 31;
    }
  },
  data() {
    return {
        param : {
            bplcId : "", 
            fcltSqno : "",
            bplcKndNm : "",
            fcltNm : "",
            bplcNm : "",
            frstInspPassYn : "",
            bplcKndCd : "",
            pubBzentyYn : "", 
            chgInspTrgtYn : "",
            inspAplyTrgtYn : "",
            pubBplcYn: "", 
            useFcltKndCd : "",
            inspPrgrsSttsCd : "",
            inspClsfCd : "",
            inspSeCd : "",
            inspHopeYmd : "", 
            dpstYn : "", 
            dpstPrnmntYmd : "",
            pyrNm : "", 
            frstInspAplyYn : "", 
            // inspYr : "", 
            // inspQtr : "", 
            inspAplyAtchFileGroupId: "",
            fileProcessingData: {},
      },
      reportParamObj : {
        selectedPopupComponent: null, // 선택된 리포트/이폼 화면 구분 ID 
        rdParamKey1: "",
        rdParamValue1: "",
        rdParamKey2: "",
        rdParamValue2: "",
        rdParamKey3: "",
        rdParamValue3: "",
        rdParamFileName: "", 
      }, 
      fileAllowedExtensions: ['jpg', 'jpeg', 'gif', 'png', 'tif', 'bmp', 'hwp', 'pdf', 'xlsx', 'xls', 'doc', 'docx', 'ppt', 'pptx'],
      // fileTextAreaWidth: "360px",  
      fileProcessingDataTest: {},
      additionalFiles: [],
      isZoomOpen: false,
      etcFiles: [],
    };
  },
  watch : {},
  created() {},
  mounted() {
    window.scrollTo({ top: 0, behavior: 'auto' });
    if (this.fcltInspAplyObj.inspAplySnValue !== '') { 
        this.readFcltInspStatDetailByInspAplySn(); // 검사신청일련번호가 존재할 경우 시설검사현황 상세 조회 
    } else {
        this.readFcltInfoByBplcIdAndFcltSqno(); // 검사신청일련번호가 없을 경우 사업장, 시설 등 정보 조회 
    }

    document.addEventListener("click", this.handleClickOutside);
  },
  beforeMount (){},
  methods : {
    readFcltInfoByBplcIdAndFcltSqno() {
        this.$apiCall.get(buildUri(API.FCLT_INSP_INFO, {bplcId : this.fcltInspAplyObj.bplcIdValue, fcltSqno : this.fcltInspAplyObj.fcltSqnoValue}), {}, this.readFcltInfoByBplcIdAndFcltSqnoSuccess, this.error);
    },
    readFcltInfoByBplcIdAndFcltSqnoSuccess(data) {
      if (!data.result) {
        this.error();
        return;
      }
      this.param = data.result;
      this.param.inspPrgrsSttsCd = "10";   // 검사진행상태코드 : 신청 = 10
      this.param.inspClsfCd = "10";        // 검사분류코드 : 시설검사 = 10
      this.param.inspSeCd = this.fcltInspAplyObj.inspSeCdValue; // 검사구분코드 : 이전화면에서 전송한 매개변수 값 적용 (최초검사 = 10-10, 계속검사 = 10-20, 변경검사 = 10-30) 
    },
    readFcltInspStatDetailByInspAplySn() {
        this.$apiCall.get(buildUri(API.FCLT_INSP_STAT_DETAIL, {inspAplySn : this.fcltInspAplyObj.inspAplySnValue}), {}, this.readFcltInspStatDetailByInspAplySnSuccess, this.error);
    },
    readFcltInspStatDetailByInspAplySnSuccess(data) {
      if (!data.result) {
        this.error();
        return;
      }
      this.param = data.result;
      this.param.inspPrgrsSttsCd = "10";   // 검사진행상태코드 : 신청 = 10
      this.param.inspClsfCd = "10";        // 검사분류코드 : 시설검사 = 10
    },
    error() {
      alert("데이터를 가져오는 중 오류가 발생했습니다.");
    },
    dpstYnCheckBoxChg() {
        this.param.dpstYn = this.dpstYn ? 'Y' : 'N';
    }, 
    fcltInspAplyCreate() {
        let txtMsg = ""; 
        
        // this.param.inspYr = this.param.inspHopeYmd.substring(0, 4); // 검사연도 
        // this.param.inspQtr = String( Math.ceil( parseInt(this.param.inspHopeYmd.substring(4, 6)) / 3 ) ); // 검사분기  
        
        if (this.param.inspSeCd === '10-10') {
          txtMsg = "최초검사 신청하시겠습니까?"; 
          this.param.frstInspAplyYn = 'Y'; // 최초검사신청여부 

        } else if (this.param.inspSeCd === '10-20') {
          txtMsg = "정기검사 신청하시겠습니까?"; 
          this.param.frstInspAplyYn = 'N';  // 최초검사신청여부 
          this.param.chgInspTrgtYn = 'N';   // 시설 변경검사대상여부 

        } else if (this.param.inspSeCd === '10-30') { 
          txtMsg = "변경검사 신청하시겠습니까?"; 
          this.param.frstInspAplyYn = 'N';  // 최초검사신청여부 
          this.param.chgInspTrgtYn = 'N';   // 시설 변경검사대상여부 
        }

        if (!confirm(txtMsg)){
            return; 
        }

        this.$refs.facAttachFileList.save().then(atchFileGroupId => {
          this.param.atchFileGroupId = atchFileGroupId;

          this.$refs.facEtcAttachFileList.save().then(atchFileGroupId => {
            this.param.etcAtchFileGroupId = atchFileGroupId;

            this.$apiCall.post(
                API.FCLT_INSP_APLY, 
                this.param,
                (data) => {
                    // 제조시설, 최초검사일 경우 품질 병행검사 신청 
                    if (this.param.bplcKndCd === '01-01' && this.param.inspSeCd === '10-10' && this.param.frstInspPassYn !== 'Y' && this.fcltInspAplyObj.inspAplySnValue === '') { 
                        const pInspAplySn = data.result.inspAplySn;
                        alert("정기 검사 신청이 완료 되었습니다.\r검사 결과가 “합격” 이후에 실적입력이 가능합니다.\r진행사항은 알림톡을 통해 안내됩니다.\r\r품질검사 신청 화면으로 이동합니다.");
                        this.navigateQltyInspAplyPage(pInspAplySn);
                    } else {
                        alert("정기 검사 신청이 완료 되었습니다.\r검사 결과가 “합격” 이후에 실적입력이 가능합니다.\r진행사항은 알림톡을 통해 안내됩니다.");
                        this.navigateTo('/sfli/FcltInspAplyStat');
                    }
                },
                (error) => {
                  if(error?.message){
                    alert(error.message);
                  }else{
                    alert("저장에 실패하였습니다.");
                  }
                }
            );

          });
        });
    }, 
    navigateTo(path) {
    // Local Storage 'fcltInspAplyObj' 삭제 
      localStorage.removeItem('fcltInspAplyObj');

      if (path) {
        this.$router.push(path).catch(() => {
          console.error("Navigation Error");
        });
      }
    },
    navigateQltyInspAplyPage(pInspAplySn) {
      if (!pInspAplySn) {
        alert("정기검사 신청 일련번호 데이터가 존재하지 않습니다."); 
        return;
      }

      const dataObj = {
        bplcId: this.fcltInspAplyObj.bplcIdValue,       /* 사업장 아이디 */
        fcltSqno: this.fcltInspAplyObj.fcltSqnoValue,   /* 시설일련번호 */
        fcltInspAplySn: pInspAplySn,                    /* 검사신청일련번호(시설검사신청일련번호) */
        inspSeCd: "20-10",                              /* 검사구분코드 */
      };

      // 데이터를 Local Storage에 저장 
      localStorage.setItem('qltyInspAplyObj', JSON.stringify(dataObj)); 

      // Local Storage 'fcltInspAplyObj' 삭제 
      localStorage.removeItem('fcltInspAplyObj');

      this.$router.push(
        {
          path : "/sfli/QltyInspAply"
        }
      ).catch(
        () => { 
          console.error("Navigation Error"); 
        }
      );
    },
    toggleZoom() {
      this.isZoomOpen = !this.isZoomOpen;
    },
    handleClickOutside(event) {
      if (this.$refs.zoomMenu && !this.$refs.zoomMenu.contains(event.target)) {
        this.isZoomOpen = false;
      }
      
    //   this.refreshToken();
    },
    openReportForm(rdFimeName) { // 리포트/이폼 화면 열기 
      this.reportParamObj.selectedPopupComponent = null; // 컴포넌트 리셋
      this.openLayerPopup(rdFimeName);

      /*
       * 재호출 후 팝업에서 노출되는 현상으로 재호출 
       */ 
      setTimeout(() => {
        this.openLayerPopup(rdFimeName);
      }, 50); 
    },
    openLayerPopup(rdFimeName) { // 레이어 팝업 기능 

      setTimeout(() => {
        this.reportParamObj.selectedPopupComponent = 'reportFcltInspAplyFormPopup'; // 정기검사 신청 내역 화면내 팝업 컴포넌트 

        // rd Param 초기화 
        this.reportParamObj.rdParamFileName = rdFimeName;
        this.reportParamObj.rdParamKey1 = "";
        this.reportParamObj.rdParamValue1 = "";
        this.reportParamObj.rdParamKey2 = "";
        this.reportParamObj.rdParamValue2 = "";
        this.reportParamObj.rdParamKey3 = "";
        this.reportParamObj.rdParamValue3 = "";

        // rd 파일명 기준으로 Param 적용   
        this.reportParamObj.rdParamFileName = rdFimeName;                         // 리포트 mrd 파일명 
        this.reportParamObj.rdParamKey1 = 'inspSeCd';                              // 리포트 화면 Param Key 
        this.reportParamObj.rdParamValue1 = this.fcltInspAplyObj.inspSeCdValue;  // 리포트 화면 Param Key에 대한 Value  
        this.reportParamObj.rdParamKey2 = 'bplcId';                                // 리포트 화면 Param Key 
        this.reportParamObj.rdParamValue2 = this.fcltInspAplyObj.bplcIdValue;  // 리포트 화면 Param Key에 대한 Value  
        this.reportParamObj.rdParamKey3 = 'fcltSqno';                              // 리포트 화면 Param Key 
        this.reportParamObj.rdParamValue3 = this.fcltInspAplyObj.fcltSqnoValue;  // 리포트 화면 Param Key에 대한 Value  

        storeSwitch.on(this.reportParamObj.selectedPopupComponent); 
      }, 0); // 0ms 딜레이로 컴포넌트 강제 리렌더링 (리포트 리렌더링 안 되는 케이스가 존재하여 추가)
    },
  }
}
</script>

<style scoped>
  .margin_top10 {
    margin-top: 11px;
  }

  /* .file_upload_list dt {
    width: 360px !important;
  } */
</style>