import apiCall from "@/js/api-call";
import {buildUri} from "@/js/utils/uri-builder";

const API = {
    UPLOAD : "/image/upload",
    DOWNLOAD: "/api/file/download/{atchFileVldNo}"
}

export class UploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        return this.loader.file
            .then(file => new Promise((resolve, reject) => {
                this._uploadFile(file, resolve, reject);
            }));
    }

    abort() {
    }

    _uploadFile(file, resolve, reject) {
        const formData = new FormData();
        formData.append("file", file);

        apiCall.upload(API.UPLOAD, formData, (response) => {
            const result = response.data.result;
            console.log(result);

            const imageUrl = buildUri(API.DOWNLOAD, {atchFileVldNo : result.atchFileVldNo});

            const envImageUrl = process.env.VUE_APP_ENV === 'local'
                ? `${process.env.VUE_APP_API_PROXY_URL}${imageUrl}`
                : `${imageUrl}`;

            resolve({
                default : envImageUrl
            });
        }, (error) => {
            reject(error.message);
        });
    }
}