<template>
  <div class="cont_box">
    <div class="title_btn_area">
      <h3 class="title">온라인 문의 상세</h3>
    </div>
    <div class="board_write">
      <table>
        <caption>온라인 문의 상세 제목, 공개 상태, 등록자, 처리자, 문의 첨부파일, 문의내용, 처리 첨부파일, 처리내용</caption>
        <colgroup>
          <col style="width:18%">
          <col style="width:32%">
          <col style="width:18%">
          <col style="width:32%">
        </colgroup>
        <tbody>
        <tr>
          <th scope="row">제목</th>
          <td colspan="3">
            {{param.dscsnTtl}}
          </td>
        </tr>
        <tr>
          <th scope="row">공개 상태</th>
          <td v-if="param.rlsYn === 'Y'" colspan="3">
            공개
          </td>
          <td v-else colspan="3">
            비공개
          </td>
        </tr>
        <tr>
          <th scope="row">등록자</th>
          <td>
            {{param.dscsnUserNm}}
          </td>
          <th scope="row">등록일자</th>
          <td>
            {{param.regDt}}
          </td>
        </tr>
        <tr>
          <th scope="row">처리자</th>
          <td>
            {{param.ansUserNm}}
          </td>
          <th scope="row">처리일자</th>
          <td>
            {{param.ansYmd}}
          </td>
        </tr>
        <tr>
          <th scope="row">문의 첨부파일</th>
          <td colspan="3" v-show="param.dscsnFiles.length != 0">
            <br-attach-file  label="첨부파일"
                            v-model:fileProcessingData="param.dscsnFileProcessingData"
                            v-model:atchFileGroupId="this.param.dscsnAtchFileGroupId"
                            :files="(param.dscsnFiles)? param.dscsnFiles : []"
                            :maxFileCount="100"
                            readonly/>

          </td>
          <td colspan="3" v-show="param.dscsnFiles.length == 0">
            <span >첨부파일이 없습니다.</span>
          </td>
        </tr>
        <tr>
          <th scope="row">문의내용</th>
          <td colspan="3" v-html="param.onlnDscsnQstnCn">
          </td>
        </tr>
        <tr>
          <th scope="row">처리 첨부파일</th>
          <td colspan="3" v-show="param.ansFiles.length != 0">
            <br-attach-file  label="첨부파일"
                            v-model:fileProcessingData="param.ansFileProcessingData"
                            v-model:atchFileGroupId="this.param.ansAtchFileGroupId"
                            :files="param.ansFiles"
                            :maxFileCount="100"
                            readonly/>
          </td>
          <td colspan="3" v-show="param.ansFiles.length == 0">
            <span v-show="param.ansFiles.length == 0">첨부파일이 없습니다.</span>
          </td>
        </tr>
        <tr>
          <th scope="row">처리내용</th>
          <td colspan="3" v-html="param.onlnDscsnAnsCn">
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="btn_area right">
      <template v-if="userId == param.dscsnUserId">
        <br-button label="수정" format="tertiary line" panel="panel" validate @click="toEdit"/>
        <br-button label="삭제" format="secondary line" @click="deleteDscsn"/>
      </template>
      <br-button label="목록" format="tertiary line" panel="panel" @click="navigate()"/>
    </div>
  </div>
</template>

<script>

import BrFormRadio from "@/components/BrFormRadio.vue";
import CkEditor from "@/components/CkEditor.vue";
import {storeSession} from "@/js/store/store-session";

const API = {
  ONLINEDISCUSSION: "/onlinediscussion",
};

export default {
  components: {BrFormRadio, CkEditor},
  data() {
    return {
      options:[
        {"value": "Y", "text": "공개"},
        {"value": "N", "text": "비공개"},
      ],
      userId: storeSession.getMemberInfo().userId,
      onlnDscsnSn: "",
      param: {
        dscsnFileProcessingData: {},
        ansFileProcessingData: {},
        dscsnFiles: [],
        dscsnAtchFileGroupId: "",
        ansFiles: [],
        ansAtchFileGroupId: "",
        dscsnUserId: "",
        dscsnTtl: "",
        onlnDscsnQstnCn: "",
        rlsYn: "",
      },
    }
  },
  beforeMount() {
    this.onlnDscsnSn = this.$route.query.onlnDscsnSn;
    this.read();
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods : {
    read() {
      this.$apiCall.get(API.ONLINEDISCUSSION+"/detail", {onlnDscsnSn: this.onlnDscsnSn}, this.success, this.error);
    },
    async success(data) {
      if (!data.result) {
        this.error();
        return;
      }

      if(data.result.delYn == "Y"){
        alert("삭제된 게시물 입니다.");
        this.navigate();
        return;
      }

      if(data.result.useYn == "N"){
        alert("미사용 게시물 입니다.");
        this.navigate();
        return;
      }

      if(data.result.rlsYn=='N' && this.userId != data.result.dscsnUserId){
        alert("비공개 게시글 입니다.");
        this.navigate();
        return;
      }

      const json = JSON.parse(JSON.stringify(data.result));
      this.param = json;
      if (this.param.dscsnFiles == null) this.param.dscsnFiles = [];
      if (this.param.ansFiles == null) this.param.ansFiles = [];

      if (this.param.ansYmd) {
        let date = this.param.ansYmd
        this.param.ansYmd = date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6, 8)
      }
    },
    error(error) {
      if(error?.message) {
        alert(error.message);
      }else{
        alert("데이터를 가져오는 중 오류가 발생했습니다.");
      }
      this.navigate();
    },
    toEdit() {
      if(this.param.onlnDscsnAnsCn != null && this.param.onlnDscsnAnsCn != ""){
        alert("답변이 등록된 문의 내역은 수정할 수 없습니다.");
        return;
      }
      this.$router.push({path : `/cust-sprt/OnlineDiscussionCreate`, query : {onlnDscsnSn : this.onlnDscsnSn}})

    },
    deleteDscsn() {
      if(confirm("삭제하시겠습니까?") != true) {
        return;
      }
      this.$apiCall.delete(
        API.ONLINEDISCUSSION,
        {"onlnDscsnSn": this.onlnDscsnSn},
        () => {
          alert("삭제되었습니다.")
          this.navigate();
        },
        () => {
          alert("삭제에 실패하였습니다.")
        }
      )
    },
    navigate() {
      this.$router.push({path : `/cust-sprt/OnlineDiscussionManage`})
        .catch(() => {
            console.error("Navigation Error")
          }
        );
    },
  },
}
</script>

<style scoped>

</style>
