const MENU_LIST = [
    {
        menuGrpNm: "시스템소개",
        path: "/sys-otln/SysIntro",
        items: [],
        isPublic: true,
    },
    {
        menuGrpNm: "사용자별 주요기능",
        path: "/sys-otln/SysCpst",
        items: [],
        isPublic: true,
    },
    {
        menuGrpNm: "시스템 사용절차",
        path: "/sys-otln/SysUseProcs",
        items: [],
        isPublic: true,
    },
];

export default {
    getMenus: function (){
        return {
            groupName: "시스템개요",
            menuList: MENU_LIST,
        };
    },
};