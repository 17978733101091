function isNumber(data) {
    const regex = new RegExp(/^[0-9]*\.?[0-9]+$/);
    return regex.test(data);
}

function password(data) {
    let trimmed = data.trim();
    return (trimmed.length >= 10) ? { completed: true, value: trimmed } : { completed: false, value: trimmed };
}

function email(data) {
    let trimmed = data.trim();
    return (trimmed.includes('@') && trimmed.includes('.') && trimmed.length >= 5) ? { completed: true, value: trimmed } : { completed: false, value: trimmed };
}

function brno(data) {
    let trimmed = data.trim().replaceAll('-', '');
    if (isNumber(trimmed)) {
        if (trimmed.length === 10) {
            return { completed: true, value: trimmed.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3') };
        } else {
            return { completed: false, value: trimmed };
        }
    } else {
        return { completed: false, value: "" };
    }
}

function number(data) {
    if (typeof data === 'number') {
        data = data.toString();
    }

    let trimmed = data.trim().replace(/[^0-9.-]/g, '');
    let [integerPart, decimalPart] = trimmed.split('.');

    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (decimalPart !== undefined) {
        decimalPart = decimalPart.slice(0, 2);  // 소수점 이하 2자리까지만 유지
    }

    let formattedNumber = decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;
    return { completed: true, value: formattedNumber };
}

function pass(data) {
    return { completed: true, value: data.trim() };
}

const map = new Map();
map.set("password", { regex: new RegExp(/^(?=.*[a-zA-Z0-9])(?=.*[a-zA-Z!@#$%^*+=-\\(\\)])(?=.*[0-9!@#$%^*+-\\(\\)]).{10,15}$/), format: password });
map.set("email", { regex: new RegExp(/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i), format: email });
map.set("brno", {regex: new RegExp(/(\d{3})-?(\d{2})-?(\d{5})/), format: brno });
map.set("korean", {regex: new RegExp(/^[ㄱ-힣]+$/), format: pass });
map.set("number", { regex: new RegExp(/^-?[0-9]+(\.[0-9]+)?$/), format: number });

export default {
    getValidator: function(validator) {
        return map.get(validator);
    },
}