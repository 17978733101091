import layout from "@/css/layout.css"
import sub from "@/css/sub.css"
import board from "@/css/board.css"
import main from "@/css/main.css"
import common from "@/css/common.css"

export default {
    layout,
    sub,
    board,
    main,
    common,
}