import {reactive} from "vue";
import moment from "moment";

const STORAGE_KEY = "session";

function getData(){
    try {
        let val = sessionStorage.getItem(STORAGE_KEY);
        if (val != null) {
            return JSON.parse(decodeURIComponent(window.atob(val)));
        }
    } catch (e) {
        console.error("Error parsing session data: ", e);
    }
    return null;
}

function setData(data){
    try {
        sessionStorage.setItem(STORAGE_KEY, window.btoa(encodeURIComponent(JSON.stringify(data))));
    } catch (e) {
        console.error("Error setting session data: ", e);
    }
}

export const storeSession = reactive({
    menu: null,
    session: null,

    setSession(session){
        session.accessExpiredTime = this.createdAccessExpiredTime(session);

        this.session = session;
        setData(session);
    },
    createdAccessExpiredTime(session) {
        const accessTokenExpireSeconds = session?.token?.accessTokenExpireSeconds || 0;
        return moment.utc().add(accessTokenExpireSeconds, 'seconds').format(); // 클라이언트 시간 기준 : 만료 시간 저장
    },
    getRemainSeconds() {
        if (this.session == null) {
            this.session = getData();
        }

        const accessExpiredTime = this.session?.accessExpiredTime || 0;

        if (accessExpiredTime === 0) {
            return 0;
        }

        const expireTime = moment(accessExpiredTime);
        return expireTime.diff(moment.utc(), 'seconds');

    },
    getSession(){
        if(this.session == null){
            this.session = getData();
        }
        return this.session;
    },
    hasSession() {
        return !!this.getSession();
    },
    isGuest(){
        if(this.session == null){
            this.session = this.getSession();
        }
        return this.session != null && this.session.isGuest === true;
    },
    hasLogin(){
        if(this.session == null){
            this.session = this.getSession();
        }
        return this.session != null && this.session.isGuest === false;
    },
    hasProxy(){
        if(this.session == null){
            this.session = this.getSession();
        }
        return this.session != null && this.session.isProxy === true && this.session.proxyId != null;
    },
    getProxyId(){
        if (this.session == null){
            this.session = this.getSession();
        }
        return this.session.proxyId;
    },
    getMenuList(){
        return this.getSession()?.menuList || [];
    },
    hasMenuSn(menuSn){
        if(!this.session) return false;
        for(let menu of this.session.menuList){
            if(menuSn === menu.menuSn){
                return true;
            }
        }
        return false;
    },
    removeSession(){
        this.menu = null;
        this.session = null;
        sessionStorage.removeItem(STORAGE_KEY);
    },
    getMemberInfo() {
        if (this.session == null) {
            this.session = getData();
        }
        return this.session != null ? this.session.member : null;
    },
    getTokenInfo() {
        if (this.session == null) {
            this.session = getData();
        }
        return this.session != null ? this.session.token : null;
    },
    isTokenValid() {
        if (this.session == null) {
            this.session = getData();
        }
        if (this.session && this.session.token) {
            const now = new Date();
            const expireDate = new Date(this.session.token.accessTokenExpireDate);
            const fifteenMinutesLater = new Date(now.getTime() + 15 * 60 * 1000);

            return expireDate > fifteenMinutesLater;
        }
        return false;
    },
    getTokenRemainingSeconds() {
        if (this.session == null) {
            this.session = getData();
        }
        if (this.session && this.session.token) {
            const now = new Date();
            const expireDate = new Date(this.session.token.accessTokenExpireDate);
            const remainingTime = Math.floor((expireDate - now) / 1000);

            return remainingTime > 0 ? remainingTime : 0;
        }
        return 9999;
    },
    setRouteMenu(menu){
        this.menu = menu;
    },
    getMenuSn(){
        return this.menu != null ? this.menu.menuSn : "";
    },
    getUserId(){
        return this.session?.member?.userId || "";
    },
    getMenuAuth(menu){
        let menuList = this.session != null ? this.session.menuList : [];
        const menuItem = menuList.find(item => item.menuSn === menu.menuSn);
        if (menuItem) {
            const privilegeMap = {
                regAuthrtYn: 'C',
                mdfcnAuthrtYn: 'U',
                delAuthrtYn: 'D',
                exlAuthrtYn: 'E'
            };
            return Object.keys(privilegeMap)
                .filter(key => menuItem[key] === 'Y')
                .map(key => privilegeMap[key]);
        }
        return [];
    },
    hasAuth(userAuth, requiredAuth) {
        if (requiredAuth.length === 0) {
            return true;
        }
        return requiredAuth.every(auth => userAuth.includes(auth));
    },
    isAdminUser() {
        const userInfo = this.getMemberInfo();

        if (!userInfo) {
            return false;
        }

        const aplySysAuthrtCd = userInfo?.aplySysAuthrtCd;
        const adminPrefixes = ['03', '04', '08'];
        return adminPrefixes.some(prefix => aplySysAuthrtCd?.startsWith(prefix));
    }
})