import { createStore } from 'vuex';

export default createStore({
    state: {
        isRegistering: false, // 회원가입 진행 여부
        registerStep: 0, // 회원가입 단계
        joinUserInfo: {
            provider: '',
            userMngSe: '', // 회원 유형
            exsBzentyYn: '', // 기존사업장 여부
            chcAgreYn: 'N', // 약관 동의 여부
            brdt: '', // 생년월일
            brno: '', // 사업장번호(전체)
            brno1: '', // 사업장번호1
            brno2: '', // 사업장번호2
            brno3: '', // 사업장번호3
            ci: '',
            di: '',
            mblTelno: '', // 휴대전화번호
            userId: '', // 사용자ID
            userNm: '', // 사용자명
        },
    },
    mutations: {
        START_REGISTER(state) {
            state.isRegistering = true;
            state.registerStep = 1;
        },
        NEXT_STEP(state) {
            state.registerStep += 1;
        },
        SET_JOIN_USER_INFO(state, joinUserInfo) {
            state.joinUserInfo = {...state.joinUserInfo, ...joinUserInfo};
        },
        SET_TERMS_AGREED(state, agreed) {
            state.joinUserInfo.chcAgreYn = agreed;
        },
        RESET_REGISTER(state) {
            state.isRegistering = false;
            state.registerStep = 0;
            state.joinUserInfo = {
                provider: '',
                userMngSe: '', // 회원 유형
                exsBzentyYn: '', // 기존사업장 여부
                chcAgreYn: 'N', // 약관 동의 여부
                brdt: '', // 생년월일
                brno: '', // 사업장번호(전체)
                brno1: '', // 사업장번호1
                brno2: '', // 사업장번호2
                brno3: '', // 사업장번호3
                ci: '',
                di: '',
                mblTelno: '', // 휴대전화번호
                userId: '', // 사용자ID
                userNm: '', // 사용자명
            };
        }
    },
    actions: {
        startRegister({ commit }) {
            commit('START_REGISTER');
        },
        nextStep({ commit }) {
            commit('NEXT_STEP');
        },
        updateJoinUserInfo({ commit }, joinUserInfo) {
            commit('SET_JOIN_USER_INFO', joinUserInfo);
        },
        agreeToTerms({ commit }, agreed) {
            commit('SET_TERMS_AGREED', agreed);
        },
        resetRegister({ commit }) {
            commit('RESET_REGISTER');
        }
    },
    getters: {
        getIsRegistering: (state) => state.isRegistering,
        getRegisterStep: (state) => state.registerStep,
        getJoinUserInfo: (state) => state.joinUserInfo,
    }
});