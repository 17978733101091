<template>
    <div v-show="isOn" class="modal visible">
      <div class="popup_box" style="width: 90%; max-width: 1000px; height: 95%; max-height: 95%;">
        <h1 class="popup_title">출력</h1>
        <template v-if="isOn">
          <report-form
              :id="rdSetObj.rdFileName"
              :path="rdSetObj.rdFileName"
              :param="rdParam"
              :rd-save-data-add="rdSaveDataAddObj"
              :rd-width="rdSetObj.rdWidth"
              :rd-height="rdSetObj.rdHeight" 
              :form-mode="rdSetObj.isFormMode"
              @submit="submit"
              @cancel="cancel"
            />
          </template>
        <button type="button" class="modal_close" @click.prevent="hide" >팝업닫기</button>
      </div>
    </div>
  </template>
  
  <script>
  
  import { storeSwitch } from "@/js/store/store-switch";
  import { createRdAgent } from "@/js/store/store-rdagent";
  import ReportForm from "@/components/ReportForm.vue";
  
  export default {
    components: { ReportForm }, 
    props: {
      bplcId: {type: String, required: true},
      prfmncYr: {type: String, required: true},
      prfmncYmFrom: {type: String, required: true},
      prfmncYmTo: {type: String, required: true},
      wtItemCd: {type: String, required: true},
      mtralCdWtEtc: {type: String, required: true},
    },
    data() {
      return {
        isOn: false,
        rdSetObj: { 
          rdFileName: "reportMnftrWtCrynList",         // 이폼/리포트 파일명 (.mrd 제외)
          isFormMode: false,      // 이폼 여부 ( true : 이폼, false : 리포트 )
          pdfFileName: "",        // 이폼 제출 시 서버에 저장되는 PDF 파일명 ( .pdf 제외한 파일명 )
          rdWidth: "99.5%",       // 뷰어 너비
          rdHeight: "90%",        // 뷰어 높이
        },
      };
    },
    computed: {
      // this.rdParam();
  
      rdParam() {
        const propsData = {
          bplcId: this.bplcId || '',
          prfmncYr: this.prfmncYr || '',
          prfmncYmFrom: this.prfmncYmFrom || '',
          prfmncYmTo: this.prfmncYmTo || '',
          wtItemCd: this.wtItemCd || '',
          mtralCdWtEtc: this.mtralCdWtEtc || '',
        };

        console.log("propsData", propsData);

        return createRdAgent(
          Object.entries(propsData).map(([key, value]) => `${key} [${value}]`).join(' '),
          this.rdSetObj.isFormMode,
          this.rdSetObj.pdfFileName
        );
      }
    },
    watch: {
    },
    created() {
      storeSwitch.setBulb('reportMnftrWtCrynPopup', this);
    },
    mounted() {
    },
    beforeMount() {
    },
    methods: {
      /** 팝업 Show */
      show() {
        this.isOn = true;
      },
      /** 팝업 Hide */
      hide() {
        this.isOn = false;
      },
    }
  }
  </script>
  
  <style scoped>
  
  </style>