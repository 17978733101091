<template>
    <div v-show="isVisible" class="modal visible">
      <div class="popup_box" style="width: 70%;">
        <div class="popup_title">
          <h1>고형연료제조 폐기물 투입열량 산정</h1>
        </div>
        <div class="popup_cont">
          <div class="board_write">
            <h3>
            고형연료제조 폐기물 투입열량 산정 : 폐기물(원료별) 발열량 적용<br>
            폐기물(원료별) 발열량 출처, (환경부, 한국환경공단) 5차 전국폐기물통계조사
            </h3>
            <table>
              <tbody>
              <tr>
                <th style="text-align: center">종류</th>
                <th scope="row">생활폐기물</th>
                <th scope="row">폐합성수지</th>
                <th scope="row">폐합성섬유</th>
                <th scope="row">폐고무</th>
                <th scope="row">폐타이어</th>
                <th scope="row">폐목재</th>
                <th scope="row">식물성잔재물</th>
              </tr>
              <tr>
                <td>발열량<br>(kcal/kg)</td>
                <td>4,556</td>
                <td>8,002</td>
                <td>4,091</td>
                <td>7,498</td>
                <td>8,000</td>
                <td>3,772</td>
                <td>3,772</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="popup_btn">
          <button type="button" class="modal_close" @click="hide">팝업 닫기</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { storeSwitch } from "@/js/store/store-switch";
  
  export default {
    components: {},
    props: [],
    emits: [],
    data() {
      return {
        isVisible: false,
        param: {
          userId: '',

        },
      };
    },
    computed: {},
    watch: {
    },
    created() {
      storeSwitch.setBulb('infoPopup', this);
    },
    beforeMount() {
  
    },
    mounted() {
      this.initialize();
    },
    beforeUnmount() {},
    methods: {
      /** 팝업 Show */
      show() {
        this.isVisible = true;
        document.documentElement.style.overflow = 'hidden';
      },
      /** 팝업 Hide */
      hide() {
        this.isVisible = false;
        document.documentElement.style.overflow = '';
      },
      /** 화면 초기화 */
      async initialize() {
        // 시도 select 초기화
      },
    }
  }
  </script>
  
  <style scoped>
    th{
      text-align: center !important;
    }
    td{
      text-align: center !important;
    }
  </style>