
<template>
  <div v-show="isOn" class="modal visible">
    <div class="popup_box" style="width: 80%;">
      <h1 class="popup_title">시설 신규등록</h1>
      <div>
        <button type="button" class="modal_close" @click.prevent="hide">팝업닫기</button>
      </div>
      <div class="popup_cont" style="max-height: calc(90vh - 15px); overflow: hidden; overflow-y: auto;">

        <div class="board_info">
          <h3 class="title1">시설 정보<span class="subtitle-note">*은 필수입력 항목 입니다.</span></h3>
        </div>
        <!-- 시설 정보 -->
        <div class="board_write">
          <table>
            <caption>시설 신규등록 정보로 구성</caption>
            <colgroup>
              <col style="width:15%">
              <col style="width:35%">
              <col style="width:15%">
              <col style="width:35%">
            </colgroup>
            <tbody>
            <tr>
              <th scope="row">사업장</th>
              <td colspan="3">
                <br-form-select label="나의관리사업장목록" :options="myMngBplcList" v-model:value="selectedBplcId" @change="onChangeBplc" :customStyle="{ 'max-width': 'none' }"/>
              </td>
            </tr>
            <tr>
              <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>시설명</th>
              <td>
                <br-form-input label="시설명" v-model="bizfaciltyFclt.fcltNm" panel="panel3" required/>
              </td>
              <th scope="row" v-if="conds.uncheck"><i v-if="conds.showSrfUse" class="necessary">*<span class="sr_only">필수입력</span></i>가동시작일자</th>
              <td v-if="conds.uncheck">
                <br-date-picker label="가동시작일자" v-model:value="bizfaciltyFclt.oprtngBgngYmd" :customStyle="{ 'max-width': 'none' }" panel="panel3" :required="conds.showSrfUse"/>
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row" style="text-align: left;">
                <div style="display: flex; align-items: center;">
                  시설준공예정일자
                </div>
              </th>
              <td>
                <br-date-picker label="시설준공예정일자" v-model:value="bizfaciltyFclt.fcltCmcnPrnmntYmd" :customStyle="{ 'max-width': 'none' }"/>
              </td>
              <th scope="row">시설준공일자</th>
              <td>
                <br-date-picker label="시설준공일자" v-model:value="bizfaciltyFclt.fcltCmcnYmd" :customStyle="{ 'max-width': 'none' }"/>
              </td>
            </tr>
            <tr v-if="conds.showAllSrf && conds.uncheck">
              <th scope="row" style="text-align: left;">
                <div style="display: flex; align-items: center;">
                  <i class="necessary" style="margin-right: 5px;">필수입력</i>건조시설<br/>보유여부
                </div>
              </th>
              <td>
                <br-form-radio label="건조시설보유여부" v-model:value="bizfaciltyFclt.dhmdfyFcltHldYn" :options="radioBtnYnList" value-field="value" text-field="text" panel="panel3" :required="conds.showAllSrf"/>
              </td>
              <th scope="row" style="text-align: left;">
                <div style="display: flex; align-items: center;">
                  <i class="necessary" style="margin-right: 5px;">필수입력</i>변경공사<br/>예정여부
                </div>
              </th>
              <td>
                <br-form-radio label="변경공사예정여부" v-model:value="bizfaciltyFclt.chgCstrnPrnmntYn" :options="radioBtnYnList" value-field="value" text-field="text" panel="panel3" :required="conds.showAllSrf"/>
              </td>
            </tr>
            <tr v-if="conds.uncheck && conds.showAllSrf">
              <th scope="row" ><i class="necessary">*<span class="sr_only">필수입력</span></i>시설단위</th>
              <td :colspan="conds.showSrfUse? '' : 3" >
                <br-form-select label="시설단위" groupCd="MBR011" includeSelectOption v-model:value="bizfaciltyFclt.fcltUnitCd" :customStyle="{ 'max-width': '270px' }" panel="panel3" :required="conds.showAllSrf"/>
              </td>
              <th scope="row" v-if="conds.showSrfUse"><i class="necessary">*<span class="sr_only">필수입력</span></i>사용시설종류</th>
              <td v-if="conds.showSrfUse">
                <br-form-select label="사용시설종류" groupCd="COM441" includeSelectOption v-model:value="bizfaciltyFclt.useFcltKndCd" panel="panel3" required/>
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row"><i v-if="conds.showAllSrf" class="necessary">*<span class="sr_only">필수입력</span></i>시설공정</th>
              <td>
                <br-form-input label="시설공정" v-model="bizfaciltyFclt.fcltProcsCn" panel="panel3" :required="conds.showAllSrf"/>
              </td>
              <th scope="row">형식</th>
              <td>
                <br-form-input label="형식" v-model="bizfaciltyFclt.oprtngFrmCn" />
              </td>
            </tr>
            <tr v-if="conds.uncheck">
                  <th scope="row">가동방식</th>
                  <td :colspan="conds.showSrfMnf? '' : 3" >
                    <div class="form_group">
                      <div class="input_group">
                        <br-form-input label="가동방식" v-model="bizfaciltyFclt.oprtngMthCn" />
                      </div>
                    </div>
                  </td>
                  <th scope="row" v-if="conds.showSrfMnf"><i class="necessary">*<span class="sr_only">필수입력</span></i>생산제품종류</th>
                  <td v-if="conds.showSrfMnf">
                    <br-form-select label="생산제품종류"
                                    :options="prdctnPrdctKndCdOption"
                                    :includeSelectOption="true"
                                    v-model:value="bizfaciltyFclt.prdctnPrdctKndCd"
                                    :customStyle="{ 'max-width': 'none' }"
                                    panel="panel3"
                                    :required="conds.showSrfMnf"
                                    @change="onChagePrdctnPrdctKnd"
                    />
                  </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row"><i v-if="conds.showAllSrf" class="necessary">*<span class="sr_only">필수입력</span></i>가동시간</th>
              <td style="white-space: nowrap;" colspan="3">
                <div class="form_group">
                  <div class="input_group">
                    <br-form-input label="일간가동시간" class="form_control" unit="hr/일" v-model="bizfaciltyFclt.dilyOprtngHr"  align="right" validator="number" panel="panel3" :required="conds.showSrfMnf || conds.showSrfUse"/>
                    <br-form-input label="연간가동일수" class="form_control" unit="일/년" v-model="bizfaciltyFclt.anlOprtngDayCnt" align="right" validator="number" panel="panel3" :required="conds.showSrfMnf || conds.showSrfUse"/>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>시설용량</th>
              <td style="white-space: nowrap;" colspan="3">
                <div class="form_group">
                  <div class="input_group">
                    <br-form-input label="시간당시설용량"
                                   class="form_control"
                                   v-model="bizfaciltyFclt.hfcapa"
                                   align="right"
                                   validator="number"
                                   unit="kg/시간"
                                   panel="panel3"
                                   required
                    />
                    <br-form-input label="일간시설용량"
                                   class="form_control"
                                   v-model="bizfaciltyFclt.dilyFcltCap"
                                   align="right"
                                   validator="number"
                                   unit="ton/일"
                                   panel="panel3"
                                   required
                    />
                    <br-form-input label="연간시설용량"
                                   class="form_control"
                                   v-model="bizfaciltyFclt.anlFcltCap"
                                   align="right"
                                   validator="number"
                                   unit="ton/년"
                                   panel="panel3"
                                   required
                    />
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row">시설면적(m²)</th>
              <td colspan="3">
                <div class="form_group">
                  <div class="input_group">
                    <br-form-input label="시설면적" unit="m²" v-model="bizfaciltyFclt.fcar" align="right" validator="number"/>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="conds.showFire && conds.uncheck">
              <th scope="row"><i v-if="conds.showFire" class="necessary">*<span class="sr_only">필수입력</span></i>소각방식</th>
              <td>
                <br-form-select label="소각방식" groupCd="COM122" includeSelectOption v-model:value="bizfaciltyFclt.fireMthCd" panel="panel3" :required="conds.showFire"/>
              </td>
              <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>소각유형</th>
              <td>
                <br-form-select label="소각유형" groupCd="COM123" includeSelectOption v-model:value="bizfaciltyFclt.fireOperCd" panel="panel3" :required="conds.showFire"/>
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row"><i v-if="conds.showAllSrf" class="necessary">*<span class="sr_only">필수입력</span></i>제품보관장소</th>
              <td>
                <br-form-input label="제품보관장소" v-model="bizfaciltyFclt.prdctCstdyPlcCn" panel="panel3" :required="conds.showAllSrf"/>
              </td>
              <th scope="row"><i v-if="conds.showAllSrf" class="necessary">*<span class="sr_only">필수입력</span></i>제품보관유형</th>
              <td>
                <br-form-select label="제품보관유형" groupCd="COM406" includeSelectOption v-model:value="bizfaciltyFclt.prdctCstdyTypeCd" :customStyle="{ 'max-width': 'none' }" panel="panel3" :required="conds.showAllSrf"/>
              </td>
            </tr>
            <tr v-if="conds.uncheck">
              <th scope="row">모델번호</th>
              <td>
                <br-form-input label="모델번호" v-model="bizfaciltyFclt.mdlNo" />
              </td>
              <th scope="row">제조업체명</th>
              <td>
                <br-form-input label="제조업체명" v-model="bizfaciltyFclt.mnftrBzentyNm" />
              </td>
            </tr>
            <tr v-if="conds.showWes && conds.uncheck">
              <th scope="row">에너지자체사용여부</th>
              <td>
                <br-form-radio label="에너지자체사용여부"  v-model:value="bizfaciltyFclt.eneSlfUseYn" :options="radioBtnYnList" value-field="value" text-field="text"/>
              </td>
              <th scope="row">발전시설 용량</th>
              <td>
                <div class="form_group">
                  <div class="input_group">
                    <br-form-input label="발전시설용량" unit="kw" v-model="bizfaciltyFclt.elctgnFcltCap" align="right" validator="number"/>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="conds.uncheck && (conds.showFire || conds.showSrfUse)">
              <th scope="row" v-if="conds.showFire"><i class="necessary">*<span class="sr_only">필수입력</span></i>소각폐기물발열량</th>
              <td v-if="conds.showFire">
                <div class="form_group">
                  <div class="input_group">
                    <br-form-input label="소각폐기물발열량" unit="kcal/kg" v-model="bizfaciltyFclt.fireWtCalqty" align="right" validator="number" panel="panel3" :required="conds.showFire"/>
                  </div>
                </div>
              </td>
              <th scope="row"><i class="necessary">*<span class="sr_only">필수입력</span></i>증기엔탈피값</th>
              <td>
                <div class="form_group">
                  <div class="input_group">
                    <br-form-input label="증기엔탈피값" unit="Kcal/kg" v-model="bizfaciltyFclt.vaporEnthlpVl" align="right" validator="number" panel="panel3" :required="conds.showFire || conds.showSrfUse"/>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="(conds.showFire || conds.showSrfUse) && conds.uncheck">
              <th scope="row">보일러부하율</th>
              <td>
                <div class="form_group">
                  <div class="input_group">
                    <br-form-input label="보일러부하율" unit="%" v-model="bizfaciltyFclt.blrLdrt" align="right" validator="number" :maxlength="3"/>
                  </div>
                </div>
              </td>
              <th scope="row">보일러효율</th>
              <td>
                <div class="form_group">
                  <div class="input_group">
                    <br-form-input label="보일러효율" unit="%" v-model="bizfaciltyFclt.blrEfcnc" align="right" validator="number" :maxlength="3"/>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="conds.showBio && conds.uncheck">
              <th scope="row">BIO가스생산량</th>
              <td>
                <div class="form_group">
                  <div class="input_group">
                    <br-form-input label="BIO가스생산량" unit="N㎥" v-model="bizfaciltyFclt.bioGasPrdqty" align="right" validator="number"/>
                  </div>
                </div>
              </td>
              <th scope="row">BIO가스 공급시설 용량</th>
              <td>
                <div class="form_group">
                  <div class="input_group">
                    <br-form-input label="BIO가스공급시설용량" unit="N㎥" v-model="bizfaciltyFclt.bioGasSplyFcltCap" validator="number" />
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="conds.showBio && conds.uncheck">
              <th scope="row">소화조설계 유입량</th>
              <td>
                <div class="form_group">
                  <div class="input_group">
                    <br-form-input label="소화조설계유입량" unit="N㎥" v-model="bizfaciltyFclt.dgstrDesignInsrtCap" align="right" validator="number" />
                  </div>
                </div>
              </td>
              <th scope="row">소화조 체류일수</th>
              <td>
                <div class="form_group">
                  <div class="input_group">
                    <br-form-input label="소화조체류일수" unit="일" v-model="bizfaciltyFclt.dgstrStayNmds" align="right" validator="number"/>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="conds.showBio && conds.uncheck">
              <th scope="row"><i v-if="conds.showBio" class="necessary">*<span class="sr_only">필수입력</span></i>소화조 용량</th>
              <td colspan="3">
                <div class="form_group">
                  <div class="input_group">
                    <br-form-input label="소화조용량" unit="N㎥" v-model="bizfaciltyFclt.dgstrCap" align="right" validator="number" panel="panel3" :required="conds.showBio"/>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="conds.showSrfUse">
              <th scope="row">혼소비율</th>
              <td colspan="3">
                <div class="form_group">
                  <div class="input_group">
                    <br-form-input label="혼소비율" v-model="bizfaciltyFclt.synFireRtCn"/>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="conds.showAllSrf">
              <th scope="row">시설신고허가일자</th>
              <td colspan="3">
                <div class="form_group">
                  <div class="input_group">
                    <br-date-picker label="시설신고허가일자" v-model:value="bizfaciltyFclt.fcltDclrPrmsnYmd" />
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- 투입품목 정보 -->
        <template v-if="['01-01', '01-02', '04-01'].includes(this.selectedBplcKndCd)">
          <div class="board_info mt_40">
            <h3 class="title2"><i class="necessary">*<span class="sr_only">필수입력</span></i>시설 투입품목</h3>
            <div>
              <br-button format="tertiary" label="추가" style="margin-right: 5px" class="btn sm primary line ico_plus" @click="addRow" />
              <br-button format="tertiary" label="삭제" class="btn sm point ico_minus" @click="removeRow" />
            </div>
          </div>
          <div class="board_list">
              <table style="table-layout: fixed;">
                <caption> 시설 투입품목 - 투입품목, 투입비율, 일간투입량으로 구성 </caption>
                <colgroup>
                  <col style="width:10%">
                  <col style="width:60%">
                  <col v-show="['01-01', '01-02'].includes(this.selectedBplcKndCd)" style="width:30%">
                  <col v-show="['04-01'].includes(this.selectedBplcKndCd)" style="width:30%">
                </colgroup>
                <thead>
                <tr>
                  <th scope="col">선택</th>
                  <th scope="col">투입품목</th>
                  <th v-show="['01-01', '01-02'].includes(this.selectedBplcKndCd)" scope="col">투입비율 (%)</th>
                  <th v-show="['04-01'].includes(this.selectedBplcKndCd)" scope="col">일간투입량</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="fcltInputItemList.length <= 0">
                  <td colspan="12">
                    <div style="display: flex; justify-content: center;">
                      <div class="no-data-message">등록된 데이터가 없습니다.</div>
                    </div>
                  </td>
                </tr>
                <tr v-else v-for="(item, index) in fcltInputItemList" :key="index"
                    :class="{'checked-style': item.chk === 'Y'}">
                  <td aria-label="선택" @click="toggleCheckbox(index)" class="checkbox-cell">
                    <input type="checkbox" id="chk1" v-model="item.chk"
                           true-value="Y" false-value="N" @click.stop>
                  </td>
                  <td aria-label="투입품목">
                    <br-form-select label="투입품목" :options="inputItemCdOption" v-model:value="item.inputItemCd" :disabled="!!item.fcltSqno" @change="handleSelectChange(item.inputItemCd, index)" :customStyle="{ 'max-width': 'none' }"/>
                  </td>
                  <td v-show="['01-01', '01-02'].includes(this.selectedBplcKndCd)" aria-label="투입비율">
                    <br-form-input label="투입비율"
                                   oninput="this.value = this.value.replace(/[^0-9.]/g, '')"
                                   v-model="item.inputItemCpstRt"
                                   :maxlength="3"
                                   @input="markAsChanged(index)"
                                   :disabled="['04-01'].includes(this.selectedBplcKndCd)"
                                   align="right"/>
                  </td>
                  <td v-show="['04-01'].includes(this.selectedBplcKndCd)" aria-label="일간투입량">
                    <br-form-input label="일간투입량"
                                   oninput="this.value = this.value.replace(/[^0-9.]/g, '')"
                                   v-model="item.dilyInpqty"
                                   @input="markAsChanged(index)"
                                   :disabled="['01-01', '01-02'].includes(this.selectedBplcKndCd)"
                                   align="right"/>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
        </template>
        <h3 class="title2">첨부서류</h3>
        <br-attach-file-list label="시설 첨부파일 목록"
                             ref="attachFileList"
                             v-model:atchFileTaskSeCd="atchFileTaskSeCd"
                             v-model:atchFileGroupId="bizfaciltyFclt.atchFileGroupId"
                             :maxFileCount="10"
                             :readonly="false"
                             :useEtcFile="true"
        />
        <div class="btn_area center">
          <br-button label="등록" format="primary" @click="createFclt" panel="panel3" validate/>
          <br-button label="닫기" class="popup_close" @click="hide"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";
import BrDatePicker from "@/components/BrDatePicker.vue";
import BrFormRadio from "@/components/BrFormRadio.vue";
import BrFormSelect from "@/components/BrFormSelect.vue";
import BrButton from "@/components/BrButton.vue";
import BrFormInput from "@/components/BrFormInput.vue";
import myBplcMngPopup from "@/views/my-office/components/popup/MyBplcMngPopup.vue";

const API = {
  FCLT_INFO: "/bizfacility/fclt-info",
  MY_MNG_BPLC: "/bizfacility/my-bplc-mng",
  FCLT_CNT: "/bizfacility/fclt-cnt",
  PRDCTN_PRDCT_KND: "/bizfacility/prdctn-prdct-knd",
};

const CODE = {
  INPUT_ITEM_CD: "MBR010", // 생산제품종류코드
  PRDCTN_PRDCT_KND_CD: "MBR010", // 생산제품종류코드
};

const initialBizfaciltyFclt = {
  bplcId: '',
  fcltSqno: '',
  fcltNm: '',
  qltyTaskIssuNo: '',
  prdctnPrdctKndCd: '',
  fcltCmcnPrnmntYmd: '',
  fcltCmcnYmd: '',
  dhmdfyFcltHldYn: 'N',
  chgCstrnPrnmntYn: 'N',
  useFcltKndCd: '',
  fcar: '',
  hfcapa: '',
  dilyFcltCap: '',
  anlFcltCap: '',
  fcltUnitCd: '',
  fcltProcsCn: '',
  oprtngFrmCn: '',
  oprtngMthCn: '',
  dilyOprtngHr: '',
  anlOprtngDayCnt: '',
  prdctCstdyPlcCn: '',
  prdctCstdyTypeCd: '',
  fcltSttsCd: '01',
  aprvYn: '',
  oprtngBgngYmd: '',
  oprtngHltBgngYmd: '',
  oprtngHltEndYmd: '',
  clsgYmd: '',
  oprtngHltClsgRsn: '',
  mdlNo: '',
  mnftrBzentyNm: '',
  frstInspRsltSn: '',
  frstInspYmd: '',
  frstInspPassYn: 'N',
  chgInspTrgtYn: 'N',
  vaporEnthlpVl: '',
  blrLdrt: '',
  blrEfcnc: '',
  eneSlfUseYn: 'N',
  fireMthCd: '',
  fireOperCd: '',
  fireWtCalqty: '',
  bioGasPrdqty: '',
  bioGasSplyFcltCap: '',
  dgstrDesignInsrtCap: '',
  dgstrStayNmds: '',
  dgstrCap: '',
  elctgnFcltCap: '',
  synFireRtCn: '',
  atchFileGroupId: '',
  useYn: 'Y',
  rmrkCn: '',
  regDt: '',
  rgtrId: '',
  mdfcnDt: '',
  mdfrId: '',
  chgAplyPrcsSttsCd: '',
  dtlChgAplyPrcsSttsCd: '',
  prcsRsn: '',
  chgAplyTypeCd: '',
  fcltDclrPrmsnYmd: '',
};

const initialConds = {
  showWes: false,
  showFire: false,
  showBio: false,
  showAllSrf: false,
  showSrfMnf: false,
  showSrfUse: false,
  uncheck: false,
};

export default {
  components: {BrFormInput, BrButton, BrFormSelect, BrFormRadio, BrDatePicker},
  emits: ['completed'],
  props: {
    bplcId: {type: String, required: true},
    bplcNm: {type: String, required: true},
    bplcKndCd: {type: String, required: true},
  },
  data() {
    return {
      isOn: false,
      atchFileTaskSeCd: '',
      selectedBplcId: '',
      selectedBplcNm: '',
      selectedBplcKndCd: '',
      myMngBplcList: [],
      uncheckedList: [  // 미관리 종류
        '06-01',
        '07-01',
        '08-01',
        '09-01',
        '10-01',
      ],
      conds : {...initialConds },
      bizfaciltyFclt: {...initialBizfaciltyFclt},
      fcltInputItemList: [],
      prdctnPrdctKndCdList: [],
      prdctnPrdctKndCdOption: [],
      radioBtnYnList: [
        {value: "Y", text: "대상"},
        {value: "N", text: "비대상"},
      ],
      initialInputItemOption: [{ value: '', text: '선택' }],
      inputItemCdList: [],
      inputItemCdOption: [],
      changedRows: new Set(), // 변경된 행의 인덱스를 저장하는 Set
    };
  },
  computed: {
    myBplcMngPopup() {
      return myBplcMngPopup
    }
  },
  watch: {
    isOn(newVal) {
      if(newVal){
        this.initialize();
      }else{
        this.reset();
      }
    },
  },
  created() {
    storeSwitch.setBulb('createFcltPopup', this);
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeUnmount() {
  },
  methods: {
    show() {
      this.isOn = true;
    },
    hide() {
      this.isOn = false;
    },
    async initialize() {

      this.selectedBplcId = this.bplcId;
      this.selectedBplcNm = this.bplcNm;
      this.selectedBplcKndCd = this.bplcKndCd;
      this.inputItemCdOption = [...this.initialInputItemOption];
      this.prdctnPrdctKndCdList = this.$store.getters.getCodeList({groupCd: CODE.PRDCTN_PRDCT_KND_CD, useYn: 'Y'});   // 생산제품종류
      this.inputItemCdList = this.$store.getters.getCodeList({groupCd: CODE.INPUT_ITEM_CD, useYn: 'Y'});   // 투입품목코드

      await this.readMyMngBplc();
      await this.setConditions(this.selectedBplcKndCd);
      await this.filterPrdctnPrdctList();
      await this.filterInputItemList();
      try {
        await this.$refs.attachFileList.load(this.atchFileTaskSeCd, this.bizfaciltyFclt.atchFileGroupId);
      } catch (e) {
        alert("파일을 불러오지 못헀습니다.");
      }
    },
    readMyMngBplc(){
      this.$apiCall.get(API.MY_MNG_BPLC, {},data => {
        if(data.result.list){
          this.myMngBplcList = data.result.list
              .filter(item => item.bplcKndCd !== '01-03')
              .map(
              item =>({
                value: item.bplcId,
                text: item.bplcNm,
                bplcKndCd: item.bplcKndCd,
              })
          );
        }else{
          this.myMngBplcList = []
        }
      })
    },
    createFclt() {

      if(!this.validateFcltItem()){
        return ;
      }
      console.log(this.bizfaciltyFclt.fcltDclrPrmsnYmd)
      if(confirm("시설을 등록하시겠습니까?")){

        this.$refs.attachFileList.save().then(atchFileGroupId => {

          this.bizfaciltyFclt.atchFileGroupId = atchFileGroupId;

          this.bizfaciltyFclt.bplcId = this.selectedBplcId;

          this.bizfaciltyFclt.prcsRsn = '신규 등록'
          // '01' 승인신청 -> 변경신청구분코드
          this.bizfaciltyFclt.chgAplySeCd = '01';
          // '01' 승인대기 -> 변경신청처리상태코드
          this.bizfaciltyFclt.chgAplyPrcsSttsCd = '01';
          // '01' 승인대기 -> 상세변경신청처리상태코드
          this.bizfaciltyFclt.dtlChgAplyPrcsSttsCd = '01';
          //변경신청유형코드  -> 03 시설
          this.bizfaciltyFclt.chgAplyTypeCd = '03';

          // 투입품목 정보 입력
          this.bizfaciltyFclt.fcltInputItemList = this.fcltInputItemList;

          this.$apiCall.post(API.FCLT_INFO, this.bizfaciltyFclt, () => {
                alert("정상적으로 등록되었습니다.\n 시스템 이용을 위해서는 관리자의 승인이 필요합니다.");
                this.reset();
                this.$emit('completed',)
                this.isOn = false;
              },
              () => {
                alert("요청에 실패하였습니다.");
              }
          );
        });
      }
    },
    setConditions(bplcKndCd) {
      const mainCd = bplcKndCd.slice(0, 2);
      const subCd = bplcKndCd.slice(3, 5);

      // 초기화
      this.conds = {
        showWes: false,
        showFire: false,
        showBio: false,
        showAllSrf: false,
        showSrfMnf: false,
        showSrfUse: false,
        uncheck: !this.uncheckedList.includes(bplcKndCd),
      };

      // 조건 설정
      if (mainCd === '01') {
        if (['01', '02'].includes(subCd)) {
          switch (subCd){
            case '01' :
              this.atchFileTaskSeCd = 'FCTA'
              break;
            case '02' :
              this.atchFileTaskSeCd = 'FCTC'
              break;
          }
          this.conds.showAllSrf = true;
          this.conds.showSrfMnf = subCd === '01';
          this.conds.showSrfUse = subCd === '02';
        }
      } else {
        this.atchFileTaskSeCd = 'FCTB';
        this.conds.showWes = true;

        if (mainCd === '03') this.conds.showFire = true;
        else if (mainCd === '04') this.conds.showBio = true;
      }
      // 관리되지 않는 폐자원에너지 추가 조건 (06~10)
      if (['06', '07', '08', '09', '10'].includes(mainCd)) {
        this.conds.uncheck = true;
      }
    },
    reset() {
      this.bizfaciltyFclt = {...initialBizfaciltyFclt};
      this.fcltInputItemList = [];
      this.conds = {...initialConds};
    },
    toggleCheckbox(index){
      this.fcltInputItemList[index].chk = this.fcltInputItemList[index].chk === 'Y' ? 'N' : 'Y';
    },
    handleSelectChange(inputItemCd, currentIndex) {
      const isDuplicate = this.fcltInputItemList.some(
          (item, index) => item.inputItemCd === inputItemCd && index !== currentIndex
      );
      if(isDuplicate){
        alert("이미 존재하는 투입품목입니다.")
        this.$nextTick(() => {
          // DOM 업데이트 이후 강제로 반영 -> 업데이트 하지않을 시 첫 이벤트만 동작하고, 이후 동작하지 않음.
          this.fcltInputItemList[currentIndex].inputItemCd = '';
        });
      }else{
        this.markAsChanged(currentIndex);
      }
    },
    markAsChanged(index){
      // 변경된 인덱스를 Set에 추가 (중복 방지)
      this.changedRows.add(index);
    },
    addRow() {
      const newRow = {
        fcltSqno: 0,
        chk: 'N',
        inputItemCd: '',
        inputItemCpstRt: '',
        dilyInpqty: '',
        useYn: 'Y',
      };
      this.fcltInputItemList.push(newRow);
    },
    removeRow() {
      // 체크된 행만 필터링
      const checkedRows = this.fcltInputItemList.filter(item => item.chk === 'Y');

      // 체크된 행이 없는 경우 알림 표시 및 함수 종료
      if (checkedRows.length === 0) {
        alert("선택된 항목이 없습니다.");
        return;
      }

      // 삭제 리스트와 화면에서 제거할 행을 분리
      const deleteList = checkedRows
          .filter(item => item.fcltSqno)
          .map(item =>
              ({
                bplcId: item.bplcId,
                fcltSqno: item.fcltSqno,
                inputItemCd: item.inputItemCd,
              })
          );
      const confirmMessage = `선택된 ${checkedRows.length}개의 투입품목을 정말 삭제하시겠습니까?`;

      if (confirm(confirmMessage)) {
        if(deleteList.length !== 0){
          this.$apiCall.delete(
              API.FCLT_INPUT_ITEM,
              {deleteList: deleteList},
              () => {
                //fcltSqno가 없는 항목은 리스트에서 제거
                this.fcltInputItemList = this.fcltInputItemList.filter(item => !(item.chk === 'Y' && !item.fcltSqno));

                alert("삭제되었습니다.")

                this.searchFcltInputItem();
              },
          );
        }
        //fcltSqno가 없는 항목은 리스트에서 제거
        this.fcltInputItemList = this.fcltInputItemList.filter(item => !(item.chk === 'Y' && !item.fcltSqno));
      }
    },
    filterInputItemList() {
      this.inputItemCdOption = [];

      switch(this.selectedBplcKndCd) {
        case '01-01':
          this.inputItemCdOption.push(...this.inputItemCdList
              .filter(item => item.frstRefVl === 'WT')
              .map(item => ({
                value: String(item.dtlCd),
                text: String(item.dtlCdNm)
              }))
          )
          break;
        case '01-02':
          this.inputItemCdOption.push(...this.inputItemCdList
              .filter(item => item.frstRefVl === 'SRF')
              .map(item => ({
                value: String(item.dtlCd),
                text: String(item.dtlCdNm)
              }))
          )
          break;
        case '04-01':
          this.inputItemCdOption.push(...this.inputItemCdList
              .filter(item => item.frstRefVl === 'BIOWT')
              .map(item => ({
                value: String(item.dtlCd),
                text: String(item.dtlCdNm)
              }))
          )
          break;
        default :
          this.inputItemCdOption.push(...this.inputItemCdList
              .map(item => ({
                value: String(item.dtlCd),
                text: String(item.dtlCdNm)
              }))
          )
          break;
      }
    },
    filterPrdctnPrdctList() {
      switch(this.selectedBplcKndCd) {
        case '01-01':
          this.prdctnPrdctKndCdOption = this.prdctnPrdctKndCdList.filter(item => item.frstRefVl === 'SRF')
              .map(item => ({
                value: item.dtlCd,
                text: item.dtlCdNm
              }));
          break;
        default :
          this.prdctnPrdctKndCdOption = [];
          break;
      }
    },
    validateFcltItem() {
      if(!['01-01', '01-02', '04-01'].includes(this.selectedBplcKndCd)){
        return true;
      }

      if(this.fcltInputItemList.length === 0){
        alert("시설 투입품목 입력은 필수 사항입니다.");
        return false;
      }

      let totalInputItemCpstRt = 0;

      for (const fcltInputItem of this.fcltInputItemList) {
        totalInputItemCpstRt += Number(fcltInputItem.inputItemCpstRt);
        if (!fcltInputItem.inputItemCd) {
          alert("투입품목이 비어 있는 항목이 있습니다. 확인 후 다시 신청해주세요.");
          return false;
        }
        if (['01-01', '01-02'].includes(this.selectedBplcKndCd) && (!fcltInputItem.inputItemCpstRt || Number(fcltInputItem.inputItemCpstRt) === 0)) {
          alert("투입비율이 비어있습니다. 확인 후 다시 신청해주세요.");
          return false;
        }
        if (['04-01'].includes(this.selectedBplcKndCd) && (!fcltInputItem.dilyInpqty || Number(fcltInputItem.dilyInpqty) === 0)) {
          alert("일간투입량이 비어있습니다. 확인 후 다시 신청해주세요.");
          return false;
        }
        if(Number(fcltInputItem.inputItemCpstRt) > 100){
          alert("투입비율은 100%를 넘길 수 없습니다. 확인 후 다시 신청해주세요.");
          return false;
        }
      }
      if(totalInputItemCpstRt > 100){
        alert("투입비율의 총 합은 100%를 넘길 수 없습니다. 확인 후 다시 신청해주세요.");
        return false;
      }
      if(['01-01', '01-02'].includes(this.selectedBplcKndCd) && totalInputItemCpstRt < 100){
        alert("투입비율의 총 합이 100%가 아닙니다. 확인 후 다시 신청해주세요.");
        return false;
      }
      return true;
    },
    async onChangeBplc(newItem){

      this.$apiCall.get(API.FCLT_CNT, {bplcId: this.selectedBplcId}, async data => {
        if (data) {
          if (['02-01', '04-01', '05-01'].includes(data.result.bplcKndCd) && data.result.fcltCnt > 0) {
            switch (data.result.bplcKndCd) {
              case '02-01' :
                alert("매립가스는 최대 하나의 시설만 등록가능합니다.")
                break;
              case '04-01' :
                alert("바이오가스는 최대 하나의 시설만 등록가능합니다.")
                break;
              case '05-01' :
                alert("합성가스는 최대 하나의 시설만 등록가능합니다.")
                break;
            }
            await this.reset();
            this.selectedBplcId = this.bplcId;
            await this.setConditions(this.bplcKndCd);
          } else {
            await this.reset();
            await this.setConditions(newItem.bplcKndCd);
            this.selectedBplcKndCd = newItem.bplcKndCd;
          }
          await this.$refs.attachFileList.load(this.atchFileTaskSeCd, '');
          await this.filterPrdctnPrdctList();
          await this.filterInputItemList();
        }
      })
    },
    onChagePrdctnPrdctKnd(){
      this.$apiCall.get(
            API.PRDCTN_PRDCT_KND,
      { bplcId: this.selectedBplcId },
  data => {
    if(data.result != null){
        for (let prdctnPrdctKndCdList of data.result){
          if(this.bizfaciltyFclt.prdctnPrdctKndCd === prdctnPrdctKndCdList.prdctnPrdctKndCd){
              alert("동일한 생산제품종류의 시설이 존재합니다. 다시 확인 후 선택해주세요.")
            this.bizfaciltyFclt.prdctnPrdctKndCd = '';
          }
        }
      }
    },
      () => {
        alert("요청에 실패하였습니다.");
      })
    },
  }
}
</script>

<style scoped>
.subtitle-note {
  font-size: 15px;      /* 폰트 크기 설정 */
  font-weight: normal;  /* 굵기 설정 (기본 또는 normal) */
  color: #888888;       /* 원하는 색상 코드로 변경 */
  margin-left: 10px;
}
.board_write > table th{
  padding: .8rem 1.0rem;
}
</style>