<template>
  <div class="tab_area">
    <ul class="tab">
      <li
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{active: activeTab === index}"
          @click="selectTab(index)"
      >
        <button type="button" class="btn_tab">
          {{ tab.name }}
          <i v-if="activeTab === index" class="sr_only">선택됨</i>
        </button>
      </li>
    </ul>
    <div class="tab_conts_wrap">
      <section class="tab_conts" v-show="activeTab === 0">
        <h3 class="title2">실적 현황</h3>
        <sb-grid
            id="prfmncGrid"
            :dataSource="prfmncDataSource"
            :columns="prfmncGridColumns"
            :gridattr="prfmncGridAttr"
            v-model:grid="prfmncGridObject"
            @loaded="prfmncGridLoaded"
            :refreshGrid="prfmncGridReload"
        />
      </section>

      <section class="tab_conts" v-show="activeTab === 1">
        <h3 class="title2">폐기물 지역별 반입 현황</h3>
        <sb-grid
            id="crynGrid"
            :dataSource="crynDataSource"
            :columns="crynGridColumns"
            :gridattr="crynGridAttr"
            v-model:grid="crynGridObject"
            @loaded="crynGridLoaded"
            :refreshGrid="crynGridReload"
        />
      </section>

      <section class="tab_conts" v-show="activeTab === 2">
        <h3 class="title2">사업장별 에너지 판매 현황</h3>
        <sb-grid
            id="ntslDtlGrid"
            :dataSource="ntslDtlDataSource"
            :columns="ntslDtlGridColumns"
            :gridattr="ntslDtlGridAttr"
            v-model:grid="ntslDtlGridObject"
            @loaded="ntslDtlGridLoaded"
            :refreshGrid="ntslDtlGridReload"
        />
      </section>
    </div>
  </div>
</template>

<script>
import SbGrid from "@/components/SbGrid.vue";
import { storeSwitch } from "@/js/store/store-switch";

const API = {
  PERFORMANCE_STATUS: "/prfmnc/bfrBioGas/prfmnc-stts",
  CARRY_IN_STATUS: "/prfmnc/bfrBioGas/cryn-stts",
  NET_SALES_DETAILS_STATUS: "/prfmnc/bfrBioGas/ntsl-dtl-stts",
};

export default {
  components: {SbGrid},
  props: ['param'],
  data() {
    return {
      tabs: [
        { name: '실적현황' },
        { name: '반입상세현황' },
        { name: '판매상세현황' },
      ],
      activeTab: 0,
      prfmncGridReload: false,
      prfmncGridObject: null,
      prfmncDataSource: [],
      prfmncGridColumns: [],
      prfmncGridAttr: {
        showRowNo: false,
        pageable: false,
        excelExport: {
          fileName: '실적 현황.xlsx',
          includeHeader: true,
        },
        height: 515,
      },
      crynGridReload: false,
      crynGridObject: null,
      crynDataSource: [],
      crynGridColumns: [],
      crynGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        excelExport: {
          fileName: '폐기물 지역별 반입 현황.xlsx',
          includeHeader: true,
          keepMerge: true,
        },
      },
      ntslDtlGridReload: false,
      ntslDtlGridObject: null,
      ntslDtlDataSource: [],
      ntslDtlGridColumns: [],
      ntslDtlGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        excelExport: {
          fileName: '업체별 에너지 판매 현황.xlsx',
          includeHeader: true,
          keepMerge: true,
        }
      },
      prfmncInfo: {},
      crynInfo: {},
      ntslDtlInfo: {},
    };
  },
  computed: {},
  watch: {
    activeTab() {
      this.search();
    }
  },
  created() {
    this.initialize();
  },
  mounted() {
  },
  methods: {
    async initialize() {
      storeSwitch.on("loading");
      await this.loadPrfmncInfo();
      this.initializePrfmncInfo();
      await this.loadCrynInfo();
      this.initializeCrynInfo();
      await this.loadNtslDtlInfo();
      this.initializeNtslDtl();
      storeSwitch.off("loading");
    },
    loadPrfmncInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.PERFORMANCE_STATUS,
            { bplcId: this.param.bplcId, prfmncYr: this.param.prfmncYr },
            data => {
              if (data.result) {
                this.prfmncInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load prfmnc info."));
            }
        );
      });
    },
    loadCrynInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.CARRY_IN_STATUS,
            { bplcId: this.param.bplcId, prfmncYr: this.param.prfmncYr },
            data => {
              if (data.result) {
                this.crynInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load cryn info."));
            }
        );
      });
    },
    loadNtslDtlInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.NET_SALES_DETAILS_STATUS,
            { bplcId: this.param.bplcId, prfmncYr: this.param.prfmncYr },
            data => {
              if (data.result) {
                this.ntslDtlInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load ntsl dtl info."));
            }
        );
      });
    },
    initializePrfmncInfo() {
      this.prfmncDataSource = this.prfmncInfo.list;
      this.initializePrfmncGridColumns();
      this.initializePrfmncGridAttr();
      this.prfmncGridReload = true;
    },
    initializeCrynInfo() {
      const crynDataCopy = this.crynInfo.list.map(item => ({ ...item }));

      // `0020`, `0021`, `0022`로 시작하는 값들의 최대값 계산
      const keysToCheck = ['0020', '0021', '0022'];

      const prfmncYmMaxValues = crynDataCopy.reduce((acc, item) => {
        // ctpvStdgCd 값이 없는 데이터는 제외
        if (!item.ctpvStdgCd) {
          return acc;
        }

        // `prfmncYm`을 기준으로 그룹화
        if (!acc[item.prfmncYm]) {
          acc[item.prfmncYm] = {};
        }

        // `keysToCheck`에 해당하는 키들의 최대값 계산
        keysToCheck.forEach(key => {
          const currentValue = item[key] || 0; // 해당 키의 값이 없으면 0으로 처리
          acc[item.prfmncYm][key] = Math.max(acc[item.prfmncYm][key] || 0, currentValue);
        });

        return acc;
      }, {});

      const updatedCrynDataCopy = [];
      const seenPrfmncYm = new Set();

      crynDataCopy.forEach(item => {
        if (!item.ctpvStdgCd) {
          updatedCrynDataCopy.push(item); // ctpvStdgCd 없는 데이터는 그대로 유지
          return;
        }

        if (!seenPrfmncYm.has(item.prfmncYm)) {
          // 첫 번째 데이터에는 최대값 설정
          keysToCheck.forEach(key => {
            item[key] = prfmncYmMaxValues[item.prfmncYm][key] || 0;
          });
          seenPrfmncYm.add(item.prfmncYm);
        } else {
          // 동일한 prfmncYm의 나머지 데이터는 0으로 설정
          keysToCheck.forEach(key => {
            item[key] = 0;
          });
        }

        updatedCrynDataCopy.push(item);
      });

      this.crynDataSource = updatedCrynDataCopy;
      this.initializeCrynGridColumns();
      this.initializeCrynGridAttr();
      this.crynGridReload = true;
    },
    initializeNtslDtl() {
      this.ntslDtlDataSource = this.ntslDtlInfo.list;
      this.initializeNtslDtlGridColumns();
      this.initializeNtslDtlGridAttr();
      this.ntslDtlGridReload = true;
    },
    initializePrfmncGridColumns() {
      this.prfmncGridColumns = [
        { field: 'prfmncId', caption: '실적아이디', visible: false },
        { field: 'prfmncYm', caption: '실적연월', type: 'date', width: 100, part: 'head',
          total: { headerCss:'grid-header' },
          format: (date) => {
            if (date && date.length === 6) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              return `${yyyy}-${mm}`;
            }
            return date;
          },
        },
        { field: 'prfmncSttsNm', caption: '실적상태', type: 'date', width: 100,
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          },
        },
        { caption: '폐기물 반입현황' },
        { caption: '보조연료',
          columns: [
            { field: 'oilAsstFuelUsqty', caption: '유류', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'oilAsstFuelUsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'lngAsstFuelUsqty', caption: 'LNG', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'lngAsstFuelUsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'etcAsstFuelUsqty', caption: '기타', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'etcAsstFuelUsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
          ]
        },
        { caption: '전기',
          columns: [
            { field: 'rynPrvtmAsstFuelUsqty', caption: '외부반입(한전)', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'rynPrvtmAsstFuelUsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'prdctnPrvtmAsstFuelUsqty', caption: '자체사용', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'prdctnPrvtmAsstFuelUsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
          ]
        },
        { field: 'totEnePrdqty', caption: '총 에너지 생산량<br>(A+B)', width: 140, align: 'right',
          dataType:'number', format: '#,##0.00',
          total: {
            aggregates: [{func: 'sum', require: 'totEnePrdqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { caption: '에너지 이용',
          columns: [
            { field: 'eneUtzqty', caption: '총 이용량<br>(A)', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'eneUtzqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'elctgnUsgGasUsqty', caption: '발전', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'elctgnUsgGasUsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { caption: '가스',
              columns: [
                { field: 'gasOtsdSupqty', caption: '외부공급', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'gasOtsdSupqty', nullAs: 0}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'gasSlfUsqty', caption: '자체사용', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'gasSlfUsqty', nullAs: 0}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
              ]
            },
            { field: 'stUsgGasUsqty', caption: '스팀<br>생산용', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'stUsgGasUsqty', nullAs: 0}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'etcUsgGasUsqty', caption: '기타', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'etcUsgGasUsqty', nullAs: 0}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
          ]
        },
        { field: 'eneUnuqty', caption: '미이용<br>(단순처리)<br>(B)', width: 140, align: 'right',
          dataType:'number', format: '#,##0.00',
          total: {
            aggregates: [{func: 'sum', require: 'eneUnuqty', nullAs: 0}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { caption: '에너지 판매',
          columns: [
            { field: 'totNtslSumAmt', caption: '총판매금액', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'totNtslSumAmt'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { caption: '전기',
              columns: [
                { field: 'prvtmSumNsqty', caption: '판매량', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'prvtmSumNsqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'prvtmNtslSumAmt', caption: '판매금액', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'prvtmNtslSumAmt'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
              ]
            },
            { caption: '가스',
              columns: [
                { field: 'gasSumNsqty', caption: '판매량', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'gasSumNsqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'gasNtslSumAmt', caption: '판매금액', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'gasNtslSumAmt'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
              ]
            },
            { caption: '스팀',
              columns: [
                { field: 'stSumNsqty', caption: '판매량', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'stSumNsqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'stNtslSumAmt', caption: '판매금액', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'stNtslSumAmt'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
              ]
            },
            { field: 'etcEneSumNsqty', caption: '기타', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'etcEneSumNsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
          ]
        },
      ];

      if (this.prfmncInfo.wtItemList && this.prfmncInfo.wtItemList.length > 0) {
        this.prfmncGridColumns[3].columns = [];

        this.prfmncInfo.wtItemList.forEach(item => {
          // 하수슬러지 제외
          if (item.wtItemCd !== '0019') {
            this.prfmncGridColumns[3].columns.push({
              field: item.wtItemCd,
              caption: item.wtItemNm,
              width: 140,
              total: {
                aggregates: [{func: 'sum', require: item.wtItemCd}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
              align: 'right',
              format: '#,##0.00'
            });
          }
        });
      }else{
        this.prfmncGridColumns[3] = {...this.prfmncGridColumns[3], total: { headerCss:'grid-header' } }
      }
    },
    initializePrfmncGridAttr() {
    },
    initializeCrynGridColumns() {
      this.crynGridColumns = [
        { field: 'prfmncId', caption: '실적아이디', visible: false },
        { field: 'prfmncYm', caption: '실적연월', type: 'date', width: 100, mergeRow: true, part: 'head',
          total: { headerCss:'grid-header' },
          format: (date) => {
            if (date && date.length === 6) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              return `${yyyy}-${mm}`;
            }
            return date;
          },
        },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false },
        { field: 'ctpvStdgNm', caption: '시·도', width: 110,
          mergeRow: (data) => data.prfmncYm + '.' + data.ctpvStdgCd,
          total: { headerCss:'grid-header' },
        },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false },
        { field: 'sggStdgNm', caption: '시·군·구', width: 110,
          mergeRow: (data) => data.prfmncYm + '.' + data.ctpvStdgCd + '.' + data.sggStdgCd,
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          }
        },
      ];

      if (this.crynInfo.wtItemList && this.crynInfo.wtItemList.length > 0) {
        const additionalColumns = [];

        this.crynInfo.wtItemList.forEach(item => {
          // 하수슬러지 제외
          if (item.wtItemCd !== '0019') {
            additionalColumns.push({
              field: item.wtItemCd,
              caption: item.wtItemNm,
              width: 140,
              total: {
                aggregates: [
                  { func: (items) => items
                        .filter(entry => entry.sggStdgCd !== "0000000000")
                        .reduce((sum, entry) => sum + (entry[item.wtItemCd] || 0), 0),
                    field: `sum${item.wtItemCd}`
                  },
                ],
                header: [{ template: `{{sum${item.wtItemCd}}}`, format: '#,##0.00' }],
                headerCss:'grid-header',
              },
              align: 'right',
              format: '#,##0.00',
              // mergeRow 조건 추가 (정규식 사용)
              ...( /^002(0|1|2)/.test(item.wtItemCd) ? {
                mergeRow: (data) => {
                  const ctpvStdgCdValue = data.ctpvStdgCd ? 'SAME' : '';
                  return data.prfmncYm  + '.' + ctpvStdgCdValue;
                }
              } : {})
            });
          }
        });

        // 마지막에 추가
        this.crynGridColumns.push(...additionalColumns);
      }
    },
    initializeCrynGridAttr() {
    },
    initializeNtslDtlGridColumns() {
      this.ntslDtlGridColumns = [
        { field: 'prfmncId', caption: '실적아이디', visible: false },
        { field: 'prfmncYm', caption: '실적연월', type: 'date', width: 100, mergeRow: true, part: 'head',
          total: { headerCss:'grid-header' },
          format: (date) => {
            if (date && date.length === 6) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              return `${yyyy}-${mm}`;
            }
            return date;
          },
        },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false },
        { field: 'ctpvStdgNm', caption: '시·도', width: 110,
          mergeRow: (data) => data.prfmncYm + '.' + data.ctpvStdgCd,
          total: { headerCss:'grid-header' },
        },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false },
        { field: 'sggStdgNm', caption: '시·군·구', width: 110,
          mergeRow: (data) => data.prfmncYm + '.' + data.ctpvStdgCd + "." + data.sggStdgCd,
          total: { headerCss:'grid-header' },
        },
        { field: 'bzentyNm', caption: '업체명', width: 200, align: 'left',
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          },
        },
        { caption: '에너지 판매',
          columns: [
            { field: 'ntslSumAmt', caption: '판매급액<br>합계', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'ntslSumAmt'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { caption: '전기(MWh,원)',
              columns: [
                { field: 'prvtmNsqty', caption: '판매량', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'prvtmNsqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'prvtmNtslAmt', caption: '판매금액', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'prvtmNtslAmt'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
              ]
            },
            { caption: '가스(Nm³,원)',
              columns: [
                { field: 'gasNsqty', caption: '판매량', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'gasNsqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'gasNtslAmt', caption: '판매금액', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'gasNtslAmt'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
              ]
            },
            { caption: '스팀(스팀톤,원)',
              columns: [
                { field: 'stNsqty', caption: '판매량', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'stNsqty'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'stNtslAmt', caption: '판매금액', width: 140, align: 'right',
                  dataType:'number', format: '#,##0.00',
                  total: {
                    aggregates: [{func: 'sum', require: 'stNtslAmt'}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
              ]
            },
            { field: 'etcEneNsqty', caption: '기타', width: 140, align: 'right',
              dataType:'number', format: '#,##0.00',
              total: {
                aggregates: [{func: 'sum', require: 'etcEneNsqty'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
          ]
        },
      ]
    },
    initializeNtslDtlGridAttr() {
    },
    prfmncGridLoaded() {
      this.prfmncGridReload = false;
    },
    crynGridLoaded() {
      this.crynGridReload = false;
    },
    ntslDtlGridLoaded() {
      this.ntslDtlGridReload = false;
    },
    selectTab(index) {
      this.activeTab = index;
    },
    async prfmncSearch() {
      await this.loadPrfmncInfo();
      this.initializePrfmncInfo();
    },
    async crynSearch() {
      await this.loadCrynInfo();
      this.initializeCrynInfo();
    },
    async ntslDtlSearch() {
      await this.loadNtslDtlInfo();
      window.SBGrid3.setClientData(this.ntslDtlGridObject, this.ntslDtlInfo.list || []);
    },
    async search() {
      storeSwitch.on("loading");
      switch (this.activeTab) {
        case 0: // 실적현황
          await this.prfmncSearch();
          break;
        case 1: // 반입상세현황
          await this.crynSearch();
          break;
        case 2: // 판매상세현황
            await this.ntslDtlSearch();
          break;
      }
      storeSwitch.off("loading");
    }
  }
}
</script>

<style scoped>

</style>