<template>
  <div class="cont_box">
    <div class="title_btn_area">
      <h3 class="title">상세 정보</h3>
    </div>
    <div class="board_write">
      <table>
        <tbody>
        <tr>
          <th scope="row">제목</th>
          <td colspan="3">
            {{param.qstnTtl}}
          </td>
        </tr>
        <tr>
          <th scope="row">등록자</th>
          <td colspan="1.5">
            {{param.rgtrId}}
          </td>
          <th scope="row">등록일시</th>
          <td colspan="1.5">
            {{param.regDt}}
          </td>
        </tr>
        <tr>
          <th scope="row">첨부파일</th>
          <td colspan="3">
            <br-attach-file label="첨부파일"
                v-model:atchFileGroupId="param.atchFileGroupId"
                v-model:fileProcessingData="param.fileProcessingData"
                :files="param.files"
                :readonly="true"
            />
          </td>
        </tr>
        <tr>
          <th scope="row">질문내용</th>
          <td colspan="3">
            {{param.bbsPstQstnCn}}
          </td>
        </tr>
        <tr>
          <th scope="row">답변내용</th>
          <td colspan="3">
            {{param.bbsPstQstnAnsCn}}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="btn_area right">
      <br-button label="목록" format="tertiary line" panel="panel" @click="navigate()"/>
    </div>
  </div>
</template>

<script>

const API = {
  FAQ: "/faq",
};

export default {
  data() {
    return {
      bbsSn: "",
      param: {
        qstnTtl: "",
        bbsPstQstnCn: "",
        bbsPstQstnAnsCn: "",
        regDt: "",
        rgtrId: "",
        atchFileGroupId: "",
        fileProcessingData: {},
        files: []
      },
    }
  },
  mounted() {
    this.pstSn = this.$route.query.pstSn;
    this.bbsSn = this.$route.query.bbsSn;
    this.read()
  },
  methods : {
    read() {
      this.$apiCall.get(API.FAQ+"/detail", {pstSn: this.pstSn}, this.success, this.error);
    },
    async success(data) {
      if (!data.result) {
        this.error();
        return;
      }

      const json = JSON.parse(JSON.stringify(data.result));
      this.param = json;

      this.param.files = []
      if (data.result.files) {
        this.param.files = data.result.files;
      }

    },
    error() {
      alert("데이터를 가져오는 중 오류가 발생했습니다.");
    },
    navigate() {
      this.$router.push({path : `/cust-sprt/FaqManage`})
        .catch(() => {
            console.error("Navigation Error")
          }
        );
    },
  },
}
</script>

<style scoped>

</style>
