<template>
  <div class="sch_form_wrap">
    <div class="sch_form row2">
      <span>
        <label>구분</label>
        <br-form-select label="선택"
            v-model:value="selected" :options="options" @change="(data) => this.type=data.value"/>
        <br-form-input label="검색어" v-model="keyword" @enterKeyup="search"/>
      </span>
      <span>
        <label>수신일시</label>
        <br-date-range label="수신일시"
                       v-model:value="selectedDate"/>
      </span>
      <span>
        <label>수신종류</label>
        <br-form-select label="선택" :options="sndngTypeOptions"
                        v-model:value="searchParam.rcptnType" includeSelectOption />
      </span>
      <span></span>
    </div>
    <div class="btn_area" style="padding-right: 50px;">
      <br-anchor-button label="검색" role="button" size="sm" format="sch" @click="search" />
      <br-anchor-button label="새로고침" role="button" size="sm" format="txtN" icon="ico_refresh" @click="reset" />
    </div>
  </div>
  <div class="board_info">
    <p class="page">
      <span class="total">전체 <strong>{{ this.count }}</strong></span>
      <span>페이지 <strong>{{ this.searchParam.page }}/{{ this.maxPage }}</strong></span>
    </p>
    <div class="btn_area">
      <a href="javascript:void(0);" role="button" class="btn sm ico_excel" @click.prevent="excelDownload()">엑셀다운로드</a>
    </div>
  </div>
  <div class="board_list scroll">
    <table style="table-layout: fixed">
      <caption>알림수신내역</caption>
      <colgroup>
        <col style="width: 120px">
        <col style="width: 180px">
        <col style="width: 80px">
        <col style="width: 300px">
        <col style="width: 300px">
      </colgroup>
      <thead>
        <tr>
          <th scope="col">알림수신일시</th>
          <th scope="col">발송주소</th>
          <th scope="col">수신종류</th>
          <th scope="col">알림 제목</th>
          <th scope="col">알림 내용</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="listObj.length <= 0" >
          <td colspan="12">
            <div style="display: flex; justify-content: center;">
              <div class="no-data-message">조회조건에 해당하는 데이터가 없습니다.</div>
            </div>
          </td>
        </tr>
        <tr v-else v-for="(item, index) in listObj" :key="index" @dblclick="toDetail(item)">
          <td aria-label="알림수신일시">{{ item.regDt }}</td>
          <td aria-label="발송주소" style="text-align: left;">{{ item.sndngAddr }}</td>
          <td aria-label="수신종류" style="text-align: center">{{ item.rcptnType }}</td>
          <td aria-label="알림제목" class="cn1" style="text-align: left;">{{ item.ttl }}</td>
          <td aria-label="알림내용" class="cn1" style="text-align: left">{{ item.cn }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <insp-pagination :total="count" :size="searchParam.size" v-model:page="searchParam.page" v-model:maxPage="maxPage" @clickedPage="search"> </insp-pagination>
</template>

<script>
import inspPagination from "@/views/sfli/components/inspPagination.vue";
import {storeSession} from "@/js/store/store-session";
import BrDateRange from "@/components/BrDateRange.vue";

const API = {
  NTC: "/notice",
};

const PAGE_SIZE = 10;

export default {
  components: {BrDateRange, inspPagination},
  data() {
    return {
      options: [
        {text: "제목", value: "1"},
        {text: "내용", value: "2"},
        {text: "발송주소", value: "3"},
      ],
      sndngTypeOptions: [
        {text: "이메일", value: "이메일"},
        {text: "메시지", value: "메시지"},
        {text: "알림톡", value: "알림톡"},
      ],
      selected: "1",
      keyword: "",
      type: "1",
      sndngType: "",
      selectedDate:[],
      searchParam: {
        ttl: "",
        cn: "",
        sndngAddr: "",
        userId: "",
        rgtrNm: "",
        startDate: "",
        endDate: "",
        page: 1,
        rcptnType: "",
      },
      listObj: [],
      userinfo: storeSession.getMemberInfo(),
      count: 0,
      maxPage: 1,
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  beforeMount() {
  },
  mounted() {
    window.scrollTo(0, 0);
    this.searchParam.userId = this.userinfo.userId;
    this.search();
  },
  methods: {
    search(){
      this.searchParam.size = PAGE_SIZE;
      this.change();
      this.$apiCall.get(API.NTC, this.searchParam, this.success, this.error);
    },
    success(data){
      if(!data.result){
        this.error();
        return;
      }
      this.count = data.result.total;
      this.listObj = data.result.list;
    },
    error() {
      alert("데이터를 가져오는 중 오류가 발생했습니다.");
    },
    reset() {
      this.selectedDate = []
      this.searchParam.startDate = '';
      this.searchParam.endDate = '';
      this.keyword = '';
      this.searchParam.rcptnType = '';
      this.search();
    },
    change(){
      this.searchParam.ttl = ""
      this.searchParam.cn = ""
      this.searchParam.sndngAddr = ""
      if(this.type==""){
        this.keyword = ""
      }else if(this.type==1){
        this.searchParam.ttl = this.keyword
      }else if(this.type==2){
        this.searchParam.cn = this.keyword
      }else if(this.type==3){
        this.searchParam.sndngAddr = this.keyword
      }

      this.searchParam.startDate = this.selectedDate[0];
      this.searchParam.endDate = this.selectedDate[1];

    },
    excelDownload() {
      this.searchParam.userId = this.userinfo.userId
      this.searchParam.fileName = "알람수신내역.xlsx"
      this.$apiCall.download(API.NTC + "/excel", this.searchParam);
    },
    toDetail(data){
      this.$router.push({path : `/my-office/NtcRcptnDetail`, query : {sn: data.sn}})
        .catch(() => {
          console.error("Navigation Error");
        });
    }
  }
}
</script>

<style scoped>
.cn1{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
