<template>
  <h2 class="title1 mt_0">관련 법률 안내</h2>
  <p class="law_txt">한국환경공단은 <strong>『고형연료제품 제조/사용/수입 신고』  『권리의무승계신고』</strong>에 근거하여 <span>폐자원에너지 종합정보관리시스템을 운영합니다.</span></p>
  <div class="law_box">
    <ul>
      <li><span><strong>(고형연료제품 제조/사용/수입 신고)</strong> 「자원의 절약과 재활용촉진에 관한 법률」 제25조의4</span> <a href="https://www.law.go.kr/LSW//lsInfoP.do?lsId=000155&ancYnChk=0#0000" target="_blank" title="새창열림" class="btn sm">관련법률 상세보기</a></li>
      <li><span><strong>(고형연료제품 제조/사용/수입 신고)</strong> 「자원의 절약과 재활용촉진에 관한 법률」 시행규칙 제19조</span> <a href="https://www.law.go.kr/LSW//lsInfoP.do?lsId=007953&ancYnChk=0#0000" target="_blank" title="새창열림" class="btn sm">관련법률 상세보기</a></li>
      <li><span><strong>(권리의무승계신고)</strong> 「자원의 절약과 재활용촉진에 관한 법률」제25조의13</span> <a href="https://www.law.go.kr/LSW//lsInfoP.do?lsId=000155&ancYnChk=0#0000" target="_blank" title="새창열림" class="btn sm">관련법률 상세보기</a></li>
      <li><span><strong>(권리의무승계신고)</strong> 「자원의 절약과 재활용촉진에 관한 법률」시행규칙 제20조의12</span> <a href="https://www.law.go.kr/LSW//lsInfoP.do?lsId=000155&ancYnChk=0#0000" target="_blank" title="새창열림" class="btn sm">관련법률 상세보기</a></li>
    </ul>
  </div>

  <h2 class="title1">고형연료제품 제조신고 안내</h2>
  <p>고형연료제품 제조시설검사 및 품질검사 적합 판정을 받은 후 사업장 소재지를 관할하는 특별자치시, 시.군.구, 특별자치도에 신고하여야 합니다.</p>
  <div class="img_box_area">
    <img src="@/assets/images/sub/report_information_1.png" alt="1.시설 및 품질검사 완료 2.폐자원에너지 종합정보관리시스템 3.신고/제조 신고 메뉴로 이동 후 신고서 작성 및 출력 4.해당 지자체 접수">
    <div class="img_box mt_25">접수가 완료되면 7일 이내에 제조신고 확인증을 받으신 후 폐자원에너지 종합정보관리시스템에 등록하시면 됩니다.</div>
  </div>
  <h3 class="title2">고형연료제품 제조신고 관련서류</h3>
  <ul class="blue_dot_list">
    <li>「폐기물관리법」 제25조제5항제6호 또는 제7호에 따른 폐기물 최종재활용업 또는 폐기물 종합재활용업의 허가증 사본</li>
    <li>「자원의 절약과 재활용촉진에 관한 법률」 제25조의5제1항에 따른 고형연료제품의 품질검사 결과서 사본<br>
      (제조신고서 제출일 기준 3개월 이내에 발급된 것으로 한정합니다)</li>
    <li>「자원의 절약과 재활용촉진에 관한 법률」 제25조의8제1항에 따른 고형연료제품 제조시설의 정기검사 결과서 사본</li>
    <li>다음 각 목의 사항이 포함된 고형연료제품의 제조·보관·공급·처리 계획서
      <ul class="dot_list">
        <li>고형연료제품의 연간 예상 제조량 및 공급량(공급업체)</li>
        <li>「자원의 절약과 재활용촉진에 관한 법률 시행규칙」 별표 7에 따른 품질기준에 적합하지 않은 고형연료제품의 처리계획</li>
        <li>고형연료제품의 제조·보관·공급·처리 과정에서의 환경오염 방지대책</li>
      </ul>
    </li>
  </ul>

  <h2 class="title1">고형연료제품 사용허가 제도 안내</h2>
  <p>고형연료제품 사용시설검사를 받기 전 사업장 소재지를 관할하는 특별자치시, 시.군.구, 특별자치도에 허가받아야 합니다.</p>
  <div class="img_box_area">
    <img src="@/assets/images/sub/report_information_2.png" alt="1.시설 및 품질검사 완료 2.폐자원에너지 종합정보관리시스템에 접속 3.신고/사용 신고 메뉴로 이동 후 신고서 작성 및 출력 4.해당 지자체 접수">
    <div class="img_box mt_25">접수가 완료되면 7일 이내에 신고 확인증을 받으신 후 폐자원에너지 종합정보관리시스템에 등록하시면 됩니다.</div>
  </div>
  <h3 class="title2">고형연료제품 사용신고 관련서류</h3>
  <ul class="blue_dot_list">
    <li>「자원의 절약과 재활용촉진에 관한 법률」 제25조의8제1항에 따른 고형연료제품 사용시설의 정기검사 결과서 사본<br>
      (같은 법 제25조의7 제1항제1호 또는 제2호에 따른 신고의 경우만 해당합니다)</li>
    <li>사용하려는 고형연료제품에 대한 「자원의 절약과 재활용촉진에 관한 법률 시행규칙」 제20조의6 제1항에 따른 품질명세서 사본</li>
    <li>별표 8의2에 따른 사항이 포함된 고형연료제품 사용시설 운영계획서
      <ul class="dot_list">
        <li>고형연료제품 사용시설의 구분</li>
        <li>고형연료제품 사용시설의 개요
          <ul class="hangul_list">
            <li>사업자명, 대표자명, 고형연료제품 사용시설 설치예정지 주소</li>
            <li>고형연료제품 사용시작 예정일 및 운영기간</li>
            <li>고형연료제품 사용시설 용량 및 설치 부지면적</li>
            <li>일일 가동 시간 및 연간 가동 일수</li>
          </ul>
        </li>
        <li>고형연료제품의 사용·보관·처리계획
          <ul class="hangul_list">
            <li>고형연료제품의 종류, 공급 업체명 및 업체별 공급 계획량, 공급 계약기간</li>
            <li>별표 7에 따른 품질기준에 적합하지 않은 고형연료제품의 처리계획</li>
            <li>고형연료제품의 사용·보관·처리 과정에서의 환경오염 방지대책</li>
          </ul>
        </li>
        <li>에너지 이용계획: 사용시작 예정일부터 5년간의 연도별·용도별 고형연료제품 사용계획</li>
        <li>방지시설 설치계획
          <ul class="hangul_list">
            <li>대기배출시설 및 방지시설 설치내역서, 일반도 및 연간 유지관리계획서</li>
            <li>폐수배출시설 및 방지시설 설치내역서, 일반도 및 연간 유지관리계획서</li>
          </ul>
        </li>
        <li>주변 환경보호 및 주민생활 관련 계획
          <ul class="hangul_list">
            <li>고형연료제품 사용시설 주변지역 환경보호 계획</li>
            <li>주민설명회 개최 계획 또는 주민지원 계획</li>
            <li>대기오염물질 배출량, 고형연료제품 품질검사 결과, 고형연료제품 사용시설 정기검사 결과 등 시설 운영 관련 정보공개 계획</li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>

  <h2 class="title1">고형연료제품 수입신고 안내</h2>
  <p>고형연료제품 수입품질검사 적합 판정을 받은 후 사업장 소재지를 관할하는 지방환경관서에 신고하여야 합니다.</p>
  <div class="img_box_area">
    <img src="@/assets/images/sub/report_information_3.png" alt="1.품질검사 완료 2.폐자원에너지 종합정보관리시스템에 접속 3.신고/사용 신고 메뉴로 이동 후 신고서 작성 및 출력 4.해당 환경 관서 접수">
    <div class="img_box mt_25">접수가 완료되면 7일 이내에 신고 확인증을 받으신 후 폐자원에너지 종합정보관리시스템에 등록하시면 됩니다.</div>
  </div>
  <h3 class="title2">고형연료제품 수입신고  관련서류</h3>
  <ul class="blue_dot_list">
    <li>「자원의 절약과 재활용촉진에 관한 법률」 제25조의5제1항에 따른 고형연료제품의 품질검사 결과서 사본<br>
      (수입신고서 제출일 기준 1개월 이내에 발급된 것으로 한정합니다)</li>
    <li>고형연료제품의 수입가격이 선적가격(C.I.F)으로 명시된 수입계약서 또는 주문서 사본</li>
    <li>다음 각 목의 사항이 포함된 고형연료제품의 수입·운반·보관·공급·처리 계획서
      <ul class="dot_list">
        <li>고형연료제품의 연간 예상 수입량(제조국·제조업체) 및 공급량(공급업체)</li>
        <li>고형연료제품의 국내 반입을 위한 운송수단 및 국내 반입 후 실시할 전수방역 계획</li>
        <li>「자원의 절약과 재활용촉진에 관한 법률 시행규칙」 별표 7에 따른 품질기준에 적합하지 않은 고형연료제품의 처리계획</li>
        <li>고형연료제품의 수입·운반·보관·공급·처리 과정에서의 환경오염 방지대책</li>
      </ul>
    </li>
  </ul>

  <h2 class="title1">권리의무승계 신고 안내</h2>
  <p>회사의 합병, 양도양수 또는 사망 등의 권리의무승계 사유가 발생했을 경우 그 사유가 발생한 날부터 30일 이내에 특별자치시장·특별자치도지사·시장·군수·구청장에게 권리의무승계 신고를 하여야 합니다.</p>
  <div class="img_box_area">
    <img src="@/assets/images/sub/report_information_4.png" alt="1.품질검사 완료 2.폐자원에너지 종합정보관리시스템에 접속 3.신고/사용 신고 메뉴로 이동 후 신고서 작성 및 출력 4.해당 환경 관서 접수">
    <div class="img_box mt_25">권리의무 승계 사유가 발생한 날부터 30일 이내</div>
  </div>
  <h3 class="title2">권리의무승계 신고 관련서류</h3>
  <ul class="blue_dot_list">
    <li>고형연료제품의 제조 또는 사용 신고확인증 원본</li>
    <li>권리·의무의 승계사실을 증명하는 서류</li>
    <li>양도나 합병의 경우에는 양도·양수 계약서 또는 합병계약서 등 양도사실이나 합병사실을 확인할 수 있는 서류를, 사망의 경우에는
      가족관계증명서 등 상속사실을 확인할 수 있는 서류를 제출합니다.</li>
  </ul>

  <div class="info_box mt_40">
    <strong class="info_title">문의 안내</strong>
    <ul class="dot_list">
      <li>주소 : 인천광역시 서구 환경로 42(경서동 종합환경연구단지) 한국환경공단 폐자원에너지센터 폐자원에너지검사부</li>
      <li>전화 : 032-590-5427</li>
      <li>메일 : srf-fa@keco.or.kr</li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {}
}
</script>

<style scoped>

</style>