<template>
  <div v-show="isVisible" class="modal visible">
    <div class="popup_box full" tabindex="0">
      <h1 class="popup_title">반입업체 조회<!--(폐기물수집업체)--></h1>
      <div class="popup_cont">
        <div class="sch_form_wrap">
          <div class="sch_form row2">
            <span>
              <label>시·도</label>
              <br-form-select label="시·도" v-model:value="searchParam.ctpvStdgCd" :options="ctpvCdList" allSelectable @change="search" />
            </span>
            <span>
              <label>시·군·구</label>
              <br-form-select label="시·군·구" v-model:value="searchParam.sggStdgCd" :options="filteredSggCdList" allSelectable @change="search" />
            </span>
            <span style="flex: 0 0 87%;">
              <label>업체명</label>
              <br-form-input label="업체명" v-model="searchParam.wtClctBzentyNmList" @enter-keyup="search"
                             placeholder="업체명을 입력하세요. 여러 개 검색 시 쉼표로 구분하세요.(예: 업체명1,업체명2,업체명3)"
              />
            </span>
            <span style="flex: 0 0 87%;">
              <label>사업자번호</label>
              <br-form-input label="사업자번호" v-model="searchParam.brnoList" @enter-keyup="search"
                             placeholder="사업자등록번호를 입력하세요.(여러 개는 쉼표로 구분)"
              />
            </span>
            <span>
              <label>등록여부</label>
              <br-form-radio label="등록여부" v-model:value="searchParam.regYn" :options="regYnOptions" allSelectable @selected="search"/>
            </span>
          </div>
          <div class="btn_area">
            <br-anchor-button label="검색" size="sm" format="sch" @click="search" />
            <br-anchor-button label="새로고침" size="sm" format="txtN" icon="ico_refresh" @click="reset" />
          </div>
        </div>
        <div class="txt_box mb_10">
          <ul class="dot_list">
            <li>업체의 정보가 조회되지 않는 경우, 행추가 버튼을 클릭하여 신규 업체를 등록하시기 바랍니다.</li>
            <li>업체의 정보가 일부 다른 경우, 행복사 버튼을 클릭하여 업체정보를 복사 후 수정하여 신규 업체로 등록하시기 바랍니다.</li>
          </ul>
        </div>
        <div class="grid_area">
          <div class="board_info">
            <h3 class="title2">폐기물수집업체 현황</h3>
            <div class="btn_area">
              <br-button label="행 추가" size="sm" @click="addBzenty" />
              <br-button label="행 복사" size="sm" @click="copyBzenty" />
              <br-button label="행 삭제" size="sm point" @click="deleteBzenty" />
              <br-button label="저장" size="sm" format="secondary" @click="saveBzenty" />
            </div>
          </div>
          <sb-grid
              id="wtClctBzentyGrid"
              :dataSource="dataSource"
              :columns="gridColumns"
              :gridattr="gridAttr"
              :param="searchParam"
              v-model:grid="bzentyGridObject"
              @loaded="gridLoaded"
              :refreshGrid="gridReload"
              fileName="폐기물수집업체 현황"
          />
        </div>
        <div class="btn_area right">
          <br-button label="반입업체등록" size="md" format="primary" @click="selectBzenty" />
          <br-button label="닫기" size="md" @click="hide" />
        </div>
      </div>
      <button type="button" class="modal_close" @click="hide">팝업 닫기</button>
    </div>
  </div>

  <address-popup
      @input-button-clicked="selectedAddress"
  />
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";
import SbGrid from "@/components/SbGrid.vue";
import AddressPopup from "@/views/mbr-mng/components/MbrFormAddress.vue";

const API = {
  WASTE_COLLECTION_BZENTY : "/wtClct/bzenty",
};
const CODE = {
  SALS_TRGT_WT: "COM405", // 영업대상폐기물
  BZENTY_STTS: "MBR003", // 업체상태
};

export default {
  components: {SbGrid, AddressPopup},
  emits: ['select-bzenty'],
  data() {
    return {
      isVisible: false,
      searchParam: {
        bplcId: "",
        ctpvStdgCd : "",
        sggStdgCd: "",
        wtClctBzentyNmList: "",
        brnoList: "",
        regYn: "",
      },
      ctpvCdList: [],
      sggCdList: [],
      salsTrgtWtList: [],
      bzentySttsList: [],
      gridReload: false,
      bzentyGridObject: null,
      dataSource: {
        ajaxConfig: {
          select: { url: API.WASTE_COLLECTION_BZENTY },
          paging: true,
        },
        schema: {
          id: 'wtClctBzentySn'
        }
      },
      gridColumns: [],
      gridAttr: {
        checkable: true,
        showStatus:true,
        pageable: true,
        editable: {eventType : 'mouseup',},
        stickyEdit: true,
        contextMenu : ['addRow', 'insertRow', 'appendRow'],
        captionHeight: 40,
      },
      wtClctBzentyKey: "",
      regYnOptions : [
        {value : "Y", text : "등록"},
        {value : "N", text : "미등록"},
      ],
    };
  },
  computed: {
    filteredSggCdList() {
      return this.sggCdList.filter(item => item.upSggStdgCd === this.searchParam.ctpvStdgCd);
    },
  },
  watch: {},
  created() {
    storeSwitch.setBulb('WtClctBzentyPopup', this);
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loadCtpvCd();
      this.loadSggCd();
      this.loadSalsTrgtWtCd();
      this.loadBzentySttsCd();
      this.initializeBzentyGridColumns();
      this.initializeBzentyGridAttr();
    },
    loadCtpvCd() {
      const ctpvList = this.$store.getters.getSggList({ type: 'ctpv' });
      this.ctpvCdList = ctpvList.map(item => ({
        text: item.sggNm,
        value: item.sggStdgCd
      }));
    },
    loadSggCd() {
      const sggList = this.$store.getters.getSggList({ type: 'sgg' });
      this.sggCdList = sggList.map(item => ({
        text: item.sggNm,
        value: item.sggStdgCd,
        upSggStdgCd: item.upSggStdgCd
      }));
    },
    loadSalsTrgtWtCd() {
      const salsTrgtWtList = this.$store.getters.getCodeList({ groupCd: CODE.SALS_TRGT_WT, useYn: 'Y' });
      this.salsTrgtWtList = salsTrgtWtList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    loadBzentySttsCd() {
      const bzentySttsList = this.$store.getters.getCodeList({ groupCd: CODE.BZENTY_STTS, useYn: 'Y' });
      this.bzentySttsList = bzentySttsList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    initializeBzentyGridColumns() {
      this.gridColumns = [
        { field: 'wtClctBzentySn', caption: '폐기물수집업체일련번호', visible: false },
        { field: 'wtClctBzentyNm', caption: '업체명', minWidth: 200, width: 100, unit:'%', align: 'left', part: 'sticky', editable : (data) => { return data.mpngCnt === 0 },
          colCss: (data) => {
            if (this.searchParam.wtClctBzentyNm && data.wtClctBzentyNm && data.wtClctBzentyNm.includes(this.searchParam.wtClctBzentyNm)) {
              return 'grid-warning';
            } else {
              return '';
            }
          },
          placeHolder: (data) => { return data.wtClctBzentyNm ? '' : '필수입력'; },
          placeHolderCss: (data) => { return data.wtClctBzentyNm ? '' : 'not-good'; },
        },
        { field: 'rprsvNm', caption: '대표자명', width: 120, editable : (data) => { return data.mpngCnt === 0},
          placeHolder: (data) => { return data.rprsvNm ? '' : '필수선택'; },
          placeHolderCss: (data) => { return data.rprsvNm ? '' : 'not-good'; }
        },
        { field: 'brno', caption: '사업자번호', width: 120, part: 'sticky', editable : (data) => { return data.mpngCnt === 0},
          format:(value) => {
            if(value && typeof value === 'string') {
              let cleanValue = value.replace(/\D/g, '');

              if (cleanValue.length > 10) {
                cleanValue = cleanValue.substring(0, 10);
              }

              return cleanValue.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
            }
            return value;
          },
          /*validators:[
            { rule :  inputValue => !isNaN(inputValue), message: '숫자만 입력할 수 있습니다' },
            { rule :  inputValue => inputValue.length === 10, message: '10글자를 입력해 주세요' },
          ],*/
          /*placeHolder: (data) => { return data.brno ? '' : '필수입력'; },*/
          placeHolderCss: (data) => { return data.brno ? '' : 'not-good'; }
        },
        { field: 'telno', caption: '전화번호', width: 120, editable : (data) => { return data.mpngCnt === 0},
          format: (value) => {
            if (value && typeof value === 'string') {
              let cleanValue = value.replace(/\D/g, '');

              if (cleanValue.length > 11) {
                cleanValue = cleanValue.substring(0, 11);
              }

              if (/^(0\d{1,2})(\d{3,4})(\d{4})$/.test(cleanValue)) {
                return cleanValue.replace(/^(0\d{1,2})(\d{3,4})(\d{4})$/, '$1-$2-$3');
              } else {
                return cleanValue;
              }
            }
            return value;
          },
          /*validators:[
            { rule :  inputValue => !isNaN(inputValue), message: '숫자만 입력할 수 있습니다' },
            { rule: 'maxLength', value: '11', message:'최대 11글자로 입력하세요'},
          ],*/
        },
        { field: 'address', caption: '주소검색', type: 'button', width: 70, exportVisible: false,
          command: (container, row) => {
            if(row.data.mpngCnt === 0) {
              const button = document.createElement('button');
              button.onclick = () => this.openAddressPopup(row.data._key_);
              button.innerText = "";
              button.className = "btn ico_search";
              container.append(button);
            }
          }
        },
        { field: 'bplcAddr', caption: '주소', width: 300, align: 'left', editable: false,
          placeHolder: (data) => { return data.bplcAddr ? '' : '필수입력'; },
          placeHolderCss: (data) => { return data.bplcAddr ? '' : 'not-good'; }
        },
        { field: 'ctpvStdgCd',
          caption: '시·도',
          type: 'combo', part: 'sticky', editable : (data) => { return data.mpngCnt === 0},
          items: this.ctpvCdList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 100,
          exportLabel : true,
          colCss: (data) => {
            if (this.searchParam.ctpvStdgCd && data.ctpvStdgCd && data.ctpvStdgCd === this.searchParam.ctpvStdgCd) {
              return 'grid-warning';
            } else {
              return '';
            }
          },
          placeHolder: (data) => { return data.ctpvStdgCd ? '' : '필수선택'; },
          placeHolderCss: (data) => { return data.ctpvStdgCd ? '' : 'not-good'; }
        },
        { field: 'sggStdgCd',
          caption: '시·군·구',
          type: 'combo', part: 'sticky', editable : (data) => { return data.mpngCnt === 0},
          items: this.sggCdList,
          comboLabel: 'text',
          comboValue: 'value',
          master: 'ctpvStdgCd',
          masterField: 'upSggStdgCd',
          useFilter: true,
          width: 110,
          exportLabel : true,
          colCss: (data) => {
            if (this.searchParam.sggStdgCd && data.sggStdgCd && data.sggStdgCd === this.searchParam.sggStdgCd) {
              return 'grid-warning';
            } else {
              return '';
            }
          },
          placeHolder: (data) => { return data.sggStdgCd ? '' : '필수선택'; },
          placeHolderCss: (data) => { return data.sggStdgCd ? '' : 'not-good'; }
        },
        { field: 'salsTrgtWtCd',
          caption: '영업대상폐기물',
          type: 'combo', editable : (data) => { return data.mpngCnt === 0},
          items: this.salsTrgtWtList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 155,
          exportLabel : true,
          colCss: (data) => {
            if (this.searchParam.salsTrgtWtCd && data.salsTrgtWtCd && data.salsTrgtWtCd === this.searchParam.salsTrgtWtCd) {
              return 'grid-warning';
            } else {
              return '';
            }
          },
          /*placeHolder: (data) => { return data.salsTrgtWtCd ? '' : '필수선택'; },*/
          placeHolderCss: (data) => { return data.salsTrgtWtCd ? '' : 'not-good'; }
        },
        { field: 'prcsTrgtWtNm', caption: '처리대상폐기물', width: 200, align: 'left', editable : (data) => { return data.mpngCnt === 0},
          colCss: (data) => {
            if (this.searchParam.prcsTrgtWtNm && data.prcsTrgtWtNm && data.prcsTrgtWtNm.includes(this.searchParam.prcsTrgtWtNm)) {
              return 'grid-warning';
            } else {
              return '';
            }
          },
        },
        { field: 'aprvPrmsnYmd',
          caption: '승인허가일',
          type: 'date', editable : (data) => { return data.mpngCnt === 0},
          calendarType: 'date',
          format: (date) => {
            if (date && date.length === 8) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              const dd = date.substring(6, 8);
              return `${yyyy}-${mm}-${dd}`;
            }
            return date;
          },
          width: 100,
        },
        { field: 'regYn', caption: '등록여부', width: 80, part: 'tail', editable: false,
          type: 'combo',
          items: this.regYnOptions,
          comboLabel: 'text',
          comboValue: 'value',
          colCss: (data) => {
            if (data.regYn === 'Y') {
              return 'bg-blue-light';
            } else {
              return '';
            }
          },
          exportLabel : true
        },
        { field: 'mpngCnt', caption: '타 사업장<br>등록 건수', width: 80, part: 'tail', editable: false }
      ]
    },
    initializeBzentyGridAttr() {
      this.gridAttr.rowCss = (data) => {
        const keys = window.SBGrid3.getCheckedKeys(this.bzentyGridObject);

        if (keys.includes(data.wtClctBzentySn)) {
          return 'grid-good';
        }

        return '';
      }
    },
    show(bplcId) {
      this.isVisible = true;
      document.documentElement.style.overflow = 'hidden';
      this.searchParam.bplcId = bplcId || "";
      this.wtClctBzentyKey = "";
      this.gridReload = true;
    },
    hide() {
      this.isVisible = false;
      this.wtClctBzentyKey = "";
      document.documentElement.style.overflow = '';
    },
    search() {
      window.SBGrid3.clearSaveData(this.bzentyGridObject);
      window.SBGrid3.reload(this.bzentyGridObject);
    },
    reset() {
      this.searchParam.ctpvStdgCd = "";
      this.searchParam.sggStdgCd = "";
      this.searchParam.wtClctBzentyNmList = "";
      this.searchParam.brnoList = "";
      this.searchParam.regYn = "";
      this.search();
    },
    gridLoaded() {
      this.gridReload = false;
    },
    openAddressPopup(key) {
      this.wtClctBzentyKey = key;
      storeSwitch.on("addressPopup");
    },
    selectedAddress(data) {
      const zipPart = data.zipNo ? `[${data.zipNo}] ` : "";
      const roadAddrPart = data.roadAddr || "";
      const roadDetailPart = data.roadDetailAddr ? ` ${data.roadDetailAddr}` : "";

      const bplcAddr = `${zipPart}${roadAddrPart}${roadDetailPart}`.trim();
      const ctpvStdgCd = data.ctpvStdgCd || "";
      const sggStdgCd = data.sggStdgCd || "";

      const key = this.wtClctBzentyKey;

      const finalResult = [
        { key: key, field: 'bplcAddr', value: bplcAddr },
        { key: key, field: 'ctpvStdgCd', value:ctpvStdgCd },
        { key: key, field: 'sggStdgCd', value: sggStdgCd },
      ];

      window.SBGrid3.setValues(this.bzentyGridObject, finalResult);
    },
    addBzenty() {
      const key = this.bzentyGridObject.rows.length > 0
          ? this.bzentyGridObject.rows[0].key
          : null;
      const insertRowData = {
        bzentySttsCd: '01',
        mpngCnt: 0,
        regYn: 'N'
      };

      window.SBGrid3.insertRow(this.bzentyGridObject, key, insertRowData);

    },
    copyBzenty() {
      const key = window.SBGrid3.getFocusedKey(this.bzentyGridObject);

      if (!key) {
        alert("선택된 업체가 없습니다.");
        return;
      }

      const rowData = window.SBGrid3.getRowData(this.bzentyGridObject, key);

      const newData = Object.assign({}, rowData);
      newData.mpngCnt = 0;
      newData.regYn = 'N';
      delete newData.wtClctBzentySn;

      window.SBGrid3.appendRow(this.bzentyGridObject, key, newData);
    },
    deleteBzenty() {
      const rowItems = window.SBGrid3.getCheckedRows(this.bzentyGridObject);

      if (rowItems.length === 0) {
        alert("선택된 업체가 없습니다.");
        return;
      }

      const hasNonInsertStatus = rowItems.some(row => row.status !== "insert");
      if (hasNonInsertStatus) {
        alert("행 추가 또는 행 복사로 생성된 업체만 삭제가 가능합니다.");
        return;
      }

      if (!confirm(`선택된 ${rowItems.length}개의 업체 정보를 삭제하시겠습니까?`)) {
        return;
      }

      const deleteKeys = window.SBGrid3.getCheckedKeys(this.bzentyGridObject);
      window.SBGrid3.deleteRows(this.bzentyGridObject, deleteKeys);
    },
    saveBzenty() {
      const { inserted, updated } = window.SBGrid3.getSaveData(this.bzentyGridObject, true, true, false);

      const list = [...inserted, ...updated];

      if (list.length === 0) {
        alert("추가되거나 수정된 업체가 없습니다.");
        return;
      }

      if(!this.validateBzenty(list)) return;

      if(confirm("변경 사항을 저장하시겠습니까?")) {
        this.$apiCall.post(
            API.WASTE_COLLECTION_BZENTY,
            { list },
            () => {
              alert("저장되었습니다.");
              window.SBGrid3.clearSaveData(this.bzentyGridObject);
              window.SBGrid3.reload(this.bzentyGridObject);
            },
            (error) => {
              alert(error.message);
            }
        );
      }
    },
    validateBzenty(list) {
      const uniqueChecker = new Set();

      for (const item of list) {
        if(item.mpngCnt > 0) {
          alert("타 사업장에 등록된 업체의 정보는 수정할 수 없습니다.");
          return;
        }

        if (!item.wtClctBzentyNm) {
          alert("업체명이 비어 있는 업체가 있습니다.\r\n확인 후 다시 저장해 주세요.");
          this.moveFocus({item, field: 'wtClctBzentyNm'});
          return false;
        }
        /*if (!item.brno) {
          alert("사업자번호가 비어 있는 업체가 있습니다.\r\n확인 후 다시 저장해 주세요.");
          this.moveFocus({item, field: 'brno'});
          return false;
        }*/
        if (!item.rprsvNm) {
          alert("대표자명이 비어 있는 업체가 있습니다.\r\n확인 후 다시 저장해 주세요.");
          this.moveFocus({item, field: 'rprsvNm'});
          return false;
        }
        if (!item.bplcAddr) {
          alert("주소가 비어 있는 업체가 있습니다.\r\n확인 후 다시 저장해 주세요.");
          this.moveFocus({item, field: 'bplcAddr'});
          return false;
        }
        if (!item.ctpvStdgCd) {
          alert("시·도가 비어 있는 업체가 있습니다.\r\n확인 후 다시 저장해 주세요.");
          this.moveFocus({item, field: 'ctpvStdgCd'});
          return false;
        }
        if (!item.sggStdgCd) {
          alert("시·군·구가 비어 있는 업체가 있습니다.\r\n확인 후 다시 저장해 주세요.");
          this.moveFocus({item, field: 'sggStdgCd'});
          return false;
        }
        /*if (!item.salsTrgtWtCd) {
          alert("영업대상사용폐기물이 선택되지 않은 업체가 있습니다.\r\n확인 후 다시 저장해 주세요.");
          this.moveFocus({item, field: 'salsTrgtWtCd'});
          return false;
        }*/

        const uniqueKey = `${item.wtClctBzentyNm}-${item.brno}-${item.telno}-${item.bplcAddr}-${item.salsTrgtWtCd}-${item.prcsTrgtWtNm}-${item.ctpvStdgCd}-${item.sggStdgCd}`;
        if (uniqueChecker.has(uniqueKey)) {
          alert("동일한 데이터가 중복으로 입력된 업체가 있습니다.\r\n확인 후 다시 저장해 주세요.");
          return false;
        }
        uniqueChecker.add(uniqueKey);

      }
      return true;
    },
    moveFocus(param) {
      const item = param.item;
      const fieldName = param.field;

      const key = item._key_;
      const column = window.SBGrid3.getColumnByField(this.bzentyGridObject, fieldName);

      window.SBGrid3.columnEditable(this.bzentyGridObject, key, column, true);
    },
    selectBzenty() {
      const checkedBzentyItem = window.SBGrid3.getCheckedRows(this.bzentyGridObject);

      if (checkedBzentyItem.length === 0) {
        alert("선택된 업체가 없습니다.");
        return;
      }

      const hasInsertStatus = checkedBzentyItem.some(row => row.status === "insert");
      if (hasInsertStatus) {
        alert("저장되지 않은 데이터가 포함되어 있습니다.\n저장 후 등록해 주시기 바랍니다.");
        return;
      }

      const hasRegistered = checkedBzentyItem.some(item => item.data.regYn === 'Y');
      if (hasRegistered) {
        alert("이미 등록된 업체가 포함되어 있습니다.");
        return;
      }

      const confirmMessage = `선택된 ${checkedBzentyItem.length}개의 업체를 등록하시겠습니까?`;

      if (confirm(confirmMessage)) {
        const selected = checkedBzentyItem.map(item => item.data.wtClctBzentySn);

        this.$emit('select-bzenty', selected);
      }
    }
  }
}
</script>

<style scoped>
</style>