<template>
  <div class="board_info">
    <h2 class="title1">온라인교육 강의상세</h2>
  </div>
  <div class="cont_box">
    <div class="board_view online_edu">
      <div class="b_tit">
        <h2>{{ param.lctrNm }}</h2>
      </div>
      <div class="b_cont">
        <dl>
          <dt>강의시간</dt>
          <dd>{{ param.lctrHr }}</dd>
        </dl>
        <dl>
          <dt>완강여부</dt>
          <dd>{{ param.rgcrsCmptnYn === "Y" ? '완료' : '미완료' }}</dd>
        </dl>
        <dl>
          <dt>현재진행률</dt>
          <dd>{{ progress ? progress : 0  }} % </dd>
        </dl>
        <div class="video_area"> 
          <video ref="videoPlayer"
            width="800"
            :src="videoSrc"
            muted
            controlsList="nodownload noremoteplayback" 
            @timeupdate="updateProgressBar"
            @ended="handleVideoEnd">
          </video>
          <div class="btn_controls">
            <button type="button" class="btn btn xsm secondary" @click="playVideo" :disabled="isPlaying" style="margin-right:10px;">재생</button>
            <button type="button" class="btn btn xsm secondary" @click="pauseVideo">일시정지</button>
          </div>
        </div>
        <div class="txt_hint">※ 재생, 일시정지, 음량 조절 기능만 제어 됩니다. </div>
      </div>
      <div class="edu_list">
        <h3>{{ param.eduNm }}</h3>
        <ul>
          <li v-for="item in lectureList" :key="item.lctrSqno">
            <span class="title">{{ item.lctrNm }}</span>
            <span class="time">{{ item.lctrHr }}</span>
            <span class="percent" style="width:100px;">{{ item.rgcrsPrgrt ? item.rgcrsPrgrt : 0 }} % / {{ item.rgcrsCmptnRt }} %</span>
            <span class="state">{{ param.rgcrsCmptnYn === 'Y' ? '완료' : '미완료'}}</span>
            <br-button label="강의보기" format="btn xsm secondary" @click="lectureInq( item )"/>
          </li>
        </ul>
      </div>
    </div>
    <div class="btn_area right">
      <div>
        <br-button label="목록" format="tertiary line" @click="navigateTo( '/cust-mng/OnlineEducationManage' )"/>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 온라인교육(강의) 상세 화면입니다.
 *
 * 작성자 : 정미라
 * 작성일 : 24. 10. 17.
 * 버전 : 1.0
 */
import { buildUri } from "@/js/utils/uri-builder";

const API = {
  ONLINE_LECTURE: "/online-education/{eduSn}/lecture/{lctrSqno}",
  ONLINE_LECTURE_LIST: "/online-education/{eduSn}/lecture"
}

 export default {
  atchFileGroupId: { type: String, default: null },
  data() {
    return {
      eduSn : "",
      lctrSqno: this.$route.query.lctrSqno,
      isPlaying: false, // 현재 재생 상태
      param : {
        eduNm : "",         // 강좌명
        lctrNm : "",        // 강의명 
        lctrHr : "",        // 강의시간
        rgcrsCmptnYn : "",  // 완강여부
        eduLctrHr : "",     // real 강의시간
        lctrSqno: "",
        lctrVdoUrlAddr: "",   // url로 입력한 값이 있을경우
        rgcrsCmptnRt: ""
      },
      atchFileVldNo: "",
      lectureList: [],
      files: [],
      strgFileNm: "",
      videoSrc: "", // 로컬테스트 시 직접url 입력 (https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4)
      intervalId: null,     // 진행도 업데이트를 위한 타이머 ID
      startTime: null,
      strgFilePathNm :"",
      elapsedTime: 0,
      totalSeconds: 0,
      progress: 0,          // 진행률 (%)
      
    };
  },
  watch : {},
  computed : {},
  created() {},
  beforeMount() {},
  mounted() {
    this.eduSn = this.$route.query.eduSn;
    this.lctrSqno = this.$route.query.lctrSqno;
    // 강의 상세 데이터를 조회
    this.readOnlineLectureDetail();
  },
  beforeUnmount() {
    this.clearProgressInterval();
  },
  methods: {
    // 특정일련번호 강의상세 조회 
    readOnlineLectureDetail() {
      this.$apiCall.get(buildUri(API.ONLINE_LECTURE, {eduSn : this.eduSn, lctrSqno : this.lctrSqno}), {}, this.readOnlineLectureDetailSuccess, this.error);
    },
    readOnlineLectureDetailSuccess( data ) {
      if ( !data.result ) {
        this.LectureDetaileerror();
        return;
      }
      this.param = data.result;

      // 파일 데이터가 있는지 확인 (로컬테스트 시엔 주석. 반영시엔 주석 풀기.)
      if (this.param.files && Array.isArray(this.param.files)) {

        let removePath = "/wes/upload/file";
        if(`${process.env.VUE_APP_ENV}` === 'prod'){
          removePath = "/mnt/nas/prod/upload/file";
        }
        console.log("removePath", removePath);

        const firstFile = this.param.files[0];
        const filePathReplace = firstFile.strgFilePathNm.replace(/\\/g, '/').replace(removePath, ''); // 개발일때 테스트 (업로드 된 경로)
        const fileName = firstFile.strgFileNm;
        const filePath = `${process.env.VUE_APP_IMAGE_RESOURCE}/api/image` + filePathReplace; // 현재 서버 경로
        this.videoSrc = `${filePath}/${fileName}`;

        console.log("Video Source Path:", this.videoSrc);
      } else {
        console.error("File information is missing or not an array:", this.videoSrc);
      }

      // 관리자가 url로 동영상 경로를 지정했을경우 직접 videoSrc에 넣어준다.
      if (this.param.lctrVdoUrlAddr){
        this.videoSrc = this.param.lctrVdoUrlAddr
      }      

      // 강의 시간 초로 변환
      this.totalSeconds = this.parseTime( this.param.eduLctrHr || "000000" );

      // 로그로 확인
      console.log("강의 총 시간 (초):", this.totalSeconds);

      // 진행률에 따른 동영상 시작 시간 설정
      const savedProgress = data.result.rgcrsPrgrt || 0; // 백분율 진행률
      const video = this.$refs.videoPlayer;
      if ( video && savedProgress > 0 && savedProgress != 100 ) {
        if (confirm( "이전 시청 기록이 있습니다. \n이어서 시청하시겠습니까?" )) {
          const startTime = ( savedProgress / 100 ) * this.totalSeconds;
          video.currentTime = startTime; // 저장된 위치로 설정
          console.log( "동영상 시작 시간:", startTime, "초" );
          this.playVideo();
        }
      }
      this.readOnlineLecture();
    },
    LectureDetaileerror() {
      alert( "데이터를 가져오는 중 오류가 발생했습니다." );
    },
    // 강좌- 강의리스트 조회 
    readOnlineLecture() {
      this.$apiCall.get( buildUri( API.ONLINE_LECTURE_LIST, { eduSn : this.eduSn }), {}, this.readOnlineLectureSuccess, this.Lectureerror );
    },
    readOnlineLectureSuccess( data ) {
      if ( !data.result ) {
        this.Lectureerror();
        return;
      }
      this.lectureList = data.result.list;
    },
    Lectureerror() {
      alert( "데이터를 가져오는 중 오류가 발생했습니다." );
    },
    lectureInq( item ) {
      if ( Number( this.lctrSqno ) !== Number( item.lctrSqno )) { // 선택한 강의가 다를 때만 경로 변경
        // 조회수 증가 및 강의보기 이동
        const url = `/online-education/${ item.eduSn }/lecture/${ item.lctrSqno }/lctrInqCnt`;
        this.$apiCall.post( url, item, 
        () => {
            this.$router.push({ 
                path: "/cust-mng/online-education/OnlineLectureDetail", 
                query: { eduSn: this.eduSn, lctrSqno: item.lctrSqno }
            }).then(() => {
                window.location.reload();
            }).catch(() => {
                console.error( "Navigation Error" );
            });
        }, 
        () => {
            alert( "조회수가 증가하지 못했습니다." );
        }); 
      } else {
          alert( "현재 해당 강의화면입니다." );
          return;
      }
    },
    navigateTo( path ) {
      if ( path ) {
        this.$router.push( path ).catch(() => {
          console.error("Navigation Error");
        });
      }
    },
    // 시간 형식 변환 (001000 -> 초 단위)
    parseTime( timeStr ) {
      const hours = parseInt( timeStr.slice(0, 2), 10 ) || 0;
      const minutes = parseInt( timeStr.slice(2, 4), 10 ) || 0;
      const seconds = parseInt( timeStr.slice(4, 6), 10 ) || 0;
      return hours * 3600 + minutes * 60 + seconds;
    },

    // 재생 버튼
    playVideo() {
      const video = this.$refs.videoPlayer;
      video.muted = false;  // 음소거해제
      if ( !video ) return;
      // 이미 재생 중이라면 다시 재생하지 않음
      if ( this.isPlaying ) {
        console.log( "이미 재생 중입니다." );
        return;
      }

      // 저장된 진행률에서 재생 시작
      const savedProgress = this.param.rgcrsPrgrt || 0; // 기존 진행률 
      const startTime = ( savedProgress / 100 ) * this.totalSeconds;

      if ( video.currentTime === 0 && savedProgress > 0 ) {
          video.currentTime = startTime; // 저장된 진행률 위치로 이동
          console.log( "저장된 진행률에서 시작:", savedProgress, "%", " (", startTime, "초)" );
      }
      if ( savedProgress === 100 )  {
        alert( "해당 강의는 이미 완강 하셨습니다." );
        return;
      }

      // 동영상을 로딩하고, 일정 시간 후 재생
      setTimeout(() => {
          video.play();
          this.isPlaying = true; // 재생 상태 업데이트
      }, 500); // 500ms 지연 후 재생

      // 현재 재생 시작 시간 초기화
      if ( !this.startTime ) {
        this.startTime = Date.now();
      }
      // 진행률 업데이트 주기적으로 호출
      this.clearProgressInterval();    // 기존 진행률 업데이트 타이머 초기화
      this.intervalId = setInterval(() => this.updateProgress(), 30000); //30000: 30초마다 업데이트
    },

    // 일시정지 버튼
    pauseVideo() {
      const video = this.$refs.videoPlayer;
      if (!video) return;
      video.pause();
      this.isPlaying = false; // 재생 상태 업데이트

      // 경과 시간 계산 및 진행률 즉시 업데이트
      this.calculateElapsedTime();
      this.updateProgress();          // 현재 상태 업데이트
      this.clearProgressInterval();   // 타이머 정지
    },

    // 경과 시간 계산
    calculateElapsedTime() {
      if ( this.startTime ) {
        const currentTime = Date.now();
        this.elapsedTime += Math.floor(( currentTime - this.startTime ) / 1000); // 초 단위로 누적
        this.startTime = null; // 재생 멈추면 초기화
      }
    },

    // 진행률 업데이트
    updateProgress( isComplete = false ) {
      const video = this.$refs.videoPlayer;
      if ( !video ) return;

      // 동영상 재생 시간과 전체 시간 비교
      const currentProgress = Math.floor(( video.currentTime / this.totalSeconds ) * 100 );

      // 진행률이 100%일 경우 업데이트를 중지
      if ( currentProgress >= 100 || isComplete ) {
        this.clearProgressInterval(); 
        //alert("강의시청이 완료되었습니다.");
      }
      // API 요청 데이터
      const url = `/online-education/${this.eduSn}/lecture/${this.lctrSqno}/progress`;
      const data = {
        eduSn: this.eduSn,
        lctrSqno: this.lctrSqno,
        progress: isComplete ? 100 : currentProgress, // 동영상 종료 시 100%, 아니면 계산된 진행률
        rgcrsCmptnRt: this.param.rgcrsCmptnRt,
        elapsedTime: this.elapsedTime,                // 누적 경과 시간
        isComplete
      };
      this.$apiCall.post( url, data, () => console.log( "Progress updated successfully:", currentProgress, "%" ), () => console.error( "Failed to update progress." ));
    },
    // 동영상 재생 종료 처리
    handleVideoEnd() {
      this.isPlaying = false;       // 재생 상태
      this.calculateElapsedTime();  // 종료 시까지의 경과 시간 계산
      this.updateProgress( true );  // 진행률을 100%로 설정하고 서버에 전송
    },
    // 타이머 정지
    clearProgressInterval() {
      if ( this.intervalId ) {
        clearInterval( this.intervalId );
        this.intervalId = null;
      }
    },
    updateProgressBar() {
      const video = this.$refs.videoPlayer;
      if ( !video || this.totalSeconds === 0 ) return;

      // 현재 재생 시간에 기반한 진행률 계산
      this.progress = Math.floor(( video.currentTime / this.totalSeconds ) * 100);
    },
  }
};
</script>

<style scoped>
  .board_view .edu_list li .time {
    width: 10.2rem;
  }

  .video_area video {
    width: 100%;
    height: auto;
  }

  .board_view .b_cont .video_area {
    min-height: 30rem;
  }
</style>