<template>
  <div v-if="isOn" class="loader_area"><span class="loader"></span></div>
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";

export default {
  components: {},
  data() {
    return {
      isOn: false,
    };
  },
  created() {
    storeSwitch.setBulb('loading', this);
  },
  methods: {
    show() {
      this.isOn = true;
    },
    hide() {
      this.isOn = false;
    }
  }
}
</script>

<style scoped>

</style>