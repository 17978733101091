<template>
    <div v-show="isOn" :class="['modal', { visible: true }]">
      <div class="popup_box" tabindex="0">
        <h1 class="popup_title">인센티브</h1>
        <div class="popup_cont">
            <h2 class="title1 mt_0">인센티브 상세</h2>
            <div class="tbl_wrap">
                <sb-grid
                    ref="listGrid"
                    id="listGrid"
                    :dataSource="listDataSource"
                    :columns="listGridColumns"
                    :gridattr="listGridAttr"
                    :countVisible="false"
                    :param="localIcntvInfo"
                    v-model:grid="listGridObject"
                    :refreshGrid="listGridReload"
                    @loaded="listGridLoaded"
                    @rowClicked="openPopup"
                />
            </div>
            <div class="btn_area center">
                <button type="button" class="btn md" @click="hide">닫기</button> 
            </div>
        </div>
        <button type="button" class="modal_close" @click="hide">팝업 닫기</button>
      </div>
    </div>

    <component  v-if="reportParamObj.selectedPopupComponent" 
        :is="reportParamObj.selectedPopupComponent" 
        :rd-param-file-name="reportParamObj.rdParamFileName" 
        :rd-param-key="reportParamObj.rdParamKey" 
        :rd-param-value="reportParamObj.rdParamValue" 
    />
</template>

<script>
/**
 * 인센티브 상세 팝업 화면
 *
 * 작성자 : 김지환
 * 작성일 : 11/04/2024
 * 버전 : 1.0
 */
import { storeSwitch } from "@/js/store/store-switch";
import SbGrid from "@/components/SbGrid.vue";
import reportQltyGrdPopup from "@/views/qlty-grd/ReportQltyGrdPopup.vue";

export default {
    components: {SbGrid,reportQltyGrdPopup},
    props: {
        icntvInfo: {
        type: Object,
        required: true,
      },
    },
    data() {
        return {
        isOn: false,
        localIcntvInfo :  { ...this.icntvInfo },
        listGridReload: false,
        listGridObject: null,
        listDataSource: {
          ajaxConfig: {
            select: { url: '/icntv/icntvInfoList' },
            paging: false,
          },
          schema: {
            id : ""
          },
        },
        listGridColumns: [],
        listGridAttr: {
          showRowNo: false,
          pageable: false,
          editable: false,
          mergeRow: true,
          height: '300px',
          excelExport: {
            fileName: '인센티브상세.xlsx',
            includeHeader: true,
            includeFormula: true,
          }
        },
        reportParamObj : {
          selectedPopupComponent: null, // 선택된 리포트/이폼 화면 구분 ID 
          rdParamKey: "",
          rdParamValue: "",
          rdParamFileName: "", 
        }, 
        qltyGrdRsltSn: "",
      };
    },
    computed: {},
    watch: {
        icntvInfo: {
            handler(newValue) {
            // icntvInfo가 변경될 때 localIcntvInfo를 업데이트
            this.localIcntvInfo = { ...newValue };
            if (this.localIcntvInfo.bplcId) {
                if(this.listGridObject) {
                    window.SBGrid3.clearSaveData(this.listGridObject);
                    this.callList();  
                }
            }
            },
            immediate: true // 처음에 qltyGrdInfo가 설정될 때도 반영
      }
    },
    created() {
        storeSwitch.setBulb('listPopup', this);
    },
    mounted() {
        this.initialize();
    },
    methods: {
        /** 팝업 Show */
        show() {
            this.isOn = true;
        },
        /** 팝업 Hide */
        hide() {
            this.localIcntvInfo = {};
            this.isOn = false;
        },       
        /** 화면 초기화 */
        async initialize() {
            this.initializeColumns();
            this.initializeGridAttr();
            this.listGridReload = true;
        },
        initializeColumns() {
            this.listGridColumns = [
                { field: 'icntvInspRcptNo', editable: false, width: 140, caption: '접수번호', mergeRow:true }, 
                // { field: 'year', editable: false, width: 60, caption: '실적연도' }, 
                // { field: 'qtr', editable: false, width: 60, caption: '실적분기' },
                // { field: 'bplcId', editable: false, width: 90, caption: '사업장ID' },
                // { field: 'bplcNm', editable: false, width: 150, caption: '사업장' },
                // { field: 'ctpvStdgCd', editable: false, width: 80, caption: '시도'  },
                // { field: 'sggStdgCd', editable: false, width: 80, caption: '시군구' },
                { field: 'pubBzentyYn', editable: false, width: 80, caption: '공공업체여부', mergeRow:true },
                // { field: 'crynBplcId', editable: false, width: 90, caption: '반입사업장ID' },
                { field: 'crynBplcNm', editable: false, width: 150, caption: '반입사업장', align: 'left' },
                { field: 'bplcKndCd', editable: false, width: 90, caption: '사업장종류', mergeRow:true },
                { field: 'prdctKndNm', editable: false, width: 100, caption: '제품종류', mergeRow:true },
                { field: 'qltyGrdIssuNo', editable: false, width: 140, caption: '품질등급발급번호', colCss:'blue' },
                { field: 'qltyGrdCd', editable: false, width: 60, caption: '등급' },
                { field: 'qltyGrdScr', editable: false, width: 60, caption: '점수' },
                { field: 'prdctAcpqty', editable: false, width: 70, caption: '반입량(톤)', align: 'right' },
                { field: 'totalAcpqty', editable: false, width: 80, caption: '총반입량(톤)', align: 'right', mergeRow:true },
                // { field: 'rtAcpqty', editable: false, width: 50, caption: '비율' },
                // { field: 'one', editable: false, width: 50, caption: '최우수' },
                // { field: 'two', editable: false, width: 50, caption: '우수' },
                // { field: 'three', editable: false, width: 50, caption: '양호' },
                // { field: 'icntvTrgtYn', editable: false, width: 60, caption: '적용여부' },
            ]
      },
      initializeGridAttr() {
        this.listGridAttr.rowCss = (data) => {
            const keys = window.SBGrid3.getCheckedKeys(this.listGridObject);
            if (keys.includes(data._key_)) {
            return 'grid-danger';
            }
            return '';
        }
      },
      listGridLoaded() {
        this.listGridReload = false;
      },
      callList() {
        window.SBGrid3.clearSaveData(this.listGridObject);
        window.SBGrid3.reload(this.listGridObject);
      },
      openPopup(data) {
        this.openReportForm(data.qltyGrdRsltSn);
      },
      openReportForm(qltyGrdRsltSn) { // 리포트/이폼 화면 열기 

        let rdFimeName = "";
        rdFimeName = "reportQltyGrd";

        this.qltyGrdRsltSn = null;
        this.qltyGrdRsltSn = qltyGrdRsltSn;

        if (!this.qltyGrdRsltSn) {
          alert("출력할 대상을 선택해주세요."); 
          return;
        }

        this.reportParamObj.selectedPopupComponent = null; // 컴포넌트 리셋
        this.openLayerPopup(rdFimeName);

        /*
        * 재호출 후 팝업에서 노출되는 현상으로 재호출 
        */ 
        setTimeout(() => {
          this.openLayerPopup(rdFimeName);
        }, 50); 

      },
      openLayerPopup(rdFimeName) { // 레이어 팝업 기능 

        setTimeout(() => {
          this.reportParamObj.selectedPopupComponent = 'reportQltyGrdPopup'; // 시설검사 신청 내역 화면내 팝업 컴포넌트 

          // rd Param 초기화 
          this.reportParamObj.rdParamFileName = rdFimeName;
          this.reportParamObj.rdParamKey = "";
          this.reportParamObj.rdParamValue = "";
          
          // rd 파일명 기준으로 Param 적용 
          switch (rdFimeName) {
            case 'reportQltyGrdOpnn': // 품질등급 의견서 
              this.reportParamObj.rdParamFileName = rdFimeName;                         // 리포트 mrd 파일명 
              this.reportParamObj.rdParamKey = 'KEY_NO';                            // 리포트 화면 Param Key 
              this.reportParamObj.rdParamValue = this.qltyGrdRsltSn;  // 리포트 화면 Param Key에 대한 Value  

              break;

            case 'reportTmprQltyGrd': // 임시품질등급서 
              this.reportParamObj.rdParamFileName = rdFimeName;                     // 리포트 mrd 파일명 
              this.reportParamObj.rdParamKey = 'KEY_NO';                            // 리포트 화면 Param Key 
              this.reportParamObj.rdParamValue = this.qltyGrdRsltSn;  // 리포트 화면 Param Key에 대한 Value  

              break;

            case 'reportQltyGrd': // 품질등급서 
              this.reportParamObj.rdParamFileName = rdFimeName;                     // 리포트 mrd 파일명 
              this.reportParamObj.rdParamKey = 'KEY_NO';                            // 리포트 화면 Param Key 
              this.reportParamObj.rdParamValue = this.qltyGrdRsltSn;  // 리포트 화면 Param Key에 대한 Value  

              break;

            default:
              break;
          }

          storeSwitch.on(this.reportParamObj.selectedPopupComponent); 
        }, 0); // 0ms 딜레이로 컴포넌트 강제 리렌더링 (리포트 리렌더링 안 되는 케이스가 존재하여 추가)
      },
    }
}
</script>

<style>
.blue {
  color: blue;
}
</style>