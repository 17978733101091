<template>
  <div class="grid_area_wrap">
    <div class="grid_area" style="flex: 0.9;">
      <div class="board_info">
        <h3 class="title2">반입업체 현황</h3>
        <div>
          <br-anchor-button label="반입업체검색" size="sm" format="tertiary" icon="ico_search" @click="showWtClctBzentyPopup" />
        </div>
      </div>
      <sb-grid
          id="crynGrid"
          :data-source="crynDataSource"
          :columns="crynGridColumns"
          :gridattr="crynGridAttr"
          :param="param"
          v-model:grid="crynGridObject"
          @loaded="crynGridLoaded"
          :refreshGrid="crynGridReload"
          @change="changeWtClctCnptUseYn"
          fileName="고형연료제조 반입업체 현황.xlsx"
      />
    </div>
    <div class="grid_area" style="flex: 1.1;">
      <div class="board_info">
        <h3 class="title2">판매(공급)업체 현황</h3>
        <div>
          <br-anchor-button label="판매업체검색" size="sm" format="tertiary" icon="ico_search" @click="showSplyBzentyPopup" />
        </div>
      </div>
      <sb-grid
          id="splyGrid"
          :dataSource="splyDataSource"
          :columns="splyGridColumns"
          :gridattr="splyGridAttr"
          :param="searchParam"
          v-model:grid="splyGridObject"
          @loaded="splyGridLoaded"
          :refreshGrid="splyGridReload"
          @change="changeUseMnftrCnptUseYn"
          fileName="고형연료제조 판매(공급)업체 현황.xlsx"
      />
    </div>
  </div>

  <wt-clct-bzenty-popup @select-bzenty="saveWtClctCnpt" />
  <use-mnftr-bplc-popup bplcKndCd="01-02" @select-bplc="saveUseMnftrCnpt" />
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";
import SbGrid from "@/components/SbGrid.vue";
import WtClctBzentyPopup from "@/views/prfmnc-rpt/components/popup/WtClctBzentyPopup.vue";
import UseMnftrBplcPopup from "@/views/prfmnc-rpt/components/popup/UseMnftrBplcPopup.vue";

const API = {
  WASTE_COLLECTION_COUNTERPARTY : "/wtClct/cnpt",
  USE_MANUFACTURE_COUNTERPARTY: "/useMnftr/cnpt",
}

export default {
  components: {
    UseMnftrBplcPopup,
    WtClctBzentyPopup,
    SbGrid
  },
  props: ['param'],
  data() {
    return {
      searchParam: {
        ...this.param,
        crynNtslSeCd: '02'
      },
      ctpvCdList: [],
      sggCdList: [],
      crynGridReload: false,
      crynGridObject: null,
      crynDataSource: {
        ajaxConfig: {
          select: { url: API.WASTE_COLLECTION_COUNTERPARTY },
          paging: false,
        },
        schema: {
          id: 'wtClctBzentySn'
        }
      },
      crynGridColumns: [],
      crynGridAttr: {
        pageable: false,
        editable: true,
        sortable: true,
        mergeRow: true,
      },
      splyGridReload: false,
      splyGridObject: null,
      splyDataSource: {
        ajaxConfig: {
          select: { url: API.USE_MANUFACTURE_COUNTERPARTY },
          paging: false,
        },
        schema: {
          id: 'useBplcId'
        }
      },
      splyGridColumns: [],
      splyGridAttr: {
        pageable: false,
        editable: true,
        sortable: true,
        mergeRow:true
      },
    };
  },
  computed: {
  },
  watch: {
    param: {
      handler(newVal) {
        this.searchParam = { ...newVal, crynNtslSeCd: '02' };
      },
      deep: true,
    }
  },
  created() {
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loadCtpvCd();
      this.loadSggCd();
      this.initializeCrynGridColumns();
      this.initializeSplyGridColumns();
      this.crynGridReload = true;
      this.splyGridReload = true;
    },
    loadCtpvCd() {
      const ctpvList = this.$store.getters.getSggList({ type: 'ctpv' });
      this.ctpvCdList = ctpvList.map(item => ({
        text: item.sggNm,
        value: item.sggStdgCd
      }));
    },
    loadSggCd() {
      const sggList = this.$store.getters.getSggList({ type: 'sgg' });
      this.sggCdList = sggList.map(item => ({
        text: item.sggNm,
        value: item.sggStdgCd,
        upSggStdgCd: item.upSggStdgCd
      }));
    },
    initializeCrynGridColumns() {
      this.crynGridColumns = [
        { field: 'bplcId', caption: '사업장아이디', visible: false },
        { field: 'wtClctBzentySn', caption: '폐기물수집업체일련번호', visible: false },
        { field: 'ctpvStdgCd', caption: '시·도', sortable : false,
          type: 'combo',
          items: this.ctpvCdList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 100,
          editable: false,
          mergeRow:true,
          exportLabel : true
        },
        { field: 'sggStdgCd', caption: '시·군·구', sortable : false,
          type: 'combo',
          items: this.sggCdList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 110,
          editable: false,
          mergeRow:true,
          exportLabel : true
        },
        { field: 'wtClctBzentyNm', caption: '업체명', minWidth: 150, width: 100, unit:'%', align: 'left', editable: false, sortable : false },
        { field: 'useYn',
          caption: '사용여부', part: 'sticky',
          type: 'check',
          checkValue: 'Y',
          checkLabel: '사용',
          unCheckValue: 'N',
          unCheckLabel: '미사용',
          checkMode:'cell',
          directApplyEdit : true,
          width: 80,
          exportLabel : true
        },
      ]
    },
    initializeSplyGridColumns() {
      this.splyGridColumns = [
        { field: 'mnftrBplcId', caption: '제조사업장아이디', visible: false },
        { field: 'useBplcId', caption: '사용사업장아이디', visible: false },
        { field: 'crynNtslSeCd', caption: '반입판매구분코드', visible: false },
        { field: 'useBplcCtpvStdgCd', caption: '시·도', sortable : false,
          type: 'combo',
          items: this.ctpvCdList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 100,
          editable: false,
          mergeRow:true,
          exportLabel : true
        },
        { field: 'useBplcSggStdgCd', caption: '시·군·구', sortable : false,
          type: 'combo',
          items: this.sggCdList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 110,
          editable: false,
          mergeRow:true,
          exportLabel : true
        },
        { field: 'useBplcNm', caption: '사업장명', minWidth: 150, width:100, unit:'%', align: 'left', editable: false, sortable : false },
        { field: 'inputItems', caption: '사용품목', width: 170, align: 'left', editable: false, sortable : false },
        {
          field: 'useYn',
          caption: '사용여부', part: 'sticky',
          type: 'check',
          checkValue: 'Y',
          checkLabel: '사용',
          unCheckValue: 'N',
          unCheckLabel: '미사용',
          checkMode:'cell',
          directApplyEdit : true,
          width: 80,
          exportLabel : true
        },
      ]
    },
    crynGridLoaded() {
      this.crynGridReload = false;
    },
    splyGridLoaded() {
      this.splyGridReload = false;
    },
    showWtClctBzentyPopup() {
      storeSwitch.on('WtClctBzentyPopup', this.param.bplcId);
    },
    showSplyBzentyPopup() {
      storeSwitch.on('BplcPopup', this.param.bplcId);
    },
    saveWtClctCnpt(list) {
      const crynAllItems = window.SBGrid3.getAllItems(this.crynGridObject);

      // wtClctBzentySn 추출하여 배열로 생성
      const existingwtClctBzentySns = crynAllItems.map(item => item.wtClctBzentySn);

      // list에서 기존 항목에 포함되지 않은 값만 필터링
      const filteredList = list.filter(item => !existingwtClctBzentySns.includes(item));

      if (filteredList.length === 0) {
        alert("이미 등록된 업체입니다.");
        return;
      }

      this.$apiCall.post(
          API.WASTE_COLLECTION_COUNTERPARTY,
          { bplcId: this.param.bplcId, list },
          () => {
            alert("저장되었습니다.");
            storeSwitch.off("WtClctBzentyPopup");
            window.SBGrid3.reload(this.crynGridObject);
          },
          (error) => {
            alert(error.message);
          }
      );
    },
    saveUseMnftrCnpt(list) {
      const mnfrtAllItems = window.SBGrid3.getAllItems(this.splyGridObject);

      // useBplcId를 추출하여 배열로 생성
      const existingUseBplcIds = mnfrtAllItems.map(item => item.useBplcId);

      // list에서 기존 항목에 포함되지 않은 값만 필터링
      const filteredList = list.filter(item => !existingUseBplcIds.includes(item));

      if (filteredList.length === 0) {
        alert("이미 등록된 사업장입니다.");
        return;
      }

      const formattedList = filteredList.map(item => ({
        mnftrBplcId: this.param.bplcId,
        useBplcId: item,
        crynNtslSeCd: '02'
      }));

      this.$apiCall.post(
          API.USE_MANUFACTURE_COUNTERPARTY,
          { list: formattedList },
          () => {
            alert("저장되었습니다.");
            storeSwitch.off("BplcPopup");
            window.SBGrid3.reload(this.splyGridObject);
          },
          (error) => {
            alert(error.message);
          }
      );
    },
    changeWtClctCnptUseYn(values) {
      values.forEach(item => {
        if (item.field === 'useYn') {
          const rowData = window.SBGrid3.getRowData(this.crynGridObject, item.key);

          if (!confirm(`${rowData.wtClctBzentyNm}의 사용여부를 변경하시겠습니까?`)) {
            window.SBGrid3.clearSaveData(this.crynGridObject);
            window.SBGrid3.reload(this.crynGridObject);
            return;
          }
          this.$apiCall.put(
              API.WASTE_COLLECTION_COUNTERPARTY,
              { bplcId: rowData.bplcId, wtClctBzentySn: rowData.wtClctBzentySn, useYn: item.value },
              () => {
                alert("사용여부가 수정되었습니다.");
                window.SBGrid3.clearSaveData(this.crynGridObject);
                window.SBGrid3.reload(this.crynGridObject);
              },
              (error) => {
                alert(error.message);
              }
          );
        }
      })
    },
    changeUseMnftrCnptUseYn(values) {
      values.forEach(item => {
        if (item.field === 'useYn') {
          const rowData = window.SBGrid3.getRowData(this.splyGridObject, item.key);

          if (!confirm(`${rowData.useBplcNm}의 사용여부를 변경하시겠습니까?`)) {
            window.SBGrid3.clearSaveData(this.splyGridObject);
            window.SBGrid3.reload(this.splyGridObject);
            return;
          }

          this.$apiCall.put(
              API.USE_MANUFACTURE_COUNTERPARTY,
              { mnftrBplcId: rowData.mnftrBplcId, useBplcId: rowData.useBplcId, crynNtslSeCd: rowData.crynNtslSeCd, useYn: item.value },
              () => {
                alert("사용여부가 수정되었습니다.");
                window.SBGrid3.clearSaveData(this.splyGridObject);
                window.SBGrid3.reload(this.splyGridObject);
              },
              (error) => {
                alert(error.message);
              }
          );
        }
      })
    },
    search() {
      window.SBGrid3.reload(this.crynGridObject);
      window.SBGrid3.reload(this.splyGridObject);
    }
  }
}
</script>

<style scoped>

</style>