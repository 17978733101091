<template>
  <rjct-rsn-area :prfmncBasicInfo="prfmncBasicInfo" />
  <prfmnc-mdfcn-prm-area :prfmncBasicInfo="prfmncBasicInfo" />

  <div class="board_info mt_40">
    <h2 class="title1" tabindex="-1" ref="sdflCrynTitle">고형연료제품 반입 현황(톤/월)</h2>
    <div>
      <br-button label="인계량적용" size="xsm" format="tertiary" @click="hndqtyAplcn" />
    </div>
  </div>
  <sb-grid
      id="sdflCrynGrid"
      :dataSource="sdflCrynDataSource"
      :columns="sdflCrynGridColumns"
      :gridattr="sdflCrynGridAttr"
      v-model:grid="sdflCrynGridObject"
      @loaded="sdflCrynGridLoaded"
      :refreshGrid="sdflCrynGridReload"
      @change="changeSdflCryn"
  />

  <h3 class="title1">고형연료제품 처리 현황</h3>
  <h2 class="title2" tabindex="-1" ref="sdflInputTitle">시설별 고형연료제품 투입 및 에너지 생산 현황</h2>
  <sb-grid
      id="sdflInputGrid"
      :dataSource="sdflInputDataSource"
      :columns="sdflInputGridColumns"
      :gridattr="sdflInputGridAttr"
      v-model:grid="sdflInputGridObject"
      @loaded="sdflInputGridLoaded"
      :refreshGrid="sdflInputGridReload"
      @change="changeSdflInput"
  />
  <h5>※ 증기(톤), 온수(Gcal), 전기(MWh)량은 월별 누계값을 입력 하셔야 합니다.</h5>
  <h5>※ 저위발열량은 최근 합격된 품질검사 정보를 자동으로 입력하고 있습니다. 반드시 검토하시고 잘못된 정보인 경우 수정하여 제출 바랍니다.</h5>

  <h2 class="title2">시설별 고형연료제품 투입량 검증 결과</h2>
  <sb-grid
      id="sdflInputVrfcGrid"
      :dataSource="sdflInputVrfcDataSource"
      :columns="sdflInputVrfcGridColumns"
      :gridattr="sdflInputVrfcGridAttr"
      v-model:grid="sdflInputVrfcGridObject"
      @loaded="sdflInputVrfcGridLoaded"
      :refreshGrid="sdflInputVrfcGridReload"
  />
  <!-- <h5>※ 월간시설용량(톤/월) = 각 시설의 일간시설용량(톤/일) * 30</h5> -->

  <h2 class="title2" tabindex="-1" ref="sdflPrcsTitle">고형연료제품 종류별 처리 현황</h2>
  <sb-grid
      id="sdflPrcsGrid"
      :dataSource="sdflPrcsDataSource"
      :columns="sdflPrcsGridColumns"
      :gridattr="sdflPrcsGridAttr"
      v-model:grid="sdflPrcsGridObject"
      @loaded="sdflPrcsGridLoaded"
      :refreshGrid="sdflPrcsGridReload"
      @change="changeSdflPrcs"
  />

  <div class="board_info mt_40">
    <h3 class="title2" tabindex="-1" ref="asstFuelTitle">시설별 보조연료 투입 현황</h3>
    <div>
      <br-button label="행 추가" size="xsm" format="primary line mr_5" @click="addAsstFuelInput" />
      <br-button label="행 삭제" size="xsm" format="point line" @click="deleteAsstFuelInput" />
    </div>
  </div>
  <sb-grid
      id="asstFuelInputGrid"
      :dataSource="asstFuelInputDataSource"
      :columns="asstFuelInputGridColumns"
      :gridattr="asstFuelInputGridAttr"
      v-model:grid="asstFuelInputGridObject"
      @loaded="asstFuelInputGridLoaded"
      :refreshGrid="asstFuelInputGridReload"
      @change="changeAsstFuelInput"
  />
  <h5>※ 보조연료 투입량은 월별 누계값을 입력 하셔야 합니다.</h5>

  <h3 class="title1" tabindex="-1" ref="prdctnTitle">폐자원에너지 생산/이용 현황</h3>
  <sb-grid
      id="prdctnGrid"
      :dataSource="prdctnDataSource"
      :columns="prdctnGridColumns"
      :gridattr="prdctnGridAttr"
      v-model:grid="prdctnGridObject"
      @loaded="prdctnGridLoaded"
      :refreshGrid="prdctnGridReload"
      @change="changePrdctn"
  />

  <h3 class="title1" tabindex="-1" ref="sdflNtslTitle">폐자원에너지 업체별 판매 현황</h3>
  <sb-grid
      id="ntslGrid"
      :dataSource="ntslDataSource"
      :columns="ntslGridColumns"
      :gridattr="ntslGridAttr"
      v-model:grid="ntslGridObject"
      @loaded="ntslGridLoaded"
      :refreshGrid="ntslGridReload"
      @change="changeNtsl"
  />
  <h5>※ 증기(톤), 온수(Gcal), 전기(MWh)량은 월별 누계값을 입력 하셔야 합니다.</h5>

  <common-button-area
      :prfmncBasicInfo="prfmncBasicInfo"
      @save="save"
      @submission="submission"
  />

  <sdfl-use-prcs-prfmnc-chg-dscnt-popup @eblc-aplcn="saveConfirmed" />
</template>

<script>
import SbGrid from "@/components/SbGrid.vue";
import utils from "@/js/utils/utils";
import RjctRsnArea from "@/views/prfmnc-rpt/dtl/components/RjctRsnArea.vue";
import PrfmncMdfcnPrmArea from "@/views/prfmnc-rpt/dtl/components/PrfmncMdfcnPrmArea.vue";
import commonButtonArea from "@/views/prfmnc-rpt/dtl/components/CommonButtonArea.vue";
import { storeSwitch } from "@/js/store/store-switch";
import SdflUsePrcsPrfmncChgDscntPopup from "@/views/prfmnc-rpt/dtl/components/popup/SdflUsePrcsPrfmncChgDscntPopup.vue";

const CODE = {
  PRODUCT: "MBR010",
  ACCEPTANCE_DIFFERENCE_REASON: "COM450", // 인계/인수차이 사유코드
  ASSISTANCE_FUEL_KIND_GROUP: "PFM007", // 보조연료종류그룹코드
  ASSISTANCE_FUEL_KIND: "PFM008", // 보조연료종류코드
  ASSISTANCE_FUEL_UNIT: "PFM009", // 보조연료단위코드
  LOW_CALORIFIC_POWER_UNIT: "PFM010", // 저위발열량단위코드
};
const ROUTE = {
  PERFORMANCE_LIST: "/prfmnc-rpt/PrfmncRpt"
};
const API = {
  USE_PERFORMANCE: "/prfmnc/sdflUse",
  USE_PROCESS_PERFORMANCE_CHANGE_CHECK: "/prfmnc/sdflUse/prcs-chg-chck",
}

export default {
  props: ['prfmncBasicInfo', 'sdflCrynInfo', 'sdflInputInfo', 'sdflPrcsInfo', 'asstFuelInputInfo', 'prdctnInfo' ,'ntslInfo'],
  components: {
    SdflUsePrcsPrfmncChgDscntPopup,
    commonButtonArea,
    RjctRsnArea,
    PrfmncMdfcnPrmArea,
    SbGrid,
  },
  data() {
    return {
      isVaporDiff: true, // 생산/이용 현황(증기-외부판매의 합계) <-> 업체별판매 현황(외부판매량-증기의 합계) 일치여부
      isHtwtrDiff: true, // 생산/이용 현황(온수-외부판매의 합계) <-> 업체별판매 현황(외부판매량-온수의 합계) 일치여부
      isPrvtmDiff: true, // 생산/이용 현황(전기-외부판매의 합계) <-> 업체별판매 현황(외부판매량-전기의 합계) 일치여부

      sdflCrynGridReload: false,
      sdflCrynGridObject: null,
      sdflCrynDataSource: [],
      sdflCrynGridColumns: [],
      sdflCrynGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        editable: {eventType: 'mouseup'},
        excelExport: {
          fileName: '고형연료제품 반입 현황(톤/월).xlsx',
          includeHeader: true,
          adjustCaption: (value) => {
            return value?.toString().includes('<br>') ? value.replace(/<br\s*\/?>/gi, '\n') : value;
          },
        },
        captionHeight: 40
      },
      sdflInputGridReload: false,
      sdflInputGridObject: null,
      sdflInputDataSource: [],
      sdflInputGridColumns: [],
      sdflInputGridAttr: {
        showRowNo: false,
        pageable: false,
        mergeRow: true,
        editable: {eventType: 'mouseup'},
        captionHeight: 40,
        toolBar: [],
      },
      sdflInputVrfcGridReload: false,
      sdflInputVrfcGridObject: null,
      sdflInputVrfcDataSource: [],
      sdflInputVrfcGridColumns: [],
      sdflInputVrfcGridAttr: {
        showRowNo: false,
        pageable: false,
        toolBar: [],
      },
      sdflPrcsGridReload: false,
      sdflPrcsGridObject: null,
      sdflPrcsDataSource: [],
      sdflPrcsGridColumns: [],
      sdflPrcsGridAttr: {
        showRowNo: false,
        pageable: false,
        editable: {eventType: 'mouseup'},
        toolBar: [],
      },
      asstFuelInputGridReload: false,
      asstFuelInputGridObject: null,
      asstFuelInputDataSource: [],
      asstFuelInputGridColumns: [],
      asstFuelInputGridAttr: {
        showRowNo: false,
        checkable: true,
        pageable: false,
        editable: {eventType: 'mouseup'},
        toolBar: [],
        hideDeleted: true,
      },
      prdctnGridReload: false,
      prdctnGridObject: null,
      prdctnDataSource: [],
      prdctnGridColumns: [],
      prdctnGridAttr: {
        showRowNo: false,
        pageable: false,
        editable: {eventType: 'mouseup'},
        toolBar: [],
      },
      ntslGridReload: false,
      ntslGridObject: null,
      ntslDataSource: [],
      ntslGridColumns: [],
      ntslGridAttr: {
        showRowNo: false,
        pageable: false,
        editable: {eventType: 'mouseup'},
        mergeRow: true,
        toolBar: [],
      },
      prdctItemCdList: [],
      hnovAcptnDiffRsnCdList: [],
      fcltList: [],
      asstFuelKndGroupCdList: [],
      asstFuelKndCdList: [],
      asstFuelUnitCdList: [],
      lcpUnitCdList: [],
    }
  },
  computed: {
  },
  watch: {
    sdflCrynInfo() {
      this.initializesdflCryn()
    },
    sdflInputInfo() {
      this.initializeSdflInput();
    },
    sdflPrcsInfo() {
      this.initializeSdflPrcs();
    },
    asstFuelInputInfo() {
      this.initializeAsstFuelInput();
    },
    prdctnInfo() {
      this.initializePrdctn();
    },
    ntslInfo() {
      this.initializeNtsl();
    }
  },
  created() {
    this.initialize();
  },
  mounted() {
  },
  methods: {
    initialize() {
      this.loadPrdctItemCd();
      this.loadHnovAcptnDiffRsnCd();
      this.loadAsstFuelKndGroupCd();
      this.loadAsstFuelKndCd();
      this.loadAsstFuelUnitCd();
      this.loadLcpUnitCd();
    },
    loadPrdctItemCd() {
      const prdctItemCdList = this.$store.getters.getCodeList({ groupCd: CODE.PRODUCT, useYn: 'Y', frstRefVl: "SRF" });
      this.prdctItemCdList = prdctItemCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    loadHnovAcptnDiffRsnCd() {
      const hnovAcptnDiffRsnCdList = this.$store.getters.getCodeList({ groupCd: CODE.ACCEPTANCE_DIFFERENCE_REASON, useYn: 'Y' });
      this.hnovAcptnDiffRsnCdList = hnovAcptnDiffRsnCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    loadAsstFuelKndGroupCd() {
      const asstFuelKndGroupCdList = this.$store.getters.getCodeList({ groupCd: CODE.ASSISTANCE_FUEL_KIND_GROUP, useYn: 'Y' });
      this.asstFuelKndGroupCdList = asstFuelKndGroupCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    loadAsstFuelKndCd() {
      const asstFuelKndCdList = this.$store.getters.getCodeList({ groupCd: CODE.ASSISTANCE_FUEL_KIND, useYn: 'Y' });
      this.asstFuelKndCdList = asstFuelKndCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd,
        frstRefVl: item.frstRefVl,
      }));
    },
    loadAsstFuelUnitCd() {
      const asstFuelUnitCdList = this.$store.getters.getCodeList({ groupCd: CODE.ASSISTANCE_FUEL_UNIT, useYn: 'Y' });
      this.asstFuelUnitCdList = asstFuelUnitCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd,
        frstRefVl: item.frstRefVl,
      }));
    },
    loadLcpUnitCd() {
      const lcpUnitCdList = this.$store.getters.getCodeList({ groupCd: CODE.LOW_CALORIFIC_POWER_UNIT, useYn: 'Y' });
      this.lcpUnitCdList = lcpUnitCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd,
        frstRefVl: item.frstRefVl,
      }));
    },
    initializesdflCryn() {
      this.sdflCrynDataSource = this.sdflCrynInfo.list;
      this.initializeSdflCrynGridColumns();
      this.initializeSdflCrynGridAttr();
      this.sdflCrynGridReload = true;
    },
    initializeSdflInput() {
      this.sdflInputDataSource = this.sdflInputInfo.list;
      this.sdflInputVrfcDataSource = { data: this.sdflInputInfo.vrfcRsltList, schema: { id: 'fcltSqno'}};
      this.initializeSdflInputGridColumns();
      this.initializeSdflInputGridAttr();
      this.initializeSdflInputVrfcGridColumns();
      this.initializeSdflInputVrfcGridAttr();
      this.sdflInputGridReload = true;
      this.sdflInputVrfcGridReload = true;

      this.fcltList = (this.sdflInputInfo.vrfcRsltList || []).map(item => ({
        text: item.fcltNm,
        value: item.fcltSqno
      }));
    },
    initializeSdflPrcs() {
      this.sdflPrcsDataSource = { data: this.sdflPrcsInfo.list, schema: { id: 'prdctItemCd'}};
      this.initializeSdflPrcsGridColumns();
      this.initializeSdflPrcsGridAttr();
      this.sdflPrcsGridReload = true;
    },
    initializeAsstFuelInput() {
      this.asstFuelInputDataSource = this.asstFuelInputInfo.list;
      this.initializeAsstFuelInputGridColumns();
      this.initializeAsstFuelInputGridAttr();
      this.asstFuelInputGridReload = true;
    },
    initializePrdctn() {
      this.prdctnDataSource = this.prdctnInfo.list;
      this.initializePrdctnGridColumns();
      this.initializePrdctnGridAttr();
      this.prdctnGridReload = true;
    },
    initializeNtsl() {
      this.ntslDataSource = this.ntslInfo.list;
      this.initializeNtslGridColumns();
      this.initializeNtslGridAttr();
      this.ntslGridReload = true;
    },
    initializeSdflCrynGridColumns() {
      const list = this.sdflCrynInfo.list;
      const uniquePrdctItemCdList = [...new Set(list.map(item => item.prdctItemCd))];
      const filteredPrdctItemList = this.prdctItemCdList
          .filter(item => uniquePrdctItemCdList.includes(item.value));

      const prdctItemList = filteredPrdctItemList.length === 1
          ? []
          : filteredPrdctItemList.map(item => ({ text: item.text, value: item.value }));

      this.sdflCrynGridColumns = [
        { field: 'bplcId', caption: '사업장아이디', visible: false, editable: false },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        { field: 'ctpvStdgNm', caption: '시·도', width: 100, mergeRow: true, editable: false, total: { headerCss:'grid-header' } },
        { field: 'sggStdgNm', caption: '시·군·구', width: 100, editable: false,
          mergeRow: (data) => data.ctpvStdgCd + "." + data.sggStdgCd,
          total: { headerCss:'grid-header' }
        },
        { field: 'bplcKndNm', caption: '사업장종류', width: 100, editable: false,
          mergeRow: (data) => data.ctpvStdgCd + "." + data.sggStdgCd + "." + data.bplcKndNm,
          total: { headerCss:'grid-header' }
        },
        { field: 'bplcNm', caption: '사업장명', width: 200, part: 'sticky', editable: false, align: 'left', total: { headerCss:'grid-header' } },
        { field: 'bzentyNm', caption: '업체명', width: 200, editable: false, align: 'left', total: { headerCss:'grid-header' } },
        { field: 'prdctItemNm', caption: '구분', width: 120,
          total: {
            aggregates: [],
            header: [
              { template: '합계', align: 'right' },
              ...prdctItemList.map(item => ({ template: item.text, align: 'right' }))
            ],
            headerCss:'grid-header',
          }
        },
        { field: 'realtmPrdctHndqty', caption: '실시간인계량(톤)<br>(A)', width: 100, editable: false,
          type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', colCss: 'grid-disabled',
          total: {
            aggregates: [
              {func: 'sum', require: 'realtmPrdctHndqty'},
              ...prdctItemList.map(item => ({
                func: (items) => items
                    .filter(entry => entry.prdctItemCd === item.value)
                    .reduce((sum, entry) => sum + (entry.realtmPrdctHndqty || 0), 0),
                field: `realtmPrdctHndqty${item.value}`
              }))
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00' },
              ...prdctItemList.map(item => ({
                template: `{{realtmPrdctHndqty${item.value}}}`, format: '#,##0.00'
              }))
            ],
            headerCss:'grid-header',
          },
        },
        ...(this.prfmncBasicInfo.prfmncSttsCd !== 'NEW' ? [{
          field: 'prdctHndqty', caption: '저장된인계량(톤)', width: 100, editable: false,
          type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', colCss: 'grid-disabled',
          total: {
            aggregates: [
              { func: 'sum', require: 'prdctHndqty' },
              ...prdctItemList.map(item => ({
                func: (items) => items
                    .filter(entry => entry.prdctItemCd === item.value)
                    .reduce((sum, entry) => sum + (entry.prdctHndqty || 0), 0),
                field: `prdctHndqty${item.value}`
              }))
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00' },
              ...prdctItemList.map(item => ({
                template: `{{prdctHndqty${item.value}}}`, format: '#,##0.00'
              }))
            ],
            headerCss:'grid-header',
          },
        }] : []),
        { field: 'prdctAcpqty', caption: '인수량(톤)<br>(B)', width: 100,
          type: 'number', dataType: 'number', align: 'right', format: '#,##0.00',
          total: {
            aggregates: [
              {func: 'sum', require: 'prdctAcpqty', nullAs: 0},
              ...prdctItemList.map(item => ({
                func: (items) => items
                    .filter(entry => entry.prdctItemCd === item.value)
                    .reduce((sum, entry) => sum + (entry.prdctAcpqty || 0), 0),
                field: `prdctAcpqty${item.value}`
              }))
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00' },
              ...prdctItemList.map(item => ({
                template: `{{prdctAcpqty${item.value}}}`, format: '#,##0.00'
              }))
            ],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctDscdCrtqty', caption: '폐기반출량(톤)', width: 100,
          type: 'number', dataType: 'number', align: 'right', format: '#,##0.00',
          total: {
            aggregates: [
              {func: 'sum', require: 'prdctDscdCrtqty', nullAs: 0},
              ...prdctItemList.map(item => ({
                func: (items) => items
                    .filter(entry => entry.prdctItemCd === item.value)
                    .reduce((sum, entry) => sum + (entry.prdctDscdCrtqty || 0), 0),
                field: `prdctDscdCrtqty${item.value}`
              }))
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00' },
              ...prdctItemList.map(item => ({
                template: `{{prdctDscdCrtqty${item.value}}}`, format: '#,##0.00'
              }))
            ],
            headerCss:'grid-header',
          },
        },
        { field: 'diff', caption: '차이량(톤)<br>(A - B)', width: 150, editable: false,
          type: 'number', dataType: 'number', align: 'right', format: '#,##0.00',
          total: {
            aggregates: [
              {
                func: (items) => {
                  const totalRealtmPrdctHndqty = this.sanitizeFloatingPointError(items.reduce((sum, item) => sum + (item.realtmPrdctHndqty || 0), 0));
                  const totalPrdctAcpqty = this.sanitizeFloatingPointError(items.reduce((sum, item) => sum + (item.prdctAcpqty || 0), 0));
                  return Math.abs(utils.round2(totalRealtmPrdctHndqty - totalPrdctAcpqty));
                  // const totalPrdctDscdCrtqty = this.sanitizeFloatingPointError(items.reduce((sum, item) => sum + (item.prdctDscdCrtqty || 0), 0));
                  // return utils.round2(totalRealtmPrdctHndqty - totalPrdctAcpqty - totalPrdctDscdCrtqty);
                },
                field: 'diffTotal'
              },
              ...prdctItemList.map(item => ({
                func: (items) => {
                  const filteredItems = items.filter(entry => entry.prdctItemCd === item.value);

                  const totalRealtmPrdctHndqty = filteredItems.reduce((sum, entry) => sum + (entry.realtmPrdctHndqty || 0), 0);
                  const totalPrdctAcpqty = filteredItems.reduce((sum, entry) => sum + (entry.prdctAcpqty || 0), 0);
                  return Math.abs(utils.round2(totalRealtmPrdctHndqty - totalPrdctAcpqty));
                  // const totalPrdctDscdCrtqty = filteredItems.reduce((sum, entry) => sum + (entry.prdctDscdCrtqty || 0), 0);
                  // return utils.round2(totalRealtmPrdctHndqty - totalPrdctAcpqty - totalPrdctDscdCrtqty);
                },
                field: `diff${item.value}`
              }))
            ],
            header: [
              { template: '{{diffTotal}}', format: '#,##0.00' },
              ...prdctItemList.map(item => ({
                template: `{{diff${item.value}}}`, format: '#,##0.00'
              }))
            ],
            headerCss:'grid-header',
          },
          getValue: (value, field, rowItem) => {
            const realtmPrdctHndqty = rowItem.data.realtmPrdctHndqty || 0
            const prdctAcpqty = rowItem.data.prdctAcpqty || 0;
            const difference = Math.abs(utils.round2(realtmPrdctHndqty - prdctAcpqty));
            // const prdctDscdCrtqty = rowItem.data.prdctDscdCrtqty || 0;
            // const difference = utils.round2(realtmPrdctHndqty - prdctAcpqty - prdctDscdCrtqty);

            return difference !== 0 ? difference : '';
          },
          colCss: (data) => {
            const realtmPrdctHndqty = data.realtmPrdctHndqty || 0
            const prdctAcpqty = data.prdctAcpqty || 0;
            // 음수, 양수에 따른 클래스 처리로 인해 절대값처리 x
            const difference = utils.round2(realtmPrdctHndqty - prdctAcpqty);
            // const prdctDscdCrtqty = data.prdctDscdCrtqty || 0;
            // const difference = utils.round2(realtmPrdctHndqty - prdctAcpqty - prdctDscdCrtqty);

            if (difference !== 0) {
              if(difference < 0){
                return 'grid-danger';
              }else{
                return 'grid-warning';
              }
            } else {
              return 'grid-disabled';
            }
          }
        },
        { field: 'hnovAcptnDiffRsnCd', caption: '비고(사유)', width: 184, align: 'left',
          type: 'combo',
          items: this.hnovAcptnDiffRsnCdList,
          comboLabel: 'text',
          comboValue: 'value',
          total: { headerCss:'grid-header' },
        },
        { field: 'hnovAcptnDiffRmrkCn', caption: '기타내용', width: 200, align: 'left', total: { headerCss:'grid-header' } },
      ]
    },
    initializeSdflCrynGridAttr() {
      const itemCount = this.sdflCrynInfo.list.length;
      let uniqueItemCount = new Set(this.sdflCrynInfo.list.map(item => item.prdctItemCd)).size;
      uniqueItemCount = uniqueItemCount === 1 ? 0 : uniqueItemCount;

      this.sdflCrynGridAttr.height = Math.max(((itemCount + uniqueItemCount) * 30) + 150, 150);
    },
    initializeSdflInputGridColumns() {
      this.sdflInputGridColumns = [
        { field: 'fcltSqno', caption: '시설순번', visible: false, editable: false },
        { field: 'fcltNm', caption: '시설명', minWidth: 250, maxWidth: 1000, width:100, unit:"%", mergeRow: true, editable: false, align: 'left', total: { headerCss:'grid-header' } },
        { field: 'prdctItemCd', caption: '제품품목코드', visible: false, editable: false },
        { field: 'prdctItemNm', caption: '고형연료제품<br>종류', width: 150, editable: false,
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          }
        },
        { field: 'prdctInpqty', caption: '고형연료제품<br>투입량(톤)', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          total: {
            aggregates: [{func: 'sum', require: 'prdctInpqty', nullAs: 0}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctLcp', caption: '저위발열량<br>(kcal/kg)', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 130,
          editable : (data) => { return (data.prdctInpqty || 0) > 0 },
          total: {
            aggregates: [
              { func: (items) => {
                  const filteredItems = items.filter(item => item.prdctLcp > 0);
                  const sumPrdctInpqty = filteredItems.reduce((total, item) => total + (item.prdctInpqty || 0), 0);
                  const sumPrdqtyLcp = filteredItems.reduce((total, item) => {
                    const prdctInpqty = item.prdctInpqty || 0;
                    const prdctLcp = item.prdctLcp || 0;
                    return total + (prdctInpqty * prdctLcp);
                  }, 0);

                  return sumPrdctInpqty > 0 ? utils.round2(sumPrdqtyLcp / sumPrdctInpqty) : 0;
                },
                field: 'prdctLcpAvg'
              }
            ],
            header: [{ template: '{{prdctLcpAvg}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { caption: '에너지생산량',
          columns: [
            { field: 'vaporPrdqty', caption: '증기(톤)', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 130,
              captionCss:'gridh-bg-vapor',
              editable : (data) => { return (data.prdctInpqty || 0) > 0 },
              total: {
                aggregates: [{func: 'sum', require: 'vaporPrdqty', nullAs: 0}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'vaporTp', caption: '증기온도(℃)', type: 'number', dataType: 'number', align: 'right',
              format: '#,##0.00', width: 100, captionCss:'gridh-bg-vapor',
              editable : (data) => { return (data.prdctInpqty || 0) > 0 },
              total: { headerCss:'grid-header' },
            },
            { field: 'vaporPrssVl', caption: '증기압력<br>(kgf/cm2)', type: 'number', dataType: 'number', align: 'right',
              format: '#,##0.00', width: 100, captionCss:'gridh-bg-vapor',
              editable : (data) => { return (data.prdctInpqty || 0) > 0 },
              total: { headerCss:'grid-header' },
            },
            { field: 'htwtrPrdqty', caption: '온수(Gcal)', type: 'number', dataType: 'number', align: 'right',
              format: '#,##0.00', width: 130, captionCss:'gridh-bg-hot-water',
              editable : (data) => { return (data.prdctInpqty || 0) > 0 },
              total: {
                aggregates: [{func: 'sum', require: 'htwtrPrdqty', nullAs: 0}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
            { field: 'prvtmPrdqty', caption: '전기(MWh)', type: 'number', dataType: 'number', align: 'right',
              format: '#,##0.00', width: 130, captionCss:'gridh-bg-electricity',
              editable : (data) => { return (data.prdctInpqty || 0) > 0 },
              total: {
                aggregates: [{func: 'sum', require: 'prvtmPrdqty', nullAs: 0}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
            },
          ]
        },
      ];
    },
    initializeSdflInputGridAttr() {
      const itemCount = this.sdflInputInfo.list.length;

      this.sdflInputGridAttr.height = Math.max((itemCount  * 30) + 150, 150);
    },
    initializeSdflInputVrfcGridColumns() {
      this.sdflInputVrfcGridColumns = [
        { field: 'fcltSqno', caption: '시설순번', visible: false },
        { field: 'fcltNm', caption: '시설명', minWidth: 100, width: 100, unit: '%', maxWidth: 1000, align: 'left',
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          }
        },
        { field: 'prdctInpqtySum', caption: '고형연료제품 투입량(톤)', dataType: 'number', align: 'right', format: '#,##0.00',
          width: 150, colCss: 'grid-disabled',
          total: {
            aggregates: [{func: 'sum', require: 'prdctInpqtySum'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { caption: '투입량 검증', captionCss:'gridh-bg-validation',
          columns: [
            { field: 'mthlyFcltCap', caption: '월간시설용량(톤/월)', dataType: 'number', align: 'right', format: '#,##0.00', captionCss:'gridh-bg-validation',
              width: 150, colCss: 'grid-disabled',
              total: {
                aggregates: [{func: 'sum', require: 'mthlyFcltCap'}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
              visible: false,
            },
            { field: 'mthlyexcsCap', caption: '초과용량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150, captionCss:'gridh-bg-validation',
              total: {
                aggregates: [{
                  func: (items) => {
                    return this.sanitizeFloatingPointError(items.reduce((sum, item) => {
                      const difference = (item.prdctInpqtySum || 0) - (item.mthlyFcltCap || 0);
                      return sum + (difference > 0 ? difference : 0);
                    }, 0));
                  },
                  field: 'excsCapTotal'
                }],
                header: [{ template: '{{excsCapTotal}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
              getValue: (value, field, rowItem) => {
                const prdctInpqtySum = rowItem.data.prdctInpqtySum || 0
                const mthlyFcltCap = rowItem.data.mthlyFcltCap || 0;
                const difference = prdctInpqtySum - mthlyFcltCap;
                return difference > 0 ? difference : '';
              },
              colCss: (data) => {
                const prdctInpqtySum = data.prdctInpqtySum || 0;
                const mthlyFcltCap = data.mthlyFcltCap || 0;

                if (prdctInpqtySum > mthlyFcltCap) {
                  return 'grid-danger';
                } else {
                  return 'grid-disabled';
                }
              },
              visible: false,
            },
            { field: 'vrfcRslt', caption: '검증결과', width: 150, captionCss:'gridh-bg-validation',
              total: { headerCss:'grid-header' },
              getValue: (value, field, rowItem) => {
                const prdctInpqtySum = rowItem.data.prdctInpqtySum || 0
                const mthlyFcltCap = rowItem.data.mthlyFcltCap || 0;
                return prdctInpqtySum > mthlyFcltCap ? "초과" : "적합";
              },
              colCss: (data) => {
                const prdctInpqtySum = data.prdctInpqtySum || 0;
                const mthlyFcltCap = data.mthlyFcltCap || 0;

                if (prdctInpqtySum > mthlyFcltCap) {
                  return 'grid-danger';
                } else {
                  return 'grid-good';
                }
              },
              visible: false,
            }
          ]
        }
      ];
    },
    initializeSdflInputVrfcGridAttr() {
      const itemCount = this.sdflInputInfo.vrfcRsltList.length;

      this.sdflInputVrfcGridAttr.height = Math.max((itemCount  * 30) + 120, 150);
    },
    initializeSdflPrcsGridColumns() {
      this.sdflPrcsGridColumns = [
        { field: 'prdctItemCd', caption: '제품품목코드', visible: false },
        { field: 'prdctItemNm', caption: '고형연료제품 종류', minWidth: 100, maxWidth: 1000, width: 100, unit: '%',
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          }
        },
        { field: 'prdctCrfqty', caption: '이월량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          editable: false, colCss: 'grid-disabled',
          total: {
            aggregates: [{func: 'sum', require: 'prdctCrfqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctSumAcpqty', caption: '인수량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          editable: false, colCss: 'grid-disabled',
          total: {
            aggregates: [{func: 'sum', require: 'prdctSumAcpqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctSumInpqty', caption: '투입량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          editable: false, colCss: 'grid-disabled',
          total: {
            aggregates: [{func: 'sum', require: 'prdctSumInpqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctRsdqty', caption: '잔재물(톤)', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          total: {
            aggregates: [{func: 'sum', require: 'prdctRsdqty', nullAs: 0}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctRmnqty', caption: '잔량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          editable: false,
          total: {
            aggregates: [{
              func: (items) => {
                const totalPrdctCrfqty    = this.sanitizeFloatingPointError(items.reduce((sum, item) => sum + (item.prdctCrfqty || 0), 0));
                const totalPrdctSumAcpqty = this.sanitizeFloatingPointError(items.reduce((sum, item) => sum + (item.prdctSumAcpqty || 0), 0));
                const totalPrdctSumInpqty = this.sanitizeFloatingPointError(items.reduce((sum, item) => sum + (item.prdctSumInpqty || 0), 0));
                const totalPrdctRsdqty    = this.sanitizeFloatingPointError(items.reduce((sum, item) => sum + (item.prdctRsdqty || 0), 0));
                const totalPrdctRmnqty    = this.sanitizeFloatingPointError(totalPrdctCrfqty + totalPrdctSumAcpqty - totalPrdctSumInpqty - totalPrdctRsdqty);
                return utils.round2(totalPrdctRmnqty);
              },
              field: 'prdctRmnqtyTotal'
            }],
            header: [{ template: '{{prdctRmnqtyTotal}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
          getValue: (value, field, rowItem) => {
            const prdctCrfqty = rowItem.data.prdctCrfqty || 0;
            const prdctSumAcpqty = rowItem.data.prdctSumAcpqty || 0
            const prdctSumInpqty = rowItem.data.prdctSumInpqty || 0;
            const prdctRsdqty = rowItem.data.prdctRsdqty || 0;
            const prdctRmnqty = prdctCrfqty + prdctSumAcpqty - prdctSumInpqty - prdctRsdqty;
            return utils.round2(prdctRmnqty)
          },
          colCss: (data) => {
            const prdctCrfqty = data.prdctCrfqty || 0;
            const prdctSumAcpqty = data.prdctSumAcpqty || 0
            const prdctSumInpqty = data.prdctSumInpqty || 0;
            const prdctRsdqty = data.prdctRsdqty || 0;
            const prdctRmnqty = utils.round2(prdctCrfqty + prdctSumAcpqty - prdctSumInpqty - prdctRsdqty);

            const epsilon = 1e-10; // 허용 오차 설정
            if (prdctRmnqty < -epsilon) {
              return 'grid-danger';
            } else {
              return 'grid-disabled';
            }
          }
        },
      ]
    },
    initializeSdflPrcsGridAttr() {
      const itemCount = this.sdflPrcsInfo.list.length;

      this.sdflPrcsGridAttr.height = Math.max((itemCount  * 30) + 90, 150);
    },
    initializeAsstFuelInputGridColumns() {
      this.asstFuelInputGridColumns = [
        { field: 'fcltSqno', caption: '시설명', minWidth: 100, width: 100, unit: '%', align: 'left',
          type: 'combo',
          items: this.fcltList,
          comboLabel: 'text',
          comboValue: 'value',
        },
        { caption: '보조연료 정보',
          columns: [
            { field: 'asstFuelKndGroupCd', caption: '연료구분', width: 150,
              type: 'combo',
              items: this.asstFuelKndGroupCdList,
              comboLabel: 'text',
              comboValue: 'value',
            },
            { field: 'asstFuelKndCd', caption: '연료종류', width: 150,
              type: 'combo',
              items: this.asstFuelKndCdList,
              comboLabel: 'text',
              comboValue: 'value',
              master: 'asstFuelKndGroupCd',
              masterField: 'frstRefVl',
              useFilter: true,
            },
            { field: 'asstFuelKndCn', caption: '기타종류명', width: 150,
              editable: (rowData) => rowData.asstFuelKndGroupCd === '99',
              getValue: (value, field, rowItem) => {
                return rowItem.data.asstFuelKndGroupCd === '99' ? value : ''
              },
              setValue: (value, field, rowItem) => {
                return rowItem.data.asstFuelKndGroupCd === '99' ? value : ''
              },
              colCss: (data) => {
                return data.asstFuelKndGroupCd === '99' ? '' : 'grid-disabled'
              }
            },
            { field: 'asstFuelInpqty', caption: '연료투입량', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150 },
            { field: 'asstFuelUnitCd', caption: '연료단위', width: 150,
              type: 'combo',
              items: this.asstFuelUnitCdList,
              comboLabel: 'text',
              comboValue: 'value',
              master: 'asstFuelKndGroupCd',
              masterField: 'frstRefVl',
              useFilter: true,
            },
            { field: 'asstFuelLcp', caption: '저위발열량', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150 },
            { field: 'lcpUnitCd', caption: '저위발열량 단위', width: 150,
              type: 'combo',
              items: this.lcpUnitCdList,
              comboLabel: 'text',
              comboValue: 'value',
              master: 'asstFuelUnitCd',
              masterField: 'frstRefVl',
              useFilter: true,
            },
          ]
        },
      ]
    },
    initializeAsstFuelInputGridAttr() {
      const itemCount = this.asstFuelInputInfo.list.length;

      this.asstFuelInputGridAttr.height = Math.max((itemCount  * 30) + 100, 235);
    },
    initializePrdctnGridColumns() {
      this.prdctnGridColumns = [
        { field: 'fcltSqno', caption: '시설순번', visible: false , editable: false },
        { field: 'fcltNm', caption: '시설명', part: 'sticky', width: 200, editable: false, align: 'left',
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          }
        },
        { caption: '에너지 이용 정보',
          columns: [
            { caption: '증기(톤)', captionCss:'gridh-bg-vapor',
              columns: [
                { caption: '자체이용', captionCss:'gridh-bg-vapor',
                  columns: [
                    { field: 'stUsgVaporUsqty', caption: '스팀이용(톤)', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
                      captionCss:'gridh-bg-vapor',
                      total: {
                        aggregates: [{func: 'sum', require: 'stUsgVaporUsqty', nullAs: 0}],
                        header: [{ template: '{{sum}}', format: '#,##0.00' }],
                        headerCss:'grid-header',
                      },
                    },
                    { field: 'htwtrUsgVaporUsqty', caption: '온수이용(톤)', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
                      captionCss:'gridh-bg-vapor',
                      total: {
                        aggregates: [{func: 'sum', require: 'htwtrUsgVaporUsqty', nullAs: 0}],
                        header: [{ template: '{{sum}}', format: '#,##0.00' }],
                        headerCss:'grid-header',
                      },
                    },
                    { field: 'elctgnUsgVaporUsqty', caption: '발전이용(톤)', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
                      captionCss:'gridh-bg-vapor',
                      total: {
                        aggregates: [{func: 'sum', require: 'elctgnUsgVaporUsqty', nullAs: 0}],
                        header: [{ template: '{{sum}}', format: '#,##0.00' }],
                        headerCss:'grid-header',
                      },
                    },
                  ]
                },
                { field: 'vaporNsqty', caption: '외부판매', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
                  captionCss:'gridh-bg-vapor',
                  total: {
                    aggregates: [{func: 'sum', require: 'vaporNsqty', nullAs: 0}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                  colCss: () => {
                    return this.isVaporDiff ? '' : 'grid-warning'
                  },
                },
              ]
            },
            { caption: '온수(Gcal)', captionCss:'gridh-bg-hot-water',
              columns: [
                { field: 'htwtrUsqty', caption: '자체이용', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
                  captionCss:'gridh-bg-hot-water',
                  total: {
                    aggregates: [{func: 'sum', require: 'htwtrUsqty', nullAs: 0}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'htwtrNsqty', caption: '외부판매', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
                  captionCss:'gridh-bg-hot-water',
                  total: {
                    aggregates: [{func: 'sum', require: 'htwtrNsqty', nullAs: 0}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                  colCss: () => {
                    return this.isHtwtrDiff ? '' : 'grid-warning'
                  },
                },
              ]
            },
            { caption: '전기(MWh)', captionCss:'gridh-bg-electricity',
              columns: [
                { field: 'prvtmUsqty', caption: '자체이용', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
                  captionCss:'gridh-bg-electricity',
                  total: {
                    aggregates: [{func: 'sum', require: 'prvtmUsqty', nullAs: 0}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                },
                { field: 'prvtmNsqty', caption: '외부판매', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00',
                  width: 150, captionCss:'gridh-bg-electricity',
                  total: {
                    aggregates: [{func: 'sum', require: 'prvtmNsqty', nullAs: 0}],
                    header: [{ template: '{{sum}}', format: '#,##0.00' }],
                    headerCss:'grid-header',
                  },
                  colCss: () => {
                    return this.isPrvtmDiff ? '' : 'grid-warning'
                  },
                },
              ]
            }
          ]
        }
      ]
    },
    initializePrdctnGridAttr() {
      const itemCount = this.prdctnInfo.list.length;

      this.prdctnGridAttr.height = Math.max((itemCount  * 30) + 200, 250);
    },
    initializeNtslGridColumns() {
      this.ntslGridColumns = [
        { field: 'eneBzentySn', caption: '에너지공급업체일련번호', visible: false, editable: false },
        { field: 'useNtslSqno', caption: '사용판매순번', visible: false, editable: false },
        { field: 'ctpvStdgNm', caption: '시·도', width: 120, mergeRow: true, editable: false, total: { headerCss:'grid-header' } },
        { field: 'sggStdgNm', caption: '시·군·구', width: 120, mergeRow: true, editable: false, total: { headerCss:'grid-header' } },
        { field: 'bzentyNm', caption: '판매업체명', minWidth:100, maxWidth: 1000, width: 100, unit: '%', editable: false, align: 'left',
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          }
        },
        { caption: '외부판매량',
          columns: [
            { field: 'vaporNsqty', caption: '증기(톤)', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
              captionCss:'gridh-bg-vapor',
              total: {
                aggregates: [{func: 'sum', require: 'vaporNsqty', nullAs: 0}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
              colCss: () => {
                return this.isVaporDiff ? '' : 'grid-warning'
              },
            },
            { field: 'htwtrNsqty', caption: '온수(Gcal)', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
              captionCss:'gridh-bg-hot-water',
              total: {
                aggregates: [{func: 'sum', require: 'htwtrNsqty', nullAs: 0}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
              colCss: () => {
                return this.isHtwtrDiff ? '' : 'grid-warning'
              },
            },
            { field: 'prvtmNsqty', caption: '전기(MWh)', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
              captionCss:'gridh-bg-electricity',
              total: {
                aggregates: [{func: 'sum', require: 'prvtmNsqty', nullAs: 0}],
                header: [{ template: '{{sum}}', format: '#,##0.00' }],
                headerCss:'grid-header',
              },
              colCss: () => {
                return this.isPrvtmDiff ? '' : 'grid-warning'
              },
            },
          ]
        }
      ]
    },
    initializeNtslGridAttr() {
      const itemCount = this.ntslInfo.list.length;

      this.ntslGridAttr.height = Math.max((itemCount  * 30) + 120, 150);
    },
    sdflCrynGridLoaded() {
      this.sdflCrynGridReload = false;
    },
    sdflInputGridLoaded() {
      this.sdflInputGridReload = false;
    },
    sdflInputVrfcGridLoaded() {
      this.sdflInputVrfcGridReload = false;
    },
    sdflPrcsGridLoaded() {
      this.sdflPrcsGridReload = false;
    },
    asstFuelInputGridLoaded() {
      this.asstFuelInputGridReload = false;
    },
    prdctnGridLoaded() {
      this.prdctnGridReload = false;
    },
    ntslGridLoaded() {
      this.ntslGridReload = false;
    },
    hndqtyAplcn() {
      const data = window.SBGrid3.getAllItems(this.sdflCrynGridObject);

      const filteredData = data
          .filter(item => item.realtmPrdctHndqty && item.realtmPrdctHndqty !== 0)
          .map(item => ({
            key: item._key_,
            field: 'prdctAcpqty',
            value: item.realtmPrdctHndqty
          }));

      if (filteredData.length === 0) {
        alert("인계량을 적용할 데이터가 없습니다.");
        return;
      }

      window.SBGrid3.setValues(this.sdflCrynGridObject, filteredData);
    },
    changeSdflCryn(values) {
      values.forEach(item => {
        if (item.field === 'prdctAcpqty') {
          //2025.03.17 null 인경우 0처리
          const data = window.SBGrid3.getRowData(this.sdflCrynGridObject, item.key);
          if(!data.prdctAcpqty){
            data.prdctAcpqty = 0;
          }

          this.setPrdctSumAcpqty(item.key);
        }

        if (item.field === 'prdctDscdCrtqty') {
          //2025.03.17 null 인경우 0처리
          const data = window.SBGrid3.getRowData(this.sdflCrynGridObject, item.key);
          if(!data.prdctDscdCrtqty){
            data.prdctDscdCrtqty = 0;
          }
        }
      });
    },
    changeSdflInput(values) {console.log(values);
      values.forEach(item => {
        if (item.field === 'prdctInpqty') {
          //2025.03.17 null 인경우 0처리
          const data = window.SBGrid3.getRowData(this.sdflInputGridObject, item.key);
          if(!data.prdctInpqty){
            data.prdctInpqty = 0;
          }

          this.setPrdctInpqty(item.key);
        }

        if (item.field === 'prdctLcp') {
          //2025.03.17 null 인경우 0처리
          const data = window.SBGrid3.getRowData(this.sdflInputGridObject, item.key);
          if(!data.prdctLcp){
            data.prdctLcp = 0;
          }
        }
        //2025.03.17 에너지 생산량 부분은 change이벤트가 발생하지 않아서 일단 주석 처리
        if (item.field === 'vaporPrdqty') {
          //2025.03.17 null 인경우 0처리
          const data = window.SBGrid3.getRowData(this.sdflInputGridObject, item.key);
          console.log(data.vaporPrdqty);
          if(!data.vaporPrdqty){
            data.vaporPrdqty = 0;
          }
        }
        if (item.field === 'vaporTp') {
          //2025.03.17 null 인경우 0처리
          const data = window.SBGrid3.getRowData(this.sdflInputGridObject, item.key);
          if(!data.vaporTp){
            data.vaporTp = 0;
          }
        }
        if (item.field === 'vaporPrssVl') {
          //2025.03.17 null 인경우 0처리
          const data = window.SBGrid3.getRowData(this.sdflInputGridObject, item.key);
          if(!data.vaporPrssVl){
            data.vaporPrssVl = 0;
          }
        }
        if (item.field === 'htwtrPrdqty') {
          //2025.03.17 null 인경우 0처리
          const data = window.SBGrid3.getRowData(this.sdflInputGridObject, item.key);
          if(!data.htwtrPrdqty){
            data.htwtrPrdqty = 0;
          }
        }
        if (item.field === 'prvtmPrdqty') {
          //2025.03.17 null 인경우 0처리
          const data = window.SBGrid3.getRowData(this.sdflInputGridObject, item.key);
          if(!data.prvtmPrdqty){
            data.prvtmPrdqty = 0;
          }
        }
      });
    },
    changeSdflPrcs(values){
      values.forEach(item => {
        if (item.field === 'prdctRsdqty') {
          //2025.03.17 null 인경우 0처리
          const data = window.SBGrid3.getRowData(this.sdflPrcsGridObject, item.key);
          if(!data.prdctRsdqty){
            data.prdctRsdqty = 0;
          }
        }
      });
    },
    changeAsstFuelInput(values) {
      values.forEach(item => {
        if (item.field === 'asstFuelKndGroupCd') {
          const matchingItems = this.asstFuelKndCdList.filter(asstItem => asstItem.frstRefVl === item.value)
          const matchingCount = matchingItems.length;
          if (matchingCount === 1) {
            window.SBGrid3.setValue(this.asstFuelInputGridObject, item.key, matchingItems[0].value, 'asstFuelKndCd');
          } else {
            window.SBGrid3.setValue(this.asstFuelInputGridObject, item.key, "", 'asstFuelKndCd');
          }

          const matchingUnitCdItems = this.asstFuelUnitCdList.filter(asstItem => asstItem.frstRefVl === item.value)
          const matchingUnitCdCount = matchingUnitCdItems.length;
          if (matchingUnitCdCount === 1) {
            window.SBGrid3.setValue(this.asstFuelInputGridObject, item.key, matchingUnitCdItems[0].value, 'asstFuelUnitCd');
          } else {
            window.SBGrid3.setValue(this.asstFuelInputGridObject, item.key, "", 'asstFuelUnitCd');
          }
        } else if (item.field === 'asstFuelUnitCd') {
          const matchingItems = this.lcpUnitCdList.filter(asstItem => asstItem.frstRefVl === item.value)
          const matchingCount = matchingItems.length;
          if (matchingCount === 1) {
            window.SBGrid3.setValue(this.asstFuelInputGridObject, item.key, matchingItems[0].value, 'lcpUnitCd');
          } else {
            window.SBGrid3.setValue(this.asstFuelInputGridObject, item.key, "", 'lcpUnitCd');
          }
        }

        if (item.field === 'asstFuelInpqty') {
          //2025.03.17 null 인경우 0처리
          const data = window.SBGrid3.getRowData(this.asstFuelInputGridObject, item.key);
          if(!data.asstFuelInpqty){
            data.asstFuelInpqty = 0;
          }
        }
        if (item.field === 'asstFuelLcp') {
          //2025.03.17 null 인경우 0처리
          const data = window.SBGrid3.getRowData(this.asstFuelInputGridObject, item.key);
          if(!data.asstFuelLcp){
            data.asstFuelLcp = 0;
          }
        }
      });
    },
    setPrdctInpqty(key) {
      const rowData = window.SBGrid3.getRowData(this.sdflInputGridObject, key);
      const fcltSqno = rowData.fcltSqno;
      const prdctItemCd = rowData.prdctItemCd;

      const allItems = window.SBGrid3.getAllItems(this.sdflInputGridObject);
      const prdctInpqtySum = this.sanitizeFloatingPointError(allItems
          .filter(item => item.fcltSqno === fcltSqno)
          .reduce((sum, item) => sum + (item.prdctInpqty || 0), 0));
      const prdctSumInpqty = this.sanitizeFloatingPointError(allItems
          .filter(item => item.prdctItemCd === prdctItemCd)
          .reduce((sum, item) => sum + (item.prdctInpqty || 0), 0));

      window.SBGrid3.setValue(this.sdflInputVrfcGridObject, fcltSqno, prdctInpqtySum, 'prdctInpqtySum');
      window.SBGrid3.setValue(this.sdflPrcsGridObject, prdctItemCd, prdctSumInpqty, 'prdctSumInpqty');
    },
    setPrdctSumAcpqty(key) {
      const rowData = window.SBGrid3.getRowData(this.sdflCrynGridObject, key);
      const prdctItemCd = rowData.prdctItemCd;

      const allItems = window.SBGrid3.getAllItems(this.sdflCrynGridObject);
      const prdctSumAcpqty = this.sanitizeFloatingPointError(allItems
          .filter(item => item.prdctItemCd === prdctItemCd)
          .reduce((sum, item) => sum + (item.prdctAcpqty || 0), 0));

      window.SBGrid3.setValue(this.sdflPrcsGridObject, prdctItemCd, prdctSumAcpqty, 'prdctSumAcpqty');
    },
    addAsstFuelInput() {
      const addRowData = {
        asstFuelInpqty: 0,
        asstFuelLcp: 0,
      };

      if (this.fcltList.length === 1) {
        addRowData.fcltSqno = this.fcltList[0].value;
      }

      window.SBGrid3.addRow(this.asstFuelInputGridObject, '', addRowData);
    },
    deleteAsstFuelInput() {
      const deleteAsstFuelInputKeys = window.SBGrid3.getCheckedKeys(this.asstFuelInputGridObject);

      if (deleteAsstFuelInputKeys.length === 0) {
        alert("선택된 보조연료 투입 정보가 없습니다.");
        return;
      }

      window.SBGrid3.deleteRows(this.asstFuelInputGridObject, deleteAsstFuelInputKeys);
    },
    toList() {
      this.$router.push(ROUTE.PERFORMANCE_LIST);
    },
    async save() {
      if (!confirm("저장하시겠습니까?")) {
        return;
      }

      const validationMessage = this.validate(true);
      let prfmncSttsCd = "IPG"; // 기본 상태 코드

      if (validationMessage) {
        alert(validationMessage);
        return;
      } else {
        if (confirm("필수입력 조건이 만족하여 제출가능한상태입니다.\n제출하시겠습니까?")) {
          prfmncSttsCd = "SUB";
        }
      }

      try {
        const result = await this.checkUsePrcsPrfmc(prfmncSttsCd);

        if (result.change) {
          storeSwitch.on('SdflUsePrcsPrfmncChgDscntPopup', result.list);
          return;
        }

        this.saveConfirmed(prfmncSttsCd);

      } catch (e) {
        alert("오류가 발생했습니다.");
      }
    },
    async submission() {
      if (!confirm("제출하시겠습니까?")) {
        return;
      }

      const validationMessage = this.validate(true);
      if (validationMessage) {
        alert(validationMessage);
        return;
      }

      try {
        const result = await this.checkUsePrcsPrfmc("SUB");

        if (result.change) {
          storeSwitch.on('SdflUsePrcsPrfmncChgDscntPopup', result.list);
          return;
        }

        this.saveConfirmed("SUB");

      } catch (e) {
        alert("오류가 발생했습니다.");
      }
    },
    validate(focusOnError = false) {
      const allSdflCrynItems = window.SBGrid3.getAllItems(this.sdflCrynGridObject);
      const totalPrdctAcpqty = this.sanitizeFloatingPointError(allSdflCrynItems.reduce((sum, item) => { return sum + (item.prdctAcpqty || 0); }, 0));

      // if (!totalPrdctAcpqty) {
      // 2025.02.03 0값도 입력되도록 수정(미가동 시설인 경우에도 실적등록하는 케이스가 있음)
      if (totalPrdctAcpqty == null) {
        if (focusOnError) {
          this.$refs.sdflCrynTitle.focus();
        }
        return "고형연료제품 반입 정보를 입력해 주세요.";
      }

      const allSdflInputItems = window.SBGrid3.getAllItems(this.sdflInputGridObject);
      const totalPrdctInpqty = this.sanitizeFloatingPointError(allSdflInputItems.reduce((sum, item) => { return sum + (item.prdctInpqty || 0); }, 0));

      // if (!totalPrdctInpqty) {
      // 2025.02.03 0값도 입력되도록 수정(미가동 시설인 경우에도 실적등록하는 케이스가 있음)
      if (totalPrdctInpqty == null) {
        if (focusOnError) {
          this.$refs.sdflInputTitle.focus();
        }
        return "고형연료제품 투입 정보를 입력해 주세요.";
      }

      const totalEnePrdqty = this.sanitizeFloatingPointError(allSdflInputItems.reduce((sum, item) => {
        return sum + (item.vaporPrdqty || 0) + (item.htwtrPrdqty || 0) + (item.prvtmPrdqty || 0);
      }, 0));

      // if (!totalEnePrdqty) {
      // 2025.02.03 0값도 입력되도록 수정(미가동 시설인 경우에도 실적등록하는 케이스가 있음)
      if (totalEnePrdqty == null) {
        if (focusOnError) {
          this.$refs.sdflInputTitle.focus();
        }
        return "에너지 생산량 정보를 입력해 주세요.";
      }

      //2025.02.10 연간 시설 용량의 30%를 초과여부 체크
      const checkFcltCap = utils.round2(this.sdflInputInfo.tAnlFcltCap * 1.3);  //연간시설용량(130%)
      const sumOfInqty = this.sanitizeFloatingPointError(totalPrdctInpqty + this.sdflInputInfo.sumOfprdInputqtyByPrv);  //해당년도 1월부터 전월까지의 투입량 합계와 입력한 투입량의 합계
      if (sumOfInqty > checkFcltCap) {
        if (focusOnError) {
          this.$refs.sdflInputTitle.focus();
        }
        return `총 투입량(${utils.formatNumberWithComma(sumOfInqty)})이 연간 시설 용량의 30%(${utils.formatNumberWithComma(checkFcltCap)})를 초과하였습니다.`;
      }

      const allPrcsItems = window.SBGrid3.getAllItems(this.sdflPrcsGridObject);
      for (const item of allPrcsItems) {
        // 각 항목별 잔여량 계산 및 확인
        const value = (item.prdctCrfqty || 0) + (item.prdctSumAcpqty || 0) - (item.prdctSumInpqty || 0) - (item.prdctRsdqty || 0);
        const prdctSumRmnqty = utils.round2(value);
        if (prdctSumRmnqty < 0) {
          if (focusOnError) {
            this.$refs.sdflPrcsTitle.focus();
            const rowItem= window.SBGrid3.getRow(this.sdflPrcsGridObject, item._key_);
            const column = window.SBGrid3.getColumnByField(this.sdflPrcsGridObject, 'prdctItemNm');

            window.SBGrid3.moveFocus(this.sdflPrcsGridObject, rowItem, column);
          }

          return "투입량(+잔재물)이 인수량(+이월량)보다 초과할 수 없습니다.";
        }
      }

      const allPrdctnItems = window.SBGrid3.getAllItems(this.prdctnGridObject);
      const allNtslItems = window.SBGrid3.getAllItems(this.ntslGridObject);

      for (const item of allPrdctnItems) {
        const vaporSumPrdqty = this.sanitizeFloatingPointError(allSdflInputItems
            .filter(sdflItem => sdflItem.fcltSqno === item.fcltSqno)
            .reduce((sum, item) => sum + (item.vaporPrdqty || 0), 0)); // 증기 생산량

        const totalVaporUsage = (item.stUsgVaporUsqty || 0) + (item.htwtrUsgVaporUsqty || 0) + (item.elctgnUsgVaporUsqty || 0) + (item.vaporNsqty || 0);

        if (vaporSumPrdqty < totalVaporUsage) {
          if (focusOnError) {
            this.$refs.prdctnTitle.focus();
            const rowItem= window.SBGrid3.getRow(this.prdctnGridObject, item._key_);
            const column = window.SBGrid3.getColumnByField(this.prdctnGridObject, 'fcltNm');

            window.SBGrid3.moveFocus(this.prdctnGridObject, rowItem, column);
          }
          return "증기 이용정보는 증기 생산량보다 초과할 수 없습니다.";
        }

        const htwtrSumPrdqty = this.sanitizeFloatingPointError(allSdflInputItems
            .filter(sdflItem => sdflItem.fcltSqno === item.fcltSqno)
            .reduce((sum, item) => sum + (item.htwtrPrdqty || 0), 0)); // 온수 생산량
        const totalHtwtrUsage = (item.htwtrUsqty || 0) + (item.htwtrNsqty || 0);

        if (htwtrSumPrdqty < totalHtwtrUsage) {
          if (focusOnError) {
            this.$refs.prdctnTitle.focus();
            const rowItem= window.SBGrid3.getRow(this.prdctnGridObject, item._key_);
            const column = window.SBGrid3.getColumnByField(this.prdctnGridObject, 'fcltNm');

            window.SBGrid3.moveFocus(this.prdctnGridObject, rowItem, column);
          }
          return "온수 이용정보는 온수 생산량보다 초과할 수 없습니다.";
        }

        const prvtmSumPrdqty = this.sanitizeFloatingPointError(allSdflInputItems
            .filter(sdflItem => sdflItem.fcltSqno === item.fcltSqno)
            .reduce((sum, item) => sum + (item.prvtmPrdqty || 0), 0)); // 전기 생산량
        const totalPrvtmUsage = (item.prvtmUsqty || 0) + (item.prvtmNsqty || 0);

        if (prvtmSumPrdqty < totalPrvtmUsage) {
          if (focusOnError) {
            this.$refs.prdctnTitle.focus();
            const rowItem= window.SBGrid3.getRow(this.prdctnGridObject, item._key_);
            const column = window.SBGrid3.getColumnByField(this.prdctnGridObject, 'fcltNm');

            window.SBGrid3.moveFocus(this.prdctnGridObject, rowItem, column);
          }
          return "전기 이용정보는 전기 생산량보다 초과할 수 없습니다.";
        }
      }

      const prdctnVaporSumNsqty = this.sanitizeFloatingPointError(allPrdctnItems.reduce((sum, item) => { return sum + (item.vaporNsqty || 0); }, 0));
      const nstlVaporSumNsqty = this.sanitizeFloatingPointError(allNtslItems.reduce((sum, item) => { return sum + (item.vaporNsqty || 0); }, 0));
      if (prdctnVaporSumNsqty !== nstlVaporSumNsqty) {
        if (focusOnError) {
          this.isVaporDiff = false;
          this.$refs.sdflNtslTitle.focus();

          if(allPrdctnItems?.length || 0 > 0){
            const rowItem = window.SBGrid3.getRowByIndex(this.prdctnGridObject, 0);
            const column = window.SBGrid3.getColumnByField(this.prdctnGridObject, 'vaporNsqty');
            window.SBGrid3.moveFocus(this.prdctnGridObject, rowItem, column);
          }

          if(allNtslItems?.length || 0 > 0){
            const rowItem = window.SBGrid3.getRowByIndex(this.ntslGridObject, 0);
            const column = window.SBGrid3.getColumnByField(this.ntslGridObject, 'vaporNsqty');
            window.SBGrid3.moveFocus(this.ntslGridObject, rowItem, column);
          }
        }
        return "시설별 증기 판매량의 합계와 판매업체별 증기 판매량 합계가 일치하지 않습니다.";
      }

      const prdctnHtwtrSumNsqty = this.sanitizeFloatingPointError(allPrdctnItems.reduce((sum, item) => { return sum + (item.htwtrNsqty || 0); }, 0));
      const ntslHtwtrSumNsqty = this.sanitizeFloatingPointError(allNtslItems.reduce((sum, item) => { return sum + (item.htwtrNsqty || 0); }, 0));
      if (prdctnHtwtrSumNsqty !== ntslHtwtrSumNsqty) {
        if (focusOnError) {
          this.isHtwtrDiff = false;
          this.$refs.sdflNtslTitle.focus();

          if(allPrdctnItems?.length || 0 > 0){
            const rowItem = window.SBGrid3.getRowByIndex(this.prdctnGridObject, 0);
            const column = window.SBGrid3.getColumnByField(this.prdctnGridObject, 'htwtrNsqty');
            window.SBGrid3.moveFocus(this.prdctnGridObject, rowItem, column);
          }

          if(allNtslItems?.length || 0 > 0){
            const rowItem = window.SBGrid3.getRowByIndex(this.ntslGridObject, 0);
            const column = window.SBGrid3.getColumnByField(this.ntslGridObject, 'htwtrNsqty');
            window.SBGrid3.moveFocus(this.ntslGridObject, rowItem, column);
          }
        }
        return "시설별 온수 판매량의 합계와 판매업체별 온수 판매량 합계가 일치하지 않습니다.";
      }

      const prdctnPrvtmSumNsqty = this.sanitizeFloatingPointError(allPrdctnItems.reduce((sum, item) => { return sum + (item.prvtmNsqty || 0); }, 0));
      const ntslPrvtmSumNsqty = this.sanitizeFloatingPointError(allNtslItems.reduce((sum, item) => { return sum + (item.prvtmNsqty || 0); }, 0));
      if (prdctnPrvtmSumNsqty !== ntslPrvtmSumNsqty) {
        if (focusOnError) {
          this.isPrvtmDiff = false;
          this.$refs.sdflNtslTitle.focus();

          if(allPrdctnItems?.length || 0 > 0){
            const rowItem = window.SBGrid3.getRowByIndex(this.prdctnGridObject, 0);
            const column = window.SBGrid3.getColumnByField(this.prdctnGridObject, 'prvtmNsqty');
            window.SBGrid3.moveFocus(this.prdctnGridObject, rowItem, column);
          }

          if(allNtslItems?.length || 0 > 0){
            const rowItem = window.SBGrid3.getRowByIndex(this.ntslGridObject, 0);
            const column = window.SBGrid3.getColumnByField(this.ntslGridObject, 'prvtmNsqty');
            window.SBGrid3.moveFocus(this.ntslGridObject, rowItem, column);
          }
        }
        return "시설별 전기 판매량의 합계와 판매업체별 전기 판매량 합계가 일치하지 않습니다.";
      }

      const { inserted, updated } = window.SBGrid3.getSaveData(this.asstFuelInputGridObject, true, true, false);
      const asstFuelInputList = [...inserted, ...updated];

      for (const item of asstFuelInputList) {
        if (!item.fcltSqno) {
          if (focusOnError) {
            this.$refs.asstFuelTitle.focus();
            const rowItem= window.SBGrid3.getRow(this.asstFuelInputGridObject, item._key_);
            const column = window.SBGrid3.getColumnByField(this.asstFuelInputGridObject, 'fcltSqno');

            window.SBGrid3.moveFocus(this.asstFuelInputGridObject, rowItem, column);
            window.SBGrid3.columnEditable(this.asstFuelInputGridObject, item._key_, column, true);
            return "보조연료 투입 현황의 시설을 선택하지 않은 데이터가 있습니다.";
          }
        }

        if (!item.asstFuelKndGroupCd) {
          if (focusOnError) {
            this.$refs.asstFuelTitle.focus();
            const rowItem= window.SBGrid3.getRow(this.asstFuelInputGridObject, item._key_);
            const column = window.SBGrid3.getColumnByField(this.asstFuelInputGridObject, 'asstFuelKndGroupCd');

            window.SBGrid3.moveFocus(this.asstFuelInputGridObject, rowItem, column);
            window.SBGrid3.columnEditable(this.asstFuelInputGridObject, item._key_, column, true);
            return "보조연료 투입 현황의 연료구분을 선택하지 않은 데이터가 있습니다.";
          }
        }

        if (!item.asstFuelKndCd) {
          if (focusOnError) {
            this.$refs.asstFuelTitle.focus();
            const rowItem= window.SBGrid3.getRow(this.asstFuelInputGridObject, item._key_);
            const column = window.SBGrid3.getColumnByField(this.asstFuelInputGridObject, 'asstFuelKndCd');

            window.SBGrid3.moveFocus(this.asstFuelInputGridObject, rowItem, column);
            window.SBGrid3.columnEditable(this.asstFuelInputGridObject, item._key_, column, true);
            return "보조연료 투입 현황의 연료종류를 선택하지 않은 데이터가 있습니다.";
          }
        }

        if (!item.asstFuelInpqty) {
          if (focusOnError) {
            this.$refs.asstFuelTitle.focus();
            const rowItem= window.SBGrid3.getRow(this.asstFuelInputGridObject, item._key_);
            const column = window.SBGrid3.getColumnByField(this.asstFuelInputGridObject, 'asstFuelInpqty');

            window.SBGrid3.moveFocus(this.asstFuelInputGridObject, rowItem, column);
            window.SBGrid3.columnEditable(this.asstFuelInputGridObject, item._key_, column, true);
            return "보조연료 투입 현황의 연료투입량이 0이거나 입력되지 않은 데이터가 있습니다.";
          }
        }
      }

      return "";
    },
    checkUsePrcsPrfmc(prfmncSttsCd) {
      return new Promise((resolve, reject) => {
        const allPrcsItems = window.SBGrid3.getAllItems(this.sdflPrcsGridObject);
        const prdctSumCrfqty = this.sanitizeFloatingPointError(allPrcsItems.reduce((sum, item) => { return sum + (item.prdctCrfqty || 0); }, 0));

        const totalPrdctCrfqty = this.sanitizeFloatingPointError(allPrcsItems.reduce((sum, item) => sum + (item.prdctCrfqty || 0), 0));
        const totalPrdctSumAcpqty = this.sanitizeFloatingPointError(allPrcsItems.reduce((sum, item) => sum + (item.prdctSumAcpqty || 0), 0));
        const totalPrdctSumInpqty = this.sanitizeFloatingPointError(allPrcsItems.reduce((sum, item) => sum + (item.prdctSumInpqty || 0), 0));
        const totalPrdctRsdqty = this.sanitizeFloatingPointError(allPrcsItems.reduce((sum, item) => sum + (item.prdctRsdqty || 0), 0));
        const prdctSumRmnqty = utils.round2(totalPrdctCrfqty + totalPrdctSumAcpqty - totalPrdctSumInpqty - totalPrdctRsdqty);

        this.$apiCall.post(
            API.USE_PROCESS_PERFORMANCE_CHANGE_CHECK,
            {
              bplcId: this.prfmncBasicInfo.bplcId,
              prfmncYm: this.prfmncBasicInfo.prfmncYr + this.prfmncBasicInfo.prfmncMm,
              prdctSumCrfqty: prdctSumCrfqty,
              prdctSumRmnqty: prdctSumRmnqty,
              updatePrfmncSttsCd: prfmncSttsCd,
            },
            (data) => {
              resolve(data.result);
            },
            () => {
              alert("제출에 실패하였습니다.");
              reject();
            }
        );
      });
    },
    saveConfirmed(prfmncSttsCd, changeList = []) {
      // const { updated: sdflCrynUpdated } = window.SBGrid3.getSaveData(this.sdflCrynGridObject, false, true, false);
      // const { updated: sdflInputUpdated } = window.SBGrid3.getSaveData(this.sdflInputGridObject, false, true, false);
      // const { updated: prdctnUpdated } = window.SBGrid3.getSaveData(this.prdctnGridObject, false, true, false);
      // const { updated: ntslUpdated } = window.SBGrid3.getSaveData(this.ntslGridObject, false, true, false);
      const { inserted, updated, deleted } = window.SBGrid3.getSaveData(this.asstFuelInputGridObject);
      const asstFuelInputList = [...inserted, ...updated, ...deleted];

      const { updated: sdflPrcsUpdated } = window.SBGrid3.getSaveData(this.sdflPrcsGridObject, false, true, false);
      const prcsAllItems = window.SBGrid3.getAllItems(this.sdflPrcsGridObject)
      const updatedItemCodes = sdflPrcsUpdated.map(item => item.prdctItemCd); // 수정된 목록의 제품종류코드
      // 이월량이 0보다 크고 수정된 목록에 없는 데이터 필터링
      const itemsToAdd = prcsAllItems.filter(item =>
          (item.prdctCrfqty || 0) > 0 && !updatedItemCodes.includes(item.prdctItemCd)
      );
      // 변경되지 않았더라도 이월량이 있는경우 데이터 유지를 위해 추가
      itemsToAdd.forEach(item => {
        sdflPrcsUpdated.push(item);
      });

      // const param = {
      //   prfmncId: this.prfmncBasicInfo.prfmncId,
      //   bplcId: this.prfmncBasicInfo.bplcId,
      //   prfmncYm: this.prfmncBasicInfo.prfmncYr + this.prfmncBasicInfo.prfmncMm,
      //   prfmncSttsCd,
      //   useCrynList: sdflCrynUpdated,
      //   useInputList: sdflInputUpdated,
      //   usePrcsList: sdflPrcsUpdated,
      //   usePrdctnList: prdctnUpdated,
      //   useNtslList: ntslUpdated,
      //   asstFuelInputList: asstFuelInputList,
      //   changeList,
      // };
      /* 2025.02.03 미가동시설도 실적등록할 수 있도록 디폴트로 조회된 0값도 저장 되도록 수정  */
      const param = {
        prfmncId: this.prfmncBasicInfo.prfmncId,
        bplcId: this.prfmncBasicInfo.bplcId,
        prfmncYm: this.prfmncBasicInfo.prfmncYr + this.prfmncBasicInfo.prfmncMm,
        prfmncSttsCd,
        useCrynList: window.SBGrid3.getAllItems(this.sdflCrynGridObject),
        useInputList: window.SBGrid3.getAllItems(this.sdflInputGridObject),
        usePrcsList: window.SBGrid3.getAllItems(this.sdflPrcsGridObject),
        usePrdctnList: window.SBGrid3.getAllItems(this.prdctnGridObject),
        useNtslList: window.SBGrid3.getAllItems(this.ntslGridObject),
        asstFuelInputList: asstFuelInputList,
        changeList,
      };

      const prfmncSttsNm = prfmncSttsCd === "SUB" ? "제출" : "저장";

      this.$apiCall.post(
          API.USE_PERFORMANCE,
          param,
          (data) => {
            alert(`${prfmncSttsNm}이 완료되었습니다.`);
            storeSwitch.off('SdflUsePrcsPrfmncChgDscntPopup');

            if (prfmncSttsCd === "SUB") {
              this.toList();
            } else {
              this.$router.push({path : "/prfmnc-rpt/dtl/SdflUse", query: {prfmncId: data.result.prfmncId}})
                  .then(() => {
                    location.reload();
                  })
                  .catch(() => {
                    console.error("Navigation Error");
                  });
            }
          },
          () => {
            alert(`${prfmncSttsNm}에 실패하였습니다.`);
          }
      );
    },
    formatNumber(number) {
      return utils.formatNumberWithComma(number);
    },

    // 소수점 둘쨰자리 오류 수정
    // 부동소수점(floating point) 연산의 정밀도 문제 스크립트 고유 버그
    sanitizeFloatingPointError(value){
      if (typeof value !== "number") return value; // 숫자가 아닐 경우 반환

      // 소수점 2자리까지만 유지하고 반올림하여 부동소수점 오차 제거
      return parseFloat((Math.round(value * 100) / 100).toFixed(2));

    },
    changePrdctn(values) {
      values.forEach(item => {
        //2025.03.17 null 인경우 0처리
        if (item.field === 'stUsgVaporUsqty') {
          const data = window.SBGrid3.getRowData(this.prdctnGridObject, item.key);
          if(!data.stUsgVaporUsqty){
            data.stUsgVaporUsqty = 0;
          }
        }

        //2025.03.17 null 인경우 0처리
        if (item.field === 'htwtrUsgVaporUsqty') {
          const data = window.SBGrid3.getRowData(this.prdctnGridObject, item.key);
          if(!data.htwtrUsgVaporUsqty){
            data.htwtrUsgVaporUsqty = 0;
          }
        }

        //2025.03.17 null 인경우 0처리
        if (item.field === 'elctgnUsgVaporUsqty') {
          const data = window.SBGrid3.getRowData(this.prdctnGridObject, item.key);
          if(!data.elctgnUsgVaporUsqty){
            data.elctgnUsgVaporUsqty = 0;
          }
        }

        //2025.03.17 null 인경우 0처리
        if (item.field === 'vaporNsqty') {
          const data = window.SBGrid3.getRowData(this.prdctnGridObject, item.key);
          if(!data.vaporNsqty){
            data.vaporNsqty = 0;
          }
        }

        //2025.03.17 null 인경우 0처리
        if (item.field === 'htwtrUsqty') {
          const data = window.SBGrid3.getRowData(this.prdctnGridObject, item.key);
          if(!data.htwtrUsqty){
            data.htwtrUsqty = 0;
          }
        }

        //2025.03.17 null 인경우 0처리
        if (item.field === 'htwtrNsqty') {
          const data = window.SBGrid3.getRowData(this.prdctnGridObject, item.key);
          if(!data.htwtrNsqty){
            data.htwtrNsqty = 0;
          }
        }

        //2025.03.17 null 인경우 0처리
        if (item.field === 'prvtmUsqty') {
          const data = window.SBGrid3.getRowData(this.prdctnGridObject, item.key);
          if(!data.prvtmUsqty){
            data.prvtmUsqty = 0;
          }
        }

        //2025.03.17 null 인경우 0처리
        if (item.field === 'prvtmNsqty') {
          const data = window.SBGrid3.getRowData(this.prdctnGridObject, item.key);
          if(!data.prvtmNsqty){
            data.prvtmNsqty = 0;
          }
        }
      });
    },
    changeNtsl(values) {
      values.forEach(item => {
        //2025.03.17 null 인경우 0처리
        if (item.field === 'vaporNsqty') {
          const data = window.SBGrid3.getRowData(this.ntslGridObject, item.key);
          if(!data.vaporNsqty){
            data.vaporNsqty = 0;
          }
        }

        //2025.03.17 null 인경우 0처리
        if (item.field === 'htwtrNsqty') {
          const data = window.SBGrid3.getRowData(this.ntslGridObject, item.key);
          if(!data.htwtrNsqty){
            data.htwtrNsqty = 0;
          }
        }

        //2025.03.17 null 인경우 0처리
        if (item.field === 'prvtmNsqty') {
          const data = window.SBGrid3.getRowData(this.ntslGridObject, item.key);
          if(!data.prvtmNsqty){
            data.prvtmNsqty = 0;
          }
        }
      });
    },
  }
}
</script>

<style scoped>
  .grid_box { margin-bottom: 5px; }
</style>