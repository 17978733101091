<template>
  <div class="member_wrap">
    <div class="desc_box">
      <i class="icon"><img src="@/assets/images/common/ico_desc.png" alt="이미지"></i>
      <div class="desc">
        <p><strong class="desc_title">아이디/비밀번호를 잊으신 경우</strong>휴대폰인증이나 간편인증으로 본인확인을 하셔야 폐자원에너지종합정보관리시스템 서비스 이용에 불편이 없습니다.</p>
      </div>
    </div>
    <div class="tab_area">
      <ul class="tab">
        <li :class="{ active: storeFindAccountInfo.findType === 'id' }">
          <button type="button" class="btn_tab" @click="setTab('id')">아이디찾기</button>
        </li>
        <li :class="{ active: storeFindAccountInfo.findType === 'pswd' }">
          <button type="button" class="btn_tab" @click="setTab('pswd')">비밀번호찾기</button>
        </li>
      </ul>
      <div class="tab_conts_wrap">
        <section v-show="storeFindAccountInfo.findType === 'id'" class="tab_conts">
          <div class="type_area">
            <a href="javascript:void(0);" class="type_box">
              <i class="mobile"></i>
              <h3>휴대폰 본인인증</h3>
              <p>법인 명의 및 타인 명의의 휴대폰으로는 인증이 불가합니다</p>
              <span class="btn sm tertiary" @click="identityAuthOpen">인증하기</span>
            </a>
            <a href="javascript:void(0);" class="type_box">
              <i class="public"></i>
              <h3>간편인증</h3>
              <p>민간 인증서로 인증합니다.</p>
              <span class="btn sm tertiary" @click="simpleAuthOpen">인증하기</span>
            </a>
          </div>
        </section>

        <section v-show="storeFindAccountInfo.findType === 'pswd'" class="tab_conts">
          <div class="txt_center mb_25">
            <div class="input_group">
              <label for="" class="mr_40" style="font-weight: 700; color:var(--font-title)"><i class="necessary">필수입력</i>아이디 입력</label>
              <br-form-input label="아이디" v-model="param.userId" required />
            </div>
          </div>
          <div class="type_area">
            <a href="javascript:void(0);" class="type_box">
              <i class="mobile"></i>
              <h3>휴대폰 본인인증</h3>
              <p>법인 명의 및 타인 명의의 휴대폰으로는 인증이 불가합니다</p>
              <span class="btn sm tertiary" @click="identityAuthOpen">인증하기</span>
            </a>
            <a href="javascript:void(0);" class="type_box">
              <i class="public"></i>
              <h3>간편인증</h3>
              <p>민간 인증서로 인증합니다.</p>
              <span class="btn sm tertiary" @click="simpleAuthOpen">인증하기</span>
            </a>
          </div>
        </section>
      </div>
    </div>
  </div>
  <!-- 본인인증 -->
  <identity-auth-modal usageCode="01001" @callback="identityAuthCallback" @fail="identityAuthFail"/>
  <!-- 간편인증 -->
  <simple-auth-modal @callback="simpleAuthCallback" @fail="simpleAuthFail"/>
</template>

<script>
import IdentityAuthModal from "@/components/IdentityAuthModal.vue";
import SimpleAuthModal from "@/components/SimpleAuthModal.vue";
import {storeSwitch} from "@/js/store/store-switch";
import storeAccount from "@/js/store/store-account";

const ROUTE = {
  LOGIN: "/user/login",
}

export default {
  components: {
    IdentityAuthModal,
    SimpleAuthModal,
  },
  data() {
    return {
      param : {
        userId: ''
      },
      idPswdSe: '',
      userId: '',
    };
  },
  computed: {
    storeFindAccountInfo() {
      return storeAccount.getters.getFindAccountInfo;
    },
  },
  watch: {},
  created() {
    storeAccount.dispatch('resetFindAccount'); // 계정찾기 진행여부 초기화
    const queryTab = this.$route.query.tab;

    if (queryTab) {
        storeAccount.dispatch('updateFindAccountInfo', {findType: queryTab}); // 계정찾기 정보
    }
  },
  mounted() {
  },
  methods: {
    setTab(tab) {
      storeAccount.dispatch('updateFindAccountInfo', {findType: tab}); // 계정찾기 정보
    },
    login() {
      this.navigateTo(ROUTE.LOGIN);
    },
    navigateTo(path) {
      if (path) {
        this.$router.push(path)
            .catch(() => {
              console.error("Navigation Error");
            });
      }
    },
    simpleAuthOpen() {
      if(storeAccount.state.findAccountInfo.findType === "pswd"){
        if(!this.param.userId){
          alert("아이디를 입력해 주세요.");
          return;
        }

        storeAccount.dispatch('updateFindAccountInfo', {userId: this.param.userId}); // 계정찾기 정보
      }

      storeSwitch.on('SimpleAuthModal');
    },
    simpleAuthCallback(result) {
      if (!result.isUser) {
        alert('간편인증정보와 일치하는 사용자가 없습니다');
        return;
      }

      if(storeAccount.state.findAccountInfo.userId){
        if(!result.bzentyUsers){
          if(storeAccount.state.findAccountInfo.userId !== result.userId){
            alert('입력한 아이디와 일치하는 정보가 없습니다.');
            return;
          } else {
            alert("인증이 성공적으로 완료되었습니다.");
          }
        } else {
          const exists = result.bzentyUsers.some(user => user.userId === storeAccount.state.findAccountInfo.userId);
          if(!exists){
            alert('입력한 아이디와 일치하는 정보가 없습니다.');
            return;
          } else {
            alert("인증이 성공적으로 완료되었습니다.");
          }
        }
      } else {
        if(!result.bzentyUsers){
          storeAccount.dispatch('updateFindAccountInfo', {userId: result.userId, bzentyYn: 'N'}); // 계정찾기 정보
        } else {
          storeAccount.dispatch('updateFindAccountInfo', {userId: result.bzentyUsers.map(user => user.userId), bzentyYn: 'Y'}); // 계정찾기 정보
        }
      }

      storeAccount.dispatch('startFindAccount'); // 계정찾기 진행여부 처리

      if(storeAccount.state.findAccountInfo.findType === 'id'){
        this.idFind();
      } else {
        this.pwsdFind();
      }

    },
    idFind() {
      this.navigateTo(`/user/UserIdInq`);
    },
    pwsdFind() {
      this.navigateTo(`/user/UserPswdChg`);
    },
    simpleAuthFail(error) {
      console.log("simpleAuthFail", error);
    },
    identityAuthOpen() {
      if(storeAccount.state.findAccountInfo.findType === "pswd"){
        if(!this.param.userId){
          alert("아이디를 입력해 주세요.");
          return;
        }

        storeAccount.dispatch('updateFindAccountInfo', {userId: this.param.userId}); // 계정찾기 정보
      }

      storeSwitch.on('IdentityAuthModal');
    },
    identityAuthCallback(result) {
      if (!result.isUser) {
        alert("본인인증정보와 일치하는 사용자가 없습니다");
        return;
      }

      if(storeAccount.state.findAccountInfo.userId){
        if(!result.bzentyUsers){
          if(storeAccount.state.findAccountInfo.userId !== result.userId){
            alert('입력한 아이디와 일치하는 정보가 없습니다.');
            return;
          } else {
            alert("인증이 성공적으로 완료되었습니다.");
          }
        } else {
          const exists = result.bzentyUsers.some(user => user.userId === storeAccount.state.findAccountInfo.userId);
          if(!exists){
            alert('입력한 아이디와 일치하는 정보가 없습니다.');
            return;
          } else {
            alert("인증이 성공적으로 완료되었습니다.");
          }
        }
      } else {
        if(!result.bzentyUsers){
          storeAccount.dispatch('updateFindAccountInfo', {userId: result.userId, bzentyYn: 'N'}); // 계정찾기 정보
        } else {
          storeAccount.dispatch('updateFindAccountInfo', {userId: result.bzentyUsers.map(user => user.userId), bzentyYn: 'Y'}); // 계정찾기 정보
        }
      }

      storeAccount.dispatch('startFindAccount'); // 계정찾기 진행여부 처리

      if(storeAccount.state.findAccountInfo.findType === 'id'){
        this.idFind();
      } else {
        this.pwsdFind();
      }
    },
    identityAuthFail(error) {
      console.log("identityAuthFail", error);
    },
  }
}
</script>

<style scoped>

</style>