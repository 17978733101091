<template>
  <!-- 서류보완요청 알림 영역 -->
  <div v-if="qltyInspStatData.inspPrgrsSttsCd === '30'" class="desc_box progress_box">
    <i class="icon"><img src="../../../../assets/images/common/ico_noti.png" alt="이미지"></i>
    <div class="progress">
      <div><strong>서류보완요청</strong>중입니다 <span>{{ this.qltyInspStatData.taskPrcsRsn }}</span></div>
    </div>
  </div>
  <!-- 신청정보 영역 -->
  <h2 class="title1 mt_0">신청정보</h2>
  <div class="board_write">
    <p class="t_caption"><span class="necessary">(<i>※</i>)</span> 필수 입력항목입니다.</p>
    <table>
      <caption>신청정보_시설 명, 위탁업체 명, 처리업구분, 검사진행상태, 검사구분, 건조시설유무, 품질표시검사여부, 품질표시검사항목, 입금(예정)일자,
        입금(예정)자명, 선입금여부, 검사희망일자, 첨부파일로 구성</caption>
      <colgroup>
        <col style="width:18%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:32%">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">시설 명</th>
        <td>{{ this.qltyInspStatData.fcltNm }}</td>
        <th scope="row">위탁업체 명</th>
        <td>{{ this.qltyInspStatData.cnsgnBzentyNm }}</td>
      </tr>
      <tr>
        <th scope="row">처리업구분</th>
        <td colspan="3">
          <div class="chk_area">
            <div class="form_check"><input type="checkbox" id="chk1_1" v-model="qltyInspStatData.snthsRecyBzentyYn" true-value="Y" false-value="N" disabled><label for="chk1_1">종합재활용업체</label></div>
            <div class="form_check"><input type="checkbox" id="chk1_2" v-model="qltyInspStatData.lastRecyBzentyYn" true-value="Y" false-value="N" disabled><label for="chk1_2">최종재활용업체</label></div>
            <div class="form_check"><input type="checkbox" id="chk1_3" v-model="qltyInspStatData.mdRecyBzentyYn" true-value="Y" false-value="N" disabled><label for="chk1_3">중간재활용업체</label></div>
            <div class="form_check"><input type="checkbox" id="chk1_4" v-model="qltyInspStatData.prcsFcltInstlDclrYn" true-value="Y" false-value="N" disabled><label for="chk1_4">처리시설설치신고</label></div>
            <div class="form_check"><input type="checkbox" id="chk1_5" v-model="qltyInspStatData.atmoDschrgFcltInstlDclrYn" true-value="Y" false-value="N" disabled><label for="chk1_5">대기배출시설설치신고</label></div>
          </div>
        </td>
      </tr>
      <tr>
        <th scope="row">제품종류</th>
        <td colspan="3">
          {{ qltyInspStatData.qltyInspItemNm }}
        </td>
      </tr>
      <tr>
        <th scope="row">검사진행상태</th>
        <td colspan="3">
          <div class="input_group">
            {{ qltyInspStatData.inspPrgrsSttsNm }}
            <br-anchor-button label="이력보기" size="xsm" format="" @click="popInspHist" />
          </div>
        </td>
      </tr>
      <tr>
        <th scope="row"><label for="inspSeCd">검사구분</label></th>
        <td>
          <br-form-select id="inspSeCd" label="검사구분" v-model:value="qltyInspStatData.inspSeCd" :options="inspSeCdList" disabled />
        </td>
        <th scope="row">건조시설유무</th>
        <td>{{ this.qltyInspStatData.dhmdfyFcltHldYn === 'Y' ? '유' : '무' }}</td>
      </tr>
      <tr>
        <th scope="row">품질검사항목</th>
        <td colspan="3">
          <br-form-radio label="품질검사항목" :options="qltyInspArtclOpt" v-model:value="qltyInspStatData.qltyInspArtclCd" @selected="onChangedQltyInspArtclCd" @click="setInspCpstCd(qltyInspStatData.qltyInspItemCd)" :disabled="!canModify"/>
        </td>
      </tr>
      <tr>
        <th scope="row">품질표시검사여부</th>
        <td colspan="3">
          <div class="chk_area">
            <br-form-radio label="품질표시검사여부" :options="qltyIndctInspYnList" v-model:value="qltyInspStatData.qltyIndctInspYn" @click="setInspCpstCd(qltyInspStatData.qltyInspItemCd)" :disabled="isSelectedOnlyMoistureItem || !canModify"/>
          </div>
        </td>
      </tr>
      <tr>
        <th scope="row"><i class="necessary">필수입력</i>검사항목</th>
        <td colspan="3">
          <!-- <div class="chk_area">
            <br-form-checkbox label="품질검사항목" :options="inspCpstCdOpt" v-model:values="qltyInspStatData.inspCpstCdList" allSelectable :disabled="qltyInspStatData.qltyIndctInspYn === 'N' || !canModify"/>
            <label for="etcArtclNm">기타항목 상세</label>
            <input type="text" name="etcArtclNm" id="etcArtclNm" maxlength="50" v-model="qltyInspStatData.etcArtclNm"  :disabled="qltyInspStatData.qltyIndctInspYn === 'N' || !canModify" />
          </div> -->
          <div class="form_check">
              <input type="checkbox" name="inspCpstCdAll" id="inspCpstCdAll" value="chkAll" v-model="allSelected" :disabled="this.inspCpstDisabled">
              <label for="inspCpstCdAll">전체</label>
          </div>
          <table>
            <caption>검사항목</caption>
            <colgroup>
              <col style="width:9%">
              <col style="width:91%">
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">품질검사</th>
                <td>
                  <div class="chk_area">
                    <div class="form_check">
                        <input type="checkbox" name="inspCpstCd150" id="inspCpstCd150" value="150" v-model="this.qltyInspStatData.inspCpstCdList">
                        <label for="inspCpstCd150">모양 및 크기</label>
                    </div>
                    <div class="form_check">
                        <input type="checkbox" name="inspCpstCd160" id="inspCpstCd160" value="160" v-model="this.qltyInspStatData.inspCpstCdList">
                        <label for="inspCpstCd160">수분(wt.%)</label>
                    </div>
                    <div class="form_check">
                        <input type="checkbox" name="inspCpstCd180" id="inspCpstCd180" value="180" v-model="this.qltyInspStatData.inspCpstCdList">
                        <label for="inspCpstCd180">(저위)발열량(kcal/kg)</label>
                    </div>
                    <div class="form_check">
                        <input type="checkbox" name="inspCpstCd170" id="inspCpstCd170" value="170" v-model="this.qltyInspStatData.inspCpstCdList">
                        <label for="inspCpstCd170">회분(wt.%)</label>
                    </div>
                    <div class="form_check">
                        <input type="checkbox" name="inspCpstCd140" id="inspCpstCd140" value="140" v-model="this.qltyInspStatData.inspCpstCdList">
                        <label for="inspCpstCd140">염소(wt.%)</label>
                    </div>
                    <div class="form_check">
                        <input type="checkbox" name="inspCpstCd130" id="inspCpstCd130" value="130" v-model="this.qltyInspStatData.inspCpstCdList">
                        <label for="inspCpstCd130">항분(wt.%)</label>
                    </div>
                    <div class="form_check">
                        <input type="checkbox" name="inspCpstCd190" id="inspCpstCd190" value="190" v-model="this.qltyInspStatData.inspCpstCdList" :disabled="inspCpstBioMassDisabled">
                        <label for="inspCpstCd190">바이오매스(wt.%)</label>
                    </div>                            
                    <div class="form_check">
                        <input type="checkbox" name="inspCpstCd80" id="inspCpstCd80" value="80" v-model="this.qltyInspStatData.inspCpstCdList">
                        <label for="inspCpstCd80">수은(Hg)</label>
                    </div>
                    <div class="form_check">
                        <input type="checkbox" name="inspCpstCd90" id="inspCpstCd90" value="90" v-model="this.qltyInspStatData.inspCpstCdList">
                        <label for="inspCpstCd90">카드뮴(Cd)</label>
                    </div>
                    <div class="form_check">
                        <input type="checkbox" name="inspCpstCd100" id="inspCpstCd100" value="100" v-model="this.qltyInspStatData.inspCpstCdList">
                        <label for="inspCpstCd100">납(Pb)</label>
                    </div>
                    <div class="form_check">
                        <input type="checkbox" name="inspCpstCd110" id="inspCpstCd110" value="110" v-model="this.qltyInspStatData.inspCpstCdList">
                        <label for="inspCpstCd110">비소(As)</label>
                    </div>
                    <div class="form_check">
                        <input type="checkbox" name="inspCpstCd200" id="inspCpstCd200" value="200" v-model="this.qltyInspStatData.inspCpstCdList">
                        <label for="inspCpstCd200">기타항목</label>
                        <input type="text" name="etcArtclNm" id="etcArtclNm" maxlength="50" v-model="this.qltyInspStatData.etcArtclNm"/>
                        <label for="etcArtclNm" style="display:none;">기타항목입력</label>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">품질검사</th>
                <td>
                  ※ 바이오 고형연료제품에 대한 품질검사인 경우 크로뮴(Cr)은 품질검사 항목에 해당될 수 있습니다.
                  <div class="chk_area">
                    <div class="form_check">
                      <input type="checkbox" name="inspCpstCd120" id="inspCpstCd120" value="120" v-model="this.qltyInspStatData.inspCpstCdList">
                      <label for="inspCpstCd120">크로뮴(Cr)</label>
                    </div>
                    <div class="form_check">
                      <input type="checkbox" name="inspCpstCd10" id="inspCpstCd10" value="10" v-model="this.qltyInspStatData.inspCpstCdList">
                      <label for="inspCpstCd10">안티몬(Sb)</label>
                    </div>
                    <div class="form_check">
                      <input type="checkbox" name="inspCpstCd20" id="inspCpstCd20" value="20" v-model="this.qltyInspStatData.inspCpstCdList">
                      <label for="inspCpstCd20">코발트</label>
                    </div>
                    <div class="form_check">
                      <input type="checkbox" name="inspCpstCd40" id="inspCpstCd40" value="40" v-model="this.qltyInspStatData.inspCpstCdList">
                      <label for="inspCpstCd40">망간(Mn)</label>
                    </div>
                    <div class="form_check">
                      <input type="checkbox" name="inspCpstCd50" id="inspCpstCd50" value="50" v-model="this.qltyInspStatData.inspCpstCdList">
                      <label for="inspCpstCd50">니켈(Ni)</label>
                    </div>
                    <div class="form_check">
                      <input type="checkbox" name="inspCpstCd60" id="inspCpstCd60" value="60" v-model="this.qltyInspStatData.inspCpstCdList">
                      <label for="inspCpstCd60">탈륨(Tl)</label>
                    </div>
                    <div class="form_check">
                      <input type="checkbox" name="inspCpstCd70" id="inspCpstCd70" value="70" v-model="this.qltyInspStatData.inspCpstCdList">
                      <label for="inspCpstCd70">바나듐(V)</label>
                    </div>
                    <div class="form_check">
                      <input type="checkbox" name="inspCpstCd30" id="inspCpstCd30" value="30" v-model="this.qltyInspStatData.inspCpstCdList">
                      <label for="inspCpstCd30">구리(Cu)</label>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

        </td>
      </tr>
      <tr>
        <th scope="row">입금(예정)일자</th>
        <td><br-date-picker label="입금(예정)일자" v-model:value="qltyInspStatData.dpstPrnmntYmd" :disabled="!canModify" futureonly/></td>
        <th scope="row"><i class="necessary">필수입력</i>입금(예정)자 명</th>
        <td>
          <div class="input_group">
            <br-form-input label="입금(예정)자 명" type="text" v-model="qltyInspStatData.pyrNm" :disabled="!canModify" required aria-label="입금(예정)자 명"/>
            <div class="form_check"><input type="checkbox" id="chk002" v-model="qltyInspStatData.dpstYn" true-value="Y" false-value="N" :disabled="!canModify"><label for="chk002">선 입금여부</label></div>
          </div>
        </td>
      </tr>
      <tr>
        <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>검사희망일자
        </th>
        <td colspan="3">
          <br-date-picker label="검사희망일자" v-model:value="qltyInspStatData.inspHopeYmd" :disabled="!canModify" required futureonly :futureDaysOffset="15"/>
          <span class="txt_hint">15일 이후로 입력</span>
        </td>
      </tr>
      <tr>
        <th scope="row">첨부파일</th>
        <td colspan="3">
          <!-- 수정일 경우 업체/사업장 첨부파일 목록 조회 --> 
          <div class="file_upload_list" v-if="canModify">
            <br-attach-file-list 
                  label="재활용허가증 또는 폐기물처리시설신고필증 및 통장사본"
                  ref="attachFileList"
                  atchFileTaskSeCd="BPCA"
                  v-model:atchFileGroupId="qltyInspStatData.bpAtchFileGroupId"
                  :useEtcFile="false"
                  :maxFileCount="2"
                  :readonly="true"
                  :unusedAtchFileList= "['002', '007', '008', '049', '053', '054']"
            />
            <br-attach-file-list 
                  label="사업자등록증"
                  ref="attachFileList"
                  atchFileTaskSeCd="BET"
                  v-model:atchFileGroupId="qltyInspStatData.bzAtchFileGroupId"
                  atchFileSeCd="003"
                  :useEtcFile="false"
                  :maxFileCount="1"
                  :readonly="true"
            />
            <br-attach-file-list v-if="qltyInspStatData.inspSeCd === '20-30'"
                                 label="품질검사 신청 첨부파일 목록"
                                 ref="attachFileList"
                                 atchFileTaskSeCd="QLT"
                                 v-model:atchFileGroupId="qltyInspStatData.inspAplyAtchFileGroupId"
                                 :useEtcFile="false"
                                 :maxFileCount="5"
                                 :readonly="!canModify"
                                 :unusedAtchFileList= "['001', '003', '006', '010', '011', '016', '033']"
                                 :allowedExtensions="allowedFileExtensions"
            />
            <br-attach-file-list v-else
                                 label="품질검사 신청 첨부파일 목록"
                                 ref="attachFileList"
                                 atchFileTaskSeCd="QLT"
                                 v-model:atchFileGroupId="qltyInspStatData.inspAplyAtchFileGroupId"
                                 :useEtcFile="false"
                                 :maxFileCount="5"
                                 :readonly="!canModify"
                                 :unusedAtchFileList= "['001', '003', '006', '010', '011', '016', '033', '036']"
                                 :allowedExtensions="allowedFileExtensions"
            />
          </div>

          <!-- 수정이 아닐 경우 업체/사업장 첨부파일이 아닌 품질검사 등록된 첨부파일 목록 조회 -->
          <div class="file_upload_list" v-if="!canModify">
            <br-attach-file-list 
                  label="재활용허가증 또는 폐기물처리시설신고필증 및 통장사본"
                  ref="attachFileList"
                  atchFileTaskSeCd="BPCA"
                  v-model:atchFileGroupId="qltyInspStatData.bpAtchFileGroupId"
                  :useEtcFile="false"
                  :maxFileCount="2"
                  :readonly="true"
                  :unusedAtchFileList= "['001', '002', '003', '006', '007', '008', '049', '053', '054']"
            />
            <br-attach-file-list 
                  label="사업자등록증"
                  ref="attachFileList"
                  atchFileTaskSeCd="BET"
                  v-model:atchFileGroupId="qltyInspStatData.bzAtchFileGroupId"
                  :useEtcFile="false"
                  :maxFileCount="1"
                  :readonly="true"
                  :unusedAtchFileList= "['001', '002', '003', '006']"
            />
            <br-attach-file-list v-if="qltyInspStatData.inspSeCd === '20-30'"
                                 label="품질검사 신청 첨부파일 목록"
                                 ref="attachFileList"
                                 atchFileTaskSeCd="QLT"
                                 v-model:atchFileGroupId="qltyInspStatData.inspAplyAtchFileGroupId"
                                 :useEtcFile="false"
                                 :maxFileCount="5"
                                 :readonly="!canModify"
                                 :unusedAtchFileList= "['010', '011', '016', '033']"
                                 :allowedExtensions="allowedFileExtensions"
            />
            <br-attach-file-list v-else
                                 label="품질검사 신청 첨부파일 목록"
                                 ref="attachFileList"
                                 atchFileTaskSeCd="QLT"
                                 v-model:atchFileGroupId="qltyInspStatData.inspAplyAtchFileGroupId"
                                 :useEtcFile="false"
                                 :maxFileCount="5"
                                 :readonly="!canModify"
                                 :unusedAtchFileList= "['010', '011', '016', '033', '036']"
                                 :allowedExtensions="allowedFileExtensions"
            />
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <!-- 검사정보 영역 -->
  <!-- <template v-if="isInspOnGoing">
    <div class="board_info mt_40">
      <h2 class="title1">검사 정보</h2>
      <br-anchor-button v-if="!canModify" label="시료체취계획서 출력" size="sm" format="" icon="ico_print" @click="printSpcmPicPlan" />
    </div>
    <div class="board_write">
      <table>
        <caption>검사 정보_검사(예정)일자, 검사원 명으로 구성</caption>
        <colgroup>
          <col style="width:18%">
          <col style="width:32%">
          <col style="width:18%">
          <col style="width:32%">
        </colgroup>
        <tbody>
        <tr>
          <th scope="row">검사(예정)일자</th>
          <td>
            <div class="input_group">
              {{ qltyInspStatData.inspPrnmntYmd }}
            </div>
          </td>
          <th scope="row">검사원 명</th>
          <td>{{ qltyInspStatData.inspectors }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </template> -->
  <!-- 최하단 버튼 영역 -->
  <div class="btn_area">
    <div>
      <br-anchor-button v-if="!canModify" label="검사신청서 출력" size="md" format="" icon="ico_print" @click="printInspApplctnForm"/>
    </div>
    <div style="display: flex;gap: 5px;">
      <br-anchor-button v-if="canModify" label="저장" size="md" format="primary" @click="save" validate/>
      <br-anchor-button v-if="canModify" label="검사신청" size="md" format="primary line" @click="applyInsp"/>
      <br-anchor-button label="목록" size="md" format="tertiary" @click="goList"/>
    </div>
  </div>
  <qlty-insp-apply-hist-popup :inspAplySn="inspAplySn"/>
</template>

<script>
/**
 * 품질검사 현황의 상세(신청정보)에 대한 화면
 *
 * 작성자 : jhkim
 * 작성일 : 11/02/2024
 * 버전 : 1.0
 */
import {storeSwitch} from "@/js/store/store-switch";
import QltyInspApplyHistPopup from "@/views/sfli/components/popup/QltyInspApplyHistPopup.vue";

/**
 품질검사 검사신청정보 확인 및 처리 화면
 *
 * 작성자 : jhkim
 * 작성일 : 11/02/2024
 * 버전 : 1.0
 */
const API = {
  QLTY_INSP_STAT : "/qlty-insp-stat",
  FOR_APPLYING : "/qlty-insp-stat/apply",
}
const CODE = {
  INSP_SE_CD: 'COM429',
  // INSP_CPST_CD: 'SIS011',
}

export default {
  components: {QltyInspApplyHistPopup},
  emits: ['print-qlty-insp-report'],
  props: {
    inspAplySn: {type: String, required: true},
  },
  computed : {
    canModify() {
      return this.qltyInspStatData.inspPrgrsSttsCd === '00' || this.qltyInspStatData.inspPrgrsSttsCd === '30';
    },
    isInspOnGoing() {
      return this.qltyInspStatData.inspPrgrsSttsCd === '40' || this.qltyInspStatData.inspPrgrsSttsCd === '60' || this.qltyInspStatData.inspPrgrsSttsCd === '70';
    },
    allSelected: {
      //getter
      get: function() {
        //고형연료 품질검사인경우 제외되는 성분
        const excludeForSrf = ["10", "20", "30", "40", "50", "60", "70", "120", "190"];
        //고형연료 품질표시검사
        const excludeForSrfAndQltyIndct = ["190"];
        //바이오고형연료
        const excludeForBioSrf = ["10", "20", "30", "40", "50", "60", "70"];
        //고형연료 코드
        const srfCdList = ["0001", "0002"];
        //바이오고형연료 코드
        const bioSrfCdList = ["0003", "0004"];

        let inspCpstOptionsLength = this.inspCpstOtions.length;
        let inspCpstCdListLength = 0;

        if(this.qltyInspStatData.qltyInspArtclCd == '02-00-00'){ //수분항목만
          inspCpstOptionsLength = this.inspCpstOtions.filter(item => item.value === "160" || item.value === "200").length;
        }else if(this.qltyInspStatData.qltyIndctInspYn == 'Y'){ //품질표시검사 여부
          if(srfCdList.includes(this.qltyInspStatData.qltyInspItemCd)){ //고형연료
            inspCpstOptionsLength = this.inspCpstOtions.filter(item => !excludeForSrfAndQltyIndct.includes(item.value)).length;
          }else if(bioSrfCdList.includes(this.qltyInspStatData.qltyInspItemCd)){  //바이오고형연료
            inspCpstOptionsLength = this.inspCpstOtions.length;
          }
        }else{
          if(srfCdList.includes(this.qltyInspStatData.qltyInspItemCd)){ //고형연료
            inspCpstOptionsLength = this.inspCpstOtions.filter(item => !excludeForSrf.includes(item.value)).length;
          }else if(bioSrfCdList.includes(this.qltyInspStatData.qltyInspItemCd)){  //바이오고형연료
            inspCpstOptionsLength = this.inspCpstOtions.filter(item => !excludeForBioSrf.includes(item.value)).length;
          }
        }

        if(this.qltyInspStatData.inspCpstCdList != null && this.qltyInspStatData.inspCpstCdList != undefined){
          inspCpstCdListLength = this.qltyInspStatData.inspCpstCdList.length;
        }

        return inspCpstOptionsLength === inspCpstCdListLength;
      },
      //setter
      set: function(e) {
        if(e){
          //검사항목 자동 세팅
          this.setInspCpstCd(this.qltyInspStatData.qltyInspItemCd);
          this.qltyInspStatData.inspCpstCdList.push("200");
        }else{
          this.qltyInspStatData.inspCpstCdList = [];
        }

      },
    },
  },
  data() {
    return {
      qltyInspStatDataOriginal: [],
      qltyInspStatData: [],
      inspSeCdList: [],
      qltyInspArtclOpt: [
        {value: "01-00-00", text: "전체"},
        {value: "02-00-00", text: "수분항목만"},
      ],
      qltyIndctInspYnList: [
        {value: "Y", text: "신청"},
        {value: "N", text: "미신청"},
      ],
      isSelectedOnlyMoistureItem: false,
      // inspCpstCdOpt: [],
      // inspCpstCdList: [],
      allowedFileExtensions: ['txt', 'csv', 'log', 'jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'mp4', 'avi', 'mov', 'wmv', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'hwp', 'hwpx'],
      inspCpstBioMassDisabled: false,
      inspCpstOtions: [
        {value: "10", text: "안티몬(Sb)"},
        {value: "20", text: "코발트(Co)"},
        {value: "30", text: "구리(Cu)"},
        {value: "40", text: "망간(Mn)"},
        {value: "50", text: "니켈(Ni)"},
        {value: "60", text: "탈륨(Tl)"},
        {value: "70", text: "바나듐(V)"},
        {value: "80", text: "수은(Hg)"},
        {value: "90", text: "카드뮴(Cd)"},
        {value: "100", text: "납(Pb)"},
        {value: "110", text: "비소(As)"},
        {value: "120", text: "크로뮴(Cr)"},
        {value: "130", text: "황분 함유량(wt.%)"},
        {value: "140", text: "염소 함유량(wt.%)"},
        {value: "150", text: "모양 및 크기"},
        {value: "160", text: "수분 함유량(wt.%)"},
        {value: "170", text: "회분 함유량(wt.%)"},
        {value: "180", text: "(저위)발열량(kcal/kg)"},
        {value: "190", text: "바이오매스(wt.%)"},
        {value: "200", text: "기타 항목"},
      ],
    };
  },
  watch : {
    //품질검사항목
    "qltyInspStatData.qltyInspArtclCd": function(val){

        // const bExecSetInspCpst = this.qltyInspStatData.qltyIndctInspYn === "N" ? true : false;

        this.qltyInspStatData.qltyIndctInspYn = (val === "02-00-00") ? "N" : "Y";

        //품질확인검사여부가 이미 N으로 세팅되어 있으면 검사항목을 자동세팅하도록 한다.
        // if(bExecSetInspCpst){
        //   //검사항목 자동 세팅
        //   this.setInspCpstCd(this.qltyInspStatData.qltyInspItemCd);
        // }
        
    },
    'qltyInspStatData.qltyIndctInspYn'(newVal) {

      if( newVal === 'N' ) {
        this.qltyInspStatData.inspCpstCdList = [];
        this.qltyInspStatData.etcArtclNm = "";
      }
      
      //검사항목 자동 세팅
      // this.setInspCpstCd(this.qltyInspStatData.qltyInspItemCd);
    },
    
  },
  created() {},
  beforeMount (){},
  mounted() {
    this.initialize();
  },
  methods : {
    async initialize() {
      this.inspSeCdList = this.$store.getters.getCodeList({ groupCd: CODE.INSP_SE_CD, useYn: 'Y', frstRefVl: '20' }).map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
      // this.inspCpstCdOpt = this.$store.getters.getCodeList({ groupCd: CODE.INSP_CPST_CD, useYn: 'Y' }).map(item => ({
      //   text: item.dtlCdNm,
      //   value: item.dtlCd
      // }));
      this.search();
    },
    search() {
      this.$apiCall.get(`${API.QLTY_INSP_STAT}/${this.inspAplySn}`, {}, data => {
        if (data.result) {

          this.qltyInspStatData = data.result;
          this.qltyInspStatData.inspCpstCdList = this.qltyInspStatData.inspCpstCdList.map(item => item.inspCpstCd);
          this.qltyInspStatDataOriginal = {...data.result};


        } else {
          this.qltyInspStatData = [];
        }
        
      },
      data => {
        // console.log(data);
        alert(data.message);
        this.goList();
      });
    },
    save() {
      // if( this.isModified() ) {    // 첨부파일 변동사항 체크하기 어려움.. 일단 주석처리..
        if( this.hasValidRequired() ) {
          if(confirm("신청서를 임시저장하시겠습니까?")) {
            this.$refs.attachFileList.save().then(atchFileGroupId => {
              this.qltyInspStatData.atchFileGroupId = atchFileGroupId;
              this.$apiCall.post(
                  API.QLTY_INSP_STAT,
                  {...this.qltyInspStatData},
                  () => {
                    alert("신청서가 저장되었습니다.");
                    this.search();
                  },
                  (error) => {
                    alert(error.message);
                  }
              );
            });
          }
        }
      // } else {
      //   alert('변경 사항이 없습니다.');
      // }
    },
    applyInsp() {
      if( this.hasValidRequired() ) {
        if(confirm("품질검사신청하시겠습니까?")) {
          this.$apiCall.post(
              API.FOR_APPLYING,
              {inspAplySn: this.qltyInspStatData.inspAplySn},
              () => {
                alert("검사신청이 완료되었습니다.\n검사결과의 진행상태는 알림톡을 통해 안내됩니다.");
                // this.$router.go(-1);
                this.$router.push("/sfli/QltyInspStat");
              },
              (error) => {
                alert(error.message);
              }
          );
        }
      }else{
        alert("필수체크 된 항목을 입력하시고 저장 후 검사신청을 하여 주십시오.");
      }
    },
    goList() {
      // this.$router.go(-1);
      this.$router.push("/sfli/QltyInspStat");
    },
    popInspHist() {
      storeSwitch.on('QltyInspApplyHistPopup');
    },
    printInspApplctnForm() {
      this.$emit('print-qlty-insp-report', 'reportQltyInspAply');
    },
    // printSpcmPicPlan() {
    //   alert('시료채취계획서 출력')
    // },
    onChangedQltyInspArtclCd(param) {
      if(param.value && param.value === '02-00-00') {
        this.qltyInspStatData.qltyIndctInspYn = 'N';
        this.isSelectedOnlyMoistureItem = true;
        this.qltyInspStatData.inspCpstCdList = [];
      } else {
        this.isSelectedOnlyMoistureItem = false;
      }
    },
    isModified() {
      let isModified = false;

      for(const key of Object.keys(this.qltyInspStatData)) {
        if( key === 'inspCpstCdList') {
          if(this.qltyInspStatData.inspCpstCdList.length === this.qltyInspStatDataOriginal.inspCpstCdList.length) {
            // 품질표시검사 항목 수가 똑같다면, 개별 비교를 진행한다.
            for(const cpstCd of this.qltyInspStatData.inspCpstCdList) {
              if(this.qltyInspStatDataOriginal.inspCpstCdList.find(item => item === cpstCd)) {
                // 품질표시검사 항목이 존재한다 = 변경되지 않았다는 뜻..
              } else {
                // 품질표시검사 항목이 존재하지 않는게 있다 = 변경된 것으로 판단.
                isModified = true;
                break;
              }
            }
          } else {
            // 품질표시검사 항목 수 서로 다르다면, 변경된 것으로 판단.
            isModified = true;
          }
        } else {
          if( this.qltyInspStatData[key] !== this.qltyInspStatDataOriginal[key] ) {
            // 코드 값의 변화가 있다면, 변경된 것으로 판단.
            isModified = true;
          }
        }

        if(isModified) break;
      }

      return isModified;
    },
    hasValidRequired() {
      if( !this.qltyInspStatData.pyrNm ) {
        alert('입금(예정)자 명은 필수입력입니다.')
        return false;
      }

      if( !this.qltyInspStatData.inspHopeYmd ) {
        alert('검사희망일자는 필수입력입니다.')
        return false;
      }

      if(this.qltyInspStatData.inspCpstCdList == null || this.qltyInspStatData.inspCpstCdList.length == 0){
        alert("검사항목을 선택하여 주십시오.");
        return;
      }

      if(this.qltyInspStatData.inspCpstCdList.includes("200") && !this.qltyInspStatData.etcArtclNm){
        alert('품질표시검사항목 중 기타 항목이 선택되었습니다.\n기타항목 상세 내용을 입력하십시오.')
        return false;
      }

      return true;
    },
    setInspCpstCd(prdctnPrdctKndCd){

      this.qltyInspStatData.inspCpstCdList = [];

      ///바이오매스 검사항목 활성화여부
      this.inspCpstBioMassDisabled = (prdctnPrdctKndCd == '0003' || prdctnPrdctKndCd == '0004') ? false : true;
      
      if( this.qltyInspStatData.qltyInspArtclCd == "02-00-00"){
        //품질검사항목이 "수분항목만"일 경우 수분만 자동 선택
        this.qltyInspStatData.inspCpstCdList.push("160");
        return;
      }
      
      if(this.qltyInspStatData.qltyIndctInspYn == 'Y'){
        if(prdctnPrdctKndCd == '0001' || prdctnPrdctKndCd == '0002'){ //고형연료
          this.qltyInspStatData.inspCpstCdList = this.inspCpstOtions.filter(item => item.value !== "190" && item.value !== "200")
                                                 .map(item => item.value);
        }else if(prdctnPrdctKndCd == '0003' || prdctnPrdctKndCd == '0004'){ //바이오 고형연료
          this.qltyInspStatData.inspCpstCdList = this.inspCpstOtions.filter(item => item.value !== "200").map(item => item.value);
        }else{
          this.qltyInspStatData.inspCpstCdList = this.inspCpstOtions.map(item => item.value);
        }
      }else{
        if(prdctnPrdctKndCd == '0001' || prdctnPrdctKndCd == '0002'){ //고형연료
          this.qltyInspStatData.inspCpstCdList = this.inspCpstOtions
                                                 .filter(item => item.value !== "200"
                                                                 && item.value !== "190"
                                                                 && item.value !== "120"
                                                                 && item.value !== "10"
                                                                 && item.value !== "20"
                                                                 && item.value !== "40"
                                                                 && item.value !== "50"
                                                                 && item.value !== "60"
                                                                 && item.value !== "70"
                                                                 && item.value !== "30")
                                                 .map(item => item.value);
        }else if(prdctnPrdctKndCd == '0003' || prdctnPrdctKndCd == '0004'){ //바이오 고형연료
          this.qltyInspStatData.inspCpstCdList = this.inspCpstOtions
                                                 .filter(item => item.value !== "10"
                                                                 && item.value !== "20"
                                                                 && item.value !== "40"
                                                                 && item.value !== "50"
                                                                 && item.value !== "60"
                                                                 && item.value !== "70"
                                                                 && item.value !== "30"
                                                                 && item.value !== "200"
                                                                 )
                                                 .map(item => item.value);
        }else{
          this.qltyInspStatData.inspCpstCdList = this.inspCpstOtions.map(item => item.value);
        }
        
      }


    },
  }
}
</script>

<style scoped>

</style>