<template>
  <div class="cont_box">
    <div class="title_btn_area">
      <h3 class="title">상세 정보</h3>
    </div>
    <div class="board_write">
      <table>
        <caption>상세 정보 제목, 등록자, 등록일시, 내용, 이미지</caption>
        <colgroup>
          <col style="width:18%">
          <col style="width:32%">
          <col style="width:18%">
          <col style="width:32%">
        </colgroup>
        <tbody>
        <tr>
          <th scope="row" >제목</th>
          <td colspan="3">
            {{param.popupNm}}
          </td>
        </tr>
        <tr>
          <th scope="row" >등록자</th>
          <td >
<!--            {{param.rgtrNm}}-->
            관리자
          </td>
          <th scope="row" >등록일시</th>
          <td >
            {{param.regDt}}
          </td>
        </tr>
        <tr>
          <th scope="row">내용</th>
          <td colspan="3">
            {{param.popupCn}}
          </td>
        </tr>
        <tr>
          <th scope="row" >이미지</th>
          <td colspan="3">
            <span class="thumb" v-if="param.atchFiles"><img :src="getPopupImg(param.atchFiles)" alt="팝업 이미지" ></span>
            <span class="thumb" v-else><img src="@/assets/images/sub/img_error02.png" alt="팝업 이미지" ></span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="btn_area right">
      <br-button label="목록" format="tertiary line" panel="panel" @click="navigate()"/>
    </div>
  </div>
</template>

<script>

import {buildUri} from "@/js/utils/uri-builder";

const API = {
  POPUP: "/popupmanage",
  DOWNLOAD: "/api/file/download/{atchFileVldNo}"
};

export default {
  data() {
    return {
      bbsSn: "",
      param: {
        popupNm: "",
        popupCn: "",
        regDt: "",
        rgtrId: "",
        rgtrNm: "",
        atchFileGroupId: "",
        fileProcessingData: {},
        atchFiles: [],
      },
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.popupSn = this.$route.query.popupSn;
    this.read()
  },
  methods : {
    read() {
      this.$apiCall.get(API.POPUP+"/detail", {popupSn: this.popupSn}, this.success, this.error);
    },
    async success(data) {
      if (!data.result) {
        this.error();
        return;
      }

      if(data.result.useYn == "N"){
        alert("미사용 게시물 입니다.");
        this.navigate();
        return;
      }
      if(data.result.delYn == "Y"){
        alert("삭제된 게시물 입니다.");
        this.navigate();
        return;
      }

      const json = JSON.parse(JSON.stringify(data.result));
      this.param = json;
      console.log(this.param);
      // 이미지 로드 방식 변경으로 미사용
      // if(this.param.files){
      //   if(this.param.files.length > 0) {
      //     try{
      //       this.param.imgSrc = require("@/" + this.param.files[0].strgFilePathNm + "/" + this.param.files[0].strgFileNm);
      //     }catch(e){
      //       this.param.imgSrc = require(`@/assets/images/sub/img_error02.png`);
      //     }
      //   }
      //   this.$forceUpdate();
      // }
    },
    getPopupImg(atchFiles) {
      if (atchFiles.length === 0 || !atchFiles[0].atchFileVldNo) {
        return "";
      }

      let imageUrl = buildUri(API.DOWNLOAD, { atchFileVldNo : atchFiles[0].atchFileVldNo });

      return process.env.VUE_APP_ENV === 'local'
        ? `${process.env.VUE_APP_API_PROXY_URL}${imageUrl}`
        : `${imageUrl}`;
    },
    error() {
      alert("데이터를 가져오는 중 오류가 발생했습니다.");
    },
    navigate() {
      this.$router.push({path : `/cust-sprt/PopupManage`})
        .catch(() => {
            console.error("Navigation Error")
          }
        );
    },
  },
}
</script>

<style scoped>

</style>
