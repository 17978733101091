<template>
  <div class="modal visible" v-show="isOn">
    <div id="popup">
        <h1 class="popup_title">현지시설 검색</h1>
        <div class="popup_cont">
            <div class="board_info">
                <h2 class="title1">현지시설 검색</h2>
            </div>
            <div class="sch_form_wrap">
                <div class="sch_form row2">
                    <span>
                        <label for="">제조국</label>
                        <br-form-select label="제조국" class="form_select" :options="mnftrNtnCdList" v-model:value="searchParam.mnftrNtnCd"/>
                    </span>
                    <span>
                        <label for="" class="w120">사업자등록번호</label>
                        <br-form-input class="form_control cal" label="사업자등록번호" v-model="searchParam.ovrsBzmnIdntfNo" @enterKeyup="search" />
                    </span>
                    <span>
                        <label for="">제조업체명</label>
                        <br-form-input class="form_control cal" label="제조업체명" v-model="searchParam.bzentyNm" @enterKeyup="search" />
                    </span>
                </div>
                <div class="btn_area">
                    <br-anchor-button role="link" class="btn sm sch" label="조회" icon="icon_search" @click="search" />
                    <br-anchor-button role="link" class="btn sm txtN ico_refresh" label="새로고침"  icon="reset" @click="resetSearchParam" />                    
                </div>
            </div>
            <!-- 그리드 소계 및 버튼 영역 -->
            <div class="board_info">
              <p class="page">
                <span class="total">전체 <strong>{{ this.fcltDataCount }}</strong></span>
                <span>페이지 <strong>{{ this.searchParam.page }}/{{ this.maxPage }}</strong></span>
              </p>
            </div>
            <div class="board_list">
                <table>
                    <caption>게시판 목록-번호, 제목, 날짜, 등록자, 공개여부, 첨부로 구성</caption>
                    <colgroup>
                        <col style="width:5rem">
                        <col style="width:15rem">
                        <col style="width:23rem">
                        <col>
                        <col style="width:23rem">
                    </colgroup>
                    <thead>
                        <tr>
                            <th scope="col">선택</th>
                            <th scope="col">제조국</th>
                            <th scope="col">제조업체명</th>
                            <th scope="col">제조시설주소</th>
                            <th scope="col">사업자번호</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in ovrsMnftrFcltList" v-bind:key="index">
                            <td aria-label="선택" style="padding: .6rem 1.2rem">
                                <button type="button" class="btn xsm secondary" @click="selectedMnftrFclt(index)">선택</button>
                            </td>
                            <td aria-label="제조국" style="padding: .6rem 1.2rem">{{ item.mnftrNtnNm }}</td>
                            <td aria-label="제조업체명" class="txt_left ellipsis" style="padding: .6rem 1.2rem">
                              <div @mouseover="showTooltip(index, 'bzentyNm')" @mouseleave="hideTooltip()">{{ item.bzentyNm }}</div>
                              <span class='tool_tip' v-show="isTooltipVisible && tooltipIndex === index && tooltipField === 'bzentyNm'">
                                {{ item.bzentyNm }}
                              </span>
                            </td>
                            <td aria-label="제조시설주소" class="txt_left ellipsis" style="padding: .6rem 1.2rem">
                              <div @mouseover="showTooltip(index, 'fcltAddr')" @mouseleave="hideTooltip()">{{ item.fcltAddr }}</div>
                              <span class='tool_tip' v-show="isTooltipVisible && tooltipIndex === index && tooltipField === 'fcltAddr'">
                                {{ item.fcltAddr }}
                              </span>
                            </td>
                            <td aria-label="사업자번호" style="padding: .6rem 1.2rem">{{ item.ovrsBzmnIdntfNo }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- 그리드 페이징 영역 -->
            <insp-pagination :total="fcltDataCount" :size="searchParam.size" v-model:page="searchParam.page" v-model:maxPage="maxPage" @clickedPage="search"></insp-pagination>
            <div class="btn_area center">
                <button type="button" class="btn md" @click="hide">닫기</button>
            </div>
        </div>
        <button type="button" class="popup_close" @click.prevent="hide">팝업 닫기</button>
    </div>
  </div>
</template>

<script>
  import { storeSwitch } from "@/js/store/store-switch";
  import inspPagination from "@/views/sfli/components/inspPagination.vue";

  const CODE = {
    MNFTR_NTN_CD: "COM415", // 국가코드
  }

  const API = {
    OVRS_INCM_FCLT: "/incmQltyInspAply/ovrsmnftrfclt",
  };

  const PAGE_SIZE = 5;

  export default {
    components: {inspPagination},
    props: {
      bplcId: {type: String, required: true},
    },
    emits: ["select-button-clicked" ],
    expose: ['reset'],
    data() {
      return {
        isOn: false,
        searchParam: {
          bzentyNm: "",
          bplcId: "",
          bplcNm: "",
          fcltNm: "",
          mnftrNtnCd: "",
          ovrsBzmnIdntfNo: "",
          page: 1,
          size: 1,
        },
        maxPage: 1,
        fcltDataCount: 0,
        rowData: [],
        ovrsMnftrFcltList: [
          {
            bplcId: "",
            incmFcltSn: "",
            bzentyNm: "",
            mnftrNtnCd: "",
            ovrsBzmnIdntfNo: "",
            mnftrNtnNm: "",
            ctyNm: "",
            fcltAddr: "",
            prdctCstdyPlcCn: "",
            srfPtYn: "",
            srfNptYn: "",
            bioSrfPtYn: "",
            bioSrfNptYn: "",
            mnfqty: "",
          }
        ],
        mnftrFcltInfo: {
          bplcId: "",
          incmFcltSn: "",
          bzentyNm: "",
          mnftrNtnCd: "",
          ovrsBzmnIdntfNo: "",
          mnftrNtnNm: "",
          ctyNm: "",
          fcltAddr: "",
          prdctCstdyPlcCn: "",
          srfPtYn: "",
          srfNptYn: "",
          bioSrfPtYn: "",
          bioSrfNptYn: "",
          mnfqty: "",
        },
        mnftrNtnCdList: [],
        isTooltipVisible: false,
        tooltipIndex: null,
        tooltipField: null
      }
    },
    computed: {},
    watch: {
      isOn(newVal) {
        if(!newVal) {
          this.reset();
        } else {
          //목록 리셋
          this.ovrsMnftrFcltList = [];
          this.search();
        }
      }
    },
    created() {
      storeSwitch.setBulb("ovrsIncmMnftrFcltPopup", this);
    },
    beforeMount() {
    },
    mounted() {
      // this.initialize();
    },
    beforeUnmount() {},
    methods: {
      /** 팝업 Show */
      show() {
        this.isOn = true;
      },
      /** 팝업 Hide */
      hide() {
        this.isOn = false;
      },
      /** 화면 초기화 */
      async initialize() {

        // 공통코드 초기화
        const mnftrNtnCdList = this.$store.getters.getCodeList({ groupCd: CODE.MNFTR_NTN_CD });         // 제조국가코드
        this.mnftrNtnCdList = mnftrNtnCdList.map(item => ({
          text: item.dtlCdNm,
          value: item.dtlCd
        }));
        this.mnftrNtnCdList.unshift({ text: "전체", value: "" });

      },
      /** 데이터 조회 */
      search() {
        //수입업체 사업장아이디 세팅
        this.searchParam.bplcId = this.bplcId;

        this.searchParam.size = PAGE_SIZE;

        this.$apiCall.post(API.OVRS_INCM_FCLT
                            , this.searchParam
                            , this.success
                            , this.error);
      },
      success(data) {
        if (!data.result) {
          this.error();
          return;
        }
        
        this.fcltDataCount = data.result.total;
        
        this.ovrsMnftrFcltList = data.result.ovrsMnftrFcltList;      
      },
      error() {
        alert('warning', "데이터를 가져오는 중 오류가 발생했습니다.");
      },
      /* 검색조건 초기화 */
      resetSearchParam(){
        for (const key of Object.keys(this.searchParam)) {
          if(["bzentyNm", "mnftrNtnCd", "ovrsBzmnIdntfNo"].includes(key)){
            this.searchParam[key] = '';
          }
        }

         //목록 리셋
         this.ovrsMnftrFcltList = [];
          this.search();
      },
      /** 데이터 초기화 */
      reset() {
        //검색조건
        this.resetSearchParam();
        this.rowData = [];

        //선택된 해외제조시설 정보
        this.mnftrFcltInfo = {};

        this.initialize();

      },
      /* 제조시설 선택 */
      selectedMnftrFclt(index){
        this.mnftrFcltInfo = this.ovrsMnftrFcltList[index];

        this.$emit('select-button-clicked', this.mnftrFcltInfo);

        this.hide();

      },
      showTooltip(index, field) {
        this.tooltipIndex = index;
        this.tooltipField = field;
        this.isTooltipVisible = true;
      },
      hideTooltip() {
        this.isTooltipVisible = false;
        this.tooltipIndex = null;
        this.tooltipField = null;
      }
    },
  }
</script>