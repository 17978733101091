<template>
  <div v-if="isVisible" class="modal visible">
    <div class="popup_box" tabindex="0">
      <h1 class="popup_title">신규 실적등록</h1>
      <div class="popup_cont">
        <div class="board_write">
          <table>
            <caption>공급자_대표자, 사용 시설명, 업소명, 사용연료종류, 사용량, 사용기간으로 구성</caption>
            <colgroup>
              <col style="width: 20%" />
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">사업장</th>
                <td>
                  <br-form-select label="사업장" v-model:value="searchParam.bplcId" :options="bplcList" />
                </td>
              </tr>
              <tr>
                <th scope="row">실적연월</th>
                <td>
                  <div class="input_group">
                    <br-form-select label="실적연도" custom-class="form_select w150" v-model:value="searchParam.prfmncYr" :options="prfmncYrList" />
                    <br-form-select label="실적월" custom-class="form_select w150" v-model:value="searchParam.prfmncMm" :options="prfmncMmList" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="btn_area center">
          <br-button label="등록" size="md" format="primary" @click="register" />
          <br-button label="닫기" size="md" @click="hide" />
        </div>
        <button type="button" class="modal_close" @click="hide">팝업 닫기</button>
      </div>
    </div>
  </div>
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";
import SbGrid from "@/components/SbGrid.vue";

const API = {
  MEMBER_BPLC: "/member/bplc-list",
  BPLC_MNFTR_DCLR: "/member/bplc-mnftr_dclr",
  PERFORMANCE_REGISTER_POSIBILITY_IDENTIFY: "/prfmnc/reg-psblty-idnty",
}
export default {
  components: {SbGrid},
  emits: ['prfmnc-reg'],
  data() {
    return {
      isVisible: false,
      searchParam: {
        bplcId: "",
        prfmncYr: "",
        prfmncMm: "",
      },
      prfmncYrList: [],
      prfmncMmList: [],
      bplcList: [],
    };
  },
  created() {
    storeSwitch.setBulb('PrfmncNewRegIdntyPopup', this);
    this.initialize();
  },
  methods: {
    initialize() {
      this.initYearMonthOptions();
    },
    initYearMonthOptions() {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 1); // 현재 날짜에서 -1개월로 설정
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();

      this.prfmncYrList = Array.from({ length: currentYear - 2012 }, (_, i) => ({
        text: `${currentYear - i}년`,
        value: (currentYear - i).toString(),
      }));

      this.prfmncMmList = Array.from({ length: 12 }, (_, i) => ({
        text: `${i + 1}월`,
        value: (i + 1).toString().padStart(2, '0'),
      }));

      this.setPreviousMonth(currentMonth, currentYear);
    },
    loadMemberBplc() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.MEMBER_BPLC, {}, data => {
          if (data.result) {
            this.bplcList = data.result.bplcList
              .filter(item => item.bplcSttsCd !== '03')   //2025.03.06 폐업 사업장은 리스트에서 제외
              .map(item => ({
                text: item.bplcNm,
                value: item.bplcId,
                bplcKndCd: item.bplcKndCd,
              }));

            if (this.bplcList.length > 0) {
              this.searchParam.bplcId = this.bplcList[0].value;
            }
            resolve();
          } else {
            reject(new Error("Failed to load member bplc."));
          }
        });
      });
    },
    register() {
      this.$apiCall.post(
          API.PERFORMANCE_REGISTER_POSIBILITY_IDENTIFY,
          this.searchParam,
          data => {
            if (data.result) {
              this.hide();
              this.$emit('prfmnc-reg', this.searchParam);
            }
          },
          (error) => {
            alert(error.message);
          }
      );
    },
    setPreviousMonth(currentMonth, currentYear) {
      if (currentMonth === 0) {
        this.searchParam.prfmncYr = (currentYear - 1).toString();
        this.searchParam.prfmncMm = '12';
      } else {
        this.searchParam.prfmncYr = currentYear.toString();
        this.searchParam.prfmncMm = (currentMonth).toString().padStart(2, '0');
      }
    },
    async show() {
      await this.loadMemberBplc();
      const currentDate = new Date();
      this.setPreviousMonth(currentDate.getMonth(), currentDate.getFullYear());

      document.documentElement.style.overflow = 'hidden';
      this.isVisible = true;
    },
    hide() {
      this.isVisible = false;
      document.documentElement.style.overflow = '';
    },
  }
}
</script>

<style scoped>
.popup_box {
  max-width: 55rem;
}
.btn_area {
  margin: 1.2rem 0 0;
}
</style>