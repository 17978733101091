<template>
  <div class="modal visible" v-show="isOn">
    <div id="popup" style="width: 70%; max-width: 1000px;">
      <h1 class="popup_title">검사연기</h1>
      <div class="popup_cont">
        <div class="desc_box progress_box">
          <i class="icon"><img src="@/assets/images/common/ico_noti.png" alt="이미지"></i>
          <div class="progress">
            <div><strong>검사연기 신청기준 : 검사일 3일 전까지 요청한 경우 (공휴일 제외)</strong></div>
          </div>
        </div>
        <div class="board_write">
          <table>
            <caption>검사연기신청_변경일자, 증빙서류, 신청사유로 구성</caption>
            <colgroup>
              <col style="width:18%">
              <col style="width:82%">
            </colgroup>
            <tbody>
            <tr>
              <th scope="row">변경일자</th>
              <td><br-date-picker label="변경일자" v-model:value="inspExtentionApply.chgYmd" futureonly/></td>
            </tr>
            <tr>
              <th scope="row">증빙서류</th>
              <td>
                <div class="file_upload_list">
                  <br-attach-file-list label="품질검사 검사연기 신청 첨부파일 목록"
                                       ref="attachFileList"
                                       atchFileTaskSeCd="EXT"
                                       v-model:atchFileGroupId="inspExtentionApply.atchFileGroupId"
                                       :useEtcFile="false"
                                       :maxFileCount="3"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">신청사유</th>
              <td><br-form-input label="신청사유" type="text" v-model="inspExtentionApply.inspSpnsAplyRsn"/></td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="btn_area center">
          <button type="button" class="btn md primary" @click="apply">신청</button>
          <button type="button" class="btn md" @click="hide">닫기</button>
        </div>
      </div>
      <button type="button" class="popup_close" @click="hide">팝업 닫기</button>
    </div>
  </div>
</template>

<script>
/**
 * 품질검사 검사연기 팝업 화면
 *
 * 작성자 : jhkim
 * 작성일 : 11/03/2024
 * 버전 : 1.0
 */
import { storeSwitch } from "@/js/store/store-switch";
import moment from "moment/moment";

const API = {
  INSP_EXTEND : "/qlty-insp-stat/extend",
}

export default {
  components: {},
  props: {
    inspAplySn: {type: String, required: true},
  },
  emits: ['refresh-data'],
  expose: ['show'],
  data() {
    return {
      isOn: false,
      inspExtentionApply: {
        chgYmd: moment().format('YYYY-MM-DD'),
        atchFileGroupId: '',
        inspSpnsAplyRsn: '',
      },
    }
  },
  computed: {},
  watch: {},
  created() {
    storeSwitch.setBulb("QltyInspExtensionApplyPopup", this);
  },
  beforeMount() {},
  mounted() {},
  beforeUnmount() {},
  methods: {
    show() {
      this.isOn = true;
    },
    hide() {
      this.isOn = false;
    },
    apply() {
      if( this.canApply() ) {
        if( confirm("검사연기 신청하시겠습니까?") ) {
          this.$refs.attachFileList.save().then(atchFileGroupId => {
            this.inspExtentionApply.atchFileGroupId = atchFileGroupId;
            this.$apiCall.post(
                `${API.INSP_EXTEND}/${this.inspAplySn}`,
                {...this.inspExtentionApply, inspAplySn: this.inspAplySn},
                () => {
                  alert("검사연기 신청이 정상적으로 처리 되었습니다.");

                  //검사결과 탭 데이터 REFRESH
                  this.$emit('refresh-data', null);

                  this.hide();
                },
                (error) => {
                  alert(error.message);
                }
            );
          });
        }
      }
    },
    canApply() {
      if( !this.inspExtentionApply.chgYmd ) {
        alert('변경일자를 선택해주세요.')
        return false;
      }

      if( !this.inspExtentionApply.inspSpnsAplyRsn ) {
        alert('신청사유를 입력해주세요.')
        return false;
      }

      return true;
    },
  },
}
</script>

<style scoped>

</style>