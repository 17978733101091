class FMFileItem {
    constructor() {
        this.id = "";
        this.atchFileGroupId = "";
        this.atchFileSqno = "";
        this.atchFileVldNo = "";
        this.type = "";
        this.name = "";
        this.ext = "";
        this.lock = false;
        this.size = -1;
        this.friendlySize = "";
        this.mdate = null;
        this.status = "WAIT";
        this.ofile = null;
    }

    toString() {
        return "[object FMFileItem]";
    }

    toContentString() {
        return `${this.id} ${this.type} ${this.status}`;
    }
}

class FMFileList extends Array {
    constructor() {
        super();
        this.totalSizeA = 0;
        this.totalSizeL = 0;
        this.virtualLength = 0;
        this.fileMap = new Map();
    }

    update() {
        this.totalSizeA = 0;
        this.totalSizeL = 0;
        this.virtualLength = 0;
        for (let item of this) {
            if ((this.toString() !== "[object FMFileItem]")) continue;

            if (item.size > 0) {
                this.totalSizeA += item.size;
            }

            switch (item.type) {
                case "FILE":
                    if (item.ofile) {
                        this.totalSizeL += item.ofile.size;
                    }
                    break;
                case "VIRTUAL":
                    this.virtualLength++;
                    break;
                default:
                    break;
            }
        }
    }

    onchanged() {
        this.update();
    }

    push(item) {
        if (item.toString() === "[object FMFileItem]") {
            super.push(item);
            this.fileMap.set(item.id, item);
            this.onchanged({state: "add", target: item});
        } else {
            console.log(`item is not FMFileItem => ${item.toString()}`);
        }
    }

    pop() {
        let item = null;
        while (this.length > 0) {
            item = super.pop();
            if (item.toString() === "[object FMFileItem]") {
                this.fileMap.delete(item.id);
                this.onchanged({ state: "delete", target: item });
                return item;
            }
        }
        return null;
    }

    shift() {
        let item = null;
        while (this.length > 0) {
            item = super.shift();
            if (item.toString() === "[object FMFileItem]") {
                this.fileMap.delete(item.id);
                this.onchanged({ state: "shift", target: item });
                return item;
            }
        }
        return null;
    }

    getTotalSize(onlyLocal = false) {
        return onlyLocal ? this.totalSizeL : this.totalSizeA;
    }

    getLength(onlyLocal = false) {
        return onlyLocal ? this.length - this.virtualLength : this.length;
    }

    getItem(id) {
        return this.fileMap.get(id) || null;
    }

    removeById(id) {
        const item = this.getItem(id);
        if (item) {
            const index = this.indexOf(item);
            if (index !== -1) {
                this.splice(index, 1);
                this.fileMap.delete(id);
                this.onchanged({ state: "delete", target: item });
            }
        }
    }

    removeByIndex(index) {
        if (index < this.length) {
            const [removedItem] = this.splice(index, 1);
            this.fileMap.delete(removedItem.id);
            this.onchanged({ state: "delete", target: removedItem });
        }
    }

    removeByObject(obj) {
        const index = this.indexOf(obj);
        if (index !== -1) {
            const [removedItem] = this.splice(index, 1);
            this.fileMap.delete(removedItem.id);
            this.onchanged({ state: "delete", target: removedItem });
        }
    }

    clear(flag) {
        this.forEach((item, index) => {
            if (item.type === flag) {
                this.splice(index, 1);
                this.fileMap.delete(item.id);
            }
        });
        this.onchanged({ state: "clear", target: null });
    }
}

export { FMFileItem, FMFileList };