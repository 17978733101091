<template>
  <button v-if="onlnMnlCn" type="button" @click.prevent="showManual">도움말</button>
  <div class="menu_help" :class="{active : isVisible}">
    <h3>{{ menuNm }} 도움말</h3>
    <div class="help_cont" v-html="onlnMnlCn"></div>
    <button type="button" class="btn_close" @click.prevent="hideManual">닫기</button>
  </div>
</template>

<script>
const API = {
  ONLINE_MANUAL : "/online-manual",
}

export default {
  props: {
    menuSn: {type: Number, default: 0},
    menuNm: {type: String, required: true},
  },
  data() {
    return {
      isVisible: false,
      onlnMnlCn: ''
    };
  },
  watch: {
    menuSn() {
      this.initialize();
    }
  },
  created() {
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (!this.menuSn) {
        this.onlnMnlCn = '';
        return;
      }

      this.$apiCall.get(API.ONLINE_MANUAL + "/" + this.menuSn, {}, this.success, this.error);
    },
    success(data) {
      if (!data.result) {
        return;
      }

      this.onlnMnlCn = data.result.onlnMnlCn;
    },
    error() {
      console.log("메뉴얼 데이터를 가져오는 중 오류가 발생했습니다.");
    },
    showManual() {
      this.isVisible = true;
    },
    hideManual() {
      this.isVisible = false;
    }
  }
}
</script>

<style scoped>

</style>