<template>
  <!-- s: 콘텐츠 영역 -->
  <div class="sch_form_wrap">
    <div class="sch_form row2">
      <span>
        <label>구분</label>
        <br-form-select label="구분" v-model:value="Param.searchType" :options="searchTypelist" allSelectable />
      </span>
      <span>
        <label>검색어</label>
        <br-form-input label="검색어 입력" v-model="Param.keyword" @enterKeyup="search" />
      </span>
      <span>
        <label>신청여부</label>
        <br-form-select label="신청여부" :options="eduAplySttsCdOptions" v-model:value="Param.eduAplySttsCd" allSelectable @enterKeyup="search" />
      </span>
      <span>
        <label>수료여부</label>
        <br-form-radio label="수료여부"  v-model:value="Param.eduFnshYn" :options="eduFnshYnlist" allSelectable @enterKeyup="search" />
      </span>
      <span>
        <label>교육기간</label>
        <div class="input_group">
            <br-date-picker label="교육기간" v-model:value="Param.eduYmd" @enterKeyup="search" />
        </div>
      </span>
    </div>
    <div class="btn_area">
      <br-anchor-button role="button" label="검색" size="sm" format="sch" @click="search" />
      <br-anchor-button role="button" label="새로고침" size="sm" format="txtN" icon="ico_refresh" @click="reset" />
    </div>
  </div>

  <div class="board_info">
    <p class="page">
      <span class="total">전체 <strong>{{ Number(list.DataCount).toLocaleString() }}</strong></span>
      <span>페이지 <strong>{{ this.searchParam.page }}/{{ this.maxPage }}</strong></span>
    </p>
  </div>
  <div class="online_edu_list">
    <a role="link" href="javascript:void(0);" v-for="(item, index) in list" :key="index" @click="educationInq(item)">
      <div class="c_tit">{{ item.eduNm }} <span class="badge" v-if="item.eduAplySttsCd">{{ getAplySttsText(item.eduAplySttsCd) }}</span></div>
      <div class="c_date">{{ item.eduYmd }}</div>
      <div v-if="isInDateRange(item.eduYmd) && item.eduAplySttsCd === '04'" :class="['c_state', item.eduFnshYn === 'Y' ? 'primary' : '']">
        {{ item.eduFnshYn === 'Y' ? '수료' : '학습중' }}
      </div>
    </a> 
  </div>
  <!-- 그리드 페이징 영역 -->
  <insp-pagination :total="list.DataCount" :size="searchParam.size" v-model:page="searchParam.page" v-model:maxPage="maxPage" @clickedPage="readOnlineEducation"></insp-pagination>
</template>

<script>
/**
 * 온라인교육(강좌) 조회 화면입니다.
 *
 * 작성자 : 정미라
 * 작성일 : 24. 10. 18.
 * 버전 : 1.0
 */
 import {storeSwitch} from "@/js/store/store-switch";
 import inspPagination from "@/views/sfli/components/inspPagination.vue";

 const API = {
    ONLINE_EDUCATION : "/online-education"
 }
 const PAGE_SIZE = 8;

export default {
  components: {inspPagination},
  data() {
    return {
      searchParam: {
        page: 1
      },
      Param :{
        searchType : "",    // 검색어 구분값
        keyword : "",       // 검색어 
        eduSn : "",         // 강좌일련번호
        eduNm : "",         // 강좌명
        eduAplySttsCd : "", // 신청코드
        eduYmd : "",        // 교육기간
        eduFnshYn : ""      // 수료여부
      },
      searchTypelist : [
        { value: "01", text: "강좌명" },
        { value: "02", text: "강좌설명" }
      ],
      eduFnshYnlist : [
        { value: "", text: "전체" },
        { value: "Y", text: "수료" },
        { value: "N", text: "학습중" },
      ],
      // 신청상태
      eduAplySttsCdOptions: [
        { value: "", text: "선택", disabled: true  }, 
        { value: "01", text: "신청중" },
        { value: "02", text: "일정취소" },
        { value: "03", text: "신청취소" },
        { value: "04", text: "승인완료" }
      ],
      maxPage: 1,
      DataCount: 0,
      list: [] 
    };
  },
  beforeMount (){},
  mounted() {
    this.readOnlineEducation();
  },
  beforeUnmount() {},
  methods : {
    readOnlineEducation() {
      this.searchParam.size = PAGE_SIZE;
      this.$apiCall.get(API.ONLINE_EDUCATION, this.searchParam, this.readOnlineEducationSuccess, this.error);
    },
    readOnlineEducationSuccess(data) {
      if (!data || !data.result || !data.result.list) {
        this.error();
        return;
      }
      this.list = data.result.list; 
      this.list.DataCount = data.result.total;
    },
    error() {
      alert("데이터를 가져오는 중 오류가 발생했습니다.");
    },
    navigateTo(path) {
      if (path) {
        this.$router.push(path).catch(() => {
          console.error("Navigation Error");
        });
      }
    },
    // 현재 날짜가 교육 기간 내에 있는지 확인하는 메서드
    isInDateRange(eduYmd) {
        const [startDateStr, endDateStr] = eduYmd.split(" ~ ");
        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);
        const currentDate = new Date();

        // 날짜만 비교하기 위해 시간 정보를 제거
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999); // 당일 끝까지 포함
        currentDate.setHours(0, 0, 0, 0);

        return currentDate >= startDate && currentDate <= endDate;
    },
    educationInq(item) {
      // 날짜 범위를 `isInDateRange`로 확인
      if (!this.isInDateRange(item.eduYmd)) {
          alert("교육기간이 아닙니다.");
          return;
      }
      if (!item || !item.eduSn) {
        storeSwitch.alert("warning", "온라인교육 데이터가 존재하지 않습니다.");
        return;
      }

      this.$router.push({path : "/cust-mng/online-education/OnlineEducationDetail", query : {eduSn : item.eduSn}}).catch(() => {
        console.error("Navigation Error");
      });
    },
    reset() {
      this.Param.searchType = "",
      this.Param.keyword = "",
      this.Param.eduYmd = "",
      this.Param.eduAplySttsCd = "",
      this.Param.eduFnshYn = ""
      this.search();
    },
    search() {
      const params = {
        searchType: this.Param.searchType,
        keyword: this.Param.keyword,
        eduYmd: this.Param.eduYmd,
        eduAplySttsCd: this.Param.eduAplySttsCd,
        eduFnshYn: this.Param.eduFnshYn,
        TEST: this.Param.TEST
      };

      // 쿼리스트링으로 변환된 파라미터를 API에 전달
      const queryString = new URLSearchParams(params).toString();

      this.$apiCall.get(`${API.ONLINE_EDUCATION}?${queryString}`, {}, this.readOnlineEducationSuccess, this.error);
    },
    getAplySttsText(eduAplySttsCd) {
      switch (eduAplySttsCd) {
        case '01':
          return '신청중';
        case '02':
          return '일정취소';
        case '03':
          return '신청취소';
        case '04':
          return '승인완료';
        default:
          return '상태없음';
      }
    }
  }
};
</script>

<style scoped>
  .form_select {
    max-width: none;
  }
</style>