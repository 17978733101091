<template>
  <div class="member_wrap">
    <ol class="join_step_wrap">
      <li>
        <i class="step1"></i>
        <span>회원선택</span>
      </li>
      <li class="active">
        <i class="step2"></i>
        <span>가입여부 확인</span>
      </li>
      <li>
        <i class="step3"></i>
        <span>약관동의</span>
      </li>
      <li>
        <i class="step4"></i>
        <span>사용자정보 입력</span>
      </li>
      <li>
        <i class="step5"></i>
        <span>가입완료</span>
      </li>
    </ol>

    <h2 class="member_title">가입여부 확인</h2>
    <div class="tit_box">본인인증을 통해 가입여부를 체크합니다.</div>
    <div class="type_area">
      <a href="javascript:void(0);" class="type_box">
        <i class="mobile"></i>
        <h3>휴대폰 본인인증</h3>
        <p>법인 명의 및 타인 명의의 휴대폰으로는 인증이 불가합니다</p>
        <span class="btn sm tertiary" @click.prevent="identityAuthOpen">인증하기</span>
      </a>
    </div>
  </div>
  <!-- 본인인증 -->
  <identity-auth-modal :isPopup="false" usageCode="01001" @callback="identityAuthCallback" @fail="identityAuthFail"/>
</template>

<script>
import IdentityAuthModal from "@/components/IdentityAuthModal.vue";
import {storeSwitch} from "@/js/store/store-switch";
import storeRegister from "@/js/store/store-register";

const API = {
  AUTH : "/join/authUser",
}

export default {
  components: {IdentityAuthModal},
  data() {
    return {
      brnoOpen: false,
      param : {
        brno : "",
        userMngSe : "",   // 시설/업무 사용자 구분
        exsBzentyYn : "",
        provider: "",
      },
      authParam : {
        di: "",
        userNm: "",
        mblTelno: "",
        brdt: "",
        ci: "",
        dupChk: "",
        dupChkMng: "",
      },
      isUser: "",
    };
  },
  computed: {
  },
  watch: {},
  created() {
  },
  beforeMount() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  },
  mounted() {
  },
  methods: {
    navigateTo(path) {
      if (path) {
        this.$router.push(path)
            .catch(() => {
              console.error("Navigation Error");
            });
      }
    },
    identityAuthOpen() {
      storeSwitch.on('IdentityAuthModal');
    },
    identityAuthCallback(result) {
      storeRegister.dispatch('updateJoinUserInfo', {provider: result.provider}); // user정보
      this.isUser = result.isUser;

      this.read();
    },
    read() {
      if(storeRegister.state.joinUserInfo.userMngSe === "task"){
        storeRegister.dispatch('updateJoinUserInfo', {brno: ''}); // user정보
      }
      this.$apiCall.get(API.AUTH, {provider : storeRegister.state.joinUserInfo.provider, brno : storeRegister.state.joinUserInfo.brno}, this.success, this.error);
    },
    success(data) {
      if (!data.result) {
        this.error();
        return;
      }

      this.authParam = data.result;

      if(this.isUser){
        if(storeRegister.state.joinUserInfo.userMngSe === "user" && this.authParam.dupChk){
          alert("이미 가입된 사용자입니다.");
          return;
        }else if(storeRegister.state.joinUserInfo.userMngSe === "task"){ // dupChkMng 제거 (구동환 과장 25.02.13)
          alert("이미 가입된 사용자입니다.");
          return;
        }else {
          alert("인증이 성공적으로 완료되었습니다.");
        }
      }else {
        alert("인증이 성공적으로 완료되었습니다.");
      }

      const authUserRes = {
        di: data.result.di,
        ci: data.result.ci,
        userNm: data.result.userNm,
        mblTelno: data.result.mblTelno,
        brdt: data.result.brdt,
      };

      storeRegister.dispatch('updateJoinUserInfo', authUserRes); // user정보
      storeRegister.dispatch('nextStep'); // 회원가입 단계처리
      this.navigateTo(`/user/TermsAgre`);
    },
    error() {
      alert('warning', "데이터를 가져오는 중 오류가 발생했습니다.");
    },
    identityAuthFail(error) {
      console.log("identityAuthFail", error);
    },
  }
}
</script>

<style scoped>

</style>