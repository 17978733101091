<template>
  <h2 class="title1" style="margin-top: 0px;">처분대상 정보</h2>
  <div class="board_write">
    <table>
      <caption>처분대상 정보</caption>
      <colgroup>
        <col style="width:18%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:32%">
      </colgroup>
      <tbody>
        <tr>
          <th scope="row">대상 유형</th>
          <td colspan="3">{{ param.admdspTrgtNm }}</td>
        </tr>
        <tr>
          <th scope="row" colspan="4" style="text-align: center;">업체</th>
        </tr>
        <tr>
          <th scope="row">업체 명</th>
          <td>{{ param.bzentyNm }}</td>
          <th scope="row">대표자 명</th>
          <td>{{ param.rprsvNm }}</td>
        </tr>
        <tr>
          <th scope="row">사업자등록번호</th>
          <td colspan="3">{{ param.brno }}</td>
        </tr>
        <tr>
          <th scope="row">업체 주소</th>
          <td colspan="3">{{ param.bzentyAddr }}</td>
        </tr>
        <template v-if="['02', '03'].includes(param.admdspTrgtCd)">
          <tr>
            <th scope="row" colspan="4" style="text-align: center;">사업장</th>
          </tr>
          <tr>
            <th scope="row">사업장 명</th>
            <td>{{ param.bplcNm }}</td>
            <th scope="row">사업장 종류</th>
            <td>{{ param.bplcKndNm }}</td>
          </tr>
          <tr>
            <th scope="row">사업장 주소</th>
            <td colspan="3">{{ param.bplcAddr }}</td>
          </tr>
        </template>
        <template v-if="param.admdspTrgtCd === '03'">
          <tr>
            <th scope="row" colspan="4" style="text-align: center;">시설</th>
          </tr>
          <tr>
            <th scope="row">시설 명</th>
            <td>{{ param.fcltNm }}</td>
            <th scope="row">생산제품 종류</th>
            <td>{{ param.prdctnPrdctKndNm }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
  <h2 class="title1">개선/금지명령</h2>
  <div class="board_write">
    <table>
      <caption>개선/금지명령</caption>
      <colgroup>
        <col style="width:18%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:32%">
      </colgroup>
      <tbody>
        <!-- 처분종류, 명령일자 -->
        <tr>
          <th scope="row">명령일자</th>
          <td>{{ param.admdspYmd }}</td>
          <th scope="row">처분종류</th>
          <td>{{ param.admdspNm ? param.admdspKndNm + ' > ' + param.admdspNm : '' }}</td>
        </tr>
        <!-- 개선/금지 기간         // 개선명령(1010), 금지명령(1020) -->
        <template v-if="['1010', '1020'].includes(param.admdspCd)">
          <tr v-if="param.impvProhbtBgngYmd && param.impvProhbtEndYmd">
            <th scope="row">개선/금지 기간</th>
            <td colspan="3">{{ param.impvProhbtBgngYmd }}~{{ param.impvProhbtEndYmd }}</td>
          </tr>
        </template>
        <!-- 고발일자, 관할 경찰청   // 금지명령(1020), 벌금(2010) -->
        <template v-if="['1020', '2010'].includes(param.admdspCd)">
          <tr v-if="param.acstnYmd || param.cmptncPolstnNm">
            <th scope="row">고발일자</th>
            <td>{{ param.acstnYmd }}</td>
            <th scope="row">관할 경찰청</th>
            <td>{{ param.cmptncPolstnNm }}</td>
          </tr>
        </template>
        <!-- 이행확인 예정일, 과징금/과태료/벌금      // 과태료(1030), 과징금(1040), 벌금(2010) -->
        <template v-if="['1030', '1040', '2010'].includes(param.admdspCd)">
          <tr>
            <th scope="row">이행확인 예정일</th>
            <td>{{ param.impltRsltIdntyPrnmntYmd }}</td>
            <th scope="row">{{ param.admdspCd === '1030'? '과태료' : param.admdspCd === '1040'? '과징금' : '벌금' }}</th>
            <td>{{ param.levyAmt }}</td>
          </tr>
        </template>
        <!-- 이행확인 예정일        // 개선명령(1010), 금지명령(1020), 경고(1050) -->
        <template v-if="['1010', '1020', '1050'].includes(param.admdspCd)">
          <tr>
            <th scope="row">이행확인 예정일</th>
            <td colspan="3">{{ param.impltRsltIdntyPrnmntYmd }}</td>
          </tr>
        </template>
        <!-- 내용 -->
        <tr>
          <th scope="row">내용</th>
          <td colspan="3">{{ param.admdspCn }}</td>
        </tr>
        <!-- 개선/금지명령 관련서류 -->
        <tr>
          <th scope="row">개선/금지명령 관련서류</th>
          <td colspan="3" v-show="param.files.length !== 0">
            <br-attach-file label="첨부파일"
                            atchFileTaskSeCd="IMPV"
                            v-model:atchFileGroupId="param.atchFileGroupId"
                            v-model:fileProcessingData="param.fileProcessingData"
                            :files="param.files"
                            :readonly="true"
            />
          </td>
          <td colspan="3" v-show="param.files.length === 0">
            첨부파일이 없습니다.
          </td>
        </tr>
        <!-- 이행확인서 -->
        <tr>
          <th scope="row"><i class="necessary">필수입력</i>이행확인서</th>
          <td colspan="3">
            <br-attach-file label="이행확인서"
                v-model:atchFileGroupId="param.impltAtchFileGroupId"
                v-model:fileProcessingData="param.fileProcessingData"
                atchFileTaskSeCd="IMPV"
                :files="param.impltFiles"
                panel="panel"
                required
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="btn_area right">
    <br-button v-if="param.impltRsltIdntyPrnmntYmd" label="저장" class="btn md primary" panel="panel" validate @click="update" />
    <a href="/my-office/ImpvProhbtCmd" class="btn md tertiary">목록</a>
  </div>
</template>

<script>
import {storeSession} from "@/js/store/store-session";

const API = {
    MY_IMPV : "/user/impv",
    HOME : "/",
}

export default {
  components: {},
  data() {
    return {
      param : {
        impvProhbtCmdSn : "",   // 시설 정보
        fcltNm : "",
        bplcNm : "",
        bplcKndCd : "",
        bplcKndNm : "",
        bplcAddr : "",
        bplcDaddr : "",
        bzentyId : "",
        bzentyNm : "",
        bzentyAddr : "",
        bzentyDaddr : "",
        rprsvNm : "",
        brno : "",
        prdctnPrdctKndNm : "",

        admdspCd : "",  // 명령 정보
        admdspKndNm : "",
        admdspNm : "",
        admdspYmd : "",
        impltRsltIdntyPrnmntYmd : "",
        impvProhbtBgngYmd : "",
        impvProhbtEndYmd : "",
        acstnYmd : "",
        cmptncPolstnNm : "",
        levyAmt : "",
        admdspCn : "",
        atchFileGroupId : "",
        fileProcessingData: {},
        fileProcessingData2: {},
        files: [],
        impltFiles: [],

        impltAtchFileGroupId : "",  // 사용자가 등록한 파일
        userId : "",

        atchFiles : [],
      },
      userinfo: storeSession.getMemberInfo(),
      fileListTaskSeCd: 'IMPV',
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
    this.param.impvProhbtCmdSn = this.$route.query.impvProhbtCmdSn;
  },
  mounted() {
    this.impvDetail();
  },
  methods: {
    navigateTo(path) {
      if (path) {
        this.$router.push(path).catch(() => {
            console.error("Navigation Error");
        });
      }
      window.scrollTo({ top: 0 });
    },
    impvDetail() {
        this.$apiCall.get(API.MY_IMPV + "/" + this.param.impvProhbtCmdSn, {userId : this.userinfo.userId}, this.success, this.error);
    },
    success(data) {
        if (!data.result) {
            this.error();
            return;
        }
        //this.param = data.result;
        const json = JSON.parse(JSON.stringify(data.result));
        this.param = json;

        this.param.files = []
        if(data.result.files){
            this.param.files = data.result.files;
        }

        this.param.impltFiles = []
        if(data.result.impltFiles){
            this.param.impltFiles = data.result.impltFiles;
        }
    },
    error() {
        alert("데이터를 가져오는 중 오류가 발생했습니다.");
    },
    update() {
        if(confirm("저장 하시겠습니까?")) {
            this.$apiCall.post(
                API.MY_IMPV, this.param,
                () => {
                    alert("저장되었습니다.");
                    this.$router.push({path : `/my-office/ImpvProhbtCmd`})
                        .catch(() => {
                              console.error("Navigation Error")
                            }
                        );
                },
                () => {
                    alert("저장에 실패하였습니다.");
                }
            );
        }
    },
  },
}
</script>

<style scoped></style>