const MENU_LIST = [
    {
        menuGrpNm: "품질등급제 소개",
        path: "/qlty-grd/QltyGrdIntro",
        items: [],
        isPublic: true,
    },
    {
        menuGrpNm: "의견서 제출 절차",
        path: "/qlty-grd/OpnnSbmsnProcss",
        items: [],
        isPublic: true,
    },
    {
        menuGrpNm: "품질등급 조회",
        path: "/qlty-grd/QltyGrdInq",
        items: []
    },
    {
        menuGrpNm: "의견서 제출",
        path: "/qlty-grd/ObjcAplyInq",
        items: [
            {
                menuNm: "의견서 신청",
                path: "/qlty-grd/objcAplyInq/ObjcAply",
            }
        ]
    },
    {
        menuGrpNm: "인센티브 대상조회",
        path: "/qlty-grd/IcntvTrgtInq",
        items: []
    },
];

export default {
    getMenus: function (){
        return {
            groupName: "품질등급제",
            menuList: MENU_LIST,
        };
    },
};