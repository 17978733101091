const MENU_LIST = [
    {
        menuGrpNm: "반입/판매(공급)업체현황",
        path: "/prfmnc-rpt/CrynSplyBzentyStts",
        items: []
    },
    {
        menuGrpNm: "실적보고",
        path: "/prfmnc-rpt/PrfmncRpt",
        items: [
            {
                menuNm: "실적보고상세",
                path: "/prfmnc-rpt/dtl/SdflMnfrt"
            },
            {
                menuNm: "실적보고상세",
                path: "/prfmnc-rpt/dtl/SdflUse"
            },
            {
                menuNm: "실적보고상세",
                path: "/prfmnc-rpt/dtl/SdflIncm"
            },
            {
                menuNm: "실적보고상세",
                path: "/prfmnc-rpt/dtl/FillGas"
            },
            {
                menuNm: "실적보고상세",
                path: "/prfmnc-rpt/dtl/FireRtrvl"
            },
            {
                menuNm: "실적보고상세",
                path: "/prfmnc-rpt/dtl/BioGas"
            },
            {
                menuNm: "실적보고상세",
                path: "/prfmnc-rpt/dtl/BfrBioGas"
            },
            {
                menuNm: "실적보고상세",
                path: "/prfmnc-rpt/dtl/SynthGas"
            },
        ]
    },
    {
        menuGrpNm: "실적보고현황",
        path: "/prfmnc-rpt/PrfmncRptStts",
        items: []
    },
    {
        menuGrpNm: "회수통계조사(연구수행기관)",
        path: "/prfmnc-rpt/RtrvlStatsExmnInst",
        items: []
    },
    {
        menuGrpNm: "회수통계조사",
        path: "/prfmnc-rpt/RtrvlStatsExmn",
        items: []
    },
];

export default {
    getMenus: function (){
        return {
            groupName: "실적보고",
            menuList: MENU_LIST,
        };
    },
};