<template>
  <div class="desc_box">
    <i class="icon"><img src="@/assets/images/common/ico_desc.png" alt="이미지"></i>
    <div class="desc">
      <strong class="desc_title">업무의 종류 및 절차를 매뉴얼로 제공합니다.</strong>
      <p>폐자원에너지 10종을 대상으로 업무의 종류 및 절차 등을 제공하고 있습니다.</p>
      <a href="" title="새창열림" class="btn sm manual" @click.prevent="openVideoPopup">동영상매뉴얼</a>
    </div>
  </div>

  <h2 class="title1">업무의 종류 및 절차</h2>
  <div class="tbl_wrap manual">
    <table>
      <caption>매뉴얼다운로드, 폐자원에너지 10종, 검사업무, 실적보고, 회수통계로 구성</caption>
      <colgroup>
        <col style="width:8%">
        <col style="width:6%">
        <col style="width:30%">
        <col style="width:30%">
        <col style="width:10%">
        <col>
      </colgroup>
      <thead>
        <tr>
          <th scope="col">매뉴얼</th>
          <th scope="col" colspan="2">폐자원에너지 10종</th>
          <th scope="col">검사업무</th>
          <th scope="col">실적보고</th>
          <th scope="col">회수통계</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><br-static-file-download atchFileVldNo="5cc7f60c-de29-4dfd-bf03-3424722ba7fd" label=""/></td>
          <td rowspan="3">고형연료</td>
          <td><a href="" title="새창열림" @click.prevent="openVideoPopup(0)">고형연료제조</a></td>
          <td>시설검사 + 품질검사 + 품질표시검사</td>
          <td rowspan="7">월보고</td>
          <td rowspan="12">연보고</td>
        </tr>
        <tr>
          <td><br-static-file-download atchFileVldNo="302f305c-4d8f-40bd-be8d-070758ffb376" label=""/></td>
          <td><a href="" title="새창열림" @click.prevent="openVideoPopup(0)">고형연료사용</a></td>
          <td>시설검사 + 품질검사</td>
        </tr>
        <tr>
          <td><br-static-file-download atchFileVldNo="4e2fa0ab-282f-48c2-aef3-073b039f2d3f" label=""/></td>
          <td><a href="" title="새창열림" @click.prevent="openVideoPopup(0)">고형연료수입</a></td>
          <td>수입품질검사</td>
        </tr>
        <tr>
          <td><br-static-file-download atchFileVldNo="47fa8861-a14b-42ea-8f14-70e8712fff81" label=""/></td>
          <td colspan="2"><a href="" title="새창열림" @click.prevent="openVideoPopup(1)">매립가스</a></td>
          <td rowspan="9">
            본 시스템 내 없음<br/>
            (폐자원에너지 종합정보관리시스템에서 진행되는 검사 업무는 없으며 별도 진행됩니다.)
          </td>
        </tr>
        <tr>
          <td><br-static-file-download atchFileVldNo="08e7c029-283e-46a7-ad30-1b909e569bec" label=""/></td>
          <td colspan="2"><a href="" title="새창열림" @click.prevent="openVideoPopup(2)">소각(소각열회수)</a></td>
        </tr>
        <tr>
          <td><br-static-file-download atchFileVldNo="db066ec2-ca19-4bd4-9c36-7d6a298579ea" label=""/></td>
          <td colspan="2"><a href="" title="새창열림" @click.prevent="openVideoPopup(3)">바이오가스</a></td>
        </tr>
        <tr>
          <td><br-static-file-download atchFileVldNo="042add60-a696-48ca-adaa-276ba99a5dd6" label=""/></td>
          <td colspan="2"><a href="" title="새창열림" @click.prevent="openVideoPopup(4)">폐기물합성가스</a></td>
        </tr>
        <tr>
          <td rowspan="5"><br-static-file-download atchFileVldNo="5f6af623-7b5e-46f7-b3f4-bb1e8e2422f6" label=""/></td>
          <td colspan="2"><a href="" title="새창열림" @click.prevent="openVideoPopup(5)">열분해유</a></td>
          <td rowspan="5">없음</td>
        </tr>
        <tr>
          <td colspan="2"><a href="" title="새창열림" @click.prevent="openVideoPopup(6)">재생연료유</a></td>
        </tr>
        <tr>
          <td colspan="2"><a href="" title="새창열림" @click.prevent="openVideoPopup(7)">정재연료유</a></td>
        </tr>
        <tr>
          <td colspan="2"><a href="" title="새창열림" @click.prevent="openVideoPopup(8)">시멘트소성로 보조연료</a></td>
        </tr>
        <tr>
          <td colspan="2"><a href="" title="새창열림" @click.prevent="openVideoPopup(9)">유기성오니 보조연료</a></td>
        </tr>
      </tbody>
    </table>
  </div>
  <video-manual-popup></video-manual-popup>
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";
import VideoManualPopup from "@/views/wstrsc-ene-intro/popup/VideoManualPopup.vue";

export default {
  components: {VideoManualPopup},
  data() {
    return {
    }
  },
  methods: {
    openVideoPopup(tabIndex) {
      storeSwitch.on('VideoManualPopup', tabIndex);
    }
  }
}
</script>