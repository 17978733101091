<template>

  <!-- 2021년까지의 (구)바이오가스 등록/수정화면 -->
<!--  <template v-if="!showDetail && parseInt(prfmncMst.prfmncYr) <= 2021">-->
<!--    <by2021-bio-gas-update-->
<!--        :prfmncMst="prfmncMst"-->
<!--        :prfmncFclt="prfmncFclt"-->
<!--        :prfmncBioGas="prfmncBioGas"-->
<!--        :prfmncBioGasWtCrynList="prfmncBioGasWtCrynList"-->
<!--        :prfmncBioGasNtslList="prfmncBioGasNtslList"-->
<!--        :showDetail="showDetail"-->
<!--    />-->
<!--  </template>-->
  <!-- 2021년까지의 (구)바이오가스 상세화면 -->
<!--  <template v-else-if="showDetail && parseInt(prfmncMst.prfmncYr) <= 2021">-->
<!--    <by2021-bio-gas-detail-->
<!--        :prfmncMst="prfmncMst"-->
<!--        :prfmncFclt="prfmncFclt"-->
<!--        :prfmncBioGas="prfmncBioGas"-->
<!--        :prfmncBioGasWtCrynList="prfmncBioGasWtCrynList"-->
<!--        :prfmncBioGasNtslList="prfmncBioGasNtslList"-->
<!--        :showDetail="showDetail"-->
<!--    />-->
<!--  </template>-->

  <!-- 2021년까지의 (구)바이오가스 - 실적상태와 관계없이 무조건 상세화면으로만 노출 -->
  <template v-if="parseInt(prfmncMst.prfmncYr) <= 2021">
    <by2021-bio-gas-detail
        :prfmncMst="prfmncMst"
        :prfmncFclt="prfmncFclt"
        :prfmncBioGas="prfmncBioGas"
        :prfmncBioGasWtCrynList="prfmncBioGasWtCrynList"
        :prfmncBioGasNtslList="prfmncBioGasNtslList"
        :showDetail="showDetail"
    />
  </template>
  <!-- 2022년 ~ 2024년 바이오가스 상세화면 -->
  <template v-else-if="showDetail && parseInt(prfmncMst.prfmncYr) >= 2022 && parseInt(prfmncMst.prfmncYr) <= 2024">
    <by2022-2024-bio-gas-detail
        :prfmncMst="prfmncMst"
        :prfmncFclt="prfmncFclt"
        :prfmncBioGas="prfmncBioGas"
        :prfmncBioGasPrdctn="prfmncBioGasPrdctn"
        :bioGasEnePrfmnc="bioGasEnePrfmnc"
        :prfmncBioGasWtCrynList="prfmncBioGasWtCrynList"
        :prfmncBioGasWtInputList="prfmncBioGasWtInputList"
        :prfmncBioGasNtslList="prfmncBioGasNtslList"
        :bioGasNtslEneSplyBzenty="bioGasNtslEneSplyBzenty"
        :asstFuelInputInfo="asstFuelInputInfo"
        :showDetail="showDetail"
    />
  </template>
  <!-- 2022년 ~ 2024년 바이오가스 등록/수정화면 -->
  <template v-else-if="!showDetail && parseInt(prfmncMst.prfmncYr) >= 2022 && parseInt(prfmncMst.prfmncYr) <= 2024">
    <by2022-2024-bio-gas-update
        :prfmncMst="prfmncMst"
        :prfmncFclt="prfmncFclt"
        :prfmncBioGas="prfmncBioGas"
        :prfmncBioGasPrdctn="prfmncBioGasPrdctn"
        :bioGasEnePrfmnc="bioGasEnePrfmnc"
        :prfmncBioGasWtCrynList="prfmncBioGasWtCrynList"
        :prfmncBioGasWtInputList="prfmncBioGasWtInputList"
        :prfmncBioGasNtslList="prfmncBioGasNtslList"
        :bioGasNtslEneSplyBzenty="bioGasNtslEneSplyBzenty"
        :asstFuelInputInfo="asstFuelInputInfo"
        :showDetail="showDetail"
    />
  </template>
  <!-- 2025년 이후 바이오가스 상세화면 -->
  <template v-else-if="showDetail && parseInt(prfmncMst.prfmncYr) >= 2025">
    <bio-gas-detail
        :prfmncMst="prfmncMst"
        :prfmncFclt="prfmncFclt"
        :prfmncBioGas="prfmncBioGas"
        :prfmncBioGasPrdctn="prfmncBioGasPrdctn"
        :bioGasEnePrfmnc="bioGasEnePrfmnc"
        :prfmncBioGasWtCrynList="prfmncBioGasWtCrynList"
        :prfmncBioGasWtInputList="prfmncBioGasWtInputList"
        :prfmncBioGasNtslList="prfmncBioGasNtslList"
        :bioGasNtslEneSplyBzenty="bioGasNtslEneSplyBzenty"
        :asstFuelInputInfo="asstFuelInputInfo"
        :showDetail="showDetail"
    />
  </template>
  <!-- 2025년 이후 바이오가스 등록/수정화면 -->
  <template v-else>
    <bio-gas-update
        :prfmncMst="prfmncMst"
        :prfmncFclt="prfmncFclt"
        :prfmncBioGas="prfmncBioGas"
        :prfmncBioGasPrdctn="prfmncBioGasPrdctn"
        :bioGasEnePrfmnc="bioGasEnePrfmnc"
        :prfmncBioGasWtCrynList="prfmncBioGasWtCrynList"
        :prfmncBioGasWtInputList="prfmncBioGasWtInputList"
        :prfmncBioGasNtslList="prfmncBioGasNtslList"
        :bioGasNtslEneSplyBzenty="bioGasNtslEneSplyBzenty"
        :asstFuelInputInfo="asstFuelInputInfo"
        :showDetail="showDetail"
    />
  </template>

</template>

<script>
import SbGrid from "@/components/SbGrid.vue";
import BioGasDetail from "@/views/prfmnc-rpt/dtl/bio-gas/BioGasDetail.vue";
import BioGasUpdate from "@/views/prfmnc-rpt/dtl/bio-gas/BioGasUpdate.vue";
import By2021BioGasDetail from "@/views/prfmnc-rpt/dtl/bio-gas/By2021BioGasDetail.vue";
import By2021BioGasUpdate from "@/views/prfmnc-rpt/dtl/bio-gas/By2021BioGasUpdate.vue";
import By20222024BioGasDetail from "@/views/prfmnc-rpt/dtl/bio-gas/By2022-2024BioGasDetail.vue";
import By20222024BioGasUpdate from "@/views/prfmnc-rpt/dtl/bio-gas/By2022-2024BioGasUpdate.vue";
import {storeSwitch} from "@/js/store/store-switch";

const API = {
  BUSINESS_PLACE_INFO: "/bplc", // 사업장 정보
  PRFMNC_MST: "/prfmnc",// 실적 공통정보
  CHECK_PERMITTED_BUSINESS: "/prfmnc/chck-prm-bplc",
  FACILITIES_INFO_BY_BUSINESS_PLACE: "/fclt/by-bplc", // 사업장 시설정보

  /**************** S : 바이오가스(구) API ****************/
  BFR_BIO_GAS_PRFMNC_CRYN_STTS: "/prfmnc/bfrBioGas/wtCrynStts", // 실적 바이오가스(구) 폐기물반입현황 조회
  BFR_BIO_GAS_DTL: "/prfmnc/bfrBioGas/{prfmncId}", // 실적 바이오가스(구) 상세조회
  BFR_BIO_GAS_PRFMNC_NTSL_STTS: "/prfmnc/bfrBioGas/ntslStts", // 실적 바이오가스(구) 판매현황 조회
  /**************** E : 바이오가스(구) API ****************/

  /**************** S : 바이오가스 API ****************/
  BIO_GAS_PRFMNC_CRYN_STTS: "/prfmnc/bioGas/wtCrynStts", // 실적 바이오가스 폐기물반입현황 조회
  BIO_GAS_PRFMNC_INPUT_STTS: "/prfmnc/bioGas/wtInputStts",
  BIO_GAS_DTL: "/prfmnc/bioGas/{prfmncId}", // 실적 바이오가스 상세조회
  BIO_GAS_PRFMNC_NTSL_STTS: "/prfmnc/bioGas/ntslStts", // 실적 바이오가스 판매현황 조회
  ASSISTANCE_FUEL_INPUT_INFORMATION: "/prfmnc/bioGas/asst-fuel-input-info", // 시설별 보조연료투입 현황 조회
  /**************** E : 바이오가스 API ****************/
  PERFORMANCE_REGISTER_POSIBILITY_IDENTIFY: "/prfmnc/reg-psblty-idnty", // 실적 신규등록 가능상태 체크
};

const CODE = {
  PERFORMANCE_STATUS: "PFM001", // 실적상태코드
  MODIFICATION_PERMIT_STATUS: "PFM002", // 실적수정허용상태코드
};

const ROUTE = {
  LIST: "/prfmnc-rpt/PrfmncRpt"
};

export default {
  components: {
    SbGrid,
    BioGasDetail,
    BioGasUpdate,
    By2021BioGasDetail,
    By2021BioGasUpdate,
    'by2022-2024-bio-gas-detail': By20222024BioGasDetail,
    'by2022-2024-bio-gas-update': By20222024BioGasUpdate,
  },
  data() {
    return {
      mode: "U", // U: 등록, 수정   R: 상세
      searchParam: {
        prfmncId: "",
        bplcId: "",
        prfmncYm: "",
        prfmncYr: "",
      },
      detailStatusCodes: ['REC', 'APR', 'SUB'],
      prfmncMst:{},
      prfmncFclt:{},
      prfmncBioGas:{}, // 바이오가스실적
      prfmncBioGasPrdctn:{}, // 바이오가스생산실적
      prfmncBioGasWtCrynList:[], // 바이오가스반입실적
      prfmncBioGasWtInputList:[], // 바이오가스투입실적
      prfmncBioGasNtslList:[], // 바이오가스판매실적
      asstFuelInputInfo: {}, // 보조연료 투입정보
      bioGasNtslEneSplyBzenty: [], // 바이오가스판매업체
      bioGasEnePrfmnc: {}, // 바이오가스에너지실적
    };
  },
  computed: {
    showDetail() {
      // 구 바이오가스(2021년도까지)의 경우 실적상태와 무관하게 무조건 상세페이지로만 노출하기 때문에 mode를 무조건 R 처리함
      return parseInt(this.searchParam.prfmncYr) <= 2021 || (Boolean(this.searchParam.prfmncId) && this.detailStatusCodes.includes(this.prfmncMst.prfmncSttsCd));
    }
  },
  watch: {
    showDetail(newVal) {
      if (newVal) {
        this.mode = 'R';
      } else {
        this.mode = 'U';
      }
    }
  },
  created() {
    const prfmncId = this.$route.query.prfmncId ?? "";
    const bplcId = this.$route.query.bplcId ?? "";
    const prfmncYm = this.$route.query.prfmncYm ?? "";

    if(prfmncId){ // 수정, 상세
      this.searchParam.prfmncId = prfmncId;
    }else{ // 신규
      this.searchParam.prfmncId = prfmncId;
      this.searchParam.bplcId = bplcId ?? "";
      this.prfmncMst.bplcId = bplcId ?? "";
      this.searchParam.prfmncYm = prfmncYm ?? "";
      this.prfmncMst.prfmncYm = prfmncYm ?? "";
      this.searchParam.prfmncYr = prfmncYm?.substring(0, 4) ?? "";
      this.prfmncMst.prfmncYr = prfmncYm?.substring(0, 4) ?? "";
      this.searchParam.prfmncMm = prfmncYm?.substring(4, 6) ?? "";
    }

    this.initialize();
  },
  mounted() {
  },
  methods: {
    async initialize() {
      storeSwitch.on("loading");
      if(!this.showDetail && Boolean(!this.searchParam.prfmncId)) await this.registerChk();
      await this.loadPrfmncSttsCd();
      await this.readBplcInfo();
      await this.readPrfmncMst();

      const result = await this.checkPermittedBplc();
      if (!result) {
        alert("허용되지 않은 사업장입니다.");
        this.$router.push(ROUTE.LIST);
        storeSwitch.off("loading");
        return;
      }

      // bplcKndCd 체크 후 리디렉션
      if (this.prfmncMst.bplcKndCd !== "04-01") {
        this.$router.push(ROUTE.LIST);
        storeSwitch.off("loading");
        return; // 리디렉션 후 나머지 초기화 작업을 중단
      }

      await this.readPrfmncFclt();
      await this.readPrfmncDtlBioGas();
      await this.readPrfmncWtCryn();
      await this.readPrfmncWtInput();
      await this.readPrfmncNtsl();
      await this.loadAsstFuelInputInfo();
      storeSwitch.off("loading");
    },
    async registerChk() {
      this.$apiCall.post(
          API.PERFORMANCE_REGISTER_POSIBILITY_IDENTIFY,
          this.searchParam,
          () => {},
          (error) => {
            alert(error.message);
            this.$router.push(ROUTE.LIST);
          }
      );
    },
    async loadPrfmncSttsCd() {
      const prfmncSttsList = this.$store.getters.getCodeList({groupCd: CODE.PERFORMANCE_STATUS, useYn: 'Y'});
      this.prfmncSttsList = prfmncSttsList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    // 사업장정보 호출
    async readBplcInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(
            API.BUSINESS_PLACE_INFO,
            this.searchParam,
            (data) => {
              if(data.result) {
                this.prfmncMst = {
                  ...this.prfmncMst,
                  bplcId: data.result.bplcId,
                  bplcNm: data.result.bplcNm,
                  bplcKndNm: data.result.bplcKndNm,
                  prfmncSttsCd: "IPG",
                  bplcKndCd: data.result.bplcKndCd,
                };
              }
              resolve(data);
            },
            (error) => {
              new Error("Failed to load readPrfmncMst.");
              reject(error);
            }
        );
      });
    },
    // 실적보고공통정보조회 호출
    async readPrfmncMst() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(
            API.PRFMNC_MST,
            this.searchParam,
            (data) => {
              if(data.result.prfmncMst) {
                this.prfmncMst = data.result.prfmncMst;

                this.searchParam.bplcId = this.prfmncMst.bplcId;
                this.searchParam.prfmncYm = this.prfmncMst.prfmncYm;
                this.searchParam.prfmncYr = this.prfmncMst.prfmncYr;
              }
              resolve(data);
            },
            (error) => {
              new Error("Failed to load readPrfmncMst.");
              reject(error);
            }
        );
      });
    },
    checkPermittedBplc() {
      return new Promise((resolve) => {
        this.$apiCall.get(API.CHECK_PERMITTED_BUSINESS,
            { bplcId: this.prfmncMst.bplcId },
            data => {
              resolve(data.result);
            },
            () => {
              resolve(false);
            }
        );
      });
    },
    // 실적 시설조회
    async readPrfmncFclt() {
      this.$apiCall.get(
          API.FACILITIES_INFO_BY_BUSINESS_PLACE,
          {
            bplcId: this.prfmncMst.bplcId,
            fcltSttsCd: "01",
            useYn: "Y",
          },
          (data) => {
            this.prfmncFclt = data.result;
          },
          new Error("Failed to load readPrfmncFclt.")
      );
    },
    // 바이오가스실적 조회
    async readPrfmncDtlBioGas() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(
            parseInt(this.prfmncMst?.prfmncYm?.substring(0, 4)) >= 2022 ? API.BIO_GAS_DTL : API.BFR_BIO_GAS_DTL,
            {...this.searchParam, mode: this.mode},
            (data) => {
              this.prfmncBioGas = data.result.bioGasPrfmnc;
              this.prfmncBioGasPrdctn = data.result.bioGasPrfmncPrdctn;
              this.bioGasNtslEneSplyBzenty = data.result.bioGasNtslEneSplyBzenty;
              this.bioGasEnePrfmnc = data.result.bioGasEnePrfmnc;
              resolve(data);
            },
            (error) => {
              new Error("Failed to load readPrfmncDtlBioGas.");
              reject(error);
            }
        );
      });
    },
    // 폐기물반입현황 조회
    async readPrfmncWtCryn() {
      this.$apiCall.get(
          parseInt(this.prfmncMst?.prfmncYm?.substring(0, 4)) >= 2022 ? API.BIO_GAS_PRFMNC_CRYN_STTS : API.BFR_BIO_GAS_PRFMNC_CRYN_STTS,
          {...this.searchParam, mode: this.mode},
          (data) => {
            this.prfmncBioGasWtCrynList = data.result;
          },
          new Error("Failed to load readPrfmncWtCryn.")
      );
    },
    // 폐기물투입현황 조회
    async readPrfmncWtInput() {
      if(parseInt(this.prfmncMst?.prfmncYm?.substring(0, 4)) >= 2025) {
        this.$apiCall.get(
            API.BIO_GAS_PRFMNC_INPUT_STTS,
            {...this.searchParam, mode: this.mode},
            (data) => {
              this.prfmncBioGasWtInputList = data.result;
            },
            new Error("Failed to load readPrfmncWtCryn.")
        );
      }
    },
    // 메탄가스판매현황 조회
    async readPrfmncNtsl() {
      this.$apiCall.get(
          parseInt(this.prfmncMst?.prfmncYm?.substring(0, 4)) >= 2022 ? API.BIO_GAS_PRFMNC_NTSL_STTS : API.BFR_BIO_GAS_PRFMNC_NTSL_STTS,
          {...this.searchParam, mode: this.mode},
          (data) => {
            this.prfmncBioGasNtslList = data.result;
          },
          new Error("Failed to load readPrfmncNtsl.")
      );
    },
    // 시설별 보조연료투입 현황 조회
    loadAsstFuelInputInfo() {
      return new Promise((resolve, reject) => {
        if (parseInt(this.searchParam.prfmncYr) >= 2025) {
          this.$apiCall.get(API.ASSISTANCE_FUEL_INPUT_INFORMATION,
              Object.assign({}, this.searchParam, {mode:this.mode}),
              data => {
                if (data.result) {
                  this.asstFuelInputInfo = data.result;
                }
                resolve();
              },
              () => {
                reject(new Error("Failed to load prfmnc prcs info."));
              }
          );
        } else {
          resolve();
        }
      });
    },
  }
}
</script>
<script setup lang="ts">
</script>