<template>
  <div class="grid_area_wrap">
    <div class="grid_area">
      <div class="board_info">
        <h3 class="title2">반입업체 현황</h3>
      </div>
      <sb-grid
          id="ovrsIncmGrid"
          :data-source="ovrsIncmDataSource"
          :columns="ovrsIncmGridColumns"
          :gridattr="ovrsIncmGridAttr"
          :param="param"
          v-model:grid="ovrsIncmGridObject"
          @loaded="ovrsIncmGridLoaded"
          :refreshGrid="ovrsIncmGridReload"
          fileName="고형연료수입 반입업체 현황.xlsx"
      />
    </div>
    <div class="grid_area">
      <div class="board_info">
        <h3 class="title2">판매(공급)업체 현황</h3>
      </div>
      <sb-grid
          id="splyGrid"
          :dataSource="splyDataSource"
          :columns="splyGridColumns"
          :gridattr="splyGridAttr"
          :param="param"
          v-model:grid="splyGridObject"
          @loaded="splyGridLoaded"
          :refreshGrid="splyGridReload"
          fileName="고형연료수입 판매(공급)업체 현황.xlsx"
      />
    </div>
  </div>
</template>

<script>
import SbGrid from "@/components/SbGrid.vue";

const API = {
  INCOME_COUNTERPARTY: "/incm/cnpt",
  INCOME_SUPPLY: "/incm/sply",
};
const CODE = {
  MANUFACTURE_NATION: "COM415", // 제조국가코드
  PRODUCT_KIND: "MBR010" // 제품종류코드
};

export default {
  components: {SbGrid},
  props: ['param'],
  data() {
    return {
      ctpvCdList: [],
      sggCdList: [],
      mnftrNtnCdList: [],
      prdctKndCdList: [],
      ovrsIncmGridReload: false,
      ovrsIncmGridObject: null,
      ovrsIncmDataSource: {
        ajaxConfig: {
          select: { url: API.INCOME_COUNTERPARTY },
          paging: false,
        },
        schema: {
          id: 'incmFcltSn'
        }
      },
      ovrsIncmGridColumns: [],
      ovrsIncmGridAttr: {
        pageable: false,
        mergeRow: true,
      },
      splyGridReload: false,
      splyGridObject: null,
      splyDataSource: {
        ajaxConfig: {
          select: { url: API.INCOME_SUPPLY },
          paging: false,
        },
        schema: {
          id: 'bplcId'
        }
      },
      splyGridColumns: [],
      splyGridAttr: {
        pageable: false,
        mergeRow:true
      },
    };
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loadCtpvCd();
      this.loadSggCd();
      this.loadMnftrNtnCd();
      this.loadPrdctKndCdList();
      this.initializeMnftrGridColumns();
      this.initializeSplyGridColumns();
      this.ovrsIncmGridReload = true;
      this.splyGridReload = true;
    },
    loadCtpvCd() {
      const ctpvList = this.$store.getters.getSggList({ type: 'ctpv' });
      this.ctpvCdList = ctpvList.map(item => ({
        text: item.sggNm,
        value: item.sggStdgCd
      }));
    },
    loadSggCd() {
      const sggList = this.$store.getters.getSggList({ type: 'sgg' });
      this.sggCdList = sggList.map(item => ({
        text: item.sggNm,
        value: item.sggStdgCd,
        upSggStdgCd: item.upSggStdgCd
      }));
    },
    loadMnftrNtnCd() {
      const mnftrNtnCdList = this.$store.getters.getCodeList({ groupCd: CODE.MANUFACTURE_NATION, useYn: 'Y' });
      this.mnftrNtnCdList = mnftrNtnCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    loadPrdctKndCdList() {
      const prdctKndCdList = this.$store.getters.getCodeList({ groupCd: CODE.PRODUCT_KIND, useYn: 'Y' });
      this.prdctKndCdList = prdctKndCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    initializeMnftrGridColumns() {
      this.ovrsIncmGridColumns = [
        { field: 'bplcId', caption: '사업장아이디', visible: false },
        { field: 'incmFcltSn', caption: '수입시설일련번호', visible: false },
        { field: 'mnftrNtnCd',
          caption: '제조국',
          type: 'combo',
          items: this.mnftrNtnCdList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 110,
          mergeRow:true,
          exportLabel : true
        },
        { field: 'bzentyNm', caption: '업체명', width: 100, unit:'%', align: 'left',
          mergeRow: (data) => data.mnftrNtnCd + "." + data.bzentyNm
        },
        { field: 'prdctKndCd',
          caption: '제품종류',
          type: 'combo',
          items: this.prdctKndCdList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 110,
          exportLabel : true,
        },
      ]
    },
    initializeSplyGridColumns() {
      this.splyGridColumns = [
        { field: 'bplcId', caption: '사업장아이디', visible: false },
        { field: 'ctpvStdgCd',
          caption: '시·도',
          type: 'combo',
          items: this.ctpvCdList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 105,
          mergeRow:true,
          exportLabel : true
        },
        { field: 'sggStdgCd',
          caption: '시·군·구',
          type: 'combo',
          items: this.sggCdList,
          comboLabel: 'text',
          comboValue: 'value',
          width: 110,
          mergeRow:true,
          exportLabel : true
        },
        { field: 'bplcNm', caption: '사업장명', width: 100, unit:'%', align: 'left' },
      ]
    },
    ovrsIncmGridLoaded() {
      this.ovrsIncmGridReload = false;
    },
    splyGridLoaded() {
      this.splyGridReload = false;
    },
    search() {
      window.SBGrid3.reload(this.ovrsIncmGridObject);
      window.SBGrid3.reload(this.splyGridObject);
    }
  }
}
</script>

<style scoped>

</style>