<template>
  <rjct-rsn-area :prfmncBasicInfo="prfmncBasicInfo" />
  <prfmnc-mdfcn-prm-area :prfmncBasicInfo="prfmncBasicInfo" />

  <h3 class="title1">고형연료제품 반입처리 현황</h3>
  <sb-grid
      id="sdflCrynGrid"
      :dataSource="sdflCrynDataSource"
      :columns="sdflCrynGridColumns"
      :gridattr="sdflCrynGridAttr"
      v-model:grid="sdflCrynGridObject"
      @loaded="sdflCrynGridLoaded"
      :refreshGrid="sdflCrynGridReload"
  />

  <h3 class="title1">고형연료제품 수입/판매 현황</h3>
  <sb-grid
      id="sdflPrcsGrid"
      :dataSource="sdflPrcsDataSource"
      :columns="sdflPrcsGridColumns"
      :gridattr="sdflPrcsGridAttr"
      v-model:grid="sdflPrcsGridObject"
      @loaded="sdflPrcsGridLoaded"
      :refreshGrid="sdflPrcsGridReload"
      @row-clicked="sdflPrcsClick"
  />

  <h3 class="title1">고형연료제품 판매 상세 현황 (단위:톤/월)</h3>
  <sb-grid
      id="ntslGrid"
      :dataSource="ntslDataSource"
      :columns="ntslGridColumns"
      :gridattr="ntslGridAttr"
      v-model:grid="ntslGridObject"
      @loaded="ntslGridLoaded"
      :refreshGrid="ntslGridReload"
  />

  <h3 class="title1">고형연료제품 종류별 공급 현황</h3>
  <div class="board_write thead">
    <table>
      <caption>폐기물 반입/투입량 - 이월량, 반입량, 투입량, 폐기반출량, 잔량으로 구성</caption>
      <colgroup>
        <col style="width: 25%;">
        <col style="width: 25%;">
        <col style="width: 25%;">
        <col >
      </colgroup>
      <thead>
      <tr>
        <th scope="col">SRF 성형</th>
        <th scope="col">SRF 비성형</th>
        <th scope="col">BIO-SRF 성형</th>
        <th scope="col">BIO-SRF 비성형</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td aria-label="SRF 성형" class="text-end">{{ formatNumber(prdctnKndInputInfo.srfPt) }} 톤</td>
        <td aria-label="SRF 비성형" class="text-end">{{ formatNumber(prdctnKndInputInfo.srfNpt) }} 톤</td>
        <td aria-label="BIO-SRF 성형" class="text-end">{{ formatNumber(prdctnKndInputInfo.bioSrfPt) }} 톤</td>
        <td aria-label="BIO-SRF 비성형" class="text-end">{{ formatNumber(prdctnKndInputInfo.bioSrfNpt) }} 톤</td>
      </tr>
      </tbody>
    </table>
  </div>

  <common-button-area
      :prfmncBasicInfo="prfmncBasicInfo"
  />
</template>

<script>
import SbGrid from "@/components/SbGrid.vue";
import utils from "@/js/utils/utils";
import RjctRsnArea from "@/views/prfmnc-rpt/dtl/components/RjctRsnArea.vue";
import PrfmncMdfcnPrmArea from "@/views/prfmnc-rpt/dtl/components/PrfmncMdfcnPrmArea.vue";
import CommonButtonArea from "@/views/prfmnc-rpt/dtl/components/CommonButtonArea.vue";

const CODE = {
  HSK: "COM417",
}

export default {
  props: ['prfmncBasicInfo', 'sdflCrynInfo', 'sdflPrcsInfo', 'ntslInfo'],
  components: {
    CommonButtonArea,
    RjctRsnArea,
    PrfmncMdfcnPrmArea,
    SbGrid,
  },
  data() {
    return {
      sdflCrynGridReload: false,
      sdflCrynGridObject: null,
      sdflCrynDataSource: [],
      sdflCrynGridColumns: [],
      sdflCrynGridAttr: {
        showRowNo: false,
        pageable: false,
        excelExport: {
          fileName: '고형연료제품 반입 현황.xlsx',
          includeHeader: true,
          includeFormula: true,
        },
        captionHeight: 40,
        height: 350,
      },
      sdflPrcsGridReload: false,
      sdflPrcsGridObject: null,
      sdflPrcsDataSource: [],
      sdflPrcsGridColumns: [],
      sdflPrcsGridAttr: {
        showRowNo: false,
        pageable: false,
        toolBar: [],
        height: 350,
      },
      ntslGridReload: false,
      ntslGridObject: null,
      ntslDataSource: [],
      ntslGridColumns: [],
      ntslGridAttr: {
        showRowNo: false,
        pageable: false,
        toolBar: [],
      },
      hskCdList: [],
      ntslList: [],
      selectedInfo: {
        inspSn: "",
        incmDclrSn: "",
      },
      prdctnKndInputInfo: {
        srfPt: 0,
        srfNpt: 0,
        bioSrfPt: 0,
        bioSrfNpt: 0
      },
      sdflPrcsGridInitialized: false,  // 최초 첫 행 선택을 관리하기 위한 플래그
      ntslGridInitialized: false  // 최초 첫 행 선택을 관리하기 위한 플래그
    };
  },
  computed: {
  },
  watch: {
    sdflCrynInfo() {
      this.initializeSdflCryn();
    },
    sdflPrcsInfo() {
      this.initializeSdflPrcs();
    },
    ntslInfo() {
      this.initializeNtsl();
    }
  },
  created() {
    this.initialize();
  },
  mounted() {
  },
  methods: {
    initialize() {
      this.loadHskCd();
    },
    loadHskCd() {
      const hskCdList = this.$store.getters.getCodeList({ groupCd: CODE.HSK, useYn: 'Y' });
      this.hskCdList = hskCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd,
        frstRefVl: item.frstRefVl,
      }));
    },
    initializeSdflCryn() {
      this.sdflCrynDataSource = this.sdflCrynInfo.list;
      this.initializeSdflCrynGridColumns();
      this.initializeSdflCrynGridAttr();
      this.sdflCrynGridReload = true;
    },
    initializeSdflPrcs() {
      this.sdflPrcsDataSource = { data: this.sdflPrcsInfo.list, schema: { id: (data) => data.inspSn + '-' + data.incmDclrSn }};
      this.initializeSdflPrcsGridColumns();
      this.initializeSdflPrcsGridAttr();
      this.sdflPrcsGridReload = true;
    },
    initializeNtsl() {
      this.ntslList = this.ntslInfo.list;
      this.setPrdctnKndInputInfo();
      this.initializeNtslGridColumns();
      this.initializeNtslGridAttr();
      this.ntslGridReload = true;
    },
    initializeSdflCrynGridColumns() {
      this.sdflCrynGridColumns = [
        { field: 'inspId', caption: '검사ID', part:'head', width: 100 },
        { field: 'incmDclrNo', caption: '수입신고번호', part:'head', width: 100 },
        { field: 'hskCd', caption: 'HSK.No', width: 115, colCss: 'grid-disabled' },
        { field: 'prdctKndNm', caption: '제품종류', width: 140, colCss: 'grid-disabled' },
        { field: 'rawmtrlKnd', caption: '원재료 종류', width: 110, align: 'left', colCss: 'grid-disabled' },
        { field: 'rawmtrlDtlNm', caption: '세부명칭', width: 180,  align: 'left', colCss: 'grid-disabled',
          type: 'combo',
          items: this.hskCdList,
          comboLabel: 'frstRefVl',
          comboValue: 'value',
        },
        { field: 'incmPmtqty', caption: '수입<br>가능물량(톤)', width: 100, colCss: 'grid-disabled',
          dataType: 'number', align: 'right', format: '#,##0.00',
        },
        { field: 'incmDclqty', caption: '수입신고량', width: 100, colCss: 'grid-disabled',
          dataType: 'number', align: 'right', format: '#,##0.00',
        },
        { field: 'prdctSumImpqty', caption: '이전 수입량<br>합계(톤)', width: 100, colCss: 'grid-disabled',
          dataType: 'number', align: 'right', format: '#,##0.00',
        },
        { field: 'dmstUnloadHburNm', caption: '수입항', width: 100, colCss: 'grid-disabled' },
        { field: 'prdctImpqty', caption: '수입량(톤)', width: 100,
          dataType: 'number', align: 'right', format: '#,##0.00'
        },
        { field: 'incmPsbltyRmnqty', caption: '수입잔량(톤)', width: 100,
          dataType: 'number', align: 'right', format: '#,##0.00',
          colCss: (data) => {
            if ((data.incmPsbltyRmnqty || 0) < 0) {
              return 'grid-danger';
            } else {
              return 'grid-disabled';
            }
          }
        },
        { field: 'dptreYmd', caption: '현지출발일', width: 100,
          type: 'date', calendarType: 'date',
          format: (date) => {
            if (date && date.length === 8) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              const dd = date.substring(6, 8);
              return `${yyyy}-${mm}-${dd}`;
            }
            return date;
          },
        },
        { field: 'arvlPrnmntYmd', caption: '도착(예정)일자', width: 100,
          type: 'date', calendarType: 'date',
          format: (date) => {
            if (date && date.length === 8) {
              const yyyy = date.substring(0, 4);
              const mm = date.substring(4, 6);
              const dd = date.substring(6, 8);
              return `${yyyy}-${mm}-${dd}`;
            }
            return date;
          },
        },
        { field: 'prdctCstdyTypeNm', caption: '보관시설', width: 112 },
        { field: 'mnftrNtnNm', caption: '제조국', width: 100, colCss: 'grid-disabled' },
        { field: 'bzentyNm', caption: '제조업체명', width: 255, align: 'left', colCss: 'grid-disabled' },
        { field: 'rbeoNm', caption: '환경청', width: 100, colCss: 'grid-disabled' },
      ]
    },
    initializeSdflCrynGridAttr() {
      const itemCount = this.sdflCrynInfo.list.length;

      this.sdflCrynGridAttr.height = Math.max((itemCount * 30) + 95, 250);
    },
    initializeSdflPrcsGridColumns() {
      this.sdflPrcsGridColumns = [
        { field: 'inspSn', caption: '검사일련번호', visible: false },
        { field: 'incmDclrSn', caption: '수입신고일련번호', visible: false },
        { field: 'inspId', caption: '검사ID', part:'head', width: 120, total: { headerCss:'grid-header' } },
        { field: 'incmDclrNo', caption: '수입신고번호', part:'head', width: 160, total: { headerCss:'grid-header' } },
        { field: 'prdctKndNm', caption: '제품종류', width: 140, colCss: 'grid-disabled', total: { headerCss:'grid-header' } },
        { field: 'rawmtrlKnd', caption: '원재료 종류', width: 110, align: 'left', colCss: 'grid-disabled',
          total: {
            aggregates: [],
            header: [{ template: '합계', align: 'right' }],
            headerCss:'grid-header',
          }
        },
        { field: 'prdctCrfqty', caption: '이월량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          colCss: 'grid-disabled',
          total: {
            aggregates: [{func: 'sum', require: 'prdctCrfqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctImpqty', caption: '수입량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          colCss: 'grid-disabled',
          total: {
            aggregates: [{func: 'sum', require: 'prdctImpqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctSumNsqty', caption: '공급량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          colCss: 'grid-disabled',
          total: {
            aggregates: [{func: 'sum', require: 'prdctSumNsqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctRsdqty', caption: '잔재물(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          total: {
            aggregates: [{func: 'sum', require: 'prdctRsdqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctRmnqty', caption: '잔량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          colCss: 'grid-disabled',
          total: {
            aggregates: [{func: 'sum', require: 'prdctRmnqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
        { field: 'prdctLcp', caption: '저위발열량(kcal/kg)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          total: {
            aggregates: [
              { func: (items) => {
                  const filteredItems = items.filter(item => item.prdctLcp > 0);
                  const sumPrdctImpqty = filteredItems.reduce((total, item) => total + (item.prdctImpqty || 0), 0);
                  const sumPrdqtyLcp = filteredItems.reduce((total, item) => {
                    const prdctImpqty = item.prdctImpqty || 0;
                    const prdctLcp = item.prdctLcp || 0;
                    return total + (prdctImpqty * prdctLcp);
                  }, 0);

                  return sumPrdctImpqty > 0 ? utils.round2(sumPrdqtyLcp / sumPrdctImpqty) : 0;
                },
                field: 'prdctLcpAvg'
              }
            ],
            header: [{ template: '{{prdctLcpAvg}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
      ]
    },
    initializeSdflPrcsGridAttr() {
      this.sdflPrcsGridAttr.rowCss = (data) => {
        const focusedRowKey = window.SBGrid3.getFocusedKey(this.sdflPrcsGridObject);

        if (focusedRowKey === data._key_) {
          return 'grid-good';
        }

        return '';
      }
    },
    initializeNtslGridColumns() {
      this.ntslGridColumns = [
        { field: 'inspSn', caption: '검사일련번호', visible: false },
        { field: 'incmDclrSn', caption: '수입신고일련번호', visible: false },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false },
        { caption: '판매 사업장 정보',
          columns: [
            { field: 'inspId', caption: '검사ID', part:'head', width: 100, total: { headerCss:'grid-header' } },
            { field: 'incmDclrNo', caption: '수입신고번호', part:'head', width: 120, total: { headerCss:'grid-header' } },
            { field: 'prdctKndNm', caption: '제품종류', width: 140, colCss: 'grid-disabled', total: { headerCss:'grid-header' } },
            { field: 'ctpvStdgNm', caption: '시·도', width: 120, colCss: 'grid-disabled', total: { headerCss:'grid-header' } },
            { field: 'sggStdgNm', caption: '시·군·구', width: 120, colCss: 'grid-disabled', total: { headerCss:'grid-header' } },
            { field: 'bplcNm', caption: '판매 사업장명', minWidth: 100, maxWidth: 1000, width: 100, unit: '%', align: 'left',
              colCss: 'grid-disabled',
              total: {
                aggregates: [],
                header: [{ template: '합계', align: 'right' }],
                headerCss:'grid-header',
              }
            },
          ]
        },
        { field: 'prdctNsqty', caption: '공급량(톤)', dataType: 'number', align: 'right', format: '#,##0.00', width: 150,
          total: {
            aggregates: [{func: 'sum', require: 'prdctNsqty'}],
            header: [{ template: '{{sum}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
        },
      ]
    },
    initializeNtslGridAttr() {
      const itemCount = this.ntslInfo.list.length;

      this.ntslGridAttr.height = Math.max((itemCount  * 30) + 110, 200);
    },
    sdflCrynGridLoaded() {
      this.sdflCrynGridReload = false;
    },
    sdflPrcsGridLoaded() {
      this.sdflPrcsGridReload = false;

      if (!this.sdflPrcsGridInitialized && this.sdflPrcsInfo.list && this.sdflPrcsInfo.list.length > 0) {
        this.selectedInfo.inspSn = this.sdflPrcsInfo.list[0].inspSn;
        this.selectedInfo.incmDclrSn = this.sdflPrcsInfo.list[0].incmDclrSn;
        this.sdflPrcsGridInitialized = true; // 첫 행 선택 완료 표시
        if (this.ntslGridObject && !this.ntslGridInitialized) {
          this.setNtslGridData();
          this.ntslGridInitialized = true; // 첫 행 선택 완료 표시
        }
      }

      if (this.selectedInfo.inspSn && this.selectedInfo.incmDclrSn) {
        const key = this.selectedInfo.inspSn + '-' + this.selectedInfo.incmDclrSn;
        const rowItem= window.SBGrid3.getRow(this.sdflPrcsGridObject, key);
        const column = window.SBGrid3.getColumn(this.sdflPrcsGridObject, 0)[0];
        window.SBGrid3.moveFocus(this.sdflPrcsGridObject, rowItem, column);
      }
    },
    ntslGridLoaded() {
      this.ntslGridReload = false;

      if (!this.ntslGridInitialized) { // 최초 한번만 실행하도록 플래그 사용
        // 첫 행 선택
        if (!this.selectedInfo.inspSn && !this.selectedInfo.incmDclrSn && this.sdflPrcsInfo.list && this.sdflPrcsInfo.list.length > 0) {
          this.selectedInfo.inspSn = this.sdflPrcsInfo.list[0].inspSn;
          this.selectedInfo.incmDclrSn = this.sdflPrcsInfo.list[0].incmDclrSn;
        }

        this.setNtslGridData();
        this.ntslGridInitialized = true; // 첫 행 선택 완료
      }
    },
    sdflPrcsClick(rowData) {
      this.selectedInfo.inspSn = rowData.inspSn;
      this.selectedInfo.incmDclrSn = rowData.incmDclrSn;
      this.setNtslGridData();
    },
    setNtslGridData() {
      const filteredItems = this.ntslList.filter(item =>
          item.inspSn === this.selectedInfo.inspSn && item.incmDclrSn === this.selectedInfo.incmDclrSn
      );
      window.SBGrid3.clearSaveData(this.ntslGridObject);
      window.SBGrid3.setClientData(this.ntslGridObject ,filteredItems);
    },
    setPrdctnKndInputInfo() {
      const srfPt = parseFloat(
          this.ntslList
              .filter(item => item.prdctKndCd === '0001')
              .reduce((sum, item) => sum + (item.prdctNsqty || 0), 0)
              .toFixed(2)
      );
      const srfNpt = parseFloat(
          this.ntslList
              .filter(item => item.prdctKndCd === '0002')
              .reduce((sum, item) => sum + (item.prdctNsqty || 0), 0)
              .toFixed(2)
      );
      const bioSrfPt = parseFloat(
          this.ntslList
              .filter(item => item.prdctKndCd === '0003')
              .reduce((sum, item) => sum + (item.prdctNsqty || 0), 0)
              .toFixed(2)
      );
      const bioSrfNpt = parseFloat(
          this.ntslList
              .filter(item => item.prdctKndCd === '0004')
              .reduce((sum, item) => sum + (item.prdctNsqty || 0), 0)
              .toFixed(2)
      );

      this.prdctnKndInputInfo = {
        srfPt,
        srfNpt,
        bioSrfPt,
        bioSrfNpt
      };
    },
    formatNumber(number) {
      return utils.formatNumberWithComma(number);
    },
  }
}
</script>

<style scoped>
  .grid_box { margin-bottom: 5px; }
</style>