<template>
  <template v-if="prfmncBasicInfo.prfmncMdfcnPrmSqno">
    <div class="board_info mt_40">
      <h3 class="title1">수정허용요청 현황</h3>
      <br-button v-if="showCancelButton" :label="cancelButtonLabel" size="sm" @click="dmndRtrcn" />
    </div>
    <div class="board_write">
      <table>
        <caption>수정허용요청 현황</caption>
        <colgroup>
          <col style="width:20%">
          <col style="width:80%">
        </colgroup>
        <tbody>
        <tr>
          <th scope="row">요청사유</th>
          <td v-html="prfmncBasicInfo.mdfcnPrmDmndRsn ? prfmncBasicInfo.mdfcnPrmDmndRsn.replace(/\n/g, '<br>') : ''"></td>
        </tr>
        <tr>
          <th scope="row">증빙자료</th>
          <td>
            <template v-if="atchFileGroupId">
            <br-attach-file label="증빙자료"
                            atchFileTaskSeCd="PMP"
                            v-model:atchFileGroupId="atchFileGroupId"
                            :files="files"
                            template="01"
                            :readonly="true"
            />
            </template>
            <template v-if="!atchFileGroupId">
              단순 오입력 수정 (증빙자료 첨부 예외 시 체크)
            </template>
          </td>
        </tr>
        <tr>
          <th scope="row">요청상태</th>
          <td>{{ prfmncBasicInfo.prfmncMdfcnPrmSttsNm }}</td>
        </tr>
        <tr v-if="prfmncBasicInfo.mdfcnPrmPrcsRsn">
          <th scope="row">처리사유</th>
          <td v-html="prfmncBasicInfo.mdfcnPrmPrcsRsn ? prfmncBasicInfo.mdfcnPrmPrcsRsn.replace(/\n/g, '<br>') : ''"></td>
        </tr>
        </tbody>
      </table>
    </div>
  </template>
</template>

<script>
const API = {
  PERFORMANCE_MODIFIER_PERMIT_PROGRESS_YN: "/prfmnc/prfmnc-mdfcn-prm/prgrs-yn",
};

export default {
  props: ['prfmncBasicInfo'],
  emits: ['dmnd-rtrcn'],
  components: {},
  data() {
    return {
      showCancelButton: this.prfmncBasicInfo.prfmncMdfcnPrmSttsCd === 'REQ' || this.prfmncBasicInfo.prfmncMdfcnPrmSttsCd === 'REJ',
      atchFileGroupId: "",
      files: [],
      param: {
        prfmncId: "",
        prfmncMdfcnPrmSqno: "",
        prgrsYn: "N",
      },
    };
  },
  computed: {
    cancelButtonLabel() {
      return this.prfmncBasicInfo.prfmncMdfcnPrmSttsCd === "REJ"
          ? "수정허용 요청종료"
          : "수정허용 요청취소";
    },
  },
  watch: {
    prfmncBasicInfo: {
      handler() {
        this.initialize();
      },
      immediate: true,
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    initialize() {
      this.atchFileGroupId = this.prfmncBasicInfo.atchFileGroupId;
      this.files = this.prfmncBasicInfo.prfmncMdfcnPrmFileList;
      this.param.prfmncId = this.prfmncBasicInfo.prfmncId;
      this.param.prfmncMdfcnPrmSqno = this.prfmncBasicInfo.prfmncMdfcnPrmSqno;
    },
    dmndRtrcn() {
      const actionText =
          this.prfmncBasicInfo.prfmncMdfcnPrmSttsCd === "REJ"
              ? "요청종료"
              : "요청취소";

      if (this.prfmncBasicInfo.prfmncMdfcnPrmSttsCd !== 'REJ') {
        this.param.prfmncMdfcnPrmSttsCd = 'CAN';
      } else {
        this.param.prfmncMdfcnPrmSttsCd = '';
      }

      if (!confirm(`수정허용 ${actionText}를 진행하시겠습니까?`)) {
        return;
      }

      this.$apiCall.put(
          API.PERFORMANCE_MODIFIER_PERMIT_PROGRESS_YN,
          this.param,
          () => {
            alert(`수정허용 ${actionText}가 완료되었습니다.`);
            this.showCancelButton = false;
            this.$emit('dmnd-rtrcn');
          },
          () => {
            alert(`수정허용 ${actionText}에 실패하였습니다.`);
          }
      );
    },
  }
}
</script>

<style scoped>

</style>