<template>
  <div class="cont_box">
    <div class="title_btn_area">
      <h3 class="title">알림 수신 내역 상세</h3>
    </div>
    <div class="board_write">
      <table>
        <colgroup>
          <col style="width:20%">
          <col style="width:80%">
        </colgroup>
        <tbody>
        <tr>
          <th scope="row">발송 주소</th>
          <td>
            {{param.sndngAddr}}
          </td>
        </tr>
        <tr>
          <th scope="row">수신 종류</th>
          <td>
            {{param.rcptnType}}
          </td>
        </tr>
        <tr>
          <th scope="row">수신 주소</th>
          <td>
            {{param.rcptnAddr}}
          </td>
        </tr>
        <tr>
          <th scope="row">알림수신일시</th>
          <td>
            {{param.regDt}}
          </td>
        </tr>
        <tr>
          <th scope="row">제목</th>
          <td>
            {{param.ttl}}
          </td>
        </tr>
        <tr>
          <th scope="row">수신 내용</th>
          <td v-html="param.cn">
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="btn_area right">
      <br-button label="목록" format="tertiary line" panel="panel" @click="navigate()"/>
    </div>
  </div>
</template>

<script>

const API = {
  NOTICE: "/notice",
};

export default {
  data() {
    return {
      sn: "",
      param: {
        sndngAddr: "",
        rcptnType: "",
        rcptnAddr: "",
        regDt: "",
        ttl: "",
        cn: "",
      },
    }
  },
  beforeMount() {
    this.sn = this.$route.query.sn;
  },
  mounted() {
    window.scrollTo(0, 0);

    this.read()
  },
  methods : {
    read() {
      this.$apiCall.get(API.NOTICE+"/detail", {sn: this.sn}, this.success, this.error);
    },
    async success(data) {
      if (!data.result) {
        this.error();
        return;
      }
      const json = JSON.parse(JSON.stringify(data.result));
      this.param = json;
    },
    error(data) {
      console.log("error", data)
      alert("데이터를 가져오는 중 오류가 발생했습니다.");
    },
    navigate() {
      this.$router.push({path : `/my-office/NtcRcptn`})
        .catch(() => {
            console.error("Navigation Error")
          }
        );
    },
  },
}
</script>

<style scoped>

</style>
