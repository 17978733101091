<template>
    <div v-show="isOn" :class="['modal', { visible: true }]">
        <div class="popup_box" tabindex="0" style="width: 850px;">
        <h1 class="popup_title">품질등급</h1>
        <div class="popup_cont">
            <h2 class="title1 mt_0">품질등급 상세</h2>
            <div class="tbl_wrap">
                <sb-grid
                    ref="qltyIndctlGrid"
                    id="qltyIndctlGrid"
                    :dataSource="qltyIndctlDataSource"
                    :columns="qltyIndctlGridColumns"
                    :gridattr="qltyIndctlGridAttr"
                    :countVisible="false"
                    :param="localQltyGrdInfo"
                    v-model:grid="qltyIndctlGridObject"
                    :refreshGrid="qltyIndctlGridReload"
                    @loaded="qltyIndctlGridLoaded"
                    @rowClicked="openPopup"
                />
            </div>
            <div class="btn_area center">
                <button type="button" class="btn md" @click="hide">닫기</button> 
            </div>
        </div>
        <button type="button" class="modal_close" @click="hide">팝업 닫기</button>
      </div>
    </div>
    <QltyInspRslPopup ref="popup" :isVisible="isPopupVisible" :searchParam="searchParam" @close="isPopupVisible = false" />
  </template>
  
  <script>
  import { storeSwitch } from "@/js/store/store-switch";
  //import {storeSnackbar} from "@/js/store/store-snackbar";
  import SbGrid from "@/components/SbGrid.vue";
  import QltyInspRslPopup from './QltyInspRslPopup.vue';

  export default {
    components: {SbGrid,QltyInspRslPopup},
    props: {
      qltyGrdInfo: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        isPopupVisible: false,
        isOn: false,
        localQltyGrdInfo :  { ...this.qltyGrdInfo },
        qltyIndctlGridReload: false,
        qltyIndctlGridObject: null,
        qltyIndctlDataSource: {
          ajaxConfig: {
            select: { url: '/qltyGrd/qltyGrdInfoList' },
            paging: false,
          },
          schema: {
            id : ""
          },
        },
        qltyIndctlGridColumns: [],
        qltyIndctlGridAttr: {
          showRowNo: false,
          pageable: false,
          editable: false,
          height: '300px',
          excelExport: {
            fileName: '품질등급상세.xlsx',
            includeHeader: true,
            includeFormula: true,
          }
        },
        searchParam: {},
      };
    },
    computed: {
    },
    watch: {
      qltyGrdInfo: {
        handler(newValue) {
          // qltyGrdInfo가 변경될 때 localQltyInspInfo를 업데이트
          this.localQltyGrdInfo = { ...newValue };
          if (this.localQltyGrdInfo.bplcId) {
            if(this.qltyIndctlGridObject) {
              window.SBGrid3.clearSaveData(this.qltyIndctlGridObject);
              this.callList();  
            }
          }
        },
        immediate: true // 처음에 qltyGrdInfo가 설정될 때도 반영
      }
    },
    created() {
      storeSwitch.setBulb('listPopup', this);
    },
    beforeMount() {
    },
    mounted() {
      this.initialize();
    },
    beforeUnmount() {
    },
    methods: {
      /** 팝업 Show */
      show() {
        this.isOn = true;
      },
      /** 팝업 Hide */
      hide() {
        this.localQltyGrdInfo = {};
        this.isOn = false;
      },
      /** 화면 초기화 */
      async initialize() {
        this.initializeColumns();
        this.initializeGridAttr();
        this.qltyIndctlGridReload = true;
      },
      initializeColumns() {
        this.qltyIndctlGridColumns = [
          { field : 'inspSeNm'      , caption : '검사구분' , editable : false , width : 100 , },
          { field : 'inspBgngYmd'   , caption : '검사일자' , editable : false , width : 100
          , format:(value) => {if (!value) return ''; return value.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');}},
          { field : 'inspSpcmNo'    , caption : '성적서번호' , editable : false , width : 150, colCss:'blue' 
          , format:(value) => {if (!value) return '평균'; return value;}},
          { field : 'inspCpstCd180' , caption : '(저위)발열량' , editable : false , width : 100 },
          { field : 'inspCpstCd140' , caption : '염소' , editable : false , width : 100 },
          { field : 'inspCpstCd80'  , caption : '수은' , editable : false , width : 100 },
          { field : 'inspCpstCd130' , caption : '황분' , editable : false , width : 100 },
        ]
      },
      initializeGridAttr() {
        this.qltyIndctlGridAttr.rowCss = (data) => {
          const keys = window.SBGrid3.getCheckedKeys(this.qltyIndctlGridObject);
          if (keys.includes(data._key_)) {
            return 'grid-danger';
          }
          return '';
        }
      },
      qltyIndctlGridLoaded() {
        this.qltyIndctlGridReload = false;
      },
      callList() {
        window.SBGrid3.clearSaveData(this.qltyIndctlGridObject);
        window.SBGrid3.reload(this.qltyIndctlGridObject);
      },
      openPopup(data) {
        if(data.inspSpcmNo === null || data.inspSpcmNo === undefined) return;
        if(data.inspSeNm == "표시검사") {
          this.searchParam = { qltyInspRslGb: 'qlty', inspSpcmNo: data.inspSpcmNo };
        }else {
          this.searchParam = { qltyInspRslGb: 'insp', inspSpcmNo: data.inspSpcmNo };   
        }
        this.isPopupVisible = true;
        this.$nextTick(() => {
          this.$refs.popup.search();
        });
      },
    }
  }
  </script>
  
  <style scoped>
  .blue {
    color: blue;
  }
  </style>