<template>
  <div v-if="isVisible" class="modal visible">
    <div class="modal_box" tabindex="0">
      <div class="modal_content">
        <div class="logout_time_info">
          <i><img src="@/assets/images/sub/img_logout_time_info.png" alt="로그아웃 타임 이미지"></i>
          <strong>자동 로그아웃 남은 시간이 <span>{{ remainingTime }}</span> 남았습니다.</strong>
          <p>홈페이지 안전한 사용을 위해 로그인 후 약 10분 동안<br>서비스 이용이 없어 로그아웃됩니다.<br>로그인 시간을 연장하시겠습니까?</p>
        </div>
      </div>
      <div class="btn_area center">
        <br-anchor-button label="연장하기" size="md" format="primary" @click="extendSession" />
        <button type="button" class="btn md" @click.stop="close">닫기</button>
      </div>
    </div>
  </div>
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";

export default {
  props: ['remainSeconds'],
  emits: ['refresh', 'change-visible', 'close-popup'],
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    remainingTime() {
      const minutes = Math.floor(this.remainSeconds / 60);
      const seconds = this.remainSeconds % 60;
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(seconds).padStart(2, '0');
      return `${formattedMinutes}분 ${formattedSeconds}초`;
    }
  },
  watch: {
    isVisible(newValue) {
      this.$emit('change-visible', newValue);
    }
  },
  created() {
    storeSwitch.setBulb('session-timeout-pop', this);
  },
  mounted() {
  },
  methods: {
    show() {
      this.isVisible = true;
      document.documentElement.style.overflow = 'hidden';
    },
    hide() {
      this.isVisible = false;
      document.documentElement.style.overflow = '';
    },
    extendSession() {
      this.$emit('close-popup');
      this.hide();
      this.$emit('refresh');
    },
    close() {
      this.$emit('close-popup');
      this.hide();
    }
  }
}
</script>

<style scoped>

</style>