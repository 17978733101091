<template>
  <div class="btn_area">
    <br-button label="목록" size="md" format="tertiary" @click="toList" />
    <div>
      <br-button v-if="showModification" label="수정허용요청" size="md" format="primary" @click="modification" />
      <br-button v-if="showRetrieval" label="회수" size="md" format="primary" @click="retrieval" />
      <br-button v-if="showSaveAndSubmit" label="임시저장" size="md" format="secondary mr_5" @click="save" />
      <br-button v-if="showSaveAndSubmit" label="제출" size="md" format="primary" @click="submission" />
    </div>
  </div>

  <prfmnc-mdfcn-prm-popup @completed="toList" />
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";
import PrfmncMdfcnPrmPopup from "@/views/prfmnc-rpt/components/popup/PrfmncMdfcnPrmPopup.vue";

const ROUTE = {
  PERFORMANCE_LIST: "/prfmnc-rpt/PrfmncRpt"
};
const API = {
  PERFORMANCE_STATUS: "/prfmnc/stts",
  PRFMNC_DDLN_STATUS: "/prfmnc/ddln-stts",
}

export default {
  components: {PrfmncMdfcnPrmPopup},
  props: ['prfmncBasicInfo'],
  emits: ['modification', 'save', 'submission'],
  computed: {
    showSaveAndSubmit() {
      // 작성중, 반려 상태이면서 (구)바이오가스-2021년까지 실적이 아닌 경우 (구 바이오가스의 경우 실적상태와 무관하게 입력, 수정 불가로 상세화면으로만 진입하므로 저장 및 제출 버튼을 노출하지 않음)
      return (this.prfmncBasicInfo.prfmncSttsCd === 'IPG' || this.prfmncBasicInfo.prfmncSttsCd === 'REJ' || this.prfmncBasicInfo.prfmncSttsCd === 'NEW') &&
          !(this.prfmncBasicInfo.bplcKndCd === '04-01' && parseInt(this.prfmncBasicInfo.prfmncYr) <= 2021);
    },
    showRetrieval() {
      // 제출 상태일 경우
      return this.prfmncBasicInfo.prfmncSttsCd === 'SUB';
    },
    showModification() {
      // 승인, 접수 상태이고, 수정허용요청이 없을 경우
      return (
          (this.prfmncBasicInfo.prfmncSttsCd === 'APR' || this.prfmncBasicInfo.prfmncSttsCd === 'REC') &&
          !this.prfmncBasicInfo.prfmncMdfcnPrmSqno
      );
    }
  },
  methods: {
    toList() {
      this.$router.push(ROUTE.PERFORMANCE_LIST);
    },
    modification() {
      const param = {
        bplcId: this.prfmncBasicInfo.bplcId,
        items: [{prfmncYr: this.prfmncBasicInfo.prfmncYr, prfmncMm: this.prfmncBasicInfo.prfmncMm,}],
        prfmncIds: [this.prfmncBasicInfo.prfmncId]
      };

      this.$apiCall.get(
          API.PRFMNC_DDLN_STATUS,
          { prfmncYr: this.prfmncBasicInfo.prfmncYr, bplcKndCd: this.prfmncBasicInfo.bplcKndCd },
          data=> {
            if(data.result === 'Y'){
              alert(`${this.prfmncBasicInfo.prfmncYr}년도는 마감되어 수정허용요청이 불가능합니다.`);
            }else{
              storeSwitch.on('PrfmncMdfcnPrmPopup', param);
            }
          }
      );
    },
    retrieval() {
      const param = {
        prfmncId: this.prfmncBasicInfo.prfmncId,
        prfmncSttsCd: "IPG"
      };

      if (!confirm("해당 실적을 회수하시겠습니까?")) {
        return;
      }

      this.$apiCall.put(
          API.PERFORMANCE_STATUS,
          param,
          () => {
            alert(`실적 회수가 완료되었습니다.`);
            this.toList();
          },
          () => {
            alert(`실적 회수에 실패하였습니다.`);
          });
    },
    submission() {
      this.$emit('submission');
    },
    save() {
      this.$emit('save');
    },
  }
}
</script>

<style scoped>

</style>