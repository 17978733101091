<template>
  <div v-show="isOn" class="modal visible">
    <div class="popup_box" style="max-width: 70%;">
      <h1 class="popup_title">나의 관리 사업장 등록/삭제</h1>
      <div>
        <button type="button" class="modal_close" @click.prevent="hide">팝업닫기</button>
      </div>
      <div class="popup_cont" style="max-height: calc(80vh - 15px); overflow: hidden;overflow-y: auto;">
        <div class="board_info mt_40">
          <div style="flex: auto;">
            <h3 class="title2">전체 사업장 목록<span class="subtitle-note" >※ 관리 사업장 저장 시, 관리자의 승인이 필요합니다.</span></h3>
            <p class="page mt_10">
              <span class="total">전체 <strong>{{ this.myBplcMngPopupDataCount }}</strong></span>
              <span>페이지 <strong>{{ this.searchParam.page }}/{{ this.maxPage === 0 ? 1 : this.maxPage }}</strong></span>
            </p>
          </div>
          <div>
            <br-button label="등록" class="btn md primary" @click="aprvRequest"/>
          </div>
        </div>
        <!-- 그리드 컨텐츠 영역 -->
        <div class="board_list">
          <table style="table-layout: fixed;">
            <caption>나의 사업장 관리 목록-사업장명, 사업장종류, 사업장주소, 승인상태로 구성 </caption>
            <colgroup>
              <col style="width:10%">
              <col style="width:20%">
              <col style="width:20%">
              <col style="width:30%">
              <col style="width:20%">
            </colgroup>
            <thead>
            <tr>
              <th scope="col">선택</th>
              <th scope="col">사업장명</th>
              <th scope="col">사업장종류</th>
              <th scope="col">사업장주소</th>
              <th scope="col">승인상태</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="myBplcMngPopupDataList.length <= 0">
              <td colspan="12">
                <div style="display: flex; justify-content: center;">
                  <div class="no-data-message">조회조건에 해당하는 데이터가 없습니다.</div>
                </div>
              </td>
            </tr>
            <tr v-else v-for="(item, index) in myBplcMngPopupDataList" :key="index"
                :class="{'checked-style': item.chk === 'Y'}">
              <td aria-label="선택" @click="toggleCheckbox(index)" class="checkbox-cell">
                <input type="checkbox" id="chk1" v-model="item.chk"
                       :disabled="item.bplcMngPicSttsCd === '01' && item.userId === this.userInfo.userId"
                       true-value="Y" false-value="N"
                       @change="handleCheckChange(item)" @click.stop>
              </td>
              <td aria-label="사업장명">{{ item.bplcNm }}</td>
              <td aria-label="사업장종류">{{ getCdName(this.bplcKndCdList, item.bplcKndCd) }}</td>
              <td aria-label="사업장주소" style="text-align: left">{{ `${item.untyZip} ${item.bplcAddr} ${item.bplcDaddr? item.bplcDaddr : ''}` }}</td>
              <td aria-label="승인상태">{{ getCdName(this.bplcMngPicSttsCdList, item.bplcMngPicSttsCd) }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- 그리드 페이징 영역 -->
        <insp-pagination :total="myBplcMngPopupDataCount" :size="searchParam.size" v-model:page="searchParam.page"
                         v-model:maxPage="maxPage" @clickedPage="search"></insp-pagination>
        <div class="btn_area center">
          <br-button label="닫기" class="btn sm tertiary" @click="hide" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 나의 사업장 관리 팝업
 *
 * 작성자 : lhs
 * 작성일 : 24. 11. 12.
 * 버전 : 1.0
 */
import {storeSwitch} from "@/js/store/store-switch";
import SbGrid from "@/components/SbGrid.vue";
import inspPagination from "@/views/sfli/components/inspPagination.vue";
import {storeSession} from "@/js/store/store-session";


const API = {
  MY_BPLC_MNG: "/bizfacility/my-bplc-mng",
  COM_DTL_CD: "/code/detail",
};

const CODE = {
  BZENTY_STTS_CD: "MBR003", // 업체상태코드
  BPLC_STTS_CD: "MBR004", // 사업장상태코드
  BPLC_KND_CD: "MBR006", // 사업장종류코드
  BPLC_MNG_PIC_STTS_CD: "MBR023",  //사업장관리담당자상태코드
}

const PAGE_SIZE = 5;

export default {
  components: {inspPagination, SbGrid},
  emits: ['completedMyBplcMng'],
  props: [],
  data() {
    return {
      isOn: false,
      userInfo: storeSession.getMemberInfo(),
      requestParam: {
        bplcId: '',
      },
      searchParam: {
        page: 1,
        size: 5,
      },
      maxPage: 1,
      selectedRowData: '',           // 선택된 행의 데이터를 저장할 변수
      selectedRowIndex: '',       // 선택된 행의 인덱스를 저장할 변수
      bplcSttsCdList: [],
      bplcKndCdList: [],
      bplcMngPicSttsCdList: [],
      myBplcMngPopupDataCount: 0,
      myBplcMngPopupDataList: [],
    };
  },
  computed: {
    initializedList() {
      return this.myBplcMngPopupDataList.map(item => {
        if (['01', '02'].includes(item.bplcMngPicSttsCd)) {
          item.chk = 'Y';  // bplcMngPicSttsCd가 '02'인 경우 초기 체크
        }
        return item;
      });
    }
  },
  watch: {
    isOn(newVal) {
      if (newVal) {
        this.search();
      } else {
        this.reset();
        this.$emit('completedMyBplcMng')
      }
    }
  },
  created() {
    storeSwitch.setBulb('myBplcMngPopup', this);
  },
  beforeMount() {
  },
  mounted() {
    this.initialize();
    // this.myBplcMngPopupDataList = this.initializedList;  // 초기화된 리스트 할당
  },
  beforeUnmount() {
  },
  methods: {
    /** 팝업 Show */
    show() {
      this.isOn = true;
    },
    /** 팝업 Hide */
    hide() {
      this.isOn = false;
    },
    async initialize() {

      this.bplcKndCdList = this.$store.getters.getCodeList({groupCd: CODE.BPLC_KND_CD});           // 사업장종류코드
      this.bplcMngPicSttsCdList = this.$store.getters.getCodeList({groupCd: CODE.BPLC_MNG_PIC_STTS_CD});           // 사업장관리담당자상태코드
      this.search();
    },
    search() {
      this.searchParam.size = PAGE_SIZE;
      this.$apiCall.get(API.MY_BPLC_MNG, this.searchParam, data => {
        if (data.result.list) {
          this.myBplcMngPopupDataCount = data.result.total;
          this.myBplcMngPopupDataList = data.result.list;
          this.myBplcMngPopupDataList = this.initializedList;
        } else {
          this.myBplcMngPopupDataList = [];
        }
      });
    },
    reset() {
      Object.keys(this.requestParam).forEach(key => {
        delete this.requestParam[key];
      });
    },
    getCdName(list, code) {
      const status = list.find(item => item.dtlCd === code);
      return status ? status.dtlCdNm : '';
    },
    aprvRequest() {
      const checkedItems = this.myBplcMngPopupDataList.filter(item => item.chk === 'Y' && (item.userId !== this.userInfo.userId || item.bplcMngPicSttsCd !== '02'))
                                                       .map(item => ({ bplcId: item.bplcId }));

      if (checkedItems.length === 0) {
        alert("선택된 항목이 없습니다.");
        return;
      }

      if(confirm(`${checkedItems.length}개의 사업장을 관리 사업장으로 등록하시겠습니까?`)){
        this.$apiCall.post(
            API.MY_BPLC_MNG,
            {checkedItems},
            () => {
              alert("승인요청되었습니다.\n관리자 승인 후 해당 사업장의 정보 확인이 가능합니다.");
              this.hide();
            },
            () => {
              alert("요청에 실패하였습니다..");
            }
        );
      }
    },
    toggleCheckbox(index){
      if(this.myBplcMngPopupDataList[index].bplcMngPicSttsCd !== '01'){
        this.myBplcMngPopupDataList[index].chk = this.myBplcMngPopupDataList[index].chk === 'Y' ? 'N' : 'Y';
        this.handleCheckChange(this.myBplcMngPopupDataList[index]);
      }
      // this.myBplcMngPopupDataList[index].checked = !this.myBplcMngPopupDataList[index].checked;
    },
    handleCheckChange(item) {
      if('Y' === item.chk){
        return;
      }
      if('N' === item.chk && '02' === item.bplcMngPicSttsCd){
        // 'N'일 때의 작업
        if(confirm(`정말 ${item.bplcNm} 사업장을/를 나의 관리 사업장 목록에서 제외하시겠습니까? 관리 사업장 재등록 시, 관리자의 승인을 다시 받아야합니다.`)){
          this.$apiCall.delete(
              API.MY_BPLC_MNG,
              {bplcId: item.bplcId},
              () => {
                this.search();
                alert(`${item.bplcNm}이 관리 사업장에서 제외되었습니다.`);

              },
              () => {
                item.chk = 'N'
                alert("요청에 실패하였습니다..");
              }
          );
        }
        item.chk = 'Y'
      }
    },
  }
}

</script>

<style scoped>
.checked-style {
  /* 체크박스가 'Y'일 때 적용할 스타일 */
  background-color: #e0f7fa;
}
.subtitle-note {
  font-size: 15px;      /* 폰트 크기 설정 */
  font-weight: normal;  /* 굵기 설정 (기본 또는 normal) */
  color: #888888;       /* 원하는 색상 코드로 변경 */
  margin-left: 10px;
}
.checkbox-cell {
  cursor: pointer;
}
</style>