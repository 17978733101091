<template>
  <div v-show="isOn" class="modal visible">
    <div class="popup_box" style="width: 70%;">
      <h1 class="popup_title">변경 신청</h1>
      <div>
        <button type="button" class="modal_close" @click.prevent="hide">팝업닫기</button>
      </div>
      <div class="popup_cont">
        <div class="board_info">
          <h3 class="title2">변경 신청
            <span class="subtitle-note">※ 신청된 이후 신청내용 변경은 불가하오니 변경신청내용을 다시 한번 확인해주세요.</span>
          </h3>
        </div>
        <div class="board_write">
          <table>
            <caption></caption>
            <colgroup>
              <col style="width:30%">
              <col style="width:70%">
            </colgroup>
            <tbody>
            <tr>
              <th scope="row" class="center">변경사유</th>
              <td>
                <div style="resize:none">
                  <br-form-textarea label="변경사유" v-model="prcsRsn" :rows="4" :maxlength="255" required></br-form-textarea>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="btn_area right">
          <br-button label="신청" format="primary" size="sm" @click="chgInfoAplyRequest"/>
          <br-button label="취소" format="tertiary" size="sm" @click="hide" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {storeSwitch} from "@/js/store/store-switch";
import {storeSession} from "@/js/store/store-session";
import BrFormTextarea from "@/components/BrFormTextarea.vue";
import BrButton from "@/components/BrButton.vue";

export default {
  components: {BrButton, BrFormTextarea},
  emits: ['chgAplyClick'],
  props: {
    id: {type: String, default: 'chgInfoAplyPopup'},
  },
  data() {
    return {
      isOn: false,
      prcsRsn: '',
      prcsUserId : storeSession.getMemberInfo().userId,
    };
  },
  computed: {
  },
  watch: {
    isOn() {
      this.reset();
    }
  },
  created() {
    storeSwitch.setBulb(this.id, this);
  },
  beforeMount() {},
  mounted() {
  },
  beforeUnmount() {},
  methods: {
    /** 팝업 Show */
    show() {
      this.isOn = true;
    },
    /** 팝업 Hide */
    hide() {
      this.isOn = false;
    },
    initialize() {
      // ToDo..
    },
    reset() {
      this.prcsRsn = '';
    },
    chgInfoAplyRequest(){
      if(this.prcsRsn){

        if(confirm("정보 변경 신청하시겠습니까?")){
          this.$emit('chgAplyClick', this.prcsRsn);
          this.isOn= false;
        }

      }else{
        alert('변경 사유가 입력되지않았습니다.');
      }
    },
  }
}
</script>


<style scoped>
.subtitle-note {
  font-size: 15px;      /* 폰트 크기 설정 */
  font-weight: normal;  /* 굵기 설정 (기본 또는 normal) */
  color: #cc0000;       /* 원하는 색상 코드로 변경 */
  margin-left: 10px;
}
</style>