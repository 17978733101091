export default {
    /**
     * 전화번호를 형식에 맞게 변환합니다.
     *
     * 1. 11자리 전화번호는 `xxx-xxxx-xxxx` 형식으로 변환됩니다.
     * 2. 10자리 전화번호는 다음의 두 가지 형식으로 변환됩니다:
     *    - '02'로 시작하는 번호: `02-xxx-xxxx` 또는 `02-xxxx-xxxx` 형식으로 변환.
     *    - '02'로 시작하지 않는 번호: `xxx-xxx-xxxx` 형식으로 변환.
     * 3. 입력된 전화번호가 유효한 형식이 아니면, 입력값을 그대로 반환합니다.
     *
     * @param {string|number} phoneNumber - 전화번호로 변환할 숫자 또는 문자열.
     * @returns {string} 포맷팅된 전화번호 문자열. 유효하지 않은 경우 입력값을 그대로 반환.
     */
    formatPhoneNumber(phoneNumber) {
        if (!phoneNumber) return '';

        if (typeof phoneNumber !== 'string') {
            phoneNumber = String(phoneNumber);
        }

        phoneNumber = phoneNumber.replace(/\D/g, '');

        if (phoneNumber.length === 11) {
            return phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
        } else if (phoneNumber.length === 10) {
            if (phoneNumber.startsWith("02")) {
                return phoneNumber.replace(/(\d{2})(\d{3,4})(\d{4})/, '$1-$2-$3');
            } else {
                return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
            }
        }

        return phoneNumber;
    },

    /**
     * 사업자등록번호를 xxx-xx-xxxxx 형식으로 변환합니다.
     *
     * @param {string|number} businessNumber - 사업자등록번호로 변환할 숫자 또는 문자열.
     * @returns {string} 포맷팅된 사업자등록번호 문자열. 유효하지 않은 경우 입력값을 그대로 반환.
     */
    formatBusinessNumber(businessNumber) {
        if (!businessNumber) return '';

        if (typeof businessNumber !== 'string') {
            businessNumber = String(businessNumber);
        }

        businessNumber = businessNumber.replace(/\D/g, '');

        if (businessNumber.length === 10) {
            return businessNumber.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
        } else {
            return businessNumber;
        }
    },

    /**
     * 숫자를 콤마로 구분된 형식으로 변환합니다.
     * 예: 1000 -> 1,000
     *
     * @param {string|number} number - 포맷팅할 숫자 또는 문자열.
     * @returns {string} 콤마로 구분된 숫자 문자열.
     */
    formatNumberWithComma(number) {
        if (number === 0) return '0';

        if (!number) return '';

        if (typeof number !== 'string') {
            number = String(number);
        }

        // 숫자로 변환 후 소수점 3자리에서 반올림 처리
        const roundedNumber = Math.round(Number(number) * 100) / 100;

        const [integerPart, decimalPart] = String(roundedNumber).split('.');
        const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
    },

    /**
     * 현재 날짜를 주어진 형식으로 반환합니다.
     * 기본 형식은 'YYYY-MM-DD'입니다.
     *
     * @param {string} format - 날짜 형식 (옵션). 예: 'YYYY-MM-DD', 'DD/MM/YYYY', 'MM-DD-YYYY'.
     * @returns {string} 포맷팅된 날짜 문자열.
     */
    getCurrentDateFormatted(format = 'YYYY-MM-DD') {
        const today = new Date();
        const year = today.getFullYear();
        const month = ('0' + (today.getMonth() + 1)).slice(-2);
        const day = ('0' + today.getDate()).slice(-2);

        const dateMap = {
            'YYYY': year,
            'MM': month,
            'DD': day
        };

        return format.replace(/YYYY|MM|DD/g, matched => dateMap[matched]);
    },

    /**
     * 숫자를 소수점 2자리로 반올림합니다.
     *
     * @param {string|number} number - 반올림할 숫자 또는 문자열.
     * @returns {number} 소수점 2자리로 반올림된 숫자.
     */
    round2(number) {
        if (!number && number !== 0) return 0;

        if (typeof number !== 'number') {
            number = parseFloat(number);
        }

        if (isNaN(number)) {
            console.error('Invalid input: Not a number');
            return 0;
        }

        const result = Math.round((number + Number.EPSILON) * 100) / 100;

        // -0을 0으로 처리
        return result === 0 ? 0 : result;
    }

}