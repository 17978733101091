<template>
  <div v-show="isOn" class="modal visible">
    <div class="popup_box" style="width: 80%; max-width: inherit">
      <h1 class="popup_title">자원순환정보시스템 시설 조회</h1>
      <div>
        <button type="button" class="modal_close" @click.prevent="hide">팝업닫기</button>
      </div>
      <div class="popup_cont" style="max-height: calc(90% - 15px);overflow: hidden;overflow-y: auto;">
        <div class="board_info">
          <h2 class="title1">자원순환정보시스템 시설 조회</h2>
        </div>
        <!-- 조회조건 -->
        <div class="sch_form_wrap">
          <div class="sch_form row2">
            <span>
              <label for="" style="width: 12rem">사업장 명</label>
              <div class="input_group">
              <br-form-input class="form_control cal" label="사업장 명" v-model="searchParam.bplcNm"
                             @enterKeyup="search"/>
              </div>
            </span>
            <span>
              <label for="" style="width: 12rem">사업장종류</label>
              <div class="input_group">
                <br-form-select label="사업장종류" groupCd="MBR006" :includeSelectOption="true" v-model:value="searchParam.bplcKndCd" @change="search" />
              </div>
            </span>
            <span>
              <label for="" style="width: 12rem">시도</label>
              <div class="input_group">
                <br-form-select label="시도" :options="rsrcRcyclnFcltCtpvCdList" v-model:value="searchParam.ctpvStdgCd" @change="(data) => onChangedCtpvCd(data)" allSelectable/>
              </div>
              <div class="input_group">
                <br-form-select label="시군구" :options="rsrcRcyclnFcltSggCdList" v-model:value="searchParam.sggStdgCd" allSelectable/>
              </div>
            </span>
            <span>
              <label for="" style="width: 12rem">사업자등록번호</label>
              <div class="input_group">
              <br-form-input class="form_control cal" label="사업자등록번호" v-model="searchParam.brno"
                             @enterKeyup="search"/>
              </div>
            </span>
          </div>
          <div class="btn_area">
            <br-anchor-button class="btn sm txtN ico_refresh" role="button" label="새로고침" icon="reset" @click="reset"/>
            <br-anchor-button class="btn sm sch" role="button" label="조회" icon="icon_search" @click="search"/>
          </div>
        </div>
        <!-- 그리드 영역 -->
        <sb-grid
            :id="`${id}Grid`"
            :dataSource="rsrcRcyclnFcltPopupDataSource"
            :columns="rsrcRcyclnFcltPopupGridColumns"
            :gridattr="rsrcRcyclnFcltPopupGridAttr"
            :param="searchParam"
            v-model:grid="rsrcRcyclnFcltPopupGridObject"
            :refreshGrid="rsrcRcyclnFcltPopupGridReload"
            :excelBtnVisible="false"
            @loaded="rsrcRcyclnFcltPopupGridLoaded"
            @row-clicked="onRowChangedrsrcRcyclnFcltPopupGrid"
        />
        <!-- 최하단 버튼 영역 -->
        <div class="btn_area center">
          <br-button label="선택" class="btn sm tertiary" @click="onClickedSelectButton" />
          <br-button label="닫기" class="btn sm tertiary" @click="hide" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storeSwitch } from "@/js/store/store-switch";
import SbGrid from "@/components/SbGrid.vue";

const API = {
  RSRC_RCYC_FCLT: '/bizfacility/rsrc-rcycln-fclt',
}

export default {
  components: {SbGrid},
  props: {
    id: {type: String, default: 'rsrcRcyclnFcltPopup'},
  },
  emits: [ 'select-button-clicked' ],
  data() {
    return {
      isOn: false,
      searchParam: {
        page: 1,
        bplcNm: '',
        bplcKndCd: '',
        ctpvStdgCd: '',
        sggStdgCd: '',
        brno: '',
      },
      rsrcRcyclnFcltCtpvCdList: [],
      rsrcRcyclnFcltSggCdList: [],
      rsrcRcyclnFcltPopupDataSource: {
        ajaxConfig: {
          select: { url: API.RSRC_RCYC_FCLT },
          paging: true,
        },
        schema: {
          id: 'bplcId'
        }
      },
      rsrcRcyclnFcltPopupGridColumns: [],
      rsrcRcyclnFcltPopupGridAttr: {
        checkable: false,
        showStatus:false,
        showRowNo: false,
        pageable: true,
        editable: false,
        height: 350,
      },
      rsrcRcyclnFcltPopupGridObject: null,
      rsrcRcyclnFcltPopupGridReload: false,
      rowData: [],
    };
  },
  computed: {},
  watch: {
    isOn(newVal) {
      if(newVal) {
        this.initialize();

      }else{
        this.reset();
      }
    }
  },
  created() {
    storeSwitch.setBulb(this.id, this);
  },
  beforeMount() {},
  mounted() {
  },
  beforeUnmount() {},
  methods: {
    show() {
      this.isOn = true;
    },
    hide() {
      this.isOn = false;
    },
    initialize() {
      // 시도 select 초기화
      this.rsrcRcyclnFcltCtpvCdList = this.$store.getters.getSggList({ type: 'ctpv' }).map(item => ({
        text: item.sggNm,
        value: item.sggStdgCd
      }));

      // 시군구 select 초기화
      this.rsrcRcyclnFcltSggCdList = [{ text: "전체", value: "" }];

      // 그리드 설정
      this.initializersrcRcyclnFcltPopupGridColumns();
      this.rsrcRcyclnFcltPopupGridReload = true;
    },
    loadSggCdList(ctpvCd) {
      this.rsrcRcyclnFcltSggCdList = this.$store.getters.getSggList({ upSggStdgCd: ctpvCd }).map(item => ({
        text: item.sggNm,
        value: item.sggStdgCd,
        upSggStdgCd: item.upSggStdgCd
      }));
      this.searchParam.sggStdgCd = '';
    },
    onChangedCtpvCd(data) {
      this.loadSggCdList(data.value);
    },
    search() {
        window.SBGrid3.reload(this.rsrcRcyclnFcltPopupGridObject);
    },
    reset() {
      for (const key of Object.keys(this.searchParam)) {
        this.searchParam[key] = '';
      }
      this.rowData = [];
    },
    initializersrcRcyclnFcltPopupGridColumns() {
      this.rsrcRcyclnFcltPopupGridColumns = [
        { field: 'bplcId', caption: '자원순환사업장아이디', width: 15, unit: '%', align: 'center' },
        { field: 'bzentyNm', caption: '업체명', width: 20, unit: '%', align: 'left' },
        { field: 'bplcNm', caption: '사업장명', width: 25, unit: '%', align: 'left' },
        { field: 'brno', caption: '사업자등록번호', width: 10, unit: '%', align: 'center' },
        { field: 'bplcKndNm', caption: '사업장종류명', width: 10, unit: '%', align: 'center' },
        { field: 'ctpvNm', caption: '시도명', width: 10, unit: '%', align: 'left' },
        { field: 'sggNm', caption: '시군구명', width: 10, unit: '%', align: 'left' },
      ]
    },
    rsrcRcyclnFcltPopupGridLoaded() {
      this.rsrcRcyclnFcltPopupGridReload = false;
    },
    onRowChangedrsrcRcyclnFcltPopupGrid(data) {
      this.rowData = {...data};
    },
    onClickedSelectButton() {
      if(this.rowData.bplcId) {
        this.$emit('select-button-clicked', this.rowData);
        this.isOn = false;
      } else {
        alert('선택된 시설이 없습니다.')
      }
    },
  }

}
</script>

<style scoped></style>