<template>
  <button
      type="button"
      class="btn"
      :class="computedClass"
      :disabled="isDisable"
      @click.prevent="onClick"
  >
    <template v-if="hasLabel">{{ label }}</template>
  </button>
</template>

<script>
import { storeValidator } from "@/js/store/store-validator";
import { storeSession } from "@/js/store/store-session";
import { storeSwitch } from "@/js/store/store-switch";
import menusTotal from "@/js/menu/menus-total";

export default {
  props: {
    label: {type: String, required: true},
    format: {type: String, default: ""},
    size: {type: String, default: ""},
    icon: {type: String, default: ""},
    disabled: {type: Boolean, default: false},
    panel: {type: String, default: ""},
    validate: {type: Boolean, default: false},
    auth: {type: Array, default: () => []},
  },
  emits: ['click'],
  data() {
    return {
      menu: this.$menusTotal.getRouteMenu(this.$route.path),
      isDisable: false,
    };
  },
  computed: {
    hasLabel() {
      return this.label != null && this.label !== "";
    },
    computedClass() {
      const classes = [
        this.format,
        this.size,
        { disabled: this.isDisable }
      ];

      if (this.icon != null && this.icon !== "") {
        classes.push('icon ' + this.icon);
      }

      return classes.filter(Boolean).join(" ");
    },
  },
  watch: {
    disabled() {
      this.updateDisableStatus();
    }
  },
  created() {
    this.updateDisableStatus();
  },
  methods: {
    updateDisableStatus() {
      this.isDisable = this.disabled;
    },
    async onClick() {
      if (this.auth.length > 0) {
        const auth = storeSession.getMenuAuth(this.menu);
        if (!storeSession.hasAuth(auth, this.auth)) {
          alert("권한이 없습니다.");
          return;
        }
      }

      if (!this.isDisable) {
        if (this.auth.includes('E')) {
          const privacyMenuPaths = menusTotal.getPrivacyMenuPaths();
          if (privacyMenuPaths.has(this.$route.path)) {
            try {
              const result = await this.openPrivacyPopup();
              if (result) {
                if (this.validate) {
                  if (storeValidator.validate(this.$route.path, this.panel)) {
                    this.$emit('click');
                  }
                } else {
                  this.$emit('click');
                }
              }
            } catch (error) {
              console.error(error);
              alert(error.message);
            }
            return;
          }
        }

        if (this.validate) {
          if (storeValidator.validate(this.$route.path, this.panel)) {
            this.$emit('click');
          }
        } else {
          this.$emit('click');
        }
      }
    },
    openPrivacyPopup() {
      return new Promise((resolve, reject) => {
        storeSwitch.privacy(this.$route.path, () => {
              resolve(true);
            },
            (error) => {
              reject(new Error(error.message));
            });
      });
    }
  }
}
</script>

<style scoped>

</style>